import Immutable from 'seamless-immutable';
import {
    RETRIEVE_SERVICE_DETAILS_CONSTANTS,
    SET_SELECTED_ACCOUNT_LEVEL,
    SEARCH_SERVICES_CONSTANTS,
    SET_SELECTED_SERVICE_IDENTIFIER,
    SUSPEND_RESUME_SERVICES_CONSTANTS,
    UPDATE_CUSTOMIZED_SERVICE_TAX
} from './actions/services.actions';

export const INITIAL_STATE = Immutable({
    data: {
        serviceThumbnails: null,
        invariantServiceAttributes: null,
        orderNumber: null,
        orderItemId: null,
        pageNumber: null,
        pageCount: null,
        recordCount: null,
        selectedServiceIdentifier: null,
        selectedServices: {
            data: null,
            currencyCode: null
        }
    },
    bulkItemError: null,
    isAccountLevelSelected: false,
    isFetchingBulkItems: false,
    isFetchingServiceDetails: false,
    isSuspendingOrResumingServices: false,
    isUpdatingServiceTax: false,
    serviceDetailsError: null,
    suspendResumeError: null
});

export default function bulkItemsReducer(state = INITIAL_STATE, {payload = {}, type, requestObject = {}}) {
    switch (type) {
        case SEARCH_SERVICES_CONSTANTS.BEGIN:
            return state.set('isFetchingBulkItems', true);
        case SEARCH_SERVICES_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingBulkItems', false)
                .set('bulkItemError', null)
                .setIn(['data', 'pageNumber'], requestObject.pageNumber)
                .setIn(['data', 'pageCount'], payload.PageCount)
                .setIn(['data', 'recordCount'], payload.RecordCount)
                .setIn(['data', 'serviceThumbnails'], payload.ServiceThumbnails)
                .setIn(['data', 'invariantServiceAttributes'], payload.InvariantServiceAttributes);
        case SEARCH_SERVICES_CONSTANTS.FAILURE:
            return state
                .set('isFetchingBulkItems', false)
                .set('bulkItemError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                });
        case SUSPEND_RESUME_SERVICES_CONSTANTS.BEGIN:
            return state
                .set('isSuspendingOrResumingServices', true)
                .set('suspendResumeError', null);
        case SUSPEND_RESUME_SERVICES_CONSTANTS.SUCCESS:
            return state
                .set('isSuspendingOrResumingServices', false)
                .set('suspendResumeError', null);
        case SUSPEND_RESUME_SERVICES_CONSTANTS.FAILURE:
            return state
                .set('isSuspendingOrResumingServices', false)
                .set('suspendResumeError', payload);
        case SET_SELECTED_ACCOUNT_LEVEL:
            return state.set('isAccountLevelSelected', payload);
        case SET_SELECTED_SERVICE_IDENTIFIER:
            return state.setIn(['data', 'selectedServiceIdentifier'], payload);
        case RETRIEVE_SERVICE_DETAILS_CONSTANTS.BEGIN:
            return state.set('isFetchingServiceDetails', true);
        case RETRIEVE_SERVICE_DETAILS_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingServiceDetails', false)
                .set('serviceDetailsError', null)
                .setIn(['data', 'selectedServices', 'data'], payload.Services)
                .setIn(['data', 'selectedServices', 'currencyCode'], payload.CurrencyCode);
        case RETRIEVE_SERVICE_DETAILS_CONSTANTS.FAILURE:
            return state
                .set('isFetchingServiceDetails', false)
                .set('serviceDetailsError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                });
        case UPDATE_CUSTOMIZED_SERVICE_TAX.BEGIN:
            return state
                .set('isUpdatingServiceTax', true);
        case UPDATE_CUSTOMIZED_SERVICE_TAX.SUCCESS:
            return state
                .set('isUpdatingServiceTax', false);
        case UPDATE_CUSTOMIZED_SERVICE_TAX.FAILURE:
            return state
                .set('isUpdatingServiceTax', false);
        default:
            return state;
    }
}
