import Immutable from 'seamless-immutable';
import {
    APPLY_SUBSCRIPTION_COUPON_CONSTANTS,
    CALCULATE_REMOVE_ORDER_QUOTE_CONSTANTS,
    CLEAR_CURRENT_SUBSCRIPTION,
    CLEAR_REMOVE_ORDER_QUOTE,
    EMPTY_SUBSCRIPTIONS_LIST,
    REMOVE_SUBSCRIPTION_CONSTANTS,
    REMOVE_SUBSCRIPTION_DISCOUNT_CONSTANTS,
    RETRIEVE_SUBSCRIPTION_CONSTANTS,
    SEARCH_SUBSCRIPTIONS_CONSTANTS,
    SET_CURRENT_SUBSCRIPTION_OFFERING_DETAILS,
    SET_EDIT_SUBSCRIPTION_ITEM_ID,
    SET_MODIFY_RENEWAL_SUBSCRIPTION_ITEM,
    SET_SUBSCRIPTION_COUPON_CODE,
    UPDATE_SUBSCRIPTION_CONSTANTS,
    RETRIEVE_PENDING_ORDER_CONSTANTS
} from './actions/customer.subscriptions.actions';
import {camelCaseKeys} from '../utilities/object.formatting.helper';

export const INITIAL_STATE = Immutable({
    couponCode: null,
    currentRemoval: null,
    currentSubscription: null,
    currentSubscriptionOffering: {
        details: null
    },
    data: null,
    editSubscriptionItemId: null,
    isFetchingData: false,
    isFetchingDetails: false,
    isRemovingSubscription: false,
    isRemovingDiscount: false,
    isRetrievingPendingOrder: false,
    isUpdating: false,
    isValidatingCoupon: false,
    modifyRenewalSubscriptionItem: null,
    pageCount: null,
    pendingOrder: null,
    pendingOrderError: null,
    recordCount: null,
    subscriptionsError: null,
});

export default function subscriptionsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case CALCULATE_REMOVE_ORDER_QUOTE_CONSTANTS.BEGIN:
            return state.set('isFetchingData', true);
        case CALCULATE_REMOVE_ORDER_QUOTE_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingData', false)
                .set('subscriptionsError', null)
                .set('currentRemoval', {
                    underContract: !!payload.ContractTerminated,
                    totalRemainingAmount: payload.TotalRemainingAmount || 0,
                    totalUsedAmount: payload.TotalUsedAmount || 0,
                    totalAmount: (payload.TotalRemainingAmount || 0) + (payload.TotalUsedAmount || 0),
                    quote: camelCaseKeys(payload.Quote)
                });
        case CALCULATE_REMOVE_ORDER_QUOTE_CONSTANTS.FAILURE:
            return state
                .set('isFetchingData', false)
                .set('subscriptionsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case CLEAR_REMOVE_ORDER_QUOTE:
            return state.set('currentRemoval', null);
        case CLEAR_CURRENT_SUBSCRIPTION:
            return state.set('currentSubscription', null);
        case SET_SUBSCRIPTION_COUPON_CODE:
            return state.set('couponCode', payload);
        case SET_EDIT_SUBSCRIPTION_ITEM_ID:
            return state.set('editSubscriptionItemId', payload);
        case APPLY_SUBSCRIPTION_COUPON_CONSTANTS.BEGIN:
            return state.set('isValidatingCoupon', true);
        case APPLY_SUBSCRIPTION_COUPON_CONSTANTS.SUCCESS:
        case APPLY_SUBSCRIPTION_COUPON_CONSTANTS.FAILURE:
            return state.set('isValidatingCoupon', false);
        case SEARCH_SUBSCRIPTIONS_CONSTANTS.BEGIN:
            return state.set('isFetchingData', true);
        case SEARCH_SUBSCRIPTIONS_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingData', false)
                .set('data', payload.Subscriptions)
                .set('pageCount', payload.PageCount)
                .set('recordCount', payload.RecordCount)
                .set('subscriptionsError', null);
        case SEARCH_SUBSCRIPTIONS_CONSTANTS.FAILURE:
            return state
                .set('isFetchingData', false)
                .set('subscriptionsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case REMOVE_SUBSCRIPTION_CONSTANTS.BEGIN:
            return state.set('isRemovingSubscription', true);
        case REMOVE_SUBSCRIPTION_CONSTANTS.SUCCESS:
            return state
                .set('isRemovingSubscription', false)
                .set('subscriptionsError', null);
        case REMOVE_SUBSCRIPTION_CONSTANTS.FAILURE:
            return state
                .set('isRemovingSubscription', false)
                .set('subscriptionsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case REMOVE_SUBSCRIPTION_DISCOUNT_CONSTANTS.BEGIN:
            return state.set('isRemovingDiscount', true);
        case REMOVE_SUBSCRIPTION_DISCOUNT_CONSTANTS.SUCCESS:
            return state
                .set('isRemovingDiscount', false)
                .set('subscriptionsError', null);
        case REMOVE_SUBSCRIPTION_DISCOUNT_CONSTANTS.FAILURE:
            return state
                .set('isRemovingDiscount', false)
                .set('subscriptionsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case RETRIEVE_SUBSCRIPTION_CONSTANTS.BEGIN:
            return state.set('isFetchingDetails', true);
        case RETRIEVE_SUBSCRIPTION_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingDetails', false)
                .set('currentSubscription', payload.Subscription)
                .setIn(['currentSubscription', 'offeringThumbnail'], payload.OfferingThumbnail)
                .setIn(['currentSubscriptionOffering', 'details'], payload.OfferingDetail)
                .set('subscriptionsError', null);
        case RETRIEVE_SUBSCRIPTION_CONSTANTS.FAILURE:
            return state
                .set('isFetchingDetails', false)
                .set('subscriptionsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case SET_CURRENT_SUBSCRIPTION_OFFERING_DETAILS:
            return state
                .setIn(['currentSubscriptionOffering', 'details'], payload);
        case UPDATE_SUBSCRIPTION_CONSTANTS.BEGIN:
            return state.set('isUpdating', true);
        case UPDATE_SUBSCRIPTION_CONSTANTS.SUCCESS:
            return state
                .set('isUpdating', false)
                .set('currentSubscription', payload.Subscription)
                .set('subscriptionsError', null);
        case UPDATE_SUBSCRIPTION_CONSTANTS.FAILURE:
            return state
                .set('isUpdating', false)
                .set('subscriptionsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case SET_MODIFY_RENEWAL_SUBSCRIPTION_ITEM:
            return state.set('modifyRenewalSubscriptionItem', payload);
        case EMPTY_SUBSCRIPTIONS_LIST:
            return INITIAL_STATE;
        case RETRIEVE_PENDING_ORDER_CONSTANTS.BEGIN:
            return state
                .set('isRetrievingPendingOrder', true);
        case RETRIEVE_PENDING_ORDER_CONSTANTS.SUCCESS:
            return state
                .set('isRetrievingPendingOrder', false)
                .set('pendingOrder', payload.Context)
                .set('pendingOrderError', null);
        case RETRIEVE_PENDING_ORDER_CONSTANTS.FAILURE:
            return state
                .set('pendingOrderError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        default:
            return state;
    }
}
