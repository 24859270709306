import {ExternalRequestHelper} from 'invision-core/src/api/external.request.helper';

export const RETRIEVE_ACCOUNT_STATUS_CUSTOM_TILE_CONSTANTS = {
    BEGIN: 'RETRIEVE_ACCOUNT_STATUS_CUSTOM_TILE_BEGIN',
    SUCCESS: 'RETRIEVE_ACCOUNT_STATUS_CUSTOM_TILE_SUCCESS',
    FAILURE: 'RETRIEVE_ACCOUNT_STATUS_CUSTOM_TILE_FAILURE'
};

const RETRIEVE_ACCOUNT_STATUS_CUSTOM_TILE_EVENTS = [
    RETRIEVE_ACCOUNT_STATUS_CUSTOM_TILE_CONSTANTS.BEGIN,
    RETRIEVE_ACCOUNT_STATUS_CUSTOM_TILE_CONSTANTS.SUCCESS,
    RETRIEVE_ACCOUNT_STATUS_CUSTOM_TILE_CONSTANTS.FAILURE
];

export const retrieveCustomTileValuePromise = (dispatch, customTile, currentCustomer, currentUserContext) => {
    return ExternalRequestHelper(dispatch, RETRIEVE_ACCOUNT_STATUS_CUSTOM_TILE_EVENTS, {
        method: customTile.method,
        url: customTile.EndPoint,
        data: {
            SubscriberId: customTile.SendSubscriberId ? currentCustomer.Id : undefined,
            SubscriberExternalReferenceId: customTile.SendSubscriberExternalReferenceId ? currentCustomer.ExternalReference: undefined,
            AgentId: customTile.SendAgentId ? currentUserContext.Id : undefined,
            AgentRole: customTile.SendAgentRole ? currentUserContext.Roles : undefined,
            Type: customTile.Type
        }
    }, customTile);
};

export const retrieveCustomTileValue = (customTile, currentCustomer, currentUserContext) => {
    return (dispatch) => {
        return retrieveCustomTileValuePromise(dispatch, customTile, currentCustomer, currentUserContext);
    };
};