import Immutable from 'seamless-immutable';
import {createSelector} from 'reselect';

import any from 'ramda/src/any';
import clone from 'ramda/src/clone';
import equals from 'ramda/src/equals';
import filter from 'ramda/src/filter';
import find from 'ramda/src/find';
import forEachObjIndexed from 'ramda/src/forEachObjIndexed';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import map from 'ramda/src/map';
import merge from 'ramda/src/mergeRight';
import path from 'ramda/src/path';
import pathOr from 'ramda/src/pathOr';
import pipe from 'ramda/src/pipe';
import pluck from 'ramda/src/pluck';
import propEq from 'ramda/src/propEq';
import propOr from 'ramda/src/propOr';
import reject from 'ramda/src/reject';
import sum from 'ramda/src/sum';
import uniq from 'ramda/src/uniq';
import uniqWith from 'ramda/src/uniqWith';
import values from 'ramda/src/values';
import without from 'ramda/src/without';

import {ProductMetadataPricingPlansByIdSelector} from 'invision-core/src/components/metadata/products/products.selectors';
import i18n from 'invision-core/src/components/i18n/i18n';
import {translateFaultCode} from 'invision-core/src/api/api.fault.helper';
import {DiscretionaryDiscountsByCurrencySelector} from 'invision-core/src/components/metadata/discounts/discounts.selectors';
import {PageSizePreferenceSelector} from 'invision-core/src/components/session/session.selectors';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import {MetadataCodeTypeSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {pascalCaseKeys} from 'invision-core/src/utilities/object.formatting.helper';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';

import {isExpired} from '../../utilities/credit.card.helper';
import {PaymentInstrumentsCanBeUsedForOrderingContainingBalanceSelector} from './customer.ewallet.selectors';
import {EditSubscriptionItemSelector} from './customer.subscriptions.selectors';
import cloneDeep from 'lodash/cloneDeep';
import {STRUCTURE_TYPES} from '../constants/structure.type.constants';
import {ASYNC_STATUS_CONSTANTS} from '../constants/wizard.constants';
import {PRICING_PLAN_TYPES} from '../constants/pricingplan.type.constants';
import {getPricingPlanAvailabilityDate} from '../helpers/products.order.selector.helpers';
import {getFormattedInstancePropertiesForUI} from '../helpers/instance.property.helper';
import LocaleKeys from '../../locales/keys';
import {
    populateItemDetails,
    removeQuantities,
    SubscriptionQuoteForPreview
} from './products.order.selectors.helper';
import {
    getButtonCount,
    setPricingPlanQuantities,
    setProductListItemSelected
} from '../../components/customer/orders/create/productsOrder/steps/products/products.wizard.helper';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {SUPPORTED_OTT_PAYMENT_INSTRUMENT_TYPES} from '../../components/customer/ewallet/ewallet.constants';

import {
    EMPTY_ARRAY,
    EMPTY_OBJECT,
    FALSE
} from '../constants/common.constants';
import {
    getFullCustomerAddress,
    formatSelectedStoreString
} from '../helpers/customer.helper';
import {BILLER_RULE_CYCLE_LEVEL} from '../../customercare.constants';

const ADDITIONAL_PROPERTY_IS_ACTIVE = 'is_active';
const CONVERGENT_BILLER_INVOICE = Number(SUPPORTED_OTT_PAYMENT_INSTRUMENT_TYPES.CONVERGENT_BILLER_INVOICE);
const TAX_LOCATION_CONSTANTS = {
    CUSTOMER_ADDRESS: 2,
    DEFAULT: 1,
    NPA_NXX: 3,
    STORE_LOCATION: 4,
};

const ProductOrderSelector = (state) => {
    return state.customercare.productOrder;
};

const ProductOrderDataSelector = createSelector(
    [ProductOrderSelector],
    (productOrder) => {
        return pathOr(null, ['data'], productOrder);
    }
);

const AvailableProductsDataSelector = createSelector(
    [ProductOrderDataSelector],
    (orderStoreData) => {
        return orderStoreData.availableProducts.data;
    }
);

const SelectedSeriesContainerSelector = createSelector(
    [ProductOrderDataSelector],
    (orderStoreData) => {
        return orderStoreData.selectedSeriesContainer;
    }
);

export const ProductOfferAsyncIdSelector = createImmutableSelector(
    [ProductOrderSelector],
    (productOfferWizard) => {
        return productOfferWizard.asyncId;
    }
);

export const ProductOfferAsyncStatusSelector = createImmutableSelector(
    [ProductOrderSelector],
    (productOrder) => {
        return productOrder.asyncStatus;
    }
);

export const IsAsyncCompleteSelector  = createImmutableSelector(
    [
        ProductOfferAsyncIdSelector,
        ProductOfferAsyncStatusSelector,
    ],
    (asyncId, asyncStatus) => {
        return asyncId ?
            asyncStatus === ASYNC_STATUS_CONSTANTS.COMPLETE || asyncStatus === ASYNC_STATUS_CONSTANTS.FAILED :
            true;
    }
);

export const CartItemDetailsSelector = createSelector(
    [ProductOrderDataSelector],
    (orderStoreData) => {
        return orderStoreData.cartItemDetails;
    }
);

export const IsShoppingCartLoadingSelector = createSelector(
    [ProductOrderSelector],
    (productOrder) => {
        return productOrder.isCreatingOrUpdatingShoppingCart;
    }
);

export const ProductsMapSelector = createSelector(
    [AvailableProductsDataSelector],
    (availableProductsData) => {
        return availableProductsData.productsMap;
    }
);
// productsMap above contains the products to display for the current page of the order search
// productsWithMetadata contains the metadata for all previously selected products
// - once the product modal loads, that product's metadata is added to productsWithMetadata and never removed
export const ProductsWithMetadataMapSelector = createSelector(
    [ProductOrderDataSelector],
    (productsOrderData) => {
        return productsOrderData.productsWithMetadata;
    }
);

export const AvailableProductsInstancePropertiesLabelSelector = createSelector(
    [ProductsWithMetadataMapSelector],
    (productsMap) => {
        const instanceProperties = {};
        if (!isNil(productsMap)) {
            forEachObjIndexed((productDetails) => {
                pathOr([], ['InstanceProperties'], productDetails).map(function(instanceProp) {
                    instanceProperties[instanceProp.Id] = instanceProp.Name;
                });
            }, productsMap || {});
        }
        return instanceProperties;
    }
);

export const SelectedProductIdSelector = createSelector(
    [AvailableProductsDataSelector],
    (availableProducts) => {
        return availableProducts.selectedProductId;
    }
);

export const HaveSelectedProductMetadata = createSelector(
    [ProductsWithMetadataMapSelector, SelectedProductIdSelector],
    (productsMetadataMap, selectedProductId) => {
        return !!productsMetadataMap[selectedProductId];
    }
);


export const EpisodesSelector = createSelector(
    [AvailableProductsDataSelector],
    (availableProducts) => {
        return availableProducts.episodes;
    }
);

export const OptionalProductsSelector = createSelector(
    [AvailableProductsDataSelector],
    (availableProducts) => {
        return availableProducts.optionalProducts;
    }
);

export const PicklistProductsSelector = createSelector(
    [AvailableProductsDataSelector],
    (availableProducts) => {
        return availableProducts.picklistProducts;
    }
);

export const SelectedProductSelector = createSelector(
    [ProductsWithMetadataMapSelector, SelectedProductIdSelector],
    (productsWithMetadataMap, selectedProductId) => {
        return propOr(EMPTY_OBJECT, selectedProductId, productsWithMetadataMap); //http://ramdajs.com/docs/#propOr
    }
);

export const SelectedProductDescriptionSelector = createSelector(
    [SelectedProductSelector],
    (selectedProduct) => {
        return selectedProduct ? selectedProduct.ShortDescription || selectedProduct.Description : null;
    }
);

// TODO Customer Order will need a better way to check for this, ideally with product template in the future for now check these product classes
export const IsSelectedProductDbss = createSelector(
    [SelectedProductSelector],
    (selectedProduct) => {
        return (selectedProduct.ProductClassification === PRODUCT_CLASSIFICATIONS.AD_HOC ||
            selectedProduct.ProductClassification === PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE) ||
            (selectedProduct.Classification === PRODUCT_CLASSIFICATIONS.AD_HOC ||
            selectedProduct.Classification === PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE);
    }
);

// TODO Customer Order, this is temporary to start build out of support for DBSS products ordering from products wizard that will later incorporate
// service feature, ad hocs, & standalones as visually for options and flows will be similar, for now this will just be for ad hocs, we can rename function later
export const IsSelectedProductAdHoc = createSelector(
    [SelectedProductSelector],
    (selectedProduct) => {
        return selectedProduct.ProductClassification === PRODUCT_CLASSIFICATIONS.AD_HOC;
    }
);

export const IsSelectedProductSeriesContainer = createSelector(
    [SelectedSeriesContainerSelector],
    (selectedSeriesContainer) => {
        return selectedSeriesContainer.productId !== null;
    }
);

export const SelectedSeriesContainerProductId = createSelector(
    [SelectedSeriesContainerSelector],
    (selectedSeriesContainer) => {
        return selectedSeriesContainer.productId;
    }
);

export const SavedOtherSeasonSelections = createSelector(
    [SelectedSeriesContainerSelector],
    (selectedSeriesContainer) => {
        return selectedSeriesContainer.savedOtherSeasonSelectionsMap;
    }
);

const ZERO_COUNT = 0;

export const OtherSeasonSelectionCount = createSelector(
    [SavedOtherSeasonSelections, SelectedProductSelector],
    (otherSeasons, selectedProduct) => {
        if (!otherSeasons || !(values(otherSeasons)).length) {
            return ZERO_COUNT;
        }

        return values(otherSeasons).reduce((accumulator, season) => {
            if (season.productId === selectedProduct.Id) {
                return accumulator;
            } else {
                return accumulator + (season.pricingPlanId ? season.quantity :
                    (season.selectedEpisodes ? (season.selectedEpisodes.length * season.quantity) : 0));
            }
        }, 0);
    }
);

export const HasOtherSavedSeasonSelections = createSelector(
    [OtherSeasonSelectionCount],
    (count) => {
        return !!count;
    }
);

export const OtherSeasonsProductsToAdd = createSelector(
    [SavedOtherSeasonSelections, SelectedProductSelector],
    (otherSeasons, selectedProduct) => {
        if (!otherSeasons) {
            return EMPTY_ARRAY;
        } else {
            return values(otherSeasons).reduce((accumulator, season) => {
                if (season.productId === selectedProduct.Id) {
                    return accumulator;
                } else {
                    if (season.pricingPlanId) {
                        return accumulator.concat({
                            productId: season.productId,
                            pricingPlanId: season.pricingPlanId,
                            quantity: season.quantity
                        });
                    } else {
                        return accumulator.concat((season.selectedEpisodes || []).map((episode) => {
                            return {
                                productId: episode.productId,
                                pricingPlanId: episode.pricingPlanId,
                                quantity: season.quantity
                            };
                        }));
                    }
                }
            }, []);
        }
    }
);

export const HasSavedSeason = createSelector(
    [SavedOtherSeasonSelections, SelectedProductSelector],
    (otherSeasons, selectedProduct) => {
        return !!(otherSeasons && otherSeasons[selectedProduct.Id]);
    }
);

const SavedSeasonEpisodes = createSelector(
    [SavedOtherSeasonSelections, SelectedProductSelector],
    (otherSeasons, selectedProduct) => {
        return !otherSeasons || !otherSeasons[selectedProduct.Id] ?
            EMPTY_ARRAY :
            otherSeasons[selectedProduct.Id].selectedEpisodes || EMPTY_ARRAY;
    }
);

export const SeriesContainerEpisodicBundlesOptions = createSelector(
    [SelectedSeriesContainerSelector],
    (selectedSeriesContainer) => {
        return selectedSeriesContainer.selectSeasonOptions.asMutable({
            deep: true
        });
    }
);

export const SeriesContainerIncludedEpisodicBundles = createSelector(
    [SelectedProductSelector, IsSelectedProductSeriesContainer],
    (selectedProduct, isSeries) => {
        if (isSeries && selectedProduct.ReferencedProducts) {
            const episodicBundles = values(selectedProduct.ReferencedProducts);
            return episodicBundles.map((episodicBundle) => {
                return {
                    product: episodicBundle
                };
            });
        } else {
            return EMPTY_ARRAY;
        }
    }
);

export const IsSelectedProductServiceFeature = createSelector(
    [SelectedProductSelector],
    (selectedProduct) => {
        return selectedProduct ? selectedProduct.ProductClassification === PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE : false;
    }
);

export const SelectedProductGuidanceRatingsSelector = createSelector(
    [SelectedProductSelector],
    (selectedProduct) => {
        if (isNil(selectedProduct) || isEmpty(selectedProduct)) {
            return null;
        }

        return selectedProduct.GuidanceRatings ? pluck('Name')(uniqWith((item1, item2) => {
            return item1.Name === item2.Name;
        })(selectedProduct.GuidanceRatings)).join(', ') : null;
    }
);

const ProductsDisplayOrderSelector = createSelector(
    [AvailableProductsDataSelector],
    (availableProductsData) => {
        return availableProductsData.productsDisplayOrder;
    }
);

export const ProductsSelector = createSelector(
    [ProductsDisplayOrderSelector, ProductsMapSelector],
    (displayOrder = {}, products = {}) => {
        return displayOrder ? displayOrder.map((productId) => {
            return products[productId];
        }) : [];
    }
);

export const SearchCatalogParamsSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrder) => {
        return Immutable.asMutable(productOrder.searchCatalogParams, {
            deep: true
        });
    }
);

export const SearchCouponCodeSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.searchCatalogParams.ProductFilter.CouponCode;
    }
);

export const ReplaceCartItemSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.replaceItem;
    }
);

export const OrderQuoteSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return pathOr(undefined, ['quote'], productOrderData);
    }
);

const SubscriptionShoppingCartSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.subscriptionShoppingCart;
    }
);
export const ModifySubscriptionRequestDataSelector = createSelector(
    [SubscriptionShoppingCartSelector],
    (subscriptionShoppingCart) => {
        return subscriptionShoppingCart.modifyRequestObject;
    }
);
const SubscriptionAddItemsSelector = createSelector(
    [ModifySubscriptionRequestDataSelector],
    (subscriptionModifyRequestData) => {
        return subscriptionModifyRequestData.AddItems;
    }
);
const SubscriptionChangeItemsSelector = createSelector(
    [ModifySubscriptionRequestDataSelector],
    (subscriptionModifyRequestData) => {
        return subscriptionModifyRequestData.ChangeItems;
    }
);
const SubscriptionRemoveItemsSelector = createSelector(
    [ModifySubscriptionRequestDataSelector],
    (subscriptionModifyRequestData) => {
        return subscriptionModifyRequestData.RemoveItems;
    }
);

export const ModifiedSubscriptionItemSelector = createSelector(
    [EditSubscriptionItemSelector, SubscriptionAddItemsSelector, SubscriptionChangeItemsSelector, SubscriptionRemoveItemsSelector, OrderQuoteSelector],
    (originalEditItem, addItems, changeItems, removeItems, orderQuote) => {
        const modifyCartItem = clone(originalEditItem);

        if (!isEmpty(modifyCartItem)) {
            if (modifyCartItem.Children && modifyCartItem.Children.length) {
                if (removeItems && removeItems.length) {
                    modifyCartItem.Children = reject((cartChild) => {
                        return removeItems.includes(convertStringToNumber(cartChild.Id));
                    }, modifyCartItem.Children);
                }
            }

            if (isNil(modifyCartItem.Children)) {
                modifyCartItem.Children = [];
            }

            if (addItems && addItems.length && orderQuote && orderQuote.Items && orderQuote.Items.length) {
                const addedQuoteItems = [];
                orderQuote.Items.forEach((quoteItem) => {

                    const foundAddedItem = find((addedItem) => {
                        return addedItem.ProductId === quoteItem.Product.Id &&
                            addedItem.PricingPlanId === quoteItem.PricingPlan.Id;
                    }, addItems);

                    if (foundAddedItem) {
                        // upon parent PP changes (when ChangeImmediately) the PP of child items may be updated as well
                        modifyCartItem.Children = reject((currentChild) => {
                            return currentChild.Product && (currentChild.Product.Id === foundAddedItem.ProductId);
                        }, modifyCartItem.Children);

                        if (foundAddedItem.InstanceProperties && foundAddedItem.InstanceProperties.length) {
                            const quoteItemWithInstanceProperties = Object.assign({}, {
                                InstanceProperties: getFormattedInstancePropertiesForUI(foundAddedItem.InstanceProperties)
                            }, quoteItem);

                            addedQuoteItems.push(quoteItemWithInstanceProperties);
                        } else {
                            addedQuoteItems.push(quoteItem);
                        }
                    }
                });

                modifyCartItem.Children = modifyCartItem.Children.concat(addedQuoteItems);
            }

            if (changeItems && changeItems.length && originalEditItem.ChangeOptions && originalEditItem.ChangeOptions.length) {
                const selectedChangeOption = originalEditItem.ChangeOptions.find((changeOption) => {
                    // pricing plan change is currently the only supported change item so can use changeItems[0]
                    return changeOption.PricingPlan && (changeOption.PricingPlan.Id === changeItems[0].PricingPlanId);
                });

                if (selectedChangeOption) {
                    modifyCartItem.PricingPlan = selectedChangeOption.PricingPlan;
                }
            }
        }

        return modifyCartItem;
    }
);

export const ReplacementCartItemSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.replacementItem;
    }
);

export const ShoppingCartIsDirtySelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.shoppingCartIsDirty;
    }
);

export const ShoppingCartSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.shoppingCart;
    }
);

export const ShoppingCartItemDetailsLocationCodesSelector = createSelector(
    [ShoppingCartSelector],
    (shoppingCart) => {
        const items = shoppingCart.Items || [];
        const locationCodesArray = [];
        items.forEach((item) => {
            const PricingPlanBillerRuleInstances = item.Details.PricingPlan.PricingPlanBillerRuleInstances;
            if (PricingPlanBillerRuleInstances) {
                PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances.forEach((bri) => {
                    if (bri.LocationCode && (bri.LocationCode !== TAX_LOCATION_CONSTANTS.DEFAULT)) {
                        locationCodesArray.push(bri.LocationCode);
                    }
                });
                PricingPlanBillerRuleInstances.RecurringBillerRuleInstances.forEach((bri) => {
                    if (bri.LocationCode && (bri.LocationCode !== TAX_LOCATION_CONSTANTS.DEFAULT)) {
                        locationCodesArray.push(bri.LocationCode);
                    }
                });
            }
        });
        return locationCodesArray.length ? uniq(locationCodesArray) : EMPTY_ARRAY;
    }
);

export const DisplayTaxAndGlLocationsSelector = createImmutableSelector(
    [ShoppingCartItemDetailsLocationCodesSelector],
    (shoppingCartItemDetailsLocationCodes) => {
        const displayTaxAndGlLocations = {};
        if (shoppingCartItemDetailsLocationCodes.length === 1) {
            displayTaxAndGlLocations.showCustomerAddressPicker = shoppingCartItemDetailsLocationCodes.includes(TAX_LOCATION_CONSTANTS.CUSTOMER_ADDRESS);
            displayTaxAndGlLocations.showServiceIdentifierPicker = shoppingCartItemDetailsLocationCodes.includes(TAX_LOCATION_CONSTANTS.NPA_NXX);
            displayTaxAndGlLocations.showStoreLocationPicker = shoppingCartItemDetailsLocationCodes.includes(TAX_LOCATION_CONSTANTS.STORE_LOCATION);
        } else if (shoppingCartItemDetailsLocationCodes.length > 1) {
            displayTaxAndGlLocations.showCustomerAddressPicker = shoppingCartItemDetailsLocationCodes.includes(TAX_LOCATION_CONSTANTS.CUSTOMER_ADDRESS) && !shoppingCartItemDetailsLocationCodes.includes(TAX_LOCATION_CONSTANTS.NPA_NXX);
            displayTaxAndGlLocations.showServiceIdentifierPicker = shoppingCartItemDetailsLocationCodes.includes(TAX_LOCATION_CONSTANTS.NPA_NXX) && !shoppingCartItemDetailsLocationCodes.includes(TAX_LOCATION_CONSTANTS.CUSTOMER_ADDRESS);
            displayTaxAndGlLocations.showStoreLocationPicker = shoppingCartItemDetailsLocationCodes.includes(TAX_LOCATION_CONSTANTS.STORE_LOCATION);
            displayTaxAndGlLocations.showCustomerAddressAndServiceIdentifierPicker = shoppingCartItemDetailsLocationCodes.includes(TAX_LOCATION_CONSTANTS.CUSTOMER_ADDRESS) && shoppingCartItemDetailsLocationCodes.includes(TAX_LOCATION_CONSTANTS.NPA_NXX);
        }
        return displayTaxAndGlLocations;
    }
);

export const ShoppingCartClassification = createSelector(
    [ShoppingCartSelector],
    (shoppingCart) => {
        return pathOr(null, ['Items', 0, 'Details', 'Product', 'ProductClassification'], shoppingCart);
    }
);

export const ShoppingCartCurrencySelector = createImmutableSelector(
    [ShoppingCartSelector],
    (shoppingCart) => {
        return shoppingCart.Currency;
    }
);

export const IsDbssShoppingCart = createSelector(
    [ShoppingCartClassification],
    (classification) => {
        return classification === PRODUCT_CLASSIFICATIONS.AD_HOC;
    }
);

export const ShoppingCartMetadataSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.shoppingCartProductsMetadata;
    }
);

export const UngiftableShoppingCartItemSelector = createSelector(
    [ShoppingCartMetadataSelector, ShoppingCartSelector],
    (shoppingCartItemMetadata, shoppingCart) => {
        return values(shoppingCartItemMetadata)
            .filter((cartItemMetadata) => {
                const shoppingCartItem = pathOr([], ['Items'], shoppingCart).find((cartItem) => {
                    return cartItemMetadata.Id === cartItem.ProductId;
                });

                const cartItemMetadataPricingPlan = pathOr([], ['PricingPlans'], cartItemMetadata).find((pricingPlan) => {
                    return pricingPlan.Id === path(['PricingPlanId'], shoppingCartItem);
                });

                return !pathOr(false, ['Giftable'], cartItemMetadataPricingPlan);
            });
    }
);

export const InvalidItemsSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        if (productOrderData) {
            return productOrderData.invalidItems;
        }
        return [];
    }
);

export const ShoppingCartErrorSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.shoppingCartError;
    }
);

const OrderQuoteHasShippedItemsSelector = createSelector(
    [OrderQuoteSelector],
    (orderQuote) => {
        return orderQuote && orderQuote.Items ? orderQuote.Items.some((quoteItem) => {
            return pathOr(false, ['PricingPlan', 'Shipped'], quoteItem);
        }) : false;
    }
);

export const OrderRequiresShippingSelector = createSelector(
    [ShoppingCartSelector, OrderQuoteHasShippedItemsSelector, ModifiedSubscriptionItemSelector],
    (shoppingCart, orderQuoteHasShippedItem, modifiedSubscriptionItem) => {
        return pathOr(false, ['ShippingRequired'], shoppingCart) || orderQuoteHasShippedItem || pathOr(false, ['PricingPlan', 'Shipped'], modifiedSubscriptionItem);
    }
);

export const OrderQuoteIsValidSelector = createSelector(
    [OrderQuoteSelector],
    (orderQuote) => {
        return orderQuote.quoteIsValid;
    }
);

export const OrderSubscriptionQuotesAsMutableSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return SubscriptionQuoteForPreview(productOrderData.subscriptionQuotes, productOrderData.quote);
    }
);

export const OrderQuotePaymentInstrumentsSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.orderQuotePaymentInstruments;
    }
);

export const OrderQuoteAmountAppliedSelector = createSelector(
    [OrderQuotePaymentInstrumentsSelector],
    (orderQuotePaymentInstruments) => {
        return (sum(without([undefined], pluck('Amount', orderQuotePaymentInstruments || [])))).toFixed(2);
    }
);

export const DoPaymentInstrumentsCoverOrderTotalSelector = createSelector(
    [OrderQuoteAmountAppliedSelector, OrderQuoteSelector],
    (appliedAmount, quote) => {
        return appliedAmount >= quote.totalAmount;
    }
);

export const DiscretionaryDiscountsReasonSelector = createSelector(
    [ProductOrderSelector],
    (productOrder) => {
        return pathOr(null, ['data', 'discretionaryDiscounts', 'reason'], productOrder);
    }
);

export const DiscretionaryDiscountsIdSelector = createSelector(
    [ProductOrderSelector],
    (productOrder) => {
        return pathOr(null, ['data', 'discretionaryDiscounts', 'selectedIds'], productOrder);
    }
);

export const DiscretionaryDiscountsSelector = createSelector(
    [DiscretionaryDiscountsIdSelector, DiscretionaryDiscountsByCurrencySelector, ShoppingCartCurrencySelector],
    (discretionaryDiscountsId, discretionaryDiscountsByCurrency, shoppingCartCurrency) => {
        return map((discountId) => {
            return find(
                propEq(Number(discountId), 'Id'),
                discretionaryDiscountsByCurrency[shoppingCartCurrency]
            );
        }, discretionaryDiscountsId || []).filter((discretionaryDiscounts) => {
            return discretionaryDiscounts;
        });
    }
);

export const CreatedOrderSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.createdOrder;
    }
);

export const RemovedOrderSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.removedOrder;
    }
);

export const UnfilteredCouponCodesSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.couponCodes;
    }
);

export const CouponCodesSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.couponCodes.asMutable({
            deep: true
        }).filter((couponCode) => {
            // in products.checkout.component.js description=null means the coupon is actively being validated
            return couponCode.description !== null;
        });
    }
);

export const InvalidCouponRedemptionsSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.shoppingCartInvalidCoupons;
    }
);

export const InvalidCouponRedemptionViewModelSelector = createSelector(
    [InvalidCouponRedemptionsSelector],
    (invalidCouponRedemptions) => {
        return invalidCouponRedemptions.map((item) => {
            const cloned = cloneDeep(item);
            cloned.translatedMessage = translateFaultCode(String(cloned.SubCode));
            return cloned;
        });
    }
);

export const ShippingMethodsViewModel = createSelector(
    [OrderQuoteSelector],
    (orderQuote) => {
        return orderQuote.ShippingMethods.map((shippingMethod) => {
            return Object.assign({}, shippingMethod, {
                nameAndProviderLabel:  shippingMethod.Provider ? `${shippingMethod.Name} (${shippingMethod.Provider})` : shippingMethod.Name
            });
        });
    }
);

export const SelectedProductBundleChildrenMapSelector = createSelector(
    [SelectedProductSelector],
    (selectedProduct) => {
        if (isNil(selectedProduct)) {
            return EMPTY_OBJECT;
        }

        if (selectedProduct.BundleChildren && selectedProduct.BundleChildren.length > 0) {
            const bundleChildren = {};

            selectedProduct.BundleChildren.forEach((item) => {
                bundleChildren[item.ProductId] = item;
            }, selectedProduct.BundleChildren);

            return bundleChildren;
        }
        return EMPTY_OBJECT;
    }
);

export const PreviouslySelectedPaymentInstrumentIdsSelector = createSelector(
    [ProductOrderDataSelector, OrderQuotePaymentInstrumentsSelector],
    (productsOrderData, orderQuotePaymentInstruments) => {
        if (orderQuotePaymentInstruments && orderQuotePaymentInstruments.length) {
            const validPreviouslySelectedPaymentInstrumentIds = [];
            productsOrderData.previouslySelectedPaymentInstrumentIds.forEach((previousPaymentId) => {
                const foundPayment = orderQuotePaymentInstruments.find((orderQuotePaymentInstrument) => {
                    return orderQuotePaymentInstrument.Id === previousPaymentId;
                });
                if (foundPayment && foundPayment.Amount) {
                    validPreviouslySelectedPaymentInstrumentIds.push(foundPayment.Id);
                }
            });
            return validPreviouslySelectedPaymentInstrumentIds;
        }
        return productsOrderData.previouslySelectedPaymentInstrumentIds;
    }
);

export const SelectablePaymentInstrumentSelector = createSelector(
    [PreviouslySelectedPaymentInstrumentIdsSelector, PaymentInstrumentsCanBeUsedForOrderingContainingBalanceSelector],
    (previouslySelectedPaymentInstrumentIds, allUserOrderablePaymentInstruments) => {
        return filter((paymentInstrument) => {
            return previouslySelectedPaymentInstrumentIds.indexOf(paymentInstrument.Id) === -1 && !isExpired(paymentInstrument)
                && paymentInstrument.Id > 0;
        }, allUserOrderablePaymentInstruments);
    }
);

export const SelectablePaymentInstrumentForServiceFeaturesSelector = createSelector(
    [SelectablePaymentInstrumentSelector],
    (paymentInstruments) => {
        return paymentInstruments && paymentInstruments.length ? paymentInstruments.filter(instrument => {
            return instrument.Type !== CONVERGENT_BILLER_INVOICE;
        }) : [];
    }
);

export const ProductBundleMappingSelector = createSelector(
    [SelectedProductSelector, ProductsWithMetadataMapSelector],
    (selectedProduct, productsWithMetadataMap) => {
        if (!selectedProduct) {
            return EMPTY_ARRAY;
        }

        //With no bundle children there will be no optional products
        if (!selectedProduct.BundleChildren || selectedProduct.BundleChildren.length === 0) {
            return EMPTY_ARRAY;
        }

        //With no referenced products there will be no associated data for the bundle children
        if (!selectedProduct.ReferencedProducts || selectedProduct.ReferencedProducts.length === 0) {
            return EMPTY_ARRAY;
        }

        //With no product or bundle contexts, there will be no possibility of matching pricing plans
        if (!selectedProduct.ProductContext || !selectedProduct.ProductContext.BundleContexts || selectedProduct.ProductContext.BundleContexts.length === 0) {
            return EMPTY_ARRAY;
        }

        const bundles = filter((item) => {
            return item.StructureType === STRUCTURE_TYPES.PRODUCT;
        }, selectedProduct.BundleChildren);

        if (!bundles || bundles.length === 0) {
            return EMPTY_ARRAY;
        }

        return bundles.map((bundle) => {
            const bundleContext = find((bundleContext) => {
                return bundleContext.Id === bundle.Id;
            }, selectedProduct.ProductContext.BundleContexts) || {};

            const planContext = bundleContext.OrderablePricingPlans || {};

            const planMap = {};
            const discountMap = {};
            const keys = Object.keys(planContext);

            for (const prop of keys) {
                const currentContext = planContext[prop];

                if (!isNil(currentContext)) {
                    const matchingPlan = find((targetPlan) => {
                        return targetPlan.Id === currentContext.Id;
                    }, bundle.PricingPlans);

                    if (!isNil(matchingPlan)) {
                        planMap[prop] = matchingPlan.merge({
                            availabilityDate: getPricingPlanAvailabilityDate(propOr(null, 'AvailabilityStart', matchingPlan))
                        });

                        //Sometimes DiscountAmount is not present and sometimes it is but matches ChargeAmount depending on bundle type
                        //Since there is a lack of consistency across plan context structures, need to check both scenarios
                        if (!isNil(currentContext.DiscountedAmount) && currentContext.DiscountedAmount !== matchingPlan.ChargeAmount) {
                            discountMap[prop] = currentContext.DiscountedAmount;
                        }
                    }
                }
            }

            const referencedProduct = productsWithMetadataMap[bundle.ProductId] || selectedProduct.ReferencedProducts[bundle.ProductId];

            if (!referencedProduct) {
                return;
            }

            const bundleItem = {
                bundle: bundle,
                product: referencedProduct,
                pricingPlans: planMap,
                discounts: discountMap
            };

            return bundleItem;
        }) || EMPTY_ARRAY;
    }
);

export const IncludedEpisodesSelector = createSelector(
    [SelectedProductSelector, SelectedProductBundleChildrenMapSelector, EpisodesSelector, ProductsWithMetadataMapSelector],
    (selectedProduct, bundleMaps, selectedEpisodes, productsWithMetadataMap) => {
        if (!selectedProduct) {
            return EMPTY_ARRAY;
        }

        //With no bundle children there will be no optional products
        if (!selectedProduct.BundleChildren || selectedProduct.BundleChildren.length === 0) {
            return EMPTY_ARRAY;
        }

        //With no referenced products there will be no associated data for the bundle children
        if (!selectedProduct.ReferencedProducts || selectedProduct.ReferencedProducts.length === 0) {
            return EMPTY_ARRAY;
        }

        //With no product or bundle contexts, there will be no possibility of matching pricing plans
        if (!selectedProduct.ProductContext || !selectedProduct.ProductContext.EpisodicContext || selectedProduct.ProductContext.EpisodicContext.length === 0) {
            return EMPTY_ARRAY;
        }

        if (!bundleMaps || bundleMaps.length === 0) {
            return EMPTY_ARRAY;
        }

        const bundleItems = [];

        selectedProduct.ProductContext.EpisodicContext.forEach((episodicContext) => {
            const bundle = bundleMaps[episodicContext.ProductId];

            const planMap = {};

            bundle.PricingPlans.forEach((bundlePricingPlan) => {
                planMap[bundlePricingPlan.Id] = bundlePricingPlan;

                const matchedOrderablePricingPlans = filter((opp) => {
                    return equals(bundlePricingPlan.Id, opp.Id);
                }, episodicContext.OrderablePricingPlans || []);
                if (matchedOrderablePricingPlans.length > 0) {
                    planMap[bundlePricingPlan.Id] = Object.assign({}, planMap[bundlePricingPlan.Id], {
                        discountedAmount: path(['DiscountedAmount'], matchedOrderablePricingPlans[0])
                    });
                }
            });

            const referencedProduct = productsWithMetadataMap[episodicContext.ProductId] || selectedProduct.ReferencedProducts[episodicContext.ProductId];

            if (!referencedProduct) {
                return;
            }

            const selectedItem = selectedEpisodes[bundle.ProductId];
            bundleItems.push(Object.assign({}, selectedItem, {
                bundle: bundle,
                entitledPricingPlanId: episodicContext.EntitledPricingPlanId,
                product: referencedProduct,
                pricingPlans: planMap
            }));
        });

        return bundleItems;
    }
);

export const SelectedShoppingCartItemIndexSelector = createSelector(
    [ProductOrderSelector],
    (productOrder) => {
        return productOrder.data.selectedCartItemIndex;
    }
);

export const SelectedCartItemQuantitySelector = createSelector(
    [SelectedShoppingCartItemIndexSelector, ShoppingCartSelector],
    (shoppingCartItemIndex, shoppingCart) => {
        return shoppingCart.Items && shoppingCart.Items.length &&
               shoppingCartItemIndex !== null && shoppingCart.Items[shoppingCartItemIndex] ?
            shoppingCart.Items[shoppingCartItemIndex].Quantity : 1;
    }
);

export const SelectedCartItemPricingPlanIdSelector = createSelector(
    [SelectedShoppingCartItemIndexSelector, ShoppingCartSelector],
    (shoppingCartItemIndex, shoppingCart) => {
        return shoppingCart.Items && shoppingCart.Items.length &&
               shoppingCartItemIndex !== null && shoppingCart.Items[shoppingCartItemIndex] ?
            shoppingCart.Items[shoppingCartItemIndex].PricingPlanId : null;
    }
);

export const IncludedProductsSelector = createSelector(
    [ProductBundleMappingSelector, SelectedProductSelector, SelectedShoppingCartItemIndexSelector, ShoppingCartSelector, ModifiedSubscriptionItemSelector],
    (bundleMaps, selectedProduct, shoppingCartItemIndex, shoppingCart, subscriptionItem) => {
        if (!bundleMaps || bundleMaps.length === 0) {
            return EMPTY_ARRAY;
        }

        if (!selectedProduct ||
            !selectedProduct.ReferencedProducts ||
            !selectedProduct.ProductContext ||
            !selectedProduct.ProductContext.BundleContexts ||
            selectedProduct.ProductContext.BundleContexts.length === 0) {
            return EMPTY_ARRAY;
        }

        const targetItems =  filter((bundleItem) => {
            return bundleItem.bundle.StructureType === STRUCTURE_TYPES.PRODUCT && bundleItem.bundle.MinimumQuantity === bundleItem.bundle.MaximumQuantity;
        }, bundleMaps) || EMPTY_ARRAY;

        const cartItem = (shoppingCart && shoppingCart.Items && shoppingCart.Items.length && !isNil(shoppingCartItemIndex))
            ? shoppingCart.Items[shoppingCartItemIndex]
            : null;

        targetItems.forEach((currentItem) => {
            if (path(['ChildItems', 'length'], cartItem)) {
                const match = find((childItem) => {
                    return childItem.ProductId === currentItem.product.Id;
                }, cartItem.ChildItems);

                if (!isNil(match) && !isNil(match.InstanceProperties)) {
                    currentItem.instanceProperties = match.InstanceProperties.asMutable({
                        deep: true
                    });
                }

            } else if (path(['Children', 'length'], subscriptionItem)) {
                const match = find((childItem) => {
                    return childItem.Product.Id === currentItem.product.Id;
                }, subscriptionItem.Children);

                if (!isNil(match) && !isNil(match.InstanceProperties)) {
                    currentItem.instanceProperties = match.InstanceProperties;
                }
            }
        });

        return targetItems;
    }
);

export const IndividualEpisodesViewModelSelector = createSelector(
    [IncludedEpisodesSelector, SavedSeasonEpisodes],
    (includedEpisodes, savedSeasonEpisodes) => {
        if (isNil(includedEpisodes) || includedEpisodes.length === 0) {
            return EMPTY_ARRAY;
        }

        return includedEpisodes.map((episode) => {
            const matchingProduct = savedSeasonEpisodes.find((savedEpisode) => {
                return savedEpisode.productId === episode.product.Id;
            });

            const item = merge(clone(episode), { //Since this is a view model returned by another selector, cloning so we don't affect other references
                isSelected: !!matchingProduct,
                purchasedPlans: [],
                instanceProperties: {},
                selectedPlanId: matchingProduct ? matchingProduct.pricingPlanId : null
            });

            //filter the pricing plans to just the purchaseable type
            item.pricingPlans = filter((plan) => {
                return plan.Type === PRICING_PLAN_TYPES.ORDERABLE;
            }, item.pricingPlans);

            item.pricingPlans = map((plan) => {
                return merge(clone(plan), {
                    availabilityDate: getPricingPlanAvailabilityDate(propOr(null, 'AvailabilityStart', plan))
                });
            }, item.pricingPlans);

            if (item.entitledPricingPlanId !== null) {
                const matchingPlan = item.pricingPlans[item.entitledPricingPlanId];

                if (!isNil(matchingPlan)) {
                    item.purchasedPlans.push(matchingPlan);
                }
            }

            return item;
        });
    });

const CurrentEpisodicProductIdsSelector = createSelector(
    [IndividualEpisodesViewModelSelector],
    (individualEpisodes) => {
        return individualEpisodes.map((episode) => {
            return episode.product.Id;
        });
    }
);

const DEFAULT_EPISODIC_PRODUCT_IDS = [];
export const RemainingEpisodicProductIdsSelector = createSelector(
    [SelectedProductSelector, CurrentEpisodicProductIdsSelector],
    (productOrder, currentEpisodeProductIds) => {
        if (!productOrder || !productOrder.BundleChildren) {
            return DEFAULT_EPISODIC_PRODUCT_IDS;
        }

        return productOrder.BundleChildren.filter((product) => {
            return !currentEpisodeProductIds.some((episodeProductId) => {
                return episodeProductId === convertStringToNumber(product.ProductId);
            });
        }).map((product) => {
            return convertStringToNumber(product.ProductId);
        });
    }
);

export const OptionalProductsViewModelSelector = createSelector(
    [ProductBundleMappingSelector, SelectedShoppingCartItemIndexSelector, ShoppingCartSelector, SelectedProductIdSelector, ModifiedSubscriptionItemSelector],
    (bundleMap, shoppingCartItemIndex, shoppingCart, selectedProductId, subscriptionItem) => {
        const cartItem = (shoppingCart && shoppingCart.Items && shoppingCart.Items.length && !isNil(shoppingCartItemIndex))
            ? shoppingCart.Items[shoppingCartItemIndex]
            : null;

        const subscriptionProductId = pathOr(null, ['Product', 'Id'], subscriptionItem);
        const filteredItems = filter((bundleItem) => {
            return bundleItem.bundle.MaximumQuantity > bundleItem.bundle.MinimumQuantity;
        }, bundleMap);

        return filteredItems.map((bundleItem) => {
            const optionalItem = Object.assign(bundleItem,
                {
                    isSelected: false,
                    quantity: bundleItem.bundle.DefaultQuantity
                });

            if (cartItem) {
                const match = find((childItem) => {
                    return childItem.ProductId === optionalItem.product.Id;
                }, cartItem.ChildItems || []);

                if (!isNil(match)) {
                    optionalItem.instanceProperties = match.InstanceProperties;
                    optionalItem.isSelected = true;
                    optionalItem.quantity = match.Quantity;
                } else {
                    optionalItem.quantity = 0; //Editing and product was not in cart so quantity goes to 0
                }
            } else if (subscriptionProductId && (subscriptionProductId === selectedProductId)) {
                let matches = [];

                if (subscriptionItem && subscriptionItem.Children) {
                    matches = filter((sourceItem) => {
                        return sourceItem.Product.Id === optionalItem.bundle.ProductId;
                    }, subscriptionItem.Children);
                }

                if (!isNil(matches) && matches.length > 0) {
                    optionalItem.instanceProperties = matches[0].InstanceProperties;
                    optionalItem.isSelected = true;
                    optionalItem.quantity = matches.length;
                    optionalItem.originallySelected = !isNil(matches[0].OrderNumber);
                } else {
                    optionalItem.quantity = 0;
                    optionalItem.originallySelected = false;
                }
            } else {
                if (optionalItem.bundle.DefaultQuantity > 0) {
                    optionalItem.isSelected = true;
                }
            }

            return bundleItem;
        });
    }
);

const PicklistViewModelInputsAreValid = (selectedProduct) => {
    if (isNil(selectedProduct)) {
        return false;
    }

    //With no bundle children there will be no optional products
    if (!selectedProduct.BundleChildren || selectedProduct.BundleChildren.length === 0) {
        return false;
    }

    //With no referenced products there will be no associated data for the bundle children
    if (!selectedProduct.ReferencedProducts || selectedProduct.ReferencedProducts.length === 0) {
        return false;
    }

    //With no product or bundle contexts, there will be no possibility of matching pricing plans
    if (isNil(selectedProduct.ProductContext) || isNil(selectedProduct.ProductContext.BundleContexts) || selectedProduct.ProductContext.BundleContexts.length === 0) {
        return false;
    }

    return true;
};

export const PicklistProductsViewModelSelector = createSelector(
    [SelectedProductSelector, SelectedShoppingCartItemIndexSelector, ShoppingCartSelector, PicklistProductsSelector, ModifiedSubscriptionItemSelector],

    (selectedProduct, shoppingCartItemIndex, shoppingCart, selectedPicklistProducts, subscriptionItem) => {
        if (!PicklistViewModelInputsAreValid(selectedProduct)) {
            return EMPTY_ARRAY;
        }

        const bundles = filter((item) => {
            return item.StructureType === STRUCTURE_TYPES.PICKLIST;
        }, selectedProduct.BundleChildren);
        if (isNil(bundles) || isEmpty(bundles)) {
            return EMPTY_ARRAY;
        }

        const cartItem = (shoppingCart && shoppingCart.Items && shoppingCart.Items.length && !isNil(shoppingCartItemIndex))
            ? shoppingCart.Items[shoppingCartItemIndex]
            : null;

        const subscriptionProductId = pathOr(null, ['Product', 'Id'], subscriptionItem);
        const productItems = [];

        bundles.forEach((bundle) => {
            if (bundle.PicklistOptions) {
                const bundleContext = find((bundleContext) => {
                    return bundleContext.Id === bundle.Id;
                }, selectedProduct.ProductContext.BundleContexts) || {};

                const planContext = bundleContext.PicklistOrderablePricingPlans || {};

                bundle.PicklistOptions.forEach((pickOption) => {
                    const planMap = {};
                    const discountMap = {};

                    const referencedProduct = selectedProduct.ReferencedProducts[pickOption.ProductId];

                    if (isNil(referencedProduct)) {
                        return;
                    }

                    selectedProduct.PricingPlans.forEach((productPlan) => {
                        const pickPlan = find((pickPlanContext) => {
                            return pickPlanContext.ProductId === pickOption.ProductId;
                        }, planContext[productPlan.Id] || []);

                        if (isNil(pickPlan) || isNil(pickPlan.OrderablePricingPlans) || pickPlan.OrderablePricingPlans.length === 0) {
                            return;
                        }

                        //The OrderablePricingPlans "collection" on pick list items only ever contains 1.
                        const pickPlanContext = pickPlan.OrderablePricingPlans[0];

                        const matchingPlan = find((pricingPlan) => {
                            return pricingPlan.Id === pickPlanContext.Id;
                        }, pickOption.PricingPlans);

                        if (!isNil(matchingPlan)) {
                            planMap[productPlan.Id] = matchingPlan.merge({
                                availabilityDate: getPricingPlanAvailabilityDate(propOr(null, 'AvailabilityStart', matchingPlan))
                            });

                            if (!isNil(pickPlanContext.DiscountedAmount) && pickPlanContext.DiscountedAmount !== matchingPlan.ChargeAmount) {
                                discountMap[productPlan.Id] = pickPlanContext.DiscountedAmount;
                            }
                        }
                    });

                    const productItem = {
                        isSelected: false,
                        bundle: bundle,
                        product: referencedProduct,
                        pricingPlans: planMap,
                        discounts: discountMap
                    };

                    if (cartItem && cartItem.ChildItems && cartItem.ChildItems.length) {
                        const match = find((childItem) => {
                            return childItem.ProductId === productItem.product.Id;
                        }, cartItem.ChildItems);

                        if (match) {
                            productItem.isSelected = true;
                        }
                    } else if (subscriptionProductId && (subscriptionProductId === selectedProduct.Id)) {
                        productItem.isSelected = pipe(
                            pluck('Product'),
                            pluck('Id'),
                            any((id) => {
                                return id === pickOption.ProductId;
                            })
                        )(pathOr([], ['Children'], subscriptionItem));
                    }

                    const selectedItem = selectedPicklistProducts[productItem.product.Id];
                    productItems.push(Object.assign({}, productItem, selectedItem));
                });
            }
        });

        return productItems.length > 0 ? productItems : EMPTY_ARRAY;
    }
);

export const PicklistSelectionMessageSelector = createSelector(
    [PicklistProductsViewModelSelector],
    (picklistItems) => {
        if (!isNil(picklistItems) && picklistItems.length > 0) {
            const bundle = picklistItems[0].bundle;
            const min = bundle.MinimumQuantity;
            const max = bundle.MaximumQuantity;

            const messageParams = {
                min: min,
                max: max
            };

            if (min === 0 && max === 0) { //No min or max
                return i18n.translate(LocaleKeys.SELECTABLE_ITEMS.FROM);
            } else if ((min > max)) { //No max
                return i18n.translate(LocaleKeys.SELECTABLE_ITEMS.AT_LEAST, messageParams);
            } else if (min === 0 && max > min) { //No min
                return i18n.translate(LocaleKeys.SELECTABLE_ITEMS.AT_MOST, messageParams);
            } else if (min === max) { //Min and max are the same (exact number)
                return i18n.translate(LocaleKeys.SELECTABLE_ITEMS.EXACTLY, messageParams);
            } else { //Both min and max
                return i18n.translate(LocaleKeys.SELECTABLE_ITEMS.BETWEEN, messageParams);
            }
        } else {
            return null;
        }
    });

export const IsFetchingSelector = createSelector(
    [ProductOrderSelector],
    (productOrder) => {
        return productOrder.isFetchingData || productOrder.isFetchingProductContext || productOrder.isFetchingDataAndProductContext;
    }
);

export const IsFetchingOrderableProductData = createSelector(
    [ProductOrderSelector],
    (productOrder) => {
        return productOrder.isFetchingOrderableProductData || productOrder.isFetchingBatchMetadata;
    }
);

export const IsFetchingSingleProductContext = createSelector(
    [ProductOrderSelector],
    (productOrder) => {
        return productOrder.isFetchingSingleProductContext;
    }
);

export const IsSubmittingOrderSelector = createSelector(
    [ProductOrderSelector],
    (productOrder) => {
        return productOrder.isSubmittingOrder;
    }
);

export const IsCalculatingQuoteSelector = createSelector(
    [ProductOrderSelector],
    (productOrder) => {
        return productOrder.isCalculatingQuote;
    }
);

export const IsValidatingCoupon = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.couponCodes.some((couponCode) => {
            return couponCode.description === null;
        });
    }
);

export const ViewLastAttemptErrorSelector = createSelector(
    [ProductOrderSelector],
    (productOrder) => {
        return productOrder.lastAttemptError;
    }
);

export const PageNumberSelector = createSelector(
    [AvailableProductsDataSelector],
    (availableProductsData) => {
        return availableProductsData.pageNumber;
    }
);

export const RecordCountSelector = createSelector(
    [AvailableProductsDataSelector],
    (availableProductsData) => {
        return availableProductsData.recordCount;
    }
);

export const StartRecordSelector = createSelector(
    [PageNumberSelector, PageSizePreferenceSelector],
    (pageNumber, pageSize) => {
        return ((pageNumber - 1) * pageSize) + 1;
    }
);

export const EndRecordSelector = createSelector(
    [RecordCountSelector, PageNumberSelector, PageSizePreferenceSelector],
    (recordCount, pageNumber, pageSize) => {
        const endNumber = (pageNumber * pageSize);
        if (endNumber < recordCount) {
            return endNumber;
        }
        return recordCount;
    }
);

export const NumberOfPagesSelector = createSelector(
    [RecordCountSelector, PageSizePreferenceSelector],
    (recordCount, pageSize) => {
        return Math.ceil(recordCount / pageSize);
    }
);

export const AddItemsSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return pathOr([], ['serviceFeatureQuote', 'AddItems'], productOrderData);
    }
);

export const RemoveItemsSelector = createSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return pathOr([], ['serviceFeatureQuote', 'RemoveItems'], productOrderData);
    }
);

export const SubmitChangeOfServiceForServiceFeatureRequestSelector = createSelector(
    [
        CartItemDetailsSelector,
        AddItemsSelector,
        RemoveItemsSelector,
        OrderQuoteSelector
    ],
    (cartItemDetails, addItems, removeItems, quote) => {
        return {
            AddItems: populateItemDetails(addItems, cartItemDetails, quote),
            RemoveItems: populateItemDetails(removeItems, cartItemDetails, quote)
        };
    }
);

export const SubmitChangeOfServiceForServiceFeatureWithoutQuantitiesRequestSelector = createSelector(
    [SubmitChangeOfServiceForServiceFeatureRequestSelector],
    (request) => {
        return {
            AddItems: removeQuantities(request.AddItems),
            RemoveItems: removeQuantities(request.RemoveItems)
        };
    }
);

export const IsServiceFeatureShoppingCart = createSelector(
    [ShoppingCartClassification, SubmitChangeOfServiceForServiceFeatureRequestSelector],
    (classification, submitChangeOfServiceRequest) => {
        return classification === PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE || !!(submitChangeOfServiceRequest.RemoveItems && submitChangeOfServiceRequest.RemoveItems.length);
    }
);

export const HasPrepaidPricingPlanSelector = createSelector(
    [SubmitChangeOfServiceForServiceFeatureRequestSelector],
    (submitChangeOfServiceRequest) => {
        return !!(submitChangeOfServiceRequest.AddItems && submitChangeOfServiceRequest.AddItems.length ? submitChangeOfServiceRequest.AddItems.find(item => {
            return pathOr(false, ['Details', 'PricingPlan', 'Prepaid'], item);
        }) : false || submitChangeOfServiceRequest.RemoveItems && submitChangeOfServiceRequest.RemoveItems.length ? submitChangeOfServiceRequest.RemoveItems.find(item => {
            return pathOr(false, ['Details', 'PricingPlan', 'Prepaid'], item);
        }) : false);
    }
);

export const ProductOrderDataListSelector = createSelector(
    [ProductsSelector, ShoppingCartSelector],
    (productOrder, shoppingCart) => {
        const products = productOrder.length ? productOrder.asMutable({
            deep: true
        }) : [];
        const newProducts = products.length ? products.map((product) => {
            product.isSelected = false;
            product.buttonCount = 0;
            return product;
        }) : [];
        const productsAreServiceFeatures = products.length ? products.find((product) => {
            return product.ProductClassification === PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE;
        }) : false;

        if (productsAreServiceFeatures && newProducts.length) {
            setProductListItemSelected(shoppingCart, newProducts);
            getButtonCount(shoppingCart, newProducts);
            setPricingPlanQuantities(shoppingCart, newProducts);
        }

        return newProducts.length ? Object.keys(newProducts).map(key => {
            return newProducts[key];
        }) : [];
    }
);

export const ShouldShowNoConfiguredProductsMessageSelector = createSelector(
    [
        IsFetchingSelector,
        ProductOrderDataListSelector,
        SearchCatalogParamsSelector
    ],
    (isFetchingData, productData, searchCatalogParams) => {
        return !isFetchingData && (
            productData.length === 0 &&
            !searchCatalogParams.ProductFilter.CouponCode &&
            !searchCatalogParams.SearchString &&
            searchCatalogParams.ProductFilter.Facets.length === 0 &&
            searchCatalogParams.ProductFilter.Categories.length === 0 &&
            searchCatalogParams.ProductFilter.StructureTypes.length === 0
        );
    }
);

export const SkipToCheckoutSelector = createSelector(
    [ProductOrderSelector],
    (productOrder) => {
        return path(['data', 'skipToCheckout'], productOrder);
    }
);

export const ProductDialogErrorMessagesSelector = createSelector(
    [ShoppingCartErrorSelector],
    (shoppingCartError) => {
        return shoppingCartError && [shoppingCartError.message];
    }
);

export const OrderablePricingPlansSelector = createSelector(
    [SelectedProductSelector],
    (selectedProduct) => {
        return pathOr([], ['ProductContext', 'OrderablePricingPlans'], selectedProduct);
    }
);

export const OptionalProductsConfigurationViewModelSelector = createSelector(
    [OptionalProductsViewModelSelector],
    (optionalProducts) => {
        return {
            showInstruction: false,
            minQuantity: 0,
            maxQuantity: 0,
            usePlanSelection: false,
            showPurchasedPlans: false,
            usePerItemQuantity: true,
            items: optionalProducts
        };
    }
);

export const PicklistProductsConfigurationViewModelSelector = createSelector(
    [PicklistProductsViewModelSelector],
    (picklistProducts) => {
        const result = {
            showInstruction: true,
            minQuantity: 0,
            maxQuantity: 0,
            usePlanSelection: false,
            showPurchasedPlans: false,
            items: picklistProducts
        };

        if (!isNil(picklistProducts) && picklistProducts.length > 0) {
            result.minQuantity = result.items[0].bundle.MinimumQuantity;
            result.maxQuantity = result.items[0].bundle.MaximumQuantity;
        }

        return result;
    }
);

export const IndividualEpisodesConfigurationViewModelSelector = createSelector(
    [IndividualEpisodesViewModelSelector],
    (individualEpisodes) => {
        return {
            mappedPricingPlanId: null,
            showInstruction: false,
            minQuantity: 1,
            maxQuantity: 0,
            usePlanSelection: true,
            showPurchasedPlans: true,
            items: individualEpisodes
        };
    }
);

const ProductsSortOptions = createSelector(
    [ProductOrderDataSelector],
    (productOrder) => {
        return productOrder.productsSortOptions;
    }
);

export const SelectedProductsSortOption = createSelector(
    [ProductOrderDataSelector],
    (orderStoreData) => {
        return orderStoreData.selectedProductsSortOption;
    }
);

export const ProductsSortOptionsFormattedSelector = createSelector(
    [ProductsSortOptions, SelectedProductsSortOption],
    (productSortoptions, selectedProductsSortOption) => {
        const productsSortOptions = [];
        productSortoptions.forEach((sortOption) => {
            const clonedSortOption = clone(sortOption);
            clonedSortOption.text = i18n.translate(clonedSortOption.key);
            if (clonedSortOption.value === selectedProductsSortOption.value
                && clonedSortOption.direction === selectedProductsSortOption.direction) {
                clonedSortOption.selected = true;
            }
            productsSortOptions.push(clonedSortOption);
        });
        return productsSortOptions;
    }
);

export const InventoryRegionFilterSelector = createImmutableSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.inventoryStore.regionFilter;
    }
);

export const FormattedInventoryStoresSelector = createImmutableSelector(
    [MetadataCodeTypeSelector(CODES.Stores)],
    (stores) => {
        const activeStores = stores.filter((store) => {
            return store.AdditionalProperties.find((additionalProperty) => {
                return additionalProperty.Key === ADDITIONAL_PROPERTY_IS_ACTIVE;
            }).Value !== FALSE;
        });
        return activeStores.map((store) => {
            return Object.assign({}, store, {
                AdditionalProperties: pascalCaseKeys(store.AdditionalProperties.reduce((additionalProperties, property) => {
                    additionalProperties[property.Key] = property.Value;
                    return additionalProperties;
                }, {}))
            });
        });
    }
);

export const InventoryStoresTableDataSelector = createImmutableSelector(
    [
        InventoryRegionFilterSelector,
        FormattedInventoryStoresSelector
    ],
    (regionFilter, stores) => {
        return stores.reduce((storesTableData, store) => {
            if (regionFilter && store.AdditionalProperties.RegionCode !== regionFilter) {
                return storesTableData;
            }

            storesTableData.push({
                address: `${store.AdditionalProperties.LineOne} ${store.AdditionalProperties.LineTwo}`,
                city: store.AdditionalProperties.City,
                description: store.Description,
                displayName: store.AdditionalProperties.DisplayName,
                name: store.Name,
                postalCode: store.AdditionalProperties.Zipcode,
                region: store.AdditionalProperties.RegionCode,
                stateProvince: store.AdditionalProperties.StateProvince,
                storeId: store.Value
            });
            return storesTableData;
        }, []);
    }
);

export const SelectedInventoryStoreSelector = createImmutableSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return pathOr(null, ['inventoryStore', 'selectedStore'], productOrderData);
    }
);

export const SelectedInventoryStoreDetailsSelector = createImmutableSelector(
    [SelectedInventoryStoreSelector, FormattedInventoryStoresSelector],
    (selectedStoreId, storesDetails) => {
        return selectedStoreId ? storesDetails.find((store) => {
            return store.Value === selectedStoreId;
        }) : null;
    }
);

export const SelectedInventoryStoreNameSelector = createImmutableSelector(
    [SelectedInventoryStoreDetailsSelector],
    (selectedInventoryStoreDetails) => {
        return formatSelectedStoreString(selectedInventoryStoreDetails);
    }
);

export const ServiceIdentifiersDataSelector = createImmutableSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.serviceIdentifiersData;
    }
);

export const ServiceIdentifiersListSelector = createImmutableSelector(
    [ServiceIdentifiersDataSelector],
    (serviceIdentifiersData) => {
        return serviceIdentifiersData.serviceIdentifiersList;
    }
);

export const SelectedServiceIdentifierSelector = createImmutableSelector(
    [ServiceIdentifiersDataSelector],
    (serviceIdentifiersData) => {
        if (!isEmpty(serviceIdentifiersData.selectedServiceIdentifier)) {
            return serviceIdentifiersData.selectedServiceIdentifier;
        } else {
            return null;
        }
    }
);

export const CustomerAddressesDataSelector = createImmutableSelector(
    [ProductOrderDataSelector],
    (productOrderData) => {
        return productOrderData.customerAddressesData;
    }
);

export const FormattedCustomersAddressesListSelector = createImmutableSelector(
    [CustomerAddressesDataSelector],
    (customerAddressesData) => {
        const formattedCustomerAddressesWithID = [];
        if (customerAddressesData.customerAddressesList) {
            customerAddressesData.customerAddressesList.forEach((address) => {
                const formattedCustomerAddresses = {};
                formattedCustomerAddresses.addressId = address.Id;
                formattedCustomerAddresses.fullAddress = (getFullCustomerAddress(address));
                formattedCustomerAddressesWithID.push(formattedCustomerAddresses);
            });
        }
        return formattedCustomerAddressesWithID;
    }
);

export const SelectedCustomerAddressSelector = createImmutableSelector(
    [CustomerAddressesDataSelector],
    (customerAddressesData) => {
        if (!isEmpty(customerAddressesData.selectedCustomerAddress)) {
            return customerAddressesData.selectedCustomerAddress;
        } else {
            return null;
        }
    }
);

export const CartContainsProductWithBillerRuleCycleLevelOtherThanItemSelector = createImmutableSelector(
    [
        ShoppingCartSelector,
        ProductMetadataPricingPlansByIdSelector,
        IsDbss
    ],
    (shoppingCart, productMetadataPricingPlansById, isDbss) => {
        if (isDbss) {
            return pathOr([], ['Items'], shoppingCart).some((item) => {
                const productMetadataPricingPlan  = productMetadataPricingPlansById[item.Details.PricingPlan.Id];
                if (productMetadataPricingPlan && productMetadataPricingPlan.PricingPlanBillerRuleInstances) {
                    const billerRuleInstances = [...(productMetadataPricingPlan.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances || []),
                        ...(productMetadataPricingPlan.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances || [])];
                    return billerRuleInstances.some((billerRuleInstance) => {
                        return billerRuleInstance.BillerRuleConfigurationChargeDetails.CycleLevel !== BILLER_RULE_CYCLE_LEVEL.ITEM;
                    });
                }
            });
        }
    }
);

export const BillingEffectiveDateConfigurationSelector = createImmutableSelector(
    [ProductOrderSelector],
    (productOrder) => {
        return productOrder.billingEffectiveDateConfiguration || EMPTY_OBJECT;
    }
);

export const BillingEffectiveDateSettingsSelector  = createImmutableSelector(
    [BillingEffectiveDateConfigurationSelector],
    (billingEffectiveDateConfiguration) => {
        return {
            minimumDate: billingEffectiveDateConfiguration.MinBillingEffectiveDate,
            pastDateMaxLength: billingEffectiveDateConfiguration.PastBillingEffectiveDateMaxLength
        };
    }
);

export const BillingEffectiveDateOptionsSelector = createImmutableSelector(
    [BillingEffectiveDateConfigurationSelector],
    (billingEffectiveDateConfiguration) => {
        return billingEffectiveDateConfiguration.OrderExecutionToBillingEffectiveDateIntentions || EMPTY_ARRAY;
    }
);