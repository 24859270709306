import {createSelector} from 'reselect';
import {getCustomerIdentifier} from '../../reducers/helpers/customer.helper';

const RecentCustomers = (store) => {
    return store.customercare.recoverableUiState.recentCustomers;
};

export const EMPTY_CUSTOMERS = [];

export const RecentCustomersSelector = createSelector(
    [RecentCustomers],
    (recentCustomers) => {
        if (recentCustomers) {
            const modifiedCustomerList = recentCustomers.data.customers.asMutable({
                deep: true
            });

            Object.keys(modifiedCustomerList).forEach((key) => {
                modifiedCustomerList[key].displayName = getCustomerIdentifier(modifiedCustomerList[key]);
            });

            return modifiedCustomerList;
        }

        return EMPTY_CUSTOMERS;
    }
);

export const IsPanelVisibleSelector = createSelector(
    [RecentCustomers],
    (recentCustomers) => {
        return recentCustomers ? recentCustomers.isPanelVisible : false;
    }
);
