import values from 'ramda/src/values';
import thunkHelper from 'invision-core/src/api/thunk.helper';
import {SUBSCRIBER_ID_HEADER} from 'invision-core/src/api/constants';

export const RETRIEVE_SHARED_ENTITLEMENTS = {
    BEGIN: 'RETRIEVE_SHARED_ENTITLEMENTS_BEGIN',
    SUCCESS: 'RETRIEVE_SHARED_ENTITLEMENTS_SUCCESS',
    FAILURE: 'RETRIEVE_SHARED_ENTITLEMENTS_FAILURE'
};

export const retrieveSharedEntitlements = (customerId, searchTerm=null, pageNumber, pageSize) => {
    return (dispatch) => {
        return thunkHelper(dispatch, values(RETRIEVE_SHARED_ENTITLEMENTS), {
            method: 'post',
            url: 'SubscriberManagement/RetrieveEntitlementBalances',
            headers: {
                [SUBSCRIBER_ID_HEADER]: customerId
            },
            data: {
                GroupEntitlement: true,
                SharedEntitlementSearchByName: searchTerm,
                EntitlementPagination: {
                    PageNumber: pageNumber,
                    PageSize: pageSize
                }
            }
        });
    };
};

export const RETRIEVE_ENTITLEMENT_USAGE = {
    BEGIN: 'RETRIEVE_ENTITLEMENT_USAGE_BEGIN',
    SUCCESS: 'RETRIEVE_ENTITLEMENT_USAGE_SUCCESS',
    FAILURE: 'RETRIEVE_ENTITLEMENT_USAGE_FAILURE'
};

export const retrieveEntitlementUsage = (customerId, entitlementIdentifier, pageNumber, pageSize) => {
    return (dispatch) => {
        return thunkHelper(dispatch, values(RETRIEVE_ENTITLEMENT_USAGE), {
            method: 'post',
            url: 'SubscriberManagement/RetrieveEntitlementUsage',
            headers: {
                [SUBSCRIBER_ID_HEADER]: customerId
            },
            data: {
                PageSize: pageSize,
                PageNumber: pageNumber,
                EntitlementIdentifier: entitlementIdentifier
            }
        });
    };
};

export const SET_SHARED_ENTITLEMENTS_PAGE_SIZE = 'SET_SHARED_ENTITLEMENTS_PAGE_SIZE';
export const setSharedEntitlementsPageSize = (pageSize) => {
    return {
        type: SET_SHARED_ENTITLEMENTS_PAGE_SIZE,
        payload: pageSize
    };
};

export const SET_SHARED_ENTITLEMENTS_CURRENT_PAGE = 'SET_SHARED_ENTITLEMENTS_CURRENT_PAGE';
export const setCurrentPage = (page) => {
    return {
        type: SET_SHARED_ENTITLEMENTS_CURRENT_PAGE,
        payload: page
    };
};

export const SET_SELECTED_SHARED_ENTITLEMENT_INDEX = 'SET_SELECTED_SHARED_ENTITLEMENT_INDEX';
export const setSelectedSharedEntitlementIndex = (entitlementIndex) => {
    return {
        type: SET_SELECTED_SHARED_ENTITLEMENT_INDEX,
        payload: entitlementIndex
    };
};

export const UPDATE_ENTITLEMENT_SHARE_STATUS = {
    BEGIN: 'UPDATE_ENTITLEMENT_SHARE_STATUS_BEGIN',
    SUCCESS: 'UPDATE_ENTITLEMENT_SHARE_STATUS_SUCCESS',
    FAILURE: 'UPDATE_ENTITLEMENT_SHARE_STATUS_FAILURE'
};

export const updateEntitlementShareStatus = (customerId, entitlementIdentifier, shareStatus) => {
    return (dispatch) => {
        return thunkHelper(dispatch, values(UPDATE_ENTITLEMENT_SHARE_STATUS), {
            method: 'post',
            url: 'SubscriberManagement/UpdateEntitlementShareStatus',
            headers: {
                [SUBSCRIBER_ID_HEADER]: customerId
            },
            data: {
                SharedStatus: shareStatus,
                EntitlementIdentifier: entitlementIdentifier
            }
        });
    };
};

export const SET_ENTITLEMENT_SHARE_STATUS = 'SET_ENTITLEMENT_SHARE_STATUS';
export const setEntitlementShareStatus = (entitlementIndex, sharingStatus) => {
    return {
        type: SET_ENTITLEMENT_SHARE_STATUS,
        payload: {
            index: entitlementIndex,
            sharingStatus: sharingStatus
        }
    };
};

export const RESET_SHARED_ENTITLEMENT_TAB_DATA = 'RESET_SHARED_ENTITLEMENT_TAB_DATA';
export const resetSharedEntitlementTabData = () => {
    return {
        type: RESET_SHARED_ENTITLEMENT_TAB_DATA
    };
};

export const SET_ENTITLEMENTS_PAGINATION_DATA = 'SET_ENTITLEMENTS_PAGINATION_DATA';
export const setEntitlementsPaginationData = (pageNumber, pageSize) => {
    return {
        type: SET_ENTITLEMENTS_PAGINATION_DATA,
        payload: {
            pageNumber,
            pageSize
        }
    };
};

export const SET_ENTITLEMENTS_SEARCH_TERM = 'SET_ENTITLEMENTS_SEARCH_TERM';
export const setEntitlementsSearchTerm = (searchTerm) => {
    return {
        type: SET_ENTITLEMENTS_SEARCH_TERM,
        payload: searchTerm
    };
};