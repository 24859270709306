import Immutable from 'seamless-immutable';
import {
    CANCEL_CONTENT_ACTIVITY,
    EMPTY_CONTENT_ACTIVITY_LIST,
    FETCH_CUSTOMER_CONTENT_ACTIVITY
} from './actions/customer.content.activity.actions';
import {FETCH_PRODUCT} from './actions/products.actions';

export const INITIAL_STATE = Immutable({
    contentActivityError: null,
    data: null,
    hasLoadedContentActivity: false,
    isLoadingContentActivity: false,
    isLoadingContentActivityDetails: false,
    isLoadingProduct: false,
    recordCount: null,
    selectedProduct: null
});

const mapContentActivity = (contentActivity) => {
    const map = {
        active: {},
        archived: {}
    };

    if (contentActivity.ActiveActivities) {
        contentActivity.ActiveActivities.forEach((item) => {
            map.active[item.Id] = item;
        });
    }

    if (contentActivity.ArchivedActivities) {
        contentActivity.ArchivedActivities.forEach((item) => {
            map.archived[item.Id] = item;
        });
    }

    return map;
};

export default (state = INITIAL_STATE, {payload = {}, type}) => {
    switch (type) {
        case FETCH_CUSTOMER_CONTENT_ACTIVITY.BEGIN:
            return state.set('isLoadingContentActivity', true);
        case FETCH_CUSTOMER_CONTENT_ACTIVITY.SUCCESS:
            return state
                .set('data', mapContentActivity(payload))
                .set('contentActivityError', null)
                .set('hasLoadedContentActivity', true)
                .set('isLoadingContentActivity', false)
                .set('recordCount', payload.RecordCount);
        case FETCH_CUSTOMER_CONTENT_ACTIVITY.FAILURE:
            return state
                .set('contentActivityError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                })
                .set('isLoadingContentActivity', false);
        case FETCH_PRODUCT.BEGIN:
            return state
                .set('isLoadingProduct', true)
                .set('selectedProduct', null);
        case FETCH_PRODUCT.SUCCESS:
            return state.set('selectedProduct', payload.Product)
                .set('isLoadingProduct', false)
                .set('contentActivityError', null);
        case FETCH_PRODUCT.FAILURE:
            return state
                .set('contentActivityError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                })
                .set('isLoadingProduct', false);
        case CANCEL_CONTENT_ACTIVITY.BEGIN:
            return state.set('isLoadingContentActivity', true);
        case CANCEL_CONTENT_ACTIVITY.SUCCESS:
            return state.set('isLoadingContentActivity', false)
                .set('contentActivityError', null);
        case CANCEL_CONTENT_ACTIVITY.FAILURE:
            return state
                .set('contentActivityError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                })
                .set('isLoadingContentActivity', false);
        case EMPTY_CONTENT_ACTIVITY_LIST:
            return INITIAL_STATE;
        default:
            return state;
    }
};
