import Immutable from 'seamless-immutable';
import {GET_WORKFLOW_ACTIVITY_INSTANCE_STATUS} from './actions/workflow.actions';
const INITIAL_STATE = Immutable({
    data: {}
});

const setWorkflowInstancesMap = (workflowInstances) => {
    const workflowInstancesMap = {};
    workflowInstances.forEach((workflowInstance) => {
        workflowInstancesMap[workflowInstance.Id] = {
            isLoading: false,
            workflowInstance
        };
    });
    return workflowInstancesMap;
};

const setWorkflowInstancesFailed = (workflowInstanceIds, payload) => {
    const workflowInstancesMap = {};
    workflowInstanceIds.forEach((workflowInstanceId) => {
        workflowInstancesMap[workflowInstanceId] = {
            isLoading: false,
            workflowError: {
                code: payload.Code,
                message: payload.Message,
                severity: payload.Severity
            }
        };
    });
    return workflowInstancesMap;
};

const setWorkflowInstancesLoading = (workflowInstanceIds) => {
    const workflowInstancesMap = {};
    workflowInstanceIds.forEach((workflowInstanceId) => {
        workflowInstancesMap[workflowInstanceId] = {
            isLoading: true
        };
    });
    return workflowInstancesMap;
};

export default (state=INITIAL_STATE, {type, payload={}, requestObject={}}) => {
    switch (type) {
        case GET_WORKFLOW_ACTIVITY_INSTANCE_STATUS.BEGIN:
            return state
                .merge({
                    data: setWorkflowInstancesLoading(requestObject.workflowInstanceIds)
                }, {
                    deep: true
                });
        case GET_WORKFLOW_ACTIVITY_INSTANCE_STATUS.SUCCESS:
            return state
                .merge({
                    data: setWorkflowInstancesMap(payload.WorkflowInstances)
                }, {
                    deep: true
                });
        case GET_WORKFLOW_ACTIVITY_INSTANCE_STATUS.FAILURE:
            return state
                .merge({
                    data: setWorkflowInstancesFailed(requestObject.workflowInstanceIds, payload)
                }, {
                    deep: true
                });
        default:
            return state;
    }
};
