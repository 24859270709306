import values from 'ramda/src/values';
import i18n from 'invision-core/src/components/i18n/i18n';
import {
    CUSTOM_SUBSCRIBER_LANGUAGE_HEADER,
    LANGUAGE_HEADER,
    SUBSCRIBER_ID_HEADER,
} from 'invision-core/src/api/constants';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import {
    SEARCH_ENTITIES,
    SORT_BY,
    SORT_DIRECTION
} from 'invision-core/src/constants/search.constants';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {getUiNotificationService} from 'invision-core/src/components/injectables/injector.helper';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {
    ApiConstants,
    InjectorHelper,
    MetadataThunkHelper,
    ThunkHelper
} from 'invision-core';
import {
    INVOICE_TIMING_CONSTANTS,
    NOTIFICATION_TIME_LENGTH,
    REASON_CODES
} from '../../customercare.constants';
import {
    ContextOfferingAction,
    OFFERING_CHARGE_TYPE_IDS,
    OFFERINGS_SEARCH_CONSTANTS
} from '../constants/wizard.constants';
import CustomerCareKeys from '../../locales/keys';
import {
    CurrentCustomerIdSelector,
    RouteParams
} from '../selectors/customer.selectors';
import {
    EditCustomerInfoSelector
} from '../selectors/new.connect.wizard.selectors';
import {SelectedContractSelector} from '../selectors/contract.selectors';
import {ConvergentBillerAccountOfferingsForSelectedAccountSelector} from '../selectors/customer.convergent.biller.selectors';
import {EMPTY_STRING} from '../constants/common.constants';

export const SEARCH_AVAILABLE_OFFERINGS = {
    BEGIN: 'SEARCH_AVAILABLE_OFFERINGS_BEGIN',
    SUCCESS: 'SEARCH_AVAILABLE_OFFERINGS_SUCCESS',
    FAILURE: 'SEARCH_AVAILABLE_OFFERINGS_FAILURE'
};

const SEARCH_AVAILABLE_OFFERINGS_TYPES = [
    SEARCH_AVAILABLE_OFFERINGS.BEGIN,
    SEARCH_AVAILABLE_OFFERINGS.SUCCESS,
    SEARCH_AVAILABLE_OFFERINGS.FAILURE
];

export const RETRIEVE_OFFERING_CONTEXT = {
    BEGIN: 'RETRIEVE_OFFERING_CONTEXT_BEGIN',
    SUCCESS: 'RETRIEVE_OFFERING_CONTEXT_SUCCESS',
    FAILURE: 'RETRIEVE_OFFERING_CONTEXT_FAILURE'
};

const RETRIEVE_OFFERING_CONTEXT_TYPES = [
    RETRIEVE_OFFERING_CONTEXT.BEGIN,
    RETRIEVE_OFFERING_CONTEXT.SUCCESS,
    RETRIEVE_OFFERING_CONTEXT.FAILURE
];

export const RETRIEVE_OFFERING_CONTEXT_QUICK_VIEW = {
    BEGIN: 'RETRIEVE_OFFERING_CONTEXT_QUICK_VIEW_BEGIN',
    SUCCESS: 'RETRIEVE_OFFERING_CONTEXT_QUICK_VIEW_SUCCESS',
    FAILURE: 'RETRIEVE_OFFERING_CONTEXT_QUICK_VIEW_FAILURE'
};

export const RETRIEVE_ORDER_CONTEXT = {
    BEGIN: 'RETRIEVE_ORDER_CONTEXT_BEGIN',
    SUCCESS: 'RETRIEVE_ORDER_CONTEXT_SUCCESS',
    FAILURE: 'RETRIEVE_ORDER_CONTEXT_FAILURE'
};

const RETRIEVE_ORDER_CONTEXT_TYPES = [
    RETRIEVE_ORDER_CONTEXT.BEGIN,
    RETRIEVE_ORDER_CONTEXT.SUCCESS,
    RETRIEVE_ORDER_CONTEXT.FAILURE
];

const RETRIEVE_OFFERING_CONTEXT_QUICK_VIEW_TYPES = values(RETRIEVE_OFFERING_CONTEXT_QUICK_VIEW);

export const UPDATE_CART_SUMMARY = {
    BEGIN: 'UPDATE_CART_SUMMARY_BEGIN',
    SUCCESS: 'UPDATE_CART_SUMMARY_SUCCESS',
    FAILURE: 'UPDATE_CART_SUMMARY_FAILURE'
};

const UPDATE_CART_SUMMARY_TYPES = [
    UPDATE_CART_SUMMARY.BEGIN,
    UPDATE_CART_SUMMARY.SUCCESS,
    UPDATE_CART_SUMMARY.FAILURE
];

export const RETRIEVE_ATTRIBUTES = {
    BEGIN: 'RETRIEVE_ATTRIBUTES_BEGIN',
    SUCCESS: 'RETRIEVE_ATTRIBUTES_SUCCESS',
    FAILURE: 'RETRIEVE_ATTRIBUTES_FAILURE'
};

const RETRIEVE_ATTRIBUTES_TYPES = [
    RETRIEVE_ATTRIBUTES.BEGIN,
    RETRIEVE_ATTRIBUTES.SUCCESS,
    RETRIEVE_ATTRIBUTES.FAILURE
];

export const CALCULATE_COS_ORDER_QUOTE = {
    BEGIN: 'CALCULATE_COS_ORDER_QUOTE_BEGIN',
    SUCCESS: 'CALCULATE_COS_ORDER_QUOTE_SUCCESS',
    FAILURE: 'CALCULATE_COS_ORDER_QUOTE_FAILURE'
};

const CALCULATE_COS_ORDER_QUOTE_TYPES = [
    CALCULATE_COS_ORDER_QUOTE.BEGIN,
    CALCULATE_COS_ORDER_QUOTE.SUCCESS,
    CALCULATE_COS_ORDER_QUOTE.FAILURE
];

export const CALCULATE_OFFERING_ORDER_QUOTE = {
    BEGIN: 'CALCULATE_OFFERING_ORDER_QUOTE_BEGIN',
    SUCCESS: 'CALCULATE_OFFERING_ORDER_QUOTE_SUCCESS',
    FAILURE: 'CALCULATE_OFFERING_ORDER_QUOTE_FAILURE'
};

const CALCULATE_OFFERING_ORDER_QUOTE_TYPES = [
    CALCULATE_OFFERING_ORDER_QUOTE.BEGIN,
    CALCULATE_OFFERING_ORDER_QUOTE.SUCCESS,
    CALCULATE_OFFERING_ORDER_QUOTE.FAILURE
];

export const RETRIEVE_OFFCYCLE_NEXT_RENEWAL_SCHEDULE= {
    BEGIN: 'RETRIEVE_OFFCYCLE_NEXT_RENEWAL_SCHEDULE_BEGIN',
    SUCCESS: 'RETRIEVE_OFFCYCLE_NEXT_RENEWAL_SCHEDULE_SUCCESS',
    FAILURE: 'RETRIEVE_OFFCYCLE_NEXT_RENEWAL_SCHEDULE_FAILURE'
};

const RETRIEVE_OFFCYCLE_NEXT_RENEWAL_SCHEDULE_TYPES = [
    RETRIEVE_OFFCYCLE_NEXT_RENEWAL_SCHEDULE.BEGIN,
    RETRIEVE_OFFCYCLE_NEXT_RENEWAL_SCHEDULE.SUCCESS,
    RETRIEVE_OFFCYCLE_NEXT_RENEWAL_SCHEDULE.FAILURE
];

export const RETRIEVE_OFFERING_ORDER_QUOTE = {
    BEGIN: 'RETRIEVE_OFFERING_ORDER_QUOTE_BEGIN',
    SUCCESS: 'RETRIEVE_OFFERING_ORDER_QUOTE_SUCCESS',
    FAILURE: 'RETRIEVE_OFFERING_ORDER_QUOTE_FAILURE'
};

const RETRIEVE_OFFERING_ORDER_QUOTE_TYPES = [
    RETRIEVE_OFFERING_ORDER_QUOTE.BEGIN,
    RETRIEVE_OFFERING_ORDER_QUOTE.SUCCESS,
    RETRIEVE_OFFERING_ORDER_QUOTE.FAILURE
];

export const RETRIEVE_ORDER_QUOTE = {
    BEGIN: 'RETRIEVE_ORDER_QUOTE_BEGIN',
    SUCCESS: 'RETRIEVE_ORDER_QUOTE_SUCCESS',
    FAILURE: 'RETRIEVE_ORDER_QUOTE_FAILURE'
};

const RETRIEVE_ORDER_QUOTE_TYPES = [
    RETRIEVE_ORDER_QUOTE.BEGIN,
    RETRIEVE_ORDER_QUOTE.SUCCESS,
    RETRIEVE_ORDER_QUOTE.FAILURE
];

export const CALCULATE_ORDER_QUOTE = {
    BEGIN: 'CALCULATE_ORDER_QUOTE_BEGIN',
    SUCCESS: 'CALCULATE_ORDER_QUOTE_SUCCESS',
    FAILURE: 'CALCULATE_ORDER_QUOTE_FAILURE'
};

const CALCULATE_ORDER_QUOTE_TYPES = [
    CALCULATE_ORDER_QUOTE.BEGIN,
    CALCULATE_ORDER_QUOTE.SUCCESS,
    CALCULATE_ORDER_QUOTE.FAILURE
];

export const RETRIEVE_BILLER_RULE_INSTANCE_DISCOUNT_CONTEXT = {
    BEGIN: 'RETRIEVE_BILLER_RULE_INSTANCE_DISCOUNT_CONTEXT_BEGIN',
    SUCCESS: 'RETRIEVE_BILLER_RULE_INSTANCE_DISCOUNT_CONTEXT_SUCCESS',
    FAILURE: 'RETRIEVE_BILLER_RULE_INSTANCE_DISCOUNT_CONTEXT_FAILURE'
};

const RETRIEVE_BILLER_RULE_INSTANCE_DISCOUNT_CONTEXT_TYPES = [
    RETRIEVE_BILLER_RULE_INSTANCE_DISCOUNT_CONTEXT.BEGIN,
    RETRIEVE_BILLER_RULE_INSTANCE_DISCOUNT_CONTEXT.SUCCESS,
    RETRIEVE_BILLER_RULE_INSTANCE_DISCOUNT_CONTEXT.FAILURE
];

export const SUBMIT_ORDER = {
    BEGIN: 'SUBMIT_ORDER_BEGIN',
    SUCCESS: 'SUBMIT_ORDER_SUCCESS',
    FAILURE: 'SUBMIT_ORDER_FAILURE'
};
const SUBMIT_ORDER_EVENTS = [SUBMIT_ORDER.BEGIN, SUBMIT_ORDER.SUCCESS, SUBMIT_ORDER.FAILURE];

export const SEARCH_INVENTORY = {
    BEGIN: 'SEARCH_INVENTORY_BEGIN',
    SUCCESS: 'SEARCH_INVENTORY_SUCCESS',
    FAILURE: 'SEARCH_INVENTORY_FAILURE'
};
const SEARCH_INVENTORY_EVENTS = [SEARCH_INVENTORY.BEGIN, SEARCH_INVENTORY.SUCCESS, SEARCH_INVENTORY.FAILURE];

export const SEARCH_INVENTORIES_AVAILABILITY = {
    BEGIN: 'SEARCH_INVENTORIES_AVAILABILITY_BEGIN',
    SUCCESS: 'SEARCH_INVENTORIES_AVAILABILITY_SUCCESS',
    FAILURE: 'SEARCH_INVENTORIES_AVAILABILITY_FAILURE'
};

export const SEARCH_INVENTORIES_AVAILABILITY_EVENTS = [SEARCH_INVENTORIES_AVAILABILITY.BEGIN, SEARCH_INVENTORIES_AVAILABILITY.SUCCESS, SEARCH_INVENTORIES_AVAILABILITY.FAILURE];

export const RETRIEVE_INVENTORY_AVAILABILITY = {
    BEGIN: 'RETRIEVE_INVENTORY_AVAILABILITY_BEGIN',
    SUCCESS: 'RETRIEVE_INVENTORY_AVAILABILITY_SUCCESS',
    FAILURE: 'RETRIEVE_INVENTORY_AVAILABILITY_FAILURE'
};

export const RETRIEVE_INVENTORY_AVAILABILITY_EVENTS = [RETRIEVE_INVENTORY_AVAILABILITY.BEGIN, RETRIEVE_INVENTORY_AVAILABILITY.SUCCESS, RETRIEVE_INVENTORY_AVAILABILITY.FAILURE];

export const UPDATE_MULTI_OFFER_SHOPPING_CART = {
    BEGIN: 'UPDATE_MULTI_OFFER_SHOPPING_CART_BEGIN',
    SUCCESS: 'UPDATE_MULTI_OFFER_SHOPPING_CART_SUCCESS',
    FAILURE: 'UPDATE_MULTI_OFFER_SHOPPING_CART_FAILURE'
};

export const UPDATE_MULTI_OFFER_SHOPPING_CART_EVENTS = [UPDATE_MULTI_OFFER_SHOPPING_CART.BEGIN, UPDATE_MULTI_OFFER_SHOPPING_CART.SUCCESS, UPDATE_MULTI_OFFER_SHOPPING_CART.FAILURE];

export const REPLACE_MULTI_OFFER_SHOPPING_CART = {
    BEGIN: 'REPLACE_MULTI_OFFER_SHOPPING_CART_BEGIN',
    SUCCESS: 'REPLACE_MULTI_OFFER_SHOPPING_CART_SUCCESS',
    FAILURE: 'REPLACE_MULTI_OFFER_SHOPPING_CART_FAILURE'
};

export const REPLACE_MULTI_OFFER_SHOPPING_CART_EVENTS = [REPLACE_MULTI_OFFER_SHOPPING_CART.BEGIN, REPLACE_MULTI_OFFER_SHOPPING_CART.SUCCESS, REPLACE_MULTI_OFFER_SHOPPING_CART.FAILURE];

export const SEARCH_SERVICE_FEATURES = {
    BEGIN: 'SEARCH_SERVICE_FEATURES_BEGIN',
    SUCCESS: 'SEARCH_SERVICE_FEATURES_SUCCESS',
    FAILURE: 'SEARCH_SERVICE_FEATURES_FAILURE'
};

const SEARCH_SERVICE_FEATURES_EVENTS = [
    SEARCH_SERVICE_FEATURES.BEGIN,
    SEARCH_SERVICE_FEATURES.SUCCESS,
    SEARCH_SERVICE_FEATURES.FAILURE
];

export const RETRIEVE_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES = {
    BEGIN: 'RETRIEVE_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES_BEGIN',
    SUCCESS: 'RETRIEVE_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES_SUCCESS',
    FAILURE: 'RETRIEVE_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES_FAILURE'
};

const RETRIEVE_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES_EVENTS = [
    RETRIEVE_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES.BEGIN,
    RETRIEVE_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES.SUCCESS,
    RETRIEVE_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES.FAILURE
];

export const GET_SERVICE_FEATURE_PRODUCT_METADATA = {
    BEGIN: 'GET_SERVICE_FEATURE_PRODUCT_METADATA_BEGIN',
    SUCCESS: 'GET_SERVICE_FEATURE_PRODUCT_METADATA_SUCCESS',
    FAILURE: 'GET_SERVICE_FEATURE_PRODUCT_METADATA_FAILURE'
};

const GET_SERVICE_FEATURE_PRODUCT_METADATA_EVENTS = [
    GET_SERVICE_FEATURE_PRODUCT_METADATA.BEGIN,
    GET_SERVICE_FEATURE_PRODUCT_METADATA.SUCCESS,
    GET_SERVICE_FEATURE_PRODUCT_METADATA.FAILURE
];

export const GET_STANDALONE_PRODUCT_METADATA = {
    BEGIN: 'GET_STANDALONE_PRODUCT_METADATA_BEGIN',
    SUCCESS: 'GET_STANDALONE_PRODUCT_METADATA_SUCCESS',
    FAILURE: 'GET_STANDALONE_PRODUCT_METADATA_FAILURE'
};

const GET_STANDALONE_PRODUCT_METADATA_EVENTS = [
    GET_STANDALONE_PRODUCT_METADATA.BEGIN,
    GET_STANDALONE_PRODUCT_METADATA.SUCCESS,
    GET_STANDALONE_PRODUCT_METADATA.FAILURE
];

export const SEND_INVENTORY_TO_STORE = {
    BEGIN: 'SEND_INVENTORY_TO_STORE_BEGIN',
    SUCCESS: 'SEND_INVENTORY_TO_STORE_SUCCESS',
    FAILURE: 'SEND_INVENTORY_TO_STORE_FAILURE'
};

const SEND_INVENTORY_TO_STORE_EVENTS = [
    SEND_INVENTORY_TO_STORE.BEGIN,
    SEND_INVENTORY_TO_STORE.SUCCESS,
    SEND_INVENTORY_TO_STORE.FAILURE
];

export const RETRIEVE_TRANSFER_FROM_SUBSCRIBER = {
    BEGIN: 'RETRIEVE_TRANSFER_FROM_SUBSCRIBER_BEGIN',
    SUCCESS: 'RETRIEVE_TRANSFER_FROM_SUBSCRIBER_SUCCESS',
    FAILURE: 'RETRIEVE_TRANSFER_FROM_SUBSCRIBER_FAILURE'
};

const RETRIEVE_TRANSFER_FROM_SUBSCRIBER_EVENTS = [
    RETRIEVE_TRANSFER_FROM_SUBSCRIBER.BEGIN,
    RETRIEVE_TRANSFER_FROM_SUBSCRIBER.SUCCESS,
    RETRIEVE_TRANSFER_FROM_SUBSCRIBER.FAILURE
];

export const CLEAR_OFFERING_ORDER_QUOTE = 'CLEAR_OFFERING_ORDER_QUOTE';
export const CLEAR_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES = 'CLEAR_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES';
export const CLEAR_QUOTE = 'CLEAR_QUOTE';
export const CLEAR_SUMMARY_QUOTE = 'CLEAR_SUMMARY_QUOTE';
export const DECISION_OPTION_ITEM_QUANTITY_CHANGED = 'DECISION_OPTION_ITEM_QUANTITY_CHANGED';
export const DECISION_OPTION_QUANTITY_CHANGED = 'DECISION_OPTION_QUANTITY_CHANGED';
export const DECISION_OPTION_SELECTED = 'DECISION_OPTION_SELECTED';
export const DECISION_OPTION_SERVICE_IDS_FOR_REMOVAL_SELECTED = 'DECISION_OPTION_SERVICE_IDS_FOR_REMOVAL_SELECTED';
export const DISCOUNT_OVERRIDE_CHANGED = 'DISCOUNT_OVERRIDE_CHANGED';
export const DISCOUNT_OVERRIDE_CLEAR = 'DISCOUNT_OVERRIDE_CLEAR';
export const MAX_ONE_NON_DECISION_GROUP_OPTION_QUANTITY_CHANGED = 'MAX_ONE_NON_DECISION_GROUP_OPTION_QUANTITY_CHANGED';
export const RESET_AVAILABLE_INVENTORY_INFORMATION = 'RESET_AVAILABLE_INVENTORY_INFORMATION';
export const RESET_INVENTORY_STORE_PICKUP_DETAILS = 'RESET_INVENTORY_STORE_PICKUP_DETAILS';
export const SAVE_INVENTORY_STORE_PICKUP_DETAILS = 'SAVE_INVENTORY_STORE_PICKUP_DETAILS';
export const SET_DECISIONS_SELECTED_PAGE = 'SET_DECISIONS_SELECTED_PAGE';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const SET_SELECTED_BRI_SELECTED_DISCOUNTS = 'SET_SELECTED_BRI_SELECTED_DISCOUNTS';
export const SET_SELECTED_OFFER_NAME = 'SET_SELECTED_OFFER_NAME';
export const SET_OFF_CYCLE_ALIGNMENT_SUBSCRIBER_PRODUCT_DATA = 'SET_OFF_CYCLE_ALIGNMENT_SUBSCRIBER_PRODUCT_DATA';

export const addSubscriberHeaders = (customerId, customerLanguage, headers={}) => {
    headers[SUBSCRIBER_ID_HEADER] = customerId;

    if (customerLanguage) {
        headers[CUSTOM_SUBSCRIBER_LANGUAGE_HEADER] = customerLanguage;
    }

    return headers;
};

export const retrieveInventoryAvailabilityPromise = (dispatch, request) => {
    return ThunkHelper(dispatch, RETRIEVE_INVENTORY_AVAILABILITY_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveInventoryAvailability',
        data: request
    }, request);
};

const searchInventoriesAvailabilityPromise = (dispatch, request) => {
    return ThunkHelper(dispatch, SEARCH_INVENTORIES_AVAILABILITY_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SearchInventoryAvailability',
        data: request
    }, request);
};

export const searchInventoriesAvailability = (request) => {
    return (dispatch) => {
        return searchInventoriesAvailabilityPromise(dispatch, request);
    };
};

export const retrieveMultipleInventoryAvailabilityPromises = (dispatch, inventoryAvailabilityRequests) => {
    dispatch(resetAvailableInventoryInformation());
    return Promise.all(inventoryAvailabilityRequests.map((combo) => {
        return retrieveInventoryAvailabilityPromise(dispatch, combo);
    }));
};

export const searchAvailableOfferingsPromise = (dispatch, searchOfferingsParam, requestObject, customerLanguage, customerId) => {
    const params = {
        method: 'post',
        url: 'SubscriberManagement/SearchOfferings',
        data: searchOfferingsParam,
        headers: addSubscriberHeaders(customerId, customerLanguage)
    };

    return ThunkHelper(dispatch, SEARCH_AVAILABLE_OFFERINGS_TYPES, params, requestObject);
};

export const searchAvailableOfferings = (searchOfferingsParam, requestObject, customerLanguage, customerId) => {
    return (dispatch) => {
        return searchAvailableOfferingsPromise(dispatch, searchOfferingsParam, requestObject, customerLanguage, customerId);
    };
};

export const retrieveOrderContext = (transferFromSubscriberId, customerId, isGroupOrder = undefined) => {
    return (dispatch) => {
        return retrieveOrderContextPromise(dispatch, transferFromSubscriberId, customerId, isGroupOrder);
    };
};


export const retrieveOrderContextPromise = (dispatch, transferFromSubscriberId, customerId, isGroupOrder) => {
    const params = {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOrderContext',
        headers: addSubscriberHeaders(customerId),
        data: {
            TransferFromSubscriberId: transferFromSubscriberId || undefined,
            IsGroupOrder: isGroupOrder
        }
    };

    return ThunkHelper(dispatch, RETRIEVE_ORDER_CONTEXT_TYPES, params);
};

export const retrieveOfferingContextPromise = (dispatch, offeringId, customerId, retrieveAttributes, completedDecisions = [], isConvergentBiller=true, segmentationContext = [], customerLanguage, subtenantId, changeOfferingInstanceId, pickupId) => {
    const contextOfferingAction = changeOfferingInstanceId ? ContextOfferingAction.TRANSITION : ContextOfferingAction.ADD;
    const data = {
        AdditionalShoppingCartUpdate: !isConvergentBiller,
        CompletedDecisionsList: completedDecisions,
        ContractId: getContractId(),
        Offerings: [{
            Action: contextOfferingAction,
            OfferingId: offeringId,
            OfferingInstanceId: changeOfferingInstanceId || undefined
        }],
        PickupId: pickupId || undefined,
        RetrieveServiceAttributes: retrieveAttributes,
        SegmentationContext: segmentationContext,
        UseSavedShoppingCart: !!changeOfferingInstanceId
    };
    if (subtenantId) {
        data['Subtenants']= [subtenantId];
    }

    const params = {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOfferingContext',
        headers: addSubscriberHeaders(customerId, customerLanguage),
        data: data
    };

    return ThunkHelper(dispatch, RETRIEVE_OFFERING_CONTEXT_TYPES, params);
};

export const retrieveOfferingContextPromiseWithOptions = (dispatch, offeringId, customerId, options) => {
    const retrieveAttributes = options.retrieveAttributes,
        completedDecisions = options.completedDecisions || [],
        isConvergentBiller = options.isConvergentBiller,
        transitionContext = options.transitionContext,
        contextOfferingAction = options.transitionContext ? ContextOfferingAction.TRANSITION : options.contextOfferingAction,
        segmentationContext = options.segmentationContext || [],
        grandfatherPricingPlans = options.grandfatherPricingPlans || true,
        subtenantId = options.subtenantId;

    const data = {
        AdditionalShoppingCartUpdate: !isConvergentBiller,
        CompletedDecisionsList: completedDecisions,
        ContractId: getContractId(),
        Offerings: [{
            Action: contextOfferingAction,
            OfferingId: offeringId
        }],
        RetrieveServiceAttributes: retrieveAttributes,
        SegmentationContext: segmentationContext,
        GrandfatherPricingPlans: grandfatherPricingPlans
    };
    if (subtenantId) {
        data['Subtenants']= [subtenantId];
    }

    const params = {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOfferingContext',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: data
    };

    if (transitionContext) {
        params.data.TransitionOutcomes = transitionContext.Outcomes;
    }

    return ThunkHelper(dispatch, RETRIEVE_OFFERING_CONTEXT_TYPES, params);
};

export const retrieveOfferingContextQuickView = (offeringId) => {
    return (dispatch, getState) => {
        const state = getState();
        const isDbss = IsDbss(state);
        const customerId = CurrentCustomerIdSelector(state);
        const newConnectCustomer = EditCustomerInfoSelector(state);
        const routeParams = RouteParams(state);
        let customerLanguage, subtenantId;
        if (!routeParams.customerId) {
            customerLanguage = newConnectCustomer.Language;
            subtenantId = newConnectCustomer.SubtenantId;
        }
        return retrieveOfferingContextQuickViewPromise(dispatch, offeringId, customerId, isDbss, customerLanguage, subtenantId);
    };
};

export const retrieveOfferingContextQuickViewPromise = (dispatch, offeringId, customerId, isConvergentBiller, customerLanguage, subtenantId) => {
    const data = {
        AdditionalShoppingCartUpdate: !isConvergentBiller,
        OrderContractInstance: getContractInstance(offeringId),
        Offerings: [{
            Action: ContextOfferingAction.ADD,
            OfferingId: offeringId
        }],
        SegmentationContext: getSegmentationContext(),
        RetrievePhysicalInventoryDecisions: false,
        RetrieveServiceAttributes: false
    };
    if (subtenantId) {
        data['Subtenants'] = [subtenantId];
    }

    return ThunkHelper(dispatch, RETRIEVE_OFFERING_CONTEXT_QUICK_VIEW_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOfferingContext',
        headers: addSubscriberHeaders(customerId, customerLanguage),
        data: data
    });
};

export const updateCartSummaryPromise = (
    dispatch,
    offeringId,
    offeringInstanceId,
    completedDecisions = [],
    isModify,
    customerId,
    contextOfferingAction,
    transitionContext,
    isConvergentBiller=true,
    customerLanguage,
    subtenantId,
    postalCode,
    pickupId,
    changeOfferingInstanceId,
    useSavedShoppingCart
) => {
    const data = {
        AdditionalShoppingCartUpdate: !isConvergentBiller,
        CompletedDecisionsList: completedDecisions,
        ContractId: getContractId(),
        Offerings: [{
            Action: contextOfferingAction || (isModify ?
                ContextOfferingAction.MODIFY :
                changeOfferingInstanceId ?
                    ContextOfferingAction.TRANSITION :
                    ContextOfferingAction.ADD),
            OfferingId: offeringId,
            OfferingInstanceId: changeOfferingInstanceId || offeringInstanceId || undefined
        }],
        OrderContractInstance: getContractInstance(offeringId),
        PickupId: pickupId,
        RetrievePhysicalInventoryDecisions: false,
        RetrieveServiceAttributes: false,
        SegmentationContext: getSegmentationContext(),
        UseSavedShoppingCart: useSavedShoppingCart || !!changeOfferingInstanceId
    };
    if (subtenantId) {
        data['Subtenants'] = [subtenantId];
    }

    const params = {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOfferingContext',
        headers: addSubscriberHeaders(customerId, customerLanguage),
        data: data
    };

    if (transitionContext) {
        params.data.TransitionOutcomes = transitionContext.Outcomes;
    }

    return ThunkHelper(dispatch, UPDATE_CART_SUMMARY_TYPES, params);
};

const getSegmentationContext = () => {
    const $ngRedux = InjectorHelper.getNgReduxService();
    const state = $ngRedux.getState();
    const newConnectCustomer = EditCustomerInfoSelector(state);
    const routeParams = RouteParams(state);
    const postalCode = (routeParams.customerId) ? null : newConnectCustomer.postalCode;
    const subscriberCategory = (routeParams.customerId) ? null : newConnectCustomer.Category;
    const subscriberType = (routeParams.customerId) ? null : newConnectCustomer.SubscriberTypeCode;

    return [{
        'Key': 'zipcode',
        'Value': postalCode
    }, {
        'Key': 'subscriber_category',
        'Value': subscriberCategory
    }, {
        'Key': 'subscriber_type',
        'Value': subscriberType
    }];
};

export const retrieveAttributesPromise = ({changeOfferingInstanceId,
    completedDecisions = [],
    customerId,
    customerLanguage,
    dispatch,
    isConvergentBiller = true,
    isEditOffer,
    isReconnectAction = false,
    offeringId,
    offeringInstanceId,
    pickupId,
    subscriberSubtenantId,
    transitionOutcomes,
    useSavedShoppingCart}) => {
    let offeringAction;
    if (offeringInstanceId && transitionOutcomes) {
        offeringAction = ContextOfferingAction.TRANSITION;
    } else if (offeringInstanceId && isEditOffer) {
        offeringAction = isReconnectAction ? ContextOfferingAction.RECONNECT : ContextOfferingAction.MODIFY;
    } else if (changeOfferingInstanceId) {
        offeringAction = ContextOfferingAction.TRANSITION;
    } else {
        offeringAction = ContextOfferingAction.ADD;
    }

    const data = {
        AdditionalShoppingCartUpdate: !isConvergentBiller,
        CompletedDecisionsList: completedDecisions,
        ContractId: getContractId(),
        OrderContractInstance: getContractInstance(offeringId),
        Offerings: [{
            Action: offeringAction,
            OfferingId: offeringId,
            OfferingInstanceId: changeOfferingInstanceId || offeringInstanceId
        }],
        PickupId: pickupId || undefined,
        RetrieveDeliveryDecisions: true,
        RetrievePhysicalInventoryDecisions: true,
        RetrieveServiceAttributes: true,
        SegmentationContext: getSegmentationContext(),
        TransitionOutcomes: transitionOutcomes,
        UseSavedShoppingCart: !!changeOfferingInstanceId || useSavedShoppingCart

    };
    if (subscriberSubtenantId) {
        data['Subtenants'] = [subscriberSubtenantId];
    }

    return ThunkHelper(dispatch, RETRIEVE_ATTRIBUTES_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOfferingContext',
        headers: addSubscriberHeaders(customerId, customerLanguage),
        data: data
    }, {
        changeOfferingInstanceId
    });
};

export const submitOrderPromise = (dispatch, customerInfo, shoppingCart, paymentInfo, billCycleName, executionOptions, recordPaymentInformation, isTransfer, additionalProperties, shippingInfo = {}, orderDetails = {}, offerAdditionalProperties) => {
    const customerId = customerInfo.Id,
        paymentInfoArray = paymentInfo ? [paymentInfo] : [];

    return ThunkHelper(dispatch, SUBMIT_ORDER_EVENTS, {
        method: 'post',
        url: isTransfer ? 'SubscriberManagement/SubmitTransferOrder' : 'SubscriberManagement/SubmitOrder', //Same promise because schema/requirements are the same
        headers: addSubscriberHeaders(customerId, customerInfo.Language),
        data: {
            AdditionalProperties: additionalProperties || null,
            BillCycleName: (billCycleName && billCycleName !== '') ? billCycleName : null,
            ContractId: getContractId(),
            ExecutionDate: executionOptions.specifiedDate || undefined,
            ExecutionDateIntention: executionOptions.effective || undefined,
            OrderComment: orderDetails?.comment || null,
            PaymentInstruments: paymentInfoArray,
            ReasonCode: orderDetails?.reasonCode || null,
            ShoppingCart: shoppingCart,
            RecordPaymentInformation: recordPaymentInformation,
            UseDefaults: true,
            ShippingAddressId: shippingInfo.addressId || undefined,
            ShippingAddress: shippingInfo.address || undefined,
            ShippingMethodId: shippingInfo.methodId || undefined,
            ShipToName: shippingInfo.shipTo || undefined,
            OrderedOfferingAdditionalProperties: !isTransfer && offerAdditionalProperties ? offerAdditionalProperties : null
        }
    });
};

export const calculateChangeOfServiceOrderQuoteRequestPromise = (dispatch, customerId, addItems, modifyItems, removeItems, offeringId, offeringInstanceId, changesEffective, toOfferId = null, reasonCode = null, executionOptions = {}, isShipping, shippingInfo = {}) => {
    const data = {
        AddItems: addItems,
        ExecutionDate: executionOptions.specifiedDate,
        ExecutionDateIntention: executionOptions.effective,
        ModifyItems: modifyItems,
        RemoveItems: removeItems,
        OfferingId: offeringId,
        OfferingInstanceId: offeringInstanceId,
        ChangeImmediately: true,
        ReturnSubscriptionPreviews: true,
        IncludeShippingMethods: isShipping,
        ShippingMethodId: shippingInfo.methodId || undefined,
        ShippingAddressId : shippingInfo.addressId || undefined
    };

    if (reasonCode) {
        data.ReasonCode = reasonCode;
    }
    if (+changesEffective === INVOICE_TIMING_CONSTANTS.INVOICE_ON_CYCLE) {
        data.ChangeImmediately = false;
    }
    if (toOfferId) {
        data.TransitionOfferingId = toOfferId;
    }

    return ThunkHelper(dispatch, CALCULATE_COS_ORDER_QUOTE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/CalculateChangeOfServiceOrderQuote',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: data
    });
};

export const retrieveOrderQuote = (customerId, quoteId) => {
    return (dispatch) => {
        return retrieveOrderQuotePromise(dispatch, customerId, quoteId);
    };
};

export const retrieveOrderQuotePromise = (dispatch, customerId, quoteId) => {
    return ThunkHelper(dispatch, RETRIEVE_ORDER_QUOTE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOrderQuote',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            QuoteId: quoteId
        }
    }, {
        calculateTaxesInQuote: true
    });
};

export const calculateOfferingOrderQuotePromise = (dispatch, customer, shoppingCart, billCycleName, executionOptions = {}, shippingInfo = {}, isShipping, bypassQuote) => {
    return ThunkHelper(dispatch, CALCULATE_OFFERING_ORDER_QUOTE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/CalculateOfferingOrderQuote',
        headers: addSubscriberHeaders(customer.Id, customer.Language),
        data: {
            // DBSS does not use the CalculateTaxes field (it always calculates taxes).
            BillCycleName: (billCycleName && billCycleName !== EMPTY_STRING) ? billCycleName : null,
            CalculateShipping: false, // True is ITV only
            CalculateQuoteOverridingConfiguration: bypassQuote,
            ConvergentBillerAddress: !(
                customer.country &&
                customer.postalCode &&
                customer.stateRegionProvince &&
                customer.addressLine1) ?
                undefined : {
                    Name: 'Service Address',
                    City: customer.city,
                    Country: customer.country,
                    DefaultBilling: true,
                    DefaultHome: true,
                    DefaultPostal: true,
                    DefaultService: true,
                    LineOne: customer.addressLine1,
                    LineTwo: customer.addressLine2 || undefined,
                    PostalCode: customer.postalCode,
                    State: customer.stateRegionProvince
                },
            ExecutionDate: executionOptions.specifiedDate,
            ExecutionDateIntention: executionOptions.effective,
            ShoppingCart: shoppingCart,
            ShippingMethodId: shippingInfo.methodId || undefined,
            IncludeShippingMethods: isShipping,
            TaxExemption: Object.assign({
                WholesaleFlag: customer.WholesaleFlag || false
            }, customer.TaxExemption)
        }
    });
};

export const calculateOfferingOrderQuote = (customer, shoppingCart, billCycleName, executionOptions, shippingInfo = {}, isShipping, bypassQuote) => {
    return (dispatch) => {
        return calculateOfferingOrderQuotePromise(dispatch, customer, shoppingCart, billCycleName, executionOptions, shippingInfo, isShipping, bypassQuote);
    };
};

const retrieveOffCycleNextRenewalSchedulesPromise = (dispatch, customerId, addItems, modifyItems, removeItems) => {
    return ThunkHelper(dispatch, RETRIEVE_OFFCYCLE_NEXT_RENEWAL_SCHEDULE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOffCycleNextRenewalSchedules',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            AddItems: addItems,
            ModifyItems: modifyItems,
            RemoveItems: removeItems
        }
    });
};

export const retrieveOffCycleNextRenewalSchedules = (customerId, addItems = [], modifyItems = [], removeItems = []) => {
    return (dispatch) => {
        return retrieveOffCycleNextRenewalSchedulesPromise(dispatch, customerId, addItems, modifyItems, removeItems);
    };
};

const retrieveOfferingOrderQuotePromise = (dispatch, customerId, quoteId) => {
    return ThunkHelper(dispatch, RETRIEVE_OFFERING_ORDER_QUOTE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOfferingOrderQuote',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            QuoteId: quoteId
        }
    });
};

export const retrieveOfferingOrderQuote = (customerId, quoteId) => {
    return (dispatch) => {
        return retrieveOfferingOrderQuotePromise(dispatch, customerId, quoteId);
    };
};

export const calculateChangeOfServiceOfferingOrderQuotePromise = (dispatch, customer, offeringId, addItems, removeItems, modifiedItems, executionOptions = {}, reasonCode, shippingInfo = {}, isShipping, bypassQuote) => {
    return ThunkHelper(dispatch, CALCULATE_OFFERING_ORDER_QUOTE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/CalculateChangeOfServiceOfferingOrderQuote',
        headers: addSubscriberHeaders(customer.Id, customer.Language),
        data: {
            AddItems: addItems,
            CalculateQuoteOverridingConfiguration: bypassQuote,
            ExecutionDate: executionOptions.specifiedDate,
            ExecutionDateIntention: executionOptions.effective,
            TransitionOfferingId: offeringId,
            RemoveItems: removeItems,
            ModifyItems: modifiedItems || [],
            ReasonCode: reasonCode || undefined,
            IncludeShippingMethods: isShipping,
            ShippingMethodId: shippingInfo.methodId || undefined,
            ShippingAddressId : shippingInfo.addressId || undefined
        }
    });
};

export const calculateChangeOfServiceOfferingOrderQuote = (customer, offeringId, addItems, removeItems, modifiedItems, executionOptions = {}, isReconnectAction = false, shippingInfo = {}, isShipping, bypassQuote) => {
    return (dispatch) => {
        const reasonCode = isReconnectAction ? convertStringToNumber(REASON_CODES.RECONNECT) : executionOptions.disconnectReason;
        return calculateChangeOfServiceOfferingOrderQuotePromise(dispatch, customer, offeringId, addItems, removeItems, modifiedItems, executionOptions, reasonCode, shippingInfo, isShipping, bypassQuote);
    };
};

export const clearOfferingOrderQuote = () => {
    return {
        type: CLEAR_OFFERING_ORDER_QUOTE
    };
};

export const setPageNumber = (pageNum) => {
    return {
        type: SET_PAGE_NUMBER,
        payload: pageNum
    };
};

export const SET_CHECKOUT_FORM_VALIDATION = 'SET_CHECKOUT_FORM_VALIDATION';

export const setCheckoutDetailsValidation = (payload) => {
    return {
        type: SET_CHECKOUT_FORM_VALIDATION,
        payload: payload
    };
};

export const retrieveBillerRuleInstanceDiscountContext = (brcId, chargeAmount, customerId, pricingPlanId, productId, segmentationContext, orderScenario, existingDiscountIds) => {
    return (dispatch) => {
        return retrieveBillerRuleInstanceDiscountContextPromise(dispatch, brcId, chargeAmount, customerId, pricingPlanId, productId, segmentationContext, orderScenario, existingDiscountIds);
    };
};

const retrieveBillerRuleInstanceDiscountContextPromise = (dispatch, brcId, chargeAmount, customerId, pricingPlanId, productId, segmentationContext, orderScenario, existingDiscountIds = []) => {
    return ThunkHelper(dispatch, RETRIEVE_BILLER_RULE_INSTANCE_DISCOUNT_CONTEXT_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveBillerRuleInstanceDiscountContext',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            BillerRuleConfigurationId: brcId,
            ChargeAmount: chargeAmount,
            ExistingDiscountIds: existingDiscountIds,
            ProductPricingPlanOrderScenario: orderScenario,
            PricingPlanId: {
                Value: `${pricingPlanId}`
            },
            ProductId: {
                Value: `${productId}`
            },
            SegmentationContext: segmentationContext
        }
    });
};

export const setDecisionsSelectedPageId = (pageId) => {
    return {
        type: SET_DECISIONS_SELECTED_PAGE,
        payload: pageId
    };
};

export const setSelectedBriSelectedDiscounts = (selectedDiscountInfo) => {
    return {
        type: SET_SELECTED_BRI_SELECTED_DISCOUNTS,
        payload: selectedDiscountInfo
    };
};

export const decisionOptionSelected = (decisionId, optionId) => {
    return {
        type: DECISION_OPTION_SELECTED,
        payload: {
            decisionId: decisionId,
            optionId: optionId
        }
    };
};

export const decisionOptionQuantityChanged = (quantity, decisionId, optionId, shouldResetOtherDecisionOptionsQuantityToZero) => {
    return {
        type: DECISION_OPTION_QUANTITY_CHANGED,
        payload: {
            decisionId,
            optionId,
            quantity,
            shouldResetOtherDecisionOptionsQuantityToZero
        }
    };
};

export const decisionOptionItemQuantityChanged = (itemId, quantity, decisionId, optionId) => {
    return {
        type: DECISION_OPTION_ITEM_QUANTITY_CHANGED,
        payload: {
            decisionId: decisionId,
            itemId: itemId,
            optionId: optionId,
            quantity: quantity
        }
    };
};

export const discountOverrideChanged = (decisionId, optionId, billerRuleConfigurationId, selectedDiscountsInfo) => {
    return {
        type: DISCOUNT_OVERRIDE_CHANGED,
        payload: {
            decisionId: decisionId,
            optionId: optionId,
            billerRuleConfigurationId,
            selectedDiscountsInfo
        }
    };
};

export const nonDecisionGroupOptionQuantityChanged = (quantity, decisionId, optionId) => {
    return {
        type: MAX_ONE_NON_DECISION_GROUP_OPTION_QUANTITY_CHANGED,
        payload: {
            decisionId: decisionId,
            optionId: optionId,
            quantity: quantity,
            shouldResetOtherDecisionOptionsQuantityToZero: true
        }
    };
};

export const clearDiscountOverride = (decisionId, optionId) => {
    return {
        type: DISCOUNT_OVERRIDE_CLEAR,
        payload: {
            decisionId: decisionId,
            optionId: optionId
        }
    };
};



export const decisionOptionServiceIdsForRemovalSelected = (decisionId, optionId, serviceIds) => {
    return {
        type: DECISION_OPTION_SERVICE_IDS_FOR_REMOVAL_SELECTED,
        payload: {
            decisionId: decisionId,
            optionId: optionId,
            serviceIds: serviceIds
        }
    };
};

export const keywordSearch = (postalCode, subscriberCategory, subscriberTypeCode, customerLanguage, additionalOptions, contextOfferingAction) => {
    return (dispatch) => {
        performSearchPromise(dispatch, postalCode, subscriberCategory, subscriberTypeCode, customerLanguage, additionalOptions, contextOfferingAction);
    };
};

export const performSearchPromise = (dispatch, postalCode, subscriberCategory, subscriberTypeCode, customerLanguage, additionalOptions, contextOfferingAction) => {
    // TODO: Customer Order - We should not be pulling in the reduxStore here, as actions should have no knowledge of
    // selector logic. The data should be passed in. Don't follow this pattern, will be refactored in the future.
    const $ngRedux = InjectorHelper.getNgReduxService();
    const pageNumber = additionalOptions.pageNumber || 1;
    const pageSize = additionalOptions.pageSize || 25;

    let offerChargeType;
    const offerChargeTypeIds = additionalOptions.chargeTypeIdsSelector($ngRedux.getState());

    if (offerChargeTypeIds && offerChargeTypeIds.length === 1) {
        offerChargeType = OFFERING_CHARGE_TYPE_IDS[offerChargeTypeIds[0]];
    }

    const data = {
        ContractId: getContractId(),
        CurrencyCode: additionalOptions.currencyCode || undefined,
        Facets: additionalOptions.facetIdsSelector($ngRedux.getState()),
        OfferingChargeType: offerChargeType,
        Action: contextOfferingAction,
        OrderScenario: additionalOptions.orderScenario,
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchString: additionalOptions.keyword,
        SegmentationContext: [{
            'Key': 'zipcode',
            'Value': postalCode
        },
        {
            'Key': 'subscriber_category',
            'Value': subscriberCategory
        },
        {
            'Key': 'subscriber_type',
            'Value': subscriberTypeCode
        }],
        SortBy: OFFERINGS_SEARCH_CONSTANTS.SORT_BY.RELEVANCE,
        SortDirection: OFFERINGS_SEARCH_CONSTANTS.SORT_DIRECTION.DESCENDING
    };
    if (additionalOptions.subtenants) {
        data['Subtenants'] = additionalOptions.subtenants;
    }

    searchAvailableOfferingsPromise(dispatch, data, {
        pageNumber
    },
    customerLanguage).catch(() => {
        getUiNotificationService().error(i18n.translate(CustomerCareKeys.OFFERINGS_ERROR), null, {
            timeOut: NOTIFICATION_TIME_LENGTH
        });
    });
};

export const keywordSearchWithCustomerId = (customerId, additionalOpts, contextOfferingAction, OfferType) => {
    return (dispatch) => {
        performSearchPromiseWithCustomerId(dispatch, customerId, additionalOpts, contextOfferingAction, OfferType);
    };
};

export const performSearchPromiseWithCustomerId = (dispatch, customerId, additionalOpts, contextOfferingAction, OfferType) => {
    // TODO: Customer Order - We should not be pulling in the reduxStore here, as actions should have no knowledge of
    // selector logic. The data should be passed in. Don't follow this pattern, will be refactored in the future.
    const $ngRedux = InjectorHelper.getNgReduxService();
    const pageNumber = additionalOpts.pageNumber || 1;
    const pageSize = additionalOpts.pageSize || 25;

    let offerChargeType;
    const offerChargeTypeIds = additionalOpts.chargeTypeIdsSelector($ngRedux.getState());

    if (offerChargeTypeIds && offerChargeTypeIds.length === 1) {
        offerChargeType = OFFERING_CHARGE_TYPE_IDS[offerChargeTypeIds[0]];
    }

    searchAvailableOfferingsPromise(dispatch, {
        ContractId: getContractId(),
        CurrencyCode: additionalOpts.currencyCode || undefined,
        Facets: additionalOpts.facetIdsSelector($ngRedux.getState()),
        OfferingChargeType: offerChargeType,
        Action: contextOfferingAction,
        NonBulkOfferingsOnly: additionalOpts.nonBulkOnly,
        OrderScenario: additionalOpts.orderScenario,
        OfferType: OfferType || additionalOpts.offerType,
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchString: additionalOpts.keyword,
        SortBy: OFFERINGS_SEARCH_CONSTANTS.SORT_BY.RELEVANCE,
        SortDirection: OFFERINGS_SEARCH_CONSTANTS.SORT_DIRECTION.DESCENDING
    },
    additionalOpts.pageNumber,
    undefined,
    customerId)
        .catch(() => {
            getUiNotificationService().error(i18n.translate(CustomerCareKeys.OFFERINGS_ERROR), null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });
        });
};

export const searchInventoryPromise = (dispatch, inventoryTypeId, searchPatterns = [], customerId) => {
    return ThunkHelper(dispatch, SEARCH_INVENTORY_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SearchInventory',
        data: {
            InventoryTypeId: inventoryTypeId,
            PageSize: 6,
            SearchPatterns: searchPatterns
        },
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const clearQuote = () => {
    return {
        type: CLEAR_QUOTE
    };
};

export const clearSummaryQuote = () => {
    return {
        type: CLEAR_SUMMARY_QUOTE
    };
};

export const setSelectedOfferName = (offerName) => {
    return {
        type: SET_SELECTED_OFFER_NAME,
        payload: offerName
    };
};

export const resetAvailableInventoryInformation = () => {
    return {
        type: RESET_AVAILABLE_INVENTORY_INFORMATION
    };
};

export const SET_OFFERING_PAGE_SIZE_PREFERENCE = 'SET_OFFERING_PAGE_SIZE_PREFERENCE';
export const setPageSizePreference = (pageSize) => {
    return {
        type: SET_OFFERING_PAGE_SIZE_PREFERENCE,
        payload: pageSize
    };
};


export const SET_PURCHASE_ORDER_NUMBER = 'SET_OFFERING_PURCHASE_ORDER_NUMBER';
export const setPurchaseOrderNumber = (poNumber) => {
    return {
        type: SET_PURCHASE_ORDER_NUMBER,
        payload: poNumber
    };
};

export const SET_CHANGES_EFFECTIVE = 'SET_CHANGES_EFFECTIVE';
export const setChangesEffective = (changesEffective) => {
    return {
        type: SET_CHANGES_EFFECTIVE,
        payload: changesEffective
    };
};

export const SET_EXTERNAL_CONTRACT_ID = 'SET_OFFERING_EXTERNAL_CONTRACT_ID';
export const setExternalContractId = (externalContractId) => {
    return {
        type: SET_EXTERNAL_CONTRACT_ID,
        payload: externalContractId
    };
};


export const SET_ACTIVE_ATTRIBUTE_FORM_NAME = 'SET_ACTIVE_ATTRIBUTE_FORM_NAME';
export const setActiveAttributeFormName = (payload) => {
    return {
        type: SET_ACTIVE_ATTRIBUTE_FORM_NAME,
        payload: payload
    };
};

export const SET_EXECUTION_OPTIONS = 'SET_OFFERING_EXECUTION_OPTIONS';
export const setExecutionOptions = (executionOptions) => {
    return {
        type: SET_EXECUTION_OPTIONS,
        payload: executionOptions
    };
};

export const SET_ORDER_DETAILS = 'SET_ORDER_DETAIL_OPTIONS';
export const setOrderDetails = (orderDetails) => {
    return {
        type: SET_ORDER_DETAILS,
        payload: orderDetails
    };
};

export const SET_DEVICE_PAYMENT_OPTIONS = 'SET_DEVICE_PAYMENT_OPTIONS';
export const setDevicePaymentOptions = (items) => {
    return {
        type: SET_DEVICE_PAYMENT_OPTIONS,
        payload: items
    };
};

export const BEGIN_EDIT_OFFER_ORDER = 'BEGIN_EDIT_OFFER_ORDER';
export const beginEditOfferOrder = () => {
    return {
        type: BEGIN_EDIT_OFFER_ORDER
    };
};

export const startEditOfferFlow = (offerName) => {
    return (dispatch) => {
        dispatch(beginEditOfferOrder());
        dispatch(setSelectedOfferName(offerName));
    };
};

export const getContractId = () => {
    // returns contract id from either RouteParams or SelectedContractSelector
    // if RouteParams are null, use SelectedContractSelector
    // if using SelectedContractSelector, we want the OrderContractId for existing orders
    // otherwise, we want the id for new connect.

    // TODO: Customer Order - We should not be pulling in the reduxStore here, as actions should have no knowledge of
    // selector logic. The data should be passed in. Don't follow this pattern, will be refactored in the future.
    const $ngRedux = InjectorHelper.getNgReduxService();
    const contract = SelectedContractSelector($ngRedux.getState());
    const routeParams = RouteParams($ngRedux.getState());
    let contractId = null;
    if (routeParams.contractId && routeParams.contractInstanceId) {
        contractId = routeParams.contractId;
    }
    return contractId || contract ? contractId || contract.OrderContractId || contract.Id : null;
};

export const getContractInstance = (offeringId) => {
    // returns order contract instance from either routeParams or selectedOfferings/selectedContract
    // if routeParams are null, use selectedOfferings/selectedContract

    // TODO: Customer Order - We should not be pulling in the reduxStore here, as actions should have no knowledge of
    // selector logic. The data should be passed in. Don't follow this pattern, will be refactored in the future.
    const $ngRedux = InjectorHelper.getNgReduxService();
    const routeParams = RouteParams($ngRedux.getState());
    const selectedOfferings = ConvergentBillerAccountOfferingsForSelectedAccountSelector($ngRedux.getState());
    const selectedContract = selectedOfferings ? selectedOfferings.find((offering) => {
        return offeringId === offering.OfferingId;
    }) : null;
    let orderContractInstance = null;
    if (routeParams.contractId && routeParams.contractInstanceId) {
        orderContractInstance = {
            Id: routeParams.contractInstanceId,
            OrderContractId: routeParams.contractId
        };
    }
    return orderContractInstance || (selectedContract && selectedContract.OrderContractInstance) ? orderContractInstance || selectedContract.OrderContractInstance : null;
};

export const SET_ACTIVE_FORM_VALIDATION_STATUS = 'SET_ACTIVE_FORM_VALIDATION_STATUS';
export const setActiveFormValidationStatus = (payload) => {
    return {
        type: SET_ACTIVE_FORM_VALIDATION_STATUS,
        payload: payload
    };
};

export const SET_ATTRIBUTE_FORM_SUBMITTED = 'SET_ATTRIBUTE_FORM_SUBMITTED';
export const setAttributeFormSubmitted = (payload) => {
    return {
        type: SET_ATTRIBUTE_FORM_SUBMITTED,
        payload: payload
    };
};

// To be invoked only for MultiOffer shopping cart. Single Offer shopping cart doesn't require this.
const updateShoppingCartPromise = (dispatch, reduxEvents, customerId, shoppingCart, isAppendToCart) => {
    return ThunkHelper(dispatch, reduxEvents, {
        method: 'post',
        url: 'SubscriberManagement/UpdateShoppingCart',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            AppendToCart: isAppendToCart,
            CombineProducts: false,
            PersistCart: true,
            ShoppingCart: shoppingCart
        }
    });
};

export const updateMultiOfferShoppingCartPromise = (dispatch, customerId, shoppingCart, isAppendToCart = true) => {
    return updateShoppingCartPromise(dispatch, UPDATE_MULTI_OFFER_SHOPPING_CART_EVENTS, customerId, shoppingCart, isAppendToCart);
};

export const updateMultiOfferShoppingCart = (customerId, shoppingCart, isAppendToCart = true) => {
    return (dispatch) => {
        return updateShoppingCartPromise(dispatch, UPDATE_MULTI_OFFER_SHOPPING_CART_EVENTS, customerId, shoppingCart, isAppendToCart);
    };
};

export const replaceMultiOfferShoppingCart = (customerId, shoppingCart, isAppendToCart = false) => {
    return (dispatch) => {
        return updateShoppingCartPromise(dispatch, REPLACE_MULTI_OFFER_SHOPPING_CART_EVENTS, customerId, shoppingCart, isAppendToCart);
    };
};

export const searchTelephoneNumberInventory = (inventoryTypeId, searchPatterns = []) => {
    return (dispatch, getState) => {
        const routeParams = RouteParams(getState());
        return searchInventoryPromise(dispatch, inventoryTypeId, searchPatterns, routeParams.customerId);
    };
};

export const SET_SELECTED_INVENTORY_STORES = 'SET_SELECTED_INVENTORY_STORES';
export const setSelectedInventoryStores = (stores) => {
    return {
        type: SET_SELECTED_INVENTORY_STORES,
        payload: stores
    };
};

export const SET_INVENTORY_REGION_FILTER = 'SET_INVENTORY_REGION_FILTER';
export const setInventoryRegionFilter = (region) => {
    return {
        type: SET_INVENTORY_REGION_FILTER,
        payload: region
    };
};

export const SET_INVENTORY_STORE_CHANGED_FLAG = 'SET_INVENTORY_STORE_CHANGED_FLAG';
export const setInventoryStoreChangedFlag = (isStoreChanged) => {
    return {
        type: SET_INVENTORY_STORE_CHANGED_FLAG,
        payload: isStoreChanged
    };
};

const searchServiceFeaturesPromise = (dispatch, serviceId, customerId, postalCode, subscriberCategory, subscriberTypeCode) => {
    return ThunkHelper(dispatch, SEARCH_SERVICE_FEATURES_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SearchCatalog',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId || undefined
        },
        data: {
            ProductFilter: {
                ProductClassification: PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE,
                ServiceId: serviceId
            },
            SearchEntities: [SEARCH_ENTITIES.PRODUCT],
            SegmentationContext: [{
                'Key': 'subscriber_category',
                'Value': subscriberCategory
            }, {
                'Key': 'subscriber_type',
                'Value': subscriberTypeCode
            }, {
                'Key': 'zipcode',
                'Value': postalCode
            }],
            ProductSort: {
                SortBy: SORT_BY.REFERENCE_DATE,
                SortDirection: SORT_DIRECTION.DESCENDING
            }
        }
    });
};

export const searchServiceFeatures = (serviceId, customerId, {postalCode, Category, SubscriberTypeCode} = {}) => {
    return (dispatch) => {
        return searchServiceFeaturesPromise(dispatch, serviceId, customerId, postalCode, Category, SubscriberTypeCode);
    };
};

const getServiceFeatureProductMetadataPromise = (dispatch, productId, language) => {
    return MetadataThunkHelper(dispatch, GET_SERVICE_FEATURE_PRODUCT_METADATA_EVENTS, {
        url: `Product/Id/${productId}`,
        headers: {
            [LANGUAGE_HEADER]: language || undefined
        }
    });
};

export const getServiceFeatureProduct = (productId, language) => {
    return (dispatch) => {
        return getServiceFeatureProductMetadataPromise(dispatch, productId, language);
    };
};

const getStandaloneProductMetadataPromise = (dispatch, productId, language) => {
    return MetadataThunkHelper(dispatch, GET_STANDALONE_PRODUCT_METADATA_EVENTS, {
        url: `Product/Id/${productId}`,
        headers: {
            [LANGUAGE_HEADER]: language || undefined
        }
    });
};

export const getStandaloneProduct = (productId, language) => {
    return (dispatch) => {
        return getStandaloneProductMetadataPromise(dispatch, productId, language);
    };
};

const retrieveProductContextForServiceFeaturesPromise = (dispatch, productId, serviceId, customerId, orderScenario) => {
    return ThunkHelper(dispatch, RETRIEVE_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveProductContext',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId || undefined
        },
        data: {
            IncludeOrderablePricingPlans: true,
            ProductId: productId,
            ProductPricingPlanOrderScenario: orderScenario,
            ServiceId: serviceId
        }
    });
};

export const retrieveProductContextForServiceFeatures = (productId, serviceId, customerId, orderScenario) => {
    return (dispatch) => {
        return retrieveProductContextForServiceFeaturesPromise(dispatch, productId, serviceId, customerId, orderScenario);
    };
};

export const clearProductContextForServiceFeatures = () => {
    return {
        type: CLEAR_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES
    };
};

export const resetInventoryStorePickupDetails = () => {
    return {
        type: RESET_INVENTORY_STORE_PICKUP_DETAILS
    };
};

export const saveInventoryStorePickupDetails = (payload) => {
    return {
        type: SAVE_INVENTORY_STORE_PICKUP_DETAILS,
        payload: payload
    };
};

export const sendInventoryToStorePromise = (dispatch, customerId, shoppingCart, pickupDetails, offering, completedDecisions, transitionIds) => {
    return ThunkHelper(dispatch, SEND_INVENTORY_TO_STORE_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SendShoppingCartToStore',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            CompletedDecisionsList: completedDecisions,
            Offering: offering,
            ShoppingCart: shoppingCart,
            SubscriberPhysicalInventoryPickup: {
                Comments: pickupDetails.comments,
                Id: pickupDetails.id,
                PickupDate: pickupDetails.date
            },
            TransitionSourceOfferingId: transitionIds ? transitionIds.transitionSourceOfferingId: null,
            TransitionSourceOfferingInstanceId: transitionIds ? transitionIds.transitionSourceOfferingInstanceId : null,
        }
    });
};

export const RETRIEVE_OFFCYCLE_ALIGNMENT_OPTIONS = {
    BEGIN: 'RETRIEVE_OFFCYCLE_ALIGNMENT_OPTIONS_BEGIN',
    SUCCESS: 'RETRIEVE_OFFCYCLE_ALIGNMENT_OPTIONS_SUCCESS',
    FAILURE: 'RETRIEVE_OFFCYCLE_ALIGNMENT_OPTIONS_FAILURE'
};

export const retrieveOffCycleAlignmentOptions = (data, customerId) => {
    return (dispatch) => {
        return retrieveOffCycleAlignmentOptionsPromise(dispatch, data, customerId);
    };
};

const retrieveOffCycleAlignmentOptionsPromise = (dispatch, data, customerId) => {
    const params = {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOffCycleAlignmentOptions',
        headers: addSubscriberHeaders(customerId),
        data: {
            ProductAndPricingPlanIds: data
        }
    };

    return ThunkHelper(dispatch, Object.values(RETRIEVE_OFFCYCLE_ALIGNMENT_OPTIONS), params);
};

export const sendInventoryToStore = (customerId, shoppingCart, pickupDetails, offering, completedDecisions, transitionIds) => {
    return (dispatch) => {
        return sendInventoryToStorePromise(dispatch, customerId, shoppingCart, pickupDetails, offering, completedDecisions, transitionIds);
    };
};

const retrieveTransferFromSubscriberPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, RETRIEVE_TRANSFER_FROM_SUBSCRIBER_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveSubscriber',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const retrieveTransferFromSubscriber = (customerId) => {
    return (dispatch) => {
        return retrieveTransferFromSubscriberPromise(dispatch, customerId);
    };
};

export const SEARCH_SUBSCRIBER_OFFERINGS_CONSTANTS = {
    BEGIN: 'SEARCH_SUBSCRIBER_OFFERINGS_BEGIN',
    SUCCESS: 'SEARCH_SUBSCRIBER_OFFERINGS_SUCCESS',
    FAILURE: 'SEARCH_SUBSCRIBER_OFFERINGS_FAILURE'
};

export const searchSubscriberOfferingsPromise = (dispatch, params, requestObj) => {
    return ThunkHelper(dispatch, values(SEARCH_SUBSCRIBER_OFFERINGS_CONSTANTS), {
        method: 'post',
        url: 'SubscriberManagement/SearchSubscriberOfferings',
        headers: {
            [SUBSCRIBER_ID_HEADER]: params.customerId
        },
        data: {
            IncludeCoolingOff: true,
            IncludeOfferingTotals: params.includeOfferingTotals,
            OfferingInstanceIds: params.offeringInstanceIds,
            OfferName: params.offerName,
            PageNumber: params.pageNumber,
            PageSize: params.pageSize,
            Status: params.status
        }
    }, requestObj);
};

export const searchSubscriberOfferings = (params, requestObj) => {
    return (dispatch) => {
        return searchSubscriberOfferingsPromise(dispatch, params, requestObj);
    };
};

export const SET_OFFERING_FILTER_DATA = 'SET_OFFERING_FILTER_DATA';
export const setOfferingFilterData = (searchBy, searchTerm, filterBy) => {
    return {
        type: SET_OFFERING_FILTER_DATA,
        payload: {
            searchBy,
            searchTerm,
            filterBy
        }
    };
};

export const SET_OFFERING_PAGINATION_DATA = 'SET_OFFERING_PAGINATION_DATA';
export const setOfferingPaginationData = (pageNumber, pageSize) => {
    return {
        type: SET_OFFERING_PAGINATION_DATA,
        payload: {
            pageNumber,
            pageSize
        }
    };
};

export const SET_SAVED_LIFE_CYCLE_ID = 'SET_SAVED_LIFE_CYCLE_ID';
export const setSavedLifeCycleId = (lifeCycleId, optionId, decisionId) => {
    return {
        type: SET_SAVED_LIFE_CYCLE_ID,
        payload: {
            lifeCycleId,
            optionId,
            decisionId
        }
    };
};

export const SET_DELIVERY_DECISION = 'SET_DELIVERY_DECISION';
export const updateDeliveryDecision = (selectedValue) => {
    return {
        type: SET_DELIVERY_DECISION,
        payload: {
            selectedValue
        }
    };
};

export const SET_SERVICE_TAX_LOCATION_DECISION = 'SET_SERVICE_TAX_LOCATION_DECISION';
export const updateServiceTaxLocationDecision = (payload) => {
    return {
        type: SET_SERVICE_TAX_LOCATION_DECISION,
        payload: payload
    };
};

export const setOffCycleAlignmentSubscriberProductData = (payload) => {
    return {
        type: SET_OFF_CYCLE_ALIGNMENT_SUBSCRIBER_PRODUCT_DATA,
        payload: payload
    };
};

export const SET_SELECTED_DELIVERY_DECISION = 'SET_SELECTED_DELIVERY_DECISION';
export const setDeliveryDecision = (decision) => {
    return {
        type: SET_SELECTED_DELIVERY_DECISION,
        payload: decision
    };
};

export const SET_SELECTED_ADDRESS = 'SET_SELECTED_ADDRESS';
export const updateShippingDetails = (address) => {
    return {
        type: SET_SELECTED_ADDRESS,
        payload: address
    };
};

export const SET_SELECTED_SHIPPING_ADDRESS = 'SET_SELECTED_SHIPPING_ADDRESS';
export const setSelectedShippingAddress = (address) => {
    return {
        type: SET_SELECTED_SHIPPING_ADDRESS,
        payload: address
    };
};

export const SET_UPDATED_SHIPPING_ADDRESS = 'SET_UPDATED_SHIPPING_ADDRESS';
export const setUpdatedShippingAddress = (address) => {
    return {
        type: SET_UPDATED_SHIPPING_ADDRESS,
        payload: address
    };
};


export const SUSPEND_OFFER = {
    BEGIN: 'SUSPEND_OFFER_BEGIN',
    SUCCESS: 'SUSPEND_OFFER_SUCCESS',
    FAILURE: 'SUSPEND_OFFER_FAILURE'
};
const SUSPEND_OFFER_EVENTS = [SUSPEND_OFFER.BEGIN, SUSPEND_OFFER.SUCCESS, SUSPEND_OFFER.FAILURE];

function suspendOfferPromise(dispatch, payload, customerId) {
    return ThunkHelper(dispatch, SUSPEND_OFFER_EVENTS,
        {
            method: 'post',
            url: 'SubscriberManagement/GroupSuspendServices',
            data: payload,
            headers: {
                [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
            }
        });
}

export function suspendOffer(payload, customerId) {
    return (dispatch) => {
        return suspendOfferPromise(dispatch, payload, customerId);
    };
}

export const RESUME_OFFER = {
    BEGIN: 'RESUME_OFFER_BEGIN',
    SUCCESS: 'RESUME_OFFER_SUCCESS',
    FAILURE: 'RESUME_OFFER_FAILURE'
};
const RESUME_OFFER_EVENTS = [RESUME_OFFER.BEGIN, RESUME_OFFER.SUCCESS, RESUME_OFFER.FAILURE];

function resumeOfferPromise(dispatch, payload, customerId) {
    return ThunkHelper(dispatch, RESUME_OFFER_EVENTS,
        {
            method: 'post',
            url: 'SubscriberManagement/GroupRestoreServices',
            data: payload,
            headers: {
                [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
            }
        });
}

export function resumeOffer(payload, customerId) {
    return (dispatch) => {
        return resumeOfferPromise(dispatch, payload, customerId);
    };
}
