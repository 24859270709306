import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {ConvergentBillerAccountOfferingsSelector} from './customer.convergent.biller.selectors';
import {BILLER_RULE_INSTANCE_TYPE} from '../../customercare.constants';
import {EMPTY_OBJECT} from '../constants/common.constants';

const ServiceSuspendResumeModalDataSelector = (state) => {
    return state.customercare.serviceSuspendResumeModal;
};

export const IsloadingServiceSuspensionQuote = createImmutableSelector(
    [ServiceSuspendResumeModalDataSelector],
    (modalData) => {
        return modalData.loadingQuotes;
    }
);

export const ServicesToSuspendQuotesSelector = createImmutableSelector(
    [ServiceSuspendResumeModalDataSelector],
    (modalData) => {
        return modalData.servicesToSuspend;
    }
);

export const ServicesSuspensionQuoteFormattedSelector = createImmutableSelector(
    [ServicesToSuspendQuotesSelector],
    (servicesToSuspend) => {
        let servicesDictionary = EMPTY_OBJECT;

        servicesToSuspend.forEach((service) => {
            const oneTimeCharge = service.BillerRuleInstanceThumbnails.find((billerRule) => {
                return billerRule.Type === BILLER_RULE_INSTANCE_TYPE.ONETIME;
            });
            const recurringCharge = service.BillerRuleInstanceThumbnails.find((billerRule) => {
                return billerRule.Type === BILLER_RULE_INSTANCE_TYPE.RECURRING;
            });

            service.ServiceIdentifiers.forEach((serviceIdentifier) => {
                servicesDictionary = servicesDictionary.set(serviceIdentifier, {
                    oneTimeCharge,
                    recurringCharge
                });
            });
        });

        return servicesDictionary;
    }
);

export const IsBillCycleOptionEnabledSelector = createImmutableSelector(
    [ServiceSuspendResumeModalDataSelector, ConvergentBillerAccountOfferingsSelector],
    (modalData, offerings) => {
        if (!modalData.selectedServiceIdentifierValues.length) {
            return true;
        }

        // check if there are any offcyle offers
        return !(offerings || []).some((offer) => {
            return offer.Options.some((option) => {
                return option.ServiceIdentifierValue &&
                    modalData.selectedServiceIdentifierValues.includes(option.ServiceIdentifierValue) &&
                    option.OffCycleDetail && option.OffCycleDetail.Id;
            });
        });
    }
);