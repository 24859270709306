import {ApiConstants,
    ThunkHelper} from 'invision-core';

export const RETRIEVE_CUSTOMER_CHALLENGE_ANSWER = {
    BEGIN: 'RETRIEVE_CUSTOMER_CHALLENGE_ANSWER_BEGIN',
    SUCCESS: 'RETRIEVE_CUSTOMER_CHALLENGE_ANSWER_SUCCESS',
    FAILURE: 'RETRIEVE_CUSTOMER_CHALLENGE_ANSWER_FAILURE'
};
const RETRIEVE_CUSTOMER_CHALLENGE_ANSWER_EVENTS = [RETRIEVE_CUSTOMER_CHALLENGE_ANSWER.BEGIN, RETRIEVE_CUSTOMER_CHALLENGE_ANSWER.SUCCESS, RETRIEVE_CUSTOMER_CHALLENGE_ANSWER.FAILURE];

export const customerChallengeAnswerPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, RETRIEVE_CUSTOMER_CHALLENGE_ANSWER_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveChallengeAnswer',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        /* so the reducer knows the customer Id */
        customerId: customerId
    });
};
export const retrieveCustomerChallengeAnswer = (customerId) => {
    return (dispatch) => {
        return customerChallengeAnswerPromise(dispatch, customerId);
    };
};
