import pathOr from 'ramda/src/pathOr';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {
    EMPTY_ARRAY,
    EMPTY_OBJECT
} from 'invision-core/src/constants/common.constants';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {MetadataCodeTypeDictionarySelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import i18n from 'invision-core/src/components/i18n/i18n';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {UOM_CATEGORIES} from '../../../src/customercare.constants';
import {SelectedServiceDetailsSelector} from '../../reducers/selectors/selected.service.details.selectors';

const EditPolicyCounterModalStoreSelector = (state) => {
    return state.customercare.editPolicyCounterModal;
};

export const IsLoadingSelector = createImmutableSelector(
    [EditPolicyCounterModalStoreSelector],
    (editPolicyCounterModalStore) => {
        return editPolicyCounterModalStore.isLoading;
    }
);

export const PolicyCountersSelector = createImmutableSelector(
    [SelectedServiceDetailsSelector],
    (selectedServiceDetails) => {
        return pathOr(EMPTY_ARRAY, ['PolicyCounters'], selectedServiceDetails);
    }
);

export const SelectedPolicyCounterSelector = createImmutableSelector(
    [EditPolicyCounterModalStoreSelector],
    (store) => {
        return store.selectedPolicyCounter || EMPTY_OBJECT;
    }
);

export const UnitOfMeasuresSelector = createImmutableSelector(
    [MetadataCodeTypeDictionarySelector(CODES.UnitsOfMeasure), SelectedPolicyCounterSelector],
    (unitOfMeasures, selectedPolicyCounter) => {
        const unitOfMeasuresOptions =  [{
            label: i18n.translate(CoreLocaleKeys.SELECT),
            value: null
        }];
        const selectedPolicyCounterUomCategory = Object.values(unitOfMeasures)?.find((unitOfMeasure) => {
            return selectedPolicyCounter.BalanceUnitCode === unitOfMeasure.Value;
        })?.AdditionalProperties?.category;

        return Object.keys(unitOfMeasures).length && selectedPolicyCounterUomCategory
            ? unitOfMeasuresOptions.concat(Object.values(unitOfMeasures).filter((uom) => {
                if (selectedPolicyCounterUomCategory === UOM_CATEGORIES.DATA_UNIVERSAL) {
                    return uom.AdditionalProperties.category.includes('Data');
                } else if (selectedPolicyCounterUomCategory === UOM_CATEGORIES.DATA_IT ||
                    selectedPolicyCounterUomCategory === UOM_CATEGORIES.DATA_SCIENTIFIC ||
                    selectedPolicyCounterUomCategory === UOM_CATEGORIES.DATA_TELCO) {
                    return uom.AdditionalProperties.category === selectedPolicyCounterUomCategory ||
                        uom.AdditionalProperties.category === UOM_CATEGORIES.DATA_UNIVERSAL;
                } else {
                    return uom.AdditionalProperties.category === selectedPolicyCounterUomCategory;
                }
            }).map((unit) => {
                return {
                    label: selectedPolicyCounterUomCategory === UOM_CATEGORIES.DATA_UNIVERSAL ?
                        `${unit.AdditionalProperties.short_name}\u00A0\u00A0${unit.Description}` :
                        unit.AdditionalProperties.short_name,
                    value: convertStringToNumber(unit.Value)
                };
            }))
            : EMPTY_ARRAY;
    }
);
