import Immutable from 'seamless-immutable';
import {
    CREATE_CASE_CONSTANTS,
    EMPTY_CASES_LIST,
    GET_CASE_ACTIVITIES_STATUS,
    REMOVE_CASE_SELECTED_CATEGORY_ID,
    RESET_CURRENT_CASE,
    RETRIEVE_CASE_CONSTANTS,
    RETRIEVE_CASE_TYPES,
    RETRIEVE_PORT_IN_DETAILS,
    SEARCH_CASES_CONSTANTS,
    SET_CASE_EDIT_MODE,
    UPDATE_CASE_CONSTANTS,
    UPDATE_CASE_INVENTORY_ITEM,
    UPDATE_CASE_SELECTED_CATEGORY_ID
} from './actions/customer.cases.actions';
import {RETRIEVE_CONVERGENT_BILLER_INVOICES_CONSTANTS} from './actions/customer.convergent.biller.actions';

export const INITIAL_STATE = Immutable({
    data: {
        case: null,
        caseActivityTypes: null,
        cases: null,
        caseTypes: null,
        invoices: {},
        isEditingCase: false,
        pageCount: null,
        pageNumber: null,
        pageSize: null,
        portInDetails: null,
        recordCount: null
    },
    casesError: null,
    invoicesError: null,
    isCreatingCase: false,
    isFetchingCase: false,
    isFetchingCaseActivityTypes: false,
    isFetchingCases: false,
    isFetchingInvoices: false,
    isUpdatingCase: false,
    selectedCaseCategoryId: null,
    selectedInventoryItem: null
});

export default function casesReducer(state = INITIAL_STATE, {payload = {}, type, requestObject = {}}) {
    switch (type) {
        case CREATE_CASE_CONSTANTS.BEGIN:
            return state.set('isCreatingCase', true);
        case CREATE_CASE_CONSTANTS.SUCCESS:
            return state
                .set('casesError', null)
                .set('isCreatingCase', false)
                .setIn(['data', 'cases'], (state.data.cases || []).concat(prepareCase(payload.Case)));
        case CREATE_CASE_CONSTANTS.FAILURE:
            return state
                .set('isCreatingCase', false)
                .set('casesError', {
                    code: payload.Code,
                    message: payload.Message,
                    translatedMessage: payload.translatedMessage,
                    severity: payload.Severity
                });
        case GET_CASE_ACTIVITIES_STATUS.BEGIN:
            return state
                .set('isFetchingCaseActivityTypes', true);
        case GET_CASE_ACTIVITIES_STATUS.SUCCESS:
            return state
                .set('casesError', null)
                .set('isFetchingCaseActivityTypes', false)
                .setIn(['data', 'caseActivityTypes'], payload.CaseActivities);
        case GET_CASE_ACTIVITIES_STATUS.FAILURE:
            return state
                .set('isFetchingCaseActivityTypes', false)
                .setIn(['data', 'caseActivityTypes'], null)
                .set('casesError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity
                });
        case RETRIEVE_CASE_CONSTANTS.BEGIN:
            return state
                .set('isFetchingCase', true);
        case RETRIEVE_CASE_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingCase', false)
                .set('casesError', null)
                .setIn(['data', 'case'], prepareCase(payload.Case, state.data.case));
        case RETRIEVE_CASE_CONSTANTS.FAILURE:
            return state
                .set('isFetchingCase', false)
                .set('casesError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity
                });
        case RETRIEVE_CASE_TYPES.BEGIN:
            return state
                .set('isFetchingCaseTypes', true);
        case RETRIEVE_CASE_TYPES.SUCCESS:
            return state
                .set('casesError', null)
                .set('isFetchingCaseTypes', false)
                .setIn(['data', 'caseTypes'], payload.FilteredCaseTypes);
        case RETRIEVE_CASE_TYPES.FAILURE:
            return state
                .set('isFetchingCaseTypes', false)
                .setIn(['data', 'caseTypes'], null)
                .set('casesError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity
                });
        case RETRIEVE_CONVERGENT_BILLER_INVOICES_CONSTANTS.BEGIN:
            return state.set('isFetchingInvoices', true);
        case RETRIEVE_CONVERGENT_BILLER_INVOICES_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingInvoices', false)
                .set('invoicesError', null)
                .setIn(['data', 'invoices'], getInvoicesMap(payload.Invoices));
        case RETRIEVE_CONVERGENT_BILLER_INVOICES_CONSTANTS.FAILURE:
            return state
                .set('isFetchingInvoices', false)
                .set('invoicesError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity
                });
        case RETRIEVE_PORT_IN_DETAILS.SUCCESS:
            return state
                .setIn(['data', 'portInDetails'], payload.PortInRequest);
        case SEARCH_CASES_CONSTANTS.BEGIN:
            return state.set('isFetchingCases', true);
        case SEARCH_CASES_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingCases', false)
                .set('casesError', null)
                .setIn(['data', 'pageNumber'], requestObject.pageNumber)
                .setIn(['data', 'pageSize'], requestObject.pageSize)
                .setIn(['data', 'pageCount'], payload.PageCount)
                .setIn(['data', 'recordCount'], payload.RecordCount)
                .setIn(['data', 'cases'], prepareCases(payload.Cases));
        case SEARCH_CASES_CONSTANTS.FAILURE:
            return state
                .set('isFetchingCases', false)
                .set('casesError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity
                });
        case UPDATE_CASE_CONSTANTS.BEGIN:
            return state.set('isUpdatingCase', true);
        case UPDATE_CASE_CONSTANTS.SUCCESS:
            return state
                .set('casesError', null)
                .set('isUpdatingCase', false)
                .setIn(['data', 'case'], prepareCase(payload.Case));
        case UPDATE_CASE_CONSTANTS.FAILURE:
            return state
                .set('isUpdatingCase', false)
                .set('casesError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                });
        case RESET_CURRENT_CASE:
            return state
                .setIn(['data', 'case'], INITIAL_STATE.data.case)
                .setIn(['data', 'portInDetails'], INITIAL_STATE.data.portInDetails);
        case UPDATE_CASE_SELECTED_CATEGORY_ID:
            return state
                .set(['selectedCaseCategoryId'], payload);
        case REMOVE_CASE_SELECTED_CATEGORY_ID:
            return state
                .set(['selectedCaseCategoryId'], null);
        case UPDATE_CASE_INVENTORY_ITEM:
            return state
                .set('selectedInventoryItem', payload);
        case EMPTY_CASES_LIST:
            return INITIAL_STATE;
        case SET_CASE_EDIT_MODE:
            return state
                .setIn(['data', 'isEditingCase'], payload);
        default:
            return state;
    }
}

const prepareCases = (cases) => {
    return cases.map(prepareCase);
};

const prepareCase = (updatedCase, existingCase) => {
    // the API encodes the category and status codes for a case as Numbers when they should be Strings
    const isSameCase = existingCase && (updatedCase.Id === existingCase.Id);

    if (isSameCase) {
        return Object.assign({},
            existingCase,
            updatedCase,
            {
                Category: `${updatedCase.Category}`,
                Status: `${updatedCase.Status}`
            }
        );
    }

    return Object.assign({}, updatedCase, {
        Category: `${updatedCase.Category}`,
        Status: `${updatedCase.Status}`
    });
};

const getInvoicesMap = (invoices) => {
    const invoicesMap = {};
    invoices.forEach((invoice) => {
        invoicesMap[invoice.InvoiceId] = invoice;
    });
    return invoicesMap;
};
