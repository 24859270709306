import compose from 'ramda/src/compose';
import flatten from 'ramda/src/flatten';
import partition from 'ramda/src/partition';
import pathOr from 'ramda/src/pathOr';

import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {MetadataCodeTypeSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';

import {createSelector} from 'reselect';
import {
    parseAdditionalProperties
} from '../helpers/payment.instrument.additional.property.parser';
import {
    AutopayConfigurationAdditionalProperties,
    autopayConfigurationAdditionalPropertyDescriptors
} from '../helpers/autopay.configuration.additional.property.parser';

export const AutopayConfigurationAdditionalPropertiesSelector = createSelector(
    [
        MetadataCodeTypeSelector(CODES.AutopayConfig)
    ],
    (autopayConfiguration) => {
        return (autopayConfiguration || []).reduce((lookup, paymentType) => {
            const model = new AutopayConfigurationAdditionalProperties();
            return parseAdditionalProperties(model, paymentType.AdditionalProperties, autopayConfigurationAdditionalPropertyDescriptors);
        }, {});
    }
);

export const IsAutoPayEnabledSelector = createImmutableSelector(
    [MetadataCodeTypeSelector(CODES.SubscriberSearchConfig)],
    (codes) => {
        if (codes.length) {
            const codesSortedByGlobalFirst = compose(flatten, partition((code) => {
                return code.Global;
            }))(codes);
            const lastEnableAutopayAdditionalProperty = codesSortedByGlobalFirst[codesSortedByGlobalFirst.length - 1].AdditionalProperties.find((additionalProperty) => {
                return additionalProperty.Key === 'enable_autopay';
            });
            return pathOr('', ['Value'], lastEnableAutopayAdditionalProperty).toLowerCase() === 'true';
        }
    });
