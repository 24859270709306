import ApiConstants from 'invision-core/src/api/constants';
import {
    ThunkHelper
} from 'invision-core';

export const SEARCH_COUPON_REDEMPTIONS_CONSTANTS ={
    BEGIN: 'SEARCH_COUPON_REDEMPTIONS_BEGIN',
    SUCCESS: 'SEARCH_COUPON_REDEMPTIONS_SUCCESS',
    FAILURE: 'SEARCH_COUPON_REDEMPTIONS_FAILURE'
};

const SEARCH_COUPON_REDEMPTIONS_EVENTS = [SEARCH_COUPON_REDEMPTIONS_CONSTANTS.BEGIN, SEARCH_COUPON_REDEMPTIONS_CONSTANTS.SUCCESS, SEARCH_COUPON_REDEMPTIONS_CONSTANTS.FAILURE];

const searchCouponRedemptionsPromise = (dispatch, customerId, filter = {}, languageHeader) => {
    const data = {
        PageSize: filter.pageSize,
        PageNumber: filter.pageNumber,
        SearchString: filter.searchString,
        CouponRedemptionCodeId: filter.couponRedemptionCodeId
    };

    return ThunkHelper(dispatch, SEARCH_COUPON_REDEMPTIONS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SearchCouponRedemptions',
        data: data,
        headers: {
            [ApiConstants.CUSTOM_SUBSCRIBER_LANGUAGE_HEADER]: languageHeader,
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId
    });
};

export const SEARCH_REDEMPTION_CODES_CONSTANTS = {
    BEGIN: 'SEARCH_REDEMPTION_CODES_BEGIN',
    SUCCESS: 'SEARCH_REDEMPTION_CODES_SUCCESS',
    FAILURE: 'SEARCH_REDEMPTION_CODES_FAILURE'
};

const SEARCH_REDEMPTION_CODES_EVENTS = [SEARCH_REDEMPTION_CODES_CONSTANTS.BEGIN, SEARCH_REDEMPTION_CODES_CONSTANTS.SUCCESS, SEARCH_REDEMPTION_CODES_CONSTANTS.FAILURE];

export const searchCouponRedemptions = (customerId, filter, languageHeader) => {
    return (dispatch) => {
        return searchCouponRedemptionsPromise(dispatch, customerId, filter, languageHeader);
    };
};

const searchRedemptionCodesPromise = (dispatch, customerId, filter = {}) => {
    const data = {
        PageSize: filter.pageSize,
        PageNumber: filter.pageNumber,
        GrantEnd: filter.grantEnd,
        GrantStart: filter.grantStart,
        SearchString: filter.searchString,
        RedemptionStatus: filter.redemptionStatus,
        SortDirection: filter.sortDirection
    };

    return ThunkHelper(dispatch, SEARCH_REDEMPTION_CODES_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SearchRedemptionCodes',
        data: data,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId
    });
};

export const searchRedemptionCodes = (customerId, filter) => {
    return (dispatch) => {
        return searchRedemptionCodesPromise(dispatch, customerId, filter);
    };
};

export const SEARCH_GRANTABLE_COUPONS_CONSTANTS = {
    BEGIN: 'SEARCH_GRANTABLE_COUPONS_BEGIN',
    SUCCESS: 'SEARCH_GRANTABLE_COUPONS_SUCCESS',
    FAILURE: 'SEARCH_GRANTABLE_COUPONS_FAILURE'
};

export const SEARCH_GRANTABLE_COUPONS_EVENTS = [SEARCH_GRANTABLE_COUPONS_CONSTANTS.BEGIN, SEARCH_GRANTABLE_COUPONS_CONSTANTS.SUCCESS, SEARCH_GRANTABLE_COUPONS_CONSTANTS.FAILURE];

const searchCouponsPromise = (dispatch, filter) => {
    const data = {
        CouponName: filter.couponName,
        PageSize: filter.pageSize,
        PageNumber: filter.pageNumber
    };

    return ThunkHelper(dispatch, SEARCH_GRANTABLE_COUPONS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SearchCoupons',
        data: data
    });
};

export const searchGrantableCoupons = (filter) => {
    return (dispatch) => {
        return searchCouponsPromise(dispatch, filter);
    };
};

export const GRANT_COUPON_CONSTANTS = {
    BEGIN: 'GRANT_COUPON_BEGIN',
    SUCCESS: 'GRANT_COUPON_SUCCESS',
    FAILURE: 'GRANT_COUPON_FAILURE'
};

export const GRANT_COUPON_EVENTS = [GRANT_COUPON_CONSTANTS.BEGIN, GRANT_COUPON_CONSTANTS.SUCCESS, GRANT_COUPON_CONSTANTS.FAILURE];

const grantCouponPromise = (dispatch, customerId, data = {}) => {
    return ThunkHelper(dispatch, GRANT_COUPON_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/GrantCoupon',
        data: data,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId
    });
};

export const grantCoupon = (customerId, data) => {
    return (dispatch) => {
        return grantCouponPromise(dispatch, customerId, data);
    };
};

export const SET_SELECTED_COUPON = 'SET_SELECTED_COUPON';

export const setSelectedCoupon = (payload) => {
    return {
        type: SET_SELECTED_COUPON,
        payload: payload
    };
};

export const RETRIEVE_REDEMPTION_CODE_DETAILS_CONSTANTS = {
    BEGIN: 'RETRIEVE_REDEMPTION_CODE_DETAILS_BEGIN',
    SUCCESS: 'RETRIEVE_REDEMPTION_CODE_DETAILS_SUCCESS',
    FAILURE: 'RETRIEVE_REDEMPTION_CODE_DETAILS_FAILURE'
};
const RETRIEVE_REDEMPTION_CODE_DETAILS_EVENTS = [
    RETRIEVE_REDEMPTION_CODE_DETAILS_CONSTANTS.BEGIN,
    RETRIEVE_REDEMPTION_CODE_DETAILS_CONSTANTS.SUCCESS,
    RETRIEVE_REDEMPTION_CODE_DETAILS_CONSTANTS.FAILURE
];
const retrieveRedemptionCodeDetailsPromise = (dispatch, customerId, couponRedemptionCodeId) => {
    return ThunkHelper(dispatch, RETRIEVE_REDEMPTION_CODE_DETAILS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveRedemptionCode',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            Id: couponRedemptionCodeId
        }
    }, {
        couponRedemptionCodeId: couponRedemptionCodeId
    });
};

export const retrieveRedemptionCodeDetails = (customerId, couponRedemptionCodeId) => {
    return (dispatch) => {
        return retrieveRedemptionCodeDetailsPromise(dispatch, customerId, couponRedemptionCodeId);
    };
};

export const SET_CURRENT_GRANT_COUPONS_PAGE = 'SET_CURRENT_GRANT_COUPONS_PAGE';
export const setCurrentGrantCouponsPage = (page) => {
    return {
        type: SET_CURRENT_GRANT_COUPONS_PAGE,
        payload: page
    };
};

export const SET_GRANT_COUPONS_NAME_FILTER = 'SET_GRANT_COUPONS_NAME_FILTER';
export const setGrantCouponsNameFilter = (name) => {
    return {
        type: SET_GRANT_COUPONS_NAME_FILTER,
        payload: name
    };
};

export const SET_CURRENT_GRANTED_COUPONS_PAGE = 'SET_CURRENT_GRANTED_COUPONS_PAGE';
export const setCurrentGrantedCouponsPage = (page) => {
    return {
        type: SET_CURRENT_GRANTED_COUPONS_PAGE,
        payload: page
    };
};

export const SET_GRANTED_COUPONS_GRANT_DATE_END_FILTER = 'SET_GRANTED_COUPONS_GRANT_DATE_END_FILTER';
export const setGrantedCouponsGrantDateEndFilter = (date) => {
    return {
        type: SET_GRANTED_COUPONS_GRANT_DATE_END_FILTER,
        payload: date
    };
};

export const SET_GRANTED_COUPONS_GRANT_DATE_START_FILTER = 'SET_GRANTED_COUPONS_GRANT_DATE_START_FILTER';
export const setGrantedCouponsGrantDateStartFilter = (date) => {
    return {
        type: SET_GRANTED_COUPONS_GRANT_DATE_START_FILTER,
        payload: date
    };
};

export const SET_GRANTED_COUPONS_NAME_OR_CODE_FILTER = 'SET_GRANTED_COUPONS_NAME_OR_CODE_FILTER';
export const setGrantedCouponsNameOrCodeFilter = (nameOrCode) => {
    return {
        type: SET_GRANTED_COUPONS_NAME_OR_CODE_FILTER,
        payload: nameOrCode
    };
};

export const SET_GRANTED_COUPONS_REDEMPTION_STATUS_FILTER = 'SET_GRANTED_COUPONS_REDEMPTION_STATUS_FILTER';
export const setGrantedCouponsRedemptionStatusFilter = (redemptionStatus) => {
    return {
        type: SET_GRANTED_COUPONS_REDEMPTION_STATUS_FILTER,
        payload: redemptionStatus
    };
};

export const SET_GRANTED_COUPONS_PAGE_SIZE_PREFERENCE = 'SET_GRANTED_COUPONS_PAGE_SIZE_PREFERENCE';
export const setGrantedCouponsPageSizePreference = (pageSize) => {
    return {
        type: SET_GRANTED_COUPONS_PAGE_SIZE_PREFERENCE,
        payload: pageSize
    };
};

export const SET_COUPONS_PAGE_SIZE_PREFERENCE = 'SET_COUPONS_PAGE_SIZE_PREFERENCE';
export const setPageSizePreference = (pageSize) => {
    return {
        type: SET_COUPONS_PAGE_SIZE_PREFERENCE,
        payload: pageSize
    };
};

export const RESEND_COUPON_REDEMPTION_CODE = {
    BEGIN: 'RESEND_COUPON_REDEMPTION_CODE_BEGIN',
    SUCCESS: 'RESEND_COUPON_REDEMPTION_CODE_SUCCESS',
    FAILURE: 'RESEND_COUPON_REDEMPTION_CODE_FAILURE'
};
const RESEND_COUPON_REDEMPTION_CODE_EVENTS = [RESEND_COUPON_REDEMPTION_CODE.BEGIN, RESEND_COUPON_REDEMPTION_CODE.SUCCESS, RESEND_COUPON_REDEMPTION_CODE.FAILURE];
export const resendRedemptionCodePromise = (dispatch, customerId, couponRedemptionCode, recipientEmailAddress, recipientName) => {
    const data = {
        RedemptionCode: couponRedemptionCode
    };

    if (recipientEmailAddress) {
        data.RecipientEmailAddress = recipientEmailAddress;
    }
    if (recipientName) {
        data.RecipientName = recipientName;
    }

    return ThunkHelper(dispatch, RESEND_COUPON_REDEMPTION_CODE_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/ResendRedemptionCode',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: data
    });
};

export const resendRedemptionCode = (customerId, couponRedemptionCodeId, recipientEmailAddress = '', recipientName = '') => {
    return (dispatch) => {
        return resendRedemptionCodePromise(dispatch, customerId, couponRedemptionCodeId, recipientEmailAddress, recipientName);
    };
};

export const EMPTY_COUPONS_LIST = 'EMPTY_COUPONS_LIST';
export const emptyCouponsList = () => {
    return {
        type: EMPTY_COUPONS_LIST
    };
};

export const REMOVE_COUPONS_FILTER = 'REMOVE_COUPONS_FILTER';
export const removeCouponsFilter = () => {
    return {
        type: REMOVE_COUPONS_FILTER
    };
};
