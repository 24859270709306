export const buildSearchBulkServicesRequest = (params) => {
    const request = {};
    request.EntityIdType = 0;
    if (params.orderItemId) {
        request.EntityId = params.orderItemId;
        request.EntityIdType = 1;
    }
    if (params.subscriberProductId) {
        request.EntityId = params.subscriberProductId;
        request.EntityIdType = 2;
    }
    if (params.status) {
        request.SearchServiceStatus = params.status;
    }
    if (params.serviceType) {
        request.SearchServiceType = params.serviceType;
    }
    if (params.searchIdentifier && params.searchIdentifier !== '') {
        request.SearchIdentifier = params.searchIdentifier;
    }
    request.PageNumber = params.pageNumber;
    request.PageSize = params.pageSize;
    return request;
};
