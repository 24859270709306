import {
    pick,
    values
} from 'ramda';

import {
    ApiConstants,
    ThunkHelper
} from 'invision-core';

import {buildCustomerRequestObject as buildSubscriberObject} from '../helpers/customer.helper';

export const RETRIEVE_HIERARCHY_CONSTANTS = {
    BEGIN: 'RETRIEVE_HIERARCHY_BEGIN',
    SUCCESS: 'RETRIEVE_HIERARCHY_SUCCESS',
    FAILURE: 'RETRIEVE_HIERARCHY_FAILURE'
};
const RETRIEVE_HIERARCHY_EVENTS = [
    RETRIEVE_HIERARCHY_CONSTANTS.BEGIN,
    RETRIEVE_HIERARCHY_CONSTANTS.SUCCESS,
    RETRIEVE_HIERARCHY_CONSTANTS.FAILURE
];
const retrieveHierarchyPromise = (dispatch, hierarchyId) => {
    return ThunkHelper(dispatch, RETRIEVE_HIERARCHY_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveHierarchy',
        data: {
            HierarchyId: hierarchyId
        }
    });
};
export const retrieveHierarchy = (hierarchyId) => {
    return (dispatch) => {
        return retrieveHierarchyPromise(dispatch, hierarchyId);
    };
};

export const SET_SELECTED_HIERARCHY_NODE = 'SET_SELECTED_HIERARCHY_NODE';
export const setSelectedHierarchyNode = (hierarchyNodeId) => {
    return {
        type: SET_SELECTED_HIERARCHY_NODE,
        payload: hierarchyNodeId
    };
};

export const SET_POPUP_HIERARCHY_NODE = 'SET_POPUP_HIERARCHY_NODE';
export const setPopupHierarchyNode = (popupNode) => {
    return {
        type: SET_POPUP_HIERARCHY_NODE,
        payload: popupNode
    };
};

export const UPDATE_HIERARCHY_NODE_CONSTANTS = {
    BEGIN: 'UPDATE_HIERARCHY_NODE_BEGIN',
    SUCCESS: 'UPDATE_HIERARCHY_NODE_SUCCESS',
    FAILURE: 'UPDATE_HIERARCHY_NODE_FAILURE'
};

export const UPDATE_HIERARCHY_NODE_EVENTS = [
    UPDATE_HIERARCHY_NODE_CONSTANTS.BEGIN,
    UPDATE_HIERARCHY_NODE_CONSTANTS.SUCCESS,
    UPDATE_HIERARCHY_NODE_CONSTANTS.FAILURE
];

const updateHierarchyNodePromise = (dispatch, request, customerId) => {
    return ThunkHelper(dispatch, UPDATE_HIERARCHY_NODE_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/UpdateHierarchyNode',
        data: request,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const updateHierarchyNode = (request, customerId) => {
    return (dispatch) => {
        return updateHierarchyNodePromise(dispatch, request, customerId);
    };
};

export const RETRIEVE_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS = {
    BEGIN: 'RETRIEVE_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_BEGIN',
    SUCCESS: 'RETRIEVE_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_SUCCESS',
    FAILURE: 'RETRIEVE_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_FAILURE'
};
const RETRIEVE_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_EVENTS = [
    RETRIEVE_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS.BEGIN,
    RETRIEVE_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS.SUCCESS,
    RETRIEVE_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS.FAILURE
];
export const retrieveNodeConvergentBillerSubscriberSummaryPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, RETRIEVE_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveConvergentBillerSubscriberSummary',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId: customerId
    });
};
export const retrieveNodeConvergentBillerSubscriberSummary = (customerId) => {
    return (dispatch) => {
        return retrieveNodeConvergentBillerSubscriberSummaryPromise(dispatch, customerId);
    };
};

export const RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS = {
    BEGIN: 'RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_BEGIN',
    SUCCESS: 'RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_SUCCESS',
    FAILURE: 'RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_FAILURE'
};
export const retrievePopupNodeConvergentBillerSubscriberSummaryPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, values(RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveConvergentBillerSubscriberSummary',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId: customerId
    });
};
export const retrievePopupNodeConvergentBillerSubscriberSummary = (customerId) => {
    return (dispatch) => {
        return retrievePopupNodeConvergentBillerSubscriberSummaryPromise(dispatch, customerId);
    };
};

export const SEARCH_HIERARCHY_NODES_CONSTANTS = {
    BEGIN: 'SEARCH_HIERARCHY_NODES_CONSTANTS_BEGIN',
    SUCCESS: 'SEARCH_HIERARCHY_NODES_CONSTANTS_SUCCESS',
    FAILURE: 'SEARCH_HIERARCHY_NODES_CONSTANTS_FAILURE'
};
export const searchHierarchyNodesPromise = (dispatch, customerId, request) => {
    return ThunkHelper(dispatch, values(SEARCH_HIERARCHY_NODES_CONSTANTS), {
        method: 'post',
        url: 'SubscriberManagement/SearchHierarchyNodes',
        data: request,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    }, request);
};
export const searchHierarchyNodes = (customerId, request) => {
    return (dispatch) => {
        return searchHierarchyNodesPromise(dispatch, customerId, request);
    };
};



export const RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS = {
    BEGIN: 'RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_ACCOUNT_DETAILS_BEGIN',
    SUCCESS: 'RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_ACCOUNT_DETAILS_SUCCESS',
    FAILURE: 'RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_ACCOUNT_DETAILS_FAILURE'
};
const RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_ACCOUNT_DETAILS_EVENTS = [
    RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS.BEGIN,
    RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS.SUCCESS,
    RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS.FAILURE,
];
export const retrievePopupNodeConvergentBillerAccountDetailsPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_ACCOUNT_DETAILS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveConvergentBillerAccountDetails',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};
export const retrievePopupNodeConvergentBillerAccountDetails = (customerId) => {
    return (dispatch) => {
        return retrievePopupNodeConvergentBillerAccountDetailsPromise(dispatch, customerId);
    };
};

export const UPDATE_NODE_SERVICE_CONSTANTS = {
    BEGIN: 'UPDATE_NODE_SERVICE_BEGIN',
    SUCCESS: 'UPDATE_NODE_SERVICE_SUCCESS',
    FAILURE: 'UPDATE_NODE_SERVICE_FAILURE'
};

export const UPDATE_NODE_SERVICE_EVENTS = [
    UPDATE_NODE_SERVICE_CONSTANTS.BEGIN,
    UPDATE_NODE_SERVICE_CONSTANTS.SUCCESS,
    UPDATE_NODE_SERVICE_CONSTANTS.FAILURE
];

const updateNodeServicePromise = (dispatch, request, destinationCustomerId ) => {
    return ThunkHelper(dispatch, UPDATE_NODE_SERVICE_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/UpdateChargeRedirectInstance',
        data: request,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: destinationCustomerId
        }
    });
};

export const updateNodeService = (request, destinationCustomerId) => {
    return (dispatch) => {
        return updateNodeServicePromise(dispatch, request, destinationCustomerId);
    };
};

export const RETRIEVE_POPUP_NODE_REDIRECTED_SERVICES_CONSTANTS = {
    BEGIN: 'RETRIEVE_POPUP_NODE_REDIRECTED_SERVICES_BEGIN',
    SUCCESS: 'RETRIEVE_POPUP_NODE_REDIRECTED_SERVICES_SUCCESS',
    FAILURE: 'RETRIEVE_POPUP_NODE_REDIRECTED_SERVICES_FAILURE'
};

export const RETRIEVE_POPUP_NODE_SERVICES_EVENTS = [
    RETRIEVE_POPUP_NODE_REDIRECTED_SERVICES_CONSTANTS.BEGIN,
    RETRIEVE_POPUP_NODE_REDIRECTED_SERVICES_CONSTANTS.SUCCESS,
    RETRIEVE_POPUP_NODE_REDIRECTED_SERVICES_CONSTANTS.FAILURE
];

const retrievePopupNodeRedirectedServicesPromise = (dispatch, destinationCustomerId ) => {
    return ThunkHelper(dispatch, RETRIEVE_POPUP_NODE_SERVICES_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveChargeRedirectInstances',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: destinationCustomerId
        }
    });
};

export const retrievePopupNodeRedirectedServices = (destinationCustomerId) => {
    return (dispatch) => {
        return retrievePopupNodeRedirectedServicesPromise(dispatch, destinationCustomerId);
    };
};

export const CREATE_CHILD_CONSTANTS = {
    BEGIN: 'CREATE_CHILD_BEGIN',
    SUCCESS: 'CREATE_CHILD_SUCCESS',
    FAILURE: 'CREATE_CHILD_FAILURE'
};
const CREATE_CHILD_EVENTS = values(CREATE_CHILD_CONSTANTS);
const createChildPromise = (dispatch, createChildModel, accountHierarchyId, parentCustomerId, defaultAddress, subscriberId) => {
    return ThunkHelper(dispatch, CREATE_CHILD_EVENTS, {
        data: {
            HierarchyId: accountHierarchyId,
            HierarchySubscriber: subscriberId ? null : buildHierarchySubscriberObject(createChildModel.Customer),
            NodeName: buildHierarchyNodeName(createChildModel.Customer),
            ParentSubscriberId: parentCustomerId,
            SubscriberId: subscriberId
        },
        method: 'post',
        url: 'SubscriberManagement/AddHierarchyNode'
    });
};
export const createChild = (model, accountHierarchyId, parentCustomerId, defaultAddress, subscriberId) => {
    return (dispatch) => {
        return createChildPromise(dispatch, model, accountHierarchyId, parentCustomerId, defaultAddress, subscriberId);
    };
};

export const SET_CREATE_CHILD_MODEL_CONSTANT = 'SET_CREATE_CHILD_MODEL_CONSTANT';
export const setCreateChildModel = (customer) => {
    return {
        type: SET_CREATE_CHILD_MODEL_CONSTANT,
        payload: {
            Customer: customer
        }
    };
};

export const buildHierarchySubscriberObject = (customer) => {
    return pick(['Addresses', 'AutoLogin', 'CountryOverride', 'Credentials', 'PaymentInstruments', 'Subscriber', 'TextScrubberOverride'], buildSubscriberObject(customer));
};

const buildHierarchyNodeName = (customer) => {
    if (customer.FirstName || customer.LastName) {
        return customer.FirstName && customer.LastName
            ? `${customer.FirstName} ${customer.LastName}`
            : customer.FirstName || customer.LastName;
    } else if (customer.CompanyName) {
        return customer.CompanyName;
    }
};

export const SET_CHILD_FOR_CUSTOMER_HIERARCHY = 'SET_CHILD_FOR_CUSTOMER_HIERARCHY';
export const setChildForCustomerHierarchy = (isChildForHeirarchy) => {
    return {
        type: SET_CHILD_FOR_CUSTOMER_HIERARCHY,
        payload: isChildForHeirarchy
    };
};

export const REMOVE_HIERARCHY_NODE_CONSTANTS = {
    BEGIN: 'REMOVE_HIERARCHY_NODE_BEGIN',
    SUCCESS: 'REMOVE_HIERARCHY_NODE_SUCCESS',
    FAILURE: 'REMOVE_HIERARCHY_NODE_FAILURE'
};

export const REMOVE_HIERARCHY_NODE_EVENTS = [
    REMOVE_HIERARCHY_NODE_CONSTANTS.BEGIN,
    REMOVE_HIERARCHY_NODE_CONSTANTS.SUCCESS,
    REMOVE_HIERARCHY_NODE_CONSTANTS.FAILURE
];


const removeHierarchyNodePromise = (dispatch, subscriberId) => {
    return ThunkHelper(dispatch, REMOVE_HIERARCHY_NODE_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RemoveHierarchyNode',
        headers: {
            'CD-SubscriberId': subscriberId
        },
        data: {
            SubscriberId: subscriberId
        }
    });
};

export const removeHierarchyNode = (subscriberId) => {
    return (dispatch) => {
        return removeHierarchyNodePromise(dispatch, subscriberId);
    };
};

export const SET_HIERARCHY_NODE_TO_MOVE = 'SET_HIERARCHY_NODE_TO_MOVE';
export const setHierarchyNodeToMove = (hierarchyNodeId) => {
    return {
        type: SET_HIERARCHY_NODE_TO_MOVE,
        payload: hierarchyNodeId
    };
};

export const SET_TARGET_NODE = 'SET_TARGET_NODE';
export const setHierarchyTargetNode = (hierarchyNodeId) => {
    return {
        type: SET_TARGET_NODE,
        payload: hierarchyNodeId
    };
};