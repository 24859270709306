import {
    RETRIEVE_ACCOUNT_STATUS_CONSTANTS,
    SET_AUTO_PAY_BANNER_FLAG
} from './actions/customer.account.status.actions';
import Immutable from 'seamless-immutable';

export const INITIAL_STATE = Immutable({
    data: null,
    isFetchingData: null,
    accountStatusError: null,
    showAutoPayBanner: true
});

export default function accountStatusReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case RETRIEVE_ACCOUNT_STATUS_CONSTANTS.BEGIN:
            return state.set('isFetchingData', true);
        case RETRIEVE_ACCOUNT_STATUS_CONSTANTS.SUCCESS:
            return state
                .set('data', payload)
                .set('isFetchingData', false)
                .set('accountStatusError', null);
        case RETRIEVE_ACCOUNT_STATUS_CONSTANTS.FAILURE:
            return state
                .set('isFetchingData', false)
                .set('showAutoPayBanner', false)
                .set('accountStatusError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case SET_AUTO_PAY_BANNER_FLAG:
            return state
                .set('showAutoPayBanner', payload);
        default:
            return state;
    }
}
