import {values} from 'ramda';
import ThunkHelper from 'invision-core/src/api/thunk.helper';
import {LastJobTokenSelector} from '../selectors/address.component.selectors';

export const VALIDATE_ADDRESS_CONSTANTS = {
    BEGIN: 'VALIDATE_ADDRESS_BEGIN',
    SUCCESS: 'VALIDATE_ADDRESS_SUCCESS',
    FAILURE: 'VALIDATE_ADDRESS_FAILURE'
};

export const SET_ADDRESS_TO_BE_VALIDATED = 'SET_ADDRESS_TO_BE_VALIDATED';

export const SET_ADDRESS_KEY_FOR_ADDRESS_BEING_VALIDATED_THROUGH_API = 'SET_ADDRESS_KEY_FOR_ADDRESS_BEING_VALIDATED_THROUGH_API';

export const SET_ADDRESS_TO_VALIDATED_ADDRESSES = 'SET_ADDRESS_TO_VALIDATED_ADDRESS';

export const SET_ADDRESS_KEY_FOR_ADDRESS_PREVIOUSLY_VALIDATED_THROUGH_API = 'SET_ADDRESS_KEY_FOR_ADDRESS_PREVIOUSLY_VALIDATED_THROUGH_API';

export const SET_SUGGESTED_ADDRESS_TO_VALIDATED_ADDRESSES = 'SET_SUGGESTED_ADDRESS_TO_VALIDATED_ADDRESS';

export const SET_FORCE_SHOW_EDIT_FORM_ADDRESSES_COMPONENT = 'SET_FORCE_SHOW_EDIT_FORM_ADDRESSES_COMPONENT';

export const SET_EDIT_ADDRESS_BACK_TO_INVALID = 'SET_EDIT_ADDRESS_BACK_TO_INVALID';

export const setAddress = (address) => {
    return {
        type: SET_ADDRESS_TO_BE_VALIDATED,
        payload: address
    };
};

const validateAddressPromise = (dispatch, address, token, addressTypes) => {
    return ThunkHelper(dispatch, values(VALIDATE_ADDRESS_CONSTANTS), {
        method: 'post',
        url: 'SubscriberManagement/ValidateAddress',
        data: {
            Address: {
                City: address.city,
                Country: address.country,
                LineOne: address.addressLine1,
                LineTwo: address.addressLine2,
                Name: address.addressLine1, //mimicking existing pattern for populating name
                PostalCode: address.postalCode,
                State: address.stateRegionProvince,
                DefaultBilling: addressTypes.DefaultBilling,
                DefaultHome: addressTypes.DefaultHome,
                DefaultPostal: addressTypes.DefaultPostal,
                DefaultService: addressTypes.DefaultService,
                DefaultShipping: addressTypes.DefaultShipping,
                DefaultWork: addressTypes.DefaultWork,
            },
            JobToken: token
        }
    });
};

export const validateAddress = (addressKey, address, token, addressTypes) => {
    return (dispatch, getState) => {
        dispatch(setAddressKeyForAddressPreviouslyValidatedThroughAPI(null));
        dispatch(setAddressKeyForAddressBeingValidatedThroughApi(addressKey));
        const lastJobToken = LastJobTokenSelector(getState());
        return validateAddressPromise(dispatch, address, lastJobToken, addressTypes);
    };
};

export const setAddressToValidatedAddress = (address, jobToken, addressValidationStatus) => {
    return {
        type: SET_ADDRESS_TO_VALIDATED_ADDRESSES,
        payload: {
            address: address,
            JobToken: jobToken,
            AddressValidationStatus: addressValidationStatus
        }
    };
};

export const setAddressKeyForAddressBeingValidatedThroughApi = (addressKey) => {
    return {
        type: SET_ADDRESS_KEY_FOR_ADDRESS_BEING_VALIDATED_THROUGH_API,
        payload: addressKey
    };
};

export const setAddressKeyForAddressPreviouslyValidatedThroughAPI = (addressKey) => {
    return {
        type: SET_ADDRESS_KEY_FOR_ADDRESS_PREVIOUSLY_VALIDATED_THROUGH_API,
        payload: addressKey
    };
};

export const RESET_ADDRESS_COMPONENT = 'RESET_ADDRESS_COMPONENT' ;

export const resetAddressComponent = () => {
    return {
        type: RESET_ADDRESS_COMPONENT,
    };
};

export const setSuggestedAddressToValidatedAddress = (address, jobToken) => {
    return {
        type: SET_SUGGESTED_ADDRESS_TO_VALIDATED_ADDRESSES,
        payload: {
            address: address,
            JobToken: jobToken
        }
    };
};

export const setForceShowEditFormAddress = (status) => {
    return {
        type: SET_FORCE_SHOW_EDIT_FORM_ADDRESSES_COMPONENT,
        payload: status
    };
};

export const setEditAddressBackToInvalid = (address, response) => {
    return {
        type: SET_EDIT_ADDRESS_BACK_TO_INVALID,
        payload: {
            address,
            response
        }
    };
};