import path from 'ramda/src/path';
import {convertUnit} from 'invision-core/src/utilities/unitConversion';
import {NA} from '../constants/billing.payments.constants';

export function getUnitOfMeasure(unitId, units) {
    return path(['AdditionalProperties', 'short_name'], units.find((unit) => {
        return unit.Value === unitId;
    }));
}

export function convertUnitOfMeasure(value, from, to, unitConversionTable) {
    let returnValue = value;
    const convertedBalance = convertUnit({
        to,
        from,
        value,
        unitConversionTable
    });
    if (convertedBalance) {
        returnValue = convertedBalance;
    }
    return returnValue || NA;
}
