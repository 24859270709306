import {createSelector} from 'reselect';
import {
    RouteParams
} from './customer.selectors';
import {
    pathOr
} from 'ramda';
import {
    CoreLocaleKeys,
    i18n
} from 'invision-core';


const recoverableUIStateSelector = (state) => {
    return state.customercare.recoverableUiState.customerCouponsLists;
};

export const CouponStoreSelector = (state) => {
    return state.customercare.coupon;
};

export const CouponRedemptionCodesSelector = createSelector(
    [CouponStoreSelector],
    (coupon) => {
        return coupon.data ? coupon.data : {};
    }
);

export const CurrentCouponRedemptionCodeIdentifierSelector = createSelector(
    [CouponStoreSelector],
    (coupon) => {
        return coupon.searchResultId;
    }
);

export const CurrentCouponRedemptionCodeSelector = createSelector(
    [CouponRedemptionCodesSelector, CurrentCouponRedemptionCodeIdentifierSelector],
    (couponCodeThumbnails = {}, currentCouponRedemptionCodeId = null) => {
        return couponCodeThumbnails[currentCouponRedemptionCodeId] ? couponCodeThumbnails[currentCouponRedemptionCodeId] : null;
    }
);

export const CurrentCouponRedemptionCodeValueSelector = createSelector(
    [CurrentCouponRedemptionCodeSelector],
    (couponCodeThumbnail) => {
        return couponCodeThumbnail.RedemptionCode.RedemptionCode;
    }
);

export const CouponRedemptionCodeErrorSelector = createSelector(
    [CouponStoreSelector],
    (coupon) => {
        return coupon.couponError;
    }
);

export const SelectedReasonIdSelector = createSelector(
    [CouponStoreSelector],
    (coupon) => {
        return coupon.selectedReasonId;
    }
);

export const CurrentRedemptionDetailsSelector = createSelector(
    [CouponRedemptionCodesSelector, RouteParams],
    (couponRedemptionCodeDetails, routeParams) => {
        return pathOr(null, [routeParams.couponRedemptionCodeId], couponRedemptionCodeDetails);
    }
);

export const CurrentRedemptionCodeRedemptionsDataSelector = createSelector(
    [CouponRedemptionCodesSelector],
    (couponRedemptionCodes) => {
        return pathOr(null, ['redemptionCodeRedemptionsData'], couponRedemptionCodes);
    }
);

export const IsRedemptionCodeGlobalSelector = createSelector(
    [CurrentRedemptionDetailsSelector],
    (currentRedemptionCodeDetails) => {
        if (currentRedemptionCodeDetails && currentRedemptionCodeDetails.RedemptionCode) {
            // All RedemptionCode objects with the Granted property WILL NOT be global
            return !currentRedemptionCodeDetails.RedemptionCode.Granted;
        }

        return false;
    }
);

export const IsFetchingRedemptionCodeRedemptionsSelector = createSelector(
    [CouponStoreSelector],
    (couponStore) => {
        return pathOr(false, ['isFetchingRedemptionCodeRedemptions'], couponStore);
    }
);

export const RedemptionCodeRedemptionsRecordCountSelector = createSelector(
    [CurrentRedemptionCodeRedemptionsDataSelector],
    (redemptionData) => {
        return pathOr(0, ['RecordCount'], redemptionData);
    }
);

export const RedemptionCodeRedemptionsTotalPageNumberSelector = createSelector(
    [CurrentRedemptionCodeRedemptionsDataSelector],
    (redemptionData) => {
        return pathOr(0, ['PageCount'], redemptionData);
    }
);

export const RedemptionCodeRedemptionsCurrentPageNumberSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return pathOr(1, ['redemptionCodeRedemptionsCurrentPageNumber'], uiState);
    }
);

export const RedemptionCodeRedemptionsSelectedPageSizeSelector = createSelector(
    [recoverableUIStateSelector],
    (redemptionData) => {
        return pathOr(null, ['redemptionCodeRedemptionsRedemptionPageSizePreference'], redemptionData);
    }
);

export const RedemptionCodeRedemptionsRedemptionDateEndFilterSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return pathOr(undefined, ['redemptionCodeRedemptionsRedemptionDateEndFilter'], uiState);
    }
);

export const RedemptionCodeRedemptionsRedemptionDateStartFilterSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return pathOr(undefined, ['redemptionCodeRedemptionsRedemptionDateStartFilter'], uiState);
    }
);

export const CurrentRedemptionCodeTableDataSelector = createSelector(
    [CurrentRedemptionCodeRedemptionsDataSelector],
    (redemptionCodeRedemptionsData) => {
        const tableData = [];
        if (redemptionCodeRedemptionsData && redemptionCodeRedemptionsData.CouponRedemptions) {
            redemptionCodeRedemptionsData.CouponRedemptions.forEach((redemptionInstance) => {
                let productPricingPlan;
                if (redemptionInstance.Product && redemptionInstance.PricingPlanName) {
                    productPricingPlan = `${redemptionInstance.Product.Name}/${redemptionInstance.PricingPlanName}`;
                } else {
                    productPricingPlan = i18n.translate(CoreLocaleKeys.NOT_APPLICABLE);
                }

                tableData.push({
                    RedemptionDate: redemptionInstance.RedemptionDate || i18n.translate(CoreLocaleKeys.NOT_APPLICABLE),
                    Redeemer: redemptionInstance.SubscriberEmail || i18n.translate(CoreLocaleKeys.NOT_APPLICABLE),
                    OrderId: redemptionInstance.OrderId || i18n.translate(CoreLocaleKeys.NOT_APPLICABLE),
                    OrderNumber: redemptionInstance.OrderNumber || i18n.translate(CoreLocaleKeys.NOT_APPLICABLE),
                    ProductPricingPlan: productPricingPlan,
                    SubscriberId: redemptionInstance.SubscriberId || undefined
                });
            });
        }
        return tableData;
    }
);
