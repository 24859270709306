import {
    MetadataActions
} from 'invision-core';

export const RETRIEVE_CUSTOMER_INFO_FOR_OTT_DASHBOARD_CONSTANTS = {
    BEGIN: 'RETRIEVE_CUSTOMER_INFO_FOR_OTT_DASHBOARD_BEGIN',
    SUCCESS: 'RETRIEVE_CUSTOMER_INFO_FOR_OTT_DASHBOARD_SUCCESS',
    FAILURE: 'RETRIEVE_CUSTOMER_INFO_FOR_OTT_DASHBOARD_FAILURE'
};

export const retrieveCustomerDataForOttDashboard = () => {
    return (dispatch) => {
        dispatch({
            type: RETRIEVE_CUSTOMER_INFO_FOR_OTT_DASHBOARD_CONSTANTS.BEGIN
        });

        return MetadataActions.codes.fetchInteractionTypesPromise(dispatch).then(() => {
            dispatch({
                type: RETRIEVE_CUSTOMER_INFO_FOR_OTT_DASHBOARD_CONSTANTS.SUCCESS
            });
        }, (error) => {
            dispatch({
                type: RETRIEVE_CUSTOMER_INFO_FOR_OTT_DASHBOARD_CONSTANTS.FAILURE,
                payload: error
            });
        });
    };
};
