import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';
import propEq from 'ramda/src/propEq';
import clone from 'ramda/src/clone';
import Immutable from 'seamless-immutable';
import {createSelector} from 'reselect';
import uuid from 'uuid/v4';
import {
    CART_ITEM_TYPE,
    DEFAULT_CART_ITEM_VIEW_MODEL
} from '../constants/products.wizard.constants';

const DEFAULT_PRODUCT_CART_VIEW_MODEL = {
    items: [],
    replaceItem: null,
    hasSubscriptionQuote: false,
    currencyCode: 'USD', // This gets overwritten below when there's a shopping cart available
    discountAmount: 0,
    discounts : '',
    subTotalAmount: 0,
    taxesCalculated: false,
    taxAmount: 0,
    taxInclusive: false,
    taxItems: [],
    totalAmount: 0,
    totalRemainingAmount: 0
};

const EMPTY_ARRAY = Immutable([]);

const mapProductCartItemToViewModel = (items, productsMetadata = {}, urlRegex = null, isRemovable = true, isAddition = false, isEditable = true) => {
    if (!items || !items.length) {
        return [];
    }

    return items.map((item, index) => {
        const cartItemId = uuid();
        const pricingPlanTermsAndConditionsString = getPricingPlanTermsAndConditions(productsMetadata, item.ProductId, item.PricingPlanId);
        return Object.assign({}, DEFAULT_CART_ITEM_VIEW_MODEL, {
            availabilityDate: getPricingPlanAvailabilityDate(pathOr(null, ['Details', 'PricingPlan', 'AvailabilityStart'], item)), //verifies availability date is in the future
            childItems: item.ChildItems && item.ChildItems.length > 0 ? mapProductCartItemToViewModel(item.ChildItems) : [],
            discountAmount: item.Details.GrossAmount - item.Details.TotalAmount,
            grossAmount: item.Details.GrossAmount,
            totalAmount: item.Details.TotalAmount,
            name: item.Details.Product.Name,
            pricingPlanName: item.Details.PricingPlan.Name,
            pricingPlanTermsAndConditions: pricingPlanTermsAndConditionsString,
            pricingPlanTermsAndConditionsIsUrl: pricingPlanTermsAndConditionsString && new RegExp(urlRegex).test(pricingPlanTermsAndConditionsString),
            pricingPlanId: item.PricingPlanId,
            productId: item.ProductId,
            instanceProperties: item.InstanceProperties,
            quantity: item.Quantity,
            id: cartItemId,
            index: index,
            isEditable: isEditable,
            isRemovable,
            itemType: isAddition ? CART_ITEM_TYPE.ADDITION : null
        });
    });
};

export const getBriName = (cartItem, productsMetadata) => {
    const product = productsMetadata[cartItem.ProductId];
    if (product) {
        const pricingPlan = product.PricingPlans.find((pricingPlan) => {
            return pricingPlan.Id === cartItem.PricingPlanId;
        });

        if (pricingPlan.PricingPlanBillerRuleInstances) {
            const bris = [
                ...pricingPlan.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances,
                ...pricingPlan.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances
            ];
            return bris[0] && bris[0].Name || '';
        }

        return '';
    }
};

const getPathToAmount = (bri, path) => {
    return pathOr(0, ['BillerRuleInstanceCharges', 0, path], bri);
};

const getSummedAmounts = (dbssBRIs, amountPath) => {
    // TODO - Temporary solution. We should not be doing front end math here
    return dbssBRIs.reduce((prev, cur) => {
        // if bri does not contain 'NonDiscountChargeAmount' then use 'ChargeAmount' property
        const pathToAmount = (amountPath === 'NonDiscountChargeAmount' && !getPathToAmount(cur, amountPath)) ?
            getPathToAmount(cur, 'ChargeAmount') : getPathToAmount(cur, amountPath);
        return prev + pathToAmount;
    }, 0);
};

const mapServiceFeatureProductCartItemToViewModel = (items, productsMetadata = {}, urlRegex = null, isRemovable = true, isAddition = false, isEditable = true, submitChangeOfServiceRequest,  checkRemoveItems = false) => {
    const newItems = items && items.asMutable ? items.asMutable({
        deep: true
    }) : items;
    if (!newItems || !newItems.length) {
        return [];
    }
    return newItems.map((item, index) => {
        const cartItemId = uuid();
        const pricingPlanTermsAndConditionsString = getPricingPlanTermsAndConditions(productsMetadata, item.ProductId, item.PricingPlanId);
        let isAddItem = null;
        const indexOfAddItem = submitChangeOfServiceRequest ? submitChangeOfServiceRequest.AddItems.findIndex((quantity) => {
            return quantity.PricingPlanId === item.PricingPlanId && quantity.ProductId === item.ProductId;
        }) : -1;
        if (indexOfAddItem > -1) {
            isAddItem = submitChangeOfServiceRequest.AddItems[indexOfAddItem];
            submitChangeOfServiceRequest.AddItems.splice(indexOfAddItem, 1);
        }
        const isRemoveItem = checkRemoveItems && submitChangeOfServiceRequest ? submitChangeOfServiceRequest.RemoveItems.find((quantity) => {
            if (quantity.PricingPlanId === item.PricingPlanId && quantity.ProductId === item.ProductId) {
                return quantity.PricingPlanId === item.PricingPlanId && quantity.ProductId === item.ProductId;
            };
        }) : null;
        const dbssBRIs = item.Details.PricingPlan && item.Details.PricingPlan.PricingPlanBillerRuleInstances ?
            [...item.Details.PricingPlan.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances,
                ...item.Details.PricingPlan.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances] : [];
        const billerRuleAmount = item.Details.PricingPlan && item.Details.PricingPlan.BillerRuleInstanceThumbnails ?
            item.Details.PricingPlan.BillerRuleInstanceThumbnails.find((billerRule) => {
                return billerRule.Amount;
            }) : null;
        const newObject = {
            availabilityDate: getPricingPlanAvailabilityDate(pathOr(null, ['Details', 'PricingPlan', 'AvailabilityStart'], item)), //verifies availability date is in the future
            childItems: item.ChildItems && item.ChildItems.length > 0 ? mapServiceFeatureProductCartItemToViewModel(item.ChildItems) : [],
            discountAmount: getSummedAmounts(dbssBRIs, 'DiscountActualAmount') * item.Quantity,
            grossAmount: getSummedAmounts(dbssBRIs, 'NonDiscountChargeAmount') * item.Quantity,
            totalAmount: item.AdHocOverrideDetails && item.AdHocOverrideDetails.Amount !== undefined ?
                item.AdHocOverrideDetails.Amount :
                billerRuleAmount ?
                    billerRuleAmount.Amount :
                    getSummedAmounts(dbssBRIs, 'ChargeAmount') * item.Quantity,
            name: item.Details.Product.Name,
            pricingPlanName: item.Details.PricingPlan.Name,
            pricingPlanTermsAndConditions: pricingPlanTermsAndConditionsString,
            pricingPlanTermsAndConditionsIsUrl: pricingPlanTermsAndConditionsString && new RegExp(urlRegex).test(pricingPlanTermsAndConditionsString),
            pricingPlanId: item.PricingPlanId,
            adHocBrcName: item.AdHocOverrideDetails ?
                item.AdHocOverrideDetails.InvoiceText || getBriName(item, productsMetadata) :
                getBriName(item, productsMetadata),
            classification: item.Details.Product.ProductClassification,
            productId: item.ProductId,
            instanceProperties: item.InstanceProperties,
            quantity: item.Quantity,
            id: cartItemId,
            index: index,
            isEditable: isEditable,
            isRemovable
        };
        if (isRemoveItem) {
            const removeQuantity = isRemoveItem.Quantity && !Number.isNaN(isRemoveItem.Quantity) ? isRemoveItem.Quantity : 0;
            newObject.customQuantity = isRemoveItem.existingQuantity - removeQuantity;
        }
        if (isAddItem) {
            newObject.customQuantity = isAddItem.Quantity - isAddItem.existingQuantity;
        }
        newObject.itemType = null;
        if (isAddition || isAddItem) {
            newObject.itemType =  CART_ITEM_TYPE.ADDITION;
        }
        if (isRemoveItem) {
            newObject.itemType = CART_ITEM_TYPE.REMOVAL;
        }
        return Object.assign({}, DEFAULT_CART_ITEM_VIEW_MODEL, newObject);
    });
};

const getCartItemForModifyItem = (modifyItem) => {
    if (isNil(modifyItem) || isEmpty(modifyItem)) {
        return null;
    }

    const modifyData = Object.assign({}, {
        ProductId: modifyItem.Product.Id,
        PricingPlanId: modifyItem.PricingPlan.Id,
        Details: modifyItem,
        ChildItems: modifyItem.Children.map((itemChild) => {
            return {
                ProductId: itemChild.Product.Id,
                PricingPlanId: itemChild.PricingPlan.Id,
                Details: itemChild,
                InstanceProperties: itemChild.InstanceProperties,
                Quantity: itemChild.Quantity || 1
            };
        }),
        InstanceProperties: modifyItem.InstanceProperties,
        Quantity: modifyItem.Quantity || 1
    });

    const arr = mapProductCartItemToViewModel([modifyData], {}, null, false);
    return arr && arr.length ? arr[0] : {};
};

export const getPricingPlanAvailabilityDate = (availabilityDate) => {
    return new Date(availabilityDate) > new Date() ? availabilityDate : null;
};

const getPricingPlanTermsAndConditions = (shoppingCartProductsMetadata, productId, pricingPlanId) => {
    if (!shoppingCartProductsMetadata[productId]) {
        return null;
    }

    const selectedPricingPlan = shoppingCartProductsMetadata[productId].PricingPlans.find(propEq(pricingPlanId, 'Id'));
    return pathOr(null, ['TermsAndConditions'], selectedPricingPlan);
};

const mapPascalToCamelCaseKeys = (taxItems) => {
    return taxItems.map((taxItem) => {
        return Object.keys(taxItem).reduce((accum, key) => {
            accum[key.toLowerCase()] = taxItem[key];
            return accum;
        }, {});
    });
};

const mapDiscountsToViewModel = (discountsStringList, discretionaryDiscounts) => {
    const discounts = discountsStringList !== '' ? discountsStringList.split(/,\s?/) : [];
    return discretionaryDiscounts.length ? discounts.concat(pluck('Name', discretionaryDiscounts)) : [];
};

const mapShippingItems = (shippingAmount, selectedShippingMethodId, shippingMethods) => {
    if (isNil(selectedShippingMethodId) || isNil(shippingMethods)) {
        return EMPTY_ARRAY;
    }

    selectedShippingMethodId = Number(selectedShippingMethodId);
    if (isNaN(selectedShippingMethodId)) {
        return EMPTY_ARRAY;
    }

    const selectedMethod = shippingMethods.find((shippingMethod) => {
        return shippingMethod.Id === selectedShippingMethodId;
    });

    if (isNil(selectedMethod)) {
        return EMPTY_ARRAY;
    }

    return [{
        amount: shippingAmount,
        description: selectedMethod.Name
    }];
};

const cartHasSubscriptionItem = (cart) => {
    return !cart.Items ?
        false :
        cart.Items.some((item) => {
            return item.Details.PricingPlan.Subscription;
        });
};

const cartHasShippedItem = (cart) => {
    return !cart.Items ?
        false :
        cart.Items.some((item) => {
            return item.Details.PricingPlan.Shipped;
        });
};

export const CartTab = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (shoppingCartMetadata, shoppingCart, orderQuote, selectedShippingMethodId, modifyCartItem, replaceCartItem, discretionaryDiscounts, isReplacing, isModifying, isRestoring, urlRegex) => {
            if ((shoppingCart.Items && shoppingCart.Items.length) || (modifyCartItem && !isEmpty(modifyCartItem))) {
                const cartItemsAreRemovableInFlow = !isModifying && !isReplacing && !isRestoring;

                if (orderQuote && orderQuote.quoteIsValid) {
                    return {
                        currencyCode: orderQuote.currency || shoppingCart.Currency || DEFAULT_PRODUCT_CART_VIEW_MODEL.currencyCode,
                        brandableCurrencyName: orderQuote.brandableCurrencyName || shoppingCart.BrandableCurrencyName,
                        discountAmount: orderQuote.discountAmount,
                        discounts: mapDiscountsToViewModel(orderQuote.appliedCoupons || shoppingCart.AppliedCoupons || DEFAULT_PRODUCT_CART_VIEW_MODEL.discounts, discretionaryDiscounts),
                        hasSubscriptionQuote: cartHasSubscriptionItem(shoppingCart),
                        items: mapProductCartItemToViewModel(shoppingCart.Items, shoppingCartMetadata, urlRegex, cartItemsAreRemovableInFlow, isReplacing, !isRestoring),
                        modifyItem: isModifying ? getCartItemForModifyItem(modifyCartItem) : null,
                        replaceItem: replaceCartItem,
                        shippingAmount: orderQuote.shippingAmount,
                        shippingItems: mapShippingItems(orderQuote.shippingAmount, selectedShippingMethodId, orderQuote.ShippingMethods),
                        shippingRequired: shoppingCart.ShippingRequired || cartHasShippedItem(shoppingCart),
                        subTotalAmount: orderQuote.subTotal,
                        taxesCalculated: true,
                        taxAmount: orderQuote.taxAmount,
                        taxInclusive: orderQuote.taxInclusive,
                        taxItems: mapPascalToCamelCaseKeys(orderQuote.taxItems || DEFAULT_PRODUCT_CART_VIEW_MODEL.taxItems),
                        totalAmount: orderQuote.totalAmount,
                        totalRemainingAmount: orderQuote.totalRemainingAmount || 0
                    };
                } else {
                    return {
                        currencyCode: shoppingCart.Currency || DEFAULT_PRODUCT_CART_VIEW_MODEL.currencyCode,
                        brandableCurrencyName: shoppingCart.BrandableCurrencyName,
                        discountAmount: shoppingCart.DiscountAmount,
                        discounts: mapDiscountsToViewModel(shoppingCart.AppliedCoupons || DEFAULT_PRODUCT_CART_VIEW_MODEL.discounts, discretionaryDiscounts),
                        hasSubscriptionQuote: false,
                        items: mapProductCartItemToViewModel(shoppingCart.Items, shoppingCartMetadata, urlRegex, cartItemsAreRemovableInFlow, isReplacing, !isRestoring),
                        modifyItem: isModifying ? getCartItemForModifyItem(modifyCartItem) : null,
                        replaceItem: replaceCartItem,
                        shippingAmount: 0,
                        shippingItems: [],
                        shippingRequired: shoppingCart.ShippingRequired,
                        subTotalAmount: shoppingCart.GrossAmount,
                        taxesCalculated: false,
                        taxAmount: 0,
                        taxInclusive: false,
                        taxItems: [],
                        totalAmount: shoppingCart.TotalAmount,
                        totalRemainingAmount: orderQuote.totalRemainingAmount || 0
                    };
                }
            } else if (replaceCartItem) {
                return Object.assign({}, DEFAULT_PRODUCT_CART_VIEW_MODEL, {
                    replaceItem: replaceCartItem
                });
            } else {
                return DEFAULT_PRODUCT_CART_VIEW_MODEL;
            }
        }
    );
};

export const QuoteTab = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (shoppingCartMetadata, shoppingCart, orderQuote, selectedShippingMethodId, modifyCartItem, replaceCartItem, discretionaryDiscounts, isReplacing, isModifying, isRestoring, urlRegex, submitChangeOfServiceForServiceFeatureRequest) => {
            if ((shoppingCart.Items && shoppingCart.Items.length) || (submitChangeOfServiceForServiceFeatureRequest.RemoveItems && submitChangeOfServiceForServiceFeatureRequest.RemoveItems.length)) {
                const cartItemsAreRemovableInFlow = !isModifying && !isReplacing && !isRestoring;
                const uniqueRemoveItemsHash = {};
                if (orderQuote && orderQuote.quoteIsValid) {
                    const returnObj = {
                        currencyCode: orderQuote.currency || shoppingCart.Currency || DEFAULT_PRODUCT_CART_VIEW_MODEL.currencyCode,
                        brandableCurrencyName: orderQuote.brandableCurrencyName || shoppingCart.BrandableCurrencyName,
                        discountAmount: orderQuote.discountAmount,
                        discounts: mapDiscountsToViewModel(orderQuote.appliedCoupons || shoppingCart.AppliedCoupons || DEFAULT_PRODUCT_CART_VIEW_MODEL.discounts, discretionaryDiscounts),
                        hasSubscriptionQuote: cartHasSubscriptionItem(shoppingCart),
                        items: mapServiceFeatureProductCartItemToViewModel(shoppingCart.Items, shoppingCartMetadata, urlRegex, cartItemsAreRemovableInFlow, isReplacing, !isRestoring, clone(submitChangeOfServiceForServiceFeatureRequest)),
                        modifyItem: isModifying ? getCartItemForModifyItem(modifyCartItem) : null,
                        orderQuoteTotals: orderQuote.orderQuoteTotals,
                        replaceItem: replaceCartItem,
                        shippingAmount: orderQuote.shippingAmount,
                        shippingItems: mapShippingItems(orderQuote.shippingAmount, selectedShippingMethodId, orderQuote.ShippingMethods),
                        shippingRequired: shoppingCart.ShippingRequired || cartHasShippedItem(shoppingCart),
                        subTotalAmount: orderQuote.subTotal,
                        taxesCalculated: true,
                        taxAmount: orderQuote.taxAmount,
                        taxInclusive: orderQuote.taxInclusive,
                        taxItems: mapPascalToCamelCaseKeys(orderQuote.taxItems || DEFAULT_PRODUCT_CART_VIEW_MODEL.taxItems),
                        totalAmount: orderQuote.totalAmount,
                        totalRemainingAmount: orderQuote.totalRemainingAmount || 0
                    };
                    const uniqueRemoveItemsHash = {};
                    if (submitChangeOfServiceForServiceFeatureRequest.RemoveItems && submitChangeOfServiceForServiceFeatureRequest.RemoveItems.length) {
                        submitChangeOfServiceForServiceFeatureRequest.RemoveItems.forEach((item) => {
                            if (uniqueRemoveItemsHash[item.PricingPlanId]) {
                                uniqueRemoveItemsHash[item.PricingPlanId].Quantity +=1;
                            } else {
                                uniqueRemoveItemsHash[item.PricingPlanId] = clone(item);
                            }
                        });
                    }
                    const uniqueRemoveItems = [];
                    for (const [, item] of Object.entries(uniqueRemoveItemsHash)) {
                        uniqueRemoveItems.push(item);
                    }
                    uniqueRemoveItems.forEach((removeItem) => {
                        returnObj.items = returnObj.items.concat(mapServiceFeatureProductCartItemToViewModel([removeItem], shoppingCartMetadata, urlRegex, cartItemsAreRemovableInFlow, isReplacing, !isRestoring, clone(submitChangeOfServiceForServiceFeatureRequest), true));

                    });
                    return returnObj;
                } else {
                    const returnObj = {
                        currencyCode: shoppingCart.Currency || DEFAULT_PRODUCT_CART_VIEW_MODEL.currencyCode,
                        brandableCurrencyName: shoppingCart.BrandableCurrencyName,
                        discountAmount: shoppingCart.DiscountAmount,
                        discounts: mapDiscountsToViewModel(shoppingCart.AppliedCoupons || DEFAULT_PRODUCT_CART_VIEW_MODEL.discounts, discretionaryDiscounts),
                        hasSubscriptionQuote: false,
                        items: mapServiceFeatureProductCartItemToViewModel(shoppingCart.Items, shoppingCartMetadata, urlRegex, cartItemsAreRemovableInFlow, isReplacing, !isRestoring, clone(submitChangeOfServiceForServiceFeatureRequest)),
                        modifyItem: isModifying ? getCartItemForModifyItem(modifyCartItem) : null,
                        orderQuoteTotals: orderQuote.orderQuoteTotals,
                        replaceItem: replaceCartItem,
                        shippingAmount: 0,
                        shippingItems: [],
                        shippingRequired: shoppingCart.ShippingRequired,
                        subTotalAmount: shoppingCart.GrossAmount,
                        taxesCalculated: false,
                        taxAmount: 0,
                        taxInclusive: false,
                        taxItems: [],
                        totalAmount: shoppingCart.TotalAmount,
                        totalRemainingAmount: orderQuote.totalRemainingAmount || 0
                    };
                    if (submitChangeOfServiceForServiceFeatureRequest.RemoveItems && submitChangeOfServiceForServiceFeatureRequest.RemoveItems.length) {
                        submitChangeOfServiceForServiceFeatureRequest.RemoveItems.forEach((item) => {
                            if (uniqueRemoveItemsHash[item.PricingPlanId]) {
                                uniqueRemoveItemsHash[item.PricingPlanId].Quantity +=1;
                            } else {
                                uniqueRemoveItemsHash[item.PricingPlanId] = clone(item);
                            }
                        });
                    }
                    const uniqueRemoveItems = [];
                    for (const [, item] of Object.entries(uniqueRemoveItemsHash)) {
                        uniqueRemoveItems.push(item);
                    }
                    uniqueRemoveItems.forEach((removeItem) => {
                        returnObj.items = returnObj.items.concat(mapServiceFeatureProductCartItemToViewModel([removeItem], shoppingCartMetadata, urlRegex, cartItemsAreRemovableInFlow, isReplacing, !isRestoring, clone(submitChangeOfServiceForServiceFeatureRequest), true));

                    });
                    return returnObj;
                }
            } else {
                return DEFAULT_PRODUCT_CART_VIEW_MODEL;
            }
        }
    );
};
