import {
    path,
    pathOr
} from 'ramda';
import {createSelector} from 'reselect';
import {
    SelectedCustomerSelector,
} from './customer.selectors';
import {
    CoreLocaleKeys,
    i18n
} from 'invision-core';
import {
    CurrentRedemptionDetailsSelector
} from './coupon.redemption.code.selectors';
import moment from 'moment';

const recoverableUIStateSelector = (state) => {
    return state.customercare.recoverableUiState.customerCouponsLists;
};

export const CustomerCouponsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(null, ['coupons'], selectedCustomer);
    }
);

export const CustomerCouponsErrorSelector = createSelector(
    [CustomerCouponsSelector],
    (customerCoupons) => {
        return pathOr(null, ['couponsError'], customerCoupons);
    }
);

export const IsFetchingRedemptionCodesSelector = createSelector(
    [CustomerCouponsSelector],
    (customerCoupons) => {
        return pathOr(false, ['isFetchingRedemptionCodes'], customerCoupons);
    }
);

export const IsFetchingCouponRedemptionsSelector = createSelector(
    [CustomerCouponsSelector],
    (customerCoupons) => {
        return pathOr(false, ['isFetchingCouponRedemptions'], customerCoupons);

    }
);

export const IsFetchingGrantableCouponsSelector = createSelector(
    [CustomerCouponsSelector],
    (customerCoupons) => {
        return pathOr(false, ['isFetchingGrantableCoupons'], customerCoupons);

    }
);

export const IsGrantingCouponSelector = createSelector(
    [CustomerCouponsSelector],
    (customerCoupons) => {
        return pathOr(false, ['isGrantingCoupon'], customerCoupons);

    }
);

export const LastGrantedCouponDetailsSelector = createSelector(
    [CustomerCouponsSelector],
    (customerCoupons) => {
        return pathOr(null, ['data', 'lastGrantedCouponDetails'], customerCoupons);
    }
);

export const CustomerCouponRedemptionsSelector = createSelector(
    [CustomerCouponsSelector],
    (customerCoupons) => {
        return pathOr([], ['data', 'couponRedemptions'], customerCoupons);

    }
);

export const CustomerRedemptionCodesSelector = createSelector(
    [CustomerCouponsSelector],
    (customerCoupons) => {
        return pathOr([], ['data', 'redemptionCodes'], customerCoupons);

    }
);

export const GrantableCouponsSelector = createSelector(
    [CustomerCouponsSelector],
    (customerCoupons) => {
        return pathOr([], ['data', 'grantableCoupons'], customerCoupons);
    }
);

export const SelectedCouponSelector = createSelector(
    [CustomerCouponsSelector],
    (customerCoupons) => {
        return pathOr(null, ['data', 'selectedCoupon'], customerCoupons);

    }
);

export const CurrentRedemptionDetailsAssociationCardsDataSelector = createSelector(
    [CurrentRedemptionDetailsSelector],
    (currentRedemptionDetails) => {
        if (path(['Coupon', 'Associations'], currentRedemptionDetails)) {
            return currentRedemptionDetails.Coupon.Associations.map((association) => {
                return {
                    ProductId: association.ProductId,
                    ProductName: association.ProductName,
                    ProductCategoryId: association.ProductCategoryId,
                    ProductCategoryName: association.ProductCategoryName,
                    PricingPlanId: association.PricingPlanId,
                    PricingPlanName: association.PricingPlanName
                };
            });
        } else {
            return [];
        }
    }
);

export const SelectedCouponAssociationCardsDataSelector = createSelector(
    [SelectedCouponSelector],
    (selectedCoupon) => {
        if (selectedCoupon && selectedCoupon.CouponAssociations) {
            return selectedCoupon.CouponAssociations.map((association) => {
                return {
                    ProductId: association.ProductId,
                    ProductName: association.ProductName,
                    ProductCategoryId: association.ProductCategoryId,
                    ProductCategoryName: association.ProductCategoryName,
                    PricingPlanId: association.PricingPlanId,
                    PricingPlanName: association.PricingPlanName
                };
            });
        } else {
            return [];
        }
    }
);

export const GrantCouponsTableDataSelector = createSelector(
    [GrantableCouponsSelector],
    (grantableCoupons) => {
        const tableData = [];
        if (grantableCoupons && grantableCoupons.Coupons) {
            grantableCoupons.Coupons.forEach((coupon) => {
                tableData.push({
                    CouponData: coupon,
                    DateAdded: coupon.Added || i18n.translate(CoreLocaleKeys.NOT_APPLICABLE),
                    Name: coupon.Name || i18n.translate(CoreLocaleKeys.NOT_APPLICABLE),
                    Discount: coupon.DiscountName || i18n.translate(CoreLocaleKeys.NOT_APPLICABLE)
                });
            });
        }
        return tableData;
    }
);

export const GrantCouponsCurrentPageNumberSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return pathOr(0, ['grantCouponsCurrentPageNumber'], uiState);
    }
);

export const GrantCouponsTotalPageNumberSelector = createSelector(
    [GrantableCouponsSelector],
    (grantableCoupons) => {
        return pathOr(0, ['PageCount'], grantableCoupons);
    }
);

export const GrantCouponsRecordCountSelector = createSelector(
    [GrantableCouponsSelector],
    (grantableCoupons) => {
        return pathOr(0, ['RecordCount'], grantableCoupons);
    }
);


export const GrantCouponsNameFilterSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return pathOr(undefined, ['grantCouponsNameFilter'], uiState);
    }
);

export const GrantCouponsShouldShowNoResultsSelector = createSelector(
    [IsFetchingGrantableCouponsSelector, GrantCouponsRecordCountSelector],
    (fetchingCoupons, recordCount) => {
        return !fetchingCoupons && (recordCount < 1);
    }
);

export const GrantCouponsShouldShowEmptyStateSelector = createSelector(
    [GrantCouponsShouldShowNoResultsSelector, GrantCouponsNameFilterSelector],
    (shouldShowNoResults, nameFilter) => {
        return shouldShowNoResults && !nameFilter;
    }
);

export const GrantedCouponsRecordCountSelector = createSelector(
    [CustomerRedemptionCodesSelector],
    (redemptionCodes) => {
        return pathOr(0, ['RecordCount'], redemptionCodes);
    }
);


export const GrantedCouponsCurrentPageNumberSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return pathOr(1, ['grantedCouponsCurrentPageNumber'], uiState);
    }
);

export const GrantedCouponsGrantDateEndFilterSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return pathOr(undefined, ['grantedCouponsGrantDateEndFilter'], uiState);
    }
);

export const GrantedCouponsGrantStartFilterSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return pathOr(undefined, ['grantedCouponsGrantDateStartFilter'], uiState);
    }
);

export const GrantedCouponsNameOrCodeFilterSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return pathOr(undefined, ['grantedCouponsNameOrCodeFilter'], uiState);
    }
);

export const GrantedCouponsRedemptionStatusFilterSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return pathOr(undefined, ['grantedCouponsRedemptionStatusFilter'], uiState);
    }
);

export const GrantCouponsSelectedPageSizePreference = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return pathOr(null, ['grantedCouponsPageSizePreference'], uiState);
    }
);

export const SelectedPageSizePreference = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return pathOr(null, ['pageSizePreference'], uiState);
    }
);

//This selector combines both the redeemed redemption codes with codes that haven't been redeemed yet
export const GrantedCouponsTableDataSelector = createSelector(
    [CustomerCouponRedemptionsSelector, CustomerRedemptionCodesSelector],
    (couponRedemptions, redemptionCodes) => {
        const tableData = [];
        if (redemptionCodes && redemptionCodes.RedemptionCodes) {
            redemptionCodes.RedemptionCodes.forEach((redemptionCodeData) => {
                let latestRedemption = {};
                let orderId = undefined;
                let orderNumber = i18n.translate(CoreLocaleKeys.NOT_APPLICABLE);
                let lastRedeemed = i18n.translate(CoreLocaleKeys.NOT_APPLICABLE);

                if (redemptionCodeData.RedemptionsCount && couponRedemptions && couponRedemptions.CouponRedemptions) {
                    const redemptionInstances = couponRedemptions.CouponRedemptions.filter((redemption) => {
                        return redemption.RedemptionCode === redemptionCodeData.RedemptionCode;
                    }).asMutable();
                    if (redemptionInstances.length > 0) {
                        const sortedRedemptionInstances = redemptionInstances.sort((redemptionA, redemptionB) => {
                            if (moment(redemptionA.RedemptionDate).isBefore(redemptionB.RedemptionDate)) {
                                return 1;
                            } else if (moment(redemptionA.RedemptionDate).isAfter(redemptionB.RedemptionDate)) {
                                return -1;
                            } else {
                                return 0;
                            }
                        });
                        latestRedemption = sortedRedemptionInstances[0];
                        lastRedeemed = latestRedemption.RedemptionDate;
                        orderId = latestRedemption.OrderId;
                        orderNumber = latestRedemption.OrderNumber;
                    }
                }
                const maxRedemptions = pathOr(undefined, ['Coupon', 'SubscriberMaxRedemptions'], latestRedemption);

                tableData.push({
                    DateGranted: redemptionCodeData.Granted || i18n.translate(CoreLocaleKeys.NOT_APPLICABLE),
                    Redemptions: redemptionCodeData.RedemptionsCount || undefined,
                    MaxRedemptions: maxRedemptions,
                    RedemptionCode: redemptionCodeData.RedemptionCode || i18n.translate(CoreLocaleKeys.NOT_APPLICABLE),
                    Coupon: redemptionCodeData.CouponName || i18n.translate(CoreLocaleKeys.NOT_APPLICABLE),
                    RedemptionCodeId: redemptionCodeData.Id,
                    OrderId: orderId,
                    OrderNumber: orderNumber,
                    LastRedeemed: lastRedeemed,
                    ExpirationDate: redemptionCodeData.Expiration || i18n.translate(CoreLocaleKeys.NOT_APPLICABLE)
                });
            });
        }
        return tableData;
    }
);
