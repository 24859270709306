import {
    i18n
} from 'invision-core';
import CustomerCareLocaleKeys from '../../locales/keys';

export const STRUCTURE_TYPES = {
    PRODUCT: 1,
    BUNDLE: 2,
    PICKLIST: 3,
    EPISODIC_BUNDLE: 4,
    SERIES_CONTAINER: 5
};

export const GetProductStructureTypes = () => {
    return [{
        id: STRUCTURE_TYPES.PRODUCT,
        label: i18n.translate(CustomerCareLocaleKeys.PRODUCTS_SEARCH.STRUCTURE_TYPES.A_LA_CARTE)
    }, {
        id: STRUCTURE_TYPES.BUNDLE,
        label: i18n.translate(CustomerCareLocaleKeys.PRODUCTS_SEARCH.STRUCTURE_TYPES.BUNDLE)
    }, {
        id: STRUCTURE_TYPES.EPISODIC_BUNDLE,
        label: i18n.translate(CustomerCareLocaleKeys.PRODUCTS_SEARCH.STRUCTURE_TYPES.EPISODIC_BUNDLE)
    }, {
        id: STRUCTURE_TYPES.SERIES_CONTAINER,
        label: i18n.translate(CustomerCareLocaleKeys.PRODUCTS_SEARCH.STRUCTURE_TYPES.SERIES_CONTAINER)
    }, {
        id: STRUCTURE_TYPES.PICKLIST,
        label: i18n.translate(CustomerCareLocaleKeys.PRODUCTS_SEARCH.STRUCTURE_TYPES.PICK_LIST)
    }];
};
