import Immutable from 'seamless-immutable';
import {
    CALCULATE_COS_ORDER_QUOTE,
    CHANGE_OF_SERVICE_ORDER_REMOVAL,
    RETRIEVE_OFFERING_OFFER_REMOVAL_CONTEXT
} from './actions/offering.remove.actions';

export const INITIAL_STATE = Immutable({
    isCalculatingQuote: false,
    isRetrievingContext: false,
    isSubmittingOrder: false,
    lastAttemptError: null,
    data: {
        contractEnd: null,
        removeItems: [],
        removeItemsTotalAmount: null
    },
    quote: null,
    removeItemRemainingAmounts: [],
    totalRemainingAmount: null
});

export default function removeOfferingReducer(state = INITIAL_STATE, {payload, type}) {
    switch (type) {
        case RETRIEVE_OFFERING_OFFER_REMOVAL_CONTEXT.BEGIN:
            return state.set('isRetrievingContext', true);
        case RETRIEVE_OFFERING_OFFER_REMOVAL_CONTEXT.SUCCESS:
            return state
                .set('isRetrievingContext', false)
                .set('lastAttemptError', null)
                .setIn(['data', 'contractEnd'], payload.ContractEnd)
                .setIn(['data', 'paymentInstrument'], payload.PaymentInstrument)
                .setIn(['data', 'removeItems'], payload.RemoveItems ? payload.RemoveItems : null)
                .setIn(['data', 'removeItemsTotalAmount'], payload.RemoveItemsTotalAmount);
        case RETRIEVE_OFFERING_OFFER_REMOVAL_CONTEXT.FAILURE:
            return state
                .set('isRetrievingContext', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage || payload.Message,
                    severity: payload.Severity
                });
        case CHANGE_OF_SERVICE_ORDER_REMOVAL.BEGIN:
            return state.set('isSubmittingOrder', true);
        case CHANGE_OF_SERVICE_ORDER_REMOVAL.SUCCESS:
            return state
                .set('isSubmittingOrder', false)
                .set('lastAttemptError', null);
        case CHANGE_OF_SERVICE_ORDER_REMOVAL.FAILURE:
            return state
                .set('isSubmittingOrder', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage || payload.Message,
                    severity: payload.Severity
                });
        case CALCULATE_COS_ORDER_QUOTE.BEGIN:
            return state.set('isCalculatingQuote', true);
        case CALCULATE_COS_ORDER_QUOTE.SUCCESS:
            return state
                .set('isCalculatingQuote', false)
                .set('lastAttemptError', null)
                .set('quote', payload.Quote)
                .set('financeCreditLimitValidation', payload.FinanceCreditLimitValidation)
                .set('removeItemRemainingAmounts', payload.RemoveItemRemainingAmounts)
                .set('totalRemainingAmount', payload.TotalRemainingAmount);
        case CALCULATE_COS_ORDER_QUOTE.FAILURE:
            return state
                .set('isCalculatingQuote', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage || payload.Message,
                    severity: payload.Severity
                });
        default:
            return state;
    }
}
