import Immutable from 'seamless-immutable';

export const EMPTY_ARRAY = Immutable([]);
export const EMPTY_OBJECT = Immutable({});
export const FALSE = 'False';
export const IMMUTABLE_EMPTY_ARRAY = Immutable([]);
export const EMPTY_STRING = '';
export const COMMA = ',';
export const COMMA_SPACE = ', ';
export const SEMI_COLON = ';';

