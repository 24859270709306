import ApiConstants from 'invision-core/src/api/constants';
import {
    ThunkHelper
} from 'invision-core';
import {
    GENERAL_STATUSES
} from '../../components/shared/constants/general.status.constants';

export const SET_SELECTED_COUPON_REDEMPTION_CODE = 'SET_SELECTED_COUPON_REDEMPTION_CODE';

export const setSelectedCouponRedemptionCode = (payload) => {
    return {
        type: SET_SELECTED_COUPON_REDEMPTION_CODE,
        payload: payload
    };
};

export const REMOVE_COUPON_REDEMPTION_CODE = {
    BEGIN: 'REMOVE_COUPON_REDEMPTION_CODE_BEGIN',
    SUCCESS: 'REMOVE_COUPON_REDEMPTION_CODE_SUCCESS',
    FAILURE: 'REMOVE_COUPON_REDEMPTION_CODE_FAILURE'
};
const REMOVE_COUPON_REDEMPTION_CODE_EVENTS = [REMOVE_COUPON_REDEMPTION_CODE.BEGIN, REMOVE_COUPON_REDEMPTION_CODE.SUCCESS, REMOVE_COUPON_REDEMPTION_CODE.FAILURE];

const removeCouponRedemptionCodePromise = (dispatch, couponRedemptionCodeId, removeReasonId) => {
    return ThunkHelper(dispatch, REMOVE_COUPON_REDEMPTION_CODE_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/UpdateRedemptionCode',
        data: {
            RedemptionCodeId: couponRedemptionCodeId,
            RedemptionCodeRemoveReason: removeReasonId,
            RedemptionCodeStatus: GENERAL_STATUSES.REMOVED
        }
    });
};

export const removeCouponRedemptionCode = (couponRedemptionCodeId, removeReasonId) => {
    return (dispatch) => {
        return removeCouponRedemptionCodePromise(dispatch, couponRedemptionCodeId, removeReasonId);
    };
};

export const UPDATE_COUPON_REDEMPTION_CODE_EXPIRATION = {
    BEGIN: 'UPDATE_COUPON_REDEMPTION_CODE_EXPIRATION_BEGIN',
    SUCCESS: 'UPDATE_COUPON_REDEMPTION_CODE_EXPIRATION_SUCCESS',
    FAILURE: 'UPDATE_COUPON_REDEMPTION_CODE_EXPIRATION_FAILURE'
};
const UPDATE_COUPON_REDEMPTION_CODE_EXPIRATION_EVENTS = [UPDATE_COUPON_REDEMPTION_CODE_EXPIRATION.BEGIN, UPDATE_COUPON_REDEMPTION_CODE_EXPIRATION.SUCCESS, UPDATE_COUPON_REDEMPTION_CODE_EXPIRATION.FAILURE];

const updateCouponRedemptionCodeExpirationPromise = (dispatch, couponRedemptionCodeId, date, redemptionCodeStatus) => {
    return ThunkHelper(dispatch, UPDATE_COUPON_REDEMPTION_CODE_EXPIRATION_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/UpdateRedemptionCode',
        data: {
            RedemptionCodeId: couponRedemptionCodeId,
            RedemptionCodeExpiration: date,
            RedemptionCodeStatus: redemptionCodeStatus
        }
    });
};

export const updateCouponRedemptionCodeExpiration = (couponRedemptionCodeId, date, redemptionCodeStatus) => {
    return (dispatch) => {
        return updateCouponRedemptionCodeExpirationPromise(dispatch, couponRedemptionCodeId, date, redemptionCodeStatus);
    };
};

export const SET_SELECTED_REASON_ID = 'SET_SELECTED_REASON_ID';

export const setSelectedReasonId = (payload) => {
    return {
        type: SET_SELECTED_REASON_ID,
        payload: payload
    };
};

export const SEARCH_COUPON_DETAILS_COUPON_REDEMPTIONS_CONSTANTS ={
    BEGIN: 'SEARCH_COUPON_DETAILS_COUPON_REDEMPTIONS_BEGIN',
    SUCCESS: 'SEARCH_COUPON_DETAILS_COUPON_REDEMPTIONS_SUCCESS',
    FAILURE: 'SEARCH_COUPON_DETAILS_COUPON_REDEMPTIONS_FAILURE'
};

const SEARCH_COUPON_DETAILS_COUPON_REDEMPTIONS_EVENTS = [SEARCH_COUPON_DETAILS_COUPON_REDEMPTIONS_CONSTANTS.BEGIN, SEARCH_COUPON_DETAILS_COUPON_REDEMPTIONS_CONSTANTS.SUCCESS, SEARCH_COUPON_DETAILS_COUPON_REDEMPTIONS_CONSTANTS.FAILURE];

const searchCouponDetailsCouponRedemptionsPromise = (dispatch, customerId, filter = {}, languageHeader) => {
    const data = {
        CouponRedemptionCodeId: filter.couponRedemptionCodeId,
        End: filter.endDate,
        PageSize: filter.pageSize,
        PageNumber: filter.pageNumber,
        Start: filter.startDate
    };

    return ThunkHelper(dispatch, SEARCH_COUPON_DETAILS_COUPON_REDEMPTIONS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SearchCouponRedemptions',
        data: data,
        headers: {
            [ApiConstants.CUSTOM_SUBSCRIBER_LANGUAGE_HEADER]: languageHeader,
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId
    });
};

export const searchCouponDetailsCouponRedemptions = (customerId, filter, languageHeader) => {
    return (dispatch) => {
        return searchCouponDetailsCouponRedemptionsPromise(dispatch, customerId, filter, languageHeader);
    };
};

export const SET_REDEMPTION_CODE_REDEMPTIONS_CURRENT_PAGE = 'SET_REDEMPTION_CODE_REDEMPTIONS_CURRENT_PAGE';
export const setRedemptionCodeRedemptionsCurrentPage = (page) => {
    return {
        type: SET_REDEMPTION_CODE_REDEMPTIONS_CURRENT_PAGE,
        payload: page
    };
};

export const SET_REDMEPTION_CODE_REDEMPTIONS_DATE_END_FILTER = 'SET_REDMEPTION_CODE_REDEMPTIONS_DATE_END_FILTER';
export const setRedemptionCodeRedemptionsDateEndFilter = (date) => {
    return {
        type: SET_REDMEPTION_CODE_REDEMPTIONS_DATE_END_FILTER,
        payload: date
    };
};

export const SET_REDMEPTION_CODE_REDEMPTIONS_DATE_START_FILTER = 'SET_REDMEPTION_CODE_REDEMPTIONS_DATE_START_FILTER';
export const setRedemptionCodeRedemptionsDateStartFilter = (date) => {
    return {
        type: SET_REDMEPTION_CODE_REDEMPTIONS_DATE_START_FILTER,
        payload: date
    };
};

export const SET_REDEMPTION_COUPONS_PAGE_SIZE_PREFERENCE = 'SET_REDEMPTION_COUPONS_PAGE_SIZE_PREFERENCE';
export const setRedemptionCodeRedemptionsPageSizePreference = (pageSize) => {
    return {
        type: SET_REDEMPTION_COUPONS_PAGE_SIZE_PREFERENCE,
        payload: pageSize
    };
};
