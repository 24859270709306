import {ThunkHelper} from 'invision-core';
import {SUBSCRIBER_ID_HEADER} from 'invision-core/src/api/constants';
import {values} from 'ramda';

export const SET_SELECTED_POLICY_COUNTER = 'SET_SELECTED_POLICY_COUNTER';
export const setSelectedPolicyCounter = (policyCounter) => {
    return {
        type: SET_SELECTED_POLICY_COUNTER,
        payload: policyCounter
    };
};

export const UPDATE_POLICY_COUNTER_BALANCE = {
    BEGIN: 'UPDATE_POLICY_COUNTER_BALANCE_BEGIN',
    SUCCESS: 'UPDATE_POLICY_COUNTER_BALANCE_SUCCESS',
    FAILURE: 'UPDATE_POLICY_COUNTER_BALANCE_FAILURE'
};

export const updatePolicyCounterBalance = (requestData, customerId) => {
    return (dispatch) => {
        return ThunkHelper(dispatch, values(UPDATE_POLICY_COUNTER_BALANCE), {
            method: 'post',
            headers: {
                [SUBSCRIBER_ID_HEADER]: customerId
            },
            url: 'SubscriberManagement/UpdatePolicyCounterBalance',
            data: requestData
        });
    };
};