import Immutable from 'seamless-immutable';
import {
    RETRIEVE_CUSTOMER,
    UPDATE_SUBSCRIBER
} from './actions/customer.actions';
import {
    HIDE_RECENT_CUSTOMERS_PANEL,
    SHOW_RECENT_CUSTOMERS_PANEL
} from './actions/recent.customers.actions';
import {
    SHOW_SEARCH_PANEL
} from './actions/search.actions';

export const INITIAL_STATE = Immutable({
    data: {
        customers: []
    },
    isPanelVisible: false
});

// Per FSD, the maximum number of customers in the recent customers list will be 15
export const MAXIMUM_RECENT_CUSTOMER_SIZE = 15;

export function isCustomerInRecentList(existingCustomers, customer) {
    return !!(existingCustomers && customer && existingCustomers.find((existingCustomer) => {
        return existingCustomer.Id === customer.Id;
    }));
}

export default function recentCustomersReducer(state = INITIAL_STATE, {payload, type}) {
    let customersClone;

    switch (type) {
        case RETRIEVE_CUSTOMER.SUCCESS:
            customersClone = state.data.customers.asMutable();

            if (isCustomerInRecentList(state.data.customers, payload.Subscriber)) {
                // remove the customer from the existing array
                customersClone = customersClone.filter((existingCustomer) => {
                    return existingCustomer.Id !== payload.Subscriber.Id;
                });
            }

            if (customersClone.length >= MAXIMUM_RECENT_CUSTOMER_SIZE) {
                customersClone = customersClone.slice(0, MAXIMUM_RECENT_CUSTOMER_SIZE - 1);
            }

            // push the new customer to the front of the customers list
            customersClone.unshift(payload.Subscriber);

            return state.setIn(['data', 'customers'], customersClone);
        case UPDATE_SUBSCRIBER.SUCCESS:
            if (isCustomerInRecentList(state.data.customers, payload.Subscriber)) {
                customersClone = state.data.customers.asMutable();

                // remove the customer from the existing array
                customersClone = customersClone.filter((existingCustomer) => {
                    return existingCustomer.Id !== payload.Subscriber.Id;
                });

                // push the new customer to the front of the customers list
                customersClone.unshift(payload.Subscriber);

                return state.setIn(['data', 'customers'], customersClone);
            } else {
                // if for some reason the customer that was updated wasn't in the recent list...we ignore.  "Shouldn't" be possible.
                return state;
            }
        case SHOW_RECENT_CUSTOMERS_PANEL:
            return state.merge({
                isPanelVisible: true
            });
        case HIDE_RECENT_CUSTOMERS_PANEL:
            return state.merge({
                isPanelVisible: false
            });
        case SHOW_SEARCH_PANEL:
            return state.merge({
                isPanelVisible: false
            });
        default:
            return state;
    }
}
