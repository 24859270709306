import i18n from 'invision-core/src/components/i18n/i18n';
import flatten from 'ramda/src/flatten';
import pluck from 'ramda/src/pluck';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {
    MetadataCodeTypeDictionarySelector,
    MetadataCodeTypeSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {ShippingMethodsSelector} from 'invision-core/src/components/metadata/shippingMethods/shipping.methods.selectors';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {BILLER_RULE_INSTANCE_TYPE} from '../../customercare.constants';
import {
    CHARGE_TIMING,
    FULL_CART_COLUMNS,
    OFFER_ORDER_QUOTE_GROUPS
} from '../constants/wizard.constants';
import {
    EMPTY_ARRAY,
    EMPTY_OBJECT
} from '../constants/common.constants';

import {OfferingOrderQuoteSelector} from './offering.order.selectors';
import {
    ExistingItemsPlaceholderName,
    ExistingServicesPlaceholderName,
    getChargeAmount,
    getItemQuote,
    GUIDServiceIdentifierValue,
    reorderFullCartItems
} from '../helpers/full.cart.selectors.helpers';
import CustomerCareLocale from './../../locales/keys';
import {getFormattedServiceAttributeValue} from './services.list.selectors.helper';

const TaxQuoteSelector = createImmutableSelector(
    [OfferingOrderQuoteSelector],
    (offeringOrderQuote) => {
        return offeringOrderQuote.TaxQuote || EMPTY_OBJECT;
    }
);

const FeeQuoteSelector = createImmutableSelector(
    [OfferingOrderQuoteSelector],
    (offeringOrderQuote) => {
        return offeringOrderQuote.FeeQuote || EMPTY_OBJECT;
    }
);


const OfferingQuotesSelector = createImmutableSelector(
    [OfferingOrderQuoteSelector],
    (offeringOrderQuote) => {
        return offeringOrderQuote.OfferingQuotes || EMPTY_ARRAY;
    }
);

const OfferingOrderQuoteTotalsSelector = createImmutableSelector(
    [OfferingOrderQuoteSelector],
    (offeringOrderQuote) => {
        return offeringOrderQuote.OfferingOrderQuoteTotals || EMPTY_ARRAY;
    }
);

const BillerRuleConfigurationsSelector = createImmutableSelector(
    [
        MetadataCodeTypeSelector(CODES.CustomBillerRuleConfiguration),
        MetadataCodeTypeSelector(CODES.EntitlementBillerRuleConfiguration),
        MetadataCodeTypeSelector(CODES.FinanceBillerRuleConfiguration),
        MetadataCodeTypeSelector(CODES.OneTimeBillerRuleConfiguration),
        MetadataCodeTypeSelector(CODES.RecurringBillerRuleConfiguration),
        MetadataCodeTypeSelector(CODES.UsageBillerRuleConfiguration)
    ],
    (customerBrcs, entitlementBrcs, financeBrcs, OneTimeBrcs, recurringBrcs, usageBrcs) => {
        return {
            [BILLER_RULE_INSTANCE_TYPE.CUSTOM]: customerBrcs,
            [BILLER_RULE_INSTANCE_TYPE.ENTITLEMENT]: entitlementBrcs,
            [BILLER_RULE_INSTANCE_TYPE.FINANCE]: financeBrcs,
            [BILLER_RULE_INSTANCE_TYPE.ONETIME]: OneTimeBrcs,
            [BILLER_RULE_INSTANCE_TYPE.RECURRING]: recurringBrcs,
            [BILLER_RULE_INSTANCE_TYPE.USAGE]: usageBrcs,
        };
    }
);

export const FullCartOfferingOrderQuoteTotalSelector = createImmutableSelector(
    [OfferingOrderQuoteTotalsSelector],
    (offeringOrderQuoteTotals) => {
        return {
            dueNextInvoice: getChargeAmount(CHARGE_TIMING.BILL_CYCLE, offeringOrderQuoteTotals),
            dueOnActivation: getChargeAmount(CHARGE_TIMING.DUE_ON_ACTIVATION, offeringOrderQuoteTotals),
            dueOnFirstUse: getChargeAmount(CHARGE_TIMING.DUE_ON_FIRST_USE, offeringOrderQuoteTotals),
            dueRecurring: getChargeAmount(CHARGE_TIMING.DUE_RECURRING, offeringOrderQuoteTotals),
            dueToday: getChargeAmount(CHARGE_TIMING.DUE_TODAY, offeringOrderQuoteTotals),
        };
    }
);

export const FullCartOfferingOrderTaxQuoteSelector = createImmutableSelector(
    [TaxQuoteSelector],
    (taxQuote) => {
        return {
            dueToday: getChargeAmount(CHARGE_TIMING.DUE_TODAY, taxQuote.TaxQuoteTotals),
            dueNextInvoice: getChargeAmount(CHARGE_TIMING.BILL_CYCLE, taxQuote.TaxQuoteTotals),
            dueOnActivation: getChargeAmount(CHARGE_TIMING.DUE_ON_ACTIVATION, taxQuote.TaxQuoteTotals),
            dueOnFirstUse: getChargeAmount(CHARGE_TIMING.DUE_ON_FIRST_USE, taxQuote.TaxQuoteTotals),
            dueRecurring: getChargeAmount(CHARGE_TIMING.DUE_RECURRING, taxQuote.TaxQuoteTotals),
            taxItemQuotes: (taxQuote.TaxItemQuotes || []).map((taxItemQuote) => {
                return {
                    name: taxItemQuote.Name,
                    dueToday: getChargeAmount(CHARGE_TIMING.DUE_TODAY, taxItemQuote.TaxItemQuoteTotals),
                    dueNextInvoice: getChargeAmount(CHARGE_TIMING.BILL_CYCLE, taxItemQuote.TaxItemQuoteTotals),
                    dueRecurring: getChargeAmount(CHARGE_TIMING.DUE_RECURRING, taxItemQuote.TaxItemQuoteTotals),
                    dueOnActivation: getChargeAmount(CHARGE_TIMING.DUE_ON_ACTIVATION, taxQuote.TaxQuoteTotals),
                    dueOnFirstUse: getChargeAmount(CHARGE_TIMING.DUE_ON_FIRST_USE, taxQuote.TaxQuoteTotals),
                };
            })
        };
    }
);

export const FullCartOfferingOrderFeeQuoteSelector = createImmutableSelector(
    [FeeQuoteSelector],
    (feeQuote) => {
        return {
            dueToday: getChargeAmount(CHARGE_TIMING.DUE_TODAY, feeQuote.FeeQuoteTotals),
            dueNextInvoice: getChargeAmount(CHARGE_TIMING.BILL_CYCLE, feeQuote.FeeQuoteTotals),
            dueOnActivation: getChargeAmount(CHARGE_TIMING.DUE_ON_ACTIVATION, feeQuote.FeeQuoteTotals),
            dueOnFirstUse: getChargeAmount(CHARGE_TIMING.DUE_ON_FIRST_USE, feeQuote.FeeQuoteTotals),
            dueRecurring: getChargeAmount(CHARGE_TIMING.DUE_RECURRING, feeQuote.FeeQuoteTotals),
            feeItemQuotes: (feeQuote.FeeItemQuotes || []).map((feeItemQuote) => {
                return {
                    name: feeItemQuote.Name,
                    dueToday: getChargeAmount(CHARGE_TIMING.DUE_TODAY, feeItemQuote.FeeItemQuoteTotals),
                    dueNextInvoice: getChargeAmount(CHARGE_TIMING.BILL_CYCLE, feeItemQuote.FeeItemQuoteTotals),
                    dueRecurring: getChargeAmount(CHARGE_TIMING.DUE_RECURRING, feeItemQuote.FeeItemQuoteTotals),
                    dueOnActivation: getChargeAmount(CHARGE_TIMING.DUE_ON_ACTIVATION, feeItemQuote.FeeItemQuoteTotals),
                    dueOnFirstUse: getChargeAmount(CHARGE_TIMING.DUE_ON_FIRST_USE, feeItemQuote.FeeItemQuoteTotals),
                };
            })
        };
    }
);

export const FullCartOfferingOrderQuotesSelector = createImmutableSelector(
    [
        OfferingQuotesSelector,
        BillerRuleConfigurationsSelector,
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression)
    ],
    (offeringQuotes, billerRuleConfigurations, serviceAttributes, regularExpressions) => {
        const fullCartItems =  [...offeringQuotes].sort(
            (offer1, offer2) => {
                return offer1.OfferingInstanceId - offer2.OfferingInstanceId;
            }).map((OfferingQuote) => {

            let additionalItems = [];
            if (OfferingQuote.ServiceQuotes) {
                const otherServices = OfferingQuote.ServiceQuotes.filter(serviceQuote => {
                    return serviceQuote.ServiceIdentifierValue === GUIDServiceIdentifierValue;
                });
                additionalItems = flatten(pluck('ItemQuotes', otherServices)).map(itemQuote => {
                    return getItemQuote(itemQuote, billerRuleConfigurations);
                }).filter(item => {
                    return item;
                });
            }

            const serviceQuotesWithoutOtherServices = (OfferingQuote.ServiceQuotes || []).filter((quote) => {
                return quote.ServiceIdentifierValue && quote.ServiceIdentifierValue !== GUIDServiceIdentifierValue;
            });

            const serviceQuotesWithoutExistingServices = (OfferingQuote.ServiceQuotes || []).filter((service) => {
                return (service.ServiceIdentifierValue && service.ServiceIdentifierValue !== ExistingServicesPlaceholderName)
                        || !service.ServiceIdentifierValue;
            });

            const serviceQuotes = serviceQuotesWithoutExistingServices.filter((ServiceQuote) => {
                return ServiceQuote.ServiceIdentifierValue &&
                        ServiceQuote.ServiceIdentifierValue !== GUIDServiceIdentifierValue
                        || serviceQuotesWithoutExistingServices.length === 1;
            }).map((ServiceQuote) => {
                const firstItemWithServiceIdentifier = (OfferingQuote.ServiceQuotes || []).map(serviceQuote => {
                    return serviceQuote.ItemQuotes.find(ItemQuote => {
                        return ItemQuote.ParentServiceIdentifier && ItemQuote.ParentServiceIdentifier.Value === ServiceQuote.ServiceIdentifierValue;
                    });
                }).find(item => {
                    return item;
                });
                const itemQuotes = ServiceQuote.ItemQuotes.length ? ServiceQuote.ItemQuotes.map((ItemQuote) => {
                    return getItemQuote(ItemQuote, billerRuleConfigurations);
                }) : [];
                return {
                    serviceIdentifierValue: firstItemWithServiceIdentifier &&
                            getFormattedServiceAttributeValue(
                                firstItemWithServiceIdentifier.ParentServiceIdentifier.ServiceAttributeId,
                                firstItemWithServiceIdentifier.ParentServiceIdentifier.Value,
                                serviceAttributes,
                                regularExpressions),
                    dueToday: getChargeAmount(CHARGE_TIMING.DUE_TODAY, ServiceQuote.Totals),
                    dueNextInvoice: getChargeAmount(CHARGE_TIMING.BILL_CYCLE, ServiceQuote.Totals),
                    dueRecurring: getChargeAmount(CHARGE_TIMING.DUE_RECURRING, ServiceQuote.Totals),
                    dueOnActivation: getChargeAmount(CHARGE_TIMING.DUE_ON_ACTIVATION, ServiceQuote.Totals),
                    dueOnFirstUse: getChargeAmount(CHARGE_TIMING.DUE_ON_FIRST_USE, ServiceQuote.Totals),
                    itemQuotes: reorderFullCartItems(itemQuotes)
                };
            }).filter((serviceQuote) => {
                return serviceQuote.itemQuotes.length;
            });

            const isExistingOfferPlaceHolder = OfferingQuote.OfferName === ExistingItemsPlaceholderName;

            return {
                offerName: isExistingOfferPlaceHolder ? i18n.translate(CustomerCareLocale.FULL_CART.EXISTING_ITEMS) : OfferingQuote.OfferName,
                isPlaceHolderForExistingItems: isExistingOfferPlaceHolder,
                offeringInstanceId: OfferingQuote.OfferingInstanceId,
                offeringOrderQuoteGroup: OfferingQuote.OfferingOrderQuoteGroup,
                dueToday: getChargeAmount(CHARGE_TIMING.DUE_TODAY, OfferingQuote.OfferingQuoteTotals),
                dueNextInvoice: getChargeAmount(CHARGE_TIMING.BILL_CYCLE, OfferingQuote.OfferingQuoteTotals),
                dueRecurring: getChargeAmount(CHARGE_TIMING.DUE_RECURRING, OfferingQuote.OfferingQuoteTotals),
                dueOnActivation: getChargeAmount(CHARGE_TIMING.DUE_ON_ACTIVATION, OfferingQuote.OfferingQuoteTotals),
                dueOnFirstUse: getChargeAmount(CHARGE_TIMING.DUE_ON_FIRST_USE, OfferingQuote.OfferingQuoteTotals),
                servicesCount: serviceQuotes.length,
                serviceQuotes: additionalItems.length && serviceQuotesWithoutOtherServices.length ? [...serviceQuotes, {
                    isAdditionalProductsSection: true,
                    itemQuotes: additionalItems
                }] : serviceQuotes
            };
        });

        const currentItems = fullCartItems.filter(item => {
            return item.offeringOrderQuoteGroup === OFFER_ORDER_QUOTE_GROUPS.CURRENT;
        });
        const existingItemHeader = fullCartItems.filter(item => {
            return item.offeringOrderQuoteGroup === OFFER_ORDER_QUOTE_GROUPS.EXISTING && item.isPlaceHolderForExistingItems;
        });
        const existingItems = fullCartItems.filter(item => {
            return item.offeringOrderQuoteGroup === OFFER_ORDER_QUOTE_GROUPS.EXISTING && !item.isPlaceHolderForExistingItems;
        });

        return [...currentItems, ...existingItemHeader, ...existingItems];
    }
);



export const ShowHideFullCartColumnsSelector = createImmutableSelector(
    [FullCartOfferingOrderQuoteTotalSelector, FullCartOfferingOrderTaxQuoteSelector, FullCartOfferingOrderQuotesSelector],
    (offeringOrderQuoteTotal, offeringOrderTaxQuote, offeringOrderQuotes) => {
        const result = {
            showDueToday: false,
            showDueNextInvoice: false,
            showDueRecurring: false,
            showDueOnActivation: false,
            showDueOnFirstUse: false,
        };
        result.showDueToday = getColumnShowHideStatus(offeringOrderQuoteTotal, offeringOrderTaxQuote, offeringOrderQuotes, FULL_CART_COLUMNS.DUE_TODAY);
        result.showDueNextInvoice = getColumnShowHideStatus(offeringOrderQuoteTotal, offeringOrderTaxQuote, offeringOrderQuotes, FULL_CART_COLUMNS.DUE_NEXT_INVOICE);
        result.showDueRecurring = getColumnShowHideStatus(offeringOrderQuoteTotal, offeringOrderTaxQuote, offeringOrderQuotes, FULL_CART_COLUMNS.DUE_RECURRING);
        result.showDueOnActivation = getColumnShowHideStatus(offeringOrderQuoteTotal, offeringOrderTaxQuote, offeringOrderQuotes, FULL_CART_COLUMNS.DUE_ON_ACTIVATION);
        result.showDueOnFirstUse = getColumnShowHideStatus(offeringOrderQuoteTotal, offeringOrderTaxQuote, offeringOrderQuotes, FULL_CART_COLUMNS.DUE_ON_FIRST_USE);
        return result;
    }
);

function getColumnShowHideStatus(offeringOrderQuoteTotal, offeringOrderTaxQuote, offeringOrderQuotes, key) {
    if (offeringOrderQuoteTotal[key] || offeringOrderQuoteTotal[key] === 0
        || offeringOrderTaxQuote[key] || offeringOrderTaxQuote[key] === 0) {
        return true;
    }
    return offeringOrderQuotes.some(offeringOrderQuotesItem => {
        if (offeringOrderQuotesItem[key] || offeringOrderQuotesItem[key] === 0) {
            return true;
        } else {
            if (offeringOrderQuotesItem.serviceQuotes && offeringOrderQuotesItem.serviceQuotes.length) {
                return offeringOrderQuotesItem.serviceQuotes.some(serviceQuote => {
                    if (serviceQuote[key] || serviceQuote[key] === 0) {
                        return true;
                    } else {
                        return serviceQuote.itemQuotes.some(itemQuote => {
                            if (itemQuote.chargeQuotes && itemQuote.chargeQuotes.length) {
                                return itemQuote.chargeQuotes.some(chargeQuote => {
                                    if (chargeQuote[key] || chargeQuote[key] === 0) {
                                        return true;
                                    }
                                });
                            }
                        });
                    }
                });
            }
        }
    });
}

const ShippingQuoteSelector = createImmutableSelector(
    [OfferingOrderQuoteSelector],
    (offeringOrderQuote) => {
        return offeringOrderQuote.ShippingQuote || EMPTY_OBJECT;
    }
);

const getMethodName = (methodId, shippingMethods) => {
    const selectedMethod = shippingMethods.find((method) => {
        return method.Id === convertStringToNumber(methodId);
    });
    return selectedMethod.Name;
};

export const FullCartOfferingOrderShippingQuoteSelector = createImmutableSelector(
    [
        ShippingQuoteSelector,
        ShippingMethodsSelector
    ],
    (shippingQuote, shippingMethods) => {
        return {
            dueToday: getChargeAmount(CHARGE_TIMING.DUE_TODAY, shippingQuote.ShippingQuoteTotals),
            //Shipping will only have due today charge, the following properties are added to align the full cart modal.
            dueNextInvoice: null,
            dueOnActivation: null,
            dueOnFirstUse: null,
            dueRecurring: null,
            shippingItemQuotes: (shippingQuote.ShippingItemQuotes || []).map((item) => {
                return {
                    name: getMethodName(item.Name, shippingMethods),
                    dueToday: getChargeAmount(CHARGE_TIMING.DUE_TODAY, item.ShippingItemQuoteTotals),
                    dueNextInvoice: null,
                    dueRecurring: null,
                    dueOnActivation: null,
                    dueOnFirstUse: null,
                };
            })
        };
    }
);
