import CustomerCareKeys from '../../locales/keys';

export const ALL_STEPS_KEYS = [
    CustomerCareKeys.WIZARD.STEPS.OFFERS,
    CustomerCareKeys.WIZARD.STEPS.DECISIONS,
    CustomerCareKeys.WIZARD.STEPS.ATTRIBUTES,
    CustomerCareKeys.WIZARD.STEPS.CHECKOUT,
];

export const STATE_OR_NAME = 'index.customercare.customer.orders.addservicesorder';

export const GROUP_TEMPLATE_ID = 'group_template';
