import Immutable from 'seamless-immutable';
import {RESET_CUSTOMER_RECOVERABLE} from './../actions/customer.actions';
import * as CasesActions from './../actions/customer.cases.actions';

export const INITIAL_STATE = Immutable({
    filterData: {
        includeStatusFilter: [],
        pageSizePreference: null
    },
    pageNumber: 1
});

export default function CasesReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case CasesActions.SET_CURRENT_PAGE:
            return state.set('pageNumber', payload);
        case CasesActions.SET_CASE_STATUS_FILTER:
            return state.setIn(['filterData', 'includeStatusFilter'], payload);
        case CasesActions.SET_CASE_PAGE_SIZE_PREFERENCE:
            return state.setIn(['filterData', 'pageSizePreference'], payload);
        case RESET_CUSTOMER_RECOVERABLE:
        case CasesActions.REMOVE_CASES_FILTER:
            return INITIAL_STATE;
        default:
            return state;
    }
}
