import Immutable from 'seamless-immutable';
import {RESET_CUSTOMER_RECOVERABLE} from './../actions/customer.actions';
import * as AddressesActions from './../actions/customer.addresses.actions';

export const INITIAL_STATE = Immutable({
    filterData: {
        includeRemoved: false
    },
    selectedItemId: null
});

export default function AddressesReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case AddressesActions.SET_INCLUDED_REMOVED_FILTER:
            return state.setIn(['filterData', 'includeRemoved'], payload);
        case AddressesActions.SET_SELECTED_ITEM_ID:
            return state.set('selectedItemId', payload);
        case RESET_CUSTOMER_RECOVERABLE:
        case AddressesActions.REMOVE_ADDRESSES_FILTER:
            return INITIAL_STATE;
        default:
            return state;
    }
}
