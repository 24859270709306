import Immutable from 'seamless-immutable';
import {RESET_CUSTOMER_RECOVERABLE} from '../actions/customer.actions';
import * as DiscountActions from '../actions/discount.actions';

export const INITIAL_STATE = Immutable({
    discountForm: {
        selectedDiscounts: null,
        discountReason: null
    }
});

export default function DiscountReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case DiscountActions.CLEAR_DISCOUNT_FORM:
        case DiscountActions.SET_DISCOUNT_FORM:
            return state.set('discountForm', payload);
        case RESET_CUSTOMER_RECOVERABLE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
