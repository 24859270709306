import {
    MetadataConstants,
    MetadataSelectors
} from 'invision-core';
import {createSelector} from 'reselect';
import {
    parseAdditionalProperties
} from '../helpers/payment.instrument.additional.property.parser';
import {
    InvisionConfigurationAdditionalProperties,
    invisionConfigurationAdditionalPropertyDescriptors
} from '../helpers/configuration.invision.additional.property.parser';

export const InvisionConfigurationAdditionalPropertiesSelector = createSelector(
    [
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.InvisionConfiguration)
    ],
    (invisionConfiguration) => {
        if (invisionConfiguration.length) {
            return (invisionConfiguration || []).reduce((lookup, paymentType) => {
                const model = new InvisionConfigurationAdditionalProperties();
                return parseAdditionalProperties(model, paymentType.AdditionalProperties, invisionConfigurationAdditionalPropertyDescriptors);
            }, {});
        } else {
            return new InvisionConfigurationAdditionalProperties();
        }

    }
);
