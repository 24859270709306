import Immutable from 'seamless-immutable';
import {SET_SELECTED_REASON_ID} from './actions/blocklist.allowlist.actions';

export const INITIAL_STATE = Immutable({
    selectedReasonId: null
});

export default function blocklistAllowlistReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case SET_SELECTED_REASON_ID:
            return state.set('selectedReasonId', payload);
        default:
            return state;
    }
}
