import Immutable from 'seamless-immutable';
import {RESET_CUSTOMER_RECOVERABLE} from './../actions/customer.actions';
import * as EwalletActions from './../actions/customer.ewallet.actions';

export const INITIAL_STATE = Immutable({
    selectedTypeId: null,
    filterData: {
        includeRemoved: false,
        selectedItemId: null
    }
});

export default function EwalletReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case EwalletActions.SET_INCLUDED_REMOVED_FILTER:
            return state.setIn(['filterData', 'includeRemoved'], payload);
        case EwalletActions.SET_SELECTED_ITEM_ID:
            return state.setIn(['filterData', 'selectedItemId'], payload);
        case EwalletActions.CLEAR_SELECTED_ITEM_ID:
            return state.setIn(['filterData', 'selectedItemId'], null);
        case EwalletActions.SET_SELECTED_TYPE_ID:
            return state.set(['selectedTypeId'], payload);
        case EwalletActions.CLEAR_SELECTED_TYPE_ID:
            return state.set(['selectedTypeId'], null);
        case EwalletActions.CREATE_PAYMENT_INSTRUMENT.SUCCESS:
            return state.setIn(['filterData', 'selectedItemId'], payload.PaymentInstrument.Id);
        case RESET_CUSTOMER_RECOVERABLE:
        case EwalletActions.REMOVE_EWALLET_FILTER:
            return INITIAL_STATE;
        default:
            return state;
    }
}
