import {values} from 'ramda';
import {
    ApiConstants,
    ThunkHelper
} from 'invision-core';

export const RETRIEVE_CANDIDATE_BILL_CYCLES = {
    BEGIN: 'RETRIEVE_CANDIDATE_BILL_CYCLES_BEGIN',
    SUCCESS: 'RETRIEVE_CANDIDATE_BILL_CYCLES_SUCCESS',
    FAILURE: 'RETRIEVE_CANDIDATE_BILL_CYCLES_FAILURE'
};

const retrieveCandidateBillCyclesPromise = (dispatch, customerId, orderType) => {
    return ThunkHelper(dispatch, values(RETRIEVE_CANDIDATE_BILL_CYCLES), {
        method: 'post',
        url: 'SubscriberManagement/EvaluateBillCycleRules',
        data: {
            OrderType: orderType
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId
    });
};

export const retrieveCandidateBillCycles = (customerId, orderType) => {
    return (dispatch) => {
        return retrieveCandidateBillCyclesPromise(dispatch, customerId, orderType);
    };
};

export const RETRIEVE_BILL_CYCLE_DETAILS = {
    BEGIN: 'RETRIEVE_BILL_CYCLE_DETAILS_BEGIN',
    SUCCESS: 'RETRIEVE_BILL_CYCLE_DETAILS_SUCCESS',
    FAILURE: 'RETRIEVE_BILL_CYCLE_DETAILS_FAILURE'
};

const retrieveBillCycleDetailsPromise = (dispatch, billCycleId) => {
    return ThunkHelper(dispatch, values(RETRIEVE_BILL_CYCLE_DETAILS), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveConvergentBillerBillCycleDetailsById',
        data: {
            Id: billCycleId
        }
    });
};
export const retrieveBillCycleDetails = (billCycleId) => {
    return (dispatch) => {
        return retrieveBillCycleDetailsPromise(dispatch, billCycleId);
    };
};


export const RETRIEVE_NEXT_BILL_CYCLE_DETAILS = {
    BEGIN: 'RETRIEVE_NEXT_BILL_CYCLE_DETAILS_BEGIN',
    SUCCESS: 'RETRIEVE_NEXT_BILL_CYCLE_DETAILS_SUCCESS',
    FAILURE: 'RETRIEVE_NEXT_BILL_CYCLE_DETAILS_FAILURE'
};

const retrieveNextBillCycleDetailsPromise = (dispatch, billCycleId) => {
    return ThunkHelper(dispatch, values(RETRIEVE_NEXT_BILL_CYCLE_DETAILS), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveConvergentBillerBillCycleDetailsById',
        data: {
            Id: billCycleId
        }
    });
};
export const retrieveNextBillCycleDetails = (billCycleId) => {
    return (dispatch) => {
        return retrieveNextBillCycleDetailsPromise(dispatch, billCycleId);
    };
};
