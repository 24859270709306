import curry from 'ramda/src/curry';
import clone from 'ramda/src/clone';
import compose from 'ramda/src/compose';
import Immutable from 'seamless-immutable';
import {
    CALCULATE_COS_ORDER_QUOTE,
    CALCULATE_OFFERING_ORDER_QUOTE,
    CALCULATE_ORDER_QUOTE,
    CLEAR_OFFERING_ORDER_QUOTE,
    CLEAR_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES,
    CLEAR_QUOTE,
    CLEAR_SUMMARY_QUOTE,
    GET_SERVICE_FEATURE_PRODUCT_METADATA,
    GET_STANDALONE_PRODUCT_METADATA,
    RESET_AVAILABLE_INVENTORY_INFORMATION,
    RESET_INVENTORY_STORE_PICKUP_DETAILS,
    RETRIEVE_OFFCYCLE_NEXT_RENEWAL_SCHEDULE,
    RETRIEVE_OFFERING_CONTEXT_QUICK_VIEW,
    RETRIEVE_OFFERING_ORDER_QUOTE,
    RETRIEVE_ORDER_CONTEXT,
    RETRIEVE_ORDER_QUOTE,
    RETRIEVE_OFFCYCLE_ALIGNMENT_OPTIONS,
    RETRIEVE_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES,
    SAVE_INVENTORY_STORE_PICKUP_DETAILS,
    SEARCH_AVAILABLE_OFFERINGS,
    SEARCH_INVENTORIES_AVAILABILITY,
    SEARCH_SERVICE_FEATURES,
    SEND_INVENTORY_TO_STORE,
    SET_CHECKOUT_FORM_VALIDATION,
    SET_EXECUTION_OPTIONS,
    SET_INVENTORY_REGION_FILTER,
    SET_INVENTORY_STORE_CHANGED_FLAG,
    SET_ORDER_DETAILS,
    SET_OFFERING_PAGE_SIZE_PREFERENCE,
    SET_PAGE_NUMBER,
    SET_SELECTED_DELIVERY_DECISION,
    SET_SELECTED_ADDRESS,
    SET_SELECTED_SHIPPING_ADDRESS,
    SET_UPDATED_SHIPPING_ADDRESS,
    SET_SELECTED_INVENTORY_STORES,
    SUBMIT_ORDER,
    UPDATE_MULTI_OFFER_SHOPPING_CART
} from './actions/offering.order.actions';
import {RETRIEVE_CONTRACT_EVENTS} from './actions/contract.details.actions';
import {
    BEGIN_NEW_CONNECT_ORDER,
    CANCEL_ORDER
} from './actions/new.connect.wizard.actions';
import {BEGIN_TRANSITION_OFFER_ORDER} from './actions/transition.offer.wizard.actions';
import {BEGIN_ADD_OFFER_ORDER} from './actions/add.offer.wizard.actions';
import {BEGIN_ADD_SERVICES_ORDER} from './actions/add.services.wizard.actions';
import {BEGIN_EDIT_OFFER_ORDER} from './actions/edit.offer.wizard.actions';
import selectedOfferingReducer, {INITIAL_STATE as SELECTED_OFFERING_INITIAL_STATE} from './selected.offering.order.reducer';
import contractReducer, {INITIAL_STATE as SELECTED_CONTRACT_INITIAL_STATE} from './contract.reducer';
import contractListReducer, {INITIAL_STATE as AVAILABLE_CONTRACTS_INITIAL_STATE} from './contracts.list.reducer';
import createOfferOrderingReducers from './helpers/offer.ordering.wizard.reducer.helper';
import {prepareSubscriptionQuotes} from './products.order.reducer.helper';
import {EMPTY_OBJECT, EMPTY_ARRAY} from './constants/common.constants';
import {offCycleAlignmentMapping} from './helpers/customer.helper';
import {QUOTE_BYPASSED_WARNING_CODE} from '../customercare.constants';

const offerOrderingReducers = createOfferOrderingReducers();

export const INITIAL_STATE = Immutable({
    data: {
        availableContracts: AVAILABLE_CONTRACTS_INITIAL_STATE,
        availableInventories: [],
        availableOfferings: {
            data: {
                isPostpaidEnabled: false,
                isPrepaidEnabled: false,
                offeringsDisplayOrder: [],
                offeringsMap: EMPTY_OBJECT,
                pageCount: 0,
                pageNumber: 0,
                pageSizePreference: null,
                recordCount: 0
            }
        },
        createdOrder: null,
        inventoryStorePickupDetails: {
            comments: null,
            date: null
        },
        offcycleRenewalSchedule: EMPTY_ARRAY,
        offeringOrderQuote: EMPTY_OBJECT,
        quoteCalculated: false,
        selectedContract: SELECTED_CONTRACT_INITIAL_STATE,
        selectedContractDefinition: null,
        selectedOffering: SELECTED_OFFERING_INITIAL_STATE,
        serviceFeature: {
            orderablePricingPlansByProductId: EMPTY_OBJECT,
            productMetadataByProductId: EMPTY_OBJECT,
            products: EMPTY_OBJECT,
            serviceFeatureCardinality: EMPTY_OBJECT,
            productMetadataLoaded: false
        },
        standalones: {
            productMetadataByProductId: EMPTY_OBJECT
        },
        subTotal: 0,
        taxAmount: 0,
        totalAmount: 0,
        totalRemainingAmount: 0
    },
    asyncId: null,
    asyncStatus: null,
    billingEffectiveDateConfiguration: EMPTY_OBJECT,
    editCheckoutFormDetails: EMPTY_ARRAY,
    executionOptions: EMPTY_OBJECT,
    fullCartAsyncStatus: null,
    inventoryStore: {
        isStoreChanged: false,
        regionFilter: null,
        selectedStores: []
    },
    isCalculatingQuote: false,
    isFetchingActiveContract: false,
    isFetchingData: false,
    isFetchingRenewalSchedule: false,
    isQuoteBypassed: false,
    isRetrieveingOrderContext:false,
    isRetrievingOffCycleAlignmentOptions: false,
    isRetrievingOfferingContextQuickView: false,
    isSendingInventoryToStore: false,
    isSubmittingOrder: false,
    isUpdatingMultiOfferShoppingCart: false,
    lastAttemptError: null,
    offCycleAlignmentOptions: {},
    orderDetails: EMPTY_OBJECT,
    quickView: {
        shoppingCart: EMPTY_OBJECT
    },
    retrieveContractError: null,
    retrieveOfferingContextQuickViewError: null,
    searchInventoryAvailabilityError: null,
    selectedAddressDetails: {},
    setSelectedShippingAddress: null,
    setUpdatedShippingAddress: null,
    selectedDeliveryDecision: null,
    subscriptionOrderQuote: null
});

export default function reducer(state = INITIAL_STATE, action) {
    return compose(
        setSelectedOfferingState(action),
        setOfferingOrderState(action),
        setAvailableContractsState(action),
        setSelectedContractState(action))(state);
}

export function offeringOrderReducer(state = INITIAL_STATE, {payload, type, requestObject}) {
    let orderQuoteTotals = {};
    if (type === CALCULATE_COS_ORDER_QUOTE.SUCCESS || type === CALCULATE_ORDER_QUOTE.SUCCESS || type === RETRIEVE_ORDER_QUOTE.SUCCESS) {
        orderQuoteTotals = offerOrderingReducers.setOrderQuote(payload);
    }
    switch (type) {
        case SEARCH_AVAILABLE_OFFERINGS.BEGIN:
            return state.merge({
                isFetchingData: true
            });
        case SEARCH_AVAILABLE_OFFERINGS.SUCCESS:
            return state
                .set('isFetchingData', false)
                .set('lastAttemptError', null)
                .setIn(['data', 'availableOfferings', 'data', 'offeringsMap'], populateOfferingsMap(payload.Offerings))
                .setIn(['data', 'availableOfferings', 'data', 'offeringsDisplayOrder'], populateOfferingDisplayOrder(payload.Offerings))
                .setIn(['data', 'availableOfferings', 'data', 'pageCount'], payload.PageCount)
                .setIn(['data', 'availableOfferings', 'data', 'recordCount'], payload.RecordCount)
                .setIn(['data', 'availableOfferings', 'data', 'isPrepaidEnabled'], !!payload.PrepaidEnabled)
                .setIn(['data', 'availableOfferings', 'data', 'isPostpaidEnabled'], !!payload.PostpaidEnabled);
        case SEARCH_AVAILABLE_OFFERINGS.FAILURE:
            return state
                .set('isFetchingData', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity
                });
        case SEARCH_INVENTORIES_AVAILABILITY.BEGIN:
            return state.set('isFetchingData', true)
                .set('searchInventoryAvailabilityError', INITIAL_STATE.searchInventoryAvailabilityError);

        case SEARCH_INVENTORIES_AVAILABILITY.FAILURE:
            return state
                .set('isFetchingData', false)
                .set('searchInventoryAvailabilityError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity
                });
        case SEARCH_INVENTORIES_AVAILABILITY.SUCCESS:
            return state
                .setIn(['data', 'availableInventories'],
                    state.data.availableInventories.concat(
                        parseSearchInventoriesAvailabilityPayload(payload.InventoryTypes ? payload.InventoryTypes : EMPTY_ARRAY, requestObject)
                    )
                )
                .set('isFetchingData', false);
        case RESET_AVAILABLE_INVENTORY_INFORMATION:
            return state
                .setIn(['data', 'availableInventories'], EMPTY_ARRAY);
        case SEARCH_SERVICE_FEATURES.BEGIN:
            return state.setIn(['data', 'serviceFeature', 'products'], EMPTY_OBJECT);
        case SEARCH_SERVICE_FEATURES.SUCCESS:
            return state.setIn(['data', 'serviceFeature', 'products'], payload.Products);
        case SEARCH_SERVICE_FEATURES.FAILURE:
            return state.setIn(['data', 'serviceFeature', 'products'], EMPTY_OBJECT);
        case CLEAR_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES:
            return state.setIn(['data', 'serviceFeature', 'orderablePricingPlansByProductId'], EMPTY_OBJECT);
        case RETRIEVE_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES.BEGIN:
            return state.setIn(['data', 'serviceFeature', 'productMetadataLoaded'], false);
        case RETRIEVE_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES.FAILURE:
            return state.setIn(['data', 'serviceFeature', 'productMetadataLoaded'], false);
        case RETRIEVE_PRODUCT_CONTEXT_FOR_SERVICE_FEATURES.SUCCESS:
        {
            const statesOrderablePricingPlans = state.data.serviceFeature.orderablePricingPlansByProductId;
            return statesOrderablePricingPlans[payload.ProductContext.ProductId] ?
                state :
                state
                    .setIn(['data', 'serviceFeature', 'orderablePricingPlansByProductId', payload.ProductContext.ProductId], payload.ProductContext.OrderablePricingPlans)
                    .setIn(['data', 'serviceFeature', 'serviceFeatureCardinality', payload.ProductContext.ProductId], payload.ProductContext.ServiceFeatureCardinality)
                    .setIn(['data', 'serviceFeature', 'productMetadataLoaded'], true);
        }
        case GET_SERVICE_FEATURE_PRODUCT_METADATA.SUCCESS:
        {
            const productMetaData = state.data.serviceFeature.productMetadataByProductId;
            return productMetaData[payload.Product.Id] ?
                state :
                state.setIn(['data', 'serviceFeature', 'productMetadataByProductId', payload.Product.Id], payload.Product);
        }
        case GET_STANDALONE_PRODUCT_METADATA.SUCCESS:
        {
            const standaloneProductMetaData = state.data.standalones.productMetadataByProductId;
            return standaloneProductMetaData[payload.Product.Id] ?
                state :
                state.setIn(['data', 'standalones', 'productMetadataByProductId', payload.Product.Id], payload.Product);
        }
        case RETRIEVE_OFFERING_CONTEXT_QUICK_VIEW.BEGIN:
            return state
                .set('isRetrievingOfferingContextQuickView', true)
                .set('retrieveOfferingContextQuickViewError', null)
                .set('quickView', {});
        case RETRIEVE_OFFERING_CONTEXT_QUICK_VIEW.SUCCESS:
            return state
                .set('isRetrievingOfferingContextQuickView', false)
                .setIn(['data', 'selectedOffering', 'pricingPlanIdsNotFulfillingRequiredPricingPlanConditions'], payload.PricingPlanIdsNotFulfillingRequiredPricingPlanConditions)
                .setIn(['quickView', 'shoppingCart'], payload.ShoppingCart);
        case RETRIEVE_OFFERING_CONTEXT_QUICK_VIEW.FAILURE:
            return state
                .set('isRetrievingOfferingContextQuickView', false)
                .set('retrieveOfferingContextQuickViewError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                });
        case RETRIEVE_ORDER_CONTEXT.BEGIN:
            return state.set('isRetrieveingOrderContext', true);
        case SET_CHECKOUT_FORM_VALIDATION:
            return state.set(['editCheckoutFormDetails'], mergeFormValidationItem(payload, state.editCheckoutFormDetails));
        case RETRIEVE_ORDER_CONTEXT.SUCCESS:
            return state
                .set('isRetrieveingOrderContext', false)
                .set('billingEffectiveDateConfiguration', payload.BillingEffectiveDateConfiguration);
        case RETRIEVE_ORDER_CONTEXT.FAILURE:
            return state.set('isRetrieveingOrderContext', false);
        case RETRIEVE_CONTRACT_EVENTS.BEGIN:
            return state
                .set('isFetchingActiveContract', true)
                .set('lastAttemptError', null)
                .setIn(['data', 'selectedContractDefinition'], null);
        case RETRIEVE_CONTRACT_EVENTS.SUCCESS:
            return state
                .setIn(['data', 'selectedContractDefinition'], payload.OrderContract)
                .set('isFetchingActiveContract', false)
                .set('retrieveContractError', null);
        case RETRIEVE_CONTRACT_EVENTS.FAILURE:
            return state
                .set('isFetchingActiveContract', false)
                .set('retrieveContractError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case SET_PAGE_NUMBER:
            return state.setIn(['data', 'availableOfferings', 'data', 'pageNumber'], payload);
        case SET_EXECUTION_OPTIONS:
            return state.set('executionOptions', payload || EMPTY_OBJECT);
        case SET_ORDER_DETAILS:
            return state.set('orderDetails', payload || EMPTY_OBJECT);
        case RETRIEVE_ORDER_QUOTE.BEGIN:
        case CALCULATE_COS_ORDER_QUOTE.BEGIN:
            return state.set('isCalculatingQuote', true);
        case RETRIEVE_ORDER_QUOTE.SUCCESS:
        case CALCULATE_COS_ORDER_QUOTE.SUCCESS:
        case CALCULATE_ORDER_QUOTE.SUCCESS:
            return state
                .set('isCalculatingQuote', false)
                .set('subscriptionOrderQuote', payload.SubscriptionQuotes && prepareSubscriptionQuotes(payload.SubscriptionQuotes))
                .setIn(['data', 'subTotal'], orderQuoteTotals.SubTotalAmount || 0)
                .setIn(['data', 'taxAmount'], orderQuoteTotals.TaxAmount || 0)
                .setIn(['data', 'totalAmount'], orderQuoteTotals.TotalAmount || 0)
                .setIn(['data', 'shippingAmount'], orderQuoteTotals.ShippingAmount || 0)
                .setIn(['data', 'totalRemainingAmount'], payload.TotalRemainingAmount || 0)
                .setIn(['data', 'quoteCalculated'], true)
                .set('asyncId', payload.QuoteId || null)
                .set('asyncStatus', payload.Status !== undefined ? payload.Status : null);
        case CALCULATE_OFFERING_ORDER_QUOTE.BEGIN:
        case RETRIEVE_OFFERING_ORDER_QUOTE.BEGIN:
            return state.set('isCalculatingQuote', true);
        case CALCULATE_OFFERING_ORDER_QUOTE.SUCCESS:
        case RETRIEVE_OFFERING_ORDER_QUOTE.SUCCESS:
            if (isQuoteBypassed(payload)) {
                return state
                    .set('isCalculatingQuote', false)
                    .set('isQuoteBypassed', true)
                    .set('asyncId', null)
                    .setIn(['data', 'offeringOrderQuote'],  EMPTY_OBJECT)
                    .set('asyncStatus', null);
            } else {
                return state
                    .set('isCalculatingQuote', false)
                    .set('isQuoteBypassed', false)
                    .set('asyncId', payload?.QuoteId || payload?.OfferingOrderQuote?.QuoteId || null)
                    .set('financeCreditLimitValidation', payload?.FinanceCreditLimitValidation)
                    .set('fullCartAsyncStatus', payload?.Status !== undefined ? payload?.Status : null)
                    .setIn(['data', 'shippingAddress'], payload?.ShippingAddress || (state.data.shippingAddress || undefined))
                    .setIn(['data', 'offeringOrderQuote'], payload?.OfferingOrderQuote || EMPTY_OBJECT)
                    .set('asyncStatus', payload?.Status !== undefined ? payload?.Status : null);
            }
        case CALCULATE_OFFERING_ORDER_QUOTE.FAILURE:
        case RETRIEVE_OFFERING_ORDER_QUOTE.FAILURE:
            return state
                .set('isCalculatingQuote', false)
                .setIn(['data', 'offeringOrderQuote'], EMPTY_OBJECT);
        case RETRIEVE_OFFCYCLE_NEXT_RENEWAL_SCHEDULE.BEGIN:
            return state.set('isFetchingRenewalSchedule', true);
        case RETRIEVE_OFFCYCLE_NEXT_RENEWAL_SCHEDULE.SUCCESS:
            return state
                .set('isFetchingRenewalSchedule', false)
                .setIn(['data', 'offcycleRenewalSchedule'], payload.OffCycleNextRenewalSchedules || EMPTY_ARRAY);
        case RETRIEVE_OFFCYCLE_NEXT_RENEWAL_SCHEDULE.FAILURE:
            return state
                .set('isFetchingRenewalSchedule', false)
                .setIn(['data', 'offcycleRenewalSchedule'], EMPTY_ARRAY);
        case CLEAR_OFFERING_ORDER_QUOTE:
            return state.setIn(['data', 'offeringOrderQuote'], EMPTY_OBJECT);
        case CALCULATE_COS_ORDER_QUOTE.FAILURE:
            return state.set('isCalculatingQuote', false);
        case CALCULATE_ORDER_QUOTE.BEGIN:
            return state.set('isCalculatingQuote', true);
        case RETRIEVE_ORDER_QUOTE.FAILURE:
        case CALCULATE_ORDER_QUOTE.FAILURE:
            return state.set('isCalculatingQuote', false);
        case CLEAR_QUOTE:
            return state
                .setIn(['data', 'quoteCalculated'], INITIAL_STATE.data.quoteCalculated)
                .setIn(['data', 'subTotal'], INITIAL_STATE.data.subTotal)
                .setIn(['data', 'taxAmount'], INITIAL_STATE.data.taxAmount)
                .setIn(['data', 'totalAmount'], INITIAL_STATE.data.totalAmount)
                .setIn(['data', 'totalRemainingAmount'], INITIAL_STATE.data.totalRemainingAmount)
                .set('subscriptionOrderQuote', INITIAL_STATE.subscriptionOrderQuote);
        case CLEAR_SUMMARY_QUOTE:
            return state
                .setIn(['data', 'quoteCalculated'], INITIAL_STATE.data.quoteCalculated)
                .setIn(['data', 'taxAmount'], INITIAL_STATE.data.taxAmount)
                .setIn(['data', 'totalAmount'], INITIAL_STATE.data.totalAmount)
                .setIn(['data', 'totalRemainingAmount'], INITIAL_STATE.data.totalRemainingAmount)
                .set('subscriptionOrderQuote', INITIAL_STATE.subscriptionOrderQuote);
        case SUBMIT_ORDER.BEGIN:
            return state.set('isSubmittingOrder', true)
                .set('lastAttemptError', null);
        case SUBMIT_ORDER.SUCCESS:
            return state
                .set('isSubmittingOrder', false)
                .setIn(['data', 'createdOrder'], payload.Order)
                .setIn(['data', 'selectedOffering'], SELECTED_OFFERING_INITIAL_STATE);
        case SUBMIT_ORDER.FAILURE:
            return state.set('isSubmittingOrder', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case BEGIN_EDIT_OFFER_ORDER:
            return setInitialStateKeepSelectedOfferingStore(state.data.selectedOffering);
        case SET_OFFERING_PAGE_SIZE_PREFERENCE:
            return state.setIn(['data', 'availableOfferings', 'data', 'pageSizePreference'], payload);
        case BEGIN_ADD_OFFER_ORDER:
            return payload.isMultiOffer ?
                INITIAL_STATE.set('inventoryStore', state.inventoryStore) : INITIAL_STATE;
        case BEGIN_ADD_SERVICES_ORDER:
            return INITIAL_STATE;
        case BEGIN_NEW_CONNECT_ORDER:
        case BEGIN_TRANSITION_OFFER_ORDER:
        case CANCEL_ORDER:
            return INITIAL_STATE;
        case UPDATE_MULTI_OFFER_SHOPPING_CART.BEGIN:
            return state.set('isUpdatingMultiOfferShoppingCart', true);
        case UPDATE_MULTI_OFFER_SHOPPING_CART.SUCCESS:
        case UPDATE_MULTI_OFFER_SHOPPING_CART.FAILURE:
            return state.set('isUpdatingMultiOfferShoppingCart', false);
        case SET_INVENTORY_STORE_CHANGED_FLAG:
            return state
                .setIn(['inventoryStore', 'isStoreChanged'], payload);
        case SET_SELECTED_DELIVERY_DECISION:
            return state
                .setIn(['selectedDeliveryDecision'], payload);
        case SET_SELECTED_ADDRESS:
            return state
                .setIn(['selectedAddressDetails'], payload);
        case SET_SELECTED_SHIPPING_ADDRESS:
            return state
                .setIn(['setSelectedShippingAddress'], payload);
        case SET_UPDATED_SHIPPING_ADDRESS:
            return state
                .setIn(['setUpdatedShippingAddress'], payload);
        case SET_SELECTED_INVENTORY_STORES:
            return state
                .setIn(['inventoryStore', 'selectedStores'], payload)
                .setIn(['inventoryStore', 'isStoreChanged'], true);
        case SET_INVENTORY_REGION_FILTER:
            return state.setIn(['inventoryStore', 'regionFilter'], payload);
        case RESET_INVENTORY_STORE_PICKUP_DETAILS:
            return state.setIn(['data', 'inventoryStorePickupDetails'], INITIAL_STATE.data.inventoryStorePickupDetails);
        case SAVE_INVENTORY_STORE_PICKUP_DETAILS:
            return state.setIn(['data', 'inventoryStorePickupDetails'], payload);
        case SEND_INVENTORY_TO_STORE.BEGIN:
            return state.set('isSendingInventoryToStore', true);
        case SEND_INVENTORY_TO_STORE.FAILURE:
            return state
                .set('isSendingInventoryToStore', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case SEND_INVENTORY_TO_STORE.SUCCESS:
            return state
                .set('isSendingInventoryToStore', false)
                .setIn(['data', 'inventoryStorePickupDetails'], INITIAL_STATE.data.inventoryStorePickupDetails);
        case RETRIEVE_OFFCYCLE_ALIGNMENT_OPTIONS.BEGIN:
            return state.set('isRetrievingOffCycleAlignmentOptions', true);
        case RETRIEVE_OFFCYCLE_ALIGNMENT_OPTIONS.SUCCESS:
            return state.set('offCycleAlignmentOptions', offCycleAlignmentMapping(payload.OffCycleChargeAlignmentOptions))
                .set('isRetrievingOffCycleAlignmentOptions', false);
        case RETRIEVE_OFFCYCLE_ALIGNMENT_OPTIONS.FAILURE:
            return state.set('offCycleAlignmentOptions', {})
                .set('isRetrievingOffCycleAlignmentOptions', false);
        default:
            return state;
    }
}

function isQuoteBypassed(payload) {
    return (payload.Warnings || []).some((warning) => {
        return warning.Code === QUOTE_BYPASSED_WARNING_CODE;
    });
}

function populateOfferingsMap(offerings) {
    const offeringsMap = {};

    offerings.forEach(offering => {
        offeringsMap[offering.Id] = offering;
    });

    return offeringsMap;
}

function populateOfferingDisplayOrder(offerings) {
    const displayOrder = [];

    offerings.forEach(offering => {
        displayOrder.push(offering.Id);
    });

    return displayOrder;
}

const setOfferingOrderState = curry((action, state) => {
    return offeringOrderReducer(state, action);
});


const curriedSetState = curry((path, childReducer, accessorFunc, action, parentState) => {
    return parentState.setIn(path, childReducer(accessorFunc(parentState), action));
});

const setSelectedOfferingState = curriedSetState(
    ['data', 'selectedOffering'],
    selectedOfferingReducer,
    state => {
        return state.data.selectedOffering;
    }
);

const setSelectedContractState = curriedSetState(
    ['data', 'selectedContract'],
    contractReducer,
    state => {
        return state.data.selectedContract;
    }
);

const setAvailableContractsState = curriedSetState(
    ['data', 'availableContracts'],
    contractListReducer,
    state => {
        return state.data.availableContracts;
    }
);

function setInitialStateKeepSelectedOfferingStore(selectedOfferStore) {
    return INITIAL_STATE.setIn(['data', 'selectedOffering'], selectedOfferStore);
}

const parseSearchInventoriesAvailabilityPayload = (inventoryTypes, requestObject) => {
    return inventoryTypes.map((inventoryType) => {
        return Object.assign({}, inventoryType, {
            ContextId: requestObject.ContextId,
        }, {
            Available: inventoryType.MeetsRequestedQuantity,
            Quantity: inventoryType.RequestedQuantity
        });
    });
};

const mergeFormValidationItem = (payload, currentState) => {
    const clonedCurrentState = clone(currentState);

    if (payload.length === 0 && clonedCurrentState) {
        return payload;
    } else if (!clonedCurrentState.length) {
        return payload;
    } else {
        if (payload.length > 1) {
            return payload;
        } else {
            clonedCurrentState.forEach((item) => {
                if (item.formName === payload.formName) {
                    item.isActive = payload.isActive !== undefined ? payload.isActive : item.isActive;
                    item.isValid = payload.isValid !== undefined ? payload.isValid : item.isValid;
                    item.glyph = payload.glyph ? payload.glyph : item.glyph;
                    item.glyphClass = payload.glyphClass ? payload.glyphClass : item.glyphClass;
                } else {
                    item.isActive = false;
                }
            });
        }

        return clonedCurrentState;
    }
};
