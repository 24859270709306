import {ApiConstants,
    ThunkHelper} from 'invision-core';

export const EXECUTE_BILL_RUN_CONSTANTS = {
    BEGIN: 'EXECUTE_BILL_RUN_BEGIN',
    SUCCESS: 'EXECUTE_BILL_RUN_SUCCESS',
    FAILURE: 'EXECUTE_BILL_RUN_FAILURE'
};
const EXECUTE_BILL_RUN_EVENTS = [EXECUTE_BILL_RUN_CONSTANTS.BEGIN, EXECUTE_BILL_RUN_CONSTANTS.SUCCESS, EXECUTE_BILL_RUN_CONSTANTS.FAILURE];

export const executeBillRunPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, EXECUTE_BILL_RUN_EVENTS, {
        method: 'post',
        url: 'LineOfBusiness/ExecuteConvergentBillerBillRun',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {}
    });
};
export const executeBillRun = (customerId) => {
    return (dispatch) => {
        return executeBillRunPromise(dispatch, customerId);
    };
};

export const COMPLETE_WORKFLOW_STATUS = {
    BEGIN: 'COMPLETE_WORKFLOW_BEGIN',
    SUCCESS: 'COMPLETE_WORKFLOW_SUCCESS',
    FAILURE: 'COMPLETE_WORKFLOW_FAILURE'
};

const COMPLETE_WORKFLOW_EVENTS = [COMPLETE_WORKFLOW_STATUS.BEGIN, COMPLETE_WORKFLOW_STATUS.SUCCESS, COMPLETE_WORKFLOW_STATUS.FAILURE];

export const completeWorkflowPromise = (dispatch, customerId, workflowId) => {
    return ThunkHelper(dispatch, COMPLETE_WORKFLOW_EVENTS, {
        method: 'post',
        url: 'Workflow/UpdateWorkflowStatus',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            ActivityId: workflowId
        }
    });
};
export const completeWorkflow = (customerId, workflowId) => {
    return (dispatch) => {
        return completeWorkflowPromise(dispatch, customerId, workflowId);
    };
};

export const RETRIEVE_ACTIVE_WORKFLOWS_STATUS = {
    BEGIN: 'RETRIEVE_ACTIVE_WORKFLOWS_BEGIN',
    SUCCESS: 'RETRIEVE_ACTIVE_WORKFLOWS_SUCCESS',
    FAILURE: 'RETRIEVE_ACTIVE_WORKFLOWS_FAILURE'
};

const RETRIEVE_ACTIVE_WORKFLOWS_EVENTS = [RETRIEVE_ACTIVE_WORKFLOWS_STATUS.BEGIN, RETRIEVE_ACTIVE_WORKFLOWS_STATUS.SUCCESS, RETRIEVE_ACTIVE_WORKFLOWS_STATUS.FAILURE];

export const retrieveActiveWorkflowsPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, RETRIEVE_ACTIVE_WORKFLOWS_EVENTS, {
        method: 'post',
        url: 'Workflow/SearchActiveActivities',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            SearchTags: [{
                'Key': 1,
                'Value': customerId.toString()
            }],
            PageSize: 25,
            State: 7 //enum: ActivityStateType.InProcess
        }
    });
};
export const retrieveActiveWorkflows = (customerId) => {
    return (dispatch) => {
        return retrieveActiveWorkflowsPromise(dispatch, customerId);
    };
};

export const SUBMIT_USAGE_STATUS = {
    BEGIN: 'SUBMIT_USAGE_BEGIN',
    SUCCESS: 'SUBMIT_USAGE_SUCCESS',
    FAILURE: 'SUBMIT_USAGE_FAILURE'
};
const SUBMIT_USAGE_EVENTS = [SUBMIT_USAGE_STATUS.BEGIN, SUBMIT_USAGE_STATUS.SUCCESS, SUBMIT_USAGE_STATUS.FAILURE];

export const createSubmitUsagePromise = (dispatch, usage = {}) => {
    return ThunkHelper(dispatch, SUBMIT_USAGE_EVENTS, {
        method: 'post',
        url: 'LineOfBusiness/SimulateUsage',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: usage.SubscriberId
        },
        data: {
            EventSource: usage.EventSource,
            NetworkEventTypeCode: usage.SelectedNetworkEvent,
            NetworkEventSubTypeCode: usage.SelectedNetworkEventSubType,
            Quantity: usage.Quantity,
            ServiceIdentifier: usage.SelectedServiceIdentifier,
            UnitOfMeasureCode: usage.SelectedUnitOfMeasureCode
        }
    });
};

export const submitUsage = (usage) => {
    return (dispatch) => {
        return createSubmitUsagePromise(dispatch, usage);
    };
};
