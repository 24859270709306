import path from 'ramda/src/path';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {
    MetadataCodeTypeDictionarySelector,
    MetadataCodeTypeSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {PageSizePreferenceSelector} from 'invision-core/src/components/session/session.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import i18n from 'invision-core/src/components/i18n/i18n';
import localeKeys from '../../locales/keys';
import {
    convertUnitOfMeasure,
    getUnitOfMeasure
} from './shared.entitlements.selectors.helper';
import {ENTITLEMENT_SHARE_STATUS} from '../../customercare.constants';
import {EMPTY_OBJECT, EMPTY_ARRAY} from '../constants/common.constants';
import {getFormattedServiceAttributeValue} from './services.list.selectors.helper';

const sharedEntitlementsData = (state) => {
    return state.customercare.customer.selectedCustomer.sharedEntitlements;
};

export const entitlementsSelector = createImmutableSelector(
    [sharedEntitlementsData],
    (sharedEntilements) => {
        return sharedEntilements.entitlements;
    }
);

export const entitlementsPaginationDataSelector = createImmutableSelector(
    [sharedEntitlementsData, PageSizePreferenceSelector],
    (sharedEntilements, defaultPageSize) => {
        if (!sharedEntilements.entitlementsPaginationData.pageSize) {
            return Object.assign({}, sharedEntilements.entitlementsPaginationData, {
                pageSize: defaultPageSize
            });
        }
        return sharedEntilements.entitlementsPaginationData;
    }
);

const recoverableUIStateSelector = (state) => {
    if (state.customercare.recoverableUiState) {
        return state.customercare.recoverableUiState.sharedEntitlements;
    }
};

export const sharedEntitlementsPageSizeSelector = createImmutableSelector(
    [recoverableUIStateSelector, PageSizePreferenceSelector],
    (uiState, defaultPageSize) => {
        return uiState.pageSize ? uiState.pageSize : defaultPageSize;
    }
);

export const currentPageNumberSelector = createImmutableSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.pageNumber;
    }
);

export const entitlementRecordCountSelector = createImmutableSelector(
    [sharedEntitlementsData],
    (sharedEntilements) => {
        return sharedEntilements.recordCount;
    }
);

export const unitsOfMeasureSelector = createImmutableSelector(
    [MetadataCodeTypeDictionarySelector(CODES.UnitsOfMeasure)],
    (unitsOfMeasure) => {
        return Object.values(unitsOfMeasure);
    }
);

export const selectedEntitlementIndexSelector = createImmutableSelector(
    [sharedEntitlementsData],
    (sharedEntitlements) => {
        return sharedEntitlements.selectedEntitlementIndex;
    }
);

export const isFetchingEntitlementUsageSelector = createImmutableSelector(
    [sharedEntitlementsData],
    (entitlementsData) => {
        return entitlementsData.isFetchingEntitlementUsage;
    }
);

export const isFetchingEntitlementsSelector = createImmutableSelector(
    [sharedEntitlementsData],
    (entitlementsData) => {
        return entitlementsData.isFetchingEntitlements;
    }
);

export const isUpdatingEntitlementShareStatusSelector = createImmutableSelector(
    [sharedEntitlementsData],
    (entitlementsData) => {
        return entitlementsData.isUpdatingEntitlementShareStatus;
    }
);

export const entitlementUsageTotalSelector = createImmutableSelector(
    [sharedEntitlementsData],
    (entitlementsData) => {
        return entitlementsData.entitlementUsageTotal;
    }
);

export const entitlementUsagesSelector = createImmutableSelector(
    [sharedEntitlementsData],
    (entitlementsData) => {
        return entitlementsData.entitlementUsages;
    }
);

export const formattedEntitlementsSelector = createImmutableSelector(
    [
        entitlementsSelector,
        unitsOfMeasureSelector,
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression)
    ],
    (entitlements, unitsOfMeasure, serviceAttributes, regularExpressions) => {
        return entitlements.map((entitlement) => {
            const owningService = entitlement.EntitlementIdentifier && entitlement.EntitlementIdentifier.OwningServiceIdentifier;
            if (owningService) {
                entitlement = entitlement.setIn(
                    ['EntitlementIdentifier', 'OwningServiceIdentifier', 'FormattedValue'],
                    getFormattedServiceAttributeValue(owningService.ServiceAttributeId, owningService.Value, serviceAttributes, regularExpressions)
                );
            }

            return Object.assign({}, entitlement, {
                ShareAction: i18n.translate(entitlement.SharingStatus === ENTITLEMENT_SHARE_STATUS.DISABLED
                    ? localeKeys.SHARING_ACTIONS.START_SHARING
                    : localeKeys.SHARING_ACTIONS.STOP_SHARING),
                UnitOfMeasure: getUnitOfMeasure(entitlement.BalanceUnitCode, unitsOfMeasure)
            });
        });
    }
);

export const selectedEntitlementSelector = createImmutableSelector(
    [selectedEntitlementIndexSelector, formattedEntitlementsSelector],
    (selectedEntitlementIndex, entitlements) => {
        return entitlements[selectedEntitlementIndex] || EMPTY_OBJECT;
    }
);

export const entitlementUsagesWithFormattedServiceAttributeSelector = createImmutableSelector(
    [
        entitlementUsagesSelector,
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression)
    ],
    (entitlementUsages, serviceAttributes, regularExpressions) => {
        return (entitlementUsages || EMPTY_ARRAY).map((service) => {
            return service.ServiceIdentifier ? service.setIn(
                ['ServiceIdentifier', 'FormattedValue'],
                getFormattedServiceAttributeValue(service.ServiceIdentifier.ServiceAttributeId, service.ServiceIdentifier.Value, serviceAttributes, regularExpressions))
                : service;
        });
    }
);

export const entitlementUsagesTableDataSelector = createImmutableSelector(
    [
        entitlementUsagesWithFormattedServiceAttributeSelector,
        selectedEntitlementSelector,
        MetadataCodeTypeDictionarySelector(CODES.UnitsOfMeasure),
        MetadataCodeTypeDictionarySelector(CODES.UsageUnitConversion),
        selectedEntitlementIndexSelector
    ],
    (entitlementUsages, selectedEntitlement, unitsOfMeasureDictionary, unitConversionTable, selectedEntitlementIndex) => {
        const ownerServiceId = path(['EntitlementIdentifier', 'OwningServiceIdentifier', 'ServiceAttributeId'], selectedEntitlement);

        return entitlementUsages.reduce((usages, service) => {
            service.EntitlementUsageTotals.forEach((usage) => {
                usages.push({
                    entitlementId: path(['EntitlementIdentifier', 'EntitlementId'], usage),
                    entitlementName: usage.EntitlementName,
                    friendlyName: service.ServiceIdentifier && service.ServiceIdentifier.FriendlyName,
                    groupCode: path(['EntitlementIdentifier', 'GroupCode'], selectedEntitlement),
                    selectedEntitlementIndex,
                    subscriberId: service.SubscriberId,
                    ownerLabel: !!ownerServiceId && ownerServiceId === (service.ServiceIdentifier && service.ServiceIdentifier.ServiceAttributeId) ?
                        i18n.translate(localeKeys.OWNER).toUpperCase() : '',
                    serviceAttributeId: service.ServiceIdentifier && service.ServiceIdentifier.ServiceAttributeId,
                    serviceId: service.ServiceIdentifier && service.ServiceIdentifier.Value,
                    serviceIdFormatted: service.ServiceIdentifier && service.ServiceIdentifier.FormattedValue,
                    transactionId: service.TransactionId,
                    usage: {
                        balanceUsed: convertUnitOfMeasure(
                            usage.TotalUsageAmount,
                            usage.UnitOfMeasureCode,
                            selectedEntitlement.BalanceUnitCode,
                            unitConversionTable
                        ),
                        initialBalance: selectedEntitlement.InitialBalance,
                        initialBalanceUnit: (selectedEntitlement.UnitDisplayName && unitsOfMeasureDictionary[selectedEntitlement.BalanceUnitCode].Name === 'Units') ? selectedEntitlement.UnitDisplayName : (path(['AdditionalProperties', 'short_name'], unitsOfMeasureDictionary[selectedEntitlement.BalanceUnitCode]) || '')
                    }
                });
            });
            return usages;
        }, []);
    }
);
