import Immutable from 'seamless-immutable';
import {
    GIFT_CARD_RESULT,
    RESEND_GIFT_CARD_NOTIFICATION
} from './actions/giftcard.result.actions';

export const INITIAL_STATE = Immutable({
    data: {},
    giftCardNotificationError: null,
    isFetchingGiftCard: false,
    isResendingGiftCardNotification: false
});

export default function giftCardResultReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case GIFT_CARD_RESULT.BEGIN:
            return state.set('data', null)
                .set('isFetchingGiftCard', true);
        case GIFT_CARD_RESULT.SUCCESS:
            return state.set('data', payload.Redemption || null)
                .set('isFetchingGiftCard', false);
        case GIFT_CARD_RESULT.FAILURE:
            return state.set('data', null)
                .set('isFetchingGiftCard', false);
        case RESEND_GIFT_CARD_NOTIFICATION.BEGIN:
            return state.set('isResendingGiftCardNotification', true);
        case RESEND_GIFT_CARD_NOTIFICATION.SUCCESS:
            return state.set('isResendingGiftCardNotification', false);
        case RESEND_GIFT_CARD_NOTIFICATION.FAILURE:
            return state
                .set('isResendingGiftCardNotification', false)
                .set('giftCardNotificationError', [payload.translatedMessage]);
        default:
            return state;
    }
}
