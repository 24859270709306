import {TRANSACTION_TOTALS_CONSTANTS} from './actions/customer.totals.actions';
import Immutable from 'seamless-immutable';

export const INITIAL_STATE = Immutable({
    data: null,
    isFetchingData: null,
    totalsError: null
});

export default function productsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case TRANSACTION_TOTALS_CONSTANTS.BEGIN:
            return state.set('isFetchingData', true);
        case TRANSACTION_TOTALS_CONSTANTS.SUCCESS:
            return state
                .set('data', payload)
                .set('isFetchingData', false)
                .set('totalsError', null);
        case TRANSACTION_TOTALS_CONSTANTS.FAILURE:
            return state
                .set('isFetchingData', false)
                .set('totalsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        default:
            return state;
    }
}
