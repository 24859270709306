import {createSelector} from 'reselect';
import pathOr from 'ramda/src/pathOr';
import clone from 'ramda/src/clone';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    MetadataCodeSelector,
    MetadataCodeTypeSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {pascalCaseKeys} from 'invision-core/src/utilities/object.formatting.helper';
import {SummaryTab} from '../helpers/offer.ordering.wizard.selector.helper';
import {
    EMPTY_ARRAY,
    EMPTY_OBJECT,
    FALSE
} from '../constants/common.constants';
import {ASYNC_STATUS_CONSTANTS} from '../constants/wizard.constants';
import {PRICE_OVERRIDE_ACCESS} from '../../security.attributes';

const ADDITIONAL_PROPERTY_IS_ACTIVE = 'is_active';

export const DROP_DOWN_DEFAULT_ADDRESS = {
    Id: 0,
    Country: null,
    State: null,
    dropDownString: 'Add New'
};

const OfferingOrderSelector = (state) => {
    return state.customercare.offeringOrder;
};


export const OfferingOrderDataSelector = createSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.data;
    }
);

export const OfferingOrderFinanceCreditLimitSelector = createImmutableSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.financeCreditLimitValidation;
    }
);

export const OfferingOrderAsyncIdSelector = createImmutableSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.asyncId;
    }
);

export const OfferingOrderCheckoutPageEditFormSelector = createImmutableSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.editCheckoutFormDetails;
    }
);

export const AsyncStatusSelector = createImmutableSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.asyncStatus;
    }
);

export const IsQuoteBypassedSelector = createImmutableSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.isQuoteBypassed;
    }
);

export const FullCartAsyncStatusSelector = createImmutableSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.fullCartAsyncStatus;
    }
);

export const IsFullCartAsyncCompleteSelector  = createImmutableSelector(
    [
        OfferingOrderAsyncIdSelector,
        FullCartAsyncStatusSelector,
    ],
    (asyncId, fullCartAsyncStatus) => {
        return asyncId ? fullCartAsyncStatus === ASYNC_STATUS_CONSTANTS.COMPLETE || fullCartAsyncStatus === ASYNC_STATUS_CONSTANTS.FAILED : true;
    }
);

export const OffcycleRenewalScheduleSelector = createImmutableSelector(
    [OfferingOrderDataSelector],
    (offeringOrderData) => {
        return pathOr(EMPTY_ARRAY, ['offcycleRenewalSchedule'], offeringOrderData);
    }
);

export const DependentPricingPlansIdsSelector = createSelector(
    [OfferingOrderDataSelector],
    (offeringOrderData) => {
        return offeringOrderData.selectedOffering ? offeringOrderData.selectedOffering.pricingPlanIdsNotFulfillingRequiredPricingPlanConditions: null;
    }
);

export const SelectedContractDefinitionSelector = createSelector(
    [OfferingOrderDataSelector],
    (offeringOrderData) => {
        return offeringOrderData.selectedContractDefinition;
    }
);

export const IsFetchingActiveContractSelector = createSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.isFetchingActiveContract;
    }
);

export const BillingEffectiveDateConfigurationSelector = createImmutableSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.billingEffectiveDateConfiguration || EMPTY_OBJECT;
    }
);

export const BillingEffectiveDateSettingsSelector  = createImmutableSelector(
    [BillingEffectiveDateConfigurationSelector],
    (billingEffectiveDateConfiguration) => {
        return {
            minimumDate: billingEffectiveDateConfiguration.MinBillingEffectiveDate,
            pastDateMaxLength: billingEffectiveDateConfiguration.PastBillingEffectiveDateMaxLength
        };
    }
);

export const BillingEffectiveDateOptionsSelector = createImmutableSelector(
    [BillingEffectiveDateConfigurationSelector],
    (billingEffectiveDateConfiguration) => {
        return billingEffectiveDateConfiguration.OrderExecutionToBillingEffectiveDateIntentions || EMPTY_ARRAY;
    }
);

export const IsInventoryStoreConfiguredSelector = createImmutableSelector([
    MetadataCodeTypeSelector(CODES.Stores)
], (stores) => {
    return !!stores.length;
});

export const OfferingQuickViewSelector = (state) => {
    return pathOr(null, ['customercare', 'offeringOrder', 'quickView'], state);
};

export const RetrieveOfferingContextQuickViewErrorSelector = createSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.retrieveOfferingContextQuickViewError;
    }
);

export const IsRetrievingOfferingContextQuickView = createSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.isRetrievingOfferingContextQuickView;
    }
);

export const AvailableOfferingsDataSelector = createSelector(
    [OfferingOrderDataSelector],
    (orderStoreData) => {
        return orderStoreData.availableOfferings.data;
    }
);

export const IsPostpaidEnabledSelector = createSelector(
    [AvailableOfferingsDataSelector],
    (availableOfferingsData) => {
        return availableOfferingsData.isPostpaidEnabled;
    }
);

export const IsPrepaidEnabledSelector = createSelector(
    [AvailableOfferingsDataSelector],
    (availableOfferingsData) => {
        return availableOfferingsData.isPrepaidEnabled;
    }
);

const OfferingsDisplayOrderSelector = createSelector(
    [AvailableOfferingsDataSelector],
    (availableOfferingsData) => {
        return availableOfferingsData.offeringsDisplayOrder;
    }
);

export const OfferingsSelector = createSelector(
    [AvailableOfferingsDataSelector],
    (availableOfferingsData) => {
        return availableOfferingsData.offeringsMap;
    }
);

export const OffersSelector = createSelector(
    [OfferingsDisplayOrderSelector, OfferingsSelector],
    (displayOrder = {}, offerings = {}) => {
        return displayOrder ? displayOrder.map((offeringId) => {
            return offerings[offeringId];
        }) : [];
    }
);

export const IsFetchingSelector = createSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.isFetchingData;
    }
);

export const ViewLastAttemptErrorSelector = createSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.lastAttemptError;
    }
);

export const PageNumberSelector = createSelector(
    [AvailableOfferingsDataSelector],
    (availableOfferingsData) => {
        return availableOfferingsData.pageNumber;
    }
);

export const RecordCountSelector = createSelector(
    [AvailableOfferingsDataSelector],
    (availableOfferingsData) => {
        return availableOfferingsData.recordCount;
    }
);

export const SelectedPageSizePreference = createSelector(
    [AvailableOfferingsDataSelector],
    (availableOfferingsData) => {
        return availableOfferingsData.pageSizePreference;
    }
);

export const NumberOfPagesSelector = createSelector(
    [AvailableOfferingsDataSelector],
    (availableOfferingsData) => {
        return availableOfferingsData.pageCount;
    }
);

export const CreatedOrderSelector = createSelector(
    [OfferingOrderDataSelector],
    (offeringOrderData) => {
        return offeringOrderData.createdOrder;
    }
);

export const CreatedOrderCurrencyCodeSelector = createSelector(
    [CreatedOrderSelector],
    (createdOrder) => {
        return createdOrder && createdOrder.Currency ? createdOrder.Currency : 'USD';
    }
);

export const IsSubmittingOrderSelector = createSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.isSubmittingOrder;
    }
);

export const IsQuotingOfferSelector = createSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.isCalculatingQuote;
    }
);

export const SubTotalAmountSelector = createSelector(
    [OfferingOrderDataSelector],
    (offeringOrderData) => {
        return offeringOrderData.subTotal;
    }
);

export const TaxAmountSelector = createSelector(
    [OfferingOrderDataSelector],
    (offeringOrderData) => {
        return offeringOrderData.taxAmount;
    }
);

export const TotalAmountSelector = createSelector(
    [OfferingOrderDataSelector],
    (offeringOrderData) => {
        return offeringOrderData.totalAmount;
    }
);

export const TotalRemainingAmountSelector = createSelector(
    [OfferingOrderDataSelector],
    (offeringOrderData) => {
        return offeringOrderData.totalRemainingAmount;
    }
);

export const QuoteCalculatedSelector = createSelector(
    [OfferingOrderDataSelector],
    (offeringOrderData) => {
        return offeringOrderData.quoteCalculated;
    }
);

export const OfferingOrderQuoteSelector = createImmutableSelector(
    [OfferingOrderDataSelector],
    (offeringOrderData) => {
        return offeringOrderData.offeringOrderQuote;
    }
);

export const QuickViewShoppingCartSelector = createSelector(
    [OfferingQuickViewSelector],
    (quickView) => {
        const shoppingCart = pathOr(null, ['shoppingCart'], quickView);
        return shoppingCart ? shoppingCart.asMutable({
            deep: true
        }) : {};
    }
);

export const IsAsyncCompleteSelector  = createImmutableSelector(
    [
        OfferingOrderAsyncIdSelector,
        AsyncStatusSelector
    ],
    (offeringOrderAsyncId, asyncStatus) => {
        return offeringOrderAsyncId ?
            asyncStatus === ASYNC_STATUS_CONSTANTS.COMPLETE || asyncStatus === ASYNC_STATUS_CONSTANTS.FAILED :
            true;
    }
);

export const QuickViewSubTotalSelector = createSelector(
    [OfferingQuickViewSelector],
    (quickView) => {
        return pathOr(0, ['shoppingCart', 'BillerRuleTotals', 'Amount'], quickView);
    }
);
export const QuickViewTotalAmountSelector = createSelector(
    [OfferingQuickViewSelector],
    (quickView) => {
        return pathOr(0, ['shoppingCart', 'BillerRuleTotals', 'TotalAmount'], quickView);
    }
);

export const QuickViewPreQuoteDueSubTotalsSelector = createImmutableSelector(
    [QuickViewShoppingCartSelector],
    (quickViewShoppingCart) => {
        const subTotalDueToday = pathOr(0, ['SubTotals', 'DueToday'], quickViewShoppingCart);
        const subTotalDueNextInvoice = pathOr(0, ['SubTotals', 'DueNextInvoice'], quickViewShoppingCart);
        const subTotalDueRecurring = pathOr(0, ['SubTotals', 'DueRecurring'], quickViewShoppingCart);
        const subTotalDueOnActivation = pathOr(0, ['SubTotals', 'DueOnActivation'], quickViewShoppingCart);
        const subTotalDueOnFirstUse = pathOr(0, ['SubTotals', 'DueOnFirstUse'], quickViewShoppingCart);

        return {
            dueToday: {
                subTotal: subTotalDueToday
            },
            dueNextInvoice: {
                subTotal: subTotalDueNextInvoice
            },
            dueOnActivation: {
                subTotal: subTotalDueOnActivation
            },
            dueOnFirstUse: {
                subTotal: subTotalDueOnFirstUse
            },
            dueRecurring: {
                subTotal: subTotalDueRecurring
            }
        };
    }
);

export const QuickViewSummaryTabViewModel = SummaryTab([
    QuickViewShoppingCartSelector,
    QuickViewSubTotalSelector,
    () => {
        return 0;
    },
    QuickViewTotalAmountSelector,
    () => {
        return false;
    },
    () => {
        return false;
    },
    () => {
        return false;
    },
    () => {
        return false;
    },
    () => {
        return false;
    },
    () => {
        return false;
    },
    QuickViewPreQuoteDueSubTotalsSelector
]);

export const TransitioningEnabledSelector = createSelector(
    [OfferingOrderSelector],
    (offering) => {
        return offering.transitioningEnabled;
    }
);

export const TransitioningContextErrorSelector = createSelector(
    [OfferingOrderSelector],
    (offering) => {
        return offering.transitioningContextError;
    }
);

export const SubscriptionOrderQuoteSelector = createSelector(
    [OfferingOrderSelector],
    (offering) => {
        return offering.subscriptionOrderQuote;
    }
);

export const SelectedInventoryStoresSelector = createImmutableSelector(
    [OfferingOrderSelector],
    (offeringStore) => {
        return offeringStore.inventoryStore.selectedStores;
    }
);

export const InventoryRegionFilterSelector = createImmutableSelector(
    [OfferingOrderSelector],
    (offeringStore) => {
        return offeringStore.inventoryStore.regionFilter;
    }
);

export const IsInventoryStoreChangedSelector = createImmutableSelector(
    [OfferingOrderSelector],
    (offeringStore) => {
        return offeringStore.inventoryStore.isStoreChanged;
    }
);

export const FormattedInventoryStoresSelector = createImmutableSelector(
    [MetadataCodeTypeSelector(CODES.Stores)],
    (stores) => {
        const activeStores = stores.filter((store) => {
            return store.AdditionalProperties.find((additionalProperty) => {
                return additionalProperty.Key === ADDITIONAL_PROPERTY_IS_ACTIVE;
            }).Value !== FALSE;
        });
        return activeStores.map((store) => {
            return Object.assign({}, store, {
                AdditionalProperties: pascalCaseKeys(store.AdditionalProperties.reduce((additionalProperties, property) => {
                    additionalProperties[property.Key] = property.Value;
                    return additionalProperties;
                }, {}))
            });
        });
    }
);

export const SelectedInventoryRegionSelector = createImmutableSelector(
    [SelectedInventoryStoresSelector, FormattedInventoryStoresSelector],
    (selectedStores, stores) => {
        if (selectedStores.length) {
            return pathOr(null, ['AdditionalProperties', 'RegionCode'], stores.find((store) => {
                return store.Value === selectedStores[0];
            }));
        }
        return null;
    }
);

export const SelectedInventoryStoresDetailsSelector = createImmutableSelector(
    [SelectedInventoryStoresSelector, FormattedInventoryStoresSelector],
    (selectedStores, storesDetails) => {
        return selectedStores.map((selectedStoreId) => {
            return storesDetails.find((store) => {
                return store.Value === selectedStoreId;
            });
        });
    }
);

export const InventoryStoresTableDataSelector = createImmutableSelector(
    [
        InventoryRegionFilterSelector,
        FormattedInventoryStoresSelector,
        MetadataCodeTypeSelector(CODES.InventoryRegion)
    ],
    (regionFilter, stores, regions) => {
        const shippableRegion = regions.filter((region) => {
            return region.AdditionalProperties.find((item) => {
                return item.Key === 'is_shipping_available' && item.Value === 'True';
            });
        });
        let filteredStore = [];
        if (shippableRegion.length) {
            filteredStore = stores.filter((store) => {
                return store.AdditionalProperties.RegionCode !== shippableRegion[0].Value;
            });
        } else {
            filteredStore = stores;
        }
        return filteredStore.reduce((storesTableData, store) => {
            if (regionFilter && store.AdditionalProperties.RegionCode !== regionFilter) {
                return storesTableData;
            }

            storesTableData.push({
                address: `${store.AdditionalProperties.LineOne} ${store.AdditionalProperties.LineTwo}`,
                city: store.AdditionalProperties.City,
                description: store.Description,
                displayName: store.AdditionalProperties.DisplayName,
                name: store.Name,
                postalCode: store.AdditionalProperties.Zipcode,
                region: store.AdditionalProperties.RegionCode,
                stateProvince: store.AdditionalProperties.StateProvince,
                storeId: store.Value
            });
            return storesTableData;
        }, []);
    }
);

export const ServiceFeatureSelector = createImmutableSelector(
    [OfferingOrderDataSelector],
    (offeringOrderData) => {
        return offeringOrderData.serviceFeature;
    }
);

export const StandalonesSelector = createImmutableSelector(
    [OfferingOrderDataSelector],
    (offeringOrderData) => {
        return offeringOrderData.standalones;
    }
);

export const ServiceFeatureProductsSelector = createImmutableSelector(
    [ServiceFeatureSelector],
    (serviceFeature) => {
        return serviceFeature.products;
    }
);

export const ProductMetadataStatusSelector = createImmutableSelector(
    [ServiceFeatureSelector],
    (serviceFeature) => {
        return serviceFeature.productMetadataLoaded;
    }
);

export const ServiceFeatureProductsFormattedSelector = createImmutableSelector(
    [ServiceFeatureProductsSelector],
    (serviceFeatureProducts) => {
        return Object.values(serviceFeatureProducts);
    }
);

export const ServiceFeatureOrdereablePricingPlansSelector = createImmutableSelector(
    [ServiceFeatureSelector],
    (serviceFeature) => {
        return serviceFeature.orderablePricingPlansByProductId;
    }
);

export const ServiceFeaturesCardinalitySelector = createImmutableSelector(
    [ServiceFeatureSelector],
    (serviceFeature) => {
        return serviceFeature.serviceFeatureCardinality;
    }
);

export const ServiceFeatureProductsMetadataSelector = createImmutableSelector(
    [ServiceFeatureSelector],
    (serviceFeature) => {
        return serviceFeature.productMetadataByProductId;
    }
);

export const StandaloneProductsMetadataSelector = createImmutableSelector(
    [StandalonesSelector],
    (standalones) => {
        return standalones.productMetadataByProductId;
    }
);

const getOrderablePricingPlans = (metadata, orderablePricingPlans, hasPriceOverrideAccess) => {
    const filteredPricingPlans = (metadata.PricingPlans || EMPTY_ARRAY).filter((pricingPlan) => {
        return (orderablePricingPlans || EMPTY_ARRAY).some((orderablePricingPlan) => {
            return orderablePricingPlan.Id === pricingPlan.Id;
        });
    });

    filteredPricingPlans.forEach((filteredPricingPlan) => {
        const orderablePricingPlan = orderablePricingPlans.find((orderablePricingPlan) => {
            return filteredPricingPlan.Id === orderablePricingPlan.Id;
        });
        if (orderablePricingPlan) {
            const pricingPlanRAndOBRIs = filteredPricingPlan && filteredPricingPlan.PricingPlanBillerRuleInstances ?
                [...filteredPricingPlan.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances,
                    ...filteredPricingPlan.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances] : [];
            filteredPricingPlan.billerRuleInstances  = orderablePricingPlan.BillerRuleInstanceThumbnails.map((brit) => {
                brit.chargeAmount = brit.Amount;
                brit.discountAmount = brit.DiscountAmount;
                brit.hasDiscount = !!brit.DiscountAmount;
                brit.name = pricingPlanRAndOBRIs.find(({BillerRuleConfigurationId}) => {
                    return brit.BillerRuleConfigurationId === BillerRuleConfigurationId;
                }).Name;
                brit.isOverrideable = hasPriceOverrideAccess && pricingPlanRAndOBRIs.some((pricingPlanBRI) => {
                    return pricingPlanBRI.Id === brit.Id && pricingPlanBRI.AllowChargeOverride;
                });
                brit.isOverrideSelected = false;
                return brit;
            });
        }
    });
    return filteredPricingPlans;

};

export const ServiceFeaturesViewModelSelector = createImmutableSelector(
    [
        ServiceFeatureProductsFormattedSelector,
        ServiceFeatureOrdereablePricingPlansSelector,
        ServiceFeatureProductsMetadataSelector,
        ServiceFeaturesCardinalitySelector,
        UserSecurityAttributesSelector,
        ProductMetadataStatusSelector
    ],
    (products, orderablePricingPlans, productsMetadata, serviceCardinality, userSecurityAttributes, isProductMetadataLoaded) => {
        return products.map((product) => {
            //Todo: Implement hydration / selected pricing plans in ASC-101329 and tie into serviceFeatures component
            const metadata = productsMetadata[product.Id] || EMPTY_OBJECT;
            const pricingPlans = orderablePricingPlans[product.Id];

            const hasPricingPlanInformation = !!(product.Id && Object.keys(orderablePricingPlans).length &&
                Object.keys(metadata).length);

            return {
                description: metadata.Description,
                hasPricingPlanInformation: hasPricingPlanInformation,
                id: product.Id,
                max: product.MaxNonBulkQuantity,
                maximumQuantity: serviceCardinality[product.Id] ? serviceCardinality[product.Id].MaximumQuantity : null,
                minimumQuantity: serviceCardinality[product.Id] ? serviceCardinality[product.Id].MinimumQuantity : null,
                name: product.Name,
                pricingPlans: hasPricingPlanInformation ?
                    getOrderablePricingPlans(clone(metadata), clone(pricingPlans), hasAccess(userSecurityAttributes, PRICE_OVERRIDE_ACCESS)) :
                    EMPTY_ARRAY,
                showNoPricingPlanMessage: isProductMetadataLoaded &&  !pricingPlans?.length
            };
        });
    }
);

export const IsSendingInventoryToStoreSelector = createImmutableSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.isSendingInventoryToStore;
    }
);

export const InventoryStorePickupDetailsSelector = createImmutableSelector(
    [OfferingOrderDataSelector],
    (offeringOrderData) => {
        return offeringOrderData.inventoryStorePickupDetails;
    }
);

export const SearchInventoryAvailabilityErrorSelector = createImmutableSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.searchInventoryAvailabilityError;
    }
);

export const IsUpdatingMultiOfferShoppingCart = createSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.isUpdatingMultiOfferShoppingCart;
    }
);

export const OfferingOrderOffCycleAlignmentSelector = createSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.offCycleAlignmentOptions;
    }
);

export const SelectedOrderExecutionOptionsSelector = createImmutableSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder && offeringOrder.executionOptions || EMPTY_OBJECT;
    }
);

export const SelectedOrderDetailsSelector = createImmutableSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder && offeringOrder.orderDetails || EMPTY_OBJECT;
    }
);


export const DbssShippingEnabledRegionIdSelector = createImmutableSelector(
    [MetadataCodeTypeSelector(CODES.InventoryRegion)],
    (inventoryRegions) => {
        const shippingEnabledInventoryRegion =  (inventoryRegions || []).find((region) => {
            return region.AdditionalProperties.find((additionalProperty) => {
                return (additionalProperty.Key === 'is_shipping_available') &&
                    additionalProperty.Value === 'True';
            });
        });

        return shippingEnabledInventoryRegion ? shippingEnabledInventoryRegion.Value : undefined;
    }
);

export const SelectedDeliveryDecisionSelector = createImmutableSelector(
    [OfferingOrderSelector],
    (offeringStore) => {
        return offeringStore.selectedDeliveryDecision;
    }
);


export const DbssShippingWarehouseIdSelector = createImmutableSelector(
    [MetadataCodeTypeSelector(CODES.Stores), DbssShippingEnabledRegionIdSelector],
    (stores, shippingEnabledRegionId) => {
        const warehouse = stores.find((store) => {
            return store.AdditionalProperties.find((additionalProperty) => {
                return (additionalProperty.Key === 'region_code') && additionalProperty.Value === shippingEnabledRegionId;
            });
        });
        return (warehouse || {}).Value;
    }
);

export const SelectedShippingAddressAndMethodSelector = createImmutableSelector(
    [OfferingOrderSelector],
    (offeringStore) => {
        return offeringStore.selectedAddressDetails || undefined;
    }
);

export const SelectedShippingAddressFromDropDownSelector = createSelector(
    [OfferingOrderSelector],
    (offeringStore) => {
        return offeringStore.setSelectedShippingAddress || DROP_DOWN_DEFAULT_ADDRESS;
    }
);

export const UpdatedShippingAddressSelector = createSelector(
    [OfferingOrderSelector],
    (offeringStore) => {
        return offeringStore.setUpdatedShippingAddress || DROP_DOWN_DEFAULT_ADDRESS;
    }
);

export const InventoryRegionWithoutWarehouseSelector = createImmutableSelector([
    MetadataCodeTypeSelector(CODES.InventoryRegion)
], (regions) => {
    const filteredRegions = regions.filter((region) => {
        return region.AdditionalProperties.find((item) => {
            return item.Key === 'is_shipping_available' && item.Value === 'False';
        });
    });
    return filteredRegions && filteredRegions.map((code) => {
        return {
            label: code.Name,
            value: code.Value
        };
    });
});


