import {findIndex} from 'ramda';

// This wizard helper is only for the logic pertaining to the wizard itself, do not include business type specific logic

export const getNextStepIndex = (allStepsKeys, skippedSteps, currentStepIndex) => {
    const nextStepKey = allStepsKeys.reduce((firstFound, stepKey, stepIndex) => {
        if (!firstFound && foundPreviousStep(stepIndex, currentStepIndex, skippedSteps, stepKey)) {
            firstFound = stepKey;
        }

        return firstFound;
    }, null);

    return Math.min(findIndex((stepKey) => {
        return stepKey === nextStepKey;
    }, allStepsKeys), allStepsKeys.length - 1);
};

const foundPreviousStep = (stepIndex, currentStepIndex, skippedSteps, stepKey) => {
    const isStepSkipped = skippedSteps.some((skippedStep) => {
        return skippedStep === stepKey;
    });

    return stepIndex > currentStepIndex && !isStepSkipped;
};

export const getPreviousStepIndex = (allStepsKeys, completedSteps, currentStepIndex) => {
    const previousStepKey = allStepsKeys.reduce((lastFound, stepKey, stepIndex) => {
        if (stepIndex < currentStepIndex && completedSteps[stepKey]) {
            lastFound = stepKey;
        }

        return lastFound;
    }, null);

    if (previousStepKey) {
        return Math.max(findIndex((stepKey) => {
            return stepKey === previousStepKey;
        }, allStepsKeys), 0);
    } else {
        return previousStepKey;
    }
};
