export const BILLING_PAYMENT_TYPE_CODES = {
    ADJUSTMENT :3,
    ALL : 0,
    CREDIT_NOTE: 5,
    DEBIT_NOTE: 6,
    INVOICE_SUMMARY : 1,
    PAYMENT : 2
};

export const INVOICE_TYPE = {
    PERIODIC: 1,
    ONDEMAND: 2
};

export const ADJUSTMENT_REASON_TYPE_CODE = 391;
export const ADJUSTMENT_TYPE_TYPE_CODE = 390;

export const NA = 'N/A';

export const PAYMENT_TYPE_CODES = {
    POINT_OF_SALE: 20
};

export const INVOICE_ITEM_TYPES = {
    INVOICE_ITEM : 1,
    TAX_ITEM : 2,
    USAGE_SUMMARY : 3,
    BILLING_FEES: 4,
    LATE_PAYMENT_FEE: 5
};

export const ACCOUNTING_METHODS_NAMES = {
    BALANCE_FORWARD: 1,
    OPEN_INVOICE: 2
};

export const MISC_ADJUSTMENT_TYPE_CODE = 1002;
export const CREDIT_NOTE_TYPE_CODE = 1006;
