import Immutable from 'seamless-immutable';
import {
    CUSTOMER_SEARCH,
    CUSTOMER_SEARCH_SUGGESTIONS
} from './actions/search.customer.actions';
import {
    populateCustomerSearchResultsMap,
    transformSearchCustomerSuggestions
} from './search.customer.reducer.helper';

export const INITIAL_STATE = Immutable({
    data: [],
    pageCount: null,
    pageNumber: null,
    recordCount: null,
    suggestions: {
        data: [],
        isLoading: false
    }
});

export default function customerSearchReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case CUSTOMER_SEARCH.BEGIN:
            return state.set('data', [])
                .set('pageCount', 0)
                .set('pageNumber', 0)
                .set('recordCount', -1);
        case CUSTOMER_SEARCH.SUCCESS:
            return state.set('data', populateCustomerSearchResultsMap(payload.Subscribers || []))
                .set('pageCount', payload.PageCount || 0)       /* if no results returned, then these fields will */
                .set('pageNumber', payload.PageNumber || 1)     /* not be in the response, so I need to supply */
                .set('recordCount', payload.RecordCount || 0);  /* default values. */
        case CUSTOMER_SEARCH_SUGGESTIONS.BEGIN:
            return state.setIn(['suggestions', 'isLoading'], true);
        case CUSTOMER_SEARCH_SUGGESTIONS.FAILURE:
            return state.setIn(['suggestions', 'isLoading'], false);
        case CUSTOMER_SEARCH_SUGGESTIONS.SUCCESS:
            return state.setIn(['suggestions', 'isLoading'], false)
                .setIn(['suggestions', 'data'], transformSearchCustomerSuggestions(payload));
        default:
            return state;
    }
}
