import {values} from 'ramda';
import ApiConstants from 'invision-core/src/api/constants';
import thunkHelper from 'invision-core/src/api/thunk.helper';
import {buildSearchBulkServicesRequest} from '../helpers/bulk.services.action.helper';
import {NUMBER_OF_ENTITLEMENTS_TO_DISPLAY} from '../../components/customer/dashboard/dbssDashboard/servicesAndOffers/services.and.offers.contants';

const SERVICE_ACTION_URL = {
    SUSPEND_SERVICES: 'SuspendServices',
    RESUME_SERVICES: 'RestoreServices'
};

export const SET_BULK_SERVICES_STATUS_FILTER = 'SET_BULK_SERVICES_STATUS_FILTER';
export const setBulkServicesStatusFilter = (includeStatusFilter) => {
    return {
        type: SET_BULK_SERVICES_STATUS_FILTER,
        payload: includeStatusFilter
    };
};

export const SET_CURRENT_PAGE = 'SET_BULK_SERVICES_CURRENT_PAGE';
export const setCurrentPage = (page) => {
    return {
        type: SET_CURRENT_PAGE,
        payload: page
    };
};

export const SET_BULK_SERVICES_SEARCH_STRING = 'SET_BULK_SERVICES_SEARCH_STRING';
export const setBulkServicesSearchString = (searchString) => {
    return {
        type: SET_BULK_SERVICES_SEARCH_STRING,
        payload: searchString
    };
};

export const SET_BULK_SERVICES_PAGE_SIZE = 'SET_BULK_SERVICES_PAGE_SIZE';
export const setBulkServicesPageSize = (pageSize) => {
    return {
        type: SET_BULK_SERVICES_PAGE_SIZE,
        payload: pageSize
    };
};

export const REMOVE_BULK_SERVICES_STATUS_FILTER = 'REMOVE_BULK_SERVICES_STATUS_FILTER';
export const removeBulkServicesStatusFilter = () => {
    return {
        type: REMOVE_BULK_SERVICES_STATUS_FILTER
    };
};

export const SEARCH_SERVICES_CONSTANTS = {
    BEGIN: 'SEARCH_SERVICES_BEGIN',
    SUCCESS: 'SEARCH_SERVICES_SUCCESS',
    FAILURE: 'SEARCH_SERVICES_FAILURE'
};
export const searchServicesPromise = (dispatch, params, requestObj) => {
    return thunkHelper(dispatch, values(SEARCH_SERVICES_CONSTANTS), {
        method: 'post',
        url: 'SubscriberManagement/SearchServices',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: params.customerId
        },
        data: buildSearchBulkServicesRequest(params)
    }, requestObj);
};
export const searchServices = (params, requestObj) => {
    return (dispatch) => {
        return searchServicesPromise(dispatch, params, requestObj);
    };
};

export const SUSPEND_RESUME_SERVICES_CONSTANTS = {
    BEGIN: 'SUSPEND_RESUME_SERVICES_BEGIN',
    SUCCESS: 'SUSPEND_RESUME_SERVICES_SUCCESS',
    FAILURE: 'SUSPEND_RESUME_SERVICES_FAILURE'
};

const suspendOrResumeServicesPromise = (dispatch, customerId, requestObj, isActive) => {
    return thunkHelper(dispatch, values(SUSPEND_RESUME_SERVICES_CONSTANTS), {
        method: 'post',
        url: `SubscriberManagement/${isActive ? SERVICE_ACTION_URL.SUSPEND_SERVICES : SERVICE_ACTION_URL.RESUME_SERVICES}`,
        data: requestObj,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};
export const suspendOrResumeServices = (customerId, requestObj, isActive) => {
    return (dispatch) => {
        return suspendOrResumeServicesPromise(dispatch, customerId, requestObj, isActive);
    };
};

export const SET_SERVICES_TYPE_OF_SERVICE_FILTER = 'SET_SERVICES_TYPE_OF_SERVICE_FILTER';
export const setServicesTypeOfServiceFilter = (includeTypeOfServiceFilter) => {
    return {
        type: SET_SERVICES_TYPE_OF_SERVICE_FILTER,
        payload: includeTypeOfServiceFilter
    };
};

export const SET_SELECTED_SERVICE_IDENTIFIER = 'SET_SELECTED_SERVICE_IDENTIFIER';
export const setSelectedServiceIdentifier = (serviceIdentifier) => {
    return {
        type: SET_SELECTED_SERVICE_IDENTIFIER,
        payload: serviceIdentifier
    };
};

export const SET_SELECTED_ACCOUNT_LEVEL = 'SET_SELECTED_ACCOUNT_LEVEL';
export const setSelectAccountLevel = (selectTab) => {
    return {
        type: SET_SELECTED_ACCOUNT_LEVEL,
        payload: selectTab
    };
};

export const RETRIEVE_SERVICE_DETAILS_CONSTANTS = {
    BEGIN: 'RETRIEVE_SERVICE_DETAILS_BEGIN',
    SUCCESS: 'RETRIEVE_SERVICE_DETAILS_SUCCESS',
    FAILURE: 'RETRIEVE_SERVICE_DETAILS_FAILURE'
};
export const retrieveServiceDetailsPromise = (dispatch, customerId, serviceIdentifiers=[]) => {
    return thunkHelper(dispatch, values(RETRIEVE_SERVICE_DETAILS_CONSTANTS), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveConvergentBillerServiceDetails',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            IncludePaymentInstrumentAssociations: true,
            IncludePolicyCounters: true,
            MaxNumberOfServiceEntitlements: NUMBER_OF_ENTITLEMENTS_TO_DISPLAY,
            MaxNumberOfSharedEntitlements: NUMBER_OF_ENTITLEMENTS_TO_DISPLAY,
            ServiceIdentifiers: serviceIdentifiers
        }
    });
};
export const retrieveServiceDetails = (customerId, serviceIdentifiers) => {
    return (dispatch) => {
        return retrieveServiceDetailsPromise(dispatch, customerId, serviceIdentifiers);
    };
};

export const UPDATE_CUSTOMIZED_SERVICE_TAX = {
    BEGIN: 'UPDATE_CUSTOMIZED_SERVICE_TAX_BEGIN',
    SUCCESS: 'UPDATE_CUSTOMIZED_SERVICE_TAX_SUCCESS',
    FAILURE: 'UPDATE_CUSTOMIZED_SERVICE_TAX_FAILURE'
};
export const updateCustomizedServiceTaxPromise = (dispatch, customizedServiceTax, customerId) => {
    return thunkHelper(dispatch, values(UPDATE_CUSTOMIZED_SERVICE_TAX), {
        method: 'post',
        url: 'SubscriberManagement/UpdateServiceTaxCustomization',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            ServiceTaxCustomization: customizedServiceTax.ServiceTaxCustomization
        }
    });
};
export const updateCustomizedServiceTax = (customizedServiceTax, customerId) => {
    return (dispatch) => {
        return updateCustomizedServiceTaxPromise(dispatch, customizedServiceTax, customerId);
    };
};