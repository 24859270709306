/* eslint-disable linebreak-style */
import Immutable from 'seamless-immutable';
import {
    SET_SHARED_ENTITLEMENTS_CURRENT_PAGE,
    SET_SHARED_ENTITLEMENTS_PAGE_SIZE
} from '../actions/shared.entitlements.actions';
export const INITIAL_STATE = Immutable({
    pageNumber: 1,
    pageSize: 25
});

export default function SharedEntitlementsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case SET_SHARED_ENTITLEMENTS_PAGE_SIZE:
            return state.set('pageSize', payload);
        case SET_SHARED_ENTITLEMENTS_CURRENT_PAGE:
            return state.set('pageNumber', payload);
        default:
            return state;
    }
}
