import {createSelector} from 'reselect';
import {SelectedCustomerSelector} from './customer.selectors';

export const CurrentRemarksSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.remarks.data.asMutable({
            deep: true
        });
    }
);

export const IsFetchingRemarksSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.remarks.isFetchingRemarks;
    }
);

export const IsUpdatingRemarkSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.remarks.isUpdatingRemark;
    }
);

export const IsCreatingRemarkSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.remarks.isCreatingRemark;
    }
);

export const IsRemarksServiceAvailableSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.remarks.isServiceAvailable;
    }
);

export const RemarksLastAttemptErrorSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.remarks.remarksErrors;
    }
);

export const RemarkToEditSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        const remarkToEdit = selectedCustomer.remarks.remarkToEdit;
        return remarkToEdit ? remarkToEdit.asMutable({
            deep: true
        }) : null;
    }
);
