import {stateGo} from 'redux-ui-router';
import {
    ApiConstants,
    InjectorHelper,
    SessionSelectors,
    ThunkHelper
} from 'invision-core';
import {
    CouponSearchRecordCountSelector
} from '../selectors/search.coupon.selectors';
import {
    CurrentCouponRedemptionCodeSelector
} from '../selectors/coupon.redemption.code.selectors';
import {
    hideSearchPanel
} from './search.actions';

export const COUPON_SEARCH = {
    BEGIN: 'COUPON_SEARCH_BEGIN',
    SUCCESS: 'COUPON_SEARCH_SUCCESS',
    FAILURE: 'COUPON_SEARCH_FAILURE'
};
const COUPON_SEARCH_EVENTS = [COUPON_SEARCH.BEGIN, COUPON_SEARCH.SUCCESS, COUPON_SEARCH.FAILURE];


export const SET_COUPON_SEARCH_MODEL = 'SET_COUPON_SEARCH_MODEL';
export const setCouponSearchModel = (payload) => {
    return {
        type: SET_COUPON_SEARCH_MODEL,
        payload: payload
    };
};

export const RESET_SEARCH_COUPON_MODEL_RECOVERABLE = 'RESET_SEARCH_COUPON_MODEL_RECOVERABLE';
export const resetCouponSearchRecoverable = () => {
    return {
        type: RESET_SEARCH_COUPON_MODEL_RECOVERABLE
    };
};

export const getCouponSearchByIdPromise = (dispatch, redemptionCodeId, pageNumber, pageSize) => {
    const data = {
        CouponRedemptionCodeId: redemptionCodeId
    };

    return getCouponSearchPromise(dispatch, data, pageNumber, pageSize);
};

export const getCouponSearchByRedemptionCodePromise = (dispatch, redemptionCode, pageNumber, pageSize) => {
    const data = {
        RedemptionCode: redemptionCode
    };

    return getCouponSearchPromise(dispatch, data, pageNumber, pageSize);
};


const getCouponSearchPromise = (dispatch, data, pageNumber, pageSize, events = COUPON_SEARCH_EVENTS) => {
    const $ngRedux = InjectorHelper.getNgReduxService();
    const state = $ngRedux.getState();
    return ThunkHelper(dispatch, events, {
        method: 'post',
        url: 'SubscriberManagement/SearchCouponRedemptions',
        headers: {
            [ApiConstants.CUSTOM_SUBSCRIBER_LANGUAGE_HEADER]: SessionSelectors.LanguageHeaderSelector(state)
        },
        data: data
    });
};

export const getCouponSearch = (couponSearchModel, pageNumber, pageSize) => {
    return (dispatch, getState) => {
        let searchTask;
        if (couponSearchModel.redemptionCode) {
            searchTask = getCouponSearchByRedemptionCodePromise(dispatch, couponSearchModel.redemptionCode, pageNumber, pageSize);
        } else if (couponSearchModel.redemptionCodeId) {
            searchTask = getCouponSearchByIdPromise(dispatch, couponSearchModel.redemptionCodeId, pageNumber, pageSize);
        }

        return searchTask.then(() => {
            if (CouponSearchRecordCountSelector(getState()) === 1) {
                const couponCodeThumbnail = CurrentCouponRedemptionCodeSelector(getState());

                if (!couponCodeThumbnail.CouponRedemptionCode.SubscriberId) {
                    dispatch(routeToCouponCode(couponCodeThumbnail));
                } else {
                    dispatch(routeToCustomerCouponCode(couponCodeThumbnail));
                }
            }
        });
    };
};

export const routeToCouponCode = (couponCodeThumbnail) => {
    // Defaults state and params
    const state = 'index.customercare.coupon';
    const params = {
        couponRedemptionCodeId: couponCodeThumbnail.CouponRedemptionCode.Id
    };

    return (dispatch) => {
        dispatch(stateGo(state, params, {
            reload: true
        }));
        dispatch(hideSearchPanel());
    };
};

export const routeToCustomerCouponCode = (couponCodeThumbnail) => {
    // Defaults state and params
    const state = 'index.customercare.customer.coupons.details';
    const params = {
        customerId: couponCodeThumbnail.CouponRedemptionCode.SubscriberId,
        couponRedemptionCodeId: couponCodeThumbnail.CouponRedemptionCode.Id
    };

    return (dispatch) => {
        dispatch(stateGo(state, params, {
            reload: true
        }));
        dispatch(hideSearchPanel());
    };
};
