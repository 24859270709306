import Immutable from 'seamless-immutable';
import {
    SET_SELECTED_POLICY_COUNTER,
    UPDATE_POLICY_COUNTER_BALANCE
} from './actions/edit.policy.counter.modal.actions';

export const INITIAL_STATE = Immutable({
    isLoading: false,
    data: {},
    selectedPolicyCounter: {}
});

export default function EditPolicyCounterModalReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case SET_SELECTED_POLICY_COUNTER:
            return state.set('selectedPolicyCounter', payload);
        case UPDATE_POLICY_COUNTER_BALANCE.BEGIN:
            return state.set('isLoading', true);
        case UPDATE_POLICY_COUNTER_BALANCE.SUCCESS:
            return state
                .set('isLoading', false)
                .set('data', payload);
        case UPDATE_POLICY_COUNTER_BALANCE.FAILURE:
            return state.set('isLoading', false);
        default:
            return state;
    }
}
