import {createSelector} from 'reselect';
import clone from 'ramda/src/clone';
import descend from 'ramda/src/descend';
import filter from 'ramda/src/filter';
import findIndex from 'ramda/src/findIndex';
import path from 'ramda/src/path';
import pathOr from 'ramda/src/pathOr';
import propEq from 'ramda/src/propEq';
import sortWith from 'ramda/src/sortWith';
import take from 'ramda/src/take';
import i18n from 'invision-core/src/components/i18n/i18n';
import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import {DiscountsMetadataMapSelector} from 'invision-core/src/components/metadata/discounts/discounts.selectors';
import {getDiscountTooltipModel} from '../../components/shared/tooltipTemplates/discount.tooltip.helper';
import CustomerCareKeys from '../../locales/keys';
import {SelectedCustomerSelector} from './customer.selectors';
import {
    ACTIVE_PRODUCTS_TO_DISPLAY,
    ORDER_ITEM_STATUS,
    RECENT_PRODUCTS_TO_DISPLAY
} from '../constants/dashboard.constants';
import {
    BILLER_RULE_QUANTITY_PRICE_TYPE
} from './../../customercare.constants';
import {
    PRODUCT_AVAILABLE_DELIVERY_CAPABILITY_ACTIONS,
    PRODUCT_CLASSIFICATIONS
} from 'invision-core/src/constants/product.constants';
import {getPoliciesWithFriendlyNames} from './customer.product.detail.selectors';
import Immutable from 'seamless-immutable';
import {StandaloneProductsMetadataSelector} from './offering.order.selectors';

const recoverableUIStateSelector = (state) => {
    return state.customercare.recoverableUiState.products;
};

export const CustomerProductsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        if (selectedCustomer.products) {
            return selectedCustomer.products.data;
        }
    }
);

export const CustomerProductsCollectionSelector = createSelector(
    [CustomerProductsSelector],
    (products) => {
        if (products && products.LockerItems) {
            return products.LockerItems;
        } else {
            return null;
        }
    }
);

export const CustomerRecentProductsCollectionSelector = createSelector(
    [CustomerProductsCollectionSelector],
    (products) => {
        if (products) {
            return products.slice(0, RECENT_PRODUCTS_TO_DISPLAY);
        } else {
            return null;
        }
    }
);

export const ActiveProductSelector = createSelector(
    [SelectedCustomerSelector, CustomerRecentProductsCollectionSelector,
        MetadataSelectors.codes.MetadataCodeSelector(
            MetadataConstants.codes.ProductLineOfBusinessDeliveryCapabilityConfigurationAction)
    ],
    (selectedCustomer, products, ProductLineOfBusinessDeliveryCapabilityConfigurationAction) => {
        if (products && products.length) {
            const activeProduct = selectedCustomer.products.activeProduct || products[0];

            if (ProductLineOfBusinessDeliveryCapabilityConfigurationAction) {
                return activeProduct.setIn(['ProductContext', 'PurchasePolicies'],
                    getPoliciesWithFriendlyNames(activeProduct, ProductLineOfBusinessDeliveryCapabilityConfigurationAction));
            } else {
                return activeProduct;
            }
        } else {
            return null;
        }
    }
);

export const CanViewContentActivitySelector = createSelector(
    [ActiveProductSelector],
    (productDetail) => {
        return !pathOr(0, ['ProductContext', 'AvailableDeliveryCapabilityActions', 'length'], productDetail) ?
            false :
            productDetail.ProductContext.AvailableDeliveryCapabilityActions.some((action) => {
                return action.Code === PRODUCT_AVAILABLE_DELIVERY_CAPABILITY_ACTIONS.DOWNLOAD ||
                    action.Code === PRODUCT_AVAILABLE_DELIVERY_CAPABILITY_ACTIONS.PLAYBACK;
            });
    }
);

export const CustomerProductsCountSelector = createSelector(
    [CustomerProductsSelector],
    (products) => {
        if (products && products.RecordCount) {
            return products.RecordCount;
        } else {
            return 0;
        }
    }
);

const DEFAULT_TABLE_DATA = [];
export const TableDataSelector = createSelector(
    [CustomerProductsCollectionSelector],
    (productsCollection) => {
        if (productsCollection) {
            return productsCollection.map((product) => {
                return Object.assign({}, product, {
                    Class: productClassConverter(product),
                    Offer: product.OfferName,
                    OrderDate: product.Created,
                    PricingPlan:  pathOr({}, ['PricingPlan'], product),
                    ProductName: pathOr('', ['Product', 'Name'], product),
                    Service: pathOr('', ['PricingPlan', 'ServiceTiers', 0, 'ServiceName'], product)
                });
            });
        }

        return DEFAULT_TABLE_DATA;
    }
);

const productClassConverter = (productObject) => {
    const classification = pathOr('', ['Product', 'ProductClassification'], productObject);
    switch (classification) {
        case PRODUCT_CLASSIFICATIONS.NONE:
            return i18n.translate(CustomerCareKeys.NONE);
        case PRODUCT_CLASSIFICATIONS.PRODUCT:
            return i18n.translate(CustomerCareKeys.PRODUCT);
        case PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE:
            return i18n.translate(CustomerCareKeys.SERVICE_FEATURE);
        case PRODUCT_CLASSIFICATIONS.AD_HOC:
            return i18n.translate(CustomerCareKeys.AD_HOC);
        default:
            return null;
    }
};

export const IncludeExpiredSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.filterData.includeExpired;
    }
);

export const IncludeRemovedSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.filterData.includeRemoved;
    }
);

export const IncludeSearchSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.filterData.includeSearch;
    }
);

export const SelectedPageSizePreference = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.filterData.pageSizePreference;
    }
);

export const ProductClassificationSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.filterData.classification ? [uiState.filterData.classification] : null;
    }
);

export const IsFetchingProductsDataSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        if (selectedCustomer.products) {
            return selectedCustomer.products.isFetchingData;
        }
    }
);

export const IsRecentProductsDataSelector = createSelector(
    [CustomerProductsSelector],
    (products) => {
        if (products) {
            return (products.RecordCount > RECENT_PRODUCTS_TO_DISPLAY) && (products.LockerItems.length <= RECENT_PRODUCTS_TO_DISPLAY);
        } else {
            return false;
        }
    }
);

export const CurrentPageNumberSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.pageNumber || 0;
    }
);

export const RecordCountSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.products.recordCount || 0;
    }
);

export const TotalPagesSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.products.pageCount;
    }
);

export const IsRemovingProductSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.products ? selectedCustomer.products.isRemovingProduct : null;
    }
);

export const SortCriteriaSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return pathOr(null, ['filterData', 'sortCriteria'], uiState);
    }
);

export const CustomerActiveProductsSelector = createSelector(
    [SelectedCustomerSelector, StandaloneProductsMetadataSelector, DiscountsMetadataMapSelector],
    (selectedCustomer, standalonesProductsMetadata, discountsMetadataMap) => {
        const standaloneProducts = pathOr([], ['products', 'activeProducts'], selectedCustomer);
        const allOrderItems = standaloneProducts.reduce((result, standalone) => {
            const product = standalonesProductsMetadata[standalone.ProductId];

            if (product) {
                const activeProductPricingPlan = product.PricingPlans.find((pricingPlan) => {
                    return pricingPlan.Id === standalone.PricingPlanId;
                });
                result.push({
                    AdHocOverrideDetails: standalone.InvoiceText || standalone.Amount || standalone.Amount === 0 ? {
                        Amount: standalone.Amount,
                        InvoiceText: standalone.InvoiceText
                    } : null,
                    Amount: standalone.Amount || 0,
                    BillerRulePrices: standalone.BillerRulePrices,
                    BillerTypeAmounts: standalone.BillerTypeAmounts,
                    Connected: standalone.ConnectedDate,
                    DiscountAmount: standalone.DiscountAmount || 0,
                    Discounts: (standalone.Discounts || []).map((discount) => {
                        return getDiscountTooltipModel(discount, activeProductPricingPlan.Currency,
                            discountsMetadataMap);
                    }),
                    GrossAmount: standalone.TotalAmount || 0,
                    Id: standalone.OrderItemId,
                    IsStandalone: product.Standalone,
                    LockerItemId: standalone.SubscriberProductId,
                    OfferingOptionPriceId: standalone.OfferingOptionPriceId,
                    Product: product,
                    PricingPlan: activeProductPricingPlan,
                    Status: standalone.Status,
                    SubscriberProductExpiration: standalone.SubscriberProductExpiration,
                    Quantity: standalone.BulkQuantity
                });
            }
            return result;
        }, []);

        return filter((item) => {
            return (
                item.Status === ORDER_ITEM_STATUS.ACTIVE) &&
                // Only show ACTIVE StandAlone Products and Adhoc products with BillerTypeAmounts Populated(Temporary Fix)
                (
                    item.Product.ProductClassification === PRODUCT_CLASSIFICATIONS.PRODUCT
                    || (item.Product.ProductClassification === PRODUCT_CLASSIFICATIONS.AD_HOC && item.BillerTypeAmounts && item.BillerTypeAmounts.length)
                    || item.PricingPlan.BillerRuleInstances.length === 0
                );
        }, allOrderItems);
    }
);

export const CustomerActiveProductsDisplayItemsSelector = createSelector(
    [CustomerActiveProductsSelector],
    (activeProducts) => {
        return activeProducts && activeProducts.length ? getMappedActiveProducts(activeProducts) : null;
    }
);

const getMappedActiveProducts = (activeProducts) => {

    const getAdHocOverrideAmount = (pricingPlan) => {
        // Checking the first BillerRuleInstances in the array because an adHoc product can only have one Bri.
        return pathOr(null, ['BillerRuleInstances', 0, 'Amount'], pricingPlan);
    };

    const dbss = (clone(activeProducts).filter(({PricingPlan:{BillerRuleInstances=[]}}) => {
        return BillerRuleInstances.length;
    }));

    const ott = (clone(activeProducts).filter(({PricingPlan:{BillerRuleInstances=[]}}) => {
        return !BillerRuleInstances.length;
    }));

    const mappedOtt = ott.map(({Id, LockerItemId, PricingPlan, Product, GrossAmount, DiscountAmount}) => {

        return {
            Id,
            LockerItemId,
            PricingPlan,
            Product,
            Quantity: 1,
            Totals: {
                Sum: GrossAmount,
                Discount: DiscountAmount
            }
        };
    });

    const getProductTotalQuantity = (OfferingOptionPriceId) => {
        const items = filter(propEq(OfferingOptionPriceId, 'OfferingOptionPriceId'))(dbss);
        return items.length;
    };
    const getProductIncrementalQuantity = (OfferingOptionPriceId, Id) => {
        return findIndex(propEq(Id, 'Id'), filter(propEq(OfferingOptionPriceId, 'OfferingOptionPriceId'))(dbss)) + 1;
    };

    const getProductTotalAndDiscount = (bris, bulkDetails, itemTotalQuantity, itemIncrementalQuantity) => {
        // TODO This front end math needs to be removed
        return bris.reduce((prev, {DiscountAmount, Amount, Bulk, QuantityPricingTypeCode, StartQuantity, EndQuantity, BillerRuleConfigurationId}) => {
            let sum = 0;
            if (Bulk) {
                sum = pathOr(0, ['Amount'], bulkDetails.find((item) => {
                    return item.BillerRuleConfigurationId === BillerRuleConfigurationId;
                }));
            } else {
                if (QuantityPricingTypeCode === BILLER_RULE_QUANTITY_PRICE_TYPE.TIERED) {
                    sum = (itemIncrementalQuantity >= StartQuantity && itemIncrementalQuantity <= EndQuantity) ? Amount : 0;
                } else if (QuantityPricingTypeCode === BILLER_RULE_QUANTITY_PRICE_TYPE.TAPERED) {
                    sum = (itemTotalQuantity >= StartQuantity && itemTotalQuantity <= EndQuantity) ? Amount : 0;
                } else {
                    sum = Amount;
                }
            }

            return Object.assign(prev, {
                Sum: prev.Sum + sum,
                Discount: prev.Discount + DiscountAmount
            });
        }, {
            Sum: 0,
            Discount: 0
        });
    };

    const mappedDbss = dbss.map(({AdHocOverrideDetails, Id, LockerItemId, OfferingOptionPriceId, PricingPlan, Product,
        Connected, Quantity, BillerRulePrices=[], SubscriberProductExpiration, Discounts}) => {
        PricingPlan.BillerRuleInstances = PricingPlan.BillerRuleInstances.filter((bri) => {
            return bri.Amount !== null;
        });
        const itemTotalQuantity = getProductTotalQuantity(OfferingOptionPriceId);
        const itemIncrementalQuantity = getProductIncrementalQuantity(OfferingOptionPriceId, Id);
        const defaultOverrideDetails = {
            Amount: getAdHocOverrideAmount(PricingPlan)
        };
        return {
            AdHocOverrideDetails: AdHocOverrideDetails ?
                Object.assign({}, defaultOverrideDetails, AdHocOverrideDetails) :
                AdHocOverrideDetails,
            Discounts,
            Id,
            LockerItemId,
            PricingPlan,
            Product,
            Quantity,
            Totals: getProductTotalAndDiscount(PricingPlan.BillerRuleInstances, BillerRulePrices, itemTotalQuantity, itemIncrementalQuantity),
            Dates: {
                Connected: Connected
            },
            SubscriberProductExpiration
        };
    });

    return take(ACTIVE_PRODUCTS_TO_DISPLAY, sortWith([
        descend(path(['Dates', 'Ordered']))
    ], mappedDbss.concat(mappedOtt)));
};

export const IsFetchingActiveProductsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        if (selectedCustomer.products) {
            return selectedCustomer.products.isFetchingActiveProductsData;
        }
    }
);

export const RemoveProductExecutionOptionsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return Immutable(selectedCustomer.products.executionOptions);
    }
);
