import {ThunkHelper} from 'invision-core';
import {values} from 'ramda';

export const GIFT_CARD_RESULT = {
    BEGIN: 'GIFT_CARD_RESULT_BEGIN',
    SUCCESS: 'GIFT_CARD_RESULT_SUCCESS',
    FAILURE: 'GIFT_CARD_RESULT_FAILURE'
};
const GIFT_CARD_RESULT_EVENTS = [GIFT_CARD_RESULT.BEGIN, GIFT_CARD_RESULT.SUCCESS, GIFT_CARD_RESULT.FAILURE];

const giftCardResultPromise = (dispatch, giftCardId, pageNumber, pageSize, events = GIFT_CARD_RESULT_EVENTS) => {
    return ThunkHelper(dispatch, events, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveGiftCardRedemption',
        data: {
            Id : giftCardId
        }
    });
};

export const getGiftCardResult = (giftCardId) => {
    return (dispatch) => {
        return giftCardResultPromise(dispatch, giftCardId);
    };
};

export const RESEND_GIFT_CARD_NOTIFICATION = {
    BEGIN: 'RESEND_GIFT_CARD_NOTIFICATION_BEGIN',
    SUCCESS: 'RESEND_GIFT_CARD_NOTIFICATION_SUCCESS',
    FAILURE: 'RESEND_GIFT_CARD_NOTIFICATION_FAILURE'
};

export const RESEND_GIFT_CARD_NOTIFICATION_TYPES = values(RESEND_GIFT_CARD_NOTIFICATION);
const resendGiftCardNotificationPromise = (dispatch, notificationInfo) => {
    return ThunkHelper(dispatch, RESEND_GIFT_CARD_NOTIFICATION_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/ResendGiftCardNotification',
        data: {
            NotifySender: notificationInfo.notifySender,
            RedemptionId: notificationInfo.Id,
            RecipientEmail: notificationInfo.RecipientEmail,
            RecipientName: notificationInfo.RecipientName,
            SenderName: notificationInfo.SenderName,
            SenderMessage: notificationInfo.message
        }
    });
};

export const submitResendGiftCardNotification = (notificationInfo) => {
    return (dispatch) => {
        return resendGiftCardNotificationPromise(dispatch, notificationInfo);
    };
};
