import {createSelector} from 'reselect';
import {SelectedCustomerSelector} from './customer.selectors';

export const IsExecutingBillRunSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.demoActions.isExecutingBillRun;
    }
);

export const IsExecutingSimulateUsage = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.demoActions.isExecutingSimulateUsage;
    }
);

export const IsRetrievingActiveWorkflowsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.demoActions.isRetrievingActiveWorkflows;
    }
);

export const IsCompletingWorkflowSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.demoActions.isCompletingWorkflow;
    }
);

export const ActiveWorkflowsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.demoActions.activeWorkflows;
    }
);