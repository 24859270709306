import {
    ApiConstants,
    ThunkHelper
} from 'invision-core';

export const RETRIEVE_OFFERING_OFFER_REMOVAL_CONTEXT = {
    BEGIN: 'RETRIEVE_OFFERING_OFFER_REMOVAL_CONTEXT_BEGIN',
    SUCCESS: 'RETRIEVE_OFFERING_OFFER_REMOVAL_CONTEXT_SUCCESS',
    FAILURE: 'RETRIEVE_OFFERING_OFFER_REMOVAL_CONTEXT_FAILURE'
};

const RETRIEVE_OFFERING_OFFER_REMOVAL_CONTEXT_TYPES = [
    RETRIEVE_OFFERING_OFFER_REMOVAL_CONTEXT.BEGIN,
    RETRIEVE_OFFERING_OFFER_REMOVAL_CONTEXT.SUCCESS,
    RETRIEVE_OFFERING_OFFER_REMOVAL_CONTEXT.FAILURE
];

export const CALCULATE_COS_ORDER_QUOTE = {
    BEGIN: 'CALCULATE_COS_ORDER_QUOTE_BEGIN',
    SUCCESS: 'CALCULATE_COS_ORDER_QUOTE_SUCCESS',
    FAILURE: 'CALCULATE_COS_ORDER_QUOTE_FAILURE'
};
const CALCULATE_COS_ORDER_QUOTE_TYPES = [
    CALCULATE_COS_ORDER_QUOTE.BEGIN,
    CALCULATE_COS_ORDER_QUOTE.SUCCESS,
    CALCULATE_COS_ORDER_QUOTE.FAILURE
];

export const CHANGE_OF_SERVICE_ORDER_REMOVAL = {
    BEGIN: 'CHANGE_OF_SERVICE_ORDER_REMOVAL_BEGIN',
    SUCCESS: 'CHANGE_OF_SERVICE_ORDER_REMOVAL_SUCCESS',
    FAILURE: 'CHANGE_OF_SERVICE_ORDER_REMOVAL_FAILURE'
};
const CHANGE_OF_SERVICE_ORDER_REMOVAL_TYPES = [
    CHANGE_OF_SERVICE_ORDER_REMOVAL.BEGIN,
    CHANGE_OF_SERVICE_ORDER_REMOVAL.SUCCESS,
    CHANGE_OF_SERVICE_ORDER_REMOVAL.FAILURE
];

export const retrieveOfferingRemovalContext = (requestData, subscriberId) => {
    return (dispatch) => {
        return retrieveOfferingRemovalContextPromise(dispatch, requestData, subscriberId);
    };
};

export const retrieveOfferingRemovalContextPromise = (dispatch, requestData, subscriberId) => {
    return ThunkHelper(dispatch, RETRIEVE_OFFERING_OFFER_REMOVAL_CONTEXT_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOfferingContext',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
        },
        data: requestData
    });
};

export const calculateChangeOfServiceQuote = (requestData, subscriberId) => {
    return (dispatch) => {
        return calculateChangeOfServiceQuotePromise(dispatch, requestData, subscriberId);
    };
};

/*
    Note: For `calculateChangeOfServiceOrderQuote` API if there is an offering id
the API will asusme it's a remove offer. If there's no offeringId, the API
will assume the request is a disconnect and go through validation that checks
whether there's any offers in the pending-removal state. If there are offers in
pending-removal state, the API will throw an error.
*/
export const calculateChangeOfServiceQuotePromise = (dispatch, requestData, subscriberId) => {
    return ThunkHelper(dispatch, CALCULATE_COS_ORDER_QUOTE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/CalculateChangeOfServiceOrderQuote',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
        },
        data : requestData
    });
};

export const submitChangeOfServiceOrderRemoval = (request, subscriberId) => {
    return (dispatch) => {
        return submitChangeOfServiceOrderRemovalPromise(dispatch, request, subscriberId);
    };
};

/*
    Note: For `submitChangeOfServiceOrderRemoval` API if there is an offering id
the API will asusme it's a remove offer. If there's no offeringId, the API
will assume the request is a disconnect and go through validation that checks
whether there's any offers in the pending-removal state. If there are offers in
pending-removal state, the API will throw an error.
*/

export const submitChangeOfServiceOrderRemovalPromise = (dispatch, request, subscriberId) => {
    return ThunkHelper(dispatch, CHANGE_OF_SERVICE_ORDER_REMOVAL_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/SubmitChangeOfServiceOrder',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
        },
        data : request
    });
};
