import Immutable from 'seamless-immutable';
import {RESET_CUSTOMER_RECOVERABLE} from './../actions/customer.actions';
import * as ServiceActions from '../actions/services.actions';
export const INITIAL_STATE = Immutable({
    filterData: {
        includeStatusFilter: null,
        includeTypeOfServiceFilter: null,
        searchString: null
    },
    pageNumber: 1,
    pageSize: null
});

export default function BulkServicesReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case ServiceActions.SET_CURRENT_PAGE:
            return state.set('pageNumber', payload);
        case ServiceActions.SET_BULK_SERVICES_PAGE_SIZE:
            return state.set('pageSize', payload);
        case ServiceActions.SET_BULK_SERVICES_STATUS_FILTER:
            return state.setIn(['filterData', 'includeStatusFilter'], payload);
        case ServiceActions.SET_BULK_SERVICES_SEARCH_STRING:
            return state.setIn(['filterData', 'searchString'], payload);
        case RESET_CUSTOMER_RECOVERABLE:
        case ServiceActions.REMOVE_BULK_SERVICES_STATUS_FILTER:
            return INITIAL_STATE;
        case ServiceActions.SET_SERVICES_TYPE_OF_SERVICE_FILTER:
            return state.setIn(['filterData', 'includeTypeOfServiceFilter'], payload);
        default:
            return state;
    }
}
