import cloneDeep from 'lodash/cloneDeep';
import * as EWalletHelper from '../helpers/ewallet.reducer.helper';

import {
    find,
    propEq
} from 'ramda';
import LocaleKeys from '../../locales/keys';

import {
    i18n,
    BoolHelper,
    RamdaHelper
} from 'invision-core';

import {
    PAYMENT_METHOD_STATUSES,
    SUPPORTED_PAYMENT_INSTRUMENT_TYPES
} from './../../components/customer/ewallet/ewallet.constants';

import {
    parseAdditionalProperties,
    ExternalBillAdditionalProperties,
    externalBillAdditionalPropertyDescriptors,
    PaymentInstrumentTypeAdditionalProperties,
} from '../helpers/payment.instrument.additional.property.parser';

import * as CreditCardHelper from '../../utilities/credit.card.helper';

export const COMPARISON_RESULT = {
    BEFORE: -1,
    AFTER: 1,
    SAME: 0
};

const BALANCE_IN_POINTS_KEY = 'balance_in_points';
const POINTS_DESCRIPTOR_KEY = 'points_descriptor';
const REQUIRE_BILLING_ADDRESS_KEY = 'require_billing_address';

export class PaymentInstrumentActionable {
    constructor() {
        this.canBlocklist = false;
        this.isDirectSource = false;
        this.isShareable = false;
        this.canCreate = false;
        this.canOrder = false;
        this.supportsAutoPay = false;
    }
}

export const comparePaymentInstrumentViewModelByTypeAndCreation = (p1, p2) => {
    const typeOrder1 = EWalletHelper.getPaymentMethodTypeSortOrder(p1.Type);
    const typeOrder2 = EWalletHelper.getPaymentMethodTypeSortOrder(p2.Type);

    if (typeOrder1 < typeOrder2) {
        return COMPARISON_RESULT.BEFORE;
    } else if (typeOrder1 > typeOrder2) {
        return COMPARISON_RESULT.AFTER;
    }

    const created1 = p1.Created;
    const created2 = p2.Created;

    // Sort created in reversed order
    if (created1 > created2) {
        return COMPARISON_RESULT.BEFORE;
    } else if (created1 < created2) {
        return COMPARISON_RESULT.AFTER;
    }

    return COMPARISON_RESULT.SAME;
};

const paymentInstrumentCanBeEdited = (paymentInstrument) => {
    return EWalletHelper.isEditablePaymentInstrumentType(paymentInstrument.Type)
        && !paymentInstrumentIsRemovedOrSuspended(paymentInstrument);
};

const paymentInstrumentIsRemovedOrSuspended = (paymentInstrument) => {
    return paymentInstrument.Status === PAYMENT_METHOD_STATUSES.REMOVED
        || paymentInstrument.Status === PAYMENT_METHOD_STATUSES.SUSPENDED;
};


const externalGiftCardTypeConfiguredForPoints = (cardType) => {
    const balanceInPointsProperty = RamdaHelper.findByKey(BALANCE_IN_POINTS_KEY, cardType.AdditionalProperties);
    return balanceInPointsProperty && balanceInPointsProperty.Value === 'True';
};

const getExternalGiftCardTypePointsDescriptor = (cardType) => {
    const pointsDescriptorProperty = RamdaHelper.findByKey(POINTS_DESCRIPTOR_KEY, cardType.AdditionalProperties);
    return pointsDescriptorProperty && pointsDescriptorProperty.Value ? pointsDescriptorProperty.Value : '';
};

const getPaymentInstrumentMetaData = (paymentInstrumentType, paymentInstrumentTypes) => {
    return find(propEq(`${paymentInstrumentType}`, 'Value'))(paymentInstrumentTypes);
};

export const cardTypeRequiresBillingAddress = (cardType) => {
    const billingAddressRequiredProperty = RamdaHelper.findByKey(REQUIRE_BILLING_ADDRESS_KEY, cardType.AdditionalProperties);
    return !!billingAddressRequiredProperty && BoolHelper.getBoolOrDefault(billingAddressRequiredProperty.Value, false);
};

export const paymentInstrumentToViewModel = (customerPaymentInstrument, paymentTypes, actionablesPerPaymentType) => {
    const paymentInstrument = cloneDeep(customerPaymentInstrument);

    const {allTypes, creditCardTypes, externalBillTypes, externalGiftCardTypes} = paymentTypes;

    const paymentInstrumentType = paymentInstrument.Type.toString();
    let paymentInstrumentSubType;
    let additionalPropertiesModel = {};
    let additionalPropertiesParseDescriptor = [];

    if (paymentInstrumentType === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD && paymentInstrument.CreditCard && paymentInstrument.CreditCard.Type) {
        const cardTypeName = CreditCardHelper.findCardType(creditCardTypes, paymentInstrument.CreditCard.Type.toString());
        paymentInstrument.CreditCard.typeName = cardTypeName ? cardTypeName : paymentInstrument.TypeName;
        paymentInstrumentSubType = paymentInstrument.CreditCard.Type;
    } else if (paymentInstrumentType === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_GIFT_CARD && paymentInstrument.ExternalGiftCard) {
        const cardTypeMetadata = externalGiftCardTypes.find((c) => {
            return String(c.Value) === String(paymentInstrument.ExternalGiftCard.Type);
        });

        paymentInstrument.ExternalGiftCard.typeName = cardTypeMetadata && cardTypeMetadata.Name ?
            cardTypeMetadata.Name : paymentInstrument.TypeName;
        if (cardTypeMetadata) {
            if (externalGiftCardTypeConfiguredForPoints(cardTypeMetadata)) {
                paymentInstrument.ExternalGiftCard.formattedBalance =
                    `${paymentInstrument.ExternalGiftCard.Balance} ${getExternalGiftCardTypePointsDescriptor(cardTypeMetadata)}`;
            }

            paymentInstrument.requiresBillingAddress = cardTypeRequiresBillingAddress(cardTypeMetadata);
            paymentInstrumentSubType = paymentInstrument.ExternalGiftCard.Type;
        }
    } else if (paymentInstrumentType === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_BILL && paymentInstrument.ExternalBill) {
        if (paymentInstrument.ExternalBill.Type && paymentInstrument.ExternalBill.Type > 0) {
            const ebillType = externalBillTypes.find((c) => {
                return String(c.Value) === String(paymentInstrument.ExternalBill.Type);
            });

            if (ebillType) {
                paymentInstrument.TypeName = ebillType.Name;
            }
        }

        paymentInstrumentSubType = paymentInstrument.ExternalBill.Type;
        additionalPropertiesModel = new ExternalBillAdditionalProperties();
        additionalPropertiesParseDescriptor = externalBillAdditionalPropertyDescriptors;
    } else if (paymentInstrumentType === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.STORED_VALUE_ACCOUNT && paymentInstrument.StoredValueAccount) {
        if (paymentInstrument.StoredValueAccount.LoyaltyCard) {
            paymentInstrument.TypeName = i18n.translate(LocaleKeys.E_WALLET.STORED_VALUE_ACCOUNT.LOYALTY_CARD);
        }
    }
    paymentInstrument.Locked = EWalletHelper.isLockedPaymentInstrument(paymentInstrument);

    const defaultPermission = new PaymentInstrumentTypeAdditionalProperties();
    const permission = actionablesPerPaymentType[paymentInstrumentType] || defaultPermission;
    paymentInstrument.actionables = new PaymentInstrumentActionable();
    paymentInstrument.actionables.canCreate = permission.userCreatable;
    paymentInstrument.actionables.canEdit = permission.userCreatable && paymentInstrumentCanBeEdited(paymentInstrument);
    paymentInstrument.actionables.canBlocklist = permission.canBlocklist;
    paymentInstrument.actionables.canOrder = permission.userOrderable;
    paymentInstrument.actionables.isShareable = permission.isShareable;
    paymentInstrument.actionables.isDirectSource = permission.isDirectSource;
    paymentInstrument.actionables.supportsAutoPay = permission.supportsAutoPay;

    if (paymentInstrumentSubType) {
        const paymentTypeMetaData = getPaymentInstrumentMetaData(paymentInstrumentSubType, allTypes);
        if (paymentTypeMetaData) {
            paymentInstrument.paymentInstrumentMetaData = parseAdditionalProperties(additionalPropertiesModel, paymentTypeMetaData.AdditionalProperties, additionalPropertiesParseDescriptor);
        }
    }

    return paymentInstrument;
};

export const isPaymentInstrumentTypeAvailable = (paymentInstrumentTypeCodeValue, allPaymentInstrumentTypes, isAllPaymentInstrumentTypesAvailable,
    availablePaymentInstrumentTypesMap) => {
    const isInAllPaymentInstrumentTypes = allPaymentInstrumentTypes.some((type) => {
        return type.Value === paymentInstrumentTypeCodeValue;
    });
    return !!(isInAllPaymentInstrumentTypes &&
        (isAllPaymentInstrumentTypesAvailable ||
            (availablePaymentInstrumentTypesMap &&
                availablePaymentInstrumentTypesMap[paymentInstrumentTypeCodeValue])));
};
