import * as R from 'ramda';
import Immutable from 'seamless-immutable';
import {i18n} from 'invision-core';
import {createSelector} from 'reselect';
import CustomerCareKeys from '../../locales/keys';
import {getNextStepIndex,
    getPreviousStepIndex} from './wizard.helper';
import {setFormattedName,
    setFormattedCityState} from './customer.helper';

const DEFAULT_TAB_PANEL_VIEW_MODEL = Immutable([
    {
        glyph: 'shopping-cart',
        notificationCount: 0
    },
    {
        glyph: 'user',
        notificationCount: 0
    }
    // {
    //     glyph: 'file-text',
    //     notificationCount: 0
    // }
    // Commenting out since we are going implement in the future
]);

export const BackButtonText = (inputSelectors, allStepsKeys) => {
    return createSelector(
        inputSelectors,
        (currentStepIndex, completedSteps) => {
            const previousStepIndex = getPreviousStepIndex(allStepsKeys, completedSteps, currentStepIndex);
            const previousStepKey = allStepsKeys[previousStepIndex];

            return previousStepKey ? i18n.translate(CustomerCareKeys.BACK_TO_STEP, {
                step: i18n.translate(previousStepKey)
            }) : '';
        }
    );
};

export const ForwardButtonText = (inputSelectors, allStepsKeys) => {
    return createSelector(
        inputSelectors,
        (currentStepIndex, skippedSteps) => {
            const nextStepIndex = getNextStepIndex(allStepsKeys, skippedSteps, currentStepIndex);
            const nextStepKey = allStepsKeys[nextStepIndex];

            return nextStepKey ? i18n.translate(CustomerCareKeys.CONTINUE_TO_STEP, {
                step: i18n.translate(nextStepKey)
            }) : i18n.translate(CustomerCareKeys.COMPLETE_ORDER);
        }
    );
};

export const TabPanelViewModel = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (selectedTabIndex) => {
            return DEFAULT_TAB_PANEL_VIEW_MODEL.map((item, index) => {
                return item.set('active', index === selectedTabIndex);
            });
        }
    );
};

export const FormattedName = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (customerInfo) => {
            return setFormattedName(customerInfo);
        }
    );
};

export const FormattedCityState = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (customerInfo) => {
            return setFormattedCityState(customerInfo);
        }
    );
};

export const FormattedAddressCityState = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (serviceAddress) => {
            if (!serviceAddress) {
                return '';
            }
            if (!serviceAddress.State) {
                return serviceAddress.City;
            }
            if (serviceAddress.City === '' || !serviceAddress.City) {
                return serviceAddress.State;
            }
            return `${serviceAddress.City}, ${serviceAddress.State}`;
        }
    );
};

export const CurrentStepIndex = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (wizardStore) => {
            return wizardStore.currentStepIndex;
        }
    );
};

export const CompletedSteps = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (wizardStore) => {
            return wizardStore.completedSteps;
        }
    );
};

export const CurrentStep = (inputSelectors, allStepsKeys) => {
    return createSelector(
        inputSelectors,
        (currentStepIndex) => {
            return allStepsKeys[currentStepIndex];
        }
    );
};

export const IsActiveStep = (inputSelectors, allStepsKeys, index) => {
    return createSelector(
        inputSelectors,
        (currentStep) => {
            return currentStep === allStepsKeys[index];
        }
    );
};

export const IsActiveTransitionStep = (inputSelectors, localeKey) => {
    return createSelector(
        inputSelectors,
        (currentStep) => {
            return currentStep === localeKey;
        }
    );
};

export const CurrentStepIsInvalid = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (wizardStore) => {
            return wizardStore.currentStepIsInvalid;
        }
    );
};

export const SelectedTabIndex = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (wizardStore) => {
            return wizardStore.selectedTabIndex;
        }
    );
};

export const SkippedStep = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (wizardStore) => {
            return wizardStore.skippedSteps;
        }
    );
};

export const StepProgressBarViewModel = (inputSelectors, allStepsKeys) => {
    return createSelector(
        inputSelectors,
        (currentStep, completedStepsMap, skippedSteps) => {
            return allStepsKeys.map((step) => {
                return {
                    name: i18n.translate(step),
                    active: currentStep === step,
                    visited: !!completedStepsMap[step],
                    skipped: R.includes(step, skippedSteps)
                };
            });
        }
    );
};


export const getLastFourDigits = (digitString) => {
    let lastFourDigits = null;
    if (digitString && digitString.length) {
        if (digitString.length >= 4) {
            lastFourDigits = digitString.substring(digitString.length - 4);
        } else {
            lastFourDigits = digitString.substring(0, digitString.length);
        }
    }
    return lastFourDigits;
};
