import pathOr from 'ramda/src/pathOr';
import createWizardActions from '../helpers/offer.ordering.wizard.action.helper';
import {
    ContextOfferingAction,
    TRANSITION_WIZARD_PREFIX
} from '../constants/wizard.constants';
import {
    INVOICE_TIMING_CONSTANTS,
    NOTIFICATION_TIME_LENGTH
} from '../../customercare.constants';
import {RemoveOfferReasonCodes} from './../constants/transition.offer.wizard.constants';
import {
    calculateChangeOfServiceOfferingOrderQuotePromise,
    retrieveOfferingContextPromiseWithOptions,
    setSelectedInventoryStores
} from '../actions/offering.order.actions';
import i18n from 'invision-core/src/components/i18n/i18n';
import {getUiNotificationService} from 'invision-core/src/components/injectables/injector.helper';
import CustomerCareKeys from '../../locales/keys';
import metadataThunkHelper from 'invision-core/src/api/metadata.thunk.helper';
import ThunkHelper from 'invision-core/src/api/thunk.helper';
import {
    SUBSCRIBER_ID_HEADER,
    LANGUAGE_HEADER,
} from 'invision-core/src/api/constants';

const wizardActions = createWizardActions(TRANSITION_WIZARD_PREFIX);
export const BEGIN_TRANSITION_OFFER_ORDER = 'BEGIN_TRANSITION_OFFER_ORDER';
export const CANCEL_ORDER = wizardActions.CANCEL_WIZARD;
export const CHANGE_OFFER_STEP_SET_COMPLETED_STEPS = 'CHANGE_OFFER_STEP_SET_COMPLETED_STEPS';
export const GO_TO_NEXT_STEP = wizardActions.GO_TO_NEXT_STEP;
export const GO_TO_PREVIOUS_STEP = wizardActions.GO_TO_PREVIOUS_STEP;
export const GO_TO_STEP = wizardActions.GO_TO_STEP;
export const RESUME_ORDER_SET_SELECTED_OFFER_ID = 'RESUME_ORDER_SET_SELECTED_OFFER_ID';
export const RESTORE_OPTION_DEFAULT_PRICING = wizardActions.RESTORE_OPTION_DEFAULT_PRICING;
export const RETRIEVE_ADDRESSES = wizardActions.RETRIEVE_ADDRESSES;
export const SEND_PORT_IN_OR_ITEM_RESERVATION = wizardActions.SEND_PORT_IN_OR_ITEM_RESERVATION;
export const SET_ATTRIBUTE_SEARCHING_INVENTORY = wizardActions.SET_ATTRIBUTE_SEARCHING_INVENTORY;
export const SAVE_OPTION_PRICING = wizardActions.SAVE_OPTION_PRICING;
export const SET_CURRENT_STEP_IS_INVALID = wizardActions.SET_CURRENT_STEP_IS_INVALID;
export const SET_EDIT_ATTRIBUTE_GROUPS = wizardActions.SET_EDIT_ATTRIBUTE_GROUPS;
export const SET_EDIT_OPTION = wizardActions.SET_EDIT_OPTION;
export const SET_EDIT_CART_ADDITIONAL_PROPERTY = wizardActions.SET_EDIT_CART_ADDITIONAL_PROPERTY;
export const SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE = wizardActions.SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE;
export const SET_EDIT_ORDER_ADDITIONAL_PROPERTY = wizardActions.SET_EDIT_ORDER_ADDITIONAL_PROPERTY;
export const SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE = wizardActions.SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE;
export const SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS = wizardActions.SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS;
export const SET_PAYMENT_INFO = wizardActions.SET_PAYMENT_INFO;
export const SET_SELECTED_FACETS_EDIT_COPY = wizardActions.SET_SELECTED_FACETS_EDIT_COPY;
export const SET_SELECTED_OFFERING_CHARGE_TYPES_EDIT_COPY = wizardActions.SET_SELECTED_OFFERING_CHARGE_TYPES_EDIT_COPY;
export const SET_SELECTED_OFFER = wizardActions.SET_SELECTED_OFFER;
export const SET_SELECTED_OFFER_EDIT_COPY = wizardActions.SET_SELECTED_OFFER_EDIT_COPY;
export const SET_SELECTED_WIZARD_TAB = wizardActions.SET_SELECTED_WIZARD_TAB;
export const UPDATE_EDIT_BRI_PRICE = wizardActions.UPDATE_EDIT_BRI_PRICE;
export const UPDATE_INVENTORY_SELECTION = wizardActions.UPDATE_INVENTORY_SELECTION;
export const UPDATE_OFFER_ATTRIBUTE = wizardActions.UPDATE_OFFER_ATTRIBUTE;
export const UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL = wizardActions.UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL;
export const UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE = wizardActions.UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE;
export const UPDATE_SELECTED_SERVICE_FEATURES = wizardActions.UPDATE_SELECTED_SERVICE_FEATURES;
export const UPDATE_PAYMENT_INFO = wizardActions.UPDATE_PAYMENT_INFO;
export const UPDATE_PORT_IN_REQUEST = wizardActions.UPDATE_PORT_IN_REQUEST;

export const cancelOrder = wizardActions.cancelWizard;
export const clearQuoteAndSetSelectedOffer = wizardActions.clearQuoteAndSetSelectedOffer;
export const fetchAddresses = wizardActions.fetchAddresses;
export const fetchAttributes = wizardActions.fetchAttributes;
export const fetchWallet = wizardActions.fetchWallet;
export const goToNextStep = wizardActions.goToNextStep;
export const goToPreviousStep = wizardActions.goToPreviousStep;
export const goToStep = wizardActions.goToStep;
export const restoreOptionDefaultPricing = wizardActions.restoreOptionDefaultPricing;
export const saveBulkPricing = wizardActions.saveBulkPricing;
export const searchAttributeInventory = wizardActions.searchAttributeInventory;
export const sendPortInOrItemReservation = wizardActions.sendPortInOrItemReservation;
export const setCurrentStepIsInvalid = wizardActions.setCurrentStepIsInvalid;
export const setEditAttributeGroups = wizardActions.setEditAttributeGroups;
export const setEditOption = wizardActions.setEditOption;
export const setEditCartAdditionalProperty = wizardActions.setEditCartAdditionalProperty;
export const setEditCartAdditionalPropertyOnChange = wizardActions.setEditCartAdditionalPropertyOnChange;
export const setEditOrderAdditionalProperty = wizardActions.setEditOrderAdditionalProperty;
export const setEditOrderAdditionalPropertyOnChange = wizardActions.setEditOrderAdditionalPropertyOnChange;
export const setEditPhysicalAttributeGroups = wizardActions.setEditPhysicalAttributeGroups;
export const setPaymentInfo = wizardActions.setPaymentInfo;
export const setSelectedFacetsEditCopy = wizardActions.setSelectedFacetsEditCopy;
export const setSelectedOffer = wizardActions.setSelectedOffer;
export const setSelectedOfferingChargeTypesEditCopy = wizardActions.setSelectedOfferingChargeTypesEditCopy;
export const setSelectedOfferEditCopy = wizardActions.setSelectedOfferEditCopy;
export const setSelectedTab = wizardActions.setSelectedTab;
export const updateAttribute = wizardActions.updateAttribute;
export const updateBillCycle = wizardActions.updateBillCycle;
export const updateCart = wizardActions.updateCart;
export const updateEditOptionPrice = wizardActions.updateEditOptionPrice;
export const updateInventorySelection = wizardActions.updateInventorySelection;
export const updateOfferPhysicalInventoryMakeAndModel = wizardActions.updateOfferPhysicalInventoryMakeAndModel;
export const updateOfferPhysicalInventoryTypeAttribute = wizardActions.updateOfferPhysicalInventoryTypeAttribute;
export const updateSelectedServiceFeatures = wizardActions.updateSelectedServiceFeatures;
export const updatePaymentInfo = wizardActions.updatePaymentInfo;
export const updatePortInRequest = wizardActions.updatePortInRequest;

export const fetchAttributesQuoteAndNavigate = (customerInfo, offeringId, completedDecisions = [],
    completedAttributes = [], completedPhysicalAttributes = [], transitionContext, isAsync, isShipping, skipGoToNextStep = false, transitionOfferingInstanceId, bypassQuote = false) => {
    return (dispatch) => {
        return transitionRetrievePreviousAttributesPromise(dispatch, customerInfo.Id, offeringId,
            completedDecisions.concat(completedAttributes).concat(completedPhysicalAttributes), transitionContext)
            .then((response) => {
                return calculateChangeOfServiceOfferingOrderQuotePromise(dispatch, customerInfo, transitionOfferingInstanceId, response.AddItems, response.RemoveItems, response.ModifyItems, undefined, undefined, undefined, isShipping, bypassQuote)
                    .then(() => {
                        if (skipGoToNextStep) {
                            dispatch(goToNextStep());
                        }
                    })
                    .catch((error) => {
                        getUiNotificationService().error(error.translatedMessage, null, {
                            timeOut: NOTIFICATION_TIME_LENGTH
                        });
                    });
            })
            .catch((error) => {
                if (!pathOr([], ['FaultData'], error).length) {
                    getUiNotificationService().error(error.translatedMessage !== error.Code.toString() ? error.translatedMessage : i18n.translate(CustomerCareKeys.QUOTE_ERROR), null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });
                }
            });
    };
};

export const fetchPreviousAttributesAndNavigate = (customerInfo, offerId, completedDecisions, transitionContext, fromOfferId,
    offeringInstanceId, isConvergentBiller, isAsync, bypassQuote = false) => {
    return (dispatch) => {
        // Disallow the user from submitting again until the request is done
        dispatch(setCurrentStepIsInvalid(true));
        return transitionRetrievePreviousAttributesPromise(dispatch, customerInfo.Id, offerId, completedDecisions, transitionContext, isConvergentBiller)
            .then((response) => {
                return handleROCPreviousAttributesResponse(dispatch, response, customerInfo, offerId, fromOfferId, offeringInstanceId, isAsync, bypassQuote);
            }).catch((error) => {
                getUiNotificationService().error(error.translatedMessage !== error.Code.toString() ? error.translatedMessage : i18n.translate(CustomerCareKeys.DECISIONS_ERROR), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            });
    };
};

const handleROCPreviousAttributesResponse = (dispatch, response, customerInfo, offerId, fromOfferId = null, offeringInstanceId, isAsync, bypassQuote) => {
    response.Context.ValueDecisions.filter((decision) => {
        // if there is a DependentServiceAttributeId then the attribute is hidden
        // if they are all hidden, then we don't need to show attributes page
        return !decision.DependentServiceAttributeId;
    });
    dispatch(setCurrentStepIsInvalid(false));
    if (response.Context.ValueDecisions.length > 0 ||( response.Context.PhysicalInventoryDecisions && response.Context.PhysicalInventoryDecisions.length > 0)) {
        dispatch(goToStep(CustomerCareKeys.WIZARD.STEPS.ATTRIBUTES));
    } else {
        return calculateChangeOfServiceOfferingOrderQuotePromise(dispatch, customerInfo, offerId, response.AddItems, response.ModifyItems, response.RemoveItems, undefined, undefined, undefined, undefined, bypassQuote)
            .then(() => {
                dispatch(goToStep(CustomerCareKeys.WIZARD.STEPS.CHECKOUT));
            });
    }
};

export const TRANSITION_RETRIEVE_PREVIOUS_ATTRIBUTES = {
    BEGIN: 'TRANSITION_RETRIEVE_PREVIOUS_ATTRIBUTES_BEGIN',
    SUCCESS: 'TRANSITION_RETRIEVE_PREVIOUS_ATTRIBUTES_SUCCESS',
    FAILURE: 'TRANSITION_RETRIEVE_PREVIOUS_ATTRIBUTES_FAILURE'
};
const TRANSITION_RETRIEVE_PREVIOUS_ATTRIBUTES_TYPES = [
    TRANSITION_RETRIEVE_PREVIOUS_ATTRIBUTES.BEGIN,
    TRANSITION_RETRIEVE_PREVIOUS_ATTRIBUTES.SUCCESS,
    TRANSITION_RETRIEVE_PREVIOUS_ATTRIBUTES.FAILURE
];

export const beginTransitionOfferOrder = () => {
    return {
        type: BEGIN_TRANSITION_OFFER_ORDER
    };
};

const transitionRetrievePreviousAttributesPromise = (dispatch, customerId, offeringId, completedDecisions = [], transitionContext, isConvergentBiller) => {
    return ThunkHelper(dispatch, TRANSITION_RETRIEVE_PREVIOUS_ATTRIBUTES_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOfferingContext',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            Offerings: [{
                Action:ContextOfferingAction.TRANSITION,
                OfferingId: offeringId
            }],
            AdditionalShoppingCartUpdate: !isConvergentBiller,
            RetrieveServiceAttributes: true,
            RetrieveDeliveryDecisions: true,
            RetrievePhysicalInventoryDecisions: true,
            CompletedDecisionsList: completedDecisions,
            TransitionOutcomes: transitionContext.Outcomes
        }
    });
};

export const RETRIEVE_TRANSITION_CONTEXT = {
    BEGIN: 'RETRIEVE_TRANSITION_CONTEXT.BEGIN',
    SUCCESS: 'RETRIEVE_TRANSITION_CONTEXT.SUCCESS',
    FAILURE: 'RETRIEVE_TRANSITION_CONTEXT.FAILURE'
};
const RETRIEVE_TRANSITION_CONTEXT_CONSTANTS = [
    RETRIEVE_TRANSITION_CONTEXT.BEGIN,
    RETRIEVE_TRANSITION_CONTEXT.SUCCESS,
    RETRIEVE_TRANSITION_CONTEXT.FAILURE
];

export const retrieveTransitionContextPromise = (dispatch, customerId, offeringId, fromOfferingId, offeringInstanceId) => {
    const reqBody = {
        ExistingOfferingId: fromOfferingId,
        ExistingOfferingInstanceId: offeringInstanceId,
        Offerings:[{
            Action: ContextOfferingAction.TRANSITION_REQUEST,
            OfferingId: offeringId
        }]
    };

    return ThunkHelper(dispatch, RETRIEVE_TRANSITION_CONTEXT_CONSTANTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveTransitionContext',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: reqBody
    });
};

export const retrieveTransitionContext = (customerId, offeringId, fromOfferingId, offeringInstanceId) => {
    return (dispatch) => {
        return retrieveTransitionContextPromise(dispatch, customerId, offeringId, fromOfferingId, offeringInstanceId).catch((errorPayload) => {
            getUiNotificationService.error(errorPayload.translatedMessage, null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });
        });
    };
};

const GET_TRANSITION_OFFERING_METADATA = {
    BEGIN: 'GET_TRANSITION_OFFERING_METADATA_BEGIN',
    SUCCESS: 'GET_TRANSITION_OFFERING_METADATA_SUCCESS',
    FAILURE: 'GET_TRANSITION_OFFERING_METADATA_FAILURE'
};

const GET_TRANSFER_OFFERING_METADATA_EVENTS = [
    GET_TRANSITION_OFFERING_METADATA.BEGIN,
    GET_TRANSITION_OFFERING_METADATA.SUCCESS,
    GET_TRANSITION_OFFERING_METADATA.FAILURE
];

const getTransitionOfferingMetadataPromise = (dispatch, offeringId, language) => {
    return metadataThunkHelper(dispatch, GET_TRANSFER_OFFERING_METADATA_EVENTS, {
        url: `Offering/Id/${offeringId}`,
        headers: {
            [LANGUAGE_HEADER]: language || undefined
        }
    });
};

export const getTransitionOfferingMetadata = (offeringId, language) => {
    return (dispatch) => {
        return getTransitionOfferingMetadataPromise(dispatch, offeringId, language);
    };
};

export const SET_TRANSITION_OFFERINGS_METADATA = 'SET_TRANSITION_OFFERINGS_METADATA';
export const setTransitionOfferingsMetadata = (offeringsMetadata) => {
    return {
        payload: offeringsMetadata,
        type: SET_TRANSITION_OFFERINGS_METADATA
    };
};

export const TRANSITION_SUBMIT_COS_ORDER = {
    BEGIN: 'TRANSITION_SUBMIT_COS_ORDER_BEGIN',
    SUCCESS: 'TRANSITION_SUBMIT_COS_ORDER_SUCCESS',
    FAILURE: 'TRANSITION_SUBMIT_COS_ORDER_FAILURE'
};
const TRANSITION_SUBMIT_COS_ORDER_TYPES = [
    TRANSITION_SUBMIT_COS_ORDER.BEGIN,
    TRANSITION_SUBMIT_COS_ORDER.SUCCESS,
    TRANSITION_SUBMIT_COS_ORDER.FAILURE
];

export const transitionOfferSubmitCosOrder = (customerId, offeringId, addItems, modifyItems, removeItems, paymentInfo, fromOfferId = null, changesEffective = null, executionOptions, recordPaymentInformation, subscriberPhysicalInventoryPickupDetail, additionalProperties, shippingInfo) => {
    return (dispatch) => {
        return transitionOfferSubmitCosOrderPromise(dispatch, customerId, offeringId, addItems, modifyItems, removeItems, paymentInfo, fromOfferId, changesEffective, executionOptions, recordPaymentInformation, subscriberPhysicalInventoryPickupDetail, additionalProperties, shippingInfo);
    };
};

export const transitionOfferSubmitCosOrderPromise = (dispatch, customerId, offeringId, addItems, modifyItems, removeItems, paymentInfo, fromOfferId = null, changesEffective = null, executionOptions = {}, recordPaymentInformation, subscriberPhysicalInventoryPickupDetail, additionalProperties, shippingInfo) => {
    const paymentInfoArray = paymentInfo ? [paymentInfo] : [];
    const reqBody = {
        AddItems: addItems,
        AdditionalProperties: additionalProperties || null,
        ChangeImmediately: true,
        ExecutionDate: executionOptions.specifiedDate,
        ExecutionDateIntention: executionOptions.effective,
        ModifyItems: modifyItems,
        RecordPaymentInformation: recordPaymentInformation,
        RemoveItems: removeItems,
        PaymentInstruments: paymentInfoArray,
        PickupId: subscriberPhysicalInventoryPickupDetail,
        ShippingAddressId: shippingInfo.addressId || undefined,
        ShippingMethodId: shippingInfo.methodId || undefined,
        ShipToName: shippingInfo.shipTo || undefined
    };

    if (fromOfferId) {
        reqBody.OfferingId = fromOfferId;
    }
    if (offeringId) {
        reqBody.TransitionOfferingId = offeringId;
    }
    if (+changesEffective === INVOICE_TIMING_CONSTANTS.INVOICE_ON_CYCLE) {
        reqBody.ChangeImmediately = false;
    }
    if (removeItems.length) {
        reqBody.RemoveReasonCode = RemoveOfferReasonCodes.DefaultedToRemove;
    }
    return ThunkHelper(dispatch, TRANSITION_SUBMIT_COS_ORDER_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/SubmitChangeOfServiceOrder',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: reqBody
    });
};

export const fetchDecisionsAndNavigate = (offerId, customerInfo, options, fromOfferId = null,
    offeringInstanceId, isAsync = false, bypassQuote = false) => {
    const transitionContext = options.transitionContext,
        isConvergentBiller = options.isConvergentBiller,
        segmentationContext = options.segmentationContext = [],
        grandfatherPricingPlans = options.grandfatherPricingPlans || true;
    return (dispatch) => {
        // Set Offers step to invalid until the api call is successful
        dispatch(setCurrentStepIsInvalid(true));

        return retrieveOfferingContextPromiseWithOptions(dispatch, offerId, customerInfo.Id, {
            isConvergentBiller,
            segmentationContext,
            transitionContext,
            grandfatherPricingPlans
        })
            .then((response) => {
                dispatch(setCurrentStepIsInvalid(false));
                if (response.Context.Decisions.length > 0) {
                    dispatch(goToNextStep());
                } else {
                    const completedDecisions = [];
                    return transitionRetrievePreviousAttributesPromise(dispatch, customerInfo.Id, offerId, completedDecisions,
                        transitionContext)
                        .then((response) => {
                            return handleROCPreviousAttributesResponse(dispatch, response, customerInfo, offerId, fromOfferId, offeringInstanceId, isAsync, bypassQuote);
                        });
                }
            })
            .catch((error) => {
                getUiNotificationService().error(error.translatedMessage, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            });
    };
};

export const SET_IS_RESUMING_STORE_ORDER = 'SET_IS_RESUMING_STORE_ORDER';
export const setIsResumingStoreOrder = (resumingOrder) => {
    return {
        type: SET_IS_RESUMING_STORE_ORDER,
        payload: resumingOrder
    };
};

export const RESUME_STORE_ORDER_TRANSITION = {
    BEGIN: 'RESUME_STORE_ORDER_TRANSITION_BEGIN',
    SUCCESS: 'RESUME_STORE_ORDER_TRANSITION_SUCCESS',
    FAILURE: 'RESUME_STORE_ORDER_TRANSITION_FAILURE'
};

export const RESUME_STORE_ORDER_TRANSITION_EVENTS = [
    RESUME_STORE_ORDER_TRANSITION.BEGIN,
    RESUME_STORE_ORDER_TRANSITION.SUCCESS,
    RESUME_STORE_ORDER_TRANSITION.FAILURE
];

const resumeStoreOrderTransitionPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, RESUME_STORE_ORDER_TRANSITION_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/ResumeStoreOrder',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            RetrievePhysicalInventoryDecisions: false,
            RetrieveServiceAttributes: false
        }
    });
};

export const RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_TRANSITION= {
    BEGIN: 'RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_TRANSITION_BEGIN',
    SUCCESS: 'RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_TRANSITION_SUCCESS',
    FAILURE: 'RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_TRANSITION_FAILURE'
};

export const RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_TRANSITION_EVENTS = [
    RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_TRANSITION.BEGIN,
    RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_TRANSITION.SUCCESS,
    RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_TRANSITION.FAILURE
];

const resumeStoreOrderPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_TRANSITION_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/ResumeStoreOrder',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            RetrievePhysicalInventoryDecisions: true,
            RetrieveServiceAttributes: true
        }
    });
};

export const resumeStoreOrderForTransition = (customerInfo, storeOrder) => {
    return (dispatch) => {
        const fromOfferingId = storeOrder.SourceTransitionOfferingId;
        const offeringInstanceId = storeOrder.SourceTransitionOfferingInstanceId;
        const offeringId = pathOr(null, ['Context', 'OfferingIds', '0'], storeOrder);
        const storeId = storeOrder.ShoppingCart.Items.find((item) => {
            return item.PhysicalInventories ? item.PhysicalInventories[0].StoreId : false;
        });
        dispatch(setSelectedOffer(offeringId));
        return resumeStoreOrderTransitionPromise(dispatch, customerInfo)
            .then(() => {
                return resumeStoreOrderPromise(dispatch, customerInfo);
            })
            .then(() => {
                return dispatch(setSelectedInventoryStores([storeId.PhysicalInventories[0].StoreId.toString()]));
            })
            .then(() => {
                dispatch(setChangeOfferStepToCompletedSteps());
                return dispatch(goToStep(CustomerCareKeys.WIZARD.STEPS.DECISIONS));
            })
            .then(() => {
                return retrieveTransitionContextPromise(dispatch, customerInfo, offeringId, fromOfferingId, offeringInstanceId);
            })
            .then(() => {
                dispatch(setCurrentStepIsInvalid(false));
            })
            .catch((error) => {
                getUiNotificationService().error(error.translatedMessage, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            });
    };
};

export const setChangeOfferStepToCompletedSteps = () => {
    return {
        type: CHANGE_OFFER_STEP_SET_COMPLETED_STEPS
    };
};

export const SET_SERVICE_TAX_LOCATION_DECISION_CHANGE_OFFER = 'SET_SERVICE_TAX_LOCATION_DECISION_CHANGE_OFFER';

export const updateServiceTaxLocationDecisionChangeOffer = (payload) => {
    return {
        type: SET_SERVICE_TAX_LOCATION_DECISION_CHANGE_OFFER,
        payload: payload
    };
};
