import Immutable from 'seamless-immutable';
import {
    CREATE_ADDRESS_CONSTANTS,
    EMPTY_ADDRESSES_LIST,
    UPDATE_FORM_ADDRESS,
    REMOVE_ADDRESS,
    RETRIEVE_ADDRESSES_CONSTANTS,
    SET_ADDRESS_DATA,
    UPDATE_ADDRESS_CONSTANTS,
    UPDATE_OVERRIDE_FLAG
} from './actions/customer.addresses.actions';

export const INITIAL_STATE = Immutable({
    addressesError: null,
    addressData: {
        address: {},
        textScrubberOverride: undefined
    },
    data: null,
    currentFormAddress: null,
    isCreatingAddress: false,
    isUpdatingAddress: false,
    isRemovingAddress: false,
    isFetchingAddresses: false,
    preventOverrideFlag: false,
    removeAddressesError: null,
    updateAddressError: null,
});

export default function addressesReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case CREATE_ADDRESS_CONSTANTS.BEGIN:
            return state
                .set('addressesError', null)
                .set('isCreatingAddress', true);
        case CREATE_ADDRESS_CONSTANTS.SUCCESS:
            return state
                .set('addressesError', null)
                .set('isCreatingAddress', false);
        case CREATE_ADDRESS_CONSTANTS.FAILURE:
            return state
                .set('isCreatingAddress', false)
                .set('addressesError', {
                    code: payload.Code,
                    message: payload.Message,
                    translatedMessage: payload.translatedMessage,
                    severity: payload.Severity
                });
        case UPDATE_FORM_ADDRESS:
            return state.set('currentFormAddress', payload);
        case REMOVE_ADDRESS.BEGIN:
            return state.set('isRemovingAddress', true);
        case REMOVE_ADDRESS.SUCCESS:
            return state
                .set('isRemovingAddress', false)
                .set('removeAddressesError', null);
        case REMOVE_ADDRESS.FAILURE:
            return state
                .set('isRemovingAddress', false)
                .set('removeAddressesError', {
                    code: payload.Code,
                    message: payload.Message,
                    translatedMessage: payload.translatedMessage,
                    severity: payload.Severity
                });
        case RETRIEVE_ADDRESSES_CONSTANTS.BEGIN:
            return state.set('isFetchingAddresses', true);
        case RETRIEVE_ADDRESSES_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingAddresses', false)
            // NOTE: the Ascendon API has a bug: it returns a payload without an Addresses property in the case that the
            // subscriber has no addresses.  we account for this here with the "|| []" syntax
                .set('data', payload.Addresses || [])
                .set('addressesError', null);
        case RETRIEVE_ADDRESSES_CONSTANTS.FAILURE:
            return state
                .set('isFetchingAddresses', false)
                .set('addressesError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case UPDATE_OVERRIDE_FLAG:
            return state.set('preventOverrideFlag', payload);
        case SET_ADDRESS_DATA:
            return state.set('addressData', payload, {
                deep: true
            });
        case UPDATE_ADDRESS_CONSTANTS.BEGIN:
            return state.set('isUpdatingAddress', true);
        case UPDATE_ADDRESS_CONSTANTS.SUCCESS:
            return state
                .set('isUpdatingAddress', false)
                .set('updateAddressError', null);
        case UPDATE_ADDRESS_CONSTANTS.FAILURE:
            return state
                .set('isUpdatingAddress', false)
                .set('updateAddressError', {
                    code: payload.Code,
                    message: payload.Message,
                    translatedMessage: payload.translatedMessage,
                    severity: payload.Severity
                });
        case EMPTY_ADDRESSES_LIST:
            return INITIAL_STATE;
        default:
            return state;
    }
}
