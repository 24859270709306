import {createSelector} from 'reselect';
import {
    find,
    pathOr,
    propEq
} from 'ramda';
import {
    CurrentCustomerSelector,
    SelectedCustomerSelector
} from './customer.selectors';
import {
    MetadataConstants,
    MetadataSelectors
} from 'invision-core';
import {
    addressStateRegionProvinceValueOptionsForCountry
} from '../helpers/customer.selectors.helpers';
import {ADDRESS_STATUSES} from '../../components/customer/addresses/addresses.constants';
import Immutable from 'seamless-immutable';

const EMPTY_ARRAY = Immutable([]);

const recoverableUIStateSelector = (state) => {
    return state.customercare.recoverableUiState.addresses;
};

export const SelectedItemIdSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.selectedItemId;
    }
);

export const AddressDataSelector = createSelector([
    SelectedCustomerSelector
], (customer) => {
    return customer.addresses.addressData;
});

export const CurrentFormAddressSelector = createSelector([
    SelectedCustomerSelector
], (customer) => {
    return customer.addresses.currentFormAddress;
});

export const CreateAddressPopupStateRegionProvinceValueOptions = createSelector([
    AddressDataSelector,
    MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressCountry),
    MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressStateProvinceRegion)
], (addressData, countryCodes, stateCodes) => {
    return addressData.address ? addressStateRegionProvinceValueOptionsForCountry(addressData.address.Country, countryCodes, stateCodes) : null;
});

export const UpdateAddressPopupStateRegionProvinceValueOptions = createSelector([
    AddressDataSelector,
    MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressCountry),
    MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressStateProvinceRegion)
], (addressData, countryCodes, stateCodes) => {
    return addressData.address ? addressStateRegionProvinceValueOptionsForCountry(addressData.address.Country, countryCodes, stateCodes) : null;
});

export const CurrentAddressesSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(EMPTY_ARRAY, ['addresses', 'data'], selectedCustomer);
    }
);


export const ShippingAddressesSelector = createSelector(
    [CurrentAddressesSelector],
    (addresses) => {
        return addresses.find(address => {
            return address.DefaultShipping;
        });
    }
);

export const PreventOverrideFlagSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(null, ['addresses', 'preventOverrideFlag'], selectedCustomer);
    }
);

export const SelectedAddressSelector = createSelector(
    [SelectedItemIdSelector, CurrentAddressesSelector],
    (selectedItemId, addresses) => {
        if (addresses && addresses.length) {
            if (selectedItemId) {
                return find(propEq(selectedItemId, 'Id'))(addresses) || null;
            } else if (!selectedItemId) {
                return addresses[0].Id;
            } else {
                return null;
            }
        }

        return null;
    }
);

const DEFAULT_ADDRESS_TYPES = {
    home: 'DefaultHome',
    postal: 'DefaultPostal',
    service: 'DefaultService',
    work: 'DefaultWork'
};

const DEFAULT_ACTIVE_ADDRESSES = [];
export const CurrentActiveAddressesSelector = createSelector(
    [CurrentAddressesSelector],
    (addresses) => {
        return Array.isArray(addresses) ? addresses.filter((address) => {
            return address.Status === ADDRESS_STATUSES.ACTIVE;
        }) : DEFAULT_ACTIVE_ADDRESSES;
    }
);

const getAddressDefaultByTypeId = (currentCustomer, type, addresses) => {
    let defaultAddress = null;

    if (addresses.length) {
        defaultAddress = pathOr(null, ['ConvergentBillerId'], currentCustomer) ? find(propEq(true, type), addresses) : undefined;
    }

    return pathOr(null, ['Id'], defaultAddress);
};

export const PreventRemovalOfDefaultTypesSelector = createSelector(
    [CurrentCustomerSelector, CurrentActiveAddressesSelector, CurrentFormAddressSelector],
    (currentCustomer, currentActiveAddresses, currentFormAddress) => {
        const isSelectedAddressDefaultByType = (type) => {
            return currentCustomer.ConvergentBillerId ? pathOr(false, [type], currentFormAddress) : false;
        };

        // checks to see if an address default exists on other accounts or has been selected in the current form
        const getDefaultAddressExists = (currentAddress, id) => {
            if (currentAddress) {
                return true;
            } else {
                return id ? id !== pathOr(false, ['Id'], currentFormAddress) : false;
            }
        };

        // Is selected address default
        const isSelectedAddressDefaultHome = isSelectedAddressDefaultByType(DEFAULT_ADDRESS_TYPES.home);
        const isSelectedAddressDefaultPostal = isSelectedAddressDefaultByType(DEFAULT_ADDRESS_TYPES.postal);
        const isSelectedAddressDefaultService = isSelectedAddressDefaultByType(DEFAULT_ADDRESS_TYPES.service);
        const isSelectedAddressDefaultWork = isSelectedAddressDefaultByType(DEFAULT_ADDRESS_TYPES.work);

        // the default address id
        const defaultHomeAddressId = getAddressDefaultByTypeId(currentCustomer, DEFAULT_ADDRESS_TYPES.home, currentActiveAddresses);
        const defaultPostalAddressId = getAddressDefaultByTypeId(currentCustomer, DEFAULT_ADDRESS_TYPES.postal, currentActiveAddresses);
        const defaultServiceAddressId = getAddressDefaultByTypeId(currentCustomer, DEFAULT_ADDRESS_TYPES.service, currentActiveAddresses);
        const defaultWorkAddressId = getAddressDefaultByTypeId(currentCustomer, DEFAULT_ADDRESS_TYPES.work, currentActiveAddresses);

        // the updated address including if selected in the form or on other addresses
        const updatedDefaultHomeAddressExists = getDefaultAddressExists(isSelectedAddressDefaultHome, defaultHomeAddressId);
        const updatedDefaultPostalAddressExists = getDefaultAddressExists(isSelectedAddressDefaultPostal, defaultPostalAddressId);
        const updatedDefaultWorkAddressExists = getDefaultAddressExists(isSelectedAddressDefaultWork, defaultWorkAddressId);

        const atLeastTwoConvergentBillerAddressExists =
            (updatedDefaultHomeAddressExists && updatedDefaultPostalAddressExists) ||
            (updatedDefaultHomeAddressExists && updatedDefaultWorkAddressExists) ||
            (updatedDefaultPostalAddressExists && updatedDefaultWorkAddressExists);

        // If atLeastTwoConvergentBillerAddressExists always return false. Then Determine if there is an existing
        // addresses with defaults and it's not the current one selected, Otherwise disable it if it is selected,
        // unless they don't have a convergent biller id then never disable it
        const isDefaultTypePreventedFromRemoval = (currentAddress, id) => {
            if (atLeastTwoConvergentBillerAddressExists) {
                return false;
            } else if (id !== null && currentAddress && currentCustomer.ConvergentBillerId) {
                return id === pathOr(false, ['Id'], currentFormAddress);
            } else {
                return currentAddress;
            }
        };

        return {
            home: isDefaultTypePreventedFromRemoval(isSelectedAddressDefaultHome, defaultHomeAddressId),
            postal: isDefaultTypePreventedFromRemoval(isSelectedAddressDefaultPostal, defaultPostalAddressId),
            service: isSelectedAddressDefaultService && defaultServiceAddressId === currentFormAddress.Id,
            work: isDefaultTypePreventedFromRemoval(isSelectedAddressDefaultWork, defaultWorkAddressId),
        };
    }
);

export const IsSelectedAddressRemovableSelector = createSelector(
    [SelectedAddressSelector, CurrentCustomerSelector, CurrentActiveAddressesSelector],
    (selectedAddress, currentCustomer, currentActiveAddresses) => {

        const homeDefaultAddressId = getAddressDefaultByTypeId(currentCustomer, DEFAULT_ADDRESS_TYPES.home, currentActiveAddresses);
        const postalDefaultAddressId = getAddressDefaultByTypeId(currentCustomer, DEFAULT_ADDRESS_TYPES.postal, currentActiveAddresses);
        const workDefaultAddressId = getAddressDefaultByTypeId(currentCustomer, DEFAULT_ADDRESS_TYPES.work, currentActiveAddresses);

        const isOnlyConvergentBillerAddress = () => {
            if (selectedAddress.DefaultHome) {
                return (workDefaultAddressId === null || workDefaultAddressId === selectedAddress.Id) &&
                    (postalDefaultAddressId === null || postalDefaultAddressId === selectedAddress.Id);
            } else if (selectedAddress.DefaultPostal) {
                return (workDefaultAddressId === null || workDefaultAddressId === selectedAddress.Id) &&
                    (homeDefaultAddressId === null || homeDefaultAddressId === selectedAddress.Id);
            } else if (selectedAddress.DefaultWork) {
                return (homeDefaultAddressId === null || homeDefaultAddressId === selectedAddress.Id) &&
                    (postalDefaultAddressId === null || postalDefaultAddressId === selectedAddress.Id);
            }
        };

        const isAddressRemovable = () => {
            if (selectedAddress && pathOr(null, ['ConvergentBillerId'], currentCustomer)) {
                return selectedAddress.InUse ?
                    !selectedAddress.InUse : selectedAddress.DefaultService ?
                        !selectedAddress.DefaultService : !isOnlyConvergentBillerAddress();

            } else {
                return true;
            }
        };

        return isAddressRemovable();
    }
);

export const IsFetchingAddressesSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.addresses.isFetchingAddresses;
    }
);

export const IsRemovingAddressSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.addresses.isRemovingAddress;
    }
);

export const IsCreatingAddressSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.addresses.isCreatingAddress;
    }
);

export const PostalCodeSelector = createSelector([
    AddressDataSelector,
    MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressCountry)
], (addressData, codes) => {
    const addressCountry = pathOr('', ['address', 'Country'], addressData);

    if (addressCountry && codes.length > 0) {
        const country = find(propEq(addressCountry, 'Value'))(codes);
        if (country) {
            const regex = find(propEq('zip_code_reg_expression', 'Key'))(country.AdditionalProperties).Value;
            const mask = find(propEq('zip_code_mask', 'Key'))(country.AdditionalProperties).Value;

            return {
                regEx: new RegExp(regex),
                mask: mask
            };
        }
    }
    return {
        regEx: '',
        mask: ''
    };
});

export const IsUpdatingAddressSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.addresses.isUpdatingAddress;
    }
);

export const AddressesErrorSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.addresses.addressesError ? [selectedCustomer.addresses.addressesError.translatedMessage] : [];
    }
);

export const RemoveAddressesErrorSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.addresses.removeAddressesError ? selectedCustomer.addresses.removeAddressesError.translatedMessage : null;
    }
);

export const UpdateAddressErrorSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.addresses.updateAddressError ? [selectedCustomer.addresses.updateAddressError.translatedMessage] : [];
    }
);

export const DefaultBillingAddressSelector = createSelector(
    [CurrentAddressesSelector],
    (addresses) => {
        if (!addresses.length) {
            return null;
        }

        const defaultBilling = propEq(true, 'DefaultBilling');
        return find(defaultBilling, addresses);
    }
);

export const DefaultShippingAddressSelector = createSelector(
    [CurrentActiveAddressesSelector],
    (addresses = []) => {
        if (!addresses) {
            return null;
        }
        const defaultShipping = propEq(true, 'DefaultShipping');
        return find(defaultShipping, addresses);
    }
);

export const IncludeRemovedSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.filterData.includeRemoved;
    }
);
