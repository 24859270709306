import Immutable from 'seamless-immutable';
import {
    RESET_MEMBER_PRIVILEGE_UI,
    SET_AVAILABLE_PAYMENT_INSTRUMENTS,
    SET_MEMBER_PRIVILEGE,
    SET_SELECTED_HOURS,
    SET_SELECTED_HOURS_FOR_HOUSEHOLD_MEMBER
} from '../actions/customer.household.actions';

export const INITIAL_STATE = Immutable({
    privileges: {},
    selectedHours: {},
    paymentInstruments: []
});

const generateDefaultSelectableHours = () => {
    return Array.from(new Array(24), (i, hour) => {
        return {
            hour,
            days: Array.from(new Array(7), (i, day) => {
                return {
                    day,
                    id: `${hour}_${day}`,
                    selected: false
                };
            })
        };
    });
};

const getHourArrayForMember = (member) => {
    const hourArray = generateDefaultSelectableHours();
    if (member.Privileges.DayAndTimePrivileges) {
        member.Privileges.DayAndTimePrivileges.forEach((priv) => {
            for (let i = 0; i < (priv.DurationMinutes / 60); i++) {
                hourArray[priv.StartingHour + i].days[priv.DayOfWeek].selected = true;
            }
        });
    }
    return hourArray;
};

export default function MemberPrivilegesReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case SET_MEMBER_PRIVILEGE:
            return state.set('privileges', state.privileges.merge(payload, {
                deep: true
            }));
        case SET_AVAILABLE_PAYMENT_INSTRUMENTS:
            return state.set('paymentInstruments', payload);
        case SET_SELECTED_HOURS:
            return state.set('selectedHours', payload);
        case SET_SELECTED_HOURS_FOR_HOUSEHOLD_MEMBER:
            return state.set('selectedHours', getHourArrayForMember(payload));
        case RESET_MEMBER_PRIVILEGE_UI:
            return INITIAL_STATE;
        default:
            return state;
    }
}
