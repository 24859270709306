/*  BSS Customer Offerings still found in customer.convergent.biller.selectors.js */
import {
    createSelector
} from 'reselect';
import {
    MetadataConstants,
    MetadataSelectors,
} from 'invision-core';
import {pathOr} from 'ramda';
import {
    SelectedCustomerSelector
} from './customer.selectors';

export const IsChangeOfferEligibilityRulesLoaded = createSelector(
    [SelectedCustomerSelector],
    (currentCustomer) => {
        return pathOr(null, ['offerings', 'isLoadingChangeOfferEligibilityRules'], currentCustomer);
    }
);

export const ChangeOfferEligibilityRulesSelector = createSelector(
    [SelectedCustomerSelector],
    (currentCustomer) => {
        return pathOr(null, ['offerings', 'changeOfferEligibilityRules'], currentCustomer);
    }
);

export const IsCoolingPeriodEnabledSelector = createSelector(
    [
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.CoolingPeriod)
    ],
    (coolingPeriod) => {
        return coolingPeriod.length > 1;
    }
);
