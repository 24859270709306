import moment from 'moment';
import i18n from 'invision-core/src/components/i18n/i18n';
import {
    getInjector,
    getUiNotificationService
} from 'invision-core/src/components/injectables/injector.helper';
import {ASYNC_STATUS_CONSTANTS} from '../constants/wizard.constants';
import CustomerCareKeys from '../../locales/keys';
import {RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION} from '../../customercare.constants';

/**
 * Returns the difference in seconds from the passed in timestamp or will return 0 if negative.
 * @param {moment.Moment} retrieveOrderQuoteTimestamp
 * @return {number}
 */
export const getRetrieveOrderQuoteCountdown = retrieveOrderQuoteTimestamp => {
    const retrieveQuoteTimestamp = retrieveOrderQuoteTimestamp.diff(moment.now(), 'seconds') || 0;

    return retrieveQuoteTimestamp > 0 ? retrieveQuoteTimestamp : 0;
};

/**
 * Used to see if async should repeat or handle any errors and make the interval stop.
 * @param {function} onRefreshQuote
 * @param {function} isIntervalContinuing
 */
export const asyncRepeatInterval = (onRefreshQuote, isIntervalContinuing) => {
    onRefreshQuote();

    const $interval = getInjector().get('$interval');

    const interval = $interval(() => {
        if (isIntervalContinuing()) {
            onRefreshQuote()
                .then((response) => {
                    if (response.Status === ASYNC_STATUS_CONSTANTS.FAILED) {
                        getUiNotificationService.transientError(i18n.translate(CustomerCareKeys.CART_SUMMARY.QUOTE_ERROR));
                        $interval.cancel(interval);
                    } else if (response.Status === ASYNC_STATUS_CONSTANTS.COMPLETE) {
                        $interval.cancel(interval);
                    }
                }).catch(() => {
                    $interval.cancel(interval);
                });
        } else  {
            $interval.cancel(interval);
        }
    }, RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION);
};

