export const prepareSubscriptionQuotes = (subscriptionQuotes) => {
    return subscriptionQuotes.map((sQ) => {
        return {
            cycleName: sQ.CycleName,
            cycleStart: sQ.CycleStart,
            cycleEnd: sQ.CycleEnd,

            items: sQ.Items.map((item) => {
                return {
                    amount: item.Amount || 0,
                    grossAmount: item.GrossAmount || 0,
                    id: item.Id,
                    name: item.Name
                };
            }),

            currency: sQ.Currency,
            brandableCurrency: sQ.BrandableCurrency,
            brandableCurrencyName: sQ.BrandableCurrencyName
        };
    });
};
