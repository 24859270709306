import CustomerCareKeys from '../../locales/keys';
import {
    CoreLocaleKeys,
    i18n,
    InjectorHelper
} from 'invision-core';
import {
    find,
    map,
    pathOr,
    propEq
} from 'ramda';
import {EARLY_TERMINATION_FEE_CHARGE_TYPES} from '../../components/shared/constants/etf.charge.type.contants';
import {FREQUENCY_TYPES} from 'invision-core/src/constants/frequency.types.constants';


export const createTableEtfPricingPlansHeaders = (chargeTypeId) => {
    const EtfPricingPlansTable = [
        {
            field: 'period',
            displayName: i18n.translate(CustomerCareKeys.PERIOD),
            width: '*',
            cellTemplate: require('../../components/shared/contractTermsConditionsDialog/cellTemplates/period.template.html'),
            resizable: false,
            enableSorting: false,
            enableColumnMoving: false,
        },
        {
            field: 'chargeTypeName',
            displayName: i18n.translate(CoreLocaleKeys.TYPE),
            width: '*',
            resizable: false,
            enableSorting: false,
            enableColumnMoving: false
        },
        {
            field: 'amount',
            displayName: i18n.translate(CoreLocaleKeys.AMOUNT),
            width: '*',
            resizable: false,
            enableSorting: false,
            enableColumnMoving: false,
            visible: chargeTypeId === EARLY_TERMINATION_FEE_CHARGE_TYPES.TIERED ? true : false
        },
        {
            field: 'minimumFee',
            displayName: i18n.translate(CoreLocaleKeys.MINIMUM_FEE),
            width: '*',
            resizable: false,
            enableSorting: false,
            enableColumnMoving: false,
            visible: chargeTypeId === EARLY_TERMINATION_FEE_CHARGE_TYPES.SLIDING ? true : false
        },
        {
            field: 'maximumFee',
            displayName: i18n.translate(CoreLocaleKeys.MAXIMUM_FEE),
            width: '*',
            resizable: false,
            enableSorting: false,
            enableColumnMoving: false,
            visible: chargeTypeId === EARLY_TERMINATION_FEE_CHARGE_TYPES.SLIDING ? true : false
        }
    ];

    return EtfPricingPlansTable;
};

const mapChargeTypeIdToChargeTypeName = (etfChargeTypeId, etfChargeTypes) => {
    const etfChargeType = find(propEq(String(etfChargeTypeId), 'Value'), etfChargeTypes);
    return etfChargeType ? etfChargeType.Name : '';
};

export const createTableEtfPricingPlansViewModel = (pricingPlanEtfInstance, etfChargeTypes) => {
    const $filter = InjectorHelper.getFilterService();
    const etfSchedules = pathOr(null, ['EarlyTerminationFeeSchedules'], pricingPlanEtfInstance);
    const formatView = (etfSchedule) => {

        if (!etfSchedule) {
            return {};
        } else {
            const periodLength = `${etfSchedule.PeriodStart} - ${etfSchedule.PeriodEnd}`;

            return {
                id: etfSchedule.Id,
                amount: $filter('invCurrency')(etfSchedule.MinValue, pricingPlanEtfInstance.CurrencyCode),
                period: getPeriodString(pricingPlanEtfInstance.PeriodTypeCode, periodLength),
                chargeTypeName: mapChargeTypeIdToChargeTypeName(pricingPlanEtfInstance.ChargeTypeCode, etfChargeTypes),
                minimumFee: $filter('invCurrency')(etfSchedule.MinValue, pricingPlanEtfInstance.CurrencyCode),
                maximumFee: $filter('invCurrency')(etfSchedule.MaxValue, pricingPlanEtfInstance.CurrencyCode)
            };
        }
    };
    return map(formatView, etfSchedules);
};

const isDateRange = (duration) => {
    return isNaN(duration);
};

export const getPeriodString = (periodType, duration) => {
    if (!periodType && !duration) {
        return '';
    }
    switch (periodType) {
        case FREQUENCY_TYPES.HOURLY:
            return (duration > 1 || isDateRange(duration)) ? i18n.translate(CoreLocaleKeys.RELATIVE_TIME.INTERVAL_MULTIPLE_HOURS, {
                interval: duration
            }) : `${duration} ${i18n.translate(CoreLocaleKeys.RELATIVE_TIME.INTERVAL_A_SINGLE_HOUR)}`;
        case FREQUENCY_TYPES.DAILY:
            return (duration > 1 || isDateRange(duration)) ? i18n.translate(CoreLocaleKeys.RELATIVE_TIME.INTERVAL_MULTIPLE_DAYS, {
                interval: duration
            }) : `${duration} ${i18n.translate(CoreLocaleKeys.RELATIVE_TIME.INTERVAL_A_SINGLE_DAY)}`;
        case FREQUENCY_TYPES.WEEKLY:
            return (duration > 1 || isDateRange(duration)) ? i18n.translate(CoreLocaleKeys.RELATIVE_TIME.INTERVAL_MULTIPLE_WEEKS, {
                interval: duration
            }) : `${duration} ${i18n.translate(CoreLocaleKeys.RELATIVE_TIME.INTERVAL_A_SINGLE_WEEK)}`;
        case FREQUENCY_TYPES.MONTHLY:
            return (duration > 1 || isDateRange(duration)) ? i18n.translate(CoreLocaleKeys.RELATIVE_TIME.INTERVAL_MULTIPLE_MONTHS, {
                interval: duration
            }) : `${duration} ${i18n.translate(CoreLocaleKeys.RELATIVE_TIME.INTERVAL_A_SINGLE_MONTH)}`;
        case FREQUENCY_TYPES.YEARLY:
            return (duration > 1 || isDateRange(duration)) ? i18n.translate(CoreLocaleKeys.RELATIVE_TIME.INTERVAL_MULTIPLE_YEARS, {
                interval: duration
            }) : `${duration} ${i18n.translate(CoreLocaleKeys.RELATIVE_TIME.INTERVAL_A_SINGLE_YEAR)}`;
        default:
            return '';
    }
};
