import Immutable from 'seamless-immutable';
import LocaleKeys from '../../locales/keys';

export const ALL_STEPS_KEYS = [
    LocaleKeys.WIZARD.STEPS.PRODUCTS,
    LocaleKeys.WIZARD.STEPS.CHECKOUT
];

export const CREATE_PRODUCTS_ORDER_STATE_NAME = 'index.customercare.customer.orders.createProductsOrder';
export const CREATE_SUBSCRIPTIONS_ORDER_STATE_NAME = 'index.customercare.customer.orders.createSubscriptionsOrder';
export const MANAGE_SERVICE_FEATURES_ORDER_STATE_NAME = 'index.customercare.customer.orders.serviceFeatures';
export const SERVICE_FEATURE_PRODUCT_STATUS_ACTIVE = 1;

export const PRODUCT_ORDER_WIZARD_MODE = {
    CREATE: 'CREATE',
    MODIFY: 'MODIFY',
    REPLACE: 'REPLACE',
    RESTORE: 'RESTORE'
};

export const SERVICE_FEATURE_ORDER_WIZARD_MODE = {
    MANAGE: 'MANAGE'
};

export const INSTANCE_PROPERTY_TYPES = Immutable({
    SELECT_OPTIONS: 1,
    TEXT_INPUT: 2
});

export const GIFT_CARD_INSTANCE_PROPERTY_NAMES = {
    NOTIFICATION_DATE: 'Gift Card Notification Date',
    RECIPIENT_EMAIL: 'Gift Card Recipient E-mail',
    SENDER_EMAIL: 'Gift Card Sender E-mail',
    SENDER_NAME: 'Gift Card Sender Name'
};

export const CART_ITEM_TYPE = {
    ADDITION: 'ADDITION',
    REMOVAL: 'REMOVAL'
};

export const DEFAULT_CART_ITEM_VIEW_MODEL = {
    brandableCurrencyName: null,
    childItems: [],
    currencyCode: null,
    discountAmount: 0,
    grossAmount: 0,
    totalAmount: 0,
    name: null,
    pricingPlanName: null,
    pricingPlanId: null,
    productId: null,
    instanceProperties: null,
    quantity: 1,
    id: null,
    index: null,
    isEditable: true,
    isRemovable: true,
    itemType: null
};

//https://qa-documentation.ascendon.tv/v18.1/data.aspx?type=ContentDirect.CoreService.Contract.Message.Subscriber.SearchCatalogEntityType&assembly=ContentDirect.CoreService.Contract
export const SEARCH_CATALOG_ENTITY_TYPES = {
    CHANNEL: 17,
    PERSON: 9,
    PRODUCT: 1,
    PROGRAM: 25
};

export const PRODUCTS_SEARCH_ORDER_CONSTANTS = {
    SORT_BY: {
        NAME: 1,
        DATE: 2,
        RELEVANCE: 4,
        VIEW_COUNT: 5
    },
    SORT_DIRECTION: {
        ASCENDING: 1,
        DESCENDING: 2
    }
};

export const PRODUCTS_SORT_OPTIONS = Immutable([
    {
        key: LocaleKeys.PRODUCTS_SEARCH.SORT_OPTIONS.RELEVANCE,
        value: PRODUCTS_SEARCH_ORDER_CONSTANTS.SORT_BY.RELEVANCE,
        direction: PRODUCTS_SEARCH_ORDER_CONSTANTS.SORT_DIRECTION.DESCENDING
    },
    {
        key: LocaleKeys.PRODUCTS_SEARCH.SORT_OPTIONS.NAME,
        value: PRODUCTS_SEARCH_ORDER_CONSTANTS.SORT_BY.NAME,
        direction: PRODUCTS_SEARCH_ORDER_CONSTANTS.SORT_DIRECTION.ASCENDING
    },
    {
        key: LocaleKeys.PRODUCTS_SEARCH.SORT_OPTIONS.DATE,
        value: PRODUCTS_SEARCH_ORDER_CONSTANTS.SORT_BY.DATE,
        direction: PRODUCTS_SEARCH_ORDER_CONSTANTS.SORT_DIRECTION.DESCENDING
    },
    {
        key: LocaleKeys.PRODUCTS_SEARCH.SORT_OPTIONS.VIEW_COUNT,
        value: PRODUCTS_SEARCH_ORDER_CONSTANTS.SORT_BY.VIEW_COUNT,
        direction: PRODUCTS_SEARCH_ORDER_CONSTANTS.SORT_DIRECTION.DESCENDING
    }
]);

export const DBSS_UPDATE_CART_ADDITIONAL_OPTIONS = {
    CombineProducts: true,
    PersistCart: false
};
