import {SUPPORTED_PAYMENT_INSTRUMENT_TYPES} from '../../components/customer/ewallet/ewallet.constants';

export const subscriberHasExternalGiftCardOfType = (allSubscriberPaymentMethods, externalGiftCardType) => {
    const existingEGC = allSubscriberPaymentMethods.find((subscriberPaymentMethod) => {
        if (String(subscriberPaymentMethod.Type) === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_GIFT_CARD) {
            return String(subscriberPaymentMethod.ExternalGiftCard.Type) === externalGiftCardType.Value;
        }
        return false;
    });
    return !!existingEGC;
};

export const isPaymentInstrumentTypeValueOptionSelected = (selectedPaymentInstrumentTypeValue, paymentInstrumentType,
    firstAcceptedPaymentInstrumentTypeCodeValue) => {
    if (selectedPaymentInstrumentTypeValue) {
        return paymentInstrumentType.Value === selectedPaymentInstrumentTypeValue;
    }
    return `${paymentInstrumentType.Value}` === firstAcceptedPaymentInstrumentTypeCodeValue;
};
