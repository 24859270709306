import Immutable from 'seamless-immutable';
import * as SearchActions from '../actions/search.coupon.actions';

export const INITIAL_STATE = Immutable({
    couponSearchModel: null
});

export default function SearchCouponsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case SearchActions.SET_COUPON_SEARCH_MODEL:
            return state.set('couponSearchModel', payload);
        case SearchActions.RESET_SEARCH_COUPON_MODEL_RECOVERABLE:
            return INITIAL_STATE;
        default:
            return state;
    }
}