import {
    RETRIEVE_ORDER_FOR_LOCKER_DETAIL_CONSTANTS,
    RETRIEVE_PRODUCT_DETAIL_CONSTANTS
} from './actions/customer.product.detail.actions';
import Immutable from 'seamless-immutable';

export const INITIAL_STATE = Immutable({
    data: null,
    orderData: null,
    isFetchingData: null
});

export default function productDetailReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case RETRIEVE_ORDER_FOR_LOCKER_DETAIL_CONSTANTS.BEGIN:
            return state
                .set('isFetchingData', true)
                .set('productDetailError', null);
        case RETRIEVE_ORDER_FOR_LOCKER_DETAIL_CONSTANTS.SUCCESS:
            return state
                .set('orderData', payload.Order)
                .set('isFetchingData', false)
                .set('productDetailError', null);
        case RETRIEVE_ORDER_FOR_LOCKER_DETAIL_CONSTANTS.FAILURE:
            return state
                .set('isFetchingData', false)
                .set('productDetailError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case RETRIEVE_PRODUCT_DETAIL_CONSTANTS.BEGIN:
            return state
                .set('isFetchingData', true)
                .set('productDetailError', null);
        case RETRIEVE_PRODUCT_DETAIL_CONSTANTS.SUCCESS:
            return state
                .set('data', payload)
                .set('isFetchingData', false)
                .set('productDetailError', null);
        case RETRIEVE_PRODUCT_DETAIL_CONSTANTS.FAILURE:
            return state
                .set('isFetchingData', false)
                .set('productDetailError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        default:
            return state;
    }
}
