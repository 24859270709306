import {ApiConstants, ThunkHelper} from 'invision-core';

export const RETRIEVE_PRODUCT_DETAIL_CONSTANTS = {
    BEGIN: 'RETRIEVE_PRODUCT_DETAIL_BEGIN',
    SUCCESS: 'RETRIEVE_PRODUCT_DETAIL_SUCCESS',
    FAILURE: 'RETRIEVE_PRODUCT_DETAIL_FAILURE'
};

const RETRIEVE_PRODUCT_DETAIL_EVENTS = [
    RETRIEVE_PRODUCT_DETAIL_CONSTANTS.BEGIN,
    RETRIEVE_PRODUCT_DETAIL_CONSTANTS.SUCCESS,
    RETRIEVE_PRODUCT_DETAIL_CONSTANTS.FAILURE
];

export const retrieveProductDetailPromise = (dispatch, customerId, lockerItemId) => {
    return ThunkHelper(dispatch, RETRIEVE_PRODUCT_DETAIL_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveLockerItem',
        data: {
            Id: lockerItemId
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const retrieveProductDetail = (customerId, lockerItemId) => {
    return (dispatch) => {
        return retrieveProductDetailPromise(dispatch, customerId, lockerItemId);
    };
};


export const RETRIEVE_ORDER_FOR_LOCKER_DETAIL_CONSTANTS = {
    BEGIN: 'RETRIEVE_ORDER_FOR_LOCKER_DETAIL_BEGIN',
    SUCCESS: 'RETRIEVE_ORDER_FOR_LOCKER_DETAIL_SUCCESS',
    FAILURE: 'RETRIEVE_ORDER_FOR_LOCKER_DETAIL_FAILURE'
};

const RETRIEVE_ORDER_FOR_LOCKER_DETAIL_EVENTS = [
    RETRIEVE_ORDER_FOR_LOCKER_DETAIL_CONSTANTS.BEGIN,
    RETRIEVE_ORDER_FOR_LOCKER_DETAIL_CONSTANTS.SUCCESS,
    RETRIEVE_ORDER_FOR_LOCKER_DETAIL_CONSTANTS.FAILURE
];

export const retrieveOrderForLockerDetailPromise = (dispatch, customerId, orderId) => {
    return ThunkHelper(dispatch, RETRIEVE_ORDER_FOR_LOCKER_DETAIL_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOrder',
        data: {
            OrderId: orderId
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const retrieveOrderForLockerDetail = (customerId, orderId) => {
    return (dispatch) => {
        return retrieveOrderForLockerDetailPromise(dispatch, customerId, orderId);
    };
};

export const GRANT_ADDITIONAL_RIGHTS_CONSTANTS = {
    BEGIN: 'GRANT_ADDITIONAL_RIGHTS_BEGIN',
    SUCCESS: 'GRANT_ADDITIONAL_RIGHTS_SUCCESS',
    FAILURE: 'GRANT_ADDITIONAL_RIGHTS_FAILURE'
};

const GRANT_ADDITIONAL_RIGHTS_EVENTS = [
    GRANT_ADDITIONAL_RIGHTS_CONSTANTS.BEGIN,
    GRANT_ADDITIONAL_RIGHTS_CONSTANTS.SUCCESS,
    GRANT_ADDITIONAL_RIGHTS_CONSTANTS.FAILURE
];

const grantAdditionalRightsPromise = (dispatch, customerId, options) => {
    return ThunkHelper(dispatch, GRANT_ADDITIONAL_RIGHTS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/GrantAccessRights',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            Id: options.Id,
            ActionCode: options.ActionCode,
            AccessRequests: options.GrantAccessCount,
            AccessHours: options.GrantTimeRemaining,
            Licenses: options.GrantLicenseCount,

        }
    });
};

export const grantAdditionalRights = (customerId, options) => {
    return (dispatch) => {
        return grantAdditionalRightsPromise(dispatch, customerId, options);
    };
};
