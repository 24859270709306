import values from 'ramda/src/values';
import ApiConstants from 'invision-core/src/api/constants';
import ThunkHelper from 'invision-core/src/api/thunk.helper';

export const UPDATE_MOBILE_NUMBER_PORTABILITY_ADDRESS_MODEL = 'UPDATE_MOBILE_NUMBER_PORTABILITY_ADDRESS_MODEL';

export const updateMobileNumberPortabilityAddressModel = (formModel) => {
    return {
        type: UPDATE_MOBILE_NUMBER_PORTABILITY_ADDRESS_MODEL,
        payload: formModel
    };
};

export const UPDATE_MOBILE_NUMBER_PORTABILITY_MODEL = 'UPDATE_MOBILE_NUMBER_PORTABILITY_MODEL';

export const updateMobileNumberPortabilityModel = (formModel) => {
    return {
        type: UPDATE_MOBILE_NUMBER_PORTABILITY_MODEL,
        payload: formModel
    };
};

export const RESET_MOBILE_NUMBER_PORTABILITY_MODELS = 'RESET_MOBILE_NUMBER_PORTABILITY_MODELS';

export const resetMobileNumberPortabilityModels = () => {
    return {
        type: RESET_MOBILE_NUMBER_PORTABILITY_MODELS,
    };
};

export const RESET_PORTABILITY_MODEL = 'RESET_PORTABILITY_MODEL';

export const resetPortabilityModel = () => {
    return {
        type: RESET_PORTABILITY_MODEL,
    };
};

export const SAVE_PORTABILITY_ATTRIBUTE = 'SAVE_PORTABILITY_ATTRIBUTE';

export const saveMobileNumberPortabilityAttribute = (attributeId) => {
    return {
        type: SAVE_PORTABILITY_ATTRIBUTE,
        payload: {
            attributeId
        }
    };
};

export const RESTORE_PORTABILITY_ATTRIBUTE = 'RESTORE_PORTABILITY_ATTRIBUTE';

export const restoreMobileNumberPortabilityAttribute  = (attributeId) => {
    return {
        type: RESTORE_PORTABILITY_ATTRIBUTE,
        payload: attributeId
    };
};

export const SET_SHOW_PORTABILITY = 'SET_SHOW_PORTABILITY';

export const setShowPortability  = (showPortability) => {
    return {
        type: SET_SHOW_PORTABILITY,
        payload: showPortability
    };
};

export const MOBILE_NUMBER_PORTABILITY_FORM_VALIDATED = 'MOBILE_NUMBER_PORTABILITY_FORM_VALIDATED';

export const formValidated  = () => {
    return {
        type: MOBILE_NUMBER_PORTABILITY_FORM_VALIDATED
    };
};
export const VALIDATE_PORT_IN_DATA = {
    BEGIN: 'VALIDATE_PORT_IN_DATA_BEGIN',
    SUCCESS: 'VALIDATE_PORT_IN_DATA_SUCCESS',
    FAILURE: 'VALIDATE_PORT_IN_DATA_FAILURE'
};

export const VALIDATE_PORT_IN_DATA_TYPES = [
    VALIDATE_PORT_IN_DATA.BEGIN,
    VALIDATE_PORT_IN_DATA.SUCCESS,
    VALIDATE_PORT_IN_DATA.FAILURE
];



export const validatePortInDataPromise = (dispatch, addressData, portabilityData, inventoryTypeId, customerId) => {
    return ThunkHelper(dispatch, VALIDATE_PORT_IN_DATA_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/ValidatePortInData',
        data: {
            PortInData: {
                AdditionalProperties: portabilityData.mnpAdditionalProps,
                Address: addressData,
                ServiceProviderId: portabilityData.provider,
                InventoryTypeId: inventoryTypeId,
                PortInNumber: portabilityData.existingNumber,
                ValidationToken: null
            }
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });

};
export const validatePortInData = (addressData, portabilityData, inventoryTypeId, customerId) => {
    return (dispatch) => {
        return validatePortInDataPromise(dispatch, addressData, portabilityData, inventoryTypeId, customerId);
    };
};
export const MOBILE_NUMBER_PORTABILITY_SET_EDIT_ADDITIONAL_PROPERTY = 'MOBILE_NUMBER_PORTABILITY_SET_EDIT_ADDITIONAL_PROPERTY';
export const mobileNumberPortabilitySetEditAdditionalProperty = (AdditionalProperties) => {
    return {
        type: MOBILE_NUMBER_PORTABILITY_SET_EDIT_ADDITIONAL_PROPERTY,
        payload: AdditionalProperties
    };

};

export const MOBILE_NUMBER_PORTABILITY_SET_EDIT_ADDITIONAL_PROPERTY_ON_CHANGE = 'MOBILE_NUMBER_PORTABILITY_SET_EDIT_ADDITIONAL_PROPERTY_ON_CHANGE';
export const mobileNumberPortabilitySetEditAdditionalPropertyOnChange = (id, newValue) => {
    return {
        type: MOBILE_NUMBER_PORTABILITY_SET_EDIT_ADDITIONAL_PROPERTY_ON_CHANGE,
        payload: {
            id: id,
            newValue: newValue
        }
    };
};

export const UPDATE_PORT_IN_DATA = {
    BEGIN: 'UPDATE_PORT_IN_DATA_BEGIN',
    SUCCESS: 'UPDATE_PORT_IN_DATA_SUCCESS',
    FAILURE: 'UPDATE_PORT_IN_DATA_FAILURE'
};

export const updatePortInDetails = ({customerId, caseId, portabilityData, address, inventoryTypeId, validationToken}) => {
    return (dispatch) => {
        return ThunkHelper(dispatch, values(UPDATE_PORT_IN_DATA), {
            method: 'post',
            url: 'SubscriberManagement/UpdatePortInDetails',
            data: {
                CaseId: caseId,
                PortInData: {
                    AdditionalProperties: portabilityData.mnpAdditionalProps,
                    Address: address,
                    ServiceProviderId: portabilityData.provider,
                    InventoryTypeId: inventoryTypeId,
                    PortInNumber: portabilityData.existingNumber,
                    ValidationToken: validationToken
                }
            },
            headers: {
                [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
            }
        });
    };
};