import {createSelector} from 'reselect';
import Immutable from 'seamless-immutable';
import {MetadataConstants, MetadataSelectors, SessionSelectors, SelectHelper} from 'invision-core';
import {CurrentCustomerIdSelector, RouteParams, SelectedCustomerSelector} from './customer.selectors';
import {
    findIndex,
    path,
    pathOr,
    propEq
} from 'ramda';

const getInteractionTypeDescription = (interactionTypeCodes, interaction) => {
    const interactionType = interactionTypeCodes.find((interactionType) => {
        return parseInt(interactionType.Value, 10) === interaction.Type;
    });

    return pathOr('', ['Description'], interactionType);
};

const getInteractionTypeName = (interactionTypeCodes, interaction) => {
    const interactionType = interactionTypeCodes.find((interactionType) => {
        return parseInt(interactionType.Value, 10) === interaction.Type;
    });

    return pathOr('', ['Name'], interactionType);
};

const InteractionTypeCodes = (state) => {
    return MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.InteractionType, state);
};

export const CurrentInteractionIdSelector = createSelector(
    [RouteParams],
    (params) => {
        return params.interactionId;
    }
);

const InteractionsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.interactions;
    }
);

const DEFAULT_INTERACTION = Immutable({});
const CurrentInteractionSelector = createSelector(
    [InteractionsSelector],
    (interactionState) => {
        return interactionState.currentInteraction ?
            interactionState.currentInteraction : DEFAULT_INTERACTION;
    }
);

export const FormattedInteractionSelector = createSelector(
    [
        CurrentInteractionSelector,
        InteractionTypeCodes
    ],
    (interaction, interactionTypeCodes) => {
        return interaction.set('TypeDescription', getInteractionTypeDescription(interactionTypeCodes, interaction));
    }
);

export const InteractionErrorSelector = createSelector(
    [InteractionsSelector],
    (interactionState) => {
        return interactionState.interactionError;
    }
);

const RecoverableInteractionsSelector = (state) => {
    return state.customercare.recoverableUiState.interactions;
};

const DEFAULT_INTERACTION_MAP = [];

export const InteractionsListSelector = createSelector(
    [InteractionsSelector],
    (interactionState) => {
        return interactionState.data.records ?
            interactionState.data.records : DEFAULT_INTERACTION_MAP;
    }
);

const DEFAULT_FORMATTED_INTERACTIONS = [];
export const FormattedInteractionsSelector = createSelector(
    [InteractionsListSelector, InteractionTypeCodes],
    (interactions, interactionTypeCodes) => {
        if (interactions.length === 0 || interactionTypeCodes.length === 0) {
            return DEFAULT_FORMATTED_INTERACTIONS;
        }
        return interactions.map((interaction) => {
            return interaction.set('TypeDescription', getInteractionTypeDescription(interactionTypeCodes, interaction));
        });
    }
);

export const FormattedInteractionsWithNamesSelector = createSelector(
    [InteractionsListSelector, InteractionTypeCodes],
    (interactions, interactionTypeCodes) => {
        if (interactions.length === 0 || interactionTypeCodes.length === 0) {
            return DEFAULT_FORMATTED_INTERACTIONS;
        }
        return interactions.map((interaction) => {
            return interaction.set('TypeName', getInteractionTypeName(interactionTypeCodes, interaction));
        });
    }
);

export const InteractionsLoadedSelector = createSelector(
    [InteractionsListSelector],
    (interactions) => {
        return interactions.length > 0;
    }
);

export const InteractionsLoadingSelector = createSelector(
    [InteractionsSelector],
    (interactionState) => {
        return interactionState.isFetchingInteractions;
    }
);

const InteractionFilterData = createSelector(
    [RecoverableInteractionsSelector],
    (recoverableInteraction) => {
        return recoverableInteraction.filterData;
    }
);

export const CategoryTypeSelector = createSelector(
    [InteractionFilterData],
    (filterData) => {
        return filterData.categoryType;
    }
);

export const InteractionTypeSelector = createSelector(
    [InteractionFilterData],
    (filterData) => {
        return filterData.interactionType;
    }
);

export const SelectedPageSizePreference = createSelector(
    [InteractionFilterData],
    (filterData) => {
        return filterData.pageSizePreference;
    }
);

export const ShowChangesSelector = createSelector(
    [InteractionFilterData],
    (filterData) => {
        return filterData.onlyShowChangedProperties;
    }
);

export const SortCriteriaSelector = createSelector(
    [InteractionFilterData],
    (filterData) => {
        return pathOr(null, ['sortCriteria'], filterData);
    }
);

export const PageNumberSelector = createSelector(
    [RecoverableInteractionsSelector],
    (interaction) => {
        return pathOr(0, ['pageNumber'], interaction);
    }
);

export const PageCountSelector = createSelector(
    [InteractionsSelector],
    (interactionState) => {
        return pathOr(0, ['data', 'pageCount'], interactionState);
    }
);

export const RecordCountSelector = createSelector(
    [InteractionsSelector],
    (interactionState) => {
        return pathOr(0, ['data', 'recordCount'], interactionState);
    }
);

export const EndRecordSelector = createSelector(
    [PageNumberSelector, SessionSelectors.PageSizePreferenceSelector, RecordCountSelector],
    (pageNumber, pageSize, recordCount) => {
        const endNumber = (pageNumber * pageSize);

        if (endNumber < recordCount) {
            return endNumber;
        }

        return recordCount;
    }
);

export const StartRecordSelector = createSelector(
    [PageNumberSelector, SessionSelectors.PageSizePreferenceSelector, RecordCountSelector],
    (pageNumber, pageSize, recordCount) => {
        if (recordCount <= 0) {
            return 0;
        }

        return ((pageNumber - 1) * pageSize) + 1;
    }
);

export const InteractionTypeCodesFormatted = createSelector(
    [CategoryTypeSelector, InteractionTypeCodes, InteractionTypeSelector],
    (categoryType, interactionTypeCodes, interactionType) => {
        const formattedInteractionCodes = [];

        interactionTypeCodes.forEach((code) => {
            const categoryAttr = code.AdditionalProperties.find((prop) => {
                return prop.Key === 'interaction_category_code';
            });

            if (categoryAttr && categoryType && categoryAttr.Value !== categoryType.value) {
                return;
            }

            const formattedCode = SelectHelper.codeValueToSelectOption(code);
            const selectedValue = interactionType ? interactionType.value : null;
            formattedCode.selected = formattedCode.value === selectedValue;
            formattedInteractionCodes.push(formattedCode);
        });

        return formattedInteractionCodes;
    }
);

export const InteractionPositionSelector = createSelector(
    [StartRecordSelector, InteractionsListSelector, CurrentInteractionSelector],
    (startingRecord, interactions, current) => {
        if (!path(['Identifier'], current)) {
            return '';
        }

        const index = findIndex(propEq(current.Identifier, 'Identifier'), interactions);
        return index + startingRecord;
    }
);

export const NextInteractionIdSelector = createSelector(
    [CurrentInteractionSelector, InteractionsListSelector],
    (current, interactions) => {
        if (!path(['Identifier'], current)) {
            return null;
        }

        const index = findIndex(propEq(current.Identifier, 'Identifier'), interactions);
        return pathOr(null, [index + 1, 'Identifier'], interactions);
    }
);

export const PreviousInteractionIdSelector = createSelector(
    [CurrentInteractionSelector, InteractionsListSelector],
    (current, interactions) => {
        if (!path(['Identifier'], current)) {
            return null;
        }

        let index = findIndex(propEq(current.Identifier, 'Identifier'), interactions) - 1;
        if (index < -1) {
            index = interactions.length - 1;
        }

        return interactions[index] ? interactions[index].Identifier : null;
    }
);

export const FilterDataSelector = createSelector(
    [
        CategoryTypeSelector,
        CurrentCustomerIdSelector,
        InteractionTypeSelector,
        PageNumberSelector,
        SessionSelectors.PageSizePreferenceSelector
    ],
    (categoryType, currentCustomerId, interactionType, currentPage, pageSize) => {
        return {
            customerId: currentCustomerId,
            InteractionCategory: pathOr(null, ['id'], categoryType),
            InteractionType: pathOr(null, ['id'], interactionType),
            PageNumber: currentPage,
            PageSize: pageSize,
            SearchBySubscriber: true
        };
    }
);
