import {createSelector} from 'reselect';
import {
    pathOr,
    uniq
} from 'ramda';
import {
    OfferingDisconnectSelector
} from './offering.disconnect.selectors';

export const AllOfferItemsSelector = createSelector(
    [OfferingDisconnectSelector],
    (offeringDisconnect) => {
        return pathOr(null, ['data', 'RemoveItems'], offeringDisconnect);
    }
);

export const OfferingsSelector = createSelector(
    [AllOfferItemsSelector],
    (offerItems) => {
        if (!offerItems) {
            return null;
        }

        return uniq(offerItems.map((offerItem) => {
            return {
                id : offerItem.OrderedOfferingId,
                offeringName : offerItem.OrderedOfferingName,
                removeItems : offerItems.filter((offerItemForFiltering) => {
                    return offerItemForFiltering.OrderedOfferingId === offerItem.OrderedOfferingId;
                })
            };
        }));
    }
);

export const SelectedCustomerOfferingsCurrency = createSelector(
    [OfferingsSelector],
    (offerings) => {
        //Currently you cannot have a configuration where the customer has offerings with different currency codes.
        if (!offerings || !offerings.length) {
            return null;
        }

        return pathOr(null, ['0', 'removeItems', '0', 'Details', 'PricingPlan', 'Currency'], offerings);
    }
);

export const IsUnderContractForDisconnectOffers = createSelector(
    [OfferingDisconnectSelector],
    (offeringDisconnect) => {
        return !!pathOr(null, ['data', 'contractEnd'], offeringDisconnect);
    }
);