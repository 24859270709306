import metadataThunkHelper from 'invision-core/src/api/metadata.thunk.helper';
import thunkHelper from 'invision-core/src/api/thunk.helper';
import {
    CUSTOM_SUBSCRIBER_LANGUAGE_HEADER,
    LANGUAGE_HEADER,
    SUBSCRIBER_ID_HEADER
} from 'invision-core/src/api/constants';
import {TRANSFER_WIZARD_PREFIX} from '../constants/wizard.constants';
import createWizardActions from '../helpers/offer.ordering.wizard.action.helper';

const wizardActions = createWizardActions(TRANSFER_WIZARD_PREFIX);

export const updateShoppingCart = wizardActions.updateShoppingCart;

export const VALIDATE_SUBSCRIBER_FOR_TRANSFER = {
    BEGIN: 'VALIDATE_SUBSCRIBER_FOR_TRANSFER_BEGIN',
    SUCCESS: 'VALIDATE_SUBSCRIBER_FOR_TRANSFER_SUCCESS',
    FAILURE: 'VALIDATE_SUBSCRIBER_FOR_TRANSFER_FAILURE'
};

export const VALIDATE_SUBSCRIBER_FOR_TRANSFER_EVENTS = [
    VALIDATE_SUBSCRIBER_FOR_TRANSFER.BEGIN,
    VALIDATE_SUBSCRIBER_FOR_TRANSFER.SUCCESS,
    VALIDATE_SUBSCRIBER_FOR_TRANSFER.FAILURE
];

export const RETRIEVE_TRANSFER_DESTINATION_CUSTOMER = {
    BEGIN: 'RETRIEVE_TRANSFER_DESTINATION_CUSTOMER_BEGIN',
    SUCCESS: 'RETRIEVE_TRANSFER_DESTINATION_CUSTOMER_SUCCESS',
    FAILURE: 'RETRIEVE_TRANSFER_DESTINATION_CUSTOMER_FAILURE'
};

export const RETRIEVE_TRANSFER_DESTINATION_CUSTOMER_EVENTS = [
    RETRIEVE_TRANSFER_DESTINATION_CUSTOMER.BEGIN,
    RETRIEVE_TRANSFER_DESTINATION_CUSTOMER.SUCCESS,
    RETRIEVE_TRANSFER_DESTINATION_CUSTOMER.FAILURE
];

export const RESET_TRANSFER_WIZARD = 'RESET_TRANSFER_WIZARD';
export const resetTransfer = () => {
    return {
        type: RESET_TRANSFER_WIZARD
    };
};

export const RETRIEVE_TRANSFERABLE_OFFERINGS = {
    BEGIN: 'RETRIEVE_TRANSFERABLE_OFFERINGS_BEGIN',
    SUCCESS: 'RETRIEVE_TRANSFERABLE_OFFERINGS_SUCCESS',
    FAILURE: 'RETRIEVE_TRANSFERABLE_OFFERINGS_FAILURE'
};

const RETRIEVE_TRANSFERABLE_OFFERINGS_EVENTS = [
    RETRIEVE_TRANSFERABLE_OFFERINGS.BEGIN,
    RETRIEVE_TRANSFERABLE_OFFERINGS.SUCCESS,
    RETRIEVE_TRANSFERABLE_OFFERINGS.FAILURE
];

const retrieveTransferableOfferingsPromise = (dispatch, customerId) => {
    return thunkHelper(dispatch, RETRIEVE_TRANSFERABLE_OFFERINGS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveTransferableOfferings',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {}
    });
};

export const retrieveTransferableOfferings = (customerId) => {
    return (dispatch) => {
        return retrieveTransferableOfferingsPromise(dispatch, customerId);
    };
};

export const RETRIEVE_TRANSFER_OFFERING_CONTEXT = {
    BEGIN: 'RETRIEVE_TRANSFER_OFFERING_CONTEXT_BEGIN',
    SUCCESS: 'RETRIEVE_TRANSFER_OFFERING_CONTEXT_SUCCESS',
    FAILURE: 'RETRIEVE_TRANSFER_OFFERING_CONTEXT_FAILURE'
};

const RETRIEVE_TRANSFER_OFFERING_CONTEXT_EVENTS = [
    RETRIEVE_TRANSFER_OFFERING_CONTEXT.BEGIN,
    RETRIEVE_TRANSFER_OFFERING_CONTEXT.SUCCESS,
    RETRIEVE_TRANSFER_OFFERING_CONTEXT.FAILURE
];

const retrieveTransferOfferingContextPromise = (dispatch, customerId, offerings) => {
    return thunkHelper(dispatch, RETRIEVE_TRANSFER_OFFERING_CONTEXT_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveTransferOfferingContext',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            Offerings: offerings
        }
    });
};

export const retrieveTransferOfferingContext= (customerId, offerings) => {
    return (dispatch) => {
        return retrieveTransferOfferingContextPromise(dispatch, customerId, offerings);
    };
};

const GET_OFFERING_METADATA = {
    BEGIN: 'GET_OFFERING_METADATA_BEGIN',
    SUCCESS: 'GET_OFFERING_METADATA_SUCCESS',
    FAILURE: 'GET_OFFERING_METADATA_FAILURE'
};

const GET_OFFERING_METADATA_EVENTS = [
    GET_OFFERING_METADATA.BEGIN,
    GET_OFFERING_METADATA.SUCCESS,
    GET_OFFERING_METADATA.FAILURE
];

const getOfferingMetadataPromise = (dispatch, offeringId, language) => {
    return metadataThunkHelper(dispatch, GET_OFFERING_METADATA_EVENTS, {
        url: `Offering/Id/${offeringId}`,
        headers: {
            [LANGUAGE_HEADER]: language || undefined
        }
    });
};

export const getOfferingMetadata = (offeringId, language) => {
    return (dispatch) => {
        return getOfferingMetadataPromise(dispatch, offeringId, language);
    };
};

export const SET_OFFERINGS_METADATA = 'SET_OFFERINGS_METADATA';
export const setOfferingsMetadata = (offeringsMetadata) => {
    return {
        payload: offeringsMetadata,
        type: SET_OFFERINGS_METADATA
    };
};

export const SELECT_OFFERING_FOR_TRANSFER = 'SELECT_OFFERING_FOR_TRANSFER';
export const selectOfferingForTransfer = (offeringInstanceId) => {
    return {
        payload: offeringInstanceId,
        type: SELECT_OFFERING_FOR_TRANSFER
    };
};

export const UNSELECT_OFFERING_FOR_TRANSFER = 'UNSELECT_OFFERING_FOR_TRANSFER';
export const unselectOfferingForTransfer = (offeringInstanceId) => {
    return {
        payload: offeringInstanceId,
        type: UNSELECT_OFFERING_FOR_TRANSFER
    };
};

export const retrieveCustomerPromise = (dispatch, customerId, languageHeader) => {
    return thunkHelper(dispatch, RETRIEVE_TRANSFER_DESTINATION_CUSTOMER_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveSubscriber',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId,
            [CUSTOM_SUBSCRIBER_LANGUAGE_HEADER]: languageHeader
        },
        data: {}
    });
};

const validateSubscriberForServiceTransferPromise = (dispatch, selectedCustomerId, currentCustomerId) => {
    return thunkHelper(dispatch, VALIDATE_SUBSCRIBER_FOR_TRANSFER_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/ValidateSubscriberForServiceTransfer',
        headers: {
            [SUBSCRIBER_ID_HEADER]: currentCustomerId
        },
        data: {
            SubscriberId: selectedCustomerId,
        }
    });
};

export const validateSubscriberForServiceTransfer = (selectedCustomerId, currentCustomerId) => {
    return (dispatch) => {
        return validateSubscriberForServiceTransferPromise(dispatch, selectedCustomerId, currentCustomerId);
    };
};

export const retrieveCustomer = (customerId, languageHeader) => {
    return (dispatch) => {
        return retrieveCustomerPromise(dispatch, customerId, languageHeader);
    };
};
