import {createSelector} from 'reselect';
import {RouteParams} from './customer.selectors';
import {
    isNil,
    propOr
} from 'ramda';
import {
    generateTitle,
    getCreationDate
} from '../../components/giftCard/gift.card.helper';

export const GiftCardResultStoreSelector = (state) => {
    return state.customercare.giftCardResult;
};

export const CurrentGiftCardIdSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return parseInt(routeParams.giftCardId, 10);
    }
);

export const IsLoadingDataSelector = createSelector(
    [GiftCardResultStoreSelector],
    (giftCard) => {
        return giftCard.isFetchingGiftCard;
    }
);

export const CurrentGiftCardSelector = createSelector(
    [GiftCardResultStoreSelector],
    (giftCard) => {
        if (!isNil(giftCard.data)) {
            const gc = Object.assign({}, giftCard.data);
            gc.creationDate = getCreationDate(giftCard.data);
            gc.title = generateTitle(giftCard.data);
            return gc;
        }
        return null;
    }
);

export const GiftCardNotificationErrorSelector = createSelector(
    [GiftCardResultStoreSelector],
    (giftCard) => {
        return propOr(null, 'giftCardNotificationError', giftCard);
    }
);

export const IsResendingGiftCardNotificationSelector = createSelector(
    [GiftCardResultStoreSelector],
    (giftCard) => {
        return propOr(false, 'isResendingGiftCardNotification', giftCard);
    }
);
