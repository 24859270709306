import Immutable from 'seamless-immutable';
import {RETRIEVE_ACCOUNT_STATUS_CUSTOM_TILE_CONSTANTS} from './actions/custom.tiles.actions';
import {CUSTOM_TILE_TYPE} from 'invision-core/src/constants/custom.tiles.constants';

export const INITIAL_STATE = Immutable({
    data: {}
});

const TEST_SAMPLE_STRING = 'good <script>alert("go team!");</script> great customer, outstanding risk';
const MAX_RESULT_LENGTH = 40;

export function CustomTileResultReducer(state = INITIAL_STATE, {payload = {}, type, requestObject}) {
    switch (type) {
        case RETRIEVE_ACCOUNT_STATUS_CUSTOM_TILE_CONSTANTS.BEGIN:
            return state
                .setIn(['data', requestObject.Id], {
                    key: requestObject.Id,
                    value: null,
                    isFetchingData: true,
                    hasError: false
                });
        case RETRIEVE_ACCOUNT_STATUS_CUSTOM_TILE_CONSTANTS.SUCCESS:
            return state
                .setIn(['data', requestObject.Id, 'hasError'], false)
                .setIn(['data', requestObject.Id, 'value'], validateResults(payload, requestObject))
                .setIn(['data', requestObject.Id, 'isFetchingData'], false);
        case RETRIEVE_ACCOUNT_STATUS_CUSTOM_TILE_CONSTANTS.FAILURE: {
            return state
                .setIn(['data', requestObject.Id, 'hasError'], true)
                .setIn(['data', requestObject.Id, 'isFetchingData'], false);
        }
        default:
            return state;
    }
}

const validateResults = (payload, requestObject) => {
    let value = payload[requestObject.responseParam];
    if (requestObject.Type === CUSTOM_TILE_TYPE.TEXT) {
        if (requestObject.isExample) {
            // this if will catch an example text response.  this is for demo and testing purposes only.
            value = TEST_SAMPLE_STRING;
        }
        if (value.length > MAX_RESULT_LENGTH) {
            value = value.substring(0, MAX_RESULT_LENGTH);
        }
    } else if (requestObject.isExample && requestObject.Type === CUSTOM_TILE_TYPE.BOOLEAN) {
        value = (value % 10) > 5;
    }
    return value;
};