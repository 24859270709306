import Immutable from 'seamless-immutable';
import * as RedeemGiftOrderActions from '../actions/redeem.gift.order.actions';
import {camelCaseKeys} from '../../utilities/object.formatting.helper';
import {pathOr} from 'ramda';

const EMPTY_ARRAY = [];

export const INITIAL_STATE = Immutable({
    giftOrderPreview: {
        isFetchingGiftOrderPreview: false,
        products: [],
        subscriptions: [],
        senderName: null,
        senderEmail: null,
        senderMessage: null,
        shippingAddress: null,
        shippingMethod: null,
        previewGiftOrderError: null,
        previewGiftOrderQuoteError: null
    },
    redemptionCode: null,
    selectedShipToName: null,
    selectedShippingAddress: null, // purposefully not storing Id only because address does not need to exist on customer
    selectedShippingMethod: null,
    giftOrderSubmit: {
        isSubmittingGiftOrderRedemption: false,
        submitGiftOrderError: null,
        orderNumber: null
    }
});

export default function RedeemGiftReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case RedeemGiftOrderActions.PREVIEW_GIFT_REDEMPTION_ORDER.BEGIN:
            return state.setIn(['giftOrderPreview', 'isFetchingGiftOrderPreview'], true)
                .setIn(['giftOrderPreview', 'products'], [])
                .setIn(['giftOrderPreview', 'subscriptions'], [])
                .setIn(['giftOrderPreview', 'senderName'], null)
                .setIn(['giftOrderPreview', 'senderEmail'], null)
                .setIn(['giftOrderPreview', 'senderMessage'], null)
                .setIn(['giftOrderPreview', 'shippingAddress'], null)
                .setIn(['giftOrderPreview', 'shippingMethod'], null);
        case RedeemGiftOrderActions.PREVIEW_GIFT_REDEMPTION_ORDER.SUCCESS:
            return state.setIn(['giftOrderPreview', 'isFetchingGiftOrderPreview'], false)
                .setIn(['giftOrderPreview', 'products'], prepareLockerItemsForGiftOrderProducts(payload.LockerItems))
                .setIn(['giftOrderPreview', 'subscriptions'], prepareSubscriptionsForGiftOrderSubscriptions(payload.Subscriptions))
                .setIn(['giftOrderPreview', 'senderName'], payload.SenderName)
                .setIn(['giftOrderPreview', 'senderEmail'], payload.SenderEmail)
                .setIn(['giftOrderPreview', 'senderMessage'], payload.SenderMessage)
                .setIn(['giftOrderPreview', 'shippingAddress'], payload.ShippingAddress ? payload.ShippingAddress : null)
                .setIn(['giftOrderPreview', 'shippingMethod'], payload.ShippingMethod ? payload.ShippingMethod : null)
                .setIn(['giftOrderPreview', 'previewGiftOrderError'], null);
        case RedeemGiftOrderActions.PREVIEW_GIFT_REDEMPTION_ORDER.FAILURE:
            return state.setIn(['giftOrderPreview', 'previewGiftOrderError'], {
                code: payload.Code,
                message: payload.translatedMessage,
                severity: payload.Severity
            })
                .setIn(['giftOrderPreview', 'isFetchingGiftOrderPreview'], false);
        case RedeemGiftOrderActions.UPDATE_PREVIEW_WITH_SUBSCRIPTIONS_QUOTE.BEGIN:
            return state.setIn(['giftOrderPreview', 'isFetchingGiftOrderPreview'], true);
        case RedeemGiftOrderActions.UPDATE_PREVIEW_WITH_SUBSCRIPTIONS_QUOTE.SUCCESS:
            return state.setIn(['giftOrderPreview', 'isFetchingGiftOrderPreview'], false)
                .setIn(['giftOrderPreview', 'subscriptions'], prepareSubscriptionsForGiftOrderSubscriptions(payload.Subscriptions))
                .setIn(['giftOrderPreview', 'previewGiftOrderQuoteError'], null);
        case RedeemGiftOrderActions.UPDATE_PREVIEW_WITH_SUBSCRIPTIONS_QUOTE.FAILURE:
            return state.setIn(['giftOrderPreview', 'previewGiftOrderQuoteError'], {
                code: payload.Code,
                message: payload.translatedMessage,
                severity: payload.Severity
            }).setIn(['giftOrderPreview', 'isFetchingGiftOrderPreview'], false);
        case RedeemGiftOrderActions.RESET_REDEEM_GIFT_ORDER:
            return INITIAL_STATE;
        case RedeemGiftOrderActions.UPDATE_REDEMPTION_CODE:
            return state.set('redemptionCode', payload);
        case RedeemGiftOrderActions.UPDATE_GIFT_ORDER_SHIPPING_ADDRESS:
            return state.set('selectedShippingAddress', payload);
        case RedeemGiftOrderActions.UPDATE_GIFT_ORDER_SHIP_TO_NAME:
            return state.set('selectedShipToName', payload);
        case RedeemGiftOrderActions.SUBMIT_GIFT_ORDER_REDEMPTION_ORDER.BEGIN:
            return state.setIn(['giftOrderSubmit', 'isSubmittingGiftOrderRedemption'], true);
        case RedeemGiftOrderActions.SUBMIT_GIFT_ORDER_REDEMPTION_ORDER.SUCCESS:
            return state.setIn(['giftOrderSubmit', 'isSubmittingGiftOrderRedemption'], false)
                .setIn(['giftOrderSubmit', 'orderNumber'], payload.Order.OrderNumber)
                .setIn(['giftOrderSubmit', 'submitGiftOrderError'], null);
        case RedeemGiftOrderActions.SUBMIT_GIFT_ORDER_REDEMPTION_ORDER.FAILURE:
            return state.setIn(['giftOrderSubmit', 'isSubmittingGiftOrderRedemption'], false)
                .setIn(['giftOrderSubmit', 'orderNumber'], null)
                .setIn(['giftOrderSubmit', 'submitGiftOrderError'], {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case RedeemGiftOrderActions.SET_SELECTED_SHIPPING_METHOD:
            return state.set('selectedShippingMethod', payload);
        default:
            return state;
    }
}

function prepareLockerItemsForGiftOrderProducts(lockerItems) {
    if (lockerItems) {
        return camelCaseKeys(lockerItems);
    }
    return EMPTY_ARRAY;
}

function prepareSubscriptionsForGiftOrderSubscriptions(subscriptions) {
    if (subscriptions) {
        const subscriptionProducts = subscriptions.map((subscription) => {

            const customSubscription = {
                item: subscription.Items[0],
                paymentInstrumentRequired: subscription.Policy.PaymentInstrumentRequired,
                quoteSummary: null
            };

            const subscriptionQuote = subscription.Quote;
            if (subscriptionQuote) {
                customSubscription.quoteSummary = {
                    cycleName: subscriptionQuote.CycleName,
                    cycleStart: subscriptionQuote.CycleStart,
                    cycleEnd: subscriptionQuote.CycleEnd,

                    items: subscriptionQuote.Items.map((item) => {
                        return {
                            amount: item.Amount || 0,
                            grossAmount: item.GrossAmount || 0,
                            id: item.Id,
                            name: item.Name
                        };
                    }),

                    currency: subscriptionQuote.Currency,
                    brandableCurrency: subscriptionQuote.BrandableCurrency,
                    brandableCurrencyName: subscriptionQuote.BrandableCurrencyName,

                    subtotal: pathOr(0, ['Totals', 'SubTotalAmount'], subscriptionQuote),
                    taxes: pathOr(0, ['Totals', 'TaxAmount'], subscriptionQuote),
                    discounts: pathOr(0, ['Totals', 'DiscountAmount'], subscriptionQuote),
                    shipping: pathOr(0, ['Totals', 'ShippingAmount'], subscriptionQuote),
                    total: pathOr(0, ['Totals', 'TotalAmount'], subscriptionQuote)
                };
            }
            return customSubscription;
        });
        return camelCaseKeys(subscriptionProducts);
    }
    return EMPTY_ARRAY;
}
