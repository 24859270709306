import Immutable from 'seamless-immutable';


export const INITIAL_STATE = Immutable({
    selectedNodeId: null
});

/* eslint-disable */
export default function customerAccountHierarchyUiReducer( state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        default:
            return state;
    }
}
/* eslint-enable */