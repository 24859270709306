import Immutable from 'seamless-immutable';
import {RESET_CUSTOMER_RECOVERABLE} from './../actions/customer.actions';
import * as HouseholdActions from './../actions/customer.household.actions';

export const INITIAL_STATE = Immutable({
    filterData: {
        includeRemoved: false
    },
    selectedHouseholdMemberId: null
});

export default function HouseholdReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case HouseholdActions.SET_INCLUDED_REMOVED_FILTER:
            return state.setIn(['filterData', 'includeRemoved'], payload);
        case HouseholdActions.SET_SELECTED_HOUSEHOLD_MEMBER:
            return state
                .set('selectedHouseholdMemberId', payload);
        case RESET_CUSTOMER_RECOVERABLE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
