import {createSelector} from 'reselect';
import {MetadataSelectors} from 'invision-core';
import {WorkflowStateEnum} from '../../utilities/workflow.state.enum';

const getMappedWorkflowInstances = (instances, workflowActivityStates) => {
    let final = instances;
    Object.keys(instances).forEach((key) => {
        const instance = instances[key].workflowInstance;
        if (instance && instance.Activities) {
            const activities = instance.Activities.map((activity) => {
                const activityState = workflowActivityStates.find((code) => {
                    return code.Value === String(activity.CurrentState);
                });

                return activity.merge({
                    activityState: WorkflowStateEnum[activity.CurrentState],
                    activityStateName: activityState.Name,
                    activityStateDescription: activityState.Description
                }, {
                    deep: true
                });
            });
            const mappedInstance = instance.set('activities', activities);
            final = final.setIn([key, 'workflowInstance'], mappedInstance);
        }
    });
    return final;
};

const WorkflowSelector = (state) => {
    return state.customercare.workflow.data;
};

export const WorkflowActivityItemsSelector = createSelector(
    [WorkflowSelector, MetadataSelectors.codes.WorkflowActivityStateSelector],
    (workflowInstances, workflowActivityStates) => {
        return workflowActivityStates.length ? getMappedWorkflowInstances(workflowInstances, workflowActivityStates)
            .asMutable({
                deep: true
            }) : null;
    }
);