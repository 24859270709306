import Immutable from 'seamless-immutable';
import {
    ENTITLEMENT_BALANCES_TO_UPDATE,
    SET_SELECTED_ENTITLEMENT
} from './actions/entitlement.balance.update.modal.actions';

export const INITIAL_STATE = Immutable({
    isLoading: false,
    data: {},
    selectedEntitlement: {}
});

export default function entitlementBalanceUpdateModalReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case ENTITLEMENT_BALANCES_TO_UPDATE.BEGIN:
            return state.set('isLoading', true);
        case ENTITLEMENT_BALANCES_TO_UPDATE.SUCCESS:
            return state
                .set('isLoading', false)
                .set('data', payload);
        case ENTITLEMENT_BALANCES_TO_UPDATE.FAILURE:
            return state.set('isLoading', false);
        case SET_SELECTED_ENTITLEMENT:
            return state.set('selectedEntitlement', payload);
        default:
            return state;
    }
}
