import {
    BusinessUnitSelectors,
    InjectorHelper,
    MetadataActions,
    MetadataSelectors,
    MetadataConstants
} from 'invision-core';

export const loadSubscriberRequirements = () => {
    const $ngRedux = InjectorHelper.getNgReduxService();
    const $q = InjectorHelper.getQService();
    const isDbss = BusinessUnitSelectors.IsDbss($ngRedux.getState());

    const requiredCodes = [
        MetadataConstants.codes.AdditionalProperty,
        MetadataConstants.codes.AdditionalPropertyValue,
        MetadataConstants.codes.AddressCountry,
        MetadataConstants.codes.BusinessUnitLanguageConfiguration,
        MetadataConstants.codes.DefaultPasswordChallenge,
        MetadataConstants.codes.Language,
        MetadataConstants.codes.PersonSuffix,
        MetadataConstants.codes.PersonTitle,
        MetadataConstants.codes.RegularExpression,
        MetadataConstants.codes.SubscriberType,
        MetadataConstants.codes.TermsAndConditionsConfiguration
    ];

    const optionalCodes = {
        display_drivers_license_state: MetadataConstants.codes.AddressStateProvinceRegion,
        display_gender: MetadataConstants.codes.PersonGender,
        display_income_level: MetadataConstants.codes.SubscriberIncomeLevelType,
        display_race: MetadataConstants.codes.PersonRace,
        display_subscriber_lead_source: MetadataConstants.codes.SubscriberLeadSourceType,
    };

    return (dispatch) => {
        const setRequirements = (requirements) => {
            if (isDbss) {
                requiredCodes.push(MetadataConstants.codes.SubscriberCategoryDefaultSubscriberType);
            }

            for (const key of Object.keys(optionalCodes)) {
                if (requirements[key]) {
                    requiredCodes.push(optionalCodes[key]);
                }
            }
        };
        const fetchRequiredCodes = () => {
            const itemsToFetch = new Set(requiredCodes);
            itemsToFetch.forEach((code) => {
                if (!MetadataSelectors.codes.MetadataCodeLoadedSelector(code)($ngRedux.getState())) {
                    dispatch(MetadataActions.codes.fetchCodeTypes(code));
                }
            });
        };

        /* Pull in the subscriber requirements for the selected business unit */
        const promises = [MetadataActions.codes.fetchSubscriberRequirementsPromise(dispatch)];

        if (isDbss) {
            promises.push(MetadataActions.codes.fetchSubscriberCategoryRequirementsPromise(dispatch));
        }

        return $q.all(promises).then(() => {
            const state = $ngRedux.getState();
            const subscriberRequirements = MetadataSelectors.codes.SubscriberRequirementsForCurrentBusinessUnitOrDefault(state);
            const subscriberCategoryRequirements = MetadataSelectors.codes.SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault(state);

            setRequirements(subscriberRequirements);
            if (isDbss) {
                setRequirements(subscriberCategoryRequirements);
            }
            fetchRequiredCodes();
        });
    };
};
