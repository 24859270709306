import _ from 'lodash';
import {createSelector} from 'reselect';
import pathOr from 'ramda/src/pathOr';
import {
    BusinessUnitCustomPagesTemplateSelector,
    BusinessUnitHasTemplateSelector
} from 'invision-core/src/components/configurable/configurable.selectors';

export const RouteParams = (state) => {
    return pathOr(null, ['router', 'currentParams'], state);
};

export const CurrentPageIdSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return pathOr(null, ['pageId'], routeParams);
    }
);

export const PageDataSelector = createSelector([
    BusinessUnitHasTemplateSelector,
    BusinessUnitCustomPagesTemplateSelector,
    CurrentPageIdSelector
], (hasTemplate, customPages, pageId) => {
    if (hasTemplate) {
        const currentPageData = _.find(customPages, (page) => {
            return page.Id === pageId;
        });
        return currentPageData ? currentPageData : null;
    }

    return null;
});
