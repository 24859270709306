import Immutable from 'seamless-immutable';
import {RESET_CUSTOMER_RECOVERABLE} from '../actions/customer.actions';
import * as Actions from '../../components/customer/orderHistory/orderDetails/revokeGiftOrderDialog/revoke.gift.order.actions';

export const INITIAL_STATE = Immutable({
    model: {
        reasonCode: null
    },
    isRevokingGiftOrder: false,
    revokeGiftOrderError: null
});

export default function Reducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case Actions.SET_REVOKE_GIFT_ORDER_MODEL:
            return state.merge({
                model: {
                    reasonCode: payload.model.reasonCode
                }
            });

        case Actions.REVOKE_GIFT_ORDER_CONSTANTS.BEGIN:
            return state.merge({
                isRevokingGiftOrder: true
            });
        case Actions.REVOKE_GIFT_ORDER_CONSTANTS.SUCCESS:
            return state.merge({
                isRevokingGiftOrder: false,
                revokeGiftOrderError: null
            });
        case Actions.REVOKE_GIFT_ORDER_CONSTANTS.FAILURE:
            return state.merge({
                isRevokingGiftOrder: false,
                revokeGiftOrderError: {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                }
            });

        case RESET_CUSTOMER_RECOVERABLE:
            return INITIAL_STATE;

        default:
            return state;
    }
}
