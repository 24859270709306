import Immutable from 'seamless-immutable';
import {createSelector} from 'reselect';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {MetadataCodeTypeSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {ShippingMethodsSelector} from 'invision-core/src/components/metadata/shippingMethods/shipping.methods.selectors';
import {
    CompletedOfferingAdditionalPropertyFieldsSelector,
    OrderAdditionalPropertyFieldsSelector
} from 'invision-core/src/components/metadata/codes/codes.additional.properties.selectors';

import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {ALL_STEPS_KEYS} from '../constants/edit.offer.wizard.constants';
import {
    AvailableCreditFromQuote,
    BillingAddressStateRegionProvinceValueOptions,
    Cart,
    DeliveryOptionsByPricingPlanHelper,
    DeliveryMethodOptionsSelector,
    EditOption,
    EditOptions,
    EditCart,
    FinanceCreditFromQuoteSelector,
    FormattedAttributes,
    FormattedDeliveryDecisions,
    FormattedPhysicalAttributes,
    GetCartAdditionalPropertiesSelector,
    GetOfferingOrderQuoteSelector,
    GetOfferQuoteSelector,
    GetOrderAdditionalPropertiesSelector,
    GetAdditionalPropertyListMapSelector,
    GetPostQuoteDueTotalsSelector,
    GetPreQuoteDueSubTotalsSelector,
    GetQuoteHasInvoiceItemsSelector,
    GetTotalDownPaymentAmountSelector,
    InventoryAvailabilityRequests,
    IsCreditLimitBreachedFromQuote,
    IsSendToStoreButtonEnabledSelectorCreator,
    PaymentInfo,
    SelectedEditOption,
    SelectedOfferId,
    ServiceAddress,
    ShippingAddressWizardSelector,
    ShippingAddressDropdownOptionsWizardSelector,
    SubscriberProductIdUndefinedSelector,
    TaxLocationDecisionsByPricingPlanHelper
} from '../helpers/offer.ordering.wizard.selector.helper';
import {CompletedSteps,
    CurrentStep,
    CurrentStepIndex,
    CurrentStepIsInvalid,
    FormattedAddressCityState,
    IsActiveStep,
    SelectedTabIndex,
    SkippedStep,
    TabPanelViewModel} from '../helpers/wizard.selector.helper';
import {
    CurrentCustomerCurrencyCodeSelector,
    RouteParams
} from './customer.selectors';
import {
    IsQuoteBypassedSelector,
    OfferingOrderAsyncIdSelector,
    OfferingOrderDataSelector,
    OfferingOrderQuoteSelector,
    OfferingOrderFinanceCreditLimitSelector,
    SelectedInventoryStoresSelector
} from './offering.order.selectors';
import {
    SelectedOfferingPhysicalInventoriesSelector,
    ShoppingCartSelector
} from './selected.offering.order.selectors';
import {EMPTY_ARRAY} from '../constants/common.constants';
import {ShippingAddressesSelector} from './customer.addresses.selectors';
import pathOr from 'ramda/src/pathOr';

const EditOfferWizardStoreSelector = state => {
    return state.customercare.recoverableUiState.editOfferWizard;
};

const CartSelector = Cart([EditOfferWizardStoreSelector]);

export const CurrentStepIndexSelector = CurrentStepIndex([EditOfferWizardStoreSelector]);

export const CurrentStepSelector = CurrentStep([CurrentStepIndexSelector], ALL_STEPS_KEYS);
export const IsDecisionsStepSelector = IsActiveStep([CurrentStepSelector], ALL_STEPS_KEYS, 0);
export const IsAttributesStepSelector = IsActiveStep([CurrentStepSelector], ALL_STEPS_KEYS, 1);
export const IsCheckoutStepSelector = IsActiveStep([CurrentStepSelector], ALL_STEPS_KEYS, 2);


export const CompletedStepsSelector = CompletedSteps([EditOfferWizardStoreSelector]);

export const EditCartSelector = EditCart([EditOfferWizardStoreSelector]);

export const SelectedEditOptionSelector = SelectedEditOption([EditCartSelector]);

export const EditOptionsSelector = EditOptions([EditCartSelector]);

export const EditOptionSelector = EditOption([SelectedEditOptionSelector, EditOptionsSelector]);

export const PaymentInfoSelector = PaymentInfo([CartSelector]);

export const PaymentInfoEditSelector = PaymentInfo([EditCartSelector]);

export const BillingAddressStateRegionProvinceValueOptionsSelector = BillingAddressStateRegionProvinceValueOptions([
    PaymentInfoEditSelector,
    MetadataCodeTypeSelector(CODES.AddressCountry),
    MetadataCodeTypeSelector(CODES.AddressStateProvinceRegion)
]);

export const SkippedStepSelector = SkippedStep([EditOfferWizardStoreSelector]);

export const CurrentStepIsInvalidSelector = CurrentStepIsInvalid([EditOfferWizardStoreSelector]);

export const SelectedOfferIdSelector = SelectedOfferId([CartSelector]);

export const SelectedTabIndexSelector = SelectedTabIndex([EditOfferWizardStoreSelector]);

export const ServiceAddressSelector = ServiceAddress([EditOfferWizardStoreSelector]);

export const TabPanelViewModelSelector = TabPanelViewModel([SelectedTabIndexSelector]);

export const FormattedCityStateSelector = FormattedAddressCityState([ServiceAddressSelector]);

export const FormattedAttributesSelector = FormattedAttributes([EditCartSelector]);

export const FormattedPhysicalAttributesSelector = FormattedPhysicalAttributes([EditCartSelector, SelectedOfferIdSelector, SelectedInventoryStoresSelector]);

export const IsSubscriberProductIdUndefinedSelector = SubscriberProductIdUndefinedSelector([FormattedPhysicalAttributesSelector]);

export const InventoryAvailabilityRequestsSelector = InventoryAvailabilityRequests([FormattedPhysicalAttributesSelector, EditCartSelector]);

export const CurrentRecurringSelector = createSelector(
    [CartSelector],
    (cart) => {
        return cart.currentRecurring;
    }
);

export const IsSubmittingOrderSelector = createSelector(
    [EditOfferWizardStoreSelector],
    (store) => {
        return store.isSubmittingOrder;
    }
);

export const IsInsufficientChargeOverrideAccessWarningSelector = createSelector(
    [EditOfferWizardStoreSelector],
    (store) => {
        return store.isInsufficientChargeOverrideAccessWarning;
    }
);

const EMPTY_ATTRIBUTE_GROUPS = Immutable([]);
export const EditAttributeGroupsSelector = createSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.editAttributeGroups ? editCart.editAttributeGroups.asMutable({
            deep: true
        }) : EMPTY_ATTRIBUTE_GROUPS;
    }
);

export const DeliveryDecisionsSelector = createImmutableSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.deliveryDecisions || EMPTY_ARRAY;
    }
);

export const DeliveryOptionsByPricingPlanSelector = DeliveryOptionsByPricingPlanHelper([DeliveryDecisionsSelector, ShippingMethodsSelector]);

export const EditPhysicalAttributeGroupsSelector = createSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.editPhysicalAttributeGroups ? editCart.editPhysicalAttributeGroups.asMutable({
            deep: true
        }) : EMPTY_ATTRIBUTE_GROUPS;
    }
);

export const EditOfferingIdSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.offeringId;
    }
);

export const EditOfferingInstanceIdSelector = createImmutableSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.offeringInstanceId;
    }
);

export const OfferQuoteSelector = GetOfferingOrderQuoteSelector([OfferingOrderQuoteSelector]);

export const EditOfferQuoteSelector = GetOfferQuoteSelector([EditOfferWizardStoreSelector]);

export const AvailableCreditFromQuoteSelector = AvailableCreditFromQuote([OfferQuoteSelector]);

export const IsCreditLimitBreachedFromQuoteSelector = IsCreditLimitBreachedFromQuote([OfferQuoteSelector]);

export const OfferQuoteFinanceCreditLimitSelector = OfferingOrderFinanceCreditLimitSelector;

export const TotalDownPaymentSelector = GetTotalDownPaymentAmountSelector([EditOfferQuoteSelector]);

export const AvailableFinanceCreditFromQuoteSelector = FinanceCreditFromQuoteSelector([OfferQuoteFinanceCreditLimitSelector]);

export const PostQuoteDueTotalsSelector = GetPostQuoteDueTotalsSelector([OfferQuoteSelector]);

export const PreQuoteDueSubTotalsSelector = GetPreQuoteDueSubTotalsSelector([ShoppingCartSelector]);

export const QuoteHasInvoiceItemsSelector = GetQuoteHasInvoiceItemsSelector([OfferQuoteSelector]);

export const IsSendToStoreButtonEnabledSelector = IsSendToStoreButtonEnabledSelectorCreator([
    SelectedOfferingPhysicalInventoriesSelector,
    IsAttributesStepSelector,
    IsCheckoutStepSelector,
    OfferingOrderAsyncIdSelector
]);

export const IsResumingStoreOrderSelector = createImmutableSelector(
    [EditOfferWizardStoreSelector],
    (editOfferWizardStore) => {
        return editOfferWizardStore.isResumingStoreOrder;
    }
);
export const ServiceTaxDecisionsSelector = createImmutableSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.serviceTaxDecisions || EMPTY_ARRAY;
    }
);

export const IsShippingRequiredSelector = createSelector(
    [ShoppingCartSelector],
    (shoppingCart) => {
        return pathOr(false, ['ShippingRequired'], shoppingCart);
    }
);

export const CartAdditionalPropertiesSelector = GetCartAdditionalPropertiesSelector([EditOfferWizardStoreSelector]);

export const CartAdditionalPropertyListMapSelector = GetAdditionalPropertyListMapSelector([CartAdditionalPropertiesSelector, CompletedOfferingAdditionalPropertyFieldsSelector]);

export const OrderAdditionalPropertiesSelector = GetOrderAdditionalPropertiesSelector([EditOfferWizardStoreSelector]);

export const OrderAdditionalPropertyListMapSelector = GetAdditionalPropertyListMapSelector([OrderAdditionalPropertiesSelector, OrderAdditionalPropertyFieldsSelector]);

export const FormattedDeliveryDecisionsSelector = FormattedDeliveryDecisions([DeliveryDecisionsSelector]);

export const ShippingAddressSelector = ShippingAddressWizardSelector([IsQuoteBypassedSelector, OfferingOrderDataSelector, ShippingAddressesSelector]);
export const ShippingAddressDropdownOptionsSelector = ShippingAddressDropdownOptionsWizardSelector([OfferingOrderDataSelector, ShippingAddressesSelector]);

export const EditOfferDeliveryMethodOptionsSelector = DeliveryMethodOptionsSelector([ShippingMethodsSelector, CurrentCustomerCurrencyCodeSelector]);

export const TaxLocationDecisionsByPricingPlanEditOfferSelector = TaxLocationDecisionsByPricingPlanHelper([ServiceTaxDecisionsSelector]);
