import * as R from 'ramda';
import Immutable from 'seamless-immutable';
import {
    CREATE_CHILD_CONSTANTS,
    RETRIEVE_HIERARCHY_CONSTANTS,
    RETRIEVE_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS,
    RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS,
    RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS,
    RETRIEVE_POPUP_NODE_REDIRECTED_SERVICES_CONSTANTS,
    SEARCH_HIERARCHY_NODES_CONSTANTS,
    SET_CHILD_FOR_CUSTOMER_HIERARCHY,
    SET_CREATE_CHILD_MODEL_CONSTANT,
    SET_HIERARCHY_NODE_TO_MOVE,
    SET_POPUP_HIERARCHY_NODE,
    SET_SELECTED_HIERARCHY_NODE,
    SET_TARGET_NODE,
    UPDATE_HIERARCHY_NODE_CONSTANTS,
    UPDATE_NODE_SERVICE_CONSTANTS
} from './actions/customer.account.hierarchy.actions';
import {
    CREATE_HIERARCHY,
    SET_EDIT_ADDITIONAL_PROPERTY,
    SET_EDIT_AP_ON_CHANGE
} from './actions/customer.actions';
import {
    CUSTOMER_SEARCH_WITHIN_SUBSCRIBER
} from './actions/search.customer.actions';
import {
    populateCustomerSearchResultsMap
} from './search.customer.reducer.helper';
import {
    INITIAL_STATE as CREATE_CUSTOMER_INITIAL_STATE
} from './create.customer.reducer';
import {
    mapAdditionalProperty,
    setEditAp
} from './helpers/customer.helper';

export const CREATE_CHILD_MODEL_INITIAL_STATE = Immutable({
    Customer: CREATE_CUSTOMER_INITIAL_STATE
});

export const INITIAL_STATE = Immutable({
    accountHierarchyError: null,
    createChildError: null,
    createChildModel: CREATE_CHILD_MODEL_INITIAL_STATE,
    data: {
        // store the results from the customer search in the responsible party popup
        customersSearch: null,
        customersSearchPageCount: 0,
        customersSearchPageNumber: 1,
        customersSearchRecordCount: 0,
        hierarchyName: null,
        // stores the Hierarchy as a map of HierarchyNode.Id to HierarchyNode
        nodesMapById: null,
        // stores the Hierarchy as a map of HierarchyNode.SubscriberId to HierarchyNode
        nodesMapBySubscriberId: null,
        //stores the node that currently has a popup
        popupHierarchyNode: null,
        //stores the Account Details for the hierarchy node who's menu item has just been selected
        popupNodeConvergentBillerAccountDetails: null,
        //stores the services for a node's redirect popup
        popupNodeRedirectedServices: null,
        // stores the SubscriberSummary for the hierarchy node who's menu item has just been selected
        popupNodeSubscriberSummary: null,
        // stores the SubscriberSummary for the current selected HierarchyNode
        selectedNodeSubscriberSummary: null,
        rootNode: null,
        // stores the HierarchyNode.Id for the "root" of the Hierarchy
        rootNodeId: null
    },
    isChildForCustomerHierarchy: false,
    isCreatingChild: false,
    isCreatingHierarchy: false,
    isRetrievingHierarchy: false,
    isRetrievingNodeServices: false,
    isRetrievingNodeSubscriberSummary: false,
    isRetrievingPopupNodeConvergentBillerAccountDetails: false,
    isRetrievingPopupNodeSubscriberSummary: false,
    isSearchingCustomers: false,
    isSearchingHierarchyNodes: false,
    isUpdatingHierarchyNode: false,
    isUpdatingNodeService: false,
    lastAttemptError: null,
    nodeToBeMoved: null,
    selectedNodeId: null,
    targetNode: null
});

export default (state = INITIAL_STATE, {payload, requestObject, type}) => {
    switch (type) {
        case RETRIEVE_HIERARCHY_CONSTANTS.BEGIN:
            return state
                .set('isRetrievingHierarchy', true);
        case RETRIEVE_HIERARCHY_CONSTANTS.SUCCESS: {
            return state
                .set('isRetrievingHierarchy', false)
                .setIn(['data', 'hierarchyName'], payload.Hierarchy.HierarchyName)
                .setIn(['data', 'rootNode'], payload.RootSubscriberHierarchyNode)
                .setIn(['data', 'rootNodeId'], payload.RootSubscriberHierarchyNode.SubscriberId)
                .set('accountHierarchyError', null);
        }
        case RETRIEVE_HIERARCHY_CONSTANTS.FAILURE:
            return state
                .set('isRetrievingHierarchy', false)
                .set('accountHierarchyError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case SEARCH_HIERARCHY_NODES_CONSTANTS.BEGIN: {
            return state
                .set('isSearchingHierarchyNodes', true);
        }
        case SEARCH_HIERARCHY_NODES_CONSTANTS.SUCCESS: {
            const nodesMaps = buildNodesMaps(state.data.rootNode, payload.HierarchyNodes, requestObject);
            return state
                .set('isSearchingHierarchyNodes', false)
                .set('accountHierarchyError', null)
                .setIn(['data', 'nodesMapById'], nodesMaps.byId)
                .setIn(['data', 'nodesMapBySubscriberId'], nodesMaps.bySubscriberId);
        }
        case SEARCH_HIERARCHY_NODES_CONSTANTS.FAILURE: {
            return state
                .set('isSearchingHierarchyNodes', false)
                .set('accountHierarchyError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        }
        case CUSTOMER_SEARCH_WITHIN_SUBSCRIBER.BEGIN:
            return state
                .set('isSearchingCustomers', true)
                .setIn(['data', 'customersSearchPageCount'], 0)
                .setIn(['data', 'customersSearchPageNumber'], 0)
                .setIn(['data', 'customersSearchRecordCount'], -1);
        case CUSTOMER_SEARCH_WITHIN_SUBSCRIBER.SUCCESS:
            return state
                .set('isSearchingCustomers', false)
                .set('lastAttemptError', null)
                .setIn(['data', 'customersSearch'], populateCustomerSearchResultsMap(payload.Subscribers || []))
                .setIn(['data', 'customersSearchPageCount'], payload.PageCount || 0)
                .setIn(['data', 'customersSearchPageNumber'], payload.PageNumber || 1)
                .setIn(['data', 'customersSearchRecordCount'], payload.RecordCount || 0);
        case CUSTOMER_SEARCH_WITHIN_SUBSCRIBER.FAILURE:
            return state
                .set('isSearchingCustomers', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case UPDATE_HIERARCHY_NODE_CONSTANTS.BEGIN:
            return state
                .set('isUpdatingHierarchyNode', true);
        case UPDATE_HIERARCHY_NODE_CONSTANTS.SUCCESS: {
            return state
                .set('isUpdatingHierarchyNode', false)
                .set('lastAttemptError', null);
        }
        case SET_POPUP_HIERARCHY_NODE:
            return state
                .setIn(['data', 'popupHierarchyNode'], payload);
        case UPDATE_HIERARCHY_NODE_CONSTANTS.FAILURE:
            return state
                .set('isUpdatingHierarchyNode', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case RETRIEVE_POPUP_NODE_REDIRECTED_SERVICES_CONSTANTS.BEGIN:
            return state
                .set('isRetrievingNodeRedirectedServices', true);
        case RETRIEVE_POPUP_NODE_REDIRECTED_SERVICES_CONSTANTS.SUCCESS:
            return state
                .set('isRetrievingNodeRedirectedServices', false)
                .setIn(['data', 'popupNodeRedirectedServices'], payload)
                .set('accountHierarchyError', null);
        case RETRIEVE_POPUP_NODE_REDIRECTED_SERVICES_CONSTANTS.FAILURE:
            return state
                .set('isRetrievingNodeRedirectedServices', false)
                .set('accountHierarchyError', {
                    code: payload.Code,
                    message:payload.translatedMessage,
                    severity: payload.Severity
                });
        case RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS.BEGIN:
            return state
                .set('isRetrievingPopupNodeConvergentBillerAccountDetails', true);
        case RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS.SUCCESS:
            return state
                .set('isRetrievingPopupNodeConvergentBillerAccountDetails', false)
                .setIn(['data', 'popupNodeConvergentBillerAccountDetails'], payload)
                .set('accountHierarchyError', null);
        case RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS.FAILURE:
            return state
                .set('isRetrievingPopupNodeConvergentBillerAccountDetails', false)
                .set('accountHierarchyError', {
                    code: payload.Code,
                    message:payload.translatedMessage,
                    severity: payload.Severity
                });
        case RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS.BEGIN:
            return state
                .set('isRetrievingPopupNodeSubscriberSummary', true);
        case RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS.SUCCESS:
            return state
                .set('isRetrievingPopupNodeSubscriberSummary', false)
                .setIn(['data', 'popupNodeSubscriberSummary'], payload.SubscriberSummary)
                .set('accountHierarchyError', null);
        case RETRIEVE_POPUP_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS.FAILURE:
            return state
                .set('isRetrievingPopupNodeSubscriberSummary', false)
                .set('accountHierarchyError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case RETRIEVE_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS.BEGIN:
            return state
                .set('isRetrievingNodeSubscriberSummary', true);
        case RETRIEVE_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS.SUCCESS:
            return state
                .set('isRetrievingNodeSubscriberSummary', false)
                .setIn(['data', 'selectedNodeSubscriberSummary'], payload.SubscriberSummary)
                .set('accountHierarchyError', null);
        case RETRIEVE_NODE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS.FAILURE:
            return state
                .set('isRetrievingNodeSubscriberSummary', false)
                .set('accountHierarchyError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case UPDATE_NODE_SERVICE_CONSTANTS.BEGIN:
            return state
                .set('isUpdatingNodeService', true);
        case UPDATE_NODE_SERVICE_CONSTANTS.SUCCESS:
            return state
                .set('isUpdatingNodeService', false)
                .set('lastAttemptError', null);
        case UPDATE_NODE_SERVICE_CONSTANTS.FAILURE:
            return state
                .set('isUpdatingNodeService', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case CREATE_CHILD_CONSTANTS.BEGIN:
            return state.set('isCreatingChild', true);
        case CREATE_CHILD_CONSTANTS.SUCCESS: {
            return state.set('isCreatingChild', false)
            // TODO: reduce the added node to the nodesMaps
                .set('createChildError', null);
        }
        case CREATE_CHILD_CONSTANTS.FAILURE:
            return state.set('isCreatingChild', false)
                .set('createChildError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                });
        case SET_EDIT_ADDITIONAL_PROPERTY:
            return state
                .setIn(['createChildModel', 'Customer', 'setEditAdditionalProp'], mapAdditionalProperty(payload));
        case SET_EDIT_AP_ON_CHANGE:
            return state
                .setIn(['createChildModel', 'Customer', 'setEditAdditionalProp'], setEditAp(payload, state.createChildModel.Customer.setEditAdditionalProp));
        case SET_SELECTED_HIERARCHY_NODE:
            return state
                .set('selectedNodeId', payload);
        case SET_CREATE_CHILD_MODEL_CONSTANT:
            return state
                .set('createChildModel', payload || INITIAL_STATE.createChildModel);
        case SET_CHILD_FOR_CUSTOMER_HIERARCHY:
            return state
                .set('isChildForCustomerHierarchy', payload);
        case SET_HIERARCHY_NODE_TO_MOVE:
            return state
                .set('nodeToBeMoved', payload);
        case SET_TARGET_NODE: {
            return state
                .set('targetNode', payload);
        }
        default:
            return state;
    }
};

export const buildNodesMaps = (rootNode, hierarchyNodes, requestObject) => {
    const nodesMapById = {};
    const nodesMapBySubscriberId = {};

    // Hierarchy technically allows multiple "root" nodes; we pick the root as the node that shares the Id of the
    // Hierarchy.
    //const rootNode = hierarchy.HierarchyNodes.find(R.propEq(hierarchy.Id.Value, 'Id'));
    // Alternatively, select the first node of the Hierarchy's list.

    mapNode(rootNode);

    hierarchyNodes && hierarchyNodes.length && hierarchyNodes.forEach((node) => {
        mapNode(node);
    });

    function mapNode(node) {
        // TODO(nms): assert no duplicate nodes in map(s) -- ?
        nodesMapBySubscriberId[node.SubscriberId || (node.Subscriber && node.Subscriber.Id)] = nodesMapById[node.Id] = Object.assign({}, {
            NodeName: node.NodeName,
            Id: node.Id,
            ParentSubscriber: node.ParentSubscriber,
            ParentSubscriberId: requestObject.ParentSubscriberId !== node.SubscriberId ? requestObject.ParentSubscriberId : undefined,
            ReportingLevel: node.ReportingLevel,
            ResponsiblePartySubscriber: node.EffectiveResponsiblePartySubscriber,
            ResponsiblePartySubscriberId: node.ResponsiblePartySubscriberId,
            Status: node.Status,
            Subscriber: node.Subscriber,
            SubscriberId: node.SubscriberId || node.Subscriber.Id
        });
    }

    return {
        byId: nodesMapById,
        bySubscriberId: nodesMapBySubscriberId
    };
};
