import Immutable from 'seamless-immutable';
import {
    RETRIEVE_AVAILABLE_TOP_UPS,
    SUBMIT_TOP_UP_REQUEST
} from './actions/customer.top.ups.actions';

export const INITIAL_STATE = Immutable({
    availableTopUps: null,
    isFetchingTopUps: false,
    isSubmittingTopUp: false,
    lastAttemptError: null
});

export default function topUpsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case RETRIEVE_AVAILABLE_TOP_UPS.BEGIN:
            return state
                .set('isFetchingTopUps', true)
                .set('availableTopUps', null)
                .set('lastAttemptError', null);
        case RETRIEVE_AVAILABLE_TOP_UPS.SUCCESS:
            return state
                .set('isFetchingTopUps', false)
                .set('availableTopUps', populateAvailableTopUpsMap(payload.AvailableTopUps));
        case RETRIEVE_AVAILABLE_TOP_UPS.FAILURE:
            return state
                .set('isFetchingTopUps', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity
                });
        case SUBMIT_TOP_UP_REQUEST.BEGIN:
            return state
                .set('isSubmittingTopUp', true)
                .set('lastAttemptError', null);
        case SUBMIT_TOP_UP_REQUEST.SUCCESS:
            return state
                .set('isSubmittingTopUp', false);
        case SUBMIT_TOP_UP_REQUEST.FAILURE:
            return state
                .set('isSubmittingTopUp', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        default:
            return state;
    }
}

function populateAvailableTopUpsMap(results) {
    const resultsMap = {};

    if (results) {
        results.forEach(service => {
            resultsMap[service.ServiceIdentifier] = service;
        });
    }

    return resultsMap;
}
