import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {MetadataCodeTypeDictionarySelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {getFormattedServiceAttributeValue} from './services.list.selectors.helper';
import {EMPTY_ARRAY} from 'invision-core/src/constants/common.constants';
import {UNIT_OF_MEASURES, ENTITLEMENT_PURCHASE_TYPE} from '../../customercare.constants';
import {GROUPED_ENTITLEMENTS_MODAL_TABS} from '../grouped.entitlements.modal.reducer';

const GroupedEntitlementsStoreSelector = (state) => {
    return state.customercare.groupedEntitlementsModal;
};

export const IsLoadingGroupedEntitlementsSelector = createImmutableSelector(
    [GroupedEntitlementsStoreSelector],
    (groupedEntitlementsState) => {
        return groupedEntitlementsState.isLoading;
    }
);

export const GroupedEntitlementsSelectedTabSelector = createImmutableSelector(
    [GroupedEntitlementsStoreSelector],
    (groupedEntitlementsState) => {
        return groupedEntitlementsState.selectedTab;
    }
);

export const GroupedEntitlementsSelector = createImmutableSelector(
    [GroupedEntitlementsStoreSelector],
    (groupedEntitlementsState) => {
        return groupedEntitlementsState.data.SharedEntitlementBalances &&
                groupedEntitlementsState.data.SharedEntitlementBalances.length ?
            groupedEntitlementsState.data.SharedEntitlementBalances :
            groupedEntitlementsState.data.ServiceEntitlementBalances;
    }
);

export const GroupedEntitlementsAvailableForPurchaseSelector = createImmutableSelector(
    [GroupedEntitlementsSelector],
    (groupedEntitlements) => {
        return (groupedEntitlements || EMPTY_ARRAY).filter((entitlement) => {
            return entitlement.AvailableForPurchase === ENTITLEMENT_PURCHASE_TYPE.PURCHASE_AND_USAGE ||
                entitlement.AvailableForPurchase === ENTITLEMENT_PURCHASE_TYPE.PURCHASE_ONLY;
        });
    }
);

export const GroupedEntitlementsAvailableForUsageSelector = createImmutableSelector(
    [GroupedEntitlementsSelector],
    (groupedEntitlements) => {
        return (groupedEntitlements || EMPTY_ARRAY).filter((entitlement) => {
            return entitlement.AvailableForPurchase === ENTITLEMENT_PURCHASE_TYPE.USAGE_ONLY ||
                    entitlement.AvailableForPurchase === ENTITLEMENT_PURCHASE_TYPE.PURCHASE_AND_USAGE;
        });
    }
);

export const GroupEntitlementsWithFormattedServiceAttributeSelector = createImmutableSelector(
    [
        GroupedEntitlementsSelectedTabSelector,
        GroupedEntitlementsSelector,
        GroupedEntitlementsAvailableForPurchaseSelector,
        GroupedEntitlementsAvailableForUsageSelector,
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression)
    ],
    (selectedTab, allEntitlements, entitlementsForPurchase, entitlementsForUsage, serviceAttributes, regularExpressions) => {
        let result = EMPTY_ARRAY;

        if (allEntitlements && allEntitlements.length) {
            if (allEntitlements[0].BalanceUnitCode === UNIT_OF_MEASURES.CURRENCY) {
                if (selectedTab === GROUPED_ENTITLEMENTS_MODAL_TABS.AVAILABLE_FOR_PAYMENT) {
                    result = entitlementsForPurchase;
                } else {
                    result = entitlementsForUsage;
                }
            } else {
                result = allEntitlements;
            }
        }

        return result.length && result.map((entitlement) => {
            const owningService = entitlement.EntitlementIdentifier && entitlement.EntitlementIdentifier.OwningServiceIdentifier;
            if (owningService) {
                entitlement = entitlement.setIn(
                    ['EntitlementIdentifier', 'OwningServiceIdentifier', 'FormattedValue'],
                    getFormattedServiceAttributeValue(owningService.ServiceAttributeId, owningService.Value, serviceAttributes, regularExpressions)
                );
            }
            return entitlement;
        }) || EMPTY_ARRAY;
    }
);
