import Immutable from 'seamless-immutable';
import {SET_NAV_GROUP} from '../components/customerActionsTemplate/customer.actions.template.actions';

export const INITIAL_STATE = Immutable({
    selectedNavGroup: null
});

export default function customerActionsTemplateReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case SET_NAV_GROUP:
            return state.set('selectedNavGroup', state.selectedNavGroup === payload.id ? null : payload.id);
        default:
            return state;
    }
}
