import clone from 'ramda/src/clone';
import compose from 'ramda/src/compose';
import contains from 'ramda/src/includes';
import flatten from 'ramda/src/flatten';
import groupBy from 'ramda/src/groupBy';
import map from 'ramda/src/map';
import partial from 'ramda/src/partial';
import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import sort from 'ramda/src/sort';
import values from 'ramda/src/values';
import {createSelector} from 'reselect';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {BRI_ACTIVATION_STATUS} from 'invision-core/src/constants/pricing.plan.constants';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import CustomerCareKeys from '../../locales/keys';
import {getLastFourDigits} from '../helpers/wizard.selector.helper';
import {duplicatedServiceIdentifierAttributes} from '../helpers/offer.ordering.wizard.helper';
import {addressStateRegionProvinceValueOptionsForCountry} from './customer.selectors.helpers';
import {additionalPropertiesMergeValuesIntoTemplate} from 'invision-core/src/components/metadata/codes/codes.helpers';
import {
    CHARGE_TIMING,
    DEFAULT_SUMMARY_TAB_VIEW_MODEL
} from '../constants/wizard.constants';
import {
    BILLER_RULE_CYCLE_LEVEL,
    BILLER_RULE_INSTANCE_TYPE,
    BILLER_RULE_QUANTITY_PRICE_TYPE,
    DELIVERY_OPTIONS,
    DOWN_PAYMENT_TYPE,
    INVENTORY_CATEGORY_CODE,
    ONE_TIME_WASH,
    TAX_RULE_OPTIONS,
    ZERO_TAX_AMOUNT,
} from '../../customercare.constants';
import {
    BRI_STATUS,
    DecisionTypes,
    DEVICE_SERVICE_FEATURE_ATTRIBUTE_ID,
    ScheduleTypes
} from '../../components/wizards/steps/decisions/decisions.constants';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {INVENTORY_LOOKUP_TYPES} from '../../components/shared/constants/offering.option.status.constants';
import {EMPTY_ARRAY} from '../constants/common.constants';
import {EMPTY_OBJECT} from 'invision-core/src/constants/common.constants';
import {getUniqueNonGuidServiceIdentifiers} from './add.offer.wizard.selectors.helpers';
import cloneDeep from 'lodash/cloneDeep';
import {addressHelper as AddressHelper} from 'invision-core/src/utilities/address.helper';

const PLAN_OR_SERVICE_SWAP = 1;

export const InventoryAvailabilityRequests = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (formattedPhysicalAttributes, editCart) => {
            const flattenedPhysicalAttributes = editCart && editCart.editPhysicalAttributeGroups ? flatten(values(editCart.editPhysicalAttributeGroups)) : [];
            return formattedPhysicalAttributes ? formattedPhysicalAttributes.map(({Id, PhysicalInventoryCompletedDecision}) => {
                return Object.assign(PhysicalInventoryCompletedDecision, {
                    InventoryTypeId: flattenedPhysicalAttributes.find((attr) => {
                        return attr.id === Id;
                    }).inventoryTypeId
                });
            }) : EMPTY_FORMATTED_ATTRIBUTES;
        }
    );
};

export const BillingAddressStateRegionProvinceValueOptions = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (paymentInfo, countries, states) => {
            const selectedCountry = (paymentInfo && paymentInfo.BillingAddress) ? paymentInfo.BillingAddress.Country : null;
            return addressStateRegionProvinceValueOptionsForCountry(selectedCountry, countries, states);
        }
    );
};

export const BillCycle = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (editCart) => {
            return editCart.billCycle;
        }
    );
};

export const Cart = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (wizardStore) => {
            return wizardStore.cart;
        }
    );
};

export const EditCart = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (wizardStore) => {
            return wizardStore.editCart;
        }
    );
};

export const EditSelectedFacetIds = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (editCart) => {
            return editCart.selectedFacetIds;
        }
    );
};

export const EditSelectedOfferId = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (editCart) => {
            return editCart.selectedOfferId;
        }
    );
};

const sortByDisplayOrder = (a, b) => {
    const aDisplayOrder = Object.keys(a.AdditionalProperties).filter((index) => {
        return a.AdditionalProperties[index].Key === 'DisplayOrder';
    }).map((index) => {
        return parseInt(a.AdditionalProperties[index].Value, 10);
    });

    const bDisplayOrder = Object.keys(b.AdditionalProperties).filter((index) => {
        return b.AdditionalProperties[index].Key === 'DisplayOrder';
    }).map((index) => {
        return parseInt(b.AdditionalProperties[index].Value, 10);
    });

    return aDisplayOrder[0] - bDisplayOrder[0];
};
export const GroupedCheckboxFilterOptions = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (facetCategories, facets, selectedFacetIds, selectedChargeTypeIds, isPostpaidEnabled, isPrepaidEnabled, isOfferTypesDisabled) => {
            const sortedCategories = sort(sortByDisplayOrder, facetCategories);
            let options = [];

            if (sortedCategories && facets && selectedFacetIds) {
                options = sortedCategories.map((category) => {
                    const filteredFacets = facets.filter((facet) => {
                        const propertyWithOfferingFacetCategoryId = facet.AdditionalProperties.find(propEq('OfferingFacetCategoryId', 'Key'));
                        return propertyWithOfferingFacetCategoryId && propertyWithOfferingFacetCategoryId.Value === category.Value.toString();
                    });

                    const filtersViewModel = sort(sortByDisplayOrder, filteredFacets).map((facet) => {
                        const facetId = facet.Value.toString();

                        return {
                            id: facetId,
                            name: facet.Name,
                            checked: selectedFacetIds.some((id) => {
                                return id === facetId;
                            })
                        };
                    });

                    return {
                        groupName: category.Name,
                        filters: filtersViewModel
                    };
                });
            }

            return options;
        }
    );
};

export const OfferOptionViewModel = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (offers, selectedOfferId, multiOfferShoppingCartItems, ChangeOfferingId) => {
            return offers.map((offer) => {
                return offer.merge({
                    isSelected: offer.Id === selectedOfferId,
                    isDisabled: ChangeOfferingId === offer.Id
                }, {
                    deep: true
                });
            });
        }
    );
};

export const SelectedOfferId = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (cart) => {
            return cart.selectedOfferId;
        }
    );
};

const defaultAttributeTypes = {
    isDropDownAttribute: false,
    isInputFieldAttribute: false,
    isMobileNumberPortabilityAttribute: false,
    isRadioAttribute: false,
    isTelephoneNumberLocationAttribute: false
};

export const FormAttributeGroupsHelper = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (editAttributeGroups, serviceAttribute) => {
            const selectOption = {
                label: i18n.translate(CoreLocaleKeys.SELECT),
                value: undefined // must be undefined for required validation to work
            };
            return editAttributeGroups.map((attributeGroup, index) => {
                const serviceFeatures = [];
                const attributes = attributeGroup.map((attribute) => {
                    Object.assign(attribute, defaultAttributeTypes);
                    if (attribute.serviceFeatures) {
                        serviceFeatures.push(
                            attribute.serviceFeatures.map((serviceFeature) => {
                                return {
                                    allowEdit: attribute.allowEdit,
                                    attributeId: attribute.id,
                                    billerRuleDetails: serviceFeature.BillerRuleDetails,
                                    displayName: `${serviceFeature.ProductName}: ${serviceFeature.PricingPlanName}`,
                                    pricingPlanId: serviceFeature.PricingPlanId,
                                    pricingPlanName: serviceFeature.PricingPlanName,
                                    productId: serviceFeature.ProductId,
                                    productName: serviceFeature.ProductName,
                                    serviceAttributeId: serviceFeature.ServiceAttributeId,
                                    serviceId: serviceFeature.ServiceId,
                                    serviceInstanceId: serviceFeature.ServiceInstanceId,
                                    subscriberProductId: serviceFeature.SubscriberProductId
                                };
                            })
                        );
                    }

                    if (attribute.possibleValues && attribute.possibleValues.length > 0) {
                        const newValues = [selectOption].concat(attribute.possibleValues.map((optionValue) => {
                            return {
                                label: optionValue,
                                value: optionValue
                            };
                        }));
                        return Object.assign({}, attribute, {
                            formValue: attribute.formValue ? attribute.formValue : newValues[0].value,
                            isDropDownAttribute: true,
                            possibleValues: newValues
                        });
                    } else if (attribute.isPortable && !attribute.passPortIn &&
                        attribute.inventoryLookupType === INVENTORY_LOOKUP_TYPES.TELEPHONE_NUMBER_LOCATION) {
                        return Object.assign({}, attribute, {
                            formValue: attribute.selectedValue,
                            isTelephoneNumberLocationAttribute: true
                        });
                    } else if (attribute.inventoryItemReservation &&
                        attribute.inventoryCategoryCode === INVENTORY_CATEGORY_CODE.LOGICAL &&
                        !attribute.passPortIn) {
                        return Object.assign({}, attribute, {
                            formValue: attribute.selectedValue,
                            isRadioAttribute: true
                        });
                    } else if (attribute.isPortable) {
                        return Object.assign({}, attribute, {
                            formValue: attribute.portInRequest && attribute.passPortIn ?
                                attribute.portInRequest.portInData.existingNumber :
                                null,
                            isMobileNumberPortabilityAttribute: true
                        });
                    } else {
                        return Object.assign({}, attribute, {
                            isInputFieldAttribute: true
                        });
                    }
                });
                const serviceIdentifiers = attributes.filter((attribute) => {
                    return attribute.serviceIdentifier;
                });
                //Passing serviceAttribute for Edit and Change offer
                //https://jira.csgicorp.com/browse/ASCBR-1369
                if (attributes.length && serviceAttribute && Object.keys(serviceAttribute).length) {
                    attributes.forEach((attribute) => {
                        attribute.serviceAttributeDisplayTypeCode = serviceAttribute[attribute.serviceAttributeId] && serviceAttribute[attribute.serviceAttributeId].AdditionalProperties && serviceAttribute[attribute.serviceAttributeId].AdditionalProperties.service_attribute_display_type_code || undefined;
                    });
                }
                return {
                    attributes: attributes,
                    formName: `attributeForm${index}`,
                    isServiceFeatureAvailable: serviceIdentifiers.some((attribute) => {
                        return attribute.isServiceFeatureAvailable;
                    }),
                    isServiceFeatureEditable: serviceIdentifiers.some((attribute) => {
                        return attribute.allowEdit;
                    }),
                    serviceFeatures: serviceFeatures.length ? flatten(serviceFeatures) : EMPTY_ARRAY
                };
            });
        }
    );
};

export const CurrentActiveAttributeNameHelper = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (services) => {
            const activeService = services && services.length ? services.find((service) => {
                return service.isActive;
            }) : null;
            return activeService ? activeService.label : null;
        }
    );
};

export const ShowPreviousButtonHelper = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (attributeServices) => {
            if (attributeServices && attributeServices.length) {
                const activeService = attributeServices.find((service) => {
                    return service.isActive;
                });
                if (activeService) {
                    const activeServiceIndex = attributeServices.indexOf(activeService);
                    return activeServiceIndex > 0 && attributeServices.length > 1;
                }
            }
            return false;
        }
    );
};

export const ShowNextButtonHelper = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (attributeServices) => {
            if (attributeServices && attributeServices.length) {
                const activeService = attributeServices.find((service) => {
                    return service.isActive;
                });
                if (activeService) {
                    const activeServiceIndex = attributeServices.indexOf(activeService);
                    return activeServiceIndex < (attributeServices.length - 1) && attributeServices.length > 1;
                }
            }
            return false;
        }
    );
};

export const FilteredFormAttributeGroupsHelper = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (formAttributeGroups, attributeServices) => {
            if (formAttributeGroups && formAttributeGroups.length && attributeServices && attributeServices.length) {
                const activeService = attributeServices.find((service) => {
                    return service.isActive;
                });
                if (activeService) {
                    const group = formAttributeGroups.find((group) => {
                        return group.formName === activeService.formName;
                    });
                    if (group) {
                        return [group];
                    }
                }
            }
            return null;
        }
    );
};

export const HasRequiredAndUnavailableInventory = (inputSelectors) => {
    return createImmutableSelector(
        inputSelectors,
        (formAttributeGroups) => {
            return formAttributeGroups && formAttributeGroups.length ?
                formAttributeGroups.some((attributeGroup) => {
                    return attributeGroup.attributes.some((attribute) => {
                        return attribute.isRequired && attribute.requestedInventoryIsUnavailable;
                    });
                }) : false;
        }
    );
};

export const FilteredPhysicalAttributeGroupsHelper = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (formAttributeGroups, physicalGroups, currentAttributeFormName) => {
            const services = [];
            if (formAttributeGroups && formAttributeGroups.length) {
                formAttributeGroups.forEach((attributeGroup, groupIndex) => {
                    const serviceObj = {
                        formName: attributeGroup.formName,
                        id: attributeGroup.formName,
                        isActive: currentAttributeFormName ? attributeGroup.formName === currentAttributeFormName : groupIndex === 0
                    };
                    if (attributeGroup.attributes && attributeGroup.attributes.length) {
                        //TODO: review this logic. Multiple properties of serviceObj are getting assigned from each items in attributes array. consequent items override perviously set values
                        attributeGroup.attributes.forEach((attribute) => {
                            serviceObj.instanceNumber = attribute.instanceNumber;
                            serviceObj.label = attribute.pricingPlanName;
                            serviceObj.pricingPlanId = attribute.pricingPlanId;
                        });
                    }
                    services.push(serviceObj);
                });
            }
            if (services && services.length) {
                const activeService = services && services.length ? services.find((service) => {
                    return service.isActive;
                }) : null;
                if (activeService) {
                    const activeServiceIndex = services.indexOf(activeService);
                    if (physicalGroups) {
                        const filteredGroups = {};
                        for (const group in physicalGroups) {
                            if (group === String(activeService.pricingPlanId)) {
                                filteredGroups[group] = physicalGroups[group].filter((physicalGroup) => {
                                    return physicalGroup.instanceNumber === activeService.instanceNumber;
                                });

                                if (filteredGroups[group]) {
                                    const attributesGroup = currentAttributeFormName ? formAttributeGroups.find((attributeGroup) => {
                                        return attributeGroup.formName === currentAttributeFormName;
                                    }) : formAttributeGroups[0];
                                    filteredGroups[group].forEach((filteredGroup) => {
                                        filteredGroup.allowEdit = pathOr(true, ['allowEdit'], attributesGroup.attributes.find((attribute) => {
                                            return attribute.inventoryTypeId === filteredGroup.inventoryTypeId;
                                        }));
                                    });
                                }
                            }
                        }
                        if (activeServiceIndex === 0) {
                            const physicalGroupPricingPlans = Object.keys(physicalGroups);
                            physicalGroupPricingPlans.forEach((pricingPlanId) => {
                                const foundService = services.find((service) => {
                                    return String(service.pricingPlanId) === String(pricingPlanId);
                                });
                                if (!foundService) {
                                    filteredGroups[pricingPlanId] = physicalGroups[pricingPlanId];
                                }
                            });
                        }
                        return filteredGroups;
                    }
                }
            }
            return physicalGroups;
        }
    );
};

export const AllAttributesAreValidHelper = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (services, currentAttributesValidationStatuses) => {
            if (services && services.length) {
                const invalidAttributeGroups = services.filter((service) => {
                    return !service.isValid;
                });
                if (!invalidAttributeGroups) {
                    return true;
                } else {
                    let problemsFound = false;
                    invalidAttributeGroups.forEach((group) => {
                        const validationStatus = currentAttributesValidationStatuses.find((status) => {
                            return status.formName === group.formName;
                        });
                        if (
                            (!validationStatus && (group.hasRequiredItems || group.missingPhysicalInventoryAttributes)) ||
                            (validationStatus && !validationStatus.isValid)) {
                            problemsFound = true;
                        }
                    });
                    return !problemsFound;
                }
            }
            return true;
        }
    );
};

const activeAttributeItem = (isHidden, index) => {
    if (isHidden) {
        return false;
    } else {
        return index === 1 || index === 0;
    }
};

export const FormAttributeGroupsNavigationHelper = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (formAttributeGroups, formPhysicalAttributeGroups, currentAttributeFormName, attributeFormSubmitted, currentAttributesValidationStatuses, lastAttemptError) => {
            let services = [];
            const faultData = pathOr([], ['faultData'], lastAttemptError);
            if (formAttributeGroups && formAttributeGroups.length) {
                const dupes = duplicatedServiceIdentifierAttributes(formAttributeGroups);
                formAttributeGroups.forEach((attributeGroup, groupIndex) => {
                    const serviceObj = {
                        formName: attributeGroup.formName,
                        hasRequiredItems: attributeGroup.attributes && attributeGroup.attributes.length ? !!(attributeGroup.attributes.find((attribute) => {
                            return attribute.isRequired && !attribute.isHidden && !attribute.formValue;
                        })) : false,
                        id: attributeGroup.formName,
                        isValid: null,
                        hasRequiredAndUnavailableInventory: attributeGroup.attributes && attributeGroup.attributes.length ? attributeGroup.attributes.some((attribute) => {
                            return attribute.isRequired && attribute.requestedInventoryIsUnavailable;
                        }) : false
                    };
                    attributeGroup.attributes.forEach((attribute) => {
                        serviceObj.instanceNumber = attribute.instanceNumber;
                        serviceObj.isHidden = (serviceObj.isHidden !== false) && !attribute.isServiceFeatureAvailable && attribute.isHidden;
                        serviceObj.isActive = currentAttributeFormName ? attributeGroup.formName === currentAttributeFormName : activeAttributeItem(serviceObj.isHidden, groupIndex);
                        serviceObj.label = attribute.pricingPlanName;
                        serviceObj.pricingPlanId = attribute.pricingPlanId;
                        serviceObj.duplicateExists = !serviceObj.duplicateExists && dupes && dupes.length ? !!(dupes.find((duplicate) => {
                            return duplicate === attribute.id;
                        })) : serviceObj.duplicateExists;
                        serviceObj.faultFound = !serviceObj.faultFound && faultData.length ? !!(faultData.find((fault) => {
                            return fault === attribute.id;
                        })) : serviceObj.faultFound;
                        serviceObj.account = attribute.serviceIdentifier && attribute.formValue && !attribute.isHidden ?
                            `(...${getLastFourDigits(attribute.formValue)})` : serviceObj.account;
                    });

                    const service = Object.assign({}, serviceObj, {
                        isValid: validateAttributes(serviceObj, currentAttributesValidationStatuses, attributeFormSubmitted)
                    });
                    services.push(Object.assign({}, service, setServiceStatus(service)));
                });
            } else if (formPhysicalAttributeGroups && formPhysicalAttributeGroups.length === undefined) {
                // undefined is needed in conditional to validate formPhysicalAttributeGroups as object
                const mergedFormPhysicalAttributeGroups = flatten(values(formPhysicalAttributeGroups));
                const serviceObj = {
                    // Hard coding values here because formName and id are null
                    // when there is no attribute form to assign them to.
                    formName: 'physicalAttributesNoService',
                    id: 'physicalAttributesNoService',
                    isActive: true,
                    isValid: null,
                    isHidden: true,
                    hasRequiredItems: !!(mergedFormPhysicalAttributeGroups.find((attribute) => {
                        return attribute.isRequired || !!(attribute.typeAttributes.find((typeAttribute) => {
                            return typeAttribute.required;
                        }));
                    }))
                };
                const service = Object.assign({}, serviceObj, {
                    isValid: validateAttributes(serviceObj, currentAttributesValidationStatuses, attributeFormSubmitted)
                });
                services.push(Object.assign({}, service, setServiceStatus(service)));
            }
            if (services && services.length && formPhysicalAttributeGroups) {
                services = validatePhysicalAttributes(services, formPhysicalAttributeGroups);
            }

            return services;
        }
    );
};

const validatePhysicalAttributes = (services, formPhysicalAttributeGroups) => {
    const clonedServices = clone(services);
    const physicalGroupsWithNoService = [];
    const activeService = services.find((service) => {
        return service.isActive;
    });
    Object.keys(formPhysicalAttributeGroups).forEach((pricingPlanId) => {
        const foundService = clonedServices.find((service) => {
            return String(service.pricingPlanId) === String(pricingPlanId) && service.instanceNumber === activeService.instanceNumber;
        });
        if (!foundService) {
            physicalGroupsWithNoService.push(formPhysicalAttributeGroups[pricingPlanId]);
        } else {
            foundService.physicalGroups = [formPhysicalAttributeGroups[pricingPlanId].filter((group) => {
                return group.instanceNumber === activeService.instanceNumber;
            })];
        }
    });
    clonedServices[0].physicalGroups = physicalGroupsWithNoService;
    const servicesToReturn = [];
    clonedServices.forEach((service) => {
        let newService = service;
        if (service.physicalGroups && service.physicalGroups.length) {
            service.physicalGroups.forEach((physicalGroup) => {
                physicalGroup.forEach((group) => {
                    let missingPhysicalAttributes = false;
                    if (group.typeAttributes && group.typeAttributes.length) {
                        const requiredPhysicalAttributes = group.typeAttributes.filter((attribute) => {
                            return attribute.required;
                        });
                        requiredPhysicalAttributes.forEach((attribute) => {
                            if (!missingPhysicalAttributes && (!group.selectedTypeAttributes || !group.selectedTypeAttributes[attribute.id])) {
                                missingPhysicalAttributes = true;
                            }
                        });
                    }
                    if ((!group.selectedMake || !group.selectedModel || missingPhysicalAttributes) && service.isValid !== null) {
                        const invalidObj = {
                            isValid: false,
                            missingPhysicalInventoryAttributes: true
                        };
                        newService = Object.assign({}, service, Object.assign({}, invalidObj, setServiceStatus(invalidObj)));
                    }
                });
            });
        }
        servicesToReturn.push(newService);
    });
    return servicesToReturn;
};

const validateAttributes = (serviceObj, currentAttributesValidationStatuses, attributeFormSubmitted) => {
    let isValid = null;

    if (serviceObj.hasRequiredAndUnavailableInventory) {
        isValid = false;
    } else {
        const validationStatus = currentAttributesValidationStatuses && currentAttributesValidationStatuses.length ?
            currentAttributesValidationStatuses.find((status) => {
                return status.formName === serviceObj.formName;
            }) : null;
        if (validationStatus) {
            isValid = validationStatus.isValid && !serviceObj.faultFound && !serviceObj.duplicateExists;
        } else {
            if (!attributeFormSubmitted) {
                isValid = serviceObj.duplicateExists ? false : null;
            } else {
                isValid = !serviceObj.hasRequiredItems && !serviceObj.faultFound && !serviceObj.duplicateExists;
            }
        }
    }

    return isValid;
};

const setServiceStatus = (serviceObj) => {
    serviceObj.glyph = 'empty';
    serviceObj.glyphClass = 'c-navGroup-emptyRouteIcon';
    if (serviceObj.isValid === true) {
        serviceObj.glyph = 'status-complete-filled';
        serviceObj.glyphClass = 'is-active';
    }
    if (serviceObj.isValid === false) {
        serviceObj.glyph = 'status-pending-filled';
        serviceObj.glyphClass = 'is-removed';
    }
    return serviceObj;
};

export const populateDevice = (device) => {
    device.makeInformationOptions = device.makeInformation.map((make) => {
        return {
            text: make.name,
            value: make.id,
            //This is to check if user changed selection from previously selected make/model for saved offer
            selected: (device.selectedMakeFromSavedCart && !device.selectedMake) ?
                device.selectedMakeFromSavedCart === make.id :
                device.selectedMake === make.id
        };
    });
    if (device.makeInformationOptions.length === 1) {
        device.makeInformationOptions[0].selected = true;

        device.makeDisabled = true;
        device.selectedMake = device.makeInformationOptions[0].value;
    }
    device.modelsByMakeId = groupBy(prop('id'))(device.makeInformation);
    Object.keys(device.modelsByMakeId).forEach((key) => {
        device.modelsByMakeId[key] = device.modelsByMakeId[key][0].possibleValues.map((item) => {
            return {
                text: item.name,
                value: item.id,
                //This is to check if user changed selection from previously selected make/model for saved offer
                selected: (device.selectedModelFromSavedCart && !device.selectedModel) ?
                    device.selectedModelFromSavedCart === item.id :
                    device.selectedModel === item.id
            };
        });
    });
    if (device.selectedMake &&
        device.modelsByMakeId[device.selectedMake] &&
        1 === device.modelsByMakeId[device.selectedMake].length) {
        device.modelsByMakeId[device.selectedMake][0].selected = true;

        device.modelDisabled = true;
        device.selectedModel = device.modelsByMakeId[device.selectedMake][0].value;
    }
    device.typeAttributeOptions = groupBy(prop('id'))(device.typeAttributes);
    device.typeAttributes.forEach((typeAttribute) => {
        let selectedTypeAttribute = (device.selectedTypeAttributes || {})[typeAttribute.id] || undefined;

        /**
         * Set the default/selected value for attr
         * if there is no default value selected and
         * if there is only one option available.
         */
        if (undefined === selectedTypeAttribute && 1 === typeAttribute.possibleValues.length) {
            selectedTypeAttribute = typeAttribute.possibleValues[0];
        }

        device.typeAttributeOptions[typeAttribute.id] = {
            label: typeAttribute.name,
            values: typeAttribute.possibleValues.map((item) => {
                return {
                    text: item,
                    value: item,
                    selected: selectedTypeAttribute === item || item === typeAttribute.selectedValue
                };
            }),
            selectedValue: selectedTypeAttribute || typeAttribute.selectedValue,
            onlyOneValue: typeAttribute.possibleValues.length === 1
        };
    });
    return device;
};

export const FormPhysicalAttributeGroupsHelper = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (editPhysicalAttributeGroups) => {
            Object.keys(editPhysicalAttributeGroups || {}).forEach((key) => {
                const devices = editPhysicalAttributeGroups[key];
                devices.forEach((device) => {
                    populateDevice(device);
                });
            });
            return editPhysicalAttributeGroups;
        }
    );
};

export const UnavailablePhysicalInventory = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (editPhysicalAttributeGroups, availableInventory) => {
            const allOptions = flatten(values(editPhysicalAttributeGroups || {}));
            const makeMap = {};
            const modelMap = {};
            const typeAttributeMap = {};
            allOptions.forEach(({makeInformation, typeAttributes}) => {
                makeInformation.forEach(({id, name, possibleValues}) => {
                    makeMap[id] = name;
                    possibleValues.forEach((model) => {
                        const toAdd = {
                            [model.id]: model.name
                        };
                        modelMap[id] = modelMap[id] ? Object.assign(modelMap[id], toAdd) : toAdd;
                    });
                });
                typeAttributes.forEach(({id, name}) => {
                    typeAttributeMap[id] = name;
                });
            });
            return editPhysicalAttributeGroups ? availableInventory
                .filter(({Available}) => {
                    return !Available;
                })
                .map((availability) => {
                    return {
                        makeSelection: makeMap[availability.MakeId],
                        modelSelection: pathOr(undefined, [availability.MakeId, availability.ModelId], modelMap),
                        typeAttributeSelections: availability.InventoryAttributes.map(({AttributeId, AttributeValue}) => {
                            return {
                                label: typeAttributeMap[AttributeId],
                                value: AttributeValue
                            };
                        }),
                        quantityAvailable: availability.AvailableUnits
                    };
                }).asMutable({
                    deep: true
                }) : EMPTY_FORMATTED_ATTRIBUTES;
        });
};

export const getTypeTranslation = (periodTypes, type, recurringPeriodType) => {
    switch (type) {
        case BILLER_RULE_INSTANCE_TYPE.CUSTOM:
            return i18n.translate(CustomerCareKeys.BILLER_RULE_INSTANCE_TYPES.CUSTOM);
        case BILLER_RULE_INSTANCE_TYPE.ENTITLEMENT:
            return i18n.translate(CustomerCareKeys.BILLER_RULE_INSTANCE_TYPES.ENTITLEMENT);
        case BILLER_RULE_INSTANCE_TYPE.FINANCE:
            return i18n.translate(CustomerCareKeys.BILLER_RULE_INSTANCE_TYPES.FINANCE);
        case BILLER_RULE_INSTANCE_TYPE.ONETIME:
            return i18n.translate(CustomerCareKeys.BILLER_RULE_INSTANCE_TYPES.ONETIME);
        case BILLER_RULE_INSTANCE_TYPE.RECURRING: {
            if (periodTypes && periodTypes.length) {
                const periodTypeMatches = periodTypes.filter((periodType) => {
                    // RecurringPeriodType is in ELS and is only seeded at the moment
                    // Should it become dyanmic then this kind of comparison won't work
                    return convertStringToNumber(periodType.Value) === recurringPeriodType;
                });
                return `${i18n.translate(CustomerCareKeys.BILLER_RULE_INSTANCE_TYPES.RECURRING)} ${periodTypeMatches.length ? periodTypeMatches[0].Name : ''}`;
            }
            break;
        }
        case BILLER_RULE_INSTANCE_TYPE.USAGE:
            return i18n.translate(CustomerCareKeys.BILLER_RULE_INSTANCE_TYPES.USAGE);
        default:
            return '';
    }
};

const hasOverriddenPrice = (billerRules, shoppingCartItemBillerRuleDetails) => {
    if (!shoppingCartItemBillerRuleDetails) {
        return false;
    }

    return billerRules.some((billerRule) => {
        return contains(billerRule.BillerRuleConfigurationId, pluck('BillerRuleConfigurationId')(shoppingCartItemBillerRuleDetails));
    });
};

const getBillerRuleDetails = (billerRules, shoppingCartItemBillerRuleDetails) => {

    if (!hasOverriddenPrice(billerRules, shoppingCartItemBillerRuleDetails)) {
        return null;
    }

    const allOverriddenCharges = [];

    billerRules.forEach((billerRule) => {

        const pricingPeriods = shoppingCartItemBillerRuleDetails.filter((item) => {
            return item.BillerRuleConfigurationId === billerRule.BillerRuleConfigurationId;
        });

        const now = new Date().toISOString();
        const billerRuleDetails = pricingPeriods.find((period) => {
            if (!period.StartDate) {
                return !period.EndDate ? true :  period.EndDate > now;
            } else {
                if (period.EndDate) {
                    return period.StartDate < now && period.EndDate > now;
                }
                return period.StartDate < now;
            }
        }) || {
            Amount: null,
            Quantity: null
        };

        allOverriddenCharges.push({
            amount: billerRuleDetails.Amount,
            quantity: billerRuleDetails.Quantity,
            type: billerRule.Type,
            billerRuleConfigurationId: billerRule.BillerRuleConfigurationId
        });
    });

    return allOverriddenCharges;
};

const combineItems = (existingItems, shoppingCart) => {
    let combinedItems = existingItems || [];

    if (shoppingCart.RemoveItems) {
        combinedItems = combinedItems.concat(shoppingCart.RemoveItems);
    }

    return combinedItems;
};

const filterInvalidItem = (shoppingCartItem, billerRuleTotals) => {
    const filteredBrits = [];
    const recurringBillerRuleInstances = pathOr(EMPTY_ARRAY, ['Details', 'PricingPlan', 'PricingPlanBillerRuleInstances', 'RecurringBillerRuleInstances'], shoppingCartItem);
    const oneTimeBillerRuleInstances = pathOr(EMPTY_ARRAY, ['Details', 'PricingPlan', 'PricingPlanBillerRuleInstances', 'OneTimeBillerRuleInstances'], shoppingCartItem);
    const financeBillerRuleInstances = pathOr(EMPTY_ARRAY, ['Details', 'PricingPlan', 'PricingPlanBillerRuleInstances', 'FinanceBillerRuleInstances'], shoppingCartItem);

    const billerRuleInstanceThumbnails = pathOr(EMPTY_ARRAY, ['Details', 'PricingPlan', 'BillerRuleInstanceThumbnails'], shoppingCartItem);
    //TODO: Using BRITS since the api does not have PricingplanBillerRuleInstances for subcription offers in ITV.
    if (billerRuleInstanceThumbnails.length) {
        billerRuleInstanceThumbnails.forEach((brit) => {
            if (brit.Type === BILLER_RULE_INSTANCE_TYPE.SUBSCRIPTION) {
                filteredBrits.push(brit);
            }
        });
    }

    if (recurringBillerRuleInstances.length && oneTimeBillerRuleInstances.length) {
        const recurringAndOneTimeBris = [];
        recurringBillerRuleInstances.forEach((rBri) => {
            if ((rBri.QuantityPricingTypeCode === BILLER_RULE_QUANTITY_PRICE_TYPE.NONE ||
            ((rBri.QuantityPricingTypeCode === BILLER_RULE_QUANTITY_PRICE_TYPE.TIERED ||
                rBri.QuantityPricingTypeCode === BILLER_RULE_QUANTITY_PRICE_TYPE.TAPERED) &&
                hasMatchingBillerRuleInstance(rBri, billerRuleTotals))) &&
            !isBillerRuleInstanceOneTimeWashed(rBri, pathOr([], ['Details', 'BillerRuleConfigurationDetails'], shoppingCartItem))) {
                recurringAndOneTimeBris.push(rBri);
            }
        });
        oneTimeBillerRuleInstances.forEach((oBri) => {
            if ((oBri.QuantityPricingTypeCode === BILLER_RULE_QUANTITY_PRICE_TYPE.NONE ||
            ((oBri.QuantityPricingTypeCode === BILLER_RULE_QUANTITY_PRICE_TYPE.TIERED ||
                oBri.QuantityPricingTypeCode === BILLER_RULE_QUANTITY_PRICE_TYPE.TAPERED) &&
                hasMatchingBillerRuleInstance(oBri, billerRuleTotals))) &&
            !isBillerRuleInstanceOneTimeWashed(oBri, pathOr([], ['Details', 'BillerRuleConfigurationDetails'], shoppingCartItem)) &&
            !isBillerRuleInstanceOneTimePaid(billerRuleInstanceThumbnails, oBri)) {
                recurringAndOneTimeBris.push(oBri);
            }
        });
        if (recurringAndOneTimeBris.length) {
            filteredBrits.push(recurringAndOneTimeBris);
        }
    } else if (recurringBillerRuleInstances.length) {
        recurringBillerRuleInstances.forEach((rBri) => {
            if ((rBri.QuantityPricingTypeCode === BILLER_RULE_QUANTITY_PRICE_TYPE.NONE ||
            ((rBri.QuantityPricingTypeCode === BILLER_RULE_QUANTITY_PRICE_TYPE.TIERED ||
                rBri.QuantityPricingTypeCode === BILLER_RULE_QUANTITY_PRICE_TYPE.TAPERED) &&
                hasMatchingBillerRuleInstance(rBri, billerRuleTotals))) &&
            !isBillerRuleInstanceOneTimeWashed(rBri, pathOr([], ['Details', 'BillerRuleConfigurationDetails'], shoppingCartItem))) {
                filteredBrits.push(rBri);
            }
        });
    } else if (oneTimeBillerRuleInstances.length) {
        oneTimeBillerRuleInstances.forEach((oBri) => {
            if ((oBri.QuantityPricingTypeCode === BILLER_RULE_QUANTITY_PRICE_TYPE.NONE ||
            ((oBri.QuantityPricingTypeCode === BILLER_RULE_QUANTITY_PRICE_TYPE.TIERED ||
                oBri.QuantityPricingTypeCode === BILLER_RULE_QUANTITY_PRICE_TYPE.TAPERED) &&
                hasMatchingBillerRuleInstance(oBri, billerRuleTotals))) &&
            !isBillerRuleInstanceOneTimeWashed(oBri, pathOr([], ['Details', 'BillerRuleConfigurationDetails'], shoppingCartItem)) &&
            !isBillerRuleInstanceOneTimePaid(billerRuleInstanceThumbnails, oBri)) {
                filteredBrits.push(oBri);
            }
        });
    }
    //For finance BRI we can have two charges on the cart, one for Due today and the other for Next Invoice,
    //so based on the number of charges adding the same BRI with differnt charge. So that we can sort based on charges and display multiple charge for the same BRI.
    if (financeBillerRuleInstances.length && billerRuleTotals.length) {
        billerRuleTotals.forEach((briTotal) => {
            const financeBriWithBillerRuleTotals = Object.assign({}, financeBillerRuleInstances[0],
                {
                    billerRuleTotal: briTotal
                });
            filteredBrits.push(financeBriWithBillerRuleTotals);
        });
    }
    return filteredBrits;
};

// This function is designed to make sure that out of the list of BillerRuleInstances, that we only display the ones that
// match the BillerRuleTotals. We are making sure that the Amount and Type on the BillerRuleInstance
// matches the BR Totals that are actually selected to display the correct information. This works for tiered and tapered
const hasMatchingBillerRuleInstance = (brit, billerRuleTotals = []) => {

    return billerRuleTotals.some((brt) => {
        return (brit.BillerRuleInstanceCharges.some((charge) => {
            return charge.ChargeAmount === brt.Amount;
        })) && brt.Type === brit.Type;
    });
};

const isBillerRuleInstanceOneTimeWashed = (billerRuleInstanceThumbnail, billerRuleConfigurationDetails) => {
    return billerRuleConfigurationDetails.some((brcDetails) => {
        return (brcDetails.BillerRuleConfigurationId === billerRuleInstanceThumbnail.BillerRuleConfigurationId) &&
            brcDetails.WashStatus === ONE_TIME_WASH.WASHED;
    });
};

const isBillerRuleInstanceOneTimePaid = (billerRuleInstanceThumbnails = [], billerRuleInstance) => {
    return billerRuleInstanceThumbnails.find((briDetails) => {
        return briDetails.BillerRuleConfigurationId === billerRuleInstance.BillerRuleConfigurationId &&
            briDetails.Type === BILLER_RULE_INSTANCE_TYPE.ONETIME &&
            briDetails.Status === BRI_STATUS.PAID;
    });
};

export const GetFinancedProductGroupInCartSelector = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (financedItems, offerQuote) => {
            financedItems.forEach((product) => {
                const quoteItem = pathOr([], ['Items'], offerQuote).find((quoteItem) => {
                    return (quoteItem.PricingPlan.Id === product.pricingPlanId
                        && quoteItem.Product.Id === product.productId);
                });
                if (quoteItem) {
                    product.financedFullAmount = quoteItem.FinancedFullAmount;
                    product.taxAmount = quoteItem.TaxAmount || ZERO_TAX_AMOUNT;
                    product.payPerAmount = quoteItem.Amount || ZERO_TAX_AMOUNT;
                }
            });
            return financedItems;
        }
    );
};

export const GetOfferQuoteSelector = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (offerWizardStore) => {
            return offerWizardStore.quote;
        }
    );
};

const getItemAmount = (chargeQuotes) => {
    if (chargeQuotes[0].ChargeQuoteTotals !== null) {
        return chargeQuotes[0].ChargeQuoteTotals[0].Amount;
    }
};

const getItemTaxAmount = (chargeQuotes) => {
    if (chargeQuotes[0].TaxItemQuotes !== null) {
        return chargeQuotes[0].TaxItemQuotes[0].TaxItemQuoteTotals[0].Amount;
    }
};

const getShippingAmountForChargeTiming = (offeringOrderQuote, chargeTiming) => {
    if (offeringOrderQuote.ShippingQuote && offeringOrderQuote.ShippingQuote.ShippingQuoteTotals) {
        const shippingItem = offeringOrderQuote.ShippingQuote.ShippingQuoteTotals.find((item) => {
            return item.ChargeTiming === chargeTiming;
        });
        return shippingItem ? shippingItem.Amount : undefined;
    }
};

const getItemQuotesForOffer = (offeringOrderQuote) => {
    const serviceQuotesForOffer = [];
    if (offeringOrderQuote.OfferingQuotes && offeringOrderQuote.OfferingQuotes.length > 1) {
        offeringOrderQuote.OfferingQuotes.forEach((offering) => {
            if (offering.ServiceQuotes) {
                serviceQuotesForOffer.push(offering.ServiceQuotes);
            }
        });
        const serviceQuotes = flatten(serviceQuotesForOffer);
        const itemQuotes = flatten(pluck('ItemQuotes', serviceQuotes));
        return itemQuotes;
    } else if (offeringOrderQuote.OfferingQuotes && offeringOrderQuote.OfferingQuotes.length) {
        return flatten(pluck('ItemQuotes', offeringOrderQuote.OfferingQuotes[0].ServiceQuotes));
    }
};

const mapOfferingQuoteProperties = (offeringOrderQuote) => {
    const orderQuoteTotals = offeringOrderQuote.OfferingOrderQuoteTotals;
    const orderTaxQuote = offeringOrderQuote.TaxQuote ? offeringOrderQuote.TaxQuote.TaxQuoteTotals : undefined;
    const orderFeeQuote = offeringOrderQuote.FeeQuote ? offeringOrderQuote.FeeQuote.FeeQuoteTotals : undefined;
    const itemQuotes = getItemQuotesForOffer(offeringOrderQuote);
    const hasInvoiceItems = (offeringOrderQuote.OfferingQuotes && offeringOrderQuote.OfferingQuotes.length) ? offeringOrderQuote.OfferingQuotes[0].HasInvoiceItems : undefined;
    const offerName = (offeringOrderQuote.OfferingQuotes && offeringOrderQuote.OfferingQuotes.length) ? offeringOrderQuote.OfferingQuotes[0].OfferName : undefined;

    return {
        Currency: offeringOrderQuote.Currency,
        OrderQuoteTotals: orderQuoteTotals && orderQuoteTotals.map((orderQuoteTotal) => {
            const taxQuoteItem = orderTaxQuote && orderTaxQuote.find((taxItem) => {
                return taxItem.ChargeTiming === orderQuoteTotal.ChargeTiming;
            });

            const feeQuoteItem = orderFeeQuote && orderFeeQuote.find((feeItem) => {
                return feeItem.ChargeTiming === orderQuoteTotal.ChargeTiming;
            });
            return {
                ChargeTiming: orderQuoteTotal.ChargeTiming,
                DiscountAmount: orderQuoteTotal.DiscountAmount,
                ShippingAmount: getShippingAmountForChargeTiming(offeringOrderQuote, orderQuoteTotal.ChargeTiming),
                SubTotalAmount: orderQuoteTotal.SubTotalAmount,
                TaxAmount: taxQuoteItem ? taxQuoteItem.Amount : undefined,
                FeeAmount: feeQuoteItem ? feeQuoteItem.Amount : undefined,
                TotalAmount: orderQuoteTotal.Amount
            };
        }),
        Items: itemQuotes && itemQuotes.map((itemQuote) => {
            return {
                Amount: itemQuote.ChargeQuotes.length && getItemAmount(itemQuote.ChargeQuotes),
                ConnectedDate: itemQuote.ConnectedDate,
                FinancedFullAmount: itemQuote.FinancedFullAmount || undefined,
                Name: itemQuote.Name,
                OfferingInstanceId: itemQuote.OfferingInstanceId,
                OrderContractInstance: itemQuote.OrderContractInstance,
                OrderedOfferingName: offerName,
                PricingPlan: itemQuote.PricingPlan,
                Product: itemQuote.Product,
                Quantity: itemQuote.Quantity,
                SubscriberProductId: itemQuote.LockerItemId,
                TaxAmount: itemQuote.ChargeQuotes.length && getItemTaxAmount(itemQuote.ChargeQuotes),
            };
        }),
        HasInvoiceItems: hasInvoiceItems,
        IsCreditLimitBreached: offeringOrderQuote.SubscriberCreditAndFinanceLimit ? offeringOrderQuote.SubscriberCreditAndFinanceLimit.IsCreditLimitBreached : undefined,
        CreditLimit: offeringOrderQuote.SubscriberCreditAndFinanceLimit ? offeringOrderQuote.SubscriberCreditAndFinanceLimit.CreditLimit : undefined,
        AvailableCredit: offeringOrderQuote.SubscriberCreditAndFinanceLimit ? offeringOrderQuote.SubscriberCreditAndFinanceLimit.AvailableCredit : undefined,
        EarlyTerminationFeeAmount: offeringOrderQuote.EarlyTerminationFeeAmount
    };
};

export const GetOfferingOrderQuoteSelector = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (offerWizardStore) => {
            return offerWizardStore && Object.keys(offerWizardStore).length ? mapOfferingQuoteProperties(offerWizardStore) : EMPTY_OBJECT;
        }
    );
};

export const GetOfferQuoteFinanceCreditLimitSelector = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (offerWizardStore) => {
            return  offerWizardStore ? offerWizardStore.financeCreditLimitValidation : undefined;
        }
    );
};

export const GetTotalDownPaymentAmountSelector = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (offerQuote) => {
            return pathOr(null, ['Totals', 'DownPaymentAmount'], offerQuote);
        }
    );
};

export const GetQuoteHasInvoiceItemsSelector = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (offerQuote) => {
            return offerQuote && offerQuote.HasInvoiceItems || null;
        }
    );
};

const getOrderQuoteSortOrder = (chargeTiming) => {
    switch (chargeTiming) {
        case CHARGE_TIMING.DUE_TODAY:
            return 1;
        case CHARGE_TIMING.DUE_ON_ACTIVATION:
            return 2;
        case CHARGE_TIMING.DUE_ON_FIRST_USE:
            return 3;
        case CHARGE_TIMING.BILL_CYCLE:
            return 4;
        case CHARGE_TIMING.DUE_RECURRING:
            return 5;
        default:
            return 0;
    }
};

export const GetPostQuoteDueTotalsSelector = (inputSelectors) => {
    return createImmutableSelector(
        inputSelectors,
        (quote) => {
            const orderQuoteTotals = pathOr(EMPTY_ARRAY, ['OrderQuoteTotals'], quote);
            if (orderQuoteTotals.length) {
                return {
                    orderQuoteTotals: orderQuoteTotals.map((orderQuoteTotal) => {
                        return {
                            chargeTiming: orderQuoteTotal.ChargeTiming,
                            discountAmount: orderQuoteTotal.DiscountAmount,
                            sortOrder: getOrderQuoteSortOrder(orderQuoteTotal.ChargeTiming),
                            subTotalAmount: orderQuoteTotal.SubTotalAmount,
                            feeAmount: orderQuoteTotal.FeeAmount,
                            taxAmount: orderQuoteTotal.TaxAmount,
                            shippingAmount: orderQuoteTotal.ShippingAmount || undefined,
                            totalAmount: orderQuoteTotal.TotalAmount
                        };
                    })
                };
            }
        });
};

export const GetPreQuoteDueSubTotalsSelector = (inputSelectors) => {
    return createImmutableSelector(
        inputSelectors,
        (shoppingCart) => {
            const subTotalDueToday = pathOr(null, ['SubTotals', 'DueToday'], shoppingCart);
            const subTotalDueNextInvoice = pathOr(null, ['SubTotals', 'DueNextInvoice'], shoppingCart);
            const subTotalDueRecurring = pathOr(null, ['SubTotals', 'DueRecurring'], shoppingCart);
            const subTotalDueOnActivation = pathOr(null, ['SubTotals', 'DueOnActivation'], shoppingCart);
            const subTotalDueOnFirstUse = pathOr(null, ['SubTotals', 'DueOnFirstUse'], shoppingCart);

            return {
                dueToday: {
                    subTotal: subTotalDueToday
                },
                dueNextInvoice: {
                    subTotal: subTotalDueNextInvoice
                },
                dueOnActivation: {
                    subTotal: subTotalDueOnActivation
                },
                dueOnFirstUse: {
                    subTotal: subTotalDueOnFirstUse
                },
                dueRecurring: {
                    subTotal: subTotalDueRecurring
                }
            };
        });
};

const hasInactiveCharges = (items = EMPTY_ARRAY) => {
    return items.some((item) => {
        const recurringBillerRuleInstances = pathOr(EMPTY_ARRAY, ['Details', 'PricingPlan', 'PricingPlanBillerRuleInstances', 'RecurringBillerRuleInstances'], item);
        const oneTimeBillerRuleInstances = pathOr(EMPTY_ARRAY, ['Details', 'PricingPlan', 'PricingPlanBillerRuleInstances', 'OneTimeBillerRuleInstances'], item);

        return [...recurringBillerRuleInstances, ...oneTimeBillerRuleInstances].some((billerRuleInstance) => {
            return billerRuleInstance.DefaultActivationStatus === BRI_ACTIVATION_STATUS.INACTIVE;
        });
    });
};

const getItemDeposits = (shoppingCartItem) => {
    const pricingPlanDepositItem = [];
    if (shoppingCartItem.Deposits && shoppingCartItem.Deposits.length) {
        shoppingCartItem.Deposits.forEach((deposit) => {
            pricingPlanDepositItem.push({
                TotalAmount: deposit.TotalAmount,
                DisplayName: shoppingCartItem.Details.PricingPlan.Deposits.find(propEq(deposit.DepositTypeId, 'DepositTypeId')).DisplayName
            });
        });
    }
    return pricingPlanDepositItem;
};

const getBillerRuleCharges = (briItems) => {
    const briCharges = [];
    if (briItems) {
        briItems.forEach((briItem) => {
            briCharges.push(briItem.BillerRuleInstanceCharges[0].ChargeAmount);
        });
    }
    return briCharges;
};

const getBriAmount = (bri, briTotals) => {
    const briTotalAmount = [];
    if (briTotals && briTotals.length) {
        briTotals.forEach((item) => {
            briTotalAmount.push({
                type: item.Type,
                amount: item.Amount,
                discount: item.DiscountAmount,
                billerRuleConfigurationId: item.BillerRuleConfigurationId
            });
        });
    } else if (bri.Type  === BILLER_RULE_INSTANCE_TYPE.SUBSCRIPTION) {
        briTotalAmount.push({
            type: bri.Type,
            amount: bri.Amount,
            discount: bri.DiscountAmount
        });
    } else {
        briTotalAmount.push({
            type: bri.Type,
            amount: briTotals && briTotals.length ? briTotals.find((briTotal) => {
                return briTotal.Type === bri.Type;
            }).Amount : undefined,
            discount: briTotals && briTotals.length ? briTotals.find((briTotal) => {
                return briTotal.Type === bri.Type;
            }).DiscountAmount : undefined
        });
    }

    return briTotalAmount;
};

const getStandaloneUsageProducts = (productsByType) => {
    const standaloneUsageProducts = [];
    const usageProducts = productsByType[BILLER_RULE_INSTANCE_TYPE.USAGE] || [];

    usageProducts.forEach(product => {
        const isOneTimeProduct = (productsByType[BILLER_RULE_INSTANCE_TYPE.ONETIME] || []).some((oneTimeProduct) => {
            return oneTimeProduct.pricingPlanId === product.pricingPlanId;
        });

        const isRecurringProduct = (productsByType[BILLER_RULE_INSTANCE_TYPE.RECURRING] || []).some((recurringProduct) => {
            return recurringProduct.pricingPlanId === product.pricingPlanId;
        });

        if (!isOneTimeProduct && !isRecurringProduct) {
            standaloneUsageProducts.push(product);
        }
    });

    return standaloneUsageProducts;
};

const getStandaloneUsageProductGroups = (productsByType) => {
    const standaloneUsageProductGroups = [];
    const standaloneUsageProducts = getStandaloneUsageProducts(productsByType);

    if (standaloneUsageProducts.length) {
        standaloneUsageProductGroups.push({
            currentRecurring: 0,
            invoiceTimingTypes: null,
            type: i18n.translate(CustomerCareKeys.BILLER_RULE_INSTANCE_TYPES.USAGE),
            products: standaloneUsageProducts,
            totalAmount: 0,
            totalDiscounts: 0
        });
    }
    return standaloneUsageProductGroups;
};

export const SummaryTab = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (shoppingCart, subTotalAmount, taxAmount, totalAmount, quoteCalculated,
            selectedContract, creditAmount, quoteOnly, totalDownPayment, postQuoteDueTotals,
            preQuoteDueSubTotals, isAddOffer = false, multiOfferShoppingCart) => {
            // quoteOnly is the skip building out deposits and productsGroups, this is needed to support multi offer

            const quoteOnlyViewModel = {
                contract: selectedContract ? selectedContract : null,
                creditAmount: creditAmount || 0,
                deposits: EMPTY_ARRAY,
                productGroups: EMPTY_ARRAY,
                productsByType: EMPTY_OBJECT,
                currencyCode: multiOfferShoppingCart?.Currency || shoppingCart?.Currency,
                discountAmount: shoppingCart.DiscountAmount || 0,
                totalAmount: shoppingCart.TotalAmount || 0,
                taxNotIncluded: !quoteCalculated,
                subTotalAmount: subTotalAmount,
                subTotalDeposits: isAddOffer ? multiOfferShoppingCart.DepositAmount : shoppingCart.DepositAmount,
                taxQuoteAmount: taxAmount,
                totalDownPayment: totalDownPayment,
                totalQuoteAmount: totalAmount,
                dueTotals: postQuoteDueTotals,
                dueSubTotals: preQuoteDueSubTotals
            };

            if (quoteOnly) {
                return quoteOnlyViewModel;
            }

            let newItems = shoppingCart.Items || [];

            if (shoppingCart.AddItems && shoppingCart.AddItems.length &&
                shoppingCart.Items && shoppingCart.Items.length) {
                newItems = shoppingCart.Items.map((item) => {
                    const addedItem = shoppingCart.AddItems.find((addItem) => {
                        return !item.LockerItemId && addItem.ProductId === item.ProductId && addItem.PricingPlanId === item.PricingPlanId;
                    });
                    return Object.assign({}, item, {
                        isAdded: addedItem ? true : null
                    });
                });
            }

            const combinedItems = combineItems(newItems, shoppingCart);

            if (combinedItems.length) {
                const mapBillerRuleToViewModel = map((item) => {
                    const filterInvalidItems = filterInvalidItem(item, item.Details.BillerRuleTotals);

                    return filterInvalidItems.map((billerRule) => {

                        const itemWithBritInfo = {
                            briAmount: getBriAmount(billerRule, (!billerRule.length && billerRule.Type === BILLER_RULE_INSTANCE_TYPE.FINANCE) ? [billerRule.billerRuleTotal] : item.Details.PricingPlan.BillerRuleInstanceThumbnails),
                            billerRule,
                            billCycleName: pathOr('', ['Details', 'PricingPlan', 'SubscriptionBillingCycleName'], item),
                            billerRuleDetails: getBillerRuleDetails(item.Details.PricingPlan.BillerRuleInstanceThumbnails, item.ShoppingCartItemBillerRuleDetails),
                            currencyCode: shoppingCart.Currency || DEFAULT_SUMMARY_TAB_VIEW_MODEL.currencyCode,
                            //TODO: ASCINV-642 mini cart discounts
                            //discountAmount: billerRule.DiscountAmount,
                            inventoryAttributes: pathOr([], ['PhysicalInventories', 0, 'InventoryAttributes'], item),
                            isAdded: item.isAdded ? item.isAdded : null,
                            hasOverriddenPrice: hasOverriddenPrice(Array.isArray(billerRule) ? billerRule : [billerRule], item.ShoppingCartItemBillerRuleDetails),
                            isFinance: !billerRule.length ? billerRule.Type === BILLER_RULE_INSTANCE_TYPE.FINANCE: false,
                            isModified: item.isModified ? item.isModified : null,
                            isRemoved: item.isRemoved ? item.isRemoved : null,
                            isStandalone: item.IsStandalone,
                            isSwapped: item.OrderItemChangeType ? item.OrderItemChangeType === PLAN_OR_SERVICE_SWAP : null,
                            itemDeposits: getItemDeposits(item),
                            name: item.Details.Product.Name,
                            offeringOptionId: item.OfferingOptionId,
                            offeringOptionPriceId: item.OfferingOptionPriceId,
                            oneTimeCharge: getBillerRuleCharges(item.Details.PricingPlan.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances),
                            pricingPlanId: item.PricingPlanId,
                            pricingPlanName: item.Details.PricingPlan.Name,
                            productId: item.ProductId,
                            quantity: item.Quantity,
                            recurringCharge: getBillerRuleCharges(item.Details.PricingPlan.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances),
                            termLength: billerRule?.Type === BILLER_RULE_INSTANCE_TYPE.FINANCE ? pathOr(null, ['ShoppingCartItemBillerRuleDetails', 0, 'TermLength'], item) : null,
                            thumbnailUrl: item.Details.Product.ThumbnailUrl
                        };
                        if (itemWithBritInfo.isFinance) {
                            //TODO: Remove once the dependency of summary tab with finance is removed from wizards other than add offer
                            itemWithBritInfo.addedItemIndex = item.addedItemIndex;
                            itemWithBritInfo.downPayment = item.downPayment;
                            itemWithBritInfo.downPaymentType = item.DownPaymentType || undefined;
                            itemWithBritInfo.isFullPriceDownPaymentSelected = item.isFullPriceDownPaymentSelected;
                            itemWithBritInfo.isDownPaymentEqualToFirstSelected = item.isDownPaymentEqualToFirstSelected;
                            itemWithBritInfo.isDownPaymentEqualToFirstInstallment = item.Details.PricingPlan.PricingPlanBillerRuleInstances.FinanceBillerRuleInstances[0].DownPaymentEqualToFirstInstallment;
                            itemWithBritInfo.isFullUpfrontPayment = item.isFullUpfrontPayment;
                            itemWithBritInfo.itemIndex = item.itemIndex;
                            itemWithBritInfo.minimumDownPayment = item.minimumDownPayment;
                        }

                        return itemWithBritInfo;
                    });
                });
                const groupBillerTypes = groupBy((billerVM) => {
                    if (billerVM.itemDeposits.length) {
                        return CHARGE_TIMING.DUE_TODAY;
                    } else if (billerVM.billerRule.length) {
                        switch (billerVM.billerRule[0].BillerRuleConfigurationChargeDetails.ChargeTiming) {
                            case CHARGE_TIMING.BILL_CYCLE:
                                return CHARGE_TIMING.BILL_CYCLE;
                            case CHARGE_TIMING.DUE_ON_ACTIVATION:
                                return CHARGE_TIMING.DUE_ON_ACTIVATION;
                            case CHARGE_TIMING.DUE_ON_FIRST_USE:
                                return CHARGE_TIMING.DUE_ON_FIRST_USE;
                            case CHARGE_TIMING.DUE_TODAY:
                                return CHARGE_TIMING.DUE_TODAY;
                            default:
                                break;
                        }
                    } else if (billerVM.billerRule.BillerRuleConfigurationChargeDetails) {
                        switch (billerVM.billerRule.BillerRuleConfigurationChargeDetails.ChargeTiming) {
                            case CHARGE_TIMING.BILL_CYCLE:
                                return CHARGE_TIMING.BILL_CYCLE;
                            case CHARGE_TIMING.DUE_ON_ACTIVATION:
                                return CHARGE_TIMING.DUE_ON_ACTIVATION;
                            case CHARGE_TIMING.DUE_ON_FIRST_USE:
                                return CHARGE_TIMING.DUE_ON_FIRST_USE;
                            case CHARGE_TIMING.DUE_TODAY:
                                return CHARGE_TIMING.DUE_TODAY;
                            default:
                                break;
                        }
                    } else if (billerVM.billerRule.Type === BILLER_RULE_INSTANCE_TYPE.FINANCE) {
                        if (billerVM.isFinance && billerVM.billerRule.billerRuleTotal) {
                            if (billerVM.billerRule.billerRuleTotal.ChargeTiming === CHARGE_TIMING.DUE_TODAY) {
                                return CHARGE_TIMING.DUE_TODAY;
                            } else if (billerVM.billerRule.billerRuleTotal.ChargeTiming === CHARGE_TIMING.BILL_CYCLE) {
                                return CHARGE_TIMING.BILL_CYCLE;
                            }
                        }
                    } else if (billerVM.billerRule.Type === BILLER_RULE_INSTANCE_TYPE.SUBSCRIPTION) {
                        return CHARGE_TIMING.DUE_TODAY;
                    }
                });

                const groupItems = groupBy((item) => {
                    return item.PricingPlanId;
                }, combinedItems || []);

                const combinedItemBris = compose(flatten, mapBillerRuleToViewModel)(combinedItems);
                const itemWithMultipleBris = combinedItemBris.find((item) => {
                    return item.recurringCharge.length > 1 || item.oneTimeCharge.length > 1;
                });

                let productsByType = [];
                if (itemWithMultipleBris) {
                    const selectedPlansWithBri = [];
                    for (const plan in groupItems) {
                        groupItems[plan].forEach((planItem) => {
                            const selectedBri = combinedItemBris.find((item) => {
                                return item.pricingPlanId === planItem.PricingPlanId;
                            });
                            if (selectedBri) {
                                selectedPlansWithBri.push(selectedBri);
                            }
                        });
                    }
                    productsByType = compose(groupBillerTypes)(selectedPlansWithBri);
                } else {
                    productsByType = compose(groupBillerTypes)(combinedItemBris);
                }
                const groupBriByTypes = groupBy((billerVM) => {
                    return billerVM.billerRule.Type;
                });
                const productsByBriType = compose(groupBriByTypes, flatten, mapBillerRuleToViewModel)(combinedItems);
                const productGroups = Object.keys(productsByBriType).filter((type) => {
                    type = +type;
                    return type === BILLER_RULE_INSTANCE_TYPE.RECURRING
                        || type === BILLER_RULE_INSTANCE_TYPE.ONETIME
                        || type === BILLER_RULE_INSTANCE_TYPE.FINANCE
                        || type === BILLER_RULE_INSTANCE_TYPE.SUBSCRIPTION;
                }).map((type) => {
                    type = +type;

                    return {
                        // For Finance BRI we are having different objects based on charge timing since we need to show two charges on the cart
                        // for down payment and down payment equal to first installment scenarios. For Full upfront payment scenario in finance plans we will only have one charge so we dont need to filter it.
                        // So for type finance BRI when there is down payment we are filtering one of the duplicate BRI which we needed for the cart
                        // 'downPayment' value doesnt change (which we are showing on the checkout screen), for every BRI object, down payment is calculated before this function is fired.
                        products: type === BILLER_RULE_INSTANCE_TYPE.FINANCE ? productsByBriType[type].filter((item) => {
                            return item.billerRule.billerRuleTotal.ChargeTiming === CHARGE_TIMING.BILL_CYCLE || item.billerRule.FullUpfrontPayment;
                        }) : productsByBriType[type],
                        typeCode: type
                    };
                }).concat(getStandaloneUsageProductGroups(productsByBriType));
                return Object.assign({}, quoteOnlyViewModel, {
                    dueSubTotals: preQuoteDueSubTotals,
                    dueTotals: postQuoteDueTotals,
                    hasInactiveCharges: hasInactiveCharges(shoppingCart.Items),
                    offerName: pathOr(undefined, [0, 'OrderedOfferingName'], shoppingCart.Items),
                    productsByType,
                    productGroups,
                    totalAmount: shoppingCart.TotalAmount || 0
                });
            } else {
                return DEFAULT_SUMMARY_TAB_VIEW_MODEL;
            }
        }
    );
};

//Todo: remove once the dependency of summary tab with finance is removed from wizards other than add offer
export const GetSummaryTabFinancedGroupSelector = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (summaryTabViewModel) => {
            return summaryTabViewModel.productGroups.find((productGroup) => {
                return (productGroup.typeCode === BILLER_RULE_INSTANCE_TYPE.FINANCE);
            });
        }
    );
};

export const EditOptions = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (editCart) => {
            return pathOr(null, ['editOptions'], editCart);
        }
    );
};

export const SelectedEditOption = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (editCart) => {
            return pathOr(null, ['selectedEditOption'], editCart);
        }
    );
};

export const getFilteredBillerRuleDetailsForBriId = (briId, billerRuleDetails = []) => {
    const billerRuleDetailsForBriId = (billerRuleDetails || []).filter(propEq(briId, 'BillerRuleConfigurationId'));
    return billerRuleDetailsForBriId.length ? billerRuleDetailsForBriId : [{
        Amount: null,
        EndDate: null,
        Quantity: null,
        StartDate: null
    }];
};

export const EditOption = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (selectedEditOption, editOptions) => {
            if (!selectedEditOption || !editOptions) {
                return null;
            }


            const editOption = editOptions.find(propEq(selectedEditOption, 'Id')).asMutable({
                deep: true
            });

            editOption.BillerRuleInstanceThumbnails.forEach((brit) => {
                const billerRuleDetails = getFilteredBillerRuleDetailsForBriId(brit.BillerRuleConfigurationId, editOption.BillerRuleDetails);

                if (!(billerRuleDetails.length === 1 && billerRuleDetails[0].TermLength)) {
                    brit.pricingPeriods = brit.pricingPeriods || billerRuleDetails.map((billerRuleDetail) => {
                        return {
                            Amount: typeof billerRuleDetail.Amount === 'number' ? billerRuleDetail.Amount : brit.Amount,
                            EndDate: billerRuleDetail.EndDate || null,
                            Quantity: billerRuleDetail.Quantity,
                            StartDate: billerRuleDetail.StartDate || null
                        };
                    });

                    const isDateSpecified = brit.pricingPeriods.some((pricingPeriod) => {
                        return pricingPeriod.StartDate !== null || pricingPeriod.EndDate !== null;
                    });

                    brit.scheduleType = isDateSpecified ? ScheduleTypes.CUSTOM : brit.scheduleType || ScheduleTypes.NONE;
                }
            });

            return editOption;
        }
    );
};

export const ServiceAddress = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (wizardStore) => {
            return wizardStore.serviceAddress;
        }
    );
};

export const PaymentInfo = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (cart) => {
            return cart.paymentInfo;
        }
    );
};

export const getItemRsvp = (attribute) => {
    return !attribute.inventoryItemReservation || attribute.passPortIn ? null : {
        InstanceId: attribute.inventoryItemReservation.instanceId || attribute.inventoryItemReservation.InstanceId,
        InventoryTypeId: attribute.inventoryItemReservation.inventoryTypeId || attribute.inventoryTypeId,
        SerialNumber: attribute.inventoryItemReservation.serialNumber,
        Token: attribute.inventoryItemReservation.token
    };
};

export const getPortInRequest = (attribute) => {
    return !attribute.portInRequest || !attribute.passPortIn ? null : {
        AdditionalProperties: attribute.portInRequest.portInData.mnpAdditionalProps,
        PortInNumber: attribute.portInRequest.portInData.existingNumber,
        InventoryTypeId: attribute.inventoryTypeId,
        ServiceAttributeId: null,
        ValidationToken: attribute.mnpToken,
        Address: attribute.address,
        ServiceProviderId: attribute.portInRequest.portInData.provider
    };
};

const getServiceFeatureAttribute = (attribute, backupServiceFeatures) => {
    if (backupServiceFeatures?.serviceFeatures?.length && attribute.dependentServiceAttributeId === backupServiceFeatures.dependentServiceAttributeId) {
        const oldServiceFeature =  backupServiceFeatures.serviceFeatures.map((serviceAttribute) => {
            return {
                ...serviceAttribute,
                ServiceAttributeId: attribute.serviceAttributeId
            };
        });

        if (attribute.serviceFeature?.length) {
            return [...oldServiceFeature, ...attribute.serviceFeatures];
        } else {
            return oldServiceFeature;
        }

    } else {
        return attribute.serviceFeatures;
    }
};

const EMPTY_FORMATTED_ATTRIBUTES = [];
export const FormattedAttributes = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (editCart) => {
            if (editCart.editAttributeGroups) {
                return editCart.editAttributeGroups.reduce((result, attributeGroup) => {
                    let backupServiceFeatures = {};
                    const transformedAttrs = attributeGroup.filter((attribute) => {
                        if (attribute.isHidden && (attribute.formValue || attribute.serviceFeatures && attribute.serviceFeatures.length) && attribute.serviceAttributeId === DEVICE_SERVICE_FEATURE_ATTRIBUTE_ID) {
                            return true;
                        } else if (attribute.isHidden && (attribute.serviceFeatures && attribute.serviceFeatures.length)) {
                            backupServiceFeatures = {
                                dependentServiceAttributeId: attribute.dependentServiceAttributeId,
                                serviceFeatures: attribute.serviceFeatures,
                            };
                        } else {
                            return !attribute.isHidden;
                        }
                    }).map((attribute) => {
                        return {
                            BillerRuleInstanceDetails:attribute.billerRuleInstanceDetails,
                            DecisionType: DecisionTypes.SERVICE_ATTRIBUTE,
                            Id: attribute.id,
                            InventoryItemReservation: getItemRsvp(attribute),
                            PortInRequest: getPortInRequest(attribute),
                            SelectedValue: attribute.formValue || null, //Needs to default to null so RoC doesn't treat it as a modify item
                            ServiceFeatures : getServiceFeatureAttribute(attribute, backupServiceFeatures),
                            ServiceInstanceId: attribute.serviceInstanceId
                        };
                    });

                    return result.concat(transformedAttrs);
                }, []).asMutable({
                    deep: true
                });
            } else {
                return EMPTY_FORMATTED_ATTRIBUTES;
            }
        }
    );
};

const createCompletedPhysicalInventoryItemRequests = (offerId, storeId, attribute) => {
    return {
        Id: attribute.id,
        DecisionType: DecisionTypes.PHYSICAL_INVENTORY,
        PhysicalInventoryCompletedDecision: {
            MakeId: attribute.selectedMake,
            ModelId: attribute.selectedModel,
            InventoryAttributes: Object.keys(attribute.selectedTypeAttributes || {}).map((key) => {
                return {
                    AttributeId: key,
                    AttributeValue: attribute.selectedTypeAttributes[key]
                };
            }),
            Quantity: 1,
            StoreId: storeId,
            InventoryTypeId:  attribute.inventoryTypeId,
            SubscriberProductId: attribute.subscriberProductId
        }
    };
};

const removeUndefinedAttributes = (formattedDecision) => {
    const physicalInventoryCompletedDecisions = formattedDecision.PhysicalInventoryCompletedDecision;
    return Object.assign(formattedDecision, {
        PhysicalInventoryCompletedDecision: Object.assign(physicalInventoryCompletedDecisions, {
            InventoryAttributes: physicalInventoryCompletedDecisions.InventoryAttributes.reduce((acc, next) => {
                return next.AttributeValue ? acc.concat(next) : acc;
            }, [])
        })
    });
};

export const FormattedPhysicalAttributes = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (editCart, offerId, stores) => {
            if (editCart.editPhysicalAttributeGroups) {
                return flatten(values(editCart.editPhysicalAttributeGroups))
                    .map(partial(createCompletedPhysicalInventoryItemRequests, [offerId, stores && stores.length ? stores[0] : null]))
                    .map(removeUndefinedAttributes);
            } else {
                return EMPTY_FORMATTED_ATTRIBUTES;
            }
        }
    );
};

export const SubscriberProductIdUndefinedSelector = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (group) => {
            if (group) {
                const itemWithoutSubscriberProductId = group.find((item) => {
                    return item.PhysicalInventoryCompletedDecision.SubscriberProductId === undefined;
                });
                return itemWithoutSubscriberProductId ? true : false;
            }
        }
    );
};

export const formatPhysicalInventory = (shoppingCartItem) => {
    const sortedAndFormattedInventoryAttributes = shoppingCartItem.InventoryAttributes.map((invAttribute) => {
        return {
            AttributeId: invAttribute.AttributeId,
            AttributeValue: invAttribute.AttributeValue
        };
    });

    return {
        DisplayName: shoppingCartItem.DisplayName,
        InventoryTypeId: shoppingCartItem.InventoryTypeId,
        MakeId: shoppingCartItem.MakeId,
        ModelId: shoppingCartItem.ModelId,
        OfferingInstanceId: shoppingCartItem.OfferingInstanceId,
        Quantity: 1,
        InventoryAttributes: sortedAndFormattedInventoryAttributes
    };
};


export const AvailableCreditFromQuote = (inputSelectors) => {
    return createImmutableSelector(inputSelectors,
        (offerWizardOrderQuote) => {
            return offerWizardOrderQuote && offerWizardOrderQuote.AvailableCredit || null;
        });
};

export const IsCreditLimitBreachedFromQuote = (inputSelectors) => {
    return createImmutableSelector(inputSelectors,
        (offerWizardOrderQuote) => {
            return offerWizardOrderQuote && offerWizardOrderQuote.IsCreditLimitBreached || false;
        });
};

export const FinanceCreditFromQuoteSelector = (inputSelectors) => {
    return createImmutableSelector(inputSelectors,
        (financeCreditLimitOrderQuote) => {
            return financeCreditLimitOrderQuote && financeCreditLimitOrderQuote.ExceededFinanceCreditLimitAmount || null;
        });
};

export const IsSendToStoreButtonEnabledSelectorCreator = (inputSelectors) => {
    return createImmutableSelector(inputSelectors,
        (physicalInventory, isAttributesStep, isCheckoutStep, asyncId) => {
            return !!((isAttributesStep || isCheckoutStep) && physicalInventory.length && !asyncId);
        });
};

export const GetCartAdditionalPropertiesSelector = (inputSelectors) => {
    return createImmutableSelector(
        inputSelectors,
        (offerWizardStore) => {
            return offerWizardStore.cartAdditionalProperties;
        }
    );
};

export const GetOrderAdditionalPropertiesSelector = (inputSelectors) => {
    return createImmutableSelector(
        inputSelectors,
        (offerWizardStore) => {
            return offerWizardStore.orderAdditionalProperties;
        }
    );
};

export const GetOfferAdditionalPropertiesSelector = (inputSelectors) => {
    return createImmutableSelector(
        inputSelectors,
        (offerWizardStore) => {
            return offerWizardStore.offerAdditionalProperties;
        }
    );
};

export const GetAdditionalPropertyListMapSelector = (inputSelectors) => {
    return createImmutableSelector(
        inputSelectors,
        (additionalPropertyValues, additionalPropertyFields) => {
            return additionalPropertiesMergeValuesIntoTemplate(additionalPropertyFields, additionalPropertyValues);
        }
    );
};

export const DeliveryOptionsByPricingPlanHelper = (inputSelectors) => {
    return createImmutableSelector(
        inputSelectors,
        (deliveryDecisions, shippingMethods) => {
            const shippableDeliveryDecisions =  deliveryDecisions.filter((deliveryDecision) => {
                return deliveryDecision.Shippable;
            });

            return shippableDeliveryDecisions.reduce((pricingPlanByDeliveryOptions, shippableDeliveryDecision) => {
                const deliveryOptions = (shippingMethods || []).reduce((accumulator, shippingMethod) => {
                    const isAvailableShippingMethod = shippableDeliveryDecision.AvailableShippingMethods.some((availableShippingMethodId) => {
                        return availableShippingMethodId === shippingMethod.Id;
                    });
                    if (isAvailableShippingMethod) {
                        accumulator.push({
                            name: shippingMethod.Name,
                            shippingCost: shippingMethod.ShippingCost,
                            currency: shippingMethod.Currency
                        });
                    }
                    return accumulator;
                }, []);

                pricingPlanByDeliveryOptions[shippableDeliveryDecision.PricingPlanId] = pricingPlanByDeliveryOptions[shippableDeliveryDecision.PricingPlanId] || {};
                pricingPlanByDeliveryOptions[shippableDeliveryDecision.PricingPlanId][shippableDeliveryDecision.InstanceNumber] = Object.assign({}, shippableDeliveryDecision, {
                    deliveryOptions,
                    selectedValue: shippableDeliveryDecision.SelectedValue || DELIVERY_OPTIONS.STORE_PICKUP
                });
                return pricingPlanByDeliveryOptions;
            }, {});
        });
};

export const TaxLocationDecisionsByPricingPlanHelper = (inputSelectors) => {
    return createImmutableSelector(
        inputSelectors,
        (taxLocationDecisions) => {
            return taxLocationDecisions.reduce((pricingPlanByTaxLocationOptions, taxLocationDecision) => {
                pricingPlanByTaxLocationOptions[taxLocationDecision.PricingPlanId] = pricingPlanByTaxLocationOptions[taxLocationDecision.PricingPlanId] || {};
                pricingPlanByTaxLocationOptions[taxLocationDecision.PricingPlanId][taxLocationDecision.InstanceNumber] = Object.assign({}, taxLocationDecision);
                pricingPlanByTaxLocationOptions[taxLocationDecision.PricingPlanId][taxLocationDecision.InstanceNumber]['taxOption'] =
                pricingPlanByTaxLocationOptions[taxLocationDecision.PricingPlanId][taxLocationDecision.InstanceNumber].ServiceTaxCustomization.OriginatingPercentage ? TAX_RULE_OPTIONS.CUSTOM : TAX_RULE_OPTIONS.DEFAULT;
                return pricingPlanByTaxLocationOptions;
            }, {});
        });
};

export const FormattedDeliveryDecisions = (inputSelectors) => {
    return createImmutableSelector(
        inputSelectors,
        (deliveryDecisions) => {
            const formattedDeliveryDecisions = [];
            if (deliveryDecisions.length) {
                deliveryDecisions.forEach((item) => {
                    formattedDeliveryDecisions.push({
                        DecisionType: item.DecisionType,
                        Id: item.Id,
                        SelectedValue: item.SelectedValue
                    });
                });
            }
            return formattedDeliveryDecisions.length ? formattedDeliveryDecisions : EMPTY_ARRAY;
        });
};

export const OfferingOrderOffCycleAlignmentOptionsSelector = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (shoppingCart, metadata, offCycleAlignmentOptions,  serviceAttributeCodeTable, regularExpressionCodeTable) => {
            const offers = {};
            const offersToServiceIdentifiers = {};
            shoppingCart && shoppingCart.Items && shoppingCart.Items.forEach((item) => {
                if (!(item.Details.PricingPlan.PricingPlanBillerRuleInstances.TriggerBillerRuleInstances.length
                    && item.Details.PricingPlan.PricingPlanBillerRuleInstances.TriggerBillerRuleInstances[0].TriggerType
                    === BILLER_RULE_INSTANCE_TYPE.ONETIME)) {
                    const pricingPlan = {
                        id: item.PricingPlanId,
                        productId: item.ProductId,
                        pricingPlanName: metadata[item.OfferingId] ? metadata[item.OfferingId].PricingPlanThumbnails[item.PricingPlanId].Name : undefined,
                        options: offCycleAlignmentOptions[item.PricingPlanId] || [],
                    };
                    if (item.ServiceAttributes && item.ServiceAttributes.length) {
                        offersToServiceIdentifiers[item.OfferingInstanceId] = item.ServiceAttributes;
                    }
                    if (metadata[item.OfferingId] && !!offCycleAlignmentOptions[item.PricingPlanId]) {
                        if (offers[item.OfferingInstanceId]) {
                            if (offers[item.OfferingInstanceId].pricingPlans) {
                                offers[item.OfferingInstanceId].pricingPlans.push(pricingPlan);
                            } else {
                                offers[item.OfferingInstanceId].pricingPlans = [pricingPlan];
                            }
                        } else {
                            offers[item.OfferingInstanceId] = {
                                offerName: metadata[item.OfferingId].Name,
                                offeringInstanceId: item.OfferingInstanceId,
                            };
                            offers[item.OfferingInstanceId].pricingPlans = [pricingPlan];
                        }
                    }
                    if (offers[item.OfferingInstanceId]) {
                        offers[item.OfferingInstanceId]['serviceAttributes'] = offersToServiceIdentifiers[item.OfferingInstanceId] && offersToServiceIdentifiers[item.OfferingInstanceId].length ?
                            getUniqueNonGuidServiceIdentifiers(offersToServiceIdentifiers[item.OfferingInstanceId], serviceAttributeCodeTable, regularExpressionCodeTable)
                            : '';
                    }
                }
            });

            return Object.values(offers).filter((offer) => {
                return offer.pricingPlans;
            });
        }
    );
};

export const OfferingOrderProductIdsAndPricingPlanIdsOffCycleOfferSelector = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (shoppingCart, offeringMetadataById) => {
            const offCycleOffers = (shoppingCart && shoppingCart.Items) ?  shoppingCart.Items.filter(item => {
                return offeringMetadataById[item.OfferingId] && offeringMetadataById[item.OfferingId].BillerRuleCycleLevel === BILLER_RULE_CYCLE_LEVEL.ITEM;
            }) : [];
            return offCycleOffers.map(item => {
                return {
                    PricingPlanId: item.PricingPlanId,
                    ProductId: item.ProductId
                };
            });
        }
    );
};

export const SelectedOffCycleAlignmentsSelector = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (subscriberProductData, alignmentOptions) => {
            return alignmentOptions.map((offer) => {
                if (subscriberProductData[offer.offeringInstanceId]) {
                    return {
                        offerName: offer.offerName,
                        offeringInstanceId: offer.offeringInstanceId,
                        serviceAttributes: offer.serviceAttributes,
                        pricingPlans: offer.pricingPlans.map((pricingPlan) => {
                            if (subscriberProductData[offer.offeringInstanceId][pricingPlan.id]
                                && subscriberProductData[offer.offeringInstanceId][pricingPlan.id].nextRenewalDate) {
                                return {
                                    pricingPlanName: pricingPlan.pricingPlanName,
                                    pricingPlanId: pricingPlan.id,
                                    subscriberProductId: subscriberProductData[offer.offeringInstanceId][pricingPlan.id].subscriberProductId,
                                    nextRenewalDate: subscriberProductData[offer.offeringInstanceId][pricingPlan.id].nextRenewalDate,
                                };
                            }
                        }).filter((pricingPlan) => {
                            return pricingPlan;
                        })
                    };
                }
            }).filter((offer) => {
                return offer && offer.pricingPlans && offer.pricingPlans.length;
            });
        }
    );
};


export const DeliveryMethodOptionsSelector = (inputSelectors) => {
    return createImmutableSelector(
        inputSelectors,
        (deliveryMethods, subscriberCurrency) => {
            return deliveryMethods && deliveryMethods.filter((deliveryMethod) => {
                return deliveryMethod.Currency === subscriberCurrency;
            }).map((deliveryMethod) => {
                return {
                    label: deliveryMethod.Name,
                    value: deliveryMethod.Id,
                    cost: deliveryMethod.ShippingCost,
                    currency: deliveryMethod.Currency
                };
            });
        });
};

export const ShippingAddressWizardSelector = (inputSelectors) => {
    return createImmutableSelector(
        inputSelectors,
        (isQuoteByPassed, wizardSelector, currentShippingAddress) => {
            return isQuoteByPassed ? currentShippingAddress : wizardSelector.shippingAddress;
        });
};

export const ShippingAddressDropdownOptionsWizardSelector = (inputSelectors) => {
    return createSelector(
        inputSelectors,
        (wizardSelector, currentShippingAddress) => {
            const shippingAddress =cloneDeep((wizardSelector.offeringOrderQuote && wizardSelector.offeringOrderQuote.Currency && currentShippingAddress) ? currentShippingAddress : null);
            const blankBillingAddress = {
                Id: 0,
                Country: null,
                State: null
            };
            const popupAddresses = shippingAddress ? [shippingAddress].concat(blankBillingAddress) : [blankBillingAddress];
            return popupAddresses.map((address) => {
                address.dropDownString = address.Id === 0 ? i18n.translate(CustomerCareKeys.ADD_NEW) : AddressHelper.createBillingAddressString(address);
                return address;
            });
        });
};

export const GetDownPaymentTypeValue = (shoppingCartItem) => {
    const item = Object.assign({}, shoppingCartItem);
    if (item.isFullPriceDownPaymentSelected) {
        item.DownPaymentType = DOWN_PAYMENT_TYPE.FULL_UPFRONT_PAYMENT;
    } else if (item.downPayment > 0 && !item.isFullPriceDownPaymentSelected && !item.isDownPaymentEqualToFirstInstallment) {
        item.DownPaymentType = DOWN_PAYMENT_TYPE.DOWN_PAYMENT_WITH_INSTALLMENT;
    } else if (item.isDownPaymentEqualToFirstInstallment !== undefined && item.isDownPaymentEqualToFirstSelected) {
        item.DownPaymentType = DOWN_PAYMENT_TYPE.DOWN_PAYMENT_EQUAL_TO_FIRST_INSTALLMENT;
    }
    return item.DownPaymentType;
};
