export const TREATMENT_STATUS = {
    NOT_IN_TREATMENT: 0,
    IN_TREATMENT: 1,
    IN_TREATMENT_WITH_P2P: 2
};

export const OfferingOptionBulkTypes = {
    BULK_NONE : 0,
    BULK_FLAT : 1,
    BULK_UNIT : 2,
};