import Immutable from 'seamless-immutable';
import {
    RESEND_GIFT_NOTIFICATION,
    RESET_RESEND_GIFT_ORDER_INFO,
    SET_RESEND_GIFT_ORDER_INFO
} from '../actions/resend.gift.notification.actions';

const EMPTY_OBJECT = Immutable({});

export const INITIAL_STATE = Immutable({
    giftOrderInfo: EMPTY_OBJECT,
    resendGiftNotificationError: null,
    isResendingGiftNotification: false
});

export default function ResendGiftNotificationReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case RESEND_GIFT_NOTIFICATION.BEGIN:
            return state
                .set('isResendingGiftNotification', true);
        case RESEND_GIFT_NOTIFICATION.SUCCESS:
        case RESET_RESEND_GIFT_ORDER_INFO:
            return state
                .set('giftOrderInfo', EMPTY_OBJECT)
                .set('resendGiftNotificationError', null)
                .set('isResendingGiftNotification', false);
        case RESEND_GIFT_NOTIFICATION.FAILURE:
            return state
                .set('isResendingGiftNotification', false)
                .set('resendGiftNotificationError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case SET_RESEND_GIFT_ORDER_INFO:
            return state
                .set('giftOrderInfo', payload);
        default:
            return state;
    }
}
