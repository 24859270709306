import Immutable from 'seamless-immutable';
import {FETCH_PRODUCT_SERVICE_RELATIONSHIPS} from './actions/product.service.relationships.actions';
import {EMPTY_ARRAY} from './constants/common.constants';

export const INITIAL_STATE = Immutable({
    data: EMPTY_ARRAY,
    isFetching: false,
    retrieveError: null
});

export default (state = INITIAL_STATE, {payload = {}, type}) => {
    switch (type) {
        case FETCH_PRODUCT_SERVICE_RELATIONSHIPS.BEGIN:
            return state.set('isFetching', true);
        case FETCH_PRODUCT_SERVICE_RELATIONSHIPS.SUCCESS:
            return state
                .set('isFetching', false)
                .set('data', payload.ConsumerPlans)
                .set('retrieveError', null);
        case FETCH_PRODUCT_SERVICE_RELATIONSHIPS.FAILURE:
            return state
                .set('isFetching', false)
                .set('retrieveError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        default:
            return state;
    }
};