import {createSelector} from 'reselect';
import {CurrentCouponRedemptionCodeIdentifierSelector} from './coupon.redemption.code.selectors';
import {
    getSearchFieldsTemplate
} from '../../components/search/search.constants';

export const CouponSearchRecordCountSelector = createSelector(
    [CurrentCouponRedemptionCodeIdentifierSelector],
    (couponRedemptionCodeId) => {
        return couponRedemptionCodeId ? 1 : -1;
    }
);

const recoverableUIStateSelector = (state) => {
    return state.customercare.recoverableUiState.searchCoupons.couponSearchModel;
};

export const CouponSearchModelSelector = createSelector(
    [recoverableUIStateSelector],
    (state) => {
        return state ? state : getSearchFieldsTemplate();
    }
);
