import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {EMPTY_ARRAY} from 'invision-core/src/constants/common.constants';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {CUSTOMER_CATEGORY} from '../../components/createCustomerPopup/create.customer.popup.constants';
import {
    SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
    SubscriberRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {SubscriberIdentificationsSelector} from '../../components/createCustomerPopup/customer.form.dropdown.selectors';
import {
    CreateEditCustomerSelector,
    SelectedCustomerCategorySelector
} from './customer.selectors';
import {EditCustomerInfoSelector} from './new.connect.wizard.selectors';
import {
    CreateChildModelSelector,
    IsChildForCustomerHierarchySelector
} from './customer.account.hierarchy.selectors';
export const FormattedNewCustomerSubscriberIdentitiesSelector = createImmutableSelector(
    [
        CreateChildModelSelector,
        EditCustomerInfoSelector,
        SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
        SubscriberRequirementsForCurrentBusinessUnitOrDefault,
        SubscriberIdentificationsSelector,
        SelectedCustomerCategorySelector,
        IsChildForCustomerHierarchySelector
    ],
    (
        createChildModel,
        customerInfo,
        subscriberCategoryRequirements,
        subscriberRequirements,
        subscriberIdentifications,
        selectedCustomerCategory,
        IsChildForCustomerHierarchySelector
    ) => {
        return IsChildForCustomerHierarchySelector ?
            formatSubscriberIdentification(
                createChildModel.Customer,
                subscriberCategoryRequirements,
                subscriberRequirements,
                subscriberIdentifications,
                selectedCustomerCategory
            ) :
            formatSubscriberIdentification(
                customerInfo,
                subscriberCategoryRequirements,
                subscriberRequirements,
                subscriberIdentifications,
                selectedCustomerCategory
            );
    }
);

export const FormattedEditCustomerSubscriberIdentitiesSelector = createImmutableSelector(
    [
        CreateEditCustomerSelector,
        SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
        SubscriberRequirementsForCurrentBusinessUnitOrDefault,
        SubscriberIdentificationsSelector,
        SelectedCustomerCategorySelector
    ],
    (
        createdEditCustomer,
        subscriberCategoryRequirements,
        subscriberRequirements,
        subscriberIdentifications,
        selectedCustomerCategory
    ) => {
        return formatSubscriberIdentification(
            createdEditCustomer,
            subscriberCategoryRequirements,
            subscriberRequirements,
            subscriberIdentifications,
            selectedCustomerCategory
        );
    });

const formatSubscriberIdentification = (customerInfo, subscriberCategoryRequirements, subscriberRequirements, subscriberIdentifications, selectedCustomerCategory) => {
    const numberOfRequiredIdentifications = selectedCustomerCategory === CUSTOMER_CATEGORY.COMMERCIAL ?
        convertStringToNumber(subscriberRequirements.number_of_required_identification_types) :
        convertStringToNumber(subscriberCategoryRequirements.number_of_required_identification_types);
    let formattedSubscriberIdentifications = EMPTY_ARRAY;
    const emptyObject = {
        Countries: EMPTY_ARRAY,
        IsPrimary: false,
        IsCountryStateRequired: false,
        IsCountryStateVisible: false,
        Regex: null,
        SubscriberIdentificationTypeCode: null,
        SubscriberIdentificationValue: null,
        UseConfiguredCountries: null
    };
    const defaultEmptyObject = [emptyObject];

    for (let i = 1; i < numberOfRequiredIdentifications; i++) {
        defaultEmptyObject.push([emptyObject]);
    }
    formattedSubscriberIdentifications = customerInfo.SubscriberIdentifications && customerInfo.SubscriberIdentifications.length ?
        customerInfo.SubscriberIdentifications.map((customerIdentification) => {
            const identification = subscriberIdentifications.find((subscriberIdentification) => {
                return subscriberIdentification.Value === customerIdentification.SubscriberIdentificationTypeCode;
            }) || null;

            return {
                Country: customerIdentification.Country || null,
                Countries: identification ? identification.Countries : EMPTY_ARRAY,
                Id: customerIdentification.Id,
                IsCountryStateRequired: identification ? identification.IsCountryStateRequired : false,
                IsCountryStateVisible: identification ? identification.IsCountryStateVisible : false,
                IsPrimary: identification ? identification.IsPrimary : false,
                Regex: identification ? identification.Regex : null,
                State: customerIdentification.State || null,
                SubscriberIdentificationTypeCode: customerIdentification.SubscriberIdentificationTypeCode,
                SubscriberIdentificationValue: customerIdentification.SubscriberIdentificationValue,
                UseConfiguredCountries: identification ? identification.UseConfiguredCountries : false
            };
        }) : defaultEmptyObject;

    for (let i = 1; i < numberOfRequiredIdentifications.number_of_required_identification_types; i++) {
        if (customerInfo.SubscriberIdentifications && customerInfo.SubscriberIdentifications.length
            && numberOfRequiredIdentifications > customerInfo.SubscriberIdentifications.length) {
            formattedSubscriberIdentifications.push(emptyObject);
        }
    }
    return {
        formattedSubscriberIdentifications,
        numberOfRequiredIdentifications
    };
};
