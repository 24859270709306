import Immutable from 'seamless-immutable';
import {
    SET_MAKE_PAYMENT_ADDRESSES,
    SET_MAKE_PAYMENT_ADDRESS_STATE_VALUE,
    SET_MAKE_PAYMENT_INSTRUMENT,
    SET_MAKE_PAYMENT_INSTRUMENT_BILLING_ADDRESS
} from './actions/make.payment.actions';

export const INITIAL_STATE = Immutable({
    address: {},
    addresses: [],
    paymentInstrument: {}
});

export default function makePaymentReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case SET_MAKE_PAYMENT_ADDRESSES:
            return state.set(['addresses'], payload);
        case SET_MAKE_PAYMENT_ADDRESS_STATE_VALUE:
            return state.setIn(['address', 'State'], payload);
        case SET_MAKE_PAYMENT_INSTRUMENT:
            return state.set(['paymentInstrument'], payload);
        case SET_MAKE_PAYMENT_INSTRUMENT_BILLING_ADDRESS:
            return state.setIn(['paymentInstrument', 'BillingAddress'], payload);
        default:
            return state;
    }
}
