import {ApiConstants, ThunkHelper} from 'invision-core';

export const PAGE_SIZE = 5;

export const FETCH_CUSTOMER_INVENTORY = {
    BEGIN: 'FETCH_CUSTOMER_INVENTORY.BEGIN',
    FAILURE: 'FETCH_CUSTOMER_INVENTORY.FAILURE',
    SUCCESS: 'FETCH_CUSTOMER_INVENTORY.SUCCESS'
};

const FETCH_CUSTOMER_INVENTORY_TYPES = [
    FETCH_CUSTOMER_INVENTORY.BEGIN,
    FETCH_CUSTOMER_INVENTORY.SUCCESS,
    FETCH_CUSTOMER_INVENTORY.FAILURE
];

export const RETRIEVE_CUSTOMER_INVENTORY_ITEM = {
    BEGIN: 'RETRIEVE_CUSTOMER_INVENTORY_ITEM.BEGIN',
    FAILURE: 'RETRIEVE_CUSTOMER_INVENTORY_ITEM.FAILURE',
    SUCCESS: 'RETRIEVE_CUSTOMER_INVENTORY_ITEM.SUCCESS'
};

export const RETRIEVE_CUSTOMER_INVENTORY_ITEM_ACTIVITIES = {
    BEGIN: 'RETRIEVE_CUSTOMER_INVENTORY_ITEM_ACTIVITIES.BEGIN',
    FAILURE: 'RETRIEVE_CUSTOMER_INVENTORY_ITEM_ACTIVITIES.FAILURE',
    SUCCESS: 'RETRIEVE_CUSTOMER_INVENTORY_ITEM_ACTIVITIES.SUCCESS'
};

const RETRIEVE_CUSTOMER_INVENTORY_ITEM_TYPES = [
    RETRIEVE_CUSTOMER_INVENTORY_ITEM.BEGIN,
    RETRIEVE_CUSTOMER_INVENTORY_ITEM.SUCCESS,
    RETRIEVE_CUSTOMER_INVENTORY_ITEM.FAILURE
];

const RETRIEVE_CUSTOMER_INVENTORY_ITEM_ACTIVITIES_TYPES = [
    RETRIEVE_CUSTOMER_INVENTORY_ITEM_ACTIVITIES.BEGIN,
    RETRIEVE_CUSTOMER_INVENTORY_ITEM_ACTIVITIES.SUCCESS,
    RETRIEVE_CUSTOMER_INVENTORY_ITEM_ACTIVITIES.FAILURE
];

export const UPDATE_INVENTORY_FRIENDLY_NAME = {
    BEGIN: 'UPDATE_INVENTORY_FRIENDLY_NAME.BEGIN',
    SUCCESS: 'UPDATE_INVENTORY_FRIENDLY_NAME.SUCCESS',
    FAILURE: 'UPDATE_INVENTORY_FRIENDLY_NAME.FAILURE'
};
const UPDATE_INVENTORY_FRIENDLY_NAME_CONSTANTS = [
    UPDATE_INVENTORY_FRIENDLY_NAME.BEGIN,
    UPDATE_INVENTORY_FRIENDLY_NAME.SUCCESS,
    UPDATE_INVENTORY_FRIENDLY_NAME.FAILURE
];

export const fetchCustomerInventoryPromise = (dispatch, customerId, params = {}) => {
    return ThunkHelper(dispatch, FETCH_CUSTOMER_INVENTORY_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/SearchSubscriberInventory',
        data: {
            InternalStatus: params.internalStatus,
            InventoryCategory: params.inventoryCategory,
            InventoryId: params.inventoryId,
            InventoryTypeName: params.inventoryTypeName,
            PageNumber: params.pageNumber,
            PageSize: params.pageSize,
            SerialNumber: params.serialNumber,
            ServiceIdentifierValue: params.serviceIdentifierValue
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const fetchCustomerInventory = (customerId, params) => {
    return (dispatch) => {
        return fetchCustomerInventoryPromise(dispatch, customerId, params);
    };
};

export const retrieveCustomerInventoryItemPromise = (dispatch, customerId, instanceId, inventoryId) => {
    return ThunkHelper(dispatch, RETRIEVE_CUSTOMER_INVENTORY_ITEM_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveSubscriberInventory',
        data: {
            InstanceId: instanceId
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        inventoryId
    });
};

export const retrieveCustomerInventoryItemActivitiesPromise = (dispatch, customerId, subscriberProductId) => {
    return ThunkHelper(dispatch, RETRIEVE_CUSTOMER_INVENTORY_ITEM_ACTIVITIES_TYPES, {
        data: {
            PageSize: PAGE_SIZE,
            SubscriberProductId: subscriberProductId
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        method: 'post',
        url: 'SubscriberManagement/RetrieveSubscriberFinanceActivityDetails'
    });
};

export const retrieveCustomerInventoryItemActivities = (customerId, subscriberProductId) => {
    return (dispatch) => {
        return retrieveCustomerInventoryItemActivitiesPromise(dispatch, customerId, subscriberProductId);
    };
};


export const RETRIEVE_CUSTOMER_INVENTORY_ITEM_FINANCE = {
    BEGIN: 'RETRIEVE_CUSTOMER_INVENTORY_ITEM_FINANCE.BEGIN',
    SUCCESS: 'RETRIEVE_CUSTOMER_INVENTORY_ITEM_FINANCE.SUCCESS',
    FAILURE: 'RETRIEVE_CUSTOMER_INVENTORY_ITEM_FINANCE.FAILURE'
};

export const fetchInventoryItemFinanceDetails = (customerId, serviceIdentifiers) => {
    return (dispatch) => {
        return fetchInventoryItemFinanceDetailsPromise(dispatch, customerId, serviceIdentifiers);
    };
};

export const fetchInventoryItemFinanceDetailsPromise = (dispatch, customerId, serviceIdentifiers) => {
    return ThunkHelper(dispatch, Object.values(RETRIEVE_CUSTOMER_INVENTORY_ITEM_FINANCE), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveSubscriberFinanceDetails',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            ServiceIdentifiers: serviceIdentifiers
        }
    });
};

export const retrieveCustomerInventoryItem = (customerId, instanceId, inventoryId) => {
    return (dispatch) => {
        return retrieveCustomerInventoryItemPromise(dispatch, customerId, instanceId, inventoryId);
    };
};

export const CLEAR_CUSTOMER_INVENTORY = 'CLEAR_CUSTOMER_INVENTORY';

export const clearCustomerInventory = () => {
    return {
        type: CLEAR_CUSTOMER_INVENTORY
    };
};

export const updateFriendlyNamePromise = (dispatch, customerId, inventoryId, friendlyName) => {
    return ThunkHelper(dispatch, UPDATE_INVENTORY_FRIENDLY_NAME_CONSTANTS, {
        method: 'post',
        url: 'SubscriberManagement/UpdateSubscriberInventoryFriendlyName',
        data: {
            FriendlyName: friendlyName,
            InventoryId: inventoryId
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const updateFriendlyName = (customerId, inventory, friendlyName) => {
    return (dispatch) => {
        return updateFriendlyNamePromise(dispatch, customerId, inventory.Id, friendlyName);
    };
};

export const SET_INVENTORY_FILTER_DATA = 'SET_INVENTORY_FILTER_DATA';
export const setInventoryFilterData = (searchBy, searchTerm, filterBy) => {
    return {
        type: SET_INVENTORY_FILTER_DATA,
        payload: {
            searchBy,
            searchTerm,
            filterBy
        }
    };
};

export const SET_INVENTORY_PAGINATION_DATA = 'SET_INVENTORY_PAGINATION_DATA';
export const setInventoryPaginationData = (pageNumber, pageSize) => {
    return {
        type: SET_INVENTORY_PAGINATION_DATA,
        payload: {
            pageNumber,
            pageSize
        }
    };
};

export const SET_PHYSICAL_INVENTORY_NAME = 'SET_PHYSICAL_INVENTORY_NAME';
export const setPhysicalInventoryName = (name) => {
    return {
        type: SET_PHYSICAL_INVENTORY_NAME,
        payload: name
    };
};
