import {ApiConstants,
    ThunkHelper} from 'invision-core';

export const SEARCH_ORDERS_CONSTANTS = {
    BEGIN: 'SEARCH_ORDERS_BEGIN',
    SUCCESS: 'SEARCH_ORDERS_SUCCESS',
    FAILURE: 'SEARCH_ORDERS_FAILURE'
};
const SEARCH_ORDERS_EVENTS = [SEARCH_ORDERS_CONSTANTS.BEGIN, SEARCH_ORDERS_CONSTANTS.SUCCESS, SEARCH_ORDERS_CONSTANTS.FAILURE];

const searchOrdersPromise = (dispatch, customerId, filter = {}) => {

    const data = {
        PageSize: filter.pageSize,
        PageNumber: filter.pageNumber
    };

    if (filter.orderStatus && filter.orderStatus.length) {
        data.Statuses = filter.orderStatus.map((selectedOrderStatus) => {
            return selectedOrderStatus.id;
        });
    }

    if (filter.orderType && filter.orderType.length) {
        data.Types = filter.orderType.map((selectedOrderType) => {
            return selectedOrderType.id;
        });
    }

    if (filter.Types && filter.Types.length) {
        data.Types = filter.Types;
    }

    if (filter.Statuses && filter.Statuses.length) {
        data.Statuses = filter.Statuses;
    }

    data.ReturnAutomaticOrders = filter.returnAutoOrders;
    data.ReturnMigratedOrders = filter.returnMigratedOrders;

    data.Start = filter.startDate;
    data.End = filter.endDate;

    return ThunkHelper(dispatch, SEARCH_ORDERS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SearchOrders',
        data: data,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId
    });
};

export const searchOrders = (customerId, filter) => {
    return (dispatch) => {
        return searchOrdersPromise(dispatch, customerId, filter);
    };
};

export const EMPTY_ORDER_HISTORY_LIST = 'EMPTY_ORDER_HISTORY_LIST';
export const emptyOrderHistoryList = () => {
    return {
        type: EMPTY_ORDER_HISTORY_LIST
    };
};


