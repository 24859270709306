import {
    CLEAR_TRANSACTIONS_ERROR,
    EMAIL_TRANSACTION_CONSTANTS,
    EMPTY_TRANSACTIONS_LIST,
    RETRIEVE_TRANSACTION_CONSTANTS,
    SEARCH_TRANSACTIONS_CONSTANTS
} from './actions/customer.transactions.actions';
import {ISSUE_WRITE_OFF_CONSTANTS} from './actions/issue.write.off.actions';
import Immutable from 'seamless-immutable';

export const INITIAL_STATE = Immutable({
    canRetryTransaction: false,
    isFetchingData: false,
    isSendingEmail: false,
    transactionsError: null,
    data: null,
    pageCount: null,
    recordCount: null
});

const convertTransactionsToMap = (transactions) => {
    const obj = {};
    if (transactions) {
        transactions.forEach((item) => {
            obj[item.Id] = item;
        });
    }
    return obj;
};

export default function transactionsReducer(state = INITIAL_STATE, {payload = {}, type, requestObject = {}}) {
    switch (type) {
        case SEARCH_TRANSACTIONS_CONSTANTS.BEGIN:
        case RETRIEVE_TRANSACTION_CONSTANTS.BEGIN:
        case ISSUE_WRITE_OFF_CONSTANTS.BEGIN:
            return state.set('isFetchingData', true);
        case CLEAR_TRANSACTIONS_ERROR:
            return state.set('transactionsError', null);
        case ISSUE_WRITE_OFF_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingData', false)
                .set('transactionsError', null);
        case RETRIEVE_TRANSACTION_CONSTANTS.SUCCESS:
            return state
                .setIn(['data', requestObject.transactionId, 'details'], payload.Transaction)
                .setIn(['data', requestObject.transactionId, 'ReceiptContext'], payload.ReceiptContext)
                .set('AllowPartialRefunds', payload.AllowPartialRefunds)
                .set('Products', payload.Products || {})
                .set('PricingPlans', payload.PricingPlans || {})
                .set('Offerings', payload.Offerings || {})
                .set('isFetchingData', false)
                .set('canRetryTransaction', payload.CanBeRetried)
                .set('transactionsError', null);
        case SEARCH_TRANSACTIONS_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingData', false)
                .set('data', convertTransactionsToMap(payload.Transactions))
                .set('pageCount', payload.PageCount || 1)
                .set('recordCount', payload.RecordCount || 0)
                .set('hasLoadedTransactions', true)
                .set('transactionsError', null);
        case SEARCH_TRANSACTIONS_CONSTANTS.FAILURE:
            return state
                .set('isFetchingData', false)
                .set('hasLoadedTransactions', false)
                .set('transactionsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case RETRIEVE_TRANSACTION_CONSTANTS.FAILURE:
        case ISSUE_WRITE_OFF_CONSTANTS.FAILURE:
            return state
                .set('isFetchingData', false)
                .set('transactionsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case EMAIL_TRANSACTION_CONSTANTS.BEGIN:
            return state.set('isSendingEmail', true);
        case EMAIL_TRANSACTION_CONSTANTS.SUCCESS:
            return state
                .set('isSendingEmail', false)
                .set('transactionsError', null);
        case EMAIL_TRANSACTION_CONSTANTS.FAILURE:
            return state
                .set('isSendingEmail', false)
                .set('transactionsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case EMPTY_TRANSACTIONS_LIST:
            return INITIAL_STATE;
        default:
            return state;
    }
}
