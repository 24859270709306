import Immutable from 'seamless-immutable';
import {
    SET_SELECTED_CASE_DETAIL_TAB_INDEX
} from './actions/customer.case.details.actions';
import {CASE_DETAILS_TABS} from '../components/customer/cases/list/case.constants';

export const INITIAL_STATE = Immutable({
    selectedCaseDetailTabIndex: CASE_DETAILS_TABS.DETAILS
});

export default function caseDetailsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case SET_SELECTED_CASE_DETAIL_TAB_INDEX:
            return state.set('selectedCaseDetailTabIndex', payload);
        default:
            return state;
    }
}