import Immutable from 'seamless-immutable';
import {
    compose,
    curry
} from 'ramda';

import {
    ConfigCreditCheckReducer,
    CONFIG_INITIAL_STATE as CONFIG_CREDIT_CHECK_INITIAL_STATE
} from './credit.check.reducer';

export const INITIAL_STATE = Immutable({
    creditCheck: CONFIG_CREDIT_CHECK_INITIAL_STATE
});

const curriedSetState = curry((path, childReducer, accessorFunc, action, parentState) => {
    return parentState.setIn(path, childReducer(accessorFunc(parentState), action));
});

export default function reducer(state = INITIAL_STATE, action) {
    return compose(
        setCustomerCreditCheckReducer(action)
    )(state);
}

const setCustomerCreditCheckReducer = curriedSetState(
    ['creditCheck'],
    ConfigCreditCheckReducer,
    state => {
        return state.creditCheck;
    }
);
