import Immutable from 'seamless-immutable';
import {
    CREATE_REMARK,
    EDIT_REMARK,
    SEARCH_REMARKS,
    SET_REMARKS_SERVICE_AVAILABILITY,
    UPDATE_REMARK
} from './actions/customer.remarks.actions';

export const INITIAL_STATE = Immutable({
    remarksErrors: [],
    remarkToEdit: null,
    data: [],
    isFetchingRemarks: false,
    isUpdatingRemark: false,
    isCreatingRemark: false,
    isServiceAvailable: true
});

export default function remarksReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case CREATE_REMARK.BEGIN:
            return state.set('isCreatingRemark', true);
        case CREATE_REMARK.SUCCESS:
            return state.set('isCreatingRemark', false);
        case CREATE_REMARK.FAILURE:
            return state
                .set('isCreatingRemark', false)
                .set('remarksErrors', [{
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity
                }]);
        case EDIT_REMARK.CLEAR:
            return state.set('remarkToEdit', null);
        case EDIT_REMARK.SET:
            return state.set('remarkToEdit', payload);
        case SEARCH_REMARKS.BEGIN:
            return state.set('isFetchingRemarks', true);
        case SEARCH_REMARKS.SUCCESS:
            return state.set('data', payload.Remarks);
        case SEARCH_REMARKS.FAILURE:
            return state
                .set('isFetchingRemarks', false)
                .set('remarksErrors', [{
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity
                }]);
        case SET_REMARKS_SERVICE_AVAILABILITY:
            return state.set('isServiceAvailable', payload);
        case UPDATE_REMARK.BEGIN:
            return state.set('isUpdatingRemark', true);
        case UPDATE_REMARK.SUCCESS:
            return state.set('isUpdatingRemark', false);
        case UPDATE_REMARK.FAILURE:
            // TODO: errors are not handled
            return state.set('isUpdatingRemark', false);
        default:
            return state;
    }
}
