import {createSelector} from 'reselect';

const recoverableUIStateSelector = (state) => {
    return state.customercare.recoverableUiState.discount;
};

export const DiscountFormSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.discountForm;
    }
);
