export const CLEAR_DISCOUNT_FORM = 'CLEAR_DISCOUNT_FORM';
export const clearDiscountForm = () => {
    return {
        type: SET_DISCOUNT_FORM,
        payload: {}
    };
};

export const SET_DISCOUNT_FORM = 'SET_DISCOUNT_FORM';
export const setDiscountForm = (form) => {
    return {
        type: SET_DISCOUNT_FORM,
        payload: form
    };
};