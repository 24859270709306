import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';
import uniqBy from 'ramda/src/uniqBy';
import {createSelector} from 'reselect';
import Immutable from 'seamless-immutable';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {OfferingMetadataPricingPlansSelector} from 'invision-core/src/components/metadata/offerings/offerings.selectors';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {
    RouteParams,
    SelectedCustomerSelector
} from './customer.selectors';
import {GENERAL_STATUSES} from '../../components/shared/constants/general.status.constants';
import {PRICING_PLAN_SERVICE_RELATIONSHIP_TYPE} from '../../customercare.constants';
import {AccountOfferingsSelector} from './dashboard.selectors';
import {GetOfferingOrderQuoteSelector} from '../helpers/offer.ordering.wizard.selector.helper';
import {OfferingOrderQuoteSelector} from './offering.order.selectors';
import {ContractInstanceSelector} from './contract.selectors';

const EMPTY_ARRAY = Immutable([]);
const EMPTY_OBJECT = Immutable({});

export const OfferingDisconnectSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.disconnectOffering;
    }
);

export const RemoveOfferItemsIdsSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.offeringId && routeParams.offeringInstanceId ? [{
            offeringId: routeParams.offeringId,
            offeringInstanceId: routeParams.offeringInstanceId
        }] : null;
    }
);

export const IsFullDisconnect = createSelector(
    [RemoveOfferItemsIdsSelector],
    (removeOfferingId) => {
        return removeOfferingId === null;
    }
);

const DisconnectData = createSelector(
    [OfferingDisconnectSelector],
    (offeringDisconnect) => {
        return offeringDisconnect.data;
    }
);

const EtfData = createSelector(
    [DisconnectData],
    (disconnectData) => {
        return disconnectData.etfWaive;
    }
);

export const BillingEffectiveDateConfigurationSelector = createImmutableSelector(
    [DisconnectData],
    (disconnectData) => {
        return disconnectData.billingEffectiveDateConfiguration || EMPTY_OBJECT;
    }
);

export const BillingEffectiveDateSettingsSelector  = createImmutableSelector(
    [BillingEffectiveDateConfigurationSelector],
    (billingEffectiveDateConfiguration) => {
        return {
            minimumDate: billingEffectiveDateConfiguration.MinBillingEffectiveDate,
            pastDateMaxLength: billingEffectiveDateConfiguration.PastBillingEffectiveDateMaxLength
        };
    }
);

export const BillingEffectiveDateOptionsSelector = createImmutableSelector(
    [BillingEffectiveDateConfigurationSelector],
    (billingEffectiveDateConfiguration) => {
        return billingEffectiveDateConfiguration.OrderExecutionToBillingEffectiveDateIntentions || EMPTY_ARRAY;
    }
);

export const IsEtfWaived = createSelector(
    [EtfData],
    (etfData) => {
        return etfData.waive;
    }
);

export const EtfWaiveReason = createSelector(
    [EtfData],
    (etfData) => {
        return etfData.waiveReason;
    }
);

export const IsRetrievingContextSelector = createSelector(
    [OfferingDisconnectSelector],
    (offeringDisconnect) => {
        return offeringDisconnect.isRetrievingContext;
    }
);

export const IsDisconnectQuoteBypassedSelector = createSelector(
    [OfferingDisconnectSelector],
    (offeringDisconnect) => {
        return offeringDisconnect.isQuoteBypassed;
    }
);

export const IsSubmittingOrderSelector = createSelector(
    [OfferingDisconnectSelector],
    (offeringDisconnect) => {
        return offeringDisconnect.isSubmittingOrder;
    }
);

const RemoveItems = createSelector(
    [DisconnectData],
    (disconnectData) => {
        return disconnectData.removeItems;
    }
);

export const DisconnectItemsSelector = createSelector(
    [RemoveItems, IsEtfWaived, EtfWaiveReason],
    (removeItems, isEtfWaive, etfWaiveReaason) => {
        return removeItems.map((item) => {
            return isEtfWaive ? Object.assign({}, item, {
                OrderContractInstance: Object.assign({}, item.OrderContractInstance, {
                    EarlyTerminationFeeOverrideReason: etfWaiveReaason,
                    IsEarlyTerminationFeeOverride: isEtfWaive
                })
            }) : item;
        });
    }
);

export const HasFinancedDevicesSelector = createImmutableSelector(
    [DisconnectItemsSelector],
    (disconnectItems) => {
        return disconnectItems.some((disconnectItem) => {
            return disconnectItem.FinanceDetails;
        });
    }
);

export const HasProvidesPlansSelector = createImmutableSelector(
    [RemoveItems, OfferingMetadataPricingPlansSelector],
    (removeItems, pricingPlanThumbnails) => {
        return removeItems.some((removeItem) => {
            return pricingPlanThumbnails[removeItem.PricingPlanId]
                && pricingPlanThumbnails[removeItem.PricingPlanId].ServiceRelationshipType === PRICING_PLAN_SERVICE_RELATIONSHIP_TYPE.PROVIDES;
        });
    }
);

export const DisconnectQuoteSelector = createSelector(
    [IsFullDisconnect, DisconnectData, GetOfferingOrderQuoteSelector([OfferingOrderQuoteSelector])],
    (isFullDisconnect, disconnectData, offeringOrderQuote) => {
        return isFullDisconnect ? disconnectData.quote : offeringOrderQuote;
    }
);

export const IsCalculatingQuoteSelector = createSelector(
    [OfferingDisconnectSelector],
    (disconnectOffering) => {
        return disconnectOffering.isCalculatingQuote;
    }
);

export const ContractIdRouteParamSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.contractId;
    }
);

const QuoteItems = createSelector(
    DisconnectQuoteSelector,
    (quote) => {
        return pathOr(EMPTY_ARRAY, ['Items'], quote);
    }
);

export const DisconnectOfferServiceFeaturesSelector = createSelector(
    DisconnectQuoteSelector,
    (quote) => {
        return (pathOr(EMPTY_ARRAY, ['Items'], quote)).filter((quoteItem) => {
            return quoteItem.Product.ProductClassification === PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE
                && quoteItem.Product.Status === GENERAL_STATUSES.ACTIVE;
        }).map((feature) => {
            return {
                Amount: feature.Amount,
                Currency: quote.Currency,
                ProductName: feature.Name,
                Quantity: feature.Quantity,
                SubscriberProductId: feature.SubscriberProductId
            };
        });
    }
);


export const DefaultEtfAmount = createSelector(
    [EtfData],
    (etfData) => {
        return etfData.defaultAmount;
    }
);


export const DisconnectOrderContractInstanceSelector = createImmutableSelector(
    [EtfWaiveReason, OfferingOrderQuoteSelector, ContractInstanceSelector],
    (etfWaiveReason, offeringOrderQuote, contractInstance) => {
        if (offeringOrderQuote.OfferingQuotes && offeringOrderQuote.OfferingQuotes.length) {
            const quoteItemWithContractInstance = offeringOrderQuote.OfferingQuotes.find((offeringQuote) => {
                return !!offeringQuote.OrderContractInstanceId;
            });
            return quoteItemWithContractInstance ? Object.assign({}, {
                orderContractInstanceId: quoteItemWithContractInstance.OrderContractInstanceId,
                contractName: contractInstance ? contractInstance.ContractName: null,
                contractId: contractInstance ? contractInstance.OrderContractId : null,
                currency : offeringOrderQuote.Currency,
                isETFOverride: contractInstance ? contractInstance.IsEarlyTerminationFeeOverride : null,
                earlyTerminationFeeOverrideReason: contractInstance && contractInstance.EarlyTerminationFeeOverrideReason ? contractInstance.EarlyTerminationFeeOverrideReason: null
            }) : EMPTY_OBJECT;
        }
        return EMPTY_OBJECT;
    }
);

export const DisconnectAdhocQuoteItemsSelector = createSelector(
    [QuoteItems],
    (quoteItems) => {
        if (quoteItems.length) {
            const adhocsQuoteItems = quoteItems.filter((quoteItem) => {
                return quoteItem.Product.ProductClassification === PRODUCT_CLASSIFICATIONS.AD_HOC;
            });

            return adhocsQuoteItems.map((adhocItem) => {
                return {
                    productName: adhocItem.Name,
                    amount: adhocItem.Amount,
                    currency: adhocItem.PricingPlan.Currency,
                    quantity: adhocItem.Quantity,
                    connectedDate: adhocItem.ConnectedDate
                };
            });
        } else {
            return EMPTY_ARRAY;
        }
    }
);

export const HasDisconnectAdhocsSelector = createSelector(
    [DisconnectAdhocQuoteItemsSelector],
    (disconnectAdhocQuoteItems) => {
        return !!disconnectAdhocQuoteItems.length;
    }
);

// TODO: A Pricing Plan can have more than 1 BulkChargeTypeCode, this is to be resolved in PD-665742
const getBulkChargeType = (pricingPlan) => {
    const bulkableBris = [...pricingPlan.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances, ...pricingPlan.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances];

    const bulkBris = bulkableBris.filter((bri) => {
        return bri.BulkChargeTypeCode;
    });

    return bulkBris && bulkBris.length ? uniqBy(Math.abs, pluck('BulkChargeTypeCode', bulkBris)) : EMPTY_ARRAY;
};

export const OfferGroupedQuoteItems = createSelector(
    [QuoteItems, OfferingMetadataPricingPlansSelector, IsDbss],
    (quoteItems, pricingPlanMetadata, isDbss) => {
        const offerQuoteItems = quoteItems.filter((quoteItem) => {
            return quoteItem.Product.ProductClassification !== PRODUCT_CLASSIFICATIONS.AD_HOC && quoteItem.Product.ProductClassification !== PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE;
        });

        if (offerQuoteItems.length) {
            const offerGroupedItems = {};

            offerQuoteItems.forEach((item) => {
                let bulkChargeTypeCodes = [];

                if (isDbss) {
                    const metadata = pricingPlanMetadata[item.PricingPlan.Id];
                    if (metadata) {
                        bulkChargeTypeCodes = getBulkChargeType(pricingPlanMetadata[item.PricingPlan.Id]);
                    }
                }

                if (!offerGroupedItems[item.OfferingInstanceId]) {
                    offerGroupedItems[item.OfferingInstanceId] = {
                        items: [],
                        orderedOfferingName: item.OrderedOfferingName
                    };
                }

                offerGroupedItems[item.OfferingInstanceId].items.push(Object.assign({}, item, {
                    bulk: !!bulkChargeTypeCodes.length,
                    bulkChargeTypeCodes
                }));
            });
            return offerGroupedItems;
        } else {
            return EMPTY_OBJECT;
        }
    }
);

export const DisconnectOfferingServiceIdentifiersSelector = createImmutableSelector(
    [RouteParams, AccountOfferingsSelector],
    (routeParams, accountOfferings) => {
        const disconnetingOffer =  accountOfferings.find((offer) => {
            return routeParams.offeringInstanceId === offer.OfferingInstanceId;
        });

        return disconnetingOffer ? disconnetingOffer.serviceIdentifiers : EMPTY_ARRAY;
    });

export const QuoteHasInvoiceItemsSelector = createImmutableSelector(
    [DisconnectQuoteSelector],
    (quote) => {
        return quote.HasInvoiceItems;
    }
);
