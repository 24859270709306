export const addDeviceFinancingToServiceThumbnail = (services, deviceFinancingInfo) => {
    if (deviceFinancingInfo && deviceFinancingInfo.length) {
        return services.map((service) => {
            const serviceDeviceFinance = deviceFinancingInfo.find((deviceFinance) => {
                return deviceFinance.AssociatedServiceIdentifier
                    && deviceFinance.AssociatedServiceIdentifier.Value === (service.ServiceIdentifier.Value || service.ServiceIdentifier);
            });
            if (serviceDeviceFinance && serviceDeviceFinance.FinanceBalance > 0) {
                return service.set('deviceFinance', {
                    serviceIdentifier: serviceDeviceFinance.ServiceIdentifier.Value,
                    status: serviceDeviceFinance.Status
                });
            }
            return service;
        });
    }
    return services;
};
