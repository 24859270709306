import thunkHelper from 'invision-core/src/api/thunk.helper';
import {CurrentCustomerIdSelector} from '../selectors/customer.selectors';
import {SUBSCRIBER_ID_HEADER} from 'invision-core/src/api/constants';

export const UPDATE_USAGE_DETAILS_TYPE_NETWORK_FILTER = 'UPDATE_USAGE_DETAILS_TYPE_NETWORK_FILTER';
export const updateNetworkEventTypeFilter = (data) => {
    return {
        type: UPDATE_USAGE_DETAILS_TYPE_NETWORK_FILTER,
        payload: data
    };
};

export const UPDATE_USAGE_DETAILS_SUB_EVENT_FILTER = 'UPDATE_USAGE_DETAILS_SUB_EVENT_FILTER';
export const updateNetworkSubEventTypeFilter = (data) => {
    return {
        type: UPDATE_USAGE_DETAILS_SUB_EVENT_FILTER,
        payload: data
    };
};

export const UPDATE_USAGE_DETAILS_TYPE_DATE_FILTER = 'UPDATE_USAGE_DETAILS_TYPE_DATE_FILTER';
export const updateUsageDetailsDateFilter = (data) => {
    return {
        type: UPDATE_USAGE_DETAILS_TYPE_DATE_FILTER,
        payload: data
    };
};

export const UPDATE_USAGE_DETAILS_CURRENCY_CODE = 'UPDATE_USAGE_DETAILS_CURRENCY_CODE';
export const updateUsageDetailsCurrencyCode = (data) => {
    return {
        type: UPDATE_USAGE_DETAILS_CURRENCY_CODE,
        payload: data
    };
};

export const UPDATE_USAGE_DETAILS_TOTAL_AMOUNT = 'UPDATE_USAGE_DETAILS_TOTAL_AMOUNT';
export const updateUsageDetailsTotalAmount = (data) => {
    return {
        type: UPDATE_USAGE_DETAILS_TOTAL_AMOUNT,
        payload: data
    };
};

export const UPDATE_USAGE_DETAILS_SORT = 'UPDATE_USAGE_DETAILS_SORT';
export const updateUsageDetailsSort = (sortData) => {
    return {
        type: UPDATE_USAGE_DETAILS_SORT,
        payload: sortData
    };
};

export const UPDATE_USAGE_SELECTED_ENTITLEMENT_DETAILS = 'UPDATE_USAGE_SELECTED_ENTITLEMENT_DETAILS';
export const updateUsageDetailsSelectedEntitlementDetails = (entitlementDetails) => {
    return {
        type: UPDATE_USAGE_SELECTED_ENTITLEMENT_DETAILS,
        payload: entitlementDetails
    };
};

export const UPDATE_USAGE_DETAILS_PAGINATION_DATA = 'UPDATE_USAGE_DETAILS_PAGINATION_DATA';
export const updateUsageDetailsPaginationData = (paginationData) => {
    return {
        type: UPDATE_USAGE_DETAILS_PAGINATION_DATA,
        payload: paginationData
    };
};

export const UPDATE_USAGE_SEARCH_ENTITLEMENTS_DATEPICKER = 'UPDATE_USAGE_SEARCH_ENTITLEMENTS_DATEPICKER';
export const updateUsageDetailsSearchEntitlementsDatePicker = (data) => {
    return {
        type: UPDATE_USAGE_SEARCH_ENTITLEMENTS_DATEPICKER,
        payload: data
    };
};

export const UPDATE_USAGE_SEARCH_INCLUDE_EXPIRED = 'UPDATE_USAGE_SEARCH_INCLUDE_EXPIRED';
export const updateUsageDetailsSearchEntitlementsIncludeExpired = (data) => {
    return {
        type: UPDATE_USAGE_SEARCH_INCLUDE_EXPIRED,
        payload: data
    };
};

export const CLEAR_USAGE_DETAILS_RECOVERABLE_UI_STATE = 'CLEAR_USAGE_DETAILS_RECOVERABLE_UI_STATE';
export const clearUsageDetailsRecoverableUIState = () => {
    return {
        type: CLEAR_USAGE_DETAILS_RECOVERABLE_UI_STATE,
        payload: null
    };
};

export const RESET_SEARCH_ENTITLEMENTS_FILTERS = 'RESET_SEARCH_ENTITLEMENTS_FILTERS';
export const clearSearchEntitlementFiltersRecoverableUIState = () => {
    return {
        type: RESET_SEARCH_ENTITLEMENTS_FILTERS,
        payload: null
    };
};

export const SET_SELECTED_CALL_CATEGORIES = 'SET_SELECTED_CALL_CATEGORIES';
export const  setSelectedCallCategories  = (callCategories) => {
    return {
        type: SET_SELECTED_CALL_CATEGORIES,
        payload: callCategories
    };
};

export const SEARCH_USAGE_DETAILS_ENTITLEMENT_NAME = 'SEARCH_USAGE_DETAILS_ENTITLEMENT_NAME';
export const searchUsageDetailsEntitlementName = (entitlementName) => {
    return {
        type: SEARCH_USAGE_DETAILS_ENTITLEMENT_NAME,
        payload: entitlementName
    };
};

export const RETRIEVE_USAGE_DETAILS_GROUPED_ENTITLEMENTS = {
    BEGIN: 'RETRIEVE_USAGE_DETAILS_GROUPED_ENTITLEMENTS_BEGIN',
    SUCCESS: 'RETRIEVE_USAGE_DETAILS_GROUPED_ENTITLEMENTS_SUCCESS',
    FAILURE: 'RETRIEVE_USAGE_DETAILS_GROUPED_ENTITLEMENTS_FAILURE'
};
export const retrieveUsageDetailsGroupedEntitlements = (groupCode, serviceIdentifier) => {
    return (dispatch, getState) => {
        const state = getState();
        const customerId = CurrentCustomerIdSelector(state);
        return thunkHelper(dispatch, Object.values(RETRIEVE_USAGE_DETAILS_GROUPED_ENTITLEMENTS), {
            method: 'post',
            url: 'SubscriberManagement/RetrieveEntitlementBalances',
            headers: {
                [SUBSCRIBER_ID_HEADER]: customerId
            },
            data: {
                GroupCode : groupCode,
                ServiceIdentifier: serviceIdentifier || null,
                EntitlementPagination: {
                    PageSize: 1000
                }
            }
        });
    };
};

export const RETRIEVE_USAGE_DETAILS_SERVICE_ENTITLEMENTS = {
    BEGIN: 'RETRIEVE_USAGE_DETAILS_SERVICE_ENTITLEMENTS_BEGIN',
    SUCCESS: 'RETRIEVE_USAGE_DETAILS_SERVICE_ENTITLEMENTS_SUCCESS',
    FAILURE: 'RETRIEVE_USAGE_DETAILS_SERVICE_ENTITLEMENTS_FAILURE'
};

const retrieveUsageDetailsServiceEntitlementsPromise = (dispatch, customerId, serviceIdentifier, includeAll, startDate, endDate) => {
    return thunkHelper(dispatch, Object.values(RETRIEVE_USAGE_DETAILS_SERVICE_ENTITLEMENTS), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveEntitlementBalances',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            EndDate: endDate,
            EntitlementPagination: {
                PageNumber: 1,
                PageSize: 25
            },
            GroupEntitlement: true,
            IncludeAll: includeAll,
            MaxNumberOfServiceEntitlements: 1000,
            MaxNumberOfSharedEntitlements: 0,
            ServiceIdentifier: {
                Value: serviceIdentifier
            },
            StartDate: startDate
        }
    });
};

export const retrieveUsageDetailsServiceEntitlements = (customerId, serviceIdentifier, includeAll, startDate, endDate) => {
    return (dispatch) => {
        return retrieveUsageDetailsServiceEntitlementsPromise(dispatch, customerId, serviceIdentifier, includeAll, startDate, endDate);
    };
};

export const RETRIEVE_USAGE_DETAILS_SHARED_ENTITLEMENTS = {
    BEGIN: 'RETRIEVE_USAGE_DETAILS_SHARED_ENTITLEMENTS_BEGIN',
    SUCCESS: 'RETRIEVE_USAGE_DETAILS_SHARED_ENTITLEMENTS_SUCCESS',
    FAILURE: 'RETRIEVE_USAGE_DETAILS_SHARED_ENTITLEMENTS_FAILURE'
};

const retrieveUsageDetailsSharedEntitlementsPromise = (dispatch, customerId, serviceIdentifier) => {
    return thunkHelper(dispatch, Object.values(RETRIEVE_USAGE_DETAILS_SHARED_ENTITLEMENTS), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveEntitlementBalances',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            EntitlementPagination: {
                PageNumber: 1,
                PageSize: 25
            },
            GroupEntitlement: false,
            MaxNumberOfServiceEntitlements: 0,
            MaxNumberOfSharedEntitlements: 1000,
            ServiceIdentifier: {
                Value: serviceIdentifier
            }
        }
    });
};

export const retrieveUsageDetailsSharedEntitlements = (customerId, serviceIdentifier) => {
    return (dispatch) => {
        return retrieveUsageDetailsSharedEntitlementsPromise(dispatch, customerId, serviceIdentifier);
    };
};