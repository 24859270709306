import {pathOr} from 'ramda';
import {createSelector} from 'reselect';
import {
    i18n,
    MetadataConstants,
    MetadataSelectors
} from 'invision-core';
import {
    RouteParams,
    SelectedCustomerSelector
} from './customer.selectors';
import {
    PRODUCT_AVAILABLE_DELIVERY_CAPABILITY_ACTIONS,
    PRODUCT_CLASSIFICATIONS
} from 'invision-core/src/constants/product.constants';
import LocaleKeys from '../../locales/keys';
import {ConvergentBillerAccountOfferingsSelector} from './customer.convergent.biller.selectors';

export const CustomerProductDetailSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.productDetail ? selectedCustomer.productDetail.data : null;
    }
);

export const CustomerProductOrderDetailSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.productDetail ? selectedCustomer.productDetail.orderData : null;
    }
);

export const getPoliciesWithFriendlyNames = (productDetail, deliveryCapabilities) => {
    if (productDetail.ProductContext && productDetail.ProductContext.PurchasePolicies) {
        return productDetail.ProductContext.PurchasePolicies.map((policy) => {
            if (policy.ActionCode) {
                const deliveryCapability = deliveryCapabilities.items.find((capability) => {
                    return policy.ActionCode && (policy.ActionCode.toString() === capability.Value);
                });

                return policy.set('FriendlyName', deliveryCapability ? deliveryCapability.Name : '');
            } else {
                return policy;
            }
        });
    } else {
        return [];
    }
};

export const CurrentProductDetailSelector = createSelector(
    [CustomerProductDetailSelector,
        MetadataSelectors.codes.MetadataCodeSelector(
            MetadataConstants.codes.ProductLineOfBusinessDeliveryCapabilityConfigurationAction)
    ],
    (productDetail, productLineOfBusinessDeliveryCapabilityConfigurationAction) => {
        if (productDetail && productDetail.LockerItem) {
            if (productLineOfBusinessDeliveryCapabilityConfigurationAction) {
                return productDetail.LockerItem.setIn(['ProductContext', 'PurchasePolicies'],
                    getPoliciesWithFriendlyNames(productDetail.LockerItem, productLineOfBusinessDeliveryCapabilityConfigurationAction));
            } else {
                return productDetail.LockerItem;
            }
        } else {
            return null;
        }
    }
);

export const CanViewContentActivitySelector = createSelector(
    [CurrentProductDetailSelector],
    (productDetail) => {
        return !pathOr(0, ['ProductContext', 'AvailableDeliveryCapabilityActions', 'length'], productDetail) ?
            false :
            productDetail.ProductContext.AvailableDeliveryCapabilityActions.some((action) => {
                return action.Code === PRODUCT_AVAILABLE_DELIVERY_CAPABILITY_ACTIONS.DOWNLOAD ||
                    action.Code === PRODUCT_AVAILABLE_DELIVERY_CAPABILITY_ACTIONS.PLAYBACK;
            });
    }
);

export const CurrentProductDetailsClassificationSelector = createSelector(
    [CurrentProductDetailSelector],
    (productDetails) => {
        const classification = pathOr(null, ['Product', 'ProductClassification'], productDetails);
        if (classification) {
            switch (classification) {
                case PRODUCT_CLASSIFICATIONS.PRODUCT:
                    return i18n.translate(LocaleKeys.PRODUCT);
                case PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE:
                    return i18n.translate(LocaleKeys.SERVICE_FEATURE);
                case PRODUCT_CLASSIFICATIONS.AD_HOC:
                    return i18n.translate(LocaleKeys.AD_HOC);
                default:
                    return null;
            }
        }
        return null;
    }
);

export const IsFetchingProductDetailDataSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.productDetail ? selectedCustomer.productDetail.isFetchingData : null;
    }
);

export const CurrentLockerItemIdSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.lockerItemId;
    }
);

export const CurrentLockerItemOrderIdSelector = createSelector(
    [CurrentProductDetailSelector],
    (currentProduct) => {
        return currentProduct ? currentProduct.OrderId : null;
    }
);


export const CurrentProductOrderDetailSelector = createSelector(
    [CustomerProductOrderDetailSelector, CurrentLockerItemIdSelector],
    (orderDetail, lockerItemId) => {
        if (orderDetail && orderDetail.Items && orderDetail.Items.length) {
            return orderDetail.Items.find((item) => {
                return item.LockerItemId === lockerItemId;
            });
        }
        return {};
    }
);

export const CurrentOfferInContextSelector = createSelector(
    [ConvergentBillerAccountOfferingsSelector, CurrentProductDetailSelector],
    (offerings, productDetail) => {
        let offerInContext = null;
        const currentProduct = pathOr(null, ['Product'], productDetail);
        const currentPricingPlanId = pathOr(null, ['PricingPlan', 'Id'], productDetail);
        if (offerings && offerings.length && currentProduct && currentPricingPlanId) {
            offerings.forEach((offering) => {
                if (currentProduct.ProductClassification === PRODUCT_CLASSIFICATIONS.PRODUCT) {
                    offerInContext = offering.Options.find((option) => {
                        return option.PricingPlanId === currentPricingPlanId;
                    });
                }
                if (currentProduct.ProductClassification === PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE) {
                    offerInContext = offering.ServiceFeatures.find((feature) => {
                        return feature.PricingPlanId === currentPricingPlanId;
                    });
                }
            });
        }
        return offerInContext;
    });

export const CurrentProductServiceIdSelector = createSelector(
    [ConvergentBillerAccountOfferingsSelector, CurrentLockerItemIdSelector],
    (offerings, lockerItemId) => {
        let orderedServiceId = null;
        let subscriberProductId = null;
        if (offerings && offerings.length) {
            offerings.forEach((offering) => {
                if (lockerItemId) {
                    subscriberProductId = offering.ServiceFeatures.find((feature) => {
                        return feature.SubscriberProductId === lockerItemId;
                    });
                }
                offering.Options.forEach((option) => {
                    if (subscriberProductId && option.ServiceAttributeValues && option.ServiceAttributeValues.length) {
                        orderedServiceId = option.ServiceAttributeValues.find((attribute) => {
                            return subscriberProductId.ServiceIdentifierValue === attribute.Value;
                        });
                    }
                });
            });
        }
        return orderedServiceId && orderedServiceId.ServiceId ? orderedServiceId.ServiceId : null;
    }
);
