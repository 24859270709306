import {values} from 'ramda';
import {ApiConstants,
    ThunkHelper} from 'invision-core';

export const RETRIEVE_ADDRESSES_CONSTANTS = {
    BEGIN: 'RETRIEVE_ADDRESSES_BEGIN',
    SUCCESS: 'RETRIEVE_ADDRESSES_SUCCESS',
    FAILURE: 'RETRIEVE_ADDRESSES_FAILURE'
};

const retrieveCustomerAddressesPromise = (dispatch, customerId, includeRemoved) => {
    return ThunkHelper(dispatch, values(RETRIEVE_ADDRESSES_CONSTANTS), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveAddresses',
        data: {
            IncludeRemoved: includeRemoved
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId
    });
};
export const retrieveCustomerAddresses = (customerId, includeRemoved) => {
    return (dispatch) => {
        return retrieveCustomerAddressesPromise(dispatch, customerId, includeRemoved);
    };
};

export const REMOVE_ADDRESS = {
    BEGIN: 'REMOVE_ADDRESS_BEGIN',
    SUCCESS: 'REMOVE_ADDRESS_SUCCESS',
    FAILURE: 'REMOVE_ADDRESS_FAILURE'
};

const removeCustomerAddressPromise = (dispatch, customerId, addressId) => {
    return ThunkHelper(dispatch, values(REMOVE_ADDRESS), {
        method: 'post',
        url: 'SubscriberManagement/RemoveAddress',
        data: {
            Id: addressId
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};
export const removeCustomerAddress = (customerId, addressId) => {
    return (dispatch) => {
        return removeCustomerAddressPromise(dispatch, customerId, addressId);
    };
};

export const CREATE_ADDRESS_CONSTANTS = {
    BEGIN: 'CREATE_ADDRESS_BEGIN',
    SUCCESS: 'CREATE_ADDRESS_SUCCESS',
    FAILURE: 'CREATE_ADDRESS_FAILURE'
};

const createAddressPromise = (dispatch, customerId, address, textScrubberOverride, preventOverrideFlag) => {
    return ThunkHelper(dispatch, values(CREATE_ADDRESS_CONSTANTS), {
        method: 'post',
        url: 'SubscriberManagement/CreateAddress',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            Address: prepareAddress(address, preventOverrideFlag),
            TextScrubberOverride: textScrubberOverride
        }
    });
};
export const createAddress = (customerId, address, textScrubberOverride, preventOverrideFlag) => {
    return (dispatch) => {
        return createAddressPromise(dispatch, customerId, address, textScrubberOverride, preventOverrideFlag);
    };
};

export const UPDATE_ADDRESS_CONSTANTS = {
    BEGIN: 'UPDATE_ADDRESS_BEGIN',
    SUCCESS: 'UPDATE_ADDRESS_SUCCESS',
    FAILURE: 'UPDATE_ADDRESS_FAILURE'
};

const updateAddressPromise = (dispatch, customerId, address, textScrubberOverride, preventOverrideFlag) => {
    return ThunkHelper(dispatch, values(UPDATE_ADDRESS_CONSTANTS), {
        method: 'post',
        url: 'SubscriberManagement/UpdateAddress',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            Address: prepareAddress(address, preventOverrideFlag),
            TextScrubberOverride: textScrubberOverride
        }
    });
};

export const updateAddress = (customerId, address, textScrubberOverride, preventOverrideFlag) => {
    return (dispatch) => {
        return updateAddressPromise(dispatch, customerId, address, textScrubberOverride, preventOverrideFlag);
    };
};

export const SET_ADDRESS_DATA = 'SET_ADDRESS_DATA';
export const setAddressData = (address, textScrubberOverride) => {
    return {
        type: SET_ADDRESS_DATA,
        payload: {
            address,
            textScrubberOverride
        }
    };
};

export const UPDATE_OVERRIDE_FLAG = 'UPDATE_OVERRIDE_FLAG';
export const updateOverrideFlag = (overrideFlag) => {
    return {
        type: UPDATE_OVERRIDE_FLAG,
        payload: overrideFlag
    };
};

export const UPDATE_FORM_ADDRESS = 'UPDATE_FORM_ADDRESS';
export const updateFormAddress = (payload) => {
    return {
        type: UPDATE_FORM_ADDRESS,
        payload: payload
    };
};

// prepareAddress encapsulates discrepencies w/ the CSG Ascendon Web Services
// API (service layer) and the presentational layer
export const prepareAddress = (address, preventOverrideFlag) => {
    // tie the Name property to the AddressLine1 property
    const proventOverrideObj = {};
    if (preventOverrideFlag) {
        Object.assign(proventOverrideObj, {
            PreventValidationOverride: preventOverrideFlag
        });
    }
    return Object.assign({}, address, {
        Name: address.Name || address.LineOne
    }, proventOverrideObj);
};

export const SET_INCLUDED_REMOVED_FILTER = 'SET_ADDRESSES_INCLUDED_REMOVED_FILTER';
export const setIncludedRemovedFilter = (includedFilter) => {
    return {
        type: SET_INCLUDED_REMOVED_FILTER,
        payload: includedFilter
    };
};

export const EMPTY_ADDRESSES_LIST = 'EMPTY_ADDRESSES_LIST';
export const emptyAddressesList = () => {
    return {
        type: EMPTY_ADDRESSES_LIST
    };
};

export const REMOVE_ADDRESSES_FILTER = 'REMOVE_ADDRESSES_FILTER';
export const removeAddressesFilter = () => {
    return {
        type: REMOVE_ADDRESSES_FILTER
    };
};

export const SET_SELECTED_ITEM_ID = 'SET_SELECTED_ITEM_ID';
export const setSelectedItemId = (includedId) => {
    return {
        type: SET_SELECTED_ITEM_ID,
        payload: includedId
    };
};
