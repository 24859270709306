import Immutable from 'seamless-immutable';
import {
    GIFT_CARD_SEARCH,
    SET_GIFT_CARD_SEARCH_PAGE
} from './actions/search.giftcard.actions';

export const INITIAL_STATE = Immutable({
    data: [],
    pageCount: null,
    pageNumber: null,
    recordCount: null
});

export default function giftCardSearchReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case SET_GIFT_CARD_SEARCH_PAGE:
            return state.set('pageNumber', payload || 1);
        case GIFT_CARD_SEARCH.BEGIN:
            return state.set('data', [])
                .set('pageCount', 0)
                .set('pageNumber', 0)
                .set('recordCount', -1);
        case GIFT_CARD_SEARCH.SUCCESS:
            return state.set('data', payload.Redemptions || [])
                .set('pageCount', payload.PageCount || 0)       /* if no results returned, then these fields will */
                .set('recordCount', payload.RecordCount || 0);  /* not be in the response, so I need to supply */
            /* default values. */
        default:
            return state;
    }
}
