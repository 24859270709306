import {values} from 'ramda';
import {
    ApiConstants,
    CoreLocaleKeys,
    i18n,
    InjectorHelper,
    ThunkHelper
} from 'invision-core';

import {
    NOTIFICATION_TIME_LENGTH
} from '../../customercare.constants';

export const ADD_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILE = 'ADD_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILE';
export const addImportBulkServiceAttributesJobPendingFile = (pendingFile) => {
    return {
        payload: pendingFile,
        type: ADD_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILE
    };
};

export const CLEAR_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILES = 'CLEAR_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILES';
export const clearImportBulkServiceAttributesJobPendingFiles = () => {
    return {
        type: CLEAR_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILES
    };
};

export const CLEAR_IMPORT_BULK_SERVICE_ATTRIBUTES_JOBS_PENDING_UPDATED_STATUS = 'CLEAR_IMPORT_BULK_SERVICE_ATTRIBUTES_JOBS_PENDING_UPDATED_STATUS';
export const clearImportBulkServiceAttributesJobsPendingUpdatedStatus = () => {
    return {
        type: CLEAR_IMPORT_BULK_SERVICE_ATTRIBUTES_JOBS_PENDING_UPDATED_STATUS
    };
};

export const REMOVE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILE = 'REMOVE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILE';
export const removeImportBulkServiceAttributesJobPendingFile = (pendingFileName) => {
    return {
        payload: pendingFileName,
        type: REMOVE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILE
    };
};

export const RETRIEVE_AWS_S3_UPLOAD_CONFIGURATION = {
    BEGIN: 'RETRIEVE_AWS_S3_UPLOAD_CONFIGURATION_BEGIN',
    SUCCESS: 'RETRIEVE_AWS_S3_UPLOAD_CONFIGURATION_SUCCESS',
    FAILURE: 'RETRIEVE_AWS_S3_UPLOAD_CONFIGURATION_FAILURE'
};
export const retrieveAwsS3UploadConfigurationPromise = (dispatch, fileName, uploadFileType, orderId, pricingPlanId, customerId) => {
    const payload = {
        FileName: fileName,
        FileType: uploadFileType,
        OrderId: orderId,
        PricingPlanId: pricingPlanId
    };

    return ThunkHelper(dispatch, values(RETRIEVE_AWS_S3_UPLOAD_CONFIGURATION), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveBulkOrderUploadUrl',
        data: payload,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
    }, payload);
};
export const retrieveAwsS3UploadConfiguration = (fileName, uploadFileType, orderId, pricingPlanId, customerId) => {
    return (dispatch) => {
        return retrieveAwsS3UploadConfigurationPromise(dispatch, fileName, uploadFileType, orderId, pricingPlanId, customerId)
            .catch(() => {
                InjectorHelper.getUiNotificationService().error(i18n.translate(CoreLocaleKeys.UPLOAD.UNABLE_TO_LOAD_CONFIG_ERROR.MESSAGE), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            });
    };
};

export const RETRIEVE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_DETAILS = {
    BEGIN: 'RETRIEVE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_DETAILS_BEGIN',
    SUCCESS: 'RETRIEVE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_DETAILS_SUCCESS',
    FAILURE: 'RETRIEVE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_DETAILS_FAILURE'
};
export const retrieveImportBulkServiceAttributesJobDetailsPromise = (dispatch, orderDetails, fileDetails) => {
    const payload = {
        BulkExtractJobId: orderDetails.jobId,
        FileLocation: fileDetails.path || null,
        OrderId: orderDetails.orderId,
        PricingPlanId: orderDetails.pricingPlanId
    };

    return ThunkHelper(dispatch, values(RETRIEVE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_DETAILS), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveBulkFileExtractDetail',
        data: payload,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: orderDetails.customerId
        },
    }, payload);
};
export const retrieveImportBulkServiceAttributesJobDetails = (orderDetails, fileDetails = {}) => {
    return (dispatch) => {
        return retrieveImportBulkServiceAttributesJobDetailsPromise(dispatch, orderDetails, fileDetails)
            .catch((error) => {
                InjectorHelper.getUiNotificationService().error(error.translatedMessage, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            });
    };
};

export const SEND_BULK_SERVICE_ATTRIBUTES_UPLOAD_NOTIFICATION = {
    BEGIN: 'SEND_BULK_SERVICE_ATTRIBUTES_UPLOAD_NOTIFICATION_BEGIN',
    SUCCESS: 'SEND_BULK_SERVICE_ATTRIBUTES_UPLOAD_NOTIFICATION_SUCCESS',
    FAILURE: 'SEND_BULK_SERVICE_ATTRIBUTES_UPLOAD_NOTIFICATION_FAILURE'
};
export const sendBulkServiceAttributesUploadNotificationPromise = (dispatch, orderDetails, fileDetails) => {
    const payload = {
        BulkExtractJobId: orderDetails.jobId,
        FileLocations: [fileDetails.path],
        OfferingId: orderDetails.offeringId,
        OrderId: orderDetails.orderId,
        PricingPlanId: orderDetails.pricingPlanId
    };

    return ThunkHelper(dispatch, values(SEND_BULK_SERVICE_ATTRIBUTES_UPLOAD_NOTIFICATION), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveBulkServicesFileUploadConfirmation',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: orderDetails.subscriberId
        },
        data: payload
    }, payload);
};

export const sendBulkServiceAttributesUploadNotification = (orderDetails, fileDetails) => {
    return (dispatch) => {
        return sendBulkServiceAttributesUploadNotificationPromise(dispatch, orderDetails, fileDetails)
            .catch((error) => {
                InjectorHelper.getUiNotificationService().error(error.translatedMessage, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });

                // NOTE: Pass-through error so it can also be displayed in-line with the corresponding file
                return Promise.reject(error);
            });
    };
};

export const SET_CURRENT_TAB = 'SET_ORDER_DETAILS_CURRENT_TAB';
export const setCurrentTab = (tab) => {
    return {
        type: SET_CURRENT_TAB,
        payload: tab
    };
};

export const SET_IS_IMPORTING_BULK_SERVICE_ATTRIBUTES = 'SET_IS_IMPORTING_BULK_SERVICE_ATTRIBUTES';
export const setIsImportingBulkServiceAttributes = (isImporting = false) => {
    return {
        payload: isImporting,
        type: SET_IS_IMPORTING_BULK_SERVICE_ATTRIBUTES
    };
};

export const UPDATE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILE_STATUS = 'UPDATE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILE_STATUS';
export const updateImportBulkServiceAttributesJobPendingFileStatus = (pendingFileName, status) => {
    return {
        payload: {
            name: pendingFileName,
            status: status
        },
        type: UPDATE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILE_STATUS
    };
};

export const UPDATE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOBS_PENDING_UPDATED_STATUS = 'UPDATE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOBS_PENDING_UPDATED_STATUS';
export const updateImportBulkServiceAttributesJobsPendingUpdatedStatus = (pricingPlanIds) => {
    return {
        payload: pricingPlanIds,
        type: UPDATE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOBS_PENDING_UPDATED_STATUS
    };
};

export const RETRIEVE_BULK_SERVICES_TEMPLATE_CONSTANTS = {
    BEGIN: 'RETRIEVE_BULK_SERVICES_TEMPLATE_BEGIN',
    SUCCESS: 'RETRIEVE_BULK_SERVICES_TEMPLATE_SUCCESS',
    FAILURE: 'RETRIEVE_BULK_SERVICES_TEMPLATE_FAILURE'
};
export const RETRIEVE_BULK_SERVICES_TEMPLATE_EVENTS = [
    RETRIEVE_BULK_SERVICES_TEMPLATE_CONSTANTS.BEGIN,
    RETRIEVE_BULK_SERVICES_TEMPLATE_CONSTANTS.SUCCESS,
    RETRIEVE_BULK_SERVICES_TEMPLATE_CONSTANTS.FAILURE
];
export const retrieveBulkServicesTemplatePromise = (dispatch, orderDetails) => {
    return ThunkHelper(dispatch, RETRIEVE_BULK_SERVICES_TEMPLATE_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveBulkServicesTemplate',
        data: {
            OrderId: orderDetails.orderId,
            PricingPlanId: orderDetails.pricingPlanId
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: orderDetails.customerId
        },
    }, {
        OrderId: orderDetails.orderId,
        PricingPlanId: orderDetails.pricingPlanId
    });
};

export const retrieveBulkServicesTemplate = (orderDetails) => {
    return (dispatch) => {
        return retrieveBulkServicesTemplatePromise(dispatch, orderDetails);
    };
};

export const SET_CHANGE_FDO_EXECUTION_OPTIONS = 'SET_CHANGE_FDO_EXECUTION_OPTIONS';
export const setExecutionOptions = (executionOptions) => {
    return {
        type: SET_CHANGE_FDO_EXECUTION_OPTIONS,
        payload: executionOptions
    };
};

export const UPDATE_FDO_EXECUTION_DATE_CONSTANTS = {
    BEGIN: 'UPDATE_FDO_EXECUTION_DATE_BEGIN',
    SUCCESS: 'UPDATE_FDO_EXECUTION_DATE_SUCCESS',
    FAILURE: 'UPDATE_FDO_EXECUTION_DATE_FAILURE'
};
export const UPDATE_FDO_EXECUTION_DATE_EVENTS = [
    UPDATE_FDO_EXECUTION_DATE_CONSTANTS.BEGIN,
    UPDATE_FDO_EXECUTION_DATE_CONSTANTS.SUCCESS,
    UPDATE_FDO_EXECUTION_DATE_CONSTANTS.FAILURE
];


export const updateFdoExecutionDatePromise = (dispatch, executionOptions ={}, orderId) => {
    return ThunkHelper(dispatch, UPDATE_FDO_EXECUTION_DATE_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/UpdateFutureDatedOrderExecutionDate',
        data: {
            BillingEffectiveDate: executionOptions.billingEffectiveDate || undefined,
            BillingEffectiveDateIntention: executionOptions.billingEffectiveIntention || undefined,
            ExecutionDate: executionOptions.specifiedDate || undefined,
            ExecutionDateIntention: executionOptions.effective || undefined,
            OrderId: orderId
        }
    });
};

export const updateFdoExecutionDate = (executionDate, orderId) => {
    return (dispatch) => {
        return updateFdoExecutionDatePromise(dispatch, executionDate, orderId);
    };
};

export const ORDER_DETAILS_RETRIEVE_ORDER_CONTEXT = {
    BEGIN: 'ORDER_DETAILS_RETRIEVE_ORDER_CONTEXT_BEGIN',
    SUCCESS: 'ORDER_DETAILS_RETRIEVE_ORDER_CONTEXT_SUCCESS',
    FAILURE: 'ORDER_DETAILS_RETRIEVE_ORDER_CONTEXT_FAILURE'
};

const ORDER_DETAILS_RETRIEVE_ORDER_CONTEXT_TYPES = [
    ORDER_DETAILS_RETRIEVE_ORDER_CONTEXT.BEGIN,
    ORDER_DETAILS_RETRIEVE_ORDER_CONTEXT.SUCCESS,
    ORDER_DETAILS_RETRIEVE_ORDER_CONTEXT.FAILURE
];

export const retrieveOrderContext = (orderDetails) => {
    return (dispatch) => {
        return retrieveOrderContextPromise(dispatch, orderDetails);
    };
};


export const retrieveOrderContextPromise = (dispatch, orderDetails = {}) => {
    const params = {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOrderContext',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: orderDetails.SubscriberId
        },
        data: {
            OrderId: orderDetails.Id || undefined
        }
    };

    return ThunkHelper(dispatch, ORDER_DETAILS_RETRIEVE_ORDER_CONTEXT_TYPES, params);
};
