import {stateGo} from 'redux-ui-router';
import isNil from 'ramda/src/isNil';
import values from 'ramda/src/values';
import ApiConstants from 'invision-core/src/api/constants';
import {
    MetadataThunkHelper,
    ThunkHelper
} from 'invision-core';
import {CurrentCustomerSelector} from 'invision-core/src/components/customer/customer.selectors';
import {setChangeImmediately} from './products.wizard.actions';
import {PRODUCT_ORDER_WIZARD_MODE} from '../constants/products.wizard.constants';
import {
    buildSubmitOrderRequestData,
    buildSubmitGiftOrderRequestData,
    buildSubmitRestoreOrderRequestData
} from '../helpers/products.order.actions.helpers';
import {
    getTransformedInstancePropertiesForItem,
    transformCartItemInstanceProperties
} from '../helpers/instance.property.helper';
import {SUBSCRIPTION_RESTORE_ROUTE} from '../../components/customer/subscriptions/subscriptions.config';
import {BILLING_EFFECTIVE_DATE_INTENTION} from '../constants/wizard.constants';

const MAX_PRODUCT_CONTEXT_SEARCH_LENGTH = 50;

export const CALCULATE_PRODUCT_ORDER_QUOTE = {
    BEGIN: 'CALCULATE_PRODUCT_ORDER_QUOTE_BEGIN',
    SUCCESS: 'CALCULATE_PRODUCT_ORDER_QUOTE_SUCCESS',
    FAILURE: 'CALCULATE_PRODUCT_ORDER_QUOTE_FAILURE'
};

const CALCULATE_PRODUCT_ORDER_QUOTE_TYPES = [
    CALCULATE_PRODUCT_ORDER_QUOTE.BEGIN,
    CALCULATE_PRODUCT_ORDER_QUOTE.SUCCESS,
    CALCULATE_PRODUCT_ORDER_QUOTE.FAILURE
];

export const CALCULATE_RENEW_ORDER_QUOTE = {
    BEGIN: 'CALCULATE_RENEW_ORDER_QUOTE_BEGIN',
    SUCCESS: 'CALCULATE_RENEW_ORDER_QUOTE_SUCCESS',
    FAILURE: 'CALCULATE_RENEW_ORDER_QUOTE_FAILURE'
};

const CALCULATE_RENEW_ORDER_QUOTE_TYPES = [
    CALCULATE_RENEW_ORDER_QUOTE.BEGIN,
    CALCULATE_RENEW_ORDER_QUOTE.SUCCESS,
    CALCULATE_RENEW_ORDER_QUOTE.FAILURE
];

export const SEARCH_AVAILABLE_PRODUCTS = {
    BEGIN: 'SEARCH_AVAILABLE_PRODUCTS_BEGIN',
    SUCCESS: 'SEARCH_AVAILABLE_PRODUCTS_SUCCESS',
    FAILURE: 'SEARCH_AVAILABLE_PRODUCTS_FAILURE'
};
const SEARCH_AVAILABLE_PRODUCTS_TYPES = [
    SEARCH_AVAILABLE_PRODUCTS.BEGIN,
    SEARCH_AVAILABLE_PRODUCTS.SUCCESS,
    SEARCH_AVAILABLE_PRODUCTS.FAILURE
];

export const RETRIEVE_PRODUCTS_CONTEXT = {
    BEGIN: 'RETRIEVE_PRODUCTS_CONTEXT_BEGIN',
    SUCCESS: 'RETRIEVE_PRODUCTS_CONTEXT_SUCCESS',
    FAILURE: 'RETRIEVE_PRODUCTS_CONTEXT_FAILURE'
};

const RETRIEVE_PRODUCTS_CONTEXT_TYPES = [
    RETRIEVE_PRODUCTS_CONTEXT.BEGIN,
    RETRIEVE_PRODUCTS_CONTEXT.SUCCESS,
    RETRIEVE_PRODUCTS_CONTEXT.FAILURE
];

export const RETRIEVE_PRODUCT_CONTEXT = {
    BEGIN: 'RETRIEVE_PRODUCT_CONTEXT_BEGIN',
    SUCCESS: 'RETRIEVE_PRODUCT_CONTEXT_SUCCESS',
    FAILURE: 'RETRIEVE_PRODUCT_CONTEXT_FAILURE'
};

const RETRIEVE_PRODUCT_CONTEXT_TYPES = [
    RETRIEVE_PRODUCT_CONTEXT.BEGIN,
    RETRIEVE_PRODUCT_CONTEXT.SUCCESS,
    RETRIEVE_PRODUCT_CONTEXT.FAILURE
];

export const LOAD_ADDITIONAL_EPISODES = {
    BEGIN: 'LOAD_ADDITIONAL_EPISODES_BEGIN',
    SUCCESS: 'LOAD_ADDITIONAL_EPISODES_SUCCESS',
    FAILURE: 'LOAD_ADDITIONAL_EPISODES_FAILURE'
};

const LOAD_ADDITIONAL_EPISODES_TYPES = [
    LOAD_ADDITIONAL_EPISODES.BEGIN,
    LOAD_ADDITIONAL_EPISODES.SUCCESS,
    LOAD_ADDITIONAL_EPISODES.FAILURE
];

export const RETRIEVE_PRODUCTS_WITH_INFO_CONSTANTS = {
    BEGIN: 'RETRIEVE_PRODUCTS_WITH_INFO_BEGIN',
    SUCCESS: 'RETRIEVE_PRODUCTS_WITH_INFO_SUCCESS',
    FAILURE: 'RETRIEVE_PRODUCTS_WITH_INFO_FAILURE'
};

export const RETRIEVE_PRODUCTS_CONTEXT_AND_METADATA_CONSTANTS = {
    BEGIN: 'RETRIEVE_PRODUCTS_CONTEXT_AND_METADATA_BEGIN',
    SUCCESS: 'RETRIEVE_PRODUCTS_CONTEXT_AND_METADATA_SUCCESS',
    FAILURE: 'RETRIEVE_PRODUCTS_CONTEXT_AND_METADATA_FAILURE'
};

export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const CLEAR_PRODUCT_ORDER_QUOTE = 'CLEAR_PRODUCT_ORDER_QUOTE';
export const SET_PRODUCT_ORDER_DISCRETIONARY_DISCOUNT = 'SET_PRODUCT_ORDER_DISCRETIONARY_DISCOUNT';
export const SET_PRODUCT_ORDER_COUPON_CODES = 'SET_PRODUCT_ORDER_COUPON_CODES';
export const SET_PREVIOUSLY_SELECTED_PAYMENT_INSTRUMENT_IDS = 'SET_PREVIOUSLY_SELECTED_PAYMENT_INSTRUMENT_IDS';
export const SET_SELECTED_PRODUCTS_SEARCH_OPTION = 'SET_SELECTED_PRODUCTS_SEARCH_OPTION';

export const CLEAR_SELECTED_OPTIONS = 'CLEAR_SELECTED_OPTIONS';
export const clearSelectedOptions = () => {
    return {
        type: CLEAR_SELECTED_OPTIONS,
    };
};

export const setProductOrderDiscretionaryDiscounts = (discretionaryDiscounts = []) => {
    return {
        type: SET_PRODUCT_ORDER_DISCRETIONARY_DISCOUNT,
        payload: discretionaryDiscounts
    };
};

export const setPreviouslySelectedPaymentInstrumentIds = (previouslySelectedPaymentInstrumentIds) => {
    return {
        type: SET_PREVIOUSLY_SELECTED_PAYMENT_INSTRUMENT_IDS,
        payload: previouslySelectedPaymentInstrumentIds
    };
};

export const setProductOrderCouponCodes = (couponCodes = []) => {
    return {
        type: SET_PRODUCT_ORDER_COUPON_CODES,
        payload: couponCodes
    };
};

export const CALCULATE_MODIFY_PRODUCT_ORDER_QUOTE = {
    BEGIN: 'CALCULATE_MODIFY_PRODUCT_ORDER_QUOTE_BEGIN',
    SUCCESS: 'CALCULATE_MODIFY_PRODUCT_ORDER_QUOTE_SUCCESS',
    FAILURE: 'CALCULATE_MODIFY_PRODUCT_ORDER_QUOTE_FAILURE'
};

const CALCULATE_MODIFY_PRODUCT_ORDER_QUOTE_TYPES = [
    CALCULATE_MODIFY_PRODUCT_ORDER_QUOTE.BEGIN,
    CALCULATE_MODIFY_PRODUCT_ORDER_QUOTE.SUCCESS,
    CALCULATE_MODIFY_PRODUCT_ORDER_QUOTE.FAILURE
];
export const SET_SHOPPING_CART_FROM_QUOTE_DATA = 'SET_SHOPPING_CART_FROM_QUOTE_DATA';
export const STORE_MODIFY_REQUEST_DATA = 'STORE_MODIFY_REQUEST_DATA';

function getModifyOrderRequestData(modificationObject,
    paymentInstrumentIds,
    paymentInstruments,
    recordPaymentInfo,
    shippingAddressId,
    shippingMethodId, shipToName, redemptionCodes, discretionaryDiscounts, discretionaryDiscountReasonCode) {
    // Common request data object for CalculateModifyOrderQuote and SubmitModifyOrder
    const requestData = Object.assign({}, modificationObject, {
        DiscretionaryDiscountReasonCode: discretionaryDiscountReasonCode,
        DiscretionaryDiscounts: discretionaryDiscounts,
        RedemptionCodes: redemptionCodes
    });

    if (recordPaymentInfo) {
        requestData.PaymentInstrumentIds = paymentInstrumentIds;
    } else {
        requestData.PaymentInstruments = paymentInstruments;
    }

    const parsedDiscretionaryDiscountReasonCode = isNil(discretionaryDiscountReasonCode) ? null : Number(discretionaryDiscountReasonCode);
    if (parsedDiscretionaryDiscountReasonCode) {
        requestData.DiscretionaryDiscountReasonCode = parsedDiscretionaryDiscountReasonCode;
    }
    if (shippingAddressId) {
        requestData.ShippingAddressId = shippingAddressId;
        requestData.ShippingMethodId = shippingMethodId;
        requestData.ShipToName = shipToName;
    }

    if (requestData.ReplaceItems && requestData.ReplaceItems.length) {
        requestData.ReplaceItems = requestData.ReplaceItems.map((item) => {
            return getTransformedInstancePropertiesForItem(item);
        });
    }

    return requestData;
}

export const calculateModifyOrderQuotePromise = (dispatch,
    customerId,
    modificationObject,
    paymentInstrumentIds,
    redemptionCodes = [],
    discretionaryDiscounts = [],
    shippingAddressId = null,
    shippingMethodId = null,
    calculateShipping = true,
    calculateTaxes = true,
    useDefaults = true,
    recordPaymentInfo = true,
    paymentInstruments = [],
    discretionaryDiscountReasonCode = null) => {
    const requestData = getModifyOrderRequestData(modificationObject, paymentInstrumentIds, paymentInstruments, recordPaymentInfo, shippingAddressId, shippingMethodId, '', redemptionCodes, discretionaryDiscounts, discretionaryDiscountReasonCode);

    if (shippingAddressId) {
        requestData.IncludeShippingMethods = true;
        requestData.ShipToName = 'ShipToName'; // ShipToName content is irrelevant to COQ but required for call to succeed
    }
    requestData.CalculateShipping = calculateShipping;
    requestData.CalculateTaxes = calculateTaxes;
    requestData.UseDefaults = useDefaults;

    return ThunkHelper(dispatch, CALCULATE_MODIFY_PRODUCT_ORDER_QUOTE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/CalculateModifyOrderQuote',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: requestData
    }).then((responsePayload) => {
        if (!calculateTaxes) {
            // we cannot call UpdateShoppingCart in Replace & Modify subscription flows
            // yet much of the UI for Replace depends on the ShoppingCart being populated, so populating it from quote data
            if (requestData.ReplaceItems && requestData.ReplaceItems.length) {
                dispatch({
                    type: SET_SHOPPING_CART_FROM_QUOTE_DATA,
                    payload: responsePayload,
                    requestObject: requestData
                });
            }

            dispatch({
                type: STORE_MODIFY_REQUEST_DATA,
                requestObject: requestData
            });
        }
    });
};

export const calculateOrderQuotePromise = (dispatch,
    customerId,
    shoppingCart,
    orderTaxLocation = null,
    paymentInstrumentIds = [],
    redemptionCodes = [],
    discretionaryDiscounts = [],
    shippingAddressId = null,
    shippingMethodId = null,
    useDefaults = true,
    recordPaymentInfo = true,
    paymentInstruments = [],
    discretionaryDiscountReasonCode = null) => {
    const requestData = {
        DiscretionaryDiscountReasonCode: discretionaryDiscountReasonCode,
        DiscretionaryDiscounts: discretionaryDiscounts,
        RedemptionCodes: redemptionCodes,
        ShoppingCart: transformCartItemInstanceProperties(shoppingCart),
        RecordPaymentInformation: recordPaymentInfo,
        ReturnSubscriptionPreviews: true,
        UseDefaults: useDefaults
    };

    if (recordPaymentInfo) {
        requestData.PaymentInstrumentIds = paymentInstrumentIds;
    } else {
        requestData.PaymentInstruments = [paymentInstruments];
    }

    if (orderTaxLocation) {
        requestData.OrderTaxLocation = orderTaxLocation;
    }

    if (shippingAddressId) {
        requestData.IncludeShippingMethods = true;
        requestData.ShippingAddressId = shippingAddressId;
        requestData.ShippingMethodId = shippingMethodId;
        requestData.ShipToName = 'ShipToName'; // ShipToName content is irrelevant to COQ but required for call to succeed
    }

    return ThunkHelper(dispatch, CALCULATE_PRODUCT_ORDER_QUOTE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/CalculateOrderQuote',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: requestData
    });
};

export const CALCULATE_COS_ORDER_QUOTE_FOR_SERVICE_FEATURES = {
    BEGIN: 'CALCULATE_COS_ORDER_QUOTE_FOR_SERVICE_FEATURES.BEGIN',
    SUCCESS: 'CALCULATE_COS_ORDER_QUOTE_FOR_SERVICE_FEATURES.SUCCESS',
    FAILURE: 'CALCULATE_COS_ORDER_QUOTE_FOR_SERVICE_FEATURES.FAILURE'
};
export const calculateChangeOfServiceOrderQuoteRequestPromise = (dispatch, customerId, addItems, modifyItems, removeItems, paymentInstrumentIds) => {
    const data = {
        AddItems: addItems,
        AllowPartialQuote: true,
        ChangeImmediately: true,
        ModifyItems: modifyItems,
        PaymentInstrumentIds: paymentInstrumentIds,
        RemoveItems: removeItems,
        ReturnSubscriptionPreviews: true
    };

    return ThunkHelper(dispatch, values(CALCULATE_COS_ORDER_QUOTE_FOR_SERVICE_FEATURES), {
        method: 'post',
        url: 'SubscriberManagement/CalculateChangeOfServiceOrderQuote',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: data
    });
};

export const calculateRenewOrderQuotePromise = (dispatch,
    customerId,
    subscriptionId,
    paymentInstrumentIds = [],
    redemptionCodes = [],
    discretionaryDiscounts = [],
    shippingAddressId = null,
    shippingMethodId = null,
    calculateTaxes = true,
    useDefaults = true) => {
    const requestData = {
        CalculateTaxes: calculateTaxes,
        DiscretionaryDiscounts: discretionaryDiscounts,
        RedemptionCodes: redemptionCodes,
        PaymentInstrumentIds: paymentInstrumentIds,
        SubscriptionId: subscriptionId,
        UseDefaults: useDefaults
    };

    if (shippingAddressId) {
        requestData.IncludeShippingMethods = true;
        requestData.ShippingAddressId = shippingAddressId;
        requestData.ShippingMethodId = shippingMethodId;
        requestData.ShipToName = 'ShipToName'; // ShipToName content is irrelevant to COQ but required for call to succeed
    }

    return ThunkHelper(dispatch, CALCULATE_RENEW_ORDER_QUOTE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/CalculateRenewOrderQuote',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: requestData
    }).then((responsePayload) => {
        dispatch({
            type: SET_SHOPPING_CART_FROM_QUOTE_DATA,
            payload: responsePayload,
            requestObject: requestData
            //requestObject: Object.assign({}, requestData, {
            //    requestType: PRODUCT_ORDER_WIZARD_MODE.RESTORE
            //})
        });
    });
};

export const clearProductOrderQuote = () => {
    return {
        type: CLEAR_PRODUCT_ORDER_QUOTE
    };
};

export const GET_PRODUCT_METADATA_EVENT_TYPES = {
    BEGIN: 'GET_PRODUCT_METADATA_BEGIN',
    SUCCESS: 'GET_PRODUCT_METADATA_SUCCESS',
    FAILURE: 'GET_PRODUCT_METADATA_FAILURE'
};

const GET_PRODUCT_METADATA_EVENTS = [
    GET_PRODUCT_METADATA_EVENT_TYPES.BEGIN,
    GET_PRODUCT_METADATA_EVENT_TYPES.SUCCESS,
    GET_PRODUCT_METADATA_EVENT_TYPES.FAILURE
];

export const GET_PRODUCT_METADATA_BATCH = {
    BEGIN: 'GET_PRODUCT_METADATA_BATCH_BEGIN',
    SUCCESS: 'GET_PRODUCT_METADATA_BATCH_SUCCESS',
    FAILURE: 'GET_PRODUCT_METADATA_BATCH_FAILURE'
};

export const getProductMetadata = (productId) => {
    return (dispatch, getState) => {
        const customer = CurrentCustomerSelector(getState());
        return getProductMetadataPromise(dispatch, productId, customer.Language);
    };
};

export const getProductMetadataBatch = (productIds) => {
    return (dispatch, getState) => {
        const customer = CurrentCustomerSelector(getState());
        return getProductMetadataBatchPromise(dispatch, productIds, customer.Language);
    };
};

export const getProductMetadataBatchPromise = (dispatch, productIds, customerLanguage) => {
    dispatch({
        type: GET_PRODUCT_METADATA_BATCH.BEGIN
    });

    const promises = [];
    productIds.forEach((id) => {
        promises.push(getProductMetadataPromise(dispatch, id, customerLanguage));
    });

    return Promise.all(promises).then(() => {
        dispatch({
            type: GET_PRODUCT_METADATA_BATCH.SUCCESS
        });
    }).catch(() => {
        dispatch({
            type: GET_PRODUCT_METADATA_BATCH.FAILURE
        });
    });
};

export const getProductMetadataPromise = (dispatch, productId, customerLanguage) => {
    return MetadataThunkHelper(dispatch, GET_PRODUCT_METADATA_EVENTS, {
        url: `Product/Id/${productId}`,
        headers: {
            [ApiConstants.LANGUAGE_HEADER]: customerLanguage
        }
    }, {
        productId: productId
    });
};

export const searchAvailableProductsPromise = (dispatch, searchProductsParam, requestObject, customerId) => {
    const options = {
        method: 'post',
        url: 'SubscriberManagement/SearchCatalog',
        data: searchProductsParam
    };

    if (options.data && options.data.SearchString) {
        //Multiple hyphens will cause the service to throw a 500 error
        options.data.SearchString = options.data.SearchString.replace(/-+/g, '-');
    }

    options.headers = {};
    if (customerId) {
        options.headers[ApiConstants.SUBSCRIBER_ID_HEADER] = customerId;
    }

    return ThunkHelper(dispatch, SEARCH_AVAILABLE_PRODUCTS_TYPES, options, requestObject);
};

export const retrieveProductsContextPromise = (dispatch, retrieveProductsContextParam, requestObject, customerId) => {
    return ThunkHelper(dispatch, RETRIEVE_PRODUCTS_CONTEXT_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveProductContext',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: retrieveProductsContextParam
    }, requestObject);
};

export const loadAdditionalEpisodesPromise = (dispatch, customerId, data) => {
    return ThunkHelper(dispatch, LOAD_ADDITIONAL_EPISODES_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveProductContext',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: data
    });
};

export const retrieveProductContextPromise = (dispatch, customerId, data, requestObject) => {
    return ThunkHelper(dispatch, RETRIEVE_PRODUCT_CONTEXT_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveProductContext',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: data
    }, requestObject);
};

export const retrieveProductContext = (customerId, data) => {
    return (dispatch) => {
        return retrieveProductContextPromise(dispatch, customerId, data);
    };
};

export const setPageNumber = (pageNum) => {
    return {
        type: SET_PAGE_NUMBER,
        payload: pageNum
    };
};

export const SET_PRODUCT_ORDER_INVENTORY_REGION_FILTER = 'SET_PRODUCT_ORDER_INVENTORY_REGION_FILTER';
export const setInventoryRegionFilter = (region) => {
    return {
        type: SET_PRODUCT_ORDER_INVENTORY_REGION_FILTER,
        payload: region
    };
};

export const SET_PRODUCT_ORDER_SELECTED_INVENTORY_STORES = 'SET_PRODUCT_ORDER_SELECTED_INVENTORY_STORES';
export const setSelectedInventoryStores = (stores) => {
    return {
        type: SET_PRODUCT_ORDER_SELECTED_INVENTORY_STORES,
        payload: stores
    };
};

export const FETCH_PRODUCT_ORDER_SERVICE_IDENTIFIER = {
    BEGIN: 'FETCH_PRODUCT_ORDER_SERVICE_IDENTIFIER.BEGIN',
    FAILURE: 'FETCH_PRODUCT_ORDER_SERVICE_IDENTIFIER.FAILURE',
    SUCCESS: 'FETCH_PRODUCT_ORDER_SERVICE_IDENTIFIER.SUCCESS'
};

const FETCH_PRODUCT_ORDER_SERVICE_IDENTIFIER_TYPES = [
    FETCH_PRODUCT_ORDER_SERVICE_IDENTIFIER.BEGIN,
    FETCH_PRODUCT_ORDER_SERVICE_IDENTIFIER.SUCCESS,
    FETCH_PRODUCT_ORDER_SERVICE_IDENTIFIER.FAILURE
];

export const fetchProdutOrderInventoryPromise = (dispatch, customerId, inventoryCategory) => {
    return ThunkHelper(dispatch, FETCH_PRODUCT_ORDER_SERVICE_IDENTIFIER_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/SearchSubscriberInventory',
        data: {
            InventoryCategory: inventoryCategory
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const fetchProductOrderCustomerInventory = (customerId, inventoryCategory) => {
    return (dispatch) => {
        return fetchProdutOrderInventoryPromise(dispatch, customerId, inventoryCategory);
    };
};

export const RETRIEVE_ADDRESSES_CONSTANTS = {
    BEGIN: 'RETRIEVE_ADDRESSES.BEGIN',
    SUCCESS: 'RETRIEVE_ADDRESSES.SUCCESS',
    FAILURE: 'RETRIEVE_ADDRESSES.FAILURE'
};

const retrieveCustomerAddressesPromise = (dispatch, customerId, includeRemoved) => {
    return ThunkHelper(dispatch, values(RETRIEVE_ADDRESSES_CONSTANTS), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveAddresses',
        data: {
            IncludeRemoved: includeRemoved
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId
    });
};

export const retrieveCustomerAddresses = (customerId, includeRemoved) => {
    return (dispatch) => {
        return retrieveCustomerAddressesPromise(dispatch, customerId, includeRemoved);
    };
};

export const setSelectedProductsSortOption = (sortBy) => {
    return {
        type: SET_SELECTED_PRODUCTS_SEARCH_OPTION,
        payload: sortBy
    };
};

export const loadAdditionalEpisodes = (customerId, productId, data) => {
    return (dispatch) => {
        return loadAdditionalEpisodesPromise(dispatch, customerId, Object.assign({}, {
            ProductId: productId
        }, data));
    };
};

export const getOrderablePricingExtended = (productId, customerId, couponCode, serviceId) => {
    return (dispatch, getState) => {
        dispatch({
            type: RETRIEVE_PRODUCTS_CONTEXT_AND_METADATA_CONSTANTS.BEGIN
        });

        return retrieveProductContextPromise(dispatch, customerId, {
            ProductId: productId,
            IncludeOrderablePricingPlans: true,
            RedemptionCodes: [couponCode],
            ServiceId: serviceId
        }).then(() => {
            const customer = CurrentCustomerSelector(getState());
            return getProductMetadataPromise(dispatch, productId, customer.Language);
        }).then(() => {
            dispatch({
                type: RETRIEVE_PRODUCTS_CONTEXT_AND_METADATA_CONSTANTS.SUCCESS
            });
        }, (error) => {
            dispatch({
                type: RETRIEVE_PRODUCTS_CONTEXT_AND_METADATA_CONSTANTS.FAILURE,
                payload: error
            });

            return Promise.reject();
        });
    };
};

export const PRODUCT_ORDER_RETRIEVE_ORDER_CONTEXT = {
    BEGIN: 'PRODUCT_ORDER_RETRIEVE_ORDER_CONTEXT_BEGIN',
    SUCCESS: 'PRODUCT_ORDER_RETRIEVE_ORDER_CONTEXT_SUCCESS',
    FAILURE: 'PRODUCT_ORDER_RETRIEVE_ORDER_CONTEXT_FAILURE'
};

const PRODUCT_ORDER_RETRIEVE_ORDER_CONTEXT_TYPES = [
    PRODUCT_ORDER_RETRIEVE_ORDER_CONTEXT.BEGIN,
    PRODUCT_ORDER_RETRIEVE_ORDER_CONTEXT.SUCCESS,
    PRODUCT_ORDER_RETRIEVE_ORDER_CONTEXT.FAILURE
];

export const retrieveOrderContext = (subscriberId) => {
    return (dispatch) => {
        return retrieveOrderContextPromise(dispatch, subscriberId);
    };
};

export const retrieveOrderContextPromise = (dispatch, subscriberId) => {
    const params = {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOrderContext',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
        },
        data: {}
    };

    return ThunkHelper(
        dispatch,
        PRODUCT_ORDER_RETRIEVE_ORDER_CONTEXT_TYPES,
        params
    );
};

export const RETRIEVE_ORDER_QUOTE_PRODUCT_ORDER = {
    BEGIN: 'RETRIEVE_ORDER_QUOTE_PRODUCT_ORDER_BEGIN',
    SUCCESS: 'RETRIEVE_ORDER_QUOTE_PRODUCT_ORDER_SUCCESS',
    FAILURE: 'RETRIEVE_ORDER_QUOTE_PRODUCT_ORDER_FAILURE'
};

const RETRIEVE_ORDER_QUOTE_TYPES = [
    RETRIEVE_ORDER_QUOTE_PRODUCT_ORDER.BEGIN,
    RETRIEVE_ORDER_QUOTE_PRODUCT_ORDER.SUCCESS,
    RETRIEVE_ORDER_QUOTE_PRODUCT_ORDER.FAILURE
];

export const retrieveOrderQuote = (customerId, quoteId) => {
    return (dispatch) => {
        return retrieveOrderQuotePromise(dispatch, customerId, quoteId);
    };
};

export const retrieveOrderQuotePromise = (dispatch, customerId, quoteId) => {
    return ThunkHelper(dispatch, RETRIEVE_ORDER_QUOTE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOrderQuote',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            QuoteId: quoteId
        }
    }, {
        calculateTaxesInQuote: true
    });
};

export const UPDATE_SEARCH_CATALOG_PARAMS = 'UPDATE_SEARCH_CATALOG_PARAMS';

export const updateSearchCatalogParams = (searchCatalogParams) => {
    return (dispatch) => {
        dispatch({
            type:  UPDATE_SEARCH_CATALOG_PARAMS,
            payload: searchCatalogParams
        });
        return retrieveProductsWithInfo(dispatch, searchCatalogParams);
    };
};

const retrieveProductsWithInfo = (dispatch, searchCatalogParams) => {
    dispatch({
        type: RETRIEVE_PRODUCTS_WITH_INFO_CONSTANTS.BEGIN
    });

    const searchBody = {
        PageNumber: searchCatalogParams.PageNumber,
        PageSize: searchCatalogParams.PageSize,
        ProductFilter: searchCatalogParams.ProductFilter,
        SearchString: searchCatalogParams.SearchString,
        SearchEntities: searchCatalogParams.SearchEntities,
        ProductSort: searchCatalogParams.ProductSort
    };

    const requestObject = {
        pageNumber: searchCatalogParams.PageNumber,
        keyword: searchCatalogParams.SearchString
    };

    return searchAvailableProductsPromise(dispatch, searchBody, requestObject, searchCatalogParams.Headers.CustomerId).then((products) => {
        const productPromises = [];
        const productIds = products.SearchResults.map((product) => {
            return product.Id;
        });

        while (productIds.length > 0) {
            const sublist = productIds.splice(0, MAX_PRODUCT_CONTEXT_SEARCH_LENGTH);
            const retrieveProductsContextParams = {
                IncludeFeaturedOrderablePricingPlan: true,
                ProductIds: sublist
            };
            if (searchCatalogParams.ProductFilter.CouponCode) {
                retrieveProductsContextParams.RedemptionCodes = [searchCatalogParams.ProductFilter.CouponCode];
            }
            productPromises.push(retrieveProductsContextPromise(dispatch, retrieveProductsContextParams, undefined, searchCatalogParams.Headers.CustomerId));
        }

        Promise.all(productPromises).then(() => {
            dispatch({
                type: RETRIEVE_PRODUCTS_WITH_INFO_CONSTANTS.SUCCESS
            });
        }, (error) => {
            dispatch({
                type: RETRIEVE_PRODUCTS_WITH_INFO_CONSTANTS.FAILURE,
                payload: error
            });
        });
    },
    (error) => {
        dispatch({
            type: RETRIEVE_PRODUCTS_WITH_INFO_CONSTANTS.FAILURE,
            payload: error
        });
    });
};

export const SUBMIT_MODIFY_PRODUCT_ORDER = {
    BEGIN: 'SUBMIT_MODIFY_PRODUCT_ORDER_BEGIN',
    SUCCESS: 'SUBMIT_MODIFY_PRODUCT_ORDER_SUCCESS',
    FAILURE: 'SUBMIT_MODIFY_PRODUCT_ORDER_FAILURE'
};

const SUBMIT_MODIFY_PRODUCT_ORDER_TYPES = [
    SUBMIT_MODIFY_PRODUCT_ORDER.BEGIN,
    SUBMIT_MODIFY_PRODUCT_ORDER.SUCCESS,
    SUBMIT_MODIFY_PRODUCT_ORDER.FAILURE
];

export const submitModifyOrderPromise = (dispatch,
    customerId,
    modificationObject,
    paymentInstrumentIds,
    paymentInstruments,
    recordPaymentInfo,
    redemptionCodes = [],
    discretionaryDiscounts = [],
    discretionaryDiscountReasonCode = null,
    shippingAddressId = null,
    shippingMethodId = null,
    shipToName = null,
    additionalProperties = null) => {
    const requestData = getModifyOrderRequestData(modificationObject,
        paymentInstrumentIds,
        paymentInstruments,
        recordPaymentInfo,
        shippingAddressId,
        shippingMethodId,
        shipToName,
        redemptionCodes,
        discretionaryDiscounts,
        discretionaryDiscountReasonCode);

    requestData.AdditionalProperties = additionalProperties;

    return ThunkHelper(dispatch, SUBMIT_MODIFY_PRODUCT_ORDER_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/SubmitModifyOrder',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: requestData
    });
};

export const SUBMIT_RESTORE_ORDER = {
    BEGIN: 'SUBMIT_RESTORE_ORDER_BEGIN',
    SUCCESS: 'SUBMIT_RESTORE_ORDER_SUCCESS',
    FAILURE: 'SUBMIT_RESTORE_ORDER_FAILURE'
};

const SUBMIT_RESTORE_ORDER_TYPES = [
    SUBMIT_RESTORE_ORDER.BEGIN,
    SUBMIT_RESTORE_ORDER.SUCCESS,
    SUBMIT_RESTORE_ORDER.FAILURE
];

export const submitRestoreOrderPromise = (dispatch,
    customerId,
    subscriptionId,
    paymentInstrumentIds,
    paymentInstruments,
    recordPaymentInfo,
    redemptionCodes = [],
    discretionaryDiscounts = [],
    discretionaryDiscountReasonCode = null,
    shippingAddressId = null,
    shippingMethodId = null,
    shipToName = null,
    waiveFee = false,
    waiveFeeReason = null,
    waiveLateFee = false,
    waiveLateFeeReason = null) => {
    const requestData = buildSubmitRestoreOrderRequestData(subscriptionId,
        paymentInstrumentIds,
        paymentInstruments,
        recordPaymentInfo,
        redemptionCodes,
        discretionaryDiscounts,
        discretionaryDiscountReasonCode,
        shippingAddressId,
        shippingMethodId,
        shipToName,
        waiveFee,
        waiveFeeReason,
        waiveLateFee,
        waiveLateFeeReason);

    return ThunkHelper(dispatch, SUBMIT_RESTORE_ORDER_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/SubmitRestoreOrder',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: requestData
    });
};

export const SUBMIT_PRODUCT_ORDER = {
    BEGIN: 'SUBMIT_PRODUCT_ORDER_BEGIN',
    SUCCESS: 'SUBMIT_PRODUCT_ORDER_SUCCESS',
    FAILURE: 'SUBMIT_PRODUCT_ORDER_FAILURE'
};

const SUBMIT_PRODUCT_ORDER_TYPES = [
    SUBMIT_PRODUCT_ORDER.BEGIN,
    SUBMIT_PRODUCT_ORDER.SUCCESS,
    SUBMIT_PRODUCT_ORDER.FAILURE
];

export const SET_REPLACE_ITEM = 'SET_REPLACE_ITEM';

export const setReplaceItem = (cartItem) => {
    return {
        type: SET_REPLACE_ITEM,
        payload: cartItem
    };
};

export const SET_REPLACEMENT_ITEM = 'SET_REPLACEMENT_ITEM';
export const setReplacementItem = (cartItem) => {
    return {
        type: SET_REPLACEMENT_ITEM,
        payload: cartItem
    };
};

export const submitOrderPromise = (dispatch,
    customerId,
    shoppingCart,
    orderTaxLocation = null,
    paymentInstrumentIds,
    paymentInstruments,
    recordPaymentInfo = true,
    redemptionCodes = [],
    discretionaryDiscounts = [],
    discretionaryDiscountReasonCode = null,
    executionOptions,
    shippingAddressId = null,
    shippingMethodId = null,
    shipToName = null,
    offeringId = null,
    useDefaults = true,
    additionalProperties,
    orderDetails) => {
    const requestData = buildSubmitOrderRequestData(shoppingCart,
        paymentInstrumentIds,
        paymentInstruments,
        recordPaymentInfo,
        redemptionCodes,
        discretionaryDiscounts,
        discretionaryDiscountReasonCode,
        executionOptions,
        shippingAddressId,
        shippingMethodId,
        shipToName,
        offeringId,
        orderTaxLocation,
        useDefaults,
        additionalProperties,
        orderDetails);

    return ThunkHelper(dispatch, SUBMIT_PRODUCT_ORDER_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/SubmitOrder',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: requestData
    });
};

export const SUBMIT_CHANGE_OF_SERVICE_FOR_SERVICE_FEATURES = {
    BEGIN: 'SUBMIT_CHANGE_OF_SERVICE_FOR_SERVICE_FEATURES.BEGIN',
    SUCCESS: 'SUBMIT_CHANGE_OF_SERVICE_FOR_SERVICE_FEATURES.SUCCESS',
    FAILURE: 'SUBMIT_CHANGE_OF_SERVICE_FOR_SERVICE_FEATURES.FAILURE'
};
export const submitServiceFeatureOrderPromise = (dispatch, customerId, addItems, modifyItems, removeItems, paymentInstrumentIds, executionOptions, additionalProperties) => {
    const data = {
        AddItems: addItems,
        AdditionalProperties: additionalProperties || null,
        ModifyItems: modifyItems,
        RemoveItems: removeItems,
        PaymentInstrumentIds: paymentInstrumentIds,
        ChangeImmediately: true,
        ExecutionDate: executionOptions?.specifiedDate,
        ExecutionDateIntention: executionOptions?.effective
    };

    return ThunkHelper(dispatch, values(SUBMIT_CHANGE_OF_SERVICE_FOR_SERVICE_FEATURES), {
        method: 'post',
        url: 'SubscriberManagement/SubmitChangeOfServiceOrder',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: data
    });
};

export const SUBMIT_GIFT_PRODUCT_ORDER = {
    BEGIN: 'SUBMIT_GIFT_PRODUCT_ORDER_BEGIN',
    SUCCESS: 'SUBMIT_GIFT_PRODUCT_ORDER_SUCCESS',
    FAILURE: 'SUBMIT_GIFT_PRODUCT_ORDER_FAILURE'
};
const SUBMIT_GIFT_PRODUCT_ORDER_TYPES = values(SUBMIT_GIFT_PRODUCT_ORDER);

export const submitGiftOrderPromise = (dispatch,
    customerId,
    shoppingCart,
    paymentInstrumentIds,
    paymentInstruments,
    recordPaymentInfo,
    giftOrderInfo,
    redemptionCodes = [],
    discretionaryDiscounts = [],
    discretionaryDiscountReasonCode = null,
    shippingAddressId = null,
    shippingMethodId = null,
    shipToName = null,
    additionalProperties) => {
    const requestData = buildSubmitGiftOrderRequestData(shoppingCart,
        paymentInstrumentIds,
        paymentInstruments,
        recordPaymentInfo,
        giftOrderInfo,
        redemptionCodes,
        discretionaryDiscounts,
        discretionaryDiscountReasonCode,
        null,
        shippingAddressId,
        shippingMethodId,
        shipToName,
        additionalProperties);

    return ThunkHelper(dispatch, SUBMIT_GIFT_PRODUCT_ORDER_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/SubmitGiftOrder',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: requestData
    });
};

export const NAVIGATE_TO_CART = 'NAVIGATE_TO_CART';
export const goToCheckoutForModify = ({childSubscriptionId, subscriptionId}) => {
    return (dispatch) => {
        dispatch({
            type: NAVIGATE_TO_CART
        });

        if (!isNil(childSubscriptionId)) {
            dispatch(stateGo('index.customercare.customer.subscriptions.detail.modify', {
                mode: PRODUCT_ORDER_WIZARD_MODE.MODIFY,
                childSubscriptionId: childSubscriptionId,
                subscriptionId: subscriptionId
            }));
        } else {
            dispatch(stateGo('index.customercare.customer.subscriptions.detail.modify', {
                mode: PRODUCT_ORDER_WIZARD_MODE.MODIFY,
                subscriptionId: subscriptionId
            }));
        }
    };
};

export const initializeReplaceOrder = (cartItem) => {
    return (dispatch) => {
        dispatch(setChangeImmediately(true));
        dispatch(setReplaceItem(cartItem));
    };
};

export const goToCheckoutForRestoreSubscription = () => {
    return (dispatch) => {
        dispatch({
            type: NAVIGATE_TO_CART
        });

        dispatch(stateGo(SUBSCRIPTION_RESTORE_ROUTE, {
            mode: PRODUCT_ORDER_WIZARD_MODE.RESTORE
        }));
    };
};

export const SET_SELECTED_PRODUCT_ID = 'SET_SELECTED_PRODUCT_ID';
export const setSelectedProductId = (productId) => {
    return {
        type: SET_SELECTED_PRODUCT_ID,
        payload: productId
    };
};

export const SET_SELECTED_SERIES_CONTAINER_PRODUCT_ID = 'SET_SELECTED_SERIES_CONTAINER_PRODUCT_ID';
export const setSelectedSeriesContainerProductId = (productId) => {
    return {
        type: SET_SELECTED_SERIES_CONTAINER_PRODUCT_ID,
        payload: productId
    };
};

export const SET_SELECTED_SERIES_CONTAINER_INCLUDED_SEASONS_OPTIONS = 'SET_SELECTED_SERIES_CONTAINER_INCLUDED_SEASONS_OPTIONS';
export const setSelectedSeriesContainerSeasons = (seasons) => {
    return {
        type: SET_SELECTED_SERIES_CONTAINER_INCLUDED_SEASONS_OPTIONS,
        payload: seasons
    };
};

export const SAVE_SERIES_CONTAINER_SEASON_SELECTIONS = 'SAVE_SERIES_CONTAINER_SEASON_SELECTIONS';
export const saveSeriesContainerSeasonSelections = (productId, pricingPlanId, selectedEpisodes, quantity) => {
    return {
        type: SAVE_SERIES_CONTAINER_SEASON_SELECTIONS,
        payload: {
            productId: productId,
            pricingPlanId: pricingPlanId,
            selectedEpisodes: selectedEpisodes,
            quantity: quantity
        }
    };
};

export const CLEAR_SAVED_SEASONS_SELECTIONS = 'CLEAR_SAVED_SEASONS_SELECTIONS';
export const clearSavedSeasonsSelections = () => {
    return {
        type: CLEAR_SAVED_SEASONS_SELECTIONS
    };
};

export const CLEAR_CART_SUMMARY_FOR_WAIVE_CHARGE = 'CLEAR_CART_SUMMARY_FOR_WAIVE_CHARGE';
export const clearCartSummaryForWaiveCharge = () => {
    return {
        type: CLEAR_CART_SUMMARY_FOR_WAIVE_CHARGE
    };
};

export const SET_EPISODES = 'SET_SELECTED_EPISODES';
export const setEpisodes = (episodes) => {
    return {
        type: SET_EPISODES,
        payload: episodes
    };
};

export const SET_OPTIONAL_PRODUCTS = 'SET_OPTIONAL_PRODUCTS';
export const setOptionalProducts = (optionalProducts) => {
    return {
        type: SET_OPTIONAL_PRODUCTS,
        payload: optionalProducts
    };
};

export const SET_PICKLIST_PRODUCTS = 'SET_PICKLIST_PRODUCTS';
export const setPicklistProducts = (picklistProducts) => {
    return {
        type: SET_PICKLIST_PRODUCTS,
        payload: picklistProducts
    };
};

export const CLEAR_PRODUCTS_METADATA = 'CLEAR_PRODUCTS_METADATA';
export const clearProductsMetadata = () => {
    return {
        type: CLEAR_PRODUCTS_METADATA
    };
};

export const SET_SELECTED_SERVICE_IDENTIFIER_FOR_PRODUCT_ORDER = 'SET_SELECTED_SERVICE_IDENTIFIER_FOR_PRODUCT_ORDER';
export const setSelectedServiceIdentifier = (serviceIdentifier) => {
    return {
        type: SET_SELECTED_SERVICE_IDENTIFIER_FOR_PRODUCT_ORDER,
        payload: serviceIdentifier
    };
};

export const SET_SELECTED_CUSTOMER_ADDRESS_FOR_PRODUCT_ORDER = 'SET_SELECTED_CUSTOMER_ADDRESS_FOR_PRODUCT_ORDER';
export const setSelectedCustomerAddress = (customerAddress) => {
    return {
        type: SET_SELECTED_CUSTOMER_ADDRESS_FOR_PRODUCT_ORDER,
        payload: customerAddress
    };
};

export const CLEAR_SHOPPING_CART_ERROR = 'PRODUCTS_ORDER_CLEAR_SHOPPING_CART_ERROR';
export const clearShoppingCartError = () => {
    return {
        type: CLEAR_SHOPPING_CART_ERROR
    };
};
