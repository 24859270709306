import {createSelector} from 'reselect';

export const CustomTilesStoreSelector = (state) => {
    return state.customercare.customTileResults;
};

export const CustomTilesResultSelector = createSelector(
    [CustomTilesStoreSelector],
    (tilesStore) => {
        return tilesStore.data;
    }
);