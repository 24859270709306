import {SUBSCRIBER_ID_HEADER} from 'invision-core/src/api/constants';
import ThunkHelper from 'invision-core/src/api/thunk.helper';

export const SEARCH_EXISTING_CUSTOMERS = {
    BEGIN: 'SEARCH_EXISTING_CUSTOMERS_BEGIN',
    SUCCESS: 'SEARCH_EXISTING_CUSTOMERS_SUCCESS',
    FAILURE: 'SEARCH_EXISTING_CUSTOMERS_FAILURE'
};

const SEARCH_EXISTING_CUSTOMERS_EVENTS = [SEARCH_EXISTING_CUSTOMERS.BEGIN, SEARCH_EXISTING_CUSTOMERS.SUCCESS, SEARCH_EXISTING_CUSTOMERS.FAILURE];

export const RETRIEVE_HOUSEHOLDS_FOR_CUSTOMER_SEARCH = {
    BEGIN: 'RETRIEVE_HOUSEHOLDS_FOR_CUSTOMER_SEARCH_BEGIN',
    SUCCESS: 'RETRIEVE_HOUSEHOLDS_FOR_CUSTOMER_SEARCH_SUCCESS',
    FAILURE: 'RETRIEVE_HOUSEHOLDS_FOR_CUSTOMER_SEARCH_FAILURE'
};

const RETRIEVE_HOUSEHOLDS_FOR_CUSTOMER_SEARCH_EVENTS = [
    RETRIEVE_HOUSEHOLDS_FOR_CUSTOMER_SEARCH.BEGIN,
    RETRIEVE_HOUSEHOLDS_FOR_CUSTOMER_SEARCH.SUCCESS,
    RETRIEVE_HOUSEHOLDS_FOR_CUSTOMER_SEARCH.FAILURE];

const searchExistingCustomersPromise = (dispatch, searchOtherCustomerModel) => {
    return ThunkHelper(dispatch, SEARCH_EXISTING_CUSTOMERS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SearchSubscribers',
        data: {
            FirstName: searchOtherCustomerModel.firstName,
            LastName: searchOtherCustomerModel.lastName,
            CompanyName: searchOtherCustomerModel.companyName,
            LoginOrEmailAddress: searchOtherCustomerModel.email,
            PageSize: 100,
            PageNumber: 1,
            PhoneNumber: searchOtherCustomerModel.phoneNumber,
            SubscriberId: searchOtherCustomerModel.customerId || undefined,
            Title: searchOtherCustomerModel.title || undefined
        }
    });
};

const retrieveHouseholdsPromise = (dispatch, subscriberId) => {
    return ThunkHelper(dispatch, RETRIEVE_HOUSEHOLDS_FOR_CUSTOMER_SEARCH_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveHouseholds',
        headers: {
            [SUBSCRIBER_ID_HEADER]: subscriberId
        }
    });
};

export const searchExistingCustomers = (searchOtherCustomerModel) => {
    return (dispatch) => {
        return searchExistingCustomersPromise(dispatch, searchOtherCustomerModel);
    };
};

export const searchHouseholdMembers = (subscriberId) => {
    return (dispatch) => {
        return retrieveHouseholdsPromise(dispatch, subscriberId);
    };
};

export const RESET_SEARCH_OTHER_CUSTOMERS_STORE = 'RESET_SEARCH_OTHER_CUSTOMERS_STORE';
export const resetSearchOtherCustomersStore = () => {
    return {
        type: RESET_SEARCH_OTHER_CUSTOMERS_STORE
    };
};
