import {
    createSelector
} from 'reselect';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {PageSizePreferenceSelector} from 'invision-core/src/components/session/session.selectors';
import {getFilterService} from 'invision-core/src/components/injectables/injector.helper';
import i18n from 'invision-core/src/components/i18n/i18n';

import {getPeriodString} from './contract.selectors.helper';

export const ContractsStoreSelector = (state) => {
    return state.customercare.offeringOrder.data.availableContracts;
};

const recoverableContractUISelector = (state) => {
    return state.customercare.recoverableUiState.contracts || {};
};

export const IsFetchingContractsSelector = createSelector(
    [ContractsStoreSelector],
    (search) => {
        return search.isFetchingResults;
    }
);

export const ContractsSearchCriteriaSelector = createSelector(
    [PageSizePreferenceSelector, recoverableContractUISelector],
    (pageSize, recoverableUi) => {
        return Object.assign({}, recoverableUi.searchCriteria, {
            PageSize: pageSize
        });
    }
);

export const ContractsSearchResultsSelector = createSelector(
    [ContractsStoreSelector],
    (store) => {
        return store.data.contracts || [];
    }
);

export const ContractsSearchResultsViewModelSelector = createSelector(
    [ContractsSearchResultsSelector],
    (contracts) => {
        const $filter = getFilterService();
        if (!contracts) {
            return [];
        }
        return contracts.map((contract) => {
            return {
                Id: contract.Id,
                Name: contract.Name,
                AvailabilityEndDate: (contract.AvailabilityEndDate) ? $filter('date')(contract.AvailabilityEndDate, 'shortDate') : i18n.translate(CoreLocaleKeys.NOT_APPLICABLE),
                CurrencyCode: contract.CurrencyCode,
                MinimumSpend: contract.MinimumSpendAmount ?  $filter('invCurrency')(contract.MinimumSpendAmount, contract.CurrencyCode) : $filter('invCurrency')(0, contract.CurrencyCode),
                Length: getPeriodString(contract.DurationPeriodType, contract.Duration),
                GracePeriod: contract.GracePeriod ? getPeriodString(contract.GracePeriodType, contract.GracePeriod) : i18n.translate(CoreLocaleKeys.NOT_APPLICABLE),
                TermsAndConditions: contract.TermsAndConditions || ''
            };
        });
    }
);
