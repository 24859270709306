import {
    createSelector
} from 'reselect';
import {pathOr} from 'ramda';
import i18n from 'invision-core/src/components/i18n/i18n';
import moment from 'moment';
import {
    convertUnitsUserFriendly,
    convertUnitsUserFriendlyIntoSmallerDescriptiveUnits,
    getAllUnitsInTheUnitCategory
} from 'invision-core/src/utilities/unitConversion/convert';
import {getFilterService} from 'invision-core/src/components/injectables/injector.helper';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    MetadataCodeTypeDictionarySelector,
    MetadataCodeTypeSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {PageSizePreferenceSelector} from 'invision-core/src/components/session/session.selectors';
import {RouteParams} from 'invision-core/src/components/router/router.selectors';
import localeKeys from './../../locales/keys';
import {
    ConvergentBillerAccountUsageDetailsByServiceIdSelector,
    CurrentAccountCurrencyCodeSelector,
    CurrentAccountSummarySelector,
    IsFetchingAccountUsageDetailsSelector
} from './customer.convergent.biller.selectors';
import {getUnitOfMeasure} from './shared.entitlements.selectors.helper';
import {unitsOfMeasureSelector} from './shared.entitlements.selectors';
import {getFormattedServiceAttributeValue} from './services.list.selectors.helper';
import {CurrentBusinessUnitTimeZoneOffsetSelector} from 'invision-core/src/components/session/businessunit.selectors';
import {
    BYTE_UNIT_CODE,
    SERVICE_ENTITLEMENTS_ACTIVATION_STATUS,
    UNIT_OF_MEASURES,
    USAGE_CALL_DIRECTION,
    USAGE_DETAILS_ACTION_TYPES
} from '../../customercare.constants';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT= {};

const findItemInCollectionForKeyMatchingValue = (collection, key, value) => {
    return collection.find((unit) => {
        return (unit[key] === value);
    });
};

const getNameForMetadataCode = (code, metadataCodes) => {
    const metadataItemForCode = findItemInCollectionForKeyMatchingValue(metadataCodes, 'Value', code);
    return metadataItemForCode ? metadataItemForCode.Name : '';
};

const usageDetailsRecoverableUIStateSelector = (state) => {
    return state.customercare.recoverableUiState.usageDetails;
};

const UsageDetailsStateSelector = (state) => {
    return state.customercare.customer.selectedCustomer &&
                state.customercare.customer.selectedCustomer.usageDetails ||
                EMPTY_OBJECT;
};

const GroupedEntitlementsSelector = createImmutableSelector(
    [UsageDetailsStateSelector],
    (usageDetailsStore) => {
        return usageDetailsStore.groupedEntitlements || EMPTY_ARRAY;
    }
);

export const ServiceEntitlementsSelector = createImmutableSelector(
    [UsageDetailsStateSelector],
    (usageDetailsStore) => {
        return usageDetailsStore.serviceEntitlements || EMPTY_ARRAY;
    }
);

export const SelectedNetworkEventTypeFiltersSelector = createSelector(
    [usageDetailsRecoverableUIStateSelector],
    (usageDetails) => {
        return usageDetails.networkTypeFilter;
    }
);

export const SelectedNetworkEventSubTypeFiltersSelector = createImmutableSelector(
    [usageDetailsRecoverableUIStateSelector],
    (usageDetails) => {
        return usageDetails.eventSubTypeFilter;
    }
);

export const SelectedNetworkEventTypeFiltersFormatSelector = createSelector(
    [SelectedNetworkEventTypeFiltersSelector],
    (networkEventType) => {
        return networkEventType ? networkEventType.map((eventType) => {
            return eventType.value;
        }) : null;
    }
);

export const UsageDetailsSelectedStartDateSelector = createSelector(
    [usageDetailsRecoverableUIStateSelector],
    (usageDetails) => {
        return usageDetails.startDate;
    }
);

export const UsageDetailsSelectedEndDateSelector = createSelector(
    [usageDetailsRecoverableUIStateSelector],
    (usageDetails) => {
        return usageDetails.endDate;
    }
);

export const UsageDetailsSearchEntitlementsDatePickerSelector = createImmutableSelector(
    [usageDetailsRecoverableUIStateSelector],
    (usageDetails) => {
        return usageDetails.searchEntitlementsDatePicker;
    }
);

export const UsageDetailsSearchEntitlementsIncludeExpiredSelector = createImmutableSelector(
    [usageDetailsRecoverableUIStateSelector],
    (usageDetails) => {
        return usageDetails.includeExpiredEntitlements;
    }
);

export const UsageDetailsSelectedCallCategoriesSelector = createImmutableSelector(
    [usageDetailsRecoverableUIStateSelector],
    (usageDetails) => {
        return usageDetails.callCategories;
    }
);

export const UsageDetailsSearchedEntitlementNameSelector = createSelector(
    [usageDetailsRecoverableUIStateSelector],
    (usageDetails) => {
        return usageDetails.entitlementName;
    }
);
export const UsageDetailsTotalAmountSelector = createSelector(
    [usageDetailsRecoverableUIStateSelector, CurrentAccountCurrencyCodeSelector],
    (usageDetails, accountCurrencyCode) => {
        if (usageDetails && usageDetails.totalAmount && (accountCurrencyCode || usageDetails.currencyCode)) {
            const $filter = getFilterService();
            return $filter('invCurrency')(usageDetails.totalAmount, (accountCurrencyCode || usageDetails.currencyCode), false, false, true);
        }
    }
);
export const UsageDetailsSortParamsSelector = createImmutableSelector(
    [usageDetailsRecoverableUIStateSelector],
    (usageDetails) => {
        return usageDetails.sortParams;
    }
);

export const IsSharedEntintitlementUsageSelector = createImmutableSelector(
    [RouteParams],
    (routeParams) => {
        return !!routeParams.isShared;
    }
);

const paginationDataSelector = createImmutableSelector(
    [usageDetailsRecoverableUIStateSelector],
    (usageDetails) => {
        return usageDetails.paginationData;
    }
);
export const UsageDetailsPaginationDataSelector = createImmutableSelector(
    [paginationDataSelector, PageSizePreferenceSelector],
    (paginationData, defaultPageSize) => {
        if (!paginationData.pageSize) {
            return Object.assign({}, paginationData, {
                pageSize: defaultPageSize
            });
        }
        return paginationData;
    }
);
export const SelectedEntitlementDetailsSelector = createImmutableSelector(
    [usageDetailsRecoverableUIStateSelector, RouteParams],
    (usageDetails, routeParams) => {
        return usageDetails.selectedEntitlementDetails && usageDetails.selectedEntitlementDetails.entitlementIndex !== undefined
            ? usageDetails.selectedEntitlementDetails
            : {
                entitlementIndex: +routeParams.entitlementIndex || 0,
                entitlementId: routeParams.entitlementId,
                balanceIterationId: routeParams.balanceIterationId
            };
    }
);
export const AccountUsageDetailsCurrentServiceSelector = createImmutableSelector(
    [CurrentAccountSummarySelector, RouteParams],
    (accountSummary, routeParams) => {
        return accountSummary && (accountSummary.Services || EMPTY_ARRAY).find((service) => {
            return service.ServiceIdentifier.Value === routeParams.serviceIdentifierValue;
        }) || EMPTY_OBJECT;
    }
);

export const SelectedGroupedEntitlementSelector = createImmutableSelector(
    [
        RouteParams,
        GroupedEntitlementsSelector
    ],
    (routeParams, groupedEntitlements) => {
        let response;

        if (routeParams.isShared && routeParams.groupCode) {
            response = (groupedEntitlements || []).find((entitlement) => {
                return entitlement.EntitlementIdentifier.EntitlementId === routeParams.entitlementId;
            });
        }

        return response;
    }
);

export const SharedEntitlementsSelector = createImmutableSelector(
    [UsageDetailsStateSelector],
    (usageDetailsStore) => {
        return usageDetailsStore.sharedEntitlements || EMPTY_ARRAY;
    }
);

export const SelectedSharedEntitlementSelector = createImmutableSelector(
    [RouteParams, SharedEntitlementsSelector],
    (routeParams, sharedEntitlements) => {
        let sharedEntitlement;

        if (routeParams.isShared) {
            sharedEntitlement = (sharedEntitlements || []).find((entitlement) => {
                return entitlement.EntitlementIdentifier.EntitlementId === routeParams.entitlementId;
            });
        }
        return sharedEntitlement || {};
    }
);

const EntitlementBalancesFromUsageDetails = createImmutableSelector(
    [SelectedGroupedEntitlementSelector, SelectedSharedEntitlementSelector],
    (groupedEntitlement, sharedEntitlement) => {
        return groupedEntitlement ? [groupedEntitlement] : ([sharedEntitlement] || EMPTY_ARRAY);
    }
);

export const IsLoadingEntitlementsSelector = createImmutableSelector(
    [UsageDetailsStateSelector],
    (usageDetailsStore) => {
        return usageDetailsStore.isLoading;
    }
);

export const AccountUsageDetailsEntitlementsSelector = createImmutableSelector(
    [
        IsSharedEntintitlementUsageSelector,
        ServiceEntitlementsSelector,
        EntitlementBalancesFromUsageDetails,
        unitsOfMeasureSelector,
        CurrentBusinessUnitTimeZoneOffsetSelector
    ],
    (isSharedUsage, serviceEntitlements, entitlementsFromUsageDetails, unitsOfMeasure, timeZoneOffset) => {
        const entitlements = (isSharedUsage
            ? entitlementsFromUsageDetails
            : serviceEntitlements
        ).reduce((entitlements, entitlement, index) => {
            if (
                entitlement.ActivationStatus !==
                SERVICE_ENTITLEMENTS_ACTIVATION_STATUS.ENTITLEMENT_INACTIVE
            ) {
                entitlements.push(
                    Object.assign({}, entitlement, {
                        index,
                        unitOfMeasure: getUnitOfMeasure(
                            entitlement.BalanceUnitCode,
                            unitsOfMeasure
                        ),
                        activationDate: entitlement.ActivationDate ? specifyTimezoneWithoutBrowserOffset(entitlement.ActivationDate, timeZoneOffset) : null,
                        expirationDate: entitlement.ActivationDate ? specifyTimezoneWithoutBrowserOffset(entitlement.ExpirationDate, timeZoneOffset) : null,
                        selectAll: false
                    })
                );
            }
            return entitlements;
        }, []);
        if (entitlements.length) {
            const entitlementsAll = entitlements.asMutable({
                deep: true
            });
            entitlementsAll.push({
                selectAll: true
            });
            return entitlementsAll;
        } else {
            return entitlements;
        }

    }
);

export const SelectedEntitlementSelector = createImmutableSelector(
    [
        ServiceEntitlementsSelector,
        SharedEntitlementsSelector,
        SelectedEntitlementDetailsSelector,
        IsSharedEntintitlementUsageSelector
    ],
    (serviceEntitlements, sharedEntitlements, selectedEntitlementDetails, isShared) => {
        const entitlements = isShared ? sharedEntitlements : serviceEntitlements;
        const selectedEntitlement = entitlements.find((entitlement) => {
            return selectedEntitlementDetails.entitlementId
                && entitlement.EntitlementIdentifier.EntitlementId === selectedEntitlementDetails.entitlementId
                && entitlement.EntitlementIdentifier.BalanceIterationId === selectedEntitlementDetails.balanceIterationId;
        });
        return selectedEntitlement
                || entitlements[selectedEntitlementDetails.entitlementIndex]
                || EMPTY_OBJECT;
    }
);

export const FormattedServiceAttributeValueSelector = createImmutableSelector(
    [
        RouteParams,
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression)
    ],
    (routeParams, serviceAttributes, regularExpressions) => {
        return getFormattedServiceAttributeValue(routeParams.serviceAttributeId, routeParams.serviceIdentifierValue, serviceAttributes, regularExpressions);
    }
);

export const UsageDetailsPageTitleSelector = createSelector(
    [
        RouteParams,
        IsSharedEntintitlementUsageSelector,
        SelectedEntitlementSelector,
        FormattedServiceAttributeValueSelector
    ],
    (routeParams, isShared, selectedEntitlement, formattedServiceAttributeValue) => {
        return isShared ?
            selectedEntitlement.EntitlementName :
            `${formattedServiceAttributeValue} ${routeParams.serviceIdentifierFriendlyName || ''}`;
    }
);

const getCallDirection = (callDirection) => {
    switch (callDirection) {
        case USAGE_CALL_DIRECTION.OUTGOING:
            return localeKeys.USAGE_DETAILS.TABLE_CONTENT.DIRECTION_OUTGOING;
        case USAGE_CALL_DIRECTION.INCOMING:
            return localeKeys.USAGE_DETAILS.TABLE_CONTENT.DIRECTION_INCOMING;
        case USAGE_CALL_DIRECTION.CALL_FORWARDING:
            return localeKeys.USAGE_DETAILS.TABLE_CONTENT.CALL_FORWARDING;
        default:
            return undefined;
    }
};

const specifyTimezoneWithoutBrowserOffset = (dateObject, timezone) => {
    const buTimezoneOffsetMinutes = moment.duration(timezone).asMinutes();
    return moment(dateObject).utcOffset(buTimezoneOffsetMinutes).format('MM/DD/YY hh:mm A');
};

export const AccountUsageDetailsTableDataSelector = createSelector(
    [
        IsFetchingAccountUsageDetailsSelector,
        MetadataCodeTypeSelector(CODES.NetworkEventType),
        MetadataCodeTypeSelector(CODES.NetworkEventSubType),
        MetadataCodeTypeDictionarySelector(CODES.UnitsOfMeasure),
        MetadataCodeTypeDictionarySelector(CODES.UsageUnitConversion),
        ConvergentBillerAccountUsageDetailsByServiceIdSelector,
        CurrentAccountCurrencyCodeSelector,
        CurrentBusinessUnitTimeZoneOffsetSelector
    ],
    (isFetchingAccountUsageDetails, networkEvents, networkEventSubTypes, unitsOfMeasure, usageUnitConversion, accountUsageDetailsForServiceId, accountCurrencyCode, TimeZoneOffset) => {
        const $filter = getFilterService();
        const formatView = (usageDetailItem) => {
            const USAGE_DIRECTION_KEY = getCallDirection(usageDetailItem.CallDirection);
            const USAGE_BILLED_KEY = usageDetailItem.InvoiceId ? localeKeys.USAGE_DETAILS.TABLE_CONTENT.BILLED_TRUE : localeKeys.USAGE_DETAILS.TABLE_CONTENT.BILLED_FALSE;
            const mappedTriggers = pathOr([], ['TriggerEvents'], usageDetailItem).map((trigger) => {
                return {
                    title: trigger.TriggerName,
                    id: trigger.TriggerBillerRuleConfigurationId
                };
            });

            const usrConvertedActualBalance = usageDetailItem.CallDirection ?
                convertUnitsUserFriendlyIntoSmallerDescriptiveUnits(usageDetailItem.ActualUsageAmount, +usageDetailItem.UnitOfMeasureCode, usageUnitConversion) :
                convertUnitsUserFriendly(usageDetailItem.ActualUsageAmount, +usageDetailItem.UnitOfMeasureCode, usageUnitConversion, BYTE_UNIT_CODE, getAllUnitsInTheUnitCategory(+usageDetailItem.ConversionUnitOfMeasureCode, usageUnitConversion));

            const usrConvertedBilledBalance = usageDetailItem.CallDirection ?
                convertUnitsUserFriendlyIntoSmallerDescriptiveUnits(usageDetailItem.UsageAmount, +usageDetailItem.UnitOfMeasureCode, usageUnitConversion) :
                convertUnitsUserFriendly(usageDetailItem.UsageAmount, +usageDetailItem.UnitOfMeasureCode, usageUnitConversion, BYTE_UNIT_CODE, getAllUnitsInTheUnitCategory(+usageDetailItem.ConversionUnitOfMeasureCode, usageUnitConversion));

            const eventOffsetLocal = TimeZoneOffset ?
                i18n.translate(localeKeys.UTC) + TimeZoneOffset.toString() : '';

            return {
                actionType: USAGE_DETAILS_ACTION_TYPES()[usageDetailItem.ActionType],
                billed: i18n.translate(USAGE_BILLED_KEY),
                callCategory: usageDetailItem.CallCategory,
                charge: $filter('invCurrency')(usageDetailItem.ChargeAmount, accountUsageDetailsForServiceId.CurrencyCode || accountCurrencyCode, false, true, true),
                dateTime: usageDetailItem.EventStartTime ? specifyTimezoneWithoutBrowserOffset(usageDetailItem.EventStartTime, TimeZoneOffset) : i18n.translate(localeKeys.NOT_APPLICABLE),
                dateTimeLocalWithOffset: usageDetailItem.EventStartTime ? `${specifyTimezoneWithoutBrowserOffset(usageDetailItem.EventStartTime, TimeZoneOffset)} ${eventOffsetLocal}` : i18n.translate(localeKeys.NOT_APPLICABLE),
                direction: USAGE_DIRECTION_KEY && i18n.translate(USAGE_DIRECTION_KEY),
                entitlementName: usageDetailItem.EntitlementName,
                eventSource: usageDetailItem.EventSource,
                networkEventSubTypeName: getNameForMetadataCode(usageDetailItem.NetworkEventSubTypeCode, networkEventSubTypes) || i18n.translate(localeKeys.USAGE_DETAILS.TABLE_CONTENT.UNKNOWN_NETWORK_EVENT),
                networkEventTypeName: getNameForMetadataCode(usageDetailItem.NetworkEventTypeCode, networkEvents) || i18n.translate(localeKeys.USAGE_DETAILS.TABLE_CONTENT.UNKNOWN_NETWORK_EVENT),
                processDate: usageDetailItem.ProcessDate ? specifyTimezoneWithoutBrowserOffset(usageDetailItem.ProcessDate, TimeZoneOffset) : i18n.translate(localeKeys.NOT_APPLICABLE),
                receiver: usageDetailItem.Receiver,
                receiverWithLocation: `${ usageDetailItem.Receiver ? usageDetailItem.Receiver  : ''}` + ` ${ usageDetailItem.ToLocation ? `(${ usageDetailItem.ToLocation })` : ''}`,
                sender: usageDetailItem.Sender,
                senderWithLocation: `${ usageDetailItem.Sender ? usageDetailItem.Sender  : ''}` + ` ${ usageDetailItem.FromLocation ? `(${ usageDetailItem.FromLocation })`  : ''}`,
                triggers: mappedTriggers,
                uomUnit: usageDetailItem.UnitDisplayName || null,
                uomUnitActualDisplayName: usageDetailItem.UnitDisplayName ? `${usageDetailItem.ActualUsageAmount} ${usageDetailItem.UnitDisplayName}`: null,
                uomUnitBilledDisplayName: usageDetailItem.UnitDisplayName ? `${usageDetailItem.UsageAmount} ${usageDetailItem.UnitDisplayName}`: null,
                usage_actual: usageDetailItem.UnitOfMeasureCode === UNIT_OF_MEASURES.CURRENCY ? {
                    value: usageDetailItem.ActualUsageAmount,
                    unitOfMeasure: +usageDetailItem.UnitOfMeasureCode
                } : usrConvertedActualBalance,
                usage_billed: usageDetailItem.UnitOfMeasureCode === UNIT_OF_MEASURES.CURRENCY ? {
                    value: usageDetailItem.UsageAmount,
                    unitOfMeasure: +usageDetailItem.UnitOfMeasureCode
                } : usrConvertedBilledBalance,
                financial_impact: usageDetailItem.FinancialImplication
            };
        };
        const necessaryDataHasBeenLoaded = !isFetchingAccountUsageDetails && networkEvents && networkEventSubTypes && unitsOfMeasure;
        const accountUsageDetailsHasUsageItems = accountUsageDetailsForServiceId && accountUsageDetailsForServiceId.UsageItems;

        return necessaryDataHasBeenLoaded && accountUsageDetailsHasUsageItems && accountUsageDetailsForServiceId ? accountUsageDetailsForServiceId.UsageItems.map(formatView).asMutable({
            deep: true
        }) : EMPTY_ARRAY;
    }
);
