import {createSelector} from 'reselect';

import {
    i18n,
    InjectorHelper
} from 'invision-core';

import {
    findIndex,
    flatten,
    pathOr,
    uniq
} from 'ramda';

import LocaleKeys from './../../locales/keys';
import * as SubscriptionConstants from '../../components/shared/constants/subscriptions.constants';

import {
    CurrentSubscriptionItemExtensionIdSelector,
    CurrentSubscriptionSelector,
    CurrencySelector,
    RemoveChildSubscriptionSelector,
    SubscriptionPricingPlanIdSelector,
    SubscriptionProductIdSelector,
    UserCanModifySelector,
    UserCanRemoveSubscriptionSelector,
    UserCanReplaceSubscriptionSelector,
    UserCanRenewSelector
} from './customer.subscriptions.selectors';

import {
    ProductsWithMetadataMapSelector,
    ShoppingCartSelector
} from './../../reducers/selectors/products.order.selectors';

function getItemWithViewProperties(item, currency, productsMap) {
    const $filter = InjectorHelper.getFilterService();
    const translatedYes = i18n.translate(LocaleKeys.YES);
    const translatedNo = i18n.translate(LocaleKeys.NO);

    const grossRenewAmountEval = item.GrossRenewAmount;
    const renewEval = item.Renew;
    const renewAmountEval = item.RenewAmount;
    const shippedEval = item.Shipped;
    const instanceProps = [];

    if (item.InstanceProperties && item.Product && productsMap[item.Product.Id]) {
        for (const key in item.InstanceProperties) {
            const metaIp = productsMap[item.Product.Id].InstanceProperties.find((mip) => {
                return mip.Id.toString() === key;
            });

            if (metaIp) {
                const ip = {
                    Name: metaIp.Name,
                    Value: item.InstanceProperties[key]
                };

                instanceProps.push(ip);
            }
        }
    }

    return item.set('GrossRenewAmount', grossRenewAmountEval && currency && currency.length > 0 ? $filter('invCurrency')(grossRenewAmountEval, currency) : i18n.translate(LocaleKeys.NOT_APPLICABLE))
        .set('uiInstanceProperties', instanceProps)
        .set('Renew', renewEval ? translatedYes : translatedNo)
        .set('RenewAmount', renewAmountEval && currency && currency.length > 0 ? $filter('invCurrency')(renewAmountEval, currency) : i18n.translate(LocaleKeys.NOT_APPLICABLE))
        .set('Shipped', shippedEval ? translatedYes : translatedNo);
}

const DEFAULT_SUBSCRIPTION_ITEMS = [];

export const SubscriptionWithDetailedItemsSelector = createSelector(
    [CurrentSubscriptionSelector,
        RemoveChildSubscriptionSelector,
        CurrencySelector,
        ProductsWithMetadataMapSelector],
    (subscription, childSubscription, currency, productsMap) => {
        if (subscription) {
            if (childSubscription) {
                let subscriptionItem = getItemWithViewProperties(childSubscription, currency, productsMap);

                if (childSubscription.Children) {
                    subscriptionItem = subscriptionItem.set('Children', subscriptionItem.Children.map((childItem) => {
                        return getItemWithViewProperties(childItem, currency, productsMap);
                    }));
                }

                return [subscriptionItem];
            }

            return subscription.Items.map((item) => {
                let subscriptionItem = getItemWithViewProperties(item, currency, productsMap);

                if (subscriptionItem.Children && subscriptionItem.Children.length) {
                    subscriptionItem = subscriptionItem.set('Children', subscriptionItem.Children.map((childItem) => {
                        return getItemWithViewProperties(childItem, currency, productsMap);
                    }));
                }
                return subscriptionItem;
            }, []);
        }

        return DEFAULT_SUBSCRIPTION_ITEMS;
    }
);

export const SubscriptionItemsExtensionSelectors = createSelector(
    [SubscriptionWithDetailedItemsSelector],
    (subscriptionItems) => {
        if (subscriptionItems.length) {
            const itemExtensions = subscriptionItems.map((item) => {
                if (item.SubscriptionExtensions) {
                    return item.SubscriptionExtensions;
                } else {
                    return [];
                }
            });
            //flatten takes arrays inside arrays and places them inside the original array
            //i.e. [1,2,[3,4],5] => [1,2,3,4,5]
            return uniq(flatten(itemExtensions));
        } else {
            return null;
        }
    }
);

export const CurrentSubscriptionItemWithExtensionSelectors = createSelector(
    [CurrentSubscriptionItemExtensionIdSelector,
        SubscriptionWithDetailedItemsSelector],
    (currentExtensionId, subscriptionItems) => {
        const items = subscriptionItems.filter((item) => {
            return item.SubscriptionExtensions ? item.SubscriptionExtensions.some((extensionItem) => {
                return extensionItem.Id === parseInt(currentExtensionId);
            }) : null;
        });
        return items.length ? items[0] : null;
    }
);

export const CurrentSubscriptionItemExtensionSelectors = createSelector(
    [CurrentSubscriptionItemExtensionIdSelector,
        SubscriptionItemsExtensionSelectors],
    (currentId, extensionItems) => {
        let extension = null;
        if (currentId && extensionItems) {
            extension = extensionItems.find((item) => {
                return item.Id === parseInt(currentId);
            });
        }
        return extension || null;
    }
);

export const CurrentSubscriptionItemExtensionPricingPlanSelectors = createSelector(
    [CurrentSubscriptionItemExtensionIdSelector,
        CurrentSubscriptionItemWithExtensionSelectors,
        CurrentSubscriptionItemExtensionSelectors],
    (currentExtensionId, subscriptionItem, subscriptionItemExtension) => {
        if (!currentExtensionId || !subscriptionItem || !subscriptionItemExtension) {
            return null;
        } else {
            if (subscriptionItemExtension.PricingPlanId === subscriptionItem.PricingPlan.Id) {
                return subscriptionItem.PricingPlan;
            }
            const option = subscriptionItem.ChangeOptions.find((option) => {
                return option.PricingPlan.Id === subscriptionItemExtension.PricingPlanId;
            });
            return pathOr(null, ['PricingPlan'], option);
        }
    }
);

export const SubscriptionShoppingCartIndexSelector = createSelector(
    [SubscriptionPricingPlanIdSelector,
        SubscriptionProductIdSelector,
        ShoppingCartSelector],
    (pricingPlanId, productId, shoppingCart) => {
        if (!pricingPlanId || !productId || !shoppingCart || !shoppingCart.Items) {
            return undefined;
        }

        return findIndex((item) => {
            return item.PricingPlanId === pricingPlanId &&
                item.ProductId === productId;
        }, shoppingCart.Items);
    }
);

export const SubscriptionProductsMoreOptionsMapSelector = createSelector(
    [
        CurrentSubscriptionSelector,
        UserCanRemoveSubscriptionSelector,
        UserCanReplaceSubscriptionSelector,
        UserCanModifySelector,
        UserCanRenewSelector
    ],
    (currentSubscription, userCanRemove, userCanReplace, userCanModify, userCanRenew) => {

        if (!currentSubscription || !currentSubscription.isAligned || currentSubscription.offeringThumbnail) {
            return {};
        }

        const moreOptionsMap = {};

        currentSubscription.Items.forEach((subscriptionItem) => {
            const moreOptions = [];

            if (userCanModify) {
                moreOptions.push({
                    id: SubscriptionConstants.MODIFY_SUBSCRIPTION,
                    title: i18n.translate(LocaleKeys.SUBSCRIPTIONS.MODIFY)
                });
            }

            if (userCanRemove) {
                moreOptions.push({
                    id: SubscriptionConstants.REMOVE_SUBSCRIPTION_OPTION_ID,
                    title: i18n.translate(LocaleKeys.SUBSCRIPTIONS.REMOVE_SUBSCRIPTION.REMOVE)
                });
            }

            if (userCanReplace) {
                moreOptions.push({
                    id: SubscriptionConstants.REPLACE_SUBSCRIPTION_OPTION_ID,
                    title: i18n.translate(LocaleKeys.SUBSCRIPTIONS.REPLACE_SUBSCRIPTION)
                });
            }

            if (userCanRenew) {
                // TODO - will be uncommented and implemented at subsr item level with ASC-67209
                moreOptions.push({
                    id: SubscriptionConstants.MODIFY_RENEWAL_OPTION_ID,
                    title: i18n.translate(LocaleKeys.SUBSCRIPTIONS.MODIFY_RENEWAL)
                });
            }

            moreOptionsMap[subscriptionItem.Id] = moreOptions;
        });

        return moreOptionsMap;
    }
);
