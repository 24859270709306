import thunkHelper from 'invision-core/src/api/thunk.helper';
import {SUBSCRIBER_ID_HEADER} from 'invision-core/src/api/constants';

export const RETRIEVE_CONTRACT_EVENTS = {
    BEGIN: 'RETRIEVE_CONTRACT.BEGIN',
    SUCCESS: 'RETRIEVE_CONTRACT.SUCCESS',
    FAILURE: 'RETRIEVE_CONTRACT.FAILURE'
};

export const retrieveContractPromise = (dispatch, id) => {
    return thunkHelper(dispatch, Object.values(RETRIEVE_CONTRACT_EVENTS), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOrderContract',
        data: {
            ContractId: id
        }
    });
};

export const retrieveContract = (id) => {
    return (dispatch) => {
        return retrieveContractPromise(dispatch, id);
    };
};

export const SET_SELECTED_CONTRACT = 'SET_SELECTED_CONTRACT';
export const setSelectedContract = (contract) => {
    return {
        type: SET_SELECTED_CONTRACT,
        payload: contract
    };
};

export const RETRIEVE_CONTRACT_INSTANCE_EVENTS = {
    BEGIN: 'RETRIEVE_CONTRACT_INSTANCE.BEGIN',
    SUCCESS: 'RETRIEVE_CONTRACT_INSTANCE.SUCCESS',
    FAILURE: 'RETRIEVE_CONTRACT_INSTANCE.FAILURE'
};


export const retrieveContractInstancePromise = (dispatch, id, customerId) => {
    return thunkHelper(dispatch, Object.values(RETRIEVE_CONTRACT_INSTANCE_EVENTS), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOrderContractInstance',
        data: {
            OrderContractInstanceId: id
        },
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
    });
};

export const retrieveContractInstance = (id, customerId) => {
    return (dispatch) => {
        return retrieveContractInstancePromise(dispatch, id, customerId);
    };
};