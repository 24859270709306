import Immutable from 'seamless-immutable';
import find from 'ramda/src/find';
import pathOr from 'ramda/src/pathOr';
import propEq from 'ramda/src/propEq';
import {createSelector} from 'reselect';
import {FeatureTogglesSelector} from 'invision-core/src/components/featureToggle/featureToggle.selectors';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import i18n from 'invision-core/src/components/i18n/i18n';
import {MetadataCodeTypeSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {getFilterService} from 'invision-core/src/components/injectables/injector.helper';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {
    PageSizePreferenceSelector,
    UserSecurityAttributesSelector
} from 'invision-core/src/components/session/session.selectors';
import {DiscountsMetadataMapSelector} from 'invision-core/src/components/metadata/discounts/discounts.selectors';
import {
    CanUserPerformMiscellaneousAndItemAdjustmentSelector,
    SelectedCustomerSelector,
    CurrentCustomerAccountingMethodSelector,
    CurrentCustomerIsExternallyManagedARSelector,
    CurrentCustomerSelector
} from './customer.selectors';
import {
    ADJUSTMENT_REASON_TYPE_CODE,
    ADJUSTMENT_TYPE_TYPE_CODE
} from '../constants/billing.payments.constants';
import {
    DISPUTE_INVOICE,
    ONE_TIME_MAKE_PAYMENT,
    PAYMENT_ADJUSTMENT_ACCESS
} from '../../security.attributes';
import {createInvoiceItemsTableViewModel} from '../selectors/billing.payments.invoice.selectors.helper';
import {
    createTableViewModel as createTableBillingViewModel,
    formatOpenInvoicesList
} from '../selectors/customer.billing.selectors.helper';
import LocaleKeys from '../../locales/keys';
import {
    AR_TRANSFER_ADJUSTMENT_EXTERNAL_REFERENCE,
    DEPOSIT_TRANSFER,
    DEPOSIT_UPDATED,
    MISC_ADJUSTMENT,
    WRITEOFF_ADJUSTMENT,
    WRITEOFF_REVERSAL_ADJUSTMENT
} from '../../components/adjustment/adjustment.modal.constants';
import {INVOICE, WITHHELD_TAX_EXTERNAL_REF} from '../../components/billingPayments/applyTaxCertificate/applyTaxCertificate.modal.constants';
import {EMPTY_ARRAY} from 'invision-core/src/constants/common.constants';
import {MAKE_PAYMENT_TYPES} from '../../components/customer/makePayment/make.payment.constants';
import {CONVERT_TO_CREDIT} from '../../components/billingPayments/billing.payments.constants';

/* ALERT! The associated RetrieveConvergentBillerAccountSummary call should not be used - use RetrieveConvergentBillerSubscriberSummary instead */

export const IsFetchingBillingPaymentsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.billingPayments.isFetchingBillingPayments;
    }
);

export const CurrentInvoiceIdSelector = (state) => {
    return state.router.currentParams.invoiceId;
};

export const AdjustmentReasonsCodeSelector = (state) => {
    return state.metadata.codes[ADJUSTMENT_REASON_TYPE_CODE];
};

export const AdjustmentTypeCodeSelector = (state) => {
    return state.metadata.codes[ADJUSTMENT_TYPE_TYPE_CODE];
};

const EMPTY_ADJUSTMENTS = [];
export const AdjustmentsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        const adjustments = selectedCustomer.billingPayments.data.adjustments;
        return adjustments ? adjustments : EMPTY_ADJUSTMENTS;
    }
);

const EMPTY_PAYMENTS = [];
export const PaymentsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        const payments = selectedCustomer.billingPayments.data.payments;
        return payments ? payments : EMPTY_PAYMENTS;
    }
);

export const CreditNotesSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        const creditNotes = selectedCustomer.billingPayments.data.creditNotes;
        return creditNotes ? creditNotes : EMPTY_ARRAY;
    }
);

export const DebitNotesSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        const debitNotes = selectedCustomer.billingPayments.data.debitNotes;
        return debitNotes ? debitNotes : EMPTY_ARRAY;
    }
);

export const CreditNotesToPaymentMappingSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        const creditToPaymentMapping = selectedCustomer.billingPayments.data.creditToPaymentMapping;
        return creditToPaymentMapping ? creditToPaymentMapping : EMPTY_ARRAY;
    }
);

const EMPTY_INVOICE_SUMMARIES = [];
export const InvoiceSummariesSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        const invoiceSummaries = selectedCustomer.billingPayments.data.invoiceSummaries;
        return invoiceSummaries ? invoiceSummaries : EMPTY_INVOICE_SUMMARIES;
    }
);

export const StartDateSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.billingPayments.metadata.startDate;
    }
);

export const EndDateSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.billingPayments.metadata.endDate;
    }
);

export const DateShortcutSelector = createSelector(
    SelectedCustomerSelector,
    (selectedCustomer) => {
        return selectedCustomer.billingPayments.metadata.dateShortcut;
    }
);

export const TypeSelector = createSelector(
    SelectedCustomerSelector,
    (selectedCustomer) => {
        return selectedCustomer.billingPayments.metadata.typeFilter;
    }
);

const EMPTY_INVOICE_ITEMS = [];
export const InvoiceItemsByIdSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        const invoiceDetails = selectedCustomer.billingPayments.invoiceDetails;
        return (invoiceDetails && invoiceDetails.InvoiceItems) || EMPTY_INVOICE_ITEMS;
    }
);

const EMPTY_ITEMS_TABLE = [];
const EMPTY_INVOICE = {};

export const InvoiceSummaryByIdSelector = createSelector(
    [InvoiceSummariesSelector, CurrentInvoiceIdSelector],
    (invoiceSummaries, currentInvoiceId) => {
        return invoiceSummaries && currentInvoiceId ? find(propEq(currentInvoiceId.toString(), 'InvoiceId'))(invoiceSummaries) : EMPTY_INVOICE;
    }
);

export const InvoiceItemsTableDataSelector = createSelector(
    [
        IsFetchingBillingPaymentsSelector,
        InvoiceItemsByIdSelector,
        MetadataCodeTypeSelector(CODES.InvoiceItemType),
        CurrentCustomerIsExternallyManagedARSelector,
        UserSecurityAttributesSelector,
        CanUserPerformMiscellaneousAndItemAdjustmentSelector,
        DiscountsMetadataMapSelector,
        InvoiceSummaryByIdSelector,
        IsDbss,
        CurrentCustomerSelector,
        FeatureTogglesSelector
    ],
    (isFetchingBillingPayments, invoiceItems, invoiceItemTypes,
        currentCustomerIsExternallyManagedAR, userSecurityAttributes,
        canUserPerformMiscellaneousAndItemAdjustment, discountsMetadataMap, invoiceSummary, isDbss, currentCustomer, features) => {
        const canApplyAdjustment = canUserPerformMiscellaneousAndItemAdjustment && (!currentCustomerIsExternallyManagedAR || (currentCustomerIsExternallyManagedAR && hasAccess(userSecurityAttributes, PAYMENT_ADJUSTMENT_ACCESS)));
        const witheldTaxesApplicable = (invoiceSummary && (invoiceSummary?.TotalWithheldTax > 0 || invoiceSummary?.TotalCashReceivable > 0)) && isDbss;
        return !isFetchingBillingPayments ? createInvoiceItemsTableViewModel(
            invoiceItems,
            invoiceSummary,
            invoiceItemTypes,
            canApplyAdjustment,
            discountsMetadataMap,
            userSecurityAttributes,
            witheldTaxesApplicable,
            currentCustomer,
            features
        ) : EMPTY_ITEMS_TABLE;
    }
);


const getBillingDataEntries = (adjustments, invoiceSummaries, payments, creditNotes, debitNotes) => {
    return Immutable.asMutable(creditNotes
        .concat(debitNotes)
        .concat(payments)
        .concat(adjustments)
        .concat(invoiceSummaries))
        .sort((a, b) => {
            return new Date(b.Date || b.IssueDate) - new Date(a.Date || a.IssueDate);
        });
};
export const CombinedBillingDataSelector = createSelector(
    [
        IsFetchingBillingPaymentsSelector,
        AdjustmentsSelector,
        InvoiceSummariesSelector,
        PaymentsSelector,
        CreditNotesSelector,
        DebitNotesSelector
    ],
    (isFetchingBillingPayments, adjustments, invoiceSummaries, payments, creditNotes, debitNotes) => {
        return !isFetchingBillingPayments ? getBillingDataEntries(adjustments, invoiceSummaries, payments, creditNotes, debitNotes) : [];
    }
);

const defaultCurrency = 'USD';
export const CombinedBillingCurrencyCodeSelector = createSelector(
    [CombinedBillingDataSelector],
    (combinedBilling = []) => {
        const currObj = combinedBilling.find((x) =>  {
            return x.CurrencyCode;
        });
        return currObj ? currObj.CurrencyCode : defaultCurrency;
    }
);

const EMPTY_TABLE_DATA = [];
export const TableDataSelector = createSelector(
    [
        CombinedBillingDataSelector,
        AdjustmentTypeCodeSelector,
        AdjustmentReasonsCodeSelector,
        MetadataCodeTypeSelector(CODES.PaymentInstrumentType),
        MetadataCodeTypeSelector(CODES.PointOfSalePaymentType),
        CreditNotesToPaymentMappingSelector,
        CurrentCustomerAccountingMethodSelector
    ],
    (views, adjustmentTypes, reasons, paymentInstrumentTypes, posPaymentTypes, creditToPaymentMapping, currentCustomerAccountingMethod) => {
        return paymentInstrumentTypes ? createTableBillingViewModel(views, adjustmentTypes, reasons, paymentInstrumentTypes, posPaymentTypes, creditToPaymentMapping, currentCustomerAccountingMethod) : EMPTY_TABLE_DATA;
    }
);

export const AdjustmentTypeValueOptions = createSelector(
    [AdjustmentTypeCodeSelector],
    (codes) => {
        return codes ? [{
            Value: null,
            Name: i18n.translate(LocaleKeys.ADJUSTMENTS.SELECT_ADJUSTMENT_TYPE)
        }].concat(codes.items.map((code) => {
            return {
                Name: code.Name,
                Value: code.Value
            };
        }).asMutable({
            deep: true
        })) : [];
    }
);

export const AdjustmentReasonCodeValueOptions = createSelector(
    [
        AdjustmentReasonsCodeSelector,
        CurrentCustomerIsExternallyManagedARSelector
    ],
    (codes, customerIsExternallyManaged) => {
        const items = codes ? codes.items : [];
        const reasonCodes = items.filter((code) => {
            let flag = true;
            if (!customerIsExternallyManaged) {
                flag = code.AdditionalProperties.find(propEq('external_reference', 'Key')).Value !== AR_TRANSFER_ADJUSTMENT_EXTERNAL_REFERENCE;
            }
            if (flag) {
                flag = (code.Value !== WRITEOFF_ADJUSTMENT && code.Value !== WRITEOFF_REVERSAL_ADJUSTMENT);
            }
            const isDepositTransferOrDepositUpdated = code.Value === DEPOSIT_TRANSFER || code.Value === DEPOSIT_UPDATED;
            return flag && !isDepositTransferOrDepositUpdated;
        });
        return reasonCodes.length ? [{
            Value: null,
            Name: i18n.translate(LocaleKeys.ADJUSTMENTS.SELECT_REASON_CODE)
        }].concat(reasonCodes.map((code) => {
            const reasonCode = {
                Name: code.Name,
                Value: code.Value
            };
            code.AdditionalProperties.forEach((additionalProperty) => {
                reasonCode[additionalProperty.Key] = additionalProperty.Value;
            });
            return reasonCode;
        }).asMutable({
            deep: true
        })) : [];
    }
);

export const AdjustmentReasonCodeForTaxSelectionOptions = createSelector(
    [AdjustmentReasonsCodeSelector],
    (codes) => {
        const items = codes ? codes.items : [];
        const reasonCodes = items.find((code) => {
            const selectedCode = code.AdditionalProperties.find(additionalProperty => {
                return additionalProperty.Key === WITHHELD_TAX_EXTERNAL_REF.Key && additionalProperty.Value === WITHHELD_TAX_EXTERNAL_REF.Value;
            });
            if (selectedCode) {
                return true;
            }
        });
        if (reasonCodes) {
            return [{
                Name: reasonCodes.Name,
                Value: reasonCodes.Value
            }];
        }
        return [];
    }
);

export const IsFetchingInvoiceContentSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.billingPaymentsInvoiceContent.isFetchingInvoiceContent;
    }
);

export const PageNumberSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.billingPayments.metadata.pageNumber;
    }
);

export const SelectedPageSizePreference = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.billingPayments.metadata.pageSize;
    }
);

export const RecordCountSelector = createSelector(
    [CombinedBillingDataSelector],
    (combinedBilling) => {
        return combinedBilling.length;
    }
);

export const PageCountSelector = createSelector(
    [RecordCountSelector, SelectedPageSizePreference],
    (recordCount, pageSize) => {
        return Math.ceil(recordCount / pageSize);
    }
);

export const PageSizeSelector = createSelector(
    [PageSizePreferenceSelector],
    (pageSize) => {
        return pageSize;
    }
);

export const StartRecordSelector = createSelector(
    [PageNumberSelector, SelectedPageSizePreference],
    (pageNumber, pageSize) => {
        return ((pageNumber - 1) * pageSize) + 1;
    }
);

export const EndRecordSelector = createSelector(
    [RecordCountSelector, PageNumberSelector, SelectedPageSizePreference],
    (recordCount, pageNumber, pageSize) => {
        const endNumber = (pageNumber * pageSize);
        if (endNumber < recordCount) {
            return endNumber;
        }
        return recordCount;
    }
);

export const BillingLedgerTableDataSelector = createSelector(
    [TableDataSelector],
    (tableData) => {
        return {
            data: tableData.data,
            columnDefs: tableData.columnDefs
        };
    }
);

export const InvoiceDetailsSummarySelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.billingPayments.invoiceDetails && selectedCustomer.billingPayments.invoiceDetails.InvoiceSummary;
    }
);

export const IsFetchingInvoiceDetailsSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.billingPayments.isFetchingInvoiceDetails;
    }
);

export const InvoiceDetailsPageCountSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.billingPayments.invoiceDetails.PageCount;
    }
);

export const InvoiceDetailsRecordCountSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.billingPayments.invoiceDetails.RecordCount;
    }
);

const EMPTY_INVOICE_LIST = [];
export const InvoiceListSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(EMPTY_INVOICE_LIST, ['billingPayments', 'invoiceList'], selectedCustomer);
    }
);

export const IsFetchingInvoiceListSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.billingPayments.isFetchingInvoiceList;
    }
);

export const InvoiceFilterSelector = createImmutableSelector(
    SelectedCustomerSelector,
    (selectedCustomer) => {
        return selectedCustomer.billingPayments.metadata.invoiceFilter;
    }
);


export const InvoiceListForDropDownSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(EMPTY_INVOICE_LIST, ['billingPayments', 'invoiceListDropdown'], selectedCustomer);
    }
);

export const InvoiceListWithTaxForDropDownSelector = createImmutableSelector(
    [InvoiceListForDropDownSelector],
    (InvoiceList) => {
        const $filter = getFilterService();
        const formattedInvoiceList = [];
        InvoiceList?.map((invoiceItem) => {
            if (invoiceItem.Invoice?.WithheldTaxDue > 0) {
                return invoiceItem;
            }
        }).forEach((item) => {
            if (item?.Invoice.WithheldTaxDue && item?.Invoice.WithheldTaxDue > 0) {
                formattedInvoiceList.push({
                    amount: item.Invoice.CurrentDue,
                    id: item.Invoice.InvoiceId,
                    text: `${i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.INVOICE_PREFIX)}${item.Invoice.InvoiceNumber} - ${$filter('invCurrency')(item.Invoice.CurrentDue, item.Invoice.CurrencyCode)}`,
                    type: INVOICE,
                    taxDueAmount: item.Invoice.WithheldTaxDue
                });
            }
        });
        return formattedInvoiceList;
    }
);

export const AdjustmentConfigSelector = createImmutableSelector(
    MetadataCodeTypeSelector(CODES.AdjustmentConfig),
    (adjustmentConfig) => {
        const adjustmentConfigObject =
            adjustmentConfig.find(item => {
                return !item.Global;
            }) ||
            adjustmentConfig.find(item => {
                return item.Global;
            });

        const resultsMap = {};

        if (adjustmentConfigObject) {
            adjustmentConfigObject.AdditionalProperties.forEach(config => {
                resultsMap[config.Key] = config.Value;
            });
        }
        return resultsMap;
    }
);
export const BillingPaymentsSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.billingPayments;
    }
);

export const BillingPaymentsOpenInvoicesSelector = createImmutableSelector(
    [BillingPaymentsSelector],
    (billingPayments) => {
        return billingPayments.openInvoices;
    }
);

export const TotalAccountBalanceSelector = createImmutableSelector(
    [BillingPaymentsSelector],
    (billingPayments) => {
        return billingPayments.totalAccountBalance;
    }
);

export const TotalCashReceivableSelector = createImmutableSelector(
    [BillingPaymentsSelector],
    (billingPayments) => {
        return billingPayments.totalCashReceivable;
    }
);

export const TotalWithheldTaxSelector = createImmutableSelector(
    [BillingPaymentsSelector],
    (billingPayments) => {
        return billingPayments.totalWithheldTax;
    }
);

export const PageCountOfLedgerSelector = createImmutableSelector(
    [BillingPaymentsSelector],
    (billingPayments) => {
        return billingPayments.pageCount;
    }
);

export const RecordCountOfLedgerSelector = createImmutableSelector(
    [BillingPaymentsSelector],
    (billingPayments) => {
        return billingPayments.recordCount;
    }
);

export const PageSizeOfLedgerSelector = createImmutableSelector(
    [BillingPaymentsSelector],
    (billingPayments) => {
        return billingPayments.metadata.pageSize;
    }
);

export const HierarchyInvoiceNodeSubscriberIdSelector = createImmutableSelector(
    [BillingPaymentsSelector],
    (billingPayments) => {
        return billingPayments.hierarchyInvoiceNodeSubscriberId;
    }
);

export const OpenInvoicesListMetadataSelector = createImmutableSelector(
    [BillingPaymentsOpenInvoicesSelector],
    (billingPaymentsOpenInvoices) => {
        return billingPaymentsOpenInvoices.metadata;
    }
);

export const OpenInvoicesListSelector = createImmutableSelector(
    [BillingPaymentsOpenInvoicesSelector],
    (billingPaymentsOpenInvoices) => {
        return formatOpenInvoicesList(billingPaymentsOpenInvoices.invoices);
    }
);

export const OpenInvoicesPageCountSelector = createImmutableSelector(
    [BillingPaymentsOpenInvoicesSelector],
    (billingPaymentsOpenInvoices) => {
        return billingPaymentsOpenInvoices.pageCount;
    }
);

export const OpenInvoicesRecordCountSelector = createImmutableSelector(
    [BillingPaymentsOpenInvoicesSelector],
    (billingPaymentsOpenInvoices) => {
        return billingPaymentsOpenInvoices.recordCount;
    });

export const OpenInvoicesCurrentPageSelector = createImmutableSelector(
    [OpenInvoicesListMetadataSelector],
    (openInvoicesListMetadata) => {
        return openInvoicesListMetadata.pageNumber;
    });

export const OpenInvoicesPageSizeSelector = createImmutableSelector(
    [OpenInvoicesListMetadataSelector],
    (openInvoicesListMetadata) => {
        return openInvoicesListMetadata.pageSize;
    });

export const IsFetchingOpenInvoicesSelector = createImmutableSelector(
    [BillingPaymentsOpenInvoicesSelector],
    (billingPaymentsOpenInvoices) => {
        return billingPaymentsOpenInvoices.isFetchingOpenInvoices;
    });

export const IsApplyingCreditNoteSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer ? selectedCustomer.isApplyingCreditNote : false;
    }
);

export const IsFetchingAdjustmentDetailsSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer ? selectedCustomer.billingPayments.isFetchingAdjustmentDetails : false;
    }
);

export const AdjustmentOrCreditNoteDetailsSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer ? selectedCustomer.billingPayments.adjustmentOrCreditNoteDetails : EMPTY_ARRAY;
    }
);

export const AdjustmentItemsTableDataSelector = createImmutableSelector(
    [AdjustmentOrCreditNoteDetailsSelector],
    (adjustmentDetails) => {
        const $filter = getFilterService();
        const currencyCode = adjustmentDetails && adjustmentDetails.AdjustmentSummary && adjustmentDetails.AdjustmentSummary.CurrencyCode;
        return adjustmentDetails && adjustmentDetails.AdjustmentDetails.length ? adjustmentDetails.AdjustmentDetails.map((adjustment) => {
            return {
                amount: adjustment.AppliedAmount,
                balance: adjustment.Balance,
                currencyCode: currencyCode,
                date: $filter('localShortDate')(adjustment.AppliedDate) || null,
                id : adjustment.InvoiceNumber || adjustment.DebitNoteAdjustmentNumber
            };
        }) : EMPTY_ARRAY;
    }
);

export const OpenInvoicesStartDateSelector = createImmutableSelector(
    [OpenInvoicesListMetadataSelector],
    (openInvoicesListMetadata) => {
        return openInvoicesListMetadata.startDate;
    });

export const OpenInvoicesEndDateSelector = createImmutableSelector(
    [OpenInvoicesListMetadataSelector],
    (openInvoicesListMetadata) => {
        return openInvoicesListMetadata.endDate;
    });

export const OpenInvoicesDateShortcutSelector = createImmutableSelector(
    [OpenInvoicesListMetadataSelector],
    (openInvoicesListMetadata) => {
        return openInvoicesListMetadata.dateShortcut;
    });

export const OpenInvoicesSearchTermSelector = createImmutableSelector(
    [OpenInvoicesListMetadataSelector],
    (openInvoicesListMetadata) => {
        return openInvoicesListMetadata.invoiceSearchTerm;
    });

export const BillingLedgerCurrentTabSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.billingPayments.currentTab;
    });

const getInvoiceActionItem = (invoiceItem, userSecurityAttributes, isCreditType) => {
    const invoiceActions = [];
    if (isCreditType) {
        invoiceActions.push({
            id: invoiceItem.Invoice.InvoiceId,
            actionId: CONVERT_TO_CREDIT,
            label: i18n.translate(LocaleKeys.CONVERT_TO_CREDIT),
        });
        return invoiceActions;
    }
    if (hasAccess(userSecurityAttributes, ONE_TIME_MAKE_PAYMENT)) {
        invoiceActions.push({
            id: invoiceItem.Invoice.InvoiceId,
            actionId: MAKE_PAYMENT_TYPES.INVOICE,
            label: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.MAKE_A_PAYMENT),
        });
    }
    if (hasAccess(userSecurityAttributes, PAYMENT_ADJUSTMENT_ACCESS)) {
        invoiceActions.push({
            id: invoiceItem.Invoice.InvoiceId,
            actionId: MISC_ADJUSTMENT,
            label: i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.MISC_ADJUSTMENT),
        });
    }
    if (hasAccess(userSecurityAttributes, DISPUTE_INVOICE)) {
        invoiceActions.push({
            id: invoiceItem.Invoice.InvoiceNumber,
            actionId: LocaleKeys.DISPUTE_INVOICE,
            label: i18n.translate(LocaleKeys.DISPUTE_INVOICE),
        });
    }
    return invoiceActions;
};

export const InvoiceListForOpenInvoiceWidgetSelector = createImmutableSelector(
    [
        BillingPaymentsOpenInvoicesSelector,
        UserSecurityAttributesSelector
    ],
    (invoiceList, userSecurityAttributes) => {
        const openInvoiceWidgetData = [];
        const balanceLabel = i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.BALANCE);
        const chargeLabel = i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.CHARGE);
        const dueDateLabel = i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.DUE_DATE);
        const accountBalanceLabel = i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.ACCOUNT_BALANCE_FROM_LAST_INVOICE);
        const accountBalanceToolTipLabel = i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.ACCOUNT_BALANCE_TOOLTIP_LABEL);

        invoiceList.invoices.forEach((invoiceItem) => {
            const isCreditType = invoiceItem.Invoice.CurrentDue < 0;
            openInvoiceWidgetData.push({
                actions: getInvoiceActionItem(invoiceItem, userSecurityAttributes, isCreditType),
                link: {
                    label: invoiceItem.Invoice.InvoiceNumber,
                    id: invoiceItem.Invoice.InvoiceId,
                },
                values: [{
                    label:balanceLabel,
                    value: getFilterService()('invCurrency')(invoiceItem.Invoice.CurrentDue, invoiceItem.Invoice.CurrencyCode)
                },
                {
                    label: chargeLabel,
                    value: getFilterService()('invCurrency')(invoiceItem.Invoice.Balance, invoiceItem.Invoice.CurrencyCode)
                },
                {
                    label: dueDateLabel,
                    value: getFilterService()('date')(invoiceItem.Invoice.DueDate, 'shortDate')
                },
                ...(invoiceItem.Invoice.PreviousInvoiceBalance && invoiceItem.Invoice.PreviousInvoiceBalance !== 0) ?
                    [{
                        label: accountBalanceLabel,
                        tooltipText: accountBalanceToolTipLabel,
                        value: invoiceItem.Invoice.PreviousInvoiceBalance ? getFilterService()('invCurrency')(invoiceItem.Invoice.PreviousInvoiceBalance, invoiceItem.Invoice.CurrencyCode) : getFilterService()('invCurrency')(0, invoiceItem.Invoice.CurrencyCode)
                    }] : []
                ]
            });
        });
        return openInvoiceWidgetData;
    }
);
