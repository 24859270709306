import Immutable from 'seamless-immutable';
import flatten from 'ramda/src/flatten';
import pluck from 'ramda/src/pluck';
import pathOr from 'ramda/src/pathOr';
import {createSelector} from 'reselect';
import LocaleKeys from '../../locales/keys';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {MetadataCodeTypeDictionarySelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {
    CompletedOfferingAdditionalPropertyFieldsSelector,
    OrderAdditionalPropertyFieldsSelector
} from 'invision-core/src/components/metadata/codes/codes.additional.properties.selectors';
import {ShippingMethodsSelector} from 'invision-core/src/components/metadata/shippingMethods/shipping.methods.selectors';
import {
    ALL_STEPS_KEYS,
    TransitionStates
} from '../constants/transition.offer.wizard.constants';
import {
    AvailableCreditFromQuote,
    Cart,
    DeliveryMethodOptionsSelector,
    DeliveryOptionsByPricingPlanHelper,
    EditCart,
    EditOption,
    EditOptions,
    EditSelectedFacetIds,
    EditSelectedOfferId,
    FinanceCreditFromQuoteSelector,
    FormattedAttributes,
    FormattedDeliveryDecisions,
    FormattedPhysicalAttributes,
    FormAttributeGroupsHelper,
    FormPhysicalAttributeGroupsHelper,
    GetAdditionalPropertyListMapSelector,
    GetCartAdditionalPropertiesSelector,
    GetOfferingOrderQuoteSelector,
    GetOfferQuoteSelector,
    GetOrderAdditionalPropertiesSelector,
    GetPostQuoteDueTotalsSelector,
    GetPreQuoteDueSubTotalsSelector,
    GetQuoteHasInvoiceItemsSelector,
    GetTotalDownPaymentAmountSelector,
    IsCreditLimitBreachedFromQuote,
    IsSendToStoreButtonEnabledSelectorCreator,
    OfferOptionViewModel,
    PaymentInfo,
    SelectedEditOption,
    SelectedOfferId,
    ServiceAddress,
    ShippingAddressWizardSelector,
    SubscriberProductIdUndefinedSelector,
    SummaryTab,
    ShippingAddressDropdownOptionsWizardSelector,
    TaxLocationDecisionsByPricingPlanHelper
} from '../helpers/offer.ordering.wizard.selector.helper';
import {
    BackButtonText,
    CompletedSteps,
    CurrentStep,
    CurrentStepIndex,
    CurrentStepIsInvalid,
    FormattedAddressCityState,
    FormattedName,
    ForwardButtonText,
    IsActiveTransitionStep,
    SelectedTabIndex,
    SkippedStep,
    StepProgressBarViewModel,
    TabPanelViewModel
} from '../helpers/wizard.selector.helper';
import {
    ShoppingCartSelector,
    SelectedOfferingPhysicalInventoriesSelector
} from './selected.offering.order.selectors';
import {
    CurrentCustomerCurrencyCodeSelector,
    CurrentCustomerSelector,
    RouteParams
} from '../../reducers/selectors/customer.selectors';
import {
    IsQuoteBypassedSelector,
    OfferingOrderAsyncIdSelector,
    OfferingOrderQuoteSelector,
    OffersSelector,
    QuoteCalculatedSelector,
    OfferingOrderFinanceCreditLimitSelector,
    SelectedInventoryStoresSelector,
    SubTotalAmountSelector,
    TaxAmountSelector,
    TotalAmountSelector,
    TotalRemainingAmountSelector
} from './offering.order.selectors';
import {SelectedContractSelector} from './contract.selectors';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {EMPTY_ARRAY} from '../constants/common.constants';
import {ShippingAddressesSelector} from './customer.addresses.selectors';

const OfferingOrderSelector = (state) => {
    return state.customercare.offeringOrder;
};

const TransitionOfferWizardStoreSelector = state => {
    return state.customercare.recoverableUiState.transitionOfferWizard;
};

const DEFAULT_CUSTOMER = {};
export const CustomerInfoSelector = createSelector(
    [CurrentCustomerSelector],
    (customer) => {
        if (customer) {
            return customer;
        } else {
            return DEFAULT_CUSTOMER;
        }
    }
);

const CartSelector = Cart([TransitionOfferWizardStoreSelector]);

export const CurrentStepIndexSelector = CurrentStepIndex([TransitionOfferWizardStoreSelector]);

export const CurrentStepIsInvalidSelector = CurrentStepIsInvalid([TransitionOfferWizardStoreSelector]);

export const CurrentStepSelector = CurrentStep([CurrentStepIndexSelector], ALL_STEPS_KEYS);
export const IsCheckoutStepSelector = IsActiveTransitionStep([CurrentStepSelector], LocaleKeys.WIZARD.STEPS.CHECKOUT);
export const IsOffersStepSelector = IsActiveTransitionStep([CurrentStepSelector], LocaleKeys.WIZARD.STEPS.OFFERS);
export const IsCompareOffersStepSelector = IsActiveTransitionStep([CurrentStepSelector], LocaleKeys.WIZARD.STEPS.COMPARE_OFFERS);
export const IsDecisionsStepSelector = IsActiveTransitionStep([CurrentStepSelector], LocaleKeys.WIZARD.STEPS.DECISIONS);
export const IsAttributesStepSelector = IsActiveTransitionStep([CurrentStepSelector], LocaleKeys.WIZARD.STEPS.ATTRIBUTES);

export const CompletedStepsSelector = CompletedSteps([TransitionOfferWizardStoreSelector]);

export const EditCartSelector = EditCart([TransitionOfferWizardStoreSelector]);

export const SelectedEditOptionSelector = SelectedEditOption([EditCartSelector]);

export const EditSelectedOfferIdSelector = EditSelectedOfferId([EditCartSelector]);

export const EditSelectedFacetIdsSelector = EditSelectedFacetIds([EditCartSelector]);

export const EditOptionsSelector = EditOptions([EditCartSelector]);

export const EditOptionSelector = EditOption([SelectedEditOptionSelector, EditOptionsSelector]);

export const ServiceAddressSelector = ServiceAddress([TransitionOfferWizardStoreSelector]);

export const FormattedCityStateSelector = FormattedAddressCityState([ServiceAddressSelector]);

export const FormattedNameSelector = FormattedName([CustomerInfoSelector]);

export const SkippedStepSelector = SkippedStep([TransitionOfferWizardStoreSelector]);

export const SelectedOfferIdSelector = SelectedOfferId([CartSelector]);

export const FormattedAttributesSelector = FormattedAttributes([EditCartSelector]);

export const FormattedPhysicalAttributesSelector = FormattedPhysicalAttributes([EditCartSelector, SelectedOfferIdSelector, SelectedInventoryStoresSelector]);

export const IsSubscriberProductIdUndefinedSelector = SubscriberProductIdUndefinedSelector([FormattedPhysicalAttributesSelector]);

export const SelectedTabIndexSelector = SelectedTabIndex([TransitionOfferWizardStoreSelector]);

export const IsSummaryTabVisibleSelector = createSelector(
    [SelectedTabIndexSelector],
    (selectedTabIndex) => {
        return selectedTabIndex === 0;
    }
);

export const TabPanelViewModelSelector = TabPanelViewModel([SelectedTabIndexSelector]);

export const PaymentInfoSelector = PaymentInfo([CartSelector]);

export const PaymentInfoEditSelector = PaymentInfo([EditCartSelector]);

export const OfferQuoteSelector = GetOfferingOrderQuoteSelector([OfferingOrderQuoteSelector]);

export const TransitionOfferQuoteSelector = GetOfferQuoteSelector([TransitionOfferWizardStoreSelector]);

export const OfferQuoteFinanceCreditLimitSelector = OfferingOrderFinanceCreditLimitSelector;

export const AvailableFinanceCreditFromQuoteSelector = FinanceCreditFromQuoteSelector([OfferingOrderFinanceCreditLimitSelector]);

export const AvailableCreditFromQuoteSelector = AvailableCreditFromQuote([OfferQuoteSelector]);

export const IsCreditLimitBreachedFromQuoteSelector = IsCreditLimitBreachedFromQuote([OfferQuoteSelector]);

export const TotalDownPaymentSelector = GetTotalDownPaymentAmountSelector([TransitionOfferQuoteSelector]);

export const PostQuoteDueTotalsSelector = GetPostQuoteDueTotalsSelector([OfferQuoteSelector]);

export const PreQuoteDueSubTotalsSelector = GetPreQuoteDueSubTotalsSelector([ShoppingCartSelector]);

export const QuoteHasInvoiceItemsSelector = GetQuoteHasInvoiceItemsSelector([OfferQuoteSelector]);

export const ServiceTaxDecisionsSelector = createImmutableSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.serviceTaxDecisions || EMPTY_ARRAY;
    }
);


export const SummaryTabViewModelSelector = SummaryTab([
    ShoppingCartSelector,
    SubTotalAmountSelector,
    TaxAmountSelector,
    TotalAmountSelector,
    QuoteCalculatedSelector,
    SelectedContractSelector,
    TotalRemainingAmountSelector,
    () => {
        return undefined;
    },
    TotalDownPaymentSelector,
    PostQuoteDueTotalsSelector,
    PreQuoteDueSubTotalsSelector
]);

export const TransitionIsAllowedSelector = createSelector(
    [MetadataCodeTypeDictionarySelector(CODES.TransitionConfiguration)],
    (transitionConfiguration) => {
        return pathOr(false, [Object.keys(transitionConfiguration)[0], 'AdditionalProperties', 'AllowTransitioning'], transitionConfiguration) === 'True';
    }
);

const OfferingOrderDataSelector = createSelector(
    [OfferingOrderSelector],
    (offeringOrder) => {
        return offeringOrder.data;
    }
);

export const SelectedOfferingResponseIdSelector = createSelector(
    [OfferingOrderDataSelector],
    (offeringOrderData) => {
        return offeringOrderData.selectedOffering.data.rocResponseOfferId;
    }
);

const AvailableOfferingsDataSelector = createSelector(
    [OfferingOrderDataSelector],
    (orderStoreData) => {
        return orderStoreData.availableOfferings.data;
    }
);

export const BackButtonTextSelector = BackButtonText(
    [
        CurrentStepIndexSelector,
        CompletedStepsSelector
    ], ALL_STEPS_KEYS
);

export const EditSelectedOfferingChargeTypeIdSelector = createSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.selectedOfferingChargeTypeIds;
    }
);

export const ForwardButtonTextSelector = ForwardButtonText(
    [
        CurrentStepIndexSelector,
        SkippedStepSelector
    ], ALL_STEPS_KEYS
);

export const OfferingsSelector = createSelector(
    [AvailableOfferingsDataSelector],
    (availableOfferingsData) => {
        return availableOfferingsData.offeringsMap;
    }
);

export const OfferOptionViewModelSelector = OfferOptionViewModel([
    OffersSelector,
    EditSelectedOfferIdSelector
]);

export const StepProgressBarViewModelSelector = StepProgressBarViewModel(
    [
        CurrentStepSelector,
        CompletedStepsSelector,
        SkippedStepSelector
    ], ALL_STEPS_KEYS
);

export const TransitionContextSelector = createSelector(
    [TransitionOfferWizardStoreSelector],
    (transitionStore) => {
        return transitionStore.transitionContext;
    }
);

export const TransitionOfferingsMetadataSelector = createImmutableSelector(
    [TransitionOfferWizardStoreSelector],
    (transitionStore) => {
        return transitionStore.offeringsMetadata;
    }
);

export const TransitionCurrentOfferingsSelector = createSelector(
    [TransitionContextSelector, TransitionOfferingsMetadataSelector],
    (transitionContext, offeringsMetadata) => {
        return transitionContext && transitionContext.CurrentOfferings ? transitionContext.CurrentOfferings.map((currentOffer) => {
            const offer = offeringsMetadata.find((offeringMetadata) => {
                return offeringMetadata.Id === currentOffer.Id;
            });
            return Object.assign({}, currentOffer, {
                DisplayName: offer ? offer.DisplayName : undefined,
                Name: offer ? offer.Name : currentOffer.Name
            });
        }) : [];
    }
);

export const TransitionOfferingsSelector = createSelector(
    [
        TransitionContextSelector,
        TransitionOfferingsMetadataSelector
    ],
    (transitionContext, offeringsMetadata) => {
        return transitionContext && transitionContext.TransitionOfferings ? transitionContext.TransitionOfferings.map((currentOffer) => {
            const offer = offeringsMetadata.find((offeringMetadata) => {
                return offeringMetadata.Id === currentOffer.Id;
            });
            return Object.assign({}, currentOffer, {
                DisplayName: offer ? offer.DisplayName : undefined,
                Name: offer ? offer.Name : currentOffer.Name
            });
        }) : [];
    }
);

export const TransitionOutcomesSelector = createSelector(
    [TransitionContextSelector],
    (transitionContext) => {
        return transitionContext && transitionContext.Outcomes || {};
    }
);

export const TransitionParsedOutcomesSelector = createSelector(
    [
        TransitionOutcomesSelector,
        TransitionCurrentOfferingsSelector,
        TransitionOfferingsSelector
    ],
    (outcomes, currentOfferings, transitionOfferings) => {
        const emptyOutcomes = !Object.keys(outcomes).length && outcomes.constructor === Object;
        return !emptyOutcomes ? outcomes.asMutable({
            deep: true
        }).map(outcome => {
            const currentAmount = getOutcomeAmount(
                    outcome,
                    currentOfferings
                ), transitionAmount = getOutcomeAmount(
                    outcome,
                    transitionOfferings
                );
            const remainingbalance = getOutcomeRemainingBalance(outcome, currentOfferings);
            const transitionRemainingBalance = getOutcomeRemainingBalance(outcome, transitionOfferings);

            const isAddition = outcome.TransitionState === TransitionStates.ADDED,
                isRemoval = outcome.TransitionState === TransitionStates.REMOVED,
                isChange = outcome.TransitionState === TransitionStates.CHANGED &&
                    !!currentAmount && !!transitionAmount &&
                    (currentAmount !== transitionAmount);
            return {
                current: {
                    title: !isAddition ? getOutcomeTitle(
                        outcome,
                        currentOfferings
                    ) : '',
                    discountedPrice: !isAddition ? getOutcomeDiscount(
                        outcome,
                        currentOfferings
                    ) : null,
                    updatedPrice: !isAddition ? getUpdatedPrice(
                        outcome,
                        currentOfferings
                    ) : null,
                    price: !isAddition ? currentAmount : null,
                    remainingbalance: !isAddition ? remainingbalance : null,
                    termAmount: !isAddition ?  getTermAmount(
                        outcome,
                        currentOfferings
                    ) : null
                },
                transition: {
                    title: !isRemoval ? getOutcomeTitle(
                        outcome,
                        transitionOfferings
                    ) : '',
                    discountedPrice: !isRemoval ? getOutcomeDiscount(
                        outcome,
                        transitionOfferings
                    ) : null,
                    updatedPrice: !isRemoval ? getUpdatedPrice(
                        outcome,
                        transitionOfferings
                    ) : null,
                    price: !isRemoval ? transitionAmount : null,
                    remainingbalance: !isAddition ? transitionRemainingBalance : null,
                    termAmount: !isRemoval ?  getTermAmount(
                        outcome,
                        transitionOfferings
                    ) : null
                },
                isOrphaned: outcome.PendingOfferingSeparation,
                isAddition,
                isRemoval,
                isChange
            };
        }) : [];
    }
);

export const TransitionCurrentOffersSavingsSelector = createSelector(
    [TransitionParsedOutcomesSelector],
    (parsedOutcomes) => {
        let currentOfferSavingsTotal = 0;
        if (parsedOutcomes && parsedOutcomes.length > 0) {
            parsedOutcomes.forEach((outcome) => {
                currentOfferSavingsTotal += outcome.current.discountedPrice;
            });
        }

        return currentOfferSavingsTotal;
    }
);

export const TransitionOffersSavingsSelector = createSelector(
    [TransitionParsedOutcomesSelector],
    (parsedOutcomes) => {
        let transitionOfferSavingsTotal = 0;
        if (parsedOutcomes && parsedOutcomes.length > 0) {
            parsedOutcomes.forEach((outcome) => {
                transitionOfferSavingsTotal += outcome.transition.discountedPrice;
            });
        }

        return transitionOfferSavingsTotal;
    }
);

const getOutcomeDetailsFromOfferingItems = (outcome, offerings) => {
    const items = flatten(pluck('Items', offerings));
    return items.find(item => {
        return item.ProductId === outcome.ProductId && item.PricingPlanId === outcome.PricingPlanId;
    });
};

const getOutcomeTitle = (outcome, items) => {
    const outcomeDetails = getOutcomeDetailsFromOfferingItems(outcome, items);
    return outcomeDetails ?
        `${outcomeDetails.ProductName}: ${outcomeDetails.PlanName}` :
        '';
};

const getOutcomeAmount = (outcome, items) => {
    const outcomeDetails = getOutcomeDetailsFromOfferingItems(outcome, items);
    return outcomeDetails ? outcomeDetails.Amount : null;
};

const getOutcomeRemainingBalance = (outcome, items) => {
    const outcomeDetails = getOutcomeDetailsFromOfferingItems(outcome, items);
    return outcomeDetails ? outcomeDetails.RemainingBalance : null;
};

const getOutcomeDiscount = (outcome, items) => {
    const outcomeDetails = getOutcomeDetailsFromOfferingItems(outcome, items);
    return outcomeDetails ? outcomeDetails.Discount : null;
};


///TODO: Remove Calculation logic from UI and obtain value from API results with UI story : ASC-99221
/// API Story https://jira.csgicorp.com/browse/ASC-99219
const getUpdatedPrice = (outcome, items) => {
    const outcomeDetails = getOutcomeDetailsFromOfferingItems(outcome, items);
    return outcomeDetails ? outcomeDetails.Amount - outcomeDetails.Discount : null;
};

const getTermAmount = (outcome, items) => {
    const outcomeDetails = getOutcomeDetailsFromOfferingItems(outcome, items);
    return outcomeDetails ? outcomeDetails.TermAmount : null;
};

const EMPTY_ATTRIBUTE_GROUPS = Immutable([]);
export const EditAttributeGroupsSelector = createSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.editAttributeGroups ? editCart.editAttributeGroups.asMutable({
            deep: true
        }) : EMPTY_ATTRIBUTE_GROUPS;
    }
);

export const DeliveryDecisionsSelector = createImmutableSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.deliveryDecisions || EMPTY_ARRAY;
    }
);

export const DeliveryOptionsByPricingPlanSelector = DeliveryOptionsByPricingPlanHelper([DeliveryDecisionsSelector, ShippingMethodsSelector]);

export const FormattedDeliveryDecisionsSelector = FormattedDeliveryDecisions([DeliveryDecisionsSelector]);

export const EditPhysicalAttributeGroupsSelector = createSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.editPhysicalAttributeGroups ? editCart.editPhysicalAttributeGroups.asMutable({
            deep: true
        }) : EMPTY_ATTRIBUTE_GROUPS;
    }
);
//Passing serviceAttribute for Edit and Change offer
//https://jira.csgicorp.com/browse/ASCBR-1369
export const FormAttributeGroups = FormAttributeGroupsHelper([EditAttributeGroupsSelector, MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute)]);
export const FormPhysicalAttributeGroups = FormPhysicalAttributeGroupsHelper([EditPhysicalAttributeGroupsSelector]);

export const TransitionLastAttemptErrorSelector = createSelector(
    [TransitionOfferWizardStoreSelector],
    (transitionStore) => {
        return transitionStore.lastAttemptError;
    }
);

export const TransitionOfferSubmittedCOSSelector = createSelector(
    [TransitionOfferWizardStoreSelector],
    (transitionStore) => {
        return transitionStore.submittedCOS.Order;
    }
);

export const IsSubmittingCOSOrderSelector = createSelector(
    [TransitionOfferWizardStoreSelector],
    (transitionStore) => {
        return transitionStore.isSubmittingCOSOrder;
    }
);

export const IsFetchingCurrentOfferSelector = createSelector(
    [TransitionOfferWizardStoreSelector],
    (transitionOfferWizardStor) => {
        return Immutable(transitionOfferWizardStor.isFetchingCurrentOffer);
    }
);

export const TransitionOfferingIdSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return Immutable(routeParams.offeringId);
    }
);

export const TransitionOfferingInstanceIdSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return Immutable(routeParams.offeringInstanceId);
    }
);

export const IsSendToStoreButtonEnabledSelector = IsSendToStoreButtonEnabledSelectorCreator([
    SelectedOfferingPhysicalInventoriesSelector,
    IsAttributesStepSelector,
    IsCheckoutStepSelector,
    OfferingOrderAsyncIdSelector
]);

export const IsResumingStoreOrderSelector = createImmutableSelector(
    [TransitionOfferWizardStoreSelector],
    (changeOfferWizardStore) => {
        return changeOfferWizardStore.isResumingStoreOrder;
    }
);

export const IsShippingRequiredSelector = createSelector(
    [ShoppingCartSelector],
    (shoppingCart) => {
        return pathOr(false, ['ShippingRequired'], shoppingCart);
    }
);

export const CartAdditionalPropertiesSelector = GetCartAdditionalPropertiesSelector([TransitionOfferWizardStoreSelector]);

export const CartAdditionalPropertyListMapSelector = GetAdditionalPropertyListMapSelector([CartAdditionalPropertiesSelector, CompletedOfferingAdditionalPropertyFieldsSelector]);

export const OrderAdditionalPropertiesSelector = GetOrderAdditionalPropertiesSelector([TransitionOfferWizardStoreSelector]);

export const OrderAdditionalPropertyListMapSelector = GetAdditionalPropertyListMapSelector([OrderAdditionalPropertiesSelector, OrderAdditionalPropertyFieldsSelector]);

export const ShippingAddressSelector = ShippingAddressWizardSelector([IsQuoteBypassedSelector, OfferingOrderDataSelector, ShippingAddressesSelector]);

export const ShippingAddressDropdownOptionsSelector = ShippingAddressDropdownOptionsWizardSelector([OfferingOrderDataSelector, ShippingAddressesSelector]);

export const TransitionOfferDeliveryMethodOptionsSelector = DeliveryMethodOptionsSelector([ShippingMethodsSelector, CurrentCustomerCurrencyCodeSelector]);

export const TaxLocationDecisionsByPricingPlanChangeOfferSelector = TaxLocationDecisionsByPricingPlanHelper([ServiceTaxDecisionsSelector]);
