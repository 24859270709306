import {createSelector} from 'reselect';
import moment from 'moment';
import Immutable from 'seamless-immutable';
import {
    addIndex,
    filter,
    flatten,
    forEach,
    isEmpty,
    join,
    last,
    map,
    path,
    pathOr,
    pipe,
    prop,
    propEq,
    reduce,
    reject,
    sort,
    sortBy
} from 'ramda';
import {
    PermissionService,
    SessionSelectors
} from 'invision-core';

import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {getFormattedServiceAttributeValue} from './services.list.selectors.helper';

import CustomerCareLocaleKeys from '../../locales/keys';
import {getBoolOrDefault} from 'invision-core/src/components/helpers/bool.helper';
import {SelectedCustomerSelector} from './customer.selectors';
import {ConvergentBillerSubscriberSummaryServicesSelector} from './customer.convergent.biller.selectors';
import {
    findLastSelectedRating,
    getFormattedName,
    sortMemberListByAlpheticalOrder
} from '../../components/customer/household/household.helpers';
import {HOUSEHOLD_ADMIN_ACCESS} from '../../security.attributes';
import {PaymentInstrumentsWithTypeNamesViewModel} from './customer.ewallet.selectors';
import {
    ACTIVATE_MEMBER_OPTION_ID,
    EDIT_PRIVILEGES_ID,
    HOUSEHOLD_OPTION_ACTIVATE_ID,
    HOUSEHOLD_OPTION_EDIT_ID,
    HOUSEHOLD_OPTION_REMOVE_ID,
    REMOVE_MEMBER_OPTION_ID
} from '../../components/customer/household/household.constants';
import {NA} from '../constants/billing.payments.constants';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {MetadataCodeTypeDictionarySelector} from 'invision-core/src/components/metadata/codes/codes.selectors';

const EMPTY_ARRAY = [];

const IMMUTABLE_EMPTY_ARRAY = Immutable([]);
const IMMUTABLE_EMPTY_OBJECT = Immutable({});
const ACTIVE = 1;
const REMOVED = 2;

export const ACTIVATE_HOUSEHOLD = {
    id: HOUSEHOLD_OPTION_ACTIVATE_ID,
    title: CustomerCareLocaleKeys.HOUSEHOLD.REACTIVATE_HOUSEHOLD
};

export const ACTIVATE_MEMBER = {
    id: ACTIVATE_MEMBER_OPTION_ID,
    title: CustomerCareLocaleKeys.HOUSEHOLD.REACTIVATE_MEMBER
};

export const EDIT_HOUSEHOLD = {
    id: HOUSEHOLD_OPTION_EDIT_ID,
    title: CustomerCareLocaleKeys.HOUSEHOLD.EDIT
};

export const EDIT_PRIVILEGES = {
    id: EDIT_PRIVILEGES_ID,
    title: CustomerCareLocaleKeys.HOUSEHOLD.EDIT_PRIVILEGES
};

export const REMOVE_HOUSEHOLD = {
    id: HOUSEHOLD_OPTION_REMOVE_ID,
    title: CustomerCareLocaleKeys.HOUSEHOLD.REMOVE_HOUSEHOLD
};

export const REMOVE_MEMBER = {
    id: REMOVE_MEMBER_OPTION_ID,
    title: CustomerCareLocaleKeys.HOUSEHOLD.REMOVE_MEMBER
};

export const HOUSEHOLD_ACCESS = {
    ENABLE_PROMOTE_TO_FULL_ACCESS: 'enable_promote_to_full_access',
    ENABLE_ACCESS_CONTROL: 'enable_access_control',
    ENABLE_PARENTAL_CONTROL: 'enable_parental_control',
    ENABLE_SERVICE_LEVEL_MANAGEMENT: 'enable_service_level_management'
};


//Returns if the CSR can access households via Security Attributes
//BusinessUnit enabled is checked via IsBusinessUnitEnabled in customer.actions.template.selectors
export const CsrHasHouseholdAdminAccessSelector = createSelector(
    [SessionSelectors.UserSecurityAttributesSelector],
    (securityAttributeSelector = {}) => {
        return PermissionService.hasAdminAccess(securityAttributeSelector, HOUSEHOLD_ADMIN_ACCESS);
    }
);

const recoverableUIStateSelector = (state) => {
    return pathOr(null, ['customercare', 'recoverableUiState', 'household'], state);
};

export const CurrentHouseholdSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.household.data;
    }
);

export const EditHouseholdDataSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.household.editHouseholdData;
    }
);

const removeAssociatedServiceFromAvailable = (availableServices, serviceToBeRemoved) => {
    return reject(propEq(serviceToBeRemoved.ServiceIdentifier.Value, 'serviceValue'), availableServices);
};

const RecoverableMemberPrivilegesSelector = (state) => {
    return pathOr(null, ['customercare', 'recoverableUiState', 'memberPrivileges'], state);
};

export const RecoverablePrivilegesSelector = createSelector(
    [RecoverableMemberPrivilegesSelector],
    (uiState) => {
        return uiState.privileges;
    }
);

export const HouseholdServicesAssociations = createSelector(
    [CurrentHouseholdSelector,
        ConvergentBillerSubscriberSummaryServicesSelector,
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression),
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute)],
    (currentHousehold, subscriberServices, regularExpressionCodeTable, serviceAttributeCodeTable) => {
        let householdServicesAssociations = [];

        if (currentHousehold && currentHousehold.MemberToServiceAssociations) {
            householdServicesAssociations = currentHousehold.MemberToServiceAssociations.map((memberToServiceAssociation) => {

                // !TODO: Optimize
                if (subscriberServices) {
                    subscriberServices = removeAssociatedServiceFromAvailable(subscriberServices, memberToServiceAssociation);
                }
                return Object.assign({}, memberToServiceAssociation.asMutable(), {
                    ServiceFriendlyName: memberToServiceAssociation.ServiceIdentifier.FriendlyName || memberToServiceAssociation.ServiceIdentifier.Value,
                    ServiceIdentifier: memberToServiceAssociation.ServiceIdentifier.Value,
                    ServiceIdentifierFormatted: getFormattedServiceAttributeValue(
                        memberToServiceAssociation.ServiceIdentifier.ServiceAttributeId,
                        memberToServiceAssociation.ServiceIdentifier.Value,
                        serviceAttributeCodeTable,
                        regularExpressionCodeTable)
                });
            });
        }

        if (currentHousehold && subscriberServices) {
            householdServicesAssociations = householdServicesAssociations.concat(subscriberServices.map((subscriberService) => {
                return {
                    ServiceFriendlyName: subscriberService.serviceFriendlyName || subscriberService.serviceValue,
                    ServiceIdentifier: subscriberService.serviceValue,
                    ServiceIdentifierFormatted: subscriberService.serviceValueFormatted,
                    SubscriberId: currentHousehold.HeadOfHouseholdSubscriberId
                };
            }));
        }
        return householdServicesAssociations;
    }
);

//Returns all Household Members
export const HouseholdMembersSelector = createSelector(
    [CurrentHouseholdSelector,
        ConvergentBillerSubscriberSummaryServicesSelector,
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression),
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute)],
    (currentHousehold, subscriberServices, regularExpressionCodeTable, serviceAttributeCodeTable) => {
        let householdMembers = [];
        if (currentHousehold && currentHousehold.Members.length) {
            const membersServicesAssociations = {};

            if (currentHousehold.MemberToServiceAssociations) {
                currentHousehold.MemberToServiceAssociations.forEach((memberToServiceAssociation) => {
                    if (!membersServicesAssociations[memberToServiceAssociation.SubscriberId]) {
                        membersServicesAssociations[memberToServiceAssociation.SubscriberId] = [];
                    }

                    // !TODO: Optimize
                    if (subscriberServices) {
                        subscriberServices = removeAssociatedServiceFromAvailable(subscriberServices, memberToServiceAssociation);
                    }

                    membersServicesAssociations[memberToServiceAssociation.SubscriberId].push({
                        ServiceFriendlyName: memberToServiceAssociation.ServiceIdentifier.FriendlyName || memberToServiceAssociation.ServiceIdentifier.Value,
                        ServiceIdentifier: memberToServiceAssociation.ServiceIdentifier.Value,
                        ServiceIdentifierFormatted: getFormattedServiceAttributeValue(
                            memberToServiceAssociation.ServiceIdentifier.ServiceAttributeId,
                            memberToServiceAssociation.ServiceIdentifier.Value,
                            serviceAttributeCodeTable,
                            regularExpressionCodeTable)
                    });
                });
            }
            householdMembers = currentHousehold.Members.map((member) => {
                let memberServices = membersServicesAssociations[member.SubscriberId] || [];

                if ((member.SubscriberId === currentHousehold.HeadOfHouseholdSubscriberId) && path(['length'], subscriberServices)) {
                    memberServices = memberServices.concat(subscriberServices.map((subscriberService) => {
                        return {
                            ServiceFriendlyName: subscriberService.serviceFriendlyName || subscriberService.serviceValue,
                            ServiceIdentifier: subscriberService.serviceValue,
                            ServiceIdentifierFormatted: subscriberService.serviceValueFormatted
                        };
                    }));
                }

                return member
                    .set('formattedName', getFormattedName(member))
                    .set('services', memberServices);
            });
        }

        return householdMembers;
    }
);

export const SelectedHouseholdMemberIdSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState ? uiState.selectedHouseholdMemberId : null;
    }
);

export const HeadOfHousholdSubscriberIdSelector = createSelector(
    [CurrentHouseholdSelector],
    (currentHousehold) => {
        return currentHousehold ? currentHousehold.HeadOfHouseholdSubscriberId : null;
    }
);

export const IncludeRemovedSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState && uiState.filterData ? uiState.filterData.includeRemoved : false;
    }
);

//Returns all household members that are NOT head of household
export const HouseholdChildMembersSelector = createSelector(
    [HeadOfHousholdSubscriberIdSelector, HouseholdMembersSelector, IncludeRemovedSelector],
    (hohSubscriberId, members, showRemoved) => {
        return members.filter((member) => {
            return showRemoved
                ? member.SubscriberId !== hohSubscriberId
                : member.SubscriberId !== hohSubscriberId && member.Status === ACTIVE;
        });
    }
);

export const HeadOfHouseholdSelector = createSelector(
    [HeadOfHousholdSubscriberIdSelector, HouseholdMembersSelector],
    (hohSubscriberId, members) => {
        return members.find((member) => {
            return member.SubscriberId === hohSubscriberId;
        }) || null;
    }
);

export const HouseholdChildMembersSortedByName = createSelector(
    [HeadOfHouseholdSelector, HouseholdChildMembersSelector],
    (headOfHousehold, members) => {
        if (members.length) {
            const mutableMembers = members.asMutable({
                deep:true
            });

            return sortMemberListByAlpheticalOrder(mutableMembers, headOfHousehold, false);
        } else {
            return IMMUTABLE_EMPTY_ARRAY;
        }
    }
);

export const ShowHouseholdSelector = createImmutableSelector(
    [CurrentHouseholdSelector, IncludeRemovedSelector],
    (currentHousehold, includeRemoved) => {
        return includeRemoved ? includeRemoved
            : ((currentHousehold && currentHousehold.Status) ? currentHousehold.Status === ACTIVE : true);
    }
);

export const HousholdMembersSorted = createSelector(
    [HeadOfHouseholdSelector, HouseholdChildMembersSortedByName, IncludeRemovedSelector],
    (headOfHousehold, children, showRemoved) => {
        return headOfHousehold ? [headOfHousehold].concat(children.filter((child) => {
            return showRemoved || child.Status === ACTIVE;
        })) : IMMUTABLE_EMPTY_ARRAY;
    }
);

export const HousholdMembersSortedForServiceAssociation = createSelector(
    [HeadOfHouseholdSelector, HouseholdChildMembersSortedByName],
    (headOfHousehold, children) => {
        return headOfHousehold ? [headOfHousehold].concat(children.filter((child) => {
            return child.Status === ACTIVE;
        })) : IMMUTABLE_EMPTY_ARRAY;
    }
);

export const HouseholdMembersListSelector = createSelector(
    [HousholdMembersSorted, IncludeRemovedSelector],
    (members, showRemoved) => {
        const filteredMembers = members.filter((member) => {
            return showRemoved || member.Status === ACTIVE;
        });
        return members.length ? Immutable([{
            isOverview: true
        }]).concat(filteredMembers) : IMMUTABLE_EMPTY_ARRAY;
    }
);


//Returns the household member selected in the richlist
export const SelectedHouseholdMemberSelector = createSelector(
    [SelectedHouseholdMemberIdSelector, HouseholdMembersListSelector],
    (selectedHouseholdMemberId, members) => {
        if (selectedHouseholdMemberId) {
            return members.find(({SubscriberId}) => {
                return SubscriberId === selectedHouseholdMemberId;
            });
        } else {
            return members.find(({isOverview}) => {
                return isOverview;
            }) || null;
        }
    }
);

export const SelectedHouseholdMemberServicesSelector = createImmutableSelector(
    [SelectedHouseholdMemberSelector],
    (selectedMember) => {
        if (selectedMember && selectedMember.services && selectedMember.services.length) {
            return  selectedMember.services.filter((service) => {
                return service.ServiceFriendlyName !== NA;
            });
        }
        return EMPTY_ARRAY;
    }
);

//Returns the household member that corresponds to the looked up subscriberId
export const CurrentHouseholdMemberSelector = createSelector(
    [SelectedCustomerSelector, HouseholdMembersSelector],
    (selectedCustomer, members) => {
        return members.length > 0 ?
            members.find((member) => {
                return member.SubscriberId === selectedCustomer.data.Id
                    && member.Status === ACTIVE;
            }) : null;
    }
);

export const SelectedMemberIsCurrentMemberSelector = createSelector(
    [SelectedHouseholdMemberSelector, CurrentHouseholdMemberSelector],
    (selectedMember, currentMember) => {
        return (selectedMember && currentMember) && (selectedMember.SubscriberId || currentMember.SubscriberId) ?
            selectedMember.SubscriberId === currentMember.SubscriberId : false;
    }
);

export const HeadOfHouseholdIsSelectedSelector = createSelector(
    [SelectedHouseholdMemberSelector, HeadOfHousholdSubscriberIdSelector],
    (selectedMember, hohSubscriberId) => {
        return selectedMember && (selectedMember.SubscriberId || hohSubscriberId) ?
            selectedMember.SubscriberId === hohSubscriberId : false;
    }
);

export const CurrentMemberIsHeadOfHouseholdSelector = createSelector(
    [CurrentHouseholdMemberSelector, HeadOfHouseholdSelector],
    (currentMember, headOfHousehold) => {
        return currentMember && headOfHousehold && (currentMember.SubscriberId || headOfHousehold.SubscriberId) ?
            currentMember.SubscriberId === headOfHousehold.SubscriberId : false;
    }
);

export const CanMakeHouseholdChangesSelector = createSelector(
    [CurrentMemberIsHeadOfHouseholdSelector, CsrHasHouseholdAdminAccessSelector],
    (isHoH, hasAdminAccess) => {
        return isHoH && hasAdminAccess;
    }
);

export const HouseholdErrorSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.household.householdError;
    }
);

export const HouseholdStatusSelector = createSelector(
    [CurrentHouseholdSelector],
    (household) => {
        return household ? household.Status : null;
    }
);

export const HouseholdMoreOptionsSelector = createSelector(
    [HouseholdStatusSelector, IsDbss],
    (householdStatus, isDbss) => {
        if (!householdStatus) {
            return EMPTY_ARRAY;
        }

        return createMenuItems([
            ACTIVATE_HOUSEHOLD,
            EDIT_HOUSEHOLD,
            REMOVE_HOUSEHOLD
        ]).filter((item) => {
            return !(householdStatus === ACTIVE && item.id === ACTIVATE_HOUSEHOLD.id) &&
                !(householdStatus === REMOVED && item.id === REMOVE_HOUSEHOLD.id) &&
                !(isDbss && item.id === REMOVE_HOUSEHOLD.id);
        });
    });

export const MemberStatusSelector = createSelector(
    [SelectedHouseholdMemberSelector],
    (selectedMember) => {
        return selectedMember ? selectedMember.Status : null;
    }
);

export const HouseholdMemberMoreOptionsSelector = createSelector(
    [MemberStatusSelector, IsDbss],
    (memberStatus, isDbss) => {
        if (!memberStatus) {
            return EMPTY_ARRAY;
        }

        return createMenuItems([
            ACTIVATE_MEMBER,
            EDIT_PRIVILEGES,
            REMOVE_MEMBER
        ]).filter((item) => {
            return !(memberStatus === ACTIVE && item.id === ACTIVATE_MEMBER.id) &&
                !(memberStatus === REMOVED && item.id === REMOVE_MEMBER.id) &&
                !(isDbss && item.id === ACTIVATE_MEMBER.id);
        });
    });

const createMenuItems = (options) => {
    return options.map(({id, title}) => {
        return {
            id,
            title: i18n.translate(title)
        };
    });
};

export const IsCreatingHouseholdSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.household.isCreatingHousehold;
    }
);

export const IsCreatingHouseholdMemberSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.household.isCreatingHouseholdMember;
    }
);

export const IsRemovingHouseholdMemberSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.household.isRemovingHouseholdMember;
    }
);

export const IsFetchingHouseholdSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.household.isFetchingHousehold;
    }
);

export const IsRemovingHouseholdSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.household.isRemovingHousehold;
    }
);

export const IsUpdatingHouseholdSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.household.isUpdatingHousehold;
    }
);
const commaDelimit = join(', ');
const mapIndexToDayOfWeek = addIndex(map)((i, index) => {
    return moment().weekday(index).format('dddd');
});
const getDayOfWeekLabels = () => {
    return mapIndexToDayOfWeek(new Array(7));
};
const mapAPIBlockToDisplayString = map((usageBlock) => {
    const start = moment().hour(usageBlock.StartingHour);
    start.minute(usageBlock.StartingMinute);
    const end = moment(start).add(usageBlock.DurationMinutes, 'minutes');
    return `${start.format('ha')}-${end.format('ha')}`;
});
const indexedForEach = addIndex(forEach);
const getUsagePrivilegeDisplayData = (usage=[]) => {
    const displayData = {};
    indexedForEach((item, index) => {
        const usageBlocksForCurrentDayOfWeek = sortBy(prop('StartingHour'))(filter(propEq(index, 'DayOfWeek'))(usage));
        const timeEntries = mapAPIBlockToDisplayString(usageBlocksForCurrentDayOfWeek);
        displayData[item] = timeEntries.length ? commaDelimit(timeEntries) : i18n.translate(CustomerCareLocaleKeys.NOT_APPLICABLE);
    })(getDayOfWeekLabels());
    return displayData;
};

const getPrivilegesFromMember = (member, timeZoneCodes) => {
    if (isEmpty(pathOr({}, ['Privileges'], member))) {
        return IMMUTABLE_EMPTY_OBJECT;
    }
    const privileges = member.Privileges;
    const timeZone = privileges.Timezone ? timeZoneCodes.items.find(({Value}) => {
        return parseInt(Value) === privileges.Timezone;
    }).Name : null;
    const TimeZone = privileges.Timezone ? String(privileges.Timezone) : null; // Normalizing data set
    return privileges
        .set('usagePrivilegeDisplay', getUsagePrivilegeDisplayData(privileges.DayAndTimePrivileges))
        .set('timeZone', timeZone)
        .set('Timezone', TimeZone)
        .set('LimitSpending', privileges.SpendingLimitAmount !== undefined);
};

export const IsUpdatingHouseholdServicesAssociationsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.household.isUpdatingHouseholdServicesAssociations;
    }
);

export const SelectedMemberPrivilegesSelector = createSelector(
    [SelectedHouseholdMemberSelector,
        MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.TimeZone),
        MetadataSelectors.codes.MetadataCodeSelector(MetadataConstants.codes.TimeZone)],
    (selectedMember, haveTimeZonesLoaded, timeZones) => {
        return haveTimeZonesLoaded ? getPrivilegesFromMember(selectedMember, timeZones) : IMMUTABLE_EMPTY_OBJECT;
    }
);

export const RecoverablePaymentInstrumentsSelector = createSelector(
    [RecoverableMemberPrivilegesSelector],
    (uiState) => {
        return uiState.paymentInstruments ? uiState.paymentInstruments : EMPTY_ARRAY;
    }
);

export const RecoverableSelectedHoursSelector = createSelector(
    [RecoverableMemberPrivilegesSelector],
    (uiState) => {
        return uiState.selectedHours ? uiState.selectedHours : EMPTY_ARRAY;
    }
);

const toAPIRequest = map(({hour, days}) => {
    return map(({day, selected}) => {
        return {
            StartingHour: hour,
            DayOfWeek: day,
            selected,
            DurationMinutes: 60
        };
    })(days);
});
const sortByDayAndHour = sort((a, b) => {
    if (a.DayOfWeek === b.DayOfWeek) {
        return a.StartingHour > b.StartingHour ? 1 : -1;
    } else {
        return a.DayOfWeek > b.DayOfWeek ? 1 : -1;
    }
});
const combineNearbySelections = reduce((a, b) => {
    const lastBlock = last(a);
    if (!b.selected) {
        return a;
    } else if (lastBlock && lastBlock.DayOfWeek === b.DayOfWeek && (b.StartingHour - lastBlock.StartingHour) === (lastBlock.DurationMinutes / 60)) {
        return a.setIn([a.length - 1, 'DurationMinutes'], lastBlock.DurationMinutes + 60);
    } else {
        return a.concat(b);
    }
}, IMMUTABLE_EMPTY_ARRAY);
const cleanForAPI = map(({DayOfWeek, StartingHour, DurationMinutes}) => {
    return {
        DayOfWeek,
        StartingHour,
        DurationMinutes,
        StartingMinute: 0
    };
});
const selectedHourViewModelToAPI = pipe(
    toAPIRequest,
    flatten,
    sortByDayAndHour,
    combineNearbySelections,
    cleanForAPI);
export const SelectedMemberPrivilegesAPIRequestSelector = createSelector(
    [RecoverablePrivilegesSelector, RecoverablePaymentInstrumentsSelector, RecoverableSelectedHoursSelector],
    (privileges=IMMUTABLE_EMPTY_OBJECT, paymentInstruments=IMMUTABLE_EMPTY_ARRAY, selectedHours=IMMUTABLE_EMPTY_ARRAY) => {
        let selectedPrivileges = privileges;
        selectedPrivileges = selectedPrivileges.set('DayAndTimePrivileges', selectedHourViewModelToAPI(selectedHours));
        if (!selectedPrivileges.LimitSpending) {
            selectedPrivileges = selectedPrivileges
                .set('LimitSpending', undefined)
                .set('SpendingBalanceAccumulates', undefined)
                .set('SpendingLimitAmount', undefined)
                .set('SpendingLimitPeriodDays', undefined)
                .set('SpendingBalance', undefined);
        }

        if (selectedPrivileges.EnforcePaymentPrivileges) {
            selectedPrivileges = selectedPrivileges.set('PaymentInstrumentPrivileges', paymentInstruments.reduce((prev, {selected, Id}) => {
                return selected ? prev.concat(Id) : prev;
            }, []));
        }

        return selectedPrivileges;
    }
);

export const CurrentMemberPrivilegesSelector = createSelector(
    [CurrentHouseholdMemberSelector,
        MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.TimeZone),
        MetadataSelectors.codes.MetadataCodeSelector(MetadataConstants.codes.TimeZone)],
    (currentMember, haveTimeZonesLoaded, timeZones) => {
        return haveTimeZonesLoaded ? getPrivilegesFromMember(currentMember, timeZones) : IMMUTABLE_EMPTY_OBJECT;
    }
);

export const CurrentMemberHasCreatePaymentInstrumentPermissionsSelector = createSelector(
    [CurrentMemberPrivilegesSelector, CurrentMemberIsHeadOfHouseholdSelector],
    (currentMemberPrivileges, selectedMemberIsHeadOfHousehold) => {
        const enforcePaymentPrivilegesSetForSelectedMember = pathOr(false, ['EnforcePaymentPrivileges'], currentMemberPrivileges);

        if (selectedMemberIsHeadOfHousehold || isEmpty(currentMemberPrivileges)) {
            return true;
        }

        // If EnforcePaymentPrivileges = false, we should NOT be allowing creation of new Payment Methods
        if (!enforcePaymentPrivilegesSetForSelectedMember) {
            return false;
        }

        return pathOr(false, ['CreatePaymentInstrumentEnabled'], currentMemberPrivileges);
    }
);

export const SelectedMemberPaymentPrivilegesSelector = createSelector(
    [SelectedMemberPrivilegesSelector],
    (privileges) => {
        return pathOr(EMPTY_ARRAY, ['PaymentInstrumentPrivileges'], privileges);
    }
);

export const SelectedMemberPaymentPrivilegesViewModelsSelector = createSelector(
    [SelectedMemberPaymentPrivilegesSelector, PaymentInstrumentsWithTypeNamesViewModel],
    (paymentInstrumentPrivileges, paymentInstrumentsViewModels) => {
        if (isEmpty(paymentInstrumentPrivileges) || isEmpty(paymentInstrumentsViewModels)) {
            return EMPTY_ARRAY;
        }

        return paymentInstrumentsViewModels.filter((paymentInstrument) => {
            return paymentInstrumentPrivileges.some((privilegeId) => {
                return privilegeId === paymentInstrument.Id;
            });
        });
    }
);

export const RatingsByCategorySelector = createSelector(
    [
        MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.Rating),
        MetadataSelectors.codes.MetadataCodeSelector(MetadataConstants.codes.Rating),
        MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.RatingCategory),
        MetadataSelectors.codes.MetadataCodeSelector(MetadataConstants.codes.RatingCategory)],
    (hasLoadedRatings, ratings, hasLoadedCategories, categories) => {
        if (hasLoadedCategories && hasLoadedRatings) {
            const ratingsByCategoryMap = {};
            ratings.items.forEach((rating) => {
                const categoryCode = rating.AdditionalProperties.find(({Key}) => {
                    return Key === 'category_code';
                }).Value;
                ratingsByCategoryMap[categoryCode] = ratingsByCategoryMap[categoryCode] ? ratingsByCategoryMap[categoryCode].concat(rating) : [rating];
            });
            return categories.items.map(({Value, Description, Name}) => {
                return {
                    value: Value,
                    description: Description,
                    name: Name,
                    selected: null,
                    ratings: ratingsByCategoryMap[Value]
                };
            });
        }

        return EMPTY_ARRAY;
    }
);

export const HouseholdPrivilegesSelector = createImmutableSelector(
    [
        MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.HouseholdPrivileges),
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.HouseholdPrivileges)],
    (householdLoaded, household) => {
        let privileges =  null;
        if (householdLoaded) {
            privileges = household.find((property) => {
                return property && !property.Global;
            });
            if (!privileges) {
                privileges = household.find((property) => {
                    return property;
                });
            }
        }
        return privileges ? privileges.AdditionalProperties: null;
    }
);

export const EnableHouseholdFullAccessControlSelector = createImmutableSelector(
    HouseholdPrivilegesSelector,
    (householdPrivileges) => {
        let result = false;
        if (householdPrivileges) {
            result = getBoolOrDefault(householdPrivileges.find((property) => {
                return property.Key === HOUSEHOLD_ACCESS.ENABLE_PROMOTE_TO_FULL_ACCESS;
            }).Value, false);
        }
        return result;
    }
);

export const EnableHouseholdServiceLevelManagementSelector = createImmutableSelector(
    HouseholdPrivilegesSelector,
    (householdPrivileges) => {
        let result = false;
        if (householdPrivileges) {
            result = getBoolOrDefault(householdPrivileges.find((property) => {
                return property.Key === HOUSEHOLD_ACCESS.ENABLE_SERVICE_LEVEL_MANAGEMENT;
            }).Value, false);
        }
        return result;
    }
);

export const EnableHouseholdAccessPrivilegesSelector = createImmutableSelector(
    HouseholdPrivilegesSelector,
    (householdPrivileges) => {
        let result = false;
        if (householdPrivileges) {
            result = getBoolOrDefault(householdPrivileges.find((property) => {
                return property.Key === HOUSEHOLD_ACCESS.ENABLE_PARENTAL_CONTROL;
            }).Value, false);
        }
        return result;
    }
);

export const EnableHouseholdUsagePrivilegesSelector = createImmutableSelector(
    HouseholdPrivilegesSelector,
    (householdPrivileges) => {
        let result = false;
        if (householdPrivileges) {
            result = getBoolOrDefault(householdPrivileges.find((property) => {
                return property.Key === HOUSEHOLD_ACCESS.ENABLE_ACCESS_CONTROL;
            }).Value, false);
        }
        return result;
    }
);

export const SelectableRatingsByCategory = createSelector(
    [
        RatingsByCategorySelector,
        SelectedMemberPrivilegesSelector
    ],
    (ratingsByCategory, memberPrivileges) => {
        if (ratingsByCategory.length) {
            const SELECT_OPTION = {
                text: i18n.translate(CustomerCareLocaleKeys.SELECT),
                value: null
            };

            return ratingsByCategory.map(({name, description, ratings, value}) => {
                const selectedRating = findLastSelectedRating(ratings, memberPrivileges.RatingPrivileges);
                return {
                    description,
                    selected: selectedRating ? selectedRating.Value : null,
                    name,
                    ratings: [SELECT_OPTION].concat(ratings.map(({Value, Name}) => {
                        return {
                            text: Name,
                            value: Value
                        };
                    })),
                    value
                };
            });
        }

        return EMPTY_ARRAY;
    }
);
