import {CHARGE_TIMING} from '../constants/wizard.constants';
import CustomerCareKeys from '../../locales/keys';

export const AMOUNT_TYPES = {
    SUB_TOTAL: 'subTotal',
    TOTAL: 'total'
};

export const getSummaryTotalsLabel = (chargeTiming, type) => {
    switch (chargeTiming) {
        case CHARGE_TIMING.DUE_TODAY:
            return type === AMOUNT_TYPES.TOTAL ?
                CustomerCareKeys.CART_SUMMARY.TOTAL_DUE_TODAY :
                CustomerCareKeys.CART_SUMMARY.SUB_TOTAL_DUE_TODAY;
        case CHARGE_TIMING.DUE_ON_ACTIVATION:
            return type === AMOUNT_TYPES.TOTAL ?
                CustomerCareKeys.CART_SUMMARY.TOTAL_DUE_ON_ACTIVATION :
                CustomerCareKeys.CART_SUMMARY.SUB_TOTAL_DUE_ON_ACTIVATION;
        case CHARGE_TIMING.DUE_ON_FIRST_USE:
            return type === AMOUNT_TYPES.TOTAL ?
                CustomerCareKeys.CART_SUMMARY.TOTAL_DUE_ON_FIRST_USE :
                CustomerCareKeys.CART_SUMMARY.SUB_TOTAL_DUE_ON_FIRST_USE;
        case CHARGE_TIMING.BILL_CYCLE:
            return type === AMOUNT_TYPES.TOTAL ?
                CustomerCareKeys.CART_SUMMARY.TOTAL_DUE_NEXT_INVOICE :
                CustomerCareKeys.CART_SUMMARY.SUB_TOTAL_DUE_NEXT_INVOICE;
        case CHARGE_TIMING.DUE_RECURRING:
            return type === AMOUNT_TYPES.TOTAL ?
                CustomerCareKeys.CART_SUMMARY.TOTAL_DUE_RECURRING :
                CustomerCareKeys.CART_SUMMARY.SUB_TOTAL_DUE_RECURRING;
        default:
            break;
    }
};
