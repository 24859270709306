import {
    CANCEL_ORDER_CONSTANTS,
    CANCEL_PENDING_ORDER_CONSTANTS,
    SEARCH_INVENTORY_FOR_UPDATE
} from './actions/order.actions';
import {
    EMPTY_ORDER_HISTORY_LIST,
    SEARCH_ORDERS_CONSTANTS
} from './actions/order.history.actions';
import Immutable from 'seamless-immutable';

export const INITIAL_STATE = Immutable({
    cancelOrderError: {},
    isCancellingOrder: false,
    isFetchingOrders: false,
    isRetrievingInventory: false,
    ordersError: null,
    orderHistory: {},
    pageCount: 0,
    recordCount: 0,
    retrieveInventoryError: null
});

export default function orderHistoryReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case SEARCH_ORDERS_CONSTANTS.BEGIN:
            return state.set('isFetchingOrders', true);
        case SEARCH_ORDERS_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingOrders', false)
                .set('data', payload.Orders)
                .set('pageCount', payload.PageCount)
                .set('recordCount', payload.RecordCount)
                .set('ordersError', null);
        case SEARCH_ORDERS_CONSTANTS.FAILURE:
            return state
                .set('isFetchingOrders', false)
                .set('ordersError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case SEARCH_INVENTORY_FOR_UPDATE.BEGIN:
            return state.set('isRetrievingInventory', true);
        case SEARCH_INVENTORY_FOR_UPDATE.SUCCESS:
            return state.set('isRetrievingInventory', false)
                .set('retrieveInventoryError', null);
        case SEARCH_INVENTORY_FOR_UPDATE.FAILURE:
            return state.set('isRetrievingInventory', false)
                .set('retrieveInventoryError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case CANCEL_ORDER_CONSTANTS.BEGIN:
        case CANCEL_PENDING_ORDER_CONSTANTS.BEGIN:
            return state.set('isCancellingOrder', true);
        case CANCEL_ORDER_CONSTANTS.SUCCESS:
        case CANCEL_PENDING_ORDER_CONSTANTS.SUCCESS:
            return state
                .set('isCancellingOrder', false);
        case CANCEL_ORDER_CONSTANTS.FAILURE:
        case CANCEL_PENDING_ORDER_CONSTANTS.FAILURE:
            return state
                .set('isCancellingOrder', false)
                .set('cancelOrderError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case EMPTY_ORDER_HISTORY_LIST:
            return INITIAL_STATE;
        default:
            return state;
    }
}
