import {ApiConstants,
    ThunkHelper} from 'invision-core';
import {stateGo} from 'redux-ui-router';
import {setLockerFilter, showSubscriptionBanner} from '../../reducers/actions/customer.transactions.actions';
import {pascalCaseKeys} from '../../utilities/object.formatting.helper';

export const CLEAR_CURRENT_SUBSCRIPTION = 'CLEAR_CURRENT_SUBSCRIPTION';
export const clearCurrentSubscription = () => {
    return {
        type: CLEAR_CURRENT_SUBSCRIPTION
    };
};

export const CLEAR_REMOVE_ORDER_QUOTE = 'CLEAR_REMOVE_ORDER_QUOTE';
export const clearRemoveOrderQuote = () => {
    return {
        type: CLEAR_REMOVE_ORDER_QUOTE
    };
};

export const REMOVE_SUBSCRIPTION_CONSTANTS = {
    BEGIN: 'REMOVE_SUBSCRIPTION_BEGIN',
    SUCCESS: 'REMOVE_SUBSCRIPTION_SUCCESS',
    FAILURE: 'REMOVE_SUBSCRIPTION_FAILURE'
};

const REMOVE_SUBSCRIPTION_EVENTS = [
    REMOVE_SUBSCRIPTION_CONSTANTS.BEGIN,
    REMOVE_SUBSCRIPTION_CONSTANTS.SUCCESS,
    REMOVE_SUBSCRIPTION_CONSTANTS.FAILURE
];

export const REMOVE_SUBSCRIPTION_DISCOUNT_CONSTANTS = {
    BEGIN: 'REMOVE_SUBSCRIPTION_DISCOUNT_BEGIN',
    SUCCESS: 'REMOVE_SUBSCRIPTION_DISCOUNT_SUCCESS',
    FAILURE: 'REMOVE_SUBSCRIPTION_DISCOUNT_FAILURE'
};

const REMOVE_SUBSCRIPTION_DISCOUNT_EVENTS = [
    REMOVE_SUBSCRIPTION_DISCOUNT_CONSTANTS.BEGIN,
    REMOVE_SUBSCRIPTION_DISCOUNT_CONSTANTS.SUCCESS,
    REMOVE_SUBSCRIPTION_DISCOUNT_CONSTANTS.FAILURE
];

export const APPLY_SUBSCRIPTION_COUPON_CONSTANTS = {
    BEGIN: 'APPLY_SUSCRIPTION_COUPON_BEGIN',
    SUCCESS: 'APPLY_SUSCRIPTION_COUPON_SUCCESS',
    FAILURE: 'APPLY_SUSCRIPTION_COUPON_FAILURE'
};

const APPLY_SUBSCRIPTION_COUPON_EVENTS = [
    APPLY_SUBSCRIPTION_COUPON_CONSTANTS.BEGIN,
    APPLY_SUBSCRIPTION_COUPON_CONSTANTS.SUCCESS,
    APPLY_SUBSCRIPTION_COUPON_CONSTANTS.FAILURE
];

export const RETRIEVE_SUBSCRIPTION_CONSTANTS = {
    BEGIN: 'RETRIEVE_SUBSCRIPTION_BEGIN',
    SUCCESS: 'RETRIEVE_SUBSCRIPTION_SUCCESS',
    FAILURE: 'RETRIEVE_SUBSCRIPTION_FAILURE'
};

const RETRIEVE_SUBSCRIPTION_EVENTS = [
    RETRIEVE_SUBSCRIPTION_CONSTANTS.BEGIN,
    RETRIEVE_SUBSCRIPTION_CONSTANTS.SUCCESS,
    RETRIEVE_SUBSCRIPTION_CONSTANTS.FAILURE
];

export const SEARCH_SUBSCRIPTIONS_CONSTANTS = {
    BEGIN: 'SEARCH_SUBSCRIPTIONS_BEGIN',
    SUCCESS: 'SEARCH_SUBSCRIPTIONS_SUCCESS',
    FAILURE: 'SEARCH_SUBSCRIPTIONS_FAILURE'
};

const SEARCH_SUBSCRIPTIONS_EVENTS = [
    SEARCH_SUBSCRIPTIONS_CONSTANTS.BEGIN,
    SEARCH_SUBSCRIPTIONS_CONSTANTS.SUCCESS,
    SEARCH_SUBSCRIPTIONS_CONSTANTS.FAILURE
];

export const SET_CURRENT_SUBSCRIPTION_OFFERING_DETAILS = 'SET_CURRENT_SUBSCRIPTION_OFFERING_DETAILS';
export const setCurrentSubscriptionOfferingDetails = (offeringDetails) => {
    return {
        type: SET_CURRENT_SUBSCRIPTION_OFFERING_DETAILS,
        payload: offeringDetails
    };
};

export const CALCULATE_REMOVE_ORDER_QUOTE_CONSTANTS = {
    BEGIN: 'CALCULATE_REMOVE_ORDER_QUOTE_BEGIN',
    SUCCESS: 'CALCULATE_REMOVE_ORDER_QUOTE_SUCCESS',
    FAILURE: 'CALCULATE_REMOVE_ORDER_QUOTE_FAILURE'
};

const CALCULATE_REMOVE_ORDER_QUOTE_EVENTS = [
    CALCULATE_REMOVE_ORDER_QUOTE_CONSTANTS.BEGIN,
    CALCULATE_REMOVE_ORDER_QUOTE_CONSTANTS.SUCCESS,
    CALCULATE_REMOVE_ORDER_QUOTE_CONSTANTS.FAILURE
];

const removeSubscriptionPromise = (dispatch, customerId, data) => {
    return ThunkHelper(dispatch, REMOVE_SUBSCRIPTION_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SubmitRemoveOrder',
        data: pascalCaseKeys(data),
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const removeSubscription = (customerId, data) => {
    return (dispatch) => {
        return removeSubscriptionPromise(dispatch, customerId, data);
    };
};


export const applySubscriptionCoupon = (customerId, subscriptionId, redemptionCode) => {
    return (dispatch) => {
        return applySubscriptionCouponPromise(dispatch, customerId, subscriptionId, redemptionCode);
    };
};

const applySubscriptionCouponPromise = (dispatch, customerId, subscriptionId, redemptionCode) => {
    return ThunkHelper(dispatch, APPLY_SUBSCRIPTION_COUPON_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/ApplyCoupon',
        data: {
            SubscriptionId: subscriptionId,
            RedemptionCodes: [redemptionCode]
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

const removeSubscriptionDiscountPromise = (dispatch, customerId, discountId, reasonId) => {
    return ThunkHelper(dispatch, REMOVE_SUBSCRIPTION_DISCOUNT_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RemoveDiscount',
        data: {
            Id: discountId,
            Reason: reasonId
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const removeSubscriptionDiscount = (customerId, discountId, reasonId) => {
    return (dispatch) => {
        return removeSubscriptionDiscountPromise(dispatch, customerId, discountId, reasonId);
    };
};

export const retrieveSubscriptionPromise = (dispatch, customerId, data) => {
    return ThunkHelper(dispatch, RETRIEVE_SUBSCRIPTION_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveSubscription',
        data,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const retrieveSubscription = (customerId, data) => {
    return (dispatch) => {
        return retrieveSubscriptionPromise(dispatch, customerId, data);
    };
};

export const searchSubscriptionsPromise = (dispatch, customerId, data) => {
    return ThunkHelper(dispatch, SEARCH_SUBSCRIPTIONS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SearchSubscriptions',
        data: data,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const searchSubscriptions = (customerId, data) => {
    return (dispatch) => {
        return searchSubscriptionsPromise(dispatch, customerId, data);
    };
};

const calculateRemoveOrderQuotePromise = (dispatch, customerId, data) => {
    return ThunkHelper(dispatch, CALCULATE_REMOVE_ORDER_QUOTE_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/CalculateRemoveOrderQuote',
        data: pascalCaseKeys(data),
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const calculateRemoveOrderQuote = (customerId, data) => {
    return (dispatch) => {
        return calculateRemoveOrderQuotePromise(dispatch, customerId, data);
    };
};

export const SET_CURRENT_PAGE = 'SET_SUBSCRIPTIONS_CURRENT_PAGE';
export const setCurrentPage = (page) => {
    return {
        type: SET_CURRENT_PAGE,
        payload: page
    };
};

export const SET_SUBSCRIPTIONS_PAGE_SIZE_PREFERENCE = 'SET_SUBSCRIPTIONS_PAGE_SIZE_PREFERENCE';
export const setPageSizePreference = (pageSize) => {
    return {
        type: SET_SUBSCRIPTIONS_PAGE_SIZE_PREFERENCE,
        payload: pageSize
    };
};

export const SET_INCLUDED_FILTER = 'SET_SUBSCRIPTIONS_INCLUDED_FILTER';
export const setIncludedFilter = (includedFilter) => {
    return {
        type: SET_INCLUDED_FILTER,
        payload: includedFilter
    };
};

export const SET_SELECTED_DISCOUNT = 'SET_SELECTED_DISCOUNT';
export const setSelectedDiscount = (discount) => {
    return {
        type: SET_SELECTED_DISCOUNT,
        payload: discount
    };
};

export const SET_SUBSCRIPTION_COUPON_CODE = 'SET_SUBSCRIPTION_COUPON_CODE';
export const setSubscriptionCouponCode = (couponCode = null) => {
    return {
        type: SET_SUBSCRIPTION_COUPON_CODE,
        payload: couponCode
    };
};
export const SET_EDIT_RENEWAL = 'SET_EDIT_RENEWAL';
export const setEditRenewal = (renewalUnderEdit) => {
    return {
        type: SET_EDIT_RENEWAL,
        payload: renewalUnderEdit
    };
};

export const UPDATE_SUBSCRIPTION_CONSTANTS = {
    BEGIN: 'UPDATE_SUBSCRIPTION_BEGIN',
    SUCCESS: 'UPDATE_SUBSCRIPTION_SUCCESS',
    FAILURE: 'UPDATE_SUBSCRIPTION_FAILURE'
};

export const RETRIEVE_PENDING_ORDER_CONSTANTS = {
    BEGIN: 'RETRIEVE_PENDING_ORDER_BEGIN',
    SUCCESS: 'RETRIEVE_PENDING_ORDER_SUCCESS',
    FAILURE: 'RETRIEVE_PENDING_ORDER_FAILURE'
};

export const SET_EDIT_SUBSCRIPTION_ITEM_ID = 'SET_EDIT_SUBSCRIPTION_ITEM_ID';
export const setEditSubscriptionItemId = (id = null) => {
    return {
        type: SET_EDIT_SUBSCRIPTION_ITEM_ID,
        payload: id
    };
};

export const SET_MODIFY_RENEWAL_SUBSCRIPTION_ITEM = 'SET_MODIFY_RENEWAL_SUBSCRIPTION_ITEM';
export const setModifyRenewalSubscriptionItem = (subscriptionItem = null) => {
    return {
        type: SET_MODIFY_RENEWAL_SUBSCRIPTION_ITEM,
        payload: subscriptionItem
    };
};

const UPDATE_SUBSCRIPTION_EVENTS = [UPDATE_SUBSCRIPTION_CONSTANTS.BEGIN, UPDATE_SUBSCRIPTION_CONSTANTS.SUCCESS, UPDATE_SUBSCRIPTION_CONSTANTS.FAILURE];
const updateSubscriptionPromise = (dispatch, customerId, data) => {
    return ThunkHelper(dispatch, UPDATE_SUBSCRIPTION_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/UpdateSubscription',
        data: data,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

const RETRIEVE_PENDING_ORDER_EVENTS = [RETRIEVE_PENDING_ORDER_CONSTANTS.BEGIN, RETRIEVE_PENDING_ORDER_CONSTANTS.SUCCESS, RETRIEVE_PENDING_ORDER_CONSTANTS.FAILURE];
const retrievePendingOrderCancellationPromise = (dispatch, customerId, data) => {
    return ThunkHelper(dispatch, RETRIEVE_PENDING_ORDER_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrievePendingOrderCancellationContext',
        data: data,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

const cancelPendingOrderPromise = (dispatch, customerId, data) => {
    return ThunkHelper(dispatch, UPDATE_SUBSCRIPTION_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/CancelPendingOrder',
        data: data,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const updateSubscription = (customerId, data) => {
    return (dispatch) => {
        return updateSubscriptionPromise(dispatch, customerId, data);
    };
};

export const retrievePendingOrder = (customerId, data) => {
    return (dispatch) => {
        return retrievePendingOrderCancellationPromise(dispatch, customerId, data);
    };
};

export const cancelPendingOrder = (customerId, data) => {

    return (dispatch) => {
        return cancelPendingOrderPromise(dispatch, customerId, data);
    };
};

export const viewSubscriptionTransactions = (previousRoute, subscription) => {
    return (dispatch) => {
        dispatch(showSubscriptionBanner(true));
        dispatch(setLockerFilter(subscription.Items[0].Id));
        dispatch(stateGo('index.customercare.customer.transactions.list', {
            previousRoute,
            subscription
        }));
    };
};

export const EMPTY_SUBSCRIPTIONS_LIST = 'EMPTY_SUBSCRIPTIONS_LIST';
export const emptySubscriptionsList = () => {
    return {
        type: EMPTY_SUBSCRIPTIONS_LIST
    };
};

export const REMOVE_SUBSCRIPTIONS_FILTER = 'REMOVE_SUBSCRIPTIONS_FILTER';
export const removeSubscriptionsFilter = () => {
    return {
        type: REMOVE_SUBSCRIPTIONS_FILTER
    };
};
