import clone from 'ramda/src/clone';
import remove from 'ramda/src/remove';
import ApiConstants from 'invision-core/src/api/constants';
import i18n from 'invision-core/src/components/i18n/i18n';
import ThunkHelper from 'invision-core/src/api/thunk.helper';
import {getUiNotificationService} from 'invision-core/src/components/injectables/injector.helper';
import CustomerCareKeys from '../../locales/keys';
import createWizardActions from '../helpers/wizard.action.helper';
import {
    calculateChangeOfServiceOrderQuoteRequestPromise,
    calculateModifyOrderQuotePromise,
    calculateOrderQuotePromise,
    calculateRenewOrderQuotePromise,
    clearProductOrderQuote,
    submitGiftOrderPromise,
    submitModifyOrderPromise,
    submitOrderPromise,
    submitRestoreOrderPromise,
    submitServiceFeatureOrderPromise
} from './products.order.actions';
import {transformCartItemInstanceProperties} from '../helpers/instance.property.helper';

const SIMPLE_EPHEMERAL_CART_OPTIONS = {
    PersistCart: false,
    CombineProducts: true
};
const ACTION_PREFIX = 'NEW_PRODUCTS';
const wizardActions = createWizardActions(ACTION_PREFIX);

export const INITIALIZE_PRODUCT_ORDER = 'INITIALIZE_PRODUCT_ORDER';
export const CANCEL_ORDER = wizardActions.CANCEL_WIZARD;
export const GO_TO_NEXT_STEP = wizardActions.GO_TO_NEXT_STEP;
export const GO_TO_PREVIOUS_STEP = wizardActions.GO_TO_PREVIOUS_STEP;
export const GO_TO_STEP = wizardActions.GO_TO_STEP;
export const SET_CURRENT_STEP_IS_INVALID = wizardActions.SET_CURRENT_STEP_IS_INVALID;
export const SET_SELECTED_NEW_PRODUCTS_TAB = wizardActions.SET_SELECTED_WIZARD_TAB;
export const SET_SHOPPING_CART_ITEM_INDEX = 'SET_SHOPPING_CART_ITEM_INDEX';
const TAX_AND_GL_CUSTOMER_ADDRESS_REQUIRED_ERROR_CODE = 11113;

export const reinitializeProductOrder = () => {
    return {
        type: INITIALIZE_PRODUCT_ORDER
    };
};
export const cancelOrder = wizardActions.cancelWizard;
export const goToNextStep = wizardActions.goToNextStep;
export const goToPreviousStep = wizardActions.goToPreviousStep;
export const goToStep = wizardActions.goToStep;
export const setCurrentStepIsInvalid = wizardActions.setCurrentStepIsInvalid;
export const setSelectedTab = wizardActions.setSelectedTab;

export const addToAndUpdateShoppingCart = ({productId, pricingPlanId, childItems, instanceProperties, quantity, customerId, shoppingCart, redemptionCodes, standalone}, additionalOptions = SIMPLE_EPHEMERAL_CART_OPTIONS) => {
    return (dispatch) => {
        const updatedCart = {
            Items: clone(shoppingCart.Items) || []
        };
        const cartItem = {
            ChildItems: Array.isArray(childItems) ? childItems : null,
            InstanceProperties: instanceProperties,
            IsStandalone: standalone,
            ProductId: productId,
            PricingPlanId: pricingPlanId,
            Quantity: quantity
        };
        updatedCart.Items.push(cartItem);

        return updateShoppingCartPromise(dispatch, customerId, updatedCart, redemptionCodes, additionalOptions);
    };
};

export const addMultipleAndUpdateShoppingCart = ({products, redemptionCodes, customerId, shoppingCart}, additionalOptions = SIMPLE_EPHEMERAL_CART_OPTIONS) => {
    return (dispatch) => {
        const updatedCart = {
            Items: clone(shoppingCart.Items) || []
        };
        // TODO Customer Order - why is this addedProductIds eveb needed? array built and not used it looks like
        const addedProductIds = [];

        products.forEach((product) => {
            const cartItem = {
                AdHocOverrideDetails: product.adHocOverrideDetails,
                BundleId: product.bundleId,
                ChildItems: Array.isArray(product.childItems) ? product.childItems : null,
                PricingPlanId: product.pricingPlanId,
                ProductId: product.productId,
                Quantity: product.quantity,
                InstanceProperties: product.instanceProperties,
                // TODO Customer Order, this can be removed later a product already contains this info to send to SV for Quoting
                IsStandalone: product.standalone,
                ShoppingCartItemBillerRuleDetails: product.shoppingCartItemBillerRuleDetails
            };
            updatedCart.Items.push(cartItem);
            addedProductIds.push(product.productId);
        });

        return updateShoppingCartPromise(dispatch, customerId, updatedCart, redemptionCodes, additionalOptions);

    };
};

export const editMultipleAndUpdateShoppingCart = ({products, redemptionCodes, customerId, shoppingCart}, additionalOptions = SIMPLE_EPHEMERAL_CART_OPTIONS) => {
    return (dispatch) => {
        const updatedCart = {
            Items: clone(shoppingCart.Items)
        };

        products.forEach((product) => {
            const targetItem = updatedCart.Items.find((item) => {
                return item.PricingPlanId === product.pricingPlanId;
            });
            const addedProductIds = [];

            if (targetItem) {
                targetItem.PricingPlanId = product.pricingPlanId;
                targetItem.ChildItems = Array.isArray(product.childItems) ? product.childItems : [];
                targetItem.InstanceProperties = product.instanceProperties;
                targetItem.Quantity = product.quantity;
            } else {
                const cartItem = {
                    BundleId: product.bundleId,
                    ChildItems: Array.isArray(product.childItems) ? product.childItems : null,
                    PricingPlanId: product.pricingPlanId,
                    ProductId: product.productId,
                    Quantity: product.quantity,
                    InstanceProperties: product.instanceProperties
                };

                updatedCart.Items.push(cartItem);
                addedProductIds.push(product.productId);
            }
        });

        return updateShoppingCartPromise(dispatch, customerId, updatedCart, redemptionCodes, additionalOptions);
    };
};

export const editAndUpdateShoppingCart = ({index, product, instanceProperties, quantity, customerId, shoppingCart, redemptionCodes}, additionalOptions = SIMPLE_EPHEMERAL_CART_OPTIONS) => {
    return (dispatch) => {
        const updatedCart = {
            Items: clone(shoppingCart.Items)
        };
        const targetItem = updatedCart.Items[index];
        targetItem.PricingPlanId = product.pricingPlanId;
        targetItem.ChildItems = Array.isArray(product.childItems) ? product.childItems : [];
        targetItem.InstanceProperties = instanceProperties;
        targetItem.Quantity = quantity;

        return updateShoppingCartPromise(dispatch, customerId, updatedCart, redemptionCodes, additionalOptions);
    };
};

export const removeFromAndUpdateShoppingCart = ({index, customerId, shoppingCart, redemptionCodes}, additionalOptions = SIMPLE_EPHEMERAL_CART_OPTIONS) => {
    return (dispatch) => {
        const updatedCart = {
            Items: remove(index, 1, shoppingCart.Items || [])
        };

        return updateShoppingCartPromise(dispatch, customerId, updatedCart, redemptionCodes, additionalOptions);
    };
};

export const updateShoppingCartWithLatest = ({customerId, shoppingCart, redemptionCodes, discretionaryDiscountIds, discretionaryDiscountReasonCode}, additionalOptions = SIMPLE_EPHEMERAL_CART_OPTIONS) => {
    return (dispatch) => {
        return updateShoppingCartPromise(dispatch, customerId, shoppingCart, redemptionCodes, additionalOptions, discretionaryDiscountIds, discretionaryDiscountReasonCode);
    };
};

export const calculateModifyOrderQuote = ({customerId,
    modificationObject,
    paymentInstrumentIds = [],
    redemptionCodes = [],
    discretionaryDiscounts = [],
    shippingAddressId = null,
    shippingMethodId = null,
    calculateShipping = true,
    calculateTaxes = true,
    useDefaults = true}) => {
    return (dispatch) => {
        dispatch(setCurrentStepIsInvalid(true));

        return calculateModifyOrderQuotePromise(dispatch,
            customerId,
            modificationObject,
            paymentInstrumentIds,
            redemptionCodes,
            discretionaryDiscounts,
            shippingAddressId,
            shippingMethodId,
            calculateShipping,
            calculateTaxes,
            useDefaults)
            .then((response) => {
                return response;
            }, (error) => {
                let errorMessage = i18n.translate(CustomerCareKeys.QUOTE_ERROR);
                if (error.translatedMessage) {
                    errorMessage = error.translatedMessage;
                }
                getUiNotificationService().transientError(errorMessage);

                return error;
            })
            .finally(() => {
                // Make it ok for the user to submit again
                dispatch(setCurrentStepIsInvalid(false));
            });
    };
};

export const calculateOrderQuote = ({customerId,
    shoppingCart,
    orderTaxLocation = null,
    paymentInstrumentIds = [],
    redemptionCodes = [],
    discretionaryDiscounts = [],
    shippingAddressId = null,
    shippingMethodId = null,
    useDefaults = true,
    recordPaymentInfo = true,
    paymentInstruments = []}) => {
    return (dispatch) => {
        // Disallow the user from submitting again until the request is done
        dispatch(setCurrentStepIsInvalid(true));

        return calculateOrderQuotePromise(dispatch, customerId, shoppingCart, orderTaxLocation,  paymentInstrumentIds, redemptionCodes, discretionaryDiscounts, shippingAddressId, shippingMethodId, useDefaults, recordPaymentInfo, paymentInstruments)
            .catch((error) => {
                if (error.Code !== TAX_AND_GL_CUSTOMER_ADDRESS_REQUIRED_ERROR_CODE) {
                    let errorMessage = i18n.translate(CustomerCareKeys.QUOTE_ERROR);
                    if (error.translatedMessage) {
                        errorMessage = error.translatedMessage;
                    }
                    getUiNotificationService().transientError(errorMessage);
                }
            })
            .finally(() => {
                // Make it ok for the user to submit again
                dispatch(setCurrentStepIsInvalid(false));
            });
    };
};

export const calculateRenewOrderQuote = ({customerId,
    subscriptionId,
    paymentInstrumentIds = [],
    redemptionCodes = [],
    discretionaryDiscounts = [],
    shippingAddressId = null,
    shippingMethodId = null,
    calculateTaxes = true,
    useDefaults = true}) => {
    return (dispatch) => {
        // Disallow the user from submitting again until the request is done
        dispatch(setCurrentStepIsInvalid(true));

        return calculateRenewOrderQuotePromise(dispatch,
            customerId,
            subscriptionId,
            paymentInstrumentIds,
            redemptionCodes,
            discretionaryDiscounts,
            shippingAddressId,
            shippingMethodId,
            calculateTaxes,
            useDefaults)
            .catch((error) => {
                let errorMessage = i18n.translate(CustomerCareKeys.QUOTE_ERROR);
                if (error.translatedMessage) {
                    errorMessage = error.translatedMessage;
                }
                getUiNotificationService().transientError(errorMessage);
            })
            .finally(() => {
                // Make it ok for the user to submit again
                dispatch(setCurrentStepIsInvalid(false));
            });
    };
};

export const calculateChangeOfServiceOrderQuote = ({customerId, addItems, modifyItems, removeItems, paymentInstrumentIds}) => {
    return (dispatch) => {
        // Disallow the user from submitting again until the request is done
        dispatch(setCurrentStepIsInvalid(true));

        return calculateChangeOfServiceOrderQuoteRequestPromise(dispatch, customerId, addItems, modifyItems, removeItems, paymentInstrumentIds)
            .catch((error) => {
                let errorMessage = i18n.translate(CustomerCareKeys.QUOTE_ERROR);
                if (error.translatedMessage) {
                    errorMessage = error.translatedMessage;
                }
                getUiNotificationService().transientError(errorMessage);
            })
            .finally(() => {
                // Make it ok for the user to submit again
                dispatch(setCurrentStepIsInvalid(false));
            });
    };
};

export const submitModifyOrder = ({customerId,
    modificationObject,
    paymentInstrumentIds = [],
    paymentInstruments = null,
    recordPaymentInfo = null,
    redemptionCodes = [],
    discretionaryDiscounts = [],
    discretionaryDiscountReasonCode = null,
    shippingAddressId = null,
    shippingMethodId = null,
    shipToName = null,
    additionalProperties}) => {
    return (dispatch) => {
        // Disallow the user from submitting again until the request is done
        dispatch(setCurrentStepIsInvalid(true));

        return submitModifyOrderPromise(dispatch,
            customerId,
            modificationObject,
            paymentInstrumentIds,
            paymentInstruments,
            recordPaymentInfo,
            redemptionCodes,
            discretionaryDiscounts,
            discretionaryDiscountReasonCode,
            shippingAddressId,
            shippingMethodId,
            shipToName,
            additionalProperties)
            .finally(() => {
                // Make it ok for the user to submit again
                dispatch(setCurrentStepIsInvalid(false));
            });
    };
};

export const submitRestoreOrder = ({customerId,
    subscriptionId,
    paymentInstrumentIds,
    recordPaymentInfo,
    redemptionCodes = [],
    discretionaryDiscounts = [],
    discretionaryDiscountReasonCode = null,
    shippingAddressId = null,
    shippingMethodId = null,
    shipToName = null,
    waiveFee = false,
    waiveFeeReason = null,
    waiveLateFee = false,
    waiveLateFeeReason = null}) => {
    return (dispatch) => {
        // Disallow the user from submitting again until the request is done
        dispatch(setCurrentStepIsInvalid(true));

        return submitRestoreOrderPromise(dispatch,
            customerId,
            subscriptionId,
            paymentInstrumentIds,
            [],
            recordPaymentInfo,
            redemptionCodes,
            discretionaryDiscounts,
            discretionaryDiscountReasonCode,
            shippingAddressId,
            shippingMethodId,
            shipToName,
            waiveFee,
            waiveFeeReason,
            waiveLateFee,
            waiveLateFeeReason)
            .finally(() => {
                // Make it ok for the user to submit again
                dispatch(setCurrentStepIsInvalid(false));
            });
    };
};

export const submitOrder = ({customerId,
    shoppingCart,
    orderTaxLocation = null,
    paymentInstrumentIds = [],
    paymentInstruments = [],
    recordPaymentInfo = true,
    redemptionCodes = [],
    discretionaryDiscounts = [],
    discretionaryDiscountReasonCode = null,
    executionOptions,
    shippingAddressId = null,
    shippingMethodId = null,
    shipToName = null,
    offeringId = null,
    useDefaults = true,
    additionalProperties,
    orderDetails}) => {
    return (dispatch) => {
        // Disallow the user from submitting again until the request is done
        dispatch(setCurrentStepIsInvalid(true));

        return submitOrderPromise(dispatch,
            customerId,
            shoppingCart,
            orderTaxLocation,
            paymentInstrumentIds,
            paymentInstruments,
            recordPaymentInfo,
            redemptionCodes,
            discretionaryDiscounts,
            discretionaryDiscountReasonCode,
            executionOptions,
            shippingAddressId,
            shippingMethodId,
            shipToName,
            offeringId,
            useDefaults,
            additionalProperties,
            orderDetails)
            .finally(() => {
                // Make it ok for the user to submit again
                dispatch(setCurrentStepIsInvalid(false));
            });
    };
};

export const submitServiceFeatureOrder = ({customerId, addItems, modifyItems, removeItems, paymentInstrumentIds, executionOptions, additionalProperties}) => {
    return (dispatch) => {
        // Disallow the user from submitting again until the request is done
        dispatch(setCurrentStepIsInvalid(true));

        return submitServiceFeatureOrderPromise(dispatch, customerId, addItems, modifyItems, removeItems, paymentInstrumentIds, executionOptions, additionalProperties)
            .finally(() => {
                // Make it ok for the user to submit again
                dispatch(setCurrentStepIsInvalid(false));
            });
    };
};

export const submitGiftOrder = ({customerId,
    shoppingCart,
    paymentInstrumentIds,
    paymentInstruments,
    recordPaymentInfo,
    giftOrderInfo,
    redemptionCodes = [],
    discretionaryDiscounts = [],
    discretionaryDiscountReasonCode = null,
    shippingAddressId = null,
    shippingMethodId = null,
    shipToName = null,
    additionalProperties}) => {
    return (dispatch) => {
        dispatch(setCurrentStepIsInvalid(true));

        return submitGiftOrderPromise(dispatch,
            customerId,
            shoppingCart,
            paymentInstrumentIds,
            paymentInstruments,
            recordPaymentInfo,
            giftOrderInfo,
            redemptionCodes,
            discretionaryDiscounts,
            discretionaryDiscountReasonCode,
            shippingAddressId,
            shippingMethodId,
            shipToName,
            additionalProperties)
            .finally(() => {
            // Make it ok for the user to submit again
                dispatch(setCurrentStepIsInvalid(false));
            });
    };
};

export const clearOrderQuote = () => {
    return (dispatch) => {
        dispatch(clearProductOrderQuote());
    };
};

export const SET_PAYMENT_INSTRUMENT_IDS = 'SET_PAYMENT_INSTRUMENT_IDS';
export const setPaymentInstrumentIds = (arrayOfIds) => {
    return {
        type: SET_PAYMENT_INSTRUMENT_IDS,
        payload: arrayOfIds
    };
};

export const SET_PAYMENT_INSTRUMENTS = 'SET_PAYMENT_INSTRUMENTS';
export const setPaymentInstruments = (arrayOfIds) => {
    return {
        type: SET_PAYMENT_INSTRUMENTS,
        payload: arrayOfIds
    };
};

export const SET_CHANGE_IMMEDIATELY = 'SET_CHANGE_IMMEDIATELY';
export const setChangeImmediately = (changeImmediately) => {
    return {
        type: SET_CHANGE_IMMEDIATELY,
        payload: changeImmediately
    };
};

export const SET_PRODUCT_ORDER_SHIPPING_ADDRESS_ID = 'SET_PRODUCT_ORDER_SHIPPING_ADDRESS_ID';
export const setProductOrderShippingAddressId = (shippingAddressId) => {
    return {
        type: SET_PRODUCT_ORDER_SHIPPING_ADDRESS_ID,
        payload: shippingAddressId
    };
};

export const SET_IS_SHIPPING_ADDRESS_SAME_AS_BILLING = 'SET_IS_SHIPPING_ADDRESS_SAME_AS_BILLING';
export const setIsShippingAddressSameAsBilling = (isShippingAddressSameAsBilling) => {
    return {
        type: SET_IS_SHIPPING_ADDRESS_SAME_AS_BILLING,
        payload: isShippingAddressSameAsBilling
    };
};

export const SET_PRODUCT_ORDER_SHIPPING_METHOD_ID = 'SET_PRODUCT_ORDER_SHIPPING_METHOD_ID';

export const setSelectedShippingMethodId = (shippingMethodId) => {
    return {
        type: SET_PRODUCT_ORDER_SHIPPING_METHOD_ID,
        payload: shippingMethodId
    };
};

export const setShoppingCartItemIndex = (cartItemIndex) => {
    return {
        type: SET_SHOPPING_CART_ITEM_INDEX,
        payload: cartItemIndex
    };
};

export const SET_TEMPORARY_SHIP_TO_NAME = 'SET_TEMPORARY_SHIP_TO_NAME';
export const setTemporaryShipToName = (temporaryShipToName) => {
    return {
        type: SET_TEMPORARY_SHIP_TO_NAME,
        payload: temporaryShipToName
    };
};

export const SET_GIFT_ORDER_INFO = 'SET_GIFT_ORDER_INFO';
export const setGiftOrderInfo = (giftOrderInfo) => {
    return {
        type: SET_GIFT_ORDER_INFO,
        payload: giftOrderInfo
    };
};

export const SET_RESTORE_ORDER_WAIVE_FEE = 'SET_RESTORE_ORDER_WAIVE_FEE';
export const setRestoreOrderWaiveFee = (waiveFee) => {
    return {
        type: SET_RESTORE_ORDER_WAIVE_FEE,
        payload: waiveFee
    };
};

export const SET_RESTORE_ORDER_WAIVE_LATE_FEE = 'SET_RESTORE_ORDER_WAIVE_LATE_FEE';
export const setRestoreOrderWaiveLateFee = (waiveLateFee) => {
    return {
        type: SET_RESTORE_ORDER_WAIVE_LATE_FEE,
        payload: waiveLateFee
    };
};

export const SET_RESTORE_ORDER_WAIVE_FEE_REASON = 'SET_RESTORE_ORDER_WAIVE_FEE_REASON';
export const setRestoreOrderWaiveFeeReason = (waiveFeeReason) => {
    return {
        type: SET_RESTORE_ORDER_WAIVE_FEE_REASON,
        payload: waiveFeeReason
    };
};

export const SET_RESTORE_ORDER_WAIVE_LATE_FEE_REASON = 'SET_RESTORE_ORDER_WAIVE_LATE_FEE_REASON';
export const setRestoreOrderWaiveLateFeeReason = (waiveLateFeeReason) => {
    return {
        type: SET_RESTORE_ORDER_WAIVE_LATE_FEE_REASON,
        payload: waiveLateFeeReason
    };
};

export const SET_IS_GIFT_ORDER = 'SET_IS_GIFT_ORDER';
export const setIsGiftOrder = (isGiftOrder) => {
    return {
        type: SET_IS_GIFT_ORDER,
        payload: isGiftOrder
    };
};

export const SET_OTT_EXECUTION_OPTIONS = 'SET_OTT_EXECUTION_OPTIONS';
export const setOttExecutionOptions = (executionOptions) => {
    return {
        type: SET_OTT_EXECUTION_OPTIONS,
        payload: executionOptions
    };
};

export const UPDATE_SERVICE_FEATURE_QUANTITY = 'UPDATE_SERVICE_FEATURE_QUANTITY';
export const updateServiceFeatureQuantity = (payload) => {
    return {
        type: UPDATE_SERVICE_FEATURE_QUANTITY,
        payload: payload
    };
};

export const updateServiceFeatureQuantityPromise = (payload) => {
    return (dispatch) => {
        dispatch(updateServiceFeatureQuantity(payload));
        return Promise.resolve();
    };
};

export const UPDATE_SHOPPING_CART = {
    BEGIN: `${ACTION_PREFIX}_UPDATE_SHOPPING_CART_BEGIN`,
    SUCCESS: `${ACTION_PREFIX}_UPDATE_SHOPPING_CART_SUCCESS`,
    FAILURE: `${ACTION_PREFIX}_UPDATE_SHOPPING_CART_FAILURE`
};
export const UPDATE_SHOPPING_CART_EVENTS = [UPDATE_SHOPPING_CART.BEGIN, UPDATE_SHOPPING_CART.SUCCESS, UPDATE_SHOPPING_CART.FAILURE];
export const updateShoppingCartPromise = (dispatch, customerId, shoppingCart, redemptionCodes = [], additionalOptions = {}, discretionaryDiscountIds = [], discretionaryDiscountReasonCode = null ) => {
    return ThunkHelper(dispatch, UPDATE_SHOPPING_CART_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/UpdateShoppingCart',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: Object.assign({
            CombineProducts: false,
            DiscretionaryDiscountReasonCode: discretionaryDiscountReasonCode,
            DiscretionaryDiscounts: discretionaryDiscountIds,
            PersistCart: true,
            RedemptionCodes: redemptionCodes,
            ShoppingCart: transformCartItemInstanceProperties(shoppingCart)

        }, additionalOptions)
    });
};
export const updateShoppingCart = (customerId, shoppingCart, redemptionCodes, additionalOptions = {}) => {
    return (dispatch) => {
        return updateShoppingCartPromise(dispatch, customerId, shoppingCart, redemptionCodes, additionalOptions);
    };
};

export const SET_EDIT_ORDER_ADDITIONAL_PROPERTY = `${ACTION_PREFIX}_SET_EDIT_ORDER_ADDITIONAL_PROPERTY`;
export const setEditOrderAdditionalProperty = (additionalProperties) => {
    return {
        type: SET_EDIT_ORDER_ADDITIONAL_PROPERTY,
        payload: additionalProperties
    };
};

export const SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE = `${ACTION_PREFIX}_SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE`;
export const setEditOrderAdditionalPropertyOnChange = (id, newValue) => {
    return {
        type: SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE,
        payload: {
            id: id,
            newValue: newValue
        }
    };
};

export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS';
export const setOrderDetails = (orderDetails) => {
    return {
        type: SET_ORDER_DETAILS,
        payload: orderDetails
    };
};