import Immutable from 'seamless-immutable';
import * as InteractionActions from './actions/customer.interaction.actions';

export const INITIAL_STATE = Immutable({
    currentInteraction: null,
    data: {},
    interactionError: null,
    isFetchingInteractions: false
});

export default function interactionsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case InteractionActions.SEARCH_INTERACTIONS.BEGIN:
            return state.set('isFetchingInteractions', true);
        case InteractionActions.SEARCH_INTERACTIONS.SUCCESS:
            return state.set('isFetchingInteractions', false)
                .setIn(['data', 'records'], payload.Interactions)
                .setIn(['data', 'recordCount'], payload.RecordCount)
                .setIn(['data', 'pageCount'], payload.PageCount)
                .set('interactionError', null);
        case InteractionActions.SEARCH_INTERACTIONS.FAILURE:
            return state.set('isFetchingInteractions', false)
                .set('interactionError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                });
        case InteractionActions.RETRIEVE_INTERACTION.BEGIN:
            return state.set('isFetchingInteractions', true);
        case InteractionActions.RETRIEVE_INTERACTION.SUCCESS:
            return state.set('isFetchingInteractions', false)
                .set('currentInteraction', payload.Interaction)
                .set('interactionError', null);
        case InteractionActions.RETRIEVE_INTERACTION.FAILURE:
            return state.set('isFetchingInteractions', false)
                .set('currentInteraction', null)
                .set('interactionError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case InteractionActions.EMPTY_INTERACTIONS_LIST:
            return INITIAL_STATE;
        default:
            return state;
    }
}
