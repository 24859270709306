import {
    SubtenantsSelectors
} from 'invision-core';
import {createSelector} from 'reselect';

export const CurrentBusinessUnitHasSubtenants = createSelector(
    [SubtenantsSelectors.SubtenantsSelector],
    (subtenants) => {
        return subtenants ? subtenants.length > 0 : false;
    }
);
