import {OVERALL} from '../components/billingPayments/billing.payments.constants';
import {
    APPLY_CREDIT_NOTE,
    RESET_BILLING_LEDGER_STATE,
    RETRIEVE_ADJUSTMENT_DETAILS,
    RETREIVE_FUTURE_INVOICES,
    RETRIEVE_LEDGER_INVOICE,
    RETRIEVE_LEDGER_SUMMARY,
    RETRIEVE_OPEN_INVOICES_LIST,
    RETRIEVE_INVOICES_LIST_DROPDOWN,
    SET_CURRENT_TAB_BILLING_LEDGER,
    UPDATE_BILLING_PAYMENTS_DATE_RANGE,
    UPDATE_BILLING_PAYMENTS_INVOICE_FILTER,
    UPDATE_BILLING_PAYMENTS_PAGE_NUMBER,
    UPDATE_BILLING_PAYMENTS_PAGE_SIZE,
    UPDATE_BILLING_PAYMENTS_TYPE_FILTER,
    UPDATE_INVOICE_NUMBER_SEARCH_TERM,
    UPDATE_OPEN_INVOICE_DATE_RANGE,
    UPDATE_OPEN_INVOICE_PAGE_NUMBER,
    UPDATE_OPEN_INVOICE_PAGE_SIZE
} from './actions/customer.billing.actions';
import {BILLING_PAYMENT_TYPE_CODES} from './constants/billing.payments.constants';
import Immutable from 'seamless-immutable';
import {RESET_CUSTOMER_RECOVERABLE} from './actions/customer.actions';
import {EMPTY_ARRAY} from 'invision-core/src/constants/common.constants';
import {
    DEFAULT_PAGE_NUMBER,
    DEFAULT_PAGE_SIZE
} from '../components/shared/constants/customer.constants';
const metadata = {
    dateShortcut: null,
    endDate: null,
    invoiceFilter: '',
    pageNumber: DEFAULT_PAGE_NUMBER,
    pageSize: DEFAULT_PAGE_SIZE,
    startDate: null,
    typeFilter: OVERALL
};

export const INITIAL_STATE = Immutable({
    billingPaymentsError: null,
    currentTab: null,
    data: {},
    recordCount: null,
    pageCount: null,
    adjustmentOrCreditNoteDetails: null,
    futureInvoicesDetails: {
        calculationSessionId: null,
        invoices: EMPTY_ARRAY,
        status: null,
        startCalculating: false

    },
    openInvoices: {
        metadata: {
            dateShortcut: null,
            endDate: null,
            invoiceSearchTerm: '',
            pageNumber: 1,
            pageSize: 25,
            startDate: null
        },
        invoices: EMPTY_ARRAY,
        isFetchingOpenInvoices: false,
        recordCount: null,
        pageCount: null
    },
    isApplyingCreditNote: false,
    isFetchingAdjustmentDetails: false,
    isFetchingBillingPayments: false,
    isFetchingInvoiceDetails: false,
    isFetchingInvoiceList: false,
    metadata: Object.assign({}, metadata),
    invoiceDetails: {},
    invoiceList: EMPTY_ARRAY,
    invoiceListDropdown: EMPTY_ARRAY
});

export default function billingPaymentsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case RETRIEVE_LEDGER_SUMMARY.BEGIN:
            return state.set('isFetchingBillingPayments', true);
        case RETRIEVE_LEDGER_SUMMARY.SUCCESS:
            return state
                .set('isFetchingBillingPayments', false)
                .set('billingPaymentsError', null)
                .set('metadata', state.metadata)
                .set('data', mapBillingType(payload.LedgerSummary))
                .set('invoiceListDropdown', payload.LedgerSummary)
                .set('pageCount', payload.PageCount)
                .set('recordCount', payload.RecordCount);
        case RETRIEVE_LEDGER_SUMMARY.FAILURE:
            return state
                .set('isFetchingBillingPayments', false)
                .set('billingPaymentsError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity
                });
        case RETRIEVE_LEDGER_INVOICE.BEGIN:
            return state.set('isFetchingInvoiceDetails', true);
        case RETRIEVE_LEDGER_INVOICE.SUCCESS:
            return state
                .set('isFetchingInvoiceDetails', false)
                .set('invoiceDetails', payload);
        case RETRIEVE_LEDGER_INVOICE.FAILURE:
            return state
                .set('isFetchingInvoiceDetails', false);
        case UPDATE_BILLING_PAYMENTS_DATE_RANGE:
            return state
                .setIn(['metadata', 'dateShortcut'], payload.dateShortcut)
                .setIn(['metadata', 'startDate'], payload.startDate)
                .setIn(['metadata', 'endDate'], payload.endDate);
        case UPDATE_BILLING_PAYMENTS_TYPE_FILTER:
            return state.setIn(['metadata', 'typeFilter'], payload.typeFilter);
        case UPDATE_BILLING_PAYMENTS_INVOICE_FILTER:
            return state.setIn(['metadata', 'invoiceFilter'], payload.invoiceFilter);
        case UPDATE_BILLING_PAYMENTS_PAGE_NUMBER:
            return state.setIn(['metadata', 'pageNumber'], payload);
        case UPDATE_BILLING_PAYMENTS_PAGE_SIZE:
            return state.setIn(['metadata', 'pageSize'], payload);
        case RESET_CUSTOMER_RECOVERABLE:
            return state.set('metadata', metadata);
        case RETREIVE_FUTURE_INVOICES.BEGIN:
            return state
                .setIn(['futureInvoicesDetails', 'startCalculating'], true);
        case RETREIVE_FUTURE_INVOICES.SUCCESS:
            return state
                .setIn(['futureInvoicesDetails', 'calculationSessionId'], payload.CalculationSessionId)
                .setIn(['futureInvoicesDetails', 'invoices'], payload.Invoices)
                .setIn(['futureInvoicesDetails', 'status'], payload.Status)
                .setIn(['futureInvoicesDetails', 'startCalculating'], false)
                .setIn(['data', 'invoiceSummaries'], payload.Invoices);
        case RETREIVE_FUTURE_INVOICES.FAILURE:
            return state
                .setIn(['futureInvoicesDetails', 'invoices'], EMPTY_ARRAY)
                .setIn(['futureInvoicesDetails', 'startCalculating'], false);
        case RETRIEVE_OPEN_INVOICES_LIST.BEGIN:
            return state
                .setIn(['openInvoices', 'isFetchingOpenInvoices'], true);
        case RETRIEVE_OPEN_INVOICES_LIST.SUCCESS:
            return state
                .setIn(['openInvoices', 'isFetchingOpenInvoices'], false)
                .setIn(['openInvoices', 'recordCount'], payload.RecordCount)
                .setIn(['openInvoices', 'pageCount'], payload.PageCount)
                .setIn(['totalAccountBalance'], payload.TotalAccountBalance)
                .setIn(['totalCashReceivable'], payload.CashReceivableDue)
                .setIn(['totalWithheldTax'], payload.WithheldTaxDue)
                .setIn(['openInvoices', 'invoices'], payload.LedgerSummary)
                .set('hierarchyInvoiceNodeSubscriberId', payload?.HierarchyInvoiceNodeSubscriberId);
        case RETRIEVE_OPEN_INVOICES_LIST.FAILURE:
            return state
                .setIn(['openInvoices', 'isFetchingOpenInvoices'], false);
        case RETRIEVE_INVOICES_LIST_DROPDOWN.BEGIN:
            return state.set('invoiceListDropdown', EMPTY_ARRAY);
        case RETRIEVE_INVOICES_LIST_DROPDOWN.SUCCESS:
            return state.set('invoiceListDropdown', payload.LedgerSummary);
        case RETRIEVE_INVOICES_LIST_DROPDOWN.FAILURE:
            return state.set('invoiceListDropdown', EMPTY_ARRAY);
        case APPLY_CREDIT_NOTE.BEGIN:
            return state.set('isApplyingCreditNote', true);
        case APPLY_CREDIT_NOTE.SUCCESS:
        case APPLY_CREDIT_NOTE.FAILURE:
            return state.set('isApplyingCreditNote', false);
        case RETRIEVE_ADJUSTMENT_DETAILS.BEGIN:
            return state.set('isFetchingAdjustmentDetails', true);
        case RETRIEVE_ADJUSTMENT_DETAILS.SUCCESS:
            return state
                .set('adjustmentOrCreditNoteDetails', payload)
                .set('isFetchingAdjustmentDetails', false);
        case RETRIEVE_ADJUSTMENT_DETAILS.FAILURE:
            return state.set('isFetchingAdjustmentDetails', false);
        case UPDATE_OPEN_INVOICE_PAGE_NUMBER:
            return state
                .setIn(['openInvoices', 'metadata', 'pageNumber'], payload);
        case UPDATE_OPEN_INVOICE_DATE_RANGE:
            return state
                .setIn(['openInvoices', 'metadata', 'startDate'], payload.startDate)
                .setIn(['openInvoices', 'metadata', 'endDate'], payload.endDate)
                .setIn(['openInvoices', 'metadata', 'dateShortcut'], payload.dateShortcut);
        case UPDATE_INVOICE_NUMBER_SEARCH_TERM:
            return state
                .setIn(['openInvoices', 'metadata', 'invoiceSearchTerm'], payload);
        case SET_CURRENT_TAB_BILLING_LEDGER:
            return state
                .set('currentTab', payload);
        case UPDATE_OPEN_INVOICE_PAGE_SIZE:
            return state
                .setIn(['openInvoices', 'metadata', 'pageSize'], payload);
        case RESET_BILLING_LEDGER_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}

function mapBillingType(payload) {
    const adjustments = [];
    const payments = [];
    const invoiceSummaries = [];
    const creditNotes = [];
    const debitNotes = [];
    const creditToPaymentMapping = {};

    if (payload) {
        payload.map((item) => {
            if (item.Type === BILLING_PAYMENT_TYPE_CODES.ADJUSTMENT) {
                item.Adjustment.Type = item.Type;
                adjustments.push(item.Adjustment);
            } else if (item.Type === BILLING_PAYMENT_TYPE_CODES.INVOICE_SUMMARY) {
                item.Invoice.Type = item.Type;
                invoiceSummaries.push(item.Invoice);
            } else if (item.Type === BILLING_PAYMENT_TYPE_CODES.PAYMENT) {
                item.Payment.Type = item.Type;
                if (item.Payment.CreditNoteNumber) {
                    creditToPaymentMapping[item.Payment.CreditNoteNumber] = item.Payment.Id;
                }
                payments.push(item.Payment);
            } else if (item.Type === BILLING_PAYMENT_TYPE_CODES.CREDIT_NOTE) {
                item.CreditNote.Type = item.Type;
                creditNotes.push(item.CreditNote);
            } else if (item.Type === BILLING_PAYMENT_TYPE_CODES.DEBIT_NOTE) {
                item.DebitNote.Type = item.Type;
                debitNotes.push(item.DebitNote);
            }
        });
    }
    return {
        adjustments: adjustments,
        creditNotes,
        creditToPaymentMapping,
        debitNotes,
        payments: payments,
        invoiceSummaries: invoiceSummaries
    };
}