export const SET_MAKE_PAYMENT_ADDRESSES = 'SET_MAKE_PAYMENT_ADDRESSES';
export const SET_MAKE_PAYMENT_INSTRUMENT = 'SET_MAKE_PAYMENT_INSTRUMENT';
export const SET_PAYMENT_INSTRUMENT_SELECTED_CARD_TYPE = 'SET_PAYMENT_INSTRUMENT_SELECTED_CARD_TYPE';
export const SET_MAKE_PAYMENT_INSTRUMENT_BILLING_ADDRESS = 'SET_MAKE_PAYMENT_INSTRUMENT_BILLING_ADDRESS';
export const SET_MAKE_PAYMENT_ADDRESS_STATE_VALUE = 'SET_MAKE_PAYMENT_ADDRESS_STATE_VALUE';

export const setMakePaymentAddresses = (addresses) => {
    return {
        type: SET_MAKE_PAYMENT_ADDRESSES,
        payload: addresses
    };
};

export const setMakePaymentAddressStateValue = (stateValue) => {
    return {
        type: SET_MAKE_PAYMENT_ADDRESS_STATE_VALUE,
        payload: stateValue
    };
};

export const setMakePaymentInstrument = (paymentInstrument) => {
    return {
        type: SET_MAKE_PAYMENT_INSTRUMENT,
        payload: paymentInstrument
    };
};

export const setPaymentInstrumentSelectedCardType = (paymentInstrument) => {
    return {
        type: SET_PAYMENT_INSTRUMENT_SELECTED_CARD_TYPE,
        payload: paymentInstrument
    };
};

export const setMakePaymentInstrumentBillingAddress = (billingAddress) => {
    return {
        type: SET_MAKE_PAYMENT_INSTRUMENT_BILLING_ADDRESS,
        payload: billingAddress
    };
};
