import Immutable from 'seamless-immutable';
import * as SearchActions from '../actions/search.giftcard.actions';

export const INITIAL_STATE = Immutable({
    giftCardSearchModel: null
});

export default function SearchGiftCardsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case SearchActions.SET_GIFT_CARD_SEARCH_MODEL:
            return state.set('giftCardSearchModel', payload);
        case SearchActions.RESET_SEARCH_GIFT_CARD_MODEL_RECOVERABLE:
            return INITIAL_STATE;
        default:
            return state;
    }
}