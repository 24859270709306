import Immutable from 'seamless-immutable';
import clone from 'ramda/src/clone';
import propEq from 'ramda/src/propEq';

export const SubscriptionQuoteForPreview = (subscriptionQuotes, quote) => {
    return subscriptionQuotes && Immutable.asMutable(subscriptionQuotes.map((subscriptionQuote) => {
        return Object.assign({}, subscriptionQuote, {
            items: subscriptionQuote.items.map((item) => {
                return Object.assign({
                    quoteItem: quote.Items.find(propEq(item.id, 'Id'))
                }, item);
            }).filter((item) => {
                return item.quoteItem;
            })
        });
    }), {
        deep: true
    });
};

export const populateItemDetails = (items, cartItemDetails, quote) => {
    const clonedItems = clone(items);
    return Immutable(clonedItems.map((item) => {
        const cartItem = cartItemDetails && cartItemDetails.length ? cartItemDetails.find((cItem) => {
            return cItem.PricingPlanId === item.PricingPlanId && cItem.ProductId === item.ProductId;
        }) : null;
        const quoteItem = quote && quote.Items && quote.Items.length ? quote.Items.find((qItem) => {
            return qItem.Product.Id === item.ProductId && qItem.PricingPlan.Id === item.PricingPlanId;
        }) : null;
        if (cartItem) {
            item.Details = cartItem.Details;
            item = Object.assign({}, cartItem, item);
        } else {
            if (quoteItem) {
                item.Details.PricingPlan = Object.assign({}, item.Details.PricingPlan, quoteItem.PricingPlan);
                item.Details.Product = Object.assign({}, item.Details.Product, quoteItem.Product);
            }
        }
        return item;
    }));
};

export const removeQuantities = (items) => {
    const clonedItems = clone(items);
    if (clonedItems.length) {
        clonedItems.forEach((item) => {
            delete(item.Quantity);
            delete(item.existingQuantity);
        });
    }
    return clonedItems;
};
