import Immutable from 'seamless-immutable';
import {pathOr} from 'ramda';
import {RESET_CUSTOMER_RECOVERABLE} from './../actions/customer.actions';
import {
    ADD_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILE,
    CLEAR_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILES,
    CLEAR_IMPORT_BULK_SERVICE_ATTRIBUTES_JOBS_PENDING_UPDATED_STATUS,
    ORDER_DETAILS_RETRIEVE_ORDER_CONTEXT,
    REMOVE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILE,
    RETRIEVE_AWS_S3_UPLOAD_CONFIGURATION,
    RETRIEVE_BULK_SERVICES_TEMPLATE_CONSTANTS,
    RETRIEVE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_DETAILS,
    SEND_BULK_SERVICE_ATTRIBUTES_UPLOAD_NOTIFICATION,
    SET_CHANGE_FDO_EXECUTION_OPTIONS,
    SET_CURRENT_TAB,
    SET_IS_IMPORTING_BULK_SERVICE_ATTRIBUTES,
    UPDATE_FDO_EXECUTION_DATE_CONSTANTS,
    UPDATE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILE_STATUS,
    UPDATE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOBS_PENDING_UPDATED_STATUS
} from '../actions/order.details.actions';
import {EMPTY_OBJECT} from '../constants/common.constants';

export const INITIAL_STATE = Immutable({
    data: {
        billingEffectiveDateConfiguration: EMPTY_OBJECT,
        executionOptions: EMPTY_OBJECT
    },
    exportingBulkServicesAttributeError: null,
    importServiceAttributesJobDetails: null,
    importServiceAttributesJobDetailsError: null,
    importServiceAttributesJobPendingFiles: [],
    importServiceAttributesJobsPendingUpdatedStatus: {},
    importServiceAttributesUploadConfiguration: null,
    isExportingBulkServiceAttributes: false,
    isImportingServiceAttributes: false,
    isRetrievingImportServiceAttributesJobDetails: false,
    isRetrievingImportServiceAttributesUploadConfiguration: false,
    isRetrievingOrderContext: false,
    isSendingBulkServiceAttributesUploadNotification: false,
    isUpdatingFdoDate: false,
    selectedTab: 1
});

export default function OrderDetailsReducer(state = INITIAL_STATE, {payload = {}, type, requestObject}) {
    switch (type) {
        case ADD_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILE:
            return state.set('importServiceAttributesJobPendingFiles',
                addImportBulkServiceAttributesJobPendingFile(state, payload));
        case CLEAR_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILES:
            return state.set('importServiceAttributesJobPendingFiles',
                INITIAL_STATE.importServiceAttributesJobPendingFiles);
        case CLEAR_IMPORT_BULK_SERVICE_ATTRIBUTES_JOBS_PENDING_UPDATED_STATUS:
            return state.set('importServiceAttributesJobsPendingUpdatedStatus',
                INITIAL_STATE.importServiceAttributesJobsPendingUpdatedStatus);
        case REMOVE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILE:
            return state.set('importServiceAttributesJobPendingFiles',
                removeImportBulkServiceAttributesJobPendingFile(state, payload));
        case RETRIEVE_AWS_S3_UPLOAD_CONFIGURATION.BEGIN:
            return state
                .set('isRetrievingImportServiceAttributesUploadConfiguration', true);
        case RETRIEVE_AWS_S3_UPLOAD_CONFIGURATION.FAILURE:
            return state
                .set('isRetrievingImportServiceAttributesUploadConfiguration', false);
        case RETRIEVE_AWS_S3_UPLOAD_CONFIGURATION.SUCCESS:
            return state
                .set('importServiceAttributesUploadConfiguration', payload)
                .set('isRetrievingImportServiceAttributesUploadConfiguration', false);
        case RETRIEVE_BULK_SERVICES_TEMPLATE_CONSTANTS.BEGIN:
            return state
                .set('isExportingBulkServiceAttributes', true)
                .set('exportingBulkServicesAttributeError', null);
        case RETRIEVE_BULK_SERVICES_TEMPLATE_CONSTANTS.SUCCESS:
            return state
                .set('isExportingBulkServiceAttributes', false)
                .set('exportingBulkServicesAttributeError', null);
        case RETRIEVE_BULK_SERVICES_TEMPLATE_CONSTANTS.FAILURE:
            return state
                .set('isExportingBulkServiceAttributes', false)
                .set('exportingBulkServicesAttributeError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                });
        case RETRIEVE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_DETAILS.BEGIN:
            return state
                .set('isRetrievingImportServiceAttributesJobDetails', true)
                .set('importServiceAttributesJobDetails', null)
                .set('importServiceAttributesJobDetailsError', null);
        case RETRIEVE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_DETAILS.FAILURE:
            return state
                .set('importServiceAttributesJobDetails', null)
                .set('isRetrievingImportServiceAttributesJobDetails', false)
                .set('importServiceAttributesJobDetailsError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                });
        case RETRIEVE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_DETAILS.SUCCESS:
            return state
                .set('importServiceAttributesJobDetails', payload)
                .set('isRetrievingImportServiceAttributesJobDetails', false)
                .set('importServiceAttributesJobDetailsError', null);
        case SEND_BULK_SERVICE_ATTRIBUTES_UPLOAD_NOTIFICATION.BEGIN:
            return state
                .set('isSendingBulkServiceAttributesUploadNotification', true);
        case SEND_BULK_SERVICE_ATTRIBUTES_UPLOAD_NOTIFICATION.FAILURE:
            return state
                .set('isSendingBulkServiceAttributesUploadNotification', false);

        // NOTE: Index "0" is assumed below because currently only one file may be uploaded at a time
        case SEND_BULK_SERVICE_ATTRIBUTES_UPLOAD_NOTIFICATION.SUCCESS:
            return state
                .set('importServiceAttributesJobsPendingUpdatedStatus',
                    updateImportBulkServiceAttributesJobsPendingUpdatedStatus(state, [{
                        dateAdded: pathOr(null, ['FileUploadConfirmations', 0, 'Added'], payload),
                        dateLastModified: pathOr(null, ['FileUploadConfirmations', 0, 'Modified'], payload),
                        offeringId: requestObject.OfferingId,
                        pricingPlanId: requestObject.PricingPlanId,
                        statusId: pathOr(null, ['FileUploadConfirmations', 0, 'BulkExportStatus'], payload)
                    }]))
                .set('isSendingBulkServiceAttributesUploadNotification', false);
        case SET_CURRENT_TAB:
            return state.set('selectedTab', payload);
        case SET_IS_IMPORTING_BULK_SERVICE_ATTRIBUTES:
            return state.set('isImportingServiceAttributes', payload);
        case RESET_CUSTOMER_RECOVERABLE:
            return INITIAL_STATE;
        case UPDATE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_FILE_STATUS:
            return state.set('importServiceAttributesJobPendingFiles',
                updateImportBulkServiceAttributesJobPendingFileStatus(state, payload));
        case UPDATE_IMPORT_BULK_SERVICE_ATTRIBUTES_JOBS_PENDING_UPDATED_STATUS:
            return state.set('importServiceAttributesJobsPendingUpdatedStatus',
                updateImportBulkServiceAttributesJobsPendingUpdatedStatus(state, payload));
        case SET_CHANGE_FDO_EXECUTION_OPTIONS:
            return state
                .setIn(['data', 'executionOptions'], payload);
        case ORDER_DETAILS_RETRIEVE_ORDER_CONTEXT.BEGIN:
            return state.set('isRetrievingOrderContext', true);
        case ORDER_DETAILS_RETRIEVE_ORDER_CONTEXT.SUCCESS:
            return state
                .setIn(['data', 'billingEffectiveDateConfiguration'], payload.BillingEffectiveDateConfiguration)
                .set('isRetrievingOrderContext', false);
        case ORDER_DETAILS_RETRIEVE_ORDER_CONTEXT.FAILURE:
            return state.set('isRetrievingOrderContext', false);
        case UPDATE_FDO_EXECUTION_DATE_CONSTANTS.BEGIN:
            return state.set('isUpdatingFdoDate', true);
        case UPDATE_FDO_EXECUTION_DATE_CONSTANTS.SUCCESS:
            return state.set('isUpdatingFdoDate', false);
        case UPDATE_FDO_EXECUTION_DATE_CONSTANTS.FAILURE:
            return state.set('isUpdatingFdoDate', false);
        default:
            return state;
    }
}

// NOTE: Only the "essential" properties are transferred from the File object to
// the item in the store since Immutable will strip others and they're not necessary
const addImportBulkServiceAttributesJobPendingFile = (state, addedFile) => {
    const addedFileSummary = {
        importResults: addedFile.importResults,
        name: addedFile.name,
        type: addedFile.type
    };

    return state.importServiceAttributesJobPendingFiles.concat([addedFileSummary]);
};

const removeImportBulkServiceAttributesJobPendingFile = (state, removedFileName) => {
    return state.importServiceAttributesJobPendingFiles.filter((pendingFile) => {
        return removedFileName !== pendingFile.name;
    });
};

const updateImportBulkServiceAttributesJobPendingFileStatus = (state, updatedFile) => {
    const fileIndex = state.importServiceAttributesJobPendingFiles.findIndex((pendingFile) => {
        return updatedFile.name === pendingFile.name;
    });

    return state.importServiceAttributesJobPendingFiles.setIn([fileIndex, 'importResults'], [updatedFile.status]);
};

const updateImportBulkServiceAttributesJobsPendingUpdatedStatus = (state, jobDetails) => {
    let transformedJobs = state.importServiceAttributesJobsPendingUpdatedStatus;

    jobDetails.forEach((job) => {
        const pricingPlanIdAsString = job.pricingPlanId.toString();

        if (state.importServiceAttributesJobsPendingUpdatedStatus[pricingPlanIdAsString]) {
            transformedJobs = state.importServiceAttributesJobsPendingUpdatedStatus.without(pricingPlanIdAsString);
        } else {
            transformedJobs = state.importServiceAttributesJobsPendingUpdatedStatus.set(pricingPlanIdAsString, job);
        }
    });

    return transformedJobs;
};
