import clone from 'ramda/src/clone';
import pathOr from 'ramda/src/pathOr';
import propEq from 'ramda/src/propEq';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import moment from 'moment';
import {TELEPHONE_SELECTION_COUNTRY_CODE_KEY} from '../constants/wizard.constants';
import {
    BILLER_RULE_INSTANCE_TYPE,
    ZERO_DOWNPAYMENT
} from '../../customercare.constants';
import {discountTypes} from '../../components/shared/decisionOverrideDiscountDialog/decision.override.discount.dialog.helper';
import {GetDownPaymentTypeValue} from '../helpers/offer.ordering.wizard.selector.helper';

export const selectFutureDatedOrderConfigurationMaxDateForCurrentBusinessUnit = (fdoConfiguration) => {
    const selectedFdoConfigObject = findNonGlobalOrReturnGlobal(fdoConfiguration);
    if (selectedFdoConfigObject) {
        const maxDate = selectedFdoConfigObject.AdditionalProperties.find(propEq('maximum_maturity_term_days', 'Key'));
        const today = moment();
        today.add(convertStringToNumber(maxDate.Value), 'days');
        return today.startOf('day').utc().toISOString();
    }
};

export const selectFutureDatedOrderConfigurationIsAllowedForCurrentBusinessUnit = (fdoConfiguration) => {
    const selectedFdoConfigObject = findNonGlobalOrReturnGlobal(fdoConfiguration);
    if (selectedFdoConfigObject) {
        return selectedFdoConfigObject.AdditionalProperties.find(propEq('is_future_dated_ordering_enabled', 'Key')).Value === 'True';
    }
};

const findNonGlobalOrReturnGlobal = (fdoConfig) => {
    return fdoConfig.find(x => {
        return !x.Global;
    }) || fdoConfig.find(x => {
        return x.Global;
    });
};

export const setDownPaymentProperties = (item, downPayment, financedFullAmount, isFullPriceDownPaymentSelected) => {
    item.downPayment = downPayment;
    item.isFullPriceDownPaymentSelected = isFullPriceDownPaymentSelected;
    item.isDownPaymentEqualToFirstSelected = !isFullPriceDownPaymentSelected;
    item.DownPaymentAmount = isFullPriceDownPaymentSelected ? financedFullAmount : downPayment;
    item.DownPaymentType = GetDownPaymentTypeValue(item);
    return item;
};

export const isFinancedShoppingCartItem = (item) => {
    // There can be only 1 Financed BRI on the Pricing Plan.
    const financeBillerRuleInstance = pathOr(null, ['Details', 'PricingPlan', 'PricingPlanBillerRuleInstances',
        'FinanceBillerRuleInstances', '0'], item);
    const isFinancedItem = financeBillerRuleInstance ?
        (financeBillerRuleInstance.Type === BILLER_RULE_INSTANCE_TYPE.FINANCE) : false;
    return {
        isFinancedItem,
        minimumDownPayment: isFinancedItem ? (financeBillerRuleInstance.MinimumDownPaymentAmount || ZERO_DOWNPAYMENT) :
            ZERO_DOWNPAYMENT,
        isFullUpfrontPayment: isFinancedItem ? (financeBillerRuleInstance.FullUpfrontPayment || false) : false
    };
};

export function filterStatesByCountry(states, country) {
    return states.filter((state) => {
        return mapAdditionalProperties(state).country_code === country;
    });
}

export function findAndAttachStateName(states, names) {
    return Object.values(states).map((state) => {
        const stateFound = findState(state.stateCode, names);
        return Object.assign({}, state, {
            stateName: stateFound ? stateFound.Name : state.stateCode
        });
    });
}

export function findState(stateCode, states) {
    return states.find((state) => {
        return state.Value === stateCode;
    });
}

export function filterByCountry(codes, country) {
    return codes.filter((code) => {
        const countryProp = code.AdditionalProperties.find((addProp) => {
            return addProp.Key === TELEPHONE_SELECTION_COUNTRY_CODE_KEY;
        });
        return countryProp.Value === country;
    });
}
export function mapCodesAdditionalProperties(codes) {
    return codes.map(mapAdditionalProperties);
}

function mapAdditionalProperties(code) {
    const additionalProps = {};
    code.AdditionalProperties.forEach((addProp) => {
        additionalProps[addProp.Key] = addProp.Value;
    });
    return additionalProps;
}

function createStateNpa(states, code) {
    const npaCodes = {
        Id: '',
        nxx: []
    };
    if (!states[code.state_code].locations[code.location].codesNpa[code.npa]) {
        states[code.state_code].locations[code.location].codesNpa[code.npa] = clone(npaCodes);
        states[code.state_code].locations[code.location].codesNpa[code.npa].Id = code.npa;
        if (states[code.state_code].locations[code.location].codesNpa[code.npa].nxx.indexOf(code.nxx) === -1) {
            states[code.state_code].locations[code.location].codesNpa[code.npa].nxx.push(code.nxx);
        }
    } else {
        if (states[code.state_code].locations[code.location].codesNpa[code.npa].nxx.indexOf(code.nxx) === -1) {
            states[code.state_code].locations[code.location].codesNpa[code.npa].nxx.push(code.nxx);
        }
    }

    return states;
}

export function reduceByState(codes) {
    let states = {};
    codes.forEach((code) => {
        const location = {
            name: code.location,
            codes:  [code.nxx],
            codesNpa: {}
        };
        if (!states[code.state_code]) {
            states[code.state_code] = {
                stateCode: code.state_code,
                stateName: code.state_code,
                code: {},
                locations: {}
            };
            states[code.state_code].code[code.npa] = code.npa;
            states[code.state_code].locations[code.location] = location;
            states = createStateNpa(states, code);
        } else {
            states[code.state_code].code[code.npa] = code.npa;
            if (!states[code.state_code].locations[code.location]) {
                states[code.state_code].locations[code.location] = location;
                states = createStateNpa(states, code);
            } else {
                states[code.state_code].locations[code.location].codes.push(code.nxx);
                states = createStateNpa(states, code);

            }
        }
    });
    return states;
}

export const getChargeAmount = (briCharges) => {
    if (briCharges.length === 1) {
        return briCharges[0].ChargeAmount;
    } else {
        return briCharges.find((briCharge) => {
            return moment(moment(new Date()).startOf('day').utc().toISOString()).isBetween(briCharge.StartDate, briCharge.EndDate);
        });
    }
};

export const getDiscountDisplayAmount = (discountMetadata) => {
    return (discountMetadata.Type === convertStringToNumber(discountTypes.amountOff.value)) ?
        discountMetadata.Amount : Math.round(discountMetadata.Amount * 100);
};

export const isChargeOverridableOption = (option) => {
    const filteredBris = option.PricingPlanBillerRuleInstances ? [
        ...option.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances,
        ...option.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances,
        ...option.PricingPlanBillerRuleInstances.FinanceBillerRuleInstances
    ] : [];

    return filteredBris.some((bri) => {
        return bri.AllowChargeOverride;
    });
};