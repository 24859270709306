import partial from 'ramda/src/partial';
import pathOr from 'ramda/src/pathOr';
import {
    clearQuote,
    retrieveAttributesPromise,
    retrieveOfferingContextPromise,
    searchInventoryPromise,
    updateCartSummaryPromise
} from '../actions/offering.order.actions';
import {retrieveWalletPromise} from '../actions/customer.ewallet.actions';
import {
    FINANCE_SERVICE_FEATURES_IDENTIFIER_VALUE,
    NOTIFICATION_TIME_LENGTH
} from '../../customercare.constants';
import i18n from 'invision-core/src/components/i18n/i18n';
import ApiConstants from 'invision-core/src/api/constants';
import {getUiNotificationService} from 'invision-core/src/components/injectables/injector.helper';
import {RouteParams} from 'invision-core/src/components/router/router.selectors';
import ThunkHelper from 'invision-core/src/api/thunk.helper';
import CustomerCareKeys from '../../locales/keys';
import createWizardActions from './wizard.action.helper';
import {EditCustomerInfoSelector} from '../selectors/new.connect.wizard.selectors';
import {retrieveOfferingsMetadata} from 'invision-core/src/components/metadata/offerings/offerings.actions';

const actionWithPayload = partial((type, payload) => {
    return {
        type,
        payload
    };
});

export default (prefix) => {
    const basicWizardActions = createWizardActions(prefix);

    const CANCEL_WIZARD = basicWizardActions.CANCEL_WIZARD;
    const cancelWizard = basicWizardActions.cancelWizard;
    const GO_TO_NEXT_STEP = basicWizardActions.GO_TO_NEXT_STEP;
    const GO_TO_PREVIOUS_STEP = basicWizardActions.GO_TO_PREVIOUS_STEP;
    const GO_TO_STEP = basicWizardActions.GO_TO_STEP;
    const goToNextStep = basicWizardActions.goToNextStep;
    const goToPreviousStep = basicWizardActions.goToPreviousStep;
    const goToStep = basicWizardActions.goToStep;
    const RESET_OFFER_ADDITIONAL_PROPERTIES = `${prefix}_RESET_OFFER_ADDITIONAL_PROPERTIES`;
    const RESTORE_OPTION_DEFAULT_PRICING = `${prefix}_RESTORE_BULK_OPTION_DEFAULT_PRICING`;
    const SAVE_BWS_OPTION_EDIT = 'SAVE_BWS_OPTION_EDIT';
    const SAVE_OPTION_PRICING = `${prefix}_SAVE_OPTION_PRICING`;
    const SET_ATTRIBUTE_SEARCHING_INVENTORY = `${prefix}_SET_ATTRIBUTE_SEARCHING_INVENTORY`;
    const SET_CURRENT_STEP_IS_INVALID = basicWizardActions.SET_CURRENT_STEP_IS_INVALID;
    const SET_EDIT_ATTRIBUTE_GROUPS = `${prefix}_SET_EDIT_ATTRIBUTE_GROUPS`;
    const SET_EDIT_CART_ADDITIONAL_PROPERTY = `${prefix}_SET_EDIT_CART_ADDITIONAL_PROPERTY`;
    const SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE = `${prefix}_SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE`;
    const SET_EDIT_OFFER_ADDITIONAL_PROPERTY = `${prefix}_SET_EDIT_OFFER_ADDITIONAL_PROPERTY`;
    const SET_EDIT_OFFER_ADDITIONAL_PROPERTY_ON_CHANGE = `${prefix}_SET_EDIT_OFFER_ADDITIONAL_PROPERTY_ON_CHANGE`;
    const SET_EDIT_OPTION = `${prefix}_SET_EDIT_OPTION`;
    const SET_EDIT_ORDER_ADDITIONAL_PROPERTY = `${prefix}_SET_EDIT_ORDER_ADDITIONAL_PROPERTY`;
    const SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE = `${prefix}_SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE`;
    const SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS = `${prefix}_SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS`;
    const SET_PAYMENT_INFO = `${prefix}_SET_PAYMENT_INFO`;
    const SET_SELECTED_WIZARD_TAB = basicWizardActions.SET_SELECTED_WIZARD_TAB;
    const setCurrentStepIsInvalid = basicWizardActions.setCurrentStepIsInvalid;
    const setSelectedTab = basicWizardActions.setSelectedTab;
    const SET_SELECTED_OFFER = `${prefix}_SET_SELECTED_OFFER`;
    const SET_SELECTED_FACETS_EDIT_COPY = `${prefix}_SET_SELECTED_FACETS_EDIT_COPY`;
    const SET_SELECTED_OFFER_EDIT_COPY = `${prefix}_SET_SELECTED_OFFER_EDIT_COPY`;
    const SET_SELECTED_OFFERING_CHARGE_TYPES_EDIT_COPY = `${prefix}_SET_SELECTED_OFFERING_CHARGE_TYPES_EDIT_COPY`;
    const UPDATE_PAYMENT_INFO = `${prefix}_UPDATE_PAYMENT_INFO`;
    const UPDATE_BILL_CYCLE = `${prefix}_UPDATE_BILL_CYCLE`;
    const UPDATE_INVENTORY_SELECTION = `${prefix}_UPDATE_INVENTORY_SELECTION`;
    const UPDATE_OFFER_ATTRIBUTE = `${prefix}_UPDATE_OFFER_ATTRIBUTE`;
    const UPDATE_PORT_IN_REQUEST = `${prefix}_UPDATE_PORT_IN_REQUEST`;
    const SEND_PORT_IN_OR_ITEM_RESERVATION = `${prefix}_SEND_PORT_IN_OR_ITEM_RESERVATION`;
    const UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE = `${prefix}_UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE`;
    const UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL = `${prefix}_UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL`;
    const UPDATE_EDIT_BRI_PRICE = `${prefix}_UPDATE_EDIT_BRI_PRICE`;
    const UPDATE_SELECTED_SERVICE_FEATURES = `${prefix}_UPDATE_SELECTED_SERVICE_FEATURES`;
    const UPDATE_SHOPPING_CART = {
        BEGIN: `${prefix}_UPDATE_SHOPPING_CART_BEGIN`,
        SUCCESS: `${prefix}_UPDATE_SHOPPING_CART_SUCCESS`,
        FAILURE: `${prefix}_UPDATE_SHOPPING_CART_FAILURE`
    };
    const UPDATE_SHOPPING_CART_EVENTS = [UPDATE_SHOPPING_CART.BEGIN, UPDATE_SHOPPING_CART.SUCCESS, UPDATE_SHOPPING_CART.FAILURE];

    const RETRIEVE_ADDRESSES = {
        BEGIN: `${prefix}_RETRIEVE_ADDRESSES_BEGIN`,
        SUCCESS: `${prefix}_RETRIEVE_ADDRESSES_SUCCESS`,
        FAILURE: `${prefix}_RETRIEVE_ADDRESSES_FAILURE`
    };
    const RETRIEVE_ADDRESSES_TYPES = [RETRIEVE_ADDRESSES.BEGIN, RETRIEVE_ADDRESSES.SUCCESS, RETRIEVE_ADDRESSES.FAILURE];

    const setAttributeSearchingInventory = actionWithPayload([SET_ATTRIBUTE_SEARCHING_INVENTORY]);
    const setEditAttributeGroups = actionWithPayload([SET_EDIT_ATTRIBUTE_GROUPS]);
    const setEditPhysicalAttributeGroups = actionWithPayload([SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS]);
    const setSelectedOfferEditCopy = actionWithPayload([SET_SELECTED_OFFER_EDIT_COPY]);

    const updatePaymentInfo = actionWithPayload([UPDATE_PAYMENT_INFO]);
    const updateBillCycle = actionWithPayload([UPDATE_BILL_CYCLE]);

    const setPaymentInfo = (paymentInfo) => {
        return {
            type: SET_PAYMENT_INFO,
            payload: paymentInfo
        };
    };

    const setSelectedOffer = (selectedOfferId) => {
        return {
            type: SET_SELECTED_OFFER,
            payload: selectedOfferId
        };
    };

    const clearQuoteAndSetSelectedOffer = (selectedOfferId) => {
        return (dispatch) => {
            dispatch(clearQuote());
            dispatch(setSelectedOffer(selectedOfferId));
        };
    };

    const fetchAttributes = ({changeOfferingInstanceId,
        completedAttributes = [],
        completedDecisions = [],
        completedPhysicalAttributes = [],
        isConvergentBiller = true,
        isEditOffer,
        isReconnectAction = false,
        offeringId,
        offeringInstanceId,
        pickupId,
        transitionOutcomes,
        useSavedShoppingCart}) => {
        return (dispatch, getState) => {
            const state = getState(),
                routeParams = RouteParams(state),
                customerId = routeParams.customerId,
                newConnect = (customerId)? {} : EditCustomerInfoSelector(state);

            return retrieveAttributesPromise({
                changeOfferingInstanceId,
                completedDecisions: completedDecisions.concat(completedAttributes).concat(completedPhysicalAttributes),
                customerId,
                customerLanguage: newConnect.Language,
                dispatch,
                isConvergentBiller,
                isEditOffer,
                isReconnectAction,
                offeringId,
                offeringInstanceId,
                pickupId,
                subscriberSubtenantId: newConnect.SubtenantId,
                transitionOutcomes,
                useSavedShoppingCart
            });
        };
    };

    const fetchAttributesAndNavigate = ({offerId,
        offeringInstanceId,
        completedDecisions = [],
        completedAttributes = [],
        completedPhysicalAttributes = [],
        skipAttributesTargetStep = CustomerCareKeys.WIZARD.STEPS.CUSTOMER}) => {
        return (dispatch, getState) => {
            const newConnect = EditCustomerInfoSelector(getState());
            return retrieveAttributesPromise({
                dispatch,
                offeringId: offerId,
                offeringInstanceId,
                completedDecisions: completedDecisions.concat(completedAttributes).concat(completedPhysicalAttributes),
                customerLanguage: newConnect.Language,
                subscriberSubtenantId: newConnect.SubtenantId
            })
                .then((response) => {

                    const visibleValueDecisions = response.Context.ValueDecisions.filter((decision) => {
                    // if there is a DependentServiceAttributeId then the attribute is hidden
                    // if they are all hidden, then we don't need to show attributes page
                    // if IsServiceFeatureAvailable is false and ServiceAttributeId is Finance service feature then we don't need to show attributes page
                        return !decision.DependentServiceAttributeId && !(!decision.IsServiceFeatureAvailable && decision.ServiceAttributeId === FINANCE_SERVICE_FEATURES_IDENTIFIER_VALUE);
                    });

                    if (visibleValueDecisions.length > 0 || (response.Context.PhysicalInventoryDecisions && response.Context.PhysicalInventoryDecisions.length > 0)) {
                        dispatch(goToNextStep());
                    } else {
                        dispatch(goToStep(skipAttributesTargetStep));
                    }
                })
                .catch((error) => {
                    if (!pathOr([], ['FaultData'], error).length) {
                        getUiNotificationService().error(error.translatedMessage ? error.translatedMessage : i18n.translate(CustomerCareKeys.DECISIONS_ERROR), null, {
                            timeOut: NOTIFICATION_TIME_LENGTH
                        });
                    }
                });
        };
    };

    const fetchDecisionsAndNavigate = ({offerId,
        customerId,
        skipAttributesTargetStep = CustomerCareKeys.WIZARD.STEPS.CUSTOMER,
        isConvergentBiller=true,
        segmentationContext = []}) => {
        return (dispatch, getState) => {
            dispatch(clearQuoteAndSetSelectedOffer(offerId));

            // Set Offers step to invalid until the api call is successful
            dispatch(setCurrentStepIsInvalid(true));
            const state = getState();
            const newConnect = EditCustomerInfoSelector(state);
            const subtenantId = (!customerId)? newConnect.SubtenantId : null;

            return dispatch(retrieveOfferingsMetadata([offerId])).then(() => {
                return retrieveOfferingContextPromise(dispatch, offerId, customerId, false, undefined, isConvergentBiller, segmentationContext, newConnect.Language, subtenantId)
                    .then((response) => {
                        dispatch(setCurrentStepIsInvalid(false));
                        if (response.Context.Decisions.length > 0) {
                            dispatch(goToNextStep());
                        } else {
                            retrieveAttributesPromise({
                                dispatch,
                                offeringId: offerId,
                                customerId,
                                isConvergentBiller,
                                customerLanguage: newConnect.Language,
                                subscriberSubtenantId: subtenantId
                            })
                                .then((response) => {
                                    if (response.Context.ValueDecisions.length > 0 || (response.Context.PhysicalInventoryDecisions && response.Context.PhysicalInventoryDecisions.length > 0)) {
                                        dispatch(goToStep(CustomerCareKeys.WIZARD.STEPS.ATTRIBUTES));
                                    } else if (customerId && skipAttributesTargetStep === CustomerCareKeys.WIZARD.STEPS.CHECKOUT) {
                                        dispatch(goToStep(skipAttributesTargetStep));
                                    } else {
                                        dispatch(goToStep(skipAttributesTargetStep));
                                    }
                                });
                        }

                    });
            }).catch((error) => {
                getUiNotificationService().error(error.translatedMessage, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
                dispatch(setSelectedOffer(null));
            });
        };
    };

    const fetchWallet = (customerId) => {
        return (dispatch) => {
            const data = {
                customerId: customerId
            };
            return retrieveWalletPromise(dispatch, data);
        };
    };

    const searchAttributeInventory = (attribute) => {
        return (dispatch, getState) => {
            const routeParams = RouteParams(getState());
            dispatch(setAttributeSearchingInventory(attribute.id));
            const inventoryTypeId = attribute.inventoryItemReservation ? attribute.inventoryItemReservation.inventoryTypeId : attribute.inventoryTypeId;
            searchInventoryPromise(dispatch, inventoryTypeId, [], routeParams.customerId);
        };
    };

    const setSelectedFacetsEditCopy = (facetId, isSelected) => {
        return {
            type: SET_SELECTED_FACETS_EDIT_COPY,
            payload: {
                facetId,
                isSelected
            }
        };
    };

    const setSelectedOfferingChargeTypesEditCopy = (offeringChargeTypeId, isSelected) => {
        return {
            type: SET_SELECTED_OFFERING_CHARGE_TYPES_EDIT_COPY,
            payload: {
                offeringChargeTypeId,
                isSelected
            }
        };
    };

    const updateAttribute = (attributeId, formValue) => {
        return {
            type: UPDATE_OFFER_ATTRIBUTE,
            payload: {
                attributeId,
                formValue
            }
        };
    };

    const updateSelectedServiceFeatures = (attributeId, selectedServiceFeatures, billerRuleInstanceDetails) => {
        return {
            type: UPDATE_SELECTED_SERVICE_FEATURES,
            payload: {
                attributeId,
                billerRuleInstanceDetails,
                selectedServiceFeatures
            }
        };
    };

    const updatePortInRequest = (attributeId, portInData, token, address) => {
        return {
            type: UPDATE_PORT_IN_REQUEST,
            payload: {
                attributeId,
                portInData,
                token,
                address
            }
        };
    };

    const sendPortInOrItemReservation = (attributeId, sendPortInReservation) => {
        return {
            type: SEND_PORT_IN_OR_ITEM_RESERVATION,
            payload: {
                attributeId,
                sendPortInReservation
            }
        };
    };

    const updateOfferPhysicalInventoryTypeAttribute = (pricingPlanId, inventoryId, typeAttributeId, value) => {
        return {
            type: UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE,
            payload: {
                pricingPlanId,
                inventoryId,
                typeAttributeId,
                value
            }
        };
    };

    const updateOfferPhysicalInventoryMakeAndModel = (pricingPlanId, inventoryId, makeId, modelId) => {
        return {
            type: UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL,
            payload: {
                pricingPlanId,
                inventoryId,
                makeId,
                modelId
            }
        };
    };

    const updateInventorySelection = (attributeId, InventoryItem, formValue) => {
        return {
            type: UPDATE_INVENTORY_SELECTION,
            payload: {
                attributeId,
                InventoryItem,
                formValue
            }
        };
    };

    const updateCart = (
        offeringId,
        offeringInstanceId,
        completedDecisions,
        isModify,
        customerId,
        callback,
        contextOfferingAction,
        transitionContext,
        isConvergentBiller=true,
        customerLanguage,
        pickupId,
        changeOfferingInstanceId,
        useSavedShoppingCart
    ) => {
        return (dispatch, getState) => {
            // TODO: Customer Order - we should not be grabbing state in actions
            const state = getState();
            const newConnect = EditCustomerInfoSelector(state);
            const subtenantId = (!customerId)? newConnect.SubtenantId : null;
            const postalCode = (customerId) ? null : newConnect.postalCode;
            // Disallow the user from submitting again until the request is done
            dispatch(setCurrentStepIsInvalid(true));
            updateCartSummaryPromise(
                dispatch,
                offeringId,
                offeringInstanceId,
                completedDecisions,
                isModify,
                customerId,
                contextOfferingAction,
                transitionContext,
                isConvergentBiller,
                customerLanguage,
                subtenantId,
                postalCode,
                pickupId,
                changeOfferingInstanceId,
                useSavedShoppingCart
            ).then(callback)
                .catch((err) => {
                    getUiNotificationService().error(err.translatedMessage, null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });
                }).finally(() => {
                // Make it ok for the user to submit again
                    dispatch(setCurrentStepIsInvalid(false));
                });
        };
    };

    const setEditOption = (option) => {
        return {
            type: SET_EDIT_OPTION,
            payload: option
        };
    };

    const restoreOptionDefaultPricing = (optionId) => {
        return {
            type: RESTORE_OPTION_DEFAULT_PRICING,
            payload: optionId
        };
    };

    const updateEditOptionPrice = (params) => {
        return {
            type: UPDATE_EDIT_BRI_PRICE,
            payload: params
        };
    };

    const saveOptionEdit = (option) => {
        return {
            type: SAVE_BWS_OPTION_EDIT,
            payload: option
        };
    };

    const saveBulkPricing = (option) => {
        return {
            type: SAVE_OPTION_PRICING,
            payload: option
        };
    };

    const retrieveAddressesPromise = (dispatch, customerId) => {
        return ThunkHelper(dispatch, RETRIEVE_ADDRESSES_TYPES, {
            method: 'post',
            url: 'SubscriberManagement/RetrieveAddresses',
            data: {
                IncludeRemoved: false
            },
            headers: {
                [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
            }
        });
    };
    const fetchAddresses = (customerId) => {
        return (dispatch) => {
            return retrieveAddressesPromise(dispatch, customerId)
                .catch((errorPayload) => {
                    getUiNotificationService().error(errorPayload.translatedMessage, null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });
                });
        };
    };

    const updateShoppingCartPromise = (dispatch, customerId, shoppingCart, redemptionCodes = [], additionalOptions = {}) => {
        return ThunkHelper(dispatch, UPDATE_SHOPPING_CART_EVENTS, {
            method: 'post',
            url: 'SubscriberManagement/UpdateShoppingCart',
            headers: {
                [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
            },
            data: Object.assign({
                PersistCart: true,
                RedemptionCodes: redemptionCodes,
                ShoppingCart: shoppingCart,
                CombineProducts: false
            }, additionalOptions)
        });
    };
    const updateShoppingCart = (customerId, shoppingCart, redemptionCodes, additionalOptions = {}) => {
        return (dispatch) => {
            return updateShoppingCartPromise(dispatch, customerId, shoppingCart, redemptionCodes, additionalOptions);
        };
    };

    const setEditCartAdditionalProperty = (additionalProperties) => {
        return {
            type: SET_EDIT_CART_ADDITIONAL_PROPERTY,
            payload: additionalProperties
        };
    };

    const setEditCartAdditionalPropertyOnChange = (id, newValue) => {
        return {
            type: SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE,
            payload: {
                id: id,
                newValue: newValue
            }
        };
    };

    const setEditOrderAdditionalProperty = (additionalProperties) => {
        return {
            type: SET_EDIT_ORDER_ADDITIONAL_PROPERTY,
            payload: additionalProperties
        };
    };

    const setEditOrderAdditionalPropertyOnChange = (id, newValue) => {
        return {
            type: SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE,
            payload: {
                id: id,
                newValue: newValue
            }
        };
    };

    const resetOfferAdditionalProperties = () => {
        return {
            type: RESET_OFFER_ADDITIONAL_PROPERTIES,
        };
    };

    const setEditOfferAdditionalProperty = (additionalProperties) => {
        return {
            type: SET_EDIT_OFFER_ADDITIONAL_PROPERTY,
            payload: additionalProperties
        };
    };

    const setEditOfferAdditionalPropertyOnChange = (id, newValue) => {
        return {
            type: SET_EDIT_OFFER_ADDITIONAL_PROPERTY_ON_CHANGE,
            payload: {
                id: id,
                newValue: newValue
            }
        };
    };

    return {
        CANCEL_WIZARD,
        GO_TO_NEXT_STEP,
        GO_TO_PREVIOUS_STEP,
        GO_TO_STEP,
        RESET_OFFER_ADDITIONAL_PROPERTIES,
        RESTORE_OPTION_DEFAULT_PRICING,
        RETRIEVE_ADDRESSES,
        SAVE_OPTION_PRICING,
        SAVE_BWS_OPTION_EDIT,
        SEND_PORT_IN_OR_ITEM_RESERVATION,
        SET_ATTRIBUTE_SEARCHING_INVENTORY,
        SET_CURRENT_STEP_IS_INVALID,
        SET_EDIT_ATTRIBUTE_GROUPS,
        SET_EDIT_OPTION,
        SET_EDIT_CART_ADDITIONAL_PROPERTY,
        SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE,
        SET_EDIT_ORDER_ADDITIONAL_PROPERTY,
        SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE,
        SET_EDIT_OFFER_ADDITIONAL_PROPERTY,
        SET_EDIT_OFFER_ADDITIONAL_PROPERTY_ON_CHANGE,
        SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS,
        SET_PAYMENT_INFO,
        SET_SELECTED_FACETS_EDIT_COPY,
        SET_SELECTED_OFFER,
        SET_SELECTED_OFFER_EDIT_COPY,
        SET_SELECTED_OFFERING_CHARGE_TYPES_EDIT_COPY,
        SET_SELECTED_WIZARD_TAB,
        UPDATE_BILL_CYCLE,
        UPDATE_EDIT_BRI_PRICE,
        UPDATE_INVENTORY_SELECTION,
        UPDATE_OFFER_ATTRIBUTE,
        UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL,
        UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE,
        UPDATE_PAYMENT_INFO,
        UPDATE_PORT_IN_REQUEST,
        UPDATE_SELECTED_SERVICE_FEATURES,
        UPDATE_SHOPPING_CART,
        cancelWizard,
        clearQuoteAndSetSelectedOffer,
        fetchAddresses,
        fetchAttributes,
        fetchAttributesAndNavigate,
        fetchDecisionsAndNavigate,
        fetchWallet,
        goToNextStep,
        goToPreviousStep,
        goToStep,
        resetOfferAdditionalProperties,
        restoreOptionDefaultPricing,
        saveOptionEdit,
        saveBulkPricing,
        searchAttributeInventory,
        sendPortInOrItemReservation,
        setCurrentStepIsInvalid,
        setEditAttributeGroups,
        setEditOption,
        setEditCartAdditionalProperty,
        setEditCartAdditionalPropertyOnChange,
        setEditOrderAdditionalProperty,
        setEditOrderAdditionalPropertyOnChange,
        setEditOfferAdditionalProperty,
        setEditOfferAdditionalPropertyOnChange,
        setEditPhysicalAttributeGroups,
        setPaymentInfo,
        setSelectedFacetsEditCopy,
        setSelectedOffer,
        setSelectedOfferEditCopy,
        setSelectedOfferingChargeTypesEditCopy,
        setSelectedTab,
        updateAttribute,
        updateBillCycle,
        updateCart,
        updateEditOptionPrice,
        updateInventorySelection,
        updateOfferPhysicalInventoryMakeAndModel,
        updateOfferPhysicalInventoryTypeAttribute,
        updatePaymentInfo,
        updatePortInRequest,
        updateSelectedServiceFeatures,
        updateShoppingCart,
        updateShoppingCartPromise
    };
};
