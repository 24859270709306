import {
    isNil
} from 'ramda';
import {
    CREDIT_CHECK_METHOD,
    CREDIT_CHECK_STATUS, UPDATE_CLASSIFICATION_METHOD_STATUS
} from '../constants/credit.check.constants';

export const determineCreditClassificationStatus = (creditCheckClassification) => {
    if (isNil(creditCheckClassification)) {
        return CREDIT_CHECK_STATUS.NO_CHECK;
    } else if (creditCheckClassification.CreditCheckExpired) {
        return CREDIT_CHECK_STATUS.EXPIRED;
    } else if (creditCheckClassification.CreditCheckMethod === CREDIT_CHECK_METHOD.SKIPPED) {
        return CREDIT_CHECK_STATUS.SKIPPED;
    } else if (creditCheckClassification.CreditCheckMethod === UPDATE_CLASSIFICATION_METHOD_STATUS.OVERRIDE_CREDIT_CHECK) {
        return CREDIT_CHECK_STATUS.OVERRIDE;
    } else {
        return CREDIT_CHECK_STATUS.SUCCESS;
    }
};
