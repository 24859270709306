import {ApiConstants, ThunkHelper} from 'invision-core';

export const RETRIEVE_RATE_SHEET = {
    BEGIN: 'RETRIEVE_RATE_SHEET_BEGIN',
    SUCCESS: 'RETRIEVE_RATE_SHEET_SUCCESS',
    FAILURE: 'RETRIEVE_RATE_SHEET_FAILURE'
};
const RETRIEVE_RATE_SHEET_EVENTS = [RETRIEVE_RATE_SHEET.BEGIN, RETRIEVE_RATE_SHEET.SUCCESS, RETRIEVE_RATE_SHEET.FAILURE];

export const retrieveRateSheetPromise = (dispatch, subscriberId, itemTypes) => {
    return ThunkHelper(dispatch, RETRIEVE_RATE_SHEET_EVENTS, {
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
        },
        method: 'post',
        data: {
            RateSheetItemTypes: itemTypes
        },
        url: 'SubscriberManagement/RetrieveSubscriberRateSheet'
    });
};

export const retrieveRateSheet = (subscriberId, itemTypes) => {
    return (dispatch) => {
        return retrieveRateSheetPromise(dispatch, subscriberId, itemTypes);
    };
};

export const SET_UPDATED_RATE_SHEET_ITEM = 'SET_UPDATED_RATE_SHEET_ITEM';
export const updateRateSheetData = (payload) => {
    return {
        type: SET_UPDATED_RATE_SHEET_ITEM,
        payload: payload
    };
};

export const SET_NEW_SHEET_ITEM = 'SET_NEW_SHEET_ITEM';
export const addNewRateSheetData = (payload) => {
    return {
        type: SET_NEW_SHEET_ITEM,
        payload: payload
    };
};

export const SET_NEW_USAGE_SHEET_ITEM = 'SET_NEW_USAGE_SHEET_ITEM';
export const addNewUsageSheetData = (payload) => {
    return {
        type: SET_NEW_USAGE_SHEET_ITEM,
        payload: payload
    };
};

export const SEARCH_RATE_SHEET = 'SEARCH_RATE_SHEET';
export const searchRateSheet = (payload) => {
    return {
        type: SEARCH_RATE_SHEET,
        payload: payload
    };
};

export const ADD_RATE_SHEET_ITEM = 'ADD_RATE_SHEET_ITEM';
export const addRateSheetItem = (payload) => {
    return {
        type: ADD_RATE_SHEET_ITEM,
        payload: payload
    };
};

export const UPDATE_NEW_BRI_ITEMS = 'UPDATE_NEW_BRI_ITEMS';
export const updateNewBriItems = (payload) => {
    return {
        type: UPDATE_NEW_BRI_ITEMS,
        payload: payload
    };
};

export const RESET_BRI_DATA = 'RESET_BRI_DATA';
export const resetBriData = (payload) => {
    return {
        type: RESET_BRI_DATA
    };
};

export const UPDATE_SUBSCRIBER_RATE_SHEET = {
    BEGIN: 'UPDATE_SUBSCRIBER_RATE_SHEET_BEGIN',
    SUCCESS: 'UPDATE_SUBSCRIBER_RATE_SHEET_SUCCESS',
    FAILURE: 'UPDATE_SUBSCRIBER_RATE_SHEET_FAILURE'
};
const UPDATE_SUBSCRIBER_RATE_SHEET_EVENTS = [UPDATE_SUBSCRIBER_RATE_SHEET.BEGIN, UPDATE_SUBSCRIBER_RATE_SHEET.SUCCESS, UPDATE_SUBSCRIBER_RATE_SHEET.FAILURE];


export const updatedSubscriberRateSheetDataPromise = (dispatch, subscriberId, addItems, updateItems) => {
    return ThunkHelper(dispatch, UPDATE_SUBSCRIBER_RATE_SHEET_EVENTS, {
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
        },
        method: 'post',
        data: {
            AddItems: addItems || [],
            UpdateItems: updateItems || []
        },
        url: 'SubscriberManagement/UpdateSubscriberRateSheet'
    });
};

export const updatedSubscriberRateSheetData = (subscriberId, addItems, updateItems) => {
    return (dispatch) => {
        return updatedSubscriberRateSheetDataPromise(dispatch, subscriberId, addItems, updateItems);
    };
};

export const UPDATE_FEE = {
    BEGIN: 'UPDATE_FEE_BEGIN',
    SUCCESS: 'UPDATE_FEE_SUCCESS',
    FAILURE: 'UPDATE_FEE_FAILURE'
};
const UPDATE_FEE_EVENTS = [UPDATE_FEE.BEGIN, UPDATE_FEE.SUCCESS, UPDATE_FEE.FAILURE];


export const updateFeePromise = (dispatch, subscriberId, feeDetails) => {
    return ThunkHelper(dispatch, UPDATE_FEE_EVENTS, {
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
        },
        method: 'post',
        data: feeDetails.Id ? {
            UpdateFees: [feeDetails]
        } : {
            AddFees: [feeDetails]
        },
        url: 'SubscriberManagement/UpdateSubscriberFees'
    });
};

export const updateFee = (subscriberId, feeDetails) => {
    return (dispatch) => {
        return updateFeePromise(dispatch, subscriberId, feeDetails);
    };
};

export const UPDATE_GLOBAL_FEE_STATUS = {
    BEGIN: 'UPDATE_GLOBAL_FEE_STATUS_BEGIN',
    SUCCESS: 'UPDATE_GLOBAL_FEE_STATUS_SUCCESS',
    FAILURE: 'UPDATE_GLOBAL_FEE_STATUS_FAILURE'
};
const UPDATE_GLOBAL_FEE_STATUS_EVENTS = [UPDATE_GLOBAL_FEE_STATUS.BEGIN, UPDATE_GLOBAL_FEE_STATUS.SUCCESS, UPDATE_GLOBAL_FEE_STATUS.FAILURE];


export const updateGlobalFeeStatusPromise = (dispatch, subscriberId, feeDetails) => {
    return ThunkHelper(dispatch, UPDATE_FEE_EVENTS, {
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
        },
        method: 'post',
        data: {
            SuppressFees: [feeDetails]
        },
        url: 'SubscriberManagement/SuppressGlobalSubscriberFees'
    });
};

export const updateGlobalFeeStatus = (subscriberId, feeDetails) => {
    return (dispatch) => {
        return updateGlobalFeeStatusPromise(dispatch, subscriberId, feeDetails);
    };
};

export const RETRIEVE_TAX_CODES = {
    BEGIN: 'RETRIEVE_TAX_CODES_BEGIN',
    SUCCESS: 'RETRIEVE_TAX_CODES_SUCCESS',
    FAILURE: 'RETRIEVE_TAX_CODES_FAILURE'
};
const RETRIEVE_TAX_CODES_EVENTS = [
    RETRIEVE_TAX_CODES.BEGIN,
    RETRIEVE_TAX_CODES.SUCCESS,
    RETRIEVE_TAX_CODES.FAILURE
];

export const retrieveTaxCodesPromise = (dispatch) => {
    return ThunkHelper(dispatch, RETRIEVE_TAX_CODES_EVENTS, {
        method: 'post',
        data: {},
        url: 'SubscriberManagement/RetrieveTaxAssociations'
    });
};

export const retrieveTaxCodes = () => {
    return (dispatch) => {
        return retrieveTaxCodesPromise(dispatch);
    };
};

export const RETRIEVE_GUIDANCE_ENTITIES = {
    BEGIN: 'RETRIEVE_GUIDANCE_ENTITIES_BEGIN',
    SUCCESS: 'RETRIEVE_GUIDANCE_ENTITIES_SUCCESS',
    FAILURE: 'RETRIEVE_GUIDANCE_ENTITIES_FAILURE'
};
const RETRIEVE_GUIDANCE_ENTITIES_EVENTS = [RETRIEVE_GUIDANCE_ENTITIES.BEGIN, RETRIEVE_GUIDANCE_ENTITIES.SUCCESS, RETRIEVE_GUIDANCE_ENTITIES.FAILURE];

export const retrieveGlGuidanceEntitiesPromise = (dispatch, currency) => {
    return ThunkHelper(dispatch, RETRIEVE_GUIDANCE_ENTITIES_EVENTS, {
        method: 'post',
        data: {
            Currency: currency
        },
        url: 'SubscriberManagement/RetrieveGLGuidanceEntities'
    });
};

export const retrieveGuidanceEntities = (currency) => {
    return (dispatch) => {
        return retrieveGlGuidanceEntitiesPromise(dispatch, currency);
    };
};

export const SEARCH_FEES = {
    BEGIN: 'SEARCH_FEES_BEGIN',
    SUCCESS: 'SEARCH_FEES_SUCCESS',
    FAILURE: 'SEARCH_FEES_FAILURE'
};
const SEARCH_FEES_EVENTS = [SEARCH_FEES.BEGIN, SEARCH_FEES.SUCCESS, SEARCH_FEES.FAILURE];

export const searchSubscriberFeesPromise = (dispatch, subscriberId, searchParams) => {
    return ThunkHelper(dispatch, SEARCH_FEES_EVENTS, {
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
        },
        method: 'post',
        data: searchParams,
        url: 'SubscriberManagement/SearchSubscriberFees'
    });
};

export const searchSubscriberFees = (subscriberId, searchParams) => {
    return (dispatch) => {
        return searchSubscriberFeesPromise(dispatch, subscriberId, searchParams);
    };
};

export const SET_SELECTED_FEE_ID = 'SET_SELECTED_FEE_ID';
export const setSelectedFeeId = (payload) => {
    return {
        type: SET_SELECTED_FEE_ID,
        payload: payload
    };
};

export const SET_PRICING_PLAN_ID = 'SET_PRICING_PLAN_ID';

export const setPricingPlanId = (pricingPlanId) => {
    return {
        type: SET_PRICING_PLAN_ID,
        payload: pricingPlanId
    };
};

export const UPDATE_CUSTOMER_RATE_SHEET_USAGE_RULES = 'UPDATE_CUSTOMER_RATE_SHEET_USAGE_RULES';
export const updateCustomerRateSheetUsageRules = (modifiedUsageRule) => {
    return {
        type: UPDATE_CUSTOMER_RATE_SHEET_USAGE_RULES,
        payload: modifiedUsageRule
    };
};

export const ADD_USAGE_RULE_ITEMS = 'ADD_USAGE_RULE_ITEMS';
export const addUsageRuleItems = (addedItems) => {
    return {
        type: ADD_USAGE_RULE_ITEMS,
        payload: addedItems
    };
};

export const MODIFIED_USAGE_RULE_ITEMS = 'MODIFIED_USAGE_RULE_ITEMS';
export const setModifiedUsageRuleItems = (modifiedItems) => {
    return {
        type: MODIFIED_USAGE_RULE_ITEMS,
        payload: modifiedItems
    };
};
export const SEARCH_PRICING_VECTORS = {
    BEGIN: 'SEARCH_PRICING_VECTORS_BEGIN',
    SUCCESS: 'SEARCH_PRICING_VECTORS_SUCCESS',
    FAILURE: 'SEARCH_PRICING_VECTORS_FAILURE'
};
const SEARCH_PRICING_VECTORS_EVENTS = [SEARCH_PRICING_VECTORS.BEGIN, SEARCH_PRICING_VECTORS.SUCCESS, SEARCH_PRICING_VECTORS.FAILURE];

export const searchPricingVectorsPromise = (dispatch, data) => {
    return ThunkHelper(dispatch, SEARCH_PRICING_VECTORS_EVENTS, {
        method: 'post',
        data: data,
        url: 'SubscriberManagement/SearchPricingVectors'
    });
};

export const searchPricingVectors = (data = {}) => {
    return (dispatch) => {
        return searchPricingVectorsPromise(dispatch, data);
    };
};

export const SET_RATE_SHEET_DETAILS_TAB = 'SET_RATE_SHEET_DETAILS_TAB';
export const setRateSheetDetailsTab = (tabValue) => {
    return {
        type: SET_RATE_SHEET_DETAILS_TAB,
        payload: tabValue
    };
};

export const SET_UBRI_TO_USAGE_RULE_GROUP_MAP = 'SET_UBRI_TO_USAGE_RULE_GROUP_MAP';
export const setUbriToUsageRuleGroupMap = (map) => {
    return {
        type: SET_UBRI_TO_USAGE_RULE_GROUP_MAP,
        payload: map
    };
};

export const RETRIEVE_USAGE_RULES_FOR_USAGE_RULE_GROUPS = {
    BEGIN: 'RETRIEVE_USAGE_RULES_FOR_USAGE_RULE_GROUPS_BEGIN',
    SUCCESS: 'RETRIEVE_USAGE_RULES_FOR_USAGE_RULE_GROUPS_SUCCESS',
    FAILURE: 'RETRIEVE_USAGE_RULES_FOR_USAGE_RULE_GROUPS_FAILURE'
};
const RETRIEVE_USAGE_RULES_FOR_USAGE_RULE_GROUPS_EVENTS = [RETRIEVE_USAGE_RULES_FOR_USAGE_RULE_GROUPS.BEGIN, RETRIEVE_USAGE_RULES_FOR_USAGE_RULE_GROUPS.SUCCESS, RETRIEVE_USAGE_RULES_FOR_USAGE_RULE_GROUPS.FAILURE];

export const retrieveUsageRulesForUsageRuleGroupsPromise = (dispatch, data) => {
    return ThunkHelper(dispatch, RETRIEVE_USAGE_RULES_FOR_USAGE_RULE_GROUPS_EVENTS, {
        method: 'post',
        data: {
            UsageRuleGroupExternalReferences: data
        },
        url: 'SubscriberManagement/RetrieveUsageRulesForUsageRuleGroups'
    });
};

export const retrieveUsageRulesForUsageRuleGroups = (data = {}) => {
    return (dispatch) => {
        return retrieveUsageRulesForUsageRuleGroupsPromise(dispatch, data);
    };
};