import Immutable from 'seamless-immutable';
import {RESET_CUSTOMER_RECOVERABLE} from '../actions/customer.actions';
import * as InteractionActions from '../actions/customer.interaction.actions';

export const INITIAL_STATE = Immutable({
    filterData: {
        categoryType: null,
        interactionType: null,
        pageSizePreference: null,
        onlyShowChangedProperties: true
    },
    pageNumber: 1
});

export default function InteractionsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case InteractionActions.SET_CURRENT_PAGE:
            return state.set('pageNumber', payload);
        case InteractionActions.SET_CATEGORY_TYPE:
            return state.setIn(['filterData', 'categoryType'], payload)
                .setIn(['filterData', 'interactionType'], null);
        case InteractionActions.SET_SHOW_CHANGED_TYPE:
            return state.setIn(['filterData', 'onlyShowChangedProperties'], payload);
        case InteractionActions.SET_INTERACTION_TYPE:
            return state.setIn(['filterData', 'interactionType'], payload);
        case InteractionActions.SET_INTERACTION_PAGE_SIZE_PREFERENCE:
            return state.setIn(['filterData', 'pageSizePreference'], payload);
        case InteractionActions.SET_INTERACTIONS_SORT_CRITERIA:
            return state.setIn(['filterData', 'sortCriteria'], payload);
        case RESET_CUSTOMER_RECOVERABLE:
        case InteractionActions.REMOVE_INTERACTIONS_FILTER:
            return INITIAL_STATE;
        default:
            return state;
    }
}
