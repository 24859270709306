import Immutable from 'seamless-immutable';
import {RESET_CUSTOMER_RECOVERABLE} from '../actions/customer.actions';
import * as DeviceActions from '../actions/customer.devices.actions';

export const INITIAL_STATE = Immutable({
    filterData: {
        includeRemoved: false
    },
    createDeviceModel: {},
    selectedDevice: {}
});

export default function DevicesReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case DeviceActions.SET_INCLUDED_FILTER:
            return state.setIn(['filterData', 'includeRemoved'], payload);
        case DeviceActions.CLEAR_DEVICE_FORM:
        case DeviceActions.CREATE_EDIT_DEVICE_FORM_CHANGED:
            return state.set('createDeviceModel', payload);
        case DeviceActions.SET_SELECTED_DEVICE:
            return state.set('selectedDevice', payload);
        case RESET_CUSTOMER_RECOVERABLE:
        case DeviceActions.CLEAR_DEVICE_FILTERS:
            return INITIAL_STATE;
        default:
            return state;
    }
}
