import {
    ApiConstants,
    MetadataThunkHelper,
    ThunkHelper
} from 'invision-core';
import {
    retrieveConvergentBillerInvoicesPromise,
    retrieveSusbcriberDepositsPromise
} from './customer.convergent.biller.actions';
import values from 'ramda/src/values';

export const SEARCH_CASES_CONSTANTS = {
    BEGIN: 'SEARCH_CASES_BEGIN',
    SUCCESS: 'SEARCH_CASES_SUCCESS',
    FAILURE: 'SEARCH_CASES_FAILURE'
};
const SEARCH_CASES_EVENTS = [SEARCH_CASES_CONSTANTS.BEGIN, SEARCH_CASES_CONSTANTS.SUCCESS, SEARCH_CASES_CONSTANTS.FAILURE];

export const searchCases = (customerId, additionalOpts) => {
    return (dispatch) => {
        ThunkHelper(dispatch, SEARCH_CASES_EVENTS, {
            method: 'post',
            url: 'SubscriberManagement/SearchCases',
            data: additionalOpts,
            headers: {
                [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
            }
        }, {
            customerId: customerId,
            pageNumber: additionalOpts.PageNumber,
            pageSize: additionalOpts.PageSize
        });
    };
};

export const RETRIEVE_CASE_CONSTANTS = {
    BEGIN: 'RETRIEVE_CASE_BEGIN',
    SUCCESS: 'RETRIEVE_CASE_SUCCESS',
    FAILURE: 'RETRIEVE_CASE_FAILURE'
};
const RETRIEVE_CASE_EVENTS = [
    RETRIEVE_CASE_CONSTANTS.BEGIN,
    RETRIEVE_CASE_CONSTANTS.SUCCESS,
    RETRIEVE_CASE_CONSTANTS.FAILURE
];

const retrieveCasePromise = (dispatch, customerId, caseId) => {
    return ThunkHelper(dispatch, RETRIEVE_CASE_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveCase',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            Id: {
                // TODO(ASC-39170): SHOULD be removed once the code type issue introducing the Number conversion is resolved
                // explicit cast to String, in case somehow it's become a Number in the tangle; MUST be a String
                Value: `${caseId}`
            }
        }
    });
};
export const retrieveCase = (customerId, caseId) => {
    return (dispatch) => {
        return retrieveCasePromise(dispatch, customerId, caseId);
    };
};

export const CREATE_CASE_CONSTANTS = {
    BEGIN: 'CREATE_CASE_BEGIN',
    SUCCESS: 'CREATE_CASE_SUCCESS',
    FAILURE: 'CREATE_CASE_FAILURE'
};
const CREATE_CASE_EVENTS = [CREATE_CASE_CONSTANTS.BEGIN, CREATE_CASE_CONSTANTS.SUCCESS, CREATE_CASE_CONSTANTS.FAILURE];

const createCasePromise = (dispatch, customerId, subCase) => {
    return ThunkHelper(dispatch, CREATE_CASE_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/CreateCase',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            Case: subCase
        }
    }, {
        customerId: customerId
    });
};
export const createCase = (customerId, subCase) => {
    return (dispatch) => {
        return createCasePromise(dispatch, customerId, subCase);
    };
};

export const UPDATE_CASE_CONSTANTS = {
    BEGIN: 'UPDATE_CASE_BEGIN',
    SUCCESS: 'UPDATE_CASE_SUCCESS',
    FAILURE: 'UPDATE_CASE_FAILURE'
};
const UPDATE_CASE_EVENTS = [
    UPDATE_CASE_CONSTANTS.BEGIN,
    UPDATE_CASE_CONSTANTS.SUCCESS,
    UPDATE_CASE_CONSTANTS.FAILURE
];

const updateCasePromise = (dispatch, customerId, case_, addedCaseActivities, addedCaseRemarks) => {
    return ThunkHelper(dispatch, UPDATE_CASE_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/UpdateCase',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            AddedCaseActivities: addedCaseActivities,
            AddedRemarks: addedCaseRemarks,
            Case: case_
        }
    });
};
export const updateCase = (customerId, case_, addedCaseActivities, addedCaseRemarks) => {
    return (dispatch) => {
        return updateCasePromise(dispatch, customerId, case_, addedCaseActivities, addedCaseRemarks);
    };
};

export const GET_CASE_ACTIVITIES_STATUS = {
    BEGIN: 'GET_CASE_ACTIVITIES_BEGIN',
    SUCCESS: 'GET_CASE_ACTIVITIES_SUCCESS',
    FAILURE: 'GET_CASE_ACTIVITIES_FAILURE'
};
const GET_CASE_ACTIVITIES_EVENTS = [GET_CASE_ACTIVITIES_STATUS.BEGIN, GET_CASE_ACTIVITIES_STATUS.SUCCESS, GET_CASE_ACTIVITIES_STATUS.FAILURE];

const getCaseActivitiesRequest = (dispatch, category) => {
    return MetadataThunkHelper(dispatch, GET_CASE_ACTIVITIES_EVENTS, {
        method: 'get',
        url: `CaseActivities/CaseCategoryCode/${category}`,
        headers: {
            [ApiConstants.JSON_LONG_AS_STRING_HEADER]: true
        }
    });
};

export const retrieveCaseActivities = (category) => {
    return (dispatch) => {
        return getCaseActivitiesRequest(dispatch, category);
    };
};

export const fetchInvoices = (subscriberId) => {
    return (dispatch) => {
        return retrieveConvergentBillerInvoicesPromise(dispatch, subscriberId);
    };
};

export const fetchDeposits = (subscriberId) => {
    return (dispatch) => {
        return retrieveSusbcriberDepositsPromise(dispatch, subscriberId);
    };
};

export const RESET_CURRENT_CASE = 'RESET_CURRENT_CASE';

export const resetCurrentCase = () => {
    return {
        type: RESET_CURRENT_CASE
    };
};

export const UPDATE_CASE_SELECTED_CATEGORY_ID = 'UPDATE_CASE_SELECTED_CATEGORY_ID';

export const updateCaseSelectedCategoryId = (data) => {
    return {
        type: UPDATE_CASE_SELECTED_CATEGORY_ID,
        payload: data
    };
};

export const REMOVE_CASE_SELECTED_CATEGORY_ID = 'REMOVE_CASE_SELECTED_CATEGORY_ID';

export const removeCaseSelectedCategoryId = () => {
    return {
        type: REMOVE_CASE_SELECTED_CATEGORY_ID
    };
};

export const UPDATE_CASE_INVENTORY_ITEM = 'UPDATE_CASE_INVENTORY_ITEM';

export const updateCaseInventoryItem = (data) => {
    return {
        type: UPDATE_CASE_INVENTORY_ITEM,
        payload: data
    };
};

export const SET_CASE_STATUS_FILTER = 'SET_CASE_STATUS_FILTER';
export const setCaseStatusFilter = (includeStatusFilter) => {
    return {
        type: SET_CASE_STATUS_FILTER,
        payload: includeStatusFilter
    };
};

export const SET_CURRENT_PAGE = 'SET_CASES_CURRENT_PAGE';
export const setCurrentPage = (page) => {
    return {
        type: SET_CURRENT_PAGE,
        payload: page
    };
};

export const EMPTY_CASES_LIST = 'EMPTY_CASES_LIST';
export const emptyCasesList = () => {
    return {
        type: EMPTY_CASES_LIST
    };
};

export const REMOVE_CASES_FILTER = 'REMOVE_CASES_FILTER';
export const removeCasesFilter = () => {
    return {
        type: REMOVE_CASES_FILTER
    };
};

export const SET_CASE_EDIT_MODE = 'SET_CASE_EDIT_MODE';
export const setCaseEditMode = (editMode) => {
    return {
        type: SET_CASE_EDIT_MODE,
        payload: editMode
    };
};

export const SET_CASE_PAGE_SIZE_PREFERENCE = 'SET_CASE_PAGE_SIZE_PREFERENCE';
export const setPageSizePreference = (pageSize) => {
    return {
        type: SET_CASE_PAGE_SIZE_PREFERENCE,
        payload: pageSize
    };
};

export const RETRIEVE_CASE_TYPES = {
    BEGIN: 'RETRIEVE_CASE_TYPES_BEGIN',
    SUCCESS: 'RETRIEVE_CASE_TYPES_SUCCESS',
    FAILURE: 'RETRIEVE_CASE_TYPES_FAILURE'
};

const retrieveCaseTypesPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, values(RETRIEVE_CASE_TYPES), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveFilteredCaseTypes',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {}
    });
};

export const fetchCaseTypes = (customerId) => {
    return (dispatch) => {
        return retrieveCaseTypesPromise(dispatch, customerId);
    };
};

export const RETRIEVE_PORT_IN_DETAILS = {
    BEGIN: 'RETRIEVE_PORT_IN_DETAILS_BEGIN',
    SUCCESS: 'RETRIEVE_PORT_IN_DETAILS_SUCCESS',
    FAILURE: 'RETRIEVE_PORT_IN_DETAILS_FAILURE'
};

export const retrievePortInDetails = (caseId) => {
    return (dispatch) => {
        return ThunkHelper(dispatch, values(RETRIEVE_PORT_IN_DETAILS), {
            method: 'post',
            url: 'SubscriberManagement/RetrievePortInDetails',
            data: {
                CaseId: caseId
            }
        });
    };
};
