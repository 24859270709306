import {ApiConstants, ThunkHelper} from 'invision-core';

export const TRANSACTION_TOTALS_CONSTANTS = {
    BEGIN: 'RETRIEVE_TRANSACTION_TOTALS_BEGIN',
    SUCCESS: 'RETRIEVE_TRANSACTION_TOTALS_SUCCESS',
    FAILURE: 'RETRIEVE_TRANSACTION_TOTALS_FAILURE'
};

const TRANSACTION_TOTALS_EVENTS = [
    TRANSACTION_TOTALS_CONSTANTS.BEGIN,
    TRANSACTION_TOTALS_CONSTANTS.SUCCESS,
    TRANSACTION_TOTALS_CONSTANTS.FAILURE
];

export const retrieveTransactionTotalsPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, TRANSACTION_TOTALS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveTransactionTotals',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const retrieveTransactionTotals = (customerId) => {
    return (dispatch) => {
        return retrieveTransactionTotalsPromise(dispatch, customerId);
    };
};
