import ThunkHelper from 'invision-core/src/api/thunk.helper';
import ApiConstants from 'invision-core/src/api/constants';

export const FETCH_PRODUCT_SERVICE_RELATIONSHIPS = {
    BEGIN: 'FETCH_PRODUCT_SERVICE_RELATIONSHIPS_BEGIN',
    SUCCESS: 'FETCH_PRODUCT_SERVICE_RELATIONSHIPS_SUCCESS',
    FAILURE: 'FETCH_PRODUCT_SERVICE_RELATIONSHIPS_FAILURE'
};

export const fetchSubscriberProductServiceRelationshipsPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, Object.values(FETCH_PRODUCT_SERVICE_RELATIONSHIPS), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveSubscriberProductServiceRelationships',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const fetchSubscriberProductServiceRelationships = (customerId) => {
    return (dispatch) => {
        return fetchSubscriberProductServiceRelationshipsPromise(dispatch, customerId);
    };
};

export const UPDATE_PRODUCT_SERVICE_RELATIONSHIPS = {
    BEGIN: 'UPDATE_PRODUCT_SERVICE_RELATIONSHIPS_BEGIN',
    SUCCESS: 'UPDATE_PRODUCT_SERVICE_RELATIONSHIPS_SUCCESS',
    FAILURE: 'UPDATE_PRODUCT_SERVICE_RELATIONSHIPS_FAILURE'
};

export const updateSubscriberProductServiceRelationshipsPromise = (dispatch, customerId, productServiceRelationships = []) => {
    return ThunkHelper(dispatch, Object.values(UPDATE_PRODUCT_SERVICE_RELATIONSHIPS), {
        method: 'post',
        url: 'SubscriberManagement/UpdateSubscriberProductServiceRelationships',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            ConsumerPlans: productServiceRelationships
        }
    });
};

export const updateSubscriberProductServiceRelationships = (customerId, productServiceRelationships = []) => {
    return (dispatch) => {
        return updateSubscriberProductServiceRelationshipsPromise(dispatch, customerId, productServiceRelationships);
    };
};