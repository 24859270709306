import AddOfferWizardReducer from './add.offer.wizard.reducer';
import AddServicesWizardReducer from './add.services.wizard.reducer';
import AddressesReducer from './recoverableUiState/addresses.reducer';
import BulkServicesReducer from './recoverableUiState/services.reducer';
import CaseDetailsReducer from './customer.case.details.reducer';
import CasesReducer from './recoverableUiState/cases.reducer';
import ContentActivityReducer from './recoverableUiState/content.activity.reducer';
import ContractsListReducer from './recoverableUiState/contracts.list.reducer';
import CreateProductsOrderWizardReducer from './products.wizard.reducer';
import CustomerAccountHierarchyUiReducer from './customer.account.hierarchy.ui.reducer';
import CustomerCouponsListsReducer from './recoverableUiState/customer.coupons.lists.reducer';
import DevicesReducer from './recoverableUiState/devices.reducer';
import DiscountReducer from './recoverableUiState/discount.reducer';
import EditOfferWizardReducer from './edit.offer.wizard.reducer';
import EwalletReducer from './recoverableUiState/ewallet.reducer';
import HouseholdReducer from './recoverableUiState/household.reducer';
import InteractionsReducer from './recoverableUiState/interactions.reducer';
import MakePaymentReducer from './make.payment.reducer';
import MemberPrivilegesReducer from './recoverableUiState/member.privileges.reducer';
import MobileNumberPortabilityReducer from './recoverableUiState/mobile.number.portability.reducer';
import NewConnectWizardReducer from './new.connect.wizard.reducer';
import OrderDetailsReducer from './recoverableUiState/order.details.reducer';
import OrderHistoryReducer from './order.history.filter.reducer';
import ProductsReducer from './recoverableUiState/products.reducer';
import RecentCustomersReducer from './recent.customers.reducer';
import RedeemGiftOrderReducer from './recoverableUiState/redeem.gift.order.reducer';
import ResendGiftNotificationReducer from './recoverableUiState/resend.gift.notification.reducer';
import RevokeGiftOrderReducer from './recoverableUiState/revoke.gift.order.reducer';
import SearchCouponsReducer from './recoverableUiState/search.coupons.reducer';
import SearchCustomersReducer from './recoverableUiState/search.customers.reducer';
import SearchSubscriberOfferingsReducer from './recoverableUiState/search.subscriber.offerings.reducer';
import SearchGiftCardsReducer from './recoverableUiState/search.giftcards.reducer';
import SharedEntitlementsReducer from './recoverableUiState/shared.entitlements.reducer';
import SubscriptionsReducer from './recoverableUiState/subscriptions.reducer';
import TransactionsReducer from './recoverableUiState/transactions.reducer';
import TransferWizardReducer from './transfer.wizard.reducer';
import TransitionOfferWizardReducer from './transition.offer.wizard.reducer';
import UsageCapsReducer from './recoverableUiState/usage.caps.reducer';
import UsageDetailsReducer from './recoverableUiState/usage.details.reducer';

// Pattern to follow:
/*
    export default {
        somePage: SomePageReducer,
        someOtherPage: SomeOtherPageReducer,
        etc..
    }
*/
// NOTE: only persistent UI state belongs here.
// See this link for more info https://confluence.csgicorp.com/display/Ascendon/Redux#Redux-UIState
export default {
    addOfferWizard: AddOfferWizardReducer,
    addresses: AddressesReducer,
    addServicesWizard: AddServicesWizardReducer,
    bulkServices: BulkServicesReducer,
    caseDetails: CaseDetailsReducer,
    cases: CasesReducer,
    contentActivity: ContentActivityReducer,
    contracts: ContractsListReducer,
    customerAccountHierarchy: CustomerAccountHierarchyUiReducer,
    customerCouponsLists: CustomerCouponsListsReducer,
    devices: DevicesReducer,
    discount: DiscountReducer,
    editOfferWizard : EditOfferWizardReducer,
    ewallet: EwalletReducer,
    household: HouseholdReducer,
    interactions: InteractionsReducer,
    makePayment: MakePaymentReducer,
    memberPrivileges: MemberPrivilegesReducer,
    mobileNumberPortability: MobileNumberPortabilityReducer,
    newConnectWizard: NewConnectWizardReducer,
    orderDetails: OrderDetailsReducer,
    orderHistory: OrderHistoryReducer,
    products: ProductsReducer,
    productsWizard: CreateProductsOrderWizardReducer,
    recentCustomers: RecentCustomersReducer,
    redeemGiftOrder: RedeemGiftOrderReducer,
    resendGiftNotification: ResendGiftNotificationReducer,
    revokeGiftOrder: RevokeGiftOrderReducer,
    searchCoupons: SearchCouponsReducer,
    searchCustomers: SearchCustomersReducer,
    searchGiftCards: SearchGiftCardsReducer,
    searchSubscriberOfferings: SearchSubscriberOfferingsReducer,
    sharedEntitlements: SharedEntitlementsReducer,
    subscriptions: SubscriptionsReducer,
    transactions: TransactionsReducer,
    transferWizard: TransferWizardReducer,
    transitionOfferWizard: TransitionOfferWizardReducer,
    usageCaps: UsageCapsReducer,
    usageDetails: UsageDetailsReducer
};
