import {
    ThunkHelper
} from 'invision-core';
import {
    values
} from 'ramda';

export const RESEND_GIFT_NOTIFICATION = {
    BEGIN: 'RESEND_GIFT_NOTIFICATION_BEGIN',
    SUCCESS: 'RESEND_GIFT_NOTIFICATION_SUCCESS',
    FAILURE: 'RESEND_GIFT_NOTIFICATION_FAILURE'
};

export const RESEND_GIFT_NOTIFICATION_TYPES = values(RESEND_GIFT_NOTIFICATION);
const resendGiftNotificationPromise = (dispatch, giftOrderInfo) => {
    return ThunkHelper(dispatch, RESEND_GIFT_NOTIFICATION_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/ResendGiftOrderNotification',
        data: {
            NotifySender: true,
            OrderId: giftOrderInfo.orderId,
            RecipientEmail: giftOrderInfo.recipientEmail,
            RecipientName: giftOrderInfo.recipientName,
            SenderMessage: giftOrderInfo.giftMessage
        }
    });
};

export const submitResendGiftNotification = (giftOrderInfo) => {
    return (dispatch) => {
        return resendGiftNotificationPromise(dispatch, giftOrderInfo);
    };
};

export const SET_RESEND_GIFT_ORDER_INFO = 'SET_RESEND_GIFT_ORDER_INFO';
export const setResendGiftOrderInfo = (giftOrderInfo) => {
    return {
        type: SET_RESEND_GIFT_ORDER_INFO,
        payload: giftOrderInfo
    };
};

export const RESET_RESEND_GIFT_ORDER_INFO = 'RESET_RESEND_GIFT_ORDER_INFO';
export const resetGiftOrderInfo = () => {
    return {
        type: RESET_RESEND_GIFT_ORDER_INFO
    };
};
