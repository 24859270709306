import Immutable from 'seamless-immutable';
import {
    REMOVE_COUPON_REDEMPTION_CODE,
    SET_SELECTED_COUPON_REDEMPTION_CODE,
    SEARCH_COUPON_DETAILS_COUPON_REDEMPTIONS_CONSTANTS,
    SET_SELECTED_REASON_ID,
    UPDATE_COUPON_REDEMPTION_CODE_EXPIRATION
} from './actions/coupon.redemption.code.actions';
import {
    RETRIEVE_REDEMPTION_CODE_DETAILS_CONSTANTS
} from './actions/customer.coupons.actions';
import {
    COUPON_SEARCH
} from './actions/search.coupon.actions';
import {
    pathOr
} from 'ramda';

export const INITIAL_STATE = Immutable({
    searchResultId: null,
    couponError: null,
    isFetchingRedemptionCodeRedemptions: false,
    data: {
        redemptionCodeRedemptionsData: undefined
    }
});

export default function couponRedemptionCodeSearchReducer(state = INITIAL_STATE, {payload = {}, type, requestObject}) {
    const couponRedemptionCodeId = pathOr('', ['CouponRedemptionCode', 'Id'], payload);

    switch (type) {
        case COUPON_SEARCH.BEGIN:
            return state.set('searchResultId', null);
        case COUPON_SEARCH.SUCCESS:
            if (couponRedemptionCodeId) {
                payload.RedemptionCode = payload.CouponRedemptionCode;
                return state
                    .set('searchResultId', couponRedemptionCodeId)
                    .setIn(['data', couponRedemptionCodeId], payload);
            } else {
                return state;
            }
        case RETRIEVE_REDEMPTION_CODE_DETAILS_CONSTANTS.BEGIN:
            return state.setIn(['data', requestObject.couponRedemptionCodeId, 'isFetching'], true);
        case RETRIEVE_REDEMPTION_CODE_DETAILS_CONSTANTS.SUCCESS:
            payload.isFetching = false;
            return state.setIn(['data', requestObject.couponRedemptionCodeId], payload);
        case RETRIEVE_REDEMPTION_CODE_DETAILS_CONSTANTS.FAILURE:
            return state.setIn(['data', requestObject.couponRedemptionCodeId, 'isFetching'], false);
        case SEARCH_COUPON_DETAILS_COUPON_REDEMPTIONS_CONSTANTS.BEGIN:
            return state.set('isFetchingRedemptionCodeRedemptions', true);
        case SEARCH_COUPON_DETAILS_COUPON_REDEMPTIONS_CONSTANTS.SUCCESS:
            return state
                .setIn(['data', 'redemptionCodeRedemptionsData'], payload)
                .set('isFetchingRedemptionCodeRedemptions', false);
        case SEARCH_COUPON_DETAILS_COUPON_REDEMPTIONS_CONSTANTS.FAILURE:
            return state
                .set('isFetchingRedemptionCodeRedemptions', false)
                .set('couponError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case REMOVE_COUPON_REDEMPTION_CODE.BEGIN:
            return state.set('couponError', null);
        case REMOVE_COUPON_REDEMPTION_CODE.SUCCESS:
            return state
                .setIn(['data', payload.RedemptionCode.Id, 'RedemptionCode'], payload.RedemptionCode)
                .set('couponError', null);
        case REMOVE_COUPON_REDEMPTION_CODE.FAILURE:
            return state.set('couponError', {
                code: payload.Code,
                message: payload.translatedMessage,
                severity: payload.Severity
            });
        case UPDATE_COUPON_REDEMPTION_CODE_EXPIRATION.BEGIN:
            return state.set('couponError', null);
        case UPDATE_COUPON_REDEMPTION_CODE_EXPIRATION.SUCCESS:
            return state
                .setIn(['data', payload.RedemptionCode.Id, 'RedemptionCode', 'Expiration'], payload.RedemptionCode.Expiration)
                .set('couponError', null);
        case UPDATE_COUPON_REDEMPTION_CODE_EXPIRATION.FAILURE:
            return state.set('couponError', {
                code: payload.Code,
                message: payload.translatedMessage,
                severity: payload.Severity
            });
        case SET_SELECTED_COUPON_REDEMPTION_CODE:
            return state.set('searchResultId', payload);
        case SET_SELECTED_REASON_ID:
            return state.set('selectedReasonId', payload);
        default:
            return state;
    }
}
