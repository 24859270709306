import {
    ApiConstants,
    i18n,
    InjectorHelper,
    ThunkHelper
} from 'invision-core';
import LocaleKeys from '../../locales/keys';
import {NOTIFICATION_TIME_LENGTH} from '../../customercare.constants';

import {
    getDefaultPaymentMethodFromPaymentInstruments,
    isSupportedPaymentInstrumentType,
    isSupportedOTTPaymentInstrumentType
} from '../helpers/ewallet.reducer.helper';

import {SUPPORTED_PAYMENT_INSTRUMENT_TYPES} from '../../components/customer/ewallet/ewallet.constants';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';

export const RETRIEVE_WALLET = {
    BEGIN: 'RETRIEVE_WALLET_BEGIN',
    SUCCESS: 'RETRIEVE_WALLET_SUCCESS',
    FAILURE: 'RETRIEVE_WALLET_FAILURE'
};

export const RETRIEVE_AVAILABLE_PAYMENT_INSTRUMENT_TYPES = {
    BEGIN: 'RETRIEVE_AVAILABLE_PAYMENT_INSTRUMENT_TYPES_BEGIN',
    SUCCESS: 'RETRIEVE_AVAILABLE_PAYMENT_INSTRUMENT_TYPES_SUCCESS',
    FAILURE: 'RETRIEVE_AVAILABLE_PAYMENT_INSTRUMENT_TYPES_FAILURE'
};

const RETRIEVE_WALLET_TYPES = [
    RETRIEVE_WALLET.BEGIN,
    RETRIEVE_WALLET.SUCCESS,
    RETRIEVE_WALLET.FAILURE
];

const RETRIEVE_AVAILABLE_PAYMENT_INSTRUMENT_TYPES_TYPES = [
    RETRIEVE_AVAILABLE_PAYMENT_INSTRUMENT_TYPES.BEGIN,
    RETRIEVE_AVAILABLE_PAYMENT_INSTRUMENT_TYPES.SUCCESS,
    RETRIEVE_AVAILABLE_PAYMENT_INSTRUMENT_TYPES.FAILURE
];


export const ADD_UNSAVED_PAYMENT_INSTRUMENT = 'ADD_UNSAVED_PAYMENT_INSTRUMENT';
export const REPLACE_WALLET_INSTRUMENTS = 'REPLACE_WALLET_INSTRUMENTS';
export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD';
export const RETRIEVE_WALLET_COMPLETED = 'RETRIEVE_WALLET_COMPLETED';
export const CLEAR_SELECTED_PAYMENT_METHOD = 'CLEAR_SELECTED_PAYMENT_METHOD';
export const CLEAR_IS_MAKING_PAYMENT = 'CLEAR_IS_MAKING_PAYMENT';
export const UPDATE_PAYMENT_ACCOUNTS_ON_SELECTED_PAYMENT_METHOD = 'UPDATE_PAYMENT_ACCOUNTS_ON_SELECTED_PAYMENT_METHOD';
export const CLEAR_EWALLET_RETRIEVAL_ERROR = 'CLEAR_EWALLET_RETRIEVAL_ERROR';
export const SET_EWALLET_RETRIEVAL_ERROR = 'SET_EWALLET_RETRIEVAL_ERROR';
export const SUPPLY_PAYMENT_INSTRUMENT = 'SUPPLY_PAYMENT_INSTRUMENT';
export const SET_RECORD_PAYMENT_INSTRUMENT = 'SET_RECORD_PAYMENT_INSTRUMENT';
export const SAVE_AUTO_PAY_FLAG_STATUS = 'SAVE_AUTO_PAY_FLAG_STATUS';

export const retrieveWalletPromise = (dispatch, data, isProducts) => {
    return ThunkHelper(dispatch, RETRIEVE_WALLET_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveWallet',
        data: data.request,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: data.customerId
        }
    }).then((response) => {
        const ewalletPaymentInstruments = response.PaymentInstruments;
        const promises = ewalletPaymentInstruments.map((instrument) => {
            return retrievePaymentInstrumentPromise(dispatch, {
                customerId: data.customerId,
                request: {
                    Id: instrument.Id,
                    RetrieveCurrencyEntitlementBalance: instrument.Type === convertStringToNumber(SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CURRENCY_ENTITLEMENT) || undefined
                }
            });
        });

        return Promise.all(promises).then((paymentInstruments) => {
            const transformedInstruments = paymentInstruments.filter((instrument) => {
                const isSupported = isProducts ? isSupportedOTTPaymentInstrumentType : isSupportedPaymentInstrumentType;
                return isSupported(instrument.PaymentInstrument.Type);
            }).map(instrument => {
                // Copy locked property from ewallet payment instrument to the current one.
                const ewalletPI = ewalletPaymentInstruments.find((paymentInstrument) => {
                    return paymentInstrument.Id === instrument.PaymentInstrument.Id;
                });
                instrument.PaymentInstrument.Locked = ewalletPI.Locked || false;
                return instrument.PaymentInstrument;
            });

            dispatch({
                type: REPLACE_WALLET_INSTRUMENTS,
                payload: transformedInstruments
            });
            dispatch({
                type: CLEAR_EWALLET_RETRIEVAL_ERROR
            });
            return transformedInstruments;
        }, (errorPayload) => {
            dispatch({
                type: SET_EWALLET_RETRIEVAL_ERROR,
                payload: errorPayload
            });
            return Promise.reject(errorPayload);
        });
    }).finally(() => {
        dispatch({
            type: RETRIEVE_WALLET_COMPLETED,
            payload: {}
        });
    });
};

export const retrieveAvailablePaymentInstrumentTypesPromise = (dispatch, data) => {
    return ThunkHelper(dispatch, RETRIEVE_AVAILABLE_PAYMENT_INSTRUMENT_TYPES_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveAvailablePaymentInstrumentTypes',
        data: data
    });

};

export const retrieveWallet = (data, isProducts) => {
    return (dispatch) => {
        return retrieveWalletPromise(dispatch, data, isProducts);
    };
};

export const retrieveAvailablePaymentInstrumentTypes = (data) => {
    return (dispatch) => {
        return retrieveAvailablePaymentInstrumentTypesPromise(dispatch, data);
    };
};


export const retrieveWalletAndSelectDefaultPaymentMethod = (data) => {
    return (dispatch) => {
        return retrieveWalletPromise(dispatch, data).then((paymentInstruments) => {
            dispatch({
                type: SELECT_PAYMENT_METHOD,
                payload: getDefaultPaymentMethodFromPaymentInstruments(paymentInstruments)
            });
        });
    };
};

export const RETRIEVE_PAYMENT_INSTRUMENT = {
    BEGIN: 'RETRIEVE_PAYMENT_INSTRUMENT_BEGIN',
    SUCCESS: 'RETRIEVE_PAYMENT_INSTRUMENT_SUCCESS',
    FAILURE: 'RETRIEVE_PAYMENT_INSTRUMENT_FAILURE'
};

const RETRIEVE_PAYMENT_INSTRUMENT_TYPES = [
    RETRIEVE_PAYMENT_INSTRUMENT.BEGIN,
    RETRIEVE_PAYMENT_INSTRUMENT.SUCCESS,
    RETRIEVE_PAYMENT_INSTRUMENT.FAILURE
];

export const retrievePaymentInstrumentPromise = (dispatch, data) => {
    return ThunkHelper(dispatch, RETRIEVE_PAYMENT_INSTRUMENT_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrievePaymentInstrument',
        data: data.request,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: data.customerId
        }
    });
};
export const retrievePaymentInstrument = (data) => {
    return (dispatch) => {
        return retrievePaymentInstrumentPromise(dispatch, data);
    };
};

export const REMOVE_PAYMENT_INSTRUMENT = {
    BEGIN: 'REMOVE_PAYMENT_BEGIN',
    SUCCESS: 'REMOVE_PAYMENT_SUCCESS',
    FAILURE: 'REMOVE_PAYMENT_FAILURE'
};
const REMOVE_PAYMENT_INSTRUMENT_EVENTS = [REMOVE_PAYMENT_INSTRUMENT.BEGIN, REMOVE_PAYMENT_INSTRUMENT.SUCCESS, REMOVE_PAYMENT_INSTRUMENT.FAILURE];

const removePaymentInstrumentPromise = (dispatch, customerId, paymentInstrumentId) => {
    return ThunkHelper(dispatch, REMOVE_PAYMENT_INSTRUMENT_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RemovePaymentInstrument',
        data: {
            Id: paymentInstrumentId
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};
export const removePaymentInstrument = (customerId, paymentInstrumentId) => {
    return (dispatch) => {
        return removePaymentInstrumentPromise(dispatch, customerId, paymentInstrumentId);
    };
};

export const ANONYMIZE_PAYMENT_INSTRUMENT = {
    BEGIN: 'ANONYMIZE_PAYMENT_BEGIN',
    SUCCESS: 'ANONYMIZE_PAYMENT_SUCCESS',
    FAILURE: 'ANONYMIZE_PAYMENT_FAILURE'
};

const ANONYMIZE_PAYMENT_INSTRUMENT_EVENTS = [ANONYMIZE_PAYMENT_INSTRUMENT.BEGIN, ANONYMIZE_PAYMENT_INSTRUMENT.SUCCESS, ANONYMIZE_PAYMENT_INSTRUMENT.FAILURE];

const anonymizePaymentInstrumentPromise = (dispatch, customerId, paymentInstrumentId) => {
    return ThunkHelper(dispatch, ANONYMIZE_PAYMENT_INSTRUMENT_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/AnonymizePaymentInstrument',
        data: {
            Id: paymentInstrumentId
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const anonymizePaymentInstrument = (customerId, paymentInstrumentId) => {
    return (dispatch) => {
        return anonymizePaymentInstrumentPromise(dispatch, customerId, paymentInstrumentId);
    };
};

export const CHARGE_INSTRUMENT = {
    BEGIN: 'CHARGE_INSTRUMENT_BEGIN',
    SUCCESS: 'CHARGE_INSTRUMENT_SUCCESS',
    FAILURE: 'CHARGE_INSTRUMENT_FAILURE'
};
const CHARGE_INSTRUMENT_TYPES = [
    CHARGE_INSTRUMENT.BEGIN,
    CHARGE_INSTRUMENT.SUCCESS,
    CHARGE_INSTRUMENT.FAILURE
];

const chargePaymentInstrumentPromise = (dispatch, data, requestObject) => {
    return ThunkHelper(dispatch, CHARGE_INSTRUMENT_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/SubmitOneTimePayment',
        data: data.request,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: data.customerId
        }
    }, requestObject);
};
export const chargePaymentInstrument = (data) => {
    return (dispatch) => {
        return chargePaymentInstrumentPromise(dispatch, data)
            .then(() => {
                InjectorHelper.getUiNotificationService().success(i18n.translate(LocaleKeys.PAYMENT_SUCCESS), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            });
    };
};

export const CLEAR_EWALLET_ERROR = 'CLEAR_EWALLET_ERROR';

export const clearEWalletError = () => {
    return {
        type: CLEAR_EWALLET_ERROR
    };
};

export const CREATE_PAYMENT_INSTRUMENT = {
    BEGIN: 'CREATE_PAYMENT_INSTRUMENT_BEGIN',
    SUCCESS: 'CREATE_PAYMENT_INSTRUMENT_SUCCESS',
    FAILURE: 'CREATE_PAYMENT_INSTRUMENT_FAILURE'
};

const CREATE_PAYMENT_INSTRUMENT_TYPES = [
    CREATE_PAYMENT_INSTRUMENT.BEGIN,
    CREATE_PAYMENT_INSTRUMENT.SUCCESS,
    CREATE_PAYMENT_INSTRUMENT.FAILURE
];

const createPaymentInstrumentPromise = (dispatch, data) => {
    return ThunkHelper(dispatch, CREATE_PAYMENT_INSTRUMENT_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/CreatePaymentInstrument',
        data: data.request,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: data.customerId
        }
    });
};

export const createPaymentInstrument = (data) => {
    return (dispatch) => {
        return createPaymentInstrumentPromise(dispatch, data);
    };
};

export const REDEEM_GIFT_CARD = {
    BEGIN: 'REDEEM_GIFT_CARD_BEGIN',
    SUCCESS: 'REDEEM_GIFT_CARD_SUCCESS',
    FAILURE: 'REDEEM_GIFT_CARD_FAILURE'
};
const REDEEM_GIFT_CARD_TYPES = [
    REDEEM_GIFT_CARD.BEGIN,
    REDEEM_GIFT_CARD.SUCCESS,
    REDEEM_GIFT_CARD.FAILURE
];

const redeemGiftCardPromise = (dispatch, PIN, customerId, accountNumber) => {
    return ThunkHelper(dispatch, REDEEM_GIFT_CARD_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RedeemGiftCard',
        data: {
            Pin: PIN,
            AccountNumber: accountNumber
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const redeemGiftCard = (PIN, customerId, accountNumber) => {
    return (dispatch) => {
        return redeemGiftCardPromise(dispatch, PIN, customerId, accountNumber)
            .then((redeemedGiftCard) => {
                dispatch(retrieveWallet({
                    request: {},
                    customerId
                }));

                return redeemedGiftCard;
            });
    };
};

export const EDIT_PAYMENT_INSTRUMENT = {
    BEGIN: 'EDIT_PAYMENT_INSTRUMENT_BEGIN',
    SUCCESS: 'EDIT_PAYMENT_INSTRUMENT_SUCCESS',
    FAILURE: 'EDIT_PAYMENT_INSTRUMENT_FAILURE'
};

const EDIT_PAYMENT_INSTRUMENT_TYPES = [
    EDIT_PAYMENT_INSTRUMENT.BEGIN,
    EDIT_PAYMENT_INSTRUMENT.SUCCESS,
    EDIT_PAYMENT_INSTRUMENT.FAILURE
];

const editPaymentInstrumentPromise = (dispatch, data) => {
    return ThunkHelper(dispatch, EDIT_PAYMENT_INSTRUMENT_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/UpdatePaymentInstrument',
        data: data.request,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: data.customerId
        }
    });
};

export const EDIT_PAYMENT_INSTRUMENT_AUTO_PAY = {
    BEGIN: 'EDIT_PAYMENT_INSTRUMENT_AUTO_PAY_BEGIN',
    SUCCESS: 'EDIT_PAYMENT_INSTRUMENT_AUTO_PAY_SUCCESS',
    FAILURE: 'EDIT_PAYMENT_INSTRUMENT_AUTO_PAY_FAILURE'
};

const EDIT_PAYMENT_INSTRUMENT_AUTO_PAY_TYPES = [
    EDIT_PAYMENT_INSTRUMENT_AUTO_PAY.BEGIN,
    EDIT_PAYMENT_INSTRUMENT_AUTO_PAY.SUCCESS,
    EDIT_PAYMENT_INSTRUMENT_AUTO_PAY.FAILURE
];

const editPaymentInstrumentAutoPayPromise = (dispatch, data) => {
    return ThunkHelper(dispatch, EDIT_PAYMENT_INSTRUMENT_AUTO_PAY_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/UpdatePaymentInstrument',
        data: data.request,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: data.customerId
        }
    });
};

export const editPaymentInstrument = (data) => {
    if (data.updateAutoPay) {
        return (dispatch) => {
            return editPaymentInstrumentAutoPayPromise(dispatch, data);
        };
    } else {
        return (dispatch) => {
            return editPaymentInstrumentPromise(dispatch, data);
        };
    }
};

export const setPaymentInstrumentDefault = (customerId, paymentMethod, applyToSubscriptions) => {
    const data = {
        updateAutoPay: true,
        customerId: customerId,
        request: {}
    };
    if (applyToSubscriptions) {
        data.request.PaymentInstrument = paymentMethod;
        data.request.PaymentInstrument.Default = true;
        data.request.ApplyToSubscriptions = applyToSubscriptions;
    } else {
        data.request.DefaultPaymentInstrumentId = paymentMethod.Id;
    }

    //using DefaultPaymentInstrumentId will not return a fully populated payment Instrument, so wallet must be reloaded
    return (dispatch) => {
        return dispatch(editPaymentInstrument(data))
            .then((paymentInstrument) => {
                dispatch(retrieveWallet({
                    request: {},
                    customerId
                }));

                return paymentInstrument;
            });
    };
};

export const activatePaymentInstrument = (customerId, paymentMethod) => {
    const data = {
        updateAutoPay: false,
        customerId: customerId,
        request: {
            IncludeRemoved: true,
            PaymentInstrument: paymentMethod
        }
    };

    return editPaymentInstrument(data);
};

export const selectPaymentMethod = (paymentMethod) => {
    return {
        type: SELECT_PAYMENT_METHOD,
        payload: paymentMethod
    };
};

export const addUnsavedPaymentMethod = (paymentMethod) => {
    return {
        type: ADD_UNSAVED_PAYMENT_INSTRUMENT,
        payload: paymentMethod
    };
};

export const supplyPaymentInstrument = (supplyPaymentInstrument) => {
    return {
        type: SUPPLY_PAYMENT_INSTRUMENT,
        payload: supplyPaymentInstrument
    };
};

export const setRecordPaymentInstrument = (recordPaymentInformation) => {
    return {
        type: SET_RECORD_PAYMENT_INSTRUMENT,
        payload: recordPaymentInformation
    };
};

export const clearSelectedPaymentMethod = () => {
    return {
        type: CLEAR_SELECTED_PAYMENT_METHOD
    };
};

export const resetIsMakingPayment = () => {
    return {
        type: CLEAR_IS_MAKING_PAYMENT
    };
};

export const updatePaymentAccountsOnSelectedPaymentMethod = (upatedPaymentAccounts) => {
    return {
        type: UPDATE_PAYMENT_ACCOUNTS_ON_SELECTED_PAYMENT_METHOD,
        payload: upatedPaymentAccounts
    };
};

export const saveAutoPayFlagStatus = (autoPayPayload) => {
    return {
        type: SAVE_AUTO_PAY_FLAG_STATUS,
        payload: autoPayPayload
    };
};

export const SET_INCLUDED_REMOVED_FILTER = 'SET_EWALLET_INCLUDED_REMOVED_FILTER';
export const setIncludeRemovedFilter = (includedFilter) => {
    return {
        type: SET_INCLUDED_REMOVED_FILTER,
        payload: includedFilter
    };
};

export const SET_SELECTED_ITEM_ID = 'SET_SELECTED_PAYMENT_ITEM_ID';
export const setSelectedItemId = (includedId) => {
    return {
        type: SET_SELECTED_ITEM_ID,
        payload: includedId
    };
};

export const SET_SELECTED_TYPE_ID = 'SET_SELECTED_PAYMENT_TYPE_ID';
export const setSelectedTypeId = (paymentTypeId) => {
    return {
        type: SET_SELECTED_TYPE_ID,
        payload: paymentTypeId
    };
};

export const CLEAR_SELECTED_ITEM_ID = 'CLEAR_SELECTED_PAYMENT_ITEM_ID';
export const clearSelectedItemId = () => {
    return {
        type: CLEAR_SELECTED_ITEM_ID
    };
};

export const CLEAR_SELECTED_TYPE_ID = 'CLEAR_SELECTED_PAYMENT_TYPE_ID';
export const clearSelectedTypeId = () => {
    return {
        type: CLEAR_SELECTED_TYPE_ID
    };
};

export const EMPTY_EWALLET_LIST = 'EMPTY_EWALLET_LIST';
export const emptyEWalletList = () => {
    return {
        type: EMPTY_EWALLET_LIST
    };
};

export const REMOVE_EWALLET_FILTER = 'REMOVE_EWALLET_FILTER';
export const removeEWalletFilter = () => {
    return {
        type: REMOVE_EWALLET_FILTER
    };
};

export const UPDATE_OFFERING_INSTANCE_PAYMENT_INSTRUMENT = {
    BEGIN: 'UPDATE_OFFERING_INSTANCE_PAYMENT_INSTRUMENT_BEGIN',
    SUCCESS: 'UPDATE_OFFERING_INSTANCE_PAYMENT_INSTRUMENT_SUCCESS',
    FAILURE: 'UPDATE_OFFERING_INSTANCE_PAYMENT_INSTRUMENT_FAILURE'
};

const UPDATE_OFFERING_INSTANCE_PAYMENT_INSTRUMENT_TYPES = [
    UPDATE_OFFERING_INSTANCE_PAYMENT_INSTRUMENT.BEGIN,
    UPDATE_OFFERING_INSTANCE_PAYMENT_INSTRUMENT.SUCCESS,
    UPDATE_OFFERING_INSTANCE_PAYMENT_INSTRUMENT.FAILURE
];

const updateOfferingInstancePaymentInstrumentPromise = (dispatch, customerId, offeringInstanceId, paymentInstrumentId) => {
    return ThunkHelper(dispatch, UPDATE_OFFERING_INSTANCE_PAYMENT_INSTRUMENT_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/UpdateOfferingInstancePaymentInstrument',
        data: {
            OfferingInstanceId: offeringInstanceId,
            PaymentInstrumentId: paymentInstrumentId
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const updateOfferingInstancePaymentInstrument = (customerId, offeringInstanceId, paymentInstrumentId) => {
    return (dispatch) => {
        return updateOfferingInstancePaymentInstrumentPromise(dispatch, customerId, offeringInstanceId, paymentInstrumentId);
    };
};

export const RENEW_OFF_CYCLE_CHARGES = {
    BEGIN: 'RENEW_OFF_CYCLE_CHARGES_BEGIN',
    SUCCESS: 'RENEW_OFF_CYCLE_CHARGES_SUCCESS',
    FAILURE: 'RENEW_OFF_CYCLE_CHARGES_FAILURE'
};

const RENEW_OFF_CYCLE_CHARGES_TYPES = [
    RENEW_OFF_CYCLE_CHARGES.BEGIN,
    RENEW_OFF_CYCLE_CHARGES.SUCCESS,
    RENEW_OFF_CYCLE_CHARGES.FAILURE
];

const renewOffCycleChargesPromise = (dispatch, customerId, subscriberProductIds, orderId) => {
    return ThunkHelper(dispatch, RENEW_OFF_CYCLE_CHARGES_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetryOffCyclePayment',
        data: {
            OrderId: orderId,
            SubscriberProductIds: subscriberProductIds
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const renewOffCycleCharges = (customerId, subscriberProductIds, orderId) => {
    return (dispatch) => {
        return renewOffCycleChargesPromise(dispatch, customerId, subscriberProductIds, orderId);
    };
};
