import ThunkHelper from 'invision-core/src/api/thunk.helper';
import {SUBSCRIBER_ID_HEADER} from 'invision-core/src/api/constants';

export const RETRIEVE_USAGE_CAPS = {
    BEGIN: 'RETRIEVE_USAGE_CAPS_SETTINGS.BEGIN',
    SUCCESS: 'RETRIEVE_USAGE_CAPS_SETTINGS.SUCCESS',
    FAILURE: 'RETRIEVE_USAGE_CAPS_SETTINGS.FAILURE'
};

const RETRIEVE_USAGE_CAPS_CONSTANTS = [
    RETRIEVE_USAGE_CAPS.BEGIN,
    RETRIEVE_USAGE_CAPS.SUCCESS,
    RETRIEVE_USAGE_CAPS.FAILURE
];

export const retrieveUsageCapsPromise = (dispatch, customerId, serviceId = null) => {
    return ThunkHelper(dispatch, RETRIEVE_USAGE_CAPS_CONSTANTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveUsageCapElections',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            ServiceIdentifier: serviceId
        }
    });
};

export const retrieveUsageCaps = (customerId, serviceId) => {
    return (dispatch) => {
        return retrieveUsageCapsPromise(dispatch, customerId, serviceId);
    };
};

export const UPDATE_USAGE_CAP_ELECTIONS = {
    BEGIN: 'UPDATE_USAGE_CAP_ELECTIONS.BEGIN',
    FAILURE: 'UPDATE_USAGE_CAP_ELECTIONS.FAILURE',
    SUCCESS: 'UPDATE_USAGE_CAP_ELECTIONS.SUCCESS'
};
const UPDATE_USAGE_CAP_ELECTIONS_CONSTANTS = [
    UPDATE_USAGE_CAP_ELECTIONS.BEGIN,
    UPDATE_USAGE_CAP_ELECTIONS.SUCCESS,
    UPDATE_USAGE_CAP_ELECTIONS.FAILURE
];
export const updateUsageCapsElections = (customerId, usageCapElections) => {
    return (dispatch) => {
        return ThunkHelper(dispatch, UPDATE_USAGE_CAP_ELECTIONS_CONSTANTS, {
            method: 'post',
            url: 'SubscriberManagement/UpdateUsageCapElections',
            headers: {
                [SUBSCRIBER_ID_HEADER]: customerId
            },
            data: usageCapElections
        });
    };
};
export const UPDATE_USAGE_CAPS_DETAILS = 'UPDATE_USAGE_CAPS_DETAILS';
export const updateUsageCapsDetails = (payload) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_USAGE_CAPS_DETAILS,
            payload: payload
        });
        return Promise.resolve();
    };
};
