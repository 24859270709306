import i18n from 'invision-core/src/components/i18n/i18n';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {RouteParams} from 'invision-core/src/components/router/router.selectors';
import LocaleKeys from './../../locales/keys';

import {
    SERVICES_AND_SHARED_ENTITLEMENTS,
    SERVICES_AND_SHARED_ENTITLEMENTS_TAB
} from '../../components/customer/servicesAndUsage/services.and.usage.config';

export const ServicesAndUsagesTabsSelector = createImmutableSelector(
    [RouteParams],
    (routeParams) => {
        return [{
            name: i18n.translate(LocaleKeys.SERVICES_AND_USAGE.TABS.SERVICES),
            state: SERVICES_AND_SHARED_ENTITLEMENTS,
            params: {
                tabName: SERVICES_AND_SHARED_ENTITLEMENTS_TAB.SERVICES
            },
            active: routeParams.tabName === SERVICES_AND_SHARED_ENTITLEMENTS_TAB.SERVICES
        }, {
            name: i18n.translate(LocaleKeys.SERVICES_AND_USAGE.TABS.SHARED_ENTITLEMENTS),
            state: SERVICES_AND_SHARED_ENTITLEMENTS,
            params: {
                tabName: SERVICES_AND_SHARED_ENTITLEMENTS_TAB.SHARED_ENTITLEMENTS
            },
            active: routeParams.tabName === SERVICES_AND_SHARED_ENTITLEMENTS_TAB.SHARED_ENTITLEMENTS
        }];
    }
);