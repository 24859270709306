import ApiConstants from 'invision-core/src/api/constants';
import ThunkHelper from 'invision-core/src/api/thunk.helper';
import {ContextOfferingAction} from '../constants/wizard.constants';
import {getContractId} from './offering.order.actions';

export const RETRIEVE_OFFERING_REMOVAL_CONTEXT = {
    BEGIN: 'RETRIEVE_OFFERING_REMOVAL_CONTEXT_BEGIN',
    SUCCESS: 'RETRIEVE_OFFERING_REMOVAL_CONTEXT_SUCCESS',
    FAILURE: 'RETRIEVE_OFFERING_REMOVAL_CONTEXT_FAILURE'
};

const RETRIEVE_OFFERING_REMOVAL_CONTEXT_TYPES = [
    RETRIEVE_OFFERING_REMOVAL_CONTEXT.BEGIN,
    RETRIEVE_OFFERING_REMOVAL_CONTEXT.SUCCESS,
    RETRIEVE_OFFERING_REMOVAL_CONTEXT.FAILURE
];

export const CHANGE_OF_SERVICE_REMOVAL = {
    BEGIN: 'CHANGE_OF_SERVICE_REMOVAL_BEGIN',
    SUCCESS: 'CHANGE_OF_SERVICE_REMOVAL_SUCCESS',
    FAILURE: 'CHANGE_OF_SERVICE_REMOVAL_FAILURE'
};
const CHANGE_OF_SERVICE_REMOVAL_TYPES = [
    CHANGE_OF_SERVICE_REMOVAL.BEGIN,
    CHANGE_OF_SERVICE_REMOVAL.SUCCESS,
    CHANGE_OF_SERVICE_REMOVAL.FAILURE
];
export const CALCULATE_COS_QUOTE = {
    BEGIN: 'CALCULATE_COS_QUOTE_BEGIN',
    SUCCESS: 'CALCULATE_COS_QUOTE_SUCCESS',
    FAILURE: 'CALCULATE_COS_QUOTE_FAILURE'
};
const CALCULATE_COS_QUOTE_TYPES = [
    CALCULATE_COS_QUOTE.BEGIN,
    CALCULATE_COS_QUOTE.SUCCESS,
    CALCULATE_COS_QUOTE.FAILURE
];

export const retrieveOfferingRemovalContext = (removeItemIds, subscriberId, contractId = null) => {
    return (dispatch) => {
        return retrieveOfferingRemovalContextPromise(dispatch, removeItemIds, subscriberId, contractId);
    };
};

export const retrieveOfferingRemovalContextPromise = (dispatch, removeItemIds, subscriberId, contractId) => {
    const data = {
        Offerings: removeItemIds.map((removeItem) => {
            return {
                Action: ContextOfferingAction.REMOVE,
                OfferingId: removeItem.offeringId,
                OfferingInstanceId: removeItem.offeringInstanceId
            };
        }),
        ContractId: contractId || getContractId()
    };

    return ThunkHelper(dispatch, RETRIEVE_OFFERING_REMOVAL_CONTEXT_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOfferingContext',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
        },
        data
    });
};

export const submitChangeOfServiceRemoval = (ItemsToRemove, subscriberId, executionOptions = {}) => {
    return (dispatch) => {
        return submitChangeOfServiceRemovalPromise(dispatch, ItemsToRemove, subscriberId, executionOptions);
    };
};

const submitChangeOfServiceRemovalPromise = (dispatch, ItemsToRemove, subscriberId, executionOptions) => {
    return ThunkHelper(dispatch, CHANGE_OF_SERVICE_REMOVAL_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/SubmitChangeOfServiceOrder',
        headers: {
            'CD-SubscriberId': subscriberId
        },
        data : {
            Comment: executionOptions.disconnectComment,
            ExecutionDate: executionOptions.specifiedDate,
            ExecutionDateIntention: executionOptions.effective,
            ReasonCode: executionOptions.disconnectReason ? executionOptions.disconnectReason.Value || executionOptions.disconnectReason : undefined,
            RemoveItems: ItemsToRemove
        }
    });
};

export const calculateChangeOfServiceQuote = (ItemsToRemove, subscriberId, reasonCode) => {
    return (dispatch) => {
        return calculateChangeOfServiceQuotePromise(dispatch, ItemsToRemove, subscriberId, reasonCode);
    };
};

export const calculateChangeOfServiceQuotePromise = (dispatch, ItemsToRemove, subscriberId, reasonCode) => {
    return ThunkHelper(dispatch, CALCULATE_COS_QUOTE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/CalculateChangeOfServiceOrderQuote',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
        },
        data : {
            ReasonCode: reasonCode || undefined,
            RemoveItems: ItemsToRemove
        }
    });
};

// NEW APIs for Full Subscriber Disconnects
export const DISCONNECT_SUBSCRIBER_QUOTE = {
    BEGIN: 'DISCONNECT_SUBSCRIBER_QUOTE_BEGIN',
    SUCCESS: 'DISCONNECT_SUBSCRIBER_QUOTE_SUCCESS',
    FAILURE: 'DISCONNECT_SUBSCRIBER_QUOTE_FAILURE'
};

const DISCONNECT_SUBSCRIBER_QUOTE_TYPES = [
    DISCONNECT_SUBSCRIBER_QUOTE.BEGIN,
    DISCONNECT_SUBSCRIBER_QUOTE.SUCCESS,
    DISCONNECT_SUBSCRIBER_QUOTE.FAILURE
];

export const DISCONNECT_SUBSCRIBER = {
    BEGIN: 'DISCONNECT_SUBSCRIBER_BEGIN',
    SUCCESS: 'DISCONNECT_SUBSCRIBER_SUCCESS',
    FAILURE: 'DISCONNECT_SUBSCRIBER_FAILURE'
};

const DISCONNECT_SUBSCRIBER_TYPES = [
    DISCONNECT_SUBSCRIBER.BEGIN,
    DISCONNECT_SUBSCRIBER.SUCCESS,
    DISCONNECT_SUBSCRIBER.FAILURE
];

export const disconnectSubscriberQuote = (subscriberId, etfFeeOverride = false, etfWaiveReasons = null, reasonCode, enableOptionalQuoteOverride = false) => {
    return (dispatch) => {
        return disconnectSubscriberQuotePromise(dispatch, subscriberId, etfFeeOverride, etfWaiveReasons, reasonCode, enableOptionalQuoteOverride);
    };
};

export const disconnectSubscriberQuotePromise = (dispatch, subscriberId, etfFeeOverride, etfWaiveReasons, reasonCode, enableOptionalQuoteOverride) => {
    return ThunkHelper(dispatch, DISCONNECT_SUBSCRIBER_QUOTE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/DisconnectSubscriberQuote',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
        },
        data: {
            EarlyTerminationFeeOverrideReason: etfWaiveReasons,
            IsEarlyTerminationFeeOverride: etfFeeOverride,
            ReasonCode: reasonCode || undefined,
            CalculateQuoteOverridingConfiguration: enableOptionalQuoteOverride
        }
    });
};

export const disconnectSubscriber = (subscriberId, executionOptions = {}, etfFeeOverride = false, etfWaiveReasons = null) => {
    return (dispatch) => {
        return disconnectSubscriberPromise(dispatch, subscriberId, executionOptions, etfFeeOverride, etfWaiveReasons);
    };
};

const disconnectSubscriberPromise = (dispatch, subscriberId, executionOptions, etfFeeOverride, etfWaiveReasons) => {
    return ThunkHelper(dispatch, DISCONNECT_SUBSCRIBER_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/DisconnectSubscriber',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
        },
        data : {
            BillingEffectiveDate: executionOptions.billingEffectiveDate,
            BillingEffectiveDateIntention:executionOptions.billingEffectiveIntention,
            Comment: executionOptions.disconnectComment,
            EarlyTerminationFeeOverrideReason: etfWaiveReasons,
            ExecutionDate: executionOptions.specifiedDate,
            ExecutionDateIntention: executionOptions.effective,
            IsEarlyTerminationFeeOverride: etfFeeOverride,
            ReasonCode: executionOptions.disconnectReason ? executionOptions.disconnectReason.Value || executionOptions.disconnectReason : undefined
        }
    });
};

export const SET_WAIVE_AND_REASON = 'SET_WAIVE_AND_REASON';
export const setWaiveAndReason = (reason) => {
    return {
        type: SET_WAIVE_AND_REASON,
        payload: reason
    };
};

export const RESTORE_DEFAULT_ETF = 'RESTORE_DEFAULT_ETF';
export const restoreDefaultEtf = () => {
    return {
        type: RESTORE_DEFAULT_ETF
    };
};

export const TOGGLE_ETF_WAIVE = 'TOGGLE_ETF_WAIVE';
export const toggleEtfWaive = () => {
    return {
        type: TOGGLE_ETF_WAIVE
    };
};

export const SET_DEFAULT_ETF_AMOUNT ='SET_DEFAULT_ETF_AMOUNT';
export const setDefaultEtfAmount = (amount) => {
    return {
        type: SET_DEFAULT_ETF_AMOUNT,
        payload: amount
    };
};

export const CLEAR_DISCONNECT_DATA = 'CLEAR_DISCONNECT_DATA';
export const clearDisconnect = () => {
    return {
        type: CLEAR_DISCONNECT_DATA
    };
};

export const DISCONNECT_RETRIEVE_ORDER_CONTEXT = {
    BEGIN: 'DISCONNECT_RETRIEVE_ORDER_CONTEXT_BEGIN',
    SUCCESS: 'DISCONNECT_RETRIEVE_ORDER_CONTEXT_SUCCESS',
    FAILURE: 'DISCONNECT_RETRIEVE_ORDER_CONTEXT_FAILURE'
};

const DISCONNECT_RETRIEVE_ORDER_CONTEXT_TYPES = [
    DISCONNECT_RETRIEVE_ORDER_CONTEXT.BEGIN,
    DISCONNECT_RETRIEVE_ORDER_CONTEXT.SUCCESS,
    DISCONNECT_RETRIEVE_ORDER_CONTEXT.FAILURE
];

export const retrieveOrderContext = (customerId) => {
    return (dispatch) => {
        return retrieveOrderContextPromise(dispatch, customerId);
    };
};


export const retrieveOrderContextPromise = (dispatch, customerId) => {
    const params = {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOrderContext',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {}
    };

    return ThunkHelper(dispatch, DISCONNECT_RETRIEVE_ORDER_CONTEXT_TYPES, params);
};