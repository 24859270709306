import Immutable from 'seamless-immutable';
import * as ContentActivityActions from '../actions/customer.content.activity.actions';

export const INITIAL_STATE = Immutable({
    filterData: {
        activeIsSelected: null,
        currentPage: 1
    },
    pageSizePreference: null,
    selectedItemId: null,
    showBanner: false
});

export default function ContentActivityReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case ContentActivityActions.SET_ACTIVE_IS_SELECTED:
            return state.setIn(['filterData', 'activeIsSelected'], payload);
        case ContentActivityActions.SET_CURRENT_PAGE:
            return state.setIn(['filterData', 'currentPage'], payload);
        case ContentActivityActions.SET_SELECTED_CONTENT_ACTIVITY:
            return state.set('selectedItemId', payload);
        case ContentActivityActions.SET_CONTENT_ACTIVITY_PAGE_SIZE_PREFERENCE:
            return state.setIn(['filterData', 'pageSizePreference'], payload);
        case ContentActivityActions.RESET_CONTENT_ACTIVITY_RECOVERABLE:
        case ContentActivityActions.REMOVE_CONTENT_ACTIVITY_FILTER:
            return INITIAL_STATE;
        case ContentActivityActions.SHOW_BACK_BANNER:
            return state.set('showBanner', payload);
        default:
            return state;
    }
}
