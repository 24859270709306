import ApiConstants from 'invision-core/src/api/constants';
import ThunkHelper from 'invision-core/src/api/thunk.helper';
import {
    RESET_SUBSCRIBER_CREDIT_CLASSIFICATION,
    RETRIEVE_CREDIT_CLASSIFICATION_DETAILS,
    SET_CREDIT_CHECK_MODAL_STATUS,
    SET_CREDIT_CHECK_SKIPPED,
    UPDATE_CLASSIFICATION_METHOD_STATUS,
    UPDATE_CREDIT_CLASSIFICATION
} from '../constants/credit.check.constants';
import {
    buildAddressesRequestObject,
    buildCreditCheckAddressRequestObject,
    buildCustomerForCreditCheck
} from '../helpers/customer.helper';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';

const RETRIEVE_CREDIT_CLASSIFICATION_DETAILS_EVENTS = [
    RETRIEVE_CREDIT_CLASSIFICATION_DETAILS.BEGIN,
    RETRIEVE_CREDIT_CLASSIFICATION_DETAILS.SUCCESS,
    RETRIEVE_CREDIT_CLASSIFICATION_DETAILS.FAILURE
];

export const performCreditCheck = (subscriberCategory, subscriberId = null, subscriberType) => {
    return (dispatch) => {
        return performCreditCheckPromise(dispatch, subscriberCategory, subscriberId, subscriberType);
    };
};

export const setCreditCheckModalStatus = (isModalOpen) => {
    return {
        type: SET_CREDIT_CHECK_MODAL_STATUS,
        payload: isModalOpen
    };
};

export const setCreditCheckSkipped = (isSkipped) => {
    return {
        type: SET_CREDIT_CHECK_SKIPPED,
        payload: isSkipped
    };
};

export const performCreditCheckPromise = (dispatch, subscriberCategory, subscriberId, subscriberType) => {
    const headers = {};
    if (subscriberId) {
        headers[ApiConstants.SUBSCRIBER_ID_HEADER] = subscriberId;
    }
    return ThunkHelper(dispatch, RETRIEVE_CREDIT_CLASSIFICATION_DETAILS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveCreditClassificationDetails',
        data: {
            SubscriberCategory: subscriberCategory,
            SubscriberType: subscriberType
        },
        headers: headers
    });
};

export const resetSubscriberCreditClassification = () => {
    return {
        type: RESET_SUBSCRIBER_CREDIT_CLASSIFICATION
    };
};

export const updateCreditClassification = (dispatch, customer, address, creditCheckAddress, method, updateSubscriber, subscriberId, overrideCreditCheckClassificationID = null, overrideCreditCheckClassificationComment = null) => {
    const requestObj = {
        Address: address.Addresses[0],
        Credentials: customer.Credentials,
        CreditCheckMethod: method,
        OverrideCreditCheckClassificationComment: overrideCreditCheckClassificationComment,
        OverrideCreditCheckClassificationId: overrideCreditCheckClassificationID,
        Subscriber: customer.Subscriber,
        SubscriberCategory: customer.Subscriber.Category,
        SubscriberType: convertStringToNumber(customer.Subscriber.SubscriberTypeCode),
        UpdateSubscriber: updateSubscriber
    };
    if (creditCheckAddress.City && creditCheckAddress.Country && creditCheckAddress.LineOne && creditCheckAddress.State && creditCheckAddress.State) {
        Object.assign(requestObj, {
            AddressForCreditCheck: creditCheckAddress
        });
    }

    return ThunkHelper(dispatch, [UPDATE_CREDIT_CLASSIFICATION.BEGIN, UPDATE_CREDIT_CLASSIFICATION.SUCCESS, UPDATE_CREDIT_CLASSIFICATION.FAILURE], {
        method: 'post',
        url: 'SubscriberManagement/UpdateCreditClassification',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
        },
        data: requestObj
    });
};

export const createOrUpdateCustomerAndRunCreditCheck = (newCustomerData, creditCheck, selectedSubscriberCreditCheckDetails, additionalData = {
    updateSubscriber: false,
    creditClassificationData: null,
    isDbss: null
}) => {
    return (dispatch) => {
        const addressesOption = buildAddressesRequestObject(newCustomerData, selectedSubscriberCreditCheckDetails.Address);
        const creditCheckAddress = buildCreditCheckAddressRequestObject(newCustomerData.AddressForCreditCheck);
        const method = (creditCheck) ? UPDATE_CLASSIFICATION_METHOD_STATUS.RUN_CREDIT_CHECK : UPDATE_CLASSIFICATION_METHOD_STATUS.SKIP_CREDIT_CHECK;
        const subscriberId = newCustomerData.Id ? newCustomerData.Id : (additionalData.creditClassificationData ? additionalData.creditClassificationData.Subscriber.Id : undefined);
        // should not be modifying the reference of newCustomerData, will be fixed in ASCINV-319
        newCustomerData = buildCustomerForCreditCheck(newCustomerData, additionalData.creditClassificationData, additionalData.isDbss);
        return updateCreditClassification(dispatch, newCustomerData, addressesOption, creditCheckAddress, method, additionalData.updateSubscriber, subscriberId);
    };
};

export const overrideCreditClassification = (newCustomerData, selectedSubscriberCreditCheckDetails, additionalData = {
    updateSubscriber: false,
    creditClassificationData: null,
    overrideCreditCheckClassificationID: null,
    overrideCreditCheckClassificationComment: null,
    isDbss: null
}) => {
    return (dispatch) => {
        const addressesOption = buildAddressesRequestObject(newCustomerData, selectedSubscriberCreditCheckDetails.Address);
        const creditCheckAddress = buildCreditCheckAddressRequestObject(newCustomerData.AddressForCreditCheck);
        const method = UPDATE_CLASSIFICATION_METHOD_STATUS.RE_RUN_CREDIT_CHECK;
        const subscriberId = (additionalData.creditClassificationData) ? additionalData.creditClassificationData.Subscriber.Id : undefined;
        newCustomerData = buildCustomerForCreditCheck(newCustomerData, additionalData.creditClassificationData, additionalData.isDbss);
        return updateCreditClassification(dispatch, newCustomerData, addressesOption, creditCheckAddress, method, additionalData.updateSubscriber,
            subscriberId, additionalData.overrideCreditCheckClassificationID, additionalData.overrideCreditCheckClassificationComment);
    };
};
