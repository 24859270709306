import Immutable from 'seamless-immutable';
import NavigatorActions from 'invision-core/src/components/navigator/navigator.actions';
import {
    GO_TO_NEXT_STEP,
    GO_TO_PREVIOUS_STEP,
    GO_TO_STEP,
    SET_CHANGE_IMMEDIATELY,
    SET_CURRENT_STEP_IS_INVALID,
    SET_EDIT_ORDER_ADDITIONAL_PROPERTY,
    SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE,
    SET_GIFT_ORDER_INFO,
    SET_IS_GIFT_ORDER,
    SET_IS_SHIPPING_ADDRESS_SAME_AS_BILLING,
    SET_ORDER_DETAILS,
    SET_OTT_EXECUTION_OPTIONS,
    SET_PAYMENT_INSTRUMENTS,
    SET_PAYMENT_INSTRUMENT_IDS,
    SET_PRODUCT_ORDER_SHIPPING_ADDRESS_ID,
    SET_PRODUCT_ORDER_SHIPPING_METHOD_ID,
    SET_RESTORE_ORDER_WAIVE_FEE,
    SET_RESTORE_ORDER_WAIVE_FEE_REASON,
    SET_RESTORE_ORDER_WAIVE_LATE_FEE,
    SET_RESTORE_ORDER_WAIVE_LATE_FEE_REASON,
    SET_SELECTED_NEW_PRODUCTS_TAB,
    SET_TEMPORARY_SHIP_TO_NAME
} from './actions/products.wizard.actions';
import {ALL_STEPS_KEYS} from './constants/products.wizard.constants';
import createWizardReducers from './helpers/wizard.reducer.helper';
import {
    mapAdditionalProperty,
    setEditAp
} from './helpers/customer.helper';
import {EMPTY_OBJECT} from './constants/common.constants';

const wizardReducers = createWizardReducers(ALL_STEPS_KEYS);

export const DEFAULT_CART = {};
export const DEFAULT_PAYMENT_INSTRUMENT_IDS = [];
export const EMPTY_ARRAY = [];

export const INITIAL_STATE = wizardReducers.initialState.merge(Immutable({
    cart: DEFAULT_CART,
    changeImmediately: false,
    editCart: DEFAULT_CART,
    executionOptions: {
        effective: null,
        specifiedDate: null
    },
    giftOrderInfo: {},
    isGiftOrder: false,
    isShippingAddressSameAsBilling: false,
    orderAdditionalProperties: EMPTY_ARRAY,
    orderDetails: EMPTY_OBJECT,
    paymentInstrumentIds: DEFAULT_PAYMENT_INSTRUMENT_IDS,
    paymentInstruments: EMPTY_ARRAY,
    shippingAddressId: null,
    shippingMethodId: null,
    temporaryShipToName: null,
    waiveFee: false,
    waiveFeeReason: null,
    waiveLateFee: false,
    waiveLateFeeReason: null
}));

export default function wizardReducer(state = INITIAL_STATE, {payload, type}) {
    switch (type) {
        case GO_TO_NEXT_STEP:
            return wizardReducers.goToNextStep(state, payload);
        case GO_TO_PREVIOUS_STEP:
            return wizardReducers.goToPreviousStep(state, payload);
        case GO_TO_STEP:
            return wizardReducers.goToStep(state, payload);
        case SET_CHANGE_IMMEDIATELY:
            return state.set('changeImmediately', payload);
        case SET_CURRENT_STEP_IS_INVALID:
            return wizardReducers.setCurrentStepIsInvalid(state, payload);
        case SET_GIFT_ORDER_INFO:
            return state.set('giftOrderInfo', payload);
        case SET_IS_GIFT_ORDER:
            return state.set('isGiftOrder', payload);
        case SET_ORDER_DETAILS:
            return state.set('orderDetails', payload || EMPTY_OBJECT);
        case SET_OTT_EXECUTION_OPTIONS:
            return state.set('executionOptions', payload);
        case SET_PAYMENT_INSTRUMENTS:
            return state.set('paymentInstruments', payload);
        case SET_PAYMENT_INSTRUMENT_IDS:
            return state.set('paymentInstrumentIds', payload);
        case SET_PRODUCT_ORDER_SHIPPING_ADDRESS_ID:
            return state.set('shippingAddressId', payload);
        case SET_PRODUCT_ORDER_SHIPPING_METHOD_ID:
            return state.set('shippingMethodId', payload);
        case SET_SELECTED_NEW_PRODUCTS_TAB:
            return wizardReducers.setSelectedOrderTab(state, payload);
        case SET_IS_SHIPPING_ADDRESS_SAME_AS_BILLING:
            return state.set('isShippingAddressSameAsBilling', payload);
        case SET_RESTORE_ORDER_WAIVE_FEE:
            return state.set('waiveFee', payload);
        case SET_RESTORE_ORDER_WAIVE_LATE_FEE:
            return state.set('waiveLateFee', payload);
        case SET_RESTORE_ORDER_WAIVE_FEE_REASON:
            return state.set('waiveFeeReason', payload);
        case SET_RESTORE_ORDER_WAIVE_LATE_FEE_REASON:
            return state.set('waiveLateFeeReason', payload);
        case SET_TEMPORARY_SHIP_TO_NAME:
            return state.set('temporaryShipToName', payload);
        case NavigatorActions.STATE_CHANGE_SUCCESS:
            return INITIAL_STATE;
        case SET_EDIT_ORDER_ADDITIONAL_PROPERTY:
            return state.set('orderAdditionalProperties', mapAdditionalProperty(payload));
        case SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE:
            return state.set('orderAdditionalProperties', setEditAp(payload, state.orderAdditionalProperties || EMPTY_ARRAY));
        default:
            return state;
    }
}
