import {createSelector} from 'reselect';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {memoize} from 'lodash';
import Immutable from 'seamless-immutable';
import pathOr from 'ramda/src/pathOr';
import values from 'ramda/src/values';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {MetadataCodeTypeSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {OFFER_STATUS_INDICATOR_STATUS} from 'invision-core/src/constants/status.constants';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import CustomerCareKeys from '../../locales/keys';
import {
    CurrentCustomerSelector,
    SelectedCustomerSelector
} from './customer.selectors';
import {addressStateRegionProvinceValueOptionsForCountry} from '../helpers/customer.selectors.helpers';
import {
    getResponsiblePartyName,
    transformRedirectablesFromExistingRedirects,
    transformRedirectablesFromOptions
} from './customer.account.hierarchy.selectors.helper';

import {OFFERING_OPTION_STATUSES} from '../../components/shared/constants/offering.option.status.constants';

export const STANDALONE_TYPES = {
    AD_HOC: 'AD_HOC'
};

// "Selected Customer's Account Hierarchy selector"
const AccountHierarchySelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.accountHierarchy;
    }
);

export const HierarchyNameSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return pathOr(null, ['data', 'hierarchyName'], accountHierarchy);
    }
);

const NodesMapByIdSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.data.nodesMapById;
    }
);

const NodesMapBySubscriberIdSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.data.nodesMapBySubscriberId;
    }
);

export const CustomerSearchMapSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.data.customersSearch;
    }
);

export const CustomerSearchMutableArraySelector = createSelector(
    [CustomerSearchMapSelector],
    (customerSearchMap) => {
        return customerSearchMap ? values(customerSearchMap.asMutable({
            deep: true
        })) : null;
    }
);

export const CustomerSearchRecordCountSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.data.customersSearchRecordCount;
    }
);

export const CustomerSearchCurrentPageNumberSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.data.customersSearchPageNumber;
    }
);

export const CustomerSearchTotalPageCountSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.data.customersSearchPageCount;
    }
);

export const CustomerSearchIsSearchingCustomersSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.isSearchingCustomers;
    }
);

//broken here
export const CurrentCustomerNodeSelector = createSelector(
    [CurrentCustomerSelector, NodesMapBySubscriberIdSelector],
    (currentCustomer, nodesMapBySubscriberId) => {
        if (currentCustomer && nodesMapBySubscriberId) {
            return nodesMapBySubscriberId[currentCustomer.Id];
        }
        return null;
    }
);
// "Selected Customer's Account Hierarchy's Selected Node's Id selector"
export const SelectedNodeIdSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.selectedNodeId;
    }
);

export const NodeToBeMoveIdSelector = createImmutableSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.nodeToBeMoved;
    }
);

export const TargetNodeSelector = createImmutableSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.targetNode;
    }
);

// "Selected Customer's Account Hierarchy's Selected Node selector"
export const SelectedNodeSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        const nodesMapById = accountHierarchy.data.nodesMapById;
        const selectedNodeId = accountHierarchy.selectedNodeId;
        return nodesMapById && selectedNodeId ? nodesMapById[selectedNodeId] : null;
    }
);

export const SelectedNodeResponsiblePartyNameSelector = createSelector(
    [SelectedNodeSelector],
    (selectedNode) => {
        if (selectedNode && selectedNode.ResponsiblePartySubscriber) {
            return getResponsiblePartyName(selectedNode.ResponsiblePartySubscriber);
        }
        return null;
    }
);

export const IsCreatingHierarchySelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.isCreatingHierarchy;
    }
);

// "Selected Customer's Account Hierarchy's Selected Node's Subscriber Summary selector"
export const SelectedNodeSubscriberSummarySelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.data.selectedNodeSubscriberSummary;
    }
);

//This will return the postpaid account details for the account, which, according to the API team, will always be the ONLY postpaid account on a subscriber
export const SelectedNodeSubscriberSummaryAccountDetailsSelector =  createSelector(
    [SelectedNodeSubscriberSummarySelector],
    (summary) => {
        const accountSummaries = pathOr([], ['AccountSummaries'], summary);
        const accountSummary = accountSummaries.find((accountSummary) => {
            return !!accountSummary.PostpaidDetails;
        });

        return accountSummary ? accountSummary.PostpaidDetails : null;
    }
);

//This is the selector for the node that currently has a popup open.
export const PopupHierarchyNodeSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.data.popupHierarchyNode;
    }
);

// This is the subscriber summary for the node who's menu item has been selected (and opened up a popup)
export const PopupNodeSubscriberSummarySelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.data.popupNodeSubscriberSummary;
    }
);


//This will return the results of the ConvergentBillerAccountDetails API call for the subscriber node with a popup that is currently open
export const PopupNodeConvergentBillerAccountDetailsSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.data.popupNodeConvergentBillerAccountDetails;
    }
);

const EMPTY_REDIRECTABLES = Immutable([]);
export const RedirectablesSelector = createSelector(
    [
        PopupNodeConvergentBillerAccountDetailsSelector,
        PopupNodeSubscriberSummarySelector
    ],
    (accountDetails, subscriberSummary) => {
        let redirectables;
        const accountSummaries = pathOr([], ['AccountSummaries'], subscriberSummary);
        let offersAndStandalones = pathOr([], ['Offerings'], accountDetails);
        const accountDetailsAdhocs = pathOr([], ['Standalones'], accountDetails).filter((standalone) => {
            return standalone.ProductClassification === PRODUCT_CLASSIFICATIONS.AD_HOC;
        });

        if (accountDetailsAdhocs.length) {
            offersAndStandalones = offersAndStandalones.concat({
                DisplayName: i18n.translate(CustomerCareKeys.AD_HOC),
                OfferingInstanceId: STANDALONE_TYPES.AD_HOC,
                Options: accountDetailsAdhocs
            });
        }

        offersAndStandalones = offersAndStandalones.map((offer) => {
            return Object.assign({}, offer, {
                Options: offer.Options.filter((option) => {
                    return pathOr(0, ['BillerTypeAmounts', 'length'], option); //Remove items that don't have BillerTypeAmounts as they are no longer being charged for.
                })
            });
        });

        if (accountSummaries.length && offersAndStandalones.length) {

            // NOTE: This assumes there's only 1 postpaid account for a subscriber
            const postpaidAccount = accountSummaries.find((accountSummary) => {
                return !!accountSummary.PostpaidDetails;
            });

            if (postpaidAccount) {
                redirectables = offersAndStandalones.filter((offer) => {

                    // Ensure an offer is not "removed" and has at least 1 option that is "active", "pending active", or "pending removed"
                    return offer.Status !== OFFER_STATUS_INDICATOR_STATUS.REMOVED &&
                        offer.Options.some((option) => {
                            return option.AccountNumber === postpaidAccount.AccountNumber &&
                                (option.Status === OFFERING_OPTION_STATUSES.ACTIVE ||
                                option.Status === OFFERING_OPTION_STATUSES.PENDING_ACTIVE ||
                                option.Status === OFFERING_OPTION_STATUSES.PENDING_REMOVED);
                        });
                });
            }
        }
        return redirectables ? Immutable(redirectables) : EMPTY_REDIRECTABLES;
    }
);

const EMPTY_REDIRECTABLES_VIEW_MODEL = Immutable([]);
export const RedirectablesViewModelSelector = createSelector(
    [
        AccountHierarchySelector,
        CurrentCustomerSelector,
        NodesMapBySubscriberIdSelector,
        RedirectablesSelector,
        PopupHierarchyNodeSelector
    ],
    (accountHierarchy, currentCustomer, nodesMapBySubscriberId, redirectables, popupHierarchyNode) => {
        const chargeRedirectInstances = pathOr([], ['data', 'popupNodeRedirectedServices', 'ChargeRedirectInstances'], accountHierarchy);

        let redirectablesWithTransformations = transformRedirectablesFromOptions(redirectables);

        // Map existing redirects to current offers/options to indicate which ones already exist, who they're redirected to, etc
        if (chargeRedirectInstances.length) {

            // Restrict existing redirects to those for the current subscriber's services
            const redirectedServices = chargeRedirectInstances.filter((service) => {
                return service.SourceSubscriber.Id === popupHierarchyNode.SubscriberId;
            });

            redirectablesWithTransformations = transformRedirectablesFromExistingRedirects(
                redirectablesWithTransformations,
                redirectedServices,
                nodesMapBySubscriberId,
                currentCustomer.Id
            );
        }

        return !redirectablesWithTransformations.length ?
            EMPTY_REDIRECTABLES_VIEW_MODEL :
            redirectablesWithTransformations.map((offer) => {
                const uniqueOptions = {};

                return offer.merge({
                    Options: offer.Options.map((option) => {

                        // Prevent duplicate instances of an option from being displayed
                        const isHidden = !!uniqueOptions[option.ProductClassification === PRODUCT_CLASSIFICATIONS.AD_HOC ?
                            option.ProductId :
                            option.OfferingOptionPriceId];

                        if (!isHidden) {
                            uniqueOptions[option.ProductClassification === PRODUCT_CLASSIFICATIONS.AD_HOC ?
                                option.ProductId :
                                option.OfferingOptionPriceId] = option;
                        }

                        return option.merge({
                            isDisplaySelected: offer.Options.some((offerOption) => {
                                return offerOption.isSelected &&
                                    (option.ProductClassification === PRODUCT_CLASSIFICATIONS.AD_HOC ?
                                        option.ProductId === offerOption.ProductId :
                                        option.OfferingOptionPriceId === offerOption.OfferingOptionPriceId);
                            }),
                            isHidden
                        });
                    })
                });
            });
    }
);

export const HierarchyNodeSelector = createSelector(
    [NodesMapByIdSelector],
    (nodesMapById) => {
        return memoize((nodeId) => {
            return nodesMapById && nodeId ? nodesMapById[nodeId] : null;
        });
    }
);

export const HierarchyNodeBySubscriberSelector = createSelector(
    [NodesMapBySubscriberIdSelector],
    (nodesMapBySubscriberId) => {
        return memoize((subscriberId) => {
            return nodesMapBySubscriberId && subscriberId ? nodesMapBySubscriberId[subscriberId] : null;
        });
    }
);

export const RootNodeIdSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.data.rootNodeId;
    }
);

export const NodeIsInPathToContextSelector = createSelector(
    [RootNodeIdSelector, NodesMapBySubscriberIdSelector, SelectedCustomerSelector],
    (rootNodeId, nodesMapBySubscriberId, selectedCustomer) => {
        const currentSubscriberNode = nodesMapBySubscriberId && nodesMapBySubscriberId[selectedCustomer.data.Id];

        return memoize((nodeId) => {
            return currentSubscriberNode && checkNode(currentSubscriberNode);

            function checkNode(nodeToCheck) {
                return nodeToCheck.Id === nodeId ||
                    nodeToCheck.Id !== rootNodeId &&
                    checkNode(nodesMapBySubscriberId[nodeToCheck.ParentSubscriberId]);
            }
        });
    }
);

// "Selected Customer's Account Hierarchy-related error selector"
export const ErrorSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.accountHierarchyError;
    }
);

export const LastAttemptErrorSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.lastAttemptError;
    }
);

export const CreateHierarchyErrorSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.createHierarchyError;
    }
);

// "Selected Customer's Account Hierarchy is being retrieved selector"
export const IsRetrievingHierarchySelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.isRetrievingHierarchy;
    }
);

export const IsUpdatingHierarchyNodeSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.isUpdatingHierarchyNode;
    }
);

export const IsUpdatingHierarchyNodeServiceSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.isUpdatingNodeService;
    }
);

// "Selected Customer's Account Hierarchy's (Selected Node's) Subscriber Summary is being retrieved selector"
export const IsRetrievingNodeSubscriberSummarySelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.isRetrievingNodeSubscriberSummary;
    }
);

export const IsRetrievingPopupNodeConvergentBillerAccountDetailsSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.isRetrievingPopupNodeConvergentBillerAccountDetails;
    }
);

export const ResponsiblePartyCustomerSelector = createSelector(
    [CurrentCustomerSelector, HierarchyNodeBySubscriberSelector],
    (currentCustomer, hierarchyNodeBySubscriber) => {
        const hierarchyNode = hierarchyNodeBySubscriber(currentCustomer.Id);
        return hierarchyNode && hierarchyNode.ResponsiblePartySubscriber;
    }
);

export const ResponsiblePartyCustomerNameSelector = createSelector(
    [ResponsiblePartyCustomerSelector],
    (responsiblePartyCustomer) => {
        if (responsiblePartyCustomer) {
            return getResponsiblePartyName(responsiblePartyCustomer);
        }
        return null;
    }
);

export const CreateChildModelSelector = createSelector(
    [AccountHierarchySelector, CurrentCustomerSelector],
    (accountHierarchy, currentCustomer) => {
        return accountHierarchy.createChildModel.merge({
            Customer: {
                HomeCountry: pathOr(null, ['createChildModel', 'Customer', 'HomeCountry'], accountHierarchy) || pathOr(null, ['HomeCountry'], currentCustomer),
                SubscriberCurrency: pathOr(null, ['createChildModel', 'Customer', 'SubscriberCurrency'], accountHierarchy) || pathOr(null, ['SubscriberCurrency'], currentCustomer)
            }
        }, {
            deep: true
        }).asMutable({
            deep: true
        });
    }
);
export const CreateChildStateRegionProvinceValueOptions = createSelector( [
    CreateChildModelSelector,
    MetadataCodeTypeSelector(CODES.AddressCountry),
    MetadataCodeTypeSelector(CODES.AddressStateProvinceRegion)
], (createChildModel, countryCodes, stateCodes) => {
    return addressStateRegionProvinceValueOptionsForCountry(createChildModel.Customer.HomeCountry, countryCodes, stateCodes);
});
export const IsCreatingChildSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.isCreatingChild;
    }
);
export const CreateChildErrorSelector = createSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.createChildError;
    }
);
export const IsChildForCustomerHierarchySelector = createImmutableSelector(
    [AccountHierarchySelector],
    (accountHierarchy) => {
        return accountHierarchy.isChildForCustomerHierarchy;
    });
