import {
    toPairs
} from 'ramda';


// Input: Instance properties formatted as needed for UI
//        Example: { 1300: 'value here', 1301: 'another' }
// Output: Instance properties formatted as needed for API Requests
//         Example: [{ Key: 1300, Value: 'value here' }, { Key: 1301, Value: 'another' }]
export const convertMapToKeyValueObjectArray = (map) => {
    return toPairs(map).map(([key, value]) => {
        return {
            Key: key,
            Value: value
        };
    });
};

// Input:  Instance properties formatted as needed for API Requests
//         Example: [{ Key: 1300, Value: 'value here' }, { Key: 1301, Value: 'another' }]
// Output: Instance properties formatted as needed for UI
//         Example: { 1300: 'value here', 1301: 'another' }
export const getFormattedInstancePropertiesForUI = (instancePropertyConfigForItem) => {
    const retVal = {};

    instancePropertyConfigForItem.forEach((configuredInstanceProperty) => {
        retVal[configuredInstanceProperty.Key] = configuredInstanceProperty.Value;
    });

    return retVal;
};

export const getTransformedInstancePropertiesForItem = (item) => {
    // Removing `Details` from the `ShoppingCartItem`, since it's causing API to fail for larger payloads
    // TODO: make update it in the API so that the `Details` won't come back in the response
    const {Details, ...otherShoppingCartItemProps} = item;

    const result = Object.assign({}, otherShoppingCartItemProps, {
        InstanceProperties: convertMapToKeyValueObjectArray(otherShoppingCartItemProps.InstanceProperties)
    });

    if (result.ChildItems && result.ChildItems.length) {
        result.ChildItems = result.ChildItems.map((childItem) => {
            if (!childItem.InstanceProperties) {
                return childItem;
            }

            return Object.assign({}, childItem, {
                InstanceProperties: convertMapToKeyValueObjectArray(childItem.InstanceProperties)
            });
        });
    }

    return result;
};

export const transformCartItemInstanceProperties = (cart, executionOptions) => {
    return Object.assign({}, cart, {
        Items: (cart.Items || []).map((item) => {
            return {
                ...(executionOptions?.billingEffectiveIntention && {
                    BillingEffectiveDateIntention: executionOptions.billingEffectiveIntention,
                    BillingEffectiveDate: executionOptions?.billingEffectiveDate
                }),
                ...getTransformedInstancePropertiesForItem(item)
            };
        })
    });
};
