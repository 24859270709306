import clone from 'ramda/src/clone';
import curry from 'ramda/src/curry';
import flatten from 'ramda/src/flatten';
import forEachObjIndexed from 'ramda/src/forEachObjIndexed';
import groupBy from 'ramda/src/groupBy';
import pathOr from 'ramda/src/pathOr';
import pipe from 'ramda/src/pipe';
import pluck from 'ramda/src/pluck';

export const markDecisionsRequiringAffectedServicesResolutionAsResolved = (decisionsRequiringAffectedServicesResolution) => {
    return decisionsRequiringAffectedServicesResolution.map((decision) => {
        return Object.assign({}, decision.asMutable(), {
            resolved: true
        });
    });
};

// Pipe functions for duplicatedServiceIdentifierAttributes
const filterNonEmptyServiceIdentifiers = (attributes) => {
    return attributes.filter((attribute) => {
        const formValue = pathOr('', ['formValue'], attribute);
        return attribute.serviceIdentifier && formValue.trim() !== '';
    });
};

const pluckBy = curry((type, array) => {
    return pluck(type, array);
});

const groupedDuplicatedArrays = (serviceIdentifiers) => {
    return groupBy((attribute) => {
        return attribute.formValue;
    }, serviceIdentifiers);
};

const filterDuplicatedOnly = (attributesByValue) => {
    let array = [];

    forEachObjIndexed((value) => {
        if (value.length > 1) {
            array = array.concat(value);
        }
    }, attributesByValue);

    return array;
};

const ATTRIBUTE_CONSTANTS = {
    arrayOfAttributes: 'attributes',
    attributeId: 'id'
};

export const duplicatedServiceIdentifierAttributes = (attributeGroups) => {
    return pipe(
        pluckBy(ATTRIBUTE_CONSTANTS.arrayOfAttributes),
        flatten,
        filterNonEmptyServiceIdentifiers,
        groupedDuplicatedArrays,
        filterDuplicatedOnly,
        pluckBy(ATTRIBUTE_CONSTANTS.attributeId)
    )(attributeGroups);
};

// Handles Add Offer & New Connect flows.
export const getShoppingCartWithUpdatedStoreIdForAPIRequest = (shoppingCart, storeId) => {
    const shoppingCartForRequestObject = clone(shoppingCart);
    (shoppingCartForRequestObject.Items || []).forEach((item) => {
        (item.PhysicalInventories || []).forEach((physicalInventory) => {
            physicalInventory.StoreId = storeId;
        });
    });
    return shoppingCartForRequestObject;
};

export const buildSegmentationContext = (customerInfo) => {
    return [{
        'Key': 'zipcode',
        'Value': customerInfo.postalCode
    }, {
        'Key': 'subscriber_category',
        'Value': customerInfo.Category
    }, {
        'Key': 'subscriber_type',
        'Value': customerInfo.SubscriberTypeCode
    }];
};

export const getMultiOfferShoppingCartWithUpdatedDeliveryDecision = (shoppingCart, decisionType) => {
    const shoppingCartForRequestObject = clone(shoppingCart);
    (shoppingCartForRequestObject.Items || []).forEach((item) => {
        if (item.DeliveryType) {
            item.DeliveryType = decisionType;
        }
    });
    return {
        cartItems: shoppingCartForRequestObject,
        isAppend: false
    };
};