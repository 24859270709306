import find from 'ramda/src/find';
import pathOr from 'ramda/src/pathOr';
import propEq from 'ramda/src/propEq';
import {DiscountsMetadataMapSelector} from 'invision-core/src/components/metadata/discounts/discounts.selectors';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {PageSizePreferenceSelector} from 'invision-core/src/components/session/session.selectors';
import i18n from 'invision-core/src/components/i18n/i18n';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {MetadataCodeTypeDictionarySelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import CustomerLocaleKeys from '../../../src/locales/keys';
import {CASE_CATEGORIES} from '../../components/customer/cases/list/case.constants';
import {PAYMENT_TYPE} from '../../components/customer/inventory/inventory.constants';
import {
    CurrentCustomerCurrencyCodeSelector,
    CurrentCustomerSelector,
    SelectedCustomerSelector
} from './customer.selectors';
import {
    ActiveCaseTypesSelector,
    SelectedCaseCategoryIdSelector,
    SelectedCaseInventoryIdSelector
} from './customer.cases.selectors';
import {getFormattedServiceAttributeValue} from './services.list.selectors.helper';

const CurrentCustomerInventoryStoreSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (currentCustomer) => {
        return currentCustomer ? currentCustomer.inventory : null;
    }
);

export const CustomerInventoryItemsSelector = createImmutableSelector(
    [
        CurrentCustomerInventoryStoreSelector,
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression)
    ],
    (inventoryStore, serviceAttributes, regularExpressions) => {
        return (inventoryStore.data || []).map((item) => {
            const serviceIdentifier = pathOr(null, ['ServiceIdentifiers', '0'], item);

            return Object.assign({}, item, {
                title: item.FriendlyName || item.InventoryTypeDisplayName || item.InventoryType,
                serviceIdentifierFormatted: serviceIdentifier && getFormattedServiceAttributeValue(serviceIdentifier.ServiceAttributeId, serviceIdentifier.Value, serviceAttributes, regularExpressions)
            });
        }) ;
    }
);

export const IsLoadingInventoryItemsSelector = createImmutableSelector(
    [CurrentCustomerInventoryStoreSelector],
    (inventoryStore) => {
        return inventoryStore.isLoadingInventory;
    }
);

export const SelectedInventoryNameSelector = createImmutableSelector(
    [CurrentCustomerInventoryStoreSelector],
    (inventoryStore) => {
        return inventoryStore.physicalInventoryName;
    }
);

export const SelectedInventoryDetailsSelector = createImmutableSelector(
    [CurrentCustomerInventoryStoreSelector],
    (inventoryStore) => {
        return inventoryStore.selectedInventory.details;
    }
);

export const IsLoadingInventoryDetailsSelector = createImmutableSelector(
    [CurrentCustomerInventoryStoreSelector],
    (inventoryStore) => {
        return inventoryStore.isLoadingInventoryDetails;
    }
);

export const SelectedInventoryFinanceDetailsSelector = createImmutableSelector(
    [CurrentCustomerInventoryStoreSelector],
    (inventoryStore) => {
        return pathOr(null, ['selectedInventory', 'financeDetails', '0'], inventoryStore);
    }
);

export const IsLoadingInventoryFinanceDetailsSelector = createImmutableSelector(
    [CurrentCustomerInventoryStoreSelector],
    (inventoryStore) => {
        return inventoryStore.isLoadingInventoryFinanceDetails;
    }
);

export const SelectedInventoryFinanceActivitiesSelector = createImmutableSelector(
    [CurrentCustomerInventoryStoreSelector],
    (inventoryStore) => {
        return inventoryStore.selectedInventory.financeActivities || [];
    }
);

const getPaymentTypeName = (paymentType) => {
    switch (paymentType) {
        case PAYMENT_TYPE.ACPBILL : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.ACPBILL);
        }
        case PAYMENT_TYPE.AMAZON_ACCOUNT : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.AMAZON_ACCOUNT);
        }
        case PAYMENT_TYPE.BATCH : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.BATCH);
        }
        case PAYMENT_TYPE.BRAIN_TREE : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.BRAIN_TREE);
        }
        case PAYMENT_TYPE.CASH : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.CASH);
        }
        case PAYMENT_TYPE.CHECK_REFUND : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.CHECK_REFUND);
        }
        case PAYMENT_TYPE.CHECK : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.CHECK);
        }
        case PAYMENT_TYPE.CONVERGENTBILLER_INVOICE : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.CONVERGENTBILLER_INVOICE);
        }
        case PAYMENT_TYPE.CONVERSION : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.CONVERSION);
        }
        case PAYMENT_TYPE.CREDIT_CARD : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.CREDIT_CARD);
        }
        case PAYMENT_TYPE.CURRENCY_ENTITLEMENT : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.CURRENCY_ENTITLEMENT);
        }
        case PAYMENT_TYPE.DIRECT_DEBIT : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.DIRECT_DEBIT);
        }
        case PAYMENT_TYPE.EXTERNAL_BILL : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.EXTERNAL_BILL);
        }
        case PAYMENT_TYPE.EXTERNAL_GIFT_CARD : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.EXTERNAL_GIFT_CARD);
        }
        case PAYMENT_TYPE.FACEBOOK_ACCOUNT : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.FACEBOOK_ACCOUNT);
        }
        case PAYMENT_TYPE.GIFT_CARD : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.GIFT_CARD);
        }
        case PAYMENT_TYPE.GOOGLEPLAY_ACCOUNT : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.GOOGLEPLAY_ACCOUNT);
        }
        case PAYMENT_TYPE.ITUNES_ACCOUNT : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.ITUNES_ACCOUNT);
        }
        case PAYMENT_TYPE.LOCKBOX_PAYMENT : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.LOCKBOX_PAYMENT);
        }
        case PAYMENT_TYPE.PAYPAL_ACCOUNT : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.PAYPAL_ACCOUNT);
        }
        case PAYMENT_TYPE.POINT_OF_SALE : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.POINT_OF_SALE);
        }
        case PAYMENT_TYPE.ROKU_ACCOUNT : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.ROKU_ACCOUNT);
        }
        case PAYMENT_TYPE.STORED_VALUE_ACCOUNT : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.STORED_VALUE_ACCOUNT);
        }
        case PAYMENT_TYPE.UWP_ACCOUNT : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.UWP_ACCOUNT);
        }
        case PAYMENT_TYPE.XBOX_ACCOUNT : {
            return i18n.translate(CustomerLocaleKeys.INVENTORY.FINANCE_DETAILS.PAYMENT_TYPES.XBOX_ACCOUNT);
        }
        default:
    }
};

export const FinanceActivityTableDataSelector = createImmutableSelector(
    [
        SelectedInventoryFinanceActivitiesSelector,
        CurrentCustomerCurrencyCodeSelector
    ],
    (financeActivities, currencyCode) => {

        const financeActivityWithCurrency = financeActivities.map((item) => {
            const financeItem = {};
            financeItem.currencyCode = currencyCode;
            financeItem.data = item;
            financeItem.paymentType = getPaymentTypeName(item.PaymentType);
            return financeItem;
        });

        return financeActivityWithCurrency;
    }
);

export const CurrentCustomerInventoryPaginationDataSelector = createImmutableSelector(
    [
        CurrentCustomerInventoryStoreSelector,
        PageSizePreferenceSelector
    ],
    (inventoryStore, defaultPageSize) => {
        if (!inventoryStore.paginationData.pageSize) {
            return Object.assign({}, inventoryStore.paginationData, {
                pageSize: defaultPageSize
            });
        }
        return inventoryStore.paginationData;
    }
);

export const CurrentCustomerInventoryFilterDataSelector = createImmutableSelector(
    [CurrentCustomerInventoryStoreSelector],
    (inventoryStore) => {
        return inventoryStore.filterData;
    }
);

export const CurrentCustomerInventoryFinanceDiscountsSelector = createImmutableSelector(
    [SelectedInventoryFinanceDetailsSelector, DiscountsMetadataMapSelector, CurrentCustomerSelector],
    (inventory, allDiscounts, currentCustomer) => {
        let discounts = [];
        if (inventory && allDiscounts && inventory.FinanceDiscounts && inventory.FinanceDiscounts.length) {
            discounts = inventory.FinanceDiscounts.map(financeDiscount => {
                return {
                    Name: allDiscounts[financeDiscount.DiscountId]?.StorefrontText || '',
                    Currency: currentCustomer.SubscriberCurrency,
                    savings: financeDiscount.Amount
                };
            });
        }
        return discounts;
    }
);

export const SelectedInventoryCaseTypesSelector = createImmutableSelector(
    [ActiveCaseTypesSelector, SelectedInventoryDetailsSelector],
    (caseTypes, inventoryDetails) => {
        const inventoryCaseTypes = {};
        caseTypes.filter(({Id, CaseTemplateId, InventoryTypeIds}) => {
            const inventoryTypeIds = (InventoryTypeIds || []);
            return inventoryTypeIds.length ?
                inventoryTypeIds.includes(inventoryDetails && inventoryDetails.InventoryTypeId) :
                Id === CASE_CATEGORIES.TROUBLE_CALL || CaseTemplateId === CASE_CATEGORIES.TROUBLE_CALL;
        }).forEach((caseType) => {
            inventoryCaseTypes[caseType.Id] = {
                caseTypeId: caseType.Id,
                title: caseType.DisplayName
            };
        });

        return inventoryCaseTypes;
    }
);

export const CaseInventoryOptionsSelector = createImmutableSelector(
    [
        ActiveCaseTypesSelector,
        CustomerInventoryItemsSelector,
        SelectedCaseCategoryIdSelector
    ],
    (caseTypes, inventoryItems, selectedCaseTypeId) => {
        const caseTemplateId = pathOr(null, ['CaseTemplateId'], find(propEq(selectedCaseTypeId, 'Id'), caseTypes));
        const inventoryTypeIds = pathOr([], ['InventoryTypeIds'], find(propEq(selectedCaseTypeId, 'Id'), caseTypes));
        const isTroubleCall = (selectedCaseTypeId === CASE_CATEGORIES.TROUBLE_CALL || caseTemplateId === CASE_CATEGORIES.TROUBLE_CALL);

        const filteredInventoryItems = (inventoryItems || []).filter(({InventoryTypeId}) => {
            return inventoryTypeIds.length ? inventoryTypeIds.includes(InventoryTypeId) : isTroubleCall;
        }).map(({SerialNumber, FriendlyName, Id, InventoryTypeId, InventoryType}) => {
            return {
                Id,
                InventoryTypeId,
                serialNumber: SerialNumber,
                text: FriendlyName || `${InventoryType ? `${InventoryType} - ` : ''}${SerialNumber}`
            };
        });

        const selectOption = {
            Id: undefined,
            text: i18n.translate(CoreLocaleKeys.SELECT)
        };
        return filteredInventoryItems.length ? [selectOption].concat(filteredInventoryItems) : filteredInventoryItems;
    }
);

export const SelectedCaseInventorySelector = createImmutableSelector(
    [SelectedCaseInventoryIdSelector, CustomerInventoryItemsSelector],
    (inventoryId, inventoryItems) => {
        return inventoryItems.find((inventory) => {
            return inventory.Id === inventoryId;
        });
    }
);
