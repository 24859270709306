import thunkHelper from 'invision-core/src/api/thunk.helper';
import {SUBSCRIBER_ID_HEADER} from 'invision-core/src/api/constants';
import {CurrentCustomerIdSelector} from '../selectors/customer.selectors';

export const RETRIEVE_SERVICE_SUSPENSION_QUOTE = {
    BEGIN: 'RETRIEVE_SERVICE_SUSPENSION_QUOTE_BEGIN',
    SUCCESS: 'RETRIEVE_SERVICE_SUSPENSION_QUOTE_SUCCESS',
    FAILURE: 'RETRIEVE_SERVICE_SUSPENSION_QUOTE_FAILURE'
};

export const retrieveServiceSuspensionQuote = (reasonCode, serviceIdentifiers) => {
    return (dispatch, getState) => {
        return thunkHelper(dispatch, Object.values(RETRIEVE_SERVICE_SUSPENSION_QUOTE), {
            method: 'post',
            url: 'SubscriberManagement/CalculateServiceSuspensionQuote',
            headers: {
                [SUBSCRIBER_ID_HEADER]: CurrentCustomerIdSelector(getState())
            },
            data: {
                ServiceSuspensionReasonCode: reasonCode,
                ServiceIdentifiers: serviceIdentifiers
            }
        });
    };
};

export const CLEAR_SERVICE_SUSPEND_RESUME_MODAL = 'CLEAR_SERVICE_SUSPEND_RESUME_MODAL';
export const clearServiceSuspendResumeModal = () => {
    return {
        type: CLEAR_SERVICE_SUSPEND_RESUME_MODAL
    };
};

export const SET_SELECTED_SERVICE_IDENTIFIER_VALUES = 'SET_SELECTED_SERVICE_IDENTIFIER_VALUES';
export const setSelectedServiceIdentifierValues = (serviceIdentifierValues = []) => {
    return {
        type: SET_SELECTED_SERVICE_IDENTIFIER_VALUES,
        payload: serviceIdentifierValues
    };
};