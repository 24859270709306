import {createSelector} from 'reselect';
import * as R from 'ramda';
import localeKeys from './../../locales/keys';
import {
    i18n,
    SelectHelper
} from 'invision-core';

import {
    RETURN_AUTO_ORDERS_FILTER_ID,
    RETURN_MIGRATED_ORDERS_FILTER_ID
} from './../actions/order.history.filter.actions';

const recoverableUIStateSelector = (state) => {
    return state.customercare.recoverableUiState.orderHistory;
};

export const selectedTypeFilterSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.typeFilter;
    }
);

export const selectedStatusFilterSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.statusFilter;
    }
);

export const pageNumberSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.pageNumber;
    }
);

export const SelectedPageSizePreferenceSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.pageSizePreference;
    }
);

export const startDateSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.startDate;
    }
);

export const endDateSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.endDate;
    }
);

export const selectedKindFilterSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.kindFilter;
    }
);
const defaultOrderKindsFiltersSelector = createSelector(
    () => {
        return [
            {
                id: RETURN_AUTO_ORDERS_FILTER_ID,
                value: RETURN_AUTO_ORDERS_FILTER_ID,
                text: i18n.translate(localeKeys.ORDER_HISTORY.FILTERS.RETURN_AUTO_ORDERS_OPTION)
            }, {
                id: RETURN_MIGRATED_ORDERS_FILTER_ID,
                value: RETURN_MIGRATED_ORDERS_FILTER_ID,
                text: i18n.translate(localeKeys.ORDER_HISTORY.FILTERS.RETURN_MIGRATED_ORDERS_OPTION)
            }
        ];
    }
);
export const orderKindsFiltersSelector = createSelector(
    [defaultOrderKindsFiltersSelector, selectedKindFilterSelector],
    (defaultOrderKinds, selectedKindFilters) => {
        return R.sortBy(R.prop('text'))(SelectHelper.mapCodesWithSelectedCodes(defaultOrderKinds, selectedKindFilters));
    }
);

export const autoOrdersFilterSelector = createSelector(
    [orderKindsFiltersSelector],
    (orderKindsFilters) => {
        return orderKindsFilters.find((orderKind) => {
            return orderKind.id === RETURN_AUTO_ORDERS_FILTER_ID;
        }) || null;
    }
);
export const migratedOrdersFilterSelector = createSelector(
    [orderKindsFiltersSelector],
    (orderKindsFilters) => {
        return orderKindsFilters.find((orderKind) => {
            return orderKind.id === RETURN_MIGRATED_ORDERS_FILTER_ID;
        }) || null;
    }
);
