import {ApiConstants, ThunkHelper} from 'invision-core';

export const SEARCH_INTERACTIONS = {
    BEGIN: 'SEARCH_INTERACTIONS.BEGIN',
    FAILURE: 'SEARCH_INTERACTIONS.FAILURE',
    SUCCESS: 'SEARCH_INTERACTIONS.SUCCESS'
};

const SEARCH_INTERACTION_TYPES = [
    SEARCH_INTERACTIONS.BEGIN,
    SEARCH_INTERACTIONS.SUCCESS,
    SEARCH_INTERACTIONS.FAILURE
];

export const RETRIEVE_INTERACTION = {
    BEGIN: 'RETRIEVE_INTERACTION.BEGIN',
    FAILURE: 'RETRIEVE_INTERACTION.FAILURE',
    SUCCESS: 'RETRIEVE_INTERACTION.SUCCESS'
};

const RETRIEVE_INTERACTION_TYPES = [
    RETRIEVE_INTERACTION.BEGIN,
    RETRIEVE_INTERACTION.SUCCESS,
    RETRIEVE_INTERACTION.FAILURE
];

export const SET_CATEGORY_TYPE = 'SET_CATEGORY_TYPE';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_INTERACTION_TYPE = 'SET_INTERACTION_TYPE';
export const SET_SHOW_CHANGED_TYPE = 'SET_INTERACTION_SHOW_CHANGED';
export const SET_INTERACTIONS_SORT_CRITERIA = 'SET_INTERACTIONS_SORT_CRITERIA';

const fetchInteractionsPromise = (dispatch, data) => {
    return ThunkHelper(dispatch, SEARCH_INTERACTION_TYPES, {
        method: 'post',
        url: 'Configuration/SearchInteractions',
        data: data,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: data.customerId
        }
    });
};

export const fetchInteractions = (data) => {
    return (dispatch) => {
        return fetchInteractionsPromise(dispatch, data);
    };
};


const retrieveInteractionPromise = (dispatch, data) => {
    return ThunkHelper(dispatch, RETRIEVE_INTERACTION_TYPES, {
        method: 'post',
        url: 'Configuration/RetrieveInteraction',
        data: {
            Identifier: data.interactionId
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: data.customerId
        }
    });
};

export const retrieveInteraction = (data) => {
    return (dispatch) => {
        return retrieveInteractionPromise(dispatch, data);
    };
};

export const setCurrentPage = (page) => {
    return {
        type: SET_CURRENT_PAGE,
        payload: page
    };
};

export const setSortCriteria = (sortCriteria) => {
    return {
        type: SET_INTERACTIONS_SORT_CRITERIA,
        payload: sortCriteria
    };
};

export const setCategoryType = (categoryType) => {
    return {
        type: SET_CATEGORY_TYPE,
        payload: categoryType
    };
};

export const setInteractionType = (interactionType) => {
    return {
        type: SET_INTERACTION_TYPE,
        payload: interactionType
    };
};

export const setShowChangedProperties = (showChanged) => {
    return {
        type: SET_SHOW_CHANGED_TYPE,
        payload: showChanged
    };
};

export const EMPTY_INTERACTIONS_LIST = 'EMPTY_INTERACTIONS_LIST';
export const emptyInteractionsList = () => {
    return {
        type: EMPTY_INTERACTIONS_LIST
    };
};

export const REMOVE_INTERACTIONS_FILTER = 'REMOVE_INTERACTIONS_FILTER';
export const removeInteractionsFilter = () => {
    return {
        type: REMOVE_INTERACTIONS_FILTER
    };
};

export const SET_INTERACTION_PAGE_SIZE_PREFERENCE = 'SET_INTERACTION_PAGE_SIZE_PREFERENCE';
export const setPageSizePreference = (pageSize) => {
    return {
        type: SET_INTERACTION_PAGE_SIZE_PREFERENCE,
        payload: pageSize
    };
};
