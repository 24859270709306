import pathOr from 'ramda/src/pathOr';
import {EMPTY_ARRAY} from 'invision-core/src/constants/common.constants';

export const getBillerRuleInstanceThumbnailsWithCustomInformation = (updatedOption, existingOption) => {
    updatedOption.BillerRuleInstanceThumbnails.forEach((brit) => {
        const matchingBri = getMatchingOneTimeRecurringOrFinanceBriFromBrcId(updatedOption, brit.BillerRuleConfigurationId);
        if (matchingBri) {
            brit.hasAvailableDiscounts =  !!pathOr(null, ['BillerRuleInstanceDiscounts', 'length'], matchingBri);
            brit.selectedDiscountsInfo = getSelectedDiscountsInfo(updatedOption, existingOption, brit.BillerRuleConfigurationId);
        }
        brit.Status = getBriStatus(updatedOption, brit.BillerRuleConfigurationId);
    });
    return updatedOption.BillerRuleInstanceThumbnails;
};

export const getMatchingOneTimeRecurringOrFinanceBriFromBrcId = (option, brcId) => {
    const oneTimeAndRecurringBris = pathOr([], ['PricingPlanBillerRuleInstances', 'RecurringBillerRuleInstances'], option).concat(
        pathOr([], ['PricingPlanBillerRuleInstances', 'OneTimeBillerRuleInstances'], option).concat(
            pathOr([], ['PricingPlanBillerRuleInstances', 'FinanceBillerRuleInstances'], option)
        )
    );

    return oneTimeAndRecurringBris.find((bri) => {
        return bri.BillerRuleConfigurationId === brcId;
    });
};

const getSelectedDiscountsInfo = (updatedOption, existingOption, brcId) => {
    const allSelectedDiscountsInOption = pathOr(null, ['OfferingDecisionOptionInstances', 0, 'SelectedDiscounts'], updatedOption) ||
        pathOr(null, ['OfferingDecisionOptionInstances', 0, 'SelectedDiscounts'], existingOption) || updatedOption.DefaultSelectedDiscounts || EMPTY_ARRAY;
    return allSelectedDiscountsInOption.filter((discount) => {
        return discount.BillerRuleConfigurationId === brcId;
    }).map((discount) => {
        return Object.assign({}, discount, {
            BillerRuleConfigurationId: brcId
        });
    });
};

const getBriStatus = (option, brcId) => {
    const briDetails = (option.BillerRuleInstanceThumbnails || []).find((bri) => {
        return bri.BillerRuleConfigurationId === brcId;
    });

    return briDetails && briDetails.Status;
};
