import pathOr from 'ramda/src/pathOr';
import Immutable from 'seamless-immutable';
import NavigatorActions from 'invision-core/src/components/navigator/navigator.actions';
import {
    CREATE_CUSTOMER_ORDER_WIZARD,
    GO_TO_NEXT_STEP,
    GO_TO_PREVIOUS_STEP,
    GO_TO_STEP,
    RESTORE_OPTION_DEFAULT_PRICING,
    RETRIEVE_CANDIDATE_BILL_CYCLES,
    RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_ORDER_WIZARD,
    SEND_PORT_IN_OR_ITEM_RESERVATION,
    SET_ATTRIBUTE_SEARCHING_INVENTORY,
    SET_CURRENT_STEP_IS_INVALID,
    SET_EDIT_ATTRIBUTE_GROUPS,
    SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE,
    SET_EDIT_CART_ADDITIONAL_PROPERTY,
    SET_EDIT_OFFER_ADDITIONAL_PROPERTY_ON_CHANGE,
    SET_EDIT_OFFER_ADDITIONAL_PROPERTY,
    SET_EDIT_OPTION,
    SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE,
    SET_EDIT_ORDER_ADDITIONAL_PROPERTY,
    SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS,
    SET_PAYMENT_INFO,
    SET_SELECTED_FACETS_EDIT_COPY,
    SET_SELECTED_NEW_CONNECT_TAB,
    SET_SELECTED_OFFER,
    SET_SELECTED_OFFER_EDIT_COPY,
    SET_SELECTED_OFFERING_CHARGE_TYPES_EDIT_COPY,
    UPDATE_BILL_CYCLE,
    UPDATE_CUSTOMER_ADDRESS_ORDER_WIZARD,
    UPDATE_CUSTOMER_INFO,
    UPDATE_CUSTOMER_INFO_EDIT_COPY,
    UPDATE_CUSTOMER_ORDER_WIZARD,
    UPDATE_EDIT_BRI_PRICE,
    UPDATE_INVENTORY_SELECTION,
    UPDATE_OFFER_ATTRIBUTE,
    UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL,
    UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE,
    UPDATE_PAYMENT_INFO,
    UPDATE_PORT_IN_REQUEST,
    UPDATE_SELECTED_SERVICE_FEATURES,
    UPDATE_SHOPPING_CART
} from './actions/new.connect.wizard.actions';
import {
    SET_EDIT_ADDITIONAL_PROPERTY,
    SET_EDIT_AP_ON_CHANGE
} from './actions/customer.actions';
import {
    mapAdditionalProperty,
    setEditAp,
    convertBillCyclesForDisplay
} from './helpers/customer.helper';
import {INITIAL_STATE as CREATE_CUSTOMER_INITIAL_STATE} from './create.customer.reducer';
import {
    CALCULATE_ORDER_QUOTE,
    RETRIEVE_ATTRIBUTES,
    RETRIEVE_ORDER_QUOTE,
    SEARCH_AVAILABLE_OFFERINGS,
    SEARCH_INVENTORY,
    SET_DELIVERY_DECISION,
    SET_SERVICE_TAX_LOCATION_DECISION
} from './actions/offering.order.actions';
import {ALL_STEPS_KEYS} from './constants/new.connect.wizard.constants';
import createWizardReducers from './helpers/wizard.reducer.helper';
import createOfferOrderingReducers from './helpers/offer.ordering.wizard.reducer.helper';
import {UPDATE_CREDIT_CLASSIFICATION} from './constants/credit.check.constants';

const wizardReducers = createWizardReducers(ALL_STEPS_KEYS);
const offerOrderingReducers = createOfferOrderingReducers();
const EMPTY_ARRAY = Immutable([]);

export const DEFAULT_CART = {
    billCycle: null,
    convergentBillerAccounts: null,
    customer: null,
    customerInfo: CREATE_CUSTOMER_INITIAL_STATE,
    editAttributeGroups: null,
    editOptions: null,
    editPhysicalAttributeGroups: null,
    isCreatingOrUpdatingCustomer: false,
    isFetchingBillCycles: false,
    isFetchingData: false,
    lastAttemptError: null,
    paymentInfo: null,
    selectedEditOption: null,
    selectedFacetIds: [],
    selectedOfferId: null,
    selectedOfferingChargeTypeIds: []
};

export const INITIAL_STATE = wizardReducers.initialState.merge(Immutable({
    cartAdditionalProperties: EMPTY_ARRAY,
    cart: DEFAULT_CART,
    editCart: DEFAULT_CART,
    isCalculatingQuote: false,
    offerAdditionalProperties: EMPTY_ARRAY,
    orderAdditionalProperties: EMPTY_ARRAY,
    quote: null
}));

export default function wizardReducer(state = INITIAL_STATE, {payload, type}) {
    switch (type) {
        case CALCULATE_ORDER_QUOTE.BEGIN:
            return state.set('isCalculatingQuote', true);
        case CALCULATE_ORDER_QUOTE.FAILURE:
            return state.set('isCalculatingQuote', false);
        case RETRIEVE_ORDER_QUOTE.SUCCESS:
        case CALCULATE_ORDER_QUOTE.SUCCESS:
            return state
                .set('quote', payload.Quote)
                .set('shippingAddress', payload.ShippingAddress)
                .set('financeCreditLimitValidation', payload.FinanceCreditLimitValidation)
                .set('isCalculatingQuote', false);
        case GO_TO_NEXT_STEP:
            return wizardReducers.goToNextStep(state, payload)
                .setIn(['editCart', 'selectedFacetIds'], DEFAULT_CART.selectedFacetIds)
                .setIn(['editCart', 'selectedOfferingChargeTypeIds'], DEFAULT_CART.selectedOfferingChargeTypeIds);
        case GO_TO_PREVIOUS_STEP:
            return wizardReducers.goToPreviousStep(state, payload)
                .setIn(['editCart', 'selectedFacetIds'], DEFAULT_CART.selectedFacetIds)
                .setIn(['editCart', 'selectedOfferingChargeTypeIds'], DEFAULT_CART.selectedOfferingChargeTypeIds);
        case GO_TO_STEP:
            return wizardReducers.goToStep(state, payload)
                .setIn(['editCart', 'selectedFacetIds'], DEFAULT_CART.selectedFacetIds)
                .setIn(['editCart', 'selectedOfferingChargeTypeIds'], DEFAULT_CART.selectedOfferingChargeTypeIds);
        case SET_CURRENT_STEP_IS_INVALID:
            return wizardReducers.setCurrentStepIsInvalid(state, payload);
        case SET_SELECTED_NEW_CONNECT_TAB:
            return wizardReducers.setSelectedOrderTab(state, payload);
        case RETRIEVE_ATTRIBUTES.SUCCESS:
            return state
                .setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.setEditAttributeGroups(payload.Context.ValueDecisions, pathOr(null, ['editCart', 'editAttributeGroups'], state)))
                .setIn(['editCart', 'deliveryDecisions'], payload.Context.DeliveryDecisions)
                .setIn(['editCart', 'serviceTaxDecisions'], payload.Context.ServiceTaxCustomizationDecisions);
        case UPDATE_CUSTOMER_INFO:
            return state.merge({
                cart: {
                    customerInfo: payload
                }
            }, {
                deep: true
            });
        case UPDATE_CUSTOMER_INFO_EDIT_COPY:
            return state.merge({
                editCart: {
                    customerInfo: payload
                }
            }, {
                deep: true
            });
        case SEARCH_AVAILABLE_OFFERINGS.FAILURE:
            return state.merge({
                currentStepIsInvalid: true
            }, {
                deep: true
            });
        case SET_DELIVERY_DECISION:
            return state
                .setIn(['editCart', 'deliveryDecisions'], offerOrderingReducers.updateDeliveryDecision(state, payload));
        case SET_SERVICE_TAX_LOCATION_DECISION:
            return state
                .setIn(['editCart', 'serviceTaxDecisions'], offerOrderingReducers.updateserviceTaxLocationDecision(state, payload));
        case SET_SELECTED_OFFER:
        {
            const nextState = offerOrderingReducers.clearStateFromOfferSelection(state, payload, DEFAULT_CART);
            return nextState
                .setIn(['cart', 'selectedOfferId'], payload)
                .set('editCart', Object.assign({}, DEFAULT_CART, {
                    customerInfo: state.editCart.customerInfo,
                    customer: state.editCart.customer
                }));
        }
        case SET_SELECTED_OFFER_EDIT_COPY:
        {
            const nextState = offerOrderingReducers.clearStateFromOfferSelection(state, payload, DEFAULT_CART);
            return nextState.setIn(['editCart', 'selectedOfferId'], payload);
        }
        case SET_SELECTED_OFFERING_CHARGE_TYPES_EDIT_COPY:
            return offerOrderingReducers.setSelectedOfferingChargeTypesEditCopy(state, payload);
        case SET_SELECTED_FACETS_EDIT_COPY:
            return offerOrderingReducers.setSelectedFacetsEditCopy(state, payload);
        case SET_EDIT_OPTION:
            return offerOrderingReducers.setEditOption(state, payload);
        case RESTORE_OPTION_DEFAULT_PRICING:
            return offerOrderingReducers.restoreDefaultOptionPricing(state, payload);
        case UPDATE_EDIT_BRI_PRICE:
            return offerOrderingReducers.updateEditOptionPricing(state, payload);
        case SET_EDIT_ATTRIBUTE_GROUPS:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.transformAttributeGroups(payload));
        case SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS:
            return state.setIn(['editCart', 'editPhysicalAttributeGroups'], !payload.formPhysicalAttributeGroups ? payload.physicalAttributeGroups : offerOrderingReducers.mergePhysicalAttributeGroups(payload.physicalAttributeGroups, payload.formPhysicalAttributeGroups));
        case UPDATE_OFFER_ATTRIBUTE:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.updateAttributeGroups(state.editCart.editAttributeGroups, payload.attributeId, payload.formValue));
        case UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL:
            return state.setIn(['editCart', 'editPhysicalAttributeGroups'], offerOrderingReducers.updatePhysicalInventoryMakeAndModel(state.editCart.editPhysicalAttributeGroups, payload));
        case UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE:
            return state.setIn(['editCart', 'editPhysicalAttributeGroups'], offerOrderingReducers.updatePhysicalInventoryTypeAttribute(state.editCart.editPhysicalAttributeGroups, payload));
        case UPDATE_INVENTORY_SELECTION:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.updateInventorySelection(state.editCart.editAttributeGroups, payload));
        case UPDATE_SELECTED_SERVICE_FEATURES:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.updateSelectedServiceFeatures(state.editCart.editAttributeGroups, payload));
        case SET_ATTRIBUTE_SEARCHING_INVENTORY:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.setAttributeInventorySearching(state.editCart.editAttributeGroups, payload));
        case SEARCH_INVENTORY.SUCCESS:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.updateAttributeInventoryOptions(state.editCart.editAttributeGroups, payload.InventoryItems));
        case SEARCH_INVENTORY.FAILURE:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.clearAttributeInventorySearching(state.editCart.editAttributeGroups));
        case SEND_PORT_IN_OR_ITEM_RESERVATION:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.sendPortInOrItemReservation(state.editCart.editAttributeGroups, payload.attributeId, payload.sendPortInReservation));
        case SET_PAYMENT_INFO:
            return offerOrderingReducers.setPaymentInfo(state, payload);
        case UPDATE_BILL_CYCLE:
            return offerOrderingReducers.updateBillCycle(state, payload);
        case UPDATE_PAYMENT_INFO:
            return offerOrderingReducers.updatePaymentInfo(state, payload);
        case UPDATE_PORT_IN_REQUEST:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.updateAttributePortInRequest(
                state.editCart.editAttributeGroups,
                payload.attributeId,
                payload.portInData,
                payload.token,
                payload.address));
        case NavigatorActions.STATE_CHANGE_SUCCESS:
            return INITIAL_STATE;
        case RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_ORDER_WIZARD.BEGIN:
            return state.setIn(['editCart', 'isFetchingData'], true);
        case RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_ORDER_WIZARD.SUCCESS:
            return state
                .setIn(['editCart', 'isFetchingData'], false)
                .setIn(['editCart', 'lastAttemptError'], null)
                .setIn(['editCart', 'convergentBillerAccounts'], payload.SubscriberSummary.AccountSummaries);
        case RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_ORDER_WIZARD.FAILURE:
            return state
                .setIn(['editCart', 'isFetchingData'], false)
                .setIn(['editCart', 'lastAttemptError'], {
                    code: payload.Code, //IDPH
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case UPDATE_CUSTOMER_ORDER_WIZARD.BEGIN:
            return state.setIn(['editCart', 'isCreatingOrUpdatingCustomer'], true);
        case UPDATE_CUSTOMER_ORDER_WIZARD.SUCCESS:
            return state
                .setIn(['editCart', 'isCreatingOrUpdatingCustomer'], false)
                .setIn(['editCart', 'lastAttemptError'], null)
                .setIn(['editCart', 'customer', 'Subscriber'], payload.Subscriber);
        case UPDATE_CUSTOMER_ORDER_WIZARD.FAILURE:
            return state
                .setIn(['editCart', 'isCreatingOrUpdatingCustomer'], false)
                .setIn(['editCart', 'lastAttemptError'], {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case UPDATE_CREDIT_CLASSIFICATION.BEGIN:
            return state
                .setIn(['editCart', 'isCreatingOrUpdatingCustomer'], true);
        case UPDATE_CREDIT_CLASSIFICATION.SUCCESS:
            return state
                .setIn(['editCart', 'isCreatingOrUpdatingCustomer'], false)
                .setIn(['editCart', 'lastAttemptError'], null)
                .setIn(['editCart', 'customer'], {
                    Subscriber: payload.Subscriber,
                    Addresses: [payload.Address]
                });
        case UPDATE_CREDIT_CLASSIFICATION.FAILURE:
            return state
                .setIn(['editCart', 'isCreatingOrUpdatingCustomer'], false)
                .setIn(['editCart', 'lastAttemptError'], {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case CREATE_CUSTOMER_ORDER_WIZARD.BEGIN:
            return state.setIn(['editCart', 'isCreatingOrUpdatingCustomer'], true);
        case CREATE_CUSTOMER_ORDER_WIZARD.SUCCESS:
            return state
                .setIn(['editCart', 'isCreatingOrUpdatingCustomer'], false)
                .setIn(['editCart', 'lastAttemptError'], null)
                .setIn(['editCart', 'customer'], payload); // TODO: There are differences in the responses of CreateCustomer & UpdateCreditClassification. Determine if there might be any conflict or data override scenario.
        case CREATE_CUSTOMER_ORDER_WIZARD.FAILURE:
            return state
                .setIn(['editCart', 'isCreatingOrUpdatingCustomer'], false)
                .setIn(['editCart', 'lastAttemptError'], {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case UPDATE_CUSTOMER_ADDRESS_ORDER_WIZARD.BEGIN:
            return state.setIn(['editCart', 'isCreatingOrUpdatingCustomer'], true);
        case UPDATE_CUSTOMER_ADDRESS_ORDER_WIZARD.SUCCESS:
            return state
                .setIn(['editCart', 'isCreatingOrUpdatingCustomer'], false)
                .setIn(['editCart', 'lastAttemptError'], null)
                .setIn(['editCart', 'customer', 'Addresses'], [payload.Address]);
        case UPDATE_CUSTOMER_ADDRESS_ORDER_WIZARD.FAILURE:
            return state
                .setIn(['editCart', 'isCreatingOrUpdatingCustomer'], false)
                .setIn(['editCart', 'lastAttemptError'], {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case SET_EDIT_ADDITIONAL_PROPERTY:
            return state
                .setIn(['editCart', 'customerInfo', 'setEditAdditionalProp'], mapAdditionalProperty(payload));
        case SET_EDIT_AP_ON_CHANGE:
            return state
                .setIn(['editCart', 'customerInfo', 'setEditAdditionalProp'], setEditAp(payload, state.editCart.customerInfo.setEditAdditionalProp));
        case UPDATE_SHOPPING_CART.BEGIN:
            return state.setIn(['editCart', 'isCreatingOrUpdatingCustomer'], true);
        case UPDATE_SHOPPING_CART.SUCCESS:
            return state
                .setIn(['editCart', 'isCreatingOrUpdatingCustomer'], false)
                .setIn(['editCart', 'customerInfo', 'shoppingCartError'], null)
                .setIn(['editCart', 'customerInfo', 'shoppingCart'], payload.ShoppingCart);
        case UPDATE_SHOPPING_CART.FAILURE:
            return state
                .setIn(['editCart', 'isCreatingOrUpdatingCustomer'], false)
                .setIn(['editCart', 'customerInfo', 'shoppingCartError'], {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case RETRIEVE_CANDIDATE_BILL_CYCLES.BEGIN:
            return state
                .setIn(['editCart', 'isFetchingBillCycles'], true);
        case RETRIEVE_CANDIDATE_BILL_CYCLES.SUCCESS:
            return state
                .setIn(['editCart', 'isFetchingBillCycles'], false)
                .setIn(['editCart', 'customerInfo', 'candidateBillCycles'], convertBillCyclesForDisplay(payload.BillCycles));
        case RETRIEVE_CANDIDATE_BILL_CYCLES.FAILURE:
            return state
                .setIn(['editCart', 'isFetchingBillCycles'], false)
                .setIn(['editCart', 'customerInfo', 'candidateBillCycles'], []);
        case SET_EDIT_CART_ADDITIONAL_PROPERTY:
            return state.set('cartAdditionalProperties', mapAdditionalProperty(payload));
        case SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE:
            return state.set('cartAdditionalProperties', setEditAp(payload, state.cartAdditionalProperties || EMPTY_ARRAY));
        case SET_EDIT_ORDER_ADDITIONAL_PROPERTY:
            return state.set('orderAdditionalProperties', mapAdditionalProperty(payload));
        case SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE:
            return state.set('orderAdditionalProperties', setEditAp(payload, state.orderAdditionalProperties || EMPTY_ARRAY));
        case SET_EDIT_OFFER_ADDITIONAL_PROPERTY:
            return state.set('offerAdditionalProperties', mapAdditionalProperty(payload));
        case SET_EDIT_OFFER_ADDITIONAL_PROPERTY_ON_CHANGE:
            return state.set('offerAdditionalProperties', setEditAp(payload, state.offerAdditionalProperties || EMPTY_ARRAY));
        default:
            return state;
    }
}
