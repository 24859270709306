import i18n from 'invision-core/src/components/i18n/i18n';
import LocaleKeys from '../locales/keys';

import clone from 'ramda/src/clone';
import contains from 'ramda/src/includes';
import defaultTo from 'ramda/src/defaultTo';
import filter from 'ramda/src/filter';
import find from 'ramda/src/find';
import map from 'ramda/src/map';
import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';
import propEq from 'ramda/src/propEq';
import without from 'ramda/src/without';

import Immutable from 'seamless-immutable';

import {translateFaultCode} from 'invision-core/src/api/api.fault.helper';

import {
    CALCULATE_COS_ORDER_QUOTE_FOR_SERVICE_FEATURES,
    CALCULATE_MODIFY_PRODUCT_ORDER_QUOTE,
    CALCULATE_PRODUCT_ORDER_QUOTE,
    CALCULATE_RENEW_ORDER_QUOTE,
    CLEAR_CART_SUMMARY_FOR_WAIVE_CHARGE,
    CLEAR_PRODUCT_ORDER_QUOTE,
    CLEAR_PRODUCTS_METADATA,
    CLEAR_SAVED_SEASONS_SELECTIONS,
    CLEAR_SELECTED_OPTIONS,
    CLEAR_SHOPPING_CART_ERROR,
    FETCH_PRODUCT_ORDER_SERVICE_IDENTIFIER,
    GET_PRODUCT_METADATA_BATCH,
    GET_PRODUCT_METADATA_EVENT_TYPES,
    LOAD_ADDITIONAL_EPISODES,
    NAVIGATE_TO_CART,
    PRODUCT_ORDER_RETRIEVE_ORDER_CONTEXT,
    RETRIEVE_ADDRESSES_CONSTANTS,
    RETRIEVE_ORDER_QUOTE_PRODUCT_ORDER,
    RETRIEVE_PRODUCT_CONTEXT,
    RETRIEVE_PRODUCTS_CONTEXT,
    RETRIEVE_PRODUCTS_CONTEXT_AND_METADATA_CONSTANTS,
    RETRIEVE_PRODUCTS_WITH_INFO_CONSTANTS,
    SAVE_SERIES_CONTAINER_SEASON_SELECTIONS,
    SEARCH_AVAILABLE_PRODUCTS,
    SET_EPISODES,
    SET_OPTIONAL_PRODUCTS,
    SET_PICKLIST_PRODUCTS,
    SET_PREVIOUSLY_SELECTED_PAYMENT_INSTRUMENT_IDS,
    SET_PRODUCT_ORDER_COUPON_CODES,
    SET_PRODUCT_ORDER_DISCRETIONARY_DISCOUNT,
    SET_PRODUCT_ORDER_INVENTORY_REGION_FILTER,
    SET_PRODUCT_ORDER_SELECTED_INVENTORY_STORES,
    SET_REPLACE_ITEM,
    SET_REPLACEMENT_ITEM,
    SET_SELECTED_CUSTOMER_ADDRESS_FOR_PRODUCT_ORDER,
    SET_SELECTED_PRODUCT_ID,
    SET_SELECTED_PRODUCTS_SEARCH_OPTION,
    SET_SELECTED_SERIES_CONTAINER_INCLUDED_SEASONS_OPTIONS,
    SET_SELECTED_SERIES_CONTAINER_PRODUCT_ID,
    SET_SELECTED_SERVICE_IDENTIFIER_FOR_PRODUCT_ORDER,
    SET_SHOPPING_CART_FROM_QUOTE_DATA,
    STORE_MODIFY_REQUEST_DATA,
    SUBMIT_CHANGE_OF_SERVICE_FOR_SERVICE_FEATURES,
    SUBMIT_GIFT_PRODUCT_ORDER,
    SUBMIT_MODIFY_PRODUCT_ORDER,
    SUBMIT_PRODUCT_ORDER,
    SUBMIT_RESTORE_ORDER,
    UPDATE_SEARCH_CATALOG_PARAMS
} from './actions/products.order.actions';
import {
    CANCEL_ORDER,
    INITIALIZE_PRODUCT_ORDER,
    SET_SHOPPING_CART_ITEM_INDEX,
    UPDATE_SERVICE_FEATURE_QUANTITY,
    UPDATE_SHOPPING_CART
} from './actions/products.wizard.actions';
import {getFormattedInstancePropertiesForUI} from './helpers/instance.property.helper';
import {camelCaseKeys} from '../utilities/object.formatting.helper';
import {
    PRODUCTS_SORT_OPTIONS,
    SEARCH_CATALOG_ENTITY_TYPES
} from '../reducers/constants/products.wizard.constants';
import {prepareSubscriptionQuotes} from './products.order.reducer.helper';
import {EMPTY_ARRAY} from './constants/common.constants';

const INVALID_COUPON_ERROR_CODE = 1038;

const EMPTY_OBJECT = Immutable({});

const SEARCH_CATALOG_EMPTY_STATE = {
    Headers: {
        CustomerId: null
    },

    PageNumber: 1,
    PageSize: null, //should come from prefs
    ProductFilter: {
        CouponCode: '',
        CategoriesAsFacets: false,
        Subscription: false,
        StructureTypes: [],
        Categories: [],
        Facets: []
    },
    ProductSort: {
        SortBy: PRODUCTS_SORT_OPTIONS[0].value,
        SortDirection: PRODUCTS_SORT_OPTIONS[0].direction
    },
    SearchEntities: [SEARCH_CATALOG_ENTITY_TYPES.PRODUCT],
    SearchString: ''
};

export const INITIAL_STATE = Immutable({
    billingEffectiveDateConfiguration: EMPTY_OBJECT,
    isCalculatingQuote: false,
    isCreatingOrUpdatingShoppingCart: false,
    isFetchingBatchMetadata: false,
    isFetchingData: false,
    isFetchingDataAndProductContext: false,
    isFetchingOrderableProductData: false,
    isFetchingProductContext: false,
    isFetchingSingleProductContext: false,
    isSubmittingOrder: false,
    lastAttemptError: null,
    data: {
        availableProducts: {
            data: {
                productsMap: {},
                productsDisplayOrder: [],
                pageNumber: 0,
                recordCount: 0,
                searchString: null,
                selectedProductId: null,
                episodes: {},
                optionalProducts: {},
                picklistProducts: {}
            }
        },
        cartItemDetails: [],
        couponCodes: [],
        createdOrder: {},
        customerAddressesData: {
            customerAddressesList: EMPTY_ARRAY,
            selectedCustomerAddress: null
        },
        discretionaryDiscounts: {
            selectedIds: null,
            reason: null
        },
        invalidItems: [],
        inventoryStore: {
            isStoreChanged: null,
            regionFilter: null,
            selectedStore: null
        },
        serviceIdentifiersData: {
            selectedServiceIdentifier: null,
            serviceIdentifiersList: EMPTY_ARRAY
        },
        orderQuotePaymentInstruments: [],
        previouslySelectedPaymentInstrumentIds: [],
        productsSortOptions: PRODUCTS_SORT_OPTIONS,
        productsWithMetadata: EMPTY_OBJECT,
        quote: {
            currency: '',
            discountAmount: 0,
            Items: [],
            orderQuoteTotals: EMPTY_ARRAY,
            quoteIsValid: false,
            shippingAmount: 0,
            ShippingMethods: [],
            subTotal: 0,
            taxAmount: 0,
            taxInclusive: false,
            taxItems: [],
            totalAmount: 0,
            totalRemainingAmount: 0
        },
        replaceItem: null,
        replacementItem: null,
        searchCatalogParams: SEARCH_CATALOG_EMPTY_STATE,
        selectedCartItemIndex: null,
        selectedProductsSortOption: PRODUCTS_SORT_OPTIONS[0],
        selectedSeriesContainer: {
            productId: null,
            savedOtherSeasonSelectionsMap: {},
            selectSeasonOptions: []
        },
        serviceFeatureQuote: {
            AddItems: [],
            RemoveItems: []
        },
        shoppingCart: {
            Items: [],
            AppliedCoupons: null
        },
        shoppingCartError: null,
        shoppingCartIsDirty: false,
        shoppingCartInvalidCoupons: [],
        shoppingCartInvalidRedemptionCodes: [],
        shoppingCartProductsMetadata: {},
        skipToCheckout: false,
        subscriptionShoppingCart: {
            modifyRequestObject: {
                AddItems: [],
                ChangeItems: [],
                RemoveItems: [],
                ReplaceItems: []
            }
        },
        subscriptionQuotes: null
    }
});

const defaultTo0 = defaultTo(0);
const defaultTo1 = defaultTo(1);

export default function ProductsListReducer(state = INITIAL_STATE, {payload = {}, type, requestObject}) {
    switch (type) {
        case INITIALIZE_PRODUCT_ORDER:
        case CANCEL_ORDER:
            return INITIAL_STATE;
        case CALCULATE_MODIFY_PRODUCT_ORDER_QUOTE.BEGIN:
        case CALCULATE_PRODUCT_ORDER_QUOTE.BEGIN:
        case CALCULATE_RENEW_ORDER_QUOTE.BEGIN:
        case CALCULATE_COS_ORDER_QUOTE_FOR_SERVICE_FEATURES.BEGIN:
            return state.set('isCalculatingQuote', true);
        case SET_SHOPPING_CART_FROM_QUOTE_DATA:
            return state.setIn(['data', 'shoppingCart', 'Items'], transformQuoteItemsToShoppingCartStructure(payload.Quote.Items, requestObject));
        case UPDATE_SERVICE_FEATURE_QUANTITY:
            return state.setIn(['data', 'serviceFeatureQuote'], payload);
        case STORE_MODIFY_REQUEST_DATA:
            return state.setIn(['data', 'subscriptionShoppingCart', 'modifyRequestObject', 'AddItems'], requestObject.AddItems)
                .setIn(['data', 'subscriptionShoppingCart', 'modifyRequestObject', 'ChangeItems'], requestObject.ChangeItems)
                .setIn(['data', 'subscriptionShoppingCart', 'modifyRequestObject', 'RemoveItems'], requestObject.RemoveItems)
                .setIn(['data', 'subscriptionShoppingCart', 'modifyRequestObject', 'ReplaceItems'], requestObject.ReplaceItems);
        case CALCULATE_MODIFY_PRODUCT_ORDER_QUOTE.SUCCESS:
        case CALCULATE_PRODUCT_ORDER_QUOTE.SUCCESS:
        case CALCULATE_RENEW_ORDER_QUOTE.SUCCESS:
        case RETRIEVE_ORDER_QUOTE_PRODUCT_ORDER.SUCCESS:
        case CALCULATE_COS_ORDER_QUOTE_FOR_SERVICE_FEATURES.SUCCESS: {
            const payloadQuote = payload && payload.Quote || EMPTY_OBJECT;
            const payloadQuoteTotals = payloadQuote && payloadQuote.Totals || EMPTY_OBJECT;
            return state
                .setIn(['data', 'quote', 'quoteIsValid'], true)
                .setIn(['data', 'orderQuotePaymentInstruments'], payload.PaymentInstruments)
                .setIn(['data', 'quote', 'appliedCoupons'], payloadQuote.AppliedCoupons)
                .setIn(['data', 'quote', 'currency'], payloadQuote.Currency)
                .setIn(['data', 'quote', 'discountAmount'], payloadQuoteTotals.DiscountAmount)
                .setIn(['data', 'quote', 'Items'], payloadQuote.Items && prepareQuoteItems(payloadQuote.Items))
                .setIn(['data', 'quote', 'orderQuoteTotals'], payloadQuote.OrderQuoteTotals || EMPTY_ARRAY)
                .setIn(['data', 'quote', 'shippingAmount'], payloadQuoteTotals.ShippingAmount || 0)
                .setIn(['data', 'quote', 'ShippingMethods'], payload.ShippingMethods || [])
                .setIn(['data', 'quote', 'subTotal'], payloadQuoteTotals.SubTotalAmount)
                .setIn(['data', 'quote', 'taxAmount'], payloadQuoteTotals.TaxAmount)
                .setIn(['data', 'quote', 'taxInclusive'], (payloadQuote.TaxInclusive === true))
                .setIn(['data', 'quote', 'taxItems'], payloadQuote.TaxItems)
                .setIn(['data', 'quote', 'totalAmount'], payloadQuoteTotals.TotalAmount)
                .setIn(['data', 'subscriptionQuotes'], payload.SubscriptionQuotes && prepareSubscriptionQuotes(payload.SubscriptionQuotes) || null)
                .setIn(['data', 'quote', 'totalRemainingAmount'], payload.TotalRemainingAmount)
                .setIn(['data', 'couponCodes'], prepareCouponCodes(state.data.couponCodes, payloadQuote.AppliedCoupons, payload.InvalidCouponRedemptions))
                .setIn(['data', 'shoppingCartInvalidRedemptionCodes'], payload.InvalidRedemptionCodes || [])
                .setIn(['data', 'shoppingCartInvalidCoupons'], payload.InvalidCouponRedemptions || [])
                .set('isCalculatingQuote', false)
                .set('asyncId', payload.QuoteId || null)
                .set('asyncStatus', payload.Status !== undefined ? payload.Status : null);
        }
        case CALCULATE_MODIFY_PRODUCT_ORDER_QUOTE.FAILURE:
        case CALCULATE_PRODUCT_ORDER_QUOTE.FAILURE:
        case CALCULATE_RENEW_ORDER_QUOTE.FAILURE:
        case RETRIEVE_ORDER_QUOTE_PRODUCT_ORDER.FAILURE:
        case CALCULATE_COS_ORDER_QUOTE_FOR_SERVICE_FEATURES.FAILURE:
            return state
                .setIn(['data', 'quote', 'quoteIsValid'], false)
                .set('isCalculatingQuote', false);
        case CLEAR_PRODUCT_ORDER_QUOTE:
            return state
                .setIn(['data', 'quote'], INITIAL_STATE.data.quote)
                .setIn(['data', 'subscriptionQuotes'], INITIAL_STATE.data.subscriptionQuotes)
                .set('isCalculatingQuote', false);
        case CLEAR_SELECTED_OPTIONS:
            return state.setIn(['data', 'availableProducts', 'data', 'episodes'], {})
                .setIn(['data', 'availableProducts', 'data', 'optionalProducts'], {})
                .setIn(['data', 'availableProducts', 'data', 'picklistProducts'], {});
        case GET_PRODUCT_METADATA_BATCH.BEGIN:
            return state.set('isFetchingBatchMetadata', true);
        case GET_PRODUCT_METADATA_BATCH.SUCCESS:
        case GET_PRODUCT_METADATA_BATCH.FAILURE:
            return state.set('isFetchingBatchMetadata', false);
        case NAVIGATE_TO_CART:
            return state.setIn(['data', 'skipToCheckout'], true);
        case SEARCH_AVAILABLE_PRODUCTS.BEGIN:
            return state.merge({
                isFetchingData: true
            });
        case SEARCH_AVAILABLE_PRODUCTS.SUCCESS:
            return onSearchAvailableProductsSuccess(state, requestObject, payload);
        case SEARCH_AVAILABLE_PRODUCTS.FAILURE:
            if (payload.Code === INVALID_COUPON_ERROR_CODE) {
                return onSearchAvailableProductsSuccess(state, requestObject, {
                    Products: [],
                    SearchResults: [],
                    RecordCount: 0
                });
            }
            return state
                .set('isFetchingData', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case RETRIEVE_PRODUCTS_CONTEXT.BEGIN:
            return state.merge({
                isFetchingProductContext: true
            });
        case RETRIEVE_PRODUCTS_CONTEXT.SUCCESS:
            return state
                .set('isFetchingProductContext', false)
                .set('lastAttemptError', null)
                .setIn(['data', 'availableProducts', 'data', 'productsMap'], populateProductsWithContext(state.data.availableProducts.data.productsMap, payload));
        case RETRIEVE_PRODUCTS_CONTEXT.FAILURE:
            return state
                .set('isFetchingProductContext', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case RETRIEVE_PRODUCT_CONTEXT.BEGIN: //Singular product context
        case LOAD_ADDITIONAL_EPISODES.BEGIN:
            return state.merge({
                isFetchingSingleProductContext: true
            });
        case RETRIEVE_PRODUCT_CONTEXT.SUCCESS:
            return state
                .set('isFetchingSingleProductContext', false)
                .set('lastAttemptError', null)
                .setIn(['data', 'availableProducts', 'data', 'productsMap', `${payload.ProductContext.ProductId}`],
                    populateSingleProductContext(payload.ProductContext.ProductId,
                        state.data.availableProducts.data.productsMap,
                        state.data.shoppingCart.Items,
                        payload));
        case PRODUCT_ORDER_RETRIEVE_ORDER_CONTEXT.SUCCESS:
            return state
                .set('billingEffectiveDateConfiguration', payload.BillingEffectiveDateConfiguration);
        case LOAD_ADDITIONAL_EPISODES.SUCCESS: {
            const currentEpisodes = state.data.availableProducts.data.productsMap[payload.ProductContext.ProductId].ProductContext.EpisodicContext;
            return state
                .set('isFetchingSingleProductContext', false)
                .set('lastAttemptError', null)
                .setIn(['data', 'productsWithMetadata', `${payload.ProductContext.ProductId}`, 'ProductContext', 'EpisodicContext'],
                    currentEpisodes.concat(payload.ProductContext.EpisodicContext));
        }
        case RETRIEVE_PRODUCT_CONTEXT.FAILURE:
        case LOAD_ADDITIONAL_EPISODES.FAILURE:
            return state
                .set('isFetchingSingleProductContext', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case GET_PRODUCT_METADATA_EVENT_TYPES.SUCCESS:
            return state
                .set('isFetchingProductContext', false)
                .set('lastAttemptError', null)
                .setIn(['data', 'productsWithMetadata', payload.Product.Id], state.data.availableProducts.data.productsMap[payload.Product.Id] ?
                    combineMetadataIntoSelectedProductContext(state.data.availableProducts.data.productsMap[payload.Product.Id], payload.Product)
                    : payload.Product
                );
        case GET_PRODUCT_METADATA_EVENT_TYPES.FAILURE:
            return state
                .set('isFetchingProductContext', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case RETRIEVE_PRODUCTS_WITH_INFO_CONSTANTS.BEGIN:
            return state.set('isFetchingDataAndProductContext', true);
        case RETRIEVE_PRODUCTS_WITH_INFO_CONSTANTS.SUCCESS:
        case RETRIEVE_PRODUCTS_WITH_INFO_CONSTANTS.FAILURE:
            return state.set('isFetchingDataAndProductContext', false);
        case RETRIEVE_PRODUCTS_CONTEXT_AND_METADATA_CONSTANTS.BEGIN:
            return state.set('isFetchingOrderableProductData', true)
                .setIn(['data', 'shoppingCartError'], null);
        case RETRIEVE_PRODUCTS_CONTEXT_AND_METADATA_CONSTANTS.SUCCESS:
        case RETRIEVE_PRODUCTS_CONTEXT_AND_METADATA_CONSTANTS.FAILURE:
            return state.set('isFetchingOrderableProductData', false);
        case SET_PRODUCT_ORDER_DISCRETIONARY_DISCOUNT:
            return state.setIn(['data', 'discretionaryDiscounts'], payload);
        case SET_PRODUCT_ORDER_COUPON_CODES:
            return state.setIn(['data', 'couponCodes'], payload);
        case SET_SHOPPING_CART_ITEM_INDEX:
            return state.setIn(['data', 'selectedCartItemIndex'], payload);
        case SET_REPLACE_ITEM:
            return state.setIn(['data', 'replaceItem'], payload);
        case SET_REPLACEMENT_ITEM:
            return state.setIn(['data', 'replacementItem'], payload);
        case UPDATE_SEARCH_CATALOG_PARAMS:
            return state.setIn(['data', 'searchCatalogParams'], payload);
        case UPDATE_SHOPPING_CART.BEGIN:
            return state.set('isCreatingOrUpdatingShoppingCart', true);
        case UPDATE_SHOPPING_CART.SUCCESS:
            return state
                .set('isCreatingOrUpdatingShoppingCart', false)
                .setIn(['data', 'cartItemDetails'], saveShoppingCartItemDetails(payload.ShoppingCart, state.data.cartItemDetails))
                .setIn(['data', 'shoppingCartError'], null)
                .setIn(['data', 'shoppingCartIsDirty'], true)
                .setIn(['data', 'shoppingCart'], payload.ShoppingCart)
                .setIn(['data', 'couponCodes'], prepareCouponCodes(state.data.couponCodes, payload.ShoppingCart.AppliedCoupons, payload.InvalidCouponRedemptions))
                .setIn(['data', 'shoppingCartInvalidRedemptionCodes'], payload.InvalidRedemptionCodes || [])
                .setIn(['data', 'shoppingCartInvalidCoupons'], payload.InvalidCouponRedemptions || [])
                .setIn(['data', 'shoppingCartProductsMetadata'], getUpdatedShoppingCartProductsMetadata(state.data.productsWithMetadata, state.data.shoppingCartProductsMetadata, payload.ShoppingCart))
                .setIn(['data', 'invalidItems'], prepareInvalidItems(state.data.productsWithMetadata, payload.InvalidItems));
        case UPDATE_SHOPPING_CART.FAILURE:
            return state
                .set('isCreatingOrUpdatingShoppingCart', false)
                .setIn(['data', 'shoppingCartError'], {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case CLEAR_SHOPPING_CART_ERROR:
            return state.setIn(['data', 'shoppingCartError'], null);
        case SUBMIT_PRODUCT_ORDER.BEGIN:
        case SUBMIT_MODIFY_PRODUCT_ORDER.BEGIN:
        case SUBMIT_GIFT_PRODUCT_ORDER.BEGIN:
        case SUBMIT_RESTORE_ORDER.BEGIN:
        case SUBMIT_CHANGE_OF_SERVICE_FOR_SERVICE_FEATURES.BEGIN:
            return state.set('isSubmittingOrder', true)
                .set('lastAttemptError', null);
        case SUBMIT_PRODUCT_ORDER.SUCCESS:
        case SUBMIT_GIFT_PRODUCT_ORDER.SUCCESS:
        case SUBMIT_RESTORE_ORDER.SUCCESS:
        case SUBMIT_CHANGE_OF_SERVICE_FOR_SERVICE_FEATURES.SUCCESS:
            return state
                .set('isSubmittingOrder', false)
                .setIn(['data', 'createdOrder'], payload.Order);
        case SUBMIT_MODIFY_PRODUCT_ORDER.SUCCESS:
            return state
                .set('isSubmittingOrder', false)
                .setIn(['data', 'createdOrder'], payload.Order)
                .setIn(['data', 'removedOrder'], payload.RemoveOrder);
        case SUBMIT_PRODUCT_ORDER.FAILURE:
        case SUBMIT_MODIFY_PRODUCT_ORDER.FAILURE:
        case SUBMIT_GIFT_PRODUCT_ORDER.FAILURE:
        case SUBMIT_RESTORE_ORDER.FAILURE:
        case SUBMIT_CHANGE_OF_SERVICE_FOR_SERVICE_FEATURES.FAILURE:
            return state.set('isSubmittingOrder', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case SET_PREVIOUSLY_SELECTED_PAYMENT_INSTRUMENT_IDS:
            return state.setIn(['data', 'previouslySelectedPaymentInstrumentIds'], payload);
        case SET_SELECTED_PRODUCT_ID:
            return state.setIn(['data', 'availableProducts', 'data', 'selectedProductId'], payload);
        case CLEAR_CART_SUMMARY_FOR_WAIVE_CHARGE:
            return state
                .setIn(['data', 'quote', 'quoteIsValid'], true)
                .setIn(['data', 'quote', 'discountAmount'], 0)
                .setIn(['data', 'quote', 'shippingAmount'], 0)
                .setIn(['data', 'quote', 'subTotal'], 0)
                .setIn(['data', 'quote', 'taxAmount'], 0)
                .setIn(['data', 'quote', 'taxInclusive'], false)
                .setIn(['data', 'quote', 'taxItems'], 0)
                .setIn(['data', 'quote', 'totalAmount'], 0)
                .setIn(['data', 'quote', 'totalRemainingAmount'], 0)
                .set('isCalculatingQuote', false);
        case SET_EPISODES:
            return state.setIn(['data', 'availableProducts', 'data', 'episodes'],
                populateSelectedProductsMap(state.data.availableProducts.data.episodes, payload));
        case SET_OPTIONAL_PRODUCTS:
            return state.setIn(['data', 'availableProducts', 'data', 'optionalProducts'],
                populateSelectedProductsMap(state.data.availableProducts.data.optionalProducts, payload));
        case SET_PICKLIST_PRODUCTS:
            return state.setIn(['data', 'availableProducts', 'data', 'picklistProducts'],
                populateSelectedProductsMap(state.data.availableProducts.data.picklistProducts, payload));
        case SET_SELECTED_PRODUCTS_SEARCH_OPTION:
            return state.setIn(['data', 'selectedProductsSortOption'], getSelectedProductSortOption(state.data.productsSortOptions, payload));
        case SET_SELECTED_SERIES_CONTAINER_PRODUCT_ID:
            return state.setIn(['data', 'selectedSeriesContainer', 'productId'], payload);
        case SET_SELECTED_SERIES_CONTAINER_INCLUDED_SEASONS_OPTIONS:
            return state.setIn(['data', 'selectedSeriesContainer', 'selectSeasonOptions'], mapSeriesContainerSeasonsOptions(payload));
        case SAVE_SERIES_CONTAINER_SEASON_SELECTIONS:
            return state.setIn(['data', 'selectedSeriesContainer', 'savedOtherSeasonSelectionsMap'], updateSavedSeasons(state.data.selectedSeriesContainer.savedOtherSeasonSelectionsMap, payload));
        case CLEAR_SAVED_SEASONS_SELECTIONS:
            return state.setIn(['data', 'selectedSeriesContainer', 'savedOtherSeasonSelectionsMap'], {});
        case CLEAR_PRODUCTS_METADATA:
            return state.setIn(['data', 'productsWithMetadata'], EMPTY_OBJECT);
        case SET_PRODUCT_ORDER_INVENTORY_REGION_FILTER:
            return state.setIn(['data', 'inventoryStore', 'regionFilter'], payload);
        case SET_PRODUCT_ORDER_SELECTED_INVENTORY_STORES:
            return state
                .setIn(['data', 'inventoryStore', 'selectedStore'], payload)
                .setIn(['data', 'inventoryStore', 'isStoreChanged'], true);
        case FETCH_PRODUCT_ORDER_SERVICE_IDENTIFIER.SUCCESS:
            return state
                .setIn(['data', 'serviceIdentifiersData', 'serviceIdentifiersList'], payload.SubscriberInventoryItems || EMPTY_ARRAY);
        case SET_SELECTED_SERVICE_IDENTIFIER_FOR_PRODUCT_ORDER:
            return state
                .setIn(['data', 'serviceIdentifiersData', 'selectedServiceIdentifier'], payload);
        case RETRIEVE_ADDRESSES_CONSTANTS.SUCCESS:
            return state
                .setIn(['data', 'customerAddressesData', 'customerAddressesList'], payload.Addresses || EMPTY_ARRAY);
        case SET_SELECTED_CUSTOMER_ADDRESS_FOR_PRODUCT_ORDER:
            return state
                .setIn(['data', 'customerAddressesData', 'selectedCustomerAddress'], payload);
        default:
            return state;
    }
}

function updateSavedSeasons(existingSeasons, season) {
    const newSeasons = clone(existingSeasons);
    newSeasons[season.productId] = season;

    return newSeasons;
}

function mapSeriesContainerSeasonsOptions(seasons) {
    const options = [{
        label: i18n.translate(LocaleKeys.ALL),
        id: 0,
        value: 0
    }];

    return options.concat(seasons.map((season) => {
        return {
            label: season.Name,
            id: season.Id,
            value: season.Id
        };
    }));
}

function saveShoppingCartItemDetails(shoppingCart, state) {
    const clonedState = clone(state);
    if (shoppingCart.Items && shoppingCart.Items.length) {
        shoppingCart.Items.forEach((cartItem) => {
            const existingItemDetail = state.find((item) => {
                return item.PricingPlanId === cartItem.PricingPlanId && item.ProductId === cartItem.ProductId;
            });
            if (!existingItemDetail) {
                clonedState.push({
                    PricingPlanId: cartItem.PricingPlanId,
                    ProductId: cartItem.ProductId,
                    Details: cartItem.Details
                });
            }
        });
    }
    return clonedState;
}

function onSearchAvailableProductsSuccess(state, requestObject, payload) {
    return state
        .set('isFetchingData', false)
        .set('lastAttemptError', null)
        .setIn(['data', 'availableProducts', 'data', 'productsMap'], payload.Products)
        .setIn(['data', 'availableProducts', 'data', 'productsDisplayOrder'], populateProductDisplayOrder(payload.SearchResults))
        .setIn(['data', 'availableProducts', 'data', 'pageNumber'], defaultTo1(requestObject.pageNumber))
        .setIn(['data', 'availableProducts', 'data', 'recordCount'], defaultTo0(payload.RecordCount))
        .setIn(['data', 'availableProducts', 'data', 'searchString'], requestObject.keyword);
}

function nestChildItemsOnOrderQuoteItems(childItems, orderItems) {
    const bundledOrderItems = [];
    orderItems.forEach((item) => {
        if (!item.Details.ParentId) {
            bundledOrderItems.push(item);
        }
    });

    childItems.forEach((childItem) => {
        bundledOrderItems.forEach((parentItem) => {
            if (parentItem.Details.Id === childItem.Details.ParentId) {
                parentItem.ChildItems.push(childItem);
            }
        });
    });

    return bundledOrderItems;
}

function getInstancePropertiesForReplaceItem(item, requestData) {
    let foundItem = null;

    if (requestData && requestData.ReplaceItems && requestData.ReplaceItems.length) {
        requestData.ReplaceItems.forEach((reqReplaceItem) => {
            if (!foundItem && item.ParentId) {
                foundItem = reqReplaceItem.ChildItems.find((reqReplaceItemChild) => {
                    return reqReplaceItemChild.ProductId === item.Product.Id;
                });
            } else if (reqReplaceItem.ProductId === item.Product.Id) {
                foundItem = reqReplaceItem;
            }
        });
    }

    if (foundItem && foundItem.InstanceProperties) {
        return getFormattedInstancePropertiesForUI(foundItem.InstanceProperties);
    } else {
        return null;
    }
}

function getShoppingCartItemFromQuoteItem(orderItem, requestData) {
    return {
        ChildItems: [],
        Details: Object.assign({}, orderItem, {
            TotalAmount: orderItem.Amount
        }),
        InstanceProperties: getInstancePropertiesForReplaceItem(orderItem, requestData),
        PricingPlanId: orderItem.PricingPlan.Id,
        ProductId: orderItem.Product.Id,
        Quantity: orderItem.Quantity
    };
}

function transformQuoteItemsToShoppingCartStructure(orderQuoteItems, requestData) {
    const orderItems = [];
    const childItems = [];

    orderQuoteItems.forEach((orderItem) => {
        if (orderItem.ParentId) {
            childItems.push(getShoppingCartItemFromQuoteItem(orderItem, requestData));
        } else {
            orderItems.push(getShoppingCartItemFromQuoteItem(orderItem, requestData));
        }
    });

    if (childItems && childItems.length > 0) {
        return nestChildItemsOnOrderQuoteItems(childItems, orderItems);
    }

    return orderItems || [];
}

function getSelectedProductSortOption(sortBys, newSelectedOption) {
    return sortBys.find(sortBy => {
        return sortBy.value === newSelectedOption.value && sortBy.direction === newSelectedOption.direction;
    });
}

function prepareInvalidItems(productMetadata, invalidItems) {
    if (Array.isArray(invalidItems)) {
        return camelCaseKeys(invalidItems).map((invalidItem) => {
            const invalidItemMetadata = productMetadata[invalidItem.productId];
            return Object.assign({}, invalidItem, {
                productName: invalidItemMetadata ? invalidItemMetadata.Name : null
            });
        });
    }
    return [];
}

function populateProductDisplayOrder(products) {
    return products.map(product => {
        return product.Id;
    });
}

function populateProductsWithContext(products, payload) {
    const productContexts = reduceProductContexts(payload);

    return Object.keys(products).reduce((accum, key) => {
        accum[key] = generateUpdatedProduct(key, products, [], productContexts);
        return accum;
    }, {});
}

function getUpdatedShoppingCartProductsMetadata(productsMetadata, previousState, payload) {
    const newState = clone(previousState);
    const items = payload.Items || [];
    items.forEach((itemInCart) => {
        if (!previousState[itemInCart.ProductId] && productsMetadata[itemInCart.ProductId]) {
            newState[itemInCart.ProductId] = productsMetadata[itemInCart.ProductId];
        }
    });

    const currentCartProductIds = pluck('ProductId', items);
    return filter((newStateItem) => {
        return contains(newStateItem.Id, currentCartProductIds);
    }, newState);
}

function populateSingleProductContext(key, products, shoppingCartItems, payload) {
    const productContexts = reduceProductContexts(payload);

    return generateUpdatedProduct(key, products, shoppingCartItems, productContexts);
}

function populateSelectedProductsMap(currentProducts = {}, selectedProducts) {
    const updatedProducts = Object.assign({}, currentProducts);
    selectedProducts.forEach((selectedProduct) => {
        updatedProducts[selectedProduct.product.Id] = selectedProduct;
    });

    return updatedProducts;
}

function reduceProductContexts(payload) {
    let productContexts = payload.ProductContexts;
    if (!productContexts) {
        productContexts = payload.ProductContext ? [payload.ProductContext] : [];
    }
    return productContexts.reduce((accum, value) => {
        accum[value.ProductId] = value;
        return accum;
    }, {});
}

function generateUpdatedProduct(key, products, shoppingCartItems, productContexts) {
    let productContext = productContexts[key];

    if (products[key]) { // Product will not be in products list unless user is on that page in the ui
        if (productContext) {
            if (products[key].ProductContext) {
                productContext = products[key].ProductContext.merge(productContext);
            }
            return products[key].set('ProductContext', productContext);
        }

        return products[key];
    }

    if (shoppingCartItems && shoppingCartItems.length) { // Product was not in products list, likely in ShoppingCart
        const foundShoppingCartItem = shoppingCartItems.find((item) => {
            return item.ProductId === key;
        });

        if (productContext && foundShoppingCartItem) {
            if (foundShoppingCartItem.ProductContext) {
                productContext = foundShoppingCartItem.ProductContext.merge(productContext);
            }
            return foundShoppingCartItem.set('ProductContext', productContext);
        }

        return foundShoppingCartItem || {
            ProductContext: productContext
        };
    }

    return {
        ProductContext: productContext
    };
}

function combineMetadataIntoSelectedProductContext(selectedProduct, product) {
    if (!selectedProduct) {
        return product;
    }

    const foundPricingPlans = product.PricingPlans.reduce((accum, value) => {
        accum[value.Id] = value;
        return accum;
    }, {});

    const toReduce = pathOr([], ['ProductContext', 'OrderablePricingPlans'], selectedProduct);
    const orderablePricingPlans = toReduce.reduce((accum, value) => {
        if (foundPricingPlans[value.Id] !== undefined) {
            value = value.merge(foundPricingPlans[value.Id]);
        }
        accum.push(value);
        return accum;
    }, []);
    return selectedProduct.merge(product).setIn(['ProductContext', 'OrderablePricingPlans'], orderablePricingPlans);
}

function prepareQuoteItems(items) {
    return items.map((item) => {
        return Object.assign({
            Amount: 0
        }, item);
    });
}

function prepareCouponCodes(couponCodes, shoppingCartAppliedCoupons = '', invalidCouponRedemptions = []) {
    return map((couponCode) => {
        if (couponCode.description) {
            return couponCode;
        } else {
            let newCouponCodeDescription = without(pluck('description', couponCodes), shoppingCartAppliedCoupons.split(', '))[0];
            if (!newCouponCodeDescription) {
                const invalidCoupon = find(propEq(couponCode.name, 'RedemptionCode'), invalidCouponRedemptions);
                newCouponCodeDescription = invalidCoupon && translateFaultCode(String(invalidCoupon.SubCode)) || '';
            }

            return {
                name: couponCode.name,
                description: newCouponCodeDescription
            };
        }
    }, couponCodes);
}
