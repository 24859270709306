import Immutable from 'seamless-immutable';
import {RETRIEVE_CUSTOMER_CHALLENGE_ANSWER} from './actions/customer.challenge.actions';

export const INITIAL_STATE = Immutable({
    challengeAnswerError: null,
    data: null,
    isFetchingChallengeAnswer: false
});

export default function challengeReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case RETRIEVE_CUSTOMER_CHALLENGE_ANSWER.BEGIN:
            return state.set('isFetchingChallengeAnswer', true);
        case RETRIEVE_CUSTOMER_CHALLENGE_ANSWER.SUCCESS:
            return state
                .set('isFetchingChallengeAnswer', false)
                .set('data', payload.ChallengeAnswer)
                .set('challengeAnswerError', null);
        case RETRIEVE_CUSTOMER_CHALLENGE_ANSWER.FAILURE:
            return state
                .set('isFetchingChallengeAnswer', false)
                .set('challengeAnswerError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                });
        default:
            return state;
    }
}
