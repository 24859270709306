import Immutable from 'seamless-immutable';
import {createSelector} from 'reselect';
// import clone from 'ramda/src/clone';
// import compose from 'ramda/src/compose';
// import flatten from 'ramda/src/flatten';
// import pathOr from 'ramda/src/pathOr';
// import pluck from 'ramda/src/pluck';
// import prop from 'ramda/src/prop';
// import sortBy from 'ramda/src/sortBy';
// import uniq from 'ramda/src/uniq';
// import filter from 'ramda/src/filter';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import i18n from 'invision-core/src/components/i18n/i18n';
import {
    MetadataCodeTypeDictionarySelector,
    MetadataCodeTypeSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {ALL_STEPS_KEYS} from '../constants/add.offer.wizard.constants';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {
    // CartAdditionalPropertyFieldsSelector,
    OfferAdditionalPropertyFieldsSelector,
    OrderAdditionalPropertyFieldsSelector
} from 'invision-core/src/components/metadata/codes/codes.additional.properties.selectors';
import {
    OfferingMetadataByIdSelector,
    OfferingMetadataProductsSelector,
    OfferingMetadataPricingPlansSelector
} from 'invision-core/src/components/metadata/offerings/offerings.selectors';
// import {ShippingMethodsSelector} from 'invision-core/src/components/metadata/shippingMethods/shipping.methods.selectors';
// import {CurrentCustomerCurrencyCodeSelector} from './customer.selectors';
import CareLocaleKeys from '../../locales/keys';
import {
    // AvailableCreditFromQuote,
    BillCycle,
    BillingAddressStateRegionProvinceValueOptions,
    Cart,
    // DeliveryMethodOptionsSelector,
    // DeliveryOptionsByPricingPlanHelper,
    EditOption,
    EditOptions,
    EditCart,
    EditSelectedFacetIds,
    EditSelectedOfferId,
    // FinanceCreditFromQuoteSelector,
    FormattedAttributes,
    // FormattedDeliveryDecisions,
    // FormattedPhysicalAttributes,
    GetAdditionalPropertyListMapSelector,
    // GetCartAdditionalPropertiesSelector,
    // GetDownPaymentTypeValue,
    // GetOfferingOrderQuoteSelector,
    // GetOfferQuoteSelector,
    GetOfferAdditionalPropertiesSelector,
    GetOrderAdditionalPropertiesSelector,
    // GetPostQuoteDueTotalsSelector,
    // GetPreQuoteDueSubTotalsSelector,
    // GetQuoteHasInvoiceItemsSelector,
    // GetTotalDownPaymentAmountSelector,
    // InventoryAvailabilityRequests,
    // IsCreditLimitBreachedFromQuote,
    // OfferingOrderOffCycleAlignmentOptionsSelector,
    // OfferingOrderProductIdsAndPricingPlanIdsOffCycleOfferSelector,
    PaymentInfo,
    SelectedEditOption,
    // SelectedOffCycleAlignmentsSelector,
    SelectedOfferId,
    ServiceAddress,
    // ShippingAddressWizardSelector,
    // TaxLocationDecisionsByPricingPlanHelper
} from '../helpers/offer.ordering.wizard.selector.helper';
import {
    CompletedSteps,
    CurrentStep,
    CurrentStepIndex,
    CurrentStepIsInvalid,
    FormattedAddressCityState,
    IsActiveStep,
    // SelectedTabIndex,
    SkippedStep,
    // TabPanelViewModel
} from '../helpers/wizard.selector.helper';
// import {SubscriptionQuoteForPreview} from './products.order.selectors.helper';
// import {SelectedPaymentMethodSelector} from './customer.ewallet.selectors';
// import {
//     isValidPaymentInstrument,
//     isValidBillingInformation
// } from '../../utilities/payment.instrument.helper';
// import {ASYNC_STATUS_CONSTANTS} from '../constants/wizard.constants';
// import {
//     getFinanceItems,
//     getUniqueNonGuidServiceIdentifiers
// } from '../helpers/add.offer.wizard.selectors.helpers';
import {
    // FilteredStoresOnSelectedRegionForStoresSearchModalSelector,
    // generateKeyFromInventoryItem,
    // HasOffCycleChargeRenewalSelector,
    // InventoryAvailabilitySelector,
    // PaymentInstrumentRequiredSelector,
    // SelectedOfferingPhysicalInventoriesSelector,
    // SelectedOfferPurchaseOrderNumberSelector,
    ShoppingCartSelector,
} from './selected.offering.order.selectors';
import {
    EMPTY_ARRAY,
    // EMPTY_OBJECT
} from '../constants/common.constants';
import {GROUP_TEMPLATE_ID} from '../constants/add.services.wizard.constants';
// import {
//     AsyncStatusSelector,
//     FullCartAsyncStatusSelector,
//     InventoryRegionFilterSelector,
//     OfferingOrderDataSelector,
//     OfferingOrderFinanceCreditLimitSelector,
//     OfferingOrderOffCycleAlignmentSelector,
//     OfferingOrderQuoteSelector,
//     SelectedInventoryRegionSelector,
//     SelectedInventoryStoresSelector,
//     SelectedOrderExecutionOptionsSelector
// } from './offering.order.selectors';
// import {BILLER_RULE_CYCLE_LEVEL} from '../../customercare.constants';
import {getFormattedServiceAttributeValue} from './services.list.selectors.helper';
import {TAX_RULE_OPTIONS} from '../../customercare.constants';
import {getServiceIdentifierAttribute} from './add.services.wizard.selectors.helper';


const AddServicesWizardStoreSelector = state => {
    return state.customercare.recoverableUiState.addServicesWizard;
};

const CartSelector = Cart([AddServicesWizardStoreSelector]);

export const CurrentStepIndexSelector = CurrentStepIndex([AddServicesWizardStoreSelector]);

export const CurrentStepSelector = CurrentStep([CurrentStepIndexSelector], ALL_STEPS_KEYS);
export const IsOffersStepSelector = IsActiveStep([CurrentStepSelector], ALL_STEPS_KEYS, 0);
export const IsDecisionsStepSelector = IsActiveStep([CurrentStepSelector], ALL_STEPS_KEYS, 1);
export const IsAttributesStepSelector = IsActiveStep([CurrentStepSelector], ALL_STEPS_KEYS, 2);
export const IsCheckoutStepSelector = IsActiveStep([CurrentStepSelector], ALL_STEPS_KEYS, 3);

export const CompletedStepsSelector = CompletedSteps([AddServicesWizardStoreSelector]);

export const EditCartSelector = EditCart([AddServicesWizardStoreSelector]);

const SelectedEditOptionSelector = SelectedEditOption([EditCartSelector]);

export const BillCycleSelector = BillCycle([EditCartSelector]);

export const EditOptionsSelector = EditOptions([EditCartSelector]);

export const EditOptionSelector = EditOption([SelectedEditOptionSelector, EditOptionsSelector]);

export const EditSelectedFacetIdsSelector = EditSelectedFacetIds([EditCartSelector]);

export const EditSelectedOfferIdSelector = EditSelectedOfferId([EditCartSelector]);

export const ServiceAddressSelector = ServiceAddress([AddServicesWizardStoreSelector]);

export const PaymentInfoSelector = PaymentInfo([CartSelector]);

export const PaymentInfoEditSelector = PaymentInfo([EditCartSelector]);

export const BillingAddressStateRegionProvinceValueOptionsSelector = BillingAddressStateRegionProvinceValueOptions([
    PaymentInfoEditSelector,
    MetadataCodeTypeSelector(CODES.AddressCountry),
    MetadataCodeTypeSelector(CODES.AddressStateProvinceRegion)
]);

export const SkippedStepSelector = SkippedStep([AddServicesWizardStoreSelector]);

export const CurrentStepIsInvalidSelector = CurrentStepIsInvalid([AddServicesWizardStoreSelector]);

export const SelectedOfferIdSelector = SelectedOfferId([CartSelector]);

// export const SelectedTabIndexSelector = SelectedTabIndex([AddServicesWizardStoreSelector]);

// export const TabPanelViewModelSelector = TabPanelViewModel([SelectedTabIndexSelector]);

export const FormattedAttributesSelector = FormattedAttributes([EditCartSelector]);
// export const FormattedPhysicalAttributesSelector = FormattedPhysicalAttributes([EditCartSelector, SelectedOfferIdSelector, SelectedInventoryStoresSelector]);
// export const InventoryAvailabilityRequestsSelector = InventoryAvailabilityRequests([FormattedPhysicalAttributesSelector, EditCartSelector]);

export const FormattedCityStateSelector = FormattedAddressCityState([ServiceAddressSelector]);

export const AddServicesOrderDataSelector = createSelector(
    [AddServicesWizardStoreSelector],
    (addServicesWizardStore) => {
        return addServicesWizardStore.data;
    }
);

export const FormValidationStatusesSelector = createImmutableSelector(
    [AddServicesWizardStoreSelector],
    (addServicesWizardStore) => {
        return addServicesWizardStore.attributesValidationStatuses;
    }
);

export const AttributesFormSubmittedSelector = createImmutableSelector(
    [AddServicesWizardStoreSelector],
    (addServicesWizardStore) => {
        return addServicesWizardStore.attributesFormSubmitted;
    }
);

// export const ChangeOfferingInstanceIdSelector = createImmutableSelector(
//     [AddServicesWizardStoreSelector],
//     (addServicesWizardStore) => {
//         return addServicesWizardStore.changeOfferingInstanceId;
//     }
// );

// export const ChangeOfferingIdSelector = createImmutableSelector(
//     [AddServicesWizardStoreSelector],
//     (addServicesWizardStore) => {
//         return addServicesWizardStore.changeOfferingId;
//     }
// );

export const EditSelectedOfferingChargeTypeIdSelector = createSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.selectedOfferingChargeTypeIds;
    }
);

// const EMPTY_ATTRIBUTE_GROUPS = Immutable([]);
// export const EditAttributeGroupsSelector = createSelector(
//     [EditCartSelector],
//     (editCart) => {
//         return editCart.editAttributeGroups ? editCart.editAttributeGroups.asMutable({
//             deep: true
//         }) : EMPTY_ATTRIBUTE_GROUPS;
//     }
// );

export const SelectedServiceTemplateIdSelector = createImmutableSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.selectedServiceTemplateId;
    }
);

export const IsGroupTemplateConfiguredSelector = createImmutableSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.isGroupTemplateConfigured;
    }
);

export const GroupTemplateSelector = createImmutableSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.groupTemplate;
    }
);

const ValueDecisionsSelector = createImmutableSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.serviceAttributeFields;
    }
);

const ServiceTemplateMap = createImmutableSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.serviceTemplateMap;
    }
);

const ServiceTemplateMapSelector = createImmutableSelector(
    [
        ValueDecisionsSelector,
        ServiceTemplateMap,
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression)
    ],
    (valueDecisions, serviceTemplateMap, serviceAttributes, regularExpressions) => {
        return Object.entries(serviceTemplateMap).reduce((accumulator, [templateId, serviceTemplate]) => {
            const serviceIdentifierAttribute = getServiceIdentifierAttribute(valueDecisions, serviceTemplate);
            const formattedName = serviceIdentifierAttribute && serviceTemplate.attributes[serviceIdentifierAttribute.ServiceAttributeId] && getFormattedServiceAttributeValue(
                serviceIdentifierAttribute.ServiceAttributeId,
                serviceTemplate.attributes[serviceIdentifierAttribute.ServiceAttributeId],
                serviceAttributes,
                regularExpressions
            );
            accumulator[templateId] = {
                ...serviceTemplate,
                ...(serviceIdentifierAttribute.ServiceAttributeId ? {
                    serviceIdentifierAttributeId: serviceIdentifierAttribute.ServiceAttributeId
                } : {}),
                title: formattedName || serviceTemplate.title,
            };
            return accumulator;
        }, {});
    }
);

const SelectedServiceIdentifierValueDecisionSelector = createImmutableSelector(
    [ValueDecisionsSelector, ServiceTemplateMapSelector, SelectedServiceTemplateIdSelector],
    (valueDecisions, serviceTemplateMap, selectedServiceTemplateId) => {
        return getServiceIdentifierAttribute(valueDecisions, serviceTemplateMap[selectedServiceTemplateId]);
    }
);

const SelectedServiceTemplateServiceIdentifierSelector = createImmutableSelector(
    [SelectedServiceIdentifierValueDecisionSelector],
    (serviceIdentifierValueDecisions) => {
        return serviceIdentifierValueDecisions && serviceIdentifierValueDecisions.ServiceAttributeId;
    }
);

function getOverriddenProps(groupTemplate, serviceTemplate, serviceIdentifier) {
    return Object.keys(serviceTemplate.attributes).reduce((serviceAttributeIds, serviceAttributeId) => {
        if (serviceIdentifier !== serviceAttributeId) {
            const groupValue = groupTemplate.attributes[serviceAttributeId];
            const serviceValue = serviceTemplate.attributes[serviceAttributeId];

            if ((serviceValue && !groupValue) || (serviceValue && groupValue && serviceValue !== groupValue)) {
                serviceAttributeIds.push(serviceAttributeId);
            }
        }

        return serviceAttributeIds;
    }, []);
}

export const SelectedServiceFeaturesSelector = createImmutableSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.selectedServiceFeatures;
    }
);

export const SelectedServiceTemplateDetailsSelector = createImmutableSelector(
    [
        SelectedServiceTemplateIdSelector,
        GroupTemplateSelector,
        ServiceTemplateMapSelector
    ],
    (selectedServiceTemplateId, groupTemplate, serviceTemplateMap, ) => {
        if (selectedServiceTemplateId === GROUP_TEMPLATE_ID) {
            return groupTemplate;
        } else {
            const serviceTemplate = serviceTemplateMap[selectedServiceTemplateId];
            const overriddenProps = getOverriddenProps(groupTemplate, serviceTemplate, serviceTemplate.serviceIdentifierAttributeId);

            return {
                ...serviceTemplate,
                overriddenProps
            };
        }
    }
);

export const ServiceTemplatesSelector = createImmutableSelector(
    [
        ServiceTemplateMapSelector
    ],
    (serviceTemplateMap) => {
        return Object.values(serviceTemplateMap);
    }
);

export const IsGroupTemplateSelectedSelector = createImmutableSelector(
    [SelectedServiceTemplateIdSelector],
    (selectedServiceTemplateId) => {
        return selectedServiceTemplateId === GROUP_TEMPLATE_ID;
    }
);

export const AllServiceAttributeFieldsSelector = createImmutableSelector(
    [ValueDecisionsSelector, SelectedServiceTemplateDetailsSelector, GroupTemplateSelector],
    (valueDecisions, selectedServiceTemplateDetails, groupTemplate) => {
        const fieldsMap = valueDecisions.reduce((acc, attribute) => {
            let isFieldHidden = false;

            if (attribute.DependentServiceAttributeId && attribute.DependentValues) {
                const dependentValues = attribute.DependentValues.split(',');
                const dependentAttributeId = attribute.DependentServiceAttributeId.split('-')[2];
                const selectedDependentValue = dependentAttributeId && (
                    selectedServiceTemplateDetails.attributes[dependentAttributeId] ||
                    groupTemplate.attributes[dependentAttributeId]
                );

                if (!selectedDependentValue || !dependentValues.includes(selectedDependentValue)) {
                    isFieldHidden = true;
                }
            }

            acc[attribute.ServiceAttributeId] = {
                ...attribute,
                isFieldHidden
            };

            return acc;
        }, {});

        return Object.values(fieldsMap);
    }
);

export const ServiceIdentifierDependantAttributeSelector = createImmutableSelector(
    [AllServiceAttributeFieldsSelector],
    (serviceAttributes) => {
        const ServiceIdentifierDependantAttributeId = (serviceAttributes || []).find((serviceAttribute) => {
            return !!serviceAttribute.DependentServiceAttributeId && serviceAttribute.ServiceIdentifierFlag;
        })?.DependentServiceAttributeId || null;
        return ServiceIdentifierDependantAttributeId ? (serviceAttributes || []).find((serviceAttribute) => {
            return serviceAttribute.Id === ServiceIdentifierDependantAttributeId;
        }) : null;
    }
);

const GroupTemplateServiceAttributeFieldsSelector = createImmutableSelector(
    [AllServiceAttributeFieldsSelector, ServiceIdentifierDependantAttributeSelector],
    (allServiceAttributeFields, serviceIdentifierDependantAttribute) => {
        return allServiceAttributeFields.filter((serviceAttribute) => {
            return !serviceAttribute.ServiceIdentifierFlag &&
                serviceAttribute.ServiceAttributeId !== serviceIdentifierDependantAttribute?.ServiceAttributeId;
        });
    }
);

export const SelectedServiceTemplateFieldsSelector = createImmutableSelector(
    [SelectedServiceTemplateIdSelector, GroupTemplateServiceAttributeFieldsSelector, AllServiceAttributeFieldsSelector],
    (selectedServiceTemplateId, groupTemplateFields, serviceTemplateFields) => {
        if (selectedServiceTemplateId === GROUP_TEMPLATE_ID) {
            return groupTemplateFields;
        } else {
            return serviceTemplateFields;
        }
    }
);

function hasOverriddenProps(groupTemplate, serviceTemplate, groupTemplateFields) {

    const groupTemplateAttributeIds = (groupTemplateFields || []).map((attribute) => {
        return attribute.ServiceAttributeId;
    });

    return groupTemplateAttributeIds.some((serviceAttributeId) => {
        const groupValue = groupTemplate.attributes[serviceAttributeId];
        const serviceValue = serviceTemplate.attributes[serviceAttributeId];

        return (serviceValue && !groupValue) || (serviceValue && groupValue && serviceValue !== groupValue);
    });
}

export const SubmittedOrderErrorSelector = createImmutableSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.lastAttemptError;
    }
);

const DuplicateSerivceIdentifiersFromServerSelector = createImmutableSelector(
    [SubmittedOrderErrorSelector],
    (lastSubmitError) => {
        let duplicatesFromServer = [];
        if (lastSubmitError && lastSubmitError.contextData && lastSubmitError.contextData.length) {
            const duplicateServiceIdentifiers = lastSubmitError.contextData.find((data) => {
                return data.Key === 'ServiceIdentifiers';
            });
            if (duplicateServiceIdentifiers) {
                duplicatesFromServer = duplicateServiceIdentifiers.Value.split(',');
            }
        }
        return duplicatesFromServer;
    }
);

export const DuplicateServiceIdentifiersSelector = createImmutableSelector(
    [ServiceTemplatesSelector, DuplicateSerivceIdentifiersFromServerSelector],
    (serviceTemplates, duplicatesFromServer) => {
        const duplicates = [];
        const serviceIdentifierMap = new Map();
        [...serviceTemplates].forEach((template) => {
            const serviceIdentifierValue = template.attributes[template.serviceIdentifierAttributeId];
            if (!serviceIdentifierMap.has(serviceIdentifierValue)) {
                serviceIdentifierMap.set(serviceIdentifierValue, serviceIdentifierValue);
            } else {
                duplicates.push(serviceIdentifierValue);
            }
        });
        duplicatesFromServer.forEach((duplicate) => {
            if (!duplicates.includes(duplicate) && serviceIdentifierMap.has(duplicate)) {
                duplicates.push(duplicate);
            }
        });
        return duplicates;
    }
);


export const AllServiceTemplatesSelector = createImmutableSelector(
    [
        GroupTemplateSelector,
        ServiceTemplatesSelector,
        SelectedServiceTemplateIdSelector,
        GroupTemplateServiceAttributeFieldsSelector,
        DuplicateServiceIdentifiersSelector,
        DuplicateSerivceIdentifiersFromServerSelector,
        FormValidationStatusesSelector,
        AttributesFormSubmittedSelector
    ],
    (groupTemplate, serviceTemplates, selectedServiceTemplateId, groupTemplateFields, duplicates, duplicatesFromServer, validationStatuses, formSubmitted) => {
        return [groupTemplate, ...serviceTemplates].map((serviceTemplate) => {
            const overridden = hasOverriddenProps(groupTemplate, serviceTemplate, groupTemplateFields);
            const serviceIdentifierValue = serviceTemplate.attributes[serviceTemplate.serviceIdentifierAttributeId];
            const isDuplicate = serviceIdentifierValue !== undefined && (duplicates.includes(serviceIdentifierValue) || duplicatesFromServer.includes(serviceIdentifierValue));
            const validationStatus = Object.keys(validationStatuses).length ? validationStatuses[serviceTemplate.Id] : null;

            return {
                ...serviceTemplate,
                glyph: overridden ? 'override' : null,
                glyphText: i18n.translate(CareLocaleKeys.ORDER_SETTINGS_HAVE_BEEN_OVERRIDDEN),
                hasError: formSubmitted && (isDuplicate || (validationStatus && !validationStatus.isValid)),
                selected: serviceTemplate.Id === selectedServiceTemplateId
            };
        });
    }
);

export const HasServiceFeaturesAvailableSelector = createImmutableSelector(
    [AllServiceAttributeFieldsSelector],
    (attributeFields) => {
        return (attributeFields || []).some((attribute) => {
            return attribute.ServiceIdentifierFlag && attribute.IsServiceFeatureAvailable;
        });
    }
);

export const IsSelectedServiceTemplateServiceIdentifierValueDuplicateSelector = createImmutableSelector(
    [DuplicateServiceIdentifiersSelector, SelectedServiceTemplateDetailsSelector, SelectedServiceTemplateServiceIdentifierSelector, DuplicateSerivceIdentifiersFromServerSelector],
    (duplicates, selectedServiceTemplate, serviceIdentifierKey, duplicatesFromServer) => {
        return (duplicates || []).includes(selectedServiceTemplate.attributes[serviceIdentifierKey]) || duplicatesFromServer.includes(selectedServiceTemplate.attributes[serviceIdentifierKey]);
    }
);

export const SummaryTabViewModelSelector = createImmutableSelector(
    [ShoppingCartSelector, ServiceTemplatesSelector, OfferingMetadataByIdSelector, OfferingMetadataProductsSelector, OfferingMetadataPricingPlansSelector],
    (shoppingCart, serviceTemplates, offeringMetadata, productMetadata, pricingPlanMetadata) => {
        const offers = (shoppingCart.Items || []).reduce((viewModel, {OfferingId, ProductId, PricingPlanId}) => {

            if (!viewModel[OfferingId]) {
                viewModel[OfferingId] = {
                    Id: OfferingId,
                    DisplayName: offeringMetadata[OfferingId].DisplayName,
                    Products: {}
                };
            }

            if (!viewModel[OfferingId].Products[ProductId]) {
                viewModel[OfferingId].Products[ProductId] = {
                    Id: ProductId,
                    DisplayName: productMetadata[ProductId].Name,
                    PricingPlans: {}
                };
            }

            if (!viewModel[OfferingId].Products[ProductId].PricingPlans[PricingPlanId]) {
                viewModel[OfferingId].Products[ProductId].PricingPlans[PricingPlanId] = {
                    Id: PricingPlanId,
                    DisplayName: pricingPlanMetadata[PricingPlanId].Name
                };
            }

            return viewModel;
        }, {});

        const services = serviceTemplates.map((serviceTemplate) => {
            return serviceTemplate.title;
        });

        return {
            offers,
            services
        };
    }
);

export const IsSubmittingOrderSelector = createImmutableSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.isSubmittingOrder;
    }
);

export const IsShoppingCartContainsOnlyServiceIndependentItemsSelector = createImmutableSelector(
    [ShoppingCartSelector],
    (shoppingCart) => {
        return (shoppingCart.Items || []).every(({Details: {PricingPlan: {IsServiceIndependent}}}) => {
            return IsServiceIndependent;
        });
    }
);

// export const DeliveryDecisionsSelector = createImmutableSelector(
//     [EditCartSelector],
//     (editCart) => {
//         return editCart.deliveryDecisions || EMPTY_ARRAY;
//     }
// );

const ServiceTaxDecisionsSelector = createImmutableSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.serviceTaxDecisions || EMPTY_ARRAY;
    }
);

export const DefaultServiceTaxDecisionsSelector = createImmutableSelector(
    [ServiceTaxDecisionsSelector, SelectedServiceIdentifierValueDecisionSelector],
    (serviceTaxDecisions, serviceIdentifierValueDecision) => {
        return serviceTaxDecisions.find((taxDecision) => {
            return taxDecision.PricingPanId === serviceIdentifierValueDecision.PricingPanId;
        });
    }
);

export const TaxLocationByPricingPlanSelector = createImmutableSelector(
    [DefaultServiceTaxDecisionsSelector, ServiceTemplateMapSelector],
    (defaultTaxDecisions, serverTemplateMap) => {
        if (defaultTaxDecisions) {
            const taxLocationMapByPricingPlan = {
                [defaultTaxDecisions.PricingPlanId]: {}
            };
            Object.entries(serverTemplateMap).forEach(([key, value]) => {
                taxLocationMapByPricingPlan[defaultTaxDecisions.PricingPlanId] = {
                    ...taxLocationMapByPricingPlan[defaultTaxDecisions.PricingPlanId],
                    [key]: {
                        ServiceTaxCustomization: value.taxLocation || {},
                        taxOption: value.taxLocation ? TAX_RULE_OPTIONS.CUSTOM : TAX_RULE_OPTIONS.DEFAULT
                    }
                };
            });
            return taxLocationMapByPricingPlan;
        }
    }
);

// export const DeliveryOptionsByPricingPlanSelector = DeliveryOptionsByPricingPlanHelper([DeliveryDecisionsSelector, ShippingMethodsSelector]);

// export const FormattedDeliveryDecisionsSelector = FormattedDeliveryDecisions([DeliveryDecisionsSelector]);

// export const TaxLocationDecisionsByPricingPlanSelector = TaxLocationDecisionsByPricingPlanHelper([ServiceTaxDecisionsSelector]);

// export const EditPhysicalAttributeGroupsSelector = createSelector(
//     [EditCartSelector],
//     (editCart) => {
//         return editCart.editPhysicalAttributeGroups ? editCart.editPhysicalAttributeGroups.asMutable({
//             deep: true
//         }) : EMPTY_ATTRIBUTE_GROUPS;
//     }
// );

// export const IsCalculatingQuoteSelector = createSelector(
//     [AddServicesWizardStoreSelector],
//     (addServicesWizardStore) => {
//         return addServicesWizardStore.isCalculatingQuote;
//     }
// );

// export const IsSavingOrderSelector = createSelector(
//     [EditCartSelector],
//     (editCart) => {
//         return editCart.isSavingOrder;
//     }
// );

// export const IsResumingOrderSelector = createSelector(
//     [AddServicesWizardStoreSelector],
//     (addServicesWizardStore) => {
//         return addServicesWizardStore.isResumingOrder;
//     }
// );

// export const IsResumingStoreOrderSelector = createImmutableSelector(
//     [AddServicesWizardStoreSelector],
//     (addServicesWizardStore) => {
//         return addServicesWizardStore.isResumingStoreOrder;
//     }
// );

// export const SubscriberPhysicalInventoryPickupDetailSelector = createImmutableSelector(
//     [AddServicesWizardStoreSelector],
//     (addServicesWizardStore) => {
//         return addServicesWizardStore.subscriberPhysicalInventoryPickupDetail;
//     }
// );

// export const PickupIdSelector = createImmutableSelector(
//     [SubscriberPhysicalInventoryPickupDetailSelector],
//     (subscriberPhysicalInventoryPickupDetail) => {
//         return subscriberPhysicalInventoryPickupDetail.id;
//     }
// );

// export const QuoteCalculatedSelector = createSelector(
//     [AddServicesOrderDataSelector],
//     (addServicesOrderData) => {
//         return addServicesOrderData.quoteCalculated;
//     }
// );

// export const SubTotalSelector = createSelector(
//     [AddServicesOrderDataSelector],
//     (addServicesOrderData) => {
//         return addServicesOrderData.subTotal;
//     }
// );

// export const TaxAmountSelector = createSelector(
//     [AddServicesOrderDataSelector],
//     (addServicesOrderData) => {
//         return addServicesOrderData.taxAmount;
//     }
// );

// export const TotalAmountSelector = createSelector(
//     [AddServicesOrderDataSelector],
//     (addServicesOrderData) => {
//         return addServicesOrderData.totalAmount;
//     }
// );

// const SubscriptionOrderQuoteSelector = createSelector(
//     [AddServicesWizardStoreSelector],
//     (addServicesWizardStore) => {
//         return addServicesWizardStore.subscriptionOrderQuote;
//     }
// );

// export const OfferQuoteSelector = GetOfferingOrderQuoteSelector([OfferingOrderQuoteSelector]);

// export const AddOfferQuoteSelector = GetOfferQuoteSelector([AddServicesWizardStoreSelector]);

// export const AvailableCreditFromQuoteBreachedSelector = AvailableCreditFromQuote([OfferQuoteSelector]);

// export const IsCreditLimitBreachedFromQuoteSelector = IsCreditLimitBreachedFromQuote([OfferQuoteSelector]);

// export const OfferQuoteFinanceCreditLimitSelector = OfferingOrderFinanceCreditLimitSelector;

// export const AvailableFinanceCreditFromQuoteSelector = FinanceCreditFromQuoteSelector([OfferQuoteFinanceCreditLimitSelector]);

// export const TotalDownPaymentSelector = GetTotalDownPaymentAmountSelector([AddOfferQuoteSelector]);

// export const SubscriptionDataForPreview = createSelector(
//     [SubscriptionOrderQuoteSelector, OfferQuoteSelector],
//     (subscriptionOrderQuote, offerQuote) => {
//         return SubscriptionQuoteForPreview(subscriptionOrderQuote, offerQuote);
//     }
// );

// export const QuoteHasInvoiceItemsSelector = GetQuoteHasInvoiceItemsSelector([OfferQuoteSelector]);

// export const ValidPaymentInstrument = createSelector(
//     [PaymentInfoSelector, SelectedPaymentMethodSelector],
//     (paymentInfo, selectedPaymentMethod) => {
//         const availablePaymentInfo = paymentInfo || selectedPaymentMethod;
//         return isValidPaymentInstrument(availablePaymentInfo) &&
//         isValidBillingInformation(availablePaymentInfo) ?
//             availablePaymentInfo : null;
//     }
// );

// const MultiOfferingSelector = createSelector(
//     [AddServicesWizardStoreSelector],
//     (addServicesWizardStore) => {
//         return Immutable(addServicesWizardStore.multiOffer);
//     }
// );

// export const OffCycleAlignmentSubscriberProductDataSelector = createImmutableSelector(
//     [MultiOfferingSelector],
//     (multiOffer) => {
//         return multiOffer.offCycleAlignmentSubscriberProductData || EMPTY_OBJECT;
//     }
// );

// export const InitializeAsMultiOfferWizardSelector = createSelector(
//     [MultiOfferingSelector],
//     (multiOffer) => {
//         return Immutable(multiOffer.initializeAsMultiOfferWizard);
//     }
// );

// export const TransferFromSubscriberIdSelector = createImmutableSelector(
//     [AddServicesWizardStoreSelector],
//     (addOfferStore) => {
//         return addOfferStore.transferFromSubscriberId;
//     }
// );

// export const TransferFromNameSelector = createImmutableSelector(
//     [AddServicesWizardStoreSelector],
//     (addOfferStore) => {
//         return addOfferStore.transferFromName;
//     }
// );

// export const IsTransferWizardSelector = createImmutableSelector(
//     [TransferFromNameSelector],
//     (transferFromName) => {
//         return !!transferFromName;
//     }
// );

// const formatShoppingCartItemsWithBillingEffectiveDate = (shoppingCart, executionOptions, alignmentData) => {
//     return shoppingCart.set('Items', shoppingCart && shoppingCart.Items && shoppingCart.Items.map((item) => {
//         if (alignmentData[item.OfferingInstanceId] && alignmentData[item.OfferingInstanceId][item.PricingPlanId]
//             && alignmentData[item.OfferingInstanceId][item.PricingPlanId].productId === item.ProductId) {
//             return item.set('OffCycleAlignmentSubscriberProductId', alignmentData[item.OfferingInstanceId][item.PricingPlanId].subscriberProductId)
//                 .set('BillingEffectiveDate', executionOptions.billingEffectiveDate)
//                 .set('BillingEffectiveDateIntention', executionOptions.billingEffectiveIntention);
//         }
//         return item.set('BillingEffectiveDate', executionOptions.billingEffectiveDate)
//             .set('BillingEffectiveDateIntention', executionOptions.billingEffectiveIntention)
//             .set('DownPaymentType', GetDownPaymentTypeValue(item));
//     }));
// };

// const formatShoppingCartWithPurchaseOrderNumber = (shoppingCart, purchaseOrderNumber) => {
//     return shoppingCart.set('Items', shoppingCart && shoppingCart.Items && shoppingCart.Items.map((item) => {
//         return purchaseOrderNumber ? item.set('PurchaseOrderNumber', purchaseOrderNumber) : item;
//     }));
// };

// export const MultiOfferShoppingCartSelector = createImmutableSelector(
//     [MultiOfferingSelector, SelectedOrderExecutionOptionsSelector, OffCycleAlignmentSubscriberProductDataSelector, SelectedOfferPurchaseOrderNumberSelector],
//     (multiOffer, executionOptions, offCycleAlignmentSubscriberProductData, purchaseOrderNumber) => {
//         const shoppingCartFormatted = executionOptions && executionOptions.billingEffectiveIntention ?
//             formatShoppingCartItemsWithBillingEffectiveDate(multiOffer.shoppingCart, executionOptions, offCycleAlignmentSubscriberProductData) :
//             multiOffer.shoppingCart;
//         return purchaseOrderNumber ? formatShoppingCartWithPurchaseOrderNumber(shoppingCartFormatted, purchaseOrderNumber) : shoppingCartFormatted;
//     }
// );

// export const IsAddOfferPaymentInstrumentRequiredSelector = createSelector(
//     [
//         PaymentInstrumentRequiredSelector,
//         MultiOfferShoppingCartSelector
//     ],
//     (selectedOfferPaymentInstrumentRequired, multiOfferShoppingCart) => {
//         return Immutable(multiOfferShoppingCart.PaymentInstrumentRequired || selectedOfferPaymentInstrumentRequired);
//     }
// );

// export const HasOffCycleChargeRenewalAddOfferSelector = createImmutableSelector(
//     [
//         HasOffCycleChargeRenewalSelector,
//         MultiOfferShoppingCartSelector
//     ],
//     (hasRenewal, multiOfferShoppingCart) => {
//         return multiOfferShoppingCart.HasOffCycleChargeRenewal || hasRenewal;
//     }
// );

// export const IsRetrievingMultiOfferCartSelector = createSelector(
//     [MultiOfferingSelector],
//     (multiOffer) => {
//         return Immutable(multiOffer.isRetrievingCart);
//     }
// );

// const MultiOfferShoppingCartItemsSelector = createSelector(
//     [MultiOfferShoppingCartSelector],
//     (multiOfferShoppingCart) => {
//         return (multiOfferShoppingCart.Items && multiOfferShoppingCart.Items.length) ? Immutable(multiOfferShoppingCart.Items) : EMPTY_ARRAY;
//     }
// );

// const MultiOfferShoppingCartOfferingsSelector = createImmutableSelector(
//     [MultiOfferShoppingCartSelector],
//     (multiOfferShoppingCart) => {
//         return multiOfferShoppingCart.Offerings || EMPTY_ARRAY;
//     }
// );

// export const IsMultiOfferShoppingCartPrepaidSelector = createSelector(
//     [MultiOfferShoppingCartItemsSelector],
//     (multiOfferShoppingCartItems) => {
//         return Immutable(multiOfferShoppingCartItems.some((item) => {
//             return item.Details.PricingPlan.Prepaid;
//         }));
//     }
// );

// export const RemoveOfferInstanceIdSelector = createSelector(
//     [MultiOfferingSelector],
//     (multiOffer) => {
//         return Immutable(multiOffer.removeOfferingInstanceId);
//     }
// );

// export const ShoppingCartWithoutRemovedOfferSelector = createImmutableSelector(
//     [RemoveOfferInstanceIdSelector, MultiOfferShoppingCartSelector],
//     (removeOfferInstanceId, multiOfferShoppingCart) => {
//         return pathOr(0, ['Items', 'length'], multiOfferShoppingCart) ?
//             multiOfferShoppingCart.setIn(['Items'], multiOfferShoppingCart.Items.filter((cartItem) => {
//                 return cartItem.OfferingInstanceId !== removeOfferInstanceId;
//             })): multiOfferShoppingCart;
//     }
// );

// export const IsRemovingOfferSavedToMultiOfferCartSelector = createImmutableSelector(
//     [RemoveOfferInstanceIdSelector, MultiOfferShoppingCartItemsSelector],
//     (removeOfferInstanceId, multiOfferCartItems) => {
//         return !removeOfferInstanceId ? false : !!multiOfferCartItems.filter((cartItem) => {
//             return cartItem.OfferingInstanceId === removeOfferInstanceId;
//         }).length;
//     });

// export const SelectedOfferingInstanceIdSelector = createSelector(
//     [MultiOfferingSelector],
//     (multiOffer) => {
//         return Immutable(multiOffer.selectedOfferingInstanceId || undefined);
//     }
// );



// const OfferInstanceIdsSelector = createImmutableSelector(
//     [MultiOfferShoppingCartItemsSelector],
//     (offerShoppingCartItems) => {
//         return uniq(pluck('OfferingInstanceId', filter((item) => {
//             return !!item.OfferingInstanceId;
//         }, offerShoppingCartItems)));
//     }
// );

// export const AddOfferAsyncIdSelector = createImmutableSelector(
//     [AddServicesWizardStoreSelector],
//     (addServicesWizard) => {
//         return addServicesWizard.asyncId;
//     }
// );

// export const IsAsyncCompleteSelector  = createImmutableSelector(
//     [
//         AddOfferAsyncIdSelector,
//         AsyncStatusSelector,
//     ],
//     (asyncId, asyncStatus) => {
//         return asyncId ?
//             asyncStatus === ASYNC_STATUS_CONSTANTS.COMPLETE || asyncStatus === ASYNC_STATUS_CONSTANTS.FAILED :
//             true;
//     }
// );

// export const IsFullCartAsyncCompleteSelector  = createImmutableSelector(
//     [
//         AddOfferAsyncIdSelector,
//         FullCartAsyncStatusSelector,
//     ],
//     (asyncId, fullCartAsyncStatus) => {
//         return asyncId ? fullCartAsyncStatus === ASYNC_STATUS_CONSTANTS.COMPLETE || fullCartAsyncStatus === ASYNC_STATUS_CONSTANTS.FAILED : true;
//     }
// );

// export const OffersCountSelector = createSelector(
//     [OfferInstanceIdsSelector],
//     (offerInstanceIds) => {
//         return Immutable(offerInstanceIds.length);
//     }
// );

// export const IsMultiOfferSelector = createSelector(
//     [OffersCountSelector],
//     (offerCount) => {
//         return Immutable(!!offerCount);
//     }
// );

// export const MultiOfferIdsSelector = createImmutableSelector(
//     [MultiOfferShoppingCartItemsSelector],
//     (multiOfferItems) => {
//         return uniq(pluck('OfferingId', multiOfferItems)).filter((value) => {
//             return value;
//         });
//     }
// );

// export const IsMultiOfferEditingSelector = createImmutableSelector(
//     [SelectedOfferingInstanceIdSelector],
//     (selectedOfferingInstanceId) => {
//         return !!selectedOfferingInstanceId;
//     }
// );

// export const EditedMultiOfferShoppingCartSelector = createImmutableSelector(
//     [ShoppingCartSelector, MultiOfferShoppingCartSelector, MultiOfferShoppingCartItemsSelector, SelectedOfferingInstanceIdSelector, ChangeOfferingInstanceIdSelector],
//     (shoppingCart, multiOfferShoppingCart, multiOfferShoppingCartItems, selectedOfferingInstanceId, changeOfferingInstanceId) => {
//         const cartItemsWithoutSelectedOfferingInstance = multiOfferShoppingCartItems.filter((cartItems) => {
//             return cartItems.OfferingInstanceId !== (changeOfferingInstanceId ? changeOfferingInstanceId : selectedOfferingInstanceId);
//         });
//         const editedMultiOfferShoppingCart = [...cartItemsWithoutSelectedOfferingInstance];
//         editedMultiOfferShoppingCart.push(...(shoppingCart.Items || []).map((item) => {
//             return Object.assign({}, item, {
//                 OfferingInstanceId: changeOfferingInstanceId || selectedOfferingInstanceId
//             });
//         }));
//         return multiOfferShoppingCart.setIn(['Items'], editedMultiOfferShoppingCart);
//     }
// );

// export const MultiOfferShoppingCartPhysicalInventoryItemsSelector = createImmutableSelector(
//     [
//         MultiOfferShoppingCartSelector,
//         IsMultiOfferEditingSelector,
//         ChangeOfferingInstanceIdSelector,
//         EditedMultiOfferShoppingCartSelector
//     ],
//     (multiOfferShoppingCart, isMultiOfferEditing, changeOfferingInstanceId, editedMultiOfferShoppingCart) => {
//         const shoppingCart = isMultiOfferEditing || changeOfferingInstanceId ? editedMultiOfferShoppingCart : multiOfferShoppingCart;

//         return pathOr(EMPTY_ARRAY, ['Items'], shoppingCart).filter((item) => {
//             return item.PhysicalInventories && item.PhysicalInventories.length;
//         });
//     }
// );

// export const AddOfferShoppingCartPhysicalInventoriesSelector = createImmutableSelector(
//     [
//         MultiOfferShoppingCartPhysicalInventoryItemsSelector,
//         SelectedOfferingPhysicalInventoriesSelector,
//         IsMultiOfferEditingSelector,
//         ChangeOfferingInstanceIdSelector
//     ],
//     (multiOfferShoppingCartPhysicalInventoryItems, selectedOfferingPhysicalInventoryItems, isMultiOfferEditing, changeOfferingInstanceId) => {
//         return isMultiOfferEditing || changeOfferingInstanceId ?
//             multiOfferShoppingCartPhysicalInventoryItems :
//             [...multiOfferShoppingCartPhysicalInventoryItems, ...selectedOfferingPhysicalInventoryItems];
//     }
// );

// export const AllPhysicalInventoryItemsFromAddOfferSelector = createImmutableSelector(
//     [AddOfferShoppingCartPhysicalInventoriesSelector],
//     (shoppingCartPhysicalInventoryItems) => {
//         const shoppingCartItemsWithPhysicalInventories = shoppingCartPhysicalInventoryItems.filter((items) => {
//             return items.PhysicalInventories;
//         });
//         return flatten(pluck('PhysicalInventories', shoppingCartItemsWithPhysicalInventories)).map((item, index) => {
//             return {
//                 DisplayName: item.DisplayName,
//                 InstanceId: generateKeyFromInventoryItem(item, index),
//                 InventoryAttributes: item.InventoryAttributes,
//                 InventoryTypeId: item.InventoryTypeId,
//                 MakeId: item.MakeId,
//                 ModelId: item.ModelId,
//                 Quantity: 1
//             };
//         });
//     }
// );

// export const AddOfferSubscriberInventoryAvailabilityInventorySelector = createImmutableSelector(
//     [
//         AllPhysicalInventoryItemsFromAddOfferSelector,
//         SelectedInventoryRegionSelector,
//         InventoryRegionFilterSelector
//     ],
//     (allPhysicalInventoryItemsFromAddOffer, selectedRegionFromCurrentlySelectedStore, currentlySelectedRegion) => {
//         return !allPhysicalInventoryItemsFromAddOffer || !selectedRegionFromCurrentlySelectedStore ? null :
//             {
//                 InventoryTypes: allPhysicalInventoryItemsFromAddOffer,
//                 RegionId: currentlySelectedRegion ? currentlySelectedRegion : selectedRegionFromCurrentlySelectedStore,
//             };
//     }
// );

// export const SelectedOfferIndexSelector = createImmutableSelector(
//     [OfferInstanceIdsSelector, SelectedOfferingInstanceIdSelector, OffersCountSelector, ChangeOfferingInstanceIdSelector],
//     (offeringInstanceIds, selectedOfferInstanceId, offersCount, changeOfferingInstanceId) => {
//         return changeOfferingInstanceId || selectedOfferInstanceId ?
//             clone(offeringInstanceIds).sort().indexOf(changeOfferingInstanceId || selectedOfferInstanceId) + 1 :
//             offersCount;
//     }
// );

// export const AddedOffersSelector = createImmutableSelector(
//     [
//         MultiOfferShoppingCartItemsSelector,
//         MultiOfferShoppingCartOfferingsSelector,
//         MetadataCodeTypeDictionarySelector(CODES.RegularExpression),
//         MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
//         IsTransferWizardSelector,
//     ],
//     (offerShoppingCartItems, multiOfferShoppingCartOfferings, regularExpressionCodeTable, serviceAttributeCodeTable, isTransferWizard) => {
//         return sortBy(prop('id'), (offerShoppingCartItems).reduce((accumulator, cartItem) => {
//             const offerInstanceIds = pluck('id', accumulator);
//             if (!offerInstanceIds.length || !offerInstanceIds.includes(cartItem.OfferingInstanceId)) {
//                 const offering = multiOfferShoppingCartOfferings.find((offer) => {
//                     return offer.OfferingId === cartItem.OfferingId;
//                 });
//                 const cartItemsForOfferingInstanceId = offerShoppingCartItems.filter((item) => {
//                     return item.OfferingInstanceId === cartItem.OfferingInstanceId;
//                 });
//                 const serviceAttributes = compose(flatten, pluck('ServiceAttributes'))(cartItemsForOfferingInstanceId);
//                 accumulator.push({
//                     id: cartItem.OfferingInstanceId,
//                     isChangeable: offering && !isTransferWizard ? offering.IsChangeable : false,
//                     name: cartItem.OrderedOfferingName,
//                     offeringId: cartItem.OfferingId,
//                     serviceIdentifiers: getUniqueNonGuidServiceIdentifiers(serviceAttributes, serviceAttributeCodeTable, regularExpressionCodeTable),
//                 });
//             }
//             return accumulator;
//         }, []));
//     }
// );

// export const MultiOfferEditingShoppingCartItems = createImmutableSelector(
//     [
//         MultiOfferShoppingCartItemsSelector,
//         IsMultiOfferEditingSelector,
//         SelectedOfferingPhysicalInventoriesSelector
//     ],
//     (multiOfferCartItems, isMultiOfferEditing, selectedOfferingPhysicalInventoryItem) => {
//         const multiOfferShoppingCartItems = isMultiOfferEditing ? multiOfferCartItems.filter((item) => {
//             return selectedOfferingPhysicalInventoryItem.some((inventoryItems) => {
//                 return item.PricingPlanId !== inventoryItems.PricingPlanId;
//             });
//         }) : EMPTY_ARRAY;
//         return [...multiOfferShoppingCartItems, ...selectedOfferingPhysicalInventoryItem];
//     }
// );

export const IsDecisionsTouchedSelector = createSelector(
    [AddServicesWizardStoreSelector],
    (addServicesWizardStore) => {
        return Immutable(!!addServicesWizardStore.isDecisionsTouched);
    }
);

// export const MultiOfferFinancedItemsForDownpaymentSelector = createImmutableSelector(
//     [MultiOfferShoppingCartSelector, OfferQuoteSelector],
//     (multiOfferShoppingCart, offerQuote) => {
//         // The multi offer financeItems is handled differently than a single offer which creates the need for 2 selectors.

//         return getFinanceItems(multiOfferShoppingCart).map((item) => {
//             const quoteItem = pathOr([], ['Items'], offerQuote).find((quoteItem) => {
//                 return (quoteItem.PricingPlan.Id === item.PricingPlanId
//                     && quoteItem.Product.Id === item.ProductId);
//             });
//             const financeBillerRuleInstance = pathOr(0, ['Details', 'PricingPlan', 'PricingPlanBillerRuleInstances',
//                 'FinanceBillerRuleInstances', '0'], item);
//             return {
//                 billerRule: item.Details.PricingPlan.BillerRuleInstanceThumbnails[0],
//                 currencyCode: item.Details.PricingPlan.Currency,
//                 downPayment: item.downPayment || financeBillerRuleInstance.MinimumDownPaymentAmount,
//                 downPaymentType: item.DownPaymentType || undefined,
//                 financedFullAmount: pathOr(0, ['FinancedFullAmount'], quoteItem),
//                 grossAmount: item.Details.PricingPlan.BillerRuleInstanceThumbnails[0].Amount,
//                 id: item.Id,
//                 inventoryAttributes: pathOr([], ['PhysicalInventories', 0, 'InventoryAttributes'], item),
//                 isDownPaymentEqualToFirstInstallment: financeBillerRuleInstance.DownPaymentEqualToFirstInstallment,
//                 isDownPaymentEqualToFirstSelected: !item.isFullPriceDownPaymentSelected,
//                 isFullPriceDownPaymentSelected: item.isFullPriceDownPaymentSelected || false,
//                 isFullUpfrontPayment: financeBillerRuleInstance.FullUpfrontPayment,
//                 itemIndex: item.itemIndex,
//                 minimumDownPayment: financeBillerRuleInstance.MinimumDownPaymentAmount,
//                 name: item.Details.Product.Name,
//                 payPerAmount: pathOr(0, ['Amount'], quoteItem),
//                 pricingPlanId: item.Details.PricingPlan.Id,
//                 pricingPlanName: item.Details.PricingPlan.Name,
//                 productId: item.Details.Product.Id,
//                 taxAmount: pathOr(0, ['TaxAmount'], quoteItem),
//                 termLength: financeBillerRuleInstance.TermLength
//             };
//         });
//     }
// );

// export const AddOfferStoreAvailabilitySelector = createImmutableSelector(
//     [
//         InventoryAvailabilitySelector,
//         FilteredStoresOnSelectedRegionForStoresSearchModalSelector,
//         AllPhysicalInventoryItemsFromAddOfferSelector],
//     (availableInventoryForStores, stores, allPhysicalInventoryItemsFromAddOffering) => {
//         let areRegionsInSync = false;
//         if (availableInventoryForStores && stores) {
//             areRegionsInSync = availableInventoryForStores && availableInventoryForStores[0] && stores ? stores.find((store) => {
//                 return store.storeId === availableInventoryForStores[0].StoreId;
//             }) : false;
//         }

//         if (availableInventoryForStores && allPhysicalInventoryItemsFromAddOffering && stores && areRegionsInSync) {
//             return allPhysicalInventoryItemsFromAddOffering.map((inventoryItem) => {
//                 const storeAvailForInventoryItem = [];
//                 stores.forEach((store) => {
//                     const itemAvailabilityAtStoreResponse = availableInventoryForStores.find((inventoryStatusAtStore) => {
//                         return inventoryStatusAtStore.InstanceId === inventoryItem.InstanceId &&
//                             inventoryStatusAtStore.StoreId === store.storeId;
//                     });

//                     if (itemAvailabilityAtStoreResponse) {
//                         storeAvailForInventoryItem.push({
//                             storeId: store.storeId,
//                             AvailableUnits: itemAvailabilityAtStoreResponse.AvailableUnits,
//                             RequestedQuantity: itemAvailabilityAtStoreResponse.RequestedQuantity,
//                             MeetsRequestedQuantity: itemAvailabilityAtStoreResponse.MeetsRequestedQuantity
//                         });
//                     }
//                 });
//                 return {
//                     DisplayName: inventoryItem.DisplayName,
//                     InstanceId: inventoryItem.InstanceId,
//                     InventoryAttributes: inventoryItem.InventoryAttributes,
//                     InventoryTypeId: inventoryItem.InventoryTypeId,
//                     MakeId: inventoryItem.MakeId,
//                     ModelId: inventoryItem.ModelId,
//                     Quantity: 1,
//                     stores: storeAvailForInventoryItem
//                 };
//             });
//         }
//         return [];
//     }
// );

// export const PostQuoteDueTotalsSelector = GetPostQuoteDueTotalsSelector([OfferQuoteSelector]);

// export const PreQuoteDueSubTotalsSelector = GetPreQuoteDueSubTotalsSelector([ShoppingCartSelector]);

// export const CartAdditionalPropertiesSelector = GetCartAdditionalPropertiesSelector([AddServicesWizardStoreSelector]);

// export const CartAdditionalPropertyListMapSelector = GetAdditionalPropertyListMapSelector([CartAdditionalPropertiesSelector, CartAdditionalPropertyFieldsSelector]);

export const OrderAdditionalPropertiesSelector = GetOrderAdditionalPropertiesSelector([AddServicesWizardStoreSelector]);
export const OfferAdditionalPropertiesSelector = GetOfferAdditionalPropertiesSelector([AddServicesWizardStoreSelector]);

export const OrderAdditionalPropertyListMapSelector = GetAdditionalPropertyListMapSelector([OrderAdditionalPropertiesSelector, OrderAdditionalPropertyFieldsSelector]);
export const OfferAdditionalPropertyListMapSelector = GetAdditionalPropertyListMapSelector([OfferAdditionalPropertiesSelector, OfferAdditionalPropertyFieldsSelector]);
// export const MultiOfferCartContainsOfferWithBillerRuleCycleLevelOtherThanItemSelector = createImmutableSelector(
//     [MultiOfferIdsSelector, OfferingMetadataByIdSelector],
//     (multiOfferIds, offeringMetadataById) => {
//         return (multiOfferIds || []).some((offerId) => {
//             return (offeringMetadataById[offerId] || {}).BillerRuleCycleLevel !== BILLER_RULE_CYCLE_LEVEL.ITEM;
//         });
//     });

// export const MultiOfferCartContainsOfferWithAtleastOneBillerRuleCycleLevelAsItemSelector = createImmutableSelector(
//     [MultiOfferIdsSelector, OfferingMetadataByIdSelector],
//     (multiOfferIds, offeringMetadataById) => {
//         return (multiOfferIds || []).some((offerId) => {
//             return (offeringMetadataById[offerId] || {}).BillerRuleCycleLevel === BILLER_RULE_CYCLE_LEVEL.ITEM;
//         });
//     });

// export const AddOfferOffCycleAlignmentOptionsSelector = OfferingOrderOffCycleAlignmentOptionsSelector([MultiOfferShoppingCartSelector,
//     OfferingMetadataByIdSelector,
//     OfferingOrderOffCycleAlignmentSelector,
//     MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
//     MetadataCodeTypeDictionarySelector(CODES.RegularExpression)]);

// export const AddOfferProductIdsAndPricingPlanIdsOffCycleOfferSelector = OfferingOrderProductIdsAndPricingPlanIdsOffCycleOfferSelector([MultiOfferShoppingCartSelector,
//     OfferingMetadataByIdSelector]);

// export const AddOfferSelectedOffCycleAlignmentsSelector = SelectedOffCycleAlignmentsSelector([OffCycleAlignmentSubscriberProductDataSelector,
//     AddOfferOffCycleAlignmentOptionsSelector]);

// export const ShippingAddressSelector = ShippingAddressWizardSelector([OfferingOrderDataSelector]);

// export const AddServicesDeliveryMethodOptionsSelector = DeliveryMethodOptionsSelector([ShippingMethodsSelector, CurrentCustomerCurrencyCodeSelector]);
