import {createSelector} from 'reselect';
import {
    CustomerSelectors
} from 'invision-core';


export const CustomerAccountStatusSelector = createSelector(
    [CustomerSelectors.SelectedCustomerSelector],
    (selectedCustomer) => {
        if (selectedCustomer.accountStatus) {
            return selectedCustomer.accountStatus.data;
        } else {
            return null;
        }
    }
);

export const IsFetchingAccountStatusDataSelector = createSelector(
    [CustomerSelectors.SelectedCustomerSelector],
    (selectedCustomer) => {
        if (selectedCustomer.accountStatus) {
            return selectedCustomer.accountStatus.isFetchingData;
        } else {
            return false;
        }
    }
);

export const ShowCustomerAutoPayBannerSelector = createSelector(
    [CustomerSelectors.SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.accountStatus.showAutoPayBanner;
    }
);
