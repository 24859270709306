import {createSelector} from 'reselect';
import pathOr from 'ramda/src/pathOr';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {MetadataCodeTypeSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {
    createTableEtfPricingPlansHeaders,
    createTableEtfPricingPlansViewModel
} from './contract.selectors.helper';
import {EARLY_TERMINATION_FEE_CHARGE_TYPES} from '../../components/shared/constants/etf.charge.type.contants';
import {SelectedContractDefinitionSelector} from './offering.order.selectors';

export const ContractSelector = (state) => {
    return pathOr(null, ['customercare', 'offeringOrder', 'data', 'selectedContract'], state);
};

export const ContractPricingPlanSelector = createSelector(
    [ContractSelector],
    (contract) => {
        return pathOr(null, ['pricingPlan'], contract);
    }
);

export const SelectedContractSelector = createSelector(
    [ContractSelector],
    (contract) => {
        return pathOr(null, ['contractDetails'], contract);
    }
);

export const IsFetchingSelectedContractSelector = createSelector(
    [ContractSelector],
    (contract) => {
        return contract.isFetchingData;
    }
);

export const SelectedContractPricingPlanEtfInstanceSelector = createSelector(
    [SelectedContractDefinitionSelector],
    (selectedContractDefinition) => {
        return pathOr(null, ['PricingPlan', 'ConvergentBillerMetadata', 'EarlyTerminationFeeBillerRuleInstances', 0], selectedContractDefinition);
    }
);

export const SelectedContractPricingPlanEtfScheduleSelector = createSelector(
    [SelectedContractPricingPlanEtfInstanceSelector],
    (pricingPlanEtfInstance) => {
        const etfSchedules = pathOr(null, ['EarlyTerminationFeeSchedules'], pricingPlanEtfInstance);

        return pricingPlanEtfInstance && (pricingPlanEtfInstance.ChargeTypeCode === EARLY_TERMINATION_FEE_CHARGE_TYPES.FLAT)
            && etfSchedules && etfSchedules.length
            ? etfSchedules[0] : etfSchedules;
    }
);

const EMPTY_TABLE_DATA = [];
export const ContractPricingPlanTableDataSelector = createSelector(
    [
        MetadataCodeTypeSelector(CODES.EarlyTerminationFeeChargeType),
        SelectedContractPricingPlanEtfInstanceSelector
    ],
    (etfChargeTypes, pricingPlanEtfInstance) => {
        const tableData = {
            columnDefs: createTableEtfPricingPlansHeaders(pricingPlanEtfInstance ? pricingPlanEtfInstance.ChargeTypeCode : null)
        };

        tableData.data =
            pricingPlanEtfInstance
                ? createTableEtfPricingPlansViewModel(pricingPlanEtfInstance, etfChargeTypes)
                : EMPTY_TABLE_DATA;

        return tableData;
    }
);

export const ContractInstanceSelector = createImmutableSelector(
    [ContractSelector],
    (contract) => {
        return contract && contract.orderContractInstance ? contract.orderContractInstance : null;
    }
);