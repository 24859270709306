import {ThunkHelper} from 'invision-core';
import {SUBSCRIBER_ID_HEADER} from 'invision-core/src/api/constants';
import {values} from 'ramda';

export const ENTITLEMENT_BALANCES_TO_UPDATE = {
    BEGIN: 'ENTITLEMENT_TO_UPDATE_BEGIN',
    SUCCESS: 'ENTITLEMENT_TO_UPDATE_SUCCESS',
    FAILURE: 'ENTITLEMENT_TO_UPDATE_FAILURE'
};

export const ENTITLEMENTS_TO_UPDATE_TYPES = values(ENTITLEMENT_BALANCES_TO_UPDATE);
export const retrieveEntitlementsToUpdatePromise = (dispatch, customerId, serviceIdentifier) => {
    return ThunkHelper(dispatch, ENTITLEMENTS_TO_UPDATE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveEntitlementBalances',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            ServiceIdentifier: serviceIdentifier || null
        }
    });
};

export const retrieveEntitlementsToUpdate = (customerId, serviceIdentifier) => {
    return (dispatch) => {
        return retrieveEntitlementsToUpdatePromise(dispatch, customerId, serviceIdentifier);
    };
};


export const ENTITLEMENT_BALANCE_UPDATE = {
    BEGIN: 'ENTITLEMENT_BALANCE_UPDATE_BEGIN',
    SUCCESS: 'ENTITLEMENT_BALANCE_UPDATE_SUCCESS',
    FAILURE: 'ENTITLEMENT_BALANCE_UPDATE_FAILURE'
};

export const entitlementBalanceUpdate = (requestData, customerId) => {
    return (dispatch) => {
        return ThunkHelper(dispatch, values(ENTITLEMENT_BALANCE_UPDATE), {
            method: 'post',
            headers: {
                [SUBSCRIBER_ID_HEADER]: customerId
            },
            url: 'SubscriberManagement/UpdateEntitlementBalance',
            data: requestData
        });
    };
};

export const SET_SELECTED_ENTITLEMENT = 'SET_SELECTED_ENTITLEMENT';
export const setSelectedEntitlement = (entitlement) => {
    return {
        type: SET_SELECTED_ENTITLEMENT,
        payload: entitlement
    };
};