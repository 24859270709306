import {
    ApiConstants,
    ThunkHelper
} from 'invision-core';
import {
    buildCustomerRequestObject
} from '../helpers/customer.helper';

export const CREATE_HOUSEHOLD_CONSTANTS = {
    BEGIN: 'CREATE_HOUSEHOLD_BEGIN',
    SUCCESS: 'CREATE_HOUSEHOLD_SUCCESS',
    FAILURE: 'CREATE_HOUSEHOLD_FAILURE'
};

const CREATE_HOUSEHOLD_EVENTS = [
    CREATE_HOUSEHOLD_CONSTANTS.BEGIN,
    CREATE_HOUSEHOLD_CONSTANTS.SUCCESS,
    CREATE_HOUSEHOLD_CONSTANTS.FAILURE
];

const createHouseholdPromise = (dispatch, customerId, household) => {
    return ThunkHelper(dispatch, CREATE_HOUSEHOLD_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/CreateHousehold',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            Household: household
        }
    });
};

export const createHousehold = (customerId, household) => {
    return (dispatch) => {
        return createHouseholdPromise(dispatch, customerId, household);
    };
};

export const CREATE_HOUSEHOLD_MEMBER_CONSTANTS = {
    BEGIN: 'CREATE_HOUSEHOLD_MEMBER_BEGIN',
    SUCCESS: 'CREATE_HOUSEHOLD_MEMBER_SUCCESS',
    FAILURE: 'CREATE_HOUSEHOLD_MEMBER_FAILURE'
};

const CREATE_HOUSEHOLD_MEMBER_EVENTS = [
    CREATE_HOUSEHOLD_MEMBER_CONSTANTS.BEGIN,
    CREATE_HOUSEHOLD_MEMBER_CONSTANTS.SUCCESS,
    CREATE_HOUSEHOLD_MEMBER_CONSTANTS.FAILURE
];

export const createHouseholdMemberPromise = (dispatch, customer, additionalOptions) => {
    return ThunkHelper(dispatch, CREATE_HOUSEHOLD_MEMBER_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/CreateSubscriber',
        data: Object.assign(buildCustomerRequestObject(customer), additionalOptions)
    });
};

export const createHouseholdMember = (customer, additionalOptions = {}) => {
    return (dispatch) => {
        return createHouseholdMemberPromise(dispatch, customer, additionalOptions);
    };
};

export const RETRIEVE_HOUSEHOLD_CONSTANTS = {
    BEGIN: 'RETRIEVE_HOUSEHOLDS_BEGIN',
    SUCCESS: 'RETRIEVE_HOUSEHOLDS_SUCCESS',
    FAILURE: 'RETRIEVE_HOUSEHOLDS_FAILURE'
};

const RETRIEVE_HOUSEHOLD_EVENTS = [
    RETRIEVE_HOUSEHOLD_CONSTANTS.BEGIN,
    RETRIEVE_HOUSEHOLD_CONSTANTS.SUCCESS,
    RETRIEVE_HOUSEHOLD_CONSTANTS.FAILURE
];

export const retrieveCustomerHouseholdsPromise = (dispatch, customerId, includeRemoved) => {
    return ThunkHelper(dispatch, RETRIEVE_HOUSEHOLD_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveHouseholds',
        data: {
            IncludeRemoved: includeRemoved
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const retrieveCustomerHouseholds = (customerId, includeRemoved) => {
    return (dispatch) => {
        return retrieveCustomerHouseholdsPromise(dispatch, customerId, includeRemoved);
    };
};

export const SET_SELECTED_HOUSEHOLD_MEMBER = 'SET_SELECTED_HOUSEHOLD_MEMBER';

export const setSelectedHouseholdMember = (memberId) => {
    return {
        type: SET_SELECTED_HOUSEHOLD_MEMBER,
        payload: memberId
    };
};

export const SET_EDIT_HOUSEHOLD_DATA = 'SET_EDIT_HOUSEHOLD_DATA';

export const setEditHouseholdData = (household) => {
    return {
        type: SET_EDIT_HOUSEHOLD_DATA,
        payload: household
    };
};

export const UPDATE_HOUSEHOLD_CONSTANTS = {
    BEGIN: 'UPDATE_HOUSEHOLD_BEGIN',
    SUCCESS: 'UPDATE_HOUSEHOLD_SUCCESS',
    FAILURE: 'UPDATE_HOUSEHOLD_FAILURE'
};

const UPDATE_HOUSEHOLD_EVENTS = [
    UPDATE_HOUSEHOLD_CONSTANTS.BEGIN,
    UPDATE_HOUSEHOLD_CONSTANTS.SUCCESS,
    UPDATE_HOUSEHOLD_CONSTANTS.FAILURE
];

const updateHouseholdPromise = (dispatch, customerId, household, status) => {
    return ThunkHelper(dispatch, UPDATE_HOUSEHOLD_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/UpdateHousehold',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            Household: household,
            Status: status
        }
    });
};

export const updateHousehold = (customerId, household) => {
    return (dispatch) => {
        return updateHouseholdPromise(dispatch, customerId, household);
    };
};

export const updateHouseholdAndStatus = (customerId, household, status) => {
    return (dispatch) => {
        return updateHouseholdPromise(dispatch, customerId, household, status);
    };
};

export const UPDATE_HOUSEHOLD_SERVICES_ASSOCIATIONS_CONSTANTS = {
    BEGIN: 'UPDATE_HOUSEHOLD_SERVICES_ASSOCIATIONS_BEGIN',
    SUCCESS: 'UPDATE_HOUSEHOLD_SERVICES_ASSOCIATIONS_SUCCESS',
    FAILURE: 'UPDATE_HOUSEHOLD_SERVICES_ASSOCIATIONS_FAILURE'
};

const UPDATE_HOUSEHOLD_SERVICES_ASSOCIATIONS_EVENTS = [
    UPDATE_HOUSEHOLD_SERVICES_ASSOCIATIONS_CONSTANTS.BEGIN,
    UPDATE_HOUSEHOLD_SERVICES_ASSOCIATIONS_CONSTANTS.SUCCESS,
    UPDATE_HOUSEHOLD_SERVICES_ASSOCIATIONS_CONSTANTS.FAILURE
];

const updateHouseholdServicesAssociationsPromise = (dispatch, data) => {
    return ThunkHelper(dispatch, UPDATE_HOUSEHOLD_SERVICES_ASSOCIATIONS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/UpdateHouseholdMemberToServices',
        data: {
            HouseholdMemberToService: {
                HeadOfHouseholdSubscriberId: data.headOfHouseholdSubscriberId,
                MemberToServiceAssociations: data.householdServicesAssociations
            }
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: data.subscriberId
        }
    });
};

export const updateHouseholdServicesAssociations = (data) => {
    return (dispatch) => {
        return updateHouseholdServicesAssociationsPromise(dispatch, data);
    };
};

export const REMOVE_HOUSEHOLD_MEMBERS_CONSTANTS = {
    BEGIN: 'REMOVE_HOUSEHOLD_MEMBERS_BEGIN',
    SUCCESS: 'REMOVE_HOUSEHOLD_MEMBERS_SUCCESS',
    FAILURE: 'REMOVE_HOUSEHOLD_MEMBERS_FAILURE'
};

const REMOVE_HOUSEHOLD_MEMBERS_EVENTS = [
    REMOVE_HOUSEHOLD_MEMBERS_CONSTANTS.BEGIN,
    REMOVE_HOUSEHOLD_MEMBERS_CONSTANTS.SUCCESS,
    REMOVE_HOUSEHOLD_MEMBERS_CONSTANTS.FAILURE
];

export const removeHouseholdMembersPromise = (dispatch, customerId, householdId, members) => {
    return ThunkHelper(dispatch, REMOVE_HOUSEHOLD_MEMBERS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/UpdateHouseholdMembers',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            Id: householdId,
            HouseholdMembersToRemove: members.map(member => {
                return member.SubscriberId;
            }),
            HouseholdMembersToUpdate: members
        }
    });
};

export const removeHouseholdMembers = (customerId, householdId, members) => {
    return (dispatch) => {
        return removeHouseholdMembersPromise(dispatch, customerId, householdId, members);
    };
};

export const UPDATE_HOUSEHOLD_MEMBERS = {
    BEGIN: 'UPDATE_HOUSEHOLD_MEMBERS_BEGIN',
    SUCCESS: 'UPDATE_HOUSEHOLD_MEMBERS_SUCCESS',
    FAILURE: 'UPDATE_HOUSEHOLD_MEMBERS_FAILURE'
};

const UPDATE_HOUSEHOLD_MEMBERS_EVENTS = [
    UPDATE_HOUSEHOLD_MEMBERS.BEGIN,
    UPDATE_HOUSEHOLD_MEMBERS.SUCCESS,
    UPDATE_HOUSEHOLD_MEMBERS.FAILURE
];

export const updateHouseholdMember = (subscriberId, householdId, member) => {
    return (dispatch) => {
        return ThunkHelper(dispatch, UPDATE_HOUSEHOLD_MEMBERS_EVENTS, {
            method: 'post',
            url: 'SubscriberManagement/UpdateHouseholdMembers',
            headers: {
                [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
            },
            data: {
                Id: householdId,
                HouseholdMembersToUpdate: [member]
            }
        });
    };
};

export const SET_MEMBER_PRIVILEGE = 'SET_MEMBER_PRIVILEGE';
export const setMemberPrivilege = (payload) => {
    return (dispatch) => {
        return Promise.resolve(dispatch({
            type: SET_MEMBER_PRIVILEGE,
            payload
        }));
    };
};

export const RESET_MEMBER_PRIVILEGE_UI = 'RESET_MEMBER_PRIVILEGE_UI';
export const resetUiState = () => {
    return {
        type: RESET_MEMBER_PRIVILEGE_UI
    };
};

export const SET_AVAILABLE_PAYMENT_INSTRUMENTS = 'SET_AVAILABLE_PAYMENT_INSTRUMENTS';
export const setAvailablePaymentInstruments = (payload) => {
    return (dispatch) => {
        return Promise.resolve(dispatch({
            type: SET_AVAILABLE_PAYMENT_INSTRUMENTS,
            payload
        }));
    };
};

export const SET_SELECTED_HOURS = 'SET_SELECTED_HOURS';
export const setSelectedHours = (payload, memberId) => {
    return (dispatch) => {
        return Promise.resolve(dispatch({
            type: SET_SELECTED_HOURS,
            payload,
            requestObject: {
                memberId
            }
        }));
    };
};

export const SET_SELECTED_HOURS_FOR_HOUSEHOLD_MEMBER = 'SET_SELECTED_HOURS_FOR_HOUSEHOLD_MEMBER';
export const setSelectedHoursForHouseholdMember = (payload) => {
    return {
        type: SET_SELECTED_HOURS_FOR_HOUSEHOLD_MEMBER,
        payload
    };
};

export const REMOVE_HOUSEHOLD = {
    BEGIN: 'REMOVE_HOUSEHOLD_BEGIN',
    SUCCESS: 'REMOVE_HOUSEHOLD_SUCCESS',
    FAILURE: 'REMOVE_HOUSEHOLD_FAILURE'
};

const REMOVE_HOUSEHOLD_EVENTS = [
    REMOVE_HOUSEHOLD.BEGIN,
    REMOVE_HOUSEHOLD.SUCCESS,
    REMOVE_HOUSEHOLD.FAILURE
];

export const removeHousehold = (subscriberId, householdId) => {
    return (dispatch) => {
        return ThunkHelper(dispatch, REMOVE_HOUSEHOLD_EVENTS, {
            method: 'post',
            url: 'SubscriberManagement/RemoveHousehold',
            headers: {
                [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
            },
            data: {
                Id: householdId
            }
        });
    };
};

export const SET_INCLUDED_REMOVED_FILTER = 'SET_HOUSEHOLD_INCLUDED_REMOVED_FILTER';
export const setIncludedRemovedFilter = (includedFilter) => {
    return {
        type: SET_INCLUDED_REMOVED_FILTER,
        payload: includedFilter
    };
};
