import {
    ApiConstants,
    ThunkHelper
} from 'invision-core';

export const SEARCH_TRANSACTIONS_CONSTANTS = {
    BEGIN: 'SEARCH_TRANSACTIONS_BEGIN',
    SUCCESS: 'SEARCH_TRANSACTIONS_SUCCESS',
    FAILURE: 'SEARCH_TRANSACTIONS_FAILURE'
};

export const RETRIEVE_TRANSACTION_CONSTANTS = {
    BEGIN: 'RETRIEVE_TRANSACTION_BEGIN',
    SUCCESS: 'RETRIEVE_TRANSACTION_SUCCESS',
    FAILURE: 'RETRIEVE_TRANSACTION_FAILURE'
};

export const EMAIL_TRANSACTION_CONSTANTS = {
    BEGIN: 'EMAIL_TRANSACTION_BEGIN',
    SUCCESS: 'EMAIL_TRANSACTION_SUCCESS',
    FAILURE: 'EMAIL_TRANSACTION_FAILURE'
};

const SEARCH_TRANSACTIONS_EVENTS = [SEARCH_TRANSACTIONS_CONSTANTS.BEGIN, SEARCH_TRANSACTIONS_CONSTANTS.SUCCESS, SEARCH_TRANSACTIONS_CONSTANTS.FAILURE];
const RETRIEVE_TRANSACTION_EVENTS = [RETRIEVE_TRANSACTION_CONSTANTS.BEGIN, RETRIEVE_TRANSACTION_CONSTANTS.SUCCESS, RETRIEVE_TRANSACTION_CONSTANTS.FAILURE];
const EMAIL_TRANSACTION_EVENTS = [EMAIL_TRANSACTION_CONSTANTS.BEGIN, EMAIL_TRANSACTION_CONSTANTS.SUCCESS, EMAIL_TRANSACTION_CONSTANTS.FAILURE];

export const searchTransactionsPromise = (dispatch, customerId, data) => {
    if (data.TransactionTypes && data.TransactionTypes.length) {
        data.TransactionTypes = data.TransactionTypes.map((type) => {
            return type.id;
        });
    }

    return ThunkHelper(dispatch, SEARCH_TRANSACTIONS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SearchTransactions',
        data: data,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const searchTransactions = (customerId, data) => {
    return (dispatch) => {
        return searchTransactionsPromise(dispatch, customerId, data);
    };
};

const retrieveTransactionPromise = (dispatch, transactionId, customerId, isDbss) => {
    return ThunkHelper(dispatch, RETRIEVE_TRANSACTION_EVENTS, {
        method: 'POST',
        url: 'SubscriberManagement/RetrieveTransaction',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            TransactionId: transactionId,
            ReturnReceiptData: true,
            IncludePurchaseInformationThumbnails: true,
            ReturnTransactionRetryIndicator: isDbss || false
        }
    }, {
        transactionId
    });
};

export const retrieveTransaction = (transactionId, customerId, isDbss) => {
    return (dispatch) => {
        return retrieveTransactionPromise(dispatch, transactionId, customerId, isDbss);
    };
};

export const emailTransaction = (requestData, customerId) => {
    return (dispatch) => {
        return ThunkHelper(dispatch, EMAIL_TRANSACTION_EVENTS, {
            method: 'POST',
            url: 'SubscriberManagement/SendTransactionReceipt',
            headers: {
                [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
            },
            data: requestData
        });
    };
};

export const SET_CURRENT_PAGE = 'SET_TRANSACTIONS_CURRENT_PAGE';
export const setCurrentPage = (page) => {
    return {
        type: SET_CURRENT_PAGE,
        payload: page
    };
};

export const SET_BRANDABLE_CURRENCY_FILTER = 'SET_TRANSACTIONS_BRANDABLE_CURRENCY_FILTER';
export const setBrandableCurrencyFilter = (brandableCurrencyFilter) => {
    return {
        type: SET_BRANDABLE_CURRENCY_FILTER,
        payload: brandableCurrencyFilter
    };
};

export const SET_CURRENCY_FILTER = 'SET_TRANSACTIONS_CURRENCY_FILTER';
export const setCurrencyFilter = (currencyFilter) => {
    return {
        type: SET_CURRENCY_FILTER,
        payload: currencyFilter
    };
};

export const SET_LOCKER_FILTER = 'SET_LOCKER_FILTER';
export const setLockerFilter = (lockerFilter) => {
    return {
        type: SET_LOCKER_FILTER,
        payload: lockerFilter
    };
};

export const SET_PAGE_SIZE_PREFERENCE = 'SET_PAGE_SIZE_PREFERENCE';
export const setPageSizePreference = (pageSize) => {
    return {
        type: SET_PAGE_SIZE_PREFERENCE,
        payload: pageSize
    };
};

export const SET_PAYMENT_INSTRUMENT_FILTER = 'SET_TRANSACTIONS_PAYMENT_INSTRUMENT_FILTER';
export const setPaymentInstrumentFilter = (paymentInstrumentFilter) => {
    return {
        type: SET_PAYMENT_INSTRUMENT_FILTER,
        payload: paymentInstrumentFilter
    };
};

export const SET_TRANSACTION_RESULT_FILTER = 'SET_TRANSACTION_RESULT_FILTER';
export const setTransactionResultFilter = (transactionResultFilter) => {
    return {
        type: SET_TRANSACTION_RESULT_FILTER,
        payload: transactionResultFilter
    };
};

export const SET_TRANSACTION_TYPE_FILTER = 'SET_TRANSACTIONS_TRANSACTION_TYPE_FILTER';
export const setTransactionTypeFilter = (transactionTypeFilter) => {
    return {
        type: SET_TRANSACTION_TYPE_FILTER,
        payload: transactionTypeFilter
    };
};

export const UPDATE_DATE_RANGE = 'UPDATE_TRANSACTIONS_DATE_RANGE';
export const updateDateRange = (startDate, endDate) => {
    return {
        type: UPDATE_DATE_RANGE,
        payload: {
            startDate: startDate,
            endDate: endDate
        }
    };
};

export const SHOW_SUBSCRIPTION_BANNER = 'SHOW_SUBSCRIPTION_BANNER';
export const showSubscriptionBanner = (showBanner) => {
    return {
        type: SHOW_SUBSCRIPTION_BANNER,
        payload: showBanner
    };
};

export const CLEAR_TRANSACTIONS_ERROR = 'CLEAR_TRANSACTIONS_ERROR';
export const clearTransactionsError = () => {
    return {
        type: CLEAR_TRANSACTIONS_ERROR
    };
};

export const EMPTY_TRANSACTIONS_LIST = 'EMPTY_TRANSACTIONS_LIST';
export const emptyTransactionsList = () => {
    return {
        type: EMPTY_TRANSACTIONS_LIST
    };
};

export const REMOVE_TRANSACTIONS_FILTER = 'REMOVE TRANSACTIONS_FILTER';
export const removeTransactionsFilter = () => {
    return {
        type: REMOVE_TRANSACTIONS_FILTER
    };
};


