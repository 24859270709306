import {
    InjectorHelper
} from 'invision-core';

// NOTE: This is code for a DEMO!  It should not be used as an example for other development work.
export const executeWebContainerRequest = (externalId, intent, context) => {
    return () => {
        const $http = InjectorHelper.getHttpService();
        const data = {
            CustomerId: externalId,
            Channel: 'Web',
            Intent: intent
        };
        if (context.length !== 0) {
            data['Contexts'] = context.map(x => {
                return {
                    Key: 'RemovedPackages',
                    Type: 'text',
                    Value: x
                };
            });
        }

        $http({
            method: 'post',
            url: 'PegaMKTContainer/Services/ExecuteWebContainer',
            data: data
        });

        const offerList = [];
        const isUserA = (externalId % 2) === 0;

        if (intent === 'up') {
            offerList.push({
                InteractionID: '4',
                OfferName: 'Foxtel Magazine',
                Group: 'Television',
                ShortDescription: 'Plan your attack – tour our guide of great TV every month',
                OfferID: 'up4',
                AssetID: '',
                Issue: 'Negotiation',
                OfferType: 'RC',
                LikelyHood: 0,
                OfferCategory: '',
                Outcome: 'Rejected',
                Behaviour: 'Neutral'
            });
            if (isUserA) {
                offerList.push({
                    InteractionID: '5',
                    OfferName: 'Sky Racing+',
                    Group: 'Television',
                    ShortDescription: 'Add a dash of rating to your Sports Package',
                    OfferID: 'up5',
                    AssetID: '',
                    Issue: 'Negotiation',
                    OfferType: 'RC',
                    LikelyHood: 0,
                    OfferCategory: '',
                    Outcome: 'Rejected',
                    Behaviour: 'Neutral'
                });
                offerList.push({
                    InteractionID: '6',
                    OfferName: 'RAI International',
                    Group: 'Television',
                    ShortDescription: 'Bellissimo! News, drama, sports and fun from Italy',
                    OfferID: 'up6',
                    AssetID: '',
                    Issue: 'Negotiation',
                    OfferType: 'RC',
                    LikelyHood: 0,
                    OfferCategory: '',
                    Outcome: 'Rejected',
                    Behaviour: 'Neutral'
                });
            } else {
                offerList.push({
                    InteractionID: '7',
                    OfferName: 'Unlimited Broadband',
                    Group: 'Television',
                    ShortDescription: 'Unlimited Broadband for Platinum HD TV customers only',
                    OfferID: 'up7',
                    AssetID: '',
                    Issue: 'Negotiation',
                    OfferType: 'RC',
                    LikelyHood: 0,
                    OfferCategory: '',
                    Outcome: 'Rejected',
                    Behaviour: 'Neutral'
                });
            }
        } else if (intent === 'down') {
            offerList.push({
                InteractionID: '3',
                OfferName: 'Unlimited Broadband + Sports Bundle',
                Group: 'Bundle',
                ShortDescription: 'Unlimited Broadband w/ Sports Bundle',
                OfferID: 'down3',
                AssetID: '',
                Issue: 'Negotiation',
                OfferType: 'RC',
                LikelyHood: 0,
                OfferCategory: '',
                Outcome: 'Rejected',
                Behaviour: 'Neutral'
            });
            offerList.push({
                InteractionID: '2',
                OfferName: 'Unlimited Broadband + Entertainment Bundle',
                Group: 'Bundle',
                ShortDescription: 'Unlimited Broadband w/ Entertainment Bundle',
                OfferID: 'down2',
                AssetID: '',
                Issue: 'Negotiation',
                OfferType: 'RC',
                LikelyHood: 0,
                OfferCategory: '',
                Outcome: 'Rejected',
                Behaviour: 'Neutral'
            });
            if (!isUserA) {
                offerList.push({
                    InteractionID: '1',
                    OfferName: 'Sports and Drama Combo',
                    Group: 'Television',
                    ShortDescription: 'Give your Foxtel experience an HD makeover',
                    OfferID: 'down1',
                    AssetID: '',
                    Issue: 'Negotiation',
                    OfferType: 'RC',
                    LikelyHood: 0,
                    OfferCategory: '',
                    Outcome: 'Rejected',
                    Behaviour: 'Neutral'
                });
            }
        } else { // move
            if (isUserA) {
                offerList.push({
                    InteractionID: '590336383181625',
                    OfferName: 'Platinum 12 Months',
                    Group: 'Television',
                    ShortDescription: 'Applies $38 discount to Plat Package for a duration of 12 Months',
                    OfferID: 'Platinum89For12Months',
                    AssetID: '',
                    Issue: 'Negotiation',
                    OfferType: 'RC',
                    LikelyHood: 0,
                    OfferCategory: '',
                    Outcome: 'Rejected',
                    Behaviour: 'Neutral'
                });
            } else {
                offerList.push({
                    InteractionID: '5903363831816252014',
                    OfferName: 'Home Move - Full Price',
                    Group: 'Television',
                    ShortDescription: 'Move Address 100%  TV Free GE/BoxSets Tier- 3 Months',
                    OfferID: 'HomeMoveFullPrice',
                    AssetID: '',
                    Issue: 'Negotiation',
                    OfferType: 'RC',
                    LikelyHood: 0,
                    OfferCategory: '',
                    Outcome: 'Rejected',
                    Behaviour: 'Neutral'
                });
                offerList.push({
                    InteractionID: '5903363831816252014',
                    OfferName: '1 Free Premium Tier (Sports)',
                    Group: 'Television',
                    ShortDescription: 'Applies 100% Discount to PremSports Tier  for 3 Month Duration',
                    OfferID: 'Free1PremiumTierSports03Months',
                    AssetID: '',
                    Issue: 'Negotiation',
                    OfferType: 'RC',
                    LikelyHood: 0,
                    OfferCategory: '',
                    Outcome: 'Rejected',
                    Behaviour: 'Neutral'
                });
            }
        }
        return Promise.resolve(offerList);
    };
};

export const captureResponseRequest = (externalId, user, offerList) => {
    return () => {
        const $http = InjectorHelper.getHttpService();

        $http({
            method: 'post',
            url: 'PegaMKTContainer/Services/CaptureResponse',
            data: {
                CustomerId: externalId,
                Channel: 'Web',
                UserAgent: user,
                OffersList: offerList
            }
        });
        return Promise.resolve();
    };
};