import {ThunkHelper} from 'invision-core';
import ApiConstants from 'invision-core/src/api/constants';

export const UPDATE_SERVICE_LIFE_CYCLE_STATUS = {
    BEGIN: 'UPDATE_SERVICE_LIFE_CYCLE_STATUS_BEGIN',
    SUCCESS: 'UPDATE_SERVICE_LIFE_CYCLE_STATUS_SUCCESS',
    FAILURE: 'UPDATE_SERVICE_LIFE_CYCLE_STATUS_FAILURE'
};
const UPDATE_SERVICE_LIFE_CYCLE_STATUS_EVENTS = [UPDATE_SERVICE_LIFE_CYCLE_STATUS.BEGIN, UPDATE_SERVICE_LIFE_CYCLE_STATUS.SUCCESS, UPDATE_SERVICE_LIFE_CYCLE_STATUS.FAILURE];

const updateServiceLifeCycleStatusPromise = (dispatch, customerId, effectiveDate, nextServiceLifeCycleStatusId, reasonDescription, serviceIdentifier) => {
    return ThunkHelper(dispatch, UPDATE_SERVICE_LIFE_CYCLE_STATUS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/UpdateServiceExternalLifeCycleStatus',
        data: {
            Comment: reasonDescription || undefined,
            EffectiveDate : effectiveDate,
            NextServiceLifeCycleStatusId : nextServiceLifeCycleStatusId,
            ServiceIdentifier : serviceIdentifier,
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
    });
};

export const updateServiceLifeCycleStatus = (customerId, effectiveDate, nextServiceLifeCycleStatusId, reasonDescription, serviceIdentifier) => {
    return (dispatch) => {
        return updateServiceLifeCycleStatusPromise(dispatch, customerId, effectiveDate, nextServiceLifeCycleStatusId, reasonDescription, serviceIdentifier);
    };
};
