import {SUBSCRIBER_ID_HEADER} from 'invision-core/src/api/constants';
import ThunkHelper from 'invision-core/src/api/thunk.helper';
import values from 'ramda/src/values';

export const RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS = {
    BEGIN: 'RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_BEGIN',
    SUCCESS: 'RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_SUCCESS',
    FAILURE: 'RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_FAILURE'
};

export const RETRIEVE_CONVERGENT_BILLER_INVOICES_CONSTANTS = {
    BEGIN: 'RETRIEVE_CONVERGENT_BILLER_INVOICES_BEGIN',
    SUCCESS: 'RETRIEVE_CONVERGENT_BILLER_INVOICES_SUCCESS',
    FAILURE: 'RETRIEVE_CONVERGENT_BILLER_INVOICES_FAILURE'
};

export const RETRIEVE_CONVERGENT_BILLER_TREATMENT_DETAILS_CONSTANTS = {
    BEGIN: 'RETRIEVE_CONVERGENT_BILLER_TREATMENT_DETAILS_BEGIN',
    SUCCESS: 'RETRIEVE_CONVERGENT_BILLER_TREATMENT_DETAILS_SUCCESS',
    FAILURE: 'RETRIEVE_CONVERGENT_BILLER_TREATMENT_DETAILS_FAILURE'
};

export const CREATE_CONVERGENT_BILLER_PROMISE_TO_PAY_CONSTANTS = {
    BEGIN: 'CREATE_CONVERGENT_BILLER_PROMISE_TO_PAY_BEGIN',
    SUCCESS: 'CREATE_CONVERGENT_BILLER_PROMISE_TO_PAY_SUCCESS',
    FAILURE: 'CREATE_CONVERGENT_BILLER_PROMISE_TO_PAY_FAILURE'
};

export const RETRIEVE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS = {
    BEGIN: 'RETRIEVE_CONVERGENT_BILLER_ACCOUNT_DETAILS_BEGIN',
    SUCCESS: 'RETRIEVE_CONVERGENT_BILLER_ACCOUNT_DETAILS_SUCCESS',
    FAILURE: 'RETRIEVE_CONVERGENT_BILLER_ACCOUNT_DETAILS_FAILURE'
};

export const RETRIEVE_CONVERGENT_BILLER_ACCOUNT_USAGE_DETAILS_CONSTANTS = {
    BEGIN: 'RETRIEVE_CONVERGENT_BILLER_ACCOUNT_USAGE_DETAILS_BEGIN',
    SUCCESS: 'RETRIEVE_CONVERGENT_BILLER_ACCOUNT_USAGE_DETAILS_SUCCESS',
    FAILURE: 'RETRIEVE_CONVERGENT_BILLER_ACCOUNT_USAGE_DETAILS_FAILURE'
};

export const RETRIEVE_CONVERGENT_BILLER_ORDER_DETAILS_CONSTANTS = {
    BEGIN: 'RETRIEVE_CONVERGENT_BILLER_ORDER_DETAILS_BEGIN',
    SUCCESS: 'RETRIEVE_CONVERGENT_BILLER_ORDER_DETAILS_SUCCESS',
    FAILURE: 'RETRIEVE_CONVERGENT_BILLER_ORDER_DETAILS_FAILURE'
};

export const RETRIEVE_SUBSCRIBER_DEPOSITS_CONSTANTS = {
    BEGIN: 'RETRIEVE_SUBSCRIBER_DEPOSITS_CONSTANTS_BEGIN',
    SUCCESS: 'RETRIEVE_SUBSCRIBER_DEPOSITS_CONSTANTS_SUCCESS',
    FAILURE: 'RETRIEVE_SUBSCRIBER_DEPOSITS_CONSTANTS_FAILURE'
};

export const UPDATE_SUBSCRIBER_TREATMENT_CONSTANTS = {
    BEGIN: 'UPDATE_SUBSCRIBER_TREATMENT_CONSTANTS_BEGIN',
    SUCCESS: 'UPDATE_SUBSCRIBER_TREATMENT_CONSTANTS_SUCCESS',
    FAILURE: 'UPDATE_SUBSCRIBER_TREATMENT_CONSTANTS_FAILURE'
};

const RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_EVENTS = [
    RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS.BEGIN,
    RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS.SUCCESS,
    RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS.FAILURE
];

const RETRIEVE_CONVERGENT_BILLER_INVOICES_EVENTS = [
    RETRIEVE_CONVERGENT_BILLER_INVOICES_CONSTANTS.BEGIN,
    RETRIEVE_CONVERGENT_BILLER_INVOICES_CONSTANTS.SUCCESS,
    RETRIEVE_CONVERGENT_BILLER_INVOICES_CONSTANTS.FAILURE
];

const RETRIEVE_CONVERGENT_BILLER_TREATMENT_DETAILS_EVENTS = [
    RETRIEVE_CONVERGENT_BILLER_TREATMENT_DETAILS_CONSTANTS.BEGIN,
    RETRIEVE_CONVERGENT_BILLER_TREATMENT_DETAILS_CONSTANTS.SUCCESS,
    RETRIEVE_CONVERGENT_BILLER_TREATMENT_DETAILS_CONSTANTS.FAILURE
];

const CREATE_CONVERGENT_BILLER_PROMISE_TO_PAY_EVENTS = [
    CREATE_CONVERGENT_BILLER_PROMISE_TO_PAY_CONSTANTS.BEGIN,
    CREATE_CONVERGENT_BILLER_PROMISE_TO_PAY_CONSTANTS.SUCCESS,
    CREATE_CONVERGENT_BILLER_PROMISE_TO_PAY_CONSTANTS.FAILURE
];

const RETRIEVE_CONVERGENT_BILLER_ACCOUNT_DETAILS_EVENTS = [
    RETRIEVE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS.BEGIN,
    RETRIEVE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS.SUCCESS,
    RETRIEVE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS.FAILURE
];

const RETRIEVE_CONVERGENT_BILLER_ACCOUNT_USAGE_DETAILS_EVENTS = [
    RETRIEVE_CONVERGENT_BILLER_ACCOUNT_USAGE_DETAILS_CONSTANTS.BEGIN,
    RETRIEVE_CONVERGENT_BILLER_ACCOUNT_USAGE_DETAILS_CONSTANTS.SUCCESS,
    RETRIEVE_CONVERGENT_BILLER_ACCOUNT_USAGE_DETAILS_CONSTANTS.FAILURE
];

const RETRIEVE_CONVERGENT_BILLER_ORDER_DETAILS_EVENTS = [
    RETRIEVE_CONVERGENT_BILLER_ORDER_DETAILS_CONSTANTS.BEGIN,
    RETRIEVE_CONVERGENT_BILLER_ORDER_DETAILS_CONSTANTS.SUCCESS,
    RETRIEVE_CONVERGENT_BILLER_ORDER_DETAILS_CONSTANTS.FAILURE
];


const RETRIEVE_SUBSCRIBER_DEPOSITS_EVENTS = [
    RETRIEVE_SUBSCRIBER_DEPOSITS_CONSTANTS.BEGIN,
    RETRIEVE_SUBSCRIBER_DEPOSITS_CONSTANTS.SUCCESS,
    RETRIEVE_SUBSCRIBER_DEPOSITS_CONSTANTS.FAILURE
];

const UPDATE_SUBSCRIBER_TREATMENT_EVENTS = [
    UPDATE_SUBSCRIBER_TREATMENT_CONSTANTS.BEGIN,
    UPDATE_SUBSCRIBER_TREATMENT_CONSTANTS.SUCCESS,
    UPDATE_SUBSCRIBER_TREATMENT_CONSTANTS.FAILURE
];

export const SET_ORDER_ITEM_TO_DISPLAY_SERVICE_ATTRIBUTES_FOR = 'SET_ORDER_ITEM_TO_DISPLAY_SERVICE_ATTRIBUTES_FOR';

export const retrieveConvergentBillerSubscriberSummaryPromise = (dispatch, customerId, includePaymentInstrumentAssociations = false) => {
    return ThunkHelper(dispatch, RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveConvergentBillerSubscriberSummary',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            IncludePaymentInstrumentAssociations: includePaymentInstrumentAssociations
        }
    }, {
        customerId: customerId
    });
};

export const retrieveConvergentBillerSubscriberSummary = (customerId, includePaymentInstrumentAssociations = false) => {
    return (dispatch) => {
        return retrieveConvergentBillerSubscriberSummaryPromise(dispatch, customerId, includePaymentInstrumentAssociations);
    };
};

export const retrieveConvergentBillerInvoicesPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, RETRIEVE_CONVERGENT_BILLER_INVOICES_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveConvergentBillerInvoices',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId: customerId
    });
};

export const retrieveSusbcriberDepositsPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, RETRIEVE_SUBSCRIBER_DEPOSITS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveConvergentBillerSubscriberDeposits',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId: customerId
    });
};

export const retrieveConvergentBillerTreatmentDetailsPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, RETRIEVE_CONVERGENT_BILLER_TREATMENT_DETAILS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveConvergentBillerSubscriberTreatmentDetails',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId
    });
};

export const retrieveConvergentBillerTreatmentDetails = (customerId) => {
    return (dispatch) => {
        return retrieveConvergentBillerTreatmentDetailsPromise(dispatch, customerId);
    };
};

export const createConvergentBillerPromiseToPaySchedulePromise = (dispatch, customerId, promiseToPayScheduleRequest) => {
    return ThunkHelper(dispatch, CREATE_CONVERGENT_BILLER_PROMISE_TO_PAY_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/CreateConvergentBillerPromiseToPaySchedule',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: promiseToPayScheduleRequest
    });
};

export const createConvergentBillerPromiseToPaySchedule = (customerId, promiseToPayScheduleRequest) => {
    return (dispatch) => {
        return createConvergentBillerPromiseToPaySchedulePromise(dispatch, customerId, promiseToPayScheduleRequest);
    };
};

export const retrieveConvergentBillerAccountDetailsPromise = (dispatch, customerId, includeOrder = false) => {
    return ThunkHelper(dispatch, RETRIEVE_CONVERGENT_BILLER_ACCOUNT_DETAILS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveConvergentBillerAccountDetails',
        data: {
            IncludeOrder: includeOrder
        },
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId
    });
};

export const retrieveConvergentBillerAccountDetails = (customerId, includeOrder = false) => {
    return (dispatch) => {
        return retrieveConvergentBillerAccountDetailsPromise(dispatch, customerId, includeOrder);
    };
};

export const retrieveConvergentBillerAccountUsageDetailsPromise = (dispatch, customerId, requestObj) => {
    return ThunkHelper(dispatch, RETRIEVE_CONVERGENT_BILLER_ACCOUNT_USAGE_DETAILS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveConvergentBillerAccountUsageDetails',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: requestObj
    }, {
        customerId,
        serviceId: requestObj.ServiceIdentifier ? requestObj.ServiceIdentifier.Value : ''
    });
};
export const retrieveConvergentBillerAccountUsageDetails = (data) => {
    return (dispatch) => {
        return retrieveConvergentBillerAccountUsageDetailsPromise(dispatch, data.customerId, data.request);
    };
};

export const retrieveConvergentBillerOrderDetailsPromise = (dispatch, customerId, orderId) => {
    return ThunkHelper(dispatch, RETRIEVE_CONVERGENT_BILLER_ORDER_DETAILS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOrder',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            OrderId: orderId
        }
    }, {
        customerId,
        orderId
    });
};
export const retrieveConvergentBillerOrderDetails = (customerId, orderId) => {
    return (dispatch) => {
        return retrieveConvergentBillerOrderDetailsPromise(dispatch, customerId, orderId);
    };
};

export const updateSubscriberTreatmentPromise = (dispatch, customerId, treatmentAction, deescalationDate) => {
    return ThunkHelper(dispatch, UPDATE_SUBSCRIBER_TREATMENT_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/UpdateSubscriberTreatment',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            TreatmentAction: treatmentAction,
            DeescalationDate: deescalationDate
        }
    });
};
export const updateSubscriberTreatment = (customerId, treatmentAction, deescalationDate) => {
    return (dispatch) => {
        return updateSubscriberTreatmentPromise(dispatch, customerId, treatmentAction, deescalationDate);
    };
};

export const setOrderItemToDisplayServiceAttributesFor = (itemId) => {
    return {
        type: SET_ORDER_ITEM_TO_DISPLAY_SERVICE_ATTRIBUTES_FOR,
        payload: itemId
    };
};

export const UPDATE_DASHBOARD_ENTITLEMENT_SHARE_STATUS = {
    BEGIN: 'UPDATE_DASHBOARD_ENTITLEMENT_SHARE_STATUS_BEGIN',
    SUCCESS: 'UPDATE_DASHBOARD_ENTITLEMENT_SHARE_STATUS_SUCCESS',
    FAILURE: 'UPDATE_DASHBOARD_ENTITLEMENT_SHARE_STATUS_FAILURE'
};
export const updateDashboardEntitlementShareStatus = (customerId, entitlementIdentifier, shareStatus) => {
    return (dispatch) => {
        return ThunkHelper(dispatch, Object.values(UPDATE_DASHBOARD_ENTITLEMENT_SHARE_STATUS), {
            method: 'post',
            url: 'SubscriberManagement/UpdateEntitlementShareStatus',
            headers: {
                [SUBSCRIBER_ID_HEADER]: customerId
            },
            data: {
                SharedStatus: shareStatus,
                EntitlementIdentifier: entitlementIdentifier
            }
        });
    };
};