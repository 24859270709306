export const RECENT_PRODUCTS_TO_DISPLAY = 7;
export const ACTIVE_PRODUCTS_TO_DISPLAY = 6;

export const ORDER_ITEM_STATUS = {
    ACTIVE: 1
};

export const ORDER_ITEM_TYPE = {
    REMOVE: 3,
    SUSPEND: 4
};

export const END_DATE_TYPES = {
    RENEW: 1,
    EXPIRE: 2
};

export const DASHBOARD_ROUTE = 'index.customercare.customer.dashboard';
export const SUBSCRIPTION_LIST_ROUTE = 'index.customercare.customer.subscriptions.list';
