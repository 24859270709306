import Immutable from 'seamless-immutable';
import {RETRIEVE_CHANGE_OFFER_ELIGIBILITY_RULES} from './actions/customer.offering.actions';

export const INITIAL_STATE = Immutable({
    changeOfferEligibilityRules: [],
    changeOfferEligibilityRulesError: null,
    data: null,
    isLoadingOfferings: false,
    isLoadingChangeOfferEligibilityRules: false,
    offeringError: null
});

export default (state = INITIAL_STATE, {payload={}, type}) => {
    switch (type) {
        case RETRIEVE_CHANGE_OFFER_ELIGIBILITY_RULES.BEGIN:
            return state.set('isLoadingChangeOfferEligibilityRules', true);
        case RETRIEVE_CHANGE_OFFER_ELIGIBILITY_RULES.SUCCESS:
            return state.set('isLoadingChangeOfferEligibilityRules', false)
                .set('changeOfferEligibilityRulesError', null)
                .set('changeOfferEligibilityRules', payload.ChangeOfferEligibilityDetails);
        case RETRIEVE_CHANGE_OFFER_ELIGIBILITY_RULES.FAILURE:
            return state
                .set('changeOfferEligibilityRulesError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity
                })
                .set('isLoadingChangeOfferEligibilityRules', false);
        default:
            return state;
    }
};
