import Immutable from 'seamless-immutable';
import {RETRIEVE_CONVERGENT_BILLER_LEDGER_INVOICE_CONTENT} from './actions/customer.billing.actions';

export const INITIAL_STATE = Immutable({
    data: {},
    invoiceContentError: null,
    isFetchingInvoiceContent: false
});

export default function billingInvoiceContentReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case RETRIEVE_CONVERGENT_BILLER_LEDGER_INVOICE_CONTENT.BEGIN:
            return state.set('isFetchingInvoiceContent', true);
        case RETRIEVE_CONVERGENT_BILLER_LEDGER_INVOICE_CONTENT.SUCCESS:
            return state
                .set('isFetchingInvoiceContent', false)
                .set('data', payload.InvoiceContent)
                .set('invoiceContentError', null);
        case RETRIEVE_CONVERGENT_BILLER_LEDGER_INVOICE_CONTENT.FAILURE:
            return state
                .set('isFetchingInvoiceContent', false)
                .set('invoiceContentError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity
                });
        default:
            return state;
    }
}
