
export const SHOW_SEARCH_PANEL = 'CUSTOMER_SEARCH_SHOW_SEARCH_PANEL';
export const showSearchPanel = () => {
    return {
        type: SHOW_SEARCH_PANEL
    };
};

export const HIDE_SEARCH_PANEL = 'CUSTOMER_SEARCH_HIDE_SEARCH_PANEL';
export const hideSearchPanel = () => {
    return {
        type: HIDE_SEARCH_PANEL
    };
};

export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
export const setSearchType = (payload) => {
    return {
        type: SET_SEARCH_TYPE,
        payload: payload
    };
};

export const CLEAR_RESULTS = 'SEARCH_CLEAR_RESULTS';
export const clearSearchResults = () => {
    return {
        type: CLEAR_RESULTS
    };
};

export const CLEAR_SEARCH_ERROR = 'CLEAR_SEARCH_ERROR';
export const clearSearchError = () => {
    return {
        type: CLEAR_SEARCH_ERROR
    };
};
