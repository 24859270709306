import Immutable from 'seamless-immutable';
import {
    EXECUTE_BILL_RUN_CONSTANTS,
    SUBMIT_USAGE_STATUS,
    RETRIEVE_ACTIVE_WORKFLOWS_STATUS,
    COMPLETE_WORKFLOW_STATUS
} from './actions/demoActions.actions';

export const INITIAL_STATE = Immutable({
    isExecutingBillRun: false,
    isExecutingSimulateUsage: false,
    isRetrievingActiveWorkflows: false,
    isCompletingWorkflow: false,
    activeWorkflows: []
});

export default function demoActionsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case EXECUTE_BILL_RUN_CONSTANTS.BEGIN:
            return state
                .set('isExecutingBillRun', true);
        case EXECUTE_BILL_RUN_CONSTANTS.SUCCESS:
            return state
                .set('isExecutingBillRun', false);
        case EXECUTE_BILL_RUN_CONSTANTS.FAILURE:
            return state
                .set('isExecutingBillRun', false);
        case SUBMIT_USAGE_STATUS.BEGIN:
            return state
                .set('isExecutingSimulateUsage', true);
        case SUBMIT_USAGE_STATUS.SUCCESS:
            return state
                .set('isExecutingSimulateUsage', false);
        case SUBMIT_USAGE_STATUS.FAILURE:
            return state
                .set('isExecutingSimulateUsage', false);
        case RETRIEVE_ACTIVE_WORKFLOWS_STATUS.BEGIN:
            return state
                .set('isRetrievingActiveWorkflows', true);
        case RETRIEVE_ACTIVE_WORKFLOWS_STATUS.SUCCESS:
            return state
                .set('isRetrievingActiveWorkflows', false)
                .merge({
                    activeWorkflows: payload.Items
                }, {
                    deep: true
                });
        case RETRIEVE_ACTIVE_WORKFLOWS_STATUS.FAILURE:
            return state
                .set('isRetrievingActiveWorkflows', false);
        case COMPLETE_WORKFLOW_STATUS.BEGIN:
            return state
                .set('isCompletingWorkflow', true);
        case COMPLETE_WORKFLOW_STATUS.SUCCESS:
            return state
                .set('isCompletingWorkflow', false);
        case COMPLETE_WORKFLOW_STATUS.FAILURE:
            return state
                .set('isCompletingWorkflow', false);
        default:
            return state;
    }
}
