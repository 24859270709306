import {createSelector} from 'reselect';
import {values} from 'ramda';
import {SearchSelector} from './search.selectors';
import {
    getSearchFieldsTemplate
} from '../../components/search/search.constants';
import {generateTitle} from '../../components/giftCard/gift.card.helper';

const GiftCardSearchSelector = createSelector(
    [SearchSelector],
    (search) => {
        return search.giftCards;
    }
);

export const GiftCardSearchMapSelector = createSelector(
    [GiftCardSearchSelector],
    (giftCards) => {
        const giftCardMap = {};
        giftCards.data.forEach((giftCard) => {
            const gc = Object.assign({}, giftCard);
            gc.title = generateTitle(giftCard);
            giftCardMap[giftCard.Id] = gc;
        });
        return giftCardMap;
    }
);

export const GiftCardSearchArraySelector = createSelector(
    [GiftCardSearchMapSelector],
    (giftCardSearchMap) => {
        return giftCardSearchMap ? values(giftCardSearchMap) : null;
    }
);

export const GiftCardSearchRecordCountSelector = createSelector(
    [GiftCardSearchSelector],
    (giftCards) => {
        return giftCards.recordCount;
    }
);

export const GiftCardSearchCurrentPageNumberSelector = createSelector(
    [GiftCardSearchSelector],
    (giftCards) => {
        return giftCards.pageNumber;
    }
);

export const GiftCardSearchTotalPageCountSelector = createSelector(
    [GiftCardSearchSelector],
    (giftCards) => {
        return giftCards.pageCount;
    }
);

const recoverableUIStateSelector = (state) => {
    return state.customercare.recoverableUiState.searchGiftCards.giftCardSearchModel;
};

export const GiftCardSearchModelSelector = createSelector(
    [recoverableUIStateSelector],
    (state) => {
        return state ? state : getSearchFieldsTemplate();
    }
);
