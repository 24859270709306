import {ApiConstants, ThunkHelper} from 'invision-core';

export const FETCH_CUSTOMER_CONTENT_ACTIVITY = {
    BEGIN: 'FETCH_CUSTOMER_CONTENT_ACTIVITY_BEGIN',
    SUCCESS: 'FETCH_CUSTOMER_CONTENT_ACTIVITY_SUCCESS',
    FAILURE: 'FETCH_CUSTOMER_CONTENT_ACTIVITY_FAILURE'
};

const FETCH_CUSTOMER_CONTENT_ACTIVITY_TYPES = [
    FETCH_CUSTOMER_CONTENT_ACTIVITY.BEGIN,
    FETCH_CUSTOMER_CONTENT_ACTIVITY.SUCCESS,
    FETCH_CUSTOMER_CONTENT_ACTIVITY.FAILURE
];

export const CANCEL_CONTENT_ACTIVITY = {
    BEGIN: 'CANCEL_CONTENT_ACTIVITY_TYPES_BEGIN',
    SUCCESS: 'CANCEL_CONTENT_ACTIVITY_TYPES_SUCCESS',
    FAILURE: 'CANCEL_CONTENT_ACTIVITY_TYPES_FAILURE'
};

const CANCEL_CONTENT_ACTIVITY_TYPES = [
    CANCEL_CONTENT_ACTIVITY.BEGIN,
    CANCEL_CONTENT_ACTIVITY.SUCCESS,
    CANCEL_CONTENT_ACTIVITY.FAILURE
];

export const fetchCustomerContentActivityPromise = (dispatch, customerId, additionalParams = {}) => {
    return ThunkHelper(dispatch, FETCH_CUSTOMER_CONTENT_ACTIVITY_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/SearchContentActivity',
        data: Object.assign({
            IncludeDownloads: true,
            SortDirection: 2
        }, additionalParams),
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const fetchCustomerContentActivity = (customerId, additionalParams) => {
    return (dispatch) => {
        return fetchCustomerContentActivityPromise(dispatch, customerId, additionalParams);
    };
};


export const cancelContentActivityPromise = (dispatch, customerId, params) => {
    return ThunkHelper(dispatch, CANCEL_CONTENT_ACTIVITY_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/CloseContentViews',
        data: params,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const cancelContentActivity = (customerId, params = {}) => {
    return (dispatch) => {
        return cancelContentActivityPromise(dispatch, customerId, params);
    };
};

export const SET_ACTIVE_IS_SELECTED = 'SET_ACTIVE_IS_SELECTED';
export const setActiveIsSelected = (activeIsSelected) => {
    return {
        type: SET_ACTIVE_IS_SELECTED,
        payload: activeIsSelected
    };
};

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const setCurrentPage = (currentPage) => {
    return {
        type: SET_CURRENT_PAGE,
        payload: currentPage
    };
};

export const SET_SELECTED_CONTENT_ACTIVITY = 'SET_SELECTED_CONTENT_ACTIVITY';
export const setSelectedContentActivity = (contentActivityId) => {
    return {
        type: SET_SELECTED_CONTENT_ACTIVITY,
        payload: contentActivityId
    };
};

export const SET_CONTENT_ACTIVITY_PAGE_SIZE_PREFERENCE = 'SET_CONTENT_ACTIVITY_PAGE_SIZE_PREFERENCE';
export const setPageSizePreference = (pageSize) => {
    return {
        type: SET_CONTENT_ACTIVITY_PAGE_SIZE_PREFERENCE,
        payload: pageSize
    };
};

export const RESET_CONTENT_ACTIVITY_RECOVERABLE = 'RESET_CONTENT_ACTIVITY_RECOVERABLE';
export const resetContentActivityRecoverable = () => {
    return {
        type: RESET_CONTENT_ACTIVITY_RECOVERABLE
    };
};

export const SHOW_BACK_BANNER = 'SHOW_BACK_BANNER';
export const showBackBanner = (showBanner) => {
    return {
        type: SHOW_BACK_BANNER,
        payload: showBanner
    };
};

export const EMPTY_CONTENT_ACTIVITY_LIST = 'EMPTY_CONTENT_ACTIVITY_LIST';
export const emptyContentActivityList = () => {
    return {
        type: EMPTY_CONTENT_ACTIVITY_LIST
    };
};

export const REMOVE_CONTENT_ACTIVITY_FILTER = 'REMOVE_CONTENT_ACTIVITY_FILTER';
export const removeContentActivityFilter = () => {
    return {
        type: REMOVE_CONTENT_ACTIVITY_FILTER
    };
};
