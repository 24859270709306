import {
    REMOVE_ORDER_HISTORY_FILTER,
    RETURN_AUTO_ORDERS_FILTER_ID,
    UPDATE_ORDER_KIND_FILTER,
    UPDATE_ORDER_STATUS_FILTER,
    UPDATE_ORDER_TYPE_FILTER,
    UPDATE_ORDER_HISTORY_PAGE_NUMBER,
    UPDATE_ORDER_PAGE_SIZE_PREFERENCE,
    UPDATE_ORDER_HISTORY_DATE_RANGE
} from './actions/order.history.filter.actions';
import Immutable from 'seamless-immutable';

export const INITIAL_STATE = Immutable({
    kindFilter: [{
        id: RETURN_AUTO_ORDERS_FILTER_ID,
        value: RETURN_AUTO_ORDERS_FILTER_ID,
        selected: true
    }],
    statusFilter: [],
    typeFilter: [],
    pageNumber: 1,
    pageSizePreference: null,
    startDate: null,
    endDate: null
});

export default function orderHistoryFilterReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case UPDATE_ORDER_KIND_FILTER:
            return state.set('kindFilter', payload);
        case UPDATE_ORDER_STATUS_FILTER:
            return state.set('statusFilter', payload);
        case UPDATE_ORDER_TYPE_FILTER:
            return state.set('typeFilter', payload);
        case UPDATE_ORDER_HISTORY_PAGE_NUMBER:
            return state.set('pageNumber', payload);
        case UPDATE_ORDER_PAGE_SIZE_PREFERENCE:
            return state.set('pageSizePreference', payload);
        case UPDATE_ORDER_HISTORY_DATE_RANGE:
            return state
                .set('startDate', payload.startDate)
                .set('endDate', payload.endDate);
        case REMOVE_ORDER_HISTORY_FILTER:
            return INITIAL_STATE;
        default:
            return state;
    }
}
