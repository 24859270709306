import {curry, compose} from 'ramda';
import Immutable from 'seamless-immutable';
import {
    HIDE_SEARCH_PANEL,
    SET_SEARCH_TYPE,
    SHOW_SEARCH_PANEL,
    CLEAR_RESULTS,
    CLEAR_SEARCH_ERROR
} from './actions/search.actions';
import {
    SEARCH_DROPDOWN_CONSTANTS
} from '../components/search/search.constants';
import customerSearchReducer, {INITIAL_STATE as INITIAL_CUSTOMER_STATE} from './search.customer.reducer';
import giftCardReducer, {INITIAL_STATE as INITIAL_GIFT_CARD_STATE} from './search.giftcard.reducer';
import searchOtherCustomerReducer, {INITIAL_STATE as INITIAL_OTHER_CUSTOMER_STATE} from './search.other.customers.reducer';
import {
    SHOW_RECENT_CUSTOMERS_PANEL
} from './actions/recent.customers.actions';
import {
    CUSTOMER_SEARCH
} from './actions/search.customer.actions';
import {
    GIFT_CARD_SEARCH
} from './actions/search.giftcard.actions';
import {
    COUPON_SEARCH
} from './actions/search.coupon.actions';
import {
    RETRIEVE_HOUSEHOLDS_FOR_CUSTOMER_SEARCH,
    SEARCH_EXISTING_CUSTOMERS
} from './actions/search.other.customers.actions';

export const INITIAL_STATE = Immutable({
    customers: INITIAL_CUSTOMER_STATE,
    giftCards: INITIAL_GIFT_CARD_STATE,
    isFetchingResults: false,
    isPanelVisible: false,
    otherCustomers: INITIAL_OTHER_CUSTOMER_STATE,
    searchError: null,
    searchType: SEARCH_DROPDOWN_CONSTANTS.INDIVIDUAL
});

export default function reducer(state = INITIAL_STATE, action) {
    return compose(
        setSearchState(action),
        setCustomerSearchState(action),
        setGiftCardSearchState(action),
        setSearchOtherCustomerState(action)
    )(state);
}

export function searchReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case CLEAR_SEARCH_ERROR:
            return state.set('searchError', null);
        case CLEAR_RESULTS:
            return INITIAL_STATE.set('isPanelVisible', state.isPanelVisible);
        case HIDE_SEARCH_PANEL:
            return state.set('isPanelVisible', false);
        case SET_SEARCH_TYPE:
            return state.set('searchType', payload);
        case SHOW_SEARCH_PANEL:
            return state.set('isPanelVisible', true);
        case SHOW_RECENT_CUSTOMERS_PANEL:
            return state.set('isPanelVisible', false);
        case CUSTOMER_SEARCH.BEGIN:
        case COUPON_SEARCH.BEGIN:
        case GIFT_CARD_SEARCH.BEGIN:
        case RETRIEVE_HOUSEHOLDS_FOR_CUSTOMER_SEARCH.BEGIN:
        case SEARCH_EXISTING_CUSTOMERS.BEGIN:
            return state
                .set('isFetchingResults', true)
                .set('searchError', null);
        case CUSTOMER_SEARCH.SUCCESS:
        case COUPON_SEARCH.SUCCESS:
        case GIFT_CARD_SEARCH.SUCCESS:
        case RETRIEVE_HOUSEHOLDS_FOR_CUSTOMER_SEARCH.SUCCESS:
        case SEARCH_EXISTING_CUSTOMERS.SUCCESS:
            return state
                .set('isFetchingResults', false)
                .set('searchError', null);
        case CUSTOMER_SEARCH.FAILURE:
        case GIFT_CARD_SEARCH.FAILURE:
        case COUPON_SEARCH.FAILURE:
        case RETRIEVE_HOUSEHOLDS_FOR_CUSTOMER_SEARCH.FAILURE:
        case SEARCH_EXISTING_CUSTOMERS.FAILURE:
            return state
                .set('isFetchingResults', false)
                .set('searchError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        default:
            return state;
    }
}

const setSearchState = curry((action, state) => {
    return searchReducer(state, action);
});

const curriedSetState = curry((path, childReducer, accessorFunc, action, parentState) => {
    return parentState.setIn(path, childReducer(accessorFunc(parentState), action));
});

const setCustomerSearchState = curriedSetState(
    ['customers'],
    customerSearchReducer,
    state => {
        return state.customers;
    }
);

const setGiftCardSearchState = curriedSetState(
    ['giftCards'],
    giftCardReducer,
    state => {
        return state.giftCards;
    }
);

const setSearchOtherCustomerState = curriedSetState(
    ['otherCustomers'],
    searchOtherCustomerReducer,
    state => {
        return state.otherCustomers;
    }
);
