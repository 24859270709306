import {
    SEARCH_CONTRACT_EVENTS
} from './actions/contracts.list.actions';

import Immutable from 'seamless-immutable';

export const INITIAL_STATE = Immutable({
    data: {
        contracts: [],
        pageCount: null,
        pageNumber: null,
        recordCount: null
    },
    searchError: null,
    isFetchingResults: false
});

export default function ContractsListReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case SEARCH_CONTRACT_EVENTS.BEGIN:
            return state
                .set('isFetchingResults', true)
                .set('searchError', null)
                .set('data', {
                    contracts: null,
                    pageCount: 0,
                    pageNumber: 0,
                    recordCount: -1
                });
        case SEARCH_CONTRACT_EVENTS.SUCCESS:
            return state
                .set('isFetchingResults', false)
                .set('searchError', null)
                .set('data', {
                    contracts: payload.Results,
                    pageCount: payload.PageCount || 0,
                    pageNumber: payload.PageNumber || 1,
                    recordCount: payload.RecordCount || 0
                });
        case SEARCH_CONTRACT_EVENTS.FAILURE:
            return state
                .set('isFetchingResults', false)
                .set('searchError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                })
                .set('data', INITIAL_STATE);
        default:
            return state;
    }
}
