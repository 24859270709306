import metadataThunkHelper from 'invision-core/src/api/metadata.thunk.helper';
import {LANGUAGE_HEADER} from 'invision-core/src/api/constants';

export const FETCH_PRODUCT = {
    BEGIN: 'FETCH_PRODUCT_BEGIN',
    SUCCESS: 'FETCH_PRODUCT_SUCCESS',
    FAILURE: 'FETCH_PRODUCT_FAILURE'
};

export const getProductMetadataPromise = (dispatch, productId, customerLanguage) => {
    return metadataThunkHelper(dispatch, Object.values(FETCH_PRODUCT), {
        url: `Product/Id/${productId}`,
        headers: {
            [LANGUAGE_HEADER]: customerLanguage
        }
    }, {
        productId: productId
    });
};

export const getProductMetadata = (productId, language) => {
    return (dispatch) => {
        return getProductMetadataPromise(dispatch, productId, language);
    };
};
