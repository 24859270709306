import Immutable from 'seamless-immutable';
import {RESET_CUSTOMER_RECOVERABLE} from '../actions/customer.actions';
import {
    SORT_FIELD_NAME,
    USAGE_SORT_DIRECTION,
    USAGE_SORT_FIELD
} from '../../components/customer/servicesAndUsage/usageDetails/usage.details.table.constants';
import {EMPTY_OBJECT, EMPTY_ARRAY} from '../constants/common.constants';
import {
    RESET_SEARCH_ENTITLEMENTS_FILTERS,
    CLEAR_USAGE_DETAILS_RECOVERABLE_UI_STATE,
    SEARCH_USAGE_DETAILS_ENTITLEMENT_NAME,
    SET_SELECTED_CALL_CATEGORIES,
    UPDATE_USAGE_DETAILS_CURRENCY_CODE,
    UPDATE_USAGE_DETAILS_PAGINATION_DATA,
    UPDATE_USAGE_DETAILS_SORT,
    UPDATE_USAGE_DETAILS_SUB_EVENT_FILTER,
    UPDATE_USAGE_DETAILS_TOTAL_AMOUNT,
    UPDATE_USAGE_DETAILS_TYPE_DATE_FILTER,
    UPDATE_USAGE_DETAILS_TYPE_NETWORK_FILTER,
    UPDATE_USAGE_SEARCH_ENTITLEMENTS_DATEPICKER,
    UPDATE_USAGE_SEARCH_INCLUDE_EXPIRED,
    UPDATE_USAGE_SELECTED_ENTITLEMENT_DETAILS
} from '../actions/usage.details.actions';

export const INITIAL_STATE = Immutable({
    currencyCode: null,
    callCategories: null,
    endDate: null,
    eventSubTypeFilter: EMPTY_ARRAY,
    entitlementName: null,
    networkTypeFilter: EMPTY_ARRAY,
    paginationData: {
        pageNumber: 1,
        pageSize: 0
    },
    searchEntitlementsDatePicker: {
        shortcut: null,
        startDate: null,
        endDate: null
    },
    includeExpiredEntitlements: false,
    selectedEntitlementDetails: EMPTY_OBJECT,
    sortParams: {
        sortBy: USAGE_SORT_FIELD[SORT_FIELD_NAME.DATE],
        sortDirection: USAGE_SORT_DIRECTION.DESCENDING
    },
    startDate: null,
    totalAmount: 0
});

const {searchEntitlementsDatePicker, includeExpiredEntitlements, ...otherInitialStateProps} = INITIAL_STATE;

export default function usageDetailsReducer(state = INITIAL_STATE, {payload = {},
    type}) {
    switch (type) {
        case UPDATE_USAGE_DETAILS_TYPE_NETWORK_FILTER:
            return state.set('networkTypeFilter', payload);
        case UPDATE_USAGE_DETAILS_SUB_EVENT_FILTER:
            return state.set('eventSubTypeFilter', payload);
        case UPDATE_USAGE_DETAILS_TYPE_DATE_FILTER:
            return state.set('startDate', payload.startDate).set('endDate', payload.endDate);
        case UPDATE_USAGE_DETAILS_CURRENCY_CODE:
            return state.set('currencyCode', payload);
        case UPDATE_USAGE_DETAILS_TOTAL_AMOUNT:
            return state.set('totalAmount', payload);
        case UPDATE_USAGE_DETAILS_PAGINATION_DATA:
            return state.set('paginationData', payload);
        case UPDATE_USAGE_DETAILS_SORT:
            return state.set('sortParams', payload);
        case UPDATE_USAGE_SEARCH_ENTITLEMENTS_DATEPICKER:
            return state.set('searchEntitlementsDatePicker', payload);
        case UPDATE_USAGE_SEARCH_INCLUDE_EXPIRED:
            return state.set('includeExpiredEntitlements', payload);
        case UPDATE_USAGE_SELECTED_ENTITLEMENT_DETAILS:
            return state.set('selectedEntitlementDetails', payload);
        case SET_SELECTED_CALL_CATEGORIES:
            return state.set('callCategories', payload);
        case SEARCH_USAGE_DETAILS_ENTITLEMENT_NAME:
            return state.set('entitlementName', payload);
        case CLEAR_USAGE_DETAILS_RECOVERABLE_UI_STATE:
        case RESET_CUSTOMER_RECOVERABLE:
            return state.merge({
                ...otherInitialStateProps
            });
        case RESET_SEARCH_ENTITLEMENTS_FILTERS:
            return state.merge({
                searchEntitlementsDatePicker,
                includeExpiredEntitlements
            });
        default:
            return state;
    }
}
