import pathOr from 'ramda/src/pathOr';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import i18n from 'invision-core/src/components/i18n/i18n';
import {
    EMPTY_ARRAY,
    EMPTY_OBJECT
} from '../constants/common.constants';
import LocaleKeys from '../../locales/keys';
import {
    MetadataCodeTypeDictionarySelector,
    MetadataCodeTypeSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {getFormattedServiceAttributeValue} from './services.list.selectors.helper';
import {
    getCustomerAdditionalPropertiesSortedByDisplayOrder,
    getCustomerCurrencyName,
    getCustomerHomeCountryName,
    getCustomerInvoiceDetailStatus
} from '../helpers/customer.selectors.helpers';
import {CUSTOMER_CATEGORY} from '../../components/createCustomerPopup/create.customer.popup.constants';
import {codeTypeFilter} from 'invision-core/src/components/codeTypeFilters/code.type.filter';
import {getNameByCountryCode} from '../../components/customer/customer.helper';
import {InvoiceDisplaySelector} from '../../components/createCustomerPopup/customer.form.dropdown.selectors';
import {SubscriberAdditionalPropertiesSelector} from 'invision-core/src/components/metadata/codes/codes.additional.properties.selectors';
import {CustomerSearchResultsViewModelSelector} from './search.other.customers.selectors';
import {CUSTOMER_STATE} from '../../components/shared/constants/customer.constants';

export const NON_TRANSFERABLE_REASON_CODES = {
    NONE: 0,
    BULK: 1,
    CONTRACT: 2,
    PREPAID: 3,
    OPEN_ORDER: 4,
    OPEN_MOBILE_NUMBER_PORT: 5
};

export const TransferWizardStoreSelector = (state) => {
    return state.customercare.recoverableUiState.transferWizard;
};

export const IsFetchingTransferableOfferingsSelector = createImmutableSelector(
    [TransferWizardStoreSelector],
    (transferStore) => {
        return transferStore.isFetchingTransferableOfferings;
    }
);

export const TransferableOfferingsSelector = createImmutableSelector(
    [TransferWizardStoreSelector],
    (transferStore) => {
        return transferStore.offerings;
    }
);

export const OfferingsMetadataSelector = createImmutableSelector(
    [TransferWizardStoreSelector],
    (transferStore) => {
        return transferStore.offeringsMetadata;
    }
);

export const OfferingInstancesSelectedForTransferSelector = createImmutableSelector(
    [TransferWizardStoreSelector],
    (transferStore) => {
        return transferStore.offeringsSelectedForTransfer;
    }
);

const getTransferableReason = (nonTransferableReasonCode) => {
    switch (nonTransferableReasonCode) {
        case NON_TRANSFERABLE_REASON_CODES.BULK:
            return LocaleKeys.TRANSFER.NON_TRANSFERABLE_REASON.BULK;
        case NON_TRANSFERABLE_REASON_CODES.CONTRACT:
            return LocaleKeys.TRANSFER.NON_TRANSFERABLE_REASON.CONTRACT;
        case NON_TRANSFERABLE_REASON_CODES.PREPAID:
            return LocaleKeys.TRANSFER.NON_TRANSFERABLE_REASON.PREPAID;
        case NON_TRANSFERABLE_REASON_CODES.OPEN_ORDER:
            return LocaleKeys.TRANSFER.NON_TRANSFERABLE_REASON.OPEN_ORDER;
        case NON_TRANSFERABLE_REASON_CODES.OPEN_MOBILE_NUMBER_PORT:
            return LocaleKeys.TRANSFER.NON_TRANSFERABLE_REASON.OPEN_MOBILE_NUMBER_PORT;
        default:
            return LocaleKeys.TRANSFER.NON_TRANSFERABLE_REASON.DEFAULT;
    }
};

export const TransferableOfferingsViewModelSelector = createImmutableSelector(
    [
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression),
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        OfferingsMetadataSelector,
        OfferingInstancesSelectedForTransferSelector,
        TransferableOfferingsSelector
    ],
    (regularExpressionCodeTable, serviceAttributeCodeTable, offeringsMetadata, offeringsSelectedForTransfer, transferableOfferings) => {
        return (!transferableOfferings.length ||
            !offeringsMetadata.length ||
            !regularExpressionCodeTable ||
            !serviceAttributeCodeTable) ?
            EMPTY_ARRAY :
            transferableOfferings.map((offering) => {
                const metadata = offeringsMetadata.find((offeringMetadata) => {
                    return offeringMetadata.Id === offering.OrderedOfferingId;
                });

                const services = pathOr(0, ['ServiceAttributeValues', 'length'], offering) ?
                    offering.ServiceAttributeValues.map((service) => {
                        return getFormattedServiceAttributeValue(
                            service.ServiceAttributeId,
                            service.Value,
                            serviceAttributeCodeTable,
                            regularExpressionCodeTable
                        );
                    }) :
                    EMPTY_ARRAY;

                return {
                    currencyCode: metadata.CurrencyCode,
                    displayName: metadata.Name,
                    isSelected: offeringsSelectedForTransfer.some((offeringInstanceId) => {
                        return offeringInstanceId === offering.OfferingInstanceId;
                    }),
                    isTransferable: offering.IsTransferable,
                    offeringId: offering.OrderedOfferingId,
                    offeringInstanceId: offering.OfferingInstanceId,
                    nonTransferableReason: offering.IsTransferable ?
                        '' :
                        i18n.translate(getTransferableReason(offering.NonTransferableReasonCode)),
                    services,
                    thumbnailUrl: metadata.ThumbnailUrl,
                    total: offering.Total,
                };
            });
    }
);

export const IsValidatingSubscriberForTransferSelector = createImmutableSelector(
    [TransferWizardStoreSelector],
    (transferWizardStore) => {
        return transferWizardStore.isValidatingSubscriberForTransfer;
    });

export const SelectedDestinationCustomerSelector = createImmutableSelector(
    [TransferWizardStoreSelector],
    (transferWizardStore) => {
        return transferWizardStore.selectedDestinationCustomer || EMPTY_OBJECT;
    });

export const IsFetchingDestinationCustomerDataSelector = createImmutableSelector(
    [TransferWizardStoreSelector],
    (transferWizardStore) => {
        return transferWizardStore.isFetchingDestinationCustomerData;
    });

export const DestinationCustomerCurrencyNameSelector = createImmutableSelector(
    [SelectedDestinationCustomerSelector, MetadataCodeTypeSelector(CODES.Currency)],
    (customer, subscriberCurrencies) => {
        return getCustomerCurrencyName(customer, subscriberCurrencies);
    });

export const DestinationCustomerHomeCountryNameSelector = createImmutableSelector(
    [SelectedDestinationCustomerSelector, MetadataCodeTypeSelector(CODES.AddressCountry)],
    (customer, homeCountries) => {
        return getCustomerHomeCountryName(customer, homeCountries);
    });

export const DestinationCustomerInvoiceDetailStatus = createImmutableSelector(
    [SelectedDestinationCustomerSelector, MetadataCodeTypeSelector(CODES.InvoiceDetailLevel)],
    (customer, invoiceDetailLevels) => {
        return getCustomerInvoiceDetailStatus(customer, invoiceDetailLevels);
    });

export const DestinationCustomerAdditionalPropertiesSelector = createImmutableSelector(
    [SelectedDestinationCustomerSelector],
    (customer) => {
        return customer.AdditionalProperties || EMPTY_ARRAY;
    });

export const DestinationCustomerInformationViewModelSelector = createImmutableSelector(
    [
        SelectedDestinationCustomerSelector,
        DestinationCustomerCurrencyNameSelector,
        DestinationCustomerHomeCountryNameSelector,
        MetadataCodeTypeSelector(CODES.Language),
        MetadataCodeTypeSelector(CODES.SubscriberType)
    ],
    (customer, customerCurrencyName, customerHomeCountryName, languages, subscriberTypeCodes) => {
        return (customer.Id) ? {
            firstName: customer.FirstName,
            lastName: customer.LastName,
            categoryCode: customer.Category,
            category: i18n.translate((customer.Category === CUSTOMER_CATEGORY.COMMERCIAL) ? LocaleKeys.COMMERCIAL : LocaleKeys.RESIDENTIAL),
            companyName: customer.CompanyName,
            typeCode: customer.SubscriberTypeCode,
            type: codeTypeFilter(customer.SubscriberTypeCode, subscriberTypeCodes),
            homeCountryName: customerHomeCountryName,
            currencyName: customerCurrencyName,
            language: getNameByCountryCode(languages, customer.Language)
        } : EMPTY_OBJECT;
    });

export const DestinationCustomerAccountInfoViewModelSelector = createImmutableSelector(
    [
        SelectedDestinationCustomerSelector,
        InvoiceDisplaySelector,
        DestinationCustomerInvoiceDetailStatus
    ],
    (customer, invoiceDisplays, invoiceDetailStatus) => {
        const invoiceDisplay = pathOr(undefined, ['SubscriberTypeDetails', 'InvoiceDisplay'], customer);
        const hideZeroAmount = pathOr(undefined, ['InvoiceConfiguration', 'HideZeroAmount'], customer);
        return (customer.Id) ? {
            email: customer.Email,
            login: customer.Login,
            state: customer.State,
            stateName: customer.StateName,
            invoiceDisplay,
            invoiceDisplayValue: getInvoiceDisplayValue(invoiceDisplay, invoiceDisplays),
            invoiceDetailStatus,
            billCycle: pathOr(undefined, ['SubscriberTypeDetails', 'BillCycle'], customer),
            hideZeroAmount: i18n.translate(hideZeroAmount ? LocaleKeys.PROFILE_PAGE.ENABLED : LocaleKeys.PROFILE_PAGE.DISABLED)
        } : EMPTY_OBJECT;
    });

const getInvoiceDisplayValue = (invoiceDisplay, invoiceDisplays) => {
    const selectedInvoiceDisplay = invoiceDisplays.find((invoice) => {
        return invoice.Value === invoiceDisplay;
    });
    return pathOr('', ['Name'], selectedInvoiceDisplay);
};


export const DestinationCustomerAdditionalPropertiesSortedByDisplayOrderSelector = createImmutableSelector(
    [
        DestinationCustomerAdditionalPropertiesSelector,
        SubscriberAdditionalPropertiesSelector
    ],
    (customerAdditionalProperties, subscriberAdditionalPropertyFields) => {
        return getCustomerAdditionalPropertiesSortedByDisplayOrder(customerAdditionalProperties, subscriberAdditionalPropertyFields);
    });

const getDisabledCustomerStateTooltipObject = () => {
    return {
        [CUSTOMER_STATE.ANONYMIZED]: {
            tooltipMessage: i18n.translate(LocaleKeys.TRANSFER.CONFIG_STEP.SEARCH_CUSTOMER_DIALOG.ANONYMIZED_DISABLED_TOOLTIP)
        },
        [CUSTOMER_STATE.BLOCKLISTED]: {
            tooltipMessage: i18n.translate(LocaleKeys.TRANSFER.CONFIG_STEP.SEARCH_CUSTOMER_DIALOG.BLOCKLISTED_DISABLED_TOOLTIP)
        },
        [CUSTOMER_STATE.PROSPECT_LITE]: {
            tooltipMessage: i18n.translate(LocaleKeys.TRANSFER.CONFIG_STEP.SEARCH_CUSTOMER_DIALOG.PROSPECT_LITE_DISABLED_TOOLTIP)
        }
    };
};

export const DestinationCustomerSearchResultsViewModelSelector = createImmutableSelector(
    [CustomerSearchResultsViewModelSelector],
    (customerSearchResults) => {
        return customerSearchResults.length ? customerSearchResults.map((customerSearchResult) => {
            return Object.assign({}, customerSearchResult, {
                isDisabled: customerSearchResult.isDisabled || !!getDisabledCustomerStateTooltipObject()[customerSearchResult.state],
                tooltipMessage: customerSearchResult.tooltipMessage || (getDisabledCustomerStateTooltipObject()[customerSearchResult.state] || {}).tooltipMessage
            });
        }) : EMPTY_ARRAY;
    });
