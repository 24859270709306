import Immutable from 'seamless-immutable';
import {
    indexOf,
    is,
    slice,
    without
} from 'ramda';
import {
    getNextStepIndex,
    getPreviousStepIndex
} from './wizard.helper';

export default (allStepsKeys) => {
    return {
        initialState: Immutable({
            currentStepIndex: 0,
            completedSteps: {
                [allStepsKeys[0]]: allStepsKeys[0]
            },
            currentStepIsInvalid: false,
            selectedTabIndex: 0,
            skippedSteps: []
        }),
        goToNextStep: (state) => {
            if (state.currentStepIndex !== allStepsKeys.length - 1) {
                return state.merge({
                    previousStepIndex: state.currentStepIndex,
                    currentStepIndex: getNextStepIndex(allStepsKeys, state.skippedSteps, state.currentStepIndex),
                    currentStepIsInvalid: false,
                    completedSteps: state.completedSteps.merge({
                        [allStepsKeys[state.currentStepIndex]]: allStepsKeys[state.currentStepIndex]
                    })
                }, {
                    deep: true
                });
            }
            return state;
        },
        goToPreviousStep: (state) => {
            const destinationStepIndex = getPreviousStepIndex(allStepsKeys, state.completedSteps, state.currentStepIndex);
            let skippedStepsBeforeDestinationStep = [];

            if (null !== destinationStepIndex) {
                skippedStepsBeforeDestinationStep = state.skippedSteps.filter((skippedStepKey) => {
                    const skippedStepIndex = allStepsKeys.findIndex((allStepKey) => {
                        return allStepKey === skippedStepKey;
                    });

                    return -1 !== skippedStepIndex ? destinationStepIndex > skippedStepIndex : false;
                });
            }

            return state.merge({
                previousStepIndex: state.currentStepIndex,
                currentStepIndex: destinationStepIndex,
                currentStepIsInvalid: false,
                skippedSteps: skippedStepsBeforeDestinationStep
            }, {
                deep: true
            });
        },
        goToStep: (state, payload) => {
            const destinationStepKey = is(Array, payload) ? payload[0] : payload;
            const shouldSkipCurrentStep = is(Array, payload) ? payload[1] : false;
            const destinationStepIndex = indexOf(destinationStepKey, allStepsKeys);
            if (destinationStepIndex - state.currentStepIndex > 0) {
                const startSkipIndex = shouldSkipCurrentStep ? state.currentStepIndex : state.currentStepIndex + 1;
                const skippedSteps = slice(startSkipIndex, destinationStepIndex, allStepsKeys);
                let completedSteps = shouldSkipCurrentStep ?
                    state.completedSteps.without(allStepsKeys[state.currentStepIndex]) :
                    state.completedSteps.merge({
                        [allStepsKeys[state.currentStepIndex]]: allStepsKeys[state.currentStepIndex]
                    });

                completedSteps = without(skippedSteps, completedSteps);
                return state.merge({
                    currentStepIndex: destinationStepIndex,
                    currentStepIsInvalid: false,
                    skippedSteps,
                    completedSteps
                });
            } else if (destinationStepIndex !== -1) {
                return state.merge({
                    currentStepIndex: destinationStepIndex,
                    currentStepIsInvalid: false
                });
            }
            return state;
        },
        setCurrentStepIsInvalid: (state, payload) => {
            return state.merge({
                currentStepIsInvalid: payload
            }, {
                deep: true
            });
        },
        setSelectedOrderTab: (state, payload) => {
            return state.set('selectedTabIndex', payload);
        }
    };
};
