import {
    SET_CONTRACTS_SEARCH_CRITERIA
} from '../actions/contracts.list.actions';

import Immutable from 'seamless-immutable';

export const INITIAL_STATE = Immutable({
    searchCriteria: {
        CreatedDateRange: null,
        CurrencyCode: null,
        ModifiedDateRange: null,
        PageNumber: 1,
        PageSize: 1000,
        IsActive: true,
        SearchFor: '',
        SortBy: 0,
        SortDirection: 1,
        SubtenantIds: [],
        TargetingSegmentIds: []
    }
});

export default function ContractsListReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case SET_CONTRACTS_SEARCH_CRITERIA:
            return state
                .set('searchCriteria', payload);
        default:
            return state;
    }
}