import curry from 'ramda/src/curry';
import Immutable from 'seamless-immutable';
import {
    isNil,
    memoize
} from 'lodash';

import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {EMPTY_OBJECT} from 'invision-core/src/constants/common.constants';
import {
    INTEGRATION_TYPES_BY_NAME as CUSTOM_WIDGET_TYPES,
    STATUSES as CUSTOM_WIDGET_STATUSES
} from 'invision-core/src/components/customWidget/custom.widget.constants';

// Wrapper to enable currying on all custom widget selectors
export const createCurriedCustomWidgetSelector = (selectors, selectorFunction) => {
    const curriedCustomWidgetDictionary = {};

    return curry((widgetId, state) => {

        // Cache lookup of each selector per widget ID since custom widget selectors are shared
        if (!curriedCustomWidgetDictionary[widgetId]) {
            curriedCustomWidgetDictionary[widgetId] = createImmutableSelector.call(this, selectors, selectorFunction);
        }

        return curriedCustomWidgetDictionary[widgetId](widgetId, state);
    });
};

const customWidgetsSelectorsMap = {};
export const CurriedCustomWidgetSelector = curry((widgetId, state) => {

    // Cache lookup of the result of each codeType metadata
    if (!customWidgetsSelectorsMap[widgetId]) {
        customWidgetsSelectorsMap[widgetId] = memoize((customWidgetDetails) => {
            return customWidgetDetails || EMPTY_OBJECT;
        });
    }

    return customWidgetsSelectorsMap[widgetId](state.customercare.customWidgets.widgets[widgetId]);
});

const UNITLESS_DIMENSION_REGEX = /\d$/;
export const CustomWidgetSelector = createCurriedCustomWidgetSelector(
    [CurriedCustomWidgetSelector],
    (customWidgetDetails) => {
        if (customWidgetDetails && !isNil(customWidgetDetails.height)) {
            return Immutable.merge(customWidgetDetails, {
                height: UNITLESS_DIMENSION_REGEX.test(customWidgetDetails.height) ?
                    `${customWidgetDetails.height}px` :
                    customWidgetDetails.height
            }, {
                deep: true
            });
        } else {
            return customWidgetDetails;
        }
    }
);

export const CustomWidgetHasApiIntegrationSelector = createCurriedCustomWidgetSelector(
    [CurriedCustomWidgetSelector],
    (customWidgetDetails) => {
        return customWidgetDetails.integrationTypeId === CUSTOM_WIDGET_TYPES.API.id;
    }
);

export const CustomWidgetIsErroredSelector = createCurriedCustomWidgetSelector(
    [CurriedCustomWidgetSelector],
    (customWidgetDetails) => {
        return customWidgetDetails.statusId === CUSTOM_WIDGET_STATUSES.errored;
    }
);

export const CustomWidgetIsExpandedSelector = createCurriedCustomWidgetSelector(
    [CurriedCustomWidgetSelector],
    (customWidgetDetails) => {
        return !!(customWidgetDetails.isExpanded);
    }
);

export const CustomWidgetIsLoadingSelector = createCurriedCustomWidgetSelector(
    [CurriedCustomWidgetSelector],
    (customWidgetDetails) => {
        return customWidgetDetails.statusId === CUSTOM_WIDGET_STATUSES.processing;
    }
);
