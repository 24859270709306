export const INIT_CUSTOM_WIDGET = 'INIT_CUSTOM_WIDGET';
export const SET_CUSTOM_WIDGET_HEIGHT = 'SET_CUSTOM_WIDGET_HEIGHT';
export const SET_CUSTOM_WIDGET_IS_EXPANDED = 'SET_CUSTOM_WIDGET_IS_EXPANDED';
export const SET_CUSTOM_WIDGET_STATUS = 'SET_CUSTOM_WIDGET_STATUS';

export const initCustomWidget = (payload) => {
    return {
        payload,
        type: INIT_CUSTOM_WIDGET
    };
};

export const setCustomWidgetHeight = (payload) => {
    return {
        payload,
        type: SET_CUSTOM_WIDGET_HEIGHT
    };
};

export const setCustomWidgetIsExpanded = (payload) => {
    return {
        payload,
        type: SET_CUSTOM_WIDGET_IS_EXPANDED
    };
};

export const setCustomWidgetStatus = (payload) => {
    return {
        payload,
        type: SET_CUSTOM_WIDGET_STATUS
    };
};
