import clone from 'ramda/src/clone';
import countBy from 'ramda/src/countBy';
import filter from 'ramda/src/filter';
import find from 'ramda/src/find';
import findLast from 'ramda/src/findLast';
import isNil from 'ramda/src/isNil';
import pathOr from 'ramda/src/pathOr';
import pipe from 'ramda/src/pipe';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import sortBy from 'ramda/src/sortBy';
import equals from 'ramda/src/equals';

import {createBillingAddressString} from 'invision-core/src/utilities/address.helper';
import i18n from 'invision-core/src/components/i18n/i18n';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {MetadataCodeTypeSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {PaymentInstrumentTypeSelector} from 'invision-core/src/components/metadata/codes/codes.selectors.deprecated';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';

import LocaleKeys from '../../locales/keys';
import Immutable from 'seamless-immutable';
import {createSelector} from 'reselect';
import {SelectedCustomerSelector} from './customer.selectors';
import {
    formatCardName,
    isExpired
} from '../../utilities/credit.card.helper';
import {
    getDefaultPaymentMethodFromPaymentInstruments,
    getDefaultPaymentMethodFromPaymentInstrumentsWithAutoPay,
    isCreatablePaymentInstrumentType,
    isAutoPaySupportedPaymentInstrumentType
} from '../helpers/ewallet.reducer.helper';
import {
    SUPPORTED_OTT_PAYMENT_INSTRUMENT_TYPES,
    SUPPORTED_PAYMENT_INSTRUMENT_TYPES
} from '../../components/customer/ewallet/ewallet.constants';
import {getFormattedExpirationForCard} from '../../components/customer/ewallet/ewallet.helpers';
import {
    parseAdditionalProperties,
    PaymentInstrumentConfigurationAdditionalProperties,
    paymentInstrumentConfigurationAdditionalPropertyDescriptors,
    PaymentInstrumentTypeAdditionalProperties,
    paymentInstrumentTypeAdditionalPropertyDescriptors
} from '../helpers/payment.instrument.additional.property.parser';
import {E_WALLET_ADMIN_ACCESS} from '../../security.attributes';
import {hasPrepaidBalance} from '../../utilities/payment.instrument.helper';
import * as SelectorHelper from './customer.ewallet.selectors.helpers';
import {MakePaymentInstrumentSelector} from './../../components/customer/makePayment/make.payment.selectors';
import {PaymentInfoEditSelector} from './new.connect.wizard.selectors';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {
    isPaymentInstrumentTypeValueOptionSelected
} from './modify.payment.method.popup.selectors.helpers';

const CONVERGENT_BILLER_INVOICE = Number(SUPPORTED_OTT_PAYMENT_INSTRUMENT_TYPES.CONVERGENT_BILLER_INVOICE);
const EMPTY_ARRAY = [];
const eWalletSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.eWallet;
    }
);

const recoverableUIStateSelector = (state) => {
    return state.customercare.recoverableUiState.ewallet;
};

const eWalletDataSelector = createSelector(
    [eWalletSelector],
    (eWallet) => {
        return eWallet.data;
    }
);

export const PaymentInstrumentsSelector = createSelector(
    [eWalletDataSelector],
    (eWalletData) => {
        return eWalletData.paymentInstruments ? eWalletData.paymentInstruments.map((paymentInstrument) => {
            let metaLine;
            if (paymentInstrument.Type === parseInt(SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD, 10)) {
                const shortDate = getFormattedExpirationForCard(paymentInstrument.CreditCard);
                metaLine = i18n.translate(LocaleKeys.HOUSEHOLD.PRIVILEGES.EXPIRES_ON, {
                    shortDate
                });
            } else {
                metaLine = paymentInstrument.TypeName;
            }
            return paymentInstrument.set('metaLine', metaLine);
        }) : null;
    }
);

export const MutablePaymentInstrumentsSelector = createSelector(
    [PaymentInstrumentsSelector],
    (paymentInstruments) => {
        return paymentInstruments ? paymentInstruments.asMutable({
            deep: true
        }) : EMPTY_ARRAY;
    }
);

export const PaymentTypesSelector = createSelector(
    [
        MetadataCodeTypeSelector(CODES.CreditCardType),
        MetadataCodeTypeSelector(CODES.ExternalBillType),
        MetadataCodeTypeSelector(CODES.ExternalGiftCardType),
        MetadataCodeTypeSelector(CODES.PaymentInstrumentType)
    ],
    (creditCardTypes, externalBillTypes, externalGiftCardTypes, paymentInstrumentTypes) => {
        const allTypes = [].concat(creditCardTypes, externalBillTypes, externalGiftCardTypes, paymentInstrumentTypes);
        return {
            allTypes: allTypes,
            creditCardTypes: creditCardTypes,
            externalBillTypes: externalBillTypes,
            externalGiftCardTypes: externalGiftCardTypes,
            paymentInstrumentTypes: paymentInstrumentTypes
        };
    }
);

export const ActionablesPerPaymentTypeSelector = createSelector(
    [
        MetadataCodeTypeSelector(CODES.PaymentInstrumentType)
    ],
    (paymentInstrumentTypes) => {
        return (paymentInstrumentTypes || []).reduce((lookup, paymentType) => {
            const typeId = paymentType.Value;
            const model = new PaymentInstrumentTypeAdditionalProperties();
            lookup[typeId] = parseAdditionalProperties(model, paymentType.AdditionalProperties, paymentInstrumentTypeAdditionalPropertyDescriptors);
            return lookup;
        }, {});
    }
);

export const PaymentInstrumentsWithTypeNamesViewModel = createSelector(
    [
        PaymentInstrumentsSelector,
        PaymentTypesSelector,
        ActionablesPerPaymentTypeSelector
    ],
    (paymentInstruments, paymentTypes, actionablesPerPermissionType) => {
        if (!paymentInstruments) {
            return [];
        }
        return [].concat(paymentInstruments)
            .map((customerPaymentInstrument) => {
                return SelectorHelper.paymentInstrumentToViewModel(customerPaymentInstrument, paymentTypes, actionablesPerPermissionType);
            })
            .sort(SelectorHelper.comparePaymentInstrumentViewModelByTypeAndCreation);
    }
);

export const PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector = createSelector(
    PaymentInstrumentsWithTypeNamesViewModel,
    (paymentInstruments) => {
        if (!paymentInstruments) {
            return [];
        }

        const result = [].concat(paymentInstruments)
            .filter((paymentInstrument) => {
                return paymentInstrument.actionables.canOrder;
            })
            .sort(SelectorHelper.comparePaymentInstrumentViewModelByTypeAndCreation);
        return result;
    }
);

export const PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesAutoPayViewModelSelector = createSelector(
    PaymentInstrumentsWithTypeNamesViewModel,
    (paymentInstruments) => {
        if (!paymentInstruments) {
            return [];
        }

        return [].concat(paymentInstruments)
            .filter((paymentInstrument) => {
                return paymentInstrument.Type === convertStringToNumber(SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CURRENCY_ENTITLEMENT) ||
                    (paymentInstrument.actionables.canOrder &&  isAutoPaySupportedPaymentInstrumentType(paymentInstrument.Type));
            })
            .sort(SelectorHelper.comparePaymentInstrumentViewModelByTypeAndCreation);
    }
);

export const PaymentInstrumentConfigurationSelector = createSelector(
    [
        MetadataCodeTypeSelector(CODES.PaymentInstrumentConfiguration)
    ],
    (paymentInstrumentConfigurationAdditionalProperties) => {
        return (paymentInstrumentConfigurationAdditionalProperties || []).map((paymentConfig) => {
            const model = new PaymentInstrumentConfigurationAdditionalProperties();
            const additionalProperties = parseAdditionalProperties(model, paymentConfig.AdditionalProperties, paymentInstrumentConfigurationAdditionalPropertyDescriptors);
            return additionalProperties;
        });
    }
);

export const AvailableTypesMapSelector = createSelector(
    [eWalletDataSelector],
    (eWalletData) => {
        //This selector should return null if null is what it has to return.
        //PLEASE DO NOT update this selector to return an EMPTY OBJECT if null because
        //null means it hasn't been set and EMPTY OBJECT means that all payment types (non-removed) are available for use on this distribution channel
        return eWalletData.availableTypesMap;
    }
);

export const IsAllPaymentInstrumentTypesAvailableSelector = createImmutableSelector(
    [AvailableTypesMapSelector],
    (availableTypes) => {
        //If availableTypesMap is an empty object then it means that all payment
        //instrument types (non-removed) are available for use on this distribution channel
        return equals(availableTypes, {});
    }
);

export const PaymentInstrumentTypeValueOptions = createImmutableSelector(
    [
        PaymentInstrumentTypeSelector,
        ActionablesPerPaymentTypeSelector,
        PaymentInfoEditSelector,
        AvailableTypesMapSelector,
        IsAllPaymentInstrumentTypesAvailableSelector
    ],
    (paymentInstrumentTypes, actionablesPerPaymentType, paymentInfoEdit, availablePaymentTypesMap, isAllPaymentInstrumentTypesAvailable) => {
        const acceptedPaymentInstruments = paymentInstrumentTypes.filter((paymentInstrumentType) => {
            const actionables = actionablesPerPaymentType[paymentInstrumentType.Value];
            const allowCreate = actionables ? actionables.userCreatable : false;
            const isNotOptedOut = isCreatablePaymentInstrumentType(paymentInstrumentType.Value);
            return allowCreate && isNotOptedOut && (isAllPaymentInstrumentTypesAvailable ||
                !!pathOr(null, [`${paymentInstrumentType.Value}`], availablePaymentTypesMap));
        });
        return acceptedPaymentInstruments.map((paymentInstrumentType) => {
            return {
                text: paymentInstrumentType.Name,
                selected: isPaymentInstrumentTypeValueOptionSelected((paymentInfoEdit || {}).Type,
                    paymentInstrumentType, acceptedPaymentInstruments[0].Value),
                paymentInstrumentType
            };
        });
    });

export const PaymentInstrumentTypeValueOptionsWithAutoPay = createSelector([
    PaymentInstrumentsSelector,
    PaymentInstrumentTypeSelector,
    PaymentInstrumentConfigurationSelector,
    ActionablesPerPaymentTypeSelector,
    PaymentInfoEditSelector,
    AvailableTypesMapSelector,
    IsAllPaymentInstrumentTypesAvailableSelector
], (paymentInstruments, paymentInstrumentTypes,
    paymentInstrumentConfigurations, actionablesPerPaymentType, paymentInfoEdit, availablePaymentTypesMap, isAllPaymentInstrumentTypesAvailable) => {
    const paymentInstrumentTypeCounts = countBy(prop('Type'))(paymentInstruments);
    const activePaymentInstrumentTypeCounts = countBy(prop('Type'))(filter(propEq(1, 'Status'))(paymentInstruments));

    const acceptedPaymentInstruments = paymentInstrumentTypes.filter((paymentInstrumentType) => {
        const typeCount = paymentInstrumentTypeCounts[paymentInstrumentType.Value];
        const typeActiveCount = activePaymentInstrumentTypeCounts[paymentInstrumentType.Value];
        const typeConfiguration = pipe(
            sortBy(prop('Global')),
            findLast(propEq(paymentInstrumentType.Value, 'paymentInstrumentTypeCode'))
        )(paymentInstrumentConfigurations);
        const isMax = typeConfiguration &&
            typeConfiguration.alertMax > 0 &&
            typeCount >= typeConfiguration.alertMax;
        const isMaxActive = typeConfiguration &&
            typeConfiguration.alertMaxActive > 0 &&
            typeActiveCount >= typeConfiguration.alertMaxActive;
        const actionables = actionablesPerPaymentType[paymentInstrumentType.Value];
        const allowCreate = actionables ? actionables.userCreatable : false;
        const isAutoPay = typeConfiguration ? typeConfiguration.supportsAutoPay : false;
        const isNotOptedOut = isCreatablePaymentInstrumentType(paymentInstrumentType.Value);
        return isAutoPay && allowCreate && isNotOptedOut && !(isMax || isMaxActive) &&
            (isAllPaymentInstrumentTypesAvailable || !!pathOr(null, [`${paymentInstrumentType.Value}`], availablePaymentTypesMap));
    });
    return acceptedPaymentInstruments.map((paymentInstrumentType) => {
        return {
            text: paymentInstrumentType.Name,
            selected: isPaymentInstrumentTypeValueOptionSelected((paymentInfoEdit || {}).Type, paymentInstrumentType,
                acceptedPaymentInstruments[0].Value),
            paymentInstrumentType
        };
    });
});

/**
 * Returns Payment Instruments with positive prepaid balance and others that don't track prepaid balance.
 * @type {Reselect.Selector<any, any>}
 * @returns Array<PAYMENT_INSTRUMENT_SUMMARY>
 * @see PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector
 */
export const PaymentInstrumentsCanBeUsedForOrderingContainingBalanceSelector = createSelector(
    [
        PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector
    ],
    (paymentInstruments) => {
        return paymentInstruments.filter((paymentInstrument) => {
            return hasPrepaidBalance(paymentInstrument) && !isExpired(paymentInstrument);
        });
    }
);

export const ShareablePaymentInstrumentsWithTypeNamesViewModel = createSelector(
    [PaymentInstrumentsWithTypeNamesViewModel],
    (paymentInstruments) => {
        if (!paymentInstruments) {
            return [];
        }

        return paymentInstruments.filter((paymentInstrument) => {
            return paymentInstrument.actionables.isShareable;
        });
    }
);

export const PaymentInstrumentsWithoutBillToInvoiceSelector = createSelector(
    [PaymentInstrumentsCanBeUsedForOrderingContainingBalanceSelector],
    (paymentInstruments) => {
        return paymentInstruments && paymentInstruments.length ? paymentInstruments.filter(instrument => {
            return instrument.Type !== CONVERGENT_BILLER_INVOICE;
        }) : [];
    }
);

export const SelectedItemIdSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.filterData.selectedItemId;
    }
);

export const SelectedTypeIdSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.selectedTypeId;
    }
);

export const DefaultPaymentMethodSelector = createSelector(
    [PaymentInstrumentsSelector],
    (paymentInstruments) => {
        if (paymentInstruments && paymentInstruments.length) {
            return getDefaultPaymentMethodFromPaymentInstruments(paymentInstruments);
        }
        return null;
    }
);

export const DefaultPaymentMethodAutoPaySelector = createSelector(
    [PaymentInstrumentsSelector],
    (paymentInstruments) => {
        if (paymentInstruments && paymentInstruments.length) {
            return getDefaultPaymentMethodFromPaymentInstrumentsWithAutoPay(paymentInstruments);
        }
        return null;
    }
);

export const SelectedPaymentMethodSelector = createSelector(
    [
        PaymentInstrumentsSelector,
        SelectedCustomerSelector
    ],
    (paymentInstruments, selectedCustomer) => {
        const selectedPayment = pathOr(null, ['eWallet', 'data', 'selectedPaymentInstrument'], selectedCustomer);
        const selectedPaymentId = pathOr(null, ['eWallet', 'data', 'selectedPaymentInstrument', 'Id'], selectedCustomer);
        if (!isNil(selectedPaymentId)) {
            const matchingInstrument = paymentInstruments && paymentInstruments.length && find(propEq(selectedPaymentId, 'Id'))(paymentInstruments);
            if (matchingInstrument) {
                return matchingInstrument;
            } else if (selectedPayment) {
                return selectedPayment;
            }
        }
        return null;
    }
);



export const AutoPayFlagStatusSelector  = createSelector(
    [eWalletSelector],
    (eWalletData) => {
        return eWalletData.isAutoPayActive;
    });

export const PaymentMethodWithConvergentBillerAccountsSelector = createSelector(
    [DefaultPaymentMethodAutoPaySelector, SelectedPaymentMethodSelector, AutoPayFlagStatusSelector],
    (defaultConvergentAccount, selectedPaymentInstrument, autoPayFlagStatus) => {
        if (defaultConvergentAccount && selectedPaymentInstrument) {
            const selectedPayment = clone(selectedPaymentInstrument);
            if (selectedPayment.ConvergentBillerPaymentInstrumentAccounts && selectedPayment.ConvergentBillerPaymentInstrumentAccounts.length) {
                selectedPayment.ConvergentBillerPaymentInstrumentAccounts[0].AutoPay = autoPayFlagStatus;
            } else if (defaultConvergentAccount && defaultConvergentAccount.ConvergentBillerPaymentInstrumentAccounts.length) {
                selectedPayment.ConvergentBillerPaymentInstrumentAccounts = clone(defaultConvergentAccount.ConvergentBillerPaymentInstrumentAccounts);
                selectedPayment.ConvergentBillerPaymentInstrumentAccounts.AutoPay = autoPayFlagStatus;
            } else {
                selectedPayment.ConvergentBillerPaymentInstrumentAccounts = [{
                    AutoPay: autoPayFlagStatus
                }];
            }
            return selectedPayment;
        }
        return defaultConvergentAccount;
    }
);

export const SelectedPaymentMethodWithTypeNamesViewModelSelector = createSelector(
    [
        SelectedPaymentMethodSelector,
        PaymentTypesSelector,
        ActionablesPerPaymentTypeSelector
    ],
    (selectedPayment, paymentTypes, actionablesPerPermissionType) => {

        if (!selectedPayment || !selectedPayment.Id) {
            return selectedPayment;
        }

        return SelectorHelper.paymentInstrumentToViewModel(selectedPayment, paymentTypes, actionablesPerPermissionType);
    }
);

export const NewPaymentMethodWithTypeNamesAndMetadataViewModelSelector = createSelector(
    [
        MakePaymentInstrumentSelector,
        PaymentTypesSelector,
        ActionablesPerPaymentTypeSelector
    ],
    (newPaymentInstrument, paymentTypes, actionablesPerPermissionType) => {

        if (!newPaymentInstrument || !newPaymentInstrument.Type) {
            return newPaymentInstrument;
        }

        return SelectorHelper.paymentInstrumentToViewModel(newPaymentInstrument, paymentTypes, actionablesPerPermissionType);
    }
);

export const SelectedPaymentMethodConvergentBillerPaymentInstrumentAccountsSelector = createSelector(
    [eWalletDataSelector],
    (eWalletData) => {
        return eWalletData.convergentBillerAccounts ? eWalletData.convergentBillerAccounts : EMPTY_ARRAY;
    }
);

export const PaymentInstrumentSummariesSelector = createSelector(
    [
        PaymentInstrumentsSelector,
        MetadataCodeTypeSelector(CODES.CreditCardType),
        SelectedPaymentMethodSelector
    ],
    (paymentInstruments, creditCardTypes, selectedPaymentMethod) => {

        return paymentInstruments.map((paymentInstrument) => {
            if (!paymentInstrument.BillingAddress || !paymentInstrument.CreditCard) {
                return {};
            }

            const tableRow = {};
            tableRow.paymentInstrument = paymentInstrument;
            tableRow.yourPaymentMethods = formatPaymentCell(paymentInstrument.CreditCard, creditCardTypes);
            tableRow.nameOnCard = paymentInstrument.CreditCard.NameOnCard;
            tableRow.expires = `${paymentInstrument.CreditCard.ExpirationMonth}/${paymentInstrument.CreditCard.ExpirationYear}`;
            tableRow.address =  createBillingAddressString(paymentInstrument.BillingAddress);
            tableRow.edit = i18n.translate(LocaleKeys.MAKE_PAYMENT.EDIT);
            tableRow.isActive = selectedPaymentMethod ? paymentInstrument.Id === selectedPaymentMethod.Id : false;
            return tableRow;
        });
    }
);

const formatPaymentCell = (creditCard, creditCardTypes) => {
    if (!creditCard || !creditCard.AccountNumber) {
        return i18n.translate(LocaleKeys.NO_RESULTS);
    }

    return formatCardName(creditCardTypes, creditCard.Type, creditCard.AccountNumber);
};

export const EwalletErrorSelector = createSelector(
    [eWalletSelector],
    (eWallet) => {
        return eWallet.eWalletError;
    }
);

export const EwalletErrorsSelector = createSelector(
    [eWalletSelector],
    (eWallet) => {
        if (eWallet.eWalletError && eWallet.eWalletError.message) {
            return [eWallet.eWalletError.message];
        } else {
            return [];
        }
    }
);

export const EwalletRetrievalErrorSelector = createSelector(
    [eWalletSelector],
    (eWallet) => {
        return eWallet.eWalletRetrievalError;
    }
);

export const PaymentInstrumentDetailSelector = createSelector(
    [eWalletDataSelector],
    (eWalletData) => {
        return eWalletData.paymentInstrumentDetail;
    }
);

export const PaymentIsInvalidSelector = createSelector(
    [eWalletDataSelector],
    (eWalletData) => {
        return eWalletData.paymentIsInvalid;
    }
);

export const PaymentIsCreatingOrEditingDataSelector = createSelector(
    [eWalletSelector],
    (eWallet) => {
        return eWallet.isCreatingOrEditingData;
    }
);

export const PaymentIsRemovingDataSelector = createSelector(
    [eWalletSelector],
    (eWallet) => {
        return eWallet.isRemovingPaymentInstrument;
    }
);

export const PaymentIsUpdatingAutoPaySelector = createSelector(
    [eWalletSelector],
    (eWallet) => {
        return eWallet.isUpdatingAutoPayConfiguration;
    }
);

export const PaymentIsFetchingDataSelector = createSelector(
    [eWalletSelector],
    (eWallet) => {
        return eWallet.isFetchingData;
    }
);

export const IsRetrievingEwalletDataSelector = createSelector(
    [eWalletSelector],
    (eWallet) => {
        return eWallet.isRetrievingWallet;
    }
);

export const IsShowingPaymentInstrumentPanelSelector = createSelector(
    [eWalletSelector],
    (eWallet) => {
        return eWallet.isShowingPaymentInstrumentPanel;
    }
);

export const IsMakingPaymentSelector = createSelector(
    [eWalletSelector],
    (eWallet) => {
        return eWallet.isMakingPayment;
    }
);

export const SupplyPaymentInstrumentSelector = createSelector(
    [eWalletSelector],
    (eWallet) => {
        return eWallet.supplyPaymentInstrument;
    }
);

export const RecordPaymentInformationSelector = createSelector(
    [eWalletSelector],
    (eWallet) => {
        return Immutable(eWallet.recordPaymentInfo);
    }
);

export const SelectedPaymentForSubmitAtCheckoutSelector = createImmutableSelector(
    [
        SelectedPaymentMethodSelector,
        RecordPaymentInformationSelector
    ],
    (selectedPayment, recordPaymentInfo) => {
        if (selectedPayment) {
            return recordPaymentInfo ? selectedPayment : selectedPayment.without(['Id', 'recordPayment']);
        } else {
            return selectedPayment;
        }
    });

export const UserHasEWalletAdminAccess = createSelector(
    [UserSecurityAttributesSelector],
    (userSecurityAttributes) => {
        return hasAccess(userSecurityAttributes, E_WALLET_ADMIN_ACCESS);
    }
);

export const IncludeRemovedSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.filterData.includeRemoved;
    }
);

export const RecoverableUISelectedPaymentMethod = createSelector(
    [
        PaymentInstrumentsSelector,
        SelectedItemIdSelector
    ],
    (paymentInstruments, selectedItemId) => {
        if (!isNil(selectedItemId) && paymentInstruments && paymentInstruments.length) {
            return find(propEq(selectedItemId, 'Id'))(paymentInstruments) || null;
        }
        return null;
    }
);

export const SelectedPaymentModelBasedOnRecoverableUI = createSelector(
    [
        RecoverableUISelectedPaymentMethod,
        PaymentTypesSelector,
        ActionablesPerPaymentTypeSelector
    ],
    (selectedPayment, paymentTypes, actionablesPerPermissionType) => {

        if (!selectedPayment || !selectedPayment.Id) {
            return selectedPayment;
        }

        return SelectorHelper.paymentInstrumentToViewModel(selectedPayment, paymentTypes, actionablesPerPermissionType);
    }
);

export const SelectedPaymentIsDirectSource = createSelector(
    [SelectedPaymentModelBasedOnRecoverableUI],
    (selectedPayment) => {

        if (selectedPayment && selectedPayment.actionables) {
            return selectedPayment.actionables.isDirectSource &&
                //PAYPAL requires a Preapproval agreement to be used as a DirectSource
                //until functionality is available to check for this we will block it
                !(String(selectedPayment.Type) === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.PAY_PAL);
        }

        return false;
    }
);

export const SelectedPaymentAutoPayEnable = createSelector(
    [SelectedPaymentMethodSelector],
    (selectedPayment) => {

        if (selectedPayment && selectedPayment.ConvergentBillerPaymentInstrumentAccounts && selectedPayment.ConvergentBillerPaymentInstrumentAccounts.length) {
            return selectedPayment.ConvergentBillerPaymentInstrumentAccounts[0].AutoPay;
        }

        return false;
    }
);

export const DefaultPaymentAutoPayEnable = createSelector(
    [DefaultPaymentMethodAutoPaySelector],
    (defaultPaymentMethodAutoPay) => {

        if (defaultPaymentMethodAutoPay && defaultPaymentMethodAutoPay.ConvergentBillerPaymentInstrumentAccounts && defaultPaymentMethodAutoPay.ConvergentBillerPaymentInstrumentAccounts.length) {
            return defaultPaymentMethodAutoPay.ConvergentBillerPaymentInstrumentAccounts[0].AutoPay || false;
        }

        return false;
    }
);

export const CurrentDefaultPaymentModel = createSelector(
    [
        DefaultPaymentMethodSelector,
        PaymentTypesSelector,
        ActionablesPerPaymentTypeSelector
    ],
    (defaultPayment, paymentTypes, actionablesPerPermissionType) => {

        if (!!defaultPayment && !!defaultPayment.Id) {
            return SelectorHelper.paymentInstrumentToViewModel(defaultPayment, paymentTypes, actionablesPerPermissionType);
        }

        return defaultPayment;
    }
);

export const IsRenewingOffCycleChargesSelector = createImmutableSelector(
    [eWalletSelector],
    (eWallet) => {
        return eWallet.isRenewingOffCycleCharges;
    }
);
