import {createSelector} from 'reselect';
import {CustomerStoreSelector} from './customer.selectors';

const CustomerTopUpsSelector = createSelector(
    [CustomerStoreSelector],
    (customerStore) => {
        return customerStore.selectedCustomer.topUps;
    }
);

export const CustomerTopUpsSelectorByServiceIdentifier = createSelector(
    [CustomerTopUpsSelector],
    (topUpsStore) => {
        return topUpsStore.availableTopUps;
    }
);

export const CustomerTopUpsServiceOptionsSelector = createSelector(
    [CustomerTopUpsSelectorByServiceIdentifier],
    (availableTopUps) => {
        return availableTopUps
            ? Object.keys(availableTopUps)
            : [];
    }
);

export const CustomerTopUpsIsFetchingTopUpsSelector = createSelector(
    [CustomerTopUpsSelector],
    (topUpsStore) => {
        return topUpsStore.isFetchingTopUps;
    }
);

export const CustomerTopUpsIsSubmittingTopUpSelector = createSelector(
    [CustomerTopUpsSelector],
    (topUpsStore) => {
        return topUpsStore.isSubmittingTopUp;
    }
);

export const LastAttemptErrorSelector = createSelector(
    [CustomerTopUpsSelector],
    (topUpsStore) => {
        return topUpsStore.lastAttemptError;
    }
);
