import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';

export const setSubscriptionData = (subscription, pricingPlanChangeSources, renewalTypes, subscriptionStateIndicators) => {
    if (subscription &&
        subscription.Items &&
        Array.isArray(renewalTypes) &&
        renewalTypes.length > 0 &&
        Array.isArray(pricingPlanChangeSources) &&
        pricingPlanChangeSources.length > 0) {

        const renewalType = renewalTypes.find((type) => {
            return convertStringToNumber(type.Value) === subscription.Type;
        });

        let subscriptionDisplayName;
        if (subscription.offeringThumbnail) {
            subscriptionDisplayName = subscription.offeringThumbnail.Name;
        } else if (subscription.Items.length > 1) {
            subscriptionDisplayName =  `${subscription.PaymentInstrument.TypeName} - ${subscription.PaymentInstrument.Name}`; // aligned name
        } else {
            subscriptionDisplayName =  `${subscription.Items[0].Product.Name} - ${subscription.Items[0].PricingPlan.Name}`;
        }

        const subscriptionWithDetails = subscription
            .set('renewalType', renewalType ? renewalType.Name : '')
            .set('isAligned', subscription.Items.length > 1)
            .set('displayName', subscriptionDisplayName)
            .set('Items',
                subscription.Items.map((item) => {
                    const changeSource = pricingPlanChangeSources.find((type) => {
                        return convertStringToNumber(type.Value) === item.RenewalSource;
                    });

                    return item.set('renewalSource', changeSource && changeSource.Name);
                }));

        if (subscription.StateIndicators && Array.isArray(subscriptionStateIndicators) &&
        subscriptionStateIndicators.length) {
            return subscriptionWithDetails.set('StateIndicators',
                subscription.StateIndicators.map((stateIndicator) => {
                    return subscriptionStateIndicators.find((stateIndicatorValue) => {
                        return convertStringToNumber(stateIndicatorValue.Value) === stateIndicator;
                    });
                }));
        }
        return subscriptionWithDetails;
    } else if (subscription && subscription.StateIndicators && Array.isArray(subscriptionStateIndicators) &&
    subscriptionStateIndicators.length) {
        return subscription
            .set('StateIndicators',
                subscription.StateIndicators.map((stateIndicator) => {
                    return subscriptionStateIndicators.find((stateIndicatorValue) => {
                        return convertStringToNumber(stateIndicatorValue.Value) === stateIndicator;
                    });
                }));
    }

    return subscription;
};

export const doesCurrentSubscriptionHaveItems = (subscription) => {
    if ( subscription &&
        subscription.Items &&
        Array.isArray(subscription.Items) &&
        subscription.Items.length > 0) {
        return true;
    }
    return false;
};
export const constructPricingPlanDictionary = (subscription) => {
    const subscriptionItem = doesCurrentSubscriptionHaveItems(subscription) ? subscription.Items[0] : null;

    if (subscriptionItem &&
        subscriptionItem.ChangeOptions &&
        Array.isArray(subscriptionItem.ChangeOptions) &&
        subscriptionItem.ChangeOptions.length > 0) {

        const pricingPlanDictionary = {};

        subscriptionItem.ChangeOptions.forEach((option) => {
            if (option.PricingPlan && option.PricingPlan.Id) {
                pricingPlanDictionary[option.PricingPlan.Id] = option.PricingPlan.Name ? option.PricingPlan.Name : 'No Pricing Plan Name';
            }
        });

        pricingPlanDictionary[subscriptionItem.PricingPlan.Id] = subscriptionItem.PricingPlan.Name;

        return pricingPlanDictionary;
    }
    return {};
};
