import ThunkHelper from 'invision-core/src/api/thunk.helper';
import {LANGUAGE_HEADER} from 'invision-core/src/api/constants';
import values from 'ramda/src/values';
import {INITIAL_STATE} from '../recoverableUiState/contracts.list.reducer';

export const SET_CONTRACTS_SEARCH_CRITERIA = 'SET_CONTRACTS_SEARCH_CRITERIA';
export const resetSearchCriteria = () => {
    return (dispatch) => {
        return dispatch({
            type: SET_CONTRACTS_SEARCH_CRITERIA,
            payload: INITIAL_STATE.searchCriteria
        });
    };
};

export const SEARCH_CONTRACT_EVENTS = {
    BEGIN: 'SEARCH_CONTRACTS_BEGIN',
    SUCCESS: 'SEARCH_CONTRACTS_SUCCESS',
    FAILURE: 'SEARCH_CONTRACTS_FAILURE'
};

const searchContractsPromise = (dispatch, searchCriteria) => {
    return ThunkHelper(dispatch, values(SEARCH_CONTRACT_EVENTS), {
        method: 'post',
        url: 'SubscriberManagement/SearchOrderContracts',
        headers: {
            [LANGUAGE_HEADER]: ''
        },
        data: searchCriteria
    });
};

export const searchContracts = (searchCriteria) => {
    return (dispatch) => {
        return searchContractsPromise(dispatch, searchCriteria);
    };
};

export const setSearchCriteriaAndSearchContracts = (searchCriteria) => {
    //TODO: this is a temporary solution until paging is supported on the modal in ASC-89791
    const searchCriteriaForcePageSizeOneThousand = Object.assign({}, searchCriteria, {
        PageSize: 1000
    });
    return (dispatch) => {
        dispatch({
            type: SET_CONTRACTS_SEARCH_CRITERIA,
            payload: searchCriteriaForcePageSizeOneThousand
        });
        return searchContractsPromise(dispatch, searchCriteriaForcePageSizeOneThousand);
    };
};