import {createSelector} from 'reselect';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {MetadataCodeTypeSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CurrentBusinessUnitSelector} from 'invision-core/src/components/session/businessunit.selectors';
import {DiscountsMetadataMapSelector} from 'invision-core/src/components/metadata/discounts/discounts.selectors';
import {getFilterService} from 'invision-core/src/components/injectables/injector.helper';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';

import {SelectedCustomerSelector} from './customer.selectors';
import CustomerCareKeys from '../../locales/keys';
import {deviceFinancingFriendlyName} from '../../components/customer/dashboard/dbssDashboard/deviceFinancing/device.financing.helper';
import {getSuspensionTypeByCode} from './services.list.selectors.helper';

const recoverableUIStateSelector = (state) => {
    return state.customercare.recoverableUiState.devices;
};

const DEFAULT_DEVICES_MAP = {};
const CurrentDevicesMapSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.devices.data ?
            selectedCustomer.devices.data : DEFAULT_DEVICES_MAP;
    }
);

export const CurrentDevicesSelector = createSelector(
    [CurrentDevicesMapSelector],
    (currentDevicesMap) => {
        return Object.keys(currentDevicesMap).map((key) => {
            return currentDevicesMap[key];
        });
    }
);

export const DeviceErrorsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.devices.devicesError;
    }
);

const DEFAULT_ERRORS = [];
export const DeviceFormErrorSelector = createSelector(
    [DeviceErrorsSelector],
    (deviceError) => {
        return deviceError && deviceError.message ? [deviceError.message] : DEFAULT_ERRORS;
    }
);

export const DeviceFormSelector = createSelector(
    [recoverableUIStateSelector],
    (recoverable) => {
        return recoverable.createDeviceModel.asMutable();
    }
);

export const SelectedDeviceSelector = createSelector(
    [recoverableUIStateSelector],
    (recoverable) => {
        return recoverable.selectedDevice;
    }
);

const DEFAULT_DEVICES = [];
export const FormattedDevicesSelector = createSelector(
    [
        CurrentDevicesSelector,
        MetadataCodeTypeSelector(CODES.DeviceType),
        MetadataCodeTypeSelector(CODES.PhysicalDeviceType)
    ],
    (devices, deviceTypes, physicalDeviceTypes) => {
        if (deviceTypes.length > 0 && physicalDeviceTypes.length > 0) {
            return devices.map((device) => {
                return formatDevice(device, deviceTypes, physicalDeviceTypes);
            });
        }
        return DEFAULT_DEVICES;
    }
);

const formatDevice = (device, deviceTypes, physicalDeviceTypes) => {
    const selectedDevice = deviceTypes.find((deviceType) => {
        return parseInt(deviceType.Value, 10) === device.DeviceType;
    });
    const selectedPhysicalDevice = physicalDeviceTypes.find((physicalDeviceType) => {
        return parseInt(physicalDeviceType.Value, 10) === device.PhysicalDeviceType;
    });

    return device
        .set('deviceTypeName', selectedDevice.Name)
        .set('physicalDeviceTypeName', selectedPhysicalDevice.Name);
};

export const FormattedSelectedDeviceSelector = createSelector(
    [FormattedDevicesSelector, SelectedDeviceSelector],
    (devices, selectedDevice) => {
        if (devices.length && selectedDevice) {
            return devices.find((device) => {
                return (device.uniqueId === selectedDevice.uniqueId) || (!selectedDevice.Start && device.Active && device.Id === selectedDevice.Id
                    && device.DeviceId === selectedDevice.DeviceId);
            });
        }
        return null;
    }
);

export const IncludeRemovedSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.filterData.includeRemoved;
    }
);

export const IsRegisteringDevicesSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.devices.isRegisteringDevice;
    }
);

export const IsFetchingDevicesSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.devices.isFetchingDevices;
    }
);

export const IsRemovingDeviceSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.devices.isRemovingDevice;
    }
);

export const IsUpdatingDevicesSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.devices.isUpdatingDevice;
    }
);

export const RemainingDeviceAssociations = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.devices.remainingAssociations;
    }
);

export const SelectedCustomerDevicesSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.devices;
    }
);

export const IsFetchingDeviceFinancingDetailsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.devices.isFetchingDeviceFinancingDetails;
    }
);

export const IsFetchingDeviceReturnDetailsSelector = createImmutableSelector(
    [SelectedCustomerDevicesSelector],
    (selectedCustomerDevices) => {
        return selectedCustomerDevices.isFetchingDeviceReturnDetails;
    }
);

export const IsApplyingReturnDeviceAdjustmentSelector = createImmutableSelector(
    [SelectedCustomerDevicesSelector],
    (selectedCustomerDevices) => {
        return selectedCustomerDevices.isApplyingDeviceAdjustment;
    }
);

export const SubscriberDeviceFinancingCurrencySelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.devices.deviceFinancingCurrency;
    }
);

export const SubscriberDeviceFinancingDetailsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.devices.deviceFinancingDetails;
    }
);

export const SubscriberDeviceReturnDetailsSelector = createImmutableSelector(
    [SelectedCustomerDevicesSelector],
    (selectedCustomerDevices) => {
        return selectedCustomerDevices.deviceReturnDetails;
    }
);

export const SubscriberDeviceFinancingWidgetActiveDeviceSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.devices.deviceFinancingWidgetSelectedDeviceIndex;
    }
);

export const SubscriberDeviceFinancingWidgetActiveDeviceDetailsSelector = createSelector(
    [
        SubscriberDeviceFinancingDetailsSelector,
        SubscriberDeviceFinancingWidgetActiveDeviceSelector
    ],
    (deviceFinancingDetails, selectedIndex) => {
        const selectedDeviceFinancingDetails = deviceFinancingDetails && deviceFinancingDetails[selectedIndex];
        return selectedDeviceFinancingDetails && Object.assign({}, selectedDeviceFinancingDetails, {
            suspendType: selectedDeviceFinancingDetails.SubscriberInventory
                            && getSuspensionTypeByCode(selectedDeviceFinancingDetails.SubscriberInventory.SuspendTypeCode),
            suspendedDate: selectedDeviceFinancingDetails.SubscriberInventory
                            && getFilterService()('date')(selectedDeviceFinancingDetails.SubscriberInventory.SuspendedOn, 'shortDate')
        });
    }
);

export const SubscriberDeviceFinancingWidgetActiveDiscountDetailsSelector = createImmutableSelector(
    [
        SubscriberDeviceFinancingWidgetActiveDeviceDetailsSelector, DiscountsMetadataMapSelector, SubscriberDeviceFinancingCurrencySelector],
    (deviceFinancingDetails, allDiscounts, currency) => {
        let discounts = [];
        if (allDiscounts && deviceFinancingDetails && deviceFinancingDetails.FinanceDiscounts) {
            discounts = deviceFinancingDetails.FinanceDiscounts.map(financeDiscount => {
                if (allDiscounts[financeDiscount.DiscountId]) {
                    return {
                        Name: allDiscounts[financeDiscount.DiscountId].StorefrontText || allDiscounts[financeDiscount.DiscountId].Name,
                        Currency: currency,
                        savings: financeDiscount.Amount
                    };
                }
            });
        }
        return discounts.filter(discount => {
            return discount;
        });
    }
);

export const SubscriberDeviceFinancingDetailsDropdownSelector = createSelector(
    [
        SubscriberDeviceFinancingDetailsSelector,
        SubscriberDeviceFinancingWidgetActiveDeviceSelector
    ],
    (deviceFinancingDetails, selectedIndex) => {
        return deviceFinancingDetails.map((device, index) => {
            return {
                text: deviceFinancingFriendlyName(device),
                selected: index === selectedIndex,
                index
            };
        });
    }
);

export const DeviceFinancingNameSelector = createSelector(
    [CurrentBusinessUnitSelector],
    (businessUnit) => {
        return businessUnit.deviceFinancingMarketingText || i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.DEVICE_FINANCING);
    }
);
