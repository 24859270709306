import pathOr from 'ramda/src/pathOr';
import cloneDeep from 'lodash/cloneDeep';
import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import {getBoolOrDefault} from 'invision-core/src/components/helpers/bool.helper';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {frequencyHelper as FrequencyHelper} from 'invision-core/src/components/helpers/frequency.helper';
import {
    COMMA,
    COMMA_SPACE,
    EMPTY_STRING,
    SEMI_COLON
} from '../constants/common.constants';

export const buildCustomerAnswer = (customer) => {
    if (customer.SecurityQuestion === MetadataConstants.CUSTOM_SECURITY_QUESTION_KEY) {
        return customer.SecurityQuestionCustom ? customer.SecurityQuestionCustom : undefined;
    }
    return customer.SecurityQuestion;
};

export const buildAddressesRequestObject = (customer, existingAddress = null, preventOverride = false) => {
    const address = {
        City: customer.city,
        Country: customer.country,
        DefaultBilling: true,
        DefaultHome: true,
        DefaultPostal: true,
        DefaultService: true,
        LineOne: customer.addressLine1,
        LineTwo: customer.addressLine2 ? customer.addressLine2 : undefined,
        Name: customer.addressLine1, // Name is required by the API and needs to be unique.
        PostalCode: customer.postalCode,
        State: customer.stateRegionProvince
    };
    const existingAddressId = pathOr(null, ['Id'], existingAddress);
    if (existingAddressId) {
        address.Id = existingAddressId;
    }
    if (preventOverride) {
        address['PreventValidationOverride'] = preventOverride;
    }
    return {
        Addresses: [address]
    };
};

export const buildCustomerRequestObject = (customer) => {
    const taxExemption = Object.assign({
        WholesaleFlag: customer.WholesaleFlag || false
    }, customer.TaxExemption);
    return {
        CountryOverride: customer.AccessCountry || undefined,
        Credentials: {
            Login: customer.Login || customer.Email,
            PasswordChallenge: customer.SecurityQuestion ? buildCustomerAnswer(customer) : undefined,
            PasswordChallengeResponse: customer.SecurityAnswer,
            SubscriberPIN: customer.SubscriberPIN || null
        },
        Subscriber: {
            AdditionalProperties: customer.setEditAdditionalProp || customer.AdditionalProperties || [],
            BirthDate: customer.BirthDate || undefined,
            BusinessPhone: customer.BusinessPhone || undefined,
            Category: customer.Category,
            ChargeTypesForDetailedTaxation: customer.ChargeTypesForDetailedTaxation || undefined,
            CompanyName: customer.CompanyName || undefined,
            ContactPreferences: customer.ContactPreferences || [],
            ConvergentBillerId: customer.ConvergentBillerId || undefined,
            CreditCheckClassification: customer.CreditCheckClassification || undefined,
            DriversLicenseNumber: customer.DriversLicenseNumber || undefined,
            DriversLicenseState: customer.DriversLicenseState || undefined,
            Email: customer.Email,
            EffectiveStartDate: customer.EffectiveStartDate || undefined,
            ExternalAuthorizations: customer.ExternalAuthorizations || [],
            ExternalReference: customer.ExternalReference || undefined,
            FirstName: customer.FirstName,
            Gender: customer.Gender || undefined,
            GuardianEmail: customer.ConsentLegalGuardianEmail || undefined,
            HierarchyId: customer.HierarchyId || undefined,
            HierarchyParentSubscriberId: convertStringToNumber(customer.HierarchyParentSubscriberId) || undefined,
            HomeCountry: customer.HomeCountry || undefined,
            HomePhone: customer.HomePhone || undefined,
            IncomeLevelType: customer.IncomeLevelType || undefined,
            InvoiceConfiguration: customer.InvoiceConfiguration || undefined,
            IsMinor: getBoolOrDefault(customer.IsMinor, false),
            Language: customer.Language || undefined,
            LastName: customer.LastName,
            LeadSourceType: customer.LeadSourceType || undefined,
            Login: customer.Login || customer.Email,
            MiddleName: customer.MiddleName || undefined,
            MobilePhone: customer.MobilePhone || undefined,
            NotificationCategoryPreferences: customer.NotificationCategoryPreferences || undefined,
            PreferredName: customer.PreferredName || undefined,
            PreferredTaxReportingCurrency: customer.PreferredTaxReportingCurrency || undefined,
            Race: customer.Race || undefined,
            Ssn: customer.Ssn || undefined,
            Status: customer.Status || undefined,
            SubscriberConsents: buildSubscriberConsents(customer.ConsentAcceptingMandatoryTerms, customer.ConsentAcceptingOptionalTerms, customer.ConsentLegalGuardianEmail),
            SubscriberCurrency: customer.SubscriberCurrency || undefined,
            SubscriberTypeCode: customer.SubscriberTypeCode || undefined,
            SubscriberTypeDetails: customer.SubscriberTypeDetails || undefined,
            SubscriberIdentifications: customer.SubscriberIdentifications || undefined,
            SubtenantId: customer.SubtenantId || undefined,
            SuffixName: customer.SuffixName || undefined,
            TaxExempt: getBoolOrDefault(customer.TaxExempt, false),
            TaxExemption: taxExemption,
            TaxExemptionCategories: customer.TaxExemptionCategories,
            TaxExemptionTypes: customer.TaxExemptionTypes,
            TermsAndConditionsAccepted: customer.TermsAndConditionsAccepted || undefined,
            Title: customer.Title || undefined
        },
        TextScrubberOverride: customer.TextScrubber || undefined
    };
};

export const buildHierarchyRequestObject = (customer) => {
    const request = {};
    const taxExemption = Object.assign({
        WholesaleFlag: customer.WholesaleFlag || false
    }, customer.TaxExemption);
    if (!customer.SubscriberId) {
        request.HierarchySubscriber = {
            Subscriber: {
                AdditionalProperties: customer.setEditAdditionalProp || [],
                BirthDate: customer.BirthDate || undefined,
                BusinessPhone: customer.BusinessPhone || undefined,
                DriversLicenseNumber: customer.DriversLicenseNumber || undefined,
                DriversLicenseState: customer.DriversLicenseState || undefined,
                Email: customer.Email,
                FirstName: customer.FirstName,
                Gender: customer.Gender || undefined,
                HomeCountry: customer.HomeCountry || undefined,
                HomePhone: customer.HomePhone || undefined,
                IncomeLevelType: customer.IncomeLevelType || undefined,
                Language: customer.Language || undefined,
                LastName: customer.LastName,
                LeadSourceType: customer.LeadSourceType || undefined,
                MiddleName: customer.MiddleName || undefined,
                MobilePhone: customer.MobilePhone || undefined,
                Race: customer.Race || undefined,
                Ssn: customer.Ssn || undefined,
                Status: customer.Status || undefined,
                SubscriberCurrency: customer.SubscriberCurrency || undefined,
                SubscriberTypeCode: customer.SubscriberTypeCode || undefined,
                SuffixName: customer.SuffixName || undefined,
                TaxExempt: customer.TaxExempt || undefined,
                TaxExemptionCategories: customer.TaxExemptionCategories,
                TaxExemption: taxExemption,
                TermsAndConditionsAccepted: customer.TermsAndConditionsAccepted || undefined
            },
            CountryOverride: customer.AccessCountry || undefined,
            Credentials: {
                Login: customer.Login || customer.Email,
                PasswordChallenge: customer.SecurityQuestion ? buildCustomerAnswer(customer) : undefined,
                PasswordChallengeResponse: customer.SecurityAnswer
            },
            TextScrubberOverride: customer.TextScrubber || undefined
        };
    } else {
        request.SubscriberId = customer.SubscriberId;
    }
    request.ResponsiblePartySubscriberId = customer.ResponsiblePartySubscriberId;
    request.HierarchyName = customer.HierarchyName;
    request.RootHierarchyName = customer.HierarchyName;
    return request;
};

export function buildSubscriberConsents(mandatoryConsents, optionalConsents, guardianEmail) {
    const consents = [];

    if (optionalConsents) {
        optionalConsents.forEach(c => {
            consents.push({
                AcceptedBy: guardianEmail || undefined,
                ConsentAccepted: c.isAccepted,
                ConsentDateTime: c.acceptRejectDate,
                ConfigConsentId: c.id
            });
        });
    }

    if (mandatoryConsents && mandatoryConsents.IsAccepted) {
        pathOr([], ['Terms'], mandatoryConsents).forEach(c => {
            consents.push({
                AcceptedBy: guardianEmail || undefined,
                ConsentAccepted: true,
                ConsentDateTime: new Date(),
                ConfigConsentId: c.id
            });
        });
    }

    return consents;
}

export function mapAdditionalProperty(AdditionalProperties) {
    return AdditionalProperties.map((property) => {
        return {
            Id: property.id || property.Id,
            Values: pathOr([], ['Values'], property)
        };
    });
}

export function mapAdditionalPropertyValue(AdditionalProperties) {
    return AdditionalProperties.map((property) => {
        return {
            Id: property.id || property.Id,
            Value: pathOr([], ['Value'], property)
        };
    });
}

export function setEditAp(newApValues, setEditAp) {
    return setEditAp.map((property) => {
        if (property.Id !== newApValues.id) {
            return property;
        } else {
            return Object.assign({}, property, {
                Values: newApValues.newValue
            });
        }
    });

}

export function setFormattedName(customerInfo) {
    const firstName = customerInfo.FirstName;
    const lastName = customerInfo.LastName;
    if (customerInfo.CompanyName) {
        return customerInfo.CompanyName;
    } else {
        return lastName ? `${firstName} ${lastName}` : firstName;
    }
}

export function setFormattedCityState(customerInfo) {
    if (!customerInfo.stateRegionProvince) {
        return customerInfo.city;
    }
    if (customerInfo.city === '' || !customerInfo.city) {
        return customerInfo.stateRegionProvince;
    }
    return `${customerInfo.city}, ${customerInfo.stateRegionProvince}`;
}

export const setAdditionalPropertiesFromEdit = (customer) => {
    const newCust = cloneDeep(customer);

    newCust.AdditionalProperties = pathOr([], ['setEditAdditionalProp'], customer).map((prop) => {
        return {
            Id: prop.Id,
            Values: prop.Values
        };
    });
    delete newCust.setEditAdditionalProp;
    return newCust;
};

export const getCustomerIdentifier = (customer) => {
    if (customer.CompanyName) {
        return customer.CompanyName;
    } else if (customer.FirstName || customer.LastName) {
        return `${customer.FirstName || ''} ${customer.LastName || ''}`.trim();
    } else if (customer.ExternalReference) {
        return customer.ExternalReference;
    } else if (customer.Login) {
        return customer.Login;
    } else if (customer.Email) {
        return customer.Email;
    }
};

export const buildCandidateBillCyclesRequest = (orderType, customerCategory, customerType, currency, country, state) => {
    const request = Object.assign({
        OrderType: orderType
    });

    if (customerCategory !== undefined && customerCategory !== null) {
        request.SubscriberCategory = customerCategory.toString();
    }
    if (customerType !== undefined && customerType !== null) {
        request.SubscriberType = customerType.toString();
    }
    if (currency !== undefined && currency !== null) {
        request.SubscriberCurrency = currency;
    }
    if (country !== undefined && country !== null) {
        request.BillingAddressCountry = country;
    }
    if (state !== undefined && state !== null) {
        request.BillingAddressState = state;
    }

    return request;
};

export const buildCustomerForCreditCheck = (customer, creditClassificationData, isDbss) => {
    const updatedCustomer = setAdditionalPropertiesFromEdit(customer);
    if (isDbss && !updatedCustomer.ConvergentBillerId) {
        updatedCustomer.ConvergentBillerId = creditClassificationData.Subscriber.ConvergentBillerId;
    }
    if (creditClassificationData && creditClassificationData.Subscriber.Login && !updatedCustomer.Login) {
        updatedCustomer.Login = creditClassificationData.Subscriber.Login;
    }
    return buildCustomerRequestObject(updatedCustomer);
};

export function convertBillCyclesForDisplay(billCycles) {
    return billCycles ? billCycles.map((billCycle) => {
        return convertBillCycleForDisplay(billCycle);
    }) : [];
}

export function offCycleAlignmentMapping(offCycleChargeAlignmentOptions) {
    const offCycleObj = {};
    offCycleChargeAlignmentOptions.forEach(option => {
        offCycleObj[option.PricingPlanId] = option.SubscriberProductOffCycleChargeDetails;
    });
    return offCycleObj;
}


export function convertBillCycleForDisplay(billCycle) {
    return {
        id: billCycle?.Id?.toString() || '',
        name: billCycle.Name,
        frequency: FrequencyHelper.convertToTranslatedFrequency(
            parseInt(billCycle.RepeatInterval, 10), parseInt(billCycle.RepeatType, 10)),
        billDate: billCycle.BillDate ? new Date(billCycle.BillDate) : null,
        paymentDueDate: billCycle.PaymentDueDate ? new Date(billCycle.PaymentDueDate) : null,
        type: billCycle.BillCycleType
    };
}

export function transformAddressResponseObjectForEditing(address) {
    if (address) {
        return {
            country: address.Country,
            addressLine1: address.LineOne,
            addressLine2: address.LineTwo,
            city: address.City,
            stateRegionProvince: address.State,
            postalCode: address.PostalCode
        };
    } else {
        return null;
    }
}

export function formatSelectedStoreString(selectedStoreObject) {
    if (selectedStoreObject && selectedStoreObject.AdditionalProperties) {
        const {LineOne, LineTwo, City, StateProvince, Zipcode} = selectedStoreObject.AdditionalProperties;
        return `${selectedStoreObject.Name}, ${LineOne} ${LineTwo}, ${City}, ${StateProvince}, ${Zipcode}`;
    } else {
        return EMPTY_STRING;
    }
}

export const getFullCustomerAddress = (address) => {
    return `${address.LineOne}${address.LineTwo ? COMMA_SPACE : EMPTY_STRING}${address.LineTwo ? address.LineTwo : EMPTY_STRING}${SEMI_COLON} ${address.City}${COMMA} ${address.State}${COMMA} ${address.Country}${SEMI_COLON} ${address.PostalCode}`;
};

export const buildCreditCheckAddressRequestObject = (customer) => {
    if (customer) {
        return {
            City: customer.city,
            Country: customer.country,
            DefaultBilling: true,
            DefaultHome: true,
            DefaultPostal: true,
            DefaultService: true,
            LineOne: customer.addressLine1,
            LineTwo: customer.addressLine2 ? customer.addressLine2 : undefined,
            PostalCode: customer.postalCode,
            State: customer.stateRegionProvince
        };
    }
};
