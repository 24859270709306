import CustomerCareKeys from '../../locales/keys';

export const OPTION_OFFERING_CHARGE_TYPE = 'OPTION_OFFERING_CHARGE_TYPE';

export const OFFERING_CHARGE_TYPE_IDS = {};
OFFERING_CHARGE_TYPE_IDS[CustomerCareKeys.OFFERING_CHARGE_TYPES.PREPAID]  = 1;
OFFERING_CHARGE_TYPE_IDS[CustomerCareKeys.OFFERING_CHARGE_TYPES.POSTPAID] = 2;

export const TELEPHONE_SELECTION_COUNTRY_CODE_KEY = 'country_code';

export const ASYNC_QUOTE_THRESHOLD = 30;

export const ASYNC_STATUS_CONSTANTS = {
    PENDING: 0,
    COMPLETE: 1,
    FAILED: 2
};

export const ContextOfferingAction = {
    ADD: 1,
    MODIFY: 2,
    TRANSITION: 3,
    REMOVE: 4,
    TRANSITION_REQUEST: 5,
    RECONNECT: 6
};

export const CHARGE_TIMING = {
    BILL_CYCLE: 1,
    DUE_ON_ACTIVATION: 2,
    DUE_TODAY: 3,
    DUE_ON_FIRST_USE: 4,
    DUE_RECURRING: 5
};

export const SUBSCRIBER_ACTIONS = {
    ADD: 1,
    MODIFY: 2,
    DISCONNECT: 3
};

export const DEFAULT_SUMMARY_TAB_VIEW_MODEL = {
    productGroups: [],
    currencyCode: 'USD',
    taxNotIncluded: true,
    taxQuoteAmount: 0,
    totalQuoteAmount: 0
};

export const OFFERINGS_SEARCH_CONSTANTS = {
    SORT_BY: {
        NAME: 1,
        RELEVANCE: 2
    },
    SORT_DIRECTION: {
        ASCENDING: 1,
        DESCENDING: 2
    }
};

export const BILLING_EFFECTIVE_DATE_INTENTION = {
    OnOrderCompletion: '1',
    OnLastOrder: '2',
    OnSpecificDate: '3',
    OnNextBillCycle: '4'
};

export const ORDER_EXECUTION_DATE_INTENTION = {
    EffectiveImmediately: '1',
    OnNextBillCycle: '2',
    OnSpecificDate: '3'
};

export const FULL_CART_COLUMNS = {
    DUE_NEXT_INVOICE: 'dueNextInvoice',
    DUE_ON_ACTIVATION: 'dueOnActivation',
    DUE_ON_FIRST_USE: 'dueOnFirstUse',
    DUE_RECURRING: 'dueRecurring',
    DUE_TODAY: 'dueToday'
};

export const ADD_WIZARD_PREFIX = 'ADD_OFFER';
export const ADD_SERVICES_WIZARD_PREFIX = 'ADD_SERVICES';
export const EDIT_WIZARD_PREFIX = 'EDIT_OFFER';
export const NEW_CONNECT_WIZARD_PREFIX = 'NEW_CONNECT';
export const TRANSFER_WIZARD_PREFIX = 'TRANSFER';
export const TRANSITION_WIZARD_PREFIX = 'TRANSITION_OFFER';
export const DEVICE_TYPE_OFFER = 1;
export const OFFER_ORDER_QUOTE_GROUPS = {
    CURRENT: 1,
    EXISTING: 2
};
