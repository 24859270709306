import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {getFilterService} from 'invision-core/src/components/injectables/injector.helper';
import {SelectedCustomerSelector} from './customer.selectors';

export const  BillingPaymentsSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.billingPayments;
    }
);

export const IsFutureInvoicesCalcultaionStartedSelector = createImmutableSelector(
    [BillingPaymentsSelector],
    (billingPayments) => {
        return billingPayments.futureInvoicesDetails.startCalculating;
    }
);

export const FutureInvoicesCalculationSessionIdSelector = createImmutableSelector(
    [BillingPaymentsSelector],
    (billingPayments) => {
        return billingPayments.futureInvoicesDetails.calculationSessionId;
    }
);

export const FutureInvoicesDataSelector = createImmutableSelector(
    [BillingPaymentsSelector],
    (billingPayments) => {
        const $filter = getFilterService();
        return billingPayments.futureInvoicesDetails.invoices.map((invoice) => {
            return {
                amountWithCurrency: invoice.EstimatedAmount ? $filter('invCurrency')(invoice.EstimatedAmount, invoice.CurrencyCode) :
                    $filter('invCurrency')(0, invoice.CurrencyCode),
                currencyCode: invoice.CurrencyCode,
                endDate: $filter('localShort')(invoice.EndDate),
                estimatedAmount: invoice.EstimatedAmount,
                hasOpenDispute: !!(invoice.CaseId?.Value || invoice.HasOpenLineItemDispute),
                invoiceDateTime: $filter('shortWithSeconds')(invoice.InvoiceDateTime),
                invoiceId: invoice.InvoiceId,
                startDate: $filter('localShort')(invoice.StartDate),
                startToEndDate : `${$filter('localShortDate')(invoice.StartDate)} - ${$filter('localShortDate')(invoice.EndDate)}`
            };
        });
    }
);

export const FutureInvoicesCalculationStatusSelector = createImmutableSelector(
    [BillingPaymentsSelector],
    (billingPayments) => {
        return billingPayments.futureInvoicesDetails.status;
    }
);