import {stateGo} from 'redux-ui-router';
import {
    ApiConstants,
    InjectorHelper,
    SessionSelectors,
    ThunkHelper
} from 'invision-core';
import {
    GiftCardSearchRecordCountSelector,
    GiftCardSearchArraySelector
} from '../selectors/search.giftcard.selectors';
import {
    hideSearchPanel
} from './search.actions';
import {GIFT_CARD_DETAILS} from '../../components/giftCard/gift.card.config';

export const GIFT_CARD_SEARCH = {
    BEGIN: 'GIFT_CARD_SEARCH_BEGIN',
    SUCCESS: 'GIFT_CARD_SEARCH_SUCCESS',
    FAILURE: 'GIFT_CARD_SEARCH_FAILURE'
};
const GIFT_CARD_SEARCH_EVENTS = [GIFT_CARD_SEARCH.BEGIN, GIFT_CARD_SEARCH.SUCCESS, GIFT_CARD_SEARCH.FAILURE];

export const SET_GIFT_CARD_SEARCH_MODEL = 'SET_GIFT_CARD_SEARCH_MODEL';
export const setGiftCardSearchModel = (payload) => {
    return {
        type: SET_GIFT_CARD_SEARCH_MODEL,
        payload: payload
    };
};

export const RESET_SEARCH_GIFT_CARD_MODEL_RECOVERABLE = 'RESET_SEARCH_GIFT_CARD_MODEL_RECOVERABLE';
export const resetGiftCardSearchRecoverable = () => {
    return {
        type: RESET_SEARCH_GIFT_CARD_MODEL_RECOVERABLE
    };
};

const giftCardSearchPromise = (dispatch, giftCardSearchModel, pageNumber, pageSize, events = GIFT_CARD_SEARCH_EVENTS) => {
    const $ngRedux = InjectorHelper.getNgReduxService();
    const state = $ngRedux.getState();

    return ThunkHelper(dispatch, events, {
        method: 'post',
        url: 'SubscriberManagement/SearchGiftCardRedemptions',
        headers: {
            [ApiConstants.CUSTOM_SUBSCRIBER_LANGUAGE_HEADER]: SessionSelectors.LanguageHeaderSelector(state)
        },
        data: {
            AccountNumber : giftCardSearchModel.accountNumber,
            PageSize: pageSize,
            PageNumber: pageNumber,
            Pin : giftCardSearchModel.pin,
            SenderEmail : giftCardSearchModel.senderEmail
        }
    });
};

export const SET_GIFT_CARD_SEARCH_PAGE = 'SET_GIFT_CARD_SEARCH_PAGE';
export const setGiftCardSearchPageInStore = (pageNumber) => {
    return {
        type: SET_GIFT_CARD_SEARCH_PAGE,
        payload: pageNumber
    };
};

export const getGiftCardSearch = (giftCardSearchModel, pageNumber, pageSize) => {
    return (dispatch) => {
        return giftCardSearchPromise(dispatch, giftCardSearchModel, pageNumber, pageSize).then(() => {
            dispatch(setGiftCardSearchPageInStore(pageNumber));
            const $ngRedux = InjectorHelper.getNgReduxService();
            if (GiftCardSearchRecordCountSelector($ngRedux.getState()) === 1) {
                const giftCard = GiftCardSearchArraySelector($ngRedux.getState())[0];
                dispatch(routeToGiftCard(giftCard));
            }
        });
    };
};

export const routeToGiftCard = (giftCard) => {
    // Defaults state and params
    const state = GIFT_CARD_DETAILS;
    const params = {
        giftCardId: giftCard.Id
    };

    return (dispatch) => {
        dispatch(stateGo(state, params, {
            reload: true
        }));
        dispatch(hideSearchPanel());
    };
};
