import Immutable from 'seamless-immutable';
import {EMPTY_ARRAY} from './constants/common.constants';
import {
    RESET_SEARCH_OTHER_CUSTOMERS_STORE,
    RETRIEVE_HOUSEHOLDS_FOR_CUSTOMER_SEARCH,
    SEARCH_EXISTING_CUSTOMERS
} from './actions/search.other.customers.actions';

import {
    getAllUniqueMembersOfAllCustomerHouseholds
} from './search.other.customers.reducer.helper';

export const INITIAL_STATE = Immutable({
    data: EMPTY_ARRAY,
    pageCount: 0,
    pageNumber: 0,
    recordCount: -1,
});

export default function searchOtherCustomerReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case RESET_SEARCH_OTHER_CUSTOMERS_STORE:
            return INITIAL_STATE;
        case SEARCH_EXISTING_CUSTOMERS.BEGIN:
            return INITIAL_STATE;
        case SEARCH_EXISTING_CUSTOMERS.SUCCESS:
            return state.set('data', payload.Subscribers || EMPTY_ARRAY)
                .set('pageCount', payload.PageCount || 0)
                .set('pageNumber', payload.PageNumber || 1)
                .set('recordCount', payload.RecordCount || 0);
        case RETRIEVE_HOUSEHOLDS_FOR_CUSTOMER_SEARCH.BEGIN:
            return INITIAL_STATE;
        case RETRIEVE_HOUSEHOLDS_FOR_CUSTOMER_SEARCH.SUCCESS:
            return state.set('data', getAllUniqueMembersOfAllCustomerHouseholds(payload.Households || []));
        default:
            return state;
    }
}
