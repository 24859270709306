import i18n from 'invision-core/src/components/i18n/i18n';
import pathOr from 'ramda/src/pathOr';
import uniq from 'ramda/src/uniq';
import pluck from 'ramda/src/pluck';
import {getUiNotificationService} from 'invision-core/src/components/injectables/injector.helper';
import {RouteParams} from 'invision-core/src/components/router/router.selectors';
import {SUBSCRIBER_ID_HEADER} from 'invision-core/src/api/constants';
import {ThunkHelper} from 'invision-core';
import CustomerCareKeys from '../../locales/keys';
import createWizardActions from '../helpers/offer.ordering.wizard.action.helper';
import {ADD_WIZARD_PREFIX} from '../constants/wizard.constants';
import {RESUME_ORDER_INSUFFICIENT_ACCESS_WARNING_CODE} from '../../customercare.constants';
import {
    addSubscriberHeaders,
    calculateOfferingOrderQuotePromise,
    clearOfferingOrderQuote,
    clearQuote,
    getContractId,
    retrieveAttributesPromise,
    retrieveOfferingContextPromise,
    setExternalContractId,
    setPurchaseOrderNumber,
    setSelectedInventoryStores,
    setSelectedOfferName,
    submitOrderPromise,
    updateMultiOfferShoppingCartPromise
} from './offering.order.actions';
import {
    retrieveContractPromise,
    setSelectedContract
} from './contract.details.actions';
// TODO: CustomerOrder: We should not be importing a selector from other wizards, if so it should be shared.
import {EditCustomerInfoSelector} from '../selectors/new.connect.wizard.selectors';
import {ShoppingCartSelector} from '../selectors/customer.selectors';
import {EditedMultiOfferShoppingCartSelector} from '../selectors/add.offer.wizard.selectors';
import {retrieveOfferingsMetadata} from 'invision-core/src/components/metadata/offerings/offerings.actions';

const wizardActions = createWizardActions(ADD_WIZARD_PREFIX);

export const BEGIN_ADD_OFFER_ORDER = 'BEGIN_ADD_OFFER_ORDER';
export const CANCEL_ORDER = wizardActions.CANCEL_WIZARD;
export const GO_TO_NEXT_STEP = wizardActions.GO_TO_NEXT_STEP;
export const GO_TO_PREVIOUS_STEP = wizardActions.GO_TO_PREVIOUS_STEP;
export const GO_TO_STEP = wizardActions.GO_TO_STEP;
export const INITIALIZE_AS_MULTI_OFFER_WIZARD = 'INITIALIZE_AS_MULTI_OFFER_WIZARD';
export const MULTI_OFFER_SET_COMPLETED_STEPS = 'MULTI_OFFER_SET_COMPLETED_STEPS';
export const RESET_FOR_CONTINUE_SHOPPING = 'RESET_FOR_CONTINUE_SHOPPING';
export const RESET_MULTI_OFFERS = 'RESET_MULTI_OFFERS';
export const RESET_OFFER_ADDITIONAL_PROPERTIES = wizardActions.RESET_OFFER_ADDITIONAL_PROPERTIES;
export const RESET_PICKUP_ID = 'RESET_PICKUP_ID';
export const RESTORE_OPTION_DEFAULT_PRICING = wizardActions.RESTORE_OPTION_DEFAULT_PRICING;
export const RESUME_ORDER_SET_SELECTED_OFFER_ID = 'RESUME_ORDER_SET_SELECTED_OFFER_ID';
export const RETRIEVE_ADDRESSES = wizardActions.RETRIEVE_ADDRESSES;
export const SAVE_OPTION_PRICING = wizardActions.SAVE_OPTION_PRICING;
export const SEND_PORT_IN_OR_ITEM_RESERVATION = wizardActions.SEND_PORT_IN_OR_ITEM_RESERVATION;
export const SET_ATTRIBUTE_SEARCHING_INVENTORY = wizardActions.SET_ATTRIBUTE_SEARCHING_INVENTORY;
export const SET_CURRENT_STEP_IS_INVALID = wizardActions.SET_CURRENT_STEP_IS_INVALID;
export const SET_EDIT_ATTRIBUTE_GROUPS = wizardActions.SET_EDIT_ATTRIBUTE_GROUPS;
export const SET_EDIT_CART_ADDITIONAL_PROPERTY = wizardActions.SET_EDIT_CART_ADDITIONAL_PROPERTY;
export const SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE = wizardActions.SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE;
export const SET_EDIT_OFFER_ADDITIONAL_PROPERTY = wizardActions.SET_EDIT_OFFER_ADDITIONAL_PROPERTY;
export const SET_EDIT_OFFER_ADDITIONAL_PROPERTY_ON_CHANGE = wizardActions.SET_EDIT_OFFER_ADDITIONAL_PROPERTY_ON_CHANGE;
export const SET_EDIT_OPTION = wizardActions.SET_EDIT_OPTION;
export const SET_EDIT_ORDER_ADDITIONAL_PROPERTY = wizardActions.SET_EDIT_ORDER_ADDITIONAL_PROPERTY;
export const SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE = wizardActions.SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE;
export const SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS = wizardActions.SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS;
export const SET_PAYMENT_INFO = wizardActions.SET_PAYMENT_INFO;
export const SET_REMOVE_OFFER_INSTANCE_ID = 'SET_REMOVE_OFFER_INSTANCE_ID';
export const SET_SELECTED_ADD_OFFER_TAB = wizardActions.SET_SELECTED_WIZARD_TAB;
export const SET_SELECTED_FACETS_EDIT_COPY = wizardActions.SET_SELECTED_FACETS_EDIT_COPY;
export const SET_SELECTED_OFFER = wizardActions.SET_SELECTED_OFFER;
export const SET_SELECTED_OFFER_EDIT_COPY = wizardActions.SET_SELECTED_OFFER_EDIT_COPY;
export const SET_SELECTED_OFFERING_CHARGE_TYPES_EDIT_COPY = wizardActions.SET_SELECTED_OFFERING_CHARGE_TYPES_EDIT_COPY;
export const SET_TRANSFER_FROM_SUBSCRIBER_ID = 'SET_TRANSFER_FROM_SUBSCRIBER_ID';
export const SET_TRANSFER_FROM_NAME = 'SET_TRANSFER_FROM_NAME';
export const UPDATE_BILL_CYCLE = wizardActions.UPDATE_BILL_CYCLE;
export const UPDATE_EDIT_BRI_PRICE = wizardActions.UPDATE_EDIT_BRI_PRICE;
export const UPDATE_INVENTORY_SELECTION = wizardActions.UPDATE_INVENTORY_SELECTION;
export const UPDATE_OFFER_ATTRIBUTE = wizardActions.UPDATE_OFFER_ATTRIBUTE;
export const UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL = wizardActions.UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL;
export const UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE = wizardActions.UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE;
export const UPDATE_PAYMENT_INFO = wizardActions.UPDATE_PAYMENT_INFO;
export const UPDATE_PORT_IN_REQUEST = wizardActions.UPDATE_PORT_IN_REQUEST;
export const UPDATE_SELECTED_SERVICE_FEATURES = wizardActions.UPDATE_SELECTED_SERVICE_FEATURES;
export const UPDATE_SHOPPING_CART = wizardActions.UPDATE_SHOPPING_CART;

export const multiOfferSetCompletedSteps = () => {
    return {
        type: MULTI_OFFER_SET_COMPLETED_STEPS
    };
};

export const beginAddOfferOrder = (isMultiOffer) => {
    return {
        type: BEGIN_ADD_OFFER_ORDER,
        payload: {
            isMultiOffer
        }
    };
};

export const resetForContinueShopping = () => {
    return {
        type: RESET_FOR_CONTINUE_SHOPPING
    };
};

export const resetMultiOffers = () => {
    return {
        type: RESET_MULTI_OFFERS
    };
};

export const resetPickupId = () => {
    return {
        type: RESET_PICKUP_ID
    };
};

export const initializeAsMultiOfferWizard = (payload) => {
    return {
        type: INITIALIZE_AS_MULTI_OFFER_WIZARD,
        payload: payload
    };
};

export const setTransferFromName = (payload) => {
    return {
        type: SET_TRANSFER_FROM_NAME,
        payload: payload
    };
};

export const setTransferFromSubscriberId = (payload) => {
    return {
        type: SET_TRANSFER_FROM_SUBSCRIBER_ID,
        payload: payload
    };
};

const resumeOrderSetSelectOffer = (selectedOfferId) => {
    return {
        type: RESUME_ORDER_SET_SELECTED_OFFER_ID,
        payload: selectedOfferId
    };
};

export const setRemoveOfferInstanceId = (removeOfferInstanceId) => {
    return {
        type: SET_REMOVE_OFFER_INSTANCE_ID,
        payload: removeOfferInstanceId
    };
};

const setSelectedOffer = wizardActions.setSelectedOffer;
export const cancelOrder = wizardActions.cancelWizard;
export const clearQuoteAndSetSelectedOffer = wizardActions.clearQuoteAndSetSelectedOffer;
export const fetchAddresses = wizardActions.fetchAddresses;
export const fetchAttributes = wizardActions.fetchAttributes;
export const fetchWallet = wizardActions.fetchWallet;
export const goToNextStep = wizardActions.goToNextStep;
export const goToPreviousStep = wizardActions.goToPreviousStep;
export const goToStep = wizardActions.goToStep;
export const resetOfferAdditionalProperties = wizardActions.resetOfferAdditionalProperties;
export const restoreOptionDefaultPricing = wizardActions.restoreOptionDefaultPricing;
export const saveBulkPricing = wizardActions.saveBulkPricing;
export const searchAttributeInventory = wizardActions.searchAttributeInventory;
export const sendPortInOrItemReservation = wizardActions.sendPortInOrItemReservation;
export const setCurrentStepIsInvalid = wizardActions.setCurrentStepIsInvalid;
export const setEditAttributeGroups = wizardActions.setEditAttributeGroups;
export const setEditCartAdditionalProperty = wizardActions.setEditCartAdditionalProperty;
export const setEditCartAdditionalPropertyOnChange = wizardActions.setEditCartAdditionalPropertyOnChange;
export const setEditOfferAdditionalProperty = wizardActions.setEditOfferAdditionalProperty;
export const setEditOfferAdditionalPropertyOnChange = wizardActions.setEditOfferAdditionalPropertyOnChange;
export const setEditOption = wizardActions.setEditOption;
export const setEditOrderAdditionalProperty = wizardActions.setEditOrderAdditionalProperty;
export const setEditOrderAdditionalPropertyOnChange = wizardActions.setEditOrderAdditionalPropertyOnChange;
export const setEditPhysicalAttributeGroups = wizardActions.setEditPhysicalAttributeGroups;
export const setPaymentInfo = wizardActions.setPaymentInfo;
export const setSelectedFacetsEditCopy = wizardActions.setSelectedFacetsEditCopy;
export const setSelectedOfferEditCopy = wizardActions.setSelectedOfferEditCopy;
export const setSelectedOfferingChargeTypesEditCopy = wizardActions.setSelectedOfferingChargeTypesEditCopy;
export const setSelectedTab = wizardActions.setSelectedTab;
export const updateAttribute = wizardActions.updateAttribute;
export const updateBillCycle = wizardActions.updateBillCycle;
export const updateCart = wizardActions.updateCart;
export const updateEditOptionPrice = wizardActions.updateEditOptionPrice;
export const updateInventorySelection = wizardActions.updateInventorySelection;
export const updateOfferPhysicalInventoryMakeAndModel = wizardActions.updateOfferPhysicalInventoryMakeAndModel;
export const updateOfferPhysicalInventoryTypeAttribute = wizardActions.updateOfferPhysicalInventoryTypeAttribute;
export const updatePaymentInfo = wizardActions.updatePaymentInfo;
export const updatePortInRequest = wizardActions.updatePortInRequest;
export const updateSelectedServiceFeatures = wizardActions.updateSelectedServiceFeatures;
export const updateShoppingCart = wizardActions.updateShoppingCart;

export const RESUME_ORDER = {
    BEGIN: 'RESUME_ORDER_BEGIN',
    SUCCESS: 'RESUME_ORDER_SUCCESS',
    FAILURE: 'RESUME_ORDER_FAILURE'
};

const RESUME_ORDER_EVENTS = [
    RESUME_ORDER.BEGIN,
    RESUME_ORDER.SUCCESS,
    RESUME_ORDER.FAILURE
];

export const RESUME_ORDER_ATTRIBUTES = {
    BEGIN: 'RESUME_ORDER_ATTRIBUTES_BEGIN',
    SUCCESS: 'RESUME_ORDER_ATTRIBUTES_SUCCESS',
    FAILURE: 'RESUME_ORDER_ATTRIBUTES_FAILURE'
};

const RESUME_ORDER_ATTRIBUTES_EVENTS = [
    RESUME_ORDER_ATTRIBUTES.BEGIN,
    RESUME_ORDER_ATTRIBUTES.SUCCESS,
    RESUME_ORDER_ATTRIBUTES.FAILURE
];

export const RESUME_STORE_ORDER = {
    BEGIN: 'RESUME_STORE_ORDER_BEGIN',
    SUCCESS: 'RESUME_STORE_ORDER_SUCCESS',
    FAILURE: 'RESUME_STORE_ORDER_FAILURE'
};

const RESUME_STORE_ORDER_EVENTS = [
    RESUME_STORE_ORDER.BEGIN,
    RESUME_STORE_ORDER.SUCCESS,
    RESUME_STORE_ORDER.FAILURE
];

export const RETRIEVE_SHOPPING_CART_OFFERINGS = {
    BEGIN: 'RETRIEVE_SHOPPING_CART_OFFERINGS_BEGIN',
    SUCCESS: 'RETRIEVE_SHOPPING_CART_OFFERINGS_SUCCESS',
    FAILURE: 'RETRIEVE_SHOPPING_CART_OFFERINGS_FAILURE'
};

const RETRIEVE_SHOPPING_CART_OFFERINGS_EVENTS = [
    RETRIEVE_SHOPPING_CART_OFFERINGS.BEGIN,
    RETRIEVE_SHOPPING_CART_OFFERINGS.SUCCESS,
    RETRIEVE_SHOPPING_CART_OFFERINGS.FAILURE
];

export const resumeOrderPromise = (dispatch, customerId, contractId, isConvergentBiller, offeringInstanceId, pickupId) => {
    const data = {
        AdditionalShoppingCartUpdate: !isConvergentBiller,
        ContractId: contractId,
        Offerings: offeringInstanceId ? [{
            OfferingInstanceId: offeringInstanceId
        }] : undefined,
        PickupId: pickupId || undefined,
        RetrievePhysicalInventoryDecisions: false,
        RetrieveServiceAttributes: false,
        UseSavedShoppingCart: true
    };

    return ThunkHelper(dispatch, RESUME_ORDER_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOfferingContext',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: data
    });
};

const resumeOrderHelper = (dispatch, customer, resumeOrderResponse, isConvergentBiller, shouldCalculateTaxes, paymentInstrument, offeringInstanceId, isMultiOfferEditing, pickupId) => {
    if (resumeOrderResponse.ShoppingCart.Items[0].PurchaseOrderNumber) {
        dispatch(setPurchaseOrderNumber(resumeOrderResponse.ShoppingCart.Items[0].PurchaseOrderNumber));
    }
    if (resumeOrderResponse.ShoppingCart.Items[0].ExternalContractId) {
        dispatch(setExternalContractId(resumeOrderResponse.ShoppingCart.Items[0].ExternalContractId));
    }

    dispatch(resumeOrderSetSelectOffer(resumeOrderResponse.Context.OfferingIds[0]));
    if (resumeOrderResponse.Context.Decisions.length > 0) {
        dispatch(setSelectedOfferName(resumeOrderResponse.ShoppingCart.Items[0].OrderedOfferingName));
        if (isMultiOfferEditing) {
            // Having to call RoC a 2nd time in order to pre-fill the attributes on the following step if you were to
            // increment or decrement the decisions.
            resumeOrderAttributesPromise(dispatch, customer.Id, isConvergentBiller, offeringInstanceId, pickupId)
                .then(() => {
                    dispatch(goToStep(CustomerCareKeys.WIZARD.STEPS.DECISIONS), true);
                })
                .catch((error) => {
                    //Should be handled in the component but these actions are already deeply chained.
                    getUiNotificationService().transientError(error.translatedMessage);
                });
        } else {
            dispatch(goToNextStep());
        }
    } else {
        resumeOrderAttributesPromise(dispatch, customer.Id, isConvergentBiller, offeringInstanceId, pickupId)
            .then((response) => {
                if (response.Context.ValueDecisions.length > 0 || response.Context.PhysicalInventoryDecisions.length > 0) {
                    dispatch(goToStep(CustomerCareKeys.WIZARD.STEPS.ATTRIBUTES), true);
                } else {
                    calculateAddOfferOrderQuotePromise(dispatch, customer, response.ShoppingCart, undefined, customer.Language, shouldCalculateTaxes, paymentInstrument, isConvergentBiller)
                        .then(() => {
                            dispatch(goToStep(CustomerCareKeys.WIZARD.STEPS.CHECKOUT), true);
                        })
                        .catch((error) => {
                            getUiNotificationService().transientError(error.translatedMessage);
                        });
                }
            });
    }
};

export const resumeOrder = (customer, errorCallback, isConvergentBiller, shouldCalculateTaxes = true, paymentInstrument = [], offeringInstanceId, isMultiOfferEditing, pickupId) => {
    return (dispatch, getState) => {
        const shoppingCart = ShoppingCartSelector(getState());
        const contractId = shoppingCart && shoppingCart.Items && shoppingCart.Items.length && shoppingCart.Items[0].OrderContractId ?
            shoppingCart.Items[0].OrderContractId : null;
        return resumeOrderPromise(dispatch, customer.Id, contractId, isConvergentBiller, offeringInstanceId, pickupId)
            .then((response) => {
                if (contractId) {
                    return retrieveContractPromise(dispatch, contractId)
                        .then((result) => {
                            if (result.OrderContract.IsActive) {
                                dispatch(setSelectedContract(result.OrderContract));
                                resumeOrderHelper(dispatch, customer, response, isConvergentBiller, shouldCalculateTaxes, paymentInstrument, offeringInstanceId, isMultiOfferEditing, pickupId);
                            } else {
                                dispatch(beginAddOfferOrder());
                                errorCallback();
                            }
                        });
                } else {
                    resumeOrderHelper(dispatch, customer, response, isConvergentBiller, shouldCalculateTaxes, paymentInstrument, offeringInstanceId, isMultiOfferEditing, pickupId);
                }
            })
            .catch(() => {
                getUiNotificationService().transientError(i18n.translate(CustomerCareKeys.RESUME_ORDER_FAILURE));
            });
    };
};

export const resumeOrderAttributesPromise = (dispatch, customerId, isConvergentBiller, offeringInstanceId, pickupId, completedDecisions = [], offeringId) => {
    const data = {
        AdditionalShoppingCartUpdate: !isConvergentBiller,
        CompletedDecisionsList: completedDecisions,
        ContractId: getContractId(),
        Offerings: offeringInstanceId ? [{
            OfferingId: offeringId,
            OfferingInstanceId: offeringInstanceId
        }] : undefined,
        RetrievePhysicalInventoryDecisions: true,
        RetrieveDeliveryDecisions: true,
        RetrieveServiceAttributes: true,
        UseSavedShoppingCart: true,
        PickupId: pickupId || undefined
    };

    return ThunkHelper(dispatch, RESUME_ORDER_ATTRIBUTES_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOfferingContext',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: data
    });
};

export const resumeOrderAttributes = (customerId, isConvergentBiller, skipStepLogic = false, offeringInstanceId, pickupId, completedDecisions = [], offeringId) => {
    return (dispatch) => {
        return resumeOrderAttributesPromise(dispatch, customerId, isConvergentBiller, offeringInstanceId, pickupId, completedDecisions, offeringId)
            .then((response) => {
                if (!skipStepLogic) {
                    if (response.Context.ValueDecisions.length > 0 || response.Context.PhysicalInventoryDecisions.length > 0) {
                        dispatch(goToNextStep());
                    } else {
                        dispatch(goToStep([CustomerCareKeys.WIZARD.STEPS.CHECKOUT, true]));
                    }
                }
            })
            .catch(() => {
                getUiNotificationService().error(i18n.translate(CustomerCareKeys.RESUME_ORDER_FAILURE));
            });
    };
};

const resumeStoreOrderPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, RESUME_STORE_ORDER_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/ResumeStoreOrder',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            RetrievePhysicalInventoryDecisions: false,
            RetrieveServiceAttributes: false
        }
    });
};

export const resumeStoreOrder = (customerInfo, isResumeChangeOffer, bypassQuote = false) => {
    return (dispatch) => {
        return resumeStoreOrderPromise(dispatch, customerInfo.Id)
            .then((response) => {
                if (isResumeChangeOffer || ((response.Warnings && !!(response.Warnings.find((warning) => {
                    return warning.Code === RESUME_ORDER_INSUFFICIENT_ACCESS_WARNING_CODE;
                }))))) {
                    return response;
                } else {
                    const pickupId = pathOr(null, ['ShoppingCart', 'SubscriberPhysicalInventoryPickupDetail', 'Id'], response);

                    if (pickupId) {
                        const physicalInventoryItemWithStore = response.ShoppingCart.Items.find((item) => {
                            return pathOr(null, ['PhysicalInventories', 0, 'StoreId'], item);
                        });
                        dispatch(setSelectedInventoryStores([physicalInventoryItemWithStore.PhysicalInventories[0].StoreId.toString()]));
                    }

                    // Cancelling a change offer requires that it puts the offer into a multi offer flow, be it single offer or multi offer
                    dispatch(setMultiOfferShoppingCart(response.ShoppingCart));
                    return calculateOfferingOrderQuotePromise(
                        dispatch,
                        customerInfo,
                        response.ShoppingCart,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        bypassQuote
                    )
                        .catch((error) => {
                            return getUiNotificationService().transientError(error.translatedMessage);
                        }).finally(() => {
                            dispatch(multiOfferSetCompletedSteps());
                            dispatch(goToStep(CustomerCareKeys.WIZARD.STEPS.CHECKOUT));
                        });
                }
            });
    };
};

export const CALCULATE_ADD_OFFER_ORDER_QUOTE = {
    BEGIN: 'CALCULATE_ADD_OFFER_ORDER_QUOTE_BEGIN',
    SUCCESS: 'CALCULATE_ADD_OFFER_ORDER_QUOTE_SUCCESS',
    FAILURE: 'CALCULATE_ADD_OFFER_ORDER_QUOTE_FAILURE'
};

const CALCULATE_ADD_OFFER_ORDER_QUOTE_EVENTS = [
    CALCULATE_ADD_OFFER_ORDER_QUOTE.BEGIN,
    CALCULATE_ADD_OFFER_ORDER_QUOTE.SUCCESS,
    CALCULATE_ADD_OFFER_ORDER_QUOTE.FAILURE
];

export const calculateAddOfferOrderQuotePromise = (dispatch, customer, shoppingCart, billCycleName, customerLanguage, calculateTaxes, paymentInstruments, isDbss, executionOptions = {}, isShipping, shippingInfo) => {
    const taxExemption = Object.assign({
        WholesaleFlag: customer.WholesaleFlag || false
    }, customer.TaxExemption);

    return ThunkHelper(dispatch, CALCULATE_ADD_OFFER_ORDER_QUOTE_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/CalculateOrderQuote',
        headers: addSubscriberHeaders(customer.Id, customerLanguage),
        data: {
            ExecutionDate: executionOptions.specifiedDate,
            ExecutionDateIntention: executionOptions.effective,
            BillCycleName: (billCycleName && billCycleName !== '') ? billCycleName : null,
            CalculateTaxes: (!isDbss && calculateTaxes) || undefined, //Dbss doesn't use this field, it will always CalculateTaxes
            PaymentInstruments: calculateTaxes && paymentInstruments ? paymentInstruments : [],
            RecordPaymentInformation: false,
            ReturnSubscriptionPreviews: true,
            ShoppingCart: shoppingCart,
            TaxExemption: taxExemption,
            IncludeShippingMethods: isShipping,
            ShippingMethodId: shippingInfo ? shippingInfo.methodId : undefined,
            ShippingAddressId : shippingInfo ? shippingInfo.addressId : undefined
        }
    }, {
        // TODO: Customer Order - Should not use a request parameter in the reducer. This should be handled by a separate
        // action that sets if you are calculating with Taxes or not. ASC-96616 will handle fixing quote.
        calculateTaxesInQuote: isDbss || calculateTaxes
    });
};

// Needed for ITV on checkout page
export const calculateAddOfferOrderQuoteWithTaxes = (customer, shoppingCart, paymentInstrumentsInformation, isDbss, executionOptions, billCycleName, isDeliveryTypeSet) => {
    return (dispatch) => {
        return calculateAddOfferOrderQuotePromise(dispatch, customer, shoppingCart, billCycleName, null, true, paymentInstrumentsInformation, isDbss, executionOptions, isDeliveryTypeSet);
    };
};

const handleMultiOfferGoToCheckout = (dispatch, customer, shoppingCart, language, shouldCalculateTaxes, paymentInstrument, isMultiOfferEditing, isDbss, isMultiOffer) => {
    //ToDo: Don't call update updateMultiOfferShoppingCartPromise (UpdateShoppingCart) when there have been no changes made while editing an offer in the multi-offer-add flow
    const offeringInstanceIds = uniq(pluck('OfferingInstanceId', (shoppingCart.Items || []).filter((item) => {
        return !!item.OfferingInstanceId;
    })));

    if (offeringInstanceIds.length || isMultiOffer) {
        updateMultiOfferShoppingCartPromise(dispatch, customer.Id, shoppingCart, !isMultiOfferEditing)
            .then(() => {
                dispatch(clearOfferingOrderQuote());
                retrieveShoppingCartOfferingsPromise(dispatch, customer.Id).then((response) => {
                    dispatch(clearQuote());
                    calculateOfferingOrderQuotePromise(dispatch, customer, response.ShoppingCart)
                        .then(() => {
                            dispatch(setSelectedOfferingInstanceId(null));
                            dispatch(goToStep(CustomerCareKeys.WIZARD.STEPS.CHECKOUT));
                        })
                        .catch((error) => {
                            getUiNotificationService().transientError(error.translatedMessage);
                        });
                })
                    .catch((error) => {
                        getUiNotificationService().transientError(error.translatedMessage);
                    });
            })
            .catch((error) => {
                getUiNotificationService().transientError(error.translatedMessage);
            });
    } else {
        // Single Offer flow that isn't saved
        calculateOfferingOrderQuotePromise(dispatch, customer, shoppingCart)
            .then(() => {
                dispatch(setMultiOfferShoppingCart(shoppingCart));
                dispatch(setSelectedOfferingInstanceId(null));
                dispatch(goToStep(CustomerCareKeys.WIZARD.STEPS.CHECKOUT));
            })
            .catch((error) => {
                getUiNotificationService().transientError(error.translatedMessage);
            });
    }
};

export const fetchAddOfferDecisionsAndNavigate = ({offerId,
    customer,
    isConvergentBiller=true,
    segmentationContext = [],
    shouldCalculateTaxes = true,
    paymentInstrument = [],
    isMultiOfferEditing,
    multiOfferShoppingCart,
    isDbss,
    changeOfferingInstanceId,
    pickupId}) => {
    return (dispatch, getState) => {
        dispatch(clearQuoteAndSetSelectedOffer(offerId));

        // Set Offers step to invalid until the api call is successful

        // TODO: Customer Order: We should not be grabbing state in the action, the data should be passed in only.
        // TODO: Customer Order: If showing a toast notification, that is something that should be handled in the component
        dispatch(setCurrentStepIsInvalid(true));
        const state = getState();
        const addOffer = EditCustomerInfoSelector(state);
        const subtenantId = (!customer.Id) ? addOffer.SubtenantId : null;


        return dispatch(retrieveOfferingsMetadata([offerId])).then(() => {
            return retrieveOfferingContextPromise(dispatch, offerId, customer.Id, false, undefined, isConvergentBiller, segmentationContext, addOffer.Language, subtenantId, changeOfferingInstanceId, pickupId)
                .then((response) => {
                    dispatch(setCurrentStepIsInvalid(false));
                    if (response.Context.Decisions.length) {
                        dispatch(goToStep(CustomerCareKeys.WIZARD.STEPS.DECISIONS));
                    } else {
                        retrieveAttributesPromise({
                            dispatch,
                            offeringId: offerId,
                            customerId: customer.Id,
                            isConvergentBiller,
                            customerLanguage: addOffer.Language,
                            subscriberSubtenantId: subtenantId
                        })
                            .then((response) => {
                                if (response.Context.ValueDecisions.length || (response.Context.PhysicalInventoryDecisions && response.Context.PhysicalInventoryDecisions.length)) {
                                    dispatch(goToStep(CustomerCareKeys.WIZARD.STEPS.ATTRIBUTES));
                                } else {
                                    handleMultiOfferGoToCheckout(
                                        dispatch,
                                        customer,
                                        isMultiOfferEditing ? multiOfferShoppingCart : response.ShoppingCart,
                                        addOffer.Language,
                                        shouldCalculateTaxes,
                                        paymentInstrument,
                                        isMultiOfferEditing,
                                        isDbss);
                                }
                            });
                    }

                });
        }).catch((error) => {
            getUiNotificationService().transientError(error.translatedMessage);
            dispatch(setSelectedOffer(null));
        });
    };
};

export const fetchAddOfferAttributesAndNavigate = ({offerId,
    offeringInstanceId,
    completedDecisions = [],
    customer,
    completedAttributes = [],
    completedPhysicalAttributes = [],
    shouldCalculateTaxes = true,
    paymentInstrument,
    isMultiOfferEditing,
    isDbss,
    changeOfferingInstanceId,
    pickupId,
    useSavedShoppingCart,
    isMultiOffer}) => {
    return (dispatch, getState) => {
        // TODO: Customer Order: We should not be grabbing state in the action, the data should be passed in only.
        // TODO: Customer Order: If showing a toast notification, that is something that should be handled in the component
        const state = getState();
        const addOffer = EditCustomerInfoSelector(state);
        const routeParams = RouteParams(state);
        const subtenantId = (!routeParams.customerId)? addOffer.SubtenantId : null;

        return retrieveAttributesPromise({
            dispatch,
            offeringId: offerId,
            offeringInstanceId,
            completedDecisions: completedDecisions.concat(completedAttributes).concat(completedPhysicalAttributes),
            customerId: customer.Id,
            isConvergentBiller: isDbss,
            customerLanguage: addOffer.Language,
            subscriberSubtenantId: subtenantId,
            isReconnectAction: false,
            changeOfferingInstanceId,
            useSavedShoppingCart,
            pickupId
        })
            .then((response) => {
                const visibleValueDecisions = response.Context.ValueDecisions.filter((decision) => {
                // if there is a DependentServiceAttributeId then the attribute is hidden
                // if they are all hidden, then we don't need to show attributes page
                    return !decision.DependentServiceAttributeId;
                });

                if (visibleValueDecisions.length || (response.Context.PhysicalInventoryDecisions && response.Context.PhysicalInventoryDecisions.length)) {
                    return dispatch(goToNextStep());
                } else {
                    return handleMultiOfferGoToCheckout(dispatch, customer, isMultiOfferEditing ? EditedMultiOfferShoppingCartSelector(state) : response.ShoppingCart, addOffer.Language, shouldCalculateTaxes, paymentInstrument, isMultiOfferEditing, isDbss, isMultiOffer);
                }
            })
            .catch((error) => {
                return getUiNotificationService().transientError(error.translatedMessage ? error.translatedMessage : i18n.translate(CustomerCareKeys.DECISIONS_ERROR));
            });
    };
};

const retrieveShoppingCartOfferingsPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, RETRIEVE_SHOPPING_CART_OFFERINGS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveShoppingCartOfferings',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const retrieveShoppingCartOfferings = (customerId) => {
    return (dispatch) => {
        return retrieveShoppingCartOfferingsPromise(dispatch, customerId);
    };
};

export const SET_IS_RESUMING_ORDER = 'SET_IS_RESUMING_ORDER';
export const setIsResumingOrder = (resumingOrder) => {
    return {
        type: SET_IS_RESUMING_ORDER,
        payload: resumingOrder
    };
};

export const SET_IS_RESUMING_STORE_ORDER = 'SET_IS_RESUMING_STORE_ORDER';
export const setIsResumingStoreOrder = (resumingOrder) => {
    return {
        type: SET_IS_RESUMING_STORE_ORDER,
        payload: resumingOrder
    };
};

export const SET_MULTI_OFFER_SHOPPING_CART = 'SET_MULTI_OFFER_SHOPPING_CART';
export const setMultiOfferShoppingCart = (shoppingCart) => {
    return {
        type: SET_MULTI_OFFER_SHOPPING_CART,
        payload: shoppingCart
    };
};

export const submitAddOrder = (customerInfo, shoppingCart, paymentInfo, billCycleName, executionOptions, recordPaymentInformation, isTransfer, additionalProperties, shippingInfo, orderDetails, offerAdditionalProperties) => {
    return (dispatch) => {
        return submitOrderPromise(dispatch, customerInfo, shoppingCart, paymentInfo, billCycleName, executionOptions, recordPaymentInformation, isTransfer, additionalProperties, shippingInfo, orderDetails, offerAdditionalProperties);
    };
};

export const SET_SELECTED_OFFERING_INSTANCE_ID = 'SET_SELECTED_OFFERING_INSTANCE_ID';
export const setSelectedOfferingInstanceId = (selectedOfferingInstanceId) => {
    return {
        type: SET_SELECTED_OFFERING_INSTANCE_ID,
        payload: selectedOfferingInstanceId
    };
};

export const SET_DECISIONS_TOUCHED = 'SET_DECISIONS_TOUCHED';
export const setDecisionsTouched = (payload) => {
    return {
        type: SET_DECISIONS_TOUCHED,
        payload
    };
};

export const SET_DOWN_PAYMENT_ON_MULTI_OFFER_SHOPPING_CART = 'SET_DOWN_PAYMENT_ON_MULTI_OFFER_SHOPPING_CART';
export const setDownPaymentOnMultiOfferShoppingCart = (item) => {
    return {
        type: SET_DOWN_PAYMENT_ON_MULTI_OFFER_SHOPPING_CART,
        payload: item
    };
};

export const SET_CHANGE_OFFERING_INSTANCE_ID = 'SET_CHANGE_OFFERING_INSTANCE_ID';
export const setChangeOfferingInstanceId = (offeringInstanceId) => {
    return {
        type: SET_CHANGE_OFFERING_INSTANCE_ID,
        payload: offeringInstanceId
    };
};

export const SET_CHANGE_OFFERING_ID = 'SET_CHANGE_OFFERING_ID';
export const setChangeOfferingId = (offeringId) => {
    return {
        type: SET_CHANGE_OFFERING_ID,
        payload: offeringId
    };
};

