import pathOr from 'ramda/src/pathOr';
import {getUiNotificationService} from 'invision-core/src/components/injectables/injector.helper';
import i18n from 'invision-core/src/components/i18n/i18n';
import {SUBSCRIBER_ID_HEADER} from 'invision-core/src/api/constants';
import {ThunkHelper} from 'invision-core';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import createWizardActions from '../helpers/offer.ordering.wizard.action.helper';
import {
    ContextOfferingAction,
    EDIT_WIZARD_PREFIX
} from '../constants/wizard.constants';
import {RemoveOfferReasonCodes} from './../constants/transition.offer.wizard.constants';
import {
    beginEditOfferOrder as offerOrderBeginEditOrder,
    BEGIN_EDIT_OFFER_ORDER as OFFER_ORDER_BEGIN_ORDER,
    calculateChangeOfServiceOfferingOrderQuotePromise,
    getContractInstance,
    getContractId,
    setSelectedInventoryStores
} from '../actions/offering.order.actions';
import {
    INVOICE_TIMING_CONSTANTS,
    REASON_CODES
} from '../../customercare.constants';
import CustomerCareKeys from '../../locales/keys';
import {
    AddItemsSelector,
    ModifyItemsSelector,
    RemoveItemsSelector
} from '../selectors/selected.offering.order.selectors';

const wizardActions = createWizardActions(EDIT_WIZARD_PREFIX);

export const BEGIN_EDIT_OFFER_ORDER = OFFER_ORDER_BEGIN_ORDER;
export const CANCEL_ORDER = wizardActions.CANCEL_WIZARD;
export const GO_TO_NEXT_STEP = wizardActions.GO_TO_NEXT_STEP;
export const GO_TO_PREVIOUS_STEP = wizardActions.GO_TO_PREVIOUS_STEP;
export const GO_TO_STEP = wizardActions.GO_TO_STEP;
export const RETRIEVE_ADDRESSES = wizardActions.RETRIEVE_ADDRESSES;
export const SAVE_OPTION_PRICING = wizardActions.SAVE_OPTION_PRICING;
export const SAVE_BWS_OPTION_EDIT = wizardActions.SAVE_BWS_OPTION_EDIT;
export const SEND_PORT_IN_OR_ITEM_RESERVATION = wizardActions.SEND_PORT_IN_OR_ITEM_RESERVATION;
export const SET_ATTRIBUTE_SEARCHING_INVENTORY = wizardActions.SET_ATTRIBUTE_SEARCHING_INVENTORY;
export const SET_CURRENT_STEP_IS_INVALID = wizardActions.SET_CURRENT_STEP_IS_INVALID;
export const SET_DECISIONS_REQUIRING_AFFECTED_SERVICES_RESOLUTION = 'SET_DECISIONS_REQUIRING_AFFECTED_SERVICES_RESOLUTION';
export const SET_EDIT_ATTRIBUTE_GROUPS = wizardActions.SET_EDIT_ATTRIBUTE_GROUPS;
export const SET_EDIT_OPTION = wizardActions.SET_EDIT_OPTION;
export const SET_EDIT_CART_ADDITIONAL_PROPERTY = wizardActions.SET_EDIT_CART_ADDITIONAL_PROPERTY;
export const SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE = wizardActions.SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE;
export const SET_EDIT_ORDER_ADDITIONAL_PROPERTY = wizardActions.SET_EDIT_ORDER_ADDITIONAL_PROPERTY;
export const SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE = wizardActions.SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE;
export const SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS = wizardActions.SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS;
export const SET_PAYMENT_INFO = wizardActions.SET_PAYMENT_INFO;
export const SET_SELECTED_EDIT_OFFER_TAB = wizardActions.SET_SELECTED_WIZARD_TAB;
export const SET_SELECTED_OFFER = wizardActions.SET_SELECTED_OFFER;
export const SET_SELECTED_OFFER_EDIT_COPY = wizardActions.SET_SELECTED_OFFER_EDIT_COPY;
export const UPDATE_EDIT_BRI_PRICE = wizardActions.UPDATE_EDIT_BRI_PRICE;
export const UPDATE_INVENTORY_SELECTION= wizardActions.UPDATE_INVENTORY_SELECTION;
export const UPDATE_OFFER_ATTRIBUTE = wizardActions.UPDATE_OFFER_ATTRIBUTE;
export const UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL = wizardActions.UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL;
export const UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE = wizardActions.UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE;
export const UPDATE_PAYMENT_INFO = wizardActions.UPDATE_PAYMENT_INFO;
export const UPDATE_PORT_IN_REQUEST = wizardActions.UPDATE_PORT_IN_REQUEST;
export const UPDATE_SELECTED_SERVICE_FEATURES = wizardActions.UPDATE_SELECTED_SERVICE_FEATURES;

export const beginEditOfferOrder = offerOrderBeginEditOrder;
export const cancelOrder = wizardActions.cancelWizard;
export const fetchAddresses = wizardActions.fetchAddresses;
export const fetchAttributes = wizardActions.fetchAttributes;
export const fetchWallet = wizardActions.fetchWallet;
export const goToNextStep = wizardActions.goToNextStep;
export const goToPreviousStep = wizardActions.goToPreviousStep;
export const goToStep = wizardActions.goToStep;
export const saveOptionEdit = wizardActions.saveOptionEdit;
export const saveBulkPricing = wizardActions.saveBulkPricing;
export const searchAttributeInventory = wizardActions.searchAttributeInventory;
export const sendPortInOrItemReservation = wizardActions.sendPortInOrItemReservation;
export const setCurrentStepIsInvalid = wizardActions.setCurrentStepIsInvalid;
export const setEditAttributeGroups = wizardActions.setEditAttributeGroups;
export const setEditOption = wizardActions.setEditOption;
export const setEditCartAdditionalProperty = wizardActions.setEditCartAdditionalProperty;
export const setEditCartAdditionalPropertyOnChange = wizardActions.setEditCartAdditionalPropertyOnChange;
export const setEditOrderAdditionalProperty = wizardActions.setEditOrderAdditionalProperty;
export const setEditOrderAdditionalPropertyOnChange = wizardActions.setEditOrderAdditionalPropertyOnChange;
export const setEditPhysicalAttributeGroups = wizardActions.setEditPhysicalAttributeGroups;
export const setPaymentInfo = wizardActions.setPaymentInfo;
export const setSelectedOffer = wizardActions.setSelectedOffer;
export const setSelectedOfferEditCopy = wizardActions.setSelectedOfferEditCopy;
export const setSelectedTab = wizardActions.setSelectedTab;
export const updateAttribute = wizardActions.updateAttribute;
export const updateBillCycle = wizardActions.updateBillCycle;
export const updateCart = wizardActions.updateCart;
export const updateEditOptionPrice = wizardActions.updateEditOptionPrice;
export const updateInventorySelection = wizardActions.updateInventorySelection;
export const updateOfferPhysicalInventoryMakeAndModel = wizardActions.updateOfferPhysicalInventoryMakeAndModel;
export const updateOfferPhysicalInventoryTypeAttribute = wizardActions.updateOfferPhysicalInventoryTypeAttribute;
export const updatePaymentInfo = wizardActions.updatePaymentInfo;
export const updatePortInRequest = wizardActions.updatePortInRequest;
export const updateSelectedServiceFeatures = wizardActions.updateSelectedServiceFeatures;

const retrieveOfferingContextPromise = (dispatch, customerId, data, STATUSES_ARRAY) => {
    return ThunkHelper(dispatch, STATUSES_ARRAY, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOfferingContext',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: data
    });
};

const handleEditRetrievePreviousAttributesPromise = (dispatch, customerId, offeringId, offeringInstanceId, isConvergentBiller, isReconnectAction) => {
    return editRetrievePreviousAttributesPromise(
        dispatch,
        customerId,
        offeringId,
        offeringInstanceId,
        null,
        isConvergentBiller,
        isReconnectAction
    ).then((response) => {
        if (response.Context.ValueDecisions.length > 0 || response.Context.PhysicalInventoryDecisions.length > 0) {
            dispatch(goToStep([CustomerCareKeys.WIZARD.STEPS.ATTRIBUTES, true]));
        } else {
            dispatch(goToStep([CustomerCareKeys.WIZARD.STEPS.CHECKOUT, true]));
        }
    });
};


export const EDIT_INITIAL_OFFERING_CONTEXT = {
    BEGIN: 'EDIT_INITIAL_OFFERING_CONTEXT_BEGIN',
    SUCCESS: 'EDIT_INITIAL_OFFERING_CONTEXT_SUCCESS',
    FAILURE: 'EDIT_INITIAL_OFFERING_CONTEXT_FAILURE'
};

const EDIT_INITIAL_OFFERING_CONTEXT_TYPES = [
    EDIT_INITIAL_OFFERING_CONTEXT.BEGIN,
    EDIT_INITIAL_OFFERING_CONTEXT.SUCCESS,
    EDIT_INITIAL_OFFERING_CONTEXT.FAILURE
];


export const fetchPreviousDecisionsPromise = (
    dispatch,
    customerId,
    offeringId,
    offeringInstanceId,
    completedDecisions = [],
    isConvergentBiller = false,
    isReconnectAction = false
) => {
    const data = {
        Offerings: [{
            Action: isReconnectAction ? ContextOfferingAction.RECONNECT : ContextOfferingAction.MODIFY,
            OfferingId: offeringId,
            OfferingInstanceId: offeringInstanceId
        }],
        AdditionalShoppingCartUpdate: !isConvergentBiller,
        RetrieveServiceAttributes: false,
        RetrievePhysicalInventoryDecisions: false,
        CompletedDecisionsList: completedDecisions,
        ContractId: getContractId(),
        OrderContractInstance: getContractInstance(offeringId)
    };
    return ThunkHelper(dispatch, EDIT_INITIAL_OFFERING_CONTEXT_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveOfferingContext',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: data
    });
};

export const fetchPreviousDecisionsAndNavigate = (
    customerId,
    offeringId,
    offeringInstanceId,
    emptyDecisionGroups = null,
    isConvergentBiller = false,
    isReconnectAction = false
) => {
    return (dispatch) => {
        // Disallow the user from submitting again until the request is done
        dispatch(setCurrentStepIsInvalid(true));
        dispatch(setSelectedOffer(offeringId));
        dispatch(setSelectedOfferEditCopy(offeringId));

        let previousDecisionsPromise = Promise.resolve();
        if (emptyDecisionGroups === null) {
            previousDecisionsPromise = previousDecisionsPromise.then( () => {
                return fetchPreviousDecisionsPromise(dispatch, customerId, offeringId, offeringInstanceId, emptyDecisionGroups, isConvergentBiller, isReconnectAction);
            }).then((response) => {
                if (!response.Context.Decisions.length) {
                    return handleEditRetrievePreviousAttributesPromise(dispatch, customerId, offeringId, offeringInstanceId, isConvergentBiller, isReconnectAction);
                }
            });
        } else if (emptyDecisionGroups) {
            previousDecisionsPromise = previousDecisionsPromise.then( () => {
                return handleEditRetrievePreviousAttributesPromise(dispatch, customerId, offeringId, offeringInstanceId, isConvergentBiller, isReconnectAction);
            });
        }

        previousDecisionsPromise.catch((error) => {
            getUiNotificationService().transientError(error.translatedMessage !== error.Code.toString()
                ? error.translatedMessage
                : i18n.translate(CustomerCareKeys.DECISIONS_ERROR));
        }).finally(() => {
            // Make it ok for the user to submit again
            dispatch(setCurrentStepIsInvalid(false));
        });

        return previousDecisionsPromise;
    };
};

export const EDIT_RETRIEVE_PREVIOUS_ATTRIBUTES = {
    BEGIN: 'EDIT_RETRIEVE_PREVIOUS_ATTRIBUTES_BEGIN',
    SUCCESS: 'EDIT_RETRIEVE_PREVIOUS_ATTRIBUTES_SUCCESS',
    FAILURE: 'EDIT_RETRIEVE_PREVIOUS_ATTRIBUTES_FAILURE'
};
const EDIT_RETRIEVE_PREVIOUS_ATTRIBUTES_TYPES = [
    EDIT_RETRIEVE_PREVIOUS_ATTRIBUTES.BEGIN,
    EDIT_RETRIEVE_PREVIOUS_ATTRIBUTES.SUCCESS,
    EDIT_RETRIEVE_PREVIOUS_ATTRIBUTES.FAILURE
];

const editRetrievePreviousAttributesPromise = (
    dispatch,
    customerId,
    offeringId,
    offeringInstanceId,
    completedDecisions = [],
    isConvergentBiller = false,
    isReconnectAction = false
) => {
    const data = {
        Offerings: [{
            Action: isReconnectAction ? ContextOfferingAction.RECONNECT : ContextOfferingAction.MODIFY,
            OfferingId: offeringId,
            OfferingInstanceId: offeringInstanceId
        }],
        AdditionalShoppingCartUpdate: !isConvergentBiller,
        RetrieveServiceAttributes: true,
        RetrievePaymentInstrument: true,
        RetrieveDeliveryDecisions: true,
        RetrievePhysicalInventoryDecisions: true,
        CompletedDecisionsList: completedDecisions,
        ContractId: getContractId(),
        OrderContractInstance: getContractInstance(offeringId)
    };
    return retrieveOfferingContextPromise(dispatch, customerId, data, EDIT_RETRIEVE_PREVIOUS_ATTRIBUTES_TYPES);
};

export const fetchPreviousAttributesAndNavigate = (customerInfo, offeringId, offeringInstanceId, completedDecisions,
    isConvergentBiller = false, isReconnectAction = false, bypassQuote = false) => {
    return (dispatch, getState) => {
        // Disallow the user from submitting again until the request is done
        dispatch(setCurrentStepIsInvalid(true));

        return editRetrievePreviousAttributesPromise(dispatch, customerInfo.Id, offeringId, offeringInstanceId,
            completedDecisions, isConvergentBiller, isReconnectAction)
            .then((response) => {
                const visibleValueDecisions = response.Context.ValueDecisions.filter((decision) => {
                    // if there is a DependentServiceAttributeId then the attribute is hidden
                    // if they are all hidden, then we don't need to show attributes page
                    return !decision.DependentServiceAttributeId;
                });
                const addItems = AddItemsSelector(getState());
                const modifyItems = ModifyItemsSelector(getState());
                const removeItems = RemoveItemsSelector(getState());
                if (visibleValueDecisions.length > 0 || response.Context.PhysicalInventoryDecisions.length > 0) {
                    dispatch(goToNextStep());
                } else {
                    const reasonCode = isReconnectAction ? convertStringToNumber(REASON_CODES.RECONNECT) : null;
                    return calculateChangeOfServiceOfferingOrderQuotePromise(
                        dispatch,
                        customerInfo,
                        offeringId,
                        addItems,
                        removeItems,
                        modifyItems,
                        undefined,
                        reasonCode,
                        undefined,
                        undefined,
                        bypassQuote
                    ).then(() => {
                        dispatch(goToStep(CustomerCareKeys.WIZARD.STEPS.CHECKOUT));
                    }).catch((error) => {
                        getUiNotificationService().transientError(error.translatedMessage);
                    });
                }
            })
            .catch((error) => {
                getUiNotificationService().transientError(error.translatedMessage !== error.Code.toString() ? error.translatedMessage : i18n.translate(CustomerCareKeys.DECISIONS_ERROR));
            })
            .finally(() => {
                // Make it ok for the user to submit again
                dispatch(setCurrentStepIsInvalid(false));
            });
    };
};

export const fetchAttributesQuote = (
    customerId,
    offeringId,
    offeringInstanceId,
    completedDecisions = [],
    completedAttributes = [],
    completedPhysicalAttributes = [],
    isConvergentBiller = false,
    isReconnectAction = false
) => {
    return (dispatch) => {
        return editRetrievePreviousAttributesPromise(
            dispatch,
            customerId,
            offeringId,
            offeringInstanceId,
            completedDecisions.concat(completedAttributes).concat(completedPhysicalAttributes),
            isConvergentBiller,
            isReconnectAction
        );
    };
};

export const EDIT_SUBMIT_COS_ORDER = {
    BEGIN: 'EDIT_SUBMIT_COS_ORDER_BEGIN',
    SUCCESS: 'EDIT_SUBMIT_COS_ORDER_SUCCESS',
    FAILURE: 'EDIT_SUBMIT_COS_ORDER_FAILURE'
};
const EDIT_SUBMIT_COS_ORDER_TYPES = [
    EDIT_SUBMIT_COS_ORDER.BEGIN,
    EDIT_SUBMIT_COS_ORDER.SUCCESS,
    EDIT_SUBMIT_COS_ORDER.FAILURE
];

export const editOfferSubmitCosOrder = (customerId, offeringId, offeringInstanceId, addItems, modifyItems, removeItems, paymentInfo, changesEffective, executionOptions, recordPaymnetInfo, isReconnectOffer, pickupId, additionalProperties, shippingInfo, orderDetails) => {
    return (dispatch) => {
        return editOfferSubmitCosOrderPromise(dispatch, customerId, offeringId, offeringInstanceId, addItems, modifyItems, removeItems, paymentInfo, changesEffective, executionOptions, recordPaymnetInfo, isReconnectOffer, pickupId, additionalProperties, shippingInfo, orderDetails);
    };
};

export const editOfferSubmitCosOrderPromise = (dispatch, customerId, offeringId, offeringInstanceId, addItems, modifyItems, removeItems, paymentInfo, changesEffective, executionOptions, recordPaymentInformation, isReconnectOffer, pickupId, additionalProperties, shippingInfo = {}, orderDetails = {}) => {
    const paymentInfoArray = paymentInfo ? [paymentInfo] : [];
    const data = {
        AddItems: addItems,
        AdditionalProperties: additionalProperties || null,
        ChangeImmediately: true,
        Comment: orderDetails?.comment || null,
        ExecutionDate: executionOptions.specifiedDate,
        ExecutionDateIntention: executionOptions.effective,
        ModifyItems: modifyItems,
        OfferingId: offeringId,
        OfferingInstanceId: offeringInstanceId,
        PaymentInstruments: paymentInfoArray,
        ReasonCode: isReconnectOffer ? REASON_CODES.RECONNECT : orderDetails?.reasonCode,
        RecordPaymentInformation: recordPaymentInformation,
        RemoveItems: removeItems,
        PickupId: pickupId || null,
        ShippingAddressId: shippingInfo.addressId || undefined,
        ShippingAddress: shippingInfo.address || undefined,
        ShippingMethodId: shippingInfo.methodId || undefined,
        ShipToName: shippingInfo.shipTo || undefined
    };

    if (+changesEffective === INVOICE_TIMING_CONSTANTS.INVOICE_ON_CYCLE) {
        data.ChangeImmediately = false;
    }

    if (removeItems.length) {
        data.RemoveReasonCode = RemoveOfferReasonCodes.DefaultedToRemove;
    }

    return ThunkHelper(dispatch, EDIT_SUBMIT_COS_ORDER_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/SubmitChangeOfServiceOrder',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: data
    });
};

export const setDecisionsRequiringAffectedServicesResolution = (decisions) => {
    return {
        type: SET_DECISIONS_REQUIRING_AFFECTED_SERVICES_RESOLUTION,
        payload: decisions
    };
};

export const RETRIEVE_DECISION_OPTION_SERVICE_IDS = {
    BEGIN: 'RETRIEVE_DECISION_OPTION_SERVICE_IDS_BEGIN',
    SUCCESS: 'RETRIEVE_DECISION_OPTION_SERVICE_IDS_SUCCESS',
    FAILURE: 'RETRIEVE_DECISION_OPTION_SERVICE_IDS_FAILURE'
};
const RETRIEVE_DECISION_OPTION_SERVICE_IDS_TYPES = [
    RETRIEVE_DECISION_OPTION_SERVICE_IDS.BEGIN,
    RETRIEVE_DECISION_OPTION_SERVICE_IDS.SUCCESS,
    RETRIEVE_DECISION_OPTION_SERVICE_IDS.FAILURE
];

export const retrieveDecisionOptionServiceIds = (customerId, decisionOptionIds, offeringInstanceId) => {
    return (dispatch) => {
        return retrieveDecisionOptionServiceIdsPromise(dispatch, customerId, decisionOptionIds, offeringInstanceId);
    };
};

export const retrieveDecisionOptionServiceIdsPromise = (dispatch, customerId, decisionOptionIds, offeringInstanceId) => {
    return ThunkHelper(dispatch, RETRIEVE_DECISION_OPTION_SERVICE_IDS_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveConvergentBillerServices',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            DecisionOptionIds: decisionOptionIds,
            OfferingInstanceId: offeringInstanceId
        }
    });
};

export const SET_IS_RESUMING_ORDER = 'SET_IS_RESUMING_ORDER';
export const setIsResumingOrder = (resumingOrder) => {
    return {
        type: SET_IS_RESUMING_ORDER,
        payload: resumingOrder
    };
};

export const SET_IS_RESUMING_STORE_ORDER_FOR_EDIT_OFFER = 'SET_IS_RESUMING_STORE_ORDER_FOR_EDIT_OFFER';
export const setIsResumingStoreOrderForEditOffer = (resumingOrder) => {
    return {
        type: SET_IS_RESUMING_STORE_ORDER_FOR_EDIT_OFFER,
        payload: resumingOrder
    };
};

export const SET_PHYSICAL_INVENTORY_ID = 'SET_PHYSICAL_INVENTORY_ID';
export const setPhysicalInventoryId = (InventoryId) => {
    return {
        type: SET_PHYSICAL_INVENTORY_ID,
        payload: InventoryId
    };
};

//first store order resume
export const RESUME_STORE_ORDER_FOR_DECISIONS_FOR_EDIT= {
    BEGIN: 'RESUME_STORE_ORDER_BEGIN_FIRST',
    SUCCESS: 'RESUME_STORE_ORDER_SUCCESS_FIRST',
    FAILURE: 'RESUME_STORE_ORDER_FAILURE_FIRST'
};

export const RESUME_STORE_ORDER_EVENTS_FOR_DECISIONS_FOR_EDIT = [
    RESUME_STORE_ORDER_FOR_DECISIONS_FOR_EDIT.BEGIN,
    RESUME_STORE_ORDER_FOR_DECISIONS_FOR_EDIT.SUCCESS,
    RESUME_STORE_ORDER_FOR_DECISIONS_FOR_EDIT.FAILURE
];

export const RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_EDIT= {
    BEGIN: 'RESUME_STORE_ORDER_BEGIN_SECOND',
    SUCCESS: 'RESUME_STORE_ORDER_SUCCESS_SECOND',
    FAILURE: 'RESUME_STORE_ORDER_FAILURE_SECOND'
};

export const RESUME_STORE_ORDER_EVENTS_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_EDIT = [
    RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_EDIT.BEGIN,
    RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_EDIT.SUCCESS,
    RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_EDIT.FAILURE
];

const resumeStoreOrderForDecisionsPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, RESUME_STORE_ORDER_EVENTS_FOR_DECISIONS_FOR_EDIT, {
        method: 'post',
        url: 'SubscriberManagement/ResumeStoreOrder',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            RetrievePhysicalInventoryDecisions: false,
            RetrieveServiceAttributes: false
        }
    });
};
const resumeStoreOrderForInventoryAttributesDecisionsPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, RESUME_STORE_ORDER_EVENTS_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_EDIT, {
        method: 'post',
        url: 'SubscriberManagement/ResumeStoreOrder',
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            RetrievePhysicalInventoryDecisions: true,
            RetrieveDeliveryDecisions: true,
            RetrieveServiceAttributes: true
        }
    });
};

export const resumeStoreOrder = (customerInfo) => {
    return (dispatch) => {
        return resumeStoreOrderForDecisionsPromise(dispatch, customerInfo.Id)
            .then((response) => {
                const pickupId = pathOr(null, ['ShoppingCart', 'SubscriberPhysicalInventoryPickupDetail', 'Id'], response);
                if (pickupId) {
                    const physicalInventoryItemWithStore = response.ShoppingCart.Items.find((item) => {
                        return pathOr(null, ['PhysicalInventories', 0, 'StoreId'], item);
                    });
                    if (physicalInventoryItemWithStore) {
                        dispatch(setSelectedInventoryStores([physicalInventoryItemWithStore.PhysicalInventories[0].StoreId.toString()]));
                    }
                }
                return resumeStoreOrderForInventoryAttributesDecisionsPromise(dispatch, customerInfo.Id);
            })
            .then((response) => {
                const pickupId = pathOr(null, ['ShoppingCart', 'SubscriberPhysicalInventoryPickupDetail', 'Id'], response);
                if (pickupId) {
                    const physicalInventoryItemWithStore = response.ShoppingCart.Items.find((item) => {
                        return pathOr(null, ['PhysicalInventories', 0, 'StoreId'], item);
                    });
                    if (physicalInventoryItemWithStore) {
                        dispatch(setSelectedInventoryStores([physicalInventoryItemWithStore.PhysicalInventories[0].StoreId.toString()]));
                    }
                }
                dispatch(goToStep(CustomerCareKeys.WIZARD.STEPS.DECISIONS), true);
            });
    };
};

export const SET_SERVICE_TAX_LOCATION_DECISION_EDIT_OFFER = 'SET_SERVICE_TAX_LOCATION_DECISION_EDIT_OFFER';

export const updateServiceTaxLocationDecisionEditOffer = (payload) => {
    return {
        type: SET_SERVICE_TAX_LOCATION_DECISION_EDIT_OFFER,
        payload: payload
    };
};
