import Immutable from 'seamless-immutable';
import {RESET_CUSTOMER_RECOVERABLE} from './../actions/customer.actions';
import * as SubscriptionActions from './../actions/customer.subscriptions.actions';

import {
    SUBSCRIPTION_TYPE
} from '../../components/shared/constants/subscriptions.constants';

export const INITIAL_STATE = Immutable({
    filterData: {
        includeRemoved: false,
        pageSizePreference: null
    },
    pageNumber: 1,
    selectedDiscount: {},
    renewalUnderEdit: {
        renew: false,
        isRenewTypeConfigurable: false,
        terminationReason: 0,
        type: SUBSCRIPTION_TYPE.MANUAL
    }
});

export default function SubscriptionsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case SubscriptionActions.SET_CURRENT_PAGE:
            return state.set('pageNumber', payload);
        case SubscriptionActions.SET_EDIT_RENEWAL:
            return state.set('renewalUnderEdit', payload);
        case SubscriptionActions.SET_INCLUDED_FILTER:
            return state.setIn(['filterData', 'includeRemoved'], payload);
        case SubscriptionActions.SET_SELECTED_DISCOUNT:
            return state.set('selectedDiscount', payload);
        case SubscriptionActions.SET_SUBSCRIPTIONS_PAGE_SIZE_PREFERENCE:
            return state.setIn(['filterData', 'pageSizePreference'], payload);
        case SubscriptionActions.REMOVE_SUBSCRIPTIONS_FILTER:
        case RESET_CUSTOMER_RECOVERABLE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
