import {stateGo} from 'redux-ui-router';
import {
    ApiConstants,
    InjectorHelper,
    SessionSelectors,
    ThunkHelper
} from 'invision-core';
import {SEARCH_ENTITY_TYPES} from '../constants/search.constants';
import {CustomerSearchArraySelector,
    CustomerSearchRecordCountSelector} from '../selectors/search.customer.selectors';
import {hideSearchPanel} from './search.actions';

export const CUSTOMER_SEARCH = {
    BEGIN: 'CUSTOMER_SEARCH_BEGIN',
    SUCCESS: 'CUSTOMER_SEARCH_SUCCESS',
    FAILURE: 'CUSTOMER_SEARCH_FAILURE'
};
const CUSTOMER_SEARCH_EVENTS = [CUSTOMER_SEARCH.BEGIN, CUSTOMER_SEARCH.SUCCESS, CUSTOMER_SEARCH.FAILURE];

export const CUSTOMER_SEARCH_SUGGESTIONS = {
    BEGIN: 'CUSTOMER_SEARCH_SUGGESTIONS_BEGIN',
    SUCCESS: 'CUSTOMER_SEARCH_SUGGESTIONS_SUCCESS',
    FAILURE: 'CUSTOMER_SEARCH_SUGGESTIONS_FAILURE'
};
const CUSTOMER_SEARCH_SUGGESTIONS_EVENTS = [CUSTOMER_SEARCH_SUGGESTIONS.BEGIN, CUSTOMER_SEARCH_SUGGESTIONS.SUCCESS, CUSTOMER_SEARCH_SUGGESTIONS.FAILURE];

export const CUSTOMER_SEARCH_WITHIN_SUBSCRIBER = {
    BEGIN: 'CUSTOMER_SEARCH_WITHIN_SUBSCRIBER_BEGIN',
    SUCCESS: 'CUSTOMER_SEARCH_WITHIN_SUBSCRIBER_SUCCESS',
    FAILURE: 'CUSTOMER_SEARCH_WITHIN_SUBSCRIBER_FAILURE'
};

export const SET_CUSTOMER_SEARCH_MODEL = 'SET_CUSTOMER_SEARCH_MODEL';
export const setCustomerSearchModel = (payload) => {
    return {
        type: SET_CUSTOMER_SEARCH_MODEL,
        payload: payload
    };
};

export const RESET_SEARCH_CUSTOMER_MODEL_RECOVERABLE = 'RESET_SEARCH_CUSTOMER_MODEL_RECOVERABLE';
export const resetCustomerSearchRecoverable = () => {
    return {
        type: RESET_SEARCH_CUSTOMER_MODEL_RECOVERABLE
    };
};

const CUSTOMER_SEARCH_WITHIN_SUBSCRIBER_EVENTS = [CUSTOMER_SEARCH_WITHIN_SUBSCRIBER.BEGIN, CUSTOMER_SEARCH_WITHIN_SUBSCRIBER.SUCCESS, CUSTOMER_SEARCH_WITHIN_SUBSCRIBER.FAILURE];


const customerSearchPromise = (dispatch, customerSearchModel, pageNumber, pageSize, events = CUSTOMER_SEARCH_EVENTS) => {
    const $ngRedux = InjectorHelper.getNgReduxService();
    const state = $ngRedux.getState();
    return ThunkHelper(dispatch, events, {
        method: 'post',
        url: 'SubscriberManagement/SearchSubscribers',
        headers: {
            [ApiConstants.CUSTOM_SUBSCRIBER_LANGUAGE_HEADER]: SessionSelectors.LanguageHeaderSelector(state)
        },
        data: {
            AddressLineOne: customerSearchModel.addressLineOne,
            CommercialName: customerSearchModel.hierarchyName,
            CompanyName: customerSearchModel.companyName,
            ConvergentBillerAccountNumber: customerSearchModel.armAccountNumber,
            DeviceId: customerSearchModel.deviceId,
            DeviceSerialNumber: customerSearchModel.deviceSerialNumber,
            ExternalReferenceId: customerSearchModel.externalId,
            FirstName: customerSearchModel.firstName,
            LastName: customerSearchModel.lastName,
            LoginOrEmailAddress: customerSearchModel.loginOrEmailAddress || customerSearchModel.emailAddress,
            OrderId: customerSearchModel.orderId,
            OrderNumber: customerSearchModel.orderNumber,
            PageSize: pageSize,
            PageNumber: pageNumber,
            PhoneNumber: customerSearchModel.phoneNumber,
            PostalCode: customerSearchModel.postalCode,
            ServiceId: customerSearchModel.serviceId,
            SubscriberId: customerSearchModel.subscriberId,
            SubscriberIdentification: customerSearchModel.subscriberIdentification,
            CategoryType: customerSearchModel.categoryType,

            // Transaction search related fields
            FilterPaymentInstrumentType: customerSearchModel.filterPaymentInstrumentType,
            PaymentInstrumentSubType: customerSearchModel.paymentInstrumentSubType,
            PaymentAccount: customerSearchModel.paymentAccount,
            PaymentReferenceId: customerSearchModel.paymentReferenceId,
            PaymentReconciliationId: customerSearchModel.paymentReconciliationId,
            PaymentTransactionDate: customerSearchModel.paymentTransactionDate,
            PaymentFirstFourDigits: customerSearchModel.paymentFirstFourDigits,
            PaymentLastFourDigits: customerSearchModel.paymentLastFourDigits,
            PurchaseReceiptReference: customerSearchModel.purchaseReceiptReference,
            ReturnUniqueSubscribersByTransaction: customerSearchModel.returnUniqueSubscribersByTransaction,
            SubscriberAdditionalProperties: customerSearchModel.subscriberAdditionalProperties,
            Title: customerSearchModel.title
        }
    });
};

export const getCustomerSearch = (customerSearchModel, pageNumber, pageSize) => {
    return (dispatch) => {
        return customerSearchPromise(dispatch, customerSearchModel, pageNumber, pageSize).then(() => {
            const $ngRedux = InjectorHelper.getNgReduxService();
            if (CustomerSearchRecordCountSelector($ngRedux.getState()) === 1) {
                const customer = CustomerSearchArraySelector($ngRedux.getState())[0];
                dispatch(routeToCustomer(customer));
            }
        });
    };
};

const searchSubscriberSSuggestionsPromise = (dispatch, searchString, searchBy, fieldType) => {
    return ThunkHelper(dispatch, CUSTOMER_SEARCH_SUGGESTIONS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SearchSubscriberSuggestions',
        data: {
            'SearchString': searchString,
            'SearchBy': searchBy,
            'FieldType': fieldType
        }
    });
};

export const searchSubscriberSuggestions = (searchString, searchBy, fieldType = 1) => {
    return (dispatch) => {
        return searchSubscriberSSuggestionsPromise(dispatch, searchString, searchBy, fieldType);
    };
};

export const searchCustomersWithinSubscriberEvents = (customerSearchModel, pageNumber, pageSize) => {
    return (dispatch) => {
        return customerSearchPromise(dispatch, customerSearchModel, pageNumber, pageSize, CUSTOMER_SEARCH_WITHIN_SUBSCRIBER_EVENTS);
    };
};

export const routeToCustomer = (customer) => {

    // Defaults state and params
    let state = 'index.customercare.customer';
    const params = {
        customerId: customer.Id
    };

    // Determine which state is navigated to based on the searched entity type.
    switch (customer.SearchEntityResult && customer.SearchEntityResult.EntityType) {
        case SEARCH_ENTITY_TYPES.TRANSACTION:
            state = 'index.customercare.customer.transactions.list';
            params.fromTransactionSearch = true;
            break;
        case SEARCH_ENTITY_TYPES.DEVICE:
            state = 'index.customercare.customer.devices';
            params.deviceId = customer.SearchEntityResult.EntityId;
            break;
        case SEARCH_ENTITY_TYPES.ORDER:
            state = 'index.customercare.customer.orderHistory.details';
            params.orderId = customer.SearchEntityResult.EntityId;
            break;
        default:
            break;
    }

    return (dispatch) => {
        dispatch(stateGo(state, params, {
            reload: true
        }));
        dispatch(hideSearchPanel());
    };
};
