import {
    EMPTY_COUPONS_LIST,
    GRANT_COUPON_CONSTANTS,
    SEARCH_COUPON_REDEMPTIONS_CONSTANTS,
    SEARCH_GRANTABLE_COUPONS_CONSTANTS,
    SEARCH_REDEMPTION_CODES_CONSTANTS,
    SET_SELECTED_COUPON,
    RESEND_COUPON_REDEMPTION_CODE,
    RETRIEVE_REDEMPTION_CODE_DETAILS_CONSTANTS
} from './actions/customer.coupons.actions';
import Immutable from 'seamless-immutable';

export const INITIAL_STATE = Immutable({
    couponsError: null,
    data: {
        couponRedemptions: null,
        grantableCoupons: null,
        lastGrantedCouponDetails: null,
        redemptionCodes: null,
        selectedCoupon: null
    },
    isFetchingGrantableCoupons: false,
    isFetchingCouponRedemptions: false,
    isFetchingRedemptionCodes: false,
    isGrantingCoupon: false
});

export default function couponsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case GRANT_COUPON_CONSTANTS.BEGIN:
            return state.set('isGrantingCoupon', true);
        case GRANT_COUPON_CONSTANTS.SUCCESS:
            return state
                .set('isGrantingCoupon', false)
                .setIn(['data', 'lastGrantedCouponDetails'], payload)
                .set('couponsError', null);
        case GRANT_COUPON_CONSTANTS.FAILURE:
            return state
                .set('isGrantingCoupon', false)
                .set('couponsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.severity
                });
        case SEARCH_GRANTABLE_COUPONS_CONSTANTS.BEGIN:
            return state.set('isFetchingGrantableCoupons', true);
        case SEARCH_GRANTABLE_COUPONS_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingGrantableCoupons', false)
                .setIn(['data', 'grantableCoupons'], payload)
                .set('couponsError', null);
        case SEARCH_GRANTABLE_COUPONS_CONSTANTS.FAILURE:
            return state
                .set('isFetchingGrantableCoupons', false)
                .set('couponsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.severity
                });
        case SEARCH_COUPON_REDEMPTIONS_CONSTANTS.BEGIN:
            return state.set('isFetchingCouponRedemptions', true);
        case SEARCH_COUPON_REDEMPTIONS_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingCouponRedemptions', false)
                .setIn(['data', 'couponRedemptions'], payload)
                .set('couponsError', null);
        case SEARCH_COUPON_REDEMPTIONS_CONSTANTS.FAILURE:
            return state
                .set('isFetchingCouponRedemptions', false)
                .set('couponsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.severity
                });
        case SEARCH_REDEMPTION_CODES_CONSTANTS.BEGIN:
            return state.set('isFetchingRedemptionCodes', true);
        case SEARCH_REDEMPTION_CODES_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingRedemptionCodes', false)
                .setIn(['data', 'redemptionCodes'], payload)
                .set('couponsError', null);
        case SEARCH_REDEMPTION_CODES_CONSTANTS.FAILURE:
            return state
                .set('isFetchingRedemptionCodes', false)
                .set('couponsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.severity
                });
        case RETRIEVE_REDEMPTION_CODE_DETAILS_CONSTANTS.SUCCESS:
            payload.isFetching = false;
            return state.set('couponsError', null);
        case RETRIEVE_REDEMPTION_CODE_DETAILS_CONSTANTS.FAILURE:
            return state
                .set('couponsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.severity
                });
        case SET_SELECTED_COUPON:
            return state
                .setIn(['data', 'selectedCoupon'], payload);
        case RESEND_COUPON_REDEMPTION_CODE.SUCCESS:
            return state.set('couponsError', null);
        case RESEND_COUPON_REDEMPTION_CODE.FAILURE:
            return state
                .set('couponsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.severity
                });
        case EMPTY_COUPONS_LIST:
            return INITIAL_STATE;
        default:
            return state;
    }
}
