import CustomerCareKeys from '../../locales/keys';

export const ALL_STEPS_KEYS = [
    CustomerCareKeys.WIZARD.STEPS.OFFERS,
    CustomerCareKeys.WIZARD.STEPS.COMPARE_OFFERS,
    CustomerCareKeys.WIZARD.STEPS.DECISIONS,
    CustomerCareKeys.WIZARD.STEPS.ATTRIBUTES,
    CustomerCareKeys.WIZARD.STEPS.CHECKOUT,
];

export const TransitionActions = {
    NONE: 0,
    ADD: 1,
    MODIFY: 2,
    TRANSITION: 3,
    REMOVE: 4,
    TRANSITION_REQUEST: 5
};

export const TransitionStates = {
    ADDED: 1,
    REMOVED: 2,
    CHANGED: 3,
    NO_CHANGE: 4
};

export const TRANSITION_OFFER = 'TRANSITION_OFFER';

export const RemoveOfferReasonCodes = {
    DefaultedToRemove: 0
};

export const STATE_OR_NAME = 'index.customercare.customer.orders.transition';
