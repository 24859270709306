import * as R from 'ramda';

const typeOnlyAction = R.partial((type) => {
    return {
        type
    };
});

const actionWithPayload = R.partial((type, payload) => {
    return {
        type,
        payload
    };
});

export default (prefix) => {
    const CANCEL_WIZARD = `${prefix}_CANCEL_WIZARD`;
    const GO_TO_NEXT_STEP = `${prefix}_GO_TO_NEXT_STEP`;
    const GO_TO_PREVIOUS_STEP = `${prefix}_GO_TO_PREVIOUS_STEP`;
    const GO_TO_STEP = `${prefix}_GO_TO_STEP`;
    const SET_CURRENT_STEP_IS_INVALID = `${prefix}_SET_CURRENT_STEP_IS_INVALID`;
    const SET_SELECTED_WIZARD_TAB = `${prefix}_SET_SELECTED_WIZARD_TAB`;

    const cancelWizard = typeOnlyAction([CANCEL_WIZARD]);
    const goToNextStep = typeOnlyAction([GO_TO_NEXT_STEP]);
    const goToPreviousStep = typeOnlyAction([GO_TO_PREVIOUS_STEP]);
    const goToStep = actionWithPayload([GO_TO_STEP]);
    const setCurrentStepIsInvalid = actionWithPayload([SET_CURRENT_STEP_IS_INVALID]);
    const setSelectedTab = actionWithPayload([SET_SELECTED_WIZARD_TAB]);

    return {
        GO_TO_NEXT_STEP,
        GO_TO_PREVIOUS_STEP,
        GO_TO_STEP,
        CANCEL_WIZARD,
        SET_CURRENT_STEP_IS_INVALID,
        SET_SELECTED_WIZARD_TAB,
        goToNextStep,
        goToPreviousStep,
        goToStep,
        cancelWizard,
        setCurrentStepIsInvalid,
        setSelectedTab
    };
};
