import Immutable from 'seamless-immutable';
import {
    path,
    pathOr
} from 'ramda';

import {
    CREATE_HOUSEHOLD_CONSTANTS,
    CREATE_HOUSEHOLD_MEMBER_CONSTANTS,
    REMOVE_HOUSEHOLD,
    REMOVE_HOUSEHOLD_MEMBERS_CONSTANTS,
    RETRIEVE_HOUSEHOLD_CONSTANTS,
    SET_EDIT_HOUSEHOLD_DATA,
    UPDATE_HOUSEHOLD_CONSTANTS,
    UPDATE_HOUSEHOLD_MEMBERS,
    UPDATE_HOUSEHOLD_SERVICES_ASSOCIATIONS_CONSTANTS
} from './actions/customer.household.actions';

export const INITIAL_STATE = Immutable({
    data: null,
    editHouseholdData: {},
    householdError: null,
    isCreatingHousehold: false,
    isCreatingHouseholdMember: false,
    isRemovingHouseholdMember: false,
    isFetchingHousehold: false,
    isRemovingHousehold: false,
    isUpdatingHousehold: false,
    isUpdatingHouseholdServicesAssociations: false,
    selectedHouseholdMemberId: null
});

function getError(payload) {
    return {
        code: payload.Code,
        message: payload.translatedMessage,
        severity: payload.Severity
    };
}

const normalizePrivilege = (privilege) => {
    return Object.assign(privilege, {
        DayAndTimePrivileges: (privilege.DayAndTimePrivileges || []).map((item) => {
            return Object.assign({
                StartingHour: 0,
                StartingMinute: 0,
                DayOfWeek: 0
            }, item);
        })
    });
};

const normalizeMemberPrivileges = (household) => {
    if (household && household.Members) {
        household.Members.forEach((member) => {
            member.Privileges = normalizePrivilege(member.Privileges);
        });
    }
    return household;
};

export default function householdReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case CREATE_HOUSEHOLD_CONSTANTS.BEGIN:
            return state
                .set('isCreatingHousehold', true)
                .set('householdError', null);
        case CREATE_HOUSEHOLD_CONSTANTS.SUCCESS:
            return state
                .set('isCreatingHousehold', false)
                .set('householdError', null)
                .set('editHouseholdData', {});
        case CREATE_HOUSEHOLD_CONSTANTS.FAILURE:
            return state
                .set('isCreatingHousehold', false)
                .set('householdError', getError(payload));
        case CREATE_HOUSEHOLD_MEMBER_CONSTANTS.BEGIN:
            return state
                .set('isCreatingHouseholdMember', true)
                .set('householdError', null);
        case CREATE_HOUSEHOLD_MEMBER_CONSTANTS.SUCCESS:
            return state
                .set('isCreatingHouseholdMember', false)
                .set('householdError', null);
        case CREATE_HOUSEHOLD_MEMBER_CONSTANTS.FAILURE:
            return state
                .set('isCreatingHouseholdMember', false)
                .set('householdError', getError(payload));
        case REMOVE_HOUSEHOLD.BEGIN:
            return state.set('isRemovingHousehold', true)
                .set('householdError', null);
        case REMOVE_HOUSEHOLD.SUCCESS:
            return state.set('isRemovingHousehold', false)
                .set('householdError', null);
        case REMOVE_HOUSEHOLD.FAILURE:
            return state.set('isRemovingHousehold', false)
                .set('householdError', getError(payload));
        case REMOVE_HOUSEHOLD_MEMBERS_CONSTANTS.BEGIN:
            return state
                .set('isRemovingHouseholdMember', true)
                .set('householdError', null);
        case REMOVE_HOUSEHOLD_MEMBERS_CONSTANTS.SUCCESS:
            return state
                .set('isRemovingHouseholdMember', false)
                .set('householdError', null)
                .setIn(['data', 'Members'], state.data.Members.filter(member => {
                    return !payload.RemovedHouseholdMembers.find(deleted => {
                        return deleted.SubscriberId === member.SubscriberId;
                    });
                }));
        case REMOVE_HOUSEHOLD_MEMBERS_CONSTANTS.FAILURE:
            return state
                .set('isRemovingHouseholdMember', false)
                .set('householdError', getError(payload));
        case RETRIEVE_HOUSEHOLD_CONSTANTS.BEGIN:
            return state.set('isFetchingHousehold', true);
        case RETRIEVE_HOUSEHOLD_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingHousehold', false)
                .set('data', payload.Households ? normalizeMemberPrivileges(payload.Households[0]) : null) //API Sends an array but we only support 1
                .set('householdError', null);
        case RETRIEVE_HOUSEHOLD_CONSTANTS.FAILURE:
            return state
                .set('isFetchingHousehold', false)
                .set('householdError', getError(payload));
        case SET_EDIT_HOUSEHOLD_DATA:
            return state
                .set('editHouseholdData', payload);
        case UPDATE_HOUSEHOLD_CONSTANTS.BEGIN:
            return state
                .set('isUpdatingHousehold', true)
                .set('householdError', null);
        case UPDATE_HOUSEHOLD_CONSTANTS.SUCCESS:
            return state
                .set('isUpdatingHousehold', false)
                .set('householdError', null)
                .set('editHouseholdData', {});
        case UPDATE_HOUSEHOLD_CONSTANTS.FAILURE:
            return state
                .set('isUpdatingHousehold', false)
                .set('householdError', getError(payload));
        case UPDATE_HOUSEHOLD_MEMBERS.BEGIN:
            return state.set('isUpdatingHousehold', true);
        case UPDATE_HOUSEHOLD_MEMBERS.SUCCESS:
            return state.set('isUpdatingHousehold', false)
                .set('householdError', null)
                .setIn(['data', 'Members'], getUpdatedMembersList(state, payload));
        case UPDATE_HOUSEHOLD_MEMBERS.FAILURE:
            return state.set('isUpdatingHousehold', false)
                .set('householdError', getError(payload));
        case UPDATE_HOUSEHOLD_SERVICES_ASSOCIATIONS_CONSTANTS.BEGIN:
            return state
                .set('isUpdatingHouseholdServicesAssociations', true)
                .set('householdError', null);
        case UPDATE_HOUSEHOLD_SERVICES_ASSOCIATIONS_CONSTANTS.SUCCESS:
            return state
                .set('isUpdatingHouseholdServicesAssociations', false)
                .set('householdError', null)
                .setIn(['data', 'MemberToServiceAssociations'], pathOr(undefined, ['HouseholdMemberToService', 'MemberToServiceAssociations'], payload));
        case UPDATE_HOUSEHOLD_SERVICES_ASSOCIATIONS_CONSTANTS.FAILURE:
            return state
                .set('isUpdatingHouseholdServicesAssociations', false)
                .set('householdError', getError(payload));
        default:
            return state;
    }
}

const getUpdatedMembersList = (state, payload) => {
    return Immutable.flatMap(state.data.Members, (member) => {
        if (member.SubscriberId === path(['UpdatedHouseholdMembers', '0', 'SubscriberId'], payload)) {
            return member.set('Privileges', normalizePrivilege(path(['UpdatedHouseholdMembers', '0', 'Privileges'], payload)));
        } else {
            return member;
        }
    });
};
