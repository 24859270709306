import Immutable from 'seamless-immutable';
import {
    equals,
    isNil,
    isEmpty,
    path,
    pathOr,
    propOr
} from 'ramda';
import {
    createSelector
} from 'reselect';
import {
    ConvergentBillerOrderDetailsSelector
} from './customer.convergent.biller.selectors';
import {
    ORDER_TYPES
} from '../../components/customer/orderHistory/orderDetails/order.details.constants';

const EMPTY_OBJECT = Immutable({});

export const CurrentDetailsGiftOrderInfoSelector = createSelector(
    [ConvergentBillerOrderDetailsSelector],
    (currentDetails) => {
        if (isNil(currentDetails) || isEmpty(currentDetails)) {
            return EMPTY_OBJECT;
        }

        return {
            orderId: path(['Id'], currentDetails),
            giftMessage: path(['GiftRedemption', 'SenderMessage'], currentDetails),
            recipientEmail: path(['GiftRedemption', 'RecipientEmail'], currentDetails),
            recipientName: path(['GiftRedemption', 'RecipientName'], currentDetails),
            isGiftOrder: equals(path(['Type'], currentDetails), ORDER_TYPES.GIFT),
            senderName: path(['GiftRedemption', 'SenderName'], currentDetails),
            senderEmail: path(['GiftRedemption', 'SenderEmail'], currentDetails),
            isRevoked: !isNil(path(['GiftRedemption', 'Revoked'], currentDetails)),
            revokeReasonName: path(['GiftRedemption', 'RevokeReasonName'], currentDetails),
            isNotified: ((pathOr([], ['GiftRedemption', 'Notifications'], currentDetails)).length > 0),
            statusName: path(['GiftRedemption', 'StatusName'], currentDetails)
        };
    }
);

const ResendGiftNotificationSelector = (store) => {
    return store.customercare.recoverableUiState.resendGiftNotification;
};

export const ResendGiftNotificationInfoSelector = createSelector(
    [ResendGiftNotificationSelector],
    (resendGiftNotification) => {
        return propOr(EMPTY_OBJECT, 'giftOrderInfo', resendGiftNotification);
    }
);

export const ResendGiftNotificationErrorSelector = createSelector(
    [ResendGiftNotificationSelector],
    (resendGiftNotification) => {
        return propOr(null, 'resendGiftNotificationError', resendGiftNotification);
    }
);

export const IsResendingGiftNotificationSelector = createSelector(
    [ResendGiftNotificationSelector],
    (resendGiftNotification) => {
        return propOr(false, 'isResendingGiftNotification', resendGiftNotification);
    }
);
