export function populateCustomerSearchResultsMap(results) {
    const resultsMap = {};

    if (results) {
        results.forEach((customer, index) => {
            resultsMap[customer.Id] = {
                ...customer,
                ...{
                    sortIndex: index
                }
            };
        });
    }

    return resultsMap;
}

export const transformSearchCustomerSuggestions = (response) => {
    const suggestions = response.Suggestions.map(s => {
        const item = {
            label: s.Value,
            iconGlyph: ''
        };
        s.Attributes.forEach(a => {
            item[a.Name] = a.Value;
        });
        item.iconGlyph = item.IsLogin ? 'user' : 'address_postal';
        return item;
    });
    return suggestions;
};
