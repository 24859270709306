import pathOr from 'ramda/src/pathOr';

import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {
    EMPTY_ARRAY,
    EMPTY_OBJECT
} from 'invision-core/src/constants/common.constants';
import {NON_BULK_SERVICE_STATUSES} from 'invision-core/src/constants/status.constants';
import i18n from 'invision-core/src/components/i18n/i18n';
import {MetadataCodeTypeDictionarySelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {PageSizePreferenceSelector} from 'invision-core/src/components/session/session.selectors';
import {
    OfferingMetadataByIdSelector,
    OfferingMetadataPricingPlansSelector
} from 'invision-core/src/components/metadata/offerings/offerings.selectors';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    getFormattedServiceAttributeValue,
    getSuspensionTypeByCode
} from './services.list.selectors.helper';
import {DiscountsMetadataMapSelector} from 'invision-core/src/components/metadata/discounts/discounts.selectors';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import {
    CustomerStoreSelector,
    SelectedCustomerSelector
} from 'invision-core/src/components/customer/customer.selectors';
import {getFilterService} from 'invision-core/src/components/injectables/injector.helper';
import {getDiscountAndBriDetails} from './selected.service.details.selectors.helper';
import {
    CurrentCustomerSubscriberCurrencyNameSelector,
    IsPIIDataAccessEnabledSelector
} from '../../reducers/selectors/customer.selectors';
import {IsCurrentAccountPostpaidSelector} from '../../reducers/selectors/customer.convergent.biller.selectors';
import {
    filterBulkOfferOptions,
    getOptionsByActiveBriType,
    isPaymentInstrumentChangeable
} from '../../components/customer/dashboard/dbssDashboard/offering/offering.component.helper';
import {BILLER_RULE_INSTANCE_TYPE} from '../../customercare.constants';
import {
    ChangeOfferEligibilityRulesSelector,
    IsCoolingPeriodEnabledSelector
} from '../../reducers/selectors/customer.offerings.selectors';
import {getMoreMenuItems} from '../../reducers/selectors/dashboard.selectors.helper';
import {
    HasSuspendResumePermissionSelector,
    IsServiceLifeCycleStatusUpdatableSelector
} from '../../reducers/selectors/services.selectors';
import CustomerLocaleKeys from '../../locales/keys';
import {OFFERING_OPTION_STATUSES} from '../../components/shared/constants/offering.option.status.constants';

const SearchSubscriberOfferingsSelector = (store) => {
    return store.customercare.recoverableUiState.searchSubscriberOfferings;
};

export const SelectedSubscriberOfferingsSelector = createImmutableSelector(
    [
        SelectedCustomerSelector
    ],
    (selectedCustomer) => {

        return selectedCustomer.offerings;
    }
);

export const SearchSubscriberOfferingsDataSelector = createImmutableSelector(
    [
        SearchSubscriberOfferingsSelector
    ],
    (offerings) => {
        if (!offerings) {
            return EMPTY_ARRAY;
        }

        return offerings.data;
    }
);

const getFormatedService = (offer, serviceAttributes, regularExpressions) => {
    const services = [];
    (offer.ServiceIdentifiers || EMPTY_ARRAY).forEach((service) => {
        services.push(getFormattedServiceAttributeValue(
            service.ServiceAttributeId,
            service.Value,
            serviceAttributes,
            regularExpressions));
    });

    return services;
};

export const SearchSubscriberOfferingsWithFormattedServiceIdentifierSelector = createImmutableSelector(
    [
        SearchSubscriberOfferingsDataSelector,
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression)
    ],
    (offerings, serviceAttributes, regularExpressions) => {
        if (!offerings) {
            return EMPTY_ARRAY;
        }
        const offersWithFormatedServices = [];
        offerings.forEach((offer) => {
            offersWithFormatedServices.push({
                offerDetails: offer,
                formatedServices: getFormatedService(offer, serviceAttributes, regularExpressions)
            });
        });

        return offersWithFormatedServices;
    }
);

export const SelectedSubscriberOfferingInstanceIdSelector = createImmutableSelector(
    [
        SelectedSubscriberOfferingsSelector
    ],
    (offerings) => {
        return offerings.selectedSubscriberOfferingInstanceId || '';
    }
);

export const SelectedSubscriberOfferingWithFormattedServiceSelector = createImmutableSelector(
    [
        SelectedSubscriberOfferingInstanceIdSelector,
        SearchSubscriberOfferingsWithFormattedServiceIdentifierSelector
    ],
    (selectedOfferingInstanceId, offerings) => {
        if (selectedOfferingInstanceId) {
            return offerings.find((offer) => {
                return offer.offerDetails.OfferingInstanceId === selectedOfferingInstanceId;
            });
        } else {
            return offerings[0];
        }
    }
);

export const SelectedSubscriberOfferingSelector = createImmutableSelector(
    [
        SelectedSubscriberOfferingsSelector
    ],
    (offerings) => {
        return offerings.selectedOffering && offerings.selectedOffering.SubscriberOffer || EMPTY_OBJECT;
    }
);

export const SelectedSubscriberOfferServiceCountSelector = createImmutableSelector(
    [
        SelectedSubscriberOfferingsSelector
    ],
    (offerings) => {
        return offerings.selectedOffering ? offerings.selectedOffering.ServiceCount : '';
    }
);

export const SelectedSubscriberServicesSelector = createImmutableSelector(
    [
        SelectedSubscriberOfferingsSelector
    ],
    (offerings) => {
        return offerings.selectedOffering ? offerings.selectedOffering.Services : EMPTY_ARRAY;
    }
);

const getMoreMenuOptions = (service, hasSuspendResumeAccess, hasUpdateServiceLifecycleStatusAccess, isPIIDataAccessEnabled) => {
    const moreMenuItems = {};
    const isServiceSuspended = service.Status === NON_BULK_SERVICE_STATUSES.SUSPENDED;
    const isPendingSuspension = service.Status === NON_BULK_SERVICE_STATUSES.PENDING_SUSPENSION;

    if (isPIIDataAccessEnabled) {
        moreMenuItems[CustomerLocaleKeys.MANAGE_ADD_ONS] = {
            id: CustomerLocaleKeys.MANAGE_ADD_ONS,
            title: i18n.translate(CustomerLocaleKeys.MANAGE_ADD_ONS),
            isLocked: isServiceSuspended,
            disabled: isServiceSuspended
        };
    }

    moreMenuItems[CustomerLocaleKeys.CUSTOMER_DASHBOARD.FRIENDLY_NAME.MODAL_TITLE] = {
        id: CustomerLocaleKeys.CUSTOMER_DASHBOARD.FRIENDLY_NAME.MODAL_TITLE,
        title: i18n.translate(CustomerLocaleKeys.CUSTOMER_DASHBOARD.FRIENDLY_NAME.MODAL_TITLE)
    };

    if (hasSuspendResumeAccess) {
        if (service.Status === NON_BULK_SERVICE_STATUSES.ACTIVE) {
            moreMenuItems[CustomerLocaleKeys.SUSPEND_SERVICE] = {
                id: CustomerLocaleKeys.SUSPEND_SERVICE,
                title: i18n.translate(CustomerLocaleKeys.SUSPEND_SERVICE)
            };
        }

        if (isServiceSuspended || isPendingSuspension) {
            moreMenuItems[CustomerLocaleKeys.RESUME_SERVICE] = {
                id: CustomerLocaleKeys.RESUME_SERVICE,
                title: i18n.translate(CustomerLocaleKeys.RESUME_SERVICE)
            };
        }
    }

    if (hasUpdateServiceLifecycleStatusAccess) {
        if (service.Status === NON_BULK_SERVICE_STATUSES.ACTIVE) {
            moreMenuItems[CustomerLocaleKeys.UPDATE_SERVICE_LIFECYCLE_STATUS] = {
                id: CustomerLocaleKeys.UPDATE_SERVICE_LIFECYCLE_STATUS,
                title: i18n.translate(CustomerLocaleKeys.UPDATE_SERVICE_LIFECYCLE_STATUS)
            };
        }
    }

    return moreMenuItems;
};

export const SubscriberServicesWithFormatedServicesSelector = createImmutableSelector(
    [
        SelectedSubscriberServicesSelector,
        SelectedSubscriberOfferingSelector,
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression),
        HasSuspendResumePermissionSelector,
        IsServiceLifeCycleStatusUpdatableSelector,
        IsPIIDataAccessEnabledSelector,
    ],
    (services, offering, serviceAttributes, regularExpressions, hasSuspendResumeAccess, isServiceLifeCycleStatusUpdatable, isPIIDataAccessEnabled) => {
        return (services || []).map((service) => {
            let serviceAttribute = null;
            const offeringOption = offering.Options.find((item) => {
                if (item.ServiceIdentifierValue === service.ServiceIdentifier.Value) {
                    serviceAttribute = item.ServiceAttributeValues.find(attribute => {
                        return attribute.ServiceId && attribute.Value === service.ServiceIdentifier.Value;
                    });
                    return serviceAttribute;
                }
            });
            return offeringOption && {
                serviceDetails: Object.assign({}, service, {
                    serviceId: serviceAttribute && serviceAttribute.ServiceId,
                    suspendedDate: getFilterService()('localShortDate')(offeringOption.SuspendedDate),
                    suspendType: getSuspensionTypeByCode(offeringOption.SuspendTypeCode),
                    serviceFeatures: (service.ServiceFeatures || EMPTY_ARRAY).filter((serviceFeature) => {
                        return serviceFeature.Status !== OFFERING_OPTION_STATUSES.REMOVED;
                    }),
                    serviceIdentifierFormatted: getFormattedServiceAttributeValue(
                        service.ServiceIdentifier.ServiceAttributeId,
                        service.ServiceIdentifier.Value,
                        serviceAttributes,
                        regularExpressions
                    )
                }),
                moreMenuOptions: getMoreMenuOptions(
                    service,
                    hasSuspendResumeAccess,
                    isServiceLifeCycleStatusUpdatable,
                    isPIIDataAccessEnabled
                )
            };
        }).filter((serviceDetails) => {
            return serviceDetails;
        });
    }
);

export const IsFetchingOfferDetailsSelector = createImmutableSelector(
    [CustomerStoreSelector],
    (customer) => {
        return customer.isFetchingOfferingDetail;
    }
);

export const SelectedOfferingAndOptionsSelector = createImmutableSelector(
    [
        SelectedSubscriberOfferingSelector,
        SearchSubscriberOfferingsDataSelector,
        OfferingMetadataByIdSelector,
        CurrentCustomerSubscriberCurrencyNameSelector,
        DiscountsMetadataMapSelector,
        IsDbss,
        IsCurrentAccountPostpaidSelector,
        MetadataCodeTypeDictionarySelector(CODES.TransitionConfiguration),
        ChangeOfferEligibilityRulesSelector,
        IsCoolingPeriodEnabledSelector,
        OfferingMetadataPricingPlansSelector,
        IsPIIDataAccessEnabledSelector
    ],
    (subscriberOffering,
        searchOfferings,
        offeringMetadataById,
        currencyCode,
        discountsMetadataMap,
        isDbss,
        isCurrentAccountPostpaid,
        transitionConfiguration,
        changeOfferEligibilityRules,
        isCoolingPeriodEnabledForBU,
        pricingPlanThumbnails,
        isPIIDataAccessEnabled) => {
        const metadataOffer = offeringMetadataById ? offeringMetadataById[subscriberOffering.OfferingId] : EMPTY_OBJECT;
        const allowTransitioning = pathOr(false, [Object.keys(transitionConfiguration)[0], 'AdditionalProperties', 'AllowTransitioning'], transitionConfiguration);
        const briOptions = getOptionsByActiveBriType(subscriberOffering, PRODUCT_CLASSIFICATIONS.PRODUCT);
        const hasSubscriptions = briOptions[BILLER_RULE_INSTANCE_TYPE.SUBSCRIPTION].length;
        const bulkOffer = filterBulkOfferOptions(subscriberOffering);
        const canChangePaymentInstrument = isPaymentInstrumentChangeable(subscriberOffering);
        const isInGracePeriod = subscriberOffering.Options ? subscriberOffering.Options.some((option) => {
            return option.OffCycleDetail &&
                option.OffCycleDetail.GracePeriodExpiryDate &&
                option.OffCycleDetail.CurrentRenewalFailedAttempts;
        }) : false;
        const filteredOffering = (searchOfferings || []).find(offering => {
            return offering.OfferingInstanceId === subscriberOffering.OfferingInstanceId;
        });
        const offer = Object.keys(subscriberOffering).length ? Object.assign({}, subscriberOffering, {
            offerThumbNailUrl: metadataOffer ? metadataOffer.ThumbnailUrl : null,
            moreOptionsMenuItems: Object.keys(subscriberOffering).length ? getMoreMenuItems(
                hasSubscriptions,
                allowTransitioning,
                subscriberOffering,
                bulkOffer,
                changeOfferEligibilityRules,
                canChangePaymentInstrument,
                isInGracePeriod,
                false,
                pricingPlanThumbnails,
                false,
                true,
                isPIIDataAccessEnabled
            ): null,
            Options: subscriberOffering.Options.map(option => {
                return Object.assign({}, option, {
                    discountAndBriDetails: getDiscountAndBriDetails(option, currencyCode, discountsMetadataMap)
                });
            }),
            IsCoolingOff: (filteredOffering && filteredOffering.IsCoolingOff) ? filteredOffering.IsCoolingOff : false
        }) : EMPTY_OBJECT;
        return offer;
    }
);

export const OfferAdditionalPropertiesSelector = createImmutableSelector(
    [SelectedSubscriberOfferingSelector],
    (selectedOffering) => {
        return selectedOffering?.OrderedOfferingAdditionalProperties || [];
    }
);

export const IsFetchingOfferingsSelector = createImmutableSelector(
    [SearchSubscriberOfferingsSelector],
    (offerings) => {
        return offerings.isLoading;
    }
);

export const SubsriberOfferingsPaginationDataSelector = createImmutableSelector(
    [SearchSubscriberOfferingsSelector, PageSizePreferenceSelector],
    (offerings, defaultPageSize) => {
        if (!offerings.paginationData.pageSize) {
            return Object.assign({}, offerings.paginationData, {
                pageSize: defaultPageSize
            });
        }
        return offerings.paginationData;
    }
);

export const SubsriberOfferingsFilterDataSelector = createImmutableSelector(
    [SearchSubscriberOfferingsSelector],
    (offerings) => {
        return offerings.filterData;
    }
);

export const SelectedOfferingInventoryItemsSelector = createImmutableSelector(
    [SelectedSubscriberOfferingsSelector],
    (offerings) => {
        return pathOr([], ['selectedOffering', 'InventoryItems'], offerings).map((inventoryItem) => {
            return Object.assign({}, inventoryItem, {
                serviceFeatures: (inventoryItem.ServiceFeatures || EMPTY_ARRAY).filter((serviceFeature) => {
                    return serviceFeature.Status !== OFFERING_OPTION_STATUSES.REMOVED;
                })
            });
        });
    }
);

export const SelectedOfferingInventoryItemsCountSelector = createImmutableSelector(
    [SelectedSubscriberOfferingsSelector],
    (offerings) => {
        return pathOr(null, ['selectedOffering', 'InventoryItemCount'], offerings);
    }
);
