import pathOr from 'ramda/src/pathOr';
import {
    BILLER_RULE_INSTANCE_TYPE,
    DEFAULT_SERVICE_IDENTIFIER_CODE_TABLE_VALUE,
    FINANCE_SERVICE_FEATURES_IDENTIFIER_VALUE
} from '../../customercare.constants';
import {getFormattedServiceAttributeValue} from '../selectors/services.list.selectors.helper';

export const getFinanceItems = (shoppingCart) => {
    const financeItems = [];

    pathOr([], ['Items'], shoppingCart).forEach((item, index) => {
        // Selecting first in BillerRuleTotals as a pricing plan containing a finance BRI can only have 1 BRI
        const isFinance = pathOr(null, ['Details', 'PricingPlan', 'PricingPlanBillerRuleInstances',
            'FinanceBillerRuleInstances', '0', 'Type'], item) === BILLER_RULE_INSTANCE_TYPE.FINANCE;
        if (isFinance) {
            const itemWithIndex = Object.assign({}, item, {
                itemIndex: index
            });
            financeItems.push(itemWithIndex);
        }
    });

    return financeItems;
};

export const getUniqueNonGuidServiceIdentifiers = (serviceAttributes, serviceAttributeCodeTable, regularExpressionCodeTable) => {
    const potentiallyDuplicatedNonGuidServiceIdentifiers = serviceAttributes.filter((serviceAttribute) => {
        return serviceAttribute && serviceAttribute.IsServiceIdentifier &&
            serviceAttribute.ServiceAttributeId !== DEFAULT_SERVICE_IDENTIFIER_CODE_TABLE_VALUE &&
            serviceAttribute.ServiceAttributeId !== FINANCE_SERVICE_FEATURES_IDENTIFIER_VALUE;
    }).map((serviceAttribute) => {
        return getFormattedServiceAttributeValue(
            serviceAttribute.ServiceAttributeId,
            serviceAttribute.Value,
            serviceAttributeCodeTable,
            regularExpressionCodeTable
        );
    });

    return Array.from(new Set(potentiallyDuplicatedNonGuidServiceIdentifiers));
};
