import Immutable from 'seamless-immutable';
import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';
import NavigatorActions from 'invision-core/src/components/navigator/navigator.actions';
import {
    CANCEL_ORDER,
    CHANGE_OFFER_STEP_SET_COMPLETED_STEPS,
    GO_TO_NEXT_STEP,
    GO_TO_PREVIOUS_STEP,
    GO_TO_STEP,
    RESTORE_OPTION_DEFAULT_PRICING,
    RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_TRANSITION,
    RETRIEVE_ADDRESSES,
    RETRIEVE_TRANSITION_CONTEXT,
    SEND_PORT_IN_OR_ITEM_RESERVATION,
    SET_ATTRIBUTE_SEARCHING_INVENTORY,
    SET_CURRENT_STEP_IS_INVALID,
    SET_EDIT_ATTRIBUTE_GROUPS,
    SET_EDIT_OPTION,
    SET_EDIT_CART_ADDITIONAL_PROPERTY,
    SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE,
    SET_EDIT_ORDER_ADDITIONAL_PROPERTY,
    SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE,
    SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS,
    SET_IS_RESUMING_STORE_ORDER,
    SET_PAYMENT_INFO,
    SET_SELECTED_FACETS_EDIT_COPY,
    SET_SELECTED_OFFER,
    SET_SELECTED_OFFER_EDIT_COPY,
    SET_SELECTED_OFFERING_CHARGE_TYPES_EDIT_COPY,
    SET_SELECTED_WIZARD_TAB,
    SET_SERVICE_TAX_LOCATION_DECISION_CHANGE_OFFER,
    SET_TRANSITION_OFFERINGS_METADATA,
    TRANSITION_RETRIEVE_PREVIOUS_ATTRIBUTES,
    TRANSITION_SUBMIT_COS_ORDER,
    UPDATE_EDIT_BRI_PRICE,
    UPDATE_INVENTORY_SELECTION,
    UPDATE_OFFER_ATTRIBUTE,
    UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL,
    UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE,
    UPDATE_SELECTED_SERVICE_FEATURES,
    UPDATE_PAYMENT_INFO,
    UPDATE_PORT_IN_REQUEST
} from './actions/transition.offer.wizard.actions';
import {ALL_STEPS_KEYS} from './constants/transition.offer.wizard.constants';
import createWizardReducers from './helpers/wizard.reducer.helper';
import createOfferOrderingReducers from './helpers/offer.ordering.wizard.reducer.helper';
import {INITIAL_STATE as CREATE_CUSTOMER_INITIAL_STATE} from './create.customer.reducer';
import {
    CALCULATE_COS_ORDER_QUOTE,
    RETRIEVE_ATTRIBUTES,
    RETRIEVE_ORDER_QUOTE,
    SEARCH_INVENTORY,
    SET_DELIVERY_DECISION
} from './actions/offering.order.actions';
import {EMPTY_ARRAY} from './constants/common.constants';
import {
    mapAdditionalProperty,
    setEditAp
} from './helpers/customer.helper';

const wizardReducers = createWizardReducers(ALL_STEPS_KEYS);
const offerOrderingReducers = createOfferOrderingReducers();

export const DEFAULT_CART = {
    customer: null,
    customerInfo: CREATE_CUSTOMER_INITIAL_STATE,
    editAttributeGroups: null,
    editOptions: null,
    editPhysicalAttributeGroups: null,
    isResumingStoreOrder: false,
    isSavingOrder: false,
    paymentInfo: null,
    selectedEditOption: null,
    selectedFacetIds: [],
    selectedOfferId: null,
    selectedOfferingChargeTypeIds: [],
    serviceAddress: null
};

export const INITIAL_STATE = wizardReducers.initialState.merge(Immutable({
    cart: DEFAULT_CART,
    cartAdditionalProperties: EMPTY_ARRAY,
    data: {
        quoteCalculated: false,
        subTotal: 0,
        taxAmount: 0,
        totalAmount: 0,
    },
    editCart: DEFAULT_CART,
    isCalculatingQuote: false,
    isResumingOrder: false,
    isRetrievingTransitionContext: false,
    isSubmittingCOSOrder: false,
    lastAttemptError: null,
    offeringsMetadata: EMPTY_ARRAY,
    orderAdditionalProperties: EMPTY_ARRAY,
    quote: null,
    serviceAddress: null,
    submittedCOS: {},
    transitionContext: {},
    transitioningEnabled: false,
    transitioningContextError: false
}));

export default function wizardReducer(state = INITIAL_STATE, {payload, type}) {
    switch (type) {
        case CALCULATE_COS_ORDER_QUOTE.BEGIN:
            return state.set('isCalculatingQuote', true);
        case CALCULATE_COS_ORDER_QUOTE.FAILURE:
            return state.set('isCalculatingQuote', false);
        case RETRIEVE_ORDER_QUOTE.SUCCESS:
        case CALCULATE_COS_ORDER_QUOTE.SUCCESS:
            return state
                .set('isCalculatingQuote', false)
                .set('financeCreditLimitValidation', payload.FinanceCreditLimitValidation)
                .set('quote', payload.Quote)
                .set('shippingAddress', payload.ShippingAddress);
        case TRANSITION_RETRIEVE_PREVIOUS_ATTRIBUTES.SUCCESS: {
            const attributeGroups = offerOrderingReducers.setEditAttributeGroups(
                payload.Context.ValueDecisions,
                pathOr(null, ['editCart', 'editAttributeGroups'], state)
            );
            const transformedAttributeGroups = offerOrderingReducers.transformAttributeGroups(attributeGroups);
            return state
                .setIn(['editCart', 'editAttributeGroups'], transformedAttributeGroups)
                .setIn(['editCart', 'deliveryDecisions'], payload.Context.DeliveryDecisions)
                .setIn(['editCart', 'serviceTaxDecisions'], payload.Context.ServiceTaxCustomizationDecisions);
        }
        case SET_DELIVERY_DECISION:
            return state
                .setIn(['editCart', 'deliveryDecisions'], offerOrderingReducers.updateDeliveryDecision(state, payload));
        case RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_TRANSITION.SUCCESS: {
            const attributeGroups = offerOrderingReducers.setEditAttributeGroups(
                payload.Context.ValueDecisions,
                pathOr(null, ['editCart', 'editAttributeGroups'], state)
            );
            const transformedAttributeGroups = offerOrderingReducers.transformAttributeGroups(attributeGroups);
            return state
                .setIn(['editCart', 'editAttributeGroups'], transformedAttributeGroups)
                .setIn(['cartAdditionalProperties'], pathOr(EMPTY_ARRAY, ['ShoppingCart', 'AdditionalProperties'], payload));
        }
        case GO_TO_NEXT_STEP:
            return wizardReducers.goToNextStep(state, payload)
                .setIn(['editCart', 'selectedFacetIds'], DEFAULT_CART.selectedFacetIds)
                .setIn(['editCart', 'selectedOfferingChargeTypeIds'], DEFAULT_CART.selectedOfferingChargeTypeIds);
        case GO_TO_PREVIOUS_STEP:
            return wizardReducers.goToPreviousStep(state, payload)
                .setIn(['editCart', 'selectedFacetIds'], DEFAULT_CART.selectedFacetIds)
                .setIn(['editCart', 'selectedOfferingChargeTypeIds'], DEFAULT_CART.selectedOfferingChargeTypeIds);
        case GO_TO_STEP:
            return wizardReducers.goToStep(state, payload)
                .setIn(['editCart', 'selectedFacetIds'], DEFAULT_CART.selectedFacetIds)
                .setIn(['editCart', 'selectedOfferingChargeTypeIds'], DEFAULT_CART.selectedOfferingChargeTypeIds);
        case CHANGE_OFFER_STEP_SET_COMPLETED_STEPS:
            return state.set('completedSteps', {
                [ALL_STEPS_KEYS[0]]: ALL_STEPS_KEYS[0],
                [ALL_STEPS_KEYS[1]]: ALL_STEPS_KEYS[1]
            }).set('currentStepIndex', 2);
        case RETRIEVE_ADDRESSES.SUCCESS:
            return offerOrderingReducers.setServiceAddress(state, payload);
        case SEARCH_INVENTORY.SUCCESS:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.updateAttributeInventoryOptions(state.editCart.editAttributeGroups, payload.InventoryItems));
        case SEARCH_INVENTORY.FAILURE:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.clearAttributeInventorySearching(state.editCart.editAttributeGroups));
        case SEND_PORT_IN_OR_ITEM_RESERVATION:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.sendPortInOrItemReservation(state.editCart.editAttributeGroups, payload.attributeId, payload.sendPortInReservation));
        case SET_ATTRIBUTE_SEARCHING_INVENTORY:
            return state.setIn(['editCart', 'editAttributeGroups'],
                offerOrderingReducers.setAttributeInventorySearching(state.editCart.editAttributeGroups, payload));
        case SET_CURRENT_STEP_IS_INVALID:
            return wizardReducers.setCurrentStepIsInvalid(state, payload);
        case SET_EDIT_ATTRIBUTE_GROUPS:
            return state.setIn(['editCart', 'editAttributeGroups'],
                offerOrderingReducers.transformAttributeGroups(payload));
        case SET_PAYMENT_INFO:
            return offerOrderingReducers.setPaymentInfo(state, payload);
        case SET_SELECTED_FACETS_EDIT_COPY:
            return offerOrderingReducers.setSelectedFacetsEditCopy(state, payload);
        case SET_SELECTED_OFFER: {
            const nextState = offerOrderingReducers.clearStateFromOfferSelection(state, payload, DEFAULT_CART);
            return nextState
                .setIn(['cart', 'selectedOfferId'], payload)
                .set('editCart', DEFAULT_CART);
        }
        case SET_SELECTED_OFFER_EDIT_COPY: {
            const nextState = offerOrderingReducers.clearStateFromOfferSelection(state, payload, DEFAULT_CART);
            return nextState.setIn(['editCart', 'selectedOfferId'], payload);
        }
        case UPDATE_INVENTORY_SELECTION:
            return state.setIn(['editCart', 'editAttributeGroups'],
                offerOrderingReducers.updateInventorySelection(state.editCart.editAttributeGroups, payload));
        case SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS:
            return state.setIn(['editCart', 'editPhysicalAttributeGroups'],
                !payload.formPhysicalAttributeGroups ? payload.physicalAttributeGroups :
                    offerOrderingReducers.mergePhysicalAttributeGroups(
                        payload.physicalAttributeGroups,
                        payload.formPhysicalAttributeGroups
                    )
            );
        case SET_SELECTED_OFFERING_CHARGE_TYPES_EDIT_COPY:
            return offerOrderingReducers.setSelectedOfferingChargeTypesEditCopy(state, payload);
        case SET_SELECTED_WIZARD_TAB:
            return wizardReducers.setSelectedOrderTab(state, payload);
        case CANCEL_ORDER:
            return INITIAL_STATE;
        case SET_EDIT_OPTION:
            return offerOrderingReducers.setEditOption(state, payload);
        case RESTORE_OPTION_DEFAULT_PRICING:
            return offerOrderingReducers.restoreDefaultOptionPricing(state, payload);
        case UPDATE_EDIT_BRI_PRICE:
            return offerOrderingReducers.updateEditOptionPricing(state, payload);
        case UPDATE_OFFER_ATTRIBUTE:
            return state.setIn(['editCart', 'editAttributeGroups'],
                offerOrderingReducers.updateAttributeGroups(
                    state.editCart.editAttributeGroups,
                    payload.attributeId,
                    payload.formValue
                )
            );
        case UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL:
            return state.setIn(['editCart', 'editPhysicalAttributeGroups'],
                offerOrderingReducers.updatePhysicalInventoryMakeAndModel(
                    state.editCart.editPhysicalAttributeGroups,
                    payload
                )
            );
        case UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE:
            return state.setIn(['editCart', 'editPhysicalAttributeGroups'],
                offerOrderingReducers.updatePhysicalInventoryTypeAttribute(
                    state.editCart.editPhysicalAttributeGroups,
                    payload
                )
            );
        case UPDATE_PAYMENT_INFO:
            return offerOrderingReducers.updatePaymentInfo(state, payload);
        case UPDATE_PORT_IN_REQUEST:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.updateAttributePortInRequest(
                state.editCart.editAttributeGroups,
                payload.attributeId,
                payload.portInData,
                payload.token,
                payload.address));
        case RETRIEVE_TRANSITION_CONTEXT.BEGIN:
            return state.set('transitioningEnabled', false)
                .set('isRetrievingTransitionContext', true)
                .setIn(['editCart', 'transitionContext'], INITIAL_STATE.editCart.transitionContext)
                .set('transitioningContextError', false);
        case RETRIEVE_TRANSITION_CONTEXT.SUCCESS:
            return state.set('transitioningEnabled', payload.TransitioningEnabled)
                .set('transitionContext', payload.TransitionContext)
                .set('isRetrievingTransitionContext', false)
                .set('transitioningContextError', false);
        case RETRIEVE_TRANSITION_CONTEXT.FAILURE:
            return state.set('isRetrievingTransitionContext', false)
                .set('transitioningEnabled', false)
                .setIn(['editCart', 'transitionContext'], INITIAL_STATE.editCart.transitionContext)
                .set('transitioningContextError', true);
        case SET_IS_RESUMING_STORE_ORDER:
            return state.set(['isResumingStoreOrder'], payload);
        case TRANSITION_SUBMIT_COS_ORDER.BEGIN:
            return state
                .set('isSubmittingCOSOrder', true)
                .set('lastAttemptError', null);
        case TRANSITION_SUBMIT_COS_ORDER.SUCCESS:
            return state
                .set('isSubmittingCOSOrder', false)
                .set('lastAttemptError', null)
                .set('submittedCOS', payload);
        case TRANSITION_SUBMIT_COS_ORDER.FAILURE:
            return state
                .set('isSubmittingCOSOrder', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    faultData: payload.FaultData,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage,
                })
                .set('transitioningContextError', true);
        case SET_TRANSITION_OFFERINGS_METADATA:
            return state.set('offeringsMetadata', pluck('Offering', payload));
        case UPDATE_SELECTED_SERVICE_FEATURES:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.updateSelectedServiceFeatures(state.editCart.editAttributeGroups, payload));
        case NavigatorActions.STATE_CHANGE_SUCCESS:
            return setInitialState(state.isResumingStoreOrder);
        case SET_EDIT_CART_ADDITIONAL_PROPERTY:
            return state.setIn(['cartAdditionalProperties'], mapAdditionalProperty(payload));
        case SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE:
            return state.setIn(['cartAdditionalProperties'], setEditAp(payload, state.cartAdditionalProperties || EMPTY_ARRAY));
        case SET_EDIT_ORDER_ADDITIONAL_PROPERTY:
            return state.set('orderAdditionalProperties', mapAdditionalProperty(payload));
        case SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE:
            return state.set('orderAdditionalProperties', setEditAp(payload, state.orderAdditionalProperties || EMPTY_ARRAY));
        case RETRIEVE_ATTRIBUTES.SUCCESS:
            return state
                .setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.setEditAttributeGroups(payload.Context.ValueDecisions, pathOr(null, ['editCart', 'editAttributeGroups'], state)))
                .setIn(['editCart', 'deliveryDecisions'], payload.Context.DeliveryDecisions)
                .setIn(['editCart', 'serviceTaxDecisions'], payload.Context.ServiceTaxCustomizationDecisions);
        case SET_SERVICE_TAX_LOCATION_DECISION_CHANGE_OFFER:
            return state
                .setIn(['editCart', 'serviceTaxDecisions'], offerOrderingReducers.updateserviceTaxLocationDecision(state, payload));
        default:
            return state;
    }

    function setInitialState(resumingStoreOrder) {
        return INITIAL_STATE.merge({
            isResumingStoreOrder: resumingStoreOrder,
        });
    }
}
