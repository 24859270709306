import {createSelector} from 'reselect';
import {pathOr} from 'ramda';
import {
    RouteParams,
    SelectedCustomerSelector
} from './customer.selectors';
import {ContextOfferingAction} from '../constants/wizard.constants';
import {
    DEFAULT_OFFER_REMAINING,
    DEFAULT_REMOVE_ITEM_REMAINING_AMOUNTS
} from '../constants/offering.remove.constants';

export const RemoveOfferItemsIdsSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.offeringId && routeParams.offeringInstanceId ? [{
            offeringId: routeParams.offeringId,
            offeringInstanceId: routeParams.offeringInstanceId
        }] : null;
    }
);

export const RemoveOfferItemsRequestSelector = createSelector(
    [RemoveOfferItemsIdsSelector],
    (removeOfferingIds) => {
        const data = {};

        if (removeOfferingIds) {
            data.Offerings = removeOfferingIds.map((removeOffering) => {
                return {
                    Action: ContextOfferingAction.REMOVE,
                    OfferingId: removeOffering.offeringId,
                    OfferingInstanceId: removeOffering.offeringInstanceId
                };
            });
        }

        data.RetrievePaymentInstrument = true;

        return data;
    }
);

export const RemoveItemsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        const items = [];
        if (selectedCustomer.removeOffering.data.removeItems) {
            selectedCustomer.removeOffering.data.removeItems.forEach((item) => {
                const temp = Object.assign({}, item.asMutable({
                    deep: true
                }));
                if (item.OrderContractInstance) {
                    temp.OrderContractInstance.EffectiveEndDate = new Date().toISOString();
                }
                items.push(temp);
            });
        }
        return items;
    }
);

export const RemoveItemsTotalAmountSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(null, ['removeOffering', 'data', 'removeItemsTotalAmount'], selectedCustomer);
    }
);

export const RemoveItemsPaymentInstrumentSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(null, ['removeOffering', 'data', 'paymentInstrument'], selectedCustomer);
    }
);

export const RemoveItemsHasContractEndSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return !!selectedCustomer.removeOffering.data.contractEnd;
    }
);

const DEFAULT_FIRST_ITEM = {};
export const FirstRemoveItemsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(DEFAULT_FIRST_ITEM, ['removeOffering', 'data', 'removeItems', 0], selectedCustomer);
    }
);

export const CurrencySelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.currency ? routeParams.currency : null;
    }
);

export const OfferingNameSelector = createSelector(
    [FirstRemoveItemsSelector],
    (firstRemoveItem) => {
        return firstRemoveItem && firstRemoveItem.OrderedOfferingName ?
            firstRemoveItem.OrderedOfferingName : null;
    }
);

export const QuoteOfferSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.removeOffering ? selectedCustomer.removeOffering.quote : null;
    }
);

export const QuoteOfferRemoveItemRemainingAmountsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(DEFAULT_REMOVE_ITEM_REMAINING_AMOUNTS, ['removeOffering', 'removeItemRemainingAmounts'], selectedCustomer);
    }
);

export const RemoveItemsRemainingAmountSelector = createSelector(
    [SelectedCustomerSelector, QuoteOfferRemoveItemRemainingAmountsSelector],
    (selectedCustomer, remainingAmounts) => {
        const items = [];
        if (selectedCustomer.removeOffering.data.removeItems) {
            selectedCustomer.removeOffering.data.removeItems.forEach((item) => {
                const temp = Object.assign({}, item.asMutable({
                    deep: true
                }));
                if (item.OrderContractInstance) {
                    temp.OrderContractInstance.EffectiveEndDate = new Date().toISOString();
                }

                const amount = remainingAmounts.find((amount) => {
                    return item.LockerItemId === amount.SubscriberProductId && item.OrderedOfferingId === amount.OfferingId;
                });

                if (amount && amount.RemainingAmount) {
                    temp.Details.RemainingAmount = amount.RemainingAmount;
                }

                items.push(temp);
            });
        }
        return items;
    }
);

export const QuoteOfferRemainingAmountSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(DEFAULT_OFFER_REMAINING, ['removeOffering', 'totalRemainingAmount'], selectedCustomer);
    }
);

export const IsCalculatingQuoteSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.removeOffering ? selectedCustomer.removeOffering.isCalculatingQuote : null;
    }
);

export const IsSubmittingOrderSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.removeOffering && selectedCustomer.removeOffering.isSubmittingOrder;
    }
);

export const LastAttemptErrorSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.removeOffering && selectedCustomer.removeOffering.lastAttemptError;
    }
);

export const FirstOfferingIdSelector = createSelector(
    [RemoveOfferItemsRequestSelector],
    (offeringsArray) => {
        return pathOr(null, ['Offerings', 0, 'OfferingId'], offeringsArray);
    }
);
