import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {
    HasAccessHelper,
    HasAdminAccessHelper
} from './permissions.selectors.helpers';

import {
    ISSUE_CREDIT_TO_ALTERNATE_PAYMENT_INSTRUMENT,
    ORDERING_ACCESS,
    REMARK_OVERWRITE_ADMIN_ACCESS,
    SUBSCRIBER_ADMIN_ACCESS,
    SUBSCRIBER_BILLING_AND_NETOWRK_SUSPENSION,
    SUBSCRIBER_FINANCE_INSTALLMENT_SUSPEND_AND_RESUME_ACCESS,
    SUBSCRIBER_NETOWRK_SUSPENSION,
    UPDATE_SERVICE_LIFECYCLE_STATUS
} from '../../security.attributes';

export const HasAdminRemarkOverwriteAccessSelector = HasAdminAccessHelper([UserSecurityAttributesSelector], REMARK_OVERWRITE_ADMIN_ACCESS);
export const HasSubscriberAdminAccessSelector = HasAdminAccessHelper([UserSecurityAttributesSelector], SUBSCRIBER_ADMIN_ACCESS);
export const HasAdminOrderingAccessSelector = HasAdminAccessHelper([UserSecurityAttributesSelector], ORDERING_ACCESS);
export const HasIssueCreditToAlternatePaymentInstrumentSelector = HasAccessHelper([UserSecurityAttributesSelector], ISSUE_CREDIT_TO_ALTERNATE_PAYMENT_INSTRUMENT);

export const HasOrderingAccessSelector = HasAccessHelper([UserSecurityAttributesSelector], ORDERING_ACCESS);
export const HasSubscriberBillingAndNetworkSuspensionAccessSelector = HasAccessHelper([UserSecurityAttributesSelector], SUBSCRIBER_BILLING_AND_NETOWRK_SUSPENSION);
export const HasSubscriberNetworkSuspensionAccessSelector = HasAccessHelper([UserSecurityAttributesSelector], SUBSCRIBER_NETOWRK_SUSPENSION);
export const HasFinanceInstallmentSuspendResumeAccessSelector = HasAccessHelper([UserSecurityAttributesSelector], SUBSCRIBER_FINANCE_INSTALLMENT_SUSPEND_AND_RESUME_ACCESS);
export const HasUpdateServiceLifecycleStatusAccessSelector = HasAccessHelper([UserSecurityAttributesSelector], UPDATE_SERVICE_LIFECYCLE_STATUS);