import allPass from 'ramda/src/allPass';
import clone from 'ramda/src/clone';
import compose from 'ramda/src/compose';
import contains from 'ramda/src/includes';
import eqProps from 'ramda/src/eqProps';
import filter from 'ramda/src/filter';
import flatten from 'ramda/src/flatten';
import groupBy from 'ramda/src/groupBy';
import isEmpty from 'ramda/src/isEmpty';
import map from 'ramda/src/map';
import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import reverse from 'ramda/src/reverse';
import sortBy from 'ramda/src/sortBy';
import uniqWith from 'ramda/src/uniqWith';
import values from 'ramda/src/values';


import Immutable from 'seamless-immutable';
import {EMPTY_ARRAY, EMPTY_OBJECT} from './constants/common.constants';
import {INVENTORY_CATEGORY_CODE} from '../customercare.constants';
import {
    CLEAR_QUOTE,
    DECISION_OPTION_ITEM_QUANTITY_CHANGED,
    DECISION_OPTION_QUANTITY_CHANGED,
    DECISION_OPTION_SELECTED,
    DECISION_OPTION_SERVICE_IDS_FOR_REMOVAL_SELECTED,
    DISCOUNT_OVERRIDE_CHANGED,
    DISCOUNT_OVERRIDE_CLEAR,
    MAX_ONE_NON_DECISION_GROUP_OPTION_QUANTITY_CHANGED,
    RESET_AVAILABLE_INVENTORY_INFORMATION,
    RETRIEVE_ATTRIBUTES,
    RETRIEVE_BILLER_RULE_INSTANCE_DISCOUNT_CONTEXT,
    RETRIEVE_INVENTORY_AVAILABILITY,
    RETRIEVE_OFFERING_CONTEXT,
    SEARCH_INVENTORIES_AVAILABILITY,
    SET_ACTIVE_ATTRIBUTE_FORM_NAME,
    SET_ACTIVE_FORM_VALIDATION_STATUS,
    SET_ATTRIBUTE_FORM_SUBMITTED,
    SET_CHANGES_EFFECTIVE,
    SET_DECISIONS_SELECTED_PAGE,
    SET_DEVICE_PAYMENT_OPTIONS,
    SET_EXTERNAL_CONTRACT_ID,
    SET_PURCHASE_ORDER_NUMBER,
    SET_SAVED_LIFE_CYCLE_ID,
    SET_SELECTED_BRI_SELECTED_DISCOUNTS,
    SET_SELECTED_OFFER_NAME,
    UPDATE_CART_SUMMARY
} from './actions/offering.order.actions';
import {
    BEGIN_NEW_CONNECT_ORDER,
    CANCEL_ORDER,
    SAVE_OPTION_PRICING,
    SET_SELECTED_OFFER as NEW_CONNECT_SET_SELECTED_OFFER,
    SET_SELECTED_OFFER_EDIT_COPY as NEW_CONNECT_SET_SELECTED_OFFER_EDIT_COPY
} from './actions/new.connect.wizard.actions';
import {
    BEGIN_EDIT_OFFER_ORDER,
    EDIT_INITIAL_OFFERING_CONTEXT,
    EDIT_RETRIEVE_PREVIOUS_ATTRIBUTES,
    EDIT_SUBMIT_COS_ORDER,
    RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_EDIT,
    RESUME_STORE_ORDER_FOR_DECISIONS_FOR_EDIT,
    RETRIEVE_DECISION_OPTION_SERVICE_IDS,
    SAVE_OPTION_PRICING as EDIT_OFFER_SAVE_BULK_PRICING,
    SAVE_BWS_OPTION_EDIT,
    SET_DECISIONS_REQUIRING_AFFECTED_SERVICES_RESOLUTION
} from './actions/edit.offer.wizard.actions';
import {
    RESUME_ORDER,
    RESUME_STORE_ORDER,
    RESUME_ORDER_ATTRIBUTES,
    SAVE_OPTION_PRICING as ADD_OFFER_SAVE_OPTION_PRICING,
    SET_SELECTED_OFFER as ADD_OFFER_SET_SELECTED_OFFER,
    SET_SELECTED_OFFER_EDIT_COPY as ADD_OFFER_SET_SELECTED_OFFER_EDIT_COPY
} from './actions/add.offer.wizard.actions';
import {
    // RESUME_ORDER,
    // RESUME_STORE_ORDER,
    // RESUME_ORDER_ATTRIBUTES,
    SAVE_OPTION_PRICING as ADD_SERVICES_SAVE_OPTION_PRICING,
    SET_SELECTED_OFFER as ADD_SERVICES_SET_SELECTED_OFFER,
    SET_SELECTED_OFFER_EDIT_COPY as ADD_SERVICES_SET_SELECTED_OFFER_EDIT_COPY
} from './actions/add.services.wizard.actions';
import {
    SAVE_OPTION_PRICING as TRANSITION_OFFER_SAVE_OPTION_PRICING,
    TRANSITION_RETRIEVE_PREVIOUS_ATTRIBUTES,
    SET_SELECTED_OFFER as TRANSITION_SET_SELECTED_OFFER,
    SET_SELECTED_OFFER_EDIT_COPY as TRANSITION_OFFER_SET_SELECTED_OFFER_EDIT_COPY,
    RESUME_STORE_ORDER_TRANSITION,
    RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_TRANSITION
} from './actions/transition.offer.wizard.actions';
import {getBillerRuleInstanceThumbnailsWithCustomInformation} from './selected.offering.order.reducer.helper';

const EMPTY_STRING = '';

export const INITIAL_STATE = Immutable({
    data: {
        decisions: {
            data: {
                decisionsMap: {},
                pageDisplayOrder: [],
                pages: {},
                selectedBillerRuleInstanceDiscountContext: null,
                selectedBriSelectedDiscounts: [],
                selectedPageId: null,
            }
        },
        attributes: {
            data: {
                attributeFormSubmitted: false,
                attributeGroups: null,
                attributesValidationStatuses: [],
                availableInventory: [],
                currentAttributeFormName: null,
                physicalInventoryAttributeGroups: null,
                serviceTaxCustomizationDecisions: EMPTY_ARRAY,
                serviceTaxCustomizationHash: EMPTY_OBJECT
            }
        },
        changesEffective: null,
        devicePaymentOptions: {
            items: []
        },
        externalContractId: null,
        paymentInstrument: null,
        purchaseOrderNumber: null,
        shoppingCart: {},
        rocResponseOfferId: null,
    },
    decisionsRequiringAffectedServicesResolution: [],
    isFetchingBillerRuleInstanceDiscountContext: false,
    isFetchingData: false,
    isFetchingDecisionOptionServiceIds: false,
    isUpdatingCart: false,
    lastAttemptError: null,
    offerName: '',
    pricingPlanIdsNotFulfillingRequiredPricingPlanConditions: null,
    subscriberPhysicalInventoryPickupDetails: null
});

export default function selectedOfferingReducer(state = INITIAL_STATE, {payload, type, requestObject}) {
    switch (type) {
        // TODO: Update CLEAR_QUOTE name to what it actually does, which doesn't pertain to quote.
        case CLEAR_QUOTE:
            return state
                .setIn(['data', 'attributes'], INITIAL_STATE.data.attributes)
                .setIn(['data', 'shoppingCart'], INITIAL_STATE.data.shoppingCart);
        case EDIT_RETRIEVE_PREVIOUS_ATTRIBUTES.BEGIN:
        case RETRIEVE_ATTRIBUTES.BEGIN:
            return state
                .set('lastAttemptError', null)
                .set('isFetchingData', true);
        case RETRIEVE_OFFERING_CONTEXT.BEGIN:
        case EDIT_INITIAL_OFFERING_CONTEXT.BEGIN:
        case RESUME_ORDER_ATTRIBUTES.BEGIN:
        case RESUME_ORDER.BEGIN:
        case RESUME_STORE_ORDER.BEGIN:
        case RESUME_STORE_ORDER_TRANSITION.BEGIN:
        case RETRIEVE_INVENTORY_AVAILABILITY.BEGIN:
        case SEARCH_INVENTORIES_AVAILABILITY.BEGIN:
            return state.set('isFetchingData', true);
        case RETRIEVE_OFFERING_CONTEXT.SUCCESS:
        case EDIT_INITIAL_OFFERING_CONTEXT.SUCCESS:
        case RESUME_ORDER.SUCCESS:
        case RESUME_STORE_ORDER.SUCCESS:
            return state
                .set('lastAttemptError', null)
                .set('isFetchingData', false)
                .set('offerName', pathOr(0, ['ShoppingCart', 'Items', 'length'], payload) ? payload.ShoppingCart.Items[0].OrderedOfferingName : '')
                .set('pricingPlanIdsNotFulfillingRequiredPricingPlanConditions', payload.PricingPlanIdsNotFulfillingRequiredPricingPlanConditions)
                .setIn(['data', 'decisions', 'data', 'decisionsMap'], populateDecisionsMap(payload.Context.Decisions))
                .setIn(['data', 'decisions', 'data', 'pageDisplayOrder'], populatePagesDisplayOrder(payload.Context.Pages))
                .setIn(['data', 'decisions', 'data', 'pages'], populatePagesMap(payload.Context.Pages, payload.Context.Decisions))
                .setIn(['data', 'shoppingCart'], payload.ShoppingCart)
                .setIn(['data', 'shoppingCart', 'RemoveItems'], payload.RemoveItems ? populateRemoveItemFlag(payload.RemoveItems) : EMPTY_ARRAY)
                .setIn(['data', 'shoppingCart', 'ModifyItems'], payload.ModifyItems ? populateModifyItemFlag(payload.ModifyItems) : EMPTY_ARRAY)
                .setIn(['data', 'shoppingCart', 'AddItems'], payload.AddItems)
                .setIn(['data', 'rocResponseOfferId'], payload.Context.OfferingIds[0])
                .setIn(['data', 'purchaseOrderNumber'], pathOr(0, ['PurchaseOrderNumberHistory', 'length'], payload) ? payload.PurchaseOrderNumberHistory[0] : state.purchaseOrderNumber)
                .setIn(['data', 'externalContractId'], pathOr(null, ['ShoppingCart', 'Items', '0', 'OrderContractInstance', 'ExternalContractId'], payload));
        case RESUME_STORE_ORDER_TRANSITION.SUCCESS:
            return state
                .set('lastAttemptError', null)
                .set('isFetchingData', false)
                .set('offerName', pathOr(0, ['ShoppingCart', 'Items', 'length'], payload) ? payload.ShoppingCart.Items[0].OrderedOfferingName : '')
                .set('pricingPlanIdsNotFulfillingRequiredPricingPlanConditions', payload.PricingPlanIdsNotFulfillingRequiredPricingPlanConditions)
                .setIn(['data', 'decisions', 'data', 'decisionsMap'], populateDecisionsMap(payload.Context.Decisions))
                .setIn(['data', 'decisions', 'data', 'pageDisplayOrder'], populatePagesDisplayOrder(payload.Context.Pages))
                .setIn(['data', 'decisions', 'data', 'pages'], populatePagesMap(payload.Context.Pages, payload.Context.Decisions))
                .setIn(['data', 'shoppingCart'], payload.ShoppingCart)
                .setIn(['data', 'shoppingCart', 'RemoveItems'], payload.RemoveItems ? populateRemoveItemFlag(payload.RemoveItems) : EMPTY_ARRAY)
                .setIn(['data', 'shoppingCart', 'ModifyItems'], payload.ModifyItems ? populateModifyItemFlag(payload.ModifyItems) : EMPTY_ARRAY)
                .setIn(['data', 'shoppingCart', 'AddItems'], payload.AddItems)
                .setIn(['data', 'rocResponseOfferId'], payload.Context.OfferingIds[0])
                .setIn(['data', 'purchaseOrderNumber'], pathOr(0, ['PurchaseOrderNumberHistory', 'length'], payload) ? payload.PurchaseOrderNumberHistory[0] : state.purchaseOrderNumber)
                .setIn(['data', 'externalContractId'], pathOr(null, ['ShoppingCart', 'Items', '0', 'OrderContractInstance', 'ExternalContractId'], payload))
                .set('subscriberPhysicalInventoryPickupDetails', pathOr(null, ['ShoppingCart', 'SubscriberPhysicalInventoryPickupDetail'], payload));
        case RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_TRANSITION.SUCCESS:
            return state
                .set('isFetchingData', false)
                .setIn(['data', 'attributes', 'data', 'attributeGroups'], populateAttributeGroups(payload.Context.ValueDecisions))
                .setIn(['data', 'attributes', 'data', 'physicalInventoryAttributeGroups'], populatePhysicalInventoryAttributeGroups(payload.Context.PhysicalInventoryDecisions))
                .setIn(['data', 'attributes', 'data', 'physicalInventoryDecisions'], payload.Context.PhysicalInventoryDecisions);
        case RESUME_STORE_ORDER_FOR_DECISIONS_FOR_EDIT.SUCCESS:
            return state
                .set('lastAttemptError', null)
                .set('isFetchingData', false)
                .set('offerName', pathOr(0, ['ShoppingCart', 'Items', 'length'], payload) ? payload.ShoppingCart.Items[0].OrderedOfferingName : '')
                .set('pricingPlanIdsNotFulfillingRequiredPricingPlanConditions', payload.PricingPlanIdsNotFulfillingRequiredPricingPlanConditions)
                .setIn(['data', 'decisions', 'data', 'decisionsMap'], populateDecisionsMap(payload.Context.Decisions))
                .setIn(['data', 'decisions', 'data', 'pageDisplayOrder'], populatePagesDisplayOrder(payload.Context.Pages))
                .setIn(['data', 'decisions', 'data', 'pages'], populatePagesMap(payload.Context.Pages, payload.Context.Decisions))
                .setIn(['data', 'shoppingCart'], payload.ShoppingCart)
                .setIn(['data', 'shoppingCart', 'RemoveItems'], payload.RemoveItems ? populateRemoveItemFlag(payload.RemoveItems) : EMPTY_ARRAY)
                .setIn(['data', 'shoppingCart', 'ModifyItems'], payload.ModifyItems ? populateModifyItemFlag(payload.ModifyItems) : EMPTY_ARRAY)
                .setIn(['data', 'shoppingCart', 'AddItems'], payload.AddItems)
                .setIn(['data', 'rocResponseOfferId'], payload.Context.OfferingIds[0])
                .setIn(['data', 'purchaseOrderNumber'], pathOr(0, ['PurchaseOrderNumberHistory', 'length'], payload) ? payload.PurchaseOrderNumberHistory[0] : state.purchaseOrderNumber)
                .setIn(['data', 'externalContractId'], pathOr(null, ['ShoppingCart', 'Items', '0', 'OrderContractInstance', 'ExternalContractId'], payload))
                .set('subscriberPhysicalInventoryPickupDetails', pathOr(null, ['ShoppingCart', 'SubscriberPhysicalInventoryPickupDetail'], payload));
        case SET_SAVED_LIFE_CYCLE_ID:
            return state
                .setIn(['data', 'decisions', 'data', 'decisionsMap'], populateDecisionsMapWithSavedLifeCycle(state.data.decisions.data.decisionsMap, payload.lifeCycleId, payload.optionId, payload.decisionId, payload.isEditable));
        case SET_SELECTED_BRI_SELECTED_DISCOUNTS:
            return state
                .setIn(['data', 'decisions', 'data', 'selectedBriSelectedDiscounts'], payload);
        case RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_EDIT.SUCCESS:
            return state
                .set('isFetchingData', false)
                .setIn(['data', 'attributes', 'data', 'attributeGroups'], populateAttributeGroups(payload.Context.ValueDecisions))
                .setIn(['data', 'attributes', 'data', 'physicalInventoryAttributeGroups'], populatePhysicalInventoryAttributeGroups(payload.Context.PhysicalInventoryDecisions))
                .setIn(['data', 'attributes', 'data', 'physicalInventoryDecisions'], payload.Context.PhysicalInventoryDecisions);
        case EDIT_RETRIEVE_PREVIOUS_ATTRIBUTES.FAILURE:
        case RETRIEVE_ATTRIBUTES.FAILURE:
        case TRANSITION_RETRIEVE_PREVIOUS_ATTRIBUTES.FAILURE:
            return state.set('lastAttemptError', {
                code: payload.Code,
                faultData: payload.FaultData,
                message: payload.Message,
                severity: payload.Severity,
                translatedMessage: payload.translatedMessage,
            }).set('isFetchingData', false);
        case RETRIEVE_OFFERING_CONTEXT.FAILURE:
        case EDIT_INITIAL_OFFERING_CONTEXT.FAILURE:
        case RETRIEVE_INVENTORY_AVAILABILITY.FAILURE:
        case RESUME_ORDER_ATTRIBUTES.FAILURE:
        case RESUME_ORDER.FAILURE:
        case RESUME_STORE_ORDER.FAILURE:
        case RESUME_STORE_ORDER_FOR_DECISIONS_FOR_EDIT.FAILURE:
        case RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_EDIT.FAILURE:
        case EDIT_SUBMIT_COS_ORDER.FAILURE:
        case SEARCH_INVENTORIES_AVAILABILITY.FAILURE:
            return state
                .set('isFetchingData', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity
                });
        case UPDATE_CART_SUMMARY.BEGIN:
            return state.set('isUpdatingCart', true);
        case UPDATE_CART_SUMMARY.SUCCESS:
            return state
                .set('lastAttemptError', null)
                .set('isUpdatingCart', false)
                .set('offerName', payload.ShoppingCart.Items && payload.ShoppingCart.Items.length ? payload.ShoppingCart.Items[0].OrderedOfferingName : '')
                .setIn(['data', 'shoppingCart'], payload.ShoppingCart)
                .setIn(['data', 'shoppingCart', 'RemoveItems'], populateRemoveItemFlag(payload.RemoveItems))
                .setIn(['data', 'shoppingCart', 'ModifyItems'], populateModifyItemFlag(payload.ModifyItems))
                .setIn(['data', 'shoppingCart', 'AddItems'], payload.AddItems)
                .setIn(['data', 'deliveryDecisions'], payload.Context.DeliveryDecisions || EMPTY_ARRAY)
                .setIn(['data', 'decisions', 'data', 'decisionsMap'], updateDecisionsMap(state.data.decisions.data.decisionsMap, payload.Context.Decisions));
        case UPDATE_CART_SUMMARY.FAILURE:
            return state
                .set('isUpdatingCart', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity
                });
        case SET_ACTIVE_ATTRIBUTE_FORM_NAME:
            return state.setIn(['data', 'attributes', 'data', 'currentAttributeFormName'], payload);
        case SET_ATTRIBUTE_FORM_SUBMITTED:
            return state.setIn(['data', 'attributes', 'data', 'attributeFormSubmitted'], payload);
        case SET_ACTIVE_FORM_VALIDATION_STATUS:
            return state.setIn(['data', 'attributes', 'data', 'attributesValidationStatuses'], mergeAttributeFormValidationStatus(payload, state.data.attributes.data.attributesValidationStatuses));
        case RETRIEVE_ATTRIBUTES.SUCCESS:
        case EDIT_RETRIEVE_PREVIOUS_ATTRIBUTES.SUCCESS:
        case RESUME_ORDER_ATTRIBUTES.SUCCESS:
        case TRANSITION_RETRIEVE_PREVIOUS_ATTRIBUTES.SUCCESS:
            return state
                .set('lastAttemptError', null)
                .set('isFetchingData', false)
                .setIn(['data', 'attributes', 'data', 'attributeGroups'], populateAttributeGroups(payload.Context.ValueDecisions))
                .setIn(['data', 'attributes', 'data', 'physicalInventoryAttributeGroups'], populatePhysicalInventoryAttributeGroups(payload.Context.PhysicalInventoryDecisions, state.data.attributes.data.physicalInventoryAttributeGroups))
                .setIn(['data', 'attributes', 'data', 'physicalInventoryDecisions'], payload.Context.PhysicalInventoryDecisions)
                .setIn(['data', 'shoppingCart'], payload.ShoppingCart)
                .setIn(['data', 'shoppingCart', 'RemoveItems'], populateRemoveItemFlag(payload.RemoveItems))
                .setIn(['data', 'shoppingCart', 'ModifyItems'], populateModifyItemFlag(payload.ModifyItems))
                .setIn(['data', 'shoppingCart', 'AddItems'], payload.AddItems)
                .setIn(['data', 'deliveryDecisions'], payload.Context.DeliveryDecisions || EMPTY_ARRAY)
                .setIn(['data', 'paymentInstrument'], payload.PaymentInstrument)
                .setIn(['data', 'attributes', 'data', 'serviceTaxCustomizationDecisions'], payload.Context.ServiceTaxCustomizationDecisions)
                .setIn(['data', 'attributes', 'data', 'serviceTaxCustomizationHash'], serviceTaxCustomization(payload.Context.ServiceTaxCustomizationDecisions));
        case SET_DECISIONS_REQUIRING_AFFECTED_SERVICES_RESOLUTION:
            return state.set('decisionsRequiringAffectedServicesResolution', populateDecisionsRequiringAffectedServicesResolution(state.decisionsRequiringAffectedServicesResolution, payload));
        case SET_DECISIONS_SELECTED_PAGE:
            return state.setIn(['data', 'decisions', 'data', 'selectedPageId'], payload);
        case DECISION_OPTION_SELECTED:
            return state.setIn(['data', 'decisions', 'data', 'decisionsMap'], setSelectedDecisionOption(state.data.decisions.data.decisionsMap, payload));
        case DECISION_OPTION_QUANTITY_CHANGED:
            return state.setIn(['data', 'decisions', 'data', 'decisionsMap'], updateDecisionOptionQuantity(state.data.decisions.data.decisionsMap, payload));
        case DECISION_OPTION_ITEM_QUANTITY_CHANGED:
            return state.setIn(['data', 'decisions', 'data', 'decisionsMap'], updateDecisionOptionItemQuantity(state.data.decisions.data.decisionsMap, payload));
        case DISCOUNT_OVERRIDE_CHANGED:
            return state.setIn(['data', 'decisions', 'data', 'decisionsMap'], updateDecisionOptionBriDiscountOverride(state.data.decisions.data.decisionsMap, payload));
        case DISCOUNT_OVERRIDE_CLEAR:
            return state.setIn(['data', 'decisions', 'data', 'decisionsMap'], updateDecisionOptionDiscountClearOverride(state.data.decisions.data.decisionsMap, payload));
        case DECISION_OPTION_SERVICE_IDS_FOR_REMOVAL_SELECTED:
            return state.setIn(['data', 'decisions', 'data', 'decisionsMap'], setDecisionOptionServiceIdsForRemoval(state.data.decisions.data.decisionsMap, payload));
        case MAX_ONE_NON_DECISION_GROUP_OPTION_QUANTITY_CHANGED:
            return state.setIn(['data', 'decisions', 'data', 'decisionsMap'], updateDecisionOptionQuantity(state.data.decisions.data.decisionsMap, payload));
        case SAVE_OPTION_PRICING:
        case ADD_OFFER_SAVE_OPTION_PRICING:
        case ADD_SERVICES_SAVE_OPTION_PRICING:
        case TRANSITION_OFFER_SAVE_OPTION_PRICING:
        case EDIT_OFFER_SAVE_BULK_PRICING:
        case SAVE_BWS_OPTION_EDIT:
            return state.setIn(['data', 'decisions', 'data', 'decisionsMap'], saveOptionPricing(state.data.decisions.data.decisionsMap, payload));
        case ADD_OFFER_SET_SELECTED_OFFER_EDIT_COPY:
        case ADD_SERVICES_SET_SELECTED_OFFER_EDIT_COPY:
        case TRANSITION_OFFER_SET_SELECTED_OFFER_EDIT_COPY:
        case NEW_CONNECT_SET_SELECTED_OFFER_EDIT_COPY:
            return payload ? state : INITIAL_STATE;
        case SET_CHANGES_EFFECTIVE:
            return state
                .setIn(['data', 'changesEffective'], payload);
        case SET_SELECTED_OFFER_NAME:
            return state.set('offerName', payload);
        case SET_DEVICE_PAYMENT_OPTIONS:
            return state
                .setIn(['data', 'devicePaymentOptions', 'items'], payload);
        case SET_EXTERNAL_CONTRACT_ID:
            return state
                .setIn(['data', 'externalContractId'], payload);
        case SET_PURCHASE_ORDER_NUMBER:
            return state
                .setIn(['data', 'purchaseOrderNumber'], payload);
        case BEGIN_EDIT_OFFER_ORDER:
            return setInitialStateKeepOfferName(state.offerName);
        case EDIT_SUBMIT_COS_ORDER.SUCCESS:
            return state.setIn(['data', 'modifiedOrder'], payload.Order);
        case RESET_AVAILABLE_INVENTORY_INFORMATION:
            return state.setIn(['data', 'attributes', 'data', 'availableInventory'], []);
        case RETRIEVE_INVENTORY_AVAILABILITY.SUCCESS:
            return state
                .setIn(['data', 'attributes', 'data', 'availableInventory'], state.data.attributes.data.availableInventory.concat(Object.assign(payload, requestObject)))
                .set('isFetchingData', false);
        case SEARCH_INVENTORIES_AVAILABILITY.SUCCESS:
            return state
                .setIn(['data', 'attributes', 'data', 'availableInventory'],
                    state.data.attributes.data.availableInventory.concat(
                        parseSearchInventoriesAvailabilityPayload(payload.InventoryTypes ? payload.InventoryTypes : [], requestObject)
                    )
                )
                .set('isFetchingData', false);
        case BEGIN_NEW_CONNECT_ORDER:
        case CANCEL_ORDER:
        case ADD_OFFER_SET_SELECTED_OFFER:
        case ADD_SERVICES_SET_SELECTED_OFFER:
        case NEW_CONNECT_SET_SELECTED_OFFER:
        case TRANSITION_SET_SELECTED_OFFER:
            return INITIAL_STATE;
        case RETRIEVE_DECISION_OPTION_SERVICE_IDS.BEGIN:
            return state.set('isFetchingDecisionOptionServiceIds', true);
        case RETRIEVE_DECISION_OPTION_SERVICE_IDS.FAILURE:
        case RETRIEVE_DECISION_OPTION_SERVICE_IDS.SUCCESS:
            return state.set('isFetchingDecisionOptionServiceIds', false);
        case TRANSITION_RETRIEVE_PREVIOUS_ATTRIBUTES.BEGIN:
            return state.set('isFetchingData', true);
        case RETRIEVE_BILLER_RULE_INSTANCE_DISCOUNT_CONTEXT.BEGIN:
            return state.set('isFetchingBillerRuleInstanceDiscountContext', true);
        case RETRIEVE_BILLER_RULE_INSTANCE_DISCOUNT_CONTEXT.SUCCESS:
            return state.setIn(['data', 'decisions', 'data', 'selectedBillerRuleInstanceDiscountContext'], payload)
                .setIn(['isFetchingBillerRuleInstanceDiscountContext'], false);
        case RETRIEVE_BILLER_RULE_INSTANCE_DISCOUNT_CONTEXT.FAILURE:
            return state.set('isFetchingBillerRuleInstanceDiscountContext', false)
                .setIn(['data', 'decisions', 'data', 'selectedBillerRuleInstanceDiscountContext'], null);
        default:
            return state;
    }
}

function populatePagesMap(pages, decisions) {
    const pagesMaps = {};
    const sortedDecisions = reverse(sortBy(prop('DisplayOrder'))(decisions));

    pages.forEach(page => {
        const decisionIdsForPage = sortedDecisions.filter((decision) => {
            return decision.PageId === page.Id;
        }).map((decision) => {
            return decision.Id;
        });

        pagesMaps[page.Id] = Immutable(page).set('decisionIds', decisionIdsForPage);
    });

    return pagesMaps;
}

function populatePagesDisplayOrder(pages) {
    const sortByPageNumber = sortBy(prop('PageNumber'));

    return sortByPageNumber(pages).map((page) => {
        return page.Id;
    });
}

function populateDecisionsMapWithSavedLifeCycle(decisionsMap, lifeCycleId, optionId, decisionId, isEditable) {
    const newDecisionsMaps = {};

    const updatedDecisions = values(decisionsMap).map(decision => {
        if (decision.Id !== decisionId) {
            return decision;
        } else {
            return Object.assign({}, decision, {
                Options: decision.Options.map(option => {
                    if (option.Id === optionId) {
                        return Object.assign({}, option, {
                            OfferingDecisionOptionInstances: [{
                                SelectedLifeCycle: {
                                    LifeCycleId: lifeCycleId,
                                    IsEditable: isEditable
                                }
                            }]
                        });
                    } else {
                        return option;
                    }
                })
            });
        }
    });

    updatedDecisions.forEach(decision => {
        newDecisionsMaps[decision.Id] = decision;
    });

    return newDecisionsMaps;
}

function populateDecisionsMap(decisions) {
    const decisionsMaps = {};
    decisions.forEach(decision => {
        decision.Options = decision.Options.map((option) => {
            option.Quantity = option.Quantity || 0;
            option.Selected = option.Selected || false;
            option.isDecisionGroupOptionItem = option.Items.some((item) => {
                return item.DecisionGroupOptionItemId;
            });
            if (option.BillerRuleInstanceThumbnails) {
                option.BillerRuleInstanceThumbnails = getBillerRuleInstanceThumbnailsWithCustomInformation(option);
            }
            return option;
        });
        decisionsMaps[decision.Id] = decision;
    });

    return decisionsMaps;
}

function updateDecisionsMap(currentDecisions, decisions) {
    const decisionsMaps = {};
    decisions.forEach(decision => {
        decision.Options = decision.Options.map((option) => {
            const matchingOption = Immutable.asMutable(currentDecisions[decision.Id].Options.find((currentOption) => {
                return currentOption.Id === option.Id;
            }));
            matchingOption.BillerRuleInstanceThumbnails = option.BillerRuleInstanceThumbnails;
            matchingOption.BillerRuleDetails = option.BillerRuleDetails;
            matchingOption.BillerRuleInstanceThumbnails = option.BillerRuleInstanceThumbnails;
            if (option.OfferingDecisionOptionInstances && option.OfferingDecisionOptionInstances.length && !isEmpty(option.OfferingDecisionOptionInstances[0])) {
                matchingOption.OfferingDecisionOptionInstances = option.OfferingDecisionOptionInstances;
            }
            if (option.BillerRuleInstanceThumbnails) {
                option.BillerRuleInstanceThumbnails = getBillerRuleInstanceThumbnailsWithCustomInformation(option, matchingOption);
                matchingOption.BillerRuleInstanceThumbnails = option.BillerRuleInstanceThumbnails;
            }
            matchingOption.DiscountAmount = option.DiscountAmount;
            matchingOption.Amount = option.Amount;
            matchingOption.Selected = option.Selected;
            return matchingOption;
        });
        decisionsMaps[decision.Id] = decision;
    });

    return decisionsMaps;
}



function populateModifyItemFlag(items) {
    return items.map((item) => {
        return Object.assign({}, item, {
            isModified: true
        });
    });
}

function populateRemoveItemFlag(items) {
    return items.map((item) => {
        return Object.assign({}, item, {
            isRemoved: true
        });
    });
}

const groupByPlanId = groupBy((attribute) => {
    return `${attribute.PricingPlanId}-${attribute.InstanceNumber}`;
});
const sortGroups = sortBy((attributes) => {
    return attributes[0].ServiceDisplayOrder;
});
const filterAttributes = filter((attribute) => {
    return attribute.InventoryProviderType === 0;
});
const sortAttributes = map(sortBy(prop('DisplayOrder')));

const annotatePricingPlanNames = (attributeGroups) => {
    const pricingPlanDupes = attributeGroups.reduce((acc, attrGroup) => {
        const planNames = acc[attrGroup[0].PricingPlanName];
        const count = planNames ? planNames + 1 : 1;

        return Object.assign({}, acc, {
            [attrGroup[0].PricingPlanName]: count
        });
    }, {});
    const filteredDupes = filter((value) => {
        return value > 1;
    }, pricingPlanDupes);

    const reversedGroups = reverse(attributeGroups).map((attrGroup) => {
        const count = filteredDupes[attrGroup[0].PricingPlanName];
        filteredDupes[attrGroup[0].PricingPlanName]--;

        if (count) {
            return attrGroup.map((attribute) => {
                return Object.assign({}, attribute, {
                    PricingPlanName: `${attribute.PricingPlanName} (${count})`
                });
            });
        } else {
            return attrGroup;
        }
    });

    return reverse(reversedGroups);
};

const isDependentAttributeHidden = (attribute, attributeGroup = []) => {
    const dependentAttribute = attributeGroup.find((groupAttribute) => {
        return groupAttribute.Id === attribute.DependentServiceAttributeId;
    });

    return ((dependentAttribute && dependentAttribute.SelectedValue) !== attribute.DependentValues) ||
        attribute.IsHidden;
};

// Produces an array of attribute groups
// An attribute group is a collection of attributes
// Both the groups and individuals attributes are sorted
export function populateAttributeGroups(attributes) {
    const transformedAttributeGroups = map((attributeGroup) => {
        return map((attribute) => {
            const isDependent = attribute.DependentServiceAttributeId && attribute.DependentValues;

            return {
                allowEdit: attribute.AllowEdit,
                dependentServiceAttributeId: attribute.DependentServiceAttributeId,
                dependentValues: isDependent ? attribute.DependentValues.split(',').map((value) => {
                    return value.trim();
                }) : undefined,
                description: attribute.Description,
                enableWildCardSearch: attribute.EnableWildcardSearch || true,
                formValue: attribute.InventoryItemReservation && attribute.InventoryCategoryCode === INVENTORY_CATEGORY_CODE.LOGICAL ?
                    attribute.InventoryItemReservation.SerialNumber :
                    attribute.SelectedValue && attribute.SelectedValue !== EMPTY_STRING ?
                        attribute.SelectedValue :
                        EMPTY_STRING,
                id: attribute.Id,
                instanceNumber: attribute.InstanceNumber,
                inventoryCategoryCode: attribute.InventoryCategoryCode,
                inventoryItemReservation: attribute.InventoryItemReservation ?
                    setInventoryDetails(attribute.InventoryItemReservation) :
                    null,
                inventoryLookupType: attribute.InventoryLookupType,
                inventoryTypeId: attribute.InventoryTypeId,
                isHidden:  !!attribute.DependentServiceAttributeId ?
                    isDependentAttributeHidden(attribute, attributeGroup) :
                    attribute.IsHidden,
                isPortable: attribute.IsPortable,
                isRequired: attribute.IsRequired,
                isServiceFeatureAvailable: attribute.IsServiceFeatureAvailable || false,
                name: attribute.Name,
                parentServicePlanOrderScenario: attribute.ParentServicePlanOrderScenario,
                passPortIn: false,
                possibleValues: attribute.PossibleValues,
                pricingPlanId: attribute.PricingPlanId,
                pricingPlanName: attribute.PricingPlanName,
                regularExpression: attribute.RegularExpression,
                requestedInventoryIsUnavailable: attribute.RequestedInventoryIsUnavailable || false,
                searchingInventory: false,
                selectedValue: attribute.SelectedValue && attribute.SelectedValue !== EMPTY_STRING && attribute.InventoryItemReservation ?
                    attribute.SelectedValue :
                    null,
                selectedValueForEdit: attribute.SelectedValue,
                serviceAttributeId: attribute.ServiceAttributeId,
                serviceAttributeType: attribute.ServiceAttributeType,
                serviceFeatures: attribute.ServiceFeatures,
                serviceId: attribute.ServiceId,
                serviceIdentifier: attribute.ServiceIdentifierFlag,
                serviceInstanceId: attribute.ServiceInstanceId
            };
        }, attributeGroup);
    });

    return compose(
        annotatePricingPlanNames,
        transformedAttributeGroups,
        sortAttributes,
        sortGroups,
        values,
        groupByPlanId,
        filterAttributes)(attributes);
}

const setInventoryDetails = (inventoryReservation) => {
    return {
        options: [{
            instanceId: inventoryReservation.InstanceId,
            serialNumber: inventoryReservation.SerialNumber
        }],
        instanceId: inventoryReservation.InstanceId,
        inventoryTypeId: inventoryReservation.InventoryTypeId,
        serialNumber: inventoryReservation.SerialNumber,
        token: inventoryReservation.Token
    };
};

export const populatePhysicalInventoryAttributeGroups = (attributes = EMPTY_ARRAY, currentState) => {
    return groupBy((attribute) => {
        return attribute.PricingPlanId;
    })(map((attribute) => {
        const currentAttribute = currentState && Object.keys(currentState).length && (currentState[attribute.PricingPlanId] || EMPTY_ARRAY).find ?
            (currentState[attribute.PricingPlanId] || EMPTY_ARRAY).find((group) => {
                return group.id === attribute.Id;
            }) : EMPTY_OBJECT;

        const {InventoryType} = attribute;
        return {
            id: attribute.Id,
            name: InventoryType.Name,
            description: InventoryType.Description,
            instanceNumber: attribute.InstanceNumber,
            isRequired: attribute.IsRequired,
            pricingPlanName: attribute.PricingPlanName,
            PricingPlanId: attribute.PricingPlanId,
            productName: attribute.ProductName,
            subscriberProductId: attribute.SubscriberProductId || undefined,
            displayName: InventoryType.DisplayName,
            typeAttributes: InventoryType.InventoryTypeAttributes.map(({Name, PossibleValues, Id, Required, SelectedValue}) => {
                const currentTypeAttribute = pathOr(EMPTY_ARRAY, ['typeAttributes'], currentAttribute).find((typeAttribute) => {
                    return typeAttribute.id === Id;
                });
                return {
                    name: Name,
                    possibleValues: PossibleValues,
                    required: Required,
                    id: Id,
                    /*
                        Below is needed in offer.ordering.wizard.reducer.helper
                        in the `populateDevice` function
                    */
                    selectedValue: currentTypeAttribute && currentTypeAttribute.selectedValue || SelectedValue
                };
            }),
            inventoryTypeId: InventoryType.Id,
            makeInformation: InventoryType.InventoryTypeMakes.map(({Name, Id, InventoryTypeModels}) => {
                return {
                    name: Name,
                    possibleValues: InventoryTypeModels.map(({Name, Id}) => {
                        return {
                            name: Name,
                            id: Id
                        };
                    }),
                    id: Id
                };
            }),
            selectedMakeFromSavedCart: InventoryType.SelectedMakeId,
            selectedModelFromSavedCart: InventoryType.SelectedModelId
        };
    }, attributes));
};

function setSelectedDecisionOption(decisionsMap, selectedOption) {
    const newDecisionsMaps = {};

    const updatedDecisions = values(decisionsMap).map(decision => {
        if (decision.Id !== selectedOption.decisionId) {
            return decision;
        } else {
            return Object.assign({}, decision, {
                Options: decision.Options.map(option => {
                    return Object.assign({}, option, {
                        Selected: option.Id === selectedOption.optionId
                    });
                })
            });
        }
    });

    updatedDecisions.forEach(decision => {
        newDecisionsMaps[decision.Id] = decision;
    });

    return newDecisionsMaps;
}

function setDecisionOptionServiceIdsForRemoval(decisionsMap, serviceIdsSelections) {
    const newDecisionsMaps = {};

    const updatedDecisions = values(decisionsMap).map(decision => {
        if (decision.Id !== serviceIdsSelections.decisionId) {
            return decision;
        } else {
            return Object.assign({}, decision, {
                Options: decision.Options.map((option) => {
                    if (option.Id !== serviceIdsSelections.optionId) {
                        return option;
                    } else {
                        return Object.assign({}, option, {
                            SelectedServicesForRemoval: serviceIdsSelections.serviceIds && serviceIdsSelections.serviceIds.length ? serviceIdsSelections.serviceIds : null
                        });
                    }
                })
            });
        }
    });

    updatedDecisions.forEach(decision => {
        newDecisionsMaps[decision.Id] = decision;
    });

    return newDecisionsMaps;
}

function updateDecisionOptionQuantity(decisionsMap, quantityUpdate) {
    const newDecisionsMaps = {};

    const updatedDecisions = values(decisionsMap).map(decision => {
        if (decision.Id !== quantityUpdate.decisionId) {
            return decision;
        } else {
            return Object.assign({}, decision, {
                Options: decision.Options.map(option => {
                    if (option.Id !== quantityUpdate.optionId) {
                        return Object.assign({}, option, {
                            Quantity: quantityUpdate.shouldResetOtherDecisionOptionsQuantityToZero ? 0 : option.Quantity || 0
                        });
                    } else {
                        return Object.assign({}, option, {
                            ...(option.BillerRuleDetails && option.Bulk ? {
                                BillerRuleDetails: option.BillerRuleDetails.map((billerRuleDetail) => {
                                    return {
                                        ...billerRuleDetail,
                                        Quantity: quantityUpdate.quantity
                                    };
                                })
                            } : {}),
                            Quantity: option.Bulk && quantityUpdate.quantity ? 1 : quantityUpdate.quantity
                        });
                    }
                })
            });
        }
    });

    updatedDecisions.forEach(decision => {
        newDecisionsMaps[decision.Id] = decision;
    });

    return newDecisionsMaps;
}

function updateDecisionOptionItemQuantity(decisionsMap, quantityUpdate) {
    const newDecisionsMaps = {};

    const updatedDecisions = values(decisionsMap).map(decision => {
        if (decision.Id !== quantityUpdate.decisionId) {
            return decision;
        } else {
            return Object.assign({}, decision, {
                Options: decision.Options.map(option => {
                    if (option.Id !== quantityUpdate.optionId) {
                        return Object.assign({}, option, {
                            Quantity: option.Quantity || 0
                        });
                    } else {
                        return Object.assign({}, option, {
                            Items: option.Items.map(item => {
                                if (item.DecisionGroupOptionItemId === quantityUpdate.itemId) {
                                    return Object.assign({}, item, {
                                        Quantity: quantityUpdate.quantity,
                                        hasError: item.MinimumQuantity > quantityUpdate.quantity || item.MaximumQuantity < quantityUpdate.quantity
                                    });
                                } else {
                                    return item;
                                }
                            })
                        });
                    }
                })
            });
        }
    });

    updatedDecisions.forEach(decision => {
        newDecisionsMaps[decision.Id] = decision;
    });

    return newDecisionsMaps;
}

function updateDecisionOptionDiscountClearOverride(decisionsMap, decisionUpdate) {
    const newDecisionsMaps = {};

    const updatedDecisions = values(decisionsMap).map(decision => {
        if (decision.Id !== decisionUpdate.decisionId) {
            return decision;
        } else {
            return Object.assign({}, decision, {
                Options: decision.Options.map(option => {
                    if (option.Id !== decisionUpdate.optionId) {
                        return Object.assign({}, option, {
                            OverrideDiscount: option.OverrideDiscount || null
                        });
                    } else {
                        return Object.assign({}, option, {
                            OverrideDiscount: null
                        });
                    }
                })
            });
        }
    });

    updatedDecisions.forEach(decision => {
        newDecisionsMaps[decision.Id] = decision;
    });

    return newDecisionsMaps;
}

function updateDecisionOptionBriDiscountOverride(decisionsMap, decisionUpdate) {
    const newDecisionsMaps = {};

    const updatedDecisions = values(decisionsMap).map(decision => {
        if (decision.Id !== decisionUpdate.decisionId) {
            return decision;
        } else {
            return Object.assign({}, decision, {
                Options: decision.Options.map(option => {
                    if (option.Id !== decisionUpdate.optionId) {
                        return option;
                    } else {
                        return Object.assign({}, option, {
                            //we often begin with a lowercase for properties we add to a model in state that didn't come from api. In this case
                            //we need OfferingDecisionOptionInstances as a property to appear for options that have not been selected. This is something that
                            //api does not provide for its own reasons so we have to make it available here.
                            OfferingDecisionOptionInstances: getUpdatedOfferingDecisionOptionInstances(option, decisionUpdate.billerRuleConfigurationId, decisionUpdate.selectedDiscountsInfo)
                        });
                    }
                })
            });
        }
    });

    updatedDecisions.forEach(decision => {
        newDecisionsMaps[decision.Id] = decision;
    });

    return newDecisionsMaps;
}

function getUpdatedOfferingDecisionOptionInstances(option, billerRuleConfigurationId, selectedDiscountsForCurrentlyUpdatedBri) {
    let updatedOfferingDecisionOptionInstances = [];

    const currentBillerRuleInstanceThumbnail = option.BillerRuleInstanceThumbnails.find((billerRuleInstanceThumbnail) => {
        return billerRuleInstanceThumbnail.BillerRuleConfigurationId === billerRuleConfigurationId;
    });

    if (option.OfferingDecisionOptionInstances && option.OfferingDecisionOptionInstances.length && !isEmpty(option.OfferingDecisionOptionInstances[0])) {
        updatedOfferingDecisionOptionInstances = option.OfferingDecisionOptionInstances.map((optionInstance) => {
            return Object.assign({}, optionInstance, {
                SelectedDiscounts: getUpdatedSelectedDiscounts(currentBillerRuleInstanceThumbnail, selectedDiscountsForCurrentlyUpdatedBri, option)
            });
        });
    } else {
        const selectedDiscounts = getUpdatedSelectedDiscounts(currentBillerRuleInstanceThumbnail, selectedDiscountsForCurrentlyUpdatedBri, option);
        if (option.Quantity === 0) {
            updatedOfferingDecisionOptionInstances.push({
                SelectedDiscounts: selectedDiscounts,
                SubscriberProductId: null
            });
        } else {
            //Currently, API duplicates selectedDiscounts in each offering instance because the application does not yet officially support discounts per instance of an option.
            //I'm mimicking that here.
            for (let instanceIndex = 0; instanceIndex < option.Quantity; instanceIndex++) {
                updatedOfferingDecisionOptionInstances.push({
                    SelectedDiscounts: selectedDiscounts,
                    SubscriberProductId: null
                });
            }
        }
    }
    return updatedOfferingDecisionOptionInstances;
}

function getUpdatedSelectedDiscounts(currentBillerRuleInstanceThumbnails, selectedDiscountsForCurrentlyUpdatedBri, option) {
    const otherBillerRuleInstanceThumbnails = (option.BillerRuleInstanceThumbnails || []).filter((billerRuleInstanceThumbnail) => {
        return billerRuleInstanceThumbnail.BillerRuleConfigurationId !== currentBillerRuleInstanceThumbnails.BillerRuleConfigurationId;
    });
    const otherExistingSelectedDiscountsInOption = compose(
        flatten,
        pluck('selectedDiscountsInfo')
    )(otherBillerRuleInstanceThumbnails);

    return selectedDiscountsForCurrentlyUpdatedBri.concat(otherExistingSelectedDiscountsInOption);
}

function saveOptionPricing(decisionsMap, newOption) {
    const optionId = newOption.Id;
    const newDecisionsMaps = {};

    const updatedDecisions = values(decisionsMap).map(decision => {
        const optionIds = pluck('Id')(decision.Options);
        if (!contains(optionId, optionIds)) {
            return decision;
        } else {
            return Object.assign({}, decision, {
                Options: decision.Options.map(option => {
                    return option.Id !== optionId ? option : Object.assign({}, option, {
                        BillerRuleInstanceThumbnails: getBillerRuleInstanceThumbnailsWithCustomInformation(newOption),
                        isPlanOrServiceSwap: newOption.isPlanOrServiceSwap
                    });
                })
            });
        }
    });

    updatedDecisions.forEach(decision => {
        newDecisionsMaps[decision.Id] = decision;
    });

    return newDecisionsMaps;
}

function setInitialStateKeepOfferName(offerName) {
    return INITIAL_STATE.merge({
        offerName: offerName
    });
}

function populateDecisionsRequiringAffectedServicesResolution(currentDecisions, updatedDecisions) {
    const currentAndUpdatedDecisions = updatedDecisions.concat(Immutable.asMutable(currentDecisions));

    return compose(
        filter(propEq(false, 'resolved')),
        uniqWith(allPass([eqProps('decisionId'), eqProps('optionId')]))
    )(currentAndUpdatedDecisions);
}

const mergeAttributeFormValidationStatus = (payload, currentStatuses) => {
    const clonedStatuses = clone(currentStatuses);
    const currentStatus = clonedStatuses.find((status) => {
        return status.formName === payload.formName;
    });
    if (!currentStatus) {
        clonedStatuses.push(payload);
    } else {
        currentStatus.isValid = payload.isValid;
    }
    return clonedStatuses;
};

const parseSearchInventoriesAvailabilityPayload = (inventoryTypes, requestObject) => {
    return inventoryTypes.map((inventoryType) => {
        return Object.assign({}, inventoryType, {
            ContextId: requestObject.ContextId,
        }, {
            Available: inventoryType.MeetsRequestedQuantity,
            Quantity: inventoryType.RequestedQuantity
        });
    });
};

const serviceTaxCustomization = (serviceTaxCustomizationVal = []) => {
    const serviceTax = {};
    serviceTaxCustomizationVal.forEach((serviceTaxRule) => {
        if (serviceTax[serviceTaxRule.ServiceId]) {
            if (serviceTax[serviceTaxRule.ServiceId][serviceTaxRule.InstanceNumber]) {
                serviceTax[serviceTaxRule.ServiceId][serviceTaxRule.InstanceNumber][serviceTaxRule.PricingPlanId] = serviceTaxRule.ServiceTaxCustomization;
            } else {
                serviceTax[serviceTaxRule.ServiceId][serviceTaxRule.InstanceNumber] = {
                    [serviceTaxRule.PricingPlanId]: serviceTaxRule.ServiceTaxCustomization
                };
            }
        } else {
            serviceTax[serviceTaxRule.ServiceId] = {
                [serviceTaxRule.InstanceNumber]: {
                    [serviceTaxRule.PricingPlanId]: serviceTaxRule.ServiceTaxCustomization
                }
            };
        }
    });
    return serviceTax;
};