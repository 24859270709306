import {createSelector} from 'reselect';
import {
    BusinessUnitLandingPageWidgetsTemplateSelector,
    BusinessUnitHasTemplateSelector,
    BusinessUnitTemplateSelector
} from 'invision-core/src/components/configurable/configurable.selectors';

const EMPTY_ARRAY = [];

export const LandingPageTemplateSelector = createSelector([
    BusinessUnitHasTemplateSelector,
    BusinessUnitTemplateSelector
], (hasTemplate, businessUnitTemplate) => {
    return hasTemplate ? businessUnitTemplate.landingPageWidgets : null;  // TODO determine if we need a default
});

export const LandingPageWidgetsSelector = createSelector([
    LandingPageTemplateSelector,
    BusinessUnitLandingPageWidgetsTemplateSelector
], (landingPageTemplate, landingPageWidgets) => {
    if ((landingPageTemplate === null)
        || (landingPageTemplate === undefined)
        || (landingPageTemplate.length === 0)
        || (landingPageWidgets.length === 0)) {
        return EMPTY_ARRAY;
    }

    return landingPageTemplate.map((widgetId) => {
        return Object.values(landingPageWidgets).find((widget) => {
            return widget.Id === widgetId;
        });
    });
});
