import Immutable from 'seamless-immutable';
import {cloneDeep} from 'lodash';
import uuid from 'uuid/v4';
import {
    ADD_RATE_SHEET_ITEM,
    ADD_USAGE_RULE_ITEMS,
    MODIFIED_USAGE_RULE_ITEMS,
    RESET_BRI_DATA,
    RETRIEVE_GUIDANCE_ENTITIES,
    RETRIEVE_RATE_SHEET,
    RETRIEVE_USAGE_RULES_FOR_USAGE_RULE_GROUPS,
    SEARCH_FEES,
    RETRIEVE_TAX_CODES,
    SEARCH_PRICING_VECTORS,
    SEARCH_RATE_SHEET,
    SET_RATE_SHEET_DETAILS_TAB,
    SET_NEW_SHEET_ITEM,
    SET_NEW_USAGE_SHEET_ITEM,
    SET_PRICING_PLAN_ID,
    SET_SELECTED_FEE_ID,
    SET_UBRI_TO_USAGE_RULE_GROUP_MAP,
    SET_UPDATED_RATE_SHEET_ITEM,
    UPDATE_FEE,
    UPDATE_NEW_BRI_ITEMS,
    UPDATE_SUBSCRIBER_RATE_SHEET,
    UPDATE_CUSTOMER_RATE_SHEET_USAGE_RULES
} from './actions/rate.sheet.actions';
import {BILLER_RULE_INSTANCE_TYPE} from '../customercare.constants';

export const INITIAL_STATE = Immutable({
    addRateSheetItem: null,
    fees: [],
    isRetrievingFees: false,
    feeDetails: {
        selectedFeeId: null,
        glGuidanceCodes: [],
        isRetrievingGuidanceCodes: false,
        isRetrievingTaxCodes: false,
        isUpdatingFee: false,
        taxAssociationCodes: []
    },
    addedUsageRuleItems: [],
    editRateSheetResults: [],
    isRetrievingRateSheet: false,
    isSearchingPricingVectors: false,
    isUpdatingRateSheet: false,
    isUsageRuleGroupLoading: false,
    modifiedUsageRuleItems: [],
    newBriItems: {
        [BILLER_RULE_INSTANCE_TYPE.ENTITLEMENT]: {},
        [BILLER_RULE_INSTANCE_TYPE.RECURRING]: {},
        [BILLER_RULE_INSTANCE_TYPE.ONETIME]: {}
    },
    pricingVectors: null,
    rateSheetDetailsGoToTab: null,
    rateSheetResults: null,
    retrieveRateSheetError: null,
    searchRateSheetValue: null,
    selectedPricingPlanId: null,
    selectedUsageRuleId: null,
    selectedUsageRuleProductId: null,
    ubriToUsageRuleGroupMap: null,
    usageRuleGroupToUsageRuleMap: null,
    usageRulesToUsageRuleIdMap: null
});

export default (state=INITIAL_STATE, {payload={}, type}) => {
    switch (type) {
        case ADD_RATE_SHEET_ITEM:
            return state.set('addRateSheetItem', payload);
        case SEARCH_RATE_SHEET:
            return state.set('searchRateSheetValue', payload);
        case RETRIEVE_RATE_SHEET.BEGIN:
            return state.set('isRetrievingRateSheet', true);
        case SET_UPDATED_RATE_SHEET_ITEM:
            return state.set('updatedRateSheetResults', updatedItems(state.updatedRateSheetResults, payload));
        case SET_NEW_SHEET_ITEM:
            return state.setIn(['newBriItems', payload.ItemType], addOrUpdateBriItem(state.newBriItems[payload.ItemType], payload.addItems));
        case UPDATE_NEW_BRI_ITEMS:
            return state.setIn(['newBriItems', payload.ItemType], payload.items);
        case SET_NEW_USAGE_SHEET_ITEM:
            return state.setIn(['newUsageBriItem'], addOrUpdateBriItem(state.newUsageBriItem, payload));
        case RESET_BRI_DATA:
            return state
                .set(['newBriItems'], INITIAL_STATE.newBriItems)
                .set(['newUsageBriItem'], null)
                .set(['updatedRateSheetResults'], null);
        case RETRIEVE_RATE_SHEET.SUCCESS:
            return state
                .set('isRetrievingRateSheet', false)
                .set('editRateSheetResults', payload.Items?.map((item) => {
                    const clonedItem = Object.assign({}, item, {
                        uuid: uuid()
                    }
                    );
                    return clonedItem;
                }))
                .set('hierarchyRootNodeId', payload.HierarchyRootNodeId)
                .set('rateSheetResults', payload.Items || []);
        case RETRIEVE_RATE_SHEET.FAILURE:
            return state.set('isRetrievingRateSheet', false)
                .set('retrieveRateSheetError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case UPDATE_SUBSCRIBER_RATE_SHEET.BEGIN:
            return state.set('isUpdatingRateSheet', true);
        case UPDATE_SUBSCRIBER_RATE_SHEET.SUCCESS:
            return state.set('isUpdatingRateSheet', false);
        case UPDATE_SUBSCRIBER_RATE_SHEET.FAILURE:
            return state.set('isUpdatingRateSheet', false);
        case SEARCH_FEES.BEGIN:
            return state
                .set('isRetrievingFees', true);
        case SEARCH_FEES.SUCCESS:
            return state
                .set('isRetrievingFees', false)
                .set('fees', payload.Fees || []);
        case SEARCH_FEES.FAILURE:
            return state.set('isRetrievingFees', false);
        case UPDATE_FEE.BEGIN:
            return state.setIn(['feeDetails', 'isUpdatingFee'], true);
        case UPDATE_FEE.SUCCESS:
            return state.setIn(['feeDetails', 'isUpdatingFee'], false);
        case UPDATE_FEE.FAILURE:
            return state.setIn(['feeDetails', 'isUpdatingFee'], false);
        case RETRIEVE_TAX_CODES.BEGIN:
            return state.setIn(['feeDetails', 'isRetrievingTaxCodes'], true);
        case RETRIEVE_TAX_CODES.SUCCESS:
            return state.setIn(['feeDetails', 'taxAssociationCodes'], payload.TaxAssociations || [])
                .setIn(['feeDetails', 'isRetrievingTaxCodes'], false);
        case RETRIEVE_TAX_CODES.FAILURE:
            return state.setIn(['feeDetails', 'isRetrievingTaxCodes'], false);
        case RETRIEVE_GUIDANCE_ENTITIES.BEGIN:
            return state.setIn(['feeDetails', 'isRetrievingGuidanceCodes'], true);
        case RETRIEVE_GUIDANCE_ENTITIES.SUCCESS:
            return state.setIn(['feeDetails', 'glGuidanceCodes'], payload.GLGuidanceEntities || [])
                .setIn(['feeDetails', 'isRetrievingGuidanceCodes'], false);
        case RETRIEVE_GUIDANCE_ENTITIES.FAILURE:
            return state.setIn(['feeDetails', 'isRetrievingGuidanceCodes'], false);
        case SET_PRICING_PLAN_ID:
            return state.set('selectedPricingPlanId', payload);
        case SET_SELECTED_FEE_ID:
            return state.setIn(['feeDetails', 'selectedFeeId'], payload);
        case UPDATE_CUSTOMER_RATE_SHEET_USAGE_RULES:
            return state.set('editRateSheetResults', payload);
        case ADD_USAGE_RULE_ITEMS:
            return state.set('addedUsageRuleItems', payload);
        case MODIFIED_USAGE_RULE_ITEMS:
            return state.set('modifiedUsageRuleItems', payload);
        case SEARCH_PRICING_VECTORS.BEGIN:
            return state.set('isSearchingPricingVectors', true);
        case SEARCH_PRICING_VECTORS.SUCCESS:
            return state
                .set('isSearchingPricingVectors', false)
                .set('pricingVectors', payload.PricingVectors || []);
        case SEARCH_PRICING_VECTORS.FAILURE:
            return state
                .set('isSearchingPricingVectors', false);
        case SET_RATE_SHEET_DETAILS_TAB:
            return state.set('rateSheetDetailsGoToTab', payload);
        case SET_UBRI_TO_USAGE_RULE_GROUP_MAP:
            return state.set('ubriToUsageRuleGroupMap', payload);
        case RETRIEVE_USAGE_RULES_FOR_USAGE_RULE_GROUPS.BEGIN:
            return state.set('isUsageRuleGroupLoading', true);
        case RETRIEVE_USAGE_RULES_FOR_USAGE_RULE_GROUPS.SUCCESS:
            return state
                .set('usageRuleGroupToUsageRuleMap', getUsageRuleGroupToUsageRuleMap(payload.UsageRules || []))
                .set('usageRulesToUsageRuleIdMap', getusageRulesToUsageRuleIdMap(payload.UsageRules || []))
                .set('usageRuleAvailableColsById', getColsForUsageRule(payload.UsageRules || []))
                .set('isUsageRuleGroupLoading', false);
        case RETRIEVE_USAGE_RULES_FOR_USAGE_RULE_GROUPS.FAILURE:
            return state
                .set('isUsageRuleGroupLoading', false);
        default:
            return state;
    }
};

function addOrUpdateBriItem(stateBriItem, payloadItem) {
    return stateBriItem && Object.keys(stateBriItem).length ? {
        ...stateBriItem,
        ...payloadItem
    } : payloadItem;
}

function updatedItems(existingItems, newItems) {
    let result = cloneDeep(existingItems);
    if (result) {
        newItems.forEach(newItem => {
            const matchingItem = result.find(existingItem => {
                return existingItem.id === newItem.id &&
                    existingItem.BillerRuleConfigurationIdentifier.Value === newItem.BillerRuleConfigurationIdentifier.Value;
            });
            if (matchingItem) {
                result = result.map(existingItem => {
                    return existingItem.id === newItem.id &&
                    existingItem.BillerRuleConfigurationIdentifier.Value === newItem.BillerRuleConfigurationIdentifier.Value ? newItem : existingItem;
                });
            } else {
                result.push(newItem);
            }
        });
    } else {
        result = [...newItems];
    }
    return result;
}

function getUsageRuleGroupToUsageRuleMap(usageRules) {
    const usageRuleGroupsToUsageRulesMap = {};
    usageRules.forEach((usageRule) => {
        (usageRule.UsageRuleGroupExternalReferences || []).forEach((groupExternalRef) => {
            if (!usageRuleGroupsToUsageRulesMap[groupExternalRef]) {
                usageRuleGroupsToUsageRulesMap[groupExternalRef] = [usageRule.Id];
            } else {
                usageRuleGroupsToUsageRulesMap[groupExternalRef] = [...usageRuleGroupsToUsageRulesMap[groupExternalRef], usageRule.Id];
            }
        });
    });
    return usageRuleGroupsToUsageRulesMap;
}

function getusageRulesToUsageRuleIdMap(usageRules) {
    const UsageRuleToUsageRuleIdMap = {};
    usageRules.forEach((usageRule) => {
        UsageRuleToUsageRuleIdMap[usageRule.Id] =  usageRule;
    });
    return UsageRuleToUsageRuleIdMap;
}

const getColsForUsageRule = (payloadUsageRules) => {
    const usageRulesCols = {};
    payloadUsageRules.forEach((usageRule) => {
        usageRulesCols[usageRule.Id] = usageRule.UsageRates.reduce((usageColHashMap, usageRate) => {
            if (!usageColHashMap[usageRate.TimeBandCode]) {
                usageColHashMap[usageRate.TimeBandCode] = true;
            }
            return usageColHashMap;
        }, {});
    });
    return usageRulesCols;
};