import {ThunkHelper} from 'invision-core';
export const GET_WORKFLOW_ACTIVITY_INSTANCE_STATUS = {
    BEGIN: 'GET_WORKFLOW_ACTIVITY_INSTANCE_BEGIN',
    SUCCESS: 'GET_WORKFLOW_ACTIVITY_INSTANCE_SUCCESS',
    FAILURE: 'GET_WORKFLOW_ACTIVITY_INSTANCE_FAILURE'
};
const GET_WORKFLOW_ACTIVITY_INSTANCE_EVENTS = [
    GET_WORKFLOW_ACTIVITY_INSTANCE_STATUS.BEGIN,
    GET_WORKFLOW_ACTIVITY_INSTANCE_STATUS.SUCCESS,
    GET_WORKFLOW_ACTIVITY_INSTANCE_STATUS.FAILURE
];

export const createWorkflowActivityInstancePromise = (dispatch, workflowInstanceIds = []) => {
    return ThunkHelper(dispatch, GET_WORKFLOW_ACTIVITY_INSTANCE_EVENTS, {
        method: 'post',
        url: 'Workflow/RetrieveWorkflowInstances',
        data: {
            WorkflowInstanceIds: workflowInstanceIds
        },
    }, {
        workflowInstanceIds
    });
};

export const getWorkflowActivityInstance = (workflowInstanceIds) => {
    return (dispatch) => {
        return createWorkflowActivityInstancePromise(dispatch, workflowInstanceIds);
    };
};
