import Immutable from 'seamless-immutable';
// import pathOr from 'ramda/src/pathOr';
import clone from 'ramda/src/clone';
import omit from 'ramda/src/omit';
import NavigatorActions from 'invision-core/src/components/navigator/navigator.actions';
import {
    // CALCULATE_ADD_OFFER_ORDER_QUOTE,
    GO_TO_NEXT_STEP,
    GO_TO_PREVIOUS_STEP,
    GO_TO_STEP,
    // INITIALIZE_AS_MULTI_OFFER_WIZARD,
    // MULTI_OFFER_SET_COMPLETED_STEPS,
    // RESET_FOR_CONTINUE_SHOPPING,
    // RESET_MULTI_OFFERS,
    RESTORE_OPTION_DEFAULT_PRICING,
    // RESUME_ORDER_ATTRIBUTES,
    // RESUME_ORDER_SET_SELECTED_OFFER_ID,
    // RESUME_STORE_ORDER,
    // RETRIEVE_ADDRESSES,
    // RETRIEVE_SHOPPING_CART_OFFERINGS,
    // SEND_PORT_IN_OR_ITEM_RESERVATION,
    // SET_ATTRIBUTE_SEARCHING_INVENTORY,
    // SET_CHANGE_OFFERING_INSTANCE_ID,
    // SET_CHANGE_OFFERING_ID,
    SET_ADD_SERVICES_ACTIVE_FORM_VALIDATION_STATUS,
    SET_ADD_SERVICE_ATTRIBUTE_FORM_SUBMITTED,
    SET_CURRENT_STEP_IS_INVALID,
    SET_DECISIONS_TOUCHED,
    // SET_DOWN_PAYMENT_ON_MULTI_OFFER_SHOPPING_CART,
    // SET_EDIT_ATTRIBUTE_GROUPS,
    // SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE,
    // SET_EDIT_CART_ADDITIONAL_PROPERTY,
    // SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS,
    // SET_IS_RESUMING_ORDER,
    // SET_IS_RESUMING_STORE_ORDER,
    // SET_MULTI_OFFER_SHOPPING_CART,
    SET_EDIT_OFFER_ADDITIONAL_PROPERTY_ON_CHANGE,
    SET_EDIT_OFFER_ADDITIONAL_PROPERTY,
    SET_EDIT_OPTION,
    SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE,
    SET_EDIT_ORDER_ADDITIONAL_PROPERTY,
    SET_PAYMENT_INFO,
    // SET_REMOVE_OFFER_INSTANCE_ID,
    // SET_SELECTED_ADD_OFFER_TAB,
    SET_SELECTED_FACETS_EDIT_COPY,
    SET_SELECTED_OFFER,
    SET_SELECTED_OFFER_EDIT_COPY,
    SET_SELECTED_OFFERING_CHARGE_TYPES_EDIT_COPY,
    // SET_SELECTED_OFFERING_INSTANCE_ID,
    // SET_TRANSFER_FROM_NAME,
    // SET_TRANSFER_FROM_SUBSCRIBER_ID,
    UPDATE_BILL_CYCLE,
    UPDATE_EDIT_BRI_PRICE,
    // UPDATE_INVENTORY_SELECTION,
    // UPDATE_OFFER_ATTRIBUTE,
    // UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL,
    // UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE,
    UPDATE_PAYMENT_INFO,
    // UPDATE_PORT_IN_REQUEST,
    UPDATE_SELECTED_SERVICE_FEATURES,
    // UPDATE_SHOPPING_CART
    // RESET_PICKUP_ID
    SET_SELECTED_SERVICE_TEMPLATE_ID,
    ADD_NEW_SERVICE_TEMPLATE,
    REMOVE_SERVICE_TEMPLATES,
    UPDATE_SERVICE_TEMPLATE,
    UPDATE_GROUP_TEMPLATE,
    SET_GROUP_TEMPLATE_CONFIGURED,
    OVERRIDE_GROUP_TEMPLATE,
    SUBMIT_ADD_SERVICES_ORDER,
} from './actions/add.services.wizard.actions';
import {
    // CALCULATE_OFFERING_ORDER_QUOTE,
    // CLEAR_SUMMARY_QUOTE,
    // REPLACE_MULTI_OFFER_SHOPPING_CART,
    RETRIEVE_ATTRIBUTES,
    // SET_ACTIVE_FORM_VALIDATION_STATUS,
    // RETRIEVE_ORDER_QUOTE,
    // SEARCH_INVENTORY,
    // SET_DELIVERY_DECISION,
    // SET_OFF_CYCLE_ALIGNMENT_SUBSCRIBER_PRODUCT_DATA,
    // SET_SERVICE_TAX_LOCATION_DECISION,
    // UPDATE_MULTI_OFFER_SHOPPING_CART
} from './actions/offering.order.actions';
import {
    ALL_STEPS_KEYS,
    GROUP_TEMPLATE_ID
} from './constants/add.services.wizard.constants';
import createWizardReducers from './helpers/wizard.reducer.helper';
import createOfferOrderingReducers from './helpers/offer.ordering.wizard.reducer.helper';

// import {INITIAL_STATE as CREATE_CUSTOMER_INITIAL_STATE} from './create.customer.reducer';
// import {prepareSubscriptionQuotes} from './products.order.reducer.helper';

import {
    mapAdditionalProperty,
    setEditAp
} from './helpers/customer.helper';

const wizardReducers = createWizardReducers(ALL_STEPS_KEYS);
const offerOrderingReducers = createOfferOrderingReducers();
const EMPTY_ARRAY = Immutable([]);
const EMPTY_OBJECT = Immutable({});

export const DEFAULT_CART = {
    // billCycle: null,
    // customer: null,
    // customerInfo: CREATE_CUSTOMER_INITIAL_STATE,
    selectedOfferId: null,
    selectedFacetIds: EMPTY_ARRAY,
    selectedOfferingChargeTypeIds: EMPTY_ARRAY,
    // serviceAddress: null,
    // editAttributeGroups: null,
    // editPhysicalAttributeGroups: null,
    selectedEditOption: null,
    editOptions: null,
    // isSavingOrder: false,
    // paymentInfo: null,
    // quote: null,
    // subscriptionOrderQuote: null,
    serviceAttributeFields: EMPTY_ARRAY,
    selectedServiceFeatures: EMPTY_ARRAY,
    selectedServiceTemplateId: GROUP_TEMPLATE_ID,
    groupTemplate: {
        Id: GROUP_TEMPLATE_ID,
        title: 'Order Settings',
        readOnly: true,
        attributes: EMPTY_OBJECT
    },
    isGroupTemplateConfigured: false,
    serviceTemplateMap: EMPTY_OBJECT,
    isSubmittingOrder: false,
    lastAttemptError: null,
    createdOrder: null
};

// const INITIAL_SUBSCRIBER_PHYSICAL_INVENTORY_PICKUP_DETAIL = Immutable({
//     comments: null,
//     date: null,
//     id: null
// });

// const INITIAL_MULTI_OFFER = Immutable({
//     initializeAsMultiOfferWizard: false,
//     isRetrievingCart: false,
//     removeOfferingInstanceId: null,
//     selectedOfferingInstanceId: null,
//     shoppingCart: EMPTY_OBJECT,
//     offCycleAlignmentSubscriberProductData: {}
// });

export const INITIAL_STATE = wizardReducers.initialState.merge(Immutable({
    // asyncId: null,
    attributesFormSubmitted: false,
    attributesValidationStatuses: EMPTY_OBJECT,
    cart: DEFAULT_CART,
    // cartAdditionalProperties: EMPTY_ARRAY,
    // changeOfferingId: null,
    // changeOfferingInstanceId: null,
    // data: {
    //     quoteCalculated: false,
    //     subTotal: 0,
    //     taxAmount: 0,
    //     totalAmount: 0
    // },
    editCart: DEFAULT_CART,
    // isCalculatingQuote: false,
    isDecisionsTouched: false,
    // isResumingOrder: false,
    // isResumingStoreOrder: false,
    // multiOffer: INITIAL_MULTI_OFFER,
    offerAdditionalProperties: EMPTY_ARRAY,
    orderAdditionalProperties: EMPTY_ARRAY,
    // quote: null,
    // serviceAddress: null,
    // subscriberPhysicalInventoryPickupDetail: INITIAL_SUBSCRIBER_PHYSICAL_INVENTORY_PICKUP_DETAIL,
    // transferSubscriberId: null,
    // transferFromName: ''
}));

export default function wizardReducer(state = INITIAL_STATE, {payload, type, requestObject}) {
    // let orderQuoteTotals = {};
    // if (type === CALCULATE_ADD_OFFER_ORDER_QUOTE.SUCCESS || type === RETRIEVE_ORDER_QUOTE.SUCCESS) {
    //     orderQuoteTotals = offerOrderingReducers.setOrderQuote(payload);
    // }
    switch (type) {
        // case CALCULATE_ADD_OFFER_ORDER_QUOTE.BEGIN:
        //     return state.set('isCalculatingQuote', true);
        // case RETRIEVE_ORDER_QUOTE.SUCCESS:
        // case CALCULATE_ADD_OFFER_ORDER_QUOTE.SUCCESS:
        //     return state
        //         .set('subscriptionOrderQuote', payload.SubscriptionQuotes && prepareSubscriptionQuotes(payload.SubscriptionQuotes))
        //         .set('quote', payload.Quote)
        //         .set('financeCreditLimitValidation', payload.FinanceCreditLimitValidation)
        //         .set('isCalculatingQuote', false)
        //         .setIn(['data', 'subTotal'], orderQuoteTotals.SubTotalAmount || 0)
        //         .setIn(['data', 'taxAmount'], orderQuoteTotals.TaxAmount || 0)
        //         .setIn(['data', 'totalAmount'], orderQuoteTotals.TotalAmount || 0)
        //         .setIn(['data', 'shippingAmount'], orderQuoteTotals.ShippingAmount || 0)
        //         .setIn(['data', 'shippingAddress'], payload.ShippingAddress)
        //         .setIn(['data', 'quoteCalculated'], requestObject.calculateTaxesInQuote)
        //         .set('asyncId', payload.QuoteId || null);
        // case CALCULATE_OFFERING_ORDER_QUOTE.SUCCESS:
        //     return state.set('asyncId', payload.OfferingOrderQuote && payload.OfferingOrderQuote.QuoteId || null);
        // case CALCULATE_ADD_OFFER_ORDER_QUOTE.FAILURE:
        //     return state.set('isCalculatingQuote', false);
        // case CLEAR_SUMMARY_QUOTE:
        //     return state
        //         .setIn(['data', 'quoteCalculated'], INITIAL_STATE.data.quoteCalculated)
        //         .setIn(['data', 'taxAmount'], INITIAL_STATE.data.taxAmount)
        //         .setIn(['data', 'totalAmount'], INITIAL_STATE.data.totalAmount);
        case GO_TO_NEXT_STEP:
            return wizardReducers.goToNextStep(state, payload)
                .setIn(['editCart', 'selectedFacetIds'], DEFAULT_CART.selectedFacetIds)
                .setIn(['editCart', 'selectedOfferingChargeTypeIds'], DEFAULT_CART.selectedOfferingChargeTypeIds);
        case GO_TO_PREVIOUS_STEP:
            return wizardReducers.goToPreviousStep(state, payload)
                // .set('subscriptionOrderQuote', DEFAULT_CART.subscriptionOrderQuote)
                // .set('quote', DEFAULT_CART.quote)
                .setIn(['editCart', 'selectedFacetIds'], DEFAULT_CART.selectedFacetIds)
                .setIn(['editCart', 'selectedOfferingChargeTypeIds'], DEFAULT_CART.selectedOfferingChargeTypeIds);
        case GO_TO_STEP:
            return wizardReducers.goToStep(state, payload)
                .setIn(['editCart', 'selectedFacetIds'], DEFAULT_CART.selectedFacetIds)
                .setIn(['editCart', 'selectedOfferingChargeTypeIds'], DEFAULT_CART.selectedOfferingChargeTypeIds);
        case SET_ADD_SERVICE_ATTRIBUTE_FORM_SUBMITTED:
            return state.setIn(['attributesFormSubmitted'], payload);
        // case INITIALIZE_AS_MULTI_OFFER_WIZARD:
        //     return state.setIn(['multiOffer', 'initializeAsMultiOfferWizard'], payload);
        // case SET_TRANSFER_FROM_SUBSCRIBER_ID:
        //     return state.set('transferFromSubscriberId', payload);
        // case SET_TRANSFER_FROM_NAME:
        //     return state.set('transferFromName', payload);
        // case MULTI_OFFER_SET_COMPLETED_STEPS:
        //     return state.set('completedSteps', {
        //         [ALL_STEPS_KEYS[0]]: ALL_STEPS_KEYS[0],
        //         [ALL_STEPS_KEYS[1]]: ALL_STEPS_KEYS[1],
        //         [ALL_STEPS_KEYS[2]]: ALL_STEPS_KEYS[2]
        //     }).set('skippedSteps', EMPTY_ARRAY);
        // case SET_IS_RESUMING_ORDER:
        //     return state
        //         .set(['isResumingOrder'], payload);
        // case SET_IS_RESUMING_STORE_ORDER:
        //     return state.set(['isResumingStoreOrder'], payload);
        // case RESUME_ORDER_ATTRIBUTES.SUCCESS:
        //     return state
        //         .setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.transformAttributeGroups(offerOrderingReducers.setEditAttributeGroups(payload.Context.ValueDecisions, pathOr(null, ['editCart', 'editAttributeGroups'], state))))
        //         .setIn(['editCart', 'deliveryDecisions'], payload.Context.DeliveryDecisions);
        // case RESUME_STORE_ORDER.SUCCESS:
        //     return state
        //         .setIn(['subscriberPhysicalInventoryPickupDetail', 'comments'],  pathOr(null, ['ShoppingCart', 'SubscriberPhysicalInventoryPickupDetail', 'Comments'], payload))
        //         .setIn(['subscriberPhysicalInventoryPickupDetail', 'date'],  pathOr(null, ['ShoppingCart', 'SubscriberPhysicalInventoryPickupDetail', 'PickupDate'], payload))
        //         .setIn(['subscriberPhysicalInventoryPickupDetail', 'id'],  pathOr(null, ['ShoppingCart', 'SubscriberPhysicalInventoryPickupDetail', 'Id'], payload))
        //         .setIn(['cartAdditionalProperties'], pathOr(EMPTY_ARRAY, ['ShoppingCart', 'AdditionalProperties'], payload));
        // case RESET_PICKUP_ID:
        //     return state.setIn(['subscriberPhysicalInventoryPickupDetail', 'id'], null);
        case SET_ADD_SERVICES_ACTIVE_FORM_VALIDATION_STATUS:
            return state.setIn(['attributesValidationStatuses'], mergeAttributeFormValidationStatus(payload, state.attributesValidationStatuses));
        case RETRIEVE_ATTRIBUTES.SUCCESS:
            return state
                .setIn(['editCart', 'serviceAttributeFields'], payload.Context.ValueDecisions)
                .setIn(['editCart', 'serviceTaxDecisions'], payload.Context.ServiceTaxCustomizationDecisions);
        // .setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.setEditAttributeGroups(payload.Context.ValueDecisions, pathOr(null, ['editCart', 'editAttributeGroups'], state)));
        // .setIn(['editCart', 'deliveryDecisions'], payload.Context.DeliveryDecisions)
        // case RETRIEVE_ADDRESSES.SUCCESS:
        //     return offerOrderingReducers.setServiceAddress(state, payload);
        // case RETRIEVE_SHOPPING_CART_OFFERINGS.BEGIN:
        //     return state.setIn(['multiOffer', 'isRetrievingCart'], true);
        // case RETRIEVE_SHOPPING_CART_OFFERINGS.SUCCESS:
        //     return state
        //         .setIn(['multiOffer', 'isRetrievingCart'], false)
        //         .setIn(['multiOffer', 'shoppingCart'], payload.ShoppingCart)
        //         .setIn(['cartAdditionalProperties'], pathOr(EMPTY_ARRAY, ['ShoppingCart', 'AdditionalProperties'], payload));
        // case RETRIEVE_SHOPPING_CART_OFFERINGS.FAILURE:
        //     return state.setIn(['multiOffer', 'isRetrievingCart'], false);
        // case UPDATE_MULTI_OFFER_SHOPPING_CART.SUCCESS:
        //     return state
        //         .setIn(['multiOffer', 'selectedOfferingInstanceId'], setAddedOfferInstanceId(state.multiOffer.selectedOfferingInstanceId, payload.ShoppingCart.Items[0].OfferingInstanceId))
        //         .setIn(['multiOffer', 'shoppingCart', 'Items'], appendAddedOfferItems(state.multiOffer.shoppingCart.Items, !!state.multiOffer.selectedOfferingInstanceId, payload.ShoppingCart.Items));
        // case REPLACE_MULTI_OFFER_SHOPPING_CART.SUCCESS:
        //     return state
        //         .setIn(['multiOffer', 'shoppingCart', 'Items'], payload.ShoppingCart.Items);
        // case SET_OFF_CYCLE_ALIGNMENT_SUBSCRIBER_PRODUCT_DATA:
        //     return state
        //         .setIn(['multiOffer', 'offCycleAlignmentSubscriberProductData'], payload);
        // case SET_CHANGE_OFFERING_INSTANCE_ID:
        //     return state.set(['changeOfferingInstanceId'], payload);
        // case SET_CHANGE_OFFERING_ID:
        //     return state.set(['changeOfferingId'], payload);
        // case SET_DELIVERY_DECISION:
        //     return state
        //         .setIn(['editCart', 'deliveryDecisions'], offerOrderingReducers.updateDeliveryDecision(state, payload));
        // case SET_SERVICE_TAX_LOCATION_DECISION:
        //     return state
        //         .setIn(['editCart', 'serviceTaxDecisions'], offerOrderingReducers.updateserviceTaxLocationDecision(state, payload));
        // case SET_REMOVE_OFFER_INSTANCE_ID:
        //     return state.setIn(['multiOffer', 'removeOfferingInstanceId'], payload);
        // case SET_MULTI_OFFER_SHOPPING_CART:
        //     return state.setIn(['multiOffer', 'shoppingCart'], payload);
        // case SEARCH_INVENTORY.SUCCESS:
        //     return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.updateAttributeInventoryOptions(state.editCart.editAttributeGroups, payload.InventoryItems));
        // case SEARCH_INVENTORY.FAILURE:
        //     return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.clearAttributeInventorySearching(state.editCart.editAttributeGroups));
        // case SET_ATTRIBUTE_SEARCHING_INVENTORY:
        //     return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.setAttributeInventorySearching(state.editCart.editAttributeGroups, payload));
        // case UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL:
        //     return state.setIn(['editCart', 'editPhysicalAttributeGroups'], offerOrderingReducers.updatePhysicalInventoryMakeAndModel(state.editCart.editPhysicalAttributeGroups, payload));
        // case UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE:
        //     return state.setIn(['editCart', 'editPhysicalAttributeGroups'], offerOrderingReducers.updatePhysicalInventoryTypeAttribute(state.editCart.editPhysicalAttributeGroups, payload));
        // case SET_EDIT_ATTRIBUTE_GROUPS:
        //     return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.transformAttributeGroups(payload));
        // case SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS:
        //     return state.setIn(['editCart', 'editPhysicalAttributeGroups'], !payload.formPhysicalAttributeGroups ? payload.physicalAttributeGroups : offerOrderingReducers.mergePhysicalAttributeGroups(payload.physicalAttributeGroups, payload.formPhysicalAttributeGroups));
        case SET_CURRENT_STEP_IS_INVALID:
            return wizardReducers.setCurrentStepIsInvalid(state, payload);
        case SET_DECISIONS_TOUCHED:
            return state.setIn(['isDecisionsTouched'], payload);
        // case SET_DOWN_PAYMENT_ON_MULTI_OFFER_SHOPPING_CART:
        // {
        //     const itemIndex = payload.id ? getItemIndex(payload.id, state.multiOffer.shoppingCart.Items) : payload.itemIndex;
        //     return state
        //         .setIn(['multiOffer', 'shoppingCart', 'Items', itemIndex, 'downPayment'], payload.downPayment)
        //         .setIn(['multiOffer', 'shoppingCart', 'Items', itemIndex, 'DownPaymentAmount'],
        //             payload.isFullPriceDownPaymentSelected ? payload.financedFullAmount : payload.downPayment)
        //         .setIn(['multiOffer', 'shoppingCart', 'Items', itemIndex, 'isFullPriceDownPaymentSelected'],
        //             payload.isFullPriceDownPaymentSelected);
        // }
        case SET_PAYMENT_INFO:
            return offerOrderingReducers.setPaymentInfo(state, payload);
        // case SET_SELECTED_ADD_OFFER_TAB:
        //     return wizardReducers.setSelectedOrderTab(state, payload);
        case SET_SELECTED_FACETS_EDIT_COPY:
            return offerOrderingReducers.setSelectedFacetsEditCopy(state, payload);
        case SET_EDIT_OPTION:
            return offerOrderingReducers.setEditOption(state, payload);
        case RESTORE_OPTION_DEFAULT_PRICING:
            return offerOrderingReducers.restoreDefaultOptionPricing(state, payload);
        case UPDATE_EDIT_BRI_PRICE:
            return offerOrderingReducers.updateEditOptionPricing(state, payload);
        case SET_SELECTED_OFFER: // TODO: Consider deleting this case, since no action relevant to Add Offer Wizard fires this event.
        {
            const nextState = offerOrderingReducers.clearStateFromOfferSelection(state, payload, DEFAULT_CART);
            return nextState
                .setIn(['cart', 'selectedOfferId'], payload)
                .set('editCart', DEFAULT_CART); // .setIn(['editCart', 'paymentInfo'], state.cart.paymentInfo);
        }
        // case RESET_FOR_CONTINUE_SHOPPING:
        //     return resetWizardForContinueShopping(
        //         state.multiOffer,
        //         state.serviceAddress,
        //         state.subscriberPhysicalInventoryPickupDetail,
        //         state.transferFromName,
        //         state.transferFromSubscriberId,
        //         state.cartAdditionalProperties);
        // case RESET_MULTI_OFFERS:
        //     return state.set('multiOffer', INITIAL_MULTI_OFFER);
        // case RESUME_ORDER_SET_SELECTED_OFFER_ID:
        //     return state.setIn(['cart', 'selectedOfferId'], payload);
        // case SEND_PORT_IN_OR_ITEM_RESERVATION:
        //     return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.sendPortInOrItemReservation(state.editCart.editAttributeGroups, payload.attributeId, payload.sendPortInReservation));
        case SET_SELECTED_OFFER_EDIT_COPY:
        {
            const nextState = offerOrderingReducers.clearStateFromOfferSelection(state, payload, DEFAULT_CART);
            return nextState.setIn(['editCart', 'selectedOfferId'], payload);
        }
        case SET_SELECTED_OFFERING_CHARGE_TYPES_EDIT_COPY:
            return offerOrderingReducers.setSelectedOfferingChargeTypesEditCopy(state, payload);
        // case SET_SELECTED_OFFERING_INSTANCE_ID:
        //     return state.setIn(['multiOffer', 'selectedOfferingInstanceId'], payload);
        case UPDATE_BILL_CYCLE:
            return offerOrderingReducers.updateBillCycle(state, payload);
        // case UPDATE_INVENTORY_SELECTION:
        //     return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.updateInventorySelection(state.editCart.editAttributeGroups, payload));
        // case UPDATE_OFFER_ATTRIBUTE:
        //     return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.updateAttributeGroups(state.editCart.editAttributeGroups, payload.attributeId, payload.formValue));
        case UPDATE_PAYMENT_INFO:
            return offerOrderingReducers.updatePaymentInfo(state, payload);
        // case UPDATE_PORT_IN_REQUEST:
        //     return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.updateAttributePortInRequest(
        //         state.editCart.editAttributeGroups,
        //         payload.attributeId,
        //         payload.portInData,
        //         payload.token,
        //         payload.address));
        case UPDATE_SELECTED_SERVICE_FEATURES:
            return state.setIn(['editCart', 'selectedServiceFeatures'], payload);
        // case UPDATE_SHOPPING_CART.BEGIN:
        //     return state.setIn(['editCart', 'isSavingOrder'], true);
        // case UPDATE_SHOPPING_CART.SUCCESS:
        // case UPDATE_SHOPPING_CART.FAILURE:
        //     return state
        //         .setIn(['editCart', 'isSavingOrder'], false);
        case NavigatorActions.STATE_CHANGE_SUCCESS:
            return INITIAL_STATE;
            // return setInitialState(
            //     state.isResumingOrder,
            //     state.isResumingStoreOrder,
            //     state.multiOffer.initializeAsMultiOfferWizard,
            //     state.transferFromName,
            //     state.changeOfferingInstanceId,
            //     state.changeOfferingId
            // );
        // case SET_EDIT_CART_ADDITIONAL_PROPERTY:
        //     return state.setIn(['cartAdditionalProperties'], mapAdditionalProperty(payload));
        // case SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE:
        //     return state.setIn(['cartAdditionalProperties'], setEditAp(payload, state.cartAdditionalProperties || EMPTY_ARRAY));
        case SET_EDIT_OFFER_ADDITIONAL_PROPERTY:
            return state.set('offerAdditionalProperties', mapAdditionalProperty(payload));
        case SET_EDIT_OFFER_ADDITIONAL_PROPERTY_ON_CHANGE:
            return state.set('offerAdditionalProperties', setEditAp(payload, state.offerAdditionalProperties || EMPTY_ARRAY));
        case SET_EDIT_ORDER_ADDITIONAL_PROPERTY:
            return state.set('orderAdditionalProperties', mapAdditionalProperty(payload));
        case SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE:
            return state.set('orderAdditionalProperties', setEditAp(payload, state.orderAdditionalProperties || EMPTY_ARRAY));
        case SET_SELECTED_SERVICE_TEMPLATE_ID:
            return state.setIn(['editCart', 'selectedServiceTemplateId'], payload);
        case ADD_NEW_SERVICE_TEMPLATE:
            return state.setIn(['editCart', 'serviceTemplateMap', payload], getNewServiceTemplate(payload));
        case UPDATE_SERVICE_TEMPLATE:
        {
            if (payload.serviceAttributeId) {
                if (!payload.serviceAttributeValue) {
                    return state.setIn(
                        ['editCart', 'serviceTemplateMap', payload.serviceTemplateId, 'attributes'],
                        removeServiceAttribute(payload.serviceAttributeId, state.editCart.serviceTemplateMap[payload.serviceTemplateId].attributes));
                } else {
                    return state.setIn(['editCart', 'serviceTemplateMap', payload.serviceTemplateId, 'attributes', payload.serviceAttributeId], payload.serviceAttributeValue);
                }
            }
            if (payload.taxLocation !== undefined) {
                return state.setIn(['editCart', 'serviceTemplateMap', payload.serviceTemplateId, 'taxLocation'], payload.taxLocation);
            }
            return state;
        }
        case UPDATE_GROUP_TEMPLATE:
            return getUpdatedGroupTemplateState(state, payload);
        case OVERRIDE_GROUP_TEMPLATE:
            return getUpdatedGroupTemplateState(state, payload)
                .setIn(['editCart', 'serviceTemplateMap'], removeServiceAttributeFromAllServices(clone(state.editCart.serviceTemplateMap), payload.serviceAttributeId));
        case REMOVE_SERVICE_TEMPLATES:
            return state
                .setIn(['editCart', 'selectedServiceTemplateId'], GROUP_TEMPLATE_ID)
                .setIn(['editCart', 'serviceTemplateMap'], removeServiceTemplate(payload, state.editCart.serviceTemplateMap));
        case SET_GROUP_TEMPLATE_CONFIGURED:
            return state.setIn(['editCart', 'isGroupTemplateConfigured'], payload);
        case SUBMIT_ADD_SERVICES_ORDER.BEGIN:
            return state.setIn(['editCart', 'isSubmittingOrder'], true);
        case SUBMIT_ADD_SERVICES_ORDER.SUCCESS:
            return state
                .setIn(['editCart', 'isSubmittingOrder'], false)
                .setIn(['editCart', 'lastAttemptError'], null);
        case SUBMIT_ADD_SERVICES_ORDER.FAILURE:
            return state
                .setIn(['editCart', 'isSubmittingOrder'], false)
                .setIn(['editCart', 'lastAttemptError'], {
                    code: payload.Code,
                    contextData: payload.ContextData,
                    faultData: payload.FaultData,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage,
                });
        default:
            return state;
    }
}

const mergeAttributeFormValidationStatus = (payload, currentStatuses) => {
    const clonedStatuses = clone(currentStatuses);
    const currentStatus = clonedStatuses[payload.formName];
    if (!currentStatus) {
        clonedStatuses[payload.formName] = payload;
    } else {
        currentStatus.isValid = payload.isValid;
    }
    return clonedStatuses;
};

function removeServiceAttributeFromAllServices(serviceTemplateMap, serviceAttributeId) {
    return Object.keys(serviceTemplateMap).reduce((newServiceTemplateMap, serviceTemplateId) => {
        serviceTemplateMap[serviceTemplateId].attributes = removeServiceAttribute(serviceAttributeId, serviceTemplateMap[serviceTemplateId].attributes);

        return newServiceTemplateMap;
    }, serviceTemplateMap);
}

function getUpdatedGroupTemplateState(state, payload) {
    if (!payload.serviceAttributeValue) {
        return state.setIn(
            ['editCart', 'groupTemplate', 'attributes'],
            removeServiceAttribute(payload.serviceAttributeId, state.editCart.groupTemplate.attributes));
    } else {
        return state.setIn(['editCart', 'groupTemplate', 'attributes', payload.serviceAttributeId], payload.serviceAttributeValue);
    }
}

function getNewServiceTemplate(serviceTemplateId) {
    return {
        Id: serviceTemplateId,
        title: 'New Service',
        attributes: EMPTY_OBJECT
    };
}

function removeServiceTemplate(serviceTemplateIds, serviceTemplateMap) {
    return omit([...serviceTemplateIds], clone(serviceTemplateMap));
}

function removeServiceAttribute(serviceAttributeId, serviceAttributes) {
    const attributes = clone(serviceAttributes);
    delete attributes[serviceAttributeId];

    return attributes;
}

// function resetWizardForContinueShopping(multiOffer, serviceAddress, subscriberPhysicalInventoryPickupDetail, transferFromName, transferFromSubscriberId, cartAdditionalProperties) {
//     return INITIAL_STATE.merge({
//         cartAdditionalProperties,
//         multiOffer,
//         subscriberPhysicalInventoryPickupDetail: INITIAL_SUBSCRIBER_PHYSICAL_INVENTORY_PICKUP_DETAIL.merge({
//             comments: subscriberPhysicalInventoryPickupDetail.comments,
//             date: subscriberPhysicalInventoryPickupDetail.date,
//             id: subscriberPhysicalInventoryPickupDetail.id
//         }),
//         serviceAddress,
//         transferFromName,
//         transferFromSubscriberId
//     });
// }

// function setInitialState(
//     resumingOrder,
//     resumingStoreOrder,
//     initializeAsMultiOfferWizard,
//     transferFromName,
//     changeOfferingInstanceId,
//     changeOfferingId
// ) {
//     return INITIAL_STATE.merge({
//         changeOfferingId,
//         changeOfferingInstanceId,
//         isResumingOrder: resumingOrder,
//         isResumingStoreOrder: resumingStoreOrder,
//         multiOffer: INITIAL_MULTI_OFFER.merge({
//             initializeAsMultiOfferWizard: initializeAsMultiOfferWizard
//         }),
//         transferFromName
//     });
// }

// These are needed for the scenario when an offer has been added for multi offer successfully we set the offeringInstanceId so we are in edit mode
// function appendAddedOfferItems(stateCartItems, isMultiOfferEditing, payloadCartItems) {
//     return stateCartItems === undefined ? EMPTY_ARRAY : isMultiOfferEditing ? payloadCartItems : [...stateCartItems, ...payloadCartItems];
// }

// function setAddedOfferInstanceId(selectedOfferingInstanceId, addedOfferInstanceId) {
//     return selectedOfferingInstanceId || addedOfferInstanceId;
// }

// function getItemIndex(id, items) {
//     return items.findIndex((item) => {
//         return item.Id === id;
//     });
// }
