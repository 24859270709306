import Immutable from 'seamless-immutable';
import pluck from 'ramda/src/pluck';
import createWizardReducers from './helpers/wizard.reducer.helper';
import CustomerCareKeys from '../locales/keys';
import {
    RESET_TRANSFER_WIZARD,
    RETRIEVE_TRANSFER_DESTINATION_CUSTOMER,
    RETRIEVE_TRANSFERABLE_OFFERINGS,
    SELECT_OFFERING_FOR_TRANSFER,
    SET_OFFERINGS_METADATA,
    UNSELECT_OFFERING_FOR_TRANSFER,
    VALIDATE_SUBSCRIBER_FOR_TRANSFER
} from './actions/transfer.wizard.actions';
import {EMPTY_ARRAY} from './constants/common.constants';

const WIZARD_STEPS_KEYS = [CustomerCareKeys.WIZARD.STEPS.CHOOSE_CUSTOMER_AND_SERVICES];
const wizardReducers = createWizardReducers(WIZARD_STEPS_KEYS);

export const INITIAL_STATE = wizardReducers.initialState.merge(Immutable({
    isFetchingDestinationCustomerData: false,
    isFetchingTransferableOfferings: false,
    isValidatingSubscriberForTransfer: false,
    offerings: EMPTY_ARRAY,
    offeringsMetadata: EMPTY_ARRAY,
    offeringsSelectedForTransfer: EMPTY_ARRAY,
    selectedDestinationCustomer: null
}));

export default function wizardReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case RESET_TRANSFER_WIZARD:
            return INITIAL_STATE;
        case RETRIEVE_TRANSFERABLE_OFFERINGS.BEGIN:
            return state.set('isFetchingTransferableOfferings', true);
        case RETRIEVE_TRANSFERABLE_OFFERINGS.SUCCESS:
            return state
                .set('offerings', payload && payload.Offerings)
                .set('isFetchingTransferableOfferings', false);
        case RETRIEVE_TRANSFERABLE_OFFERINGS.FAILURE:
            return state.set('isFetchingTransferableOfferings', false);
        case SELECT_OFFERING_FOR_TRANSFER:
            return state.set('offeringsSelectedForTransfer', state.offeringsSelectedForTransfer.concat(payload));
        case SET_OFFERINGS_METADATA:
            return state.set('offeringsMetadata', pluck('Offering', payload));
        case UNSELECT_OFFERING_FOR_TRANSFER:
        {
            const index = state.offeringsSelectedForTransfer.findIndex((offeringInstanceId) => {
                return offeringInstanceId === payload;
            });
            return state.set('offeringsSelectedForTransfer', state.offeringsSelectedForTransfer.slice(0, index));
        }
        case RETRIEVE_TRANSFER_DESTINATION_CUSTOMER.BEGIN:
            return state.setIn(['isFetchingDestinationCustomerData'], true);
        case RETRIEVE_TRANSFER_DESTINATION_CUSTOMER.SUCCESS:
            return state
                .setIn(['selectedDestinationCustomer'], payload.Subscriber || null)
                .setIn(['isFetchingDestinationCustomerData'], false);
        case RETRIEVE_TRANSFER_DESTINATION_CUSTOMER.FAILURE:
            return state
                .setIn(['isFetchingDestinationCustomerData'], false);
        case VALIDATE_SUBSCRIBER_FOR_TRANSFER.BEGIN:
            return state.setIn(['isValidatingSubscriberForTransfer'], true);
        case VALIDATE_SUBSCRIBER_FOR_TRANSFER.SUCCESS:
            return state.setIn(['isValidatingSubscriberForTransfer'], false);
        case VALIDATE_SUBSCRIBER_FOR_TRANSFER.FAILURE:
            return state.setIn(['isValidatingSubscriberForTransfer'], false);
        default:
            return state;
    }
}
