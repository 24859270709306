import {
    ApiConstants,
    InjectorHelper,
    MetadataThunkHelper,
    SessionSelectors,
    SubtenantsActions,
    ThunkHelper
} from 'invision-core';
import {
    buildCustomerRequestObject,
    buildHierarchyRequestObject,
} from '../helpers/customer.helper';
import reject from  'ramda/src/reject';
import values from 'ramda/src/values';

export const ANONYMIZE_CUSTOMER = {
    BEGIN: 'ANONYMIZE_CUSTOMER_BEGIN',
    SUCCESS: 'ANONYMIZE_CUSTOMER_SUCCESS',
    FAILURE: 'ANONYMIZE_CUSTOMER_FAILURE'
};

export const anonymizeCustomerPromise = (dispatch, customerId, immediate) => {
    return ThunkHelper(dispatch, values(ANONYMIZE_CUSTOMER), {
        method: 'post',
        url: 'SubscriberManagement/AnonymizeSubscriber',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            AnonymizeImmediately: !!immediate
        }
    });
};

export const anonymizeCustomer = (customerId, immediate) => {
    return (dispatch) => {
        return anonymizeCustomerPromise(dispatch, customerId, immediate);
    };
};

export const CANCEL_ANONYMIZE_CUSTOMER = {
    BEGIN: 'CANCEL_ANONYMIZE_CUSTOMER_BEGIN',
    SUCCESS: 'CANCEL_ANONYMIZE_CUSTOMER_SUCCESS',
    FAILURE: 'CANCEL_ANONYMIZE_CUSTOMER_FAILURE'
};

export const cancelAnonymizeCustomerPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, values(CANCEL_ANONYMIZE_CUSTOMER), {
        method: 'post',
        url: 'SubscriberManagement/CancelScheduledSubscriberAnonymization',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {}
    });
};

export const cancelAnonymizeCustomer = (customerId) => {
    return (dispatch) => {
        return cancelAnonymizeCustomerPromise(dispatch, customerId);
    };
};

export const RETRIEVE_CUSTOMER = {
    BEGIN: 'RETRIEVE_CUSTOMER_BEGIN',
    SUCCESS: 'RETRIEVE_CUSTOMER_SUCCESS',
    FAILURE: 'RETRIEVE_CUSTOMER_FAILURE'
};

export const retrieveCustomerPromise = (dispatch, customerId) => {
    const $ngRedux = InjectorHelper.getNgReduxService();
    const state = $ngRedux.getState();
    return ThunkHelper(dispatch, values(RETRIEVE_CUSTOMER), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveSubscriber',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId,
            [ApiConstants.CUSTOM_SUBSCRIBER_LANGUAGE_HEADER]: SessionSelectors.LanguageHeaderSelector(state)
        },
        data: {}
    });
};

export const retrieveCustomer = (customerId) => {
    return (dispatch) => {
        return retrieveCustomerPromise(dispatch, customerId);
    };
};


export const CUSTOMER_RESET_PASSWORD = {
    BEGIN: 'CUSTOMER_RESET_PASSWORD_BEGIN',
    SUCCESS: 'CUSTOMER_RESET_PASSWORD_SUCCESS',
    FAILURE: 'CUSTOMER_RESET_PASSWORD_FAILURE'
};

// Session ID header is undefined since the API doesn't accept a session ID
export const customerResetPasswordPromise = (dispatch, subscriberLogin) => {
    return ThunkHelper(dispatch, values(CUSTOMER_RESET_PASSWORD), {
        method: 'post',
        url: 'SubscriberManagement/GeneratePassword',
        data: {
            Login: subscriberLogin
        }
    });
};

export const customerResetPassword = (subscriberLogin) => {
    return (dispatch) => {
        return customerResetPasswordPromise(dispatch, subscriberLogin);
    };
};

export const CUSTOMER_RESET_REVOKED_PASSWORD = {
    BEGIN: 'CUSTOMER_RESET_REVOKED_PASSWORD_BEGIN',
    SUCCESS: 'CUSTOMER_RESET_REVOKED_PASSWORD_SUCCESS',
    FAILURE: 'CUSTOMER_RESET_REVOKED_PASSWORD_FAILURE'
};

// Session ID header is undefined since the API doesn't accept a session ID
export const customerResetRevokedPasswordPromise = (dispatch, customerId, bypassResetPassword) => {
    return ThunkHelper(dispatch, values(CUSTOMER_RESET_REVOKED_PASSWORD), {
        method: 'post',
        url: 'SubscriberManagement/ResetRevokedPassword',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            BypassResetPassword: !!bypassResetPassword
        }
    });
};

export const customerResetRevokedPassword = (customerId, bypassResetPassword) => {
    return (dispatch) => {
        return customerResetRevokedPasswordPromise(dispatch, customerId, bypassResetPassword);
    };
};

export const CUSTOMER_RESET_DEVICE_PIN = {
    BEGIN: 'CUSTOMER_RESET_DEVICE_PIN_BEGIN',
    SUCCESS: 'CUSTOMER_RESET_DEVICE_PIN_SUCCESS',
    FAILURE: 'CUSTOMER_RESET_DEVICE_PIN_FAILURE'
};

export const customerResetDevicePinPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, values(CUSTOMER_RESET_DEVICE_PIN), {
        method: 'post',
        url: 'SubscriberManagement/UpdateDevicePin',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const customerResetDevicePin = (customerId) => {
    return (dispatch) => {
        return customerResetDevicePinPromise(dispatch, customerId);
    };
};

export const RETRIEVE_DEVICE_PIN_STATUS = {
    BEGIN: 'RETRIEVE_DEVICE_PIN_STATUS_BEGIN',
    SUCCESS: 'RETRIEVE_DEVICE_PIN_STATUS_SUCCESS',
    FAILURE: 'RETRIEVE_DEVICE_PIN_STATUS_FAILURE'
};

export const retrieveDevicePinStatusPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, values(RETRIEVE_DEVICE_PIN_STATUS), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveDevicePinStatus',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const retrieveDevicePinStatus = (customerId) => {
    return (dispatch) => {
        return retrieveDevicePinStatusPromise(dispatch, customerId);
    };
};

export const UPDATE_LIFECYCLE_DETAILS = 'UPDATE_LIFECYCLE_DETAILS';

export const updateLifeCycleDetails = (lifeCycleDetails) => {
    return {
        type: UPDATE_LIFECYCLE_DETAILS,
        payload: lifeCycleDetails
    };
};

export const UPDATE_SUBSCRIBER = {
    BEGIN: 'UPDATE_SUBSCRIBER_BEGIN',
    SUCCESS: 'UPDATE_SUBSCRIBER_SUCCESS',
    FAILURE: 'UPDATE_SUBSCRIBER_FAILURE'
};

export const updateSubscriber = (customer) => {
    return (dispatch) => {
        return updateSubscriberPromise(dispatch, customer);
    };
};

export const updateSubscriberPromise = (dispatch, customer) => {
    return ThunkHelper(dispatch, values(UPDATE_SUBSCRIBER), {
        method: 'post',
        url: 'SubscriberManagement/UpdateSubscriber',
        data: Object.assign(buildCustomerRequestObject(customer)),
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customer.Id
        }
    });
};

export const RESEND_VERIFICATION_EMAIL = {
    BEGIN: 'RESEND_VERIFICATION_EMAIL_BEGIN',
    SUCCESS: 'RESEND_VERIFICATION_EMAIL_SUCCESS',
    FAILURE: 'RESEND_VERIFICATION_EMAIL_FAILURE'
};

export const resendVerificationEmail = (customerId) => {
    return (dispatch) => {
        return resendVerificationEmailPromise(dispatch, customerId);
    };
};

export const resendVerificationEmailPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, values(RESEND_VERIFICATION_EMAIL), {
        method: 'post',
        url: 'SubscriberManagement/ResendSubscriberActivationEmail',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const deleteExternalAuthorizationPromise = (dispatch, customer, externalAuthorizationId) => {
    const newExternalAuths = reject((auth) => {
        return auth.Id === externalAuthorizationId;
    }, customer.ExternalAuthorizations);

    const newCustomer = customer.merge({
        ExternalAuthorizations: newExternalAuths
    });

    return updateSubscriberPromise(dispatch, newCustomer);
};

export const deleteExternalAuthorization = (customer, externalAuthorizationId) => {
    return (dispatch) => {
        return deleteExternalAuthorizationPromise(dispatch, customer, externalAuthorizationId);
    };
};


export const CREATE_CUSTOMER = {
    BEGIN: 'CREATE_CUSTOMER_BEGIN',
    SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
    FAILURE: 'CREATE_CUSTOMER_FAILURE'
};

const CREATE_CUSTOMER_PIN = 'CREATE_CUSTOMER_PIN';

export const createCustomerPromise = (dispatch, customer, additionalOptions) => {
    return ThunkHelper(dispatch, values(CREATE_CUSTOMER), {
        method: 'post',
        url: 'SubscriberManagement/CreateSubscriber',
        data: Object.assign(buildCustomerRequestObject(customer), additionalOptions)
    });
};

const updateSubscriberPINPromise = (dispatch, subscriberPIN, subscriberId) => {
    return ThunkHelper(dispatch, CREATE_CUSTOMER_PIN, {
        method: 'post',
        url: 'SubscriberManagement/UpdateSubscriberPIN',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
        },
        data: subscriberPIN
    });
};

export const UPDATE_CUSTOMER_BILL_CYCLE = {
    BEGIN: 'UPDATE_CUSTOMER_BILL_CYCLE_BEGIN',
    SUCCESS: 'UPDATE_CUSTOMER_BILL_CYCLE_SUCCESS',
    FAILURE: 'UPDATE_CUSTOMER_BILL_CYCLE_FAILURE'
};
const updateSubscriberBillCyclePromise = (dispatch, billCycleId, subscriberId) => {
    return ThunkHelper(dispatch, values(UPDATE_CUSTOMER_BILL_CYCLE), {
        method: 'post',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: subscriberId
        },
        url: 'SubscriberManagement/UpdateSubscriberBillCycle',
        data: {
            BillCycleId: billCycleId
        }
    });
};

export const createCustomer = (customer, additionalOptions = {}) => {
    return (dispatch) => {
        return createCustomerPromise(dispatch, customer, additionalOptions);
    };
};

export const updateSubscriberPIN = (subscriberPIN, subscriberId) => {
    return (dispatch) => {
        return updateSubscriberPINPromise(dispatch, subscriberPIN, subscriberId);
    };
};

export const updateSubscriberBillCycle = (billCycleId, subscriberId) => {
    return (dispatch) => {
        return updateSubscriberBillCyclePromise(dispatch, billCycleId, subscriberId);
    };
};

export const CREATE_EDIT_CUSTOMER_FORM_CHANGED = 'CREATE_EDIT_CUSTOMER_FORM_CHANGED';

export const createEditCustomerFormChanged = (updatedCustomerModel) => {
    return {
        type: CREATE_EDIT_CUSTOMER_FORM_CHANGED,
        payload: updatedCustomerModel
    };
};


export const CLEAR_CREATE_EDIT_CUSTOMER_FORM = 'CLEAR_CREATE_EDIT_CUSTOMER_FORM';

export const clearCreateEditCustomerForm = () => {
    return {
        type: CLEAR_CREATE_EDIT_CUSTOMER_FORM
    };
};

export const SET_CUSTOMER_CATEGORY = 'SET_CUSTOMER_CATEGORY';

export const setCustomerCategory = (category) => {
    return {
        type: SET_CUSTOMER_CATEGORY,
        payload: category
    };
};

export const SET_EDIT_ADDITIONAL_PROPERTY = 'SET_EDIT_ADDITIONAL_PROPERTY';

export const setEditAdditionalProperty = (AdditionalProperties) => {
    return {
        type: SET_EDIT_ADDITIONAL_PROPERTY,
        payload: AdditionalProperties
    };

};

export const SET_EDIT_AP_ON_CHANGE = 'SET_EDIT_AP_ON_CHANGE';
export const setEditApOnChange = (id, newValue) => {
    return {
        type: SET_EDIT_AP_ON_CHANGE,
        payload: {
            id:id,
            newValue:newValue
        }
    };
};


export const CREATE_HIERARCHY = {
    BEGIN: 'CREATE_HIERARCHY_BEGIN',
    SUCCESS: 'CREATE_HIERARCHY_SUCCESS',
    FAILURE: 'CREATE_HIERARCHY_FAILURE'
};

export const createHierarchyPromise = (dispatch, customer, additionalOptions) => {
    return ThunkHelper(dispatch, values(CREATE_HIERARCHY), {
        method: 'post',
        url: 'SubscriberManagement/CreateHierarchy',
        data: Object.assign(buildHierarchyRequestObject(customer), additionalOptions)
    });
};

export const createHierarchy = (customer, additionalOptions = {}) => {
    return (dispatch) => {
        return createHierarchyPromise(dispatch, customer, additionalOptions);
    };
};


export const FETCH_SHOPPING_CART = {
    BEGIN: 'FETCH_SHOPPING_CART_BEGIN',
    SUCCESS: 'FETCH_SHOPPING_CART_SUCCESS',
    FAILURE: 'FETCH_SHOPPING_CART_FAILURE'
};

export const FETCH_STORE_ORDER = {
    BEGIN: 'FETCH_STORE_ORDER_BEGIN',
    SUCCESS: 'FETCH_STORE_ORDER_SUCCESS',
    FAILURE: 'FETCH_STORE_ORDER_FAILURE'
};

export const CANCEL_STORE_ORDER = {
    BEGIN: 'CANCEL_STORE_ORDER_BEGIN',
    SUCCESS: 'CANCEL_STORE_ORDER_SUCCESS',
    FAILURE: 'CANCEL_STORE_ORDER_FAILURE'
};

export const CLEAR_SAVED_SHOPPING_CART = {
    BEGIN: 'CLEAR_SAVED_SHOPPING_CART_BEGIN',
    SUCCESS: 'CLEAR_SAVED_SHOPPING_CART_SUCCESS',
    FAILURE: 'CLEAR_SAVED_SHOPPING_CART_FAILURE'
};

export const fetchShoppingCartPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, values(FETCH_SHOPPING_CART), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveShoppingCart',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId: customerId
    });
};

export const clearSavedShoppingCartPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, values(CLEAR_SAVED_SHOPPING_CART), {
        method: 'post',
        url: 'SubscriberManagement/UpdateShoppingCart',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            PersistCart: true,
            CombineProducts: false,
            ShoppingCart: {}
        }
    });
};

export const fetchShoppingCart = (customerId) => {
    return (dispatch) => {
        return fetchShoppingCartPromise(dispatch, customerId);
    };
};

const fetchStoreOrderPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, values(FETCH_STORE_ORDER), {
        method: 'post',
        url: 'SubscriberManagement/ResumeStoreOrder',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            RetrievePhysicalInventoryDecisions: false,
            RetrieveServiceAttributes: false
        }
    });
};

export const fetchStoreOrder = (customerId) => {
    return (dispatch) => {
        return fetchStoreOrderPromise(dispatch, customerId);
    };
};

const cancelStoreOrderPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, values(CANCEL_STORE_ORDER), {
        method: 'post',
        url: 'SubscriberManagement/CancelStoreReservation',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {}
    });
};

export const cancelStoreOrder = (customerId) => {
    return (dispatch) => {
        return cancelStoreOrderPromise(dispatch, customerId);
    };
};

export const clearSavedShoppingCart = (customerId) => {
    return (dispatch) => {
        return clearSavedShoppingCartPromise(dispatch, customerId);
    };
};

export const RESET_CUSTOMER_RECOVERABLE = 'RESET_CUSTOMER_RECOVERABLE';
export const resetCustomerRecoverable = () => {
    return {
        type: RESET_CUSTOMER_RECOVERABLE
    };
};


export const SET_IS_EDITING_PROFILE = 'SET_IS_EDITING_PROFILE';
export const setIsEditingProfile = (newValue) => {
    return {
        type: SET_IS_EDITING_PROFILE,
        payload:newValue
    };
};

export const SEND_CONSENT_TERMS = {
    BEGIN: 'SEND_CONSENT_TERMS_BEGIN',
    SUCCESS: 'SEND_CONSENT_TERMS_SUCCESS',
    FAILURE: 'SEND_CONSENT_TERMS_FAILURE'
};

export const sendConsentTermsPromise = (dispatch, email, country) => {
    return ThunkHelper(dispatch, values(SEND_CONSENT_TERMS), {
        method: 'post',
        url: 'SubscriberManagement/SendConsentTerms',
        data: {
            ConsentCountry: country,
            EmailAddress: email
        }
    });
};

export const sendConsentTerms = (email, country) => {
    return (dispatch) => {
        return sendConsentTermsPromise(dispatch, email, country);
    };
};

export const SET_IS_CONSENT_TERMS_SENT = 'SET_IS_CONSENT_TERMS_SENT';
export const setIsConsentTermsSent = (newValue) => {
    return {
        type: SET_IS_CONSENT_TERMS_SENT,
        payload: newValue
    };
};

export const INVALIDATE_CREDIT_CLASSIFICATION = 'INVALIDATE_CREDIT_CLASSIFICATION';
export const invalidateCreditClassification = () => {
    return {
        type: INVALIDATE_CREDIT_CLASSIFICATION
    };
};

export const fetchSubtenantMetadata = () => {
    return (dispatch) => {
        return MetadataThunkHelper(dispatch, SubtenantsActions.SEARCH_SUBTENANTS_TYPES, {
            method: 'post',
            url: 'Subtenants'
        });
    };
};

export const searchSubtenantsPromise = (dispatch) => {
    return ThunkHelper(dispatch, SubtenantsActions.SEARCH_SUBTENANTS_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/SearchSubtenants'
    });
};

export const searchSubtenants = () => {
    return (dispatch) => {
        return searchSubtenantsPromise(dispatch);
    };
};

export const CUSTOMER_WRAP_UP = {
    BEGIN: 'CUSTOMER_WRAP_UP_BEGIN',
    SUCCESS: 'CUSTOMER_WRAP_UP_SUCCESS',
    FAILURE: 'CUSTOMER_WRAP_UP_FAILURE'
};

export const customerWrapUpPromise = (dispatch, {customerId, reasonId, comments}) => {
    return ThunkHelper(dispatch, values(CUSTOMER_WRAP_UP), {
        method: 'post',
        url: 'SubscriberManagement/SubscriberWrapUp',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId,
        },
        data: {
            Comment: comments,
            ReasonCode: reasonId
        }
    });
};

export const customerWrapUp = (customerWrapUpModel) => {
    return (dispatch) => {
        return customerWrapUpPromise(dispatch, customerWrapUpModel);
    };
};

export const SET_RELOAD_SUBSCRIBER_STATE_FLAG = 'SET_RELOAD_SUBSCRIBER_STATE_FLAG';
export const setReloadSubscriberStateFlag = (boolean) => {
    return {
        type: SET_RELOAD_SUBSCRIBER_STATE_FLAG,
        payload: !!boolean
    };
};

export const VALIDATE_SUBSCRIBER_PIN = {
    BEGIN: 'VALIDATE_SUBSCRIBER_PIN_BEGIN',
    SUCCESS: 'VALIDATE_SUBSCRIBER_PIN_SUCCESS',
    FAILURE: 'VALIDATE_SUBSCRIBER_PIN_FAILURE'
};

export const validateCustomerPin = (customerId, pin) => {
    return (dispatch) => {
        return ThunkHelper(dispatch, Object.values(VALIDATE_SUBSCRIBER_PIN), {
            method: 'post',
            url: 'SubscriberManagement/ValidateSubscriberPIN',
            headers: {
                [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId,
            },
            data: {
                SubscriberPIN: pin
            }
        });
    };
};

export const SET_SERVICES_AND_OFFERS_SELECTED_TAB = 'SET_SERVICES_AND_OFFERS_SELECTED_TAB';
export const setServiceAndOfferWidgetSelectedTab = (tab) => {
    return {
        type: SET_SERVICES_AND_OFFERS_SELECTED_TAB,
        payload: tab
    };
};

export const SET_SELECTED_SUBSCRIBER_OFFERING_INSTANCE_ID = 'SET_SELECTED_SUBSCRIBER_OFFERING_INSTANCE_ID';
export const setSelectedOfferingInstanceId = (id) => {
    return {
        type: SET_SELECTED_SUBSCRIBER_OFFERING_INSTANCE_ID,
        payload: id
    };
};

export const SET_SELECTED_SUBSCRIBER_HIERARCHY_ID = 'SET_SELECTED_SUBSCRIBER_HIERARCHY_ID';
export const setSelectedCustomerHierarchyId = (id) => {
    return {
        type: SET_SELECTED_SUBSCRIBER_HIERARCHY_ID,
        payload: id
    };
};

export const RETRIEVE_SUBSCRIBER_OFFERING_DETAIL = {
    BEGIN: 'RETRIEVE_SUBSCRIBER_OFFERING_DETAIL_BEGIN',
    SUCCESS: 'RETRIEVE_SUBSCRIBER_OFFERING_DETAIL_SUCCESS',
    FAILURE: 'RETRIEVE_SUBSCRIBER_OFFERING_DETAIL_FAILURE'
};

export const retrieveSubscriberOfferingDetail = (customerId, id, applyFutureGlobalDiscounts = false) => {
    return (dispatch) => {
        return ThunkHelper(dispatch, Object.values(RETRIEVE_SUBSCRIBER_OFFERING_DETAIL), {
            method: 'post',
            url: 'SubscriberManagement/RetrieveSubscriberOfferingDetail',
            headers: {
                [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId,
            },
            data: {
                ApplyFutureGlobalDiscounts: applyFutureGlobalDiscounts,
                OfferingInstanceId: id,
                IncludePaymentInstrumentAssociations: true
            }
        });
    };
};
