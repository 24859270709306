import Immutable from 'seamless-immutable';
import all from 'ramda/src/all';
import equals from 'ramda/src/equals';
import pipe from 'ramda/src/pipe';
import pluck from 'ramda/src/pluck';
import propEq from 'ramda/src/propEq';
import pathOr from 'ramda/src/pathOr';
import {createSelector} from 'reselect';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {ActiveSubtenantsSelector} from 'invision-core/src/components/subtenants/subtenants.selectors';
import {ALL_STEPS_KEYS} from '../constants/new.connect.wizard.constants';
import {billCycleHelper} from 'invision-core/src/utilities/bill.cycle.helper';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {MetadataCodeTypeSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {ShippingMethodsSelector} from 'invision-core/src/components/metadata/shippingMethods/shipping.methods.selectors';

import {
    OfferingOrderQuoteSelector,
    OfferingsSelector,
    OfferingOrderFinanceCreditLimitSelector,
    SelectedInventoryStoresSelector
} from './offering.order.selectors';
import {
    AvailableCreditFromQuote,
    BillCycle,
    BillingAddressStateRegionProvinceValueOptions,
    Cart,
    DeliveryOptionsByPricingPlanHelper,
    DeliveryMethodOptionsSelector,
    EditOption,
    EditOptions,
    EditCart,
    EditSelectedFacetIds,
    EditSelectedOfferId,
    FinanceCreditFromQuoteSelector,
    FormattedAttributes,
    FormattedDeliveryDecisions,
    FormattedPhysicalAttributes,
    GetAdditionalPropertyListMapSelector,
    GetCartAdditionalPropertiesSelector,
    GetOfferAdditionalPropertiesSelector,
    GetOfferingOrderQuoteSelector,
    GetOrderAdditionalPropertiesSelector,
    GetPostQuoteDueTotalsSelector,
    GetQuoteHasInvoiceItemsSelector,
    GetTotalDownPaymentAmountSelector,
    InventoryAvailabilityRequests,
    IsCreditLimitBreachedFromQuote,
    PaymentInfo,
    SelectedEditOption,
    SelectedOfferId,
    TaxLocationDecisionsByPricingPlanHelper
} from '../helpers/offer.ordering.wizard.selector.helper';
import {
    CompletedSteps,
    CurrentStep,
    CurrentStepIndex,
    CurrentStepIsInvalid,
    FormattedCityState,
    FormattedName,
    IsActiveStep,
    SelectedTabIndex,
    SkippedStep,
    TabPanelViewModel
} from '../helpers/wizard.selector.helper';
import {
    currencyValuesForCountry,
    currencyValueOptionsForCountry
} from '../helpers/customer.selectors.helpers';
import {CUSTOMER_CATEGORY} from '../../components/createCustomerPopup/create.customer.popup.constants';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {
    CartAdditionalPropertyFieldsSelector,
    OfferAdditionalPropertyFieldsSelector,
    OrderAdditionalPropertyFieldsSelector
} from 'invision-core/src/components/metadata/codes/codes.additional.properties.selectors';
import {
    EMPTY_ARRAY
} from '../constants/common.constants';


const NewConnectWizardStoreSelector = state => {
    return state.customercare.recoverableUiState.newConnectWizard;
};

const CartSelector = Cart([NewConnectWizardStoreSelector]);

export const CurrentStepIndexSelector = CurrentStepIndex([NewConnectWizardStoreSelector]);

export const CurrentStepSelector = CurrentStep([CurrentStepIndexSelector], ALL_STEPS_KEYS);
export const IsServiceabilityStepSelector = IsActiveStep([CurrentStepSelector], ALL_STEPS_KEYS, 0);
export const IsOffersStepSelector = IsActiveStep([CurrentStepSelector], ALL_STEPS_KEYS, 1);
export const IsDecisionsStepSelector = IsActiveStep([CurrentStepSelector], ALL_STEPS_KEYS, 2);
export const IsAttributesStepSelector = IsActiveStep([CurrentStepSelector], ALL_STEPS_KEYS, 3);
export const IsCustomerStepSelector = IsActiveStep([CurrentStepSelector], ALL_STEPS_KEYS, 4);
export const IsCheckoutStepSelector = IsActiveStep([CurrentStepSelector], ALL_STEPS_KEYS, 5);

export const CompletedStepsSelector = CompletedSteps([NewConnectWizardStoreSelector]);

export const EditCartSelector = EditCart([NewConnectWizardStoreSelector]);

export const EditOptionsSelector = EditOptions([EditCartSelector]);

const SelectedEditOptionSelector = SelectedEditOption([EditCartSelector]);

export const BillCycleSelector = BillCycle([EditCartSelector]);

export const EditOptionSelector = EditOption([SelectedEditOptionSelector, EditOptionsSelector]);

export const EditSelectedFacetIdsSelector = EditSelectedFacetIds([EditCartSelector]);

export const EditSelectedOfferIdSelector = EditSelectedOfferId([EditCartSelector]);

export const PaymentInfoSelector = PaymentInfo([CartSelector]);

export const PaymentInfoEditSelector = PaymentInfo([EditCartSelector]);

export const SkippedStepSelector = SkippedStep([NewConnectWizardStoreSelector]);

export const CurrentStepIsInvalidSelector = CurrentStepIsInvalid([NewConnectWizardStoreSelector]);

export const SelectedTabIndexSelector = SelectedTabIndex([NewConnectWizardStoreSelector]);

export const TabPanelViewModelSelector = TabPanelViewModel([SelectedTabIndexSelector]);

export const BillingAddressStateRegionProvinceValueOptionsSelector = BillingAddressStateRegionProvinceValueOptions([
    PaymentInfoEditSelector,
    MetadataCodeTypeSelector(CODES.AddressCountry),
    MetadataCodeTypeSelector(CODES.AddressStateProvinceRegion)
]);

export const PaymentInfoEditAutoPaySelector = createSelector(
    [PaymentInfoEditSelector],
    (paymentInfoEdit) => {
        return paymentInfoEdit && paymentInfoEdit.ConvergentBillerPaymentInstrumentAccounts ?
            pipe(
                pluck('AutoPay'),
                all(equals(true))
            )(paymentInfoEdit.ConvergentBillerPaymentInstrumentAccounts)
            : false;
    }
);

export const CustomerInfoSelector = createSelector(
    [CartSelector],
    (cart) => {
        return cart.customerInfo.asMutable();
    }
);

const emptyCustomerInfo = {};

export const EditCustomerInfoSelector = createSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.customerInfo !== undefined ? editCart.customerInfo.asMutable({
            deep:true
        }) : emptyCustomerInfo;
    }
);

export const EditCustomerSubscriberCurrency = createImmutableSelector(
    [EditCustomerInfoSelector],
    (editCustomerInfo) => {
        return editCustomerInfo.SubscriberCurrency;
    });

export const EditCustomerInfoIsCategoryValidSelector = createSelector(
    [EditCustomerInfoSelector],
    (editCustomerInfo) => {
        const customerCategory = editCustomerInfo.Category;
        return (customerCategory === CUSTOMER_CATEGORY.RESIDENTIAL || customerCategory === CUSTOMER_CATEGORY.COMMERCIAL);
    }
);

export const EditCustomerInfoSubtenantSelector = createSelector(
    [EditCustomerInfoSelector],
    (editCustomer) => {
        return editCustomer.SubtenantId ? [editCustomer.SubtenantId] : null;
    }
);

export const FormattedNameSelector = FormattedName([CustomerInfoSelector]);

export const FormattedCityStateSelector = FormattedCityState([CustomerInfoSelector]);

export const SelectedOfferIdSelector = SelectedOfferId([CartSelector]);

export const SelectedOfferSelector = createSelector(
    [
        SelectedOfferIdSelector,
        OfferingsSelector
    ],
    (selectedOfferId, offeringsMap) => {
        return offeringsMap[selectedOfferId];
    }
);

export const FormattedAttributesSelector = FormattedAttributes([EditCartSelector]);
export const FormattedPhysicalAttributesSelector = FormattedPhysicalAttributes([EditCartSelector, SelectedOfferIdSelector, SelectedInventoryStoresSelector]);
export const InventoryAvailabilityRequestsSelector = InventoryAvailabilityRequests([FormattedPhysicalAttributesSelector, EditCartSelector]);

const EMPTY_ATTRIBUTE_GROUPS = Immutable([]);
export const EditAttributeGroupsSelector = createSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.editAttributeGroups ? editCart.editAttributeGroups.asMutable({
            deep: true
        }) : EMPTY_ATTRIBUTE_GROUPS;
    }
);

export const DeliveryDecisionsSelector = createImmutableSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.deliveryDecisions || EMPTY_ARRAY;
    }
);

export const ServiceTaxDecisionsSelector = createImmutableSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.serviceTaxDecisions || EMPTY_ARRAY;
    }
);

export const DeliveryOptionsByPricingPlanSelector = DeliveryOptionsByPricingPlanHelper([DeliveryDecisionsSelector, ShippingMethodsSelector]);

export const FormattedDeliveryDecisionsSelector = FormattedDeliveryDecisions([DeliveryDecisionsSelector]);

export const TaxLocationDecisionsByPricingPlanSelector = TaxLocationDecisionsByPricingPlanHelper([ServiceTaxDecisionsSelector]);

export const EditPhysicalAttributeGroupsSelector = createSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.editPhysicalAttributeGroups ? editCart.editPhysicalAttributeGroups.asMutable({
            deep: true
        }) : EMPTY_ATTRIBUTE_GROUPS;
    }
);

export const EditSelectedOfferingChargeTypeIdSelector = createSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.selectedOfferingChargeTypeIds;
    }
);

export const ConvergentBillerAccountsSelector = createSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.convergentBillerAccounts;
    }
);

export const CreatedCustomerSelector = createSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.customer;
    }
);

export const IsCreatingOrUpdatingCustomerSelector = createSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.isCreatingOrUpdatingCustomer;
    }
);

export const IsFetchingBillCyclesSelector = createSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.isFetchingBillCycles;
    }
);

export const IsFetchingDataSelector = createSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.isFetchingData;
    }
);

export const ViewLastAttemptErrorSelector = createSelector(
    [EditCartSelector],
    (editCart) => {
        return editCart.lastAttemptError;
    }
);

export const CreateCountryCurrencyValueOptions = createSelector([
    EditCustomerInfoSelector,
    MetadataCodeTypeSelector(CODES.CountryCurrency)
], (customer, currencies) => {
    return currencyValueOptionsForCountry(customer.HomeCountry, currencies);
});

export const DefaultCountryCurrencySelector = createSelector([
    EditCustomerInfoSelector,
    MetadataCodeTypeSelector(CODES.CountryCurrency)
], (customer, currencies) => {
    const countryCurrencies = currencyValuesForCountry(customer.HomeCountry, currencies) || [];
    return countryCurrencies.find((currencyOption) => {
        return currencyOption.IsDefault;
    });
});

export const CurrentCustomerIsExternallyManagedARSelector = createSelector(
    [
        CustomerInfoSelector,
        MetadataCodeTypeSelector(CODES.SubscriberType)
    ],
    (currentCustomer, subscriberTypes) => {
        const subscriberType = subscriberTypes.find((subscriberType) => {
            return subscriberType.Value === currentCustomer.SubscriberTypeCode;
        });
        return !!(subscriberType && subscriberType.AdditionalProperties.find(propEq('externally_managed_accounts_receivable', 'Key')).Value === 'True');
    }
);

export const NewConnectCandidateBillCyclesSelector = createSelector([
    EditCustomerInfoSelector
], (customer) => {
    return customer.candidateBillCycles.map(billCycleHelper.addTranslatedDueDates);
});


export const HasCustomerInfoSelector = createSelector(
    [CustomerInfoSelector, FormattedNameSelector, FormattedCityStateSelector],
    (customerInfo, formattedName, formattedCityState) => {
        return !!(formattedName || customerInfo.addressLine1 || customerInfo.addressLine2 || formattedCityState || customerInfo.postalCode);
    }
);

export const IsPopoutableSelector = createSelector(
    [IsServiceabilityStepSelector, SelectedOfferIdSelector],
    (isServiceabilityStep, selectedOfferId) => {
        return isServiceabilityStep && selectedOfferId === null;
    }
);

export const GetSubtenantNameSelector = createSelector(
    [CustomerInfoSelector, ActiveSubtenantsSelector],
    (customerInfo, currentSubtenants) => {
        if (customerInfo.SubtenantId) {
            const subtenant = currentSubtenants.find((subtenant) => {
                return subtenant.Id.Value === customerInfo.SubtenantId.Value;
            });
            return subtenant ? subtenant.Name : i18n.translate(CoreLocaleKeys.NOT_APPLICABLE);
        } else {
            return i18n.translate(CoreLocaleKeys.NOT_APPLICABLE);
        }
    }
);

export const OfferQuoteSelector = GetOfferingOrderQuoteSelector([OfferingOrderQuoteSelector]);

export const OfferQuoteFinanceCreditLimitSelector = OfferingOrderFinanceCreditLimitSelector;

export const AvailableCreditLimitFromQuoteSelector = AvailableCreditFromQuote([OfferQuoteSelector]);

export const IsCreditLimitBreachedFromQuoteSelector = IsCreditLimitBreachedFromQuote([OfferQuoteSelector]);

export const AvailableFinanceCreditFromQuoteSelector = FinanceCreditFromQuoteSelector([OfferQuoteFinanceCreditLimitSelector]);

export const TotalDownPaymentSelector = GetTotalDownPaymentAmountSelector([OfferQuoteSelector]);

export const CreatedCustomerCreditLimitSelector = createImmutableSelector(
    [CreatedCustomerSelector, OfferQuoteSelector],
    (createdCustomer, offerQuoteSelector) => {
        return pathOr(null, ['Subscriber', 'CreditCheckClassification', 'CreditLimit'], createdCustomer) || offerQuoteSelector && offerQuoteSelector.CreditLimit;
    }
);

export const PostQuoteDueTotalsSelector = GetPostQuoteDueTotalsSelector([OfferQuoteSelector]);

export const QuoteHasInvoiceItemsSelector = GetQuoteHasInvoiceItemsSelector([OfferQuoteSelector]);

export const CartAdditionalPropertiesSelector = GetCartAdditionalPropertiesSelector([NewConnectWizardStoreSelector]);

export const CartAdditionalPropertyListMapSelector = GetAdditionalPropertyListMapSelector([CartAdditionalPropertiesSelector, CartAdditionalPropertyFieldsSelector]);

export const OrderAdditionalPropertiesSelector = GetOrderAdditionalPropertiesSelector([NewConnectWizardStoreSelector]);
export const OfferAdditionalPropertiesSelector = GetOfferAdditionalPropertiesSelector([NewConnectWizardStoreSelector]);

export const OrderAdditionalPropertyListMapSelector = GetAdditionalPropertyListMapSelector([OrderAdditionalPropertiesSelector, OrderAdditionalPropertyFieldsSelector]);
export const OfferAdditionalPropertyListMapSelector = GetAdditionalPropertyListMapSelector([OfferAdditionalPropertiesSelector, OfferAdditionalPropertyFieldsSelector]);

export const NewConnectOfferDeliveryMethodOptionsSelector = DeliveryMethodOptionsSelector([ShippingMethodsSelector, EditCustomerSubscriberCurrency]);
