import pathOr from 'ramda/src/pathOr';
import Immutable from 'seamless-immutable';
import {CALCULATE_COS_QUOTE,
    CHANGE_OF_SERVICE_REMOVAL,
    CLEAR_DISCONNECT_DATA,
    DISCONNECT_RETRIEVE_ORDER_CONTEXT,
    DISCONNECT_SUBSCRIBER,
    DISCONNECT_SUBSCRIBER_QUOTE,
    RESTORE_DEFAULT_ETF,
    RETRIEVE_OFFERING_REMOVAL_CONTEXT,
    SET_DEFAULT_ETF_AMOUNT,
    SET_WAIVE_AND_REASON,
    TOGGLE_ETF_WAIVE} from './actions/offering.disconnect.actions';
import {QUOTE_BYPASSED_WARNING_CODE} from '../customercare.constants';

export const INITIAL_STATE = Immutable({
    isRetrievingContext: false,
    isSubmittingOrder: false,
    isCalculatingQuote:false,
    isQuoteBypassed: false,
    lastAttemptError: null,
    data: {
        billingEffectiveDateConfiguration: {},
        contextRetrieved: false,
        etfWaive: {
            defaultAmount: null,
            waive: false,
            waiveReason: null
        },
        quote: {},
        removeItems: [],
        shoppingCart: {},
        subTotal: 0,
        taxAmount: 0,
        totalAmount: 0
    }
});

export default function disconnectOfferingReducer(state = INITIAL_STATE, {payload, type}) {
    switch (type) {
        case RETRIEVE_OFFERING_REMOVAL_CONTEXT.BEGIN:
            return state.set('isRetrievingContext', true);
        case RETRIEVE_OFFERING_REMOVAL_CONTEXT.SUCCESS:
            return state
                .set('isRetrievingContext', false)
                .set('lastAttemptError', null)
                .setIn(['data', 'removeItems'], payload.RemoveItems)
                .setIn(['data', 'shoppingCart'], payload.ShoppingCart)
                .setIn(['data', 'contextRetrieved'], true)
                .setIn(['data', 'contractEnd'], pathOr(null, ['ContractEnd'], payload));
        case RETRIEVE_OFFERING_REMOVAL_CONTEXT.FAILURE:
            return state
                .set('isRetrievingContext', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage || payload.Message,
                    severity: payload.Severity
                });
        case CHANGE_OF_SERVICE_REMOVAL.BEGIN:
        case DISCONNECT_SUBSCRIBER.BEGIN:
            return state.set('isSubmittingOrder', true);
        case CHANGE_OF_SERVICE_REMOVAL.SUCCESS:
        case DISCONNECT_SUBSCRIBER.SUCCESS:
            return state
                .set('isSubmittingOrder', false)
                .set('lastAttemptError', null);
        case CHANGE_OF_SERVICE_REMOVAL.FAILURE:
        case DISCONNECT_SUBSCRIBER.FAILURE:
            return state
                .set('isSubmittingOrder', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage || payload.Message,
                    severity: payload.Severity
                });
        case CALCULATE_COS_QUOTE.BEGIN:
        case DISCONNECT_SUBSCRIBER_QUOTE.BEGIN:
            return state
                .set('isCalculatingQuote', true)
                .setIn(['data', 'quote'], {});
        case CALCULATE_COS_QUOTE.SUCCESS:
        case DISCONNECT_SUBSCRIBER_QUOTE.SUCCESS:
            if (isQuoteBypassed(payload)) {
                return state
                    .set('isCalculatingQuote', false)
                    .set('isQuoteBypassed', true)
                    .set('lastAttemptError', null)
                    .setIn(['data', 'quote'], INITIAL_STATE.data.quote);
            } else {
                return state
                    .set('isCalculatingQuote', false)
                    .set('isQuoteBypassed', false)
                    .set('lastAttemptError', null)
                    .setIn(['data', 'quote'], payload.Quote);
            }
        case CALCULATE_COS_QUOTE.FAILURE:
            return state
                .set('isCalculatingQuote', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage || payload.Message,
                    severity: payload.Severity
                });
        case DISCONNECT_RETRIEVE_ORDER_CONTEXT.SUCCESS:
            return state.setIn(['data', 'billingEffectiveDateConfiguration'], payload.BillingEffectiveDateConfiguration);
        case SET_WAIVE_AND_REASON:
            return state
                .setIn(['data', 'etfWaive', 'waive'], true)
                .setIn(['data', 'etfWaive', 'waiveReason'], payload);
        case TOGGLE_ETF_WAIVE:
            return state.setIn(['data', 'etfWaive', 'waive'], !state.data.etfWaive.waive);
        case RESTORE_DEFAULT_ETF:
            return state
                .setIn(['data', 'etfWaive', 'waive'], false)
                .setIn(['data', 'etfWaive', 'waiveReason'], null);
        case SET_DEFAULT_ETF_AMOUNT:
            return state.setIn(['data', 'etfWaive', 'defaultAmount'], payload);
        case CLEAR_DISCONNECT_DATA:
            return INITIAL_STATE;
        default:
            return state;
    }
}

function isQuoteBypassed(payload) {
    return (payload.Warnings || []).some((warning) => {
        return warning.Code === QUOTE_BYPASSED_WARNING_CODE;
    });
}