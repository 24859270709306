import Immutable from 'seamless-immutable';
import {RESET_CUSTOMER_RECOVERABLE} from './../actions/customer.actions';
import * as ProductsActions from './../actions/customer.products.actions';
import {productsListGridSortConstants} from '../../components/customer/products/list/products.list.component';

export const INITIAL_STATE = Immutable({
    filterData: {
        classification: null,
        includeExpired: false,
        includeRemoved: false,
        includeSearch: null,
        pageSizePreference: null,
        sortCriteria: {
            SortBy: productsListGridSortConstants.column.OrderDate,
            SortDirection: productsListGridSortConstants.direction.desc
        }
    },
    pageNumber: 1
});

export default function ProductsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case ProductsActions.SET_CURRENT_PAGE:
            return state.set('pageNumber', payload);
        case ProductsActions.SET_INCLUDED_EXPIRED_FILTER:
            return state.setIn(['filterData', 'includeExpired'], payload);
        case ProductsActions.SET_INCLUDED_REMOVED_FILTER:
            return state.setIn(['filterData', 'includeRemoved'], payload);
        case ProductsActions.SET_INCLUDED_SEARCH_FILTER:
            return state.setIn(['filterData', 'includeSearch'], payload);
        case ProductsActions.SET_PRODUCTS_PAGE_SIZE_PREFERENCE:
            return state.setIn(['filterData', 'pageSizePreference'], payload);
        case ProductsActions.SET_PRODUCTS_LIST_SORT_CRITERIA:
            return state.setIn(['filterData', 'sortCriteria'], payload);
        case ProductsActions.SET_PRODUCTS_CLASSIFICATION:
            return state.setIn(['filterData', 'classification'], payload);
        case ProductsActions.REMOVE_PRODUCTS_FILTER:
        case RESET_CUSTOMER_RECOVERABLE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
