export const SHOW_RECENT_CUSTOMERS_PANEL = 'SHOW_RECENT_CUSTOMERS_PANEL';
export const HIDE_RECENT_CUSTOMERS_PANEL = 'HIDE_RECENT_CUSTOMERS_PANEL';

export const showRecentCustomersPanel = () => {
    return {
        type: SHOW_RECENT_CUSTOMERS_PANEL
    };
};

export const hideRecentCustomersPanel = () => {
    return {
        type: HIDE_RECENT_CUSTOMERS_PANEL
    };
};
