import Immutable from 'seamless-immutable';
import {
    RETRIEVE_BILL_CYCLE_DETAILS,
    RETRIEVE_CANDIDATE_BILL_CYCLES,
    RETRIEVE_NEXT_BILL_CYCLE_DETAILS
} from './actions/customer.billcycle.actions';
import {
    convertBillCycleForDisplay,
    convertBillCyclesForDisplay
} from './helpers/customer.helper';

export const INITIAL_STATE = Immutable({
    billCycleDetails: null,
    newBillCycleDetails: null,
    candidateBillCycles: [],
    isEvaluatingBillCycles: false,
    isFetchingBillCycleDetails: false,
    lastRetrieveError: null
});

export default function billCycleReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case RETRIEVE_BILL_CYCLE_DETAILS.BEGIN:
            return state.set('isFetchingBillCycleDetails', true);
        case RETRIEVE_BILL_CYCLE_DETAILS.SUCCESS:
            return state.set('billCycleDetails', convertBillCycleForDisplay(payload.BillCycleDetails))
                .set('isFetchingBillCycleDetails', false);
        case RETRIEVE_BILL_CYCLE_DETAILS.FAILURE:
            return state.set('billCycleDetails', null)
                .set('isFetchingBillCycleDetails', false)
                .set('lastRetrieveError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                });
        case RETRIEVE_CANDIDATE_BILL_CYCLES.BEGIN:
            return state.set('isEvaluatingBillCycles', true);
        case RETRIEVE_CANDIDATE_BILL_CYCLES.SUCCESS:
            return state.set('candidateBillCycles', convertBillCyclesForDisplay(payload.BillCycles))
                .set('isEvaluatingBillCycles', false);
        case RETRIEVE_CANDIDATE_BILL_CYCLES.FAILURE:
            return state.set('candidateBillCycles', [])
                .set('isEvaluatingBillCycles', false);
        case RETRIEVE_NEXT_BILL_CYCLE_DETAILS.BEGIN:
            return state.set('isFetchingBillCycleDetails', true);
        case RETRIEVE_NEXT_BILL_CYCLE_DETAILS.SUCCESS:
            return state.set('newBillCycleDetails', convertBillCycleForDisplay(payload.BillCycleDetails))
                .set('isFetchingBillCycleDetails', false);
        case RETRIEVE_NEXT_BILL_CYCLE_DETAILS.FAILURE:
            return state.set('newBillCycleDetails', null)
                .set('isFetchingBillCycleDetails', false)
                .set('lastRetrieveError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                });
        default:
            return state;
    }
}

