import path from 'ramda/src/path';
import {CHARGE_TIMING} from '../constants/wizard.constants';

export const GUIDServiceIdentifierValue = '[Other Services]';
export const ExistingItemsPlaceholderName = '[Other Existing Offers]';
export const ExistingServicesPlaceholderName = '[Other Existing Services]';

export const BILLER_RULE_INSTANCE_TYPE = {
    RECURRING: 0,
    USAGE: 1,
    ONE_TIME: 2,
    ENTITLEMENT: 3,
    CUSTOM: 4,
    EARLY_TERMINATION_FEE: 5,
    SUBSCRIPTION: 6,
    TRIGGER: 7,
    FINANCE: 8
};

export const PRODUCT_CLASSIFICATION = {
    PRODUCT: 1,
    SERVICE_FEATURE: 2,
    ADD_HOC: 3
};

export function getChargeAmount(chargeTiming, charges=[]) {
    const result =  path(['Amount'], charges.find((charge) => {
        return charge.ChargeTiming === chargeTiming;
    }));
    return (result || result === 0) ? result : '';
}

export function reorderFullCartItems(itemQuotes) {
    const products = [];
    const serviceFeatures = [];
    const addHoc = [];
    const otherItems = [];
    itemQuotes.forEach(itemQuote => {
        switch (itemQuote.productClassification) {
            case PRODUCT_CLASSIFICATION.PRODUCT:
                products.push(itemQuote);
                break;
            case PRODUCT_CLASSIFICATION.SERVICE_FEATURE:
                serviceFeatures.push(itemQuote);
                break;
            case PRODUCT_CLASSIFICATION.ADD_HOC:
                addHoc.push(itemQuote);
                break;
            default :
                otherItems.push(itemQuote);
        }
    });

    return [...reorderBasedOnBillerRuleType(products), ...reorderBasedOnBillerRuleType(serviceFeatures),
        ...reorderBasedOnBillerRuleType(addHoc), ...reorderBasedOnBillerRuleType(otherItems)];
}

function reorderBasedOnBillerRuleType(items) {
    const financedItems = [];
    const oneTimeItems = [];
    const recurringItems = [];
    const otherItems = [];
    items.forEach(item => {
        switch (item.type) {
            case BILLER_RULE_INSTANCE_TYPE.FINANCE:
                financedItems.push(item);
                break;
            case BILLER_RULE_INSTANCE_TYPE.ONE_TIME:
                oneTimeItems.push(item);
                break;
            case BILLER_RULE_INSTANCE_TYPE.RECURRING:
                recurringItems.push(item);
                break;
            default :
                otherItems.push(item);
        }
    });
    return [...financedItems, ...oneTimeItems, ...recurringItems, ...otherItems];
}

export function getItemQuote(itemQuote, billerRuleConfigurations) {
    return {
        name: `${itemQuote.Product.Name}: ${itemQuote.PricingPlan.Name}`,
        chargeQuotes: (itemQuote.ChargeQuotes || []).reduce((chargeQuotes, chargeQuote) => {
            chargeQuotes.push({
                name: path(['Name'], billerRuleConfigurations[chargeQuote.BillerRuleInstanceType].find((brc) => {
                    return brc.Value === chargeQuote.BillerRuleConfigurationId;
                })),
                dueOnActivation: getChargeAmount(CHARGE_TIMING.DUE_ON_ACTIVATION, chargeQuote.ChargeQuoteTotals),
                dueOnFirstUse: getChargeAmount(CHARGE_TIMING.DUE_ON_FIRST_USE, chargeQuote.ChargeQuoteTotals),
                dueRecurring: getChargeAmount(CHARGE_TIMING.DUE_RECURRING, chargeQuote.ChargeQuoteTotals),
                dueToday: getChargeAmount(CHARGE_TIMING.DUE_TODAY, chargeQuote.ChargeQuoteTotals),
                dueNextInvoice: getChargeAmount(CHARGE_TIMING.BILL_CYCLE, chargeQuote.ChargeQuoteTotals),
                periodEndDate: chargeQuote.PeriodEndDate,
                periodStartDate: chargeQuote.PeriodStartDate,
                prorated: chargeQuote.Prorated
            });

            chargeQuote.DiscountItemQuotes && chargeQuote.DiscountItemQuotes.length && chargeQuote.DiscountItemQuotes.forEach((discountItemQuote) => {
                chargeQuotes.push({
                    name: discountItemQuote.DiscountName,
                    isDiscount: true,
                    dueOnActivation: getChargeAmount(CHARGE_TIMING.DUE_ON_ACTIVATION, discountItemQuote.DiscountQuoteTotals),
                    dueOnFirstUse: getChargeAmount(CHARGE_TIMING.DUE_ON_FIRST_USE, discountItemQuote.DiscountQuoteTotals),
                    dueRecurring: getChargeAmount(CHARGE_TIMING.DUE_RECURRING, discountItemQuote.DiscountQuoteTotals),
                    dueToday: getChargeAmount(CHARGE_TIMING.DUE_TODAY, discountItemQuote.DiscountQuoteTotals),
                    dueNextInvoice: getChargeAmount(CHARGE_TIMING.BILL_CYCLE, discountItemQuote.DiscountQuoteTotals),
                    periodEndDate: discountItemQuote.PeriodEndDate,
                    periodStartDate: discountItemQuote.PeriodStartDate,
                    prorated: discountItemQuote.Prorated
                });
            });
            return chargeQuotes;
        }, []),
        depositQuotes: itemQuote.DepositQuotes,
        itemStatus: itemQuote.Action || undefined,
        productClassification: itemQuote.Product.ProductClassification,
        type: itemQuote.PricingPlan.BillerRuleInstanceThumbnails?.[0]?.Type
    };
}
