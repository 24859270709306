import Immutable from 'seamless-immutable';
import {
    CLEAR_SERVICE_SUSPEND_RESUME_MODAL,
    RETRIEVE_SERVICE_SUSPENSION_QUOTE,
    SET_SELECTED_SERVICE_IDENTIFIER_VALUES
} from './actions/services.suspend.resume.modal.actions';
import {EMPTY_ARRAY} from './constants/common.constants';

export const INITIAL_STATE = Immutable({
    loadingQuotes: false,
    servicesToSuspend: EMPTY_ARRAY,
    selectedServiceIdentifierValues: EMPTY_ARRAY
});

export default function servicesSuspendResumeModalReducer(state = INITIAL_STATE, {payload, type}) {
    switch (type) {
        case RETRIEVE_SERVICE_SUSPENSION_QUOTE.BEGIN:
            return state.set('loadingQuotes', true);
        case RETRIEVE_SERVICE_SUSPENSION_QUOTE.SUCCESS:
            return state.set('servicesToSuspend', payload.ServicesToSuspend)
                .set('loadingQuotes', false);
        case RETRIEVE_SERVICE_SUSPENSION_QUOTE.FAILURE:
            return state.set('loadingQuotes', false);
        case CLEAR_SERVICE_SUSPEND_RESUME_MODAL:
            return INITIAL_STATE;
        case SET_SELECTED_SERVICE_IDENTIFIER_VALUES:
            return state.set('selectedServiceIdentifierValues', payload);
        default:
            return state;
    }
}