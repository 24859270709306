import {combineReducers} from 'redux';
import {router} from 'redux-ui-router';
import ConfigReducer from './config.reducer';
import CouponRedemptionCodeReducer from './coupon.redemption.code.reducer';
import CustomerActionsTemplateReducer from './customer.actions.template.reducer';
import {CustomTileResultReducer} from './custom.tiles.reducer';
import CustomerReducer from './customer.reducer';
import {CustomWidgetsReducer} from './custom.widgets.reducer';
import EditPolicyCounterModalReducer from './edit.policy.counter.modal.reducer';
import entitlementBalanceUpdateModalReducer from './entitlement.balance.update.modal.reducer';
import GiftCardReducer from './giftcard.result.reducer';
import GroupedEntitlementsModalReducer from './grouped.entitlements.modal.reducer';
import OfferingOrderReducer from './offering.order.reducer';
import ProductOrderReducer from './products.order.reducer';
import RecoverableUiReducer from './recoverable.ui.reducer';
import ServiceSuspendResumeModalReducer from './services.suspend.resume.modal.reducer';
import WorkflowReducer from './workflow.reducer';

import ApplicationReducer from 'invision-core/src/components/application/application.reducer';
import AddressComponentReducer from './addresses.component.reducer';
import ConfigurationReducer from 'invision-core/src/reducers/configuration.reducer';
import EnvironmentReducer from 'invision-core/src/reducers/environment.reducer';
import FaultReducer from 'invision-core/src/reducers/fault.reducer';
import WarningReducer from 'invision-core/src/reducers/warning.reducer';
import MetadataReducer from 'invision-core/src/components/metadata/metadata.reducer';
import SecurityReducer from 'invision-core/src/reducers/security.reducer';
import SessionReducer from 'invision-core/src/reducers/session.reducer';
import SubtenantsReducer from 'invision-core/src/reducers/subtenants.reducer';
import UnsavedChangesPromptReducer from 'invision-core/src/components/unsavedChangesPrompt/reducers';

const customerCareReducers = combineReducers({
    addressesComponent: AddressComponentReducer,
    config: ConfigReducer,
    coupon: CouponRedemptionCodeReducer,
    customer: CustomerReducer,
    customerActionsTemplate: CustomerActionsTemplateReducer,
    customTileResults: CustomTileResultReducer,
    customWidgets: CustomWidgetsReducer,
    editPolicyCounterModal: EditPolicyCounterModalReducer,
    entitlementBalanceUpdateModal: entitlementBalanceUpdateModalReducer,
    giftCardResult: GiftCardReducer,
    groupedEntitlementsModal: GroupedEntitlementsModalReducer,
    offeringOrder: OfferingOrderReducer,
    productOrder: ProductOrderReducer,
    serviceSuspendResumeModal: ServiceSuspendResumeModalReducer,
    workflow: WorkflowReducer,

    // There are two categories of state.
    // 1. Persistent data (data that we CRUD with the backend)
    // 2. Persistent UI state (visual statefulness in the app)
    //
    // Reducers above this comment list out the `persistent data reducers`.
    // The RecoverableUiReducer reducer below houses all of the persistent UI state

    recoverableUiState: combineReducers(RecoverableUiReducer)
});

export default {
    application: ApplicationReducer,
    configuration: ConfigurationReducer,
    customercare: customerCareReducers,
    environment: EnvironmentReducer,
    fault: FaultReducer,
    metadata: combineReducers(MetadataReducer),
    security: SecurityReducer,
    session: SessionReducer,
    router: router,
    subtenants: SubtenantsReducer,
    unsavedChangesPrompt: UnsavedChangesPromptReducer,
    warnings: WarningReducer
};
