import Immutable from 'seamless-immutable';
import {
    CLEAR_CUSTOMER_INVENTORY,
    FETCH_CUSTOMER_INVENTORY,
    RETRIEVE_CUSTOMER_INVENTORY_ITEM_ACTIVITIES,
    RETRIEVE_CUSTOMER_INVENTORY_ITEM_FINANCE,
    RETRIEVE_CUSTOMER_INVENTORY_ITEM,
    SET_INVENTORY_FILTER_DATA,
    SET_INVENTORY_PAGINATION_DATA,
    SET_PHYSICAL_INVENTORY_NAME
} from './actions/customer.inventory.actions';
import {RESET_CUSTOMER_RECOVERABLE} from './actions/customer.actions';

export const INITIAL_STATE = Immutable({
    data: [],
    filterData: {
        filterBy: [],
        searchBy: null,
        searchTerm: null
    },
    isLoadingInventory: false,
    isLoadingInventoryDetails: false,
    isLoadingInventoryFinanceDetails: false,
    paginationData: {
        pageCount: 0,
        pageNumber: 1,
        pageSize: 0,
        recordCount: 0
    },
    physicalInventoryName: null,
    selectedInventory: {
        details: null,
        financeDetails: null,
        financeActivities: null,
    }
});

export default (state=INITIAL_STATE, {payload={}, type}) => {
    switch (type) {
        case CLEAR_CUSTOMER_INVENTORY:
            return state.set('data', null);
        case FETCH_CUSTOMER_INVENTORY.BEGIN:
            return state.set('isLoadingInventory', true);
        case FETCH_CUSTOMER_INVENTORY.SUCCESS:
            return state
                .set('data', payload.SubscriberInventoryItems)
                .setIn(['paginationData', 'pageCount'], payload.PageCount)
                .setIn(['paginationData', 'recordCount'], payload.RecordCount)
                .set('isLoadingInventory', false);
        case FETCH_CUSTOMER_INVENTORY.FAILURE:
            return state.set('isLoadingInventory', false);
        case RETRIEVE_CUSTOMER_INVENTORY_ITEM.BEGIN:
            return state.set('isLoadingInventoryDetails', true);
        case RETRIEVE_CUSTOMER_INVENTORY_ITEM.SUCCESS:
            return state
                .set('isLoadingInventoryDetails', false)
                .setIn(['selectedInventory', 'details'], payload.SubscriberInventoryItem);
        case RETRIEVE_CUSTOMER_INVENTORY_ITEM.FAILURE:
            return state.set('isLoadingInventoryDetails', false);
        case RETRIEVE_CUSTOMER_INVENTORY_ITEM_ACTIVITIES.SUCCESS:
            return state
                .setIn(['selectedInventory', 'financeActivities'], payload.DeviceFinanceActivities || []);
        case RETRIEVE_CUSTOMER_INVENTORY_ITEM_FINANCE.BEGIN:
            return state.set('isLoadingInventoryFinanceDetails', true);
        case RETRIEVE_CUSTOMER_INVENTORY_ITEM_FINANCE.SUCCESS:
            return state
                .set('isLoadingInventoryFinanceDetails', false)
                .setIn(['selectedInventory', 'financeDetails'], payload.SubscriberDeviceFinanceDetails || []); // if no device financing exists the API doesn't return SubscriberDeviceFinanceDetails
        case RETRIEVE_CUSTOMER_INVENTORY_ITEM_FINANCE.FAILURE:
            return state.set('isLoadingInventoryFinanceDetails', false);
        case RESET_CUSTOMER_RECOVERABLE:
            return INITIAL_STATE;
        case SET_INVENTORY_FILTER_DATA:
            return state
                .setIn(['filterData', 'filterBy'], payload.filterBy)
                .setIn(['filterData', 'searchBy'], payload.searchBy)
                .setIn(['filterData', 'searchTerm'], payload.searchTerm);
        case SET_INVENTORY_PAGINATION_DATA:
            return state
                .setIn(['paginationData', 'pageNumber'], payload.pageNumber)
                .setIn(['paginationData', 'pageSize'], payload.pageSize);
        case SET_PHYSICAL_INVENTORY_NAME:
            return state.set('physicalInventoryName', payload);
        default:
            return state;
    }
};
