import CustomerCareKeys from '../../locales/keys';

export const ALL_STEPS_KEYS = [
    CustomerCareKeys.WIZARD.STEPS.ELIGIBILITY,
    CustomerCareKeys.WIZARD.STEPS.OFFERS,
    CustomerCareKeys.WIZARD.STEPS.DECISIONS,
    CustomerCareKeys.WIZARD.STEPS.ATTRIBUTES,
    CustomerCareKeys.WIZARD.STEPS.CUSTOMER,
    CustomerCareKeys.WIZARD.STEPS.CHECKOUT
];

export const ALL_STEPS_MAPS = {
    ELIGIBILITY: CustomerCareKeys.WIZARD.STEPS.ELIGIBILITY,
    OFFERS: CustomerCareKeys.WIZARD.STEPS.OFFERS,
    DECISIONS: CustomerCareKeys.WIZARD.STEPS.DECISIONS,
    ATTRIBUTES: CustomerCareKeys.WIZARD.STEPS.ATTRIBUTES,
    CUSTOMER: CustomerCareKeys.WIZARD.STEPS.CUSTOMER,
    CHECKOUT: CustomerCareKeys.WIZARD.STEPS.CHECKOUT
};

export const STATE_OR_NAME = 'index.customercare.newconnect';
