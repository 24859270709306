import values from 'ramda/src/values';

import {
    ALWAYS_ABLE_TO_SET_AS_DEFAULT_TYPES,
    CREATABLE_PAYMENT_INSTRUMENT_TYPES,
    DEFAULT_UNKNOWN_PAYMENT_METHOD_TYPE_SORT_ORDER,
    EDITABLE_PAYMENT_INSTRUMENT_TYPES,
    PAYMENT_INSTRUMENT_ALWAYS_REQUIRED_ADDRESS,
    PAYMENT_INSTRUMENT_NEVER_REQUIRED_ADDRESS,
    PAYMENT_METHOD_TYPE_SORT_ORDER,
    READ_ONLY_PAYMENT_INSTRUMENT_TYPES_EXCEPTIONS,
    SUPPORTED_OTT_PAYMENT_INSTRUMENT_TYPES,
    SUPPORTED_PAYMENT_INSTRUMENT_TYPES,
    AUTO_PAY_SUPPORTED_PAYMENT_INSTRUMENT_TYPES
} from '../../components/customer/ewallet/ewallet.constants';

const EMPTY_ARRAY = [];

export const getDefaultPaymentMethodFromPaymentInstruments = (customerPaymentInstruments) => {
    let defaultPaymentMethod = customerPaymentInstruments.find((currentInstrument) => {
        return currentInstrument.Default;
    });

    if (!defaultPaymentMethod || !defaultPaymentMethod.Id) {
        defaultPaymentMethod = customerPaymentInstruments[0];
    }
    return defaultPaymentMethod;
};

export const getDefaultPaymentMethodFromPaymentInstrumentsWithAutoPay = (customerPaymentInstruments) => {
    let defaultPaymentMethodWithConvergentAccounts = customerPaymentInstruments.find((currentPaymentInstrument) => {
        return (currentPaymentInstrument.ConvergentBillerPaymentInstrumentAccounts || EMPTY_ARRAY).some((convergentBillerPaymentInstrumentAccount) => {
            return convergentBillerPaymentInstrumentAccount.AutoPay;
        }) ;
    });

    if (!defaultPaymentMethodWithConvergentAccounts) {
        defaultPaymentMethodWithConvergentAccounts = getDefaultPaymentMethodFromPaymentInstruments(customerPaymentInstruments);
    }
    return defaultPaymentMethodWithConvergentAccounts;
};

export const isSupportedPaymentInstrumentType = (type) => {
    return values(SUPPORTED_PAYMENT_INSTRUMENT_TYPES).indexOf(String(type)) > -1;
};

export const isSupportedOTTPaymentInstrumentType = (type) => {
    return values(SUPPORTED_OTT_PAYMENT_INSTRUMENT_TYPES).indexOf(String(type)) > -1;
};

export const isCreatablePaymentInstrumentType = (type) => {
    return values(CREATABLE_PAYMENT_INSTRUMENT_TYPES).indexOf(String(type)) > -1;
};

export const isEditablePaymentInstrumentType = (type) => {
    return values(EDITABLE_PAYMENT_INSTRUMENT_TYPES).indexOf(String(type)) > -1;
};

export const isAddressAlwaysRequiredForPaymentInstrumentType = (type) => {
    return values(PAYMENT_INSTRUMENT_ALWAYS_REQUIRED_ADDRESS).indexOf(String(type)) > -1;
};

export const isAddressNeverRequiredForPaymentInstrumentType = (type) => {
    return values(PAYMENT_INSTRUMENT_NEVER_REQUIRED_ADDRESS).indexOf(String(type)) > -1;
};

export const isAlwaysAbleToBeSetAsDefaultType = (type) => {
    return values(ALWAYS_ABLE_TO_SET_AS_DEFAULT_TYPES).indexOf(String(type)) > -1;
};

export const isReadOnlyException = (type) => {
    return values(READ_ONLY_PAYMENT_INSTRUMENT_TYPES_EXCEPTIONS).indexOf(String(type)) !== -1;
};

export const isLockedPaymentInstrument = (paymentMethod) => {
    return Boolean(paymentMethod.Locked);
};

export const isAutoPaySupportedPaymentInstrumentType = (type) => {
    return values(AUTO_PAY_SUPPORTED_PAYMENT_INSTRUMENT_TYPES).indexOf(String(type)) > -1;
};

let _paymentMethodTypeSortOrderLookup;
const getPaymentMethodTypeSortOrderLookup = () => {
    if (!_paymentMethodTypeSortOrderLookup) {
        _paymentMethodTypeSortOrderLookup = PAYMENT_METHOD_TYPE_SORT_ORDER.reduce(
            (lookup, paymentMethod, index) => {
                lookup[paymentMethod] = index;
                return lookup;
            },
            {}
        );
    }

    return _paymentMethodTypeSortOrderLookup;
};

export const getPaymentMethodTypeSortOrder = (methodType) => {
    const sortOrder = getPaymentMethodTypeSortOrderLookup()[methodType.toString()];
    if (isNaN(sortOrder)) {
        return DEFAULT_UNKNOWN_PAYMENT_METHOD_TYPE_SORT_ORDER;
    }

    return sortOrder;
};

/**
 * This method is to fix the data deficiencies returned from the API
 * For example, in Stored Value Account, when initial amount and current balance have zero value
 * the property is simply omitted
 * @param paymentInstrument
 */
export const fixPaymentInstrumentData = (paymentInstrument) => {
    if (!paymentInstrument) {
        return paymentInstrument;
    }

    if (paymentInstrument.GiftCard && isNaN(paymentInstrument.GiftCard.Balance)) {
        paymentInstrument.GiftCard.Balance = 0;
    }

    if (paymentInstrument.StoredValueAccount) {
        const sva = paymentInstrument.StoredValueAccount;
        if (isNaN(sva.Balance)) {
            sva.Balance = 0;
        }

        if (isNaN(sva.InitialAmount)) {
            sva.InitialAmount = 0;
        }
    }

    return paymentInstrument;
};
