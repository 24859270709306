import values from 'ramda/src/values';
import ApiConstants from 'invision-core/src/api/constants';
import ThunkHelper from 'invision-core/src/api/thunk.helper';
import {
    sendInventoryToStorePromise,
    submitOrderPromise
} from './offering.order.actions';
import {
    buildAddressesRequestObject,
    buildCustomerRequestObject,
    buildCandidateBillCyclesRequest
} from '../helpers/customer.helper';
import createWizardActions from '../helpers/offer.ordering.wizard.action.helper';
import {NEW_CONNECT_WIZARD_PREFIX} from '../constants/wizard.constants';

const wizardActions = createWizardActions(NEW_CONNECT_WIZARD_PREFIX);

export const BEGIN_NEW_CONNECT_ORDER = 'BEGIN_NEW_CONNECT_ORDER';
export const CANCEL_ORDER = wizardActions.CANCEL_WIZARD;
export const GO_TO_NEXT_STEP = wizardActions.GO_TO_NEXT_STEP;
export const GO_TO_PREVIOUS_STEP = wizardActions.GO_TO_PREVIOUS_STEP;
export const GO_TO_STEP = wizardActions.GO_TO_STEP;
export const RESTORE_OPTION_DEFAULT_PRICING = wizardActions.RESTORE_OPTION_DEFAULT_PRICING;
export const SAVE_OPTION_PRICING = wizardActions.SAVE_OPTION_PRICING;
export const SEND_PORT_IN_OR_ITEM_RESERVATION = wizardActions.SEND_PORT_IN_OR_ITEM_RESERVATION;
export const SET_ATTRIBUTE_SEARCHING_INVENTORY = wizardActions.SET_ATTRIBUTE_SEARCHING_INVENTORY;
export const SET_CURRENT_STEP_IS_INVALID = wizardActions.SET_CURRENT_STEP_IS_INVALID;
export const SET_EDIT_ATTRIBUTE_GROUPS = wizardActions.SET_EDIT_ATTRIBUTE_GROUPS;
export const SET_EDIT_CART_ADDITIONAL_PROPERTY = wizardActions.SET_EDIT_CART_ADDITIONAL_PROPERTY;
export const SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE = wizardActions.SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE;
export const SET_EDIT_OFFER_ADDITIONAL_PROPERTY = wizardActions.SET_EDIT_OFFER_ADDITIONAL_PROPERTY;
export const SET_EDIT_OFFER_ADDITIONAL_PROPERTY_ON_CHANGE = wizardActions.SET_EDIT_OFFER_ADDITIONAL_PROPERTY_ON_CHANGE;
export const SET_EDIT_OPTION = wizardActions.SET_EDIT_OPTION;
export const SET_EDIT_ORDER_ADDITIONAL_PROPERTY = wizardActions.SET_EDIT_ORDER_ADDITIONAL_PROPERTY;
export const SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE = wizardActions.SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE;
export const SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS = wizardActions.SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS;
export const SET_PAYMENT_INFO = wizardActions.SET_PAYMENT_INFO;
export const SET_SELECTED_FACETS_EDIT_COPY = wizardActions.SET_SELECTED_FACETS_EDIT_COPY;
export const SET_SELECTED_NEW_CONNECT_TAB = wizardActions.SET_SELECTED_WIZARD_TAB;
export const SET_SELECTED_OFFER = wizardActions.SET_SELECTED_OFFER;
export const SET_SELECTED_OFFER_EDIT_COPY = wizardActions.SET_SELECTED_OFFER_EDIT_COPY;
export const SET_SELECTED_OFFERING_CHARGE_TYPES_EDIT_COPY = wizardActions.SET_SELECTED_OFFERING_CHARGE_TYPES_EDIT_COPY;
export const UPDATE_BILL_CYCLE = wizardActions.UPDATE_BILL_CYCLE;
export const UPDATE_CUSTOMER_INFO = 'UPDATE_CUSTOMER_INFO';
export const UPDATE_CUSTOMER_INFO_EDIT_COPY = 'UPDATE_CUSTOMER_INFO_EDIT_COPY';
export const UPDATE_EDIT_BRI_PRICE = wizardActions.UPDATE_EDIT_BRI_PRICE;
export const UPDATE_INVENTORY_SELECTION= wizardActions.UPDATE_INVENTORY_SELECTION;
export const UPDATE_OFFER_ATTRIBUTE = wizardActions.UPDATE_OFFER_ATTRIBUTE;
export const UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL = wizardActions.UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL;
export const UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE = wizardActions.UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE;
export const UPDATE_PAYMENT_INFO = wizardActions.UPDATE_PAYMENT_INFO;
export const UPDATE_PORT_IN_REQUEST = wizardActions.UPDATE_PORT_IN_REQUEST;
export const UPDATE_SELECTED_SERVICE_FEATURES = wizardActions.UPDATE_SELECTED_SERVICE_FEATURES;
export const UPDATE_SHOPPING_CART = wizardActions.UPDATE_SHOPPING_CART;

export const CREATE_CUSTOMER_ORDER_WIZARD = {
    BEGIN: 'CREATE_CUSTOMER_ORDER_WIZARD_BEGIN',
    SUCCESS: 'CREATE_CUSTOMER_ORDER_WIZARD_SUCCESS',
    FAILURE: 'CREATE_CUSTOMER_ORDER_WIZARD_FAILURE'
};
export const RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_ORDER_WIZARD = {
    BEGIN: 'RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_ORDER_WIZARD_BEGIN',
    SUCCESS: 'RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_ORDER_WIZARD_SUCCESS',
    FAILURE: 'RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_ORDER_WIZARD_FAILURE'
};
export const UPDATE_CUSTOMER_ADDRESS_ORDER_WIZARD = {
    BEGIN: 'UPDATE_CUSTOMER_ADDRESS_WIZARD_BEGIN',
    SUCCESS: 'UPDATE_CUSTOMER_ADDRESS_WIZARD_SUCCESS',
    FAILURE: 'UPDATE_CUSTOMER_ADDRESS_WIZARD_FAILURE'
};
export const UPDATE_CUSTOMER_ORDER_WIZARD = {
    BEGIN: 'UPDATE_CUSTOMER_ORDER_WIZARD_BEGIN',
    SUCCESS: 'UPDATE_CUSTOMER_ORDER_WIZARD_SUCCESS',
    FAILURE: 'UPDATE_CUSTOMER_ORDER_WIZARD_FAILURE'
};

export const RETRIEVE_CANDIDATE_BILL_CYCLES = {
    BEGIN: 'RETRIEVE_CANDIDATE_BILL_CYCLES_BEGIN',
    SUCCESS: 'RETRIEVE_CANDIDATE_BILL_CYCLES_SUCCESS',
    FAILURE: 'RETRIEVE_CANDIDATE_BILL_CYCLES_FAILURE'
};

export const cancelOrder = wizardActions.cancelWizard;
export const clearQuoteAndSetSelectedOffer = wizardActions.clearQuoteAndSetSelectedOffer;
export const fetchAttributes = wizardActions.fetchAttributes;
export const fetchAttributesAndNavigate = wizardActions.fetchAttributesAndNavigate;
export const fetchDecisionsAndNavigate = wizardActions.fetchDecisionsAndNavigate;
export const goToNextStep = wizardActions.goToNextStep;
export const goToPreviousStep = wizardActions.goToPreviousStep;
export const goToStep = wizardActions.goToStep;
export const restoreOptionDefaultPricing = wizardActions.restoreOptionDefaultPricing;
export const saveBulkPricing = wizardActions.saveBulkPricing;
export const searchAttributeInventory = wizardActions.searchAttributeInventory;
export const sendPortInOrItemReservation = wizardActions.sendPortInOrItemReservation;
export const setCurrentStepIsInvalid = wizardActions.setCurrentStepIsInvalid;
export const setEditAttributeGroups = wizardActions.setEditAttributeGroups;
export const setEditCartAdditionalProperty = wizardActions.setEditCartAdditionalProperty;
export const setEditCartAdditionalPropertyOnChange = wizardActions.setEditCartAdditionalPropertyOnChange;
export const setEditOfferAdditionalProperty = wizardActions.setEditOfferAdditionalProperty;
export const setEditOfferAdditionalPropertyOnChange = wizardActions.setEditOfferAdditionalPropertyOnChange;
export const setEditOption = wizardActions.setEditOption;
export const setEditOrderAdditionalProperty = wizardActions.setEditOrderAdditionalProperty;
export const setEditOrderAdditionalPropertyOnChange = wizardActions.setEditOrderAdditionalPropertyOnChange;
export const setEditPhysicalAttributeGroups = wizardActions.setEditPhysicalAttributeGroups;
export const setPaymentInfo = wizardActions.setPaymentInfo;
export const setSelectedFacetsEditCopy = wizardActions.setSelectedFacetsEditCopy;
export const setSelectedOfferEditCopy = wizardActions.setSelectedOfferEditCopy;
export const setSelectedOfferingChargeTypesEditCopy = wizardActions.setSelectedOfferingChargeTypesEditCopy;
export const setSelectedTab = wizardActions.setSelectedTab;
export const updateAttribute = wizardActions.updateAttribute;
export const updateBillCycle = wizardActions.updateBillCycle;
export const updateCart = wizardActions.updateCart;
export const updateEditOptionPrice = wizardActions.updateEditOptionPrice;
export const updateInventorySelection = wizardActions.updateInventorySelection;
export const updateOfferPhysicalInventoryMakeAndModel = wizardActions.updateOfferPhysicalInventoryMakeAndModel;
export const updateOfferPhysicalInventoryTypeAttribute = wizardActions.updateOfferPhysicalInventoryTypeAttribute;
export const updatePaymentInfo = wizardActions.updatePaymentInfo;
export const updatePortInRequest = wizardActions.updatePortInRequest;
export const updateSelectedServiceFeatures = wizardActions.updateSelectedServiceFeatures;
export const updateShoppingCartPromise = wizardActions.updateShoppingCartPromise;

export const beginNewConnectOrder = () => {
    return {
        type: BEGIN_NEW_CONNECT_ORDER
    };
};

export const updateCustomerInfo = (customerInfoObj) => {
    return {
        type: UPDATE_CUSTOMER_INFO,
        payload: customerInfoObj
    };
};

export const updateCustomerInfoEditCopy = (customerInfoObj) => {
    return {
        type: UPDATE_CUSTOMER_INFO_EDIT_COPY,
        payload: customerInfoObj
    };
};

export const updateCustomerInfoEditCopyPromise = (customerInfoObj) => {
    return (dispatch) => {
        dispatch(updateCustomerInfoEditCopy(customerInfoObj));
        return Promise.resolve();
    };
};

export const navigateToFetchOffers = () => {
    return (dispatch) => {
        dispatch(goToNextStep());
        return Promise.resolve();
    };
};

const retrieveConvergentBillerSubscriberSummaryPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, values(RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_ORDER_WIZARD), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveConvergentBillerSubscriberSummary',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId: customerId
    });
};

const createCustomerPromise = (dispatch, customer, additionalOptions, paymentInstrument) => {
    const paymentInstruments = paymentInstrument ? {
        PaymentInstruments: [paymentInstrument]
    }: null;
    return ThunkHelper(dispatch, values(CREATE_CUSTOMER_ORDER_WIZARD), {
        method: 'post',
        url: 'SubscriberManagement/CreateSubscriber',
        data: Object.assign(buildCustomerRequestObject(customer), additionalOptions, paymentInstruments)
    });
};

export const createCustomer = (customer, preventOverride) => {
    return  (dispatch) => {
        const addressesOption = buildAddressesRequestObject(customer, null, preventOverride);
        return createCustomerPromise(dispatch, customer, addressesOption);
    };
};

export const createCustomerAndSaveOrder = (customer, shoppingCart, redemptionCodes, additionalOptions, paymentInfo, preventOverride) => {
    return (dispatch) => {
        const addressesOption = buildAddressesRequestObject(customer, null, preventOverride);
        return createCustomerPromise(dispatch, customer, addressesOption, paymentInfo).then((response) => {
            const subscriberId = response.Subscriber.Id;
            return updateShoppingCartPromise(dispatch, subscriberId, shoppingCart, redemptionCodes, additionalOptions);
        });
    };
};

export const createCustomerAndSendOrderToStore = (customer, shoppingCart, inventoryStorePickupDetails, preventOverride) => {
    return (dispatch) => {
        const addressesOption = buildAddressesRequestObject(customer, null, preventOverride);

        return createCustomerPromise(dispatch, customer, addressesOption).then((response) => {
            const subscriberId = response.Subscriber.Id;

            return sendInventoryToStorePromise(dispatch, subscriberId, shoppingCart, inventoryStorePickupDetails);
        });
    };
};

export const sendOrderToStore = (customer, shoppingCart, inventoryStorePickupDetails) => {
    return (dispatch) => {
        const subscriberId = customer.Subscriber.Id;
        return sendInventoryToStorePromise(dispatch, subscriberId, shoppingCart, inventoryStorePickupDetails);
    };
};

export const updateCustomerAndSaveOrder = (oldCustomerData, newCustomerData, shoppingCart, redemptionCodes, additionalOptions) => {
    return (dispatch) => {
        const customerId = oldCustomerData.Subscriber.Id;
        return updateCustomerPromise(dispatch, newCustomerData, oldCustomerData, customerId).then(() => {
            return updateAddressPromise(dispatch, customerId, newCustomerData, oldCustomerData.Addresses[0].Id);
        }).then(() => {
            return updateShoppingCartPromise(dispatch, customerId, shoppingCart, redemptionCodes, additionalOptions);
        });
    };
};

export const saveOrder = (oldCustomerData, shoppingCart, redemptionCodes, additionalOptions) => {
    return (dispatch) => {
        const customerId = oldCustomerData.Subscriber.Id;
        return updateShoppingCartPromise(dispatch, customerId, shoppingCart, redemptionCodes, additionalOptions);
    };
};

export const updateCustomerAndSendOrderToStore = (oldCustomerData, newCustomerData, shoppingCart, inventoryStorePickupDetails) => {
    return (dispatch) => {
        const customerId = oldCustomerData.Subscriber.Id;

        return updateCustomerPromise(dispatch, newCustomerData, oldCustomerData, customerId).then(() => {
            return updateAddressPromise(dispatch, customerId, newCustomerData, oldCustomerData.Addresses[0].Id);
        }).then(() => {
            return sendInventoryToStorePromise(dispatch, customerId, shoppingCart, inventoryStorePickupDetails);
        });
    };
};

export const updateCustomer = (oldCustomerData, newCustomerData) => {
    return (dispatch) => {
        const customerId = oldCustomerData.Subscriber.Id;
        return updateCustomerPromise(dispatch, newCustomerData, oldCustomerData, customerId);
    };
};

const updateCustomerPromise = (dispatch, newCustomerData, oldCustomerData, customerId) => {
    // merge data from the oldCustomerData and newCustomerData.
    // this is done to retain any important pieces of info in oldCustomerData that need to be round-tripped back to the api.
    // example: ConvergentBillerId.
    const mergedCustomerData = Object.assign({}, oldCustomerData.Subscriber, newCustomerData);

    //when guid is present in old subscriber then it should be used as login
    mergedCustomerData.Login = (oldCustomerData.Subscriber.Login && !newCustomerData.Login) ? oldCustomerData.Subscriber.Login : newCustomerData.Login;

    const requestObj = buildCustomerRequestObject(mergedCustomerData);
    // Credentials are not updated via this API call, so don't send it.
    delete requestObj.Credentials;
    // Even if Language is not required in the createCustomer api call it's _ALWAYS_ required for the updateCustomer call!
    requestObj.Subscriber.Language = requestObj.Subscriber.Language || oldCustomerData.Subscriber.Language;

    return ThunkHelper(dispatch, values(UPDATE_CUSTOMER_ORDER_WIZARD), {
        method: 'post',
        url: 'SubscriberManagement/UpdateSubscriber',
        data: requestObj,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

const updateAddressPromise = (dispatch, customerId, customerData, addressId) => {
    return ThunkHelper(dispatch, values(UPDATE_CUSTOMER_ADDRESS_ORDER_WIZARD), {
        method: 'post',
        url: 'SubscriberManagement/UpdateAddress',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        data: {
            Address: {
                City: customerData.city,
                Country: customerData.country,
                DefaultBilling: true,
                DefaultHome: true,
                DefaultPostal: true,
                DefaultService: true,
                Id: addressId,
                LineOne: customerData.addressLine1,
                LineTwo: customerData.addressLine2 ? customerData.addressLine2 : undefined,
                Name: customerData.addressLine1, // Name is required by the API and needs to be unique.
                PostalCode: customerData.postalCode,
                State: customerData.stateRegionProvince
            },
            TextScrubberOverride: customerData.textScrubber || undefined
        }
    });
};

const configureAutoPayPromise = (dispatch, customerId, paymentInfo, convergentBillerAccounts) => {
    if (!paymentInfo || !paymentInfo.AutoPay) {
        // Autopay is not configured, so skip this step
        return Promise.resolve(paymentInfo);
    } else if (paymentInfo.AutoPay) {
        if (convergentBillerAccounts) {
            // we have the account data in Redux already!
            const convergentBillerPaymentAccounts = convergentBillerAccounts.map(x => {
                return {
                    AccountNumber: x.AccountNumber,
                    AutoPay: true
                };
            });
            paymentInfo = Object.assign({}, paymentInfo, {
                ConvergentBillerPaymentInstrumentAccounts: convergentBillerPaymentAccounts
            });
            return Promise.resolve(paymentInfo);
        } else {
            // we don't have the account information so we need to get it by calling an API
            return retrieveConvergentBillerSubscriberSummaryPromise(dispatch, customerId).then((response) => {
                const convergentBillerPaymentAccounts = response.SubscriberSummary.AccountSummaries.map(x => {
                    return {
                        AccountNumber: x.AccountNumber,
                        AutoPay: true
                    };
                });
                paymentInfo = Object.assign({}, paymentInfo, {
                    ConvergentBillerPaymentInstrumentAccounts: convergentBillerPaymentAccounts
                });
                return Promise.resolve(paymentInfo);
            });
        }
    }
};

export const updateCustomerAndSubmitOrder = (dto, shippingInfo) => {
    return (dispatch) => {
        return updateCustomerPromise(dispatch, dto.newCustomerData, dto.oldCustomerData, dto.customerId).then(() => {
            return updateAddressPromise(dispatch, dto.customerId, dto.newCustomerData, dto.oldCustomerData.Addresses[0].Id);
        }).then(() => {
            return configureAutoPayPromise(dispatch, dto.customerId, dto.paymentInfo, dto.convergentBillerAccounts);
        }).then((response) => {
            dto.paymentInfo = response;
            if (dto.paymentInfo && dto.paymentInfo.BillingAddressIsServiceAddress) {
                dto.paymentInfo = Object.assign({}, dto.paymentInfo, {
                    BillingAddressId: dto.oldCustomerData.Addresses[0].Id
                });
            }

            const customerInfo = {
                'Id': dto.customerId,
                'SubtenantId': dto.subtenantId,
                'Language': dto.customerLanguage
            };
            return submitOrderPromise(dispatch, customerInfo, dto.shoppingCart, dto.paymentInfo, dto.billCycleName, dto.executionOptions, dto.recordPaymentInfo, false, dto.additionalProperties, shippingInfo, dto.offerAdditionalProperties);
        });
    };
};

export const updateAddress = (oldCustomerData, newCustomerData) => {
    return (dispatch) => {
        const customerId = oldCustomerData.Subscriber.Id;
        return updateAddressPromise(dispatch, customerId, newCustomerData, oldCustomerData.Addresses[0].Id);
    };
};

export const submitOrder = (dto, shippingInfo) => {
    return (dispatch) => {
        return configureAutoPayPromise(dispatch, dto.customerId, dto.paymentInfo, dto.convergentBillerAccounts)
            .then((response) => {
                dto.paymentInfo = response;
                if (dto.paymentInfo && dto.paymentInfo.BillingAddressIsServiceAddress) {
                    dto.paymentInfo = Object.assign({}, dto.paymentInfo, {
                        BillingAddressId: dto.oldCustomerData.Addresses[0].Id
                    });
                }

                const customerInfo = {
                    'Id': dto.customerId,
                    'SubtenantId': dto.subtenantId,
                    'Language': dto.customerLanguage
                };
                return submitOrderPromise(dispatch, customerInfo, dto.shoppingCart, dto.paymentInfo, dto.billCycleName, dto.executionOptions, dto.recordPaymentInfo, false, dto.additionalProperties, shippingInfo, dto.orderDetails, dto.offerAdditionalProperties);
            });
    };
};

export const retrieveCandidateBillCyclesNewConnectPromise = (dispatch, orderType, customerCategory, currency, customerType, country, state) => {
    return ThunkHelper(dispatch, values(RETRIEVE_CANDIDATE_BILL_CYCLES), {
        method: 'post',
        url: 'SubscriberManagement/EvaluateBillCycleRules',
        data: buildCandidateBillCyclesRequest(orderType, customerCategory, customerType, currency, country, state)
    });

};
export const retrieveCandidateBillCyclesNewConnect = (orderType, customerCategory, currency, customerType, country, state = {}) => {
    return (dispatch) => {
        return retrieveCandidateBillCyclesNewConnectPromise(dispatch, orderType, customerCategory, currency, customerType, country, state);
    };
};
