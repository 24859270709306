import Immutable from 'seamless-immutable';

import {
    RETRIEVE_ENTITLEMENT_BALANCES,
    RESET_GROUPED_ENTITLEMENTS_MODAL_STORE,
    SET_GROUPED_ENTITLEMENTS_SELECTED_TAB
} from './actions/grouped.entitlements.modal.actions';

export const GROUPED_ENTITLEMENTS_MODAL_TABS = {
    AVAILABLE_FOR_PAYMENT: 'available_for_payment',
    AVAILABLE_FOR_USAGE: 'available_for_usage'
};

export const INITIAL_STATE = Immutable({
    isLoading: false,
    selectedTab: GROUPED_ENTITLEMENTS_MODAL_TABS.AVAILABLE_FOR_PAYMENT,
    data: {}
});

export default function groupedEntitlementsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case RETRIEVE_ENTITLEMENT_BALANCES.BEGIN:
            return state.set('isLoading', true);
        case RETRIEVE_ENTITLEMENT_BALANCES.SUCCESS:
            return state
                .set('isLoading', false)
                .set('data', payload);
        case RETRIEVE_ENTITLEMENT_BALANCES.FAILURE:
            return state.set('isLoading', false);
        case SET_GROUPED_ENTITLEMENTS_SELECTED_TAB:
            return state.set('selectedTab', payload);
        case RESET_GROUPED_ENTITLEMENTS_MODAL_STORE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
