import {createSelector} from 'reselect';
import {SelectedCustomerSelector} from './customer.selectors';
import {ConvergentBillerAccountDetailsSelector,
    ConvergentBillerDepositsSelector,
    ConvergentBillerInvoicesSelector} from './customer.convergent.biller.selectors';
import {AdditionalPropValueType,
    MetadataConstants,
    MetadataHelpers,
    MetadataSelectors,
    RamdaHelper} from 'invision-core';
import {
    createTableViewModel,
    createSelectOptionsForCodes,
    createSelectOptionsForInvoices,
    createSelectOptionsForCaseActivities,
    createDepositsTableViewModel
} from '../../components/customer/cases/list/list.selectors.helper';
import {
    createPreferredContactFromContactPreferences
} from '../../components/customer/cases/detail/detail.selectors.helper';
import {
    CASE_CATEGORIES,
    CASE_STATUS
} from './../../components/customer/cases/list/case.constants';
import {GENERAL_STATUSES} from '../../components/shared/constants/general.status.constants';
import {OrdersSelector} from './order.history.selectors';
import filter from 'ramda/src/filter';
import find from 'ramda/src/find';
import map from 'ramda/src/map';
import pathOr from 'ramda/src/pathOr';
import propEq from 'ramda/src/propEq';
import sortWith from 'ramda/src/sortWith';
import ascend from 'ramda/src/ascend';
import prop from 'ramda/src/prop';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';

const recoverableUIStateSelector = (state) => {
    if (state.customercare.recoverableUiState) {
        return state.customercare.recoverableUiState.cases;
    }
};

export const CustomerCasesSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.cases.data.cases;
    }
);

export const CustomerCasePreferredContactSelector = createSelector(
    [SelectedCustomerSelector, MetadataSelectors.codes.MetadataCodeTypeIntegerSelector(MetadataConstants.codes.ContactMethod)],
    (selectedCustomer, contactTypes) => {
        return createPreferredContactFromContactPreferences(selectedCustomer, contactTypes);
    }
);

export const IsFetchingCasesSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.cases.isFetchingCases;
    }
);

export const IsFetchingCaseSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.cases.isFetchingCase;
    }
);

export const IsFetchingCaseActivitiesSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.cases.isFetchingCaseActivityTypes;
    }
);

export const IsCreatingCaseSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.cases.isCreatingCase;
    }
);

export const IsUpdatingCaseSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.cases.isUpdatingCase;
    }
);

export const CurrentCaseSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.cases.data.case;
    }
);

export const CurrentCasePortInDetailsSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.cases.data.portInDetails;
    }
);

export const SelectedCaseCategoryIdSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.cases.selectedCaseCategoryId;
    }
);

export const IsCurrentCaseStatusClosedSelector = createSelector(
    [CurrentCaseSelector],
    (currentCase) => {
        return currentCase && currentCase.Status === CASE_STATUS.CLOSED;
    }
);

export const IsCurrentCaseEditableSelector = createSelector(
    [CurrentCaseSelector],
    (currentCase) => {
        return currentCase && currentCase.Status !== CASE_STATUS.CLOSED && currentCase.Status !== CASE_STATUS.CANCELED;
    }
);

export const CaseActivityTableDataSelector = createSelector(
    [CurrentCaseSelector],
    (currentCase) => {
        return currentCase ? currentCase.CaseActivities : [];
    }
);

export const InvoicesMapSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.cases.data.invoices;
    }
);

export const CaseActivityTypesSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.cases.data.caseActivityTypes;
    }
);

export const FormattedCaseActivityTypesSelector = createSelector(
    [CaseActivityTypesSelector],
    (caseActivityTypes) => {
        return caseActivityTypes ? createSelectOptionsForCaseActivities(caseActivityTypes) : [];
    }
);

export const CustomerInvoicesSelector = createSelector(
    [ConvergentBillerInvoicesSelector, InvoicesMapSelector],
    (convergentBillerInvoices = {}, caseInvoices = {}) => {
        return convergentBillerInvoices && convergentBillerInvoices.length ? convergentBillerInvoices.map((invoice) => {
            const subscriberInvoice = caseInvoices[invoice.InvoiceId];
            return subscriberInvoice;
        }).filter((invoice) => {
            return !!invoice;
        }) : [];
    }
);

export const CaseTypesSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.cases.data.caseTypes || [];
    }
);

export const ActiveCaseTypesSelector = createSelector(
    [CaseTypesSelector],
    (caseTypes) => {
        return filter((caseType) => {
            return caseType.Status === GENERAL_STATUSES.ACTIVE;
        }, caseTypes);
    }
);

export const DisputeInvoiceCaseTypesSelector = createImmutableSelector(
    [CaseTypesSelector],
    (caseTypes) => {
        return filter((caseType) => {
            return caseType.CaseTemplateId === CASE_CATEGORIES.INVOICE_DISPUTE || caseType.IsGlobal;
        }, caseTypes);
    }
);

export const CustomerDespositsSelector = createSelector(
    [ConvergentBillerDepositsSelector,
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.DepositType),
        ConvergentBillerAccountDetailsSelector,
        OrdersSelector],
    (convergentBillerDeposits = {}, subscriberMapDeposits = {}, currentAccountSummary = {}, orderHistory = {}) => {
        return convergentBillerDeposits && subscriberMapDeposits && orderHistory && convergentBillerDeposits.length && subscriberMapDeposits.length && orderHistory.length ? createDepositsTableViewModel(convergentBillerDeposits, subscriberMapDeposits, currentAccountSummary, orderHistory): [];
    }
);

export const FormattedInvoicesSelector = createSelector(
    [CustomerInvoicesSelector],
    (invoices) => {
        return invoices ? createSelectOptionsForInvoices(invoices) : [];
    }
);

export const IsFetchingInvoicesSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.cases.isFetchingInvoices;
    }
);

export const CasesErrorSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.cases.casesError;
    }
);

export const RecordCountSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.cases.data.recordCount;
    }
);

export const IncludeStatusFilterSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        if (uiState) {
            return uiState.filterData.includeStatusFilter;
        }
    }
);

export const SelectedPageSizePreference = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.filterData.pageSizePreference;
    }
);

export const CurrentPageNumberSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.pageNumber || 0;
    }
);

export const PageCountSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.cases.data.pageCount;
    }
);

export const CaseFormIsInvalidSelector = createSelector(
    [CasesErrorSelector],
    ( casesError ) => {
        return casesError ? true : false;
    }
);

const EMPTY_CASES_TABLE = {};
export const CasesTableDataSelector = createSelector(
    [
        IsFetchingCasesSelector,
        CustomerCasesSelector,
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.CaseStatus),
        CaseTypesSelector,
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.CasePriority),
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.CaseSeverity)
    ],
    (isFetchingCases, cases, statusCodes, categoryCodes, priorityCodes, severityCodes) => {
        return !isFetchingCases ? createTableViewModel(cases, statusCodes, categoryCodes, priorityCodes, severityCodes) : EMPTY_CASES_TABLE;
    }
);

export const FormattedCasePriorityCodeSelector = createSelector(
    [MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.CasePriority)],
    (categoryCodes) => {
        return categoryCodes ? createSelectOptionsForCodes(categoryCodes) : null;
    }
);

export const FormattedCaseSeverityCodeSelector = createSelector(
    [MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.CaseSeverity)],
    (codes) => {
        return codes ? createSelectOptionsForCodes(codes) : null;
    }
);

export const FormattedCaseStatusCodeSelector = createSelector(
    [MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.CaseStatus)],
    (codes) => {
        return codes ? createSelectOptionsForCodes(codes) : null;
    }
);

export const FormattedCaseCategoryCodeSelector = createSelector(
    [ActiveCaseTypesSelector],
    (caseTypes) => {
        return map((caseType) => {
            return {
                text: caseType.DisplayName,
                value: caseType.Id
            };
        }, sortWith([ascend(prop('DisplayName'))], caseTypes));
    }
);

export const FormattedDisputeInvoiceCaseTypesSelector = createSelector(
    [DisputeInvoiceCaseTypesSelector],
    (caseTypes) => {
        return map((caseType) => {
            return {
                text: caseType.DisplayName,
                value: caseType.Id
            };
        }, sortWith([ascend(prop('DisplayName'))], caseTypes));
    }
);

export const SelectedCaseInventoryIdSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer ? selectedCustomer.cases.selectedInventoryItem : null;
    }
);

export const IsCaseEditModeSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer ? selectedCustomer.cases.data.isEditingCase : false;
    }
);

export const CustomFieldsFromCurrentCategorySelector = createSelector(
    [
        CurrentCaseSelector,
        SelectedCaseCategoryIdSelector,
        ActiveCaseTypesSelector,
        MetadataSelectors.codes.MetadataCodeTypeIntegerSelector(MetadataConstants.codes.RegularExpression),
        IsCaseEditModeSelector
    ],
    (currentCase, selectedCaseCategoryId, caseTypes, regexCodes, isEditMode) => {
        if (selectedCaseCategoryId) {
            return map((additionalProperty) => {
                let collection = [];
                if (additionalProperty.AdditionalPropertyValueType === AdditionalPropValueType.BOOLEAN) {
                    collection = ['True', 'False'];
                } else if (additionalProperty.AdditionalPropertyValueType === AdditionalPropValueType.SELECT) {
                    collection = pathOr([], ['PossibleValues'], additionalProperty);
                }

                return {
                    id: additionalProperty.Id,
                    type: additionalProperty.AdditionalPropertyValueType,
                    name: additionalProperty.Name,
                    value: isEditMode ? pathOr(null, ['Value'], find(propEq(additionalProperty.Id, 'Id'), pathOr([], ['AdditionalPropertyValues'], currentCase))) : null,
                    required: additionalProperty.IsRequired,
                    regex: additionalProperty.RegExCode ? new RegExp(String(MetadataHelpers.codes.getRegexForCode(regexCodes, additionalProperty.RegExCode))): '',
                    regexErrorMessage: pathOr('', ['Description'], RamdaHelper.findByValue(additionalProperty.RegExCode, regexCodes)),
                    options: map((value) => {
                        return {
                            id: value,
                            name: value
                        };
                    }, collection),
                    description: undefined
                };
            }, filter((property) => {
                return property.Status === GENERAL_STATUSES.ACTIVE;
            }, sortWith([ascend(prop('DisplayOrder'))], pathOr([], ['CaseAdditionalProperties'], find(propEq(selectedCaseCategoryId, 'Id'), caseTypes)))));
        }
        return [];
    }
);
