import {
    i18n,
    BoolHelper,
    MetadataConstants,
    MetadataSelectors,
    RamdaHelper
} from 'invision-core';
import {createSelector} from 'reselect';
import CustomerCareLocaleKeys from '../../locales/keys';
import {
    countBy,
    filter,
    pathOr,
    prop,
    propEq
} from 'ramda';
import {MakePaymentInstrumentSelector} from '../../components/customer/makePayment/make.payment.selectors';
import {cardTypeRequiresBillingAddress} from './customer.ewallet.selectors.helpers';
import {
    parseAdditionalProperties,
    PaymentInstrumentConfigurationAdditionalProperties,
    paymentInstrumentConfigurationAdditionalPropertyDescriptors
} from '../../reducers/helpers/payment.instrument.additional.property.parser';
import {
    ActionablesPerPaymentTypeSelector,
    AvailableTypesMapSelector,
    IsAllPaymentInstrumentTypesAvailableSelector,
    PaymentInstrumentsSelector
} from './customer.ewallet.selectors';
import {
    ENABLE_ACCOUNT_NUMBER_KEY,
    SUPPORTED_PAYMENT_INSTRUMENT_TYPES
} from '../../components/customer/ewallet/ewallet.constants';
import * as EWalletHelper from '../helpers/ewallet.reducer.helper';
import {
    isPaymentInstrumentTypeValueOptionSelected,
    subscriberHasExternalGiftCardOfType
} from './modify.payment.method.popup.selectors.helpers';

const TRUE = 'True';

const SelectedPaymentInstrumentTypeValueSelector = createSelector([
    MakePaymentInstrumentSelector
], (currentPaymentInstrumentConfig) => {
    return currentPaymentInstrumentConfig ? currentPaymentInstrumentConfig.Type : null;
});

const SelectedExternalGiftCardTypeSelector = createSelector([
    MakePaymentInstrumentSelector
], (currentPaymentInstrumentConfig) => {
    return pathOr(null, ['ExternalGiftCard', 'Type'], currentPaymentInstrumentConfig);
});

const SelectedExternalBillTypeSelector = createSelector([
    MakePaymentInstrumentSelector
], (currentPaymentInstrumentConfig) => {
    return pathOr(null, ['ExternalBill', 'Type'], currentPaymentInstrumentConfig);
});

export const PaymentInstrumentConfigurationSelector = createSelector(
    [
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.PaymentInstrumentConfiguration)
    ],
    (paymentInstrumentConfigurationAdditionalProperties) => {
        return (paymentInstrumentConfigurationAdditionalProperties || []).map((paymentConfig) => {
            if (!paymentConfig.Global) {
                const model = new PaymentInstrumentConfigurationAdditionalProperties();
                const additionalProperties = parseAdditionalProperties(model, paymentConfig.AdditionalProperties, paymentInstrumentConfigurationAdditionalPropertyDescriptors);
                return additionalProperties;
            }
        });
    }
);

export const PaymentInstrumentTypeValueOptions = createSelector([
    MetadataSelectors.codes.PaymentInstrumentTypeSelector,
    ActionablesPerPaymentTypeSelector,
    SelectedPaymentInstrumentTypeValueSelector,
    AvailableTypesMapSelector,
    IsAllPaymentInstrumentTypesAvailableSelector
], (paymentInstrumentTypes, actionablesPerPaymentType, selectedPaymentInstrumentTypeValue, availablePaymentTypesMap,
    isAllPaymentInstrumentTypesAvailable) => {

    const acceptedPaymentInstruments = paymentInstrumentTypes.filter((paymentInstrumentType) => {
        const actionables = actionablesPerPaymentType[paymentInstrumentType.Value];
        const allowCreate = actionables ? actionables.userCreatable : false;
        const isNotOptedOut = EWalletHelper.isCreatablePaymentInstrumentType(paymentInstrumentType.Value);
        return allowCreate && isNotOptedOut && (isAllPaymentInstrumentTypesAvailable ||
            !!pathOr(null, [`${paymentInstrumentType.Value}`], availablePaymentTypesMap));
    });
    return acceptedPaymentInstruments.map((paymentInstrumentType) => {
        return {
            text: paymentInstrumentType.Name,
            selected: isPaymentInstrumentTypeValueOptionSelected(selectedPaymentInstrumentTypeValue,
                paymentInstrumentType, acceptedPaymentInstruments[0].Value),
            paymentInstrumentType
        };
    });
});

export const PaymentInstrumentTypeMaxFlagsSelector = createSelector([
    PaymentInstrumentsSelector,
    MetadataSelectors.codes.PaymentInstrumentTypeSelector,
    PaymentInstrumentConfigurationSelector
], (paymentInstruments, paymentInstrumentTypes, paymentInstrumentConfigurations) => {
    const paymentInstrumentTypeCounts = countBy(prop('Type'))(paymentInstruments);
    const activePaymentInstrumentTypeCounts = countBy(prop('Type'))(filter(propEq(1, 'Status'))(paymentInstruments));

    return paymentInstrumentTypes.map((paymentInstrumentType) => {
        const typeCount = paymentInstrumentTypeCounts[paymentInstrumentType.Value];
        const typeActiveCount = activePaymentInstrumentTypeCounts[paymentInstrumentType.Value];
        const typeConfiguration = paymentInstrumentConfigurations.find((config) => {
            return config && config.paymentInstrumentTypeCode === paymentInstrumentType.Value;
        });
        const isMax = typeConfiguration &&
            typeConfiguration.alertMax > 0 &&
            typeCount >= typeConfiguration.alertMax;
        const isMaxActive = typeConfiguration &&
            typeConfiguration.alertMaxActive > 0 &&
            typeActiveCount >= typeConfiguration.alertMaxActive;

        return {
            value: paymentInstrumentType.Value,
            isMax: isMax,
            isMaxActive: isMaxActive
        };
    });
});

export const PaymentInstrumentTypeErrorsSelector = createSelector([
    PaymentInstrumentTypeMaxFlagsSelector,
    SelectedPaymentInstrumentTypeValueSelector
], (paymentInstrumentTypesMaxFlags
    , selectedPaymentInstrumentTypeValue
) => {
    const currentFlags = paymentInstrumentTypesMaxFlags.find((typeFlag) => {
        return typeFlag.value === selectedPaymentInstrumentTypeValue;
    });

    if (currentFlags && (currentFlags.isMax || currentFlags.isMaxActive)) {
        return [
            currentFlags.isMax ? i18n.translate(CustomerCareLocaleKeys.E_WALLET.ERRORS.MAX) : '',
            currentFlags.isMaxActive ? i18n.translate(CustomerCareLocaleKeys.E_WALLET.ERRORS.MAX_ACTIVE) : '',
        ].filter((r) => {
            return r && r.length > 0;
        });
    } else {
        return [];
    }
});

export const SelectedPaymentTypeRequiresAddress = createSelector([
    SelectedPaymentInstrumentTypeValueSelector,
    MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.ExternalGiftCardType),
    SelectedExternalGiftCardTypeSelector,
    MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.ExternalBillType),
    SelectedExternalBillTypeSelector
], (selectedPaymentInstrumentType, externalGiftCardTypes, selectedExternalGiftCardTypeValue,
    externalBillTypes, selectedExternalBillTypeValue) => {
    if (EWalletHelper.isAddressAlwaysRequiredForPaymentInstrumentType(selectedPaymentInstrumentType)) {
        return true;
    }

    if (EWalletHelper.isAddressNeverRequiredForPaymentInstrumentType(selectedPaymentInstrumentType)) {
        return false;
    }

    if (selectedPaymentInstrumentType === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_GIFT_CARD &&
        externalGiftCardTypes && selectedExternalGiftCardTypeValue) {
        const selectedType = externalGiftCardTypes.find((type) => {
            return type.Value.toString() === selectedExternalGiftCardTypeValue.toString();
        });

        return selectedType && cardTypeRequiresBillingAddress(selectedType);
    } else if (selectedPaymentInstrumentType === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_BILL &&
        externalBillTypes && selectedExternalBillTypeValue) {
        const selectedExternalBillType = externalBillTypes.find((type) => {
            return type.Value.toString() === selectedExternalBillTypeValue.toString();
        });

        return selectedExternalBillType && cardTypeRequiresBillingAddress(selectedExternalBillType);
    }

    return false;
});

const ALLOW_MULTIPLE_PER_SUB_KEY = 'allow_multiple_per_subscriber';
const IS_ECARD_PERSISTABLE = 'is_persistable';
const ACCOUNT_NUMBER_REGEX_KEY = 'account_number_regex';
const ACCOUNT_NUMBER_REGEX_DESCRIPTION_KEY = 'account_number_regex_description';
export const SubscriberExternalGiftCardTypeOptionsWithRegexes = createSelector([
    PaymentInstrumentsSelector,
    MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.ExternalGiftCardType)
], (allSubscriberPaymentInstruments, allExternalGiftCardTypes) => {
    const allowedEGCTypesForSubscriber = allExternalGiftCardTypes.filter((egcType) => {
        const multipleAllowedPerSubscriberProperty = RamdaHelper.findByKey(ALLOW_MULTIPLE_PER_SUB_KEY, egcType.AdditionalProperties);
        const isEcardPersistableProperty = RamdaHelper.findByKey(IS_ECARD_PERSISTABLE, egcType.AdditionalProperties);
        const isECardPersistable = (isEcardPersistableProperty && BoolHelper.getBoolOrDefault(isEcardPersistableProperty.Value, true));

        if (multipleAllowedPerSubscriberProperty && !BoolHelper.getBoolOrDefault(multipleAllowedPerSubscriberProperty.Value, true)) {
            return !subscriberHasExternalGiftCardOfType(allSubscriberPaymentInstruments, egcType) && isECardPersistable;
        }
        return isECardPersistable;
    });

    return allowedEGCTypesForSubscriber.map((egcType) => {
        const accountNumberRegexProperty = RamdaHelper.findByKey(ACCOUNT_NUMBER_REGEX_KEY, egcType.AdditionalProperties);
        egcType.accountNumberRegex = accountNumberRegexProperty ? accountNumberRegexProperty.Value : '';

        const accountNumberRegexDescriptionProperty = RamdaHelper.findByKey(ACCOUNT_NUMBER_REGEX_DESCRIPTION_KEY, egcType.AdditionalProperties);
        egcType.accountNumberRegexDescription = accountNumberRegexProperty ? accountNumberRegexDescriptionProperty.Value : '';
        return egcType;
    });
});

export const IsAccountNumberEnabledSelector = createSelector(
    [MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.GiftCardConfiguration)],
    (giftCardConfiguration) => {
        const enableAccountNumberValues = [];
        giftCardConfiguration.forEach((giftCard) => {
            const enableAccountNumber = giftCard.AdditionalProperties.find((additionalProperty) => {
                return additionalProperty.Key === ENABLE_ACCOUNT_NUMBER_KEY;
            });
            enableAccountNumberValues.push(enableAccountNumber.Value);
        });
        return enableAccountNumberValues.includes(TRUE);
    }
);
