import {
    ApiConstants,
    ThunkHelper
} from 'invision-core';

export const ADD_TO_BLOCKLIST = {
    BEGIN: 'ADD_TO_BLOCKLIST_BEGIN',
    SUCCESS: 'ADD_TO_BLOCKLIST_SUCCESS',
    FAILURE: 'ADD_TO_BLOCKLIST_FAILURE'
};
const ADD_TO_BLOCKLIST_EVENTS = [ADD_TO_BLOCKLIST.BEGIN, ADD_TO_BLOCKLIST.SUCCESS, ADD_TO_BLOCKLIST.FAILURE];

const addPaymentInstrumentToBlocklistPromise = (dispatch, customerId, paymentInstrumentId, reasonId, comments) => {
    return ThunkHelper(dispatch, ADD_TO_BLOCKLIST_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/AddPaymentInstrumentToBlocklist',
        data: {
            PaymentInstrumentId: paymentInstrumentId,
            Reason: reasonId,
            Comments: comments
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};
export const addPaymentInstrumentToBlocklist = (customerId, paymentInstrumentId, reasonId, comments) => {
    return (dispatch) => {
        return addPaymentInstrumentToBlocklistPromise(dispatch, customerId, paymentInstrumentId, reasonId, comments);
    };
};

export const ADD_TO_ALLOWLIST = {
    BEGIN: 'ADD_TO_ALLOWLIST_BEGIN',
    SUCCESS: 'ADD_TO_ALLOWLIST_SUCCESS',
    FAILURE: 'ADD_TO_ALLOWLIST_FAILURE'
};
const ADD_TO_ALLOWLIST_EVENTS = [ADD_TO_ALLOWLIST.BEGIN, ADD_TO_ALLOWLIST.SUCCESS, ADD_TO_ALLOWLIST.FAILURE];

const addPaymentInstrumentToAllowlistPromise = (dispatch, customerId, paymentInstrumentId, reasonId, comments) => {
    return ThunkHelper(dispatch, ADD_TO_ALLOWLIST_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/AddPaymentInstrumentToAllowlist',
        data: {
            PaymentInstrumentId: paymentInstrumentId,
            Reason: reasonId,
            Comments: comments
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};
export const addPaymentInstrumentToAllowlist = (customerId, paymentInstrumentId, reasonId, comments) => {
    return (dispatch) => {
        return addPaymentInstrumentToAllowlistPromise(dispatch, customerId, paymentInstrumentId, reasonId, comments);
    };
};

export const REMOVE_FROM_BLOCKLIST = {
    BEGIN: 'REMOVE_FROM_BLOCKLIST_BEGIN',
    SUCCESS: 'REMOVE_FROM_BLOCKLIST_SUCCESS',
    FAILURE: 'REMOVE_FROM_BLOCKLIST_FAILURE'
};
const REMOVE_FROM_BLOCKLIST_EVENTS = [REMOVE_FROM_BLOCKLIST.BEGIN, REMOVE_FROM_BLOCKLIST.SUCCESS, REMOVE_FROM_BLOCKLIST.FAILURE];

const removePaymentInstrumentFromBlocklistPromise = (dispatch, customerId, paymentInstrumentId, reasonId, comments) => {
    return ThunkHelper(dispatch, REMOVE_FROM_BLOCKLIST_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RemovePaymentInstrumentFromBlocklist',
        data: {
            PaymentInstrumentId: paymentInstrumentId,
            Reason: reasonId,
            Comments: comments
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};
export const removePaymentInstrumentFromBlocklist = (customerId, paymentInstrumentId, reasonId, comments) => {
    return (dispatch) => {
        return removePaymentInstrumentFromBlocklistPromise(dispatch, customerId, paymentInstrumentId, reasonId, comments);
    };
};

export const REMOVE_FROM_ALLOWLIST = {
    BEGIN: 'REMOVE_FROM_ALLOWLIST_BEGIN',
    SUCCESS: 'REMOVE_FROM_ALLOWLIST_SUCCESS',
    FAILURE: 'REMOVE_FROM_ALLOWLIST_FAILURE'
};
const REMOVE_FROM_ALLOWLIST_EVENTS = [REMOVE_FROM_ALLOWLIST.BEGIN, REMOVE_FROM_ALLOWLIST.SUCCESS, REMOVE_FROM_ALLOWLIST.FAILURE];

const removePaymentInstrumentFromAllowlistPromise = (dispatch, customerId, paymentInstrumentId, reasonId, comments) => {
    return ThunkHelper(dispatch, REMOVE_FROM_ALLOWLIST_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RemovePaymentInstrumentFromAllowlist',
        data: {
            PaymentInstrumentId: paymentInstrumentId,
            Reason: reasonId,
            Comments: comments
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};
export const removePaymentInstrumentFromAllowlist = (customerId, paymentInstrumentId, reasonId, comments) => {
    return (dispatch) => {
        return removePaymentInstrumentFromAllowlistPromise(dispatch, customerId, paymentInstrumentId, reasonId, comments);
    };
};

export const SET_SELECTED_REASON_ID = 'SET_SELECTED_REASON_ID';

export const setSelectedReasonId = (reasonId) => {
    return {
        type: SET_SELECTED_REASON_ID,
        payload: reasonId
    };
};
