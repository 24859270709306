import Immutable from 'seamless-immutable';
import {
    RESET_SHARED_ENTITLEMENT_TAB_DATA,
    RETRIEVE_ENTITLEMENT_USAGE,
    RETRIEVE_SHARED_ENTITLEMENTS,
    SET_ENTITLEMENT_SHARE_STATUS,
    SET_ENTITLEMENTS_PAGINATION_DATA,
    SET_ENTITLEMENTS_SEARCH_TERM,
    SET_SELECTED_SHARED_ENTITLEMENT_INDEX,
    UPDATE_ENTITLEMENT_SHARE_STATUS
} from './actions/shared.entitlements.actions';
import {
    EMPTY_ARRAY
} from './constants/common.constants';

export const INITIAL_STATE = Immutable({
    entitlements: EMPTY_ARRAY,
    entitlementsPaginationData: {
        pageCount: 0,
        pageNumber: 0,
        pageSize: 0,
        recordCount: 0,
        searchTerm: null
    },
    entitlementUsages: EMPTY_ARRAY,
    isFetchingEntitlements: false,
    isFetchingEntitlementUsage: false,
    isUpdatingEntitlementShareStatus: false,
    pageCount: 0,
    recordCount: 0,
    selectedEntitlementIndex: 0
});

export default function sharedEntitlementsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case RETRIEVE_SHARED_ENTITLEMENTS.BEGIN:
            return state.set('isFetchingEntitlements', true);
        case RETRIEVE_SHARED_ENTITLEMENTS.SUCCESS: {
            return state.set('entitlements', payload.SharedEntitlementBalances || EMPTY_ARRAY)
                .setIn(['entitlementsPaginationData', 'pageCount'], payload.EntitlementPagination && payload.EntitlementPagination.PageCount)
                .setIn(['entitlementsPaginationData', 'recordCount'], payload.EntitlementPagination && payload.EntitlementPagination.RecordCount)
                .set('isFetchingEntitlements', false);
        }
        case RETRIEVE_SHARED_ENTITLEMENTS.FAILURE:
            return state.set('isFetchingEntitlements', false);
        case RETRIEVE_ENTITLEMENT_USAGE.BEGIN:
            return state.set('isFetchingEntitlementUsage', true);
        case RETRIEVE_ENTITLEMENT_USAGE.SUCCESS:
            return state.set('entitlementUsages', payload.Services)
                .set('isFetchingEntitlementUsage', false)
                .set('pageCount', payload.PageCount)
                .set('recordCount', payload.RecordCount);
        case RETRIEVE_ENTITLEMENT_USAGE.FAILURE:
            return state.set('isFetchingEntitlementUsage', false)
                .set('pageCount', null)
                .set('recordCount', null);
        case SET_SELECTED_SHARED_ENTITLEMENT_INDEX:
            return state.set('selectedEntitlementIndex', payload);
        case SET_ENTITLEMENT_SHARE_STATUS:
            return state.setIn(['entitlements', payload.index, 'SharingStatus'], payload.sharingStatus);
        case SET_ENTITLEMENTS_PAGINATION_DATA:
            return state.setIn(['entitlementsPaginationData', 'pageNumber'], payload.pageNumber)
                .setIn(['entitlementsPaginationData', 'pageSize'], payload.pageSize);
        case SET_ENTITLEMENTS_SEARCH_TERM:
            return state.setIn(['entitlementsPaginationData', 'searchTerm'], payload);
        case UPDATE_ENTITLEMENT_SHARE_STATUS.BEGIN:
            return state.set('isUpdatingEntitlementShareStatus', true);
        case UPDATE_ENTITLEMENT_SHARE_STATUS.FAILURE:
        case UPDATE_ENTITLEMENT_SHARE_STATUS.SUCCESS:
            return state.set('isUpdatingEntitlementShareStatus', false);
        case RESET_SHARED_ENTITLEMENT_TAB_DATA:
            return INITIAL_STATE;
        default:
            return state;
    }
}
