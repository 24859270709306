import Immutable from 'seamless-immutable';

import {EMPTY_OBJECT} from 'invision-core/src/constants/common.constants';

import {
    INIT_CUSTOM_WIDGET,
    SET_CUSTOM_WIDGET_HEIGHT,
    SET_CUSTOM_WIDGET_IS_EXPANDED,
    SET_CUSTOM_WIDGET_STATUS
} from './actions/custom.widgets.actions';

export const INITIAL_STATE = Immutable({
    widgets: EMPTY_OBJECT
});

export function CustomWidgetsReducer(state = INITIAL_STATE, {payload, type}) {
    switch (type) {
        case INIT_CUSTOM_WIDGET: {
            return state.setIn(['widgets', payload.id], payload);
        }
        case SET_CUSTOM_WIDGET_HEIGHT: {
            return state.setIn(['widgets', payload.id, 'height'], payload.height);
        }
        case SET_CUSTOM_WIDGET_IS_EXPANDED: {
            return state.setIn(['widgets', payload.id, 'isExpanded'], payload.isExpanded);
        }
        case SET_CUSTOM_WIDGET_STATUS: {
            return state.setIn(['widgets', payload.id, 'statusId'], payload.statusId);
        }
        default: {
            return state;
        }
    }
}
