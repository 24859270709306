import {ThunkHelper} from 'invision-core';

export const ISSUE_WRITE_OFF_CONSTANTS = {
    BEGIN: 'ISSUE_WRITE_OFF_BEGIN',
    SUCCESS: 'ISSUE_WRITE_OFF_SUCCESS',
    FAILURE: 'ISSUE_WRITE_OFF_FAILURE'
};

const ISSUE_WRITE_OFF_EVENTS = [ISSUE_WRITE_OFF_CONSTANTS.BEGIN, ISSUE_WRITE_OFF_CONSTANTS.SUCCESS, ISSUE_WRITE_OFF_CONSTANTS.FAILURE];

export const IssueWriteOff = (data) => {
    return (dispatch) => {
        return ThunkHelper(dispatch, ISSUE_WRITE_OFF_EVENTS, {
            method: 'POST',
            url: 'SubscriberManagement/ProcessWriteOff',
            data
        });
    };
};