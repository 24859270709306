import {
    ApiConstants,
    ThunkHelper
} from 'invision-core';

export const REGISTER_DEVICE = {
    BEGIN: 'REGISTER_DEVICE_BEGIN',
    SUCCESS: 'REGISTER_DEVICE_SUCCESS',
    FAILURE: 'REGISTER_DEVICE_FAILURE'
};

const REGISTER_DEVICE_TYPES = [
    REGISTER_DEVICE.BEGIN,
    REGISTER_DEVICE.SUCCESS,
    REGISTER_DEVICE.FAILURE
];

export const REMOVE_DEVICE = {
    BEGIN: 'REMOVE_DEVICE_BEGIN',
    SUCCESS: 'REMOVE_DEVICE_SUCCESS',
    FAILURE: 'REMOVE_DEVICE_FAILURE'
};

const REMOVE_DEVICE_TYPES = [
    REMOVE_DEVICE.BEGIN,
    REMOVE_DEVICE.SUCCESS,
    REMOVE_DEVICE.FAILURE
];

export const RETRIEVE_DEVICES = {
    BEGIN: 'RETRIEVE_DEVICES_BEGIN',
    SUCCESS: 'RETRIEVE_DEVICES_SUCCESS',
    FAILURE: 'RETRIEVE_DEVICES_FAILURE'
};

const RETRIEVE_DEVICES_TYPES = [
    RETRIEVE_DEVICES.BEGIN,
    RETRIEVE_DEVICES.SUCCESS,
    RETRIEVE_DEVICES.FAILURE
];

export const UPDATE_DEVICE = {
    BEGIN: 'UPDATE_DEVICES_BEGIN',
    SUCCESS: 'UPDATE_DEVICES_SUCCESS',
    FAILURE: 'UPDATE_DEVICES_FAILURE'
};

const UPDATE_DEVICE_TYPES = [
    UPDATE_DEVICE.BEGIN,
    UPDATE_DEVICE.SUCCESS,
    UPDATE_DEVICE.FAILURE
];

export const RETRIEVE_DEVICE_FINANCING_DETAILS = {
    BEGIN: 'RETRIEVE_DEVICE_FINANCING_DETAILS_BEGIN',
    SUCCESS: 'RETRIEVE_DEVICE_FINANCING_DETAILS_SUCCESS',
    FAILURE: 'RETRIEVE_DEVICE_FINANCING_DETAILS_FAILURE'
};

const RETRIEVE_DEVICE_FINANCING_DETAILS_TYPES = [
    RETRIEVE_DEVICE_FINANCING_DETAILS.BEGIN,
    RETRIEVE_DEVICE_FINANCING_DETAILS.SUCCESS,
    RETRIEVE_DEVICE_FINANCING_DETAILS.FAILURE
];

export const RETRIEVE_DEVICE_REFUND_AMOUNT = {
    BEGIN: 'RETRIEVE_DEVICE_REFUND_AMOUNT_BEGIN',
    SUCCESS: 'RETRIEVE_DEVICE_REFUND_AMOUNT_SUCCESS',
    FAILURE: 'RETRIEVE_DEVICE_REFUND_AMOUNT_FAILURE'
};

const RETRIEVE_DEVICE_REFUND_AMOUNT_TYPES = [
    RETRIEVE_DEVICE_REFUND_AMOUNT.BEGIN,
    RETRIEVE_DEVICE_REFUND_AMOUNT.SUCCESS,
    RETRIEVE_DEVICE_REFUND_AMOUNT.FAILURE
];

export const CREATE_DEVICE_ADJUSTMENT = {
    BEGIN: 'CREATE_DEVICE_ADJUSTMENT_BEGIN',
    SUCCESS: 'CREATE_DEVICE_ADJUSTMENT_SUCCESS',
    FAILURE: 'CREATE_DEVICE_ADJUSTMENT_FAILURE'
};

const CREATE_DEVICE_ADJUSTMENT_TYPE = [
    CREATE_DEVICE_ADJUSTMENT.BEGIN,
    CREATE_DEVICE_ADJUSTMENT.SUCCESS,
    CREATE_DEVICE_ADJUSTMENT.FAILURE
];

export const CLEAR_DEVICE_FORM = 'CLEAR_DEVICE_FORM';
export const clearDeviceForm = () => {
    return {
        type: CLEAR_DEVICE_FORM,
        payload: {}
    };
};

export const CLEAR_DEVICE_FILTERS = 'CLEAR_DEVICE_FILTERS';
export const clearDeviceFilters = () => {
    return {
        type: CLEAR_DEVICE_FILTERS
    };
};

export const CREATE_EDIT_DEVICE_FORM_CHANGED = 'CREATE_EDIT_DEVICE_FORM_CHANGED';
export const setDeviceForm = (form) => {
    return {
        type: CREATE_EDIT_DEVICE_FORM_CHANGED,
        payload: form
    };
};

const registerDevicePromise = (dispatch, customerId, data) => {
    return ThunkHelper(dispatch, REGISTER_DEVICE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RegisterDevice',
        data,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const registerDevice = (customerId, data) => {
    return (dispatch) => {
        return registerDevicePromise(dispatch, customerId, data);
    };
};

const removeDevicePromise = (dispatch, customerId, deviceId) => {
    return ThunkHelper(dispatch, REMOVE_DEVICE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/UnregisterDevice',
        data: {
            DeviceId: deviceId
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const removeDevice = (customerId, deviceId) => {
    return (dispatch) => {
        return removeDevicePromise(dispatch, customerId, deviceId);
    };
};

const retrieveCustomerDevicesPromise = (dispatch, customerId, includeRemoved) => {
    return ThunkHelper(dispatch, RETRIEVE_DEVICES_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveDevices',
        data: {
            IncludeRemoved: includeRemoved
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId
    });
};

export const retrieveCustomerDevices = (customerId, includeRemoved) => {
    return (dispatch) => {
        return retrieveCustomerDevicesPromise(dispatch, customerId, includeRemoved);
    };
};

export const SET_INCLUDED_FILTER = 'SET_DEVICE_INCLUDED_FILTER';
export const setIncludedFilter = (includeFilter) => {
    return {
        type: SET_INCLUDED_FILTER,
        payload: includeFilter
    };
};

export const SET_SELECTED_DEVICE = 'SET_SELECTED_DEVICE';
export const setSelectedDevice = (device) => {
    return {
        type: SET_SELECTED_DEVICE,
        payload: device
    };
};

const updateDevicePromise = (dispatch, customerId, data) => {
    return ThunkHelper(dispatch, UPDATE_DEVICE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/UpdateDevice',
        data: {
            SubscriberDevice: data
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const updateDevice = (customerId, data) => {
    return (dispatch) => {
        return updateDevicePromise(dispatch, customerId, data);
    };
};

export const retrieveSubscriberFinanceDetailsPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, RETRIEVE_DEVICE_FINANCING_DETAILS_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveSubscriberFinanceDetails',
        data: {},
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const retrieveSubscriberFinanceDetails = (customerId) => {
    return (dispatch) => {
        return retrieveSubscriberFinanceDetailsPromise(dispatch, customerId);
    };
};


export const retrieveDeviceRefundAmountPromise = (dispatch, subscriberProductId, customerId) => {
    return ThunkHelper(dispatch, RETRIEVE_DEVICE_REFUND_AMOUNT_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveDeviceRefundAmount',
        data: {
            SubscriberDeviceRefundDetails: [{
                SubscriberProductId: subscriberProductId
            }]
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const retrieveDeviceRefundAmount = (subscriberProductId, customerId) => {
    return (dispatch) => {
        return retrieveDeviceRefundAmountPromise(dispatch, subscriberProductId, customerId);
    };
};

export const createDeviceAdjustmentPromise = (dispatch, deviceDetails, customerId) => {
    return ThunkHelper(dispatch, CREATE_DEVICE_ADJUSTMENT_TYPE, {
        method: 'post',
        url: 'SubscriberManagement/CreateDeviceAdjustment',
        data: deviceDetails,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const createDeviceAdjustment = (deviceDetails, customerId) => {
    return (dispatch) => {
        return createDeviceAdjustmentPromise(dispatch, deviceDetails, customerId);
    };
};

export const SET_DEVICE_FINANCING_WIDGET_SELECTED_DEVICE = 'SET_DEVICE_FINANCING_WIDGET_SELECTED_DEVICE';
export const setDeviceFinancingWidgetSelectedDevice = (index) => {
    return {
        type: SET_DEVICE_FINANCING_WIDGET_SELECTED_DEVICE,
        payload: index
    };
};
