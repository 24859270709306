import {createSelector} from 'reselect';
import {SelectedCustomerSelector} from './customer.selectors';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {SUBSCRIBER_PASSWORD_CHALLENGE_RESPONSE_ACCESS} from '../../security.attributes';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';

export const IsFetchingChallengeAnswerSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.challenge.isFetchingChallengeAnswer;
    }
);

export const ChallengeAnswerSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.challenge.data;
    }
);

export const HasSubscriberPasswordAccessSelector = createImmutableSelector(
    [UserSecurityAttributesSelector],
    (securityAttributes) => {
        return hasAccess(securityAttributes, SUBSCRIBER_PASSWORD_CHALLENGE_RESPONSE_ACCESS);
    }
);
