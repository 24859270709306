import isNil from 'ramda/src/isNil';
import pathOr from 'ramda/src/pathOr';
import Immutable from 'seamless-immutable';
import {createSelector} from 'reselect';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {OrderAdditionalPropertyFieldsSelector} from 'invision-core/src/components/metadata/codes/codes.additional.properties.selectors';
import {additionalPropertiesMergeValuesIntoTemplate} from 'invision-core/src/components/metadata/codes/codes.helpers';
import {
    ALL_STEPS_KEYS,
    SERVICE_FEATURE_PRODUCT_STATUS_ACTIVE
} from '../constants/products.wizard.constants';
import {
    OrderQuoteIsValidSelector,
    OrderQuotePaymentInstrumentsSelector,
    ShippingMethodsViewModel,
    SkipToCheckoutSelector
} from './products.order.selectors';
import {ConvergentBillerAccountDetailsSelector} from './customer.convergent.biller.selectors';
import {RouteParams} from './customer.selectors';

const ProductsWizardStoreSelector = state => {
    return state.customercare.recoverableUiState.productsWizard;
};

export const FeatureOrderProductPricingPlanQuantitySelector = createSelector(
    [ConvergentBillerAccountDetailsSelector, RouteParams],
    (accountDetails, routeParams) => {
        const quantities = [];
        if (accountDetails && accountDetails.offerings) {
            accountDetails.offerings.forEach((offering) => {
                const features = pathOr([], ['ServiceFeatures'], offering);
                features.forEach((feature) => {
                    if (feature.ServiceAttributeValues && feature.ServiceAttributeValues.length && feature.ServiceAttributeValues[0].Value === routeParams.serviceId) {
                        const verifiedFeature = feature.ServiceAttributeValues.find((attribute) => {
                            return attribute.Value === routeParams.serviceId;
                        });
                        if (verifiedFeature && feature.Status === SERVICE_FEATURE_PRODUCT_STATUS_ACTIVE) {
                            quantities.push({
                                childItems: [],
                                productId: feature.ProductId,
                                productName: feature.ProductName,
                                pricingPlanId: feature.PricingPlanId,
                                subscriberProductId: feature.SubscriberProductId,
                                shoppingCartItemBillerRuleDetails: feature.BillerRulePrices && feature.BillerRulePrices.length ? [...feature.BillerRulePrices] : undefined
                            });
                        }
                    }
                });
            });
        }
        return quantities;
    }
);

export const IsShippingAddressSameAsBillingSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.isShippingAddressSameAsBilling;
    }
);

export const CurrentStepIndexSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        if (wizardStore && !isNil(wizardStore.currentStepIndex)) {
            return wizardStore.currentStepIndex;
        }
        return -1;
    }
);

export const PreviousStepIndexSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        if (wizardStore && !isNil(wizardStore.previousStepIndex)) {
            return wizardStore.previousStepIndex;
        }
        return -1;
    }
);

export const CurrentStepSelector = createSelector(
    [CurrentStepIndexSelector],
    (currentStepIndex) => {
        return ALL_STEPS_KEYS[currentStepIndex];
    }
);

export const IsProductsStepSelector = createSelector(
    [CurrentStepSelector],
    (currentStep) => {
        return currentStep === ALL_STEPS_KEYS[0];
    }
);

export const IsCheckoutStepSelector = createSelector(
    [CurrentStepSelector],
    (currentStep) => {
        return currentStep === ALL_STEPS_KEYS[1];
    }
);

export const ShowPreviousButtonSelector = createSelector(
    [IsProductsStepSelector, SkipToCheckoutSelector],
    (isProductsStep, skipToCheckout) => {
        return !isProductsStep && !skipToCheckout;
    }
);

export const CompletedStepsSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.completedSteps;
    }
);

export const SkippedStepSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.skippedSteps;
    }
);

export const CurrentStepIsInvalidSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.currentStepIsInvalid;
    }
);

export const SelectedTabIndexSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.selectedTabIndex;
    }
);

const DEFAULT_TAB_PANEL_VIEW_MODEL = Immutable([
    {
        glyph: 'shopping-cart',
        notificationCount: 0
    },
    {
        glyph: 'user',
        notificationCount: 0
    }
    // {
    //     glyph: 'file-text',
    //     notificationCount: 0
    // }
    // Commenting out since we are going implement in the future
]);
export const TabPanelViewModelSelector = createSelector(
    [SelectedTabIndexSelector],
    (selectedTabIndex) => {
        return DEFAULT_TAB_PANEL_VIEW_MODEL.map((item, index) => {
            return item.set('active', index === selectedTabIndex);
        });
    }
);

export const ChangeImmediateSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.changeImmediately;
    }
);

export const PaymentInstrumentsSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.paymentInstruments;
    }
);

export const PaymentInstrumentsCleanSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        const returnObject = wizardStore.paymentInstruments;
        if (returnObject && returnObject.length && returnObject[0]) {
            return returnObject[0].without(['Id', 'recordPayment', 'Locked']);
        }
        return returnObject;
    }
);

export const PaymentInstrumentIdsSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.paymentInstrumentIds;
    }
);

export const PaymentInstrumentIdsValidInQuoteSelector = createSelector(
    [PaymentInstrumentIdsSelector, OrderQuotePaymentInstrumentsSelector],
    (paymentInstrumentIds, orderQuotePaymentInstruments) => {
        if (orderQuotePaymentInstruments && orderQuotePaymentInstruments.length) {
            const validPaymentInstrumentIds = [];
            paymentInstrumentIds.forEach((paymentInstrumentId) => {
                const foundPayment = orderQuotePaymentInstruments.find((orderQuotePaymentInstrument) => {
                    return orderQuotePaymentInstrument.Id === paymentInstrumentId;
                });
                if (!foundPayment || (foundPayment && foundPayment.Amount)) {
                    validPaymentInstrumentIds.push(paymentInstrumentId);
                }
            });
            return Immutable(validPaymentInstrumentIds);
        }
        return paymentInstrumentIds;
    }
);

export const ProductOrderShippingAddressIdSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.shippingAddressId;
    }
);

export const IsShippingMethodsAvailableSelector = createSelector(
    [
        OrderQuoteIsValidSelector,
        ProductOrderShippingAddressIdSelector,
        ShippingMethodsViewModel
    ],
    (isQuoteValid, selectedShippingAddressId, shippingMethods) => {
        return !!(isQuoteValid && selectedShippingAddressId && shippingMethods.length);
    }
);

export const ProductOrderShippingMethodIdSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.shippingMethodId;
    }
);

export const ProductWizardIsGiftOrderSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.isGiftOrder || false;
    }
);

export const ProductWizardGiftOrderInfoSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.giftOrderInfo || {};
    }
);

export const ProductWizardOrderDetailsSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.orderDetails;
    }
);

export const IsProductWizardRestoreOrderWaivingFeeSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.waiveFee || false;
    }
);

export const IsProductWizardRestoreOrderWaivingLateFeeSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.waiveLateFee || false;
    }
);

export const ProductWizardRestoreOrderWaiveFeeReasonSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.waiveFeeReason || null;
    }
);

export const ProductWizardRestoreOrderWaiveLateFeeReasonSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.waiveLateFeeReason || null;
    }
);

export const ProductWizardTemporaryShipToNameSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.temporaryShipToName;
    }
);

export const OttExecutionOptionsSelector = createSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return Immutable(wizardStore.executionOptions);
    }
);

export const OrderAdditionalPropertiesSelector = createImmutableSelector(
    [ProductsWizardStoreSelector],
    (wizardStore) => {
        return wizardStore.orderAdditionalProperties;
    }
);

export const OrderAdditionalPropertyListMapSelector = createImmutableSelector(
    [OrderAdditionalPropertiesSelector, OrderAdditionalPropertyFieldsSelector],
    (additionalPropertyValues, additionalPropertyFields) => {
        return additionalPropertiesMergeValuesIntoTemplate(additionalPropertyFields, additionalPropertyValues);
    }
);