import {createSelector} from 'reselect';
import {values} from 'ramda';
import {
    getSearchFieldsTemplate
} from '../../components/search/search.constants';
import {SearchSelector} from './search.selectors';
import {getCustomerIdentifier} from '../../reducers/helpers/customer.helper';
import {SubscriberStatesMapSelector} from '../../reducers/selectors/customer.selectors';

const CustomerSearchSelector = createSelector(
    [SearchSelector],
    (search) => {
        return search.customers;
    }
);

export const CustomerSearchMapSelector = createSelector(
    [
        CustomerSearchSelector,
        SubscriberStatesMapSelector
    ],
    (customers, stateMap) => {
        const map = {};
        values(customers.data).forEach((item) => {
            map[item.Id] = item.asMutable({
                deep:true
            });

            //Get the current stateName, either Global or BU specific
            const itemState = map[item.Id].State;
            map[item.Id].displayName = getCustomerIdentifier(item);
            map[item.Id].stateName = itemState >= 0 ? stateMap[itemState] : '';
        });

        return map;
    }
);

export const CustomerSearchArraySelector = createSelector(
    [CustomerSearchMapSelector],
    (customerSearchMap) => {
        return customerSearchMap ? (values(customerSearchMap) || []).sort((a, b) => {
            return a.sortIndex - b.sortIndex;
        }) : null;
    }
);

export const CustomerSearchRecordCountSelector = createSelector(
    [CustomerSearchSelector],
    (customers) => {
        return customers.recordCount;
    }
);

export const CustomerSearchCurrentPageNumberSelector = createSelector(
    [CustomerSearchSelector],
    (customers) => {
        return customers.pageNumber;
    }
);

export const CustomerSearchTotalPageCountSelector = createSelector(
    [CustomerSearchSelector],
    (customers) => {
        return customers.pageCount;
    }
);

const recoverableUIStateSelector = (state) => {
    return state.customercare.recoverableUiState.searchCustomers.customerSearchModel;
};

export const CustomerSearchModelSelector = createSelector(
    [recoverableUIStateSelector],
    (state) => {
        return state ? state : getSearchFieldsTemplate();
    }
);

export const CustomerSearchSuggestionsIsLoadingSelector = createSelector(
    [CustomerSearchSelector],
    (customers) => {
        return customers.suggestions.isLoading;
    }
);

export const CustomerSearchSuggestionsSelector = createSelector(
    [CustomerSearchSelector],
    (customers) => {
        return customers.suggestions.data.asMutable({
            deep: true
        });
    }
);
