import {createSelector} from 'reselect';
import {
    SelectedCustomerSelector,
    CurrentCustomerBillCycleSelector,
    CurrentCustomerNewBillCycleSelector
} from './customer.selectors';
import {BillCycleHelper} from 'invision-core';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {map} from 'ramda';

export const IsRetrievingBillCycleDetailsSelector = createSelector([
    SelectedCustomerSelector
], (customer) => {
    return customer.billCycle.isFetchingBillCycleDetails;
});

export const IsEvaluatingBillCyclesSelector = createSelector([
    SelectedCustomerSelector
], (customer) => {
    return customer.billCycle.isEvaluatingBillCycles;
});

export const CandidateBillCyclesSelector = createSelector([
    SelectedCustomerSelector
], (customer) => {
    return map(BillCycleHelper.addTranslatedDueDates, customer.billCycle.candidateBillCycles);
});

export const BillCycleDetailsSelector = createSelector([
    SelectedCustomerSelector, CurrentCustomerBillCycleSelector
], (customer, currentCustomerBillCycle) => {
    if (currentCustomerBillCycle) {
        return customer.billCycle.billCycleDetails;
    } else {
        return null;
    }
});

export const NewBillCycleDetailsSelector = createImmutableSelector([
    SelectedCustomerSelector, CurrentCustomerNewBillCycleSelector
], (customer, currentCustomerNewBillCycle) => {
    return currentCustomerNewBillCycle  ? customer?.billCycle?.newBillCycleDetails : null;
});

export const BillCycleDetailsLastRetrieveErrorSelector = createSelector([
    SelectedCustomerSelector
], (customer) => {
    return customer.billCycle.lastRetrieveError;
});
