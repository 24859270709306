import Immutable from 'seamless-immutable';
import * as CouponsActions from './../actions/customer.coupons.actions';
import * as RedemptionCodeActions from './../actions/coupon.redemption.code.actions';

export const INITIAL_STATE = Immutable({
    grantCouponsNameFilter: undefined,
    grantCouponsCurrentPageNumber: 1,
    grantedCouponsCurrentPageNumber: 1,
    grantedCouponsGrantDateEndFilter: undefined,
    grantedCouponsGrantDateStartFilter: undefined,
    grantedCouponsNameOrCodeFilter: undefined,
    grantedCouponsPageSizePreference: null,
    grantedCouponsRedemptionStatusFilter: undefined,
    pageSizePreference: null,
    redemptionCodeRedemptionsCurrentPageNumber: 1,
    redemptionCodeRedemptionsRedemptionDateEndFilter: undefined,
    redemptionCodeRedemptionsRedemptionDateStartFilter: undefined,
    redemptionCodeRedemptionsRedemptionPageSizePreference: null
});

export default function CustomerCouponsListsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case CouponsActions.SET_CURRENT_GRANT_COUPONS_PAGE:
            return state.set('grantCouponsCurrentPageNumber', payload);
        case CouponsActions.SET_GRANT_COUPONS_NAME_FILTER:
            return state.set('grantCouponsNameFilter', payload);
        case CouponsActions.SET_CURRENT_GRANTED_COUPONS_PAGE:
            return state.set('grantedCouponsCurrentPageNumber', payload);
        case CouponsActions.SET_COUPONS_PAGE_SIZE_PREFERENCE:
            return state.set('pageSizePreference', payload);
        case CouponsActions.SET_GRANTED_COUPONS_GRANT_DATE_END_FILTER:
            return state.set('grantedCouponsGrantDateEndFilter', payload);
        case CouponsActions.SET_GRANTED_COUPONS_GRANT_DATE_START_FILTER:
            return state.set('grantedCouponsGrantDateStartFilter', payload);
        case CouponsActions.SET_GRANTED_COUPONS_NAME_OR_CODE_FILTER:
            return state.set('grantedCouponsNameOrCodeFilter', payload);
        case CouponsActions.SET_GRANTED_COUPONS_REDEMPTION_STATUS_FILTER:
            return state.set('grantedCouponsRedemptionStatusFilter', payload);
        case CouponsActions.REMOVE_COUPONS_FILTER:
            return INITIAL_STATE;
        case CouponsActions.SET_GRANTED_COUPONS_PAGE_SIZE_PREFERENCE:
            return state.set('grantedCouponsPageSizePreference', payload);
        case RedemptionCodeActions.SET_REDEMPTION_CODE_REDEMPTIONS_CURRENT_PAGE:
            return state.set('redemptionCodeRedemptionsCurrentPageNumber', payload);
        case RedemptionCodeActions.SET_REDMEPTION_CODE_REDEMPTIONS_DATE_END_FILTER:
            return state.set('redemptionCodeRedemptionsRedemptionDateEndFilter', payload);
        case RedemptionCodeActions.SET_REDMEPTION_CODE_REDEMPTIONS_DATE_START_FILTER:
            return state.set('redemptionCodeRedemptionsRedemptionDateStartFilter', payload);
        case RedemptionCodeActions.SET_REDEMPTION_COUPONS_PAGE_SIZE_PREFERENCE:
            return state.set('redemptionCodeRedemptionsRedemptionPageSizePreference', payload);
        default:
            return state;
    }
}
