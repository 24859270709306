import {i18n} from 'invision-core';
import ReportingLocaleKeys from '../../locales/keys';

/**
 * @see {@link https://qa-documentation.ascendon.tv/v17.4/data.aspx?type=ContentDirect.CoreService.Contract.Data.Subscriber.StoredValueAccountRechargePolicy&assembly=ContentDirect.CoreService.Contract}
 */
export const StoredValueAcccountRechargeTypeEnum = {
    frequencyByDay: 3,
    manually: 4,
    monthly: 2,
    whenEmpty: 1,
};


let _labelLookup = null;
export const toLabel = (rechargeType) => {
    if (_labelLookup === null) {
        _labelLookup = {
            [ StoredValueAcccountRechargeTypeEnum.frequencyByDay ]: i18n.translate(ReportingLocaleKeys.E_WALLET.STORED_VALUE_ACCOUNT.RECHARGE_TYPES.FREQUENCY_BY_DAY.TEXT),
            [ StoredValueAcccountRechargeTypeEnum.manually ]: i18n.translate(ReportingLocaleKeys.E_WALLET.STORED_VALUE_ACCOUNT.RECHARGE_TYPES.MANUALLY),
            [ StoredValueAcccountRechargeTypeEnum.monthly ]: i18n.translate(ReportingLocaleKeys.E_WALLET.STORED_VALUE_ACCOUNT.RECHARGE_TYPES.MONTHLY),
            [ StoredValueAcccountRechargeTypeEnum.whenEmpty ]: i18n.translate(ReportingLocaleKeys.E_WALLET.STORED_VALUE_ACCOUNT.RECHARGE_TYPES.WHEN_EMPTY)
        };
    }
    return _labelLookup[rechargeType];
};
