import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {
    EMPTY_ARRAY,
    EMPTY_OBJECT
} from 'invision-core/src/constants/common.constants';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {MetadataCodeTypeDictionarySelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import i18n from 'invision-core/src/components/i18n/i18n';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {UOM_CATEGORIES} from '../../../src/customercare.constants';

const EntitlementBalanceUpdateModalStoreSelector = (state) => {
    return state.customercare.entitlementBalanceUpdateModal;
};

export const IsLoadingEntitlementBalancesSelector = createImmutableSelector(
    [EntitlementBalanceUpdateModalStoreSelector],
    (entitlementBalances) => {
        return entitlementBalances.isLoading;
    }
);

export const ServiceEntitlementsSelector = createImmutableSelector(
    [EntitlementBalanceUpdateModalStoreSelector],
    (entitlementBalances) => {
        return entitlementBalances.data.ServiceEntitlementBalances ? entitlementBalances.data.ServiceEntitlementBalances : EMPTY_ARRAY;
    }
);

export const SharedEntitlementsSelector = createImmutableSelector(
    [EntitlementBalanceUpdateModalStoreSelector],
    (entitlementBalances) => {
        return entitlementBalances.data.SharedEntitlementBalances ? entitlementBalances.data.SharedEntitlementBalances : EMPTY_ARRAY;
    }
);

export const SelectedEntitlementSelector = createImmutableSelector(
    [EntitlementBalanceUpdateModalStoreSelector],
    (store) => {
        return store.selectedEntitlement || EMPTY_OBJECT;
    }
);

export const UnitOfMeasuresSelector = createImmutableSelector(
    [MetadataCodeTypeDictionarySelector(CODES.UnitsOfMeasure), SelectedEntitlementSelector],
    (unitOfMeasures, selectedEntitlement) => {
        const unitOfMeasuresOptions =  [{
            label: i18n.translate(CoreLocaleKeys.SELECT),
            value: null
        }];
        const selectedEntitlementUomCategory = Object.values(unitOfMeasures)?.find((unitOfMeasure) => {
            return selectedEntitlement.BalanceUnitCode === unitOfMeasure.Value;
        })?.AdditionalProperties?.category;

        return Object.keys(unitOfMeasures).length && selectedEntitlementUomCategory
            ? unitOfMeasuresOptions.concat(Object.values(unitOfMeasures).filter((uom) => {
                if (selectedEntitlementUomCategory === UOM_CATEGORIES.DATA_UNIVERSAL) {
                    return uom.AdditionalProperties.category.includes('Data');
                } else if (selectedEntitlementUomCategory === UOM_CATEGORIES.DATA_IT ||
                    selectedEntitlementUomCategory === UOM_CATEGORIES.DATA_SCIENTIFIC ||
                    selectedEntitlementUomCategory === UOM_CATEGORIES.DATA_TELCO) {
                    return uom.AdditionalProperties.category === selectedEntitlementUomCategory ||
                        uom.AdditionalProperties.category === UOM_CATEGORIES.DATA_UNIVERSAL;
                } else {
                    return uom.AdditionalProperties.category === selectedEntitlementUomCategory;
                }
            }).map((unit) => {
                return {
                    label: selectedEntitlementUomCategory === UOM_CATEGORIES.DATA_UNIVERSAL ?
                        `${unit.AdditionalProperties.short_name}\u00A0\u00A0${unit.Description}` :
                        unit.AdditionalProperties.short_name,
                    value: convertStringToNumber(unit.Value)
                };
            }))
            : EMPTY_ARRAY;
    }
);
