import Immutable from 'seamless-immutable';
import isEmpty from 'ramda/src/isEmpty';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {
    getOptionBillerRulesGroupedByType,
    getOptionHasEntitlementBillerRuleOnly
} from '../../components/customer/dashboard/dbssDashboard/offering/offering.component.helper';
import {OFFERING_OPTION_STATUSES} from '../../components/shared/constants/offering.option.status.constants';
import LocaleKeys from '../../locales/keys';

export const getResponsiblePartyName = (responsibleParty) => {
    if (!responsibleParty.CompanyName) {
        if (!responsibleParty.FirstName && !responsibleParty.LastName) {
            return i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.NA);
        } else {
            if (responsibleParty.FirstName && responsibleParty.LastName) {
                return `${responsibleParty.FirstName} ${responsibleParty.LastName}`;
            }
            return responsibleParty.FirstName || responsibleParty.LastName;
        }
    }
    return responsibleParty.CompanyName;
};

export const transformRedirectablesFromExistingRedirects = (redirectables, redirectedServices, nodesMapBySubscriberId, currentCustomerId) => {
    return redirectables.map((offer) => {
        let hasRedirectionsOutsideContext = false;
        let redirectedOutsideContextMessage = null;

        let transformedOptions = offer.Options.map((option) => {
            const optionService = option.ServiceAttributeValues && option.ServiceAttributeValues.length ? option.ServiceAttributeValues.find((serviceAttribute) => {
                return serviceAttribute.IsServiceIdentifier;
            }) : null;

            let isCurrentlyRedirectedToContext = false;
            let isSelected = false;

            if (optionService) {

                // Determine whether option's already redirected
                const redirectedService = redirectedServices.find((service) => {
                    return optionService.Value === service.ServiceIdentifier &&
                        option.PricingPlanId === service.PricingPlanId;
                });

                // Determine option's redirect context
                if (redirectedService) {
                    const currentCustomerIsDestinationCustomer = redirectedService.DestinationSubscriber.Id === currentCustomerId;
                    const nodeRedirectedTo = !currentCustomerIsDestinationCustomer && nodesMapBySubscriberId ? nodesMapBySubscriberId[redirectedService.DestinationSubscriber.Id] : null;

                    isCurrentlyRedirectedToContext = currentCustomerIsDestinationCustomer;

                    if (nodeRedirectedTo) {
                        if (!redirectedOutsideContextMessage) {
                            redirectedOutsideContextMessage = i18n.translate(LocaleKeys.REDIRECT_CHARGES.SERVICE_CURRENTLY_REDIRECTED, {
                                name: nodeRedirectedTo ? nodeRedirectedTo.NodeName : i18n.translate(CoreLocaleKeys.NOT_APPLICABLE)
                            });
                        }

                        if (!hasRedirectionsOutsideContext) {
                            hasRedirectionsOutsideContext = true;
                        }

                        isSelected = true;
                    } else if (currentCustomerIsDestinationCustomer) {
                        isSelected = true;
                    }
                }
            }

            return option.merge({
                isCurrentlyRedirectedToContext,
                isSelected
            });
        });

        // Disable offer's options if any are redirected outside current context
        // NOTE: An offer currently can only be redirected to 1 outside context at a time
        if (hasRedirectionsOutsideContext) {
            transformedOptions = transformedOptions.map((option) => {
                return option.merge({
                    isDisabled: true
                });
            });
        }

        return offer.merge({
            hasRedirectionsOutsideContext,
            Options: transformedOptions,
            redirectedOutsideContextMessage: offer.redirectedOutsideContextMessage || redirectedOutsideContextMessage
        });
    });
};

export const transformRedirectablesFromOptions = (redirectables) => {
    return redirectables.reduce((accumulator, offer) => {
        const ebriOnlyOptions = {};
        const pendingActiveOptions = {};

        let transformedOptions = offer.Options.reduce((accumulator, option) => {
            const optionBillerRulesGroupedByType = getOptionBillerRulesGroupedByType(option);
            const optionHasOnlyEbri = getOptionHasEntitlementBillerRuleOnly(optionBillerRulesGroupedByType);
            const optionIsPendingActive = option.Status === OFFERING_OPTION_STATUSES.PENDING_ACTIVE;

            const optionHasServiceIdentifier = option.ServiceAttributeValues && option.ServiceAttributeValues.some((serviceAttribute) => {
                return !!(serviceAttribute && serviceAttribute.IsServiceIdentifier);
            });

            if (optionIsPendingActive) {
                pendingActiveOptions[option.OfferingOptionPriceId] = option;
            }

            // Omit options that aren't "active", "pending active", or "pending removed"
            if (optionIsPendingActive ||
                option.Status === OFFERING_OPTION_STATUSES.ACTIVE ||
                option.Status === OFFERING_OPTION_STATUSES.PENDING_REMOVED) {
                if (optionHasOnlyEbri) {
                    ebriOnlyOptions[option.OfferingOptionPriceId] = option;
                }

                return accumulator.concat(option.merge({
                    isDisabled: optionIsPendingActive || !optionHasServiceIdentifier
                }));
            } else {
                return accumulator;
            }
        }, Immutable([]));

        // Omit options with EBRIs-only
        if (!isEmpty(ebriOnlyOptions)) {
            transformedOptions = transformedOptions.filter((option) => {
                return !ebriOnlyOptions[option.OfferingOptionPriceId];
            });
        }

        // Mark all instances of an option that has a "pending active" instance as disabled
        // since all instances of an option must be included in a redirected charge
        if (!isEmpty(pendingActiveOptions)) {
            transformedOptions = transformedOptions.map((option) => {
                return pendingActiveOptions[option.OfferingOptionPriceId] ?
                    option.merge({
                        isDisabled: true
                    }) :
                    option;
            });
        }

        // Omit offers without displayable options
        if (transformedOptions.length) {
            return accumulator.concat(offer.merge({
                hasRedirectionsOutsideContext: false,
                Options: transformedOptions,
                redirectedOutsideContextMessage: null
            }));
        } else {
            return accumulator;
        }
    }, Immutable([]));
};
