import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {RouteCurrentStateSelector} from 'invision-core/src/components/router/router.selectors';
import i18n from 'invision-core/src/components/i18n/i18n';
import {EMPTY_ARRAY} from '../constants/common.constants';
import {SearchSelector} from './search.selectors';
import {CurrentCustomerSelector} from './customer.selectors';
import {getCustomerIdentifier} from '../helpers/customer.helper';
import CustomerCareKeys from '../../locales/keys';
const ACCOUNT_HIERARCHY_URL_NAME = 'index.customercare.customer.accountHierarchy';
export const SearchOtherCustomersStoreSelector = createImmutableSelector(
    [SearchSelector],
    (search) => {
        return search.otherCustomers;
    });

export const CustomerSearchResultsViewModelSelector = createImmutableSelector(
    [SearchOtherCustomersStoreSelector, CurrentCustomerSelector, RouteCurrentStateSelector],
    (searchedCustomers, currentCustomer, routeCurrentState) => {
        return searchedCustomers.data.length ? searchedCustomers.data.map((customer) => {
            let isCustomerSelectionDisabled = currentCustomer.Id === (customer.Id || customer.SubscriberId);
            let tooltipMessage = (isCustomerSelectionDisabled && i18n.translate(CustomerCareKeys.SEARCH_OTHER_CUSTOMER.CANNOT_SELECT_CURRENT_CUSTOMER)) || undefined;
            if (routeCurrentState.name === ACCOUNT_HIERARCHY_URL_NAME && !isCustomerSelectionDisabled && !!customer.HierarchyId) {
                isCustomerSelectionDisabled = !!customer.HierarchyId;
                tooltipMessage = i18n.translate(CustomerCareKeys.ACCOUNT_HIERARCHY.THIS_CUSTOMER_IS_ALREADY_ASSOCIATED_TO_A_HIERARCHY);
            }
            return {
                id: customer.Id || customer.SubscriberId,
                companyName: customer.CompanyName,
                displayName: getCustomerIdentifier(customer),
                email: customer.Email,
                firstName: customer.FirstName,
                isDisabled: isCustomerSelectionDisabled,
                lastName: customer.LastName,
                preferredName: customer.PreferredName,
                state: customer.State,
                tooltipMessage
            };
        }) : EMPTY_ARRAY;
    });
