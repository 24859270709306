import {
    createSelector
} from 'reselect';
import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import {
    addressStateRegionProvinceValueOptionsForCountry
} from '../helpers/customer.selectors.helpers';
import propEq from 'ramda/src/propEq';
import find from 'ramda/src/find';
import Immutable from 'seamless-immutable';
import pathOr from 'ramda/src/pathOr';
import {PortInRequestAdditionalPropertiesSelector} from 'invision-core/src/components/metadata/codes/codes.additional.properties.selectors';
import {additionalPropertiesMergeValuesIntoTemplate} from 'invision-core/src/components/metadata/codes/codes.helpers';

const StoreSelector = (state) => {
    return state.customercare.recoverableUiState.mobileNumberPortability;
};

export const MobileNumberPortabilityAddressModelSelector = createSelector(
    [StoreSelector],
    (store) => {
        return store.addressModel;
    }
);

export const MobileNumberPortabilityModelSelector = createSelector(
    [StoreSelector],
    (store) => {
        return store.portabilityModel;
    }
);

export const MobileNumberPortabilityShowPortabilitySelector = createSelector(
    [StoreSelector],
    (store) => {
        return !!store.showPortability;
    }
);

export const IsResumingCartWithMnpData = createSelector(
    [StoreSelector],
    (store) => {
        return !!store.isResumingCartWithMnpData;
    }
);

export const MobileNumberPortabilityAttributesSelector = createSelector(
    [StoreSelector],
    (store) => {
        return Immutable(store.savedPortabilityAttributes);
    }
);

const EMPTY_REGEX = {
    regEx: '',
    mask: ''
};

export const PostalCodeRegexSelector = createSelector(
    [
        MobileNumberPortabilityAddressModelSelector,
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressCountry)
    ],
    (addressModel, codes) => {
        const country = addressModel.Country;
        if (country && codes.length > 0) {
            const countryObj = find(propEq(country, 'Value'))(codes);
            if (countryObj) {
                const regex = find(propEq('zip_code_reg_expression', 'Key'))(countryObj.AdditionalProperties).Value;
                const mask = find(propEq('zip_code_mask', 'Key'))(countryObj.AdditionalProperties).Value;

                return {
                    regEx: new RegExp(regex),
                    mask: mask
                };
            }
        }
        return EMPTY_REGEX;
    }
);

export const StateOptionsSelector = createSelector(
    [
        MobileNumberPortabilityAddressModelSelector,
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressCountry),
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressStateProvinceRegion)
    ],
    (addressData, countries, states) => {
        return addressStateRegionProvinceValueOptionsForCountry(addressData.Country, countries, states);
    }
);

export const MnpAdditionalPropertiesSelector = createSelector(
    [
        MobileNumberPortabilityModelSelector,
        PortInRequestAdditionalPropertiesSelector
    ],
    (portabilityModel, mnpAdditionalPropertyFields) => {

        const editedAdditionalProps = pathOr([], ['mnpAdditionalProps'], portabilityModel);
        return Immutable(additionalPropertiesMergeValuesIntoTemplate(mnpAdditionalPropertyFields, editedAdditionalProps));
    }
);
