import countBy from 'ramda/src/countBy';
import filter from 'ramda/src/filter';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import i18n from 'invision-core/src/components/i18n/i18n';
import {INTERNAL_STATUS} from 'invision-ui/lib/components/messaging/statusIndicator/inventoryInternalStatus/inventory.internal.status.types';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {PaymentInstrumentTypeSelector} from 'invision-core/src/components/metadata/codes/codes.selectors.deprecated';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {
    hasAccess,
    hasAdminAccess
} from 'invision-core/src/components/security/permission.service';
import {createSelector} from 'reselect';
import LocaleKeys from '../../locales/keys';
import {MutableTransactionDetailPaymentInstruments} from './customer.transactions.selectors';
import {ConvergentBillerOrderPaymentInstruments} from './customer.convergent.biller.selectors';
import {
    AvailableTypesMapSelector,
    IsAllPaymentInstrumentTypesAvailableSelector,
    MutablePaymentInstrumentsSelector,
    PaymentInstrumentsSelector,
    PaymentInstrumentsWithTypeNamesViewModel
} from './customer.ewallet.selectors';
import {isPaymentInstrumentTypeAvailable} from './customer.ewallet.selectors.helpers';
import {
    CurrentMemberHasCreatePaymentInstrumentPermissionsSelector
} from '../../reducers/selectors/customer.household.selectors';
import {
    E_WALLET_ADMIN_ACCESS,
    ISSUE_CREDIT_TO_ALTERNATE_PAYMENT_INSTRUMENT,
    REFUND_LIMIT_AND_ACCESS,
    RETURN_DEVICE_ACCESS
} from '../../security.attributes';
import {
    NEW_PAYMENT_INSTRUMENT,
    REFUND_BY_CHECK
} from '../../components/customer/applyCreditModal/apply.credit.modal.constants';
import {
    PaymentInstrumentConfigurationSelector
} from './modify.payment.method.popup.selectors';
import {isCreatablePaymentInstrumentType} from '../helpers/ewallet.reducer.helper';
import {SUPPORTED_PAYMENT_INSTRUMENT_TYPES} from '../../components/customer/ewallet/ewallet.constants';
import {SubscriberDeviceFinancingWidgetActiveDeviceDetailsSelector} from './customer.devices.selectors';
import {IsPIIDataAccessEnabledSelector} from './customer.selectors';

export const MutableCurrentPaymentInstrumentsSelector = createSelector(
    [
        MutableTransactionDetailPaymentInstruments,
        ConvergentBillerOrderPaymentInstruments
    ],
    (transactionPaymentInstruments, orderDetailPaymentInstruments) => {
        let paymentInstruments = transactionPaymentInstruments && transactionPaymentInstruments.length > 0 ?
            transactionPaymentInstruments : [];

        paymentInstruments = paymentInstruments && orderDetailPaymentInstruments && orderDetailPaymentInstruments.length > 0
            ? orderDetailPaymentInstruments : paymentInstruments;

        return paymentInstruments;
    }
);

export const CurrentPaymentInstrumentsTypeSelector = createSelector(
    [MutableCurrentPaymentInstrumentsSelector],
    (paymentInstruments) => {
        return paymentInstruments.map(({Type}) => {
            return Type;
        });
    }
);

export const CanAddPaymentInstruments = createSelector(
    [
        CurrentMemberHasCreatePaymentInstrumentPermissionsSelector,
        IsPIIDataAccessEnabledSelector,
        UserSecurityAttributesSelector
    ],
    (currentSubHasEditPermission, isPIIDataAccessEnabled, userSecurityAttributes) => {
        return hasAdminAccess(userSecurityAttributes, E_WALLET_ADMIN_ACCESS) &&
            currentSubHasEditPermission && isPIIDataAccessEnabled;
    }
);

export const CanCreateCurrentPaymentInstrumentTypes = createSelector(
    [
        PaymentInstrumentsSelector,
        CurrentPaymentInstrumentsTypeSelector,
        PaymentInstrumentConfigurationSelector,
    ],
    (paymentInstruments, paymentInstrumentTypes, paymentInstrumentConfigurations) => {
        const paymentInstrumentTypeCounts = countBy(prop('Type'))(paymentInstruments);
        const activePaymentInstrumentTypeCounts = countBy(prop('Type'))(filter(propEq(1, 'Status'))(paymentInstruments));

        const paymentInstrumentTypesCanCreate = paymentInstrumentTypes.map((type) => {
            const typeCount = paymentInstrumentTypeCounts[type];
            const typeActiveCount = activePaymentInstrumentTypeCounts[type];
            const typeConfiguration = paymentInstrumentConfigurations.find((config) => {
                return config && Number(config.paymentInstrumentTypeCode) === type;
            });
            const isMax = typeConfiguration &&
                typeConfiguration.alertMax > 0 &&
                typeCount >= typeConfiguration.alertMax;
            const isMaxActive = typeConfiguration &&
                typeConfiguration.alertMaxActive > 0 &&
                typeActiveCount >= typeConfiguration.alertMaxActive;
            const isNotOptedOut = isCreatablePaymentInstrumentType(type);

            return {
                Type: type,
                CanCreate: !(isMax || isMaxActive) && isNotOptedOut
            };
        });

        return paymentInstrumentTypesCanCreate.every(type => {
            return type.CanCreate;
        });
    }
);

export const IsLoyaltyAccount = createSelector(
    [MutableCurrentPaymentInstrumentsSelector],
    (paymentInstruments) => {
        return paymentInstruments.every(paymentInstrument => {
            return paymentInstrument && paymentInstrument.StoredValueAccount && paymentInstrument.StoredValueAccount.LoyaltyCard;
        });
    }
);

export const CanChangePaymentInstrument = createSelector(
    [
        UserSecurityAttributesSelector,
        IsLoyaltyAccount
    ],
    (userSecurityAttributes, isLoyaltyAccount) => {
        return hasAccess(userSecurityAttributes,
            ISSUE_CREDIT_TO_ALTERNATE_PAYMENT_INSTRUMENT) && !isLoyaltyAccount;

    }
);

export const MutablePaymentInstrumentsTypeFilterOptionsSelector = createSelector(
    [
        MutablePaymentInstrumentsSelector,
        CurrentPaymentInstrumentsTypeSelector,
        CanAddPaymentInstruments,
        CanCreateCurrentPaymentInstrumentTypes,
        PaymentInstrumentTypeSelector,
        IsAllPaymentInstrumentTypesAvailableSelector,
        AvailableTypesMapSelector
    ],
    (currentPaymentInstruments, paymentInstrumentTypes, canAddPaymentInstruments,
        canCreateCurrentPaymentInstrumentTypes, allPaymentInstrumentTypes, isAllPaymentInstrumentTypesAvailable, availablePaymentInstrumentTypes) => {
        const currentPaymentInstrumentsFiltered = currentPaymentInstruments.filter(({Type}) => {
            return paymentInstrumentTypes.includes(Type);
        }).map(({Id, Name}) => {
            return {
                value: Id,
                text: Name
            };
        });

        if (canAddPaymentInstruments && canCreateCurrentPaymentInstrumentTypes &&
            isPaymentInstrumentTypeAvailable(SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD, allPaymentInstrumentTypes,
                isAllPaymentInstrumentTypesAvailable, availablePaymentInstrumentTypes)) {
            currentPaymentInstrumentsFiltered.push({
                value: NEW_PAYMENT_INSTRUMENT,
                text: i18n.translate(LocaleKeys.ORDER_DETAILS.CREDIT.ADD_NEW_PAYMENT_METHOD)
            });
        }

        return currentPaymentInstrumentsFiltered;
    }
);

export const CanApplyCreditSelector = createImmutableSelector(
    [UserSecurityAttributesSelector],
    (userSecurityAttributes) => {
        return hasAccess(userSecurityAttributes, REFUND_LIMIT_AND_ACCESS) ;
    }
);

export const CanReturnDeviceSelector = createImmutableSelector(
    [UserSecurityAttributesSelector, SubscriberDeviceFinancingWidgetActiveDeviceDetailsSelector],
    (userSecurityAttributes, deviceDetails) => {
        if (deviceDetails && deviceDetails.SubscriberInventory) {
            return hasAccess(userSecurityAttributes, RETURN_DEVICE_ACCESS)
                && deviceDetails.SubscriberInventory.InventoryInternalStatus === INTERNAL_STATUS.ACTIVE;
        }
        return false;
    }
);

export const ApplyCreditFromOverviewPaymentOptionsSelector = createImmutableSelector(
    [
        PaymentInstrumentsWithTypeNamesViewModel,
        PaymentInstrumentTypeSelector,
        IsAllPaymentInstrumentTypesAvailableSelector,
        AvailableTypesMapSelector,
        IsDbss
    ],
    (currentPaymentInstruments, allPaymentInstrumentTypes,
        isAllPaymentInstrumentTypesAvailable, availablePaymentInstrumentTypes, isDbss) => {
        const creditCardOrEcheckPaymentInstruments = currentPaymentInstruments.filter((paymentInstrument) => {
            return `${paymentInstrument.Type}` === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD ||
                `${paymentInstrument.Type}` === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.E_CHECK ||
                `${paymentInstrument.Type}` === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_BILL;
        });

        const paymentInstrumentOptions = creditCardOrEcheckPaymentInstruments.map(({Id, Name}) => {
            return {
                value: Id,
                text: Name
            };
        });

        if (isPaymentInstrumentTypeAvailable(SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CHECK_REFUND, allPaymentInstrumentTypes,
            isAllPaymentInstrumentTypesAvailable, availablePaymentInstrumentTypes) && isDbss) {
            paymentInstrumentOptions.push({
                value: REFUND_BY_CHECK,
                text: i18n.translate(LocaleKeys.ORDER_DETAILS.CREDIT.REFUND_BY_CHECK)
            });
        }
        return paymentInstrumentOptions;
    }
);



