import Immutable from 'seamless-immutable';
import {
    RETRIEVE_USAGE_DETAILS_GROUPED_ENTITLEMENTS,
    RETRIEVE_USAGE_DETAILS_SERVICE_ENTITLEMENTS,
    RETRIEVE_USAGE_DETAILS_SHARED_ENTITLEMENTS
} from './actions/usage.details.actions';

export const INITIAL_STATE = Immutable({
    groupedEntitlements: [],
    isLoading: false,
    serviceEntitlements: [],
    sharedEntitlements: []
});

export default function usageDetailsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case RETRIEVE_USAGE_DETAILS_SERVICE_ENTITLEMENTS.BEGIN:
            return state.set('isLoading', true);
        case RETRIEVE_USAGE_DETAILS_SERVICE_ENTITLEMENTS.SUCCESS:
            return state.set('serviceEntitlements', payload.ServiceEntitlementBalances)
                .set('isLoading', false);
        case RETRIEVE_USAGE_DETAILS_SERVICE_ENTITLEMENTS.FAILURE:
            return state.set('isLoading', false);
        case RETRIEVE_USAGE_DETAILS_GROUPED_ENTITLEMENTS.BEGIN:
            return state.set('isLoading', true);
        case RETRIEVE_USAGE_DETAILS_GROUPED_ENTITLEMENTS.SUCCESS:
            return state.set('groupedEntitlements', payload.SharedEntitlementBalances)
                .set('isLoading', false);
        case RETRIEVE_USAGE_DETAILS_GROUPED_ENTITLEMENTS.BEGIN:
            return state.set('isLoading', true);
        case RETRIEVE_USAGE_DETAILS_SHARED_ENTITLEMENTS.BEGIN:
            return state.set('isLoading', true);
        case RETRIEVE_USAGE_DETAILS_SHARED_ENTITLEMENTS.SUCCESS:
            return state.set('sharedEntitlements', payload.SharedEntitlementBalances)
                .set('isLoading', false);
        case RETRIEVE_USAGE_DETAILS_SHARED_ENTITLEMENTS.BEGIN:
            return state.set('isLoading', true);
        default:
            return state;
    }
}
