import {ApiConstants, ThunkHelper} from 'invision-core';

export const RETRIEVE_ACCOUNT_STATUS_CONSTANTS = {
    BEGIN: 'RETRIEVE_ACCOUNT_STATUS_BEGIN',
    SUCCESS: 'RETRIEVE_ACCOUNT_STATUS_SUCCESS',
    FAILURE: 'RETRIEVE_ACCOUNT_STATUS_FAILURE'
};

const RETRIEVE_ACCOUNT_STATUS_EVENTS = [
    RETRIEVE_ACCOUNT_STATUS_CONSTANTS.BEGIN,
    RETRIEVE_ACCOUNT_STATUS_CONSTANTS.SUCCESS,
    RETRIEVE_ACCOUNT_STATUS_CONSTANTS.FAILURE
];

export const retrieveCustomerAccountStatusPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, RETRIEVE_ACCOUNT_STATUS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveSubscriberStatistics',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const retrieveCustomerAccountStatus = (customerId) => {
    return (dispatch) => {
        return retrieveCustomerAccountStatusPromise(dispatch, customerId);
    };
};

export const SET_AUTO_PAY_BANNER_FLAG = 'SET_AUTO_PAY_BANNER_FLAG';
export const setCustomerAutoPayBannerFlag = (page) => {
    return {
        type: SET_AUTO_PAY_BANNER_FLAG,
        payload: page
    };
};
