import {createSelector} from 'reselect';
import {SelectedCustomerSelector} from './customer.selectors';

export const CustomerTotalsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        if (selectedCustomer.totals) {
            return selectedCustomer.totals.data;
        } else {
            return null;
        }
    }
);

export const CustomerTotalsCollectionSelector = createSelector(
    [CustomerTotalsSelector],
    (data) => {
        if (data && data.Totals) {
            return data.Totals;
        } else {
            return null;
        }
    }
);

export const IsFetchingTotalsDataSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        if (selectedCustomer.totals) {
            return selectedCustomer.totals.isFetchingData;
        } else {
            return null;
        }
    }
);

export const TotalsCountSelector = createSelector(
    [CustomerTotalsCollectionSelector],
    (totals) => {
        return totals ? totals.length : null;
    }
);
