import {createSelector} from 'reselect';
import pathOr from 'ramda/src/pathOr';
import {CustomerStoreSelector, IsPIIDataAccessEnabledSelector} from './customer.selectors';
import {
    SEARCH_DROPDOWN_CONSTANTS,
    TRANSACTION_RECEIPT_TAX_BREAKOUT_PROPERTY
} from '../../components/search/search.constants';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    CurrentBusinessUnitSelector,
    IsDbss
} from 'invision-core/src/components/session/businessunit.selectors';
import {
    SubscriberAdditionalPropertiesSelector
} from '../../components/createCustomer/additionalPropertiesSection/additional.properties.filtered.selector';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeDictionarySelector,
    MetadataCodeTypeSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {findByKey} from 'invision-core/src/utilities/ramda.helper';
import {getBoolOrDefault} from 'invision-core/src/components/helpers/bool.helper';
import CustomerCareLocaleKeys from '../../locales/keys';
import Immutable from 'seamless-immutable';
import {CUSTOMER_WRAPUP_PROPERTY} from '../constants/customer.wrapup.constants';
import {EMPTY_ARRAY} from '../constants/common.constants';

const EMPTY_OBJECT = Immutable({});

export const SearchSelector = createSelector(
    [CustomerStoreSelector],
    (customerStore) => {
        return customerStore.search;
    }
);

export const SearchTypeSelector = createSelector(
    [SearchSelector],
    (search) => {
        return search ? search.searchType : null;
    }
);

export const IsSearchPanelVisibleSelector = createSelector(
    [SearchSelector],
    (search) => {
        return search.isPanelVisible;
    }
);

export const SearchIsFetchingResultsSelector = createSelector(
    [SearchSelector],
    (search) => {
        return search.isFetchingResults;
    }
);

const BusinessUnitTemplateSelector = createSelector([
    MetadataCodeTypeDictionarySelector(CODES.BusinessUnitTemplate),
    CurrentBusinessUnitSelector
], (templateCodeType, currentBusinessUnit) => {
    const currentBusinessUnitId = currentBusinessUnit && currentBusinessUnit.id;
    const codes = templateCodeType && Object.values(templateCodeType) || EMPTY_ARRAY;

    if (!codes || !codes.length) {
        return null;
    }

    const currentBusinessUnitTemplate = codes.find((code) => {
        return code && code.AdditionalProperties && code.AdditionalProperties.BusinessUnitId === currentBusinessUnitId;
    }) || codes.find((code) => {
        return code.Global;
    });

    const template = currentBusinessUnitTemplate &&
        currentBusinessUnitTemplate.AdditionalProperties &&
        currentBusinessUnitTemplate.AdditionalProperties.Template;

    return template ? JSON.parse(template) : null;
});

export const SearchOptionsSelector = createSelector(
    [
        IsDbss,
        IsPIIDataAccessEnabledSelector,
        SearchTypeSelector,
        BusinessUnitTemplateSelector,
        SubscriberAdditionalPropertiesSelector
    ],
    (isDbss, isPIIDataAccessEnabled, searchType, template, additionalProperties) => {
        const billingItems = pathOr([], ['subNavigation', 'billing'], template);
        const hasTransactions = billingItems.includes('transactions');
        const searchableAdditionalProperties = additionalProperties.find((property) => {
            return property.isSearchable;
        });
        const options = [
            ...[
                {
                    id: SEARCH_DROPDOWN_CONSTANTS.INDIVIDUAL,
                    text: i18n.translate(CustomerCareLocaleKeys.INDIVIDUAL),
                    selected: false
                }
            ],
            ...(isDbss ? [{
                id: SEARCH_DROPDOWN_CONSTANTS.COMPANY,
                text: i18n.translate(CustomerCareLocaleKeys.COMPANY),
                selected: false
            }] : []),
            ...(isPIIDataAccessEnabled ? [{
                id: SEARCH_DROPDOWN_CONSTANTS.ADDRESS,
                text: i18n.translate(CustomerCareLocaleKeys.ADDRESS),
                selected: false
            }] : []),
            ...[
                {
                    id: SEARCH_DROPDOWN_CONSTANTS.BUSINESS,
                    text: i18n.translate(CustomerCareLocaleKeys.BUSINESS),
                    selected: false
                }
            ],
            ...(hasTransactions && isPIIDataAccessEnabled ? [{
                id: SEARCH_DROPDOWN_CONSTANTS.TRANSACTION,
                text: i18n.translate(CustomerCareLocaleKeys.TRANSACTION),
                selected: false
            }] : []),
            ...(searchableAdditionalProperties ? [{
                id: isDbss ? SEARCH_DROPDOWN_CONSTANTS.ADDITIONAL_PROPERTIES : SEARCH_DROPDOWN_CONSTANTS.ADDITIONAL_PROPERTIES,
                text: i18n.translate(isDbss ? CustomerCareLocaleKeys.ADDITIONAL_PROPERTIES : CustomerCareLocaleKeys.ADDITIONAL_PROPERTIES),
                selected: false
            }] : [])
        ];
        return options.map((option) => {
            return Object.assign({}, option, {
                selected: option.id === searchType
            });
        });
    }
);

export const SearchTypeTabsSelector = createSelector(
    [IsDbss],
    (isDbss) => {
        return getSearchTypeTabs(isDbss);
    }
);

const SearchErrorSelector = createSelector(
    [SearchSelector],
    (search) => {
        return search.searchError;
    }
);

const EMPTY_SEARCH_API_ERRORS = [];
export const SearchApiErrorSelector = createSelector(
    [SearchErrorSelector],
    (searchError) => {
        return searchError ? [searchError.message] : EMPTY_SEARCH_API_ERRORS;
    }
);

const getSearchTypeTabs = (isDbss) => {
    const tabData = [
        {
            id: SEARCH_DROPDOWN_CONSTANTS.INDIVIDUAL,
            active: true,
            glyph: 'logo_customer_care'
        }
    ];

    if (!isDbss) {
        tabData.push({
            id: SEARCH_DROPDOWN_CONSTANTS.GIFT_CARD,
            active: false,
            glyph: 'redeem-gift-orders-lg'
        });
        tabData.push({
            id: SEARCH_DROPDOWN_CONSTANTS.COUPON,
            active: false,
            glyph: 'discounts-lg'
        });
    }

    return tabData;
};

export const SubscriberSearchForCurrentBusinessUnitSelector = createSelector(
    [
        MetadataCodeLoadedSelector(CODES.SubscriberSearchConfig),
        MetadataCodeTypeSelector(CODES.SubscriberSearchConfig)
    ],
    (isSubscriberSearchConfigLoaded, subscriberSearchConfigCodes) => {
        let currentBusinessUnitCode = undefined;
        let globalCode = undefined;

        if (isSubscriberSearchConfigLoaded) {
            for (const code of subscriberSearchConfigCodes) {
                //Check if the given BU has a record and it's enabled
                if (code.Global) {
                    globalCode = code;
                } else {
                    currentBusinessUnitCode = code;
                    break;
                }
            }
        }
        return currentBusinessUnitCode || globalCode || EMPTY_OBJECT;
    }
);

export const IsCustomerWrapUpRequiredSelector = createSelector(
    [
        SubscriberSearchForCurrentBusinessUnitSelector
    ],
    (subscriberSearchConfigCode) => {
        let isCustomerWrapUpRequired = false;
        if (Object.keys(subscriberSearchConfigCode).length) {
            const customerWrapUpAttribute = findByKey(CUSTOMER_WRAPUP_PROPERTY, subscriberSearchConfigCode.AdditionalProperties);
            isCustomerWrapUpRequired = getBoolOrDefault(pathOr(null, ['Value'], customerWrapUpAttribute), false);
        }
        return isCustomerWrapUpRequired;
    }
);

export const IsTransactionReceiptTaxBreakoutEnabledSelector = createSelector(
    [
        SubscriberSearchForCurrentBusinessUnitSelector
    ],
    (subscriberSearchConfigCode) => {
        let isTransactionReceiptTaxBreakoutEnabled = false;
        if (Object.keys(subscriberSearchConfigCode).length) {
            const transactionReceiptTaxBreakout = findByKey(TRANSACTION_RECEIPT_TAX_BREAKOUT_PROPERTY, subscriberSearchConfigCode.AdditionalProperties);
            isTransactionReceiptTaxBreakoutEnabled = getBoolOrDefault(pathOr(null, ['Value'], transactionReceiptTaxBreakout), false);
        }
        return isTransactionReceiptTaxBreakoutEnabled;
    }
);

export const IsPredictiveSearchEnabledSelector = createSelector(
    [
        MetadataCodeLoadedSelector(CODES.SubscriberSearchConfig),
        MetadataCodeTypeSelector(CODES.SubscriberSearchConfig)
    ],
    (isSubscriberSearchConfigLoaded, subscriberSearchConfig) => {
        if (isSubscriberSearchConfigLoaded) {
            let globalCode, buCode;
            subscriberSearchConfig.forEach(code => {
                if (code.Global) {
                    globalCode = code;
                } else {
                    buCode = code;
                }
            });
            if (buCode) {
                return getBoolOrDefault(pathOr(false, ['AdditionalProperties', '0', 'Value'], buCode), false);
            } else {
                return getBoolOrDefault(pathOr(false, ['AdditionalProperties', '0', 'Value'], globalCode), false);
            }
        } else {
            return false;
        }
    }
);
