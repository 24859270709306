import {createSelector} from 'reselect';
import pathOr from 'ramda/src/pathOr';
import isEmpty from 'ramda/src/isEmpty';
import i18n from 'invision-core/src/components/i18n/i18n';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {formatCountryAndPostalCode} from 'invision-core/src/utilities/address.helper';
import {
    getUpperLimitAccess,
    hasAccess
} from 'invision-core/src/components/security/permission.service';
import {parseFilenameFromPath} from 'invision-core/src/components/upload/upload.helper';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import CustomerLocaleKeys from '../../locales/keys';
import {
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_ERROR_STATUSES,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_STATUSES,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES_WITH_RESULTS_SUMMARY,
    IMPORT_BULK_SERVICE_ATTRIBUTES_UPLOAD_STATUSES,
    MAX_NUM_IMPORT_UPLOADABLE_FILES,
    ORDER_TYPES
} from  '../../components/customer/orderHistory/orderDetails/order.details.constants';
import {
    ConvergentBillerOrderDetailsSelector,
    ConvergentBillerOrderPaymentInstruments
} from './customer.convergent.biller.selectors';
import {CurrentCustomerIdSelector} from './customer.selectors';
import {generateFormattedFileResult} from '../../components/customer/orderHistory/orderDetails/importBulkAttributesModal/import.bulk.attributes.modal.helper';
import {ISSUE_CREDIT_STANDARD_CURRENCY_LIMIT, OVERRIDE_BILLING_EFFECTIVE_DATE} from '../../security.attributes';
import {ORDER_STATUSES} from '../../../src/components/shared/constants/order.status.constants';
import {
    EMPTY_ARRAY,
    EMPTY_OBJECT
} from '../constants/common.constants';
import {
    BILLING_EFFECTIVE_DATE_ORDER_OPTIONS,
    SCHEDULED_ORDER_OPTIONS
} from '../../customercare.constants';

const recoverableUIStateSelector = (state) => {
    return state.customercare.recoverableUiState.orderDetails;
};

export const OrderDetailsImportServiceAttributesJobDetailsSelector = createSelector(
    [recoverableUIStateSelector],
    (recoverableUIStateSelector) => {
        return recoverableUIStateSelector.importServiceAttributesJobDetails;
    }
);

export const OrderDetailsImportServiceAttributesJobDetailsErrorSelector = createSelector(
    [recoverableUIStateSelector],
    (recoverableUIStateSelector) => {
        return recoverableUIStateSelector.importServiceAttributesJobDetailsError;
    }
);

export const OrderDetailsImportServiceAttributesJobPendingFilesSelector = createSelector(
    [recoverableUIStateSelector],
    (recoverableUIStateSelector) => {
        return recoverableUIStateSelector.importServiceAttributesJobPendingFiles;
    }
);

export const OrderDetailsImportServiceAttributesJobsPendingUpdatedStatusSelector = createSelector(
    [recoverableUIStateSelector],
    (recoverableUIStateSelector) => {
        return recoverableUIStateSelector.importServiceAttributesJobsPendingUpdatedStatus;
    }
);

const EMPTY_JOB_DETAILS_VIEW_MODEL = {
    importHistory: [],
    importHistorySummary: null,
    jobId: null,
    orderId: null,
    pendingFiles: [],
    pendingUpdatedStatus: false,
    pricingPlanId: null,
    pricingPlanName: null,
    statusId: null,
    subscriberId: null
};
export const OrderDetailsImportServiceAttributesJobDetailsViewModelSelector = createSelector(
    [CurrentCustomerIdSelector,
        OrderDetailsImportServiceAttributesJobDetailsSelector,
        OrderDetailsImportServiceAttributesJobPendingFilesSelector,
        OrderDetailsImportServiceAttributesJobsPendingUpdatedStatusSelector],
    (subscriberId, jobDetails, pendingFiles, pendingJobs) => {
        let jobDetailsViewModel;

        if (jobDetails) {
            const mutableJobDetails = jobDetails.asMutable({
                deep: true
            });
            const mutablePendingFiles = pendingFiles.asMutable({
                deep: true
            });
            const numJobExtracts = mutableJobDetails.BulkFileExtractDetail ? Math.max(0, mutableJobDetails.BulkFileExtractDetail.length) : 0;
            const lastJobExtractIndex = Math.max(0, numJobExtracts - 1);

            jobDetailsViewModel = Object.assign({}, EMPTY_JOB_DETAILS_VIEW_MODEL, {
                importHistory: [],
                jobId: mutableJobDetails.BulkExtractJobId,
                orderId: mutableJobDetails.OrderId,
                pendingFiles: mutablePendingFiles || [],
                pendingUpdatedStatus: !!(pendingJobs[mutableJobDetails.PricingPlanId]),
                pricingPlanId: mutableJobDetails.PricingPlanId,
                pricingPlanName: mutableJobDetails.PricingPlanName,
                subscriberId: subscriberId
            });

            if (0 === numJobExtracts) {
                jobDetailsViewModel.statusId = IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.NOT_STARTED;
            } else {
                mutableJobDetails.BulkFileExtractDetail.forEach((extract, index) => {
                    let errorDetailSummary = null;
                    let importResults = [];

                    if (extract.BulkExtractErrors) {
                        importResults = extract.BulkExtractErrors.map((error) => {
                            return generateFormattedFileResult(
                                IMPORT_BULK_SERVICE_ATTRIBUTES_UPLOAD_STATUSES.FAILURE,
                                error.ErrorDescription
                            );
                        });
                    } else if (IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.FINISHED === extract.Status) {
                        const formattedResult = generateFormattedFileResult(
                            IMPORT_BULK_SERVICE_ATTRIBUTES_UPLOAD_STATUSES.SUCCESS,
                            i18n.translate(CustomerLocaleKeys.ORDER_DETAILS.IMPORT_BULK_SERVICE_ATTRIBUTES.FILE_IMPORTED_SUCCESSFULLY)
                        );

                        importResults = [formattedResult];
                    } else if (-1 !== IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_STATUSES.indexOf(extract.Status)) {
                        const formattedResult = generateFormattedFileResult(
                            IMPORT_BULK_SERVICE_ATTRIBUTES_UPLOAD_STATUSES.PROGRESS,
                            i18n.translate(CustomerLocaleKeys.ORDER_DETAILS.IMPORT_BULK_SERVICE_ATTRIBUTES.FILE_PROCESSING)
                        );

                        importResults = [formattedResult];
                    }

                    if ((-1 !== IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_ERROR_STATUSES.indexOf(extract.Status)) && extract.BulkExtractErrorDetailFileSummary) {
                        errorDetailSummary = {
                            contentType: extract.BulkExtractErrorDetailFileSummary.ContentType,
                            fileLocation: extract.BulkExtractErrorDetailFileSummary.ErrorDetailFileLocation,
                            isErrorFileAvailable: extract.BulkExtractErrorDetailFileSummary.IsAvailable
                        };
                    }

                    if (lastJobExtractIndex === index) {
                        jobDetailsViewModel.statusId = extract.Status;

                        if (-1 !== IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES_WITH_RESULTS_SUMMARY.indexOf(extract.Status)) {
                            jobDetailsViewModel.importHistorySummary = {
                                dateAdded: extract.Added,
                                dateLastModified: extract.Modified,
                                numFailedItems: extract.FailureRecords,
                                numSuccessfulItems: extract.SuccessRecords,
                                numTotalItems: extract.TotalRecords
                            };
                        }
                    }

                    const file = {
                        dateAdded: extract.Added,
                        dateLastModified: extract.Modified,
                        errorDetailSummary: errorDetailSummary,
                        extractId: extract.BulkFileExtractId,
                        importResults: importResults,
                        name: parseFilenameFromPath(extract.FileLocation),
                        statusId: extract.Status
                    };

                    // NOTE: Job extracts are returned in ascending order by date added,
                    // but are displayed in descending order
                    jobDetailsViewModel.importHistory.unshift(file);
                });
            }
        }

        return jobDetailsViewModel || EMPTY_JOB_DETAILS_VIEW_MODEL;
    }
);

export const OrderDetailsImportServiceAttributesUploadConfigurationSelector = createSelector(
    [recoverableUIStateSelector],
    (recoverableUIStateSelector) => {
        return recoverableUIStateSelector.importServiceAttributesUploadConfiguration;
    }
);

export const OrderDetailsBulkServiceAttributesJobIsImportingSelector = createSelector(
    [recoverableUIStateSelector],
    (recoverableUIStateSelector) => {
        return recoverableUIStateSelector.isImportingServiceAttributes;
    }
);

export const OrderDetailsIsRetrievingImportServiceAttributesJobDetailsSelector = createSelector(
    [recoverableUIStateSelector],
    (recoverableUIStateSelector) => {
        return recoverableUIStateSelector.isRetrievingImportServiceAttributesJobDetails;
    }
);

export const OrderDetailsIsRetrievingImportServiceAttributesUploadConfigurationSelector = createSelector(
    [recoverableUIStateSelector],
    (recoverableUIStateSelector) => {
        return recoverableUIStateSelector.isRetrievingImportServiceAttributesUploadConfiguration;
    }
);

export const OrderDetailsIsSendingBulkServiceAttributesUploadNotificationSelector = createSelector(
    [recoverableUIStateSelector],
    (recoverableUIStateSelector) => {
        return recoverableUIStateSelector.isSendingBulkServiceAttributesUploadNotification;
    }
);

export const OrderDetailsIsExportingBulkServiceAttributesSelector = createSelector(
    [recoverableUIStateSelector],
    (recoverableUIStateSelector) => {
        return recoverableUIStateSelector.isExportingBulkServiceAttributes;
    }
);

export const OrderDetailsExportingBulkServiceAttributesErrorSelector = createSelector(
    [recoverableUIStateSelector],
    (recoverableUIStateSelector) => {
        return recoverableUIStateSelector.exportingBulkServicesAttributeError;
    }
);

export const OrderDetailsBulkServiceAttributesJobIsProcessingSelector = createSelector(
    [OrderDetailsImportServiceAttributesJobDetailsViewModelSelector],
    (jobDetailsViewModel) => {
        return -1 !== IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_STATUSES.indexOf(jobDetailsViewModel.statusId);
    }
);

export const OrderDetailsImportServiceAttributesFormSubmissionIsDisabledSelector = createSelector(
    [OrderDetailsImportServiceAttributesJobDetailsViewModelSelector,
        OrderDetailsBulkServiceAttributesJobIsImportingSelector,
        OrderDetailsIsRetrievingImportServiceAttributesJobDetailsSelector,
        OrderDetailsIsRetrievingImportServiceAttributesUploadConfigurationSelector,
        OrderDetailsIsSendingBulkServiceAttributesUploadNotificationSelector,
        OrderDetailsBulkServiceAttributesJobIsProcessingSelector],
    (jobDetailsViewModel, isImportingServiceAttributes, isRetrievingJobDetails,
        isRetrievingUploadConfig, isSendingUploadNotification, isProcessingServiceAttributes) => {
        const actionIsInProgress = isRetrievingUploadConfig ||
           isRetrievingJobDetails ||
           isImportingServiceAttributes ||
           isSendingUploadNotification ||
           isProcessingServiceAttributes;

        return actionIsInProgress ||
            !jobDetailsViewModel.jobId ||
            jobDetailsViewModel.pendingUpdatedStatus ||
            jobDetailsViewModel.statusId === IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.FINISHED ||
            jobDetailsViewModel.statusId === IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.FINISHED_WITH_ERRORS;
    }
);

export const OrderDetailsImportServiceAttributesFormEditingIsDisabledSelector = createSelector(
    [OrderDetailsImportServiceAttributesFormSubmissionIsDisabledSelector,
        OrderDetailsImportServiceAttributesJobDetailsViewModelSelector],
    (formSubmissionIsDisabled, jobDetailsViewModel) => {
        return formSubmissionIsDisabled ||
            jobDetailsViewModel.pendingFiles.length >= MAX_NUM_IMPORT_UPLOADABLE_FILES ||
            jobDetailsViewModel.statusId === IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.FINISHED_WITH_ERRORS;
    }
);

export const OrderDetailTabsSelector = createSelector(
    [recoverableUIStateSelector, ConvergentBillerOrderDetailsSelector],
    (orderDetailUiState, currentOrderDetails) => {
        const tabs = [{
            name: i18n.translate(CustomerLocaleKeys.ORDER_DETAILS.ORDER_DETAILS),
        }, {
            name: i18n.translate(CustomerLocaleKeys.ORDER_DETAILS.WORKFLOW),
        }];

        if (currentOrderDetails && currentOrderDetails.Type === ORDER_TYPES.GIFT) {
            tabs.push({
                name: i18n.translate(CustomerLocaleKeys.ORDER_DETAILS.NOTIFICATION_HISTORY),
            });
        }

        return tabs.map((tab, index) => {
            tab.active = index + 1 === orderDetailUiState.selectedTab;
            return tab;
        });
    }
);

export const OrderDetailsDataSelector = createImmutableSelector(
    [recoverableUIStateSelector],
    (orderDetails) => {
        return orderDetails.data;
    }
);

export const CurrentOrderBillingEffectiveDateSelector = createImmutableSelector(
    [ConvergentBillerOrderDetailsSelector],
    (currentOrderDetails) => {
        return currentOrderDetails && currentOrderDetails.Items && (currentOrderDetails.Items.find((item) => {
            return item.BillingEffectiveDate;
        }) || EMPTY_OBJECT).BillingEffectiveDate || null;
    }
);

export const SelectedOfferExecutionOptionsSelector = createImmutableSelector(
    [OrderDetailsDataSelector, CurrentOrderBillingEffectiveDateSelector, ConvergentBillerOrderDetailsSelector, UserSecurityAttributesSelector],
    (orderDetailsData, billingEffectiveDate, currentOrderDetails, userSecurityAttributes) => {
        return isEmpty(orderDetailsData.executionOptions) ?  {
            billingEffectiveDate: billingEffectiveDate,
            billingEffectiveIntention: billingEffectiveDate ?
                `${BILLING_EFFECTIVE_DATE_ORDER_OPTIONS.ON_SPECIFIED_DATE}` :
                hasAccess(userSecurityAttributes, OVERRIDE_BILLING_EFFECTIVE_DATE) ?
                    `${BILLING_EFFECTIVE_DATE_ORDER_OPTIONS.ON_ORDER_COMPLETION}` :
                    undefined,
            effective: `${SCHEDULED_ORDER_OPTIONS.ON_SPECIFIED_DATE}`,
            specifiedDate: pathOr(null, ['FutureDatedOrder', 'ExecutionDate'], currentOrderDetails)
        } : orderDetailsData.executionOptions;
    }
);

export const IsRetrievingOrderContextSelector = createImmutableSelector(
    [recoverableUIStateSelector],
    (orderDetails) => {
        return orderDetails.isRetrievingOrderContext;
    }
);

export const BillingEffectiveDateConfigurationSelector = createImmutableSelector(
    [OrderDetailsDataSelector],
    (data) => {
        return data.billingEffectiveDateConfiguration;
    }
);

export const BillingEffectiveDateSettingsSelector = createImmutableSelector(
    [BillingEffectiveDateConfigurationSelector],
    (configuration) => {
        return {
            minimumDate: configuration.MinBillingEffectiveDate,
            pastDateMaxLength: configuration.PastBillingEffectiveDateMaxLength
        };
    }
);

export const BillingEffectiveDateOptionsSelector = createImmutableSelector(
    [BillingEffectiveDateConfigurationSelector],
    (configuration) => {
        return configuration.OrderExecutionToBillingEffectiveDateIntentions || EMPTY_ARRAY;
    }
);


export const IsGiftRedemptionOrderSelector = createSelector(
    [ConvergentBillerOrderDetailsSelector],
    (currentOrderDetails) => {
        return ORDER_TYPES.GIFT_REDEMPTION === pathOr(null, ['Type'], currentOrderDetails);
    }
);

export const IsDbssOrderSelector = createSelector(
    [ConvergentBillerOrderDetailsSelector],
    (currentOrderDetails) => {
        return !!pathOr(false, ['Items', 0, 'PricingPlan', 'HasConvergentBillerExtension'], currentOrderDetails);
    }
);

export const OrderCanBeCreditedSelector = createSelector(
    [ConvergentBillerOrderDetailsSelector, ConvergentBillerOrderPaymentInstruments],
    (currentOrderDetails, currentOrderPaymentInstruments) => {
        const totalAmount = pathOr(0, ['Totals', 'NetTotalAmount'], currentOrderDetails);
        return !!(currentOrderPaymentInstruments.length && totalAmount > 0);
    }
);

export const CurrentOrderHasShippingAddressSelector = createSelector(
    [ConvergentBillerOrderDetailsSelector],
    (currentOrderDetails) => {
        return !!pathOr(false, ['ShippingAddress'], currentOrderDetails);
    }
);

export const FormattedShippingCityStateSelector = createSelector(
    [ConvergentBillerOrderDetailsSelector, CurrentOrderHasShippingAddressSelector],
    (currentOrderDetails, currentOrderHasShippingAddress) => {
        if (!currentOrderHasShippingAddress) {
            return '';
        }
        const {City = '', State = '', Country = '', PostalCode = ''} = currentOrderDetails.ShippingAddress || {};
        const countryAndPostalCode = formatCountryAndPostalCode(Country, PostalCode).countryAndPostalCode;
        return `${City ? `${City}, ` : ''}${State ? `${State}, ` : ''}${countryAndPostalCode}`;
    }
);

export const CreditOrderLimitSelector = createSelector(
    [UserSecurityAttributesSelector],
    (userSecurityAttributes) => {
        return getUpperLimitAccess(userSecurityAttributes, ISSUE_CREDIT_STANDARD_CURRENCY_LIMIT.id);
    }
);

export const CanIssueOrderCreditSelector = createSelector(
    [IsDbssOrderSelector, OrderCanBeCreditedSelector, CreditOrderLimitSelector],
    (isDbssOrder, orderCanBeCredited, creditOrderLimit) => {
        return !isDbssOrder && orderCanBeCredited && creditOrderLimit > 0;
    }
);

export const ShowOrderDetailsExecutionDate = createSelector(
    [ConvergentBillerOrderDetailsSelector],
    (orderDetails) => {
        return ORDER_STATUSES.PENDING === pathOr(null, ['FutureDatedOrder', 'Status'], orderDetails);
    }
);
