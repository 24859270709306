import {
    compose,
    defaultTo,
    isNil,
    toString
} from 'ramda';
import {createSelector} from 'reselect';
import i18n from 'invision-core/src/components/i18n/i18n';
import Configurable from 'invision-core/src/components/configurable/configurable';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {CurrentCustomerSelector} from 'invision-core/src/components/customer/customer.selectors';
import {getBoolOrDefault} from 'invision-core/src/components/helpers/bool.helper';
import {BusinessUnitCustomTilesTemplateSelector} from 'invision-core/src/components/configurable/configurable.selectors';
import {CUSTOM_TILE_TYPE} from 'invision-core/src/constants/custom.tiles.constants';
import {CustomerAccountStatusViewIsViewable, CustomerTemplateSelector} from './customer.actions.template.selectors';
import {CustomerAccountStatusSelector} from './customer.account.status.selectors';
import {CustomTilesResultSelector} from './custom.tiles.selectors';

export const CustomerAccountStatusViewIsExpandedSelector = createSelector(
    [
        CustomerAccountStatusViewIsViewable,
        CustomerTemplateSelector
    ],
    (hasAccountStatus, template) => {
        if (hasAccountStatus && template && template.accountStatusWidget) {
            return template.accountStatusWidget.isExpanded;
        }
        return true;
    }
);

export const CustomerAccountStatusViewHasTiles = createSelector(
    [
        CustomerAccountStatusViewIsViewable,
        CustomerAccountStatusSelector,
        CustomerTemplateSelector
    ],
    (hasAccountStatus, accountStatus, template) => {
        return Boolean(hasAccountStatus && accountStatus && template && template.accountStatusWidget
            && template.accountStatusWidget.tableData && template.accountStatusWidget.tableData.length);
    }
);

export const CustomerAccountStatusViewNameSelector = createSelector(
    [CustomerTemplateSelector],
    (template) => {
        return (template && template.accountStatusWidget) ? template.accountStatusWidget.Name : null;
    }
);

const EXAMPLE_REQUEST = 'http://numbersapi.com/';
export const CustomerAccountStatusViewModelSelector = createSelector(
    [
        CustomerAccountStatusViewIsViewable,
        CustomerAccountStatusSelector,
        CustomerTemplateSelector,
        CurrentCustomerSelector,
        BusinessUnitCustomTilesTemplateSelector,
        CustomTilesResultSelector
    ],
    (hasAccountStatus, accountStatus, template, currentCustomer, customTiles, customTilesResults) => {
        if (hasAccountStatus) {
            if (accountStatus && template && template.accountStatusWidget && template.accountStatusWidget.tableData) {
                const tileList = [];
                template.accountStatusWidget.tableData.forEach((key) => {
                    const tile = Configurable.CustomerCare.CUSTOMER_ACCOUNT_STATUS_CONSTANTS[key];
                    if (tile) {
                        tileList.push(Object.assign({}, tile, {
                            glyph: for_account_status_tiles[key].glyph,
                            isActionable: for_account_status_tiles[key].boolOrDefault(!!accountStatus[key], currentCustomer),
                            isEmphasized: (for_account_status_tiles[key].noEmphasis)? false : for_account_status_tiles[key].boolOrDefault(!!accountStatus[key], currentCustomer),
                            name: i18n.translate(tile.label),
                            value: for_account_status_tiles[key].defaultMethod(accountStatus[key], currentCustomer)
                        }));
                    } else {
                        const customTileConfig = Object.values(customTiles).filter(tile => {
                            return tile.Id === key;
                        })[0];
                        if (customTileConfig) {
                            const isExample = customTileConfig.EndPoint.indexOf(EXAMPLE_REQUEST) > -1;
                            const resultObj = (customTilesResults && customTilesResults[key]) ? customTilesResults[key] : {};
                            const defaultMethod = customTileConfig.Type === CUSTOM_TILE_TYPE.NUMBER ? defaultToZero : customTileConfig.Type === CUSTOM_TILE_TYPE.BOOLEAN ? yesOrNo : textOrDash;

                            tileList.push(Object.assign({}, customTileConfig, {
                                glyph: 'custom-widget',
                                hasError: Boolean(resultObj.hasError),
                                isActionable: false,
                                isCustom: true,
                                isEmphasized: false,
                                isExample,
                                method: isExample ? 'get' : 'post',
                                name: customTileConfig.Name,
                                responseParam: isExample ? 'number' : 'value',
                                value: defaultMethod(resultObj.value)
                            }));
                        }
                    }
                });
                return tileList;
            }
        }
        return null;
    }
);

export const textOrDash = (text) => {
    return text? text : '-';
};

export const defaultToZero = compose(toString, defaultTo(0));

export const yesOrNo = (bool) => {
    return bool ? i18n.translate(CoreLocaleKeys.YES): i18n.translate(CoreLocaleKeys.NO);
};

export const yesOrNoForLoginRevoked = (bool, currentCustomer) => {
    if (isNil(currentCustomer)) {
        return yesOrNo(bool);
    } else {
        return currentCustomer.LoginRevoked ? i18n.translate(CoreLocaleKeys.YES): i18n.translate(CoreLocaleKeys.NO);
    }
};

export const boolOrDefault = (bool) => {
    return getBoolOrDefault(bool);
};

export const boolOrDefaultForLoginRevoked = (bool, currentCustomer) => {
    if (isNil(currentCustomer)) {
        return boolOrDefault(bool);
    } else {
        return currentCustomer.LoginRevoked;
    }
};

const for_account_status_tiles = {
    [Configurable.CustomerCare.CUSTOMER_ACCOUNT_STATUS_CONSTANTS_IDS.DISCRETIONARY_DISCOUNTS_COUNT]: {
        glyph: 'percent',
        defaultMethod: defaultToZero,
        boolOrDefault
    },
    [Configurable.CustomerCare.CUSTOMER_ACCOUNT_STATUS_CONSTANTS_IDS.DEVICE_SESSION_PIN_BOOL]:  {
        glyph: 'devices',
        noEmphasis: true,
        defaultMethod: yesOrNo,
        boolOrDefault
    },
    [Configurable.CustomerCare.CUSTOMER_ACCOUNT_STATUS_CONSTANTS_IDS.EXPIRED_PI_COUNT]:  {
        glyph: 'credit-card-no-f',
        defaultMethod: defaultToZero,
        boolOrDefault
    },
    [Configurable.CustomerCare.CUSTOMER_ACCOUNT_STATUS_CONSTANTS_IDS.LOGIN_REVOKED_BOOL]:  {
        glyph: 'user',
        defaultMethod: yesOrNoForLoginRevoked,
        boolOrDefault: boolOrDefaultForLoginRevoked
    },
    [Configurable.CustomerCare.CUSTOMER_ACCOUNT_STATUS_CONSTANTS_IDS.OPEN_CASES_COUNT]:  {
        glyph: 'briefcase',
        defaultMethod: defaultToZero,
        boolOrDefault
    },
    [Configurable.CustomerCare.CUSTOMER_ACCOUNT_STATUS_CONSTANTS_IDS.OPEN_ORDERS_COUNT]:  {
        glyph: 'shopping-cart',
        defaultMethod: defaultToZero,
        boolOrDefault
    },
    [Configurable.CustomerCare.CUSTOMER_ACCOUNT_STATUS_CONSTANTS_IDS.PAYMENT_FAILURES_COUNT]:  {
        glyph: 'credit-card-alt',
        defaultMethod: defaultToZero,
        boolOrDefault
    }
};
