import thunkHelper from 'invision-core/src/api/thunk.helper';
import {CurrentCustomerIdSelector} from '../selectors/customer.selectors';
import {SUBSCRIBER_ID_HEADER} from 'invision-core/src/api/constants';


export const RETRIEVE_ENTITLEMENT_BALANCES = {
    BEGIN: 'RETRIEVE_ENTITLEMENT_BALANCES_BEGIN',
    SUCCESS: 'RETRIEVE_ENTITLEMENT_BALANCES_SUCCESS',
    FAILURE: 'RETRIEVE_ENTITLEMENT_BALANCES_FAILURE'
};

export const retrieveEntitlementBalances = (groupCode, serviceIdentifier, groupEntitlement) => {
    return (dispatch, getState) => {
        const state = getState();
        const customerId = CurrentCustomerIdSelector(state);
        return thunkHelper(dispatch, Object.values(RETRIEVE_ENTITLEMENT_BALANCES), {
            method: 'post',
            url: 'SubscriberManagement/RetrieveEntitlementBalances',
            headers: {
                [SUBSCRIBER_ID_HEADER]: customerId
            },
            data: {
                GroupCode : groupCode,
                GroupEntitlement: groupEntitlement,
                ServiceIdentifier: serviceIdentifier || null
            }
        });
    };
};

export const RESET_GROUPED_ENTITLEMENTS_MODAL_STORE = 'RESET_GROUPED_ENTITLEMENTS_MODAL_STORE';
export const resetGroupedEntitlementsModalStore = () => {
    return {
        type: RESET_GROUPED_ENTITLEMENTS_MODAL_STORE
    };
};

export const SET_GROUPED_ENTITLEMENTS_SELECTED_TAB = 'SET_GROUPED_ENTITLEMENTS_SELECTED_TAB';
export const setGroupedEntitlementsSelectedTab = (tab) => {
    return {
        type: SET_GROUPED_ENTITLEMENTS_SELECTED_TAB,
        payload: tab
    };
};