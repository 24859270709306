import Immutable from 'seamless-immutable';
import {
    SEARCH_SUBSCRIBER_OFFERINGS_CONSTANTS,
    SET_OFFERING_FILTER_DATA,
    SET_OFFERING_PAGINATION_DATA
} from '../actions/offering.order.actions';
export const INITIAL_STATE = Immutable({
    data: null,
    filterData: {
        filterBy: [],
        searchBy: null,
        searchTerm: null
    },
    paginationData: {
        pageCount: 0,
        pageNumber: 1,
        pageSize: 0,
        recordCount: 0
    }
});

export default function SearchSubscriberOfferingsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case SEARCH_SUBSCRIBER_OFFERINGS_CONSTANTS.BEGIN:
            return state.set('isLoading', true);
        case SEARCH_SUBSCRIBER_OFFERINGS_CONSTANTS.SUCCESS:
            return state
                .set('isLoading', false)
                .setIn(['paginationData', 'pageCount'], payload.PageCount)
                .setIn(['paginationData', 'recordCount'], payload.RecordCount)
                .set('data', payload.SubscriberOfferings);
        case SEARCH_SUBSCRIBER_OFFERINGS_CONSTANTS.FAILURE:
            return state.set('isLoading', false);
        case SET_OFFERING_FILTER_DATA:
            return state
                .setIn(['filterData', 'filterBy'], payload.filterBy)
                .setIn(['filterData', 'searchBy'], payload.searchBy)
                .setIn(['filterData', 'searchTerm'], payload.searchTerm);
        case SET_OFFERING_PAGINATION_DATA:
            return state
                .setIn(['paginationData', 'pageNumber'], payload.pageNumber)
                .setIn(['paginationData', 'pageSize'], payload.pageSize);
        default:
            return state;
    }
}
