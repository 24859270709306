import {createSelector} from 'reselect';

import {
    hasAccess,
    hasAdminAccess
} from 'invision-core/src/components/security/permission.service';


export const HasAdminAccessHelper = (inputSelectors, ACCESS_LEVEL) => {
    return createSelector(
        inputSelectors,
        (userSecurityAttributes) => {
            return hasAdminAccess(userSecurityAttributes, ACCESS_LEVEL);
        }
    );
};

export const HasAccessHelper = (inputSelectors, ACCESS_LEVEL) => {
    return createSelector(
        inputSelectors,
        (userSecurityAttributes) => {
            return hasAccess(userSecurityAttributes, ACCESS_LEVEL);
        }
    );
};
