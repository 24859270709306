import {createSelector} from 'reselect';
import {
    find,
    pathOr,
    propEq
} from 'ramda';
import {
    MetadataConstants,
    MetadataSelectors,
    SessionSelectors
} from 'invision-core';
import {MetadataCodeSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {EMPTY_ARRAY} from '../constants/common.constants';
import {SelectedCustomerSelector} from './customer.selectors';
import {pageNumberSelector} from './order.history.filter.selectors';
import i18n from 'invision-core/src/components/i18n/i18n';
import {getBoolOrDefault} from 'invision-core/src/components/helpers/bool.helper';
import CustomerCareKeys from '../../locales/keys';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';

const hasOrderHistory = function(selectedCustomer) {
    return (selectedCustomer && selectedCustomer.orderHistory);
};

export const ReasonCodesSelector = createImmutableSelector(
    [MetadataCodeSelector(CODES.ReasonCodes)],
    (serviceReasonCodes) => {
        return (serviceReasonCodes && serviceReasonCodes.items) ? serviceReasonCodes.items : EMPTY_ARRAY;
    }
);

export const IsFetchingOrdersSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return hasOrderHistory(selectedCustomer) ? selectedCustomer.orderHistory.isFetchingOrders : null;
    }
);

export const TotalPagesSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return hasOrderHistory(selectedCustomer) ? selectedCustomer.orderHistory.pageCount : null;
    }
);

export const RecordCountSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return hasOrderHistory(selectedCustomer) ? selectedCustomer.orderHistory.recordCount : null;
    }
);

export const StartRecordSelector = createSelector(
    [pageNumberSelector, SessionSelectors.PageSizePreferenceSelector],
    (pageNumber, pageSize) => {
        return ((pageNumber - 1) * pageSize) + 1;
    }
);

export const EndRecordSelector = createSelector(
    [RecordCountSelector, pageNumberSelector, SessionSelectors.PageSizePreferenceSelector],
    (recordCount, pageNumber, pageSize) => {
        const endNumber = (pageNumber * pageSize);
        if (endNumber < recordCount) {
            return endNumber;
        }
        return recordCount;
    }
);

export const OrdersSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return hasOrderHistory(selectedCustomer) ? selectedCustomer.orderHistory.data : null;
    }
);

export const IsRetrievingInventory = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return hasOrderHistory(selectedCustomer) ? selectedCustomer.orderHistory.isRetrievingInventory : null;
    }
);

export const RetrieveInventoryError = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return hasOrderHistory(selectedCustomer) ? selectedCustomer.orderHistory.retrieveInventoryError : null;
    }
);

export const TableDataSelector = createSelector(
    [OrdersSelector, ReasonCodesSelector, MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.OrderType)],
    (orders, reasonCodes, orderTypes) => {
        if (orders && orderTypes.length) {
            return orders.map((order) => {
                const orderTypeEval = propEq(order.Type.toString(), 'Value');
                const orderType = order.TransferDetail ? {
                    Name: i18n.translate(CustomerCareKeys.ORDER_DETAILS.TRANSFER_ORDER_TYPE)
                } : find(orderTypeEval, orderTypes);
                const reason = (reasonCodes || []).find(reasonCode => {
                    return order.ReasonCode &&  (reasonCode.Value === order.ReasonCode.toString());
                });
                return order
                    .set('OrderTypeName', orderType.Name)
                    .set('OrderReasonValue', reason ? reason.Name : null)
                    .set('OrderName', order.OrderDescription);
            });
        }

        return [];
    }
);

export const IsCancellingOrderSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(false, ['orderHistory', 'isCancellingOrder'], selectedCustomer);
    }
);

export const CancelOrderErrorSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(false, ['orderHistory', 'cancelOrderError'], selectedCustomer);
    }
);

export const GroupOrderFeatureVisibilitySelector = createSelector(
    [MetadataSelectors.codes.MetadataCodeTypeSelector(CODES.FeatureToggleConfig)],
    (feaureToggleCodes) => {
        if (feaureToggleCodes?.length) {
            const groupOrderingFeatureCodes= feaureToggleCodes.filter(featureToggleCode => {
                return findAdditonalProperty(featureToggleCode?.AdditionalProperties, 'GroupOrdering', 'Value');
            });
            const isGroupOrderingFeatureEnabled = findAdditonalProperty(groupOrderingFeatureCodes?.find((groupOrderingFeatureCode) => {
                return !groupOrderingFeatureCode.hasOwnProperty('Global');
            }
            )?.AdditionalProperties, 'enabled')?.Value === 'True' ? true : false;
            const isGroupOrderingFeatureGlobalValue = findAdditonalProperty(groupOrderingFeatureCodes?.find((groupOrderingFeatureCode) => {
                return groupOrderingFeatureCode.hasOwnProperty('Global');
            }
            )?.AdditionalProperties, 'enabled')?.Value === 'True' ? true : false;
            return isGroupOrderingFeatureEnabled || isGroupOrderingFeatureGlobalValue;
        }
        return false;
    }
);
function findAdditonalProperty(additionalProperties, targetProperty, type='Key') {
    const findProperty = (property) => {
        return property[type] === targetProperty;
    };
    return additionalProperties?.find(findProperty);
}
