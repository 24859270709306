import {
    ApiConstants,
    ThunkHelper
} from 'invision-core';

export const RETRIEVE_CHANGE_OFFER_ELIGIBILITY_RULES = {
    BEGIN: 'RETRIEVE_CHANGE_OFFER_ELEGIBILITY_RULES.BEGIN',
    SUCCESS: 'RETRIEVE_CHANGE_OFFER_ELEGIBILITY_RULES.SUCCESS',
    FAILURE: 'RETRIEVE_CHANGE_OFFER_ELEGIBILITY_RULES.FAILURE'
};

export const RETRIEVE_SUBSCRIBER_OFFERINGS = {
    BEGIN: 'RETRIEVE_SUBSCRIBER_OFFERINGS_BEGIN',
    SUCCESS: 'RETRIEVE_SUBSCRIBER_OFFERINGS_SUCCESS',
    FAILURE: 'RETRIEVE_SUBSCRIBER_OFFERINGS_FAILURE'
};

const RETRIEVE_CHANGE_OFFER_ELIGIBILITY_RULES_CONSTANTS = [
    RETRIEVE_CHANGE_OFFER_ELIGIBILITY_RULES.BEGIN,
    RETRIEVE_CHANGE_OFFER_ELIGIBILITY_RULES.SUCCESS,
    RETRIEVE_CHANGE_OFFER_ELIGIBILITY_RULES.FAILURE
];

export const retrieveChangeOfferEligibilityRulesPromise = (dispatch, customerId, offeringIds) => {
    return ThunkHelper(dispatch, RETRIEVE_CHANGE_OFFER_ELIGIBILITY_RULES_CONSTANTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveChangeOfferEligibilityRules',
        data: {
            OfferingInstanceIds: offeringIds
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const retrieveChangeOfferEligibilityRules = (customerId, offeringIds) => {
    return (dispatch) => {
        return retrieveChangeOfferEligibilityRulesPromise(dispatch, customerId, offeringIds);
    };
};
