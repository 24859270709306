export const RETURN_AUTO_ORDERS_FILTER_ID = 1;
export const RETURN_MIGRATED_ORDERS_FILTER_ID = 2;

export const UPDATE_ORDER_TYPE_FILTER = 'UPDATE_ORDER_TYPE_FILTER';
export const updateOrderTypeFilter = (data) => {
    return {
        type: UPDATE_ORDER_TYPE_FILTER,
        payload: data
    };
};

export const UPDATE_ORDER_KIND_FILTER = 'UPDATE_ORDER_KIND_FILTER';
export const updateOrderKindFilter = (data) => {
    return {
        type: UPDATE_ORDER_KIND_FILTER,
        payload: data
    };
};

export const UPDATE_ORDER_STATUS_FILTER = 'UPDATE_ORDER_STATUS_FILTER';
export const updateOrderStatusFilter = (data) => {
    return {
        type: UPDATE_ORDER_STATUS_FILTER,
        payload: data
    };
};

export const UPDATE_ORDER_HISTORY_PAGE_NUMBER = 'UPDATE_ORDER_HISTORY_PAGE_NUMBER';
export const updatePageNumber = (data) => {
    return {
        type: UPDATE_ORDER_HISTORY_PAGE_NUMBER,
        payload: data
    };
};

export const UPDATE_ORDER_PAGE_SIZE_PREFERENCE = 'UPDATE_ORDER_PAGE_SIZE_PREFERENCE';
export const updatePageSizePreference = (pageSize) => {
    return {
        type: UPDATE_ORDER_PAGE_SIZE_PREFERENCE,
        payload: pageSize
    };
};

export const UPDATE_ORDER_HISTORY_DATE_RANGE = 'UPDATE_ORDER_HISTORY_DATE_RANGE';
export const updateDateRange = (data) => {
    return {
        type: UPDATE_ORDER_HISTORY_DATE_RANGE,
        payload: data
    };
};

export const REMOVE_ORDER_HISTORY_FILTER = 'REMOVE_ORDER_HISTORY_FILTER';
export const removeOrderHistoryFilter = () => {
    return {
        type: REMOVE_ORDER_HISTORY_FILTER
    };
};
