import {
    RETRIEVE_USAGE_CAPS,
    UPDATE_USAGE_CAP_ELECTIONS,
    UPDATE_USAGE_CAPS_DETAILS
} from '../actions/customer.usage.caps.actions';
import {ThunkReducer} from 'invision-core';

export const RETRIEVE_USAGE_CAPS_PATH = ['retrieveUsageCaps'];
export const ALL_USAGE_CAPS_PATH = ['retrieveUsageCaps', 'data', 'AllServicesUsageCaps'];
export const USAGE_CAPS_ELECTIONS_PATH = ['retrieveUsageCaps', 'data', 'UsageCapElections'];
const thunkReducerPaths = [
    RETRIEVE_USAGE_CAPS_PATH,
    ALL_USAGE_CAPS_PATH,
    USAGE_CAPS_ELECTIONS_PATH
];

export const INITIAL_STATE = ThunkReducer.initializeState({
    isUpdatingData: false,
    isFormDirty: false
}, thunkReducerPaths);

export default function UsageCapsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    const localThunkReducer = ThunkReducer.getLocalThunkReducer(state, type, payload);
    switch (type) {
        case RETRIEVE_USAGE_CAPS.BEGIN:
        case RETRIEVE_USAGE_CAPS.FAILURE:
        case RETRIEVE_USAGE_CAPS.SUCCESS:
            return localThunkReducer({
                statePath: RETRIEVE_USAGE_CAPS_PATH,
                actionType: RETRIEVE_USAGE_CAPS
            });
        case UPDATE_USAGE_CAP_ELECTIONS.BEGIN:
            return state.set('isUpdatingData', true);
        case UPDATE_USAGE_CAP_ELECTIONS.FAILURE:
        case UPDATE_USAGE_CAP_ELECTIONS.SUCCESS:
            return state.set('isUpdatingData', false);
        case UPDATE_USAGE_CAPS_DETAILS:
            return state.setIn(['retrieveUsageCaps', 'data', 'UsageCapElections'], payload);
        default:
            return state;
    }
}
