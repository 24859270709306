import {ApiConstants,
    i18n,
    InjectorHelper,
    ThunkHelper} from 'invision-core';
import {stateGo} from 'redux-ui-router';
import LocaleKeys from '../../locales/keys';
import {NOTIFICATION_TIME_LENGTH} from '../../customercare.constants';

export const RETRIEVE_AVAILABLE_TOP_UPS = {
    BEGIN: 'RETRIEVE_AVAILABLE_TOP_UPS_BEGIN',
    SUCCESS: 'RETRIEVE_AVAILABLE_TOP_UPS_SUCCESS',
    FAILURE: 'RETRIEVE_AVAILABLE_TOP_UPS_FAILURE'
};
const RETRIEVE_AVAILABLE_TOP_UPS_EVENTS = [RETRIEVE_AVAILABLE_TOP_UPS.BEGIN, RETRIEVE_AVAILABLE_TOP_UPS.SUCCESS, RETRIEVE_AVAILABLE_TOP_UPS.FAILURE];

const retrieveAvailableTopUpsPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, RETRIEVE_AVAILABLE_TOP_UPS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveAvailableTopUps',
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const getAvailableTopUps = (customerId) => {
    return (dispatch) => {
        return retrieveAvailableTopUpsPromise(dispatch, customerId);
    };
};

export const SUBMIT_TOP_UP_REQUEST = {
    BEGIN: 'SUBMIT_TOP_UP_REQUEST_BEGIN',
    SUCCESS: 'SUBMIT_TOP_UP_REQUEST_SUCCESS',
    FAILURE: 'SUBMIT_TOP_UP_REQUEST_FAILURE'
};
const SUBMIT_TOP_UP_REQUEST_EVENTS = [SUBMIT_TOP_UP_REQUEST.BEGIN, SUBMIT_TOP_UP_REQUEST.SUCCESS, SUBMIT_TOP_UP_REQUEST.FAILURE];

const submitTopUpPromise = (dispatch, customerId, topUpDTO) => {
    return ThunkHelper(dispatch, SUBMIT_TOP_UP_REQUEST_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SubmitTopUp',
        data: {
            PaymentInstrumentIds: [topUpDTO.paymentInstrumentId],
            PricingPlanId: topUpDTO.pricingPlanId,
            ServiceIdentifier: topUpDTO.serviceIdentifier
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const submitTopUp = (customerId, topUpDTO) => {
    return (dispatch) => {
        return submitTopUpPromise(dispatch, customerId, topUpDTO).then(() => {
            dispatch(stateGo('index.customercare.customer.dashboard', {
                customerId: customerId
            }));
            InjectorHelper.getUiNotificationService().success(i18n.translate(LocaleKeys.TOP_UPS.SUCCESS), null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });
        });
    };
};
