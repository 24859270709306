import Immutable from 'seamless-immutable';
import pathOr from 'ramda/src/pathOr';
import {
    VALIDATE_ADDRESS_CONSTANTS,
    RESET_ADDRESS_COMPONENT,
    SET_ADDRESS_TO_BE_VALIDATED,
    SET_ADDRESS_KEY_FOR_ADDRESS_BEING_VALIDATED_THROUGH_API,
    SET_ADDRESS_KEY_FOR_ADDRESS_PREVIOUSLY_VALIDATED_THROUGH_API,
    SET_ADDRESS_TO_VALIDATED_ADDRESSES,
    SET_FORCE_SHOW_EDIT_FORM_ADDRESSES_COMPONENT,
    SET_SUGGESTED_ADDRESS_TO_VALIDATED_ADDRESSES,
    SET_EDIT_ADDRESS_BACK_TO_INVALID
} from './actions/address.component.actions';
import {generateKeyFromAddress} from './helpers/address.helper';
import {ADDRESS_STATUS_CONSTANTS} from '../components/customer/addresses/addresses.constants';

export const INITIAL_STATE = Immutable({
    addressBeingValidated: null,
    isForceShowEditForm: false,
    isValidating: false,
    lastJobToken: null,
    mduRange: null,
    needToValidate: false,
    previousAddressValidated: null,
    unvalidatedAddresses: {}, //This is where we will store the addresses we tried to validate
    validatedAddresses: {} //stored response from API for each validated address
});

export default function AddressesComponentReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case SET_ADDRESS_TO_BE_VALIDATED:
            return state
                .setIn(['unvalidatedAddresses'], processAddress(state.unvalidatedAddresses, payload));
            //          TO-DO should we have a reset state otherwise addresses key too large?
        case RESET_ADDRESS_COMPONENT:
            return INITIAL_STATE;
        case SET_ADDRESS_KEY_FOR_ADDRESS_BEING_VALIDATED_THROUGH_API:
            return state
                .setIn(['addressBeingValidated'], payload);
        case SET_ADDRESS_KEY_FOR_ADDRESS_PREVIOUSLY_VALIDATED_THROUGH_API:
            return state
                .setIn(['previousAddressValidated'], payload);
        case SET_SUGGESTED_ADDRESS_TO_VALIDATED_ADDRESSES:
            return state
                .setIn(['validatedAddresses', generateKeyFromAddress(payload.address)], {
                    ValidatedAddress: payload.address,
                    JobToken: jobTokenFromResponse(payload)
                });
        case SET_EDIT_ADDRESS_BACK_TO_INVALID:
            return state
                .setIn(['validatedAddresses', generateKeyFromAddress(payload.address)], payload.response);
        case SET_ADDRESS_TO_VALIDATED_ADDRESSES:
            return state
                .setIn(['validatedAddresses', generateKeyFromAddress(payload.address)], {
                    ValidatedAddress: payload.address,
                    JobToken: jobTokenFromResponse(payload),
                    AddressValidationStatus: payload.AddressValidationStatus
                });
        case SET_FORCE_SHOW_EDIT_FORM_ADDRESSES_COMPONENT:
            return state
                .setIn(['isForceShowEditForm'], payload);
        case VALIDATE_ADDRESS_CONSTANTS.BEGIN:
            return state.setIn(['isValidating'], true);
        case VALIDATE_ADDRESS_CONSTANTS.SUCCESS:
            return state
                .setIn(['isValidating'], false)
                .setIn(['mduRange'], getMduRange(payload))
                .setIn(['validatedAddresses', state.addressBeingValidated], compareAddress(payload))
                .setIn(['lastJobToken'], jobTokenFromResponse(payload));
        case VALIDATE_ADDRESS_CONSTANTS.FAILURE:
            return state
                .setIn(['isValidating'], false)
                .setIn(['lastJobToken'], jobTokenFromResponse(payload));
        default:
            return state;
    }
}

const getMduRange = (payload) => {
    if (payload.AddressValidationStatus === ADDRESS_STATUS_CONSTANTS.MDU_VALIDATION_ERROR && payload.SuggestedAddresses) {
        const mduSuggestedAddress = payload.SuggestedAddresses.find((address) => {
            return address.MduRange;
        });
        return mduSuggestedAddress && mduSuggestedAddress.MduRange;
    }
    return null;
};

// if requested and validated address are different, then show the validated address as suggestion
const compareAddress = (payload) => {
    if (payload.ValidatedAddress && payload.RequestedAddress && !payload.AddressValidationStatus) {
        const valAddress = payload.ValidatedAddress;
        const reqAddress = payload.RequestedAddress;
        const addressKeys = ['LineOne', 'LineTwo', 'City', 'Country', 'PostalCode', 'State'];
        const isAddressSame = addressKeys.every(key => {
            // eslint-disable-next-line no-prototype-builtins
            reqAddress.hasOwnProperty(key) && reqAddress[key] === valAddress[key];
        });
        if (!isAddressSame) {
            payload = {
                RequestedAddress: reqAddress,
                SuggestedAddresses: [valAddress]
            };
            return payload;
        }
    } else if (payload.AddressValidationStatus === ADDRESS_STATUS_CONSTANTS.MDU_VALIDATION_ERROR && payload.SuggestedAddresses) {
        delete payload.SuggestedAddresses;
        return payload;
    }
    return payload;
};

const jobTokenFromResponse = (payload) => {
    return pathOr(null, ['ContextData', '0', 'Value'], payload) || pathOr(null, ['JobToken'], payload);
};

const processAddress= (addresses, payload) => {
    /*
        - Verify if `ValidatedADdress` matches the address from request
        - Make sure validatedAddress is processed correctly from generateKeyFromAddress
    */
    const key = generateKeyFromAddress(payload);

    if (addresses[key]) {
        return addresses;
    } else {
        return addresses.setIn([key], Object.assign({}, {
            addressLine1: payload.addressLine1,
            addressLine2: payload.addressLine2,
            city: payload.city,
            country: payload.country,
            postalCode: payload.postalCode,
            stateRegionProvince: payload.stateRegionProvince
        }));
    }
};
