import {createSelector} from 'reselect';
import Immutable from 'seamless-immutable';
import {CurrentActiveAddressesSelector} from './customer.addresses.selectors';

import {
    add,
    clone,
    filter,
    pluck,
    prop,
    propEq,
    reduce
} from 'ramda';

const EMPTY_OBJECT = {};

const RedeemGiftOrderSelector = (store) => {
    return store.customercare.recoverableUiState.redeemGiftOrder;
};

export const GiftOrderPreviewAsMutableSelector = createSelector(
    [RedeemGiftOrderSelector],
    (redeemGiftOrder) => {
        return redeemGiftOrder.giftOrderPreview.asMutable(
            {
                deep:true
            });
    }
);

export const GiftOrderSubmitSelector = createSelector(
    [RedeemGiftOrderSelector],
    (redeemGiftOrder) => {
        return redeemGiftOrder.giftOrderSubmit;
    }
);


export const IsFetchingPreviewGiftOrderSelector = createSelector(
    [GiftOrderPreviewAsMutableSelector],
    (giftOrderPreview) => {
        return giftOrderPreview.isFetchingGiftOrderPreview;
    }
);

export const IsSubmittingGiftOrderRedemptionSelector = createSelector(
    [GiftOrderSubmitSelector],
    (giftOrderSubmit) => {
        return giftOrderSubmit.isSubmittingGiftOrderRedemption;
    }
);

export const GiftOrderRedemptionCodeSelector = createSelector(
    [RedeemGiftOrderSelector],
    (redeemGiftOrder) => {
        return redeemGiftOrder.redemptionCode;
    }
);

export const GiftOrderRequiresPaymentInstrumentSelector = createSelector(
    [GiftOrderPreviewAsMutableSelector],
    (giftOrderPreview) => {
        if (giftOrderPreview.subscriptions.length > 0) {
            return filter(propEq(true, 'paymentInstrumentRequired'), giftOrderPreview.subscriptions).length > 0;
        }
        return false;
    }
);

export const SubscriptionRenewalChargeAndCurrencySelector = createSelector(
    [GiftOrderPreviewAsMutableSelector],
    (giftOrderPreview) => {
        if (giftOrderPreview.subscriptions.length > 0) {
            const quoteSummary = {
                subtotal: 0,
                taxes: 0,
                discounts: 0,
                shipping: 0,
                total: 0
            };
            giftOrderPreview.subscriptions.forEach((subscription) => {
                if (subscription.quoteSummary) {
                    quoteSummary.subtotal = reduce(add, quoteSummary.subtotal, [subscription.quoteSummary.subtotal]);
                    quoteSummary.taxes = reduce(add, quoteSummary.taxes, [subscription.quoteSummary.taxes]);
                    quoteSummary.discounts = reduce(add, quoteSummary.discounts, [subscription.quoteSummary.discounts]);
                    quoteSummary.shipping = reduce(add, quoteSummary.shipping, [subscription.quoteSummary.shipping]);
                    quoteSummary.total = reduce(add, quoteSummary.total, [subscription.quoteSummary.total]);
                }
            });
            const currencyCode = giftOrderPreview.subscriptions[0].item.pricingPlan.currency;
            return {
                quoteSummary,
                currencyCode
            };
        }
        return EMPTY_OBJECT;
    }
);

export const GiftOrderSubscriptionQuotesAsMutableSelector = createSelector(
    [RedeemGiftOrderSelector],
    (redeemGiftOrder) => {
        return redeemGiftOrder.giftOrderPreview.subscriptions && Immutable.asMutable(
            pluck('quoteSummary', filter(prop('quoteSummary'), redeemGiftOrder.giftOrderPreview.subscriptions)), {
                deep: true
            }
        );
    }
);

export const GiftOrderSelectedShippingAddressSelector = createSelector(
    [RedeemGiftOrderSelector],
    (redeemGiftOrder) => {
        return redeemGiftOrder.selectedShippingAddress;
    }
);

export const GiftOrderShipToNameSelector = createSelector(
    [RedeemGiftOrderSelector],
    (redeemGiftOrder) => {
        return redeemGiftOrder.selectedShipToName;
    }
);

export const ShippingAddressesAvailableForGiftOrder = createSelector(
    [CurrentActiveAddressesSelector, GiftOrderPreviewAsMutableSelector],
    (customerAddresses, giftOrderPreviewData) => {
        const availableShippingAddresses = clone(customerAddresses);
        if (giftOrderPreviewData.shippingAddress) {
            availableShippingAddresses.unshift(giftOrderPreviewData.shippingAddress);
        }

        return availableShippingAddresses;
    }
);

export const SelectedShippingMethodSelector = createSelector(
    [RedeemGiftOrderSelector],
    (redeemGiftOrder) => {
        return redeemGiftOrder.selectedShippingMethod || null;
    }
);
