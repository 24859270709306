import Immutable from 'seamless-immutable';
import {ApiFaultHelper} from 'invision-core';
import {
    CLEAR_DEVICE_FORM,
    CREATE_EDIT_DEVICE_FORM_CHANGED,
    CREATE_DEVICE_ADJUSTMENT,
    REGISTER_DEVICE,
    REMOVE_DEVICE,
    RETRIEVE_DEVICE_FINANCING_DETAILS,
    RETRIEVE_DEVICE_REFUND_AMOUNT,
    RETRIEVE_DEVICES,
    SET_DEVICE_FINANCING_WIDGET_SELECTED_DEVICE,
    UPDATE_DEVICE,
} from './actions/customer.devices.actions';
import FaultCodes from '../api/fault.code.keys';
import {EMPTY_ARRAY} from './constants/common.constants';

export const INITIAL_STATE = Immutable({
    data: null,
    deviceAdjustmentDetails: EMPTY_ARRAY,
    deviceFinancingCurrency: null,
    deviceFinancingDetails: EMPTY_ARRAY,
    deviceReturnDetails: EMPTY_ARRAY,
    deviceFinancingWidgetSelectedDeviceIndex: 0,
    devicesError: null,
    isApplyingDeviceAdjustment: false,
    isRegisteringDevice: false,
    isFetchingDevices: true,
    isFetchingDeviceFinancingDetails: false,
    isFetchingDeviceReturnDetails: false,
    isRemovingDevice: false,
    isUpdatingDevice: false,
    remainingAssociations: null
});

export default function devicesReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case CLEAR_DEVICE_FORM:
            return state.set('devicesError', null);
        case RETRIEVE_DEVICES.BEGIN:
            return state.set('isFetchingDevices', true);
        case RETRIEVE_DEVICES.SUCCESS:
            return state
                .set('isFetchingDevices', false)
                .set('data', normalizeDevices(payload.Devices))
                .set('devicesError', null)
                .set('remainingAssociations', payload.RemainingDeviceAssociations);
        case RETRIEVE_DEVICES.FAILURE:
            return state
                .set('isFetchingDevices', false)
                .set('devicesError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                })
                .set('remainingAssociations', null);
        case CREATE_EDIT_DEVICE_FORM_CHANGED:
            return state.set('devicesError', null);
        case REGISTER_DEVICE.BEGIN:
            return state.set('isRegisteringDevice', true);
        case REGISTER_DEVICE.SUCCESS:
            return state
                .set('isRegisteringDevice', false)
                .set('devicesError', null);
        case REGISTER_DEVICE.FAILURE: {
            const translatedMessage = payload.Code.toString() === FaultCodes.DEVICE_NOT_FOUND ?
                ApiFaultHelper.translateClientFaultCode(payload.Code, 'rendezvous') : payload.translatedMessage;
            return state
                .set('isRegisteringDevice', false)
                .set('devicesError', {
                    code: payload.Code,
                    message: translatedMessage,
                    severity: payload.Severity
                });
        }
        case REMOVE_DEVICE.BEGIN:
            return state.set('isRemovingDevice', true);
        case REMOVE_DEVICE.SUCCESS:
            return state
                .set('isRemovingDevice', false)
                .set('devicesError', null);
        case REMOVE_DEVICE.FAILURE:
            return state
                .set('isRemovingDevice', false)
                .set('devicesError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case UPDATE_DEVICE.BEGIN:
            return state.set('isUpdatingDevice', true);
        case UPDATE_DEVICE.SUCCESS:
            return state
                .set('isUpdatingDevice', false)
                .set('devicesError', null);
        case UPDATE_DEVICE.FAILURE:
            return state
                .set('isUpdatingDevice', false)
                .set('devicesError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case RETRIEVE_DEVICE_FINANCING_DETAILS.BEGIN:
            return state
                .set('isFetchingDeviceFinancingDetails', true)
                .set('deviceFinancingDetails', EMPTY_ARRAY)
                .set('deviceFinancingCurrency', null);
        case RETRIEVE_DEVICE_FINANCING_DETAILS.SUCCESS:
            return state
                .set('isFetchingDeviceFinancingDetails', false)
                .set('deviceFinancingDetails', payload.SubscriberDeviceFinanceDetails || EMPTY_ARRAY)
                .set('deviceFinancingCurrency', payload.CurrencyCode);
        case RETRIEVE_DEVICE_FINANCING_DETAILS.FAILURE:
            return state
                .set('isFetchingDeviceFinancingDetails', false)
                .set('deviceFinancingDetails', EMPTY_ARRAY)
                .set('deviceFinancingCurrency', null);
        case RETRIEVE_DEVICE_REFUND_AMOUNT.BEGIN:
            return state
                .set('isFetchingDeviceReturnDetails', true)
                .set('deviceReturnDetails', EMPTY_ARRAY);
        case RETRIEVE_DEVICE_REFUND_AMOUNT.SUCCESS:
            return state
                .set('isFetchingDeviceReturnDetails', false)
                .set('deviceReturnDetails', payload.SubscriberDeviceRefundDetails);
        case RETRIEVE_DEVICE_REFUND_AMOUNT.FAILURE:
            return state
                .set('isFetchingDeviceReturnDetails', false)
                .set('deviceReturnDetails', EMPTY_ARRAY);
        case CREATE_DEVICE_ADJUSTMENT.BEGIN:
            return state
                .set('isApplyingDeviceAdjustment', true)
                .set('deviceAdjustmentDetails', EMPTY_ARRAY);
        case CREATE_DEVICE_ADJUSTMENT.SUCCESS:
            return state
                .set('isApplyingDeviceAdjustment', false)
                .set('deviceAdjustmentDetails', payload);
        case CREATE_DEVICE_ADJUSTMENT.FAILURE:
            return state
                .set('isApplyingDeviceAdjustment', false)
                .set('deviceAdjustmentDetails', EMPTY_ARRAY);
        case SET_DEVICE_FINANCING_WIDGET_SELECTED_DEVICE:
            return state.set('deviceFinancingWidgetSelectedDeviceIndex', payload);
        default:
            return state;
    }
}

const generateId = (device) => {
    return `${device.Id}-${device.DeviceId}-${device.Start}-${device.End}`;
};

const normalizeDevices = (devices = []) => {
    return devices.reduce((current, device) => {
        device.uniqueId = generateId(device);
        current[device.uniqueId] = device;
        return current;
    }, {});
};
