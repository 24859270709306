import {USAGE_CAPS_THRESHOLD_TYPES} from '../../components/customer/servicesAndUsage/usageCaps/usage.caps.constants';
import {getFilterService as getFilterService} from 'invision-core/src/components/injectables/injector.helper';


export const thresholdDisplayName = (threshold, thresholdType, currentBusinessUnitCurrency) => {
    const $filter = getFilterService();
    let displayName = '';
    if (thresholdType.Code === USAGE_CAPS_THRESHOLD_TYPES.Currency) {
        displayName = currentBusinessUnitCurrency
            ? $filter('invCurrency')(threshold.MaximumAmount, currentBusinessUnitCurrency)
            : threshold.MaximumAmount;
    } else if (threshold !== undefined) {
        displayName = `${threshold.MaximumAmount} ${thresholdType.Name}`;
    }
    return displayName;
};

export const mapThresholdOptions = (thresholds, thresholdType, currentBusinessUnitCurrency, allServices = false) => {
    return thresholds.map((threshold) => {
        return threshold.merge({
            DisplayName: thresholdDisplayName(threshold, thresholdType, currentBusinessUnitCurrency),
            selected: threshold.Default && allServices === false ? 'selected' : undefined
        });
    });
};

export const mapUsageCaps = (usageCapsElections, currentBusinessUnitCurrency, allServices = false) => {
    const mappedUsageCaps = usageCapsElections.map((usageCap) => {
        const mappedThresholds = mapThresholdOptions(usageCap.Thresholds, usageCap.ThresholdType, currentBusinessUnitCurrency, allServices);
        const findElectedThreshold = (threshold) => {
            return usageCap.ElectedThreshold && threshold.Id === usageCap.ElectedThreshold.Id;
        };

        return usageCap.merge({
            Thresholds: mappedThresholds,
            ElectedThreshold: (allServices || !usageCap.Elected) ? mappedThresholds[0] : mappedThresholds.find(findElectedThreshold)
        });
    });

    return mappedUsageCaps;
};
