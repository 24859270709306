import Immutable from 'seamless-immutable';
import NavigatorActions from 'invision-core/src/components/navigator/navigator.actions';
import {
    MOBILE_NUMBER_PORTABILITY_FORM_VALIDATED,
    MOBILE_NUMBER_PORTABILITY_SET_EDIT_ADDITIONAL_PROPERTY,
    MOBILE_NUMBER_PORTABILITY_SET_EDIT_ADDITIONAL_PROPERTY_ON_CHANGE,
    RESET_MOBILE_NUMBER_PORTABILITY_MODELS,
    RESET_PORTABILITY_MODEL,
    RESTORE_PORTABILITY_ATTRIBUTE,
    SAVE_PORTABILITY_ATTRIBUTE,
    SET_SHOW_PORTABILITY,
    UPDATE_MOBILE_NUMBER_PORTABILITY_ADDRESS_MODEL,
    UPDATE_MOBILE_NUMBER_PORTABILITY_MODEL,
} from './../actions/mobile.number.portability.actions';
import {
    RESUME_ORDER_ATTRIBUTES
} from '../actions/add.offer.wizard.actions';
import {RETRIEVE_ATTRIBUTES} from '../actions/offering.order.actions';
import {
    mapAdditionalProperty,
    setEditAp
} from '../helpers/customer.helper';

export const INITIAL_STATE = Immutable({
    portabilityModel: {
        existingNumber: null,
        provider: null,
        accountNumber: null,
        token: null,
        mnpAdditionalProps: []
    },
    addressModel: {
        LineOne: null,
        LineTwo: null,
        Country: null,
        City: null,
        State: null,
        PostalCode: null
    },
    lastValidatedAddress: {
        LineOne: null,
        LineTwo: null,
        Country: null,
        City: null,
        State: null,
        PostalCode: null
    },
    savedPortabilityAttributes: {},
    showPortability: false,
    isResumingCartWithMnpData: false
});

export default function MobileNumberPortabilityReducer(state = INITIAL_STATE, {payload = {}, type, requestObject}) {
    switch (type) {
        case UPDATE_MOBILE_NUMBER_PORTABILITY_MODEL:
            return state.setIn(['portabilityModel'], payload);
        case UPDATE_MOBILE_NUMBER_PORTABILITY_ADDRESS_MODEL:
            return state.setIn(['addressModel'], payload);
        case RESET_PORTABILITY_MODEL:
            return state.setIn(['portabilityModel'], INITIAL_STATE.portabilityModel);
        case RESET_MOBILE_NUMBER_PORTABILITY_MODELS:
            return INITIAL_STATE;
        case MOBILE_NUMBER_PORTABILITY_FORM_VALIDATED:
            return state.setIn(['lastValidatedAddress'], state.addressModel);
        case SET_SHOW_PORTABILITY:
            return state.setIn(['showPortability'], payload);
        case SAVE_PORTABILITY_ATTRIBUTE:
            return state.setIn(['savedPortabilityAttributes', payload.attributeId], (Immutable({
                addressModel: state.addressModel,
                portabilityModel: state.portabilityModel,
                showPortability: state.showPortability
            })));
        case RESTORE_PORTABILITY_ATTRIBUTE:
            if (!state.savedPortabilityAttributes[payload]) {
                return INITIAL_STATE.setIn(['savedPortabilityAttributes'], state.savedPortabilityAttributes)
                    .setIn(['addressModel'], state.lastValidatedAddress)
                    .setIn(['lastValidatedAddress'], state.lastValidatedAddress);
            }
            return state.setIn(['addressModel'], state.savedPortabilityAttributes[payload].addressModel)
                .setIn(['portabilityModel'], state.savedPortabilityAttributes[payload].portabilityModel)
                .setIn(['showPortability'], state.savedPortabilityAttributes[payload].showPortability);
        case MOBILE_NUMBER_PORTABILITY_SET_EDIT_ADDITIONAL_PROPERTY:
            return state
                .setIn(['portabilityModel', 'mnpAdditionalProps'], mapAdditionalProperty(payload));
        case MOBILE_NUMBER_PORTABILITY_SET_EDIT_ADDITIONAL_PROPERTY_ON_CHANGE:
            return state
                .setIn(['portabilityModel', 'mnpAdditionalProps'], setEditAp(payload, state.portabilityModel.mnpAdditionalProps));
        case RETRIEVE_ATTRIBUTES.SUCCESS: {
            return requestObject && requestObject.changeOfferingInstanceId ?
                state
                    .setIn(['savedPortabilityAttributes'], populateMnpAttributes(payload.Context.ValueDecisions))
                    .setIn(['showPortability'], INITIAL_STATE.showPortability)
                    .setIn(['isResumingCartWithMnpData'], !!getMnpAttributes(payload.Context.ValueDecisions).length) :
                state;
        }
        case RESUME_ORDER_ATTRIBUTES.SUCCESS:
            return state
                .setIn(['savedPortabilityAttributes'], populateMnpAttributes(payload.Context.ValueDecisions))
                .setIn(['showPortability'], INITIAL_STATE.showPortability)
                .setIn(['isResumingCartWithMnpData'], !!getMnpAttributes(payload.Context.ValueDecisions).length);
        case NavigatorActions.STATE_CHANGE_SUCCESS:
            return INITIAL_STATE;
        default:
            return state;
    }
}

function getMnpAttributes(valueDecisions) {
    return valueDecisions.filter((attribute) => {
        return attribute.IsPortable && attribute.PortInRequest;
    });
}

function populateMnpAttributes(valueDecisions) {
    const portInRequests = {};
    const updatedDecisions = getMnpAttributes(valueDecisions);
    const updatedAttributes = updatedDecisions.map((item) => {
        return Object.assign({}, item, {
            portabilityModel : {
                existingNumber: item.PortInRequest.PortInNumber,
                provider: item.PortInRequest.ServiceProviderId.toString(),
                token: item.PortInRequest.ValidationToken,
                mnpAdditionalProps: item.PortInRequest.AdditionalProperties || []
            },
            showPortability: true,
            addressModel : item.PortInRequest.Address
        });
    });
    updatedAttributes.forEach((item) => {
        Object.assign(portInRequests, {
            [item.Id]: item
        });
    });
    return portInRequests;
}
