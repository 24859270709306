import Immutable from 'seamless-immutable';
import {
    RETRIEVE_CONTRACT_EVENTS,
    RETRIEVE_CONTRACT_INSTANCE_EVENTS,
    SET_SELECTED_CONTRACT,
} from './actions/contract.details.actions';

export const INITIAL_STATE = Immutable({
    isCreatingContract: false,
    isFetchingData: false,
    isFetchingOrderContractInstance: false,
    lastAttemptError: null,
    contractDetails: null,
    staticContractData: {
        contractName: ''
    },
    orderContractInstance: {}
});


export default function ContractReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case RETRIEVE_CONTRACT_EVENTS.BEGIN:
            return state
                .set('isFetchingData', true)
                .set('lastAttemptError', null)
                .setIn(['staticContractData', 'contractName'], null);
        case RETRIEVE_CONTRACT_EVENTS.SUCCESS:
            return state
                .set('lastAttemptError', null)
                .set('isFetchingData', false)
                .setIn(['staticContractData', 'contractName'], payload.OrderContract.Name);
        case RETRIEVE_CONTRACT_EVENTS.FAILURE:
            return state
                .set('isFetchingData', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case RETRIEVE_CONTRACT_INSTANCE_EVENTS.BEGIN:
            return state
                .set('isFetchingOrderContractInstance', true);
        case RETRIEVE_CONTRACT_INSTANCE_EVENTS.SUCCESS:
            return state
                .set('isFetchingOrderContractInstance', false)
                .set('orderContractInstance', payload.OrderContractInstance);
        case RETRIEVE_CONTRACT_INSTANCE_EVENTS.FAILURE:
            return state
                .set('isFetchingOrderContractInstance', false);
        case SET_SELECTED_CONTRACT:
            return state
                .set('contractDetails', payload);
        default:
            return state;
    }
}
