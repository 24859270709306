import pluck from 'ramda/src/pluck';

export const GRACE_PERIOD_TYPE = {
    SUBSCRIBER_PRODUCT: 1,
    ORDER: 2
};

export const getOrderIdAndSubscriberProductIds = (offer = {}) => {
    const isOrderPaymentFailure = offer.Options.some((option) => {
        return option.OffCycleDetail &&
            option.OffCycleDetail.CurrentRenewalFailedAttempts &&
            option.OffCycleDetail.GracePeriodExpiryDate &&
            option.OffCycleDetail.GracePeriodType === GRACE_PERIOD_TYPE.ORDER;
    });

    if (isOrderPaymentFailure) {
        return {
            orderId: offer.OrdersPendingPayment[0]
        };
    } else {
        const subscriberProductIds = pluck('SubscriberProductId', offer.Options.filter((option) => {
            return option.OffCycleDetail &&
                option.OffCycleDetail.CurrentRenewalFailedAttempts &&
                option.OffCycleDetail.GracePeriodExpiryDate &&
                option.OffCycleDetail.GracePeriodType === GRACE_PERIOD_TYPE.SUBSCRIBER_PRODUCT;
        }));

        return {
            subscriberProductIds
        };
    }
};
