import Immutable from 'seamless-immutable';
import any from 'ramda/src/any';
import descend from 'ramda/src/descend';
import clone from 'ramda/src/clone';
import compose from 'ramda/src/compose';
import concat from 'ramda/src/concat';
import filter from 'ramda/src/filter';
import flatten from 'ramda/src/flatten';
import indexOf from 'ramda/src/indexOf';
import isEmpty from 'ramda/src/isEmpty';
import map from 'ramda/src/map';
import min from 'ramda/src/min';
import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import sortBy from 'ramda/src/sortBy';
import sortWith from 'ramda/src/sortWith';
import split from 'ramda/src/split';
import sum from 'ramda/src/sum';
import values from 'ramda/src/values';
import {createSelector} from 'reselect';

import {
    CurrentBusinessUnitSelector
} from 'invision-core/src/components/session/businessunit.selectors';
import i18n from 'invision-core/src/components/i18n/i18n';
import {
    MetadataCodeTypeDictionarySelector,
    MetadataCodeTypeSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {
    OfferingMetadataByIdSelector,
    OfferingMetadataPricingPlansSelector
} from 'invision-core/src/components/metadata/offerings/offerings.selectors';
import {
    EMPTY_ARRAY,
    EMPTY_OBJECT
} from 'invision-core/src/constants/common.constants';
import {DiscountsMetadataMapSelector} from 'invision-core/src/components/metadata/discounts/discounts.selectors';
import {BRI_ACTIVATION_STATUS} from 'invision-core/src/constants/pricing.plan.constants';

import {DecisionTypes} from '../../components/wizards/steps/decisions/decisions.constants';
import {
    BILLER_RULE_CYCLE_LEVEL,
    BILLER_RULE_INSTANCE_TYPE,
    PRICING_PLAN_SERVICE_RELATIONSHIP_TYPE
} from '../../customercare.constants';
import CustomerCareKeys from '../../locales/keys';
import {
    getFilteredBillerRuleDetailsForBriId
} from '../helpers/offer.ordering.wizard.selector.helper';
import {SelectedContractSelector} from './contract.selectors';

import {SelectedOfferAccountDetailsSelector} from './customer.convergent.biller.selectors';
import {
    ALLOWABLE_ACTIONS,
    GENERAL_STATUSES
} from '../../components/shared/constants/general.status.constants';

import {
    CustomerInfoSelector,
    IsCustomerStepSelector,
    IsCheckoutStepSelector
} from './new.connect.wizard.selectors';
import {
    CustomerStoreSelector,
    SelectedCustomerHomeCountrySelector
} from './customer.selectors';
import {
    SelectedInventoryRegionSelector,
    SelectedInventoryStoresSelector,
    InventoryRegionFilterSelector,
    InventoryStoresTableDataSelector,
    FormattedInventoryStoresSelector,
    OfferingOrderAsyncIdSelector,
    SelectedOrderExecutionOptionsSelector
} from './offering.order.selectors';
import {
    filterByCountry,
    filterStatesByCountry,
    findAndAttachStateName,
    getChargeAmount,
    getDiscountDisplayAmount,
    isChargeOverridableOption,
    isFinancedShoppingCartItem,
    mapCodesAdditionalProperties,
    reduceByState,
    selectFutureDatedOrderConfigurationIsAllowedForCurrentBusinessUnit,
    selectFutureDatedOrderConfigurationMaxDateForCurrentBusinessUnit,
    setDownPaymentProperties
} from './selected.offering.order.selector.helper';

import {
    IMMUTABLE_EMPTY_ARRAY
} from '../constants/common.constants';
import {getUniqueNonGuidServiceIdentifiers} from '../helpers/add.offer.wizard.selectors.helpers';
import {getDiscountTooltipModel} from '../../components/shared/tooltipTemplates/discount.tooltip.helper';

const SelectedOfferingSelector = state => {
    return state.customercare.offeringOrder.data.selectedOffering;
};

export const SelectedOfferingPickupIdSelector = createImmutableSelector(
    [SelectedOfferingSelector],
    (selectedOffering) => {
        return selectedOffering.subscriberPhysicalInventoryPickupDetails ||  null;
    }
);

export const ROCOfferIdDataSelector = createSelector(
    [SelectedOfferingSelector],
    (selectedOfferingStore) => {
        return selectedOfferingStore.data.rocResponseOfferId;
    }
);

const DecisionsDataSelector = createSelector(
    [SelectedOfferingSelector],
    (selectedOfferingStore) => {
        return selectedOfferingStore.data.decisions.data;
    }
);

export const SelectedOfferingDataSelector = createImmutableSelector(
    [SelectedOfferingSelector],
    (selectedOffering) => {
        return selectedOffering.data;
    }
);

export const SelectedOfferingShoppingCartSelector = createImmutableSelector(
    [SelectedOfferingDataSelector],
    (selectedOfferingShoppingCartData) => {
        return selectedOfferingShoppingCartData.shoppingCart;
    }
);

export const SelectedOfferingShoppingCartOfferingsSelector = createImmutableSelector(
    [SelectedOfferingShoppingCartSelector],
    (selectedOfferingShoppingCart) => {
        return selectedOfferingShoppingCart.Offerings || EMPTY_ARRAY;
    }
);

export const IsSelectedOfferingChangeable = createImmutableSelector(
    [SelectedOfferingShoppingCartOfferingsSelector],
    (selectedOfferingShoppingCartOfferings) => {
        return pathOr(false, ['0', 'IsChangeable'], selectedOfferingShoppingCartOfferings);
    }
);

export const SelectedOfferingShoppingCartItemsSelector = createImmutableSelector(
    [SelectedOfferingShoppingCartSelector],
    (shoppingCartItemsData) => {
        return (shoppingCartItemsData.Items && shoppingCartItemsData.Items.length) ? shoppingCartItemsData.Items : IMMUTABLE_EMPTY_ARRAY;
    }
);

export const SelectedOfferingServiceIdentifiersSelector = createImmutableSelector(
    [
        SelectedOfferingShoppingCartItemsSelector,
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression),
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute)
    ],
    (shoppingCartItems, regularExpressionCodeTable, serviceAttributeCodeTable) => {
        const serviceAttributes = compose(flatten, pluck('ServiceAttributes'))(shoppingCartItems);
        return getUniqueNonGuidServiceIdentifiers(serviceAttributes, serviceAttributeCodeTable, regularExpressionCodeTable);
    });

export const SelectedOfferDevicePaymentOptionsSelector = createSelector(
    [SelectedOfferingSelector],
    (offering) => {
        return pathOr(null, ['data', 'devicePaymentOptions'], offering);
    }
);

export const SelectedOfferPurchaseOrderNumberSelector = createSelector(
    [SelectedOfferingSelector],
    (offering) => {
        return pathOr(null, ['data', 'purchaseOrderNumber'], offering);
    }
);

export const GetMaxDateForFutureDatedOrder = createSelector(
    [MetadataCodeTypeSelector(CODES.FutureDatedOrderConfiguration)],
    (fdoConfiguration) => {
        return selectFutureDatedOrderConfigurationMaxDateForCurrentBusinessUnit(fdoConfiguration);
    }
);

export const IsFutureDatedOrderAllowedInBunt = createSelector(
    [MetadataCodeTypeSelector(CODES.FutureDatedOrderConfiguration)],
    (fdoConfiguration) => {
        return selectFutureDatedOrderConfigurationIsAllowedForCurrentBusinessUnit(fdoConfiguration);
    }
);

export const SelectedOfferExternalContractIdSelector = createSelector(
    [SelectedOfferingSelector],
    (offering) => {
        return pathOr(null, ['data', 'externalContractId'], offering);
    }
);

export const SubmittedOrderSelector = createSelector(
    [SelectedOfferingSelector],
    (selectedOfferingStore) => {
        return selectedOfferingStore.data.modifiedOrder;
    }
);

export const SubmittedOrderErrorSelector = createSelector(
    [SelectedOfferingSelector],
    (selectedOfferingStore) => {
        return selectedOfferingStore.lastAttemptError;
    }
);

export const SubmittedOrderCurrencyCodeSelector = createSelector(
    [SubmittedOrderSelector],
    (createdOrder) => {
        return createdOrder && createdOrder.CurrencyCode ? createdOrder.CurrencyCode : 'USD';
    }
);

export const AttributesDataSelector = createSelector(
    [SelectedOfferingSelector],
    (selectedOfferingStore) => {
        return selectedOfferingStore.data.attributes.data;
    }
);

export const PageDisplayOrderSelector = createSelector(
    [DecisionsDataSelector],
    (decisionsData) => {
        return decisionsData.pageDisplayOrder;
    }
);

export const PagesSelector = createSelector(
    [DecisionsDataSelector],
    (decisionsData) => {
        return decisionsData.pages;
    }
);

export const PagesIsFetchingDataSelector = createSelector(
    [SelectedOfferingSelector],
    (selectedOfferingStore) => {
        return selectedOfferingStore.isFetchingData;
    }
);

export const IsUpdatingCartSelector = createSelector(
    [SelectedOfferingSelector],
    (selectedOfferingStore) => {
        return selectedOfferingStore.isUpdatingCart;
    }
);

export const DecisionsRequiringAffectedServicesResolutionSelector = createSelector(
    [SelectedOfferingSelector],
    (selectedOfferingStore) => {
        return selectedOfferingStore.decisionsRequiringAffectedServicesResolution;
    }
);

export const IsFetchingBillerRuleInstanceDiscountContextSelector = createImmutableSelector(
    [SelectedOfferingSelector],
    (selectedOfferingStore) => {
        return selectedOfferingStore.isFetchingBillerRuleInstanceDiscountContext;
    });

export const DiscretionaryDiscountTableDataSelector = createSelector(
    [DecisionsDataSelector, DiscountsMetadataMapSelector],
    (decisionsData, discountsMetadataMap) => {
        const discretionaryDiscounts = clone(pathOr(EMPTY_ARRAY, ['selectedBillerRuleInstanceDiscountContext', 'DiscretionaryDiscounts'], decisionsData));
        return !discretionaryDiscounts ? EMPTY_ARRAY : discretionaryDiscounts.map((discretionaryDiscount) => {
            const discretionaryDiscountMetadata = discountsMetadataMap[pathOr(undefined, ['DiscountId', 'Value'], discretionaryDiscount)] || EMPTY_OBJECT;
            return {
                amount: getDiscountDisplayAmount(discretionaryDiscountMetadata),
                currency: discretionaryDiscountMetadata.Currency,
                discountAmountType: `${discretionaryDiscountMetadata.Type}`,
                discountId: pathOr(null, ['DiscountId', 'Value'], discretionaryDiscount),
                isSelected: false,
                isDisabled: false,
                isOverridable: discretionaryDiscountMetadata.AllowDiscountOverride,
                name: discretionaryDiscountMetadata.StorefrontText || discretionaryDiscountMetadata.Name,
                savings: discretionaryDiscount.DiscountAmount
            };
        });
    });

export const RegularDiscountTableDataSelector= createSelector(
    [DecisionsDataSelector, DiscountsMetadataMapSelector],
    (decisionsData, discountsMetadataMap) => {
        const regularDiscounts = clone(pathOr(EMPTY_ARRAY, ['selectedBillerRuleInstanceDiscountContext', 'BillerRuleInstanceDiscounts'], decisionsData));
        const selectedGlobalDiscounts = clone(pathOr(EMPTY_ARRAY, ['selectedBillerRuleInstanceDiscountContext', 'GlobalDiscounts'], decisionsData))
            .filter((globalDiscount) => {
                return (decisionsData.selectedBriSelectedDiscounts || []).some((selectedBriSelectedDiscount) => {
                    return `${selectedBriSelectedDiscount.DiscountId}` === globalDiscount.DiscountId.Value;
                });
            }).map((globalDiscount) => {
                return Object.assign({}, globalDiscount, {
                    isGlobalDiscount: true
                });
            });
        const regularAndGlobalDiscounts = [...regularDiscounts, ...selectedGlobalDiscounts];
        return !regularAndGlobalDiscounts ? EMPTY_ARRAY : regularAndGlobalDiscounts.map((discount) => {
            const discountMetadata = discountsMetadataMap[pathOr(undefined, ['DiscountId', 'Value'], discount)] || EMPTY_OBJECT;
            return {
                amount: getDiscountDisplayAmount(discountMetadata),
                currency: discountMetadata.Currency,
                discountAmountType: `${discountMetadata.Type}`,
                discountId: pathOr(null, ['DiscountId', 'Value'], discount),
                isDisabled: !!discount.isGlobalDiscount,
                isGlobalDiscount: discount.isGlobalDiscount,
                isOverridable: discountMetadata.AllowDiscountOverride,
                isSelected: false,
                name: discountMetadata.StorefrontText || discountMetadata.Name,
                savings: discount.DiscountAmount
            };
        });
    });

export const DecisionsRequireAffectedServicesResolutionSelector = createSelector(
    [DecisionsRequiringAffectedServicesResolutionSelector],
    (decisionsRequiringAffectedServicesResolution) => {
        const unresolvedDecisionsRequiringAffectedServicesResolution = decisionsRequiringAffectedServicesResolution.filter((decision) => {
            return !decision.resolved;
        });

        return !!unresolvedDecisionsRequiringAffectedServicesResolution.length;
    }
);

export const PagesIsFetchingDecisionOptionServiceIdsSelector = createSelector(
    [SelectedOfferingSelector],
    (selectedOfferingStore) => {
        return selectedOfferingStore.isFetchingDecisionOptionServiceIds;
    }
);

export const SelectedPageIdSelector = createSelector(
    [DecisionsDataSelector],
    (decisionsData) => {
        return decisionsData.selectedPageId;
    }
);

const PagesInOrderSelector = createSelector(
    [
        PageDisplayOrderSelector,
        PagesSelector,
        SelectedPageIdSelector
    ],
    (pageDisplayOrder, pages, selectedPageId) => {
        return pageDisplayOrder.map((pageId) => {
            return pages[pageId].merge({
                selected: pageId === selectedPageId
            }, {
                deep: true
            });
        });
    }
);

export const DecisionsSelector = createSelector(
    [DecisionsDataSelector],
    (decisionsData) => {
        return decisionsData.decisionsMap;
    }
);

export const ServicesForRemovalSelector = createSelector(
    [DecisionsSelector],
    (decisionsMap) => {
        if (decisionsMap) {
            const services = [];
            const decisionValues = values(decisionsMap);
            if (decisionValues && decisionValues.length) {
                decisionValues.forEach((decision) => {
                    if (decision.Options && decision.Options.length) {
                        decision.Options.forEach((option) => {
                            if (option.SelectedServicesForRemoval && option.SelectedServicesForRemoval.length) {
                                option.SelectedServicesForRemoval.forEach((service) => {
                                    services.push(service.Value);
                                });
                            }
                        });
                    }
                });
            }
            return services;
        } else {
            return IMMUTABLE_EMPTY_ARRAY;
        }
    }
);

export const ServicesBeingRemovedHaveServiceFeaturesSelector = createSelector(
    [ServicesForRemovalSelector, SelectedOfferAccountDetailsSelector],
    (servicesForRemoval, selectedOffering) => {
        return servicesForRemoval.some((service) => {
            if (pathOr(0, ['ServiceFeatures', 'length'], selectedOffering)) {
                return selectedOffering.ServiceFeatures.filter((feature) => {
                    return feature.Status === GENERAL_STATUSES.ACTIVE;
                }).find((feature) => {
                    return feature.ServiceAttributeValues.find((attribute) => {
                        return attribute.Value === service;
                    });
                });
            }
        });
    }
);

function isQuantityDecisionInvalid(options) {
    const min = options[0].MinimumQuantity;
    const max = options[0].MaximumQuantity;
    const totalQuantities = sum(pluck('Quantity')(options));

    return min > totalQuantities || totalQuantities > max;
}

function isQuantityDecisionGroupsInvalid(options, decision) {
    const test = pluck('Quantity')(options).map((option) => {
        return isNaN(option) ? 0 : option;
    });
    const totalQuantities = sum(test);
    return decision.MinimumQuantity > totalQuantities || totalQuantities > decision.MaximumQuantity;
}

function isQuantityDecisionGroupsItemsInvalid(options) {
    return !!options.find((element) => {
        return element.Items.some((item) => {
            return item.hasError;
        }) || false;
    });
}

function isStandaloneDecisionInvalid(options, decision) {
    const totalQuantities = sum(pluck('Quantity')(options));

    return totalQuantities > min(pathOr(0, ['MaximumQuantity'], decision), pathOr(0, ['RemainingOrderableQuantity'], decision));
}

export const ShoppingCartSelector = createSelector(
    [
        SelectedOfferingSelector,
        SelectedContractSelector,
        SelectedOfferPurchaseOrderNumberSelector,
        SelectedOfferExternalContractIdSelector,
        SelectedOfferDevicePaymentOptionsSelector,
        SelectedOrderExecutionOptionsSelector
    ],
    (
        selectedOfferingStore,
        selectedContract,
        selectedOfferPurchaseOrderNumber,
        selectedOfferExternalContractId,
        devicePaymentOptions,
        executionOptions
    ) => {
        const shoppingCart = clone(pathOr({}, ['data', 'shoppingCart'], selectedOfferingStore));
        if (shoppingCart.Items || shoppingCart.AddItems || shoppingCart.ModifyItems || shoppingCart.RemoveItems) {
            populatePurchaseOrderNumberAndEffectiveDate(selectedOfferPurchaseOrderNumber, shoppingCart.Items, executionOptions);
            populatePurchaseOrderNumberAndEffectiveDate(selectedOfferPurchaseOrderNumber, shoppingCart.AddItems, executionOptions);
            populatePurchaseOrderNumberAndEffectiveDate(selectedOfferPurchaseOrderNumber, shoppingCart.ModifyItems, executionOptions);
            populatePurchaseOrderNumberAndEffectiveDate(selectedOfferPurchaseOrderNumber, shoppingCart.RemoveItems, executionOptions);
            populateDownPaymentAmount(devicePaymentOptions, shoppingCart.Items, shoppingCart.AddItems);
            populateDownPaymentAmount(devicePaymentOptions, shoppingCart.Items, shoppingCart.RemoveItems);
            if (selectedContract) {
                populateOrderContractId(selectedContract, shoppingCart.Items);
                populateOrderContractId(selectedContract, shoppingCart.AddItems);
                populateOrderContractId(selectedContract, shoppingCart.ModifyItems);
                populateOrderContractId(selectedContract, shoppingCart.RemoveItems);
                populateExternalContractId(selectedOfferExternalContractId, shoppingCart.Items);
                populateExternalContractId(selectedOfferExternalContractId, shoppingCart.AddItems);
                populateExternalContractId(selectedOfferExternalContractId, shoppingCart.ModifyItems);
                populateExternalContractId(selectedOfferExternalContractId, shoppingCart.RemoveItems);
            }
        }
        return shoppingCart;
    }
);

export const RemoveItemsSelector = createSelector(
    [ShoppingCartSelector],
    (shoppingCart) => {
        return pathOr(null, ['RemoveItems'], shoppingCart);
    }
);

export const PagesWithDecisionsSelector = createSelector(
    [
        PagesInOrderSelector,
        DecisionsSelector,
        OfferingMetadataPricingPlansSelector,
        DiscountsMetadataMapSelector,
        RemoveItemsSelector
    ],
    (pages, decisionsMap, offeringMetadataPricingPlans, discountsMetadataMap, removedOptionItems) => {
        if (!pages) {
            return;
        }

        return pages.map((page) => {
            const decisions = page.decisionIds.map((decisionId) => {
                let providesPlansWarning = '';
                if (removedOptionItems && removedOptionItems.length) {
                    const newlyRemovedItems = decisionsMap[decisionId].Options.filter((option) => {
                        return removedOptionItems.find((item) => {
                            return item.OfferingOptionPriceId === option.Id && item.PricingPlanId === option.PricingPlanId
                                    && offeringMetadataPricingPlans[option.PricingPlanId]
                                    && offeringMetadataPricingPlans[option.PricingPlanId].ServiceRelationshipType === PRICING_PLAN_SERVICE_RELATIONSHIP_TYPE.PROVIDES;
                        });
                    });
                    if (newlyRemovedItems.length) {
                        providesPlansWarning = newlyRemovedItems.length > 1 ? i18n.translate(CustomerCareKeys.DECISIONS.REMOVE_PLAN_ASSOCIATIONS_GENERIC)
                            : i18n.translate(CustomerCareKeys.DECISIONS.REMOVE_PLAN_ASSOCIATIONS_SINGLE, {
                                pricingPlanName: offeringMetadataPricingPlans[newlyRemovedItems[0].PricingPlanId].PricingPlanName
                            });
                    }
                }
                if (decisionsMap[decisionId].DecisionType === DecisionTypes.QUANTITY) {
                    return Object.assign({}, decisionsMap[decisionId], {
                        hasError: isQuantityDecisionInvalid(decisionsMap[decisionId].Options),
                        providesPlansWarning: providesPlansWarning
                    });
                } else if (decisionsMap[decisionId].DecisionType === DecisionTypes.GROUP && (decisionsMap[decisionId].MaximumQuantity > 1 || decisionsMap[decisionId].MaximumQuantity > decisionsMap[decisionId].MinimumQuantity)) {
                    return Object.assign({}, decisionsMap[decisionId], {
                        hasError: isQuantityDecisionGroupsInvalid(decisionsMap[decisionId].Options, decisionsMap[decisionId]),
                        providesPlansWarning: providesPlansWarning
                    });
                } else if (decisionsMap[decisionId].DecisionType === DecisionTypes.STANDALONE) {
                    return Object.assign({}, decisionsMap[decisionId], {
                        hasError: isStandaloneDecisionInvalid(decisionsMap[decisionId].Options, decisionsMap[decisionId]),
                        providesPlansWarning: providesPlansWarning
                    });
                } else {
                    return Object.assign({}, decisionsMap[decisionId], {
                        providesPlansWarning: providesPlansWarning
                    });
                }
            });
            const sortByDisplayOrder = sortBy(prop('DisplayOrder'));
            const pageMap =  page
                .set('decisions', sortByDisplayOrder(getDecisionsViewModel(decisions, offeringMetadataPricingPlans, discountsMetadataMap)))
                .set('Name', page.Name || i18n.translate(CustomerCareKeys.DECISIONS.UNNAMED_PAGE));
            return pageMap;
        });
    }
);

function getDecisionsViewModel(decisions, offeringMetadataPricingPlans, discountsMetadataMap) {
    return decisions.map(decision => {
        const options = decision.Options.map(option => {
            return Object.assign({}, option, {
                BillerRuleInstanceThumbnails: addCustomPropertiesToBillerRuleInstance(option, option.BillerRuleInstanceThumbnails,
                    offeringMetadataPricingPlans, discountsMetadataMap)
            });
        });
        return Object.assign({}, decision, {
            Options: options
        });
    });
}

function addCustomPropertiesToBillerRuleInstance(option, billerRuleInstanceThumbnails, offeringMetadataPricingPlans, discountsMetadataMap) {
    return billerRuleInstanceThumbnails.map((billerRuleInstanceThumbnail) => {
        const billerRuleInstanceObj= Object.assign({}, billerRuleInstanceThumbnail, {
            discounts: EMPTY_ARRAY,
            triggers: EMPTY_ARRAY,
        });
        if ((billerRuleInstanceThumbnail.selectedDiscountsInfo || []).length && !isEmpty(discountsMetadataMap)) {
            billerRuleInstanceObj.discounts = getDiscounts((billerRuleInstanceThumbnail.selectedDiscountsInfo || []), discountsMetadataMap);
        }
        if (!isEmpty(offeringMetadataPricingPlans)) {
            const isInactiveUntilFirstUseBri = (option.BillerRuleInstanceThumbnails || []).some((billerRuleInstanceThumbnail) => {
                return billerRuleInstanceThumbnail.Status === BRI_ACTIVATION_STATUS.INACTIVE;
            });
            if (isInactiveUntilFirstUseBri) {
                const pricingPlanBillerRuleInstances = (offeringMetadataPricingPlans[option.PricingPlanId] || {}).PricingPlanBillerRuleInstances;
                if (pricingPlanBillerRuleInstances) {
                    billerRuleInstanceObj.triggers = (pricingPlanBillerRuleInstances.TriggerBillerRuleInstances || []).filter((triggerBillerRuleInstance) => {
                        return (triggerBillerRuleInstance.Actions || []).some((action) => {
                            return action.BillerRuleConfigurationId === billerRuleInstanceThumbnail.BillerRuleConfigurationId;
                        });
                    });
                }
            }
        }
        return billerRuleInstanceObj;
    });
}

function getDiscounts(selectedDiscountsInfo, discountsMetadataMap) {
    const discounts = [];
    selectedDiscountsInfo.forEach((discount) => {
        const discountMetadata = discountsMetadataMap[discount.DiscountId];
        if (discountMetadata) {
            const discountTooltipModel = getDiscountTooltipModel(discount, discountMetadata.Currency, discountsMetadataMap);
            discounts.push(discountTooltipModel);
        }
    });
    return (selectedDiscountsInfo.length && !isEmpty(discountsMetadataMap)) ? discounts : EMPTY_ARRAY;
}

const EMPTY_MAPPED_DECISIONS = [];
export const DecisionsRequiringAffectedServicesResolutionMappedForSelectedPageSelector = createSelector(
    [
        DecisionsRequiringAffectedServicesResolutionSelector,
        PagesWithDecisionsSelector
    ],
    (decisionsRequiringAffectedServicesResolution, pagesWithDecisions) => {
        const mappedDecisions = [];
        const pageWithDecisions = pagesWithDecisions.find(propEq(true, 'selected'));

        if (pageWithDecisions) {
            pageWithDecisions.decisions.forEach((decision) => {
                const updatedDecisionOptions = [];
                const filteredDecisionsRequiringAffectedServicesResolution = decisionsRequiringAffectedServicesResolution.filter(propEq(decision.Id, 'decisionId'));

                if (filteredDecisionsRequiringAffectedServicesResolution.length) {
                    decision.Options.forEach((option) => {
                        const decisionRequiringAffectedServicesResolution = filteredDecisionsRequiringAffectedServicesResolution.find(propEq(option.Id, 'optionId'));

                        if (decisionRequiringAffectedServicesResolution) {
                            updatedDecisionOptions.push({
                                Id: option.Id,
                                Name: option.Name,
                                numRequiredSelections: decisionRequiringAffectedServicesResolution.numRequiredSelections,
                                serviceIdentifiers: [],
                                updatedQuantity: decisionRequiringAffectedServicesResolution.updatedQuantity
                            });
                        }
                    });
                }

                if (updatedDecisionOptions.length) {
                    mappedDecisions.push({
                        Id: decision.Id,
                        Name: decision.Name,
                        Options: updatedDecisionOptions
                    });
                }
            });
        }

        return mappedDecisions.length ? mappedDecisions : EMPTY_MAPPED_DECISIONS;
    }
);

export const IsFirstDecisionPageSelector = createSelector(
    [
        PageDisplayOrderSelector,
        SelectedPageIdSelector
    ],
    (pageOrder, selectedPageId) => {
        return pageOrder[0] === selectedPageId;
    }
);

export const IsLastDecisionPageSelector = createSelector(
    [
        PageDisplayOrderSelector,
        SelectedPageIdSelector
    ],
    (pageOrder, selectedPageId) => {
        return pageOrder[pageOrder.length - 1] === selectedPageId;
    }
);

export const BackDecisionPageTextSelector = createSelector(
    [
        IsFirstDecisionPageSelector,
        PageDisplayOrderSelector,
        SelectedPageIdSelector,
        PagesSelector
    ],
    (isFirstPage, pageOrder, selectedPageId, pages) => {
        return isFirstPage || selectedPageId === null
            || pageOrder.length === 0 || indexOf(selectedPageId, pageOrder) < 0 ? ''
            : pages[pageOrder[indexOf(selectedPageId, pageOrder) - 1]].Name || i18n.translate(CustomerCareKeys.DECISIONS.UNNAMED_PAGE);
    }
);

export const NextDecisionPageTextSelector = createSelector(
    [
        IsLastDecisionPageSelector,
        PageDisplayOrderSelector,
        SelectedPageIdSelector,
        PagesSelector
    ],
    (isLastPage, pageOrder, selectedPageId, pages) => {
        return isLastPage || selectedPageId === null
        || pageOrder.length === 0 ? ''
            : pages[pageOrder[+1 + indexOf(selectedPageId, pageOrder)]].Name || i18n.translate(CustomerCareKeys.DECISIONS.UNNAMED_PAGE);
    }
);

export const CurrentAttributeFormName = createSelector(
    [AttributesDataSelector],
    (attributesData) => {
        return attributesData.currentAttributeFormName;
    }
);

export const CurrentAttributesValidationStatuses = createSelector(
    [AttributesDataSelector],
    (attributesData) => {
        return attributesData.attributesValidationStatuses;
    }
);

export const AttributeFormSubmittedSelector = createSelector(
    [AttributesDataSelector],
    (attributesData) => {
        return attributesData.attributeFormSubmitted;
    }
);

const EMPTY_ATTRIBUTE_GROUPS = [];
export const AttributeGroupsSelector = createSelector(
    [AttributesDataSelector],
    (attributesData) => {
        return attributesData.attributeGroups ? attributesData.attributeGroups.asMutable({
            deep: true
        }) : EMPTY_ATTRIBUTE_GROUPS;
    }
);

export const PhysicalAttributeGroupsSelector = createSelector(
    [AttributesDataSelector],
    (attributesData) => {
        return attributesData.physicalInventoryAttributeGroups ? attributesData.physicalInventoryAttributeGroups.asMutable({
            deep: true
        }) : EMPTY_ATTRIBUTE_GROUPS;
    }
);

export const PhysicalAttributeDecisionsSelector = createImmutableSelector(
    [AttributesDataSelector],
    (attributesData) => {
        return attributesData.physicalInventoryDecisions ? attributesData.physicalInventoryDecisions: IMMUTABLE_EMPTY_ARRAY;
    }
);

export const SelectedOfferingPhysicalInventoriesSelector = createImmutableSelector(
    [SelectedOfferingShoppingCartItemsSelector, PhysicalAttributeDecisionsSelector],
    (shoppingCartItemsData, physicalAttributeDecisions) => {
        return shoppingCartItemsData.filter((item) => {
            return physicalAttributeDecisions.some((attribute) => {
                return item.PricingPlanId === attribute.PricingPlanId && attribute.DecisionType === DecisionTypes.PHYSICAL_INVENTORY && !attribute.SubscriberProductId;
            });
        });
    }
);

export const AllPhysicalInventoryItemsFromSelectedOffering = createImmutableSelector(
    [SelectedOfferingPhysicalInventoriesSelector],
    (shoppingCartItems) => {
        const shoppingCartItemsWithPhysicalInventories = shoppingCartItems.filter((items) => {
            return items.PhysicalInventories;
        });
        return flatten(pluck('PhysicalInventories', shoppingCartItemsWithPhysicalInventories)).map((item, index) => {
            return {
                DisplayName: item.DisplayName,
                InstanceId: generateKeyFromInventoryItem(item, index),
                InventoryAttributes: item.InventoryAttributes,
                InventoryTypeId: item.InventoryTypeId,
                MakeId: item.MakeId,
                ModelId: item.ModelId,
                Quantity: 1
            };
        });
    }
);



export const IsSendToStoreButtonEnabledSelector = createSelector([
    SelectedOfferingPhysicalInventoriesSelector,
    IsCustomerStepSelector,
    IsCheckoutStepSelector,
    OfferingOrderAsyncIdSelector
], (physicalInventory, isCustomerStep, isCheckoutStep, asyncId) => {
    return !!((isCustomerStep || isCheckoutStep) && physicalInventory.length && !asyncId);
});

export const InventoryAvailabilitySelector = createSelector(
    [AttributesDataSelector],
    (attributesData) => {
        return attributesData.availableInventory || EMPTY_ATTRIBUTE_GROUPS;
    }
);

export const AreAnyInventoryUnavailableSelector = createSelector(
    [InventoryAvailabilitySelector],
    (inventoryAvailability) => {
        return !!inventoryAvailability.find(({Available}) => {
            return !Available;
        });
    }
);

const filterQuantityDecisions = filter((decision) => {
    return decision.DecisionType === DecisionTypes.QUANTITY;
});

const filterDecisionGroups = filter((decision) => {
    return decision.DecisionType === DecisionTypes.GROUP && !(decision.MaximumQuantity > 1 || decision.MaximumQuantity > decision.MinimumQuantity);
});

const filterQuantityDecisionGroups = filter((decision) => {
    return decision.DecisionType === DecisionTypes.GROUP && (decision.MaximumQuantity > 1 || decision.MaximumQuantity > decision.MinimumQuantity);
});

const filterStandaloneDecisions = filter((decision) => {
    return decision.DecisionType === DecisionTypes.STANDALONE;
});

const filterSelectedOption = filter((option) => {
    return option.Selected;
});

const mapQuantityDecision = map((decision) => {
    const completedQuantityOptions = decision.Options.map((option) => {
        return {
            Id: split('_', decision.Id)[1],
            CompletedOfferingDecisionInstances: getCompletedOfferingDecisionInstances(option),
            DecisionType: DecisionTypes.QUANTITY,
            SelectedValue: option.Id,
            IsPlanOrServiceSwap: option.isPlanOrServiceSwap,
            Quantity: option.Bulk && option.Quantity > 0 ? 1 : option.Quantity,
            BillerRuleInstanceDetails: getCompletedBillerRuleDetails(option),
            SelectedServicesForRemoval: option.SelectedServicesForRemoval || null,
            ItemDecisions: mapQuantityDecisionGroupItems(option.Items || []),
            SubscriberProductId: pathOr(undefined, ['OfferingDecisionOptionInstances', 0, 'SubscriberProductId'], option)
        };
    });
    return {
        completedQuantityOptions: completedQuantityOptions
    };
});

const mapQuantityDecisionGroupItems = map((items) => {
    return {
        DecisionGroupOptionItemId: items.DecisionGroupOptionItemId,
        Quantity: items.Quantity
    };
});

const mapQuantityDecisionGroups = map((decision) => {
    const completedQuantityOptions = decision.Options.map((option) => {
        return {
            Id: split('_', decision.Id)[1],
            DecisionType: DecisionTypes.GROUP,
            SelectedValue: option.Id,
            OverrideDiscount: option.OverrideDiscount || undefined,
            Quantity: !isNaN(option.Quantity) ? option.Quantity : 0,
            BillerRuleInstanceDetails: getCompletedBillerRuleDetails(option),
            SelectedServicesForRemoval: option.SelectedServicesForRemoval || null,
            ItemDecisions: mapQuantityDecisionGroupItems(option.Items || [])
        };
    });
    return {
        completedQuantityOptions: completedQuantityOptions
    };
});

const mapStandaloneDecision = map((decision) => {
    const completedStandaloneOptions = decision.Options.map((option) => {
        return {
            Id: split('_', decision.Id)[1],
            CompletedOfferingDecisionInstances: getCompletedOfferingDecisionInstances(option),
            DecisionType: DecisionTypes.STANDALONE,
            SelectedValue: option.Id,
            IsPlanOrServiceSwap: option.isPlanOrServiceSwap,
            Quantity: option.Bulk && option.Quantity > 0 ? 1 : option.Quantity,
            BillerRuleInstanceDetails: getCompletedBillerRuleDetails(option),
            SelectedServicesForRemoval: option.SelectedServicesForRemoval || null,
            ItemDecisions: mapQuantityDecisionGroupItems(option.Items || []),
            SubscriberProductId: pathOr(undefined, ['OfferingDecisionOptionInstances', 0, 'SubscriberProductId'], option)
        };
    });
    return {
        completedStandaloneOptions: completedStandaloneOptions
    };
});

function getCompletedOfferingDecisionInstances(option) {
    const completedOfferingDecisionInstances = [];
    if (option.OfferingDecisionOptionInstances) {
        const offeringDecisionOptionInstance = option.OfferingDecisionOptionInstances[0];
        //Currently, API duplicates selectedDiscounts in each offering instance because the application does not yet officially support discounts per instance of an option.
        //I'm mimicking that here.
        for (let instanceIndex = 0; instanceIndex < (option.Quantity || 1); instanceIndex++) {
            if (option.Quantity) {
                completedOfferingDecisionInstances.push({
                    ...(offeringDecisionOptionInstance?.SelectedLifeCycle && {
                        SelectedLifeCycleId:  offeringDecisionOptionInstance?.SelectedLifeCycle?.LifeCycleId
                    }),
                    SelectedDiscountDecisions: (offeringDecisionOptionInstance.SelectedDiscounts || []).map((selectedDiscount) => {
                        return Object.assign({}, selectedDiscount, {
                            DiscountAmount: undefined
                        });
                    })
                });
            }
        }
    } else if (option.DefaultSelectedDiscounts) {
        //Currently, API duplicates selectedDiscounts in each offering instance because the application does not yet officially support discounts per instance of an option.
        //I'm mimicking that here.
        for (let instanceIndex = 0; instanceIndex < (option.Quantity || 1); instanceIndex++) {
            completedOfferingDecisionInstances.push({
                SelectedDiscountDecisions: option.DefaultSelectedDiscounts.map((defaultSelectedDiscount) => {
                    return Object.assign({}, defaultSelectedDiscount, {
                        DiscountAmount: undefined
                    });
                })
            });
        }
    }

    return completedOfferingDecisionInstances.length ? completedOfferingDecisionInstances : undefined;
}

function getCompletedBillerRuleDetails(option) {
    if (option.Bulk || isChargeOverridableOption(option)) {
        const billerRuleDetails = option.BillerRuleInstanceThumbnails.map((brit) => {
            const quantity = option.Bulk && option.Quantity > 0 ? option.BillerRuleDetails?.[0]?.Quantity : option.Quantity;
            let billerRuleDecisionDetails = getFilteredBillerRuleDetailsForBriId(brit.BillerRuleConfigurationId, option.BillerRuleDetails).reduce((acc, billerRuleDetail) => {
                if (billerRuleDetail.BillerRuleConfigurationId) {
                    acc.push(Object.assign({}, billerRuleDetail, {
                        Amount: billerRuleDetail && typeof billerRuleDetail.Amount === 'number' ? billerRuleDetail.Amount : brit.Amount,
                        ...(billerRuleDetail?.TermLength && {
                            TermLength: billerRuleDetail.TermLength
                        }),
                        Quantity: quantity
                    }));
                }
                return acc;
            }, []);

            if (brit.pricingPeriods) {
                billerRuleDecisionDetails = brit.pricingPeriods.map((period) => {
                    return Object.assign({}, period, {
                        Quantity: quantity
                    });
                });
            }

            if (brit.financeOverrides) {
                billerRuleDecisionDetails = [brit.financeOverrides];
            }

            let filteredBillerRuleDecisions = billerRuleDecisionDetails.filter((item) => {
                return item.Quantity;
            });

            /** if it's non bulk and there are no price override, then
             * we should not send the BillerRuleInstanceDetails in ROC */
            if (!option.Bulk &&
                !brit.financeOverrides &&
                filteredBillerRuleDecisions.length === 1 &&
                (filteredBillerRuleDecisions[0].Amount === brit.CatalogAmount || filteredBillerRuleDecisions[0].Amount === null)) {
                filteredBillerRuleDecisions = [];
            }

            return {
                BillerRuleConfigurationId: brit.BillerRuleConfigurationId,
                BillerRuleDecisionDetails: filteredBillerRuleDecisions
            };
        });

        return billerRuleDetails.filter((item) => {
            return item.BillerRuleDecisionDetails.length;
        });

    } else {
        return null;
    }
}

const mapDecisionGroups = map((decision) => {
    const selectedOption = filterSelectedOption(decision.Options);
    return {
        Id: split('_', decision.Id)[1],
        DecisionType: DecisionTypes.GROUP,
        SelectedValue: selectedOption && selectedOption.length && selectedOption[0].Id || null,
        OverrideDiscount: selectedOption && selectedOption.length && selectedOption[0].OverrideDiscount ? selectedOption[0].OverrideDiscount : undefined,
        Quantity: 1,
        ItemDecisions: selectedOption && selectedOption[0] && selectedOption[0].Items ? mapQuantityDecisionGroupItems(selectedOption[0].Items) : null
    };
});

const filterUnselectedDecisionGoups = filter(decision => {
    return decision.SelectedValue;
});

function getQuantityDecisions(decisionMap) {
    return compose(
        filterQuantityDecisions,
        values)(decisionMap);
}

function getQuantityDecisionsGroups(decisionMap) {
    return compose(
        filterQuantityDecisionGroups,
        values)(decisionMap);
}

function getStandaloneDecisions(decisionMap) {
    return compose(
        filterStandaloneDecisions,
        values)(decisionMap);
}

function getCompletedQuantityDecisions(decisionsMap) {
    return compose(
        mapQuantityDecision,
        filterQuantityDecisions,
        values)(decisionsMap);
}

function getCompletedDecisionGroups(decisionsMap) {
    return compose(
        filterUnselectedDecisionGoups,
        mapDecisionGroups,
        filterDecisionGroups,
        values)(decisionsMap);
}

function getCompletedQuantityDecisionGroups(decisionsMap) {
    return compose(
        mapQuantityDecisionGroups,
        filterQuantityDecisionGroups,
        values)(decisionsMap);
}

function getCompletedStandaloneDecisions(decisionsMap) {
    return compose(
        mapStandaloneDecision,
        filterStandaloneDecisions,
        values)(decisionsMap);
}

export const DecisionsGroupEmptySelector = createSelector(
    [DecisionsSelector],
    (decisionsMap) => {
        return isEmpty(mapDecisionGroups(decisionsMap));
    }
);

export const CompletedDecisionsSelector = createSelector(
    [DecisionsSelector],
    (decisionsMap) => {
        let completedDecision = [];

        const mappedQuantityDecisions = getCompletedQuantityDecisions(decisionsMap);
        const mappedDecisionGroups = getCompletedDecisionGroups(decisionsMap);
        const mappedQuantityDecisionGroups = getCompletedQuantityDecisionGroups(decisionsMap);
        const mappedStandaloneDecisions = getCompletedStandaloneDecisions(decisionsMap);

        const completedQuantityDecisionOptions = mappedQuantityDecisions.reduce((accDecisions, quantityDecision) => {
            return accDecisions.concat(quantityDecision.completedQuantityOptions);
        }, []);

        const completedQuantityDecisionGroupdOptions = mappedQuantityDecisionGroups.reduce((accDecisions, quantityDecision) => {
            return accDecisions.concat(quantityDecision.completedQuantityOptions);
        }, []);

        const completedStandaloneDecisionOptions = mappedStandaloneDecisions.reduce((accDecisions, standaloneDecision) => {
            return accDecisions.concat(standaloneDecision.completedStandaloneOptions);
        }, []);

        completedDecision = concat(completedDecision, mappedDecisionGroups);
        completedDecision = concat(completedDecision, completedQuantityDecisionGroupdOptions);
        completedDecision = concat(completedDecision, completedQuantityDecisionOptions);
        completedDecision = concat(completedDecision, completedStandaloneDecisionOptions);
        return completedDecision;
    }
);

export const DecisionsHasErrorSelector = createSelector(
    [DecisionsSelector],
    (decisionsMap) => {
        const quantityDecisions = getQuantityDecisions(decisionsMap);
        const quantityDGDecisions = getQuantityDecisionsGroups(decisionsMap);
        const standaloneDecisions = getStandaloneDecisions(decisionsMap);


        const decisionsError = quantityDecisions.reduce((hasError, quantityDecision) => {
            const isDecisionInvalid = isQuantityDecisionInvalid(quantityDecision.Options);
            return hasError || isDecisionInvalid;
        }, false);

        const decisionsDGError = quantityDGDecisions.reduce((hasError, quantityDGDecision) => {
            const isDecisionInvalid = isQuantityDecisionGroupsInvalid(quantityDGDecision.Options, quantityDGDecision);
            return hasError || isDecisionInvalid;
        }, false);

        const decisionsDGOIError = quantityDGDecisions.reduce((hasError, quantityDGDecision) => {
            const isDecisionInvalid = isQuantityDecisionGroupsItemsInvalid(quantityDGDecision.Options);
            return hasError || isDecisionInvalid;
        }, false);

        const decisionsSEError = standaloneDecisions.reduce((hasError, standaloneDecision) => {
            const isDecisionInvalid = isStandaloneDecisionInvalid(standaloneDecision.Options, standaloneDecision);
            return hasError || isDecisionInvalid;
        }, false);

        return decisionsError || decisionsDGError || decisionsDGOIError || decisionsSEError;
    }
);

export const SelectedOfferNameSelector = createSelector(
    [SelectedOfferingSelector],
    (selectedOfferingStore) => {
        return selectedOfferingStore.offerName;
    }
);

export const CurrentRemoveOfferSelector = createSelector(
    [SelectedOfferNameSelector, ROCOfferIdDataSelector],
    (offerName, offerId) => {
        return offerId && offerName ? [{
            name: offerName,
            id: offerId
        }] : IMMUTABLE_EMPTY_ARRAY;
    }
);

export const SelectedPageHasErrorSelector = createSelector(
    [PagesWithDecisionsSelector, SelectedPageIdSelector],
    (pages, selectedPage) => {
        const currentPage = pages.filter((page) => {
            return page.Id === selectedPage;
        });
        const dgWithErrors = currentPage.filter((element) => {
            return element.decisions.some((subElement) => {
                return subElement.hasError === true;
            });
        });
        return (dgWithErrors.length > 0);
    });

export const SelectedOfferChangesEffectiveSelector = createSelector(
    [SelectedOfferingSelector],
    (offering) => {
        return offering.data.changesEffective;
    }
);

const populateExternalContractId = (externalContractId, items) => {
    return items ? items.map((item) => {
        if (item.OrderContractInstance) {
            item.OrderContractInstance.ExternalContractId = externalContractId;
        }
        item.ExternalContractId = externalContractId;
        return item;
    }) : null;
};

export const SelectedOfferPaymentInstrumentSelector = createSelector(
    [SelectedOfferingSelector],
    (offering) => {
        return offering.data.paymentInstrument;
    }
);

const populatePurchaseOrderNumberAndEffectiveDate = (purchaseOrderNumber, items, executionOptions) => {
    return items ? items.forEach((item) => {
        item.PurchaseOrderNumber = purchaseOrderNumber;

        if (executionOptions && executionOptions.billingEffectiveIntention) {
            item.BillingEffectiveDate = executionOptions.billingEffectiveDate;
            item.BillingEffectiveDateIntention = executionOptions.billingEffectiveIntention;
        }
        return item;
    }) : null;
};

const populateOrderContractId = (selectedContract, items) => {
    return items ? items.map((item) => {
        item.OrderContractId = selectedContract.OrderContractId || selectedContract.Id;
        return item;
    }) : null;
};

export const populateDownPaymentAmount = (devicePaymentOptions, items, addOrRemoveItems) => {
    const devicePaymentOptionItemsSafe = pathOr([], ['items'], devicePaymentOptions);
    let purchasingItemIndex = 0, devicePaymentOptionItemIndex = 0;
    (items || []).forEach((item) => {
        item.itemIndex = purchasingItemIndex++;
        const {isFinancedItem, minimumDownPayment, isFullUpfrontPayment} = isFinancedShoppingCartItem(item);
        if (isFinancedItem) {
            item.isFullUpfrontPayment = isFullUpfrontPayment;
            item.minimumDownPayment = minimumDownPayment;
            setDownPaymentProperties(item, item.minimumDownPayment, null, false);
        }
    });
    // Add/Remove Items will be present only when Editing/Transitioning an Offer
    (addOrRemoveItems || []).forEach((item) => {
        item.itemIndex = purchasingItemIndex++;
        const {isFinancedItem, minimumDownPayment, isFullUpfrontPayment} = isFinancedShoppingCartItem(item);
        if (isFinancedItem) {
            item.isFullUpfrontPayment = isFullUpfrontPayment;
            item.minimumDownPayment = minimumDownPayment;
            let downPayment = item.minimumDownPayment, financedFullAmount = null, isFullPriceDownPaymentSelected = false;
            const devicePaymentOptionItem = devicePaymentOptionItemsSafe[devicePaymentOptionItemIndex];
            if (devicePaymentOptionItemsSafe.length && devicePaymentOptionItem) {
                downPayment = devicePaymentOptionItem.downPayment;
                financedFullAmount = devicePaymentOptionItem.financedFullAmount;
                isFullPriceDownPaymentSelected = devicePaymentOptionItem.isFullPriceDownPaymentSelected;
                devicePaymentOptionItemIndex++;
            }
            setDownPaymentProperties(item, downPayment, financedFullAmount, isFullPriceDownPaymentSelected);
        }
    });
    devicePaymentOptionItemsSafe.forEach((devicePaymentOptionItem) => {
        const item = items[devicePaymentOptionItem.itemIndex];
        setDownPaymentProperties(
            item,
            devicePaymentOptionItem.downPayment,
            devicePaymentOptionItem.financedFullAmount,
            devicePaymentOptionItem.isFullPriceDownPaymentSelected
        );
    });
};

export const HasBulkShoppingCartItem = createSelector(
    [SelectedOfferingSelector],
    (selectedOfferingStore) => {
        const shoppingCartItems = pathOr([], ['data', 'shoppingCart', 'Items'], selectedOfferingStore);

        return Immutable(!shoppingCartItems.length ?
            false :
            shoppingCartItems.some((item) => {
                const billerRuleInstances = [
                    ...item.Details.PricingPlan.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances,
                    ...item.Details.PricingPlan.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances];

                return billerRuleInstances.some((bri) => {
                    return bri.BulkChargeTypeCode;
                });
            })
        );
    }
);

export const AddItemsSelector = createSelector(
    [ShoppingCartSelector],
    (shoppingCart) => {
        return pathOr(null, ['AddItems'], shoppingCart);
    }
);

export const ModifyItemsSelector = createSelector(
    [ShoppingCartSelector],
    (shoppingCart) => {
        return pathOr(null, ['ModifyItems'], shoppingCart);
    }
);

export const ShoppingCartIsPostpaidSelector = createSelector(
    [ShoppingCartSelector],
    (shoppingCartSelector) => {
        if (shoppingCartSelector.Items) {
            return any((item) => {
                return !item.Details.PricingPlan.Prepaid;
            })(shoppingCartSelector.Items);
        }
        return false;
    }
);

export const PaymentInstrumentRequiredSelector = createSelector(
    [ShoppingCartSelector],
    (cart) => {
        return cart.PaymentInstrumentRequired || false;
    }
);

export const HasOffCycleChargeRenewalSelector = createImmutableSelector(
    [ShoppingCartSelector],
    (cart) => {
        return cart.HasOffCycleChargeRenewal || false;
    }
);

export const ShoppingCartHaveOnlyStandaloneItemsSelector = createSelector(
    [ShoppingCartSelector],
    (shoppingCart) => {
        const shoppingCartItems = pathOr([], ['Items'], shoppingCart);
        const standaloneItems = shoppingCartItems.filter((item) => {
            return item && item.IsStandalone === true;
        });

        return shoppingCartItems.length > 0 && standaloneItems.length === shoppingCartItems.length;
    }
);

export const IsSaveOrderButtonDisabledSelector = createSelector(
    [ShoppingCartHaveOnlyStandaloneItemsSelector, ShoppingCartSelector],
    (shoppingCartHaveOnlyStandaloneItems, shoppingCart) => {
        return shoppingCartHaveOnlyStandaloneItems || !(shoppingCart.Items && shoppingCart.Items.length);
    }
);

export const SaveOrderButtonTooltipTextSelector = createSelector(
    [ShoppingCartHaveOnlyStandaloneItemsSelector, ShoppingCartSelector],
    (shoppingCartHaveOnlyStandaloneItems, shoppingCart) => {
        let tooltipText = '';
        if (!(shoppingCart.Items && shoppingCart.Items.length)) {
            tooltipText = i18n.translate(CustomerCareKeys.SAVE_CART.CART_IS_EMPTY);
        } else if (shoppingCartHaveOnlyStandaloneItems) {
            tooltipText = i18n.translate(CustomerCareKeys.SAVE_CART.CART_IS_HAVING_ONLY_STANDALONE_PRODUCTS);
        }
        return tooltipText;
    }
);

export const TelephoneNumberProvinceRegionSelector = createSelector(
    [MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.TelephoneCodes),
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressStateProvinceRegion),
        CustomerInfoSelector,
        SelectedCustomerHomeCountrySelector,
        CurrentBusinessUnitSelector],
    (codes, stateNames, newConnectCustomerInfo, existingCustomerInfoHomeCountry, businessUnit) => {
        //Look for selected country
        const selectedCountry = newConnectCustomerInfo.HomeCountry || existingCustomerInfoHomeCountry || businessUnit.defaultCountry;
        const filteredStates = filterStatesByCountry(stateNames, selectedCountry);
        const result = compose(reduceByState, mapCodesAdditionalProperties, filterByCountry)(codes, selectedCountry);
        return Immutable(findAndAttachStateName(result, filteredStates));
    }
);

export const generateKeyFromInventoryItem = (item, index) => {
    return `InventoryTypeId-${item.InventoryTypeId}-MakeId-${item.MakeId}-ModelId-${item.ModelId}-Index-${index}`;
};

export const FilteredStoresOnSelectedRegionSelector = createImmutableSelector(
    [InventoryStoresTableDataSelector, SelectedInventoryRegionSelector],
    (stores, selectedRegion) => {
        if (selectedRegion) {
            return stores.filter((store) => {
                return store.region === selectedRegion;
            });
        }
        return EMPTY_ARRAY;
    }
);

export const FilteredStoresOnSelectedRegionForStoresSearchModalSelector = createImmutableSelector(
    [FormattedInventoryStoresSelector, FilteredStoresOnSelectedRegionSelector, InventoryRegionFilterSelector],
    (allStores, storesForRegionforCurrentlySelectedStore, regionFilter) => {
        //regionFilter gets set to null when exiting a store select modal.
        if (regionFilter) {
            const filteredStores = allStores.filter((store) => {
                return store.AdditionalProperties.RegionCode === regionFilter;
            });

            return filteredStores.map((store) => {
                return {
                    displayName: store.AdditionalProperties.DisplayName,
                    address: store.AdditionalProperties.LineOne,
                    city: store.AdditionalProperties.City,
                    postalCode: store.AdditionalProperties.Zipcode,
                    stateProvince: store.AdditionalProperties.stateProvince,
                    storeId: store.Value
                };
            });
        } else {
            return storesForRegionforCurrentlySelectedStore;
        }
    }
);


export const SelectedOfferingPhysicalInventoryAvailabilitySelector = createImmutableSelector(
    [
        AllPhysicalInventoryItemsFromSelectedOffering,
        SelectedInventoryRegionSelector,
        InventoryRegionFilterSelector
    ],
    (allPhysicalInventoryItemsFromSelectedOffering, selectedRegionFromCurrentlySelectedStore, currentlySelectedRegion) => {
        return !allPhysicalInventoryItemsFromSelectedOffering || !selectedRegionFromCurrentlySelectedStore ? null :
            {
                InventoryTypes: allPhysicalInventoryItemsFromSelectedOffering,
                RegionId: currentlySelectedRegion ? currentlySelectedRegion : selectedRegionFromCurrentlySelectedStore,
            };
    }
);

export const SelectedOfferingStoreAvailabilitySelector = createImmutableSelector(
    [
        InventoryAvailabilitySelector,
        FilteredStoresOnSelectedRegionForStoresSearchModalSelector,
        AllPhysicalInventoryItemsFromSelectedOffering
    ],
    (availableInventoryForStores, stores, allPhysicalInventoryItemsFromSelectedOffering) => {
        let areRegionsInSync = false;
        if (availableInventoryForStores && stores) {
            areRegionsInSync = availableInventoryForStores && availableInventoryForStores[0] && stores ? stores.find((store) => {
                return store.storeId === availableInventoryForStores[0].StoreId;
            }) : false;
        }

        if (availableInventoryForStores && allPhysicalInventoryItemsFromSelectedOffering && stores && areRegionsInSync) {
            return allPhysicalInventoryItemsFromSelectedOffering.map((inventoryItem) => {
                const storeAvailForInventoryItem = [];
                stores.forEach((store) => {
                    const itemAvailabilityAtStoreResponse = availableInventoryForStores.find((inventoryStatusAtStore) => {
                        return inventoryStatusAtStore.InstanceId === inventoryItem.InstanceId &&
                            inventoryStatusAtStore.StoreId === store.storeId;
                    });
                    if (itemAvailabilityAtStoreResponse) {
                        storeAvailForInventoryItem.push({
                            storeId: store.storeId,
                            AvailableUnits: itemAvailabilityAtStoreResponse.AvailableUnits,
                            RequestedQuantity: itemAvailabilityAtStoreResponse.RequestedQuantity,
                            MeetsRequestedQuantity: itemAvailabilityAtStoreResponse.MeetsRequestedQuantity
                        });
                    }
                });
                return {
                    DisplayName: inventoryItem.DisplayName,
                    InstanceId: inventoryItem.InstanceId,
                    InventoryAttributes: inventoryItem.InventoryAttributes,
                    InventoryTypeId: inventoryItem.InventoryTypeId,
                    MakeId: inventoryItem.MakeId,
                    ModelId: inventoryItem.ModelId,
                    Quantity: 1,
                    stores: storeAvailForInventoryItem
                };
            });
        }
        return EMPTY_ARRAY;
    }
);

export const IsAllSelectedPhysicalInventoryPresentAtSelectedStore = createImmutableSelector(
    [InventoryAvailabilitySelector, SelectedInventoryStoresSelector],
    (inventoryAvailabilityForSelectedInventoryItemsAtSelectedStores, selectedStores) => {
        return inventoryAvailabilityForSelectedInventoryItemsAtSelectedStores.filter((inventoryAvailabilityAtStore) => {
            return selectedStores.some((storeId) => {
                return inventoryAvailabilityAtStore.StoreId === storeId;
            });
        }).some((inventoryAvailabilityAtSelectedStore) => {
            return !inventoryAvailabilityAtSelectedStore.MeetsRequestedQuantity;
        }) ? false : true;
    }
);

export const LifeCycleDetailsForPricingPlanSelector = createImmutableSelector(
    [OfferingMetadataByIdSelector, ROCOfferIdDataSelector],
    (offeringMedata, offerId) => {
        if (offeringMedata && offeringMedata[offerId]) {
            return pluck('LifeCycles', offeringMedata[offerId].Options).filter((element) => {
                return undefined !== element;
            });
        } else {
            return EMPTY_ARRAY;
        }
    }
);

export const ActiveLifeCycleSelector = createImmutableSelector(
    [LifeCycleDetailsForPricingPlanSelector],
    (lifeCycle) => {
        const allLifeCycles = [];

        lifeCycle.forEach((lifeCycleDetails) => {
            if (lifeCycleDetails !== null && lifeCycleDetails !== undefined && lifeCycleDetails.length && lifeCycleDetails[0].Status === GENERAL_STATUSES.ACTIVE) {
                allLifeCycles.push(lifeCycleDetails);
            }
        });
        return allLifeCycles;
    }
);

const sortMultipleLifeCycles = (multipleLifeCycles) => {
    let sortedLifeCycles = [];
    const sortByWeight = sortWith([descend(prop('Weight'))]);
    const sortByName = sortWith([prop('Name')]);
    const lifeCycleWithWeight = [];
    const lifeCycleWithoutWeight = [];
    multipleLifeCycles.forEach((lifeCycleItem) => {
        if (lifeCycleItem.Weight) {
            lifeCycleWithWeight.push(lifeCycleItem);
        } else {
            lifeCycleWithoutWeight.push(lifeCycleItem);
        }
    });

    let filteredArray = lifeCycleWithWeight;
    let duplicateArray = [];

    lifeCycleWithWeight.forEach((item1) => {
        const matchingItems = filteredArray.filter((item2) => {
            return item2.Weight === item1.Weight;
        });
        if (matchingItems.length > 1) {
            duplicateArray = duplicateArray.concat(matchingItems);
            const newArray = filteredArray.filter(filteredItem => {
                return filteredItem.Weight !== matchingItems[0].Weight;
            });
            filteredArray = newArray;
        }
    });

    const lifeCycleSortedWithWeights = [...filteredArray, ...sortByName(duplicateArray)];

    sortedLifeCycles = [...sortByWeight(lifeCycleSortedWithWeights), ...sortByName(lifeCycleWithoutWeight)];

    return sortedLifeCycles;
};

export const LifeCyclesForProductsSelector = createImmutableSelector(
    [ActiveLifeCycleSelector,
        DecisionsSelector],
    (lifeCycles, decisions) => {
        const decisionWithLifeCycles = [];

        flatten(values(decisions)).forEach((decision) => {
            lifeCycles.forEach((lifeCycleInfo) => {
                if (lifeCycleInfo.length > 1) {
                    const multipleLifeCycles = [];
                    flatten(lifeCycleInfo).forEach((lifeCycle) => {
                        if (decision.ProductId === lifeCycle.ProductId) {
                            multipleLifeCycles.push(lifeCycle);
                        }
                    });
                    if (multipleLifeCycles.length) {
                        decisionWithLifeCycles.push({
                            productId: decision.ProductId,
                            lifeCycles: sortMultipleLifeCycles(multipleLifeCycles)
                        });
                    }
                } else if (decision.ProductId === lifeCycleInfo[0].ProductId) {
                    decisionWithLifeCycles.push({
                        productId: lifeCycleInfo[0].ProductId,
                        lifeCycles: [lifeCycleInfo[0]]
                    });
                }
            });
        });

        return decisionWithLifeCycles;
    }
);

export const LifeCycleStepsPricingPlanSelector = createSelector(
    [ActiveLifeCycleSelector, DecisionsSelector, OfferingMetadataPricingPlansSelector],
    (lifeCycle, decisionMap, pricingPlanThumbnail) => {
        const lifeCycleSteps = [];

        flatten(values(decisionMap)).forEach((decision) => {
            flatten(lifeCycle).forEach((lifeCycleInfo) => {
                if (decision.ProductId === lifeCycleInfo.ProductId) {
                    lifeCycleSteps.push({
                        productId: lifeCycleInfo.ProductId,
                        steps: lifeCycleInfo.Steps
                    });
                }
            });
        });

        const pricingPlanInPricingPlanThumbnail = pricingPlanThumbnail ? Object.values(pricingPlanThumbnail).filter((pricingPlan) => {
            return lifeCycleSteps.length ? lifeCycleSteps.find((lifeCyclePricingPlan) => {
                return lifeCyclePricingPlan.productId === pricingPlan.ProductId;
            }): EMPTY_ARRAY;
        }): EMPTY_ARRAY;
        const lifeCyclePricingPlanInPricingPlanThumbnail = pricingPlanInPricingPlanThumbnail.filter((pricingPlanThumbnail) => {
            return lifeCycleSteps ? flatten(pluck('steps', lifeCycleSteps)).find((lifeCyclePricingPlan) => {
                return lifeCyclePricingPlan.PricingPlanId === pricingPlanThumbnail.PricingPlanBillerRuleInstances.PricingPlanId;
            }) : EMPTY_ARRAY;
        });

        return lifeCyclePricingPlanInPricingPlanThumbnail;
    });

const getBriDetails = (bri, briName, isBothRbriAndObriPresent = false) => {
    if (bri.length > 1 && isBothRbriAndObriPresent) {
        const briInfo = [];
        bri.forEach((billerRuleDetails) => {
            briInfo.push({
                Name: briName[billerRuleDetails.Type].Name,
                Amount: getChargeAmount(billerRuleDetails.BillerRuleInstanceCharges),
                Type: billerRuleDetails.Type
            });
        });
        return briInfo;
    } else if (bri.length > 1 && !isBothRbriAndObriPresent) {
        const briDetails = [];
        bri.forEach((billerRuleDetails) => {
            briDetails.push({
                Name: billerRuleDetails.Name,
                Amount: getChargeAmount(billerRuleDetails.BillerRuleInstanceCharges),
                Type: billerRuleDetails.Type
            });
        });
        return briDetails;
    } else {
        return [{
            Name: briName.Name,
            Amount: getChargeAmount(bri[0].BillerRuleInstanceCharges),
            Type: bri[0].Type
        }];
    }
};

const getBillerRuleInstancesForPricingPlanInLifeCycle = (pricingPlanInLifeCycle, oneTimeBri, recurringBri, stepsDetails) => {
    const pricingPlanThumbnailInLifeCycle = [];
    pricingPlanInLifeCycle ? pricingPlanInLifeCycle.forEach((lifeCycleDetails) => {
        if (lifeCycleDetails.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances.length
            && lifeCycleDetails.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances.length) {
            const oneTimeBriName = oneTimeBri.find((briName) => {
                return lifeCycleDetails.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances[0].BillerRuleConfigurationId === briName.Value;
            });
            const recurringBriName = recurringBri.find((briName) => {
                return lifeCycleDetails.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances[0].BillerRuleConfigurationId === briName.Value;
            });
            pricingPlanThumbnailInLifeCycle.push({
                [lifeCycleDetails.Id]: {
                    pricingPlanInfo: lifeCycleDetails,
                    billerRuleInstances: getBriDetails([...lifeCycleDetails.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances, ...lifeCycleDetails.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances], {
                        [BILLER_RULE_INSTANCE_TYPE.ONETIME]: oneTimeBriName,
                        [BILLER_RULE_INSTANCE_TYPE.RECURRING]: recurringBriName
                    }, true),
                    durationCount: Object.keys(stepsDetails).length ? stepsDetails[lifeCycleDetails.Id].DurationCount : []
                }
            });
        } else if (lifeCycleDetails.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances.length) {
            const oneTimeBriName = oneTimeBri.find((briName) => {
                return lifeCycleDetails.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances[0].BillerRuleConfigurationId === briName.Value;
            });
            pricingPlanThumbnailInLifeCycle.push({
                [lifeCycleDetails.Id]: {
                    pricingPlanInfo: lifeCycleDetails,
                    billerRuleInstances: getBriDetails(lifeCycleDetails.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances, oneTimeBriName),
                    durationCount: Object.keys(stepsDetails).length ? stepsDetails[lifeCycleDetails.Id].DurationCount : []
                }
            });
        } else if (lifeCycleDetails.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances.length) {
            const recurringBriName = recurringBri.find((briName) => {
                return lifeCycleDetails.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances[0].BillerRuleConfigurationId === briName.Value;
            });
            pricingPlanThumbnailInLifeCycle.push({
                [lifeCycleDetails.Id]: {
                    pricingPlanInfo: lifeCycleDetails,
                    billerRuleInstances: getBriDetails(lifeCycleDetails.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances, recurringBriName),
                    durationCount: Object.keys(stepsDetails).length ? stepsDetails[lifeCycleDetails.Id].DurationCount : []
                }
            });
        }
    }): EMPTY_ARRAY;
    return pricingPlanThumbnailInLifeCycle;
};

export const DecisionsLifeCycleStepsForOffersSelector = createImmutableSelector(
    [LifeCycleStepsPricingPlanSelector, ActiveLifeCycleSelector],
    (pricingPlanInLifeCycle, lifeCycles) => {
        const filteredLifeCyclePricingPlans = pluck('Steps', flatten(lifeCycles)).filter((lifeCycle) => {
            return lifeCycle.find((lifeCyclePricingPlan) => {
                return pricingPlanInLifeCycle.find((pricingPlan) => {
                    return pricingPlan.Id === lifeCyclePricingPlan.PricingPlanId;
                });
            });
        });
        return flatten(filteredLifeCyclePricingPlans).reduce((acc, current) => {
            return acc[current.PricingPlanId] = current, acc;
        }, {});
    }
);

export const PricingPlanChargeDetailsSelector = createImmutableSelector(
    [LifeCycleStepsPricingPlanSelector,
        MetadataCodeTypeSelector(CODES.RecurringBillerRuleConfiguration),
        MetadataCodeTypeSelector(CODES.OneTimeBillerRuleConfiguration),
        DecisionsLifeCycleStepsForOffersSelector],
    (pricingPlanInLifeCycle, recurringBillerRuleConfiguration, oneTimeBillerRuleConfiguration, stepsDetails) => {
        return getBillerRuleInstancesForPricingPlanInLifeCycle(pricingPlanInLifeCycle, oneTimeBillerRuleConfiguration, recurringBillerRuleConfiguration, stepsDetails);
    }
);

export const PricingPlanInLifeCycleForOffersSelector = createImmutableSelector(
    [CustomerStoreSelector, OfferingMetadataByIdSelector, OfferingMetadataPricingPlansSelector],
    (customerStore, offeringMedata, pricingPlanThumbnail) => {
        if (customerStore.lifeCycleDetails && Object.keys(offeringMedata).length && offeringMedata[customerStore.lifeCycleDetails.offerId]) {
            const lifeCycleDetailsInMetadata = flatten(pluck('LifeCycles', offeringMedata[customerStore.lifeCycleDetails.offerId].Options)).find((lifeCycle) => {
                if (lifeCycle) {
                    return lifeCycle.Id === customerStore.lifeCycleDetails.lifeCycleId;
                }
            });
            const filteredMetadataPricingPlans = [];
            Object.values(pricingPlanThumbnail).forEach((pricingPlan) => {
                lifeCycleDetailsInMetadata ? lifeCycleDetailsInMetadata.Steps.find((step) => {
                    if (step.PricingPlanId === pricingPlan.Id) {
                        filteredMetadataPricingPlans.push({
                            ...pricingPlan,
                            sortIndex: step.StepNumber
                        });
                        return step.PricingPlanId === pricingPlan.Id;
                    }
                }) : EMPTY_ARRAY;
            });
            return filteredMetadataPricingPlans.sort((a, b) => {
                return a.sortIndex - b.sortIndex;
            });
        }
    }
);

export const DashBoardLifeCycleStepsForOffersSelector = createImmutableSelector(
    [CustomerStoreSelector, OfferingMetadataByIdSelector],
    (customerStore, offeringMedata) => {
        if (customerStore.lifeCycleDetails && Object.keys(offeringMedata).length && offeringMedata[customerStore.lifeCycleDetails.offerId]) {
            const lifeCycleDetailsInMetadata = flatten(pluck('LifeCycles', offeringMedata[customerStore.lifeCycleDetails.offerId].Options)).find((lifeCycle) => {
                if (lifeCycle) {
                    return lifeCycle.Id === customerStore.lifeCycleDetails.lifeCycleId;
                }
            });
            const lifeCycleSteps = lifeCycleDetailsInMetadata ? lifeCycleDetailsInMetadata.Steps.reduce((acc, current) => {
                return acc[current.PricingPlanId] = current, acc;
            }, {}): EMPTY_OBJECT;
            return lifeCycleSteps;
        }
    }
);

export const LifeCyclePricingPlanDetailsSelector = createImmutableSelector(
    [
        PricingPlanInLifeCycleForOffersSelector,
        MetadataCodeTypeSelector(CODES.RecurringBillerRuleConfiguration),
        MetadataCodeTypeSelector(CODES.OneTimeBillerRuleConfiguration),
        DashBoardLifeCycleStepsForOffersSelector
    ],
    (pricingPlanInLifeCycle, recurringBillerRuleConfiguration, oneTimeBillerRuleConfiguration, stepsDetails) => {
        return getBillerRuleInstancesForPricingPlanInLifeCycle(pricingPlanInLifeCycle, oneTimeBillerRuleConfiguration, recurringBillerRuleConfiguration, stepsDetails);
    }
);

export const CartContainsOfferWithBillerRuleCycleLevelOtherThanItemSelector = createImmutableSelector(
    [ShoppingCartSelector, OfferingMetadataByIdSelector],
    (shoppingCart, offeringMetadataById) => {
        return (shoppingCart.Items || []).some((item) => {
            return (offeringMetadataById[item.OfferingId] || {}).BillerRuleCycleLevel !== BILLER_RULE_CYCLE_LEVEL.ITEM;
        });
    });

export const CartContainsOffcycleOfferSelector = createImmutableSelector(
    [ShoppingCartSelector, OfferingMetadataByIdSelector],
    (shoppingCart, offeringMetadataById) => {
        return (shoppingCart.Items || []).some((item) => {
            return (offeringMetadataById[item.OfferingId] || {}).BillerRuleCycleLevel === BILLER_RULE_CYCLE_LEVEL.ITEM;
        });
    });

export const SelectedDeliveryDecisionFromRocSelector = createSelector(
    [SelectedOfferingSelector],
    (selectedOfferingStore) => {
        if (selectedOfferingStore.data.deliveryDecisions) {
            const selectedDecisions = selectedOfferingStore.data.deliveryDecisions.find((item) => {
                return !!item.SelectedValue;
            });
            return selectedDecisions ? selectedDecisions.SelectedValue : undefined;
        }
    }
);

export const ServiceTaxCustomizationHashSelector = createImmutableSelector(
    [AttributesDataSelector],
    (attributes) => {
        return attributes.serviceTaxCustomizationHash;
    });
