import {ApiConstants,
    ThunkHelper} from 'invision-core';

export const SEARCH_REMARKS = {
    BEGIN: 'SEARCH_REMARKS_BEGIN',
    SUCCESS: 'SEARCH_REMARKS_SUCCESS',
    FAILURE: 'SEARCH_REMARKS_FAILURE'
};
const SEARCH_REMARKS_EVENTS = [SEARCH_REMARKS.BEGIN, SEARCH_REMARKS.SUCCESS, SEARCH_REMARKS.FAILURE];

export const searchCustomerRemarksPromise = (dispatch, customerId) => {
    return ThunkHelper(dispatch, SEARCH_REMARKS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SearchRemarks',
        data: {},
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        hasUnavailableServiceHandler: true
    })
        .then((response) => {
            dispatch(setCustomerRemarksServiceAvailability(true));

            return Promise.resolve(response);
        })
        .catch((error) => {

            // NOTE: Since `hasUnavailableServiceHandler` is passed to `ThunkHelper`, determine whether
            // the upstream service actually failed and the default handler was bypassed so the customer
            // can continue to load with reduced functionality
            if (error.handleUnavailableService) {
                dispatch(setCustomerRemarksServiceAvailability(false));
            } else {
                dispatch(setCustomerRemarksServiceAvailability(true));
            }

            return Promise.reject(error);
        });
};
export const searchCustomerRemarks = (customerId) => {
    return (dispatch) => {
        return searchCustomerRemarksPromise(dispatch, customerId);
    };
};

export const EDIT_REMARK = {
    CLEAR: 'EDIT_REMARK_CLEAR',
    SET: 'EDIT_REMARK_SET'
};
export const clearRemarkBeingEdited = () => {
    return {
        type: EDIT_REMARK.CLEAR
    };
};
export const setRemarkBeingEdited = (remarkToEdit) => {
    return {
        type: EDIT_REMARK.SET,
        payload: remarkToEdit
    };
};

export const UPDATE_REMARK = {
    BEGIN: 'UPDATE_REMARK_BEGIN',
    SUCCESS: 'UPDATE_REMARK_SUCCESS',
    FAILURE: 'UPDATE_REMARK_FAILURE'
};
const UPDATE_REMARK_EVENTS = [UPDATE_REMARK.BEGIN, UPDATE_REMARK.SUCCESS, UPDATE_REMARK.FAILURE];

export const updateCustomerRemarkPromise = (dispatch, data, requestObject) => {
    return ThunkHelper(dispatch, UPDATE_REMARK_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/UpdateRemark',
        data: {
            Remark: data.remark
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: data.customerId
        }
    }, requestObject);
};
export const updateCustomerRemark = (data, requestObject) => {
    return (dispatch) => {
        return updateCustomerRemarkPromise(dispatch, data, requestObject);
    };
};

export const CREATE_REMARK = {
    BEGIN: 'CREATE_REMARK_BEGIN',
    SUCCESS: 'CREATE_REMARK_SUCCESS',
    FAILURE: 'CREATE_REMARK_FAILURE'
};
const CREATE_REMARK_EVENTS = [CREATE_REMARK.BEGIN, CREATE_REMARK.SUCCESS, CREATE_REMARK.FAILURE];

export const createCustomerRemarkPromise = (dispatch, data, requestObject) => {
    return ThunkHelper(dispatch, CREATE_REMARK_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/CreateRemark',
        data: {
            Remark: data.remark
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: data.customerId
        }
    }, requestObject);
};
export const createCustomerRemark = (data, requestObject) => {
    return (dispatch) => {
        return createCustomerRemarkPromise(dispatch, data, requestObject);
    };
};

export const SET_REMARKS_SERVICE_AVAILABILITY = 'SET_REMARKS_SERVICE_AVAILABILITY';

export const setCustomerRemarksServiceAvailability = (serviceAvailable = true) => {
    return {
        type: SET_REMARKS_SERVICE_AVAILABILITY,
        payload: serviceAvailable
    };
};
