export class InvisionConfigurationAdditionalProperties {
    constructor() {
        this.displayCvv = true;
    }
}

export const ValueTypeEnum = {
    BOOLEAN: 'boolean',
    NUMBER: 'number',
    STRING: 'string'
};

export class AdditionPropertyDescriptor {
    constructor(key, propertyName, valueType) {
        this.key = key;
        this.propertyName = propertyName;
        this.valueType = valueType;
    }
}

export const invisionConfigurationAdditionalPropertyDescriptors = [
    new AdditionPropertyDescriptor('display_cvv', 'displayCvv', ValueTypeEnum.BOOLEAN)
];
