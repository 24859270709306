import pathOr from 'ramda/src/pathOr';
import clone from 'ramda/src/clone';
import {InjectorHelper} from 'invision-core';
import {NON_BULK_SERVICE_STATUSES} from 'invision-core/src/constants/status.constants';
import {ORDER_STATUS, ORDER_TYPES} from '../../components/customer/orderHistory/orderDetails/order.details.constants';
import {
    NON_BULK_SERVICE_CATEGORIZATION,
    TYPE_OF_SERVICE
} from '../../components/customer/servicesAndUsage/servicesAndSharedEntitlements/services/services.list.constants';
import i18n from 'invision-core/src/components/i18n/i18n';
import {SERVICE_SUSPENSION_TYPES} from '../../components/shared/constants/service.constants';
import CustomerCareKeys from '../../locales/keys';
import {EMPTY_STRING} from '../constants/common.constants';


const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export function getSuspensionTypeByCode(suspensionTypeCode) {
    if (suspensionTypeCode === SERVICE_SUSPENSION_TYPES.NETWORK) {
        return i18n.translate(CustomerCareKeys.SERVICE_SUSPENSION_TYPE.NETWORK_ONLY);
    }
    if (suspensionTypeCode === SERVICE_SUSPENSION_TYPES.NETWORK_AND_BILLING) {
        return i18n.translate(CustomerCareKeys.SERVICE_SUSPENSION_TYPE.NETWORK_AND_BILLING);
    }
    return EMPTY_STRING;
}

export function createTableViewModel(services, currentBusinessUnitCurrencyCode, activeProducts, currentAccountServices = [], orders=[], units, nonTelcoUsageService) {
    const $filter = InjectorHelper.getFilterService();


    const formatView = (row) => {
        const serviceAttribute = row.ServiceAttributeValues && row.ServiceAttributeValues.length ?
            row.ServiceAttributeValues.find(attribute => {
                return attribute && attribute.Value ? attribute.Value === row.ServiceIdentifier : null;
            }) : null;

        const serviceAttributesWithInstanceId = row.ServiceAttributeValues && row.ServiceAttributeValues.length ?
            row.ServiceAttributeValues.find(attribute => {
                return attribute && attribute.IsServiceIdentifier;
            }) : undefined;

        const matchingCurrentAccountService = currentAccountServices.find((service) => {
            return service.ServiceIdentifier.Value === row.ServiceIdentifier;
        });

        const matchingOpenOrder = orders.find((ord) => {
            return row.SubscriberProductId === pathOr(null, ['OrderItems', 0, 'LockerItemId'], ord)
                && ord.OrderStatus === ORDER_STATUS.OPEN
                && (ord.Type === ORDER_TYPES.SUSPEND || ord.Type === ORDER_TYPES.RESTORE);
        });
        const usageCaps = (row.UsageCaps || []).map(item => {
            const usageCapItem = clone(item);
            if (item.ThresholdType.Code && units.items.length) {
                const unit = units.items.filter(unitItem => {
                    return unitItem.Value === item.ThresholdType.Code.toString();
                })[0];
                usageCapItem.ThresholdType.Name = unit.Name;
            }
            return usageCapItem;
        });
        const itemObj = {
            //TODO: find out if row.UsageCaps has some breached caps
            deviceFinance: row.deviceFinance,
            EllipsisMenuOption: configureEllipsisOptions(row.SearchServiceType,
                row.ServiceStatus,
                row.SubscriberProductId,
                activeProducts,
                matchingOpenOrder,
                serviceAttributesWithInstanceId && serviceAttributesWithInstanceId.ServiceInstanceId || undefined),
            NonTelcoUsageLoaded: !!nonTelcoUsageService,
            NetworkIdentifier: row.NetworkIdentifier,
            NetworkIdentifierName: row.NetworkIdentifierName,
            NetworkServiceIdentifierAttributeId: row.NetworkServiceIdentifierAttributeId,
            HasOffCycleCharges: row.HasOffCycleCharges,
            ServiceIdentifier: {
                FormattedValue: row.ServiceIdentifierFormatted,
                Value: row.ServiceIdentifier,
                ServiceId: serviceAttribute && serviceAttribute.ServiceId || undefined,
                ServiceInstanceId: (serviceAttributesWithInstanceId && serviceAttributesWithInstanceId.ServiceInstanceId) ? serviceAttributesWithInstanceId.ServiceInstanceId : undefined,
                SubscriberProductId: row.SubscriberProductId || undefined
            },
            ServiceIdentifierAttributeId: row.ServiceIdentifierAttributeId,
            ServiceIdentifierName: row.ServiceIdentifierName,
            ServiceLifeCycleStatus: row.ServiceLifeCycleStatusDetails && row.ServiceLifeCycleStatusDetails.Name,
            ServiceName: row.ServiceName,
            ServiceStatus: row.ServiceStatus,
            ServiceSuspendedDate: $filter('localShortDate')(pathOr(undefined, ['SuspendedDate'], matchingCurrentAccountService)),
            SuspensionDetail: row.SuspensionDetail,
            SuspendType: getSuspensionTypeByCode(row.SuspendTypeCode),
            SuspendTypeCode: row.SuspendTypeCode,
            UsageCapElections: {
                usageCaps: usageCaps,
                currencyCode: currentBusinessUnitCurrencyCode
            },
            TypeOfService: row.SearchServiceType
        };
        if (row.ServiceAttributes) {
            itemObj.ServiceAttributes = [];
            row.ServiceAttributes.forEach((attribute) => {
                itemObj.ServiceAttributes.push(
                    {
                        ServiceAttributeId: attribute.AttributeId,
                        ServiceAttributeName: attribute.AttributeName,
                        Value: attribute.AttributeValue
                    }
                );
            });
        }
        return itemObj;
    };

    if (services && services.serviceThumbnails) {
        return services.serviceThumbnails.map(formatView);
    }
    return EMPTY_ARRAY;
}

function configureEllipsisOptions(SearchServiceType, serviceStatus, subscriberProductId, activeProducts, matchingOpenOrder, serviceInstanceId) {
    const serviceSuspended = activeProducts ? activeProducts.find((product) => {
        return product.Items ? product.Items.find((item) => {
            return item.LockerItemId === subscriberProductId
            && product.OrderStatus === ORDER_STATUS.OPEN ||
            product.OrderStatus === ORDER_STATUS.PENDING;
        }): EMPTY_OBJECT;
    }) : EMPTY_OBJECT;

    if (SearchServiceType === TYPE_OF_SERVICE.BULK) {
        return TYPE_OF_SERVICE.BULK;
    } else if (SearchServiceType === TYPE_OF_SERVICE.NON_BULK && serviceStatus === NON_BULK_SERVICE_STATUSES.ACTIVE) {
        if (serviceSuspended) {
            return matchingOpenOrder
                ? (serviceInstanceId ? NON_BULK_SERVICE_CATEGORIZATION.LOCKED_SUSPENDED_NON_BULK_RESUME_DISABLED_WITH_MANAGED_FEATURES : NON_BULK_SERVICE_CATEGORIZATION.LOCKED_SUSPENDED_NON_BULK_RESUME_DISABLED)
                : (serviceInstanceId  ? NON_BULK_SERVICE_CATEGORIZATION.LOCKED_SUSPENDED_NON_BULK_WITH_MANAGED_FEATURES : NON_BULK_SERVICE_CATEGORIZATION.LOCKED_SUSPENDED_NON_BULK);
        } else {
            return serviceInstanceId ? NON_BULK_SERVICE_CATEGORIZATION.ACTIVE_NON_BULK_WITH_MANAGED_FEATURES : NON_BULK_SERVICE_CATEGORIZATION.ACTIVE_NON_BULK;
        }
    } else if (SearchServiceType === TYPE_OF_SERVICE.NON_BULK && (serviceStatus === NON_BULK_SERVICE_STATUSES.SUSPENDED || serviceStatus === NON_BULK_SERVICE_STATUSES.PENDING_SUSPENSION) ) {
        return matchingOpenOrder
            ? (serviceInstanceId ? NON_BULK_SERVICE_CATEGORIZATION.LOCKED_SUSPENDED_NON_BULK_RESUME_DISABLED_WITH_MANAGED_FEATURES : NON_BULK_SERVICE_CATEGORIZATION.LOCKED_SUSPENDED_NON_BULK_RESUME_DISABLED)
            : (serviceInstanceId ? NON_BULK_SERVICE_CATEGORIZATION.SUSPENDED_NON_BULK_WITH_MANAGED_FEATURES : NON_BULK_SERVICE_CATEGORIZATION.SUSPENDED_NON_BULK);
    } else if (serviceStatus === NON_BULK_SERVICE_STATUSES.REMOVED) {
        return NON_BULK_SERVICE_CATEGORIZATION.REMOVED;
    }
}

export function getFormattedServiceAttributeValue(serviceAttributeId, serviceAttributeValue, serviceAttributes, regularExpressions) {
    const serviceAttribute = serviceAttributes[serviceAttributeId];

    if (!serviceAttribute) {
        return serviceAttributeValue;
    }
    const matchPattern = regularExpressions[serviceAttribute.AdditionalProperties.match_pattern_regex_code];
    const formatPattern = regularExpressions[serviceAttribute.AdditionalProperties.format_pattern_regex_code];

    if (matchPattern && formatPattern) {
        try {
            return serviceAttributeValue.replace(new RegExp(matchPattern.AdditionalProperties.regex), formatPattern.AdditionalProperties.regex);
        } catch (error) {
            return serviceAttributeValue;
        }
    }

    return serviceAttributeValue;
}
