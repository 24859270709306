import Immutable from 'seamless-immutable';
import * as SearchActions from '../actions/search.customer.actions';

export const INITIAL_STATE = Immutable({
    customerSearchModel: null
});

export default function SearchCustomersReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case SearchActions.SET_CUSTOMER_SEARCH_MODEL:
            return state.set('customerSearchModel', payload);
        case SearchActions.RESET_SEARCH_CUSTOMER_MODEL_RECOVERABLE:
            return INITIAL_STATE;
        default:
            return state;
    }
}