export class AutopayConfigurationAdditionalProperties {
    constructor() {
        this.numberOfDays = 0;
    }
}

export const ValueTypeEnum = {
    BOOLEAN: 'boolean',
    NUMBER: 'number',
    STRING: 'string'
};

export class AdditionPropertyDescriptor {
    constructor(key, propertyName, valueType) {
        this.key = key;
        this.propertyName = propertyName;
        this.valueType = valueType;
    }
}

export const autopayConfigurationAdditionalPropertyDescriptors = [
    new AdditionPropertyDescriptor('number_of_days', 'numberOfDays', ValueTypeEnum.NUMBER)
];
