import Immutable from 'seamless-immutable';
import contains from 'ramda/src/includes';
import isNil from 'ramda/src/isNil';
import pathOr from 'ramda/src/pathOr';
import {createSelector} from 'reselect';
import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {
    CreditCheckConfigurationSelector as CustomerCreditCheckConfigurationSelector,
    SelectedCustomerIsCreditCheckSkippedSelector,
    SelectedCustomerSelector,
    SelectedCustomerCreditCheckDetailsSelector
} from './customer.selectors';
import {CREDIT_CHECK_STATUS, UPDATE_CLASSIFICATION_METHOD_STATUS} from '../constants/credit.check.constants';
import FaultCodes from '../../api/fault.code.keys';
import {SubmittedOrderErrorSelector} from './selected.offering.order.selectors';
import {addressStateRegionProvinceValueOptionsForCountry} from '../helpers/customer.selectors.helpers';
import {determineCreditClassificationStatus} from './credit.check.helper';

const IMMUTABLE_EMPTY_ARRAY = Immutable([]);

export const CreditCheckConfigurationSelector = CustomerCreditCheckConfigurationSelector;
export const IsCreditCheckSkippedSelector = SelectedCustomerIsCreditCheckSkippedSelector;
export const SubscriberCreditCheckDetailsSelector = SelectedCustomerCreditCheckDetailsSelector;
export const CreditCheckAdditionalPropertiesSelector = createSelector(
    [CreditCheckConfigurationSelector],
    (config) => {
        return !isNil(config) && config.RequiredAdditionalProperties;
    }
);

export const CanUserOverrideCreditCheckSelector = createSelector(
    [CreditCheckConfigurationSelector],
    (config) => {
        return !isNil(config) && config.CanOverrideCreditCheckClassification;
    }
);

export const CreditCheckClassificationOptionsSelector = createSelector(
    [CreditCheckConfigurationSelector],
    (config) => {
        if (config && config.CreditClassifications) {
            return config.CreditClassifications.asMutable({
                deep: true
            }).map((item) => {
                return {
                    id: item.Id,
                    text: item.Name,
                    selected: false
                };
            });
        }
    }
);

export const IsCreditCheckEnabledSelector = createSelector(
    [CreditCheckConfigurationSelector],
    (config) => {
        return !isNil(config) && !isNil(config.CreditCheckNotEnabled) && !config.CreditCheckNotEnabled;
    }
);

export const IsCreditCheckRequiredSelector = createSelector(
    [CreditCheckConfigurationSelector],
    (config) => {
        return !isNil(config) && !!config.CreditCheckRequired;
    }
);

export const IsMatchSubscriberCreditCheckDetailsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        const selectedCustomerId = pathOr(null, ['data', 'Id'], selectedCustomer);
        const creditCheckCustomerId = pathOr(null, ['creditCheck', 'Subscriber', 'Id'], selectedCustomer);
        return (selectedCustomerId === creditCheckCustomerId);
    }
);

export const SubscriberIsUpdatingCreditClassificationSelector = createSelector(
    [SubscriberCreditCheckDetailsSelector],
    (subscriberCreditCheck) => {
        return subscriberCreditCheck.isUpdatingCreditClassification;
    }
);

export const SubscriberCreditCheckStatusSelector = createSelector(
    [SubscriberCreditCheckDetailsSelector, SubmittedOrderErrorSelector, SubscriberCreditCheckDetailsSelector],
    (selectedCustomerCreditCheckDetails, submittedOrderLastAttemptError, subscriberCreditCheckDetails) => {
        const editOfferErrorCode = pathOr(null, ['code'], submittedOrderLastAttemptError);
        const newConnectErrorCode = pathOr(null, ['error', 'code'], subscriberCreditCheckDetails);
        const errorCodesOfInterest = [FaultCodes.ORDER_SUBMITTED_CREDIT_CHECK_ERROR];
        if ((editOfferErrorCode && contains(String(editOfferErrorCode), errorCodesOfInterest)) ||
            (newConnectErrorCode && contains(String(newConnectErrorCode), errorCodesOfInterest))) {
            return CREDIT_CHECK_STATUS.ERROR;
        } else {
            const creditCheckClassification = pathOr(null, ['CreditCheckClassification'], selectedCustomerCreditCheckDetails);
            return determineCreditClassificationStatus(creditCheckClassification);
        }
    }
);

export const SubscriberCreditCheckAddressStatesOptions = createSelector(
    [
        SelectedCustomerSelector,
        SubscriberCreditCheckDetailsSelector,
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressCountry),
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressStateProvinceRegion)],
    (selectedCustomer, selectedCustomerCreditCheckDetails, addressCountries, addressStates) => {
        const country = pathOr(null, ['Address', 'Country'], selectedCustomerCreditCheckDetails) || selectedCustomer.data.HomeCountry;
        if (country) {
            return addressStateRegionProvinceValueOptionsForCountry(country, addressCountries, addressStates);
        } else {
            return [];
        }
    }
);

export const SubscriberCreditClassificationStatusSelector = createSelector(
    [SubscriberCreditCheckDetailsSelector],
    (selectedCustomerCreditCheckDetails) => {
        const creditCheckClassification = pathOr(null, ['CreditCheckClassification'], selectedCustomerCreditCheckDetails);
        return determineCreditClassificationStatus(creditCheckClassification);
    }
);

export const RunCreditCheckErrorMessageSelector = createImmutableSelector(
    [SubscriberCreditCheckDetailsSelector],
    (subscriberCreditCheckDetails) => {
        return subscriberCreditCheckDetails.runCreditCheckErrorMessage;
    }
);

export const IsCreditCheckSuccessSelector = createSelector(
    [SubscriberCreditClassificationStatusSelector],
    (subscriberCreditClassificationStatus) => {
        return subscriberCreditClassificationStatus === CREDIT_CHECK_STATUS.SUCCESS;
    }
);

export const MarkMandatoryCreditCheckFieldsDisabledSelector = createSelector(
    [SubscriberCreditCheckDetailsSelector],
    (selectedCustomerCreditCheckDetails) => {
        const creditCheckClassificationMethod = pathOr(null, ['CreditCheckClassification', 'CreditCheckMethod'], selectedCustomerCreditCheckDetails);
        return (creditCheckClassificationMethod === UPDATE_CLASSIFICATION_METHOD_STATUS.RUN_CREDIT_CHECK);
    }
);

export const IsAddressMandatoryInCreditCheckSelector = createSelector(
    [CreditCheckConfigurationSelector],
    (config) => {
        return (!isNil(config) && config.RequiredSubscriberFields && config.RequiredSubscriberFields.includes('address'));
    }
);

export const CreditClassificationsSelector = createImmutableSelector(
    [CreditCheckConfigurationSelector],
    (config) => {
        return pathOr(IMMUTABLE_EMPTY_ARRAY, ['CreditClassifications'], config);
    }
);
