import isNil from 'ramda/src/isNil';
import {transformCartItemInstanceProperties} from './instance.property.helper';
import {BILLING_EFFECTIVE_DATE_INTENTION} from '../constants/wizard.constants';

export const buildSubmitOrderRequestData = (shoppingCart,
    paymentInstrumentIds,
    paymentInstruments,
    recordPaymentInfo = true,
    redemptionCodes = [],
    discretionaryDiscounts = [],
    discretionaryDiscountReasonCode = null,
    executionOptions = {},
    shippingAddressId = null,
    shippingMethodId = null,
    shipToName = null,
    offeringId = null,
    orderTaxLocation,
    useDefaults = true,
    additionalProperties = null,
    orderDetails = {}) => {
    const requestData = {
        AdditionalProperties: additionalProperties,
        DiscretionaryDiscounts: discretionaryDiscounts,
        OfferingId: offeringId,
        RedemptionCodes: redemptionCodes,
        ShoppingCart: transformCartItemInstanceProperties(shoppingCart, executionOptions),
        UseDefaults: useDefaults
    };

    if (recordPaymentInfo) {
        requestData.PaymentInstrumentIds = paymentInstrumentIds;
    } else {
        requestData.RecordPaymentInformation = recordPaymentInfo;
        requestData.PaymentInstruments = [paymentInstruments];
    }

    if (orderDetails?.comment) {
        requestData.OrderComment = orderDetails.comment;
    }

    if (orderDetails?.reasonCode) {
        requestData.ReasonCode = orderDetails.reasonCode;
    }

    if (executionOptions?.effective) {
        requestData.ExecutionDate =
            executionOptions.effective === BILLING_EFFECTIVE_DATE_INTENTION.OnSpecificDate ?
                executionOptions.specifiedDate : null;
        requestData.ExecutionDateIntention = executionOptions.effective;
    }

    const parsedDiscretionaryDiscountReasonCode = isNil(discretionaryDiscountReasonCode) ? null : Number(discretionaryDiscountReasonCode);
    if (parsedDiscretionaryDiscountReasonCode) {
        requestData.DiscretionaryDiscountReasonCode = parsedDiscretionaryDiscountReasonCode;
    }
    if (shippingAddressId) {
        requestData.ShippingAddressId = shippingAddressId;
        requestData.ShippingMethodId = shippingMethodId;
        requestData.ShipToName = shipToName;
    }

    if (orderTaxLocation) {
        requestData.OrderTaxLocation = orderTaxLocation;
    }

    return requestData;
};

export const buildSubmitRestoreOrderRequestData = (subscriptionId,
    paymentInstrumentIds,
    paymentInstruments,
    recordPaymentInfo = true,
    redemptionCodes = [],
    discretionaryDiscounts = [],
    discretionaryDiscountReasonCode = null,
    shippingAddressId = null,
    shippingMethodId = null,
    shipToName = null,
    waiveFee = false,
    waiveFeeReason = null,
    waiveLateFee = false,
    waiveLateFeeReason = null) => {
    const requestData = {
        DiscretionaryDiscounts: discretionaryDiscounts,
        RedemptionCodes: redemptionCodes,
        SubscriptionId: subscriptionId,
    };

    if (recordPaymentInfo) {
        requestData.PaymentInstrumentIds = paymentInstrumentIds;
    } else {
        requestData.RecordPaymentInformation = recordPaymentInfo;
        requestData.PaymentInstruments = [paymentInstruments];
    }

    const parsedDiscretionaryDiscountReasonCode = isNil(discretionaryDiscountReasonCode) ? null : Number(discretionaryDiscountReasonCode);
    if (parsedDiscretionaryDiscountReasonCode) {
        requestData.DiscretionaryDiscountReasonCode = parsedDiscretionaryDiscountReasonCode;
    }

    if (shippingAddressId) {
        requestData.ShippingAddressId = shippingAddressId;
        requestData.ShippingMethodId = shippingMethodId;
        requestData.ShipToName = shipToName;
    }

    if (waiveFee) {
        requestData.WaiveFee = waiveFee;
        const parsedWaiveFeeReason = isNil(waiveFeeReason) ? null : Number(waiveFeeReason);
        if (parsedWaiveFeeReason) {
            requestData.WaiveFeeReason = parsedWaiveFeeReason;
        }
    }

    if (waiveLateFee) {
        requestData.WaiveLateFee = waiveLateFee;
        const parsedWaiveLateFeeReason = isNil(waiveLateFeeReason) ? null : Number(waiveLateFeeReason);
        if (parsedWaiveLateFeeReason) {
            requestData.WaiveLateFeeReason = parsedWaiveLateFeeReason;
        }
    }

    return requestData;
};

export const buildSubmitGiftOrderRequestData = (shoppingCart,
    paymentInstrumentIds,
    paymentInstruments,
    recordPaymentInfo = true,
    giftOrderInfo,
    redemptionCodes = [],
    discretionaryDiscounts = [],
    discretionaryDiscountReasonCode = null,
    giftOrderExecutionDate = null,
    shippingAddressId = null,
    shippingMethodId = null,
    shipToName = null,
    additionalProperties = null) => {
    const requestData = buildSubmitOrderRequestData(shoppingCart,
        paymentInstrumentIds,
        paymentInstruments,
        recordPaymentInfo,
        redemptionCodes,
        discretionaryDiscounts,
        discretionaryDiscountReasonCode,
        giftOrderExecutionDate,
        shippingAddressId,
        shippingMethodId,
        shipToName);

    if (giftOrderInfo.giftMessage) {
        requestData.SenderMessage = giftOrderInfo.giftMessage;
    }
    if (giftOrderInfo.notificationDate) {
        requestData.NotificationDate = giftOrderInfo.notificationDate;
    }

    requestData.AdditionalProperties = additionalProperties;
    requestData.RecipientEmail = giftOrderInfo.recipientEmail;
    requestData.RecipientName = giftOrderInfo.recipientName;
    requestData.SendNotification = true;

    return requestData;
};
