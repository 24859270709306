import {ApiConstants, ThunkHelper} from 'invision-core';
export const PREVIEW_GIFT_REDEMPTION_ORDER = {
    BEGIN: 'PREVIEW_GIFT_REDEMPTION_ORDER_BEGIN',
    SUCCESS: 'PREVIEW_GIFT_REDEMPTION_ORDER_SUCCESS',
    FAILURE: 'PREVIEW_GIFT_REDEMPTION_ORDER_FAILURE'
};

export const UPDATE_PREVIEW_WITH_SUBSCRIPTIONS_QUOTE = {
    BEGIN: 'UPDATE_PREVIEW_WITH_SUBSCRIPTIONS_QUOTE_BEGIN',
    SUCCESS: 'UPDATE_PREVIEW_WITH_SUBSCRIPTIONS_QUOTE_SUCCESS',
    FAILURE: 'UPDATE_PREVIEW_WITH_SUBSCRIPTIONS_QUOTE_FAILURE'
};

export const SUBMIT_GIFT_ORDER_REDEMPTION_ORDER = {
    BEGIN: 'SUBMIT_GIFT_ORDER_REDEMPTION_ORDER_BEGIN',
    SUCCESS: 'SUBMIT_GIFT_ORDER_REDEMPTION_ORDER_SUCCESS',
    FAILURE: 'SUBMIT_GIFT_ORDER_REDEMPTION_ORDER_FAILURE'
};

export const RESET_REDEEM_GIFT_ORDER = 'RESET_REDEEM_GIFT_ORDER';
export const UPDATE_REDEMPTION_CODE = 'UPDATE_REDEMPTION_CODE';
export const UPDATE_GIFT_ORDER_SHIPPING_ADDRESS = 'UPDATE_GIFT_ORDER_SHIPPING_ADDRESS';
export const UPDATE_GIFT_ORDER_SHIP_TO_NAME = 'UPDATE_GIFT_ORDER_SHIP_TO_NAME';

const PREVIEW_GIFT_REDEMPTION_ORDER_TYPES = [
    PREVIEW_GIFT_REDEMPTION_ORDER.BEGIN,
    PREVIEW_GIFT_REDEMPTION_ORDER.SUCCESS,
    PREVIEW_GIFT_REDEMPTION_ORDER.FAILURE
];

const UPDATE_PREVIEW_WITH_SUBSCRIPTIONS_QUOTE_TYPES = [
    UPDATE_PREVIEW_WITH_SUBSCRIPTIONS_QUOTE.BEGIN,
    UPDATE_PREVIEW_WITH_SUBSCRIPTIONS_QUOTE.SUCCESS,
    UPDATE_PREVIEW_WITH_SUBSCRIPTIONS_QUOTE.FAILURE
];

const SUBMIT_GIFT_ORDER_REDEMPTION_ORDER_TYPES = [
    SUBMIT_GIFT_ORDER_REDEMPTION_ORDER.BEGIN,
    SUBMIT_GIFT_ORDER_REDEMPTION_ORDER.SUCCESS,
    SUBMIT_GIFT_ORDER_REDEMPTION_ORDER.FAILURE
];


const updatePreviewWithSubscriptionsQuotePromise = (dispatch, redemptionCode, paymentInstrumentId, customerId) => {
    const requestData = {
        RedemptionCode: redemptionCode
    };
    if (paymentInstrumentId) {
        requestData.ReturnSubscriptionPreviews = true;
        requestData.PaymentInstrumentIds = [paymentInstrumentId];
    }

    let customHeaders = {};
    if (customerId) {
        customHeaders = {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        };
    }

    return ThunkHelper(dispatch, UPDATE_PREVIEW_WITH_SUBSCRIPTIONS_QUOTE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/PreviewGiftRedemptionOrder',
        data: requestData,
        headers: customHeaders
    });
};


const fetchGiftOrderPreviewPromise = (dispatch, redemptionCode, paymentInstrumentId, customerId) => {
    const requestData = {
        RedemptionCode: redemptionCode
    };
    if (paymentInstrumentId) {
        requestData.ReturnSubscriptionPreviews = true;
        requestData.PaymentInstrumentIds = [paymentInstrumentId];
    }

    let customHeaders = {};
    if (customerId) {
        customHeaders = {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        };
    }

    return ThunkHelper(dispatch, PREVIEW_GIFT_REDEMPTION_ORDER_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/PreviewGiftRedemptionOrder',
        data: requestData,
        headers: customHeaders
    });
};

export const updatePreviewWithSubscriptionsQuote = (redemptionCode, paymentInstrumentId, customerId) => {
    return (dispatch) => {
        return updatePreviewWithSubscriptionsQuotePromise(dispatch, redemptionCode, paymentInstrumentId, customerId);
    };
};

export const fetchGiftOrderPreview = (redemptionCode) => {
    return (dispatch) => {
        return fetchGiftOrderPreviewPromise(dispatch, redemptionCode, null, null);
    };
};

const submitRedeemGiftOrderPromise = (dispatch, redemptionCode, customerId, paymentInstrumentId, shippingAddress, shippingMethodId, shipToName) => {
    const requestData = {
        RedemptionCode: redemptionCode
    };
    if (paymentInstrumentId) {
        requestData.PaymentInstrumentIds = [paymentInstrumentId];
    }
    if (shippingAddress) {
        requestData.ShippingAddress = shippingAddress;
        requestData.ShippingMethodId = shippingMethodId;
        requestData.ShipToName = shipToName;
        requestData.RecordPaymentInformation = false;
    }

    return ThunkHelper(dispatch, SUBMIT_GIFT_ORDER_REDEMPTION_ORDER_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/SubmitGiftRedemptionOrder',
        data: requestData,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const submitRedeemGiftOrder = ({customerId, redemptionCode, shippingAddress = null, shippingMethodId = null, shipToName = null, paymentInstrumentId = null}) => {
    return (dispatch) => {
        return submitRedeemGiftOrderPromise(dispatch, redemptionCode, customerId, paymentInstrumentId, shippingAddress, shippingMethodId, shipToName);
    };
};

export const resetRedeemGiftOrder = () => {
    return {
        type: RESET_REDEEM_GIFT_ORDER
    };
};

export const updateGiftOrderRedemptionCode = (redemptionCode) => {
    return {
        type: UPDATE_REDEMPTION_CODE,
        payload: redemptionCode
    };
};

export const updateGiftOrderSelectedShippingAddress = (shippingAddress) => {
    return {
        type: UPDATE_GIFT_ORDER_SHIPPING_ADDRESS,
        payload: shippingAddress
    };
};

export const updateGiftOrderShipToName = (shipToName) => {
    return {
        type: UPDATE_GIFT_ORDER_SHIP_TO_NAME,
        payload: shipToName
    };
};

export const SET_SELECTED_SHIPPING_METHOD = 'SET_SELECTED_SHIPPING_METHOD';
export const setSelectedShippingMethod = (shippingMethod) => {
    return {
        type: SET_SELECTED_SHIPPING_METHOD,
        payload: shippingMethod
    };
};
