import {
    ApiConstants,
    i18n,
    InjectorHelper,
    InvoiceHelper,
    ThunkHelper
} from 'invision-core';
import CustomerCareLocaleKeys from '../../locales/keys';

export const RETRIEVE_LEDGER_SUMMARY = {
    BEGIN: 'RETRIEVE_LEDGER_SUMMARY_BEGIN',
    SUCCESS: 'RETRIEVE_LEDGER_SUMMARY_SUCCESS',
    FAILURE: 'RETRIEVE_LEDGER_SUMMARY_FAILURE'
};
const RETRIEVE_LEDGER_SUMMARY_TYPES = [
    RETRIEVE_LEDGER_SUMMARY.BEGIN,
    RETRIEVE_LEDGER_SUMMARY.SUCCESS,
    RETRIEVE_LEDGER_SUMMARY.FAILURE
];

export const RETRIEVE_OPEN_INVOICES_LIST = {
    BEGIN: 'RETRIEVE_OPEN_INVOICES_LIST_BEGIN',
    SUCCESS: 'RETRIEVE_OPEN_INVOICES_LIST_SUCCESS',
    FAILURE: 'RETRIEVE_OPEN_INVOICES_LIST_FAILURE'
};
const RETRIEVE_OPEN_INVOICES_LIST_TYPES = [
    RETRIEVE_OPEN_INVOICES_LIST.BEGIN,
    RETRIEVE_OPEN_INVOICES_LIST.SUCCESS,
    RETRIEVE_OPEN_INVOICES_LIST.FAILURE
];
export const retrieveLedgerSummaryPromise = (dispatch, data, dispatchTypes = RETRIEVE_LEDGER_SUMMARY_TYPES) => {

    return ThunkHelper(dispatch, dispatchTypes, {
        data: data.request,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: data.customerId
        },
        method: 'post',
        url: 'SubscriberManagement/RetrieveLedgerSummary'
    });
};

export const retrieveLedgerSummary = (data, fetchOpenInvoicesList = false) => {
    return (dispatch) => {
        return retrieveLedgerSummaryPromise(dispatch, data, fetchOpenInvoicesList ? RETRIEVE_OPEN_INVOICES_LIST_TYPES : RETRIEVE_LEDGER_SUMMARY_TYPES);
    };
};

export const RETRIEVE_INVOICES_LIST_DROPDOWN = {
    BEGIN: 'RETRIEVE_INVOICES_LIST_DROPDOWN_BEGIN',
    SUCCESS: 'RETRIEVE_INVOICES_LIST_DROPDOWN_SUCCESS',
    FAILURE: 'RETRIEVE_INVOICES_LIST_DROPDOWN_FAILURE'
};

const RETRIEVE_INVOICES_LIST_DROPDOWN_TYPES = [
    RETRIEVE_INVOICES_LIST_DROPDOWN.BEGIN,
    RETRIEVE_INVOICES_LIST_DROPDOWN.SUCCESS,
    RETRIEVE_INVOICES_LIST_DROPDOWN.FAILURE
];

export const retrieveInvoicesForDropdown = (data) => {
    return (dispatch) => {
        return retrieveLedgerSummaryPromise(dispatch, data, RETRIEVE_INVOICES_LIST_DROPDOWN_TYPES);
    };
};

export const RETRIEVE_LEDGER_INVOICE = {
    BEGIN: 'RETRIEVE_LEDGER_INVOICE_BEGIN',
    SUCCESS: 'RETRIEVE_LEDGER_INVOICE_SUCCESS',
    FAILURE: 'RETRIEVE_LEDGER_INVOICE_FAILURE'
};
const RETRIEVE_LEDGER_INVOICE_TYPES = [
    RETRIEVE_LEDGER_INVOICE.BEGIN,
    RETRIEVE_LEDGER_INVOICE.SUCCESS,
    RETRIEVE_LEDGER_INVOICE.FAILURE
];

export const retrieveLedgerInvoiceDetailsPromise = (dispatch, invoiceDetailsRequest) => {
    return ThunkHelper(dispatch, RETRIEVE_LEDGER_INVOICE_TYPES, {
        data: invoiceDetailsRequest.request,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: invoiceDetailsRequest.customerId
        },
        method: 'post',
        url: 'SubscriberManagement/RetrieveLedgerInvoiceDetails'
    });
};

export const retrieveLedgerInvoiceDetails = (invoiceDetailsRequest) => {
    return (dispatch) => {
        return retrieveLedgerInvoiceDetailsPromise(dispatch, invoiceDetailsRequest);
    };
};

export const UPDATE_BILLING_PAYMENTS_PAGE_NUMBER = 'UPDATE_BILLING_PAYMENTS_PAGE_NUMBER';

export const UPDATE_BILLING_PAYMENTS_DATE_RANGE = 'UPDATE_BILLING_PAYMENTS_DATE_RANGE';

export const updateDateRange = (startDate, endDate, dateShortcut) => {
    return {
        payload: {
            dateShortcut,
            endDate,
            startDate
        },
        type: UPDATE_BILLING_PAYMENTS_DATE_RANGE
    };
};

export const UPDATE_BILLING_PAYMENTS_TYPE_FILTER = 'UPDATE_BILLING_PAYMENTS_TYPE_FILTER';

export const updateTypeFilter = (typeFilter) => {
    return {
        payload: {
            typeFilter
        },
        type: UPDATE_BILLING_PAYMENTS_TYPE_FILTER
    };
};

export const UPDATE_BILLING_PAYMENTS_INVOICE_FILTER = 'UPDATE_BILLING_PAYMENTS_INVOICE_FILTER';

export const updateInvoiceFilter = (invoiceNumber) => {
    return {
        payload: {
            invoiceFilter: invoiceNumber
        },
        type: UPDATE_BILLING_PAYMENTS_INVOICE_FILTER
    };
};

export const RETRIEVE_CONVERGENT_BILLER_LEDGER_INVOICE_CONTENT = {
    BEGIN: 'RETRIEVE_CONVERGENT_BILLER_LEDGER_INVOICE_CONTENT_BEGIN',
    SUCCESS: 'RETRIEVE_CONVERGENT_BILLER_LEDGER_INVOICE_CONTENT_SUCCESS',
    FAILURE: 'RETRIEVE_CONVERGENT_BILLER_LEDGER_INVOICE_CONTENT_FAILURE'
};
const RETRIEVE_CONVERGENT_BILLER_LEDGER_INVOICE_CONTENT_TYPES = [
    RETRIEVE_CONVERGENT_BILLER_LEDGER_INVOICE_CONTENT.BEGIN,
    RETRIEVE_CONVERGENT_BILLER_LEDGER_INVOICE_CONTENT.SUCCESS,
    RETRIEVE_CONVERGENT_BILLER_LEDGER_INVOICE_CONTENT.FAILURE
];

export const retrieveAndOpenConvergentBillerLedgerInvoicePromise = (dispatch, data) => {
    return ThunkHelper(dispatch, RETRIEVE_CONVERGENT_BILLER_LEDGER_INVOICE_CONTENT_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveConvergentBillerInvoice',
        data: data.request,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: data.customerId
        }
    });
};
export const retrieveAndOpenConvergentBillerLedgerInvoice = (data) => {
    return (dispatch) => {
        return retrieveAndOpenConvergentBillerLedgerInvoicePromise(dispatch, data)
            .then((response) => {
                const invoiceFileName = `${data.customerName} - ${data.invoiceNumber}.pdf`;
                const openedInvoice = InvoiceHelper.openInvoice(response.InvoiceType, response.InvoiceContent, invoiceFileName);

                if (!openedInvoice) {
                    const uiNotificationService = InjectorHelper.getUiNotificationService();
                    const translatedMessage = i18n.translate(CustomerCareLocaleKeys.BILLING_AND_PAYMENT_INVOICE.INVOICE_UNABLE_TO_OPEN);

                    uiNotificationService.error(translatedMessage);
                }
            })
            .catch((error) => {
                InjectorHelper.getUiNotificationService().transientError(error.translatedMessage);
            });
    };
};

export const CREATE_CONVERGENT_BILLER_LEDGER_ADJUSTMENT = {
    BEGIN: 'CREATE_CONVERGENT_BILLER_LEDGER_ADJUSTMENT_BEGIN',
    SUCCESS: 'CREATE_CONVERGENT_BILLER_LEDGER_ADJUSTMENT_SUCCESS',
    FAILURE: 'CREATE_CONVERGENT_BILLER_LEDGER_ADJUSTMENT_FAILURE'
};
const CREATE_CONVERGENT_BILLER_LEDGER_ADJUSTMENT_TYPES = [
    CREATE_CONVERGENT_BILLER_LEDGER_ADJUSTMENT.BEGIN,
    CREATE_CONVERGENT_BILLER_LEDGER_ADJUSTMENT.SUCCESS,
    CREATE_CONVERGENT_BILLER_LEDGER_ADJUSTMENT.FAILURE
];

const createConvergentBillerAdjustmentPromise = (dispatch, data) => {
    return ThunkHelper(dispatch, CREATE_CONVERGENT_BILLER_LEDGER_ADJUSTMENT_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/CreateConvergentBillerAdjustment',
        data: data.request,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: data.customerId
        }
    });
};
export const createConvergentBillerAdjustment = (data) => {
    return (dispatch) => {
        return createConvergentBillerAdjustmentPromise(dispatch, data);
    };
};

export const updatePageNumber = (data) => {
    return {
        type: UPDATE_BILLING_PAYMENTS_PAGE_NUMBER,
        payload: data
    };
};

export const UPDATE_BILLING_PAYMENTS_PAGE_SIZE = 'UPDATE_BILLING_PAYMENTS_PAGE_SIZE';
export const setPageSizePreference = (pageSize) => {
    return {
        type: UPDATE_BILLING_PAYMENTS_PAGE_SIZE,
        payload: pageSize
    };
};

export const RETREIVE_FUTURE_INVOICES = {
    BEGIN: 'RETREIVE_FUTURE_INVOICES_BEGIN',
    SUCCESS: 'RETREIVE_FUTURE_INVOICES_SUCCESS',
    FAILURE: 'RETREIVE_FUTURE_INVOICES_FAILURE'
};
const  RETREIVE_FUTURE_INVOICES_TYPES = [
    RETREIVE_FUTURE_INVOICES.BEGIN,
    RETREIVE_FUTURE_INVOICES.SUCCESS,
    RETREIVE_FUTURE_INVOICES.FAILURE
];

const retrieveFutureInvoicesPromise = (dispatch, futureInvoiceRequest, customerId) => {
    return ThunkHelper(dispatch, RETREIVE_FUTURE_INVOICES_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RequestFutureInvoices',
        data: futureInvoiceRequest,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const retrieveFutureInvoices = (futureInvoiceRequest, customerId) => {
    return (dispatch) => {
        return retrieveFutureInvoicesPromise(dispatch, futureInvoiceRequest, customerId);
    };
};

export const APPLY_CREDIT_NOTE = {
    BEGIN: 'APPLY_CREDIT_NOTE_BEGIN',
    SUCCESS: 'APPLY_CREDIT_NOTE_SUCCESS',
    FAILURE: 'APPLY_CREDIT_NOTE_FAILURE'
};
const  APPLY_CREDIT_NOTE_TYPES = [
    APPLY_CREDIT_NOTE.BEGIN,
    APPLY_CREDIT_NOTE.SUCCESS,
    APPLY_CREDIT_NOTE.FAILURE
];

const applyCreditNotePromise = (dispatch, applyCreditNoteRequest) => {
    return ThunkHelper(dispatch, APPLY_CREDIT_NOTE_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/ApplyConvergentBillerCreditNote',
        data: applyCreditNoteRequest.request,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: applyCreditNoteRequest.customerId
        }
    });
};

export const applyCreditNote = (applyCreditNoteRequest) => {
    return (dispatch) => {
        return applyCreditNotePromise(dispatch, applyCreditNoteRequest);
    };
};

export const RETRIEVE_ADJUSTMENT_DETAILS = {
    BEGIN: 'RETRIEVE_ADJUSTMENT_DETAILS_BEGIN',
    SUCCESS: 'RETRIEVE_ADJUSTMENT_DETAILS_SUCCESS',
    FAILURE: 'RETRIEVE_ADJUSTMENT_DETAILS_FAILURE'
};
const  RETRIEVE_ADJUSTMENT_DETAILS_TYPES = [
    RETRIEVE_ADJUSTMENT_DETAILS.BEGIN,
    RETRIEVE_ADJUSTMENT_DETAILS.SUCCESS,
    RETRIEVE_ADJUSTMENT_DETAILS.FAILURE
];

const retrieveAdjustmentDetailsPromise = (dispatch, retrieveAdjustmentDetailsRequest) => {
    return ThunkHelper(dispatch, RETRIEVE_ADJUSTMENT_DETAILS_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveAdjustmentDetails',
        data: retrieveAdjustmentDetailsRequest.request,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: retrieveAdjustmentDetailsRequest.customerId
        }
    });
};

export const retrieveAdjustmentDetails = (retrieveAdjustmentDetailsRequest) => {
    return (dispatch) => {
        return retrieveAdjustmentDetailsPromise(dispatch, retrieveAdjustmentDetailsRequest);
    };
};

export const UPDATE_OPEN_INVOICE_PAGE_NUMBER = 'UPDATE_OPEN_INVOICE_PAGE_NUMBER';
export const updateOpenInvoicesPageNumber = (pageNumber) => {
    return {
        type: UPDATE_OPEN_INVOICE_PAGE_NUMBER,
        payload: pageNumber
    };
};

export const UPDATE_OPEN_INVOICE_DATE_RANGE = 'UPDATE_OPEN_INVOICE_DATE_RANGE';
export const updateOpenInvoicesDateRange = (startDate, endDate, dateShortcut) => {
    return {
        type: UPDATE_OPEN_INVOICE_DATE_RANGE,
        payload: {
            startDate,
            endDate,
            dateShortcut
        }
    };
};

export const UPDATE_INVOICE_NUMBER_SEARCH_TERM = 'UPDATE_INVOICE_NUMBER_SEARCH_TERM';
export const updateInvoiceNumberSearchTerm = (searchTerm) => {
    return {
        type: UPDATE_INVOICE_NUMBER_SEARCH_TERM,
        payload: searchTerm
    };
};

export const SET_CURRENT_TAB_BILLING_LEDGER = 'SET_CURRENT_TAB_BILLING_LEDGER';
export const setCurrentTabBillingLedger = (selectedTab) => {
    return {
        type: SET_CURRENT_TAB_BILLING_LEDGER,
        payload: selectedTab
    };
};

export const UPDATE_OPEN_INVOICE_PAGE_SIZE = 'UPDATE_OPEN_INVOICE_PAGE_SIZE';
export const updateOpenInvoicesPageSize = (pageSize) => {
    return {
        type: UPDATE_OPEN_INVOICE_PAGE_SIZE,
        payload: pageSize
    };
};

export const RESET_BILLING_LEDGER_STATE = 'RESET_BILLING_LEDGER_STATE';
export const resetBillingLedger = () => {
    return {
        type: RESET_BILLING_LEDGER_STATE
    };
};

export const RETRIEVE_BILL_ADJUSTMENT_CONTENT = {
    BEGIN: 'RETRIEVE_BILL_ADJUSTMENT_CONTENT_BEGIN',
    SUCCESS: 'RETRIEVE_BILL_ADJUSTMENT_CONTENT_SUCCESS',
    FAILURE: 'RETRIEVE_BILL_ADJUSTMENT_CONTENT_FAILURE'
};
const RETRIEVE_BILL_ADJUSTMENT_CONTENT_TYPES = [
    RETRIEVE_BILL_ADJUSTMENT_CONTENT.BEGIN,
    RETRIEVE_BILL_ADJUSTMENT_CONTENT.SUCCESS,
    RETRIEVE_BILL_ADJUSTMENT_CONTENT.FAILURE
];

export const retrieveAndOpenBillAdjustmentPromise = (dispatch, data) => {
    return ThunkHelper(dispatch, RETRIEVE_BILL_ADJUSTMENT_CONTENT_TYPES, {
        method: 'post',
        url: 'SubscriberManagement/RetrieveStatement',
        data: data.request,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: data.CustomerId
        }
    });
};

export const retrieveAndOpenBillAdjustmentStatement = (data) => {
    return (dispatch) => {
        return retrieveAndOpenBillAdjustmentPromise(dispatch, data)
            .then((response) => {
                const invoiceFileName = `${data.AdjustmentId}.pdf`;
                const openedInvoice = InvoiceHelper.openBillAdjustmentStatement(response.StatementDocumentType, response.StatementContent, invoiceFileName);

                if (!openedInvoice) {
                    const uiNotificationService = InjectorHelper.getUiNotificationService();
                    const translatedMessage = i18n.translate(CustomerCareLocaleKeys.BILLING_AND_PAYMENT_INVOICE.INVOICE_UNABLE_TO_OPEN);

                    uiNotificationService.error(translatedMessage);
                }
            })
            .catch((error) => {
                InjectorHelper.getUiNotificationService().transientError(error.translatedMessage);
            });
    };
};