import Immutable from 'seamless-immutable';
import contains from 'ramda/src/includes';
import clone from 'ramda/src/clone';
import find from 'ramda/src/find';
import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';
import propEq from 'ramda/src/propEq';
import uniq from 'ramda/src/uniq';
import CustomerCareKeys from '../../locales/keys';
import {BILLER_RULE_INSTANCE_TYPE} from '../../customercare.constants';
import {populateAttributeGroups} from '../selected.offering.order.reducer';
import {populateDevice} from './offer.ordering.wizard.selector.helper';

export default () => {
    const recursivelyUpdateChainedAttributes = (attributeGroup, attribute) => {
        const dependentAttrsToUpdate = attributeGroup.reduce((depAttrs, otherAttribute) => {
            let updatedAttr;

            if (otherAttribute.dependentServiceAttributeId === attribute.id) {
                if (otherAttribute.isHidden && !attribute.isHidden) {
                    if (!otherAttribute.dependentValues) {
                        updatedAttr = otherAttribute.set('isHidden', false);
                    } else if (contains(attribute.formValue, otherAttribute.dependentValues)) {
                        updatedAttr = otherAttribute.set('isHidden', false);
                    }
                } else {
                    if (attribute.isHidden) {
                        updatedAttr = otherAttribute.set('isHidden', true);
                    } else if (otherAttribute.dependentValues && !contains(attribute.formValue, otherAttribute.dependentValues)) {
                        updatedAttr = otherAttribute.set('isHidden', true);
                    }
                }
            }

            if (updatedAttr) {
                depAttrs.push(updatedAttr);
            }

            return depAttrs;
        }, []);

        const updatedGroup = dependentAttrsToUpdate.reduce(updateGroupWith, attributeGroup);

        return dependentAttrsToUpdate.reduce(recursivelyUpdateChainedAttributes, updatedGroup);
    };
    const updateGroupWith = (attributeGroup, updatedAttribute) => {
        return attributeGroup.map((attribute) => {
            return attribute.id === updatedAttribute.id ? updatedAttribute : attribute;
        });
    };

    return {
        clearAttributeInventorySearching: (attributeGroups = []) => {
            if (attributeGroups) {
                return attributeGroups.map((attributeGroup) => {
                    const attribute = attributeGroup.find((attr) => {
                        return attr.searchingInventory;
                    });

                    if (attribute) {
                        const updatedAttribute = Object.assign({}, attribute, {
                            searchingInventory: false
                        });
                        return updateGroupWith(attributeGroup, updatedAttribute);
                    } else {
                        return attributeGroup;
                    }
                });
            } else {
                return [];
            }
        },
        clearStateFromOfferSelection: (state, offerId, defaultCart) => {
            let skippedSteps = state.skippedSteps;
            const completedSteps = state.completedSteps.asMutable();

            if (!offerId || offerId !== state.cart.selectedOfferId) {
                delete completedSteps[CustomerCareKeys.WIZARD.STEPS.OFFERS];
                delete completedSteps[CustomerCareKeys.WIZARD.STEPS.COMPARE_OFFERS];
                delete completedSteps[CustomerCareKeys.WIZARD.STEPS.DECISIONS];
                delete completedSteps[CustomerCareKeys.WIZARD.STEPS.ATTRIBUTES];

                skippedSteps = state.skippedSteps.filter((stepKey) => {
                    return stepKey !== CustomerCareKeys.WIZARD.STEPS.DECISIONS && stepKey !== CustomerCareKeys.WIZARD.STEPS.ATTRIBUTES;
                });
            }

            return state
                .set('subscriptionOrderQuote', defaultCart.subscriptionOrderQuote)
                .set('quote', defaultCart.quote)
                .setIn(['cart', 'editAttributeGroups'], defaultCart.editAttributeGroups)
                .setIn(['cart', 'editPhysicalAttributeGroups'], defaultCart.editPhysicalAttributeGroups)
                .setIn(['cart', 'selectedEditOption'], defaultCart.selectedEditOption)
                .setIn(['cart', 'editOptions'], defaultCart.editOptions)
                .set('completedSteps', completedSteps)
                .set('skippedSteps', skippedSteps);
        },
        mergePhysicalAttributeGroups: (newAttributes, existingAttributes) => {
            const getAttribute = (pricingPlan, id, groups) => {
                return find(propEq(id, 'id'))(groups[pricingPlan]);
            };
            const mergedAttributeGroups = {};
            for (const pricingPlan in newAttributes) {
                newAttributes[pricingPlan].forEach((attribute) => {
                    if (!mergedAttributeGroups[pricingPlan]) {
                        mergedAttributeGroups[pricingPlan] = [];
                    }
                    if (!existingAttributes[pricingPlan]) {
                        mergedAttributeGroups[pricingPlan].push(populateDevice(attribute));
                    } else {
                        const existingAttribute = getAttribute(pricingPlan, attribute.id, existingAttributes);
                        const item = existingAttribute ? existingAttribute : populateDevice(attribute);
                        mergedAttributeGroups[pricingPlan].push(item);
                    }
                });
            }
            return mergedAttributeGroups;
        },
        setEditAttributeGroups: (newAttributes, editCartAttributeGroups) => {
            const populatedAttributeGroups = populateAttributeGroups(newAttributes);
            if (editCartAttributeGroups && editCartAttributeGroups.length && populatedAttributeGroups && populatedAttributeGroups.length) {
                populatedAttributeGroups.forEach((attributes) => {
                    for (let i = 0; i < attributes.length; i++) {
                        const existingAttribute = getAttribute(attributes[i].id, editCartAttributeGroups);
                        if (existingAttribute) {
                            const existingAttributeWithOrderScenario = Object.assign({}, existingAttribute, {
                                parentServicePlanOrderScenario: attributes[i].parentServicePlanOrderScenario
                            });
                            if (attributes[i].inventoryItemReservation) {
                                attributes[i] = Object.assign({}, existingAttributeWithOrderScenario, {
                                    inventoryItemReservation: attributes[i].inventoryItemReservation
                                });
                            } else {
                                attributes[i] = existingAttributeWithOrderScenario;
                            }
                        }
                    }
                });
            }
            return populatedAttributeGroups;
        },
        getRecurringTotal: (payload) => {
            const hasRecurring = payload.ShoppingCart.BillerRuleTotals.find((total) => {
                return total.Type === BILLER_RULE_INSTANCE_TYPE.RECURRING;
            });
            return hasRecurring ? hasRecurring.TotalAmount : 0;
        },
        restoreDefaultOptionPricing: (state, payload) => {
            return state.setIn(['editCart', 'editOptions'], restoreDefaultOptionPricing(state.editCart.editOptions, payload));
        },
        setAttributeInventorySearching: (attributeGroups, attributeId) => {
            if (attributeGroups) {
                return attributeGroups.map((attributeGroup) => {
                    const attribute = attributeGroup.find((attr) => {
                        return attr.id === attributeId;
                    });

                    if (attribute) {
                        const updatedAttribute = Object.assign({}, attribute, {
                            searchingInventory: true
                        });

                        return updateGroupWith(attributeGroup, updatedAttribute);
                    } else {
                        return attributeGroup;
                    }
                });
            } else {
                return [];
            }
        },
        setEditOption: (state, payload) => {
            return state
                .setIn(['editCart', 'editOptions'], setEditOption(state.editCart.editOptions, payload))
                .setIn(['editCart', 'selectedEditOption'], payload.Id);
        },
        setOrderQuote: (payload) => {
            return pathOr({}, ['Quote', 'Totals'], payload);
        },
        setPaymentInfo: (state, payload) => {
            return state.setIn(['cart', 'paymentInfo'], payload);
        },
        setSelectedFacetsEditCopy: (state, payload) => {
            return state.merge({
                editCart: {
                    selectedFacetIds: payload.isSelected
                        ? uniq(state.editCart.selectedFacetIds.concat([payload.facetId]))
                        : state.editCart.selectedFacetIds.filter(id => {
                            return id !== payload.facetId;
                        })
                }
            }, {
                deep: true
            });
        },
        setSelectedOfferingChargeTypesEditCopy: (state, payload) => {
            return state.merge({
                editCart: {
                    selectedOfferingChargeTypeIds: payload.isSelected
                        ? uniq(state.editCart.selectedOfferingChargeTypeIds.concat([payload.offeringChargeTypeId]))
                        : state.editCart.selectedOfferingChargeTypeIds.filter(id => {
                            return id !== payload.offeringChargeTypeId;
                        })
                }
            }, {
                deep: true
            });
        },
        setServiceAddress: (state, payload) => {
            return state.set('serviceAddress', find(propEq(true, 'DefaultService'))(payload.Addresses || []));
        },
        transformAttributeGroups: (payload) => {
            return Immutable(payload).map((attrGroup) => {
                const parentAttrsWithDependentsIds = attrGroup.filter((attr) => {
                    return !!attr.dependentServiceAttributeId;
                }).map((attr) => {
                    return attr.dependentServiceAttributeId;
                });

                const parentAttrsWithDependents = attrGroup.filter((attr) => {
                    return !attr.dependentServiceAttributeId && parentAttrsWithDependentsIds.includes(attr.id);
                });

                return parentAttrsWithDependents.reduce(recursivelyUpdateChainedAttributes, attrGroup);
            });
        },
        updateAttributeGroups: (attributeGroups, attributeId, formValue) => {
            if (attributeGroups) {
                return attributeGroups.map((attributeGroup) => {
                    const attribute = attributeGroup.find((attr) => {
                        return attr.id === attributeId;
                    });

                    if (attribute) {
                        const updatedAttribute = attribute.set('formValue', formValue);
                        const updatedGroup = updateGroupWith(attributeGroup, updatedAttribute);

                        return recursivelyUpdateChainedAttributes(updatedGroup, updatedAttribute);
                    } else {
                        return attributeGroup;
                    }
                });
            } else {
                return [];
            }
        },
        updateAttributePortInRequest: (attributeGroups, attributeId, portInData, token, address) => {
            return !attributeGroups ? [] : attributeGroups.map((attributeGroup) => {
                const attribute = attributeGroup.find((attr) => {
                    return attr.id === attributeId;
                });

                if (!attribute) {
                    return attributeGroup;
                }
                const updatedAttribute = Object.assign({}, attribute, {
                    mnpToken: token,
                    address: address,
                    portInRequest: Object.assign({}, attribute.portInRequest, {
                        portInData
                    })
                });
                const updatedGroup = updateGroupWith(attributeGroup, updatedAttribute);

                return recursivelyUpdateChainedAttributes(updatedGroup, updatedAttribute);
            });
        },

        sendPortInOrItemReservation(completedDecisions, attributeId, sendPortInReservation) {
            return !completedDecisions ? [] : completedDecisions.map((decision) => {
                const attribute = decision.find((attr) => {
                    return attr.id === attributeId;
                });
                if (attribute) {
                    const updatedAttribute = Object.assign({}, attribute, {
                        passPortIn: sendPortInReservation,
                    });
                    const updatedGroup = updateGroupWith(decision, updatedAttribute);

                    return recursivelyUpdateChainedAttributes(updatedGroup, updatedAttribute);
                } else {
                    return decision;
                }
            });
        },
        updateAttributeInventoryOptions: (attributeGroups = [], options) => {
            if (attributeGroups) {
                return attributeGroups.map((attributeGroup) => {
                    const attribute = attributeGroup.find((attr) => {
                        return attr.searchingInventory;
                    });

                    if (attribute) {
                        const mappedOptions = options.map((option) => {
                            return {
                                instanceId: option.InstanceId,
                                inventoryStateId: option.InventoryStateId,
                                isPreferred: option.IsPreferred,
                                serialNumber: option.SerialNumber
                            };
                        });
                        const optionsIncludesSelectedValue = mappedOptions.find((option) => {
                            return option.serialNumber === attribute.selectedValue;
                        });
                        const updatedAttribute = Object.assign({}, attribute, {
                            searchingInventory: false,
                            inventoryItemReservation: Object.assign({}, attribute.inventoryItemReservation, {
                                options: attribute.selectedValue && !optionsIncludesSelectedValue ? [{
                                    serialNumber: attribute.selectedValue
                                }, ...mappedOptions] : mappedOptions
                            })
                        });

                        return updateGroupWith(attributeGroup, updatedAttribute);
                    } else {
                        return attributeGroup;
                    }
                });
            } else {
                return [];
            }
        },
        updateDeliveryDecision: (state, payload) => {
            const deliveryDecisions = clone(state.editCart.deliveryDecisions);
            deliveryDecisions && deliveryDecisions.forEach((item) => {
                if (item.Shippable) {
                    return item.SelectedValue = payload.selectedValue;
                }
            });
            return deliveryDecisions;
        },
        updateserviceTaxLocationDecision: (state, payload) => {
            if (payload) {
                const serviceTaxDecisions = clone(state.editCart.serviceTaxDecisions);
                serviceTaxDecisions.forEach((item) => {
                    item.ServiceTaxCustomization = payload[item.PricingPlanId][item.InstanceNumber].ServiceTaxCustomization;
                    item.selectedTaxOption = payload[item.PricingPlanId][item.InstanceNumber].taxOption;
                });
                return serviceTaxDecisions;
            }
        },
        updateBillCycle: (state, payload) => {
            return state.setIn(['editCart', 'billCycle'], payload);
        },
        updateInventorySelection: (attributeGroups, payload) => {
            if (attributeGroups) {
                return attributeGroups.map((attributeGroup) => {
                    const attribute = attributeGroup.find((attr) => {
                        return attr.id === payload.attributeId;
                    });

                    if (attribute) {
                        const updatedReservation = attribute.inventoryItemReservation ? attribute.inventoryItemReservation
                            .set('instanceId', payload.InventoryItem.instanceId || payload.InventoryItem.InstanceId)
                            .set('serialNumber', undefined)
                            .set('token',  attribute.inventoryItemReservation ? attribute.inventoryItemReservation.token : undefined) : {
                            instanceId: payload.InventoryItem.instanceId || payload.InventoryItem.InstanceId,
                            serialNumber: undefined,
                            token: undefined
                        };
                        const updatedAttribute = attribute
                            .set('formValue', payload.formValue)
                            .set('selectedValue', payload.formValue)
                            .set('inventoryItemReservation', updatedReservation);
                        return updateGroupWith(attributeGroup, updatedAttribute);
                    } else {
                        return attributeGroup;
                    }
                });
            } else {
                return [];
            }
        },
        updateEditOptionPricing: (state, payload) => {
            return state.setIn(['editCart', 'editOptions'], updateEditOptionPricing(state.editCart.editOptions, payload));
        },
        updatePaymentInfo: (state, payload) => {
            return state.setIn(['editCart', 'paymentInfo'], payload);
        },
        updatePhysicalInventoryMakeAndModel(state, {pricingPlanId, inventoryId, makeId, modelId}) {
            const indexOfInventory = state[pricingPlanId].findIndex((item) => {
                return item.id === inventoryId;
            });
            return state
                .setIn([pricingPlanId, indexOfInventory, 'selectedMake'], makeId)
                .setIn([pricingPlanId, indexOfInventory, 'selectedModel'], modelId);
        },
        updatePhysicalInventoryTypeAttribute(state, {pricingPlanId, inventoryId, typeAttributeId, value}) {
            const indexOfInventory = state[pricingPlanId].findIndex((item) => {
                return item.id === inventoryId;
            });
            return state
                .setIn([pricingPlanId, indexOfInventory, 'selectedTypeAttributes', typeAttributeId], value);
        },
        updateSelectedServiceFeatures(attributeGroups, {attributeId, billerRuleInstanceDetails, selectedServiceFeatures}) {
            return attributeGroups.map((attributeGroup) => {
                const attribute = attributeGroup.find((attribute) => {
                    return attribute.id === attributeId && attribute.serviceIdentifier;
                });
                return attribute ?
                    updateGroupWith(attributeGroup, attribute
                        .set('serviceFeatures', selectedServiceFeatures)
                        .set('billerRuleInstanceDetails', billerRuleInstanceDetails)) :
                    attributeGroup;
            });
        }
    };
};

const restoreDefaultOptionPricing = (editOptions, optionId) => {
    return editOptions.map((option) => {
        if (option.Id !== optionId) {
            return option;
        } else {
            return Object.assign({}, option, {
                BillerRuleInstanceThumbnails: option.defaultAmounts
            });
        }
    });
};

const setEditOption = (editOptions, setOption) => {
    // no edit options
    let updatedOptions = [];
    if (editOptions === null) {
        updatedOptions.push(Object.assign({}, setOption, {
            defaultAmounts: setOption.BillerRuleInstanceThumbnails,
            isPlanOrServiceSwap: setOption.isPlanOrServiceSwap
        }));
        return updatedOptions;
    }

    // new edit option does not exist in collection
    updatedOptions = editOptions.map((option) => {
        return option;
    });

    const optionIds = pluck('Id')(editOptions);
    if (!contains(setOption.Id, optionIds)) {
        const newOption = Object.assign({}, setOption, {
            defaultAmounts: setOption.BillerRuleInstanceThumbnails,
            isPlanOrServiceSwap: setOption.isPlanOrServiceSwap
        });
        return updatedOptions.concat([newOption]);
    }

    // edit option already exists and just need update
    updatedOptions = editOptions.map((option) => {
        return option.Id !== setOption.Id ? option : Object.assign({}, option, {
            BillerRuleInstanceThumbnails: setOption.BillerRuleInstanceThumbnails,
            BillerRuleDetails: setOption.BillerRuleDetails,
            isPlanOrServiceSwap: setOption.isPlanOrServiceSwap !== undefined ? setOption.isPlanOrServiceSwap : option.isPlanOrServiceSwap
        });
    });
    return updatedOptions;
};

const updateEditOptionPricing = (editOptions, payload) => {
    return editOptions.map((option) => {
        return Object.assign({}, option, {
            BillerRuleInstanceThumbnails: option.BillerRuleInstanceThumbnails.map((bri, index) => {
                return Object.assign({}, bri, {
                    pricingPeriods: payload[index]?.pricingPeriods,
                    financeOverrides: payload[index]?.financeOverrides,
                    scheduleType: payload[index]?.scheduleType
                });
            })
        });
    });
};
const getAttribute = (attributeId, groups) => {
    let decision = null;
    groups.forEach((group) => {
        group.forEach((decisionValue) => {
            if (decisionValue.id === attributeId) {
                decision = decisionValue;
            }
        });
    });
    return decision;
};
