import Immutable from 'seamless-immutable';
import {pathOr} from 'ramda';

import {
    RESET_SUBSCRIBER_CREDIT_CLASSIFICATION,
    RETRIEVE_CREDIT_CLASSIFICATION_DETAILS,
    SET_CREDIT_CHECK_MODAL_STATUS,
    SET_CREDIT_CHECK_SKIPPED,
    UPDATE_CREDIT_CLASSIFICATION
} from './constants/credit.check.constants';

import {CREATE_CUSTOMER_ORDER_WIZARD} from './actions/new.connect.wizard.actions';
import {SUBMIT_ORDER} from './actions/offering.order.actions';
import {EDIT_SUBMIT_COS_ORDER} from './actions/edit.offer.wizard.actions';
import {TRANSITION_SUBMIT_COS_ORDER} from './actions/transition.offer.wizard.actions';

// Do not follow this pattern. ASCINV-319 story will clean this up.
// Reducers values should all be lower case. A reducer file should only have one base INITIAL_STATE and one Reducer

export const CUSTOMER_INITIAL_STATE = Immutable({
    Address: null,
    AddressForCreditCheck: null,
    CreditCheckClassification: null,
    error: null,
    isCreditCheckSkipped: false,
    isFetchingData: false,
    isLoaded: false,
    isUpdatingCreditClassification: false,
    runCreditCheckErrorMessage: null,
    Subscriber: null
});

export const CONFIG_INITIAL_STATE = Immutable({
    creditCheckModalStatus: false,
    CreditCheckNotEnabled: null,
    CreditCheckRequired: null,
    RequiredSubscriberFields: [],
    RequiredAdditionalProperties: [],
    CanOverrideCreditCheckClassification: null,
    CreditClassifications: [],
    isFetchingData: false,
    isLoaded: false,
    error: null
});

export function CustomerCreditCheckReducer(state = CUSTOMER_INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case CREATE_CUSTOMER_ORDER_WIZARD.SUCCESS:
            return state
                .set('Address', pathOr(null, ['Address'], payload))
                .set('Subscriber', pathOr(null, ['Subscriber'], payload));
        case EDIT_SUBMIT_COS_ORDER.FAILURE:
            return state
                .set('error', {
                    code: payload.Code
                });
        case RETRIEVE_CREDIT_CLASSIFICATION_DETAILS.BEGIN:
            return state
                .set('isFetchingData', true);
        case RETRIEVE_CREDIT_CLASSIFICATION_DETAILS.SUCCESS: {
            const subscriber = pathOr(null, ['Subscriber'], payload);
            if (subscriber) {
                return state
                    .set('CreditCheckClassification', pathOr(null, ['Subscriber', 'CreditCheckClassification'], payload))
                    .set('Address', pathOr(null, ['Address'], payload))
                    .set('Subscriber', pathOr(null, ['Subscriber'], payload))
                    .set('isFetchingData', false)
                    .set('isLoaded', true)
                    .set('error', null);
            } else {
                return state
                    .set('isLoaded', false)
                    .set('error', null)
                    .set('isFetchingData', false);
            }
        }
        case RETRIEVE_CREDIT_CLASSIFICATION_DETAILS.FAILURE:
            return state
                .set('isFetchingData', false)
                .set('isLoaded', false)
                .set('error', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case SUBMIT_ORDER.FAILURE:
            return state
                .set('error', {
                    code: payload.Code
                });
        case TRANSITION_SUBMIT_COS_ORDER.FAILURE:
            return state
                .set('error', {
                    code: payload.Code
                });
        case UPDATE_CREDIT_CLASSIFICATION.BEGIN:
            return state
                .set('isUpdatingCreditClassification', true);
        case UPDATE_CREDIT_CLASSIFICATION.FAILURE:
            return state
                .set('isUpdatingCreditClassification', false)
                .set('isLoaded', false)
                .set('error', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case UPDATE_CREDIT_CLASSIFICATION.SUCCESS:
            return state
                .set('Address', pathOr(null, ['Address'], payload))
                .set('CreditCheckClassification', pathOr(null, ['Subscriber', 'CreditCheckClassification'], payload))
                .set('error', null)
                .set('isUpdatingCreditClassification', false)
                .set('isLoaded', true)
                .set('runCreditCheckErrorMessage', payload.RunCreditCheckErrorMessage)
                .set('Subscriber', pathOr(null, ['Subscriber'], payload));
        case RESET_SUBSCRIBER_CREDIT_CLASSIFICATION:
            return CUSTOMER_INITIAL_STATE;
        case SET_CREDIT_CHECK_SKIPPED:
            return state
                .set('isCreditCheckSkipped', payload);
        default:
            return state;
    }
}

export function ConfigCreditCheckReducer(state = CONFIG_INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case RETRIEVE_CREDIT_CLASSIFICATION_DETAILS.BEGIN:
            return state.set('isFetchingData', true);
        case RETRIEVE_CREDIT_CLASSIFICATION_DETAILS.SUCCESS:
            return state
                .set('CreditCheckNotEnabled', pathOr(false, ['CreditCheckNotEnabled'], payload))
                .set('CreditCheckRequired', pathOr(null, ['CreditCheckRequired'], payload))
                .set('RequiredSubscriberFields', pathOr(null, ['RequiredSubscriberFields'], payload))
                .set('RequiredAdditionalProperties', pathOr([], ['RequiredAdditionalProperties'], payload))
                .set('CanOverrideCreditCheckClassification', pathOr(null, ['CanOverrideCreditCheckClassification'], payload))
                .set('CreditClassifications', pathOr([], ['CreditClassifications'], payload))
                .set('error', null)
                .set('isFetchingData', false)
                .set('isLoaded', true);
        case RETRIEVE_CREDIT_CLASSIFICATION_DETAILS.FAILURE:
            return state
                .set('error', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                })
                .set('isFetchingData', false);
        case SET_CREDIT_CHECK_MODAL_STATUS:
            return state
                .set('creditCheckModalStatus', payload);
        default:
            return state;
    }
}


