import {createSelector} from 'reselect';

import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {getSecurityAttributeById} from 'invision-core/src/components/security/permission.service';
import {ADDRESS_OVERRIDE_ACCESS} from '../../security.attributes';
import i18n from 'invision-core/src/components/i18n/i18n';
import LocaleKeys from '../../locales/keys';
import {ADDRESS_STATUS_CONSTANTS} from '../../components/customer/addresses/addresses.constants';
import {
    COMMA,
    EMPTY_STRING
} from '../constants/common.constants';

const AddressComponentSelector = state => {
    return state.customercare.addressesComponent;
};

export const IsValidatingStatusSelector = createImmutableSelector([AddressComponentSelector],
    (addressStore) => {
        return addressStore.isValidating;
    }
);

export const ValidatedAddressesSelector = createImmutableSelector([AddressComponentSelector],
    (addressStore) => {
        return addressStore.validatedAddresses;
    }
);

export const UnvalidatedAddressesSelector = createImmutableSelector([AddressComponentSelector],
    (addressStore) => {
        return addressStore.unvalidatedAddresses;
    }
);

export const AddressBeingValidatedSelector = createImmutableSelector([AddressComponentSelector],
    (addressStore) => {
        return addressStore.addressBeingValidated;
    }
);


export const PreviouslyValidatedAddressSelector = createImmutableSelector([AddressComponentSelector],
    (addressStore) => {
        return addressStore.previousAddressValidated;
    }
);

export const LastJobTokenSelector = createImmutableSelector([AddressComponentSelector],
    (addressStore) => {
        return addressStore.lastJobToken;
    }
);

export const IsForceShowEditFormStatusSelector = createImmutableSelector([AddressComponentSelector],
    (addressStore) => {
        return addressStore.isForceShowEditForm;
    }
);

//Below will have to change if we allow more than one address being set
export const CurrentUnvalidatedAddressSelector = createImmutableSelector([AddressComponentSelector, AddressBeingValidatedSelector],
    (addressStore, addressKeyForAddressBeingValidated) => {
        return addressStore.unvalidatedAddresses[addressKeyForAddressBeingValidated];
    }
);

export const IsThereSuggestedAddressesSelector = createImmutableSelector([ValidatedAddressesSelector, AddressBeingValidatedSelector],
    (validatedAddresses, addressKeyForAddressBeingValidated) => {
        const validatedAddress = validatedAddresses[addressKeyForAddressBeingValidated];

        return (validatedAddress && validatedAddress.SuggestedAddresses);
    }
);

export const IsInvalidAddressOverridableScenarioSelector = createImmutableSelector([ValidatedAddressesSelector, AddressBeingValidatedSelector],
    (validatedAddresses, addressKeyForAddressBeingValidated) => {
        const validatedAddress = validatedAddresses[addressKeyForAddressBeingValidated];

        return (validatedAddress && validatedAddress.AddressValidationStatus === ADDRESS_STATUS_CONSTANTS.INVALID);
    }
);

export const CanUserOverrideAddressSelector = createSelector([
    UserSecurityAttributesSelector
], (userSecurityAttributes) => {
    return getSecurityAttributeById(userSecurityAttributes, ADDRESS_OVERRIDE_ACCESS.id);
});

export const OVERRIDE_ADDRESS_VALUE = 'OVERRIDE_ADDRESS';

export const SuggestedAddressesSelector = createImmutableSelector([IsThereSuggestedAddressesSelector, ValidatedAddressesSelector, AddressBeingValidatedSelector, PreviouslyValidatedAddressSelector, CanUserOverrideAddressSelector, IsInvalidAddressOverridableScenarioSelector],
    (isThereSuggestedAddresses, validatedAddresses, addressKeyBeingValidated, addressKeyForPreviouslyValidatedAddress, canUserOverrideAddress, isInvalidAddressOverrideScenario) => {
        if (isThereSuggestedAddresses) {
            const addressKey = addressKeyForPreviouslyValidatedAddress ? addressKeyForPreviouslyValidatedAddress : addressKeyBeingValidated,
                radioButtonsList = validatedAddresses[addressKey].SuggestedAddresses.map((address) => {
                    return {
                        displayValue: `${address.LineOne}, ${address.LineTwo ? address.LineTwo + COMMA : EMPTY_STRING} ${address.City}, ${address.State}, ${address.PostalCode}, ${address.Country}`,
                        value: address
                    };
                });

            return canUserOverrideAddress ? [{
                //translate below
                displayValue: i18n.translate(LocaleKeys.ADDRESS_COMPONENT.KEEP_SUGGESTED_ADDRESS),
                value: OVERRIDE_ADDRESS_VALUE
            }, ...radioButtonsList] :
                radioButtonsList;
        } else if (isInvalidAddressOverrideScenario && canUserOverrideAddress) {
            return [{
                displayValue: i18n.translate(LocaleKeys.ADDRESS_COMPONENT.KEEP_SUGGESTED_ADDRESS),
                value: OVERRIDE_ADDRESS_VALUE
            }];
        }
        return [];
    }
);

export const MduRangeSelector = createImmutableSelector(
    [AddressComponentSelector],
    (addressStore) => {
        return addressStore.mduRange;
    }
);