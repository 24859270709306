import Immutable from 'seamless-immutable';
import {pathOr} from 'ramda';
import {NavigatorActions} from 'invision-core';
import {RESUME_ORDER_INSUFFICIENT_ACCESS_WARNING_CODE} from '../customercare.constants';
import {
    EDIT_INITIAL_OFFERING_CONTEXT,
    EDIT_RETRIEVE_PREVIOUS_ATTRIBUTES,
    EDIT_SUBMIT_COS_ORDER,
    GO_TO_NEXT_STEP,
    GO_TO_PREVIOUS_STEP,
    GO_TO_STEP,
    RETRIEVE_ADDRESSES,
    SEND_PORT_IN_OR_ITEM_RESERVATION,
    SET_ATTRIBUTE_SEARCHING_INVENTORY,
    SET_CURRENT_STEP_IS_INVALID,
    SET_EDIT_ATTRIBUTE_GROUPS,
    SET_EDIT_OPTION,
    SET_EDIT_CART_ADDITIONAL_PROPERTY,
    SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE,
    SET_EDIT_ORDER_ADDITIONAL_PROPERTY,
    SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE,
    SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS,
    SET_PAYMENT_INFO,
    SET_SELECTED_EDIT_OFFER_TAB,
    SET_SELECTED_OFFER,
    SET_SELECTED_OFFER_EDIT_COPY,
    UPDATE_EDIT_BRI_PRICE,
    UPDATE_INVENTORY_SELECTION,
    UPDATE_OFFER_ATTRIBUTE,
    UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL,
    UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE,
    UPDATE_PAYMENT_INFO,
    UPDATE_PORT_IN_REQUEST,
    UPDATE_SELECTED_SERVICE_FEATURES,
    SET_IS_RESUMING_STORE_ORDER_FOR_EDIT_OFFER,
    SET_SERVICE_TAX_LOCATION_DECISION_EDIT_OFFER,
    RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_EDIT
} from './actions/edit.offer.wizard.actions';
import {
    CALCULATE_COS_ORDER_QUOTE,
    RETRIEVE_ATTRIBUTES,
    RETRIEVE_ORDER_QUOTE,
    SEARCH_INVENTORY,
    SET_DELIVERY_DECISION
} from './actions/offering.order.actions';
import {ALL_STEPS_KEYS} from './constants/edit.offer.wizard.constants';
import createWizardReducers from './helpers/wizard.reducer.helper';
import createOfferOrderingReducers from './helpers/offer.ordering.wizard.reducer.helper';
import {populatePhysicalInventoryAttributeGroups} from './selected.offering.order.reducer';
import {EMPTY_ARRAY} from './constants/common.constants';
import {
    mapAdditionalProperty,
    setEditAp
} from './helpers/customer.helper';

const wizardReducers = createWizardReducers(ALL_STEPS_KEYS);
const offerOrderingReducers = createOfferOrderingReducers();

export const DEFAULT_CART = {
    currentRecurring: null,
    customer: null,
    editAttributeGroups: null,
    editPhysicalAttributeGroups: null,
    paymentInfo: null,
    selectedEditOption: null,
    editOptions: null,
    selectedFacetIds: [],
    selectedOfferId: null,
    selectedOfferingChargeTypeIds: []
};

export const INITIAL_STATE = wizardReducers.initialState.merge(Immutable({
    cart: DEFAULT_CART,
    cartAdditionalProperties: EMPTY_ARRAY,
    editCart: DEFAULT_CART,
    isInsufficientChargeOverrideAccessWarning: false,
    isCalculatingQuote: false,
    isResumingStoreOrder: false,
    isSubmittingOrder: false,
    orderAdditionalProperties: EMPTY_ARRAY,
    quote: null,
    serviceAddress: null

}));
export default function wizardReducer(state = INITIAL_STATE, {payload, type}) {
    switch (type) {
        case CALCULATE_COS_ORDER_QUOTE.BEGIN:
            return state.set('isCalculatingQuote', true);
        case CALCULATE_COS_ORDER_QUOTE.FAILURE:
            return state.set('isCalculatingQuote', false);
        case RETRIEVE_ORDER_QUOTE.SUCCESS:
        case CALCULATE_COS_ORDER_QUOTE.SUCCESS:
            return state
                .set('isCalculatingQuote', false)
                .set('financeCreditLimitValidation', payload.FinanceCreditLimitValidation)
                .set('quote', payload.Quote)
                .set('shippingAddress', payload.ShippingAddress);
        case EDIT_INITIAL_OFFERING_CONTEXT.SUCCESS:
            return state
                .setIn(['cart', 'currentRecurring'], offerOrderingReducers.getRecurringTotal(payload))
                .set(['isInsufficientChargeOverrideAccessWarning'], updateInsufficientChargeOverrideAccessWarning(payload));
        case EDIT_RETRIEVE_PREVIOUS_ATTRIBUTES.SUCCESS:
            return state
                .setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.transformAttributeGroups(offerOrderingReducers.setEditAttributeGroups(payload.Context.ValueDecisions, pathOr(null, ['editCart', 'editAttributeGroups'], state))))
                .setIn(['editCart', 'deliveryDecisions'], payload.Context.DeliveryDecisions)
                .setIn(['editCart', 'serviceTaxDecisions'], payload.Context.ServiceTaxCustomizationDecisions);
        case SET_DELIVERY_DECISION:
            return state
                .setIn(['editCart', 'deliveryDecisions'], offerOrderingReducers.updateDeliveryDecision(state, payload));
        case EDIT_SUBMIT_COS_ORDER.BEGIN:
            return state.set('isSubmittingOrder', true);
        case EDIT_SUBMIT_COS_ORDER.SUCCESS:
            return state
                .set('isSubmittingOrder', false)
                .setIn(['data', 'modifiedOrder'], payload.Order);
        case EDIT_SUBMIT_COS_ORDER.FAILURE:
            return state
                .set('isSubmittingOrder', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity
                });
        case GO_TO_NEXT_STEP:
            return wizardReducers.goToNextStep(state, payload);
        case GO_TO_PREVIOUS_STEP:
            return wizardReducers.goToPreviousStep(state, payload);
        case GO_TO_STEP:
            return wizardReducers.goToStep(state, payload);
        case RESUME_STORE_ORDER_FOR_INVENTORY_AND_ATTRIBUTES_DECISIONS_FOR_EDIT.SUCCESS:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.transformAttributeGroups(offerOrderingReducers.setEditAttributeGroups(payload.Context.ValueDecisions, pathOr(null, ['editCart', 'editAttributeGroups'], state))))
                .setIn(['data', 'attributes', 'data', 'physicalInventoryAttributeGroups'], populatePhysicalInventoryAttributeGroups(payload.Context.PhysicalInventoryDecisions))
                .setIn(['data', 'attributes', 'data', 'physicalInventoryDecisions'], payload.Context.PhysicalInventoryDecisions)
                .setIn(['cartAdditionalProperties'], pathOr(EMPTY_ARRAY, ['ShoppingCart', 'AdditionalProperties'], payload));
            //Need to do PhysicalInventoryDecisions to editPhysicalAttributeGroups
        case RETRIEVE_ATTRIBUTES.SUCCESS:
            return state
                .setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.setEditAttributeGroups(payload.Context.ValueDecisions, pathOr(null, ['editCart', 'editAttributeGroups'], state)))
                .setIn(['editCart', 'deliveryDecisions'], payload.Context.DeliveryDecisions)
                .setIn(['editCart', 'serviceTaxDecisions'], payload.Context.ServiceTaxCustomizationDecisions);
        case RETRIEVE_ADDRESSES.SUCCESS:
            return offerOrderingReducers.setServiceAddress(state, payload);
        case SEARCH_INVENTORY.SUCCESS:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.updateAttributeInventoryOptions(state.editCart.editAttributeGroups, payload.InventoryItems));
        case SEARCH_INVENTORY.FAILURE:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.clearAttributeInventorySearching(state.editCart.editAttributeGroups));
        case SEND_PORT_IN_OR_ITEM_RESERVATION:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.sendPortInOrItemReservation(state.editCart.editAttributeGroups, payload.attributeId, payload.sendPortInReservation));
        case SET_ATTRIBUTE_SEARCHING_INVENTORY:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.setAttributeInventorySearching(state.editCart.editAttributeGroups, payload));
        case SET_EDIT_ATTRIBUTE_GROUPS:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.transformAttributeGroups(payload));
        case SET_EDIT_PHYSICAL_ATTRIBUTE_GROUPS:
            return state.setIn(['editCart', 'editPhysicalAttributeGroups'], !payload.formPhysicalAttributeGroups ? payload.physicalAttributeGroups : offerOrderingReducers.mergePhysicalAttributeGroups(payload.physicalAttributeGroups, payload.formPhysicalAttributeGroups));
        case SET_CURRENT_STEP_IS_INVALID:
            return wizardReducers.setCurrentStepIsInvalid(state, payload);
        case SET_EDIT_OPTION:
            return offerOrderingReducers.setEditOption(state, payload);
        case SET_IS_RESUMING_STORE_ORDER_FOR_EDIT_OFFER:
            return state.set(['isResumingStoreOrder'], payload);
        case SET_PAYMENT_INFO:
            return offerOrderingReducers.setPaymentInfo(state, payload);
        case SET_SELECTED_EDIT_OFFER_TAB:
            return wizardReducers.setSelectedOrderTab(state, payload);
        case SET_SELECTED_OFFER:
        {
            const nextState = offerOrderingReducers.clearStateFromOfferSelection(state, payload, DEFAULT_CART);
            return nextState
                .setIn(['cart', 'selectedOfferId'], payload)
                .set('editCart', DEFAULT_CART);
        }
        case SET_SELECTED_OFFER_EDIT_COPY:
        {
            const nextState = offerOrderingReducers.clearStateFromOfferSelection(state, payload, DEFAULT_CART);
            return nextState.setIn(['editCart', 'selectedOfferId'], payload);
        }
        case SET_SERVICE_TAX_LOCATION_DECISION_EDIT_OFFER:
            return state
                .setIn(['editCart', 'serviceTaxDecisions'], offerOrderingReducers.updateserviceTaxLocationDecision(state, payload));
        case UPDATE_EDIT_BRI_PRICE:
            return offerOrderingReducers.updateEditOptionPricing(state, payload);
        case UPDATE_INVENTORY_SELECTION:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.updateInventorySelection(state.editCart.editAttributeGroups, payload));
        case UPDATE_OFFER_ATTRIBUTE:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.updateAttributeGroups(state.editCart.editAttributeGroups, payload.attributeId, payload.formValue));
        case UPDATE_PAYMENT_INFO:
            return offerOrderingReducers.updatePaymentInfo(state, payload);
        case UPDATE_PORT_IN_REQUEST:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.updateAttributePortInRequest(
                state.editCart.editAttributeGroups,
                payload.attributeId,
                payload.portInData,
                payload.token,
                payload.address));
        case UPDATE_OFFER_PHYSICAL_INVENTORY_MAKE_AND_MODEL:
            return state.setIn(['editCart', 'editPhysicalAttributeGroups'], offerOrderingReducers.updatePhysicalInventoryMakeAndModel(state.editCart.editPhysicalAttributeGroups, payload));
        case UPDATE_OFFER_PHYSICAL_INVENTORY_TYPE_ATTRIBUTE:
            return state.setIn(['editCart', 'editPhysicalAttributeGroups'], offerOrderingReducers.updatePhysicalInventoryTypeAttribute(state.editCart.editPhysicalAttributeGroups, payload));
        case UPDATE_SELECTED_SERVICE_FEATURES:
            return state.setIn(['editCart', 'editAttributeGroups'], offerOrderingReducers.updateSelectedServiceFeatures(state.editCart.editAttributeGroups, payload));
        case NavigatorActions.STATE_CHANGE_SUCCESS:
            return setInitialState(state.isResumingStoreOrder);
        case SET_EDIT_CART_ADDITIONAL_PROPERTY:
            return state.setIn(['cartAdditionalProperties'], mapAdditionalProperty(payload));
        case SET_EDIT_CART_ADDITIONAL_PROPERTY_ON_CHANGE:
            return state.setIn(['cartAdditionalProperties'], setEditAp(payload, state.cartAdditionalProperties || EMPTY_ARRAY));
        case SET_EDIT_ORDER_ADDITIONAL_PROPERTY:
            return state.set('orderAdditionalProperties', mapAdditionalProperty(payload));
        case SET_EDIT_ORDER_ADDITIONAL_PROPERTY_ON_CHANGE:
            return state.set('orderAdditionalProperties', setEditAp(payload, state.orderAdditionalProperties || EMPTY_ARRAY));
        default:
            return state;
    }
}

function setInitialState(resumingStoreOrder) {
    return INITIAL_STATE.merge({
        isResumingStoreOrder: resumingStoreOrder,
    });
}

function updateInsufficientChargeOverrideAccessWarning(payload) {
    return payload && payload.Warnings && !!(payload.Warnings.find((warning) => {
        return warning.Code === RESUME_ORDER_INSUFFICIENT_ACCESS_WARNING_CODE;
    }));
}
