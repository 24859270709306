import compose from 'ramda/src/compose';
import curry from 'ramda/src/curry';
import pathOr from 'ramda/src/pathOr';
import Immutable from 'seamless-immutable';
import {
    ANONYMIZE_CUSTOMER,
    CANCEL_STORE_ORDER,
    CLEAR_CREATE_EDIT_CUSTOMER_FORM,
    CLEAR_SAVED_SHOPPING_CART,
    CREATE_CUSTOMER,
    CREATE_EDIT_CUSTOMER_FORM_CHANGED,
    CUSTOMER_WRAP_UP,
    FETCH_SHOPPING_CART,
    FETCH_STORE_ORDER,
    INVALIDATE_CREDIT_CLASSIFICATION,
    RETRIEVE_CUSTOMER,
    RETRIEVE_DEVICE_PIN_STATUS,
    RETRIEVE_SUBSCRIBER_OFFERING_DETAIL,
    SEND_CONSENT_TERMS,
    SET_CUSTOMER_CATEGORY,
    SET_EDIT_ADDITIONAL_PROPERTY,
    SET_EDIT_AP_ON_CHANGE,
    SET_IS_CONSENT_TERMS_SENT,
    SET_IS_EDITING_PROFILE,
    SET_RELOAD_SUBSCRIBER_STATE_FLAG,
    SET_SELECTED_SUBSCRIBER_HIERARCHY_ID,
    SET_SELECTED_SUBSCRIBER_OFFERING_INSTANCE_ID,
    SET_SERVICES_AND_OFFERS_SELECTED_TAB,
    UPDATE_CUSTOMER_BILL_CYCLE,
    UPDATE_LIFECYCLE_DETAILS,
    UPDATE_SUBSCRIBER
} from './actions/customer.actions';
import {
    ADD_WIZARD_PREFIX,
    ADD_SERVICES_WIZARD_PREFIX
} from './constants/wizard.constants';
import {CREATE_CHILD_CONSTANTS} from './actions/customer.account.hierarchy.actions';
import {mapAdditionalProperty, setEditAp} from './helpers/customer.helper';
import {INITIAL_STATE as CREATE_CUSTOMER_INITIAL_STATE} from './create.customer.reducer';
import addressesReducer, {INITIAL_STATE as ADDRESSES_INITIAL_STATE} from './customer.addresses.reducer';
import billCycleReducer, {INITIAL_STATE as BILL_CYCLE_INITIAL_STATE} from './customer.billcycle.reducer';
import billingInvoiceContentReducer, {INITIAL_STATE as BILLING_INVOICE_CONTENT_INITIAL_STATE} from './customer.billing.invoice.content.reducer';
import billingPaymentsReducer, {INITIAL_STATE as BILLING_PAYMENTS_INITIAL_STATE} from './customer.billing.payments.reducer';
import bulkItemsReducer, {INITIAL_STATE as BULK_ITEMS_INITIAL_STATE} from './customer.services.reducer';
import casesReducer, {INITIAL_STATE as CASES_INITIAL_STATE} from './customer.cases.reducer';
import challengeReducer, {INITIAL_STATE as CHALLENGE_INITIAL_STATE} from './customer.challenge.reducer';
import contentActivityReducer, {INITIAL_STATE as CONTENT_ACTIVITY_STATE} from './customer.content.activity.reducer';
import convergentBillerReducer, {INITIAL_STATE as CONVERGENT_BILLER_INITIAL_STATE} from './customer.convergent.biller.reducer';
import couponsReducer, {INITIAL_STATE as COUPONS_INITIAL_STATE} from './customer.coupons.reducer';
import demoActionsReducer, {INITIAL_STATE as DEMO_ACTIONS_INITIAL_STATE} from './demoActions.reducer';
import devicesReducer, {INITIAL_STATE as DEVICES_INITIAL_STATE} from './customer.devices.reducer';
import disconnectOfferingReducer, {INITIAL_STATE as DISCONNECT_OFFERING_INITIAL_STATE} from './offering.disconnect.reducer';
import ewalletReducer, {INITIAL_STATE as EWALLET_INITIAL_STATE} from './customer.ewallet.reducer';
import householdReducer, {INITIAL_STATE as HOUSEHOLD_INITIAL_STATE} from './customer.household.reducer';
import interactionsReducer, {INITIAL_STATE as INTERACTION_INITIAL_STATE} from './customer.interactions.reducer';
import inventoryReducer, {INITIAL_STATE as INVENTORY_INITIAL_STATE} from './customer.inventory.reducer';
import rateSheetReducer, {INITIAL_STATE as RATE_SHEET_INITIAL_STATE} from './customer.rate.sheet.reducer';
import offeringsReducer, {INITIAL_STATE as OFFERINGS_INITIAL_STATE} from './customer.offering.reducer';
import productDetailReducer, {INITIAL_STATE as PRODUCT_DETAIL_INITIAL_STATE} from './customer.product.detail.reducer';
import productsReducer, {INITIAL_STATE as PRODUCTS_INITIAL_STATE} from './customer.products.reducer';
import productServiceRelationshipsReducer, {INITIAL_STATE as PRODUCT_SERVICE_RELATIONSHIPS_INITIAL_STATE} from './product.service.relationships.reducer';
import remarksReducer, {INITIAL_STATE as REMARKS_INITIAL_STATE} from './customer.remarks.reducer';
import removeOfferingReducer, {INITIAL_STATE as REMOVE_OFFERING_INITIAL_STATE} from './offering.remove.reducer';
import SearchReducer, {INITIAL_STATE as SEARCH_INITIAL_STATE} from './search.reducer';
import SharedEntitlementsReducer, {INITIAL_STATE as SHARED_ENTITLEMENT_INITIAL_STATE} from './shared.entitlements.reducer';
import UsageDetailsEntitlementReducer, {INITIAL_STATE as USAGE_DETAILS_INITIAL_STATE} from './usage.details.reducer';
import subscriptionsReducer, {INITIAL_STATE as SUBSCRIPTIONS_INITIAL_STATE} from './customer.subscriptions.reducer';
import topUpsReducer, {INITIAL_STATE as TOP_UPS_INITIAL_STATE} from './customer.top.ups.reducer';
import totalsReducer, {INITIAL_STATE as TOTALS_INITIAL_STATE} from './customer.totals.reducer';
import transactionsReducer, {INITIAL_STATE as TRANSACTIONS_INITIAL_STATE} from './customer.transactions.reducer';
import orderHistoryReducer, {INITIAL_STATE as ORDER_HISTORY_INITIAL_STATE} from './order.history.reducer';
import accountHierarchyReducer, {INITIAL_STATE as ACCOUNT_HIERARCHY_INITIAL_STATE} from './customer.account.hierarchy.reducer';
import accountStatusReducer, {INITIAL_STATE as ACCOUNT_STATUS_INITIAL_STATE} from './customer.account.status.reducer';
import {
    CUSTOMER_INITIAL_STATE as CUSTOMER_CREDIT_CHECK_INITIAL_STATE,
    CustomerCreditCheckReducer
} from './credit.check.reducer';
import {
    RESET_SUBSCRIBER_CREDIT_CLASSIFICATION,
    UPDATE_CREDIT_CLASSIFICATION
} from './constants/credit.check.constants';
import {EMPTY_OBJECT} from './constants/common.constants';

export const INITIAL_STATE = Immutable({
    createCustomerModel: CREATE_CUSTOMER_INITIAL_STATE,
    instanceLevelOrdered: false,
    isCreatingCustomer: false,
    isEditingProfile: false,
    isFetchingCustomer: false,
    isFetchingData: false,
    isRetrievingConvergentBillerSubscriberAccounts: false,
    lastAttemptError: null,
    retrieveConvergentBillerSubscriberAccountsError: null,
    search: SEARCH_INITIAL_STATE,
    selectedCustomer: {
        data: {}, // The contents of the customer details
        subscriberAccounts: null, // "ConvergentBillerSubscriberAccounts"
        accountHierarchy: ACCOUNT_HIERARCHY_INITIAL_STATE,
        accountStatus: ACCOUNT_STATUS_INITIAL_STATE,
        addresses: ADDRESSES_INITIAL_STATE,
        billCycle: BILL_CYCLE_INITIAL_STATE,
        billingPaymentsInvoiceContent: BILLING_INVOICE_CONTENT_INITIAL_STATE,
        billingPayments: BILLING_PAYMENTS_INITIAL_STATE,
        bulkItems: BULK_ITEMS_INITIAL_STATE,
        cases: CASES_INITIAL_STATE,
        category: null,
        // Because of how the API works the ChallengeAnswer is
        // stored here and the ChallengeQuestion is stored in
        // the overview's data object below
        // TODO: API to return question/answer together
        challenge: CHALLENGE_INITIAL_STATE,
        contentActivity: CONTENT_ACTIVITY_STATE,
        convergentBiller: CONVERGENT_BILLER_INITIAL_STATE,
        coupons: COUPONS_INITIAL_STATE,
        creditCheck: CUSTOMER_CREDIT_CHECK_INITIAL_STATE,
        demoActions: DEMO_ACTIONS_INITIAL_STATE,
        devicePinStatus: null,
        devices: DEVICES_INITIAL_STATE,
        disconnectOffering: DISCONNECT_OFFERING_INITIAL_STATE,
        eWallet: EWALLET_INITIAL_STATE,
        household: HOUSEHOLD_INITIAL_STATE,
        interactions: INTERACTION_INITIAL_STATE,
        inventory: INVENTORY_INITIAL_STATE,
        isConsentTermsSent: null,
        isWrappingCustomer: false,
        offerings: OFFERINGS_INITIAL_STATE,
        orderHistory: ORDER_HISTORY_INITIAL_STATE,
        rateSheet: RATE_SHEET_INITIAL_STATE,
        productDetail: PRODUCT_DETAIL_INITIAL_STATE,
        products: PRODUCTS_INITIAL_STATE,
        productServiceRelationships: PRODUCT_SERVICE_RELATIONSHIPS_INITIAL_STATE,
        reloadSubscriberState: false,
        remarks: REMARKS_INITIAL_STATE,
        removeOffering: REMOVE_OFFERING_INITIAL_STATE,
        sharedEntitlements: SHARED_ENTITLEMENT_INITIAL_STATE,
        subscriptions: SUBSCRIPTIONS_INITIAL_STATE,
        topUps: TOP_UPS_INITIAL_STATE,
        totals: TOTALS_INITIAL_STATE,
        transactions: TRANSACTIONS_INITIAL_STATE,
        usageDetails: USAGE_DETAILS_INITIAL_STATE
    },
    shoppingCart: null,
    shoppingCartError: null,
    storeOrder: EMPTY_OBJECT,
    storeOrderError: EMPTY_OBJECT
});

export default function reducer(state = INITIAL_STATE, action) {
    return compose(
        setAccountHierarchyState(action),
        setAccountStatusState(action),
        setTopUpsState(action),
        setSearchState(action),
        setRemarksState(action),
        setHouseholdsState(action),
        setEWalletState(action),
        setDevicesState(action),
        setConvergentBillerState(action),
        setChallengeState(action),
        setCasesState(action),
        setContentActivityState(action),
        setCouponsState(action),
        setCustomerCreditCheckReducer(action),
        setDemoActionsState(action),
        setDisconnectOfferingState(action),
        setBillingPaymentsState(action),
        setbillingPaymentsInvoiceContentState(action),
        setAddressesState(action),
        setBillCycleState(action),
        setBulkItemsState(action),
        setCustomerState(action),
        setInteractionsState(action),
        setInventoryState(action),
        setOfferingState(action),
        setRateSheetState(action),
        setSharedEntitlementsState(action),
        setUsageDetailsState(action),
        setSubscriptionsState(action),
        setProductDetailState(action),
        setProductsState(action),
        setProductServiceRelationshipsState(action),
        setRemoveOfferingState(action),
        setOrderHistoryState(action),
        setTotalsState(action),
        setTransactionsState(action))(state);
}

export function customerReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case ANONYMIZE_CUSTOMER.BEGIN:
            return state.set('isFetchingData', true);
        case ANONYMIZE_CUSTOMER.SUCCESS:
            return state
                .set('isFetchingData', false)
                .set('lastAttemptError', null)
                .set('selectedCustomer', INITIAL_STATE.selectedCustomer);
        case ANONYMIZE_CUSTOMER.FAILURE:
            return state.merge({
                isFetchingData: false,
                lastAttemptError: {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                },
                selectedCustomer: INITIAL_STATE.selectedCustomer
            }, {
                deep: true
            });
        case SET_SELECTED_SUBSCRIBER_OFFERING_INSTANCE_ID:
            return state.setIn(['selectedCustomer', 'offerings', 'selectedSubscriberOfferingInstanceId'], payload);
        case SET_SELECTED_SUBSCRIBER_HIERARCHY_ID:
            return state.setIn(['selectedCustomer', 'data', 'HierarchyId'], payload);
        case RETRIEVE_SUBSCRIBER_OFFERING_DETAIL.BEGIN:
            return state.set('isFetchingOfferingDetail', true);
        case RETRIEVE_SUBSCRIBER_OFFERING_DETAIL.SUCCESS:
            return state
                .set('isFetchingOfferingDetail', false)
                .setIn(['selectedCustomer', 'offerings', 'selectedOffering'], payload);
        case RETRIEVE_SUBSCRIBER_OFFERING_DETAIL.FAILURE:
            return state.set('isFetchingOfferingDetail', false);
        case CREATE_CUSTOMER.BEGIN:
            return state.set('isCreatingCustomer', true);
        case CREATE_CUSTOMER.SUCCESS:
            return state
                .set('createCustomerModel', CREATE_CUSTOMER_INITIAL_STATE)
                .set('isCreatingCustomer', false)
                .set('lastAttemptError', null)
                .set('selectedCustomer', INITIAL_STATE.selectedCustomer)
                .setIn(['selectedCustomer', 'data'], prepareSubscriber(payload.Subscriber));
        case CREATE_CUSTOMER.FAILURE:
            return state
                .set('isCreatingCustomer', false)
                .set('lastAttemptError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                });
        case CREATE_CHILD_CONSTANTS.FAILURE:
            return state.set('lastAttemptError', {
                code: payload.Code,
                message: payload.Message,
                severity: payload.Severity,
                translatedMessage: payload.translatedMessage
            });
        case UPDATE_CREDIT_CLASSIFICATION.SUCCESS:
            return state
                .setIn(['selectedCustomer', 'data'], prepareSubscriber(payload.Subscriber));
        case UPDATE_SUBSCRIBER.BEGIN:
            return state.set('isFetchingData', true);
        case UPDATE_SUBSCRIBER.SUCCESS: {
            return state
                .set('isFetchingData', false)
                .set('lastAttemptError', null)
                .set('selectedCustomer', INITIAL_STATE.selectedCustomer)
                .setIn(['selectedCustomer', 'data'], payload.Subscriber);
        }
        case UPDATE_SUBSCRIBER.FAILURE:
            return state.merge({
                isFetchingData: false,
                lastAttemptError: {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                }
            }, {
                deep: true
            });
        case RETRIEVE_CUSTOMER.BEGIN:
            return state.set('isFetchingData', true);
        case SET_SERVICES_AND_OFFERS_SELECTED_TAB:
            return state
                .set('selectedTabServicesAndOffersWidget', payload);
        case RETRIEVE_CUSTOMER.SUCCESS:
            if (payload.Subscriber && pathOr(null, ['Subscriber', 'Id'], payload) === pathOr(null, ['selectedCustomer', 'data', 'Id'], state)) {
                const reloadSubscriber = pathOr(null, ['selectedCustomer', 'reloadSubscriberState'], state);
                const subscriberState = pathOr(null, ['selectedCustomer', 'data', 'State'], state);
                const turnOff = reloadSubscriber && `${payload.Subscriber.State}` !== subscriberState;
                return state
                    .set('isFetchingData', false)
                    .set('lastAttemptError', null)
                    .setIn(['selectedCustomer', 'reloadSubscriberState'], turnOff ? false : reloadSubscriber)
                    .setIn(['selectedCustomer', 'data'], payload.Subscriber ? prepareSubscriber(payload.Subscriber) : payload.Subscriber);
            } else {
                return state
                    .set('isFetchingData', false)
                    .set('lastAttemptError', null)
                    .set('selectedCustomer', INITIAL_STATE.selectedCustomer)
                    .setIn(['selectedCustomer', 'data'], payload.Subscriber ? prepareSubscriber(payload.Subscriber) : payload.Subscriber);
            }
        case RETRIEVE_CUSTOMER.FAILURE:
            return state.merge({
                isFetchingData: false,
                lastAttemptError: {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                },
                selectedCustomer: INITIAL_STATE.selectedCustomer
            }, {
                deep: true
            });
        case FETCH_SHOPPING_CART.BEGIN:
        case FETCH_STORE_ORDER.BEGIN:
        case CANCEL_STORE_ORDER.BEGIN:
            return state.set('isFetchingData', true);
        case FETCH_SHOPPING_CART.SUCCESS:
        case CLEAR_SAVED_SHOPPING_CART.SUCCESS:
        case `${ADD_WIZARD_PREFIX}_UPDATE_SHOPPING_CART_SUCCESS`:
        case `${ADD_SERVICES_WIZARD_PREFIX}_UPDATE_SHOPPING_CART_SUCCESS`:
            return state
                .set('shoppingCartError', null)
                .set('isFetchingData', false)
                .set('shoppingCart', payload.ShoppingCart)
                .set('invalidInventory', payload.InvalidInventoryItemReservations)
                .set('instanceLevelOrdered', payload.InstanceLevelOrdered);
        case FETCH_STORE_ORDER.SUCCESS:
        case CANCEL_STORE_ORDER.SUCCESS:
            return state
                .set('storeOrderError', EMPTY_OBJECT)
                .set('isFetchingData', false)
                .set('storeOrder', payload);
        case CANCEL_STORE_ORDER.FAILURE:
            return state.set('isFetchingData', false);
        case FETCH_STORE_ORDER.FAILURE:
            return state
                .set('storeOrderError', {
                    code: payload.Code,
                    subCode: payload.Subcode,
                    translatedMessage: payload.translatedMessage
                })
                .set('storeOrder', EMPTY_OBJECT)
                .set('isFetchingData', false);
        case FETCH_SHOPPING_CART.FAILURE:
            return state
                .set('isFetchingData', false)
                .set('shoppingCartError', {
                    code: payload.Code,
                    message: payload.Message,
                    severity: payload.Severity,
                    translatedMessage: payload.translatedMessage
                });
        case CREATE_EDIT_CUSTOMER_FORM_CHANGED:
            return state
                .set('createCustomerModel', payload);
        case UPDATE_LIFECYCLE_DETAILS:
            return state
                .set('lifeCycleDetails', payload);
        case CLEAR_CREATE_EDIT_CUSTOMER_FORM:
            return state
                .set('createCustomerModel', CREATE_CUSTOMER_INITIAL_STATE)
                .set('lastAttemptError', null);
        case SET_CUSTOMER_CATEGORY:
            return state
                .setIn(['selectedCustomer', 'category'], payload);
        case SET_EDIT_ADDITIONAL_PROPERTY:
            return state
                .setIn(['createCustomerModel', 'setEditAdditionalProp'], mapAdditionalProperty(payload));
        case SET_EDIT_AP_ON_CHANGE:
            return state
                .setIn(['createCustomerModel', 'setEditAdditionalProp'], setEditAp(payload, state.createCustomerModel.setEditAdditionalProp));
        case SET_IS_EDITING_PROFILE:
            return state
                .setIn(['isEditingProfile'], payload);
        case RETRIEVE_DEVICE_PIN_STATUS.BEGIN:
            return state.set('isFetchingData', true);
        case RETRIEVE_DEVICE_PIN_STATUS.SUCCESS:
            return state
                .set('isFetchingData', false)
                .setIn(['selectedCustomer', 'devicePinStatus'], payload.HasDevicePin);
        case RETRIEVE_DEVICE_PIN_STATUS.FAILURE:
            return state.set('isFetchingData', false);
        case RESET_SUBSCRIBER_CREDIT_CLASSIFICATION:
            return state
                .set('createCustomerModel', CREATE_CUSTOMER_INITIAL_STATE)
                .set('selectedCustomer', INITIAL_STATE.selectedCustomer);
        case INVALIDATE_CREDIT_CLASSIFICATION:
            return state.setIn(['selectedCustomer', 'creditCheck', 'isLoaded'], false);
        case SEND_CONSENT_TERMS.BEGIN:
            return state.setIn(['selectedCustomer', 'isConsentTermsSent'], null);
        case SEND_CONSENT_TERMS.FAILURE:
            return state.setIn(['selectedCustomer', 'isConsentTermsSent'], false);
        case SEND_CONSENT_TERMS.SUCCESS:
            return state.setIn(['selectedCustomer', 'isConsentTermsSent'], true);
        case SET_IS_CONSENT_TERMS_SENT:
            return state.setIn(['selectedCustomer', 'isConsentTermsSent'], payload);
        case CUSTOMER_WRAP_UP.BEGIN:
            return state.setIn(['selectedCustomer', 'isWrappingCustomer'], true);
        case CUSTOMER_WRAP_UP.FAILURE:
        case CUSTOMER_WRAP_UP.SUCCESS:
            return state.setIn(['selectedCustomer', 'isWrappingCustomer'], false);
        case SET_RELOAD_SUBSCRIBER_STATE_FLAG:
            return state.setIn(['selectedCustomer', 'reloadSubscriberState'], payload);
        case UPDATE_CUSTOMER_BILL_CYCLE.BEGIN:
            return state.set('isFetchingData', true);
        case UPDATE_CUSTOMER_BILL_CYCLE.SUCCESS:
            return state
                .set('isFetchingData', false);
        case UPDATE_CUSTOMER_BILL_CYCLE.FAILURE:
            return state
                .set('isFetchingData', false);
        default:
            return state;
    }
}

const setCustomerState = curry((action, state) => {
    return customerReducer(state, action);
});

const curriedSetState = curry((path, childReducer, accessorFunc, action, parentState) => {
    return parentState.setIn(path, childReducer(accessorFunc(parentState), action));
});

const prepareSubscriber = (subscriber) => {
    function coerceIfSet(value) {
        //This method is used to coerce code type references
        return value && `${value}`;
    }

    function coerceGenderCode(value) {
        // This method is used to coerce the Gender code type specifically.
        // Using the coerceIfSet does not work correctly with the Gender code type.
        if (value && value > 0) {
            return value && `${value}`;
        } else if (value === 0) {
            return '0';
        } else {
            return undefined;
        }
    }

    function prepareContactPreference(contactPreference) {
        // the API encodes these properties for contact preference as Numbers when they should be Strings
        return Object.assign({}, contactPreference, {
            ContactEventType: coerceIfSet(contactPreference.ContactEventType),
            ContactMethod: coerceIfSet(contactPreference.ContactMethod)
        });
    }
    // the API encodes the race and other codes for a subscriber as Numbers when they should be Strings
    return Object.assign({}, subscriber, {
        ContactPreferences: subscriber.ContactPreferences.map(prepareContactPreference),
        Gender: coerceGenderCode(subscriber.Gender),
        IncomeLevelType: coerceIfSet(subscriber.IncomeLevelType),
        InvoiceConfiguration: {
            HideZeroAmount: pathOr(undefined, ['InvoiceConfiguration', 'HideZeroAmount'], subscriber),
            InvoiceDetailLevel: coerceIfSet(pathOr(undefined, ['InvoiceConfiguration', 'InvoiceDetailLevel'], subscriber))
        },
        LeadSourceType: coerceIfSet(subscriber.LeadSourceType),
        NotificationCategoryPreferences: subscriber.NotificationCategoryPreferences,
        Race: coerceIfSet(subscriber.Race),
        Status: coerceIfSet(subscriber.Status),
        SubscriberTypeCode: coerceIfSet(subscriber.SubscriberTypeCode),
        State: coerceIfSet(subscriber.State),
        StateName: coerceIfSet(subscriber.StateName)

    });
};

// The functions below provide the first 3 arguments to `curriedSetState`. This creates
// a new function that is still expecting the last two arguments: action and parentState
const setOrderHistoryState = curriedSetState(
    ['selectedCustomer', 'orderHistory'],
    orderHistoryReducer,
    state => {
        return state.selectedCustomer.orderHistory;
    }
);
const setAccountHierarchyState = curriedSetState(
    ['selectedCustomer', 'accountHierarchy'],
    accountHierarchyReducer,
    state => {
        return state.selectedCustomer.accountHierarchy;
    }
);
const setAddressesState = curriedSetState(
    ['selectedCustomer', 'addresses'],
    addressesReducer,
    state => {
        return state.selectedCustomer.addresses;
    }
);

const setBillCycleState = curriedSetState(
    ['selectedCustomer', 'billCycle'],
    billCycleReducer,
    state => {
        return state.selectedCustomer.billCycle;
    }
);
const setbillingPaymentsInvoiceContentState = curriedSetState(
    ['selectedCustomer', 'billingPaymentsInvoiceContent'],
    billingInvoiceContentReducer,
    state => {
        return state.selectedCustomer.billingPaymentsInvoiceContent;
    }
);
const setBillingPaymentsState = curriedSetState(
    ['selectedCustomer', 'billingPayments'],
    billingPaymentsReducer,
    state => {
        return state.selectedCustomer.billingPayments;
    }
);

const setCasesState = curriedSetState(
    ['selectedCustomer', 'cases'],
    casesReducer,
    state => {
        return state.selectedCustomer.cases;
    }
);
const setBulkItemsState = curriedSetState(
    ['selectedCustomer', 'bulkItems'],
    bulkItemsReducer,
    state => {
        return state.selectedCustomer.bulkItems;
    }
);
const setChallengeState = curriedSetState(
    ['selectedCustomer', 'challenge'],
    challengeReducer,
    state => {
        return state.selectedCustomer.challenge;
    }
);
const setContentActivityState = curriedSetState(
    ['selectedCustomer', 'contentActivity'],
    contentActivityReducer,
    state => {
        return state.selectedCustomer.contentActivity;
    }
);
const setConvergentBillerState = curriedSetState(
    ['selectedCustomer', 'convergentBiller'],
    convergentBillerReducer,
    state => {
        return state.selectedCustomer.convergentBiller;
    }
);

const setCouponsState = curriedSetState(
    ['selectedCustomer', 'coupons'],
    couponsReducer,
    state => {
        return state.selectedCustomer.coupons;
    }
);
const setCustomerCreditCheckReducer = curriedSetState(
    ['selectedCustomer', 'creditCheck'],
    CustomerCreditCheckReducer,
    state => {
        return state.selectedCustomer.creditCheck;
    }
);
const setDemoActionsState = curriedSetState(
    ['selectedCustomer', 'demoActions'],
    demoActionsReducer,
    state => {
        return state.selectedCustomer.demoActions;
    }
);
const setDisconnectOfferingState = curriedSetState(
    ['selectedCustomer', 'disconnectOffering'],
    disconnectOfferingReducer,
    state => {
        return state.selectedCustomer.disconnectOffering;
    }
);
const setEWalletState = curriedSetState(
    ['selectedCustomer', 'eWallet'],
    ewalletReducer,
    state => {
        return state.selectedCustomer.eWallet;
    }
);
const setDevicesState = curriedSetState(
    ['selectedCustomer', 'devices'],
    devicesReducer,
    state => {
        return state.selectedCustomer.devices;
    }
);
const setHouseholdsState = curriedSetState(
    ['selectedCustomer', 'household'],
    householdReducer,
    state => {
        return state.selectedCustomer.household;
    }
);
const setInteractionsState = curriedSetState(
    ['selectedCustomer', 'interactions'],
    interactionsReducer,
    state => {
        return state.selectedCustomer.interactions;
    }
);
const setInventoryState = curriedSetState(
    ['selectedCustomer', 'inventory'],
    inventoryReducer,
    state => {
        return state.selectedCustomer.inventory;
    }
);
const setRateSheetState = curriedSetState(
    ['selectedCustomer', 'rateSheet'],
    rateSheetReducer,
    state => {
        return state.selectedCustomer.rateSheet;
    }
);
const setOfferingState = curriedSetState(
    ['selectedCustomer', 'offerings'],
    offeringsReducer,
    state => {
        return state.selectedCustomer.offerings;
    }
);
const setProductDetailState = curriedSetState(
    ['selectedCustomer', 'productDetail'],
    productDetailReducer,
    state => {
        return state.selectedCustomer.productDetail;
    }
);
const setProductsState = curriedSetState(
    ['selectedCustomer', 'products'],
    productsReducer,
    state => {
        return state.selectedCustomer.products;
    }
);
const setProductServiceRelationshipsState = curriedSetState(
    ['selectedCustomer', 'productServiceRelationships'],
    productServiceRelationshipsReducer,
    state => {
        return state.selectedCustomer.productServiceRelationships;
    }
);
const setRemarksState = curriedSetState(
    ['selectedCustomer', 'remarks'],
    remarksReducer,
    state => {
        return state.selectedCustomer.remarks;
    }
);
const setRemoveOfferingState = curriedSetState(
    ['selectedCustomer', 'removeOffering'],
    removeOfferingReducer,
    state => {
        return state.selectedCustomer.removeOffering;
    }
);
const setSearchState = curriedSetState(
    ['search'],
    SearchReducer,
    state => {
        return state.search;
    }
);
const setSharedEntitlementsState = curriedSetState(
    ['selectedCustomer', 'sharedEntitlements'],
    SharedEntitlementsReducer,
    (state) => {
        return state.selectedCustomer.sharedEntitlements;
    }
);

const setUsageDetailsState = curriedSetState(
    ['selectedCustomer', 'usageDetails'],
    UsageDetailsEntitlementReducer,
    (state) => {
        return state.selectedCustomer.usageDetails;
    }
);

const setSubscriptionsState = curriedSetState(
    ['selectedCustomer', 'subscriptions'],
    subscriptionsReducer,
    state => {
        return state.selectedCustomer.subscriptions;
    }
);
const setTopUpsState = curriedSetState(
    ['selectedCustomer', 'topUps'],
    topUpsReducer,
    state => {
        return state.selectedCustomer.topUps;
    }
);
const setTotalsState = curriedSetState(
    ['selectedCustomer', 'totals'],
    totalsReducer,
    state => {
        return state.selectedCustomer.totals;
    }
);
const setTransactionsState = curriedSetState(
    ['selectedCustomer', 'transactions'],
    transactionsReducer,
    state => {
        return state.selectedCustomer.transactions;
    }
);
const setAccountStatusState = curriedSetState(
    ['selectedCustomer', 'accountStatus'],
    accountStatusReducer,
    state => {
        return state.selectedCustomer.accountStatus;
    }
);
