export const CREDIT_CHECK_METHOD = {
    SKIPPED: 2
};

export const CREDIT_CHECK_STATUS = {
    NO_CHECK: 1,
    SUCCESS: 2,
    SKIPPED: 3,
    EXPIRED: 4,
    ERROR: 5,
    OVERRIDE: 6,
};

export const UPDATE_CLASSIFICATION_METHOD_STATUS = {
    OVERRIDE_CREDIT_CHECK: 3,
    RUN_CREDIT_CHECK: 1,
    RE_RUN_CREDIT_CHECK: 3,
    SKIP_CREDIT_CHECK: 2
};

export const CREDIT_CHECK_RESULT_SUCCESS_STATUS = 1;

export const RESET_SUBSCRIBER_CREDIT_CLASSIFICATION = 'RESET_SUBSCRIBER_CREDIT_CLASSIFICATION';

export const RETRIEVE_CREDIT_CLASSIFICATION_DETAILS = {
    BEGIN: 'RETRIEVE_CREDIT_CLASSIFICATION_DETAILS_BEGIN',
    SUCCESS: 'RETRIEVE_CREDIT_CLASSIFICATION_DETAILS_SUCCESS',
    FAILURE: 'RETRIEVE_CREDIT_CLASSIFICATION_DETAILS_FAILURE'
};

export const UPDATE_CREDIT_CLASSIFICATION = {
    BEGIN: 'UPDATE_CREDIT_CLASSIFICATION_BEGIN',
    SUCCESS: 'UPDATE_CREDIT_CLASSIFICATION_SUCCESS',
    FAILURE: 'UPDATE_CREDIT_CLASSIFICATION_FAILURE'
};

export const SET_CREDIT_CHECK_SKIPPED = 'SET_CREDIT_CHECK_SKIPPED';
export const SET_CREDIT_CHECK_MODAL_STATUS = 'SET_CREDIT_CHECK_MODAL_STATUS';
