import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {MetadataCodeTypeDictionarySelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {OfferingMetadataPricingPlansSelector} from 'invision-core/src/components/metadata/offerings/offerings.selectors';
import {SelectedCustomerSelector} from './customer.selectors';

const ProductServiceRelationshipsStoreSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.productServiceRelationships;
    }
);

export const ProductServiceRelationshipsSelector = createImmutableSelector(
    [ProductServiceRelationshipsStoreSelector, MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute), OfferingMetadataPricingPlansSelector],
    (productServiceRelationshipsStore, allServiceAttribute, pricingPlanThumbnails) => {
        return productServiceRelationshipsStore.data.map((item) => {
            return Object.assign({}, item, {
                serviceAttributeName: (item.NetworkIdentifierAttributeId && item.NetworkIdentifierAttributeId.Value && (Object.keys(allServiceAttribute).length > 0)) ?
                    allServiceAttribute[item.NetworkIdentifierAttributeId.Value].Name : null,
                PricingPlanName: pricingPlanThumbnails[`${item.PricingPlanId.Value}`].PricingPlanName,
                LinkableProviderPlans: (item.LinkableProviderPlans || []).map((providerPlan) => {
                    return Object.assign({}, providerPlan, {
                        PricingPlanName: pricingPlanThumbnails[`${providerPlan.PricingPlanId.Value}`].PricingPlanName
                    });
                })
            });
        });
    }
);

export const IsFetchingProductServiceRelationshipsSelector = createImmutableSelector(
    [ProductServiceRelationshipsStoreSelector],
    (productServiceRelationshipsStore) => {
        return productServiceRelationshipsStore.isFetching;
    }
);