import Immutable from 'seamless-immutable';
import {RESET_CUSTOMER_RECOVERABLE} from '../actions/customer.actions';
import * as TransactionActions from '../actions/customer.transactions.actions';

export const INITIAL_STATE = Immutable({
    showBanner: true,
    filterData: {
        brandableCurrency: null,
        currency: null,
        endDate: null,
        lockerItemId: null,
        pageSizePreference: null,
        paymentInstrument: null,
        startDate: null,
        transactionResult: [],
        transactionType: null
    },
    pageNumber: 1
});

export default function TransactionsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case TransactionActions.SET_CURRENT_PAGE:
            return state.set('pageNumber', payload);
        case TransactionActions.SET_BRANDABLE_CURRENCY_FILTER:
            return state.setIn(['filterData', 'brandableCurrency'], payload);
        case TransactionActions.SET_CURRENCY_FILTER:
            return state.setIn(['filterData', 'currency'], payload);
        case TransactionActions.SET_LOCKER_FILTER:
            return state.setIn(['filterData', 'lockerItemId'], payload);
        case TransactionActions.SET_PAYMENT_INSTRUMENT_FILTER:
            return state.setIn(['filterData', 'paymentInstrument'], payload);
        case TransactionActions.SET_TRANSACTION_RESULT_FILTER:
            return state.setIn(['filterData', 'transactionResult'], payload);
        case TransactionActions.SET_TRANSACTION_TYPE_FILTER:
            return state.setIn(['filterData', 'transactionTypes'], payload);
        case TransactionActions.SET_PAGE_SIZE_PREFERENCE:
            return state.setIn(['filterData', 'pageSizePreference'], payload);
        case TransactionActions.SHOW_SUBSCRIPTION_BANNER:
            return state.set('showBanner', payload);
        case TransactionActions.UPDATE_DATE_RANGE:
            return state.setIn(['filterData', 'endDate'], payload.endDate)
                .setIn(['filterData', 'startDate'], payload.startDate);
        case RESET_CUSTOMER_RECOVERABLE:
        case TransactionActions.REMOVE_TRANSACTIONS_FILTER:
            return INITIAL_STATE;
        default:
            return state;
    }
}
