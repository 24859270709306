import {BoolHelper} from 'invision-core';

export class ExternalBillAdditionalProperties {
    constructor() {
        this.accountNumberRegex = '';
        this.accountNumberRegexDescription = '';
        this.requireNameOnAccount = false;
        this.requirePhoneNumber = false;
        this.requireBillingAddress = false;
        this.requireAuthorizationChallenge = false;
    }
}

export class PaymentInstrumentTypeAdditionalProperties {
    constructor() {
        this.canBlocklist = false;
        this.isDirectSource = false;
        this.isShareable = false;
        this.userCreatable = false;
        this.userOrderable = false;
    }
}

export class PaymentInstrumentConfigurationAdditionalProperties {
    constructor() {
        this.paymentInstrumentTypeCode = false;
        this.requirePhoneNumber = false;
        this.supportsAutoPay = false;
        this.alertMaxActive =  Number.MAX_SAFE_INTEGER;
        this.alertMax =  Number.MAX_SAFE_INTEGER;
    }
}

export const ValueTypeEnum = {
    BOOLEAN: 'boolean',
    NUMBER: 'number',
    STRING: 'string'
};

export class AdditionPropertyDescriptor {
    constructor(key, propertyName, valueType) {
        this.key = key;
        this.propertyName = propertyName;
        this.valueType = valueType;
    }
}

export const externalBillAdditionalPropertyDescriptors = [
    new AdditionPropertyDescriptor('account_number_regex', 'accountNumberRegex', ValueTypeEnum.STRING),
    new AdditionPropertyDescriptor('account_number_regex_description', 'accountNumberRegexDescription', ValueTypeEnum.STRING),
    new AdditionPropertyDescriptor('require_name_on_account', 'requireNameOnAccount', ValueTypeEnum.BOOLEAN),
    new AdditionPropertyDescriptor('require_phone_number', 'requirePhoneNumber', ValueTypeEnum.BOOLEAN),
    new AdditionPropertyDescriptor('require_billing_address', 'requireBillingAddress', ValueTypeEnum.BOOLEAN),
    new AdditionPropertyDescriptor('require_authorization_challenge', 'requireAuthorizationChallenge', ValueTypeEnum.BOOLEAN)
];

export const paymentInstrumentTypeAdditionalPropertyDescriptors = [
    new AdditionPropertyDescriptor('can_blacklist', 'canBlocklist', ValueTypeEnum.BOOLEAN),
    new AdditionPropertyDescriptor('is_direct_source', 'isDirectSource', ValueTypeEnum.BOOLEAN),
    new AdditionPropertyDescriptor('is_shareable', 'isShareable', ValueTypeEnum.BOOLEAN),
    new AdditionPropertyDescriptor('user_creatable', 'userCreatable', ValueTypeEnum.BOOLEAN),
    new AdditionPropertyDescriptor('user_orderable', 'userOrderable', ValueTypeEnum.BOOLEAN)
];

export const paymentInstrumentConfigurationAdditionalPropertyDescriptors = [
    new AdditionPropertyDescriptor('payment_instrument_type_code', 'paymentInstrumentTypeCode', ValueTypeEnum.STRING),
    new AdditionPropertyDescriptor('requires_phone_number', 'requirePhoneNumber', ValueTypeEnum.BOOLEAN),
    new AdditionPropertyDescriptor('alert_max_active', 'alertMaxActive', ValueTypeEnum.NUMBER),
    new AdditionPropertyDescriptor('alert_max', 'alertMax', ValueTypeEnum.NUMBER),
    new AdditionPropertyDescriptor('supports_autopay', 'supportsAutoPay', ValueTypeEnum.BOOLEAN),
];

/**
 * Parse additionalProperties values into baseObject,
 * a sample additional properties can be accessed at https://qa6.metadata.ascendon.tv/Codes/SystemId/64c0bb7a-53ec-4130-a2ec-8dc8a024d98f/Type/223
 * @param baseObject
 * @param additionalProperties
 * @param descriptors
 */
export const parseAdditionalProperties = ( baseObject, additionalProperties, descriptors ) => {
    const descriptorByKey = descriptors.reduce( (acc, descriptor) => {
        const key = descriptor.key;
        acc[key] = descriptor;
        return acc;
    }, {});

    (additionalProperties || []).forEach((prop) => {
        const key = prop.Key;
        const descriptor = descriptorByKey[key];

        if (descriptor) {
            const rawValue = prop.Value;
            const type = descriptor.valueType;
            const propName = descriptor.propertyName;
            const defaultValue = baseObject[propName];
            let parsedValue;

            if (type === ValueTypeEnum.BOOLEAN) {
                parsedValue = BoolHelper.getBoolOrDefault(rawValue, defaultValue);
            } else if (type === ValueTypeEnum.NUMBER) {
                parsedValue = parseFloat(rawValue);
            } else if (type === ValueTypeEnum.STRING) {
                parsedValue = (rawValue !== null && typeof(rawValue) !== 'undefined') ? rawValue.toString() : rawValue;
            }

            baseObject[propName] = parsedValue;
        }
    });

    return baseObject;
};
