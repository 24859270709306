import {
    EMPTY_PRODUCTS_LIST,
    REMOVE_PRODUCTS_CONSTANTS,
    RETRIEVE_PRODUCTS_CONSTANTS,
    SET_ACTIVE_PRODUCT,
    SET_REMOVE_PRODUCT_EXECUTION_OPTIONS
} from './actions/customer.products.actions';
import {RETRIEVE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS} from './actions/customer.convergent.biller.actions';
import Immutable from 'seamless-immutable';

export const INITIAL_STATE = Immutable({
    activeProduct: null,
    activeProducts: null,
    activeProductsError: null,
    data: null,
    executionOptions: {
        effective: null,
        specifiedDate: null
    },
    isFetchingData: null,
    isFetchingActiveProductsData: null,
    isRemovingProduct: null,
    pageCount: null,
    productsError: null,
    recordCount: null
});

export default function productsReducer(state = INITIAL_STATE, {payload = {}, type}) {
    switch (type) {
        case RETRIEVE_PRODUCTS_CONSTANTS.BEGIN:
            return state.set('isFetchingData', true);
        case RETRIEVE_PRODUCTS_CONSTANTS.SUCCESS:
            return state
                .set('data', payload)
                .set('isFetchingData', false)
                .set('pageCount', payload.PageCount)
                .set('productsError', null)
                .set('recordCount', payload.RecordCount);
        case RETRIEVE_PRODUCTS_CONSTANTS.FAILURE:
            return state
                .set('isFetchingData', false)
                .set('productsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case RETRIEVE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS.BEGIN:
            return state.set('isFetchingActiveProductsData', true);
        case RETRIEVE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS.SUCCESS:
            return state
                .set('activeProducts', payload.Standalones)
                .set('isFetchingActiveProductsData', false)
                .set('activeProductsError', null);
        case RETRIEVE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS.FAILURE:
            return state
                .set('isFetchingActiveProductsData', false)
                .set('activeProductsError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case REMOVE_PRODUCTS_CONSTANTS.BEGIN:
            return state
                .set('isRemovingProduct', true);
        case REMOVE_PRODUCTS_CONSTANTS.SUCCESS:
            return state
                .set('isRemovingProduct', false);
        case REMOVE_PRODUCTS_CONSTANTS.FAILURE:
            return state
                .set('isRemovingProduct', false);
        case SET_ACTIVE_PRODUCT:
            return state
                .set('activeProduct', payload);
        case SET_REMOVE_PRODUCT_EXECUTION_OPTIONS:
            return state.set('executionOptions', payload);
        case EMPTY_PRODUCTS_LIST:
            return INITIAL_STATE;
        default:
            return state;
    }
}
